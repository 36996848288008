import { AfterViewInit, ChangeDetectorRef, Component, OnInit, QueryList, ViewChild, ViewChildren } from '@angular/core';
import { GrpcNode, GrpcNodeProperty } from '@xprojectorcore/xprojector_backend/proto/xprojector.grpc.models.pb';
import { GrpcDataSourceInstance, SearchNodesRequest, SearchProperty } from '@xprojectorcore/xprojector_backend/proto/xprojector.xconf.pb';
import { XProjectorXConfClient } from '@xprojectorcore/xprojector_backend/xprojector-xconf-client';
import { SplitAreaDirective } from 'angular-split';
import { XprojAlertService, XProjectorClient, ArrayUtils, XprojModalService, DashboardOutputChangeParameters, XprojDashboardComponent, OutputDataType, DateHelper, LinkedWidgetChangeParameters, XprojDashboardInteractService } from 'xproj-lib';
import { BmsTrustee } from '@features/bms/models/bms-trustee';
import { BmsBillingPeriod, BmsExportBotConfig, BmsExportBotExecution, BmsMonthOfYear } from '@core/models/bms-export-bot';
import { XbotExecutionService } from '@xprojectorcore/services/xbot-execution.service';
import { XbotExecutionResult, XbotExecutionStatus } from '@xprojectorcore/models/xbot-execution-queue-item';
import { XProjectorFilesClient } from '@xprojectorcore/xprojector_backend/xprojector-files-client';
import { saveAs } from 'file-saver';
import { FileInfo } from '@xprojectorcore/models/file-info';
import { ClrDatagridColumn, ClrDatagridComparatorInterface, ClrDatagridNumericFilterInterface, ClrDatagridSortOrder, ClrDatagridStringFilterInterface } from '@clr/angular';
import { StateService } from '@xprojectorcore/services/state-service';
import { BmsDataExportService } from '@features/bms/bms-admin/services/bms-data-export-service';
import { BmsDataExportValue, BmsDataExportValueStatus } from '@features/bms/models/bms-data-export-value';
import { BmsAdminService } from '@features/bms/bms-admin/services/bms-admin-service';
import { BmsCustomerConfig } from '@features/bms/models/bms-customer-config';
import { TypedJSON } from 'typedjson';
import { ActivatedRoute, Router } from '@angular/router';
import { map } from 'rxjs/operators';
import { NGXLogger } from 'ngx-logger';
import { UnitConversionsService } from '@xprojectorcore/services/unit-conversions.service';
import { XProjectorBmsExportClient } from '@core/xprojector_backend/xprojector-bms-export-client';
import { BmsDataUtils } from '@features/bms/bms-admin/utils/bms-data-utils';
import { BmsStateService } from '@core/services/bms-state-service';
import { DataExportStatusFilterComponent } from '../../filters/data-export-status-filter/data-export-status-filter.component';
import { DataExportExternalidFilterComponent } from '../../filters/data-export-externalid-filter/data-export-externalid-filter.component';
import { XProjectorBmsCustomerAdminClient } from '@app/core/xprojector_backend/xprojector-bms-customeradmin-client';
import { debug } from 'console';

class ExternalIdComparator implements ClrDatagridComparatorInterface<BmsDataExportValue> {
  compare(a: BmsDataExportValue, b: BmsDataExportValue) {
    if (a && b && a.externalId && b.externalId) {
      return a.externalId.padStart(10, '0') > b.externalId.padStart(10, '0') ? 1 : -1;
    }
    else {
      return a ? 1 : -1
    }
  }
}

class ExternalIdFilter implements ClrDatagridStringFilterInterface<BmsDataExportValue> {

  accepts(item: BmsDataExportValue, search: string) : boolean {
    let result = false;
    let externalId = item.externalId.toLowerCase();
    search.split(',').forEach(s => {
      if (s.startsWith('_')) {
        result = result || s.substring(1) == externalId;
      }
      else {
        result = result || externalId.indexOf(s) >= 0;
      }

    });

    return result;
  }
}

class SvLantFilter implements ClrDatagridStringFilterInterface<BmsDataExportValue> {

  accepts(item: BmsDataExportValue, search: string) : boolean {
    let result = false;
    let svLantApartmentNo = item.svLantApartmentNo + '';
    search.split(',').forEach(s => {
      if (s.startsWith('_')) {
        result = result || s.substring(1) == svLantApartmentNo;
      }
      else {
        result = result || svLantApartmentNo.indexOf(s) >= 0;
      }

    });

    return result;
  }
}

class MeterIdFilter implements ClrDatagridStringFilterInterface<BmsDataExportValue> {

  accepts(item: BmsDataExportValue, search: string) : boolean {
    let result = false;
    let meterId = item.meterId + '';
    search.split(',').forEach(s => {
      if (s.startsWith('_')) {
        result = result || s.substring(1) == meterId;
      }
      else {
        result = result || meterId.indexOf(s) >= 0;
      }

    });

    return result;
  }
}

class DiffFilter implements ClrDatagridNumericFilterInterface<BmsDataExportValue> {

  accepts(item: BmsDataExportValue, low: number, high: number) : boolean {
    let result = true;

    if (low != null &&  item.diff < low) {
      result = false;
    }
    else if (high != null && (item.diff > high || item.diff < -9999999999)) {
      result = false;
    }

    return result;
  }
}

class OverviewCustomerInfo {
  customerId: string;
  customerConfig: BmsCustomerConfig;
  customerName: string;
  dataExportValues: BmsDataExportValue[];
  errorCount: number;
  allSigned: boolean;
  allInvoiced: boolean;
  hasData: boolean;
  active: boolean;
  status: BmsDataExportValueStatus;
  exportPeriods: { start: Date, end: Date }[];
  initiated: boolean;
  tariffsSet: boolean;
}

class BillingPeriodConfig {
  billingPeriod: BmsBillingPeriod;
  startMonth: BmsMonthOfYear;
  exportPeriods: { start: Date, end: Date }[];
  overviewCustomerInfos: OverviewCustomerInfo[];
  selectedExecExportPeriods: number;
  filterLateInvoice : boolean = false;
  filterSpecialInvoice : boolean = false;
}

enum FlagStatus {
  All = 0,
  OnlyTrue = 1,
  OnlyFalse = 2
}

enum EditAllMode {
  StartValue = 0,
  Tariff = 1,
  EndValue = 2,
  Consumption = 3
}

const errorFlags: BmsDataExportValueStatus = BmsDataExportValueStatus.Error | BmsDataExportValueStatus.Warning | BmsDataExportValueStatus.MissingStartValue
  | BmsDataExportValueStatus.MissingEndValue | BmsDataExportValueStatus.MissingTariff;

@Component({
  selector: 'app-bms-trustee-admin',
  templateUrl: './bms-trustee-admin.component.html',
  styleUrls: ['./bms-trustee-admin.component.scss']
})
export class BmsTrusteeAdminComponent implements OnInit, AfterViewInit {
  @ViewChildren(SplitAreaDirective) areasEl: QueryList<SplitAreaDirective>
  @ViewChildren(ClrDatagridColumn) tableColumns: QueryList<ClrDatagridColumn>;

  trustees: BmsTrustee[] = [];

  loading: boolean = false;
  overviewLoading: boolean = false;
  selectedTrustee: BmsTrustee = null;
  inparamTrusteeId: string;
  inparamCustomerId: string;
  selectedTrusteeConfig: BmsCustomerConfig;
  selectedTrusteeCustomers: GrpcNode[] = [];

  loadingCustomer: boolean = false;
  selectedCustomers = [];

  showAddCustomer: boolean = false;
  addNewCustomerNode : GrpcNode;
  customers: GrpcNode[] = [];
  filteredCustomers: GrpcNode[] = [];

  showImportData: boolean = false;
  importDatafile: any;
  importDatafileMetertype: string;
  importDatafileExportPeriod: number = 0;

  exportConfigs: GrpcNode[] = [];
  loadingExport: boolean = false;
  selectedExport: number = -1;
  selectedExportConfigNode: GrpcNode = null;
  selectedExportConfig: BmsExportBotConfig = null;

  billingPeriodConfigs: BillingPeriodConfig[] = [];

  execCreateFiles: boolean = false;

  showEditExportConfigs: boolean = false;
  botsDataSourceInstance: GrpcDataSourceInstance;
  dataReportsDataSourceInstance: GrpcDataSourceInstance;

  showEditExportProperties: boolean = false;
  botExecConfig = new BmsExportBotExecution();
  execExportPeriods: { start: Date, end: Date }[] = [];
  customeConfigsByCustomer: Map<string, BmsCustomerConfig>;
  selectedExecExportPeriods: number = 0;
  execStatusMessage: string = '';

  showExportedFiles: boolean = false;
  exportedFileInfos: FileInfo[] = [];
  selectedExportFileInfo: FileInfo;

  showEditAllExportStartDataValues: boolean = false;
  showEditAllExportEndDataValues: boolean = false;
  showEditAllExportConsumptions: boolean = false;
  showEditAllExportTariffs: boolean = false;
  editAllData: {
    startValue: number,
    setToFirstPeriodValue: boolean,
    overridePreviousValues: boolean,
    tariff?: number,
    endValueDate : Date,
    endValueDateString : string,
    onlySelected : boolean
  } = { startValue: 0,
        setToFirstPeriodValue: true,
        overridePreviousValues: false,
        endValueDate: null,
        endValueDateString: this.dateHelper.utils.formatByString(new Date(), 'yyyy-MM-dd'),
        onlySelected: false };

  gridDetailState: any = null;
  dashboardGridDetailOutputParameters: DashboardOutputChangeParameters[] = [];
  dashboardGridDetailManualDataValue : boolean = false;
  dashboardGridDetailDataExportValue : BmsDataExportValue;
  periodLastYearSet : boolean = false;

  showViewInvoiced: boolean = false;
  invoicedCustomers: string[] = [];

  _overviewActive: boolean = false;
  get overviewActive(): boolean {
    return this._overviewActive;
  }
  set overviewActive(value: boolean) {
    this._overviewActive = value;
    if (this._overviewActive) {
      this.updateCustomerInfos();
    }
  }

  configActive: boolean = false;
  dataReportBasisActive: boolean = false;

  dataBasisOverviewCustomerInfo: OverviewCustomerInfo;

  //dataBasisCustomerId: string;
  selectedDataBasisExportPeriod: number = 0;
  selectedExportDataValues: BmsDataExportValue[] = [];
  selectedExportDataValueCopy: BmsDataExportValue;
  selectedExportDataValueEdit: BmsDataExportValue;
  responsiveWidthEdit: number = 700;
  editExtrapolateStart: Date;
  editExtrapolateStartString: string = '1977-01-01';
  editExtrapolateEnd: Date;
  editExtrapolateEndString: string = '1977-01-01';
  selectedExportDataValueMeterType: string;
  loadingDataExportValues: boolean = false;
  dataExportValues: BmsDataExportValue[] = [];
  dataExportValuesByMeterType: Map<string, BmsDataExportValue[]> = new Map<string, BmsDataExportValue[]>();
  dataPointValueStep: string = '0.01';
  tariffValueStep: string = '0.01';
  dataExportValuesMeterTypes: string[] = [];
  dashboardOutputParameters: DashboardOutputChangeParameters[] = [];
  responsiveWidth: number = 834;
  showEditExportDataValue: boolean = false;
  showEditManualExportDataValue: boolean = false;
  selectedExportSignedFlagStatus: FlagStatus = FlagStatus.All;
  selectedExportMissingValueFlagStatus: FlagStatus = FlagStatus.All;
  selectedExportErrorFlagStatus: FlagStatus = FlagStatus.All;
  selectedExportWarningFlagStatus: FlagStatus = FlagStatus.All;
  meterTypeOptionNodes: GrpcNode[] = [];
  errorCount: number;
  allSigned: boolean;
  allInvoiced: boolean;
  hasData: boolean;

  overviewCustomerInfos: OverviewCustomerInfo[] = [];
  // selectedOverviewExportPeriod: number = 0;

  extrapolateAllCurrent: number = 0;
  extrapolateAllTotal: number = 0;
  extrapolateAllPercent: number = 0;
  extrapolateAllRunning: boolean = false;

  deviceIdFilter: string = '';
  exportDataMeterId: number = -1;

  ascSort = ClrDatagridSortOrder.ASC;
  descSort = ClrDatagridSortOrder.DESC;
  externalIdSort = new ExternalIdComparator();
  externalIdFilter = new ExternalIdFilter();
  svLantFilter = new SvLantFilter();
  meterIdFilter = new MeterIdFilter();
  diffFilter = new DiffFilter();

  copyOperationInfo : string = '';
  editOperationMode : boolean = false;
  copyInvoiceInfo : string = '';
  editInvoiceMode : boolean = false;

  rightPanelWidth: number = 300;

  BmsBillingPeriod: BmsBillingPeriod;
  FlagStatus = FlagStatus;
  EditAllMode = EditAllMode;

  constructor(
    private state: StateService,
    private bmsState: BmsStateService,
    private router: Router,
    private alertService: XprojAlertService,
    private xConfClient: XProjectorXConfClient,
    private xbotExecutionService: XbotExecutionService,
    private xProjectorFilesClient: XProjectorFilesClient,
    private dataExportService: BmsDataExportService,
    private adminService: BmsAdminService,
    private modalService: XprojModalService,
    private cdr: ChangeDetectorRef,
    private logger: NGXLogger,
    private route: ActivatedRoute,
    private dateHelper: DateHelper,
    private unitConversions: UnitConversionsService,
    private bmsExportClient: XProjectorBmsExportClient,
    private dashboardInteractService: XprojDashboardInteractService,
    private bmsCustomerAdminClient: XProjectorBmsCustomerAdminClient
  ) { }


  async ngOnInit() {
    this.route.params.pipe(map(p => p.id)).subscribe(async (id) => {
      if (id) {
        this.inparamTrusteeId = id;
      }
    });
    this.route.params.pipe(map(p => p.customerid)).subscribe(async (customerid) => {
      if (customerid) {
        this.inparamCustomerId = customerid;
      }
    });

    let lsrightPanelWidth = Number.parseInt(localStorage.getItem("xprojector-bmsrightPanelWidth") || this.rightPanelWidth.toString());
    if (lsrightPanelWidth != this.rightPanelWidth) {
      this.rightPanelWidth = lsrightPanelWidth;
    }

    if (!this.inparamTrusteeId) {
      let lastTrusteeId = localStorage.getItem("xprojector-trustee-admin-lasttrusteeid");
      if (lastTrusteeId) {
        this.inparamTrusteeId = lastTrusteeId;
      }
    }

    this.execExportPeriods = this.getExportPeriods(this.selectedExportConfig?.billingPeriod, this.selectedExportConfig?.billingPeriodStartMonth);

    await this.updateTrustees();

    if (this.trustees.length > 0) {
      if (!this.selectedTrustee) {
        this.selectedTrustee = this.trustees[0];
      }
    }
  }

  async ngAfterViewInit() {
    // if (this.trustees.length > 0) {
    //   this.selectedTrustee = this.trustees[0];
    //   this.updateTrusteeConfig();
    //   await this.updateSelectedCustomers();
    // }
  }

  async onSplitDragEnd($event) {
    let treePaneArea = this.getPaneArea(2);

    if (treePaneArea) {
      this.rightPanelWidth = treePaneArea.elRef.nativeElement.clientWidth;
      localStorage.setItem("xprojector-bmsrightPanelWidth", this.rightPanelWidth.toString());
    }

    let dashboardPaneArea = this.getPaneArea(1);
    if (dashboardPaneArea) {
      this.responsiveWidth = (dashboardPaneArea.elRef.nativeElement.clientWidth * 2) / 3 - 75;
    }
  }

  getPaneArea(order: number): SplitAreaDirective {
    let result: SplitAreaDirective = undefined;
    this.areasEl?.forEach(area => {
      if (area.order == order) {
        result = area;
      }
    });

    return result;
  }

  async updateTrusteeConfig() {
    if (this.selectedTrustee) {
      this.selectedTrusteeConfig = await this.adminService.getCustomerConfig(this.selectedTrustee?.id);
      if (this.selectedTrusteeConfig) {
        switch (this.selectedTrusteeConfig.reportDataPointDecimalCount) {
          case 0:
            this.dataPointValueStep = '1';
            break;
          case 1:
            this.dataPointValueStep = '0.1';
            break;
          case 2:
            this.dataPointValueStep = '0.01';
            break;
          case 3:
            this.dataPointValueStep = '0.001';
            break;
          default:
            this.dataPointValueStep = '0.0001';
            break;
        }

        switch (this.selectedTrusteeConfig.tariffDecimalCount) {
          case 0:
            this.tariffValueStep = '1';
            break;
          case 1:
            this.tariffValueStep = '0.1';
            break;
          case 2:
            this.tariffValueStep = '0.01';
            break;
          case 3:
            this.tariffValueStep = '0.001';
            break;
          default:
            this.tariffValueStep = '0.0001';
            break;
        }
      }
    }
  }

  async updateTrustees() {
    this.loading = true;
    try {
      this.trustees = await this.adminService.getTrustees();
      if (this.inparamTrusteeId?.length > 0) {
        this.selectedTrustee = this.trustees.find(trustee => trustee.id == this.inparamTrusteeId);
      }
    }
    finally {
      this.loading = false;
    }
  }

  async selectedTrusteeChanged($event) {
    this.selectedExport = null;
    this.selectedExport = -1;
    this.selectedExportConfigNode = null;
    this.selectedExportConfig = null;
    this.exportConfigs = [];
    this.selectedCustomers = [];
    this.selectedExportDataValues = [];
    this.selectedExportDataValueEdit = null;
    this.selectedExportDataValueCopy = null;
    this.botsDataSourceInstance = null;
    this.dataReportsDataSourceInstance = null;
    this.customeConfigsByCustomer = null;

    this.overviewActive = true;

    if (this.selectedTrustee) {
      localStorage.setItem("xprojector-trustee-admin-lasttrusteeid", this.selectedTrustee.id);
    }

    await this.updateTrusteeConfig();
    await this.updateExportConfigs();
    await this.updateSelectedCustomers();
  }

  selectedCustomerChanged($event) {
    this.logger.info($event, this.selectedCustomers);
  }

  async updateSelectedCustomers() {
    this.selectedTrusteeCustomers = [];
    if (this.selectedTrustee) {
      this.selectedTrusteeCustomers = await this.xConfClient.getReferencedNodes(
        '_x_bms_trustee_root_' + this.selectedTrustee.id,
        '_x_bms_trustee_root',
        [],
        '_x_datasource'
      );

      this.selectedTrusteeCustomers.sort((a, b) => a.name > b.name ? 1 : -1);
      this.initOverviewCustomerStatusInfo();
    }
  }

  async initOverviewCustomerStatusInfo() {
    if (this.selectedTrusteeCustomers) {
      this.overviewCustomerInfos = [];
      this.selectedTrusteeCustomers.forEach(customer => {
        this.overviewCustomerInfos.push({
          customerId: customer.id,
          customerConfig: undefined,
          customerName: customer.name,
          dataExportValues: [],
          status: BmsDataExportValueStatus.None,
          errorCount: 0,
          allSigned: false,
          allInvoiced: false,
          hasData : false,
          active: true,
          exportPeriods: [],
          initiated: false,
          tariffsSet: false
        });
      });

      this.updateCustomerInfos();
    }
  }

  async updateCustomerInfos() {
    this.overviewLoading = true;
    try {
      await ArrayUtils.AsyncForEach(this.billingPeriodConfigs, async (billingPeriodConfig) => {
        billingPeriodConfig.overviewCustomerInfo = [];
        await this.updateOverviewCustomerInfos(billingPeriodConfig, true);
      });
    }
    finally {
      this.overviewLoading = false;
    }
  }

  async updateOverviewCustomerInfos(billingPeriodConfig: BillingPeriodConfig, force: boolean = false) {
    let customerIds : string[] = [];
    let loadCustomerConfigs : boolean = false;
    this.overviewCustomerInfos.forEach(info => {
      customerIds.push(info.customerId)
      if (!info.customerConfig) {
        loadCustomerConfigs = true;
      }
    });
    if (loadCustomerConfigs) {
      let customerConfigs = await this.adminService.getCustomerConfigs(customerIds);
      this.overviewCustomerInfos.forEach(info => {
        info.customerConfig = customerConfigs.find(x => x.customerId == info.customerId);
      });
    }

    if (billingPeriodConfig.overviewCustomerInfos.length == 0) {
      this.overviewCustomerInfos.forEach((info) => {
        if (info.customerConfig) {
          if (info.customerConfig.billingPeriod == billingPeriodConfig.billingPeriod &&
            (billingPeriodConfig.billingPeriod != BmsBillingPeriod.Quarter || info.customerConfig.billingPeriodStartMonth == billingPeriodConfig.startMonth)) {
            billingPeriodConfig.overviewCustomerInfos.push(info);
            info.exportPeriods = billingPeriodConfig.exportPeriods;

            //Billing period changed?
            if (info.exportPeriods.length > 0 && info.customerConfig.billingPeriodChangedAt && info.customerConfig.billingPeriodChangedAt > info.exportPeriods[info.exportPeriods.length - 1].start) {
              if (info.customerConfig.billingPeriod == BmsBillingPeriod.Month) {
                let exportPeriods: { start: Date, end: Date }[] = [];
                let quarters = this.getExportPeriods(BmsBillingPeriod.Quarter, info.customerConfig.billingPeriodStartMonth);
                for (let i = quarters.length - 1; i >= 0; i--) {
                  if (quarters[i].end < info.customerConfig.billingPeriodChangedAt) {
                    exportPeriods.push(quarters[i]);
                  }
                  else {
                    break;
                  }
                }

                for (let i = info.exportPeriods.length - 1; i >= 0; i--) {
                  if (info.exportPeriods[i].end > info.customerConfig.billingPeriodChangedAt) {
                    exportPeriods.push(info.exportPeriods[i]);
                  }
                }

                info.exportPeriods = exportPeriods.sort((a, b) => a.start < b.start ? 1 : -1);
              }
              else if (info.customerConfig.billingPeriod == BmsBillingPeriod.Quarter) {
                let exportPeriods: { start: Date, end: Date }[] = [];
                let months = this.getExportPeriods(BmsBillingPeriod.Month, info.customerConfig.billingPeriodStartMonth);
                for (let i = months.length - 1; i >= 0; i--) {
                  if (months[i].end < info.customerConfig.billingPeriodChangedAt) {
                    exportPeriods.push(months[i]);
                  }
                  else {
                    break;
                  }
                }

                for (let i = info.exportPeriods.length - 1; i >= 0; i--) {
                  if (info.exportPeriods[i].end > info.customerConfig.billingPeriodChangedAt) {
                    exportPeriods.push(info.exportPeriods[i]);
                  }
                }

                info.exportPeriods = exportPeriods.sort((a, b) => a.start < b.start ? 1 : -1);
              }
            }
          }
          else {
            if (info.customerConfig && info.customerConfig.billingPeriod != billingPeriodConfig.billingPeriod
              && info.customerConfig.billingPeriodChangedAt > billingPeriodConfig.exportPeriods[billingPeriodConfig.exportPeriods.length - 1].start) {
              billingPeriodConfig.overviewCustomerInfos.push({
                customerId: info.customerId,
                customerConfig: info.customerConfig,
                customerName: info.customerName,
                dataExportValues: [],
                status: BmsDataExportValueStatus.None,
                errorCount: 0,
                allSigned: false,
                allInvoiced: false,
                hasData : false,
                active: true,
                exportPeriods: billingPeriodConfig.exportPeriods,
                initiated: false,
                tariffsSet: false
              });
            }
          }
        }
      });
    }

    setTimeout(async () => {
      await ArrayUtils.AsyncForEach(billingPeriodConfig.overviewCustomerInfos, async (info: OverviewCustomerInfo) => {
        if (!info.initiated || force) {
          let periodStart = this.dateHelper.utils.addMinutes(info.exportPeriods[billingPeriodConfig.selectedExecExportPeriods].start, -info.exportPeriods[billingPeriodConfig.selectedExecExportPeriods].start.getTimezoneOffset());
          let periodEnd = this.dateHelper.utils.addMinutes(info.exportPeriods[billingPeriodConfig.selectedExecExportPeriods].end, -info.exportPeriods[billingPeriodConfig.selectedExecExportPeriods].end.getTimezoneOffset());
          info.dataExportValues = await this.dataExportService.getDataExportValues({
            customerId: info.customerId,
            periodStart: periodStart,
            periodEnd: periodEnd
          });

          info.status = BmsDataExportValueStatus.None;
          info.errorCount = 0;
          info.allSigned = info.dataExportValues.length > 0;
          info.allInvoiced = info.dataExportValues.length > 0;
          info.hasData = info.dataExportValues.length > 0;
          info.tariffsSet = false;

          info.dataExportValues.forEach(value => {
            info.status |= (value.status & (BmsDataExportValueStatus.Error | BmsDataExportValueStatus.MissingTariff | BmsDataExportValueStatus.MissingStartValue | BmsDataExportValueStatus.MissingEndValue));
            if ((value.status & (BmsDataExportValueStatus.Error | BmsDataExportValueStatus.MissingTariff | BmsDataExportValueStatus.MissingStartValue | BmsDataExportValueStatus.MissingEndValue)) != 0) {
              info.errorCount++;
            }
            info.allSigned &&= ((value.status & BmsDataExportValueStatus.Signed) > 0);
            info.allInvoiced &&= ((value.status & BmsDataExportValueStatus.Invoiced) > 0);
          });
          if (info.customerConfig) {
            if (info.customerConfig.billingPeriod == billingPeriodConfig.billingPeriod) {
              info.active = !info.customerConfig.billingPeriodChangedAt || info.exportPeriods[billingPeriodConfig.selectedExecExportPeriods].start >= info.customerConfig.billingPeriodChangedAt;
            }
            else {
              info.active = !info.customerConfig.billingPeriodChangedAt || billingPeriodConfig.exportPeriods[billingPeriodConfig.selectedExecExportPeriods].end < info.customerConfig.billingPeriodChangedAt;
            }
            if (info.customerConfig.invoiceDay >= 15) {
              let tariffChangeRequests = await this.bmsCustomerAdminClient.getBillingTariffChangeRequests(info.customerConfig.customerId, false, periodStart, periodEnd);
              let meterTypeInfos = await this.bmsCustomerAdminClient.getMeterTypes(info.customerConfig.customerId, true);
              let tariffGroupInfos = await this.bmsCustomerAdminClient.getTariffGroups(info.customerConfig.customerId, true, false);
              info.tariffsSet = tariffChangeRequests.length > 0 && tariffChangeRequests.length >= (meterTypeInfos.length + tariffGroupInfos.length);
            }
          }
          info.initiated = true;
        }
        this.cdr.markForCheck();
      });
    });
  }

  async selectedOverviewPeriodChange(billingPeriodConfig: BillingPeriodConfig) {
    this.updateOverviewCustomerInfos(billingPeriodConfig, true);
  }

  async viewCustomerDataExportValues(billingPeriodConfig: BillingPeriodConfig, info: OverviewCustomerInfo) {
    if (info) {
      this.selectedDataBasisExportPeriod = billingPeriodConfig.selectedExecExportPeriods; //TODO
      this.dataBasisOverviewCustomerInfo = info;
      this.dataReportBasisActive = true;
      this.updateDataBasis();
      info.initiated = false;
    }
  }

  async updateExportConfigs() {
    if (this.selectedTrustee) {
      try {
        this.loadingExport = true;
        let nodes = await this.xConfClient.getReferencedNodes('_x_botservices_root_' + this.selectedTrustee.id,
          '_x_botservices_root',
          ['_x_botservices_hasbot'],
          '_x_botservices_bot',
          10);

        this.exportConfigs = nodes.filter(node => node.nodeTypeId == '_x_bms_node_exportconfig');

        this.exportConfigs.sort((a, b) => a.name > b.name ? 1 : -1);

        this.billingPeriodConfigs = [];
        this.exportConfigs.forEach(c => {
          let cPeriod = BmsBillingPeriod[c.propertyValues.find(p => p.key == 'bmsbillingperiod')?.value];
          let cStartMonth = BmsMonthOfYear[c.propertyValues.find(p => p.key == 'bmsbillingperiodstartmonth')?.value];
          let periodConfig = this.billingPeriodConfigs.find(p => p.billingPeriod == cPeriod && p.startMonth == cStartMonth);
          if (!periodConfig) {
            this.billingPeriodConfigs.push({
              billingPeriod: cPeriod,
              startMonth: cStartMonth,
              exportPeriods: this.getExportPeriods(cPeriod, cStartMonth),
              overviewCustomerInfos: [],
              selectedExecExportPeriods: 1,
              filterLateInvoice : false,
              filterSpecialInvoice : false
            });
          }
        });
        if (this.billingPeriodConfigs.length == 0) {
          this.billingPeriodConfigs.push({
            billingPeriod: BmsBillingPeriod.Month,
            startMonth: BmsMonthOfYear.March,
            exportPeriods: this.getExportPeriods(BmsBillingPeriod.Month, BmsMonthOfYear.March),
            overviewCustomerInfos: [],
            selectedExecExportPeriods: 1,
            filterLateInvoice : false,
            filterSpecialInvoice : false
          });
        }
        this.billingPeriodConfigs = this.billingPeriodConfigs.sort((a, b) => a.billingPeriod > b.billingPeriod ? 1 : -1);
      }
      finally {
        this.loadingExport = false;
      }
    }
  }

  selectedExportChanged($index) {
    this.logger.info($index, this.selectedExport);
    if ($index < this.exportConfigs.length) {
      this.selectedExportConfigNode = this.exportConfigs[$index];
      this.selectedExportConfig = new BmsExportBotConfig();
      this.selectedExportConfig.nodeId = this.selectedExportConfigNode.id;
      this.selectedExportConfig.name = this.selectedExportConfigNode.name;
      this.selectedExportConfigNode.propertyValues.forEach(p => {
        switch (p.key) {
          case 'enabled':
            this.selectedExportConfig.enabled = p.value.toLowerCase() == 'true';
            break;
          case 'createperioddatabasis':
            this.selectedExportConfig.createPeriodDataBasis = p.value.toLowerCase() == 'true';
            break;
          case 'createperioddatareport':
            this.selectedExportConfig.createPeriodDataReport = p.value.toLowerCase() == 'true';
            break;
          case 'schedule':
            this.selectedExportConfig.schedule = p.value;
            break;
          case 'metertypes':
            this.selectedExportConfig.meterTypes = p.value.split('|');
            break;
          case 'bmsbillingperiod':
            this.selectedExportConfig.billingPeriod = BmsBillingPeriod[p.value];
            break;
          case 'setinvoicedflag':
            this.selectedExportConfig.setInvoicedFlag = p.value.toLowerCase() == 'true';
            break;
          case 'outputonlysigned':
            this.selectedExportConfig.outputOnlySigned = p.value.toLowerCase() == 'true';
            break;
          case 'outputonlynotinvoiced':
            this.selectedExportConfig.outputOnlyNotInvoiced = p.value.toLowerCase() == 'true';
            break;
          case 'includeStateactivebilling':
            this.selectedExportConfig.includeStateActiveBilling = p.value.toLowerCase() == 'true';
            break;
          case 'includeStateactivenobilling':
            this.selectedExportConfig.includeStateActiveNoBilling = p.value.toLowerCase() == 'true';
            break;
          case 'includeStateactiveexport':
            this.selectedExportConfig.includeStateActiveExport = p.value.toLowerCase() == 'true';
            break;
          case 'signokdatavalues':
            this.selectedExportConfig.signOkDatavalues = p.value.toLowerCase() == 'true';
            break;
          case 'qaexportenabled':
            this.selectedExportConfig.qaExportEnabled = p.value.toLowerCase() == 'true';
            break;
          case 'includeStatetest':
            this.selectedExportConfig.includeStateTest = p.value.toLowerCase() == 'true';
            break;
          case 'bmsbillingperiod':
            this.selectedExportConfig.billingPeriod = +p.value;
            if (isNaN(this.selectedExportConfig.billingPeriod)) {
              this.selectedExportConfig.billingPeriod = BmsBillingPeriod[p.value as string];
            }
            break;
          case 'bmsbillingperiodstartmonth':
            this.selectedExportConfig.billingPeriodStartMonth = +p.value;
            if (isNaN(this.selectedExportConfig.billingPeriodStartMonth)) {
              this.selectedExportConfig.billingPeriodStartMonth = BmsMonthOfYear[p.value as string];
            }
            break;
          case 'extrapolatemaxdays':
            this.selectedExportConfig.extrapolateMaxDays = +p.value;
            break;
          case 'customersperoutput':
            this.selectedExportConfig.customersPerOutput = +p.value;
            break;
          case 'invoicedaymin':
            this.selectedExportConfig.invoiceDayMin = +p.value;
            break;
          case 'invoicedaymax':
            this.selectedExportConfig.invoiceDayMax = +p.value;
            break;
          case 'invoicedaywaittariffset':
            this.selectedExportConfig.invoiceDayWaitTariffSet = p.value.toLowerCase() == 'true';
            break;
        }
      });
    }
  }

  async onEditExportConfigs() {
    if (!this.botsDataSourceInstance) {
      this.botsDataSourceInstance = await this.xConfClient.getDataSourceInstance('_x_botservices_' + this.selectedTrustee.id);
    }
    if (!this.dataReportsDataSourceInstance) {
      this.dataReportsDataSourceInstance = await this.xConfClient.getDataSourceInstance('_x_datareports_' + this.selectedTrustee.id);
    }
    this.showEditExportConfigs = true;
  }

  async onCloseEditExportConfigs() {
    this.showEditExportConfigs = false;
    await this.updateExportConfigs();
  }

  async onRemoveSelectedCustomers() {
    this.modalService.ShowConfirmModal({ header: 'Remove customer', description: 'Remove selected customers, are you sure?' }, async (result) => {
      if (result) {
        await ArrayUtils.AsyncForEach(this.selectedCustomers, async (customerOverview) => {
          await this.xConfClient.deleteReference('_x_bms_trustee_root_' + this.selectedTrustee.id, '_x_bms_trustee_root',
          customerOverview.customerId, '_x_datasource', '_x_bms_trusteee_hascustomer', '', this.selectedTrusteeConfig?.customerId);
        });
        this.selectedCustomers = [];
        await this.updateSelectedCustomers();
      }
    });
  }

  async onShowAddCustomer() {
    if (this.customers.length == 0) {
      this.customers = await this.getCustomerNodes();
    }
    this.filteredCustomers = this.customers.filter(customer => this.selectedTrusteeCustomers.findIndex(tc => tc.id == customer.id) == -1);
    this.addNewCustomerNode = null;
    this.showAddCustomer = true;
  }

  async onAddCustomer() {
    if (this.addNewCustomerNode) {
      let result = await this.xConfClient.createReference('_x_bms_trustee_root_' + this.selectedTrustee.id, '_x_bms_trustee_root', this.addNewCustomerNode.id,
          '_x_datasource', '_x_bms_trusteee_hascustomer', true, null, '', this.selectedTrusteeConfig?.customerId);
      if (result.result) {
        await this.updateSelectedCustomers();
        this.alertService.info('Customer added.');
      }
      else {
        this.alertService.error(result.message);
      }
    }
  }

  private async getCustomerNodes(): Promise<GrpcNode[]> {
    let request = new SearchNodesRequest();
    request.rootId = '_x_xconf_customers';
    request.rootLabel = '_x_datasource';
    request.label = '_x_bms_customerconfig_root';

    let p = new SearchProperty();
    p.key = 'customeristrustee';
    p.value = 'false';
    p.typeName = 'boolean';

    let p2 = new SearchProperty();
    p2.key = 'disabled';
    p2.value = 'false';
    p2.typeName = 'boolean';
    request.properties = [p, p2];
    request.propertiesOperatorAnd = true;
    let customerConfigNodes = await this.xConfClient.searchNodes(request);

    request.rootId = '_x_xconf_customers';
    request.rootLabel = '_x_datasource';
    request.label = '_x_datasource';
    request.properties = [];
    request.limit = 2000;
    request.maxHops = 1;
    request.skip = 0;

    let customerNodes = await this.xConfClient.searchNodes(request);

    return customerNodes.nodes.filter(customerNode => customerConfigNodes.nodes.findIndex(t => t.id == '_x_bms_customerconfig_root_' + customerNode.id) > -1)
      .sort((a, b) => a.name > b.name ? 1 : -1);
  }

  async manualExport() {
    this.botExecConfig = new BmsExportBotExecution();
    this.botExecConfig.configNodeId = this.selectedExportConfig?.nodeId;
    this.botExecConfig.meterTypes = [];
    this.selectedExportConfig?.meterTypes.forEach(m => this.botExecConfig.meterTypes.push(m));
    this.botExecConfig.createPeriodDataBasis = this.selectedExportConfig?.createPeriodDataBasis;
    this.botExecConfig.createPeriodDataReport = this.selectedExportConfig?.createPeriodDataReport;
    this.botExecConfig.setInvoicedFlag = this.selectedExportConfig?.setInvoicedFlag;
    this.botExecConfig.outputOnlySigned = this.selectedExportConfig?.outputOnlySigned;
    this.botExecConfig.outputOnlyNotInvoiced = this.selectedExportConfig?.outputOnlyNotInvoiced;
    this.botExecConfig.enableOutput = false;
    this.botExecConfig.testExportEnabled = false;
    this.botExecConfig.bmsBillingPeriod = this.selectedExportConfig.billingPeriod;
    this.botExecConfig.bmsBillingPeriodStartMonth = this.selectedExportConfig.billingPeriodStartMonth;
    this.botExecConfig.qaExportEnabled = this.selectedExportConfig.qaExportEnabled;
    this.botExecConfig.extrapolateMaxDays = this.selectedExportConfig.extrapolateMaxDays;
    this.botExecConfig.signOkDatavalues = this.selectedExportConfig.signOkDatavalues;
    this.botExecConfig.includeStateTest = this.selectedExportConfig.includeStateTest;
    this.botExecConfig.includeStateActiveBilling = this.selectedExportConfig.includeStateActiveBilling;
    this.botExecConfig.includeStateActiveNoBilling = this.selectedExportConfig.includeStateActiveNoBilling;
    this.botExecConfig.includeStateActiveExport = this.selectedExportConfig.includeStateActiveExport;
    this.botExecConfig.customersPerOutput = this.selectedExportConfig.customersPerOutput;
    this.botExecConfig.invoiceDayMin = this.selectedExportConfig.invoiceDayMin;
    this.botExecConfig.invoiceDayMax = this.selectedExportConfig.invoiceDayMax;
    this.botExecConfig.invoiceDayWaitTariffSet = this.selectedExportConfig.invoiceDayWaitTariffSet;
    this.botExecConfig.customerIds = [];
    this.selectedCustomers.forEach(customerOverview => {
      this.botExecConfig.customerIds.push(customerOverview.customerId);
    });

    this.execExportPeriods = this.getExportPeriods(this.selectedExportConfig?.billingPeriod, this.selectedExportConfig?.billingPeriodStartMonth);
    this.selectedExecExportPeriods = 0;

    if (this.meterTypeOptionNodes.length == 0) {
      this.meterTypeOptionNodes = await this.xConfClient.getReferencedNodesWithCache('_x_bms_metertypes_root', '_x_bms_metertypes_root', '_x_bms_metertypes_root', [], '', 1);
      this.meterTypeOptionNodes = this.meterTypeOptionNodes.sort((a, b) => {
        return a.name > b.name ? 1 : -1;
      });
    }

    this.showEditExportProperties = true;
  }

  getExportPeriods(billingPeriod: BmsBillingPeriod, startMonth: BmsMonthOfYear): { start: Date, end: Date }[] {
    let result = [];
    let now = new Date();

    switch (billingPeriod ?? BmsBillingPeriod.Month) {
      case BmsBillingPeriod.Week:
        let startOfWeek = this.dateHelper.utils.startOfWeek(now);
        let endOfWeek = this.dateHelper.utils.endOfWeek(now);
        for (let i = 0; i < 13; i++) {
          result.push({ start: this.dateHelper.utils.addWeeks(startOfWeek, -i), end: this.dateHelper.utils.addWeeks(endOfWeek, -i) });
        }
        break;

      case BmsBillingPeriod.Month:
        for (let i = 0; i < 25; i++) {
          let month = this.dateHelper.utils.addMonths(now, -i);
          let startOfMonth = this.dateHelper.utils.startOfMonth(month);
          let endOfMonth = this.dateHelper.utils.endOfMonth(month);
          result.push({ start: startOfMonth, end: endOfMonth });
        }
        break;

      case BmsBillingPeriod.Quarter:
        let month = this.dateHelper.utils.getMonth(now);
        let offset = 0 + ((month + 4 - (1 + ((startMonth - 1) % 3))) % 3);

        for (let i = 0; i < 13; i++) {
          let monthStart = this.dateHelper.utils.addMonths(now, -((i * 3) + offset));
          let monthEnd = this.dateHelper.utils.addMonths(now, -((i * 3) + offset) + 2);
          let startOfMonth = this.dateHelper.utils.startOfMonth(monthStart);
          let endOfMonth = this.dateHelper.utils.endOfMonth(monthEnd);
          result.push({ start: startOfMonth, end: endOfMonth });
        }
        break;

      default:
        for (let i = 1; i < 25; i++) {
          let month = this.dateHelper.utils.addMonths(now, -i);
          let startOfMonth = this.dateHelper.utils.startOfMonth(month);
          let endOfMonth = this.dateHelper.utils.endOfMonth(month);
          result.push({ start: startOfMonth, end: endOfMonth });
        }
        break;
    }

    return result;
  }

  formatByString(date: Date, format: string, displayUTC: boolean = false): string {
    let d = displayUTC ? this.dateHelper.utils.addMinutes(date, date.getTimezoneOffset()) : date;
    return this.dateHelper.utils.formatByString(d, format);
  }

  async onCloseEditExportProperties() {
    if (this.selectedExportConfig) {
      this.execCreateFiles = true;

      if (this.selectedExecExportPeriods < this.execExportPeriods.length) {
        this.botExecConfig.periodStart = this.dateHelper.utils.addMinutes(this.execExportPeriods[this.selectedExecExportPeriods].start, -this.execExportPeriods[this.selectedExecExportPeriods].start.getTimezoneOffset());
        this.botExecConfig.periodEnd = this.dateHelper.utils.addMinutes(this.execExportPeriods[this.selectedExecExportPeriods].end, -this.execExportPeriods[this.selectedExecExportPeriods].end.getTimezoneOffset());
      }

      this.botExecConfig.signOkDatavalues = this.selectedExecExportPeriods == 0 ? false : this.botExecConfig.signOkDatavalues;
      this.botExecConfig.storeOnClient = this.botExecConfig.storeOnClient && this.botExecConfig.createPeriodDataReport;

      this.xbotExecutionService.executeBot('bms_export', JSON.stringify(this.botExecConfig), 300, 100, 'trusteeadmin', this.state.userId).subscribe(
        result => {
          if (result.result == XbotExecutionResult.STATUS) {
            this.execStatusMessage = result.message;
          }
          else if (result.result == XbotExecutionResult.OK) {
            this.alertService.success('Executed ok: ' + result.message);
            if (this.botExecConfig.storeOnClient) {
              this.xProjectorFilesClient.getFile(this.selectedExportConfig.nodeId, this.selectedTrustee.id)
                .then(blob => {
                  saveAs(blob, this.selectedTrustee.name + ".zip");
                })
                .catch(error => {
                  this.alertService.error(error);
                }
                );
            }
            if (result.message?.length > 0) {
              this.invoicedCustomers = result.message.split(',');
              this.showViewInvoiced = true;
              //this.modalService.ShowConfirmModal({ header: 'Invoiced customers', description: 'Invoiced customers: ' + result.message, showCancel: false }, (result) => {});
            }
          }
          else if (result.result == XbotExecutionResult.CANCELED) {
            this.alertService.error('Execution canceled.');
          }
          else {
            this.alertService.error(result.message);
          }
        },
        error => {
          this.execCreateFiles = false;
          this.alertService.error(error.message);
        },
        () => {
          this.execStatusMessage = '';
          this.execCreateFiles = false;
        }
      )
    }
  }

  async viewExportedFiles() {
    if (this.selectedExportConfig) {
      this.selectedExportFileInfo = null;
      let fileInfos = await this.xProjectorFilesClient.getFileInfos(['bmsexport'], 100, 0, this.selectedTrustee.id);
      this.exportedFileInfos = fileInfos.files.filter(info => info.id.startsWith(this.selectedExportConfig.nodeId) && info.tags.includes('bmsexport'));
      this.execExportPeriods = this.getExportPeriods(this.selectedExportConfig?.billingPeriod, this.selectedExportConfig?.billingPeriodStartMonth);
      if (!this.customeConfigsByCustomer && this.billingPeriodConfigs) {
        this.customeConfigsByCustomer = new Map<string, BmsCustomerConfig>();
        this.billingPeriodConfigs.forEach(billingPeriodConfig => {
          billingPeriodConfig.overviewCustomerInfos.forEach(info => {
            this.customeConfigsByCustomer.set(info.customerId, info.customerConfig);
          });
        });
      }
      this.showExportedFiles = true;
    }
  }

  selectedExportFileInfoChanged(fileInfo: FileInfo) {

  }

  async omDownloadExportedFile() {
    if (this.selectedExportFileInfo) {
      this.xProjectorFilesClient.getFile(this.selectedExportFileInfo.id, this.selectedTrustee.id)
        .then(blob => {
          if (this.selectedExportFileInfo.name.startsWith('Export')) {
            saveAs(blob, this.selectedTrustee.name + ' ' + this.selectedExportFileInfo.name + ".zip");
          }
          else {
            saveAs(blob, this.selectedExportFileInfo.name);
          }
        })
        .catch(error => {
          this.alertService.error(error);
        });
    }
  }

  formatDate(date: Date, displayUTC: boolean = false): string {
    return this.dateHelper.utils.formatByString(displayUTC ? this.dateHelper.utils.addMinutes(date, -date.getTimezoneOffset()) : date, "yyyy-MM-dd HH:mm:ss");
  }

  async selectedDataBasisCustomerChange($event) {
    this.editInvoiceMode = false;
    this.editOperationMode = false;
    this.clearFilters();
    this.updateDataBasis();
  }

  async selectedDataBasisPeriodChange($event) {
    this.updateDataBasis();
  }

  onSelectTabDataReportBasis($event) {
    if (this.overviewCustomerInfos?.length > 0) {
      this.dataBasisOverviewCustomerInfo = this.overviewCustomerInfos[0]
      this.updateDataBasis();
    }
  }

  async selectedMeterTypeChanged() {
    this.clearFilters();
    await this.updateDataBasis();
  }

  async updateDataBasis() {
    if (this.dataBasisOverviewCustomerInfo) {
      try {
        this.deviceIdFilter = '';
        this.loadingDataExportValues = true;
        this.dataExportValues = [];

        let dataExportValues = await this.dataExportService.getDataExportValues({
          customerId: this.dataBasisOverviewCustomerInfo.customerId,
          periodStart: this.dateHelper.utils.addMinutes(this.dataBasisOverviewCustomerInfo.exportPeriods[this.selectedDataBasisExportPeriod].start, -this.dataBasisOverviewCustomerInfo.exportPeriods[this.selectedDataBasisExportPeriod].start.getTimezoneOffset()),
          periodEnd: this.dateHelper.utils.addMinutes(this.dataBasisOverviewCustomerInfo.exportPeriods[this.selectedDataBasisExportPeriod].end, -this.dataBasisOverviewCustomerInfo.exportPeriods[this.selectedDataBasisExportPeriod].end.getTimezoneOffset())
        });

        this.errorCount = 0;
        this.allSigned = dataExportValues.length > 0;
        this.allInvoiced = dataExportValues.length > 0;
        this.hasData = dataExportValues.length > 0;

        dataExportValues.forEach(dataExportValue => {
          if (dataExportValue.status & (BmsDataExportValueStatus.MissingStartValue | BmsDataExportValueStatus.MissingEndValue)) {
            dataExportValue.diff = -9999999999;
          }
          else {
            dataExportValue.diff = +(dataExportValue.endValue - dataExportValue.startValue).toFixed(this.selectedTrusteeConfig.reportDataPointDecimalCount);
          }

          dataExportValue.startValue = +dataExportValue.startValue.toFixed(this.dataBasisOverviewCustomerInfo.customerConfig.reportDataPointDecimalCount);
          dataExportValue.endValue = +dataExportValue.endValue.toFixed(this.dataBasisOverviewCustomerInfo.customerConfig.reportDataPointDecimalCount);
          dataExportValue.tariff = +dataExportValue.tariff.toFixed(this.dataBasisOverviewCustomerInfo.customerConfig.tariffDecimalCount);
          dataExportValue.vatRate = +dataExportValue.vatRate.toFixed(this.dataBasisOverviewCustomerInfo.customerConfig.tariffDecimalCount);

          if ((dataExportValue.status & (BmsDataExportValueStatus.Error | BmsDataExportValueStatus.MissingTariff | BmsDataExportValueStatus.MissingStartValue | BmsDataExportValueStatus.MissingEndValue)) != 0) {
            this.errorCount++;
          }
          this.allSigned &&= ((dataExportValue.status & BmsDataExportValueStatus.Signed) > 0);
          this.allInvoiced &&= ((dataExportValue.status & BmsDataExportValueStatus.Invoiced) > 0);
        });

        this.dataExportValuesByMeterType = ArrayUtils.GroupBy(dataExportValues, 'meterType');
        this.dataExportValuesByMeterType['ALL METERTYPES'] = dataExportValues;
        this.dataExportValuesMeterTypes = [];
        let tmp = Object.entries(this.dataExportValuesByMeterType).map(([metertype, data]) => ({ metertype, data }));
        tmp.forEach(kvp => this.dataExportValuesMeterTypes.push(kvp.metertype));
        this.dataExportValuesMeterTypes.sort((a, b) => a > b ? 1 : -1);

        if (this.exportDataMeterId > 0) {
          this.dataExportValues = dataExportValues.filter(dataValue => dataValue.meterId == this.exportDataMeterId);
        }
        else if (this.dataExportValuesMeterTypes.length > 0) {
          if (this.dataExportValuesMeterTypes.findIndex(mt => mt == this.selectedExportDataValueMeterType) < 0) {
            this.selectedExportDataValueMeterType = this.dataExportValuesMeterTypes[0];
          }

          this.dataExportValues = this.dataExportValuesByMeterType[this.selectedExportDataValueMeterType];
          if (this.selectedExportSignedFlagStatus != FlagStatus.All) {
            this.dataExportValues = this.dataExportValues.filter(dataValue =>
              (this.selectedExportSignedFlagStatus == FlagStatus.OnlyTrue && (dataValue.status & BmsDataExportValueStatus.Signed) > 0)
              || (this.selectedExportSignedFlagStatus == FlagStatus.OnlyFalse && (dataValue.status & BmsDataExportValueStatus.Signed) == 0)
            )
          }

          if (this.selectedExportMissingValueFlagStatus != FlagStatus.All) {
            this.dataExportValues = this.dataExportValues.filter(dataValue =>
              (this.selectedExportMissingValueFlagStatus == FlagStatus.OnlyTrue && (dataValue.status & (BmsDataExportValueStatus.MissingEndValue | BmsDataExportValueStatus.MissingStartValue)) == 0)
              || (this.selectedExportMissingValueFlagStatus == FlagStatus.OnlyFalse && (dataValue.status & (BmsDataExportValueStatus.MissingEndValue | BmsDataExportValueStatus.MissingStartValue)) > 0)
            )
          }

          if (this.selectedExportErrorFlagStatus != FlagStatus.All) {
            this.dataExportValues = this.dataExportValues.filter(dataValue =>
              (this.selectedExportErrorFlagStatus == FlagStatus.OnlyTrue && (dataValue.status & errorFlags) > 0)
              || (this.selectedExportErrorFlagStatus == FlagStatus.OnlyFalse && (dataValue.status & errorFlags) == 0)
            )
          }

          if (this.selectedExportWarningFlagStatus != FlagStatus.All) {
            this.dataExportValues = this.dataExportValues.filter(dataValue =>
              (this.selectedExportWarningFlagStatus == FlagStatus.OnlyTrue && (dataValue.status & BmsDataExportValueStatus.Warning) > 0)
              || (this.selectedExportWarningFlagStatus == FlagStatus.OnlyFalse && (dataValue.status & BmsDataExportValueStatus.Warning) == 0)
            )
          }

        }

        if (this.dataBasisOverviewCustomerInfo) {
          if (this.dataBasisOverviewCustomerInfo.customerConfig.invoiceDay >= 15) {
            let tariffChangeRequests = await this.bmsCustomerAdminClient.getBillingTariffChangeRequests(this.dataBasisOverviewCustomerInfo.customerConfig.customerId, false,
              this.dataBasisOverviewCustomerInfo.exportPeriods[this.selectedDataBasisExportPeriod].start, this.dataBasisOverviewCustomerInfo.exportPeriods[this.selectedDataBasisExportPeriod].end);
            let meterTypeInfos = await this.bmsCustomerAdminClient.getMeterTypes(this.dataBasisOverviewCustomerInfo.customerConfig.customerId, true);
            let tariffGroupInfos = await this.bmsCustomerAdminClient.getTariffGroups(this.dataBasisOverviewCustomerInfo.customerConfig.customerId, true, false);
            this.dataBasisOverviewCustomerInfo.tariffsSet = tariffChangeRequests.length > 0 && tariffChangeRequests.length >= (meterTypeInfos.length + tariffGroupInfos.length);
          }
        }

      }
      finally {
        this.loadingDataExportValues = false;
        this.exportDataMeterId = -1;
      }
    }
  }

  async selectedExportDataValueChanged($event) {
    try {
      if (this.selectedExportDataValues.length == 1) {
        this.selectedExportDataValueCopy = TypedJSON.parse(TypedJSON.stringify(this.selectedExportDataValues[0], BmsDataExportValue), BmsDataExportValue);

        if (this.selectedExportDataValueCopy.startValue < 0) {
          this.selectedExportDataValueCopy.startValue = -1;
        }
        if (this.selectedExportDataValueCopy.endValue < 0) {
          this.selectedExportDataValueCopy.endValue = -1;
        }

        let outputs: DashboardOutputChangeParameters[] = [];
        let out = new DashboardOutputChangeParameters();
        out.outputParameterName = 'MeterId';
        out.value = this.selectedExportDataValueCopy.meterId;
        outputs.push(out);

        let outStart = new DashboardOutputChangeParameters();
        outStart.outputParameterName = 'Start';
        outStart.value = this.dateHelper.utils.addDays(this.selectedExportDataValueCopy.start, -1);
        outputs.push(outStart);

        let outEnd = new DashboardOutputChangeParameters();
        outEnd.outputParameterName = 'End';
        outEnd.value = this.dateHelper.utils.addDays(this.selectedExportDataValueCopy.end, 1);
        outputs.push(outEnd);

        this.dashboardOutputParameters = outputs;

        this.cdr.detectChanges();
      }
    }
    catch { }
  }

  async onEditSelected() {
    if (this.selectedExportDataValues.length == 1) {
      this.selectedExportDataValueEdit = this.selectedExportDataValues[0];
      this.selectedExportDataValueCopy = TypedJSON.parse(TypedJSON.stringify(this.selectedExportDataValues[0], BmsDataExportValue), BmsDataExportValue);
      await this.onGridDetailChange(this.selectedExportDataValueCopy);

      if (this.selectedExportDataValueEdit.meterId > 0) {
        this.showEditExportDataValue = true;
      }
      else {
        this.selectedExportDataValueCopy.startDateString = this.dateHelper.utils.formatByString(this.selectedExportDataValueCopy.start, 'yyyy-MM-dd');
        this.selectedExportDataValueCopy.endDateString = this.dateHelper.utils.formatByString(this.selectedExportDataValueCopy.end, 'yyyy-MM-dd');

        this.showEditManualExportDataValue = true;
      }
    }
  }

  async selectedDataBasisMeterTypeChange($event) {
    this.dataExportValues = this.dataExportValuesByMeterType[this.selectedExportDataValueMeterType];
  }

  async saveSelectedDataExportvalue() {
    try {
      if (this.selectedExportDataValueCopy) {
        this.selectedExportDataValueCopy.modifiedAt = new Date();

        if ((this.selectedExportDataValueCopy.startValue >= 0 && this.selectedExportDataValueCopy.startValue != this.selectedExportDataValueEdit.startValue)
          || (this.selectedExportDataValueCopy.endValue >= 0 && this.selectedExportDataValueCopy.endValue != this.selectedExportDataValueEdit.endValue)) {
          this.selectedExportDataValueCopy.status &= ~BmsDataExportValueStatus.AutomaticEstimate;
          this.selectedExportDataValueCopy.status |= BmsDataExportValueStatus.ManualEstimate;
        }

        if (this.selectedExportDataValueCopy.startValue >= 0) {
          this.selectedExportDataValueCopy.status &= ~BmsDataExportValueStatus.MissingStartValue;
        }
        if (this.selectedExportDataValueCopy.endValue >= 0) {
          this.selectedExportDataValueCopy.status &= ~BmsDataExportValueStatus.MissingEndValue;
        }
        if (this.selectedExportDataValueCopy.tariff > 0) {
          this.selectedExportDataValueCopy.status &= ~BmsDataExportValueStatus.MissingTariff;
        }

        let result = await this.dataExportService.setDataExportValues([this.selectedExportDataValueCopy]);
        if (result) {
          this.alertService.info('Date exportvalue updated ok.');
          this.selectedExportDataValueEdit.externalId = this.selectedExportDataValueCopy.externalId;
          this.selectedExportDataValueEdit.prefix = this.selectedExportDataValueCopy.prefix;
          this.selectedExportDataValueEdit.startValue = this.selectedExportDataValueCopy.startValue;
          this.selectedExportDataValueEdit.endValue = this.selectedExportDataValueCopy.endValue;
          this.selectedExportDataValueEdit.tariff = this.selectedExportDataValueCopy.tariff;
          this.selectedExportDataValueEdit.vatRate = this.selectedExportDataValueCopy.vatRate;
          this.selectedExportDataValueEdit.includeVAT = this.selectedExportDataValueCopy.includeVAT;
          this.selectedExportDataValueEdit.status = this.selectedExportDataValueCopy.status;
          if (this.selectedExportDataValueEdit.status & (BmsDataExportValueStatus.MissingStartValue | BmsDataExportValueStatus.MissingEndValue)) {
            this.selectedExportDataValueEdit.diff = -9999999999;
          }
          else {
            this.selectedExportDataValueEdit.diff = +(this.selectedExportDataValueEdit.endValue - this.selectedExportDataValueEdit.startValue).toFixed(this.selectedTrusteeConfig.reportDataPointDecimalCount);
          }

          this.selectedExportDataValueEdit.modifiedAt = this.selectedExportDataValueCopy.modifiedAt;
        }
        else {
          this.alertService.error('Error update dataexport value!');
        }
      }
    }
    catch (err) {
      this.alertService.error('Error update data export value: ', err);
    }

    this.showEditExportDataValue = false;
    this.selectedExportDataValueCopy = null;
  }

  doShowEditAllExportStartDataValues() {
    this.editAllData.onlySelected = this.selectedExportDataValues?.length > 0
    this.editAllData.overridePreviousValues = false;
    this.showEditAllExportStartDataValues = true;
  }

  doShowEditAllExportEndDataValues() {
    this.editAllData.onlySelected = this.selectedExportDataValues?.length > 0
    this.editAllData.overridePreviousValues = false;
    this.showEditAllExportEndDataValues = true;
  }

  doShowEditAllExportConsumptions() {
    this.editAllData.onlySelected = this.selectedExportDataValues?.length > 0
    this.editAllData.overridePreviousValues = false;
    this.showEditAllExportConsumptions = true;
  }

  async onSaveEditAllExportDataValues(mode: EditAllMode = EditAllMode.StartValue) {
    try {
      let toSet: BmsDataExportValue[] = [];
      await ArrayUtils.AsyncForEach(this.editAllData.onlySelected ? this.selectedExportDataValues : this.dataExportValues, async (dataExportValue: BmsDataExportValue) => {
        if ((dataExportValue.status & (BmsDataExportValueStatus.Signed | BmsDataExportValueStatus.Invoiced)) == 0) {
          if (mode == EditAllMode.Tariff) {
            if (this.editAllData.tariff) {
              if (this.editAllData.tariff != dataExportValue.tariff) {
                dataExportValue.tariff = this.editAllData.tariff;
                toSet.push(dataExportValue);
              }
            }
          }
          else if (mode == EditAllMode.StartValue) {
            if (this.editAllData.overridePreviousValues || (dataExportValue.status & BmsDataExportValueStatus.MissingStartValue) > 0) {
              dataExportValue.modifiedAt = new Date();
              if (!this.editAllData.setToFirstPeriodValue) {
                dataExportValue.startValue = this.editAllData.startValue;
                dataExportValue.status |= BmsDataExportValueStatus.ManualEstimate;
                if (dataExportValue.startValue >= 0) {
                  dataExportValue.status &= ~BmsDataExportValueStatus.MissingStartValue;
                }
              }
              else {
                let datapointValue = await this.dataExportService.getDatapointValueAfter(dataExportValue.meterId, this.dataBasisOverviewCustomerInfo.exportPeriods[this.selectedDataBasisExportPeriod].start);
                if (datapointValue) {
                  let startValue = await this.unitConversions.unitConversionFromDerived(datapointValue.value, dataExportValue.unit);
                  dataExportValue.startValue = +startValue.toFixed(this.dataBasisOverviewCustomerInfo.customerConfig.reportDataPointDecimalCount);
                  dataExportValue.status |= BmsDataExportValueStatus.ManualEstimate;
                  if (dataExportValue.startValue >= 0) {
                    dataExportValue.status &= ~BmsDataExportValueStatus.MissingStartValue;
                  }
                }
              }

              toSet.push(dataExportValue);
            }
          }
          else if (mode == EditAllMode.EndValue) {
            if (this.editAllData.overridePreviousValues || (dataExportValue.status & BmsDataExportValueStatus.MissingEndValue) > 0) {
              dataExportValue.modifiedAt = new Date();
              this.editAllData.endValueDate = this.dateHelper.utils.parse(this.editAllData.endValueDateString, 'yyyy-MM-dd');
              this.editAllData.endValueDate = this.dateHelper.utils.addMinutes(this.editAllData.endValueDate, -this.editAllData.endValueDate.getTimezoneOffset());
              let datapointValue = await this.dataExportService.getDatapointValueAfter(dataExportValue.meterId, this.editAllData.endValueDate);
              if (datapointValue) {
                let endValue = await this.unitConversions.unitConversionFromDerived(datapointValue.value, dataExportValue.unit);
                dataExportValue.endValue = +endValue.toFixed(this.dataBasisOverviewCustomerInfo.customerConfig.reportDataPointDecimalCount);
                dataExportValue.status |= BmsDataExportValueStatus.ManualEstimate;
                if (dataExportValue.endValue >= 0) {
                  dataExportValue.status &= ~BmsDataExportValueStatus.MissingEndValue;
                }

                toSet.push(dataExportValue);
              }
            }
          }
          else if (mode == EditAllMode.Consumption) {
            if ((dataExportValue.status & BmsDataExportValueStatus.MissingStartValue) == 0 && (this.editAllData.overridePreviousValues || (dataExportValue.status & BmsDataExportValueStatus.MissingEndValue) > 0)) {
              dataExportValue.modifiedAt = new Date();
              dataExportValue.endValue = dataExportValue.startValue + this.editAllData.startValue;
              dataExportValue.status |= BmsDataExportValueStatus.ManualEstimate;
              if (dataExportValue.endValue >= 0) {
                dataExportValue.status &= ~BmsDataExportValueStatus.MissingEndValue;
              }

              toSet.push(dataExportValue);
            }
          }
        }
      });
      if (toSet.length > 0) {
        let result = await this.dataExportService.setDataExportValues(toSet);
        if (result) {
          this.alertService.success('Data exportvalues updated ok. (' + toSet.length + ' values)');
          toSet.forEach(dataExportValue => {
            if (dataExportValue.status & (BmsDataExportValueStatus.MissingStartValue | BmsDataExportValueStatus.MissingEndValue)) {
              dataExportValue.diff = -9999999999;
            }
            else {
              dataExportValue.diff = +(dataExportValue.endValue - dataExportValue.startValue).toFixed(this.selectedTrusteeConfig.reportDataPointDecimalCount);
            }
          });
        }
        else {
          this.alertService.error('Error update dataexport values!');
        }
      }
      else {
        this.alertService.info('No values updated.');
      }

    }
    catch (err) {
      this.alertService.error('Error update data export valuess: ', err);
    }
  }

  async deleteSelectedDataExportvalue() {
    if (this.selectedExportDataValueCopy) {
      this.showEditExportDataValue = false;
      this.cdr.detectChanges();
      this.modalService.ShowConfirmModal({ header: 'Delete value', description: 'Delete data export value, are you sure?' }, async (result) => {
        if (result) {
          let deleteresult = await this.dataExportService.deleteResidentExchange(this.selectedExportDataValueCopy);
          if (deleteresult) {
            this.alertService.info('Data exportvalue deleted ok.');
            this.dataExportValues = this.dataExportValues.filter(value => value.id != this.selectedExportDataValueCopy.id);
          }
          else {
            this.alertService.error('Error delete dataexport value!');
          }
          this.selectedExportDataValueCopy = null;
        }
      });
    }
  }

  getDataExportValueStatusText(status: BmsDataExportValueStatus, defaultResult: string = ''): string {
    let result: string[] = [];
    if (status & BmsDataExportValueStatus.AutomaticEstimate) {
      result.push('AUT_EST');
    }
    if (status & BmsDataExportValueStatus.ManualEstimate) {
      result.push('MAN_EST');
    }
    if (status & BmsDataExportValueStatus.MissingEndValue) {
      result.push('NO_END');
    }
    if (status & BmsDataExportValueStatus.MissingStartValue) {
      result.push('NO_START');
    }
    if (status & BmsDataExportValueStatus.MissingTariff) {
      result.push('NO_TAR');
    }
    if (status & BmsDataExportValueStatus.Error) {
      result.push('ERROR');
    }
    if (status & BmsDataExportValueStatus.Warning) {
      result.push('WARN');
    }
    if (status & BmsDataExportValueStatus.Signed) {
      result.push('SIGNED');
    }
    if (status & BmsDataExportValueStatus.Invoiced) {
      result.push('INVOICED');
    }
    if (status & BmsDataExportValueStatus.NegativeConsumption) {
      result.push('NEG');
    }
    if (status & BmsDataExportValueStatus.HighConsumption) {
      result.push('HIGH');
    }
    if (status & BmsDataExportValueStatus.LowConsumption) {
      result.push('LOW');
    }

    return result.length == 0 ? defaultResult : result.join(',');
  }

  async onSignAll() {
    if (this.dataExportValues) {
      let hasErrors: boolean = false;
      this.dataExportValues.forEach(dataExportValue => {
        if ((dataExportValue.status & errorFlags) > 0) {
          hasErrors = true;
        }
      });

      if (hasErrors) {
        this.modalService.ShowConfirmModal({
          header: 'Sign - Clear errors',
          description: 'The exportvalues has errors, do you want to clear error flags?',
          ok: 'Sign and clear errors',
          cancel: 'Only sign values'
        }, async (result) => {
          if (result) {
            this.dataExportValues.forEach(dataExportValue => {
              if (dataExportValue.startValue >= 0 && dataExportValue.endValue >= 0) {
                dataExportValue.status &= ~errorFlags;
              }
            });
          }

          this.dataExportValues.forEach(dataExportValue => {
            dataExportValue.status |= BmsDataExportValueStatus.Signed;
          });

          await this.dataExportService.setDataExportValues(this.dataExportValues);
        });
      }
      else {
        this.dataExportValues.forEach(dataExportValue => {
          dataExportValue.status |= BmsDataExportValueStatus.Signed;
        });

        await this.dataExportService.setDataExportValues(this.dataExportValues);
      }
    }
  }

  async onUnsignAll() {
    if (this.dataExportValues) {
      this.dataExportValues.forEach(dataExportValue => {
        dataExportValue.status &= ~BmsDataExportValueStatus.Signed;
      });

      await this.dataExportService.setDataExportValues(this.dataExportValues);
    }
  }

  async onSignSelected() {
    if (this.selectedExportDataValues.length > 0) {
      let exportDataValueToSet : BmsDataExportValue[] = [];

      if (this.hasError(this.selectedExportDataValues)) {
        let clearErrors = await this.modalService.ShowConfirmModalAsync({
          header: 'Sign - Clear error',
          description: 'The exportvalue has error, do you want to clear error flag?',
          ok : 'Sign and clear errors',
          cancel : 'Only sign value'
        });

        this.selectedExportDataValues.forEach(exportDataValue => {
          if (clearErrors) {
            exportDataValue.status &= ~errorFlags;
          }
          exportDataValue.status |= BmsDataExportValueStatus.Signed;
          exportDataValueToSet.push(exportDataValue);
        });
      }
      else {
        this.selectedExportDataValues.forEach(exportDataValue => {
          exportDataValue.status |= BmsDataExportValueStatus.Signed;
          exportDataValueToSet.push(exportDataValue);
        });
      }

      if (exportDataValueToSet.length > 0) {
        await this.dataExportService.setDataExportValues(exportDataValueToSet);
      }
    }
  }

  async onUnsignSelected() {
    this.selectedExportDataValues.forEach(exportDataValue => {
      exportDataValue.status &= ~BmsDataExportValueStatus.Signed;
    });
    await this.dataExportService.setDataExportValues(this.selectedExportDataValues);
  }

  async onUnsetInvoicedAll() {
    this.modalService.ShowConfirmModal({ header: 'Unset invoiced', description: 'Unset all invoiced data export values, are you sure?' }, async (result) => {
      if (result) {
        if (this.dataExportValues) {
          this.dataExportValues.forEach(dataExportValue => {
            dataExportValue.status &= ~BmsDataExportValueStatus.Invoiced;
          });

          await this.dataExportService.setDataExportValues(this.dataExportValues);
        }
      }
    });
  }

  async onSetInvoicedAll() {
    this.modalService.ShowConfirmModal({ header: 'Set invoiced', description: 'Set all data export values as invoiced, are you sure?' }, async (result) => {
      if (result) {
        if (this.dataExportValues) {
          this.dataExportValues.forEach(dataExportValue => {
            dataExportValue.status |= BmsDataExportValueStatus.Invoiced;
          });

          await this.dataExportService.setDataExportValues(this.dataExportValues);
        }
      }
    });
  }

  async onDeleteAll() {
    this.selectedExportDataValues = [];
    this.cdr.detectChanges();
    this.modalService.ShowConfirmModal({ header: 'Delete values', description: 'Delete all unsigned data export values, are you sure?' }, async (result) => {
      if (result) {
        if (this.dataExportValues) {
          let found = false;
          this.dataExportValues.forEach(dataExportValue => {
            if ((dataExportValue.status & (BmsDataExportValueStatus.Signed | BmsDataExportValueStatus.Invoiced)) == 0) {
              dataExportValue.deleted = true;
              found = true;
            }
          });

          if (found) {
            let deleteresult = await this.dataExportService.setDataExportValues(this.dataExportValues.filter(value => value.deleted));

            if (deleteresult) {
              this.alertService.info('Data exportvalues deleted ok.');
              this.dataExportValues = this.dataExportValues.filter(value => !value.deleted);
            }
            else {
              this.alertService.error('Error delete dataexport values!');
            }
          }
          else {
            this.alertService.warn('Found no unsigned or not invoiced values to delete.');
          }

        }
      }
    });
  }

  async onComment() {
    if (this.selectedExportDataValues.length > 0) {
      let currentComment : string = this.selectedExportDataValues[0].comment;


      let addcommentResult = await this.modalService.ShowInputModalAsync({
        header: 'Comment',
        description: 'Input comment:',
        value: currentComment,
        ok: 'Ok',
        cancel: 'Cancel'
      });
      if (addcommentResult.result) {
        this.selectedExportDataValues.forEach(exportDataValue => {
          if ((exportDataValue.status & BmsDataExportValueStatus.Signed) == 0) {
            exportDataValue.comment = addcommentResult.value;
          }
        });

        await this.dataExportService.setDataExportValues(this.selectedExportDataValues);
      }
    }
  }

  async addEmptyRow() {
    if (this.selectedExportDataValueMeterType == 'ALL METERTYPES') {
      this.alertService.error('No Meter type selected!');
    }
    else {
      let dataExportValues : BmsDataExportValue[] = this.dataExportValuesByMeterType[this.selectedExportDataValueMeterType];

      if (dataExportValues.length > 0) {
        let selectedPeriod = this.dataBasisOverviewCustomerInfo.exportPeriods[this.selectedDataBasisExportPeriod];

        this.selectedExportDataValueCopy = TypedJSON.parse(TypedJSON.stringify(dataExportValues[0], BmsDataExportValue), BmsDataExportValue);
        this.selectedExportDataValueCopy.id = 0;
        this.selectedExportDataValueCopy.createdAt = new Date();
        this.selectedExportDataValueCopy.modifiedAt = new Date();
        this.selectedExportDataValueCopy.start = selectedPeriod.start;
        this.selectedExportDataValueCopy.end = selectedPeriod.end;
        this.selectedExportDataValueCopy.startValue = 0;
        this.selectedExportDataValueCopy.endValue = 0;
        this.selectedExportDataValueCopy.meterId = -1;
        this.selectedExportDataValueCopy.prefix = '';
        this.selectedExportDataValueCopy.comment = '';
        this.selectedExportDataValueCopy.externalId = '';
        this.selectedExportDataValueCopy.svLantApartmentNo = 0;

        this.selectedExportDataValueCopy.startDateString = this.dateHelper.utils.formatByString(this.selectedExportDataValueCopy.start, 'yyyy-MM-dd');
        this.selectedExportDataValueCopy.endDateString = this.dateHelper.utils.formatByString(this.selectedExportDataValueCopy.end, 'yyyy-MM-dd');

        await this.onGridDetailChange(this.selectedExportDataValueCopy);
        this.showEditManualExportDataValue = true;
      }
    }
  }

  async saveManualDataExportvalue() {
    try {
      if (this.selectedExportDataValueCopy) {
        this.selectedExportDataValueCopy.modifiedAt = new Date();
        this.selectedExportDataValueCopy.status |= BmsDataExportValueStatus.ManualEstimate;

        this.selectedExportDataValueCopy.start = this.dateHelper.utils.parse(this.selectedExportDataValueCopy.startDateString, 'yyyy-MM-dd');
        this.selectedExportDataValueCopy.end = this.dateHelper.utils.parse(this.selectedExportDataValueCopy.endDateString, 'yyyy-MM-dd');

        this.selectedExportDataValueCopy.start = this.dateHelper.utils.addMinutes(this.selectedExportDataValueCopy.start, -this.selectedExportDataValueCopy.start.getTimezoneOffset());
        this.selectedExportDataValueCopy.end = this.dateHelper.utils.addMinutes(this.selectedExportDataValueCopy.end, -this.selectedExportDataValueCopy.end.getTimezoneOffset());

        if (this.selectedExportDataValueCopy.tariff <= 0) {
          this.selectedExportDataValueCopy.status |= BmsDataExportValueStatus.MissingTariff;
        }

        let result = await this.dataExportService.setDataExportValues([this.selectedExportDataValueCopy]);
        if (result) {
          this.alertService.info('Date exportvalue updated ok.');
        }
        else {
          this.alertService.error('Error update dataexport value!');
        }
      }
    }
    catch (err) {
      this.alertService.error('Error update data export value: ', err);
    }

    this.showEditExportDataValue = false;
    this.selectedExportDataValueCopy = null;

    this.showEditManualExportDataValue = false;
    this.updateDataBasis();
  }

  async onDeleteSelected() {
    this.modalService.ShowConfirmModal({ header: 'Delete value', description: 'Delete selected data export values, are you sure?' }, async (result) => {
      if (result) {
        let exportDataValueToSet : BmsDataExportValue[] = [];
        this.selectedExportDataValues.forEach(exportDataValue => {
          if ((exportDataValue.status & (BmsDataExportValueStatus.Signed | BmsDataExportValueStatus.Invoiced)) == 0) {
            exportDataValue.deleted = true;
            exportDataValueToSet.push(exportDataValue);
          }
        });
        if (exportDataValueToSet.length > 0) {
          let deleteresult = await this.dataExportService.setDataExportValues(exportDataValueToSet);

          if (deleteresult) {
            this.alertService.info('Data exportvalues deleted ok.');
            this.dataExportValues = this.dataExportValues.filter(value => !value.deleted);
          }
          else {
            this.alertService.error('Error delete dataexport value!');
          }
          this.selectedExportDataValues = [];
        }
        else {
          this.alertService.error('All selected values are signed or invoiced.');
        }
      }
    });

  }

  allAreSignedOrInvoiced(exportDataValues: BmsDataExportValue[]): boolean {
    let result = true;
    exportDataValues.forEach(exportDataValue => {
       if ((exportDataValue.status & (BmsDataExportValueStatus.Signed | BmsDataExportValueStatus.Invoiced)) == 0) {
        result = false;
       }
    });
    return result;
  }

  allAreSigned(exportDataValues: BmsDataExportValue[]): boolean {
    let result = true;
    exportDataValues.forEach(exportDataValue => {
       if ((exportDataValue.status & BmsDataExportValueStatus.Signed) == 0) {
        result = false;
       }
    });
    return result;
  }

  hasSigned(exportDataValues: BmsDataExportValue[]): boolean {
    let result = false;
    exportDataValues.forEach(exportDataValue => {
       if ((exportDataValue.status & BmsDataExportValueStatus.Signed) != 0) {
        result = true;
       }
    });
    return result;
  }

  hasError(exportDataValues: BmsDataExportValue[]): boolean {
    let result = false;
    exportDataValues.forEach(exportDataValue => {
       if ((exportDataValue.status & errorFlags) != 0) {
        result = true;
       }
    });
    return result;
  }


  getBmsBillingPeriodString(period: BmsBillingPeriod) {
    return BmsBillingPeriod[period];
  }

  async onGridDetailChange(dataExportValue: BmsDataExportValue) {
    let dashboardPaneArea = this.getPaneArea(1);
    if (dashboardPaneArea) {
      let width = (dashboardPaneArea.elRef.nativeElement.clientWidth * 2) / 3 - 75;
      if (width != this.responsiveWidth) {
        this.responsiveWidth = width;
      }
    }

    this.dashboardGridDetailOutputParameters = [];
    this.dashboardGridDetailManualDataValue = false;
    if (dataExportValue != null) {
      let out = new DashboardOutputChangeParameters();
      out.outputParameterName = 'Id';
      out.value = dataExportValue.meterId;
      out.datatype = OutputDataType.Int64;
      this.dashboardGridDetailOutputParameters.push(out);

      let outFrom = new DashboardOutputChangeParameters();
      outFrom.outputParameterName = 'From';
      outFrom.value = this.dateHelper.utils.addYears(new Date(), -2);
      outFrom.datatype = OutputDataType.Timestamp;
      this.dashboardGridDetailOutputParameters.push(outFrom);

      let outTo = new DashboardOutputChangeParameters();
      outTo.outputParameterName = 'To';
      outTo.value = new Date();
      outTo.datatype = OutputDataType.Timestamp;
      this.dashboardGridDetailOutputParameters.push(outTo);

      let outCustomerId = new DashboardOutputChangeParameters();
      outCustomerId.outputParameterName = 'CustomerId';
      outCustomerId.value = this.dataBasisOverviewCustomerInfo?.customerId;
      outCustomerId.datatype = OutputDataType.String;
      this.dashboardGridDetailOutputParameters.push(outCustomerId);

      this.dashboardGridDetailManualDataValue = dataExportValue.meterId <= 0;
      this.dashboardGridDetailDataExportValue = dataExportValue;
    }
  }

  async onWidgetValueChangedEdit(parameters: LinkedWidgetChangeParameters) {
    this.logger.debug(parameters);

    this.editExtrapolateStart = parameters.zoom.from;
    this.editExtrapolateStartString = this.dateHelper.utils.formatByString(this.editExtrapolateStart, 'yyyy-MM-dd')
    this.editExtrapolateEnd = parameters.zoom.to;
    this.editExtrapolateEndString = this.dateHelper.utils.formatByString(this.editExtrapolateEnd, 'yyyy-MM-dd')
  }

  setExtrapolatePeriodLastYear() {
    this.dashboardInteractService.ResetDashboard();
    this.periodLastYearSet = !this.periodLastYearSet;

    if (this.periodLastYearSet) {
      this.editExtrapolateStart = this.dateHelper.utils.addYears(this.selectedExportDataValueEdit.start, -1);
      this.editExtrapolateStartString = this.dateHelper.utils.formatByString(this.editExtrapolateStart, 'yyyy-MM-dd')
      this.editExtrapolateEnd = this.dateHelper.utils.addYears(this.selectedExportDataValueEdit.end, -1);
      this.editExtrapolateEnd = this.dateHelper.utils.addSeconds(this.editExtrapolateEnd, -1);
      this.editExtrapolateEndString = this.dateHelper.utils.formatByString(this.editExtrapolateEnd, 'yyyy-MM-dd')
    }
    else {
      this.editExtrapolateStart = this.dateHelper.utils.addYears(new Date(), -2);
      this.editExtrapolateStartString = this.dateHelper.utils.formatByString(this.editExtrapolateStart, 'yyyy-MM-dd')
      this.editExtrapolateEnd = new Date();
      this.editExtrapolateEndString = this.dateHelper.utils.formatByString(this.editExtrapolateEnd, 'yyyy-MM-dd')
    }

    this.dashboardGridDetailOutputParameters = [];

    let outFrom = new DashboardOutputChangeParameters();
    outFrom.outputParameterName = 'From';
    outFrom.value = this.editExtrapolateStart;
    outFrom.datatype = OutputDataType.Timestamp;
    this.dashboardGridDetailOutputParameters.push(outFrom);

    let outTo = new DashboardOutputChangeParameters();
    outTo.outputParameterName = 'To';
    outTo.value = this.editExtrapolateEnd;
    outTo.datatype = OutputDataType.Timestamp;
    this.dashboardGridDetailOutputParameters.push(outTo);
  }

  async editExtrapolateEndValue(forceLastPeriodEndValue : boolean = false) {
    let extrapolateStart = this.dateHelper.utils.parse(this.editExtrapolateStartString, 'yyyy-MM-dd');
    let extrapolateEnd = this.dateHelper.utils.parse(this.editExtrapolateEndString, 'yyyy-MM-dd');

    let result = await this.bmsExportClient.getExtrapolatedValue(this.dataBasisOverviewCustomerInfo.customerId,
      this.selectedExportDataValueEdit.meterId, this.selectedExportDataValueEdit.start, this.selectedExportDataValueEdit.end,
      extrapolateStart, extrapolateEnd, forceLastPeriodEndValue);

    this.logger.info('extrapolatedValue', result);

    if (result.ok) {
      this.selectedExportDataValueCopy.endValue = +result.value.toFixed(this.selectedTrusteeConfig.reportDataPointDecimalCount);
    }
    else {
      this.alertService.error(result.message);
    }
  }

  async extrapolateAllEndValues(forceLastPeriodEndValue : boolean = false) {
    if (this.selectedExportDataValueMeterType == 'ALL METERTYPES') {
      this.alertService.error('No Meter type selected!');
    }
    else {
      if (this.dataExportValues) {
        this.modalService.ShowConfirmModal({ header: forceLastPeriodEndValue ? 'Extrapolate all from last period end' : 'Extrapolate all', description: 'Extrapolate all meters of same metertype, are you sure?' }, async (result) => {
          if (result) {
            try {
              this.extrapolateAllRunning = true;
              this.extrapolateAllCurrent = 0;
              this.extrapolateAllPercent = 0;
              this.extrapolateAllTotal = this.dataExportValues.filter(dataExportValue => (dataExportValue.status & (BmsDataExportValueStatus.Signed | BmsDataExportValueStatus.Invoiced | BmsDataExportValueStatus.MissingStartValue)) == 0
                && (dataExportValue.status & (BmsDataExportValueStatus.MissingEndValue | BmsDataExportValueStatus.AutomaticEstimate)) > 0).length;
              let extrapolateStart = this.dateHelper.utils.parse(this.editExtrapolateStartString, 'yyyy-MM-dd');
              let extrapolateEnd = this.dateHelper.utils.parse(this.editExtrapolateEndString, 'yyyy-MM-dd');

              let toSet: BmsDataExportValue[] = [];
              await ArrayUtils.AsyncForEach(this.dataExportValues, async (dataExportValue: BmsDataExportValue) => {
                if ((dataExportValue.status & (BmsDataExportValueStatus.Signed | BmsDataExportValueStatus.Invoiced | BmsDataExportValueStatus.MissingStartValue)) == 0
                  && (dataExportValue.status & (BmsDataExportValueStatus.MissingEndValue | BmsDataExportValueStatus.AutomaticEstimate)) > 0) {
                  let result = await this.bmsExportClient.getExtrapolatedValue(this.dataBasisOverviewCustomerInfo.customerId,
                    dataExportValue.meterId, this.selectedExportDataValueEdit.start, this.selectedExportDataValueEdit.end,
                    extrapolateStart, extrapolateEnd, forceLastPeriodEndValue);

                  this.logger.info('extrapolatedValue', result);

                  if (result.ok) {
                    dataExportValue.endValue = +result.value.toFixed(this.selectedTrusteeConfig.reportDataPointDecimalCount);
                    if (dataExportValue.endValue >= 0) {
                      dataExportValue.status &= ~BmsDataExportValueStatus.MissingEndValue;
                      dataExportValue.status |= BmsDataExportValueStatus.AutomaticEstimate;
                      if (dataExportValue.status & (BmsDataExportValueStatus.MissingStartValue | BmsDataExportValueStatus.MissingEndValue)) {
                        dataExportValue.diff = -9999999999;
                      }
                      else {
                        dataExportValue.diff = +(dataExportValue.endValue - dataExportValue.startValue).toFixed(this.selectedTrusteeConfig.reportDataPointDecimalCount);
                      }
                    }
                    toSet.push(dataExportValue);
                  }
                  this.extrapolateAllCurrent++;
                  this.extrapolateAllPercent = +(this.extrapolateAllCurrent * 100 / this.extrapolateAllTotal).toFixed(0);
                }
              });

              await this.dataExportService.setDataExportValues(toSet);
            }
            finally {
              this.extrapolateAllRunning = false;
              this.showEditExportDataValue = false;
              this.alertService.success('Extrpolate all finished ok.');
            }
          }
        });
      }
    }
  }

  async onViewInBMS() {
    if (this.dataBasisOverviewCustomerInfo && this.selectedExportDataValues.length == 1) {
      this.router.navigateByUrl('/bmsadmin/customers/' + this.dataBasisOverviewCustomerInfo.customerId + '/meters/' + this.selectedExportDataValues[0].meterId + '/' + '_x_bms_meter');
    }
  }

  async search() {
    this.exportDataMeterId = -1;
    let meter = await this.adminService.getMeterByIdentifier(this.deviceIdFilter);

    if (meter) {
      this.exportDataMeterId = meter.id;
    }

    this.updateDataBasis();
  }

  async showImportDatafile() {
    if (this.meterTypeOptionNodes.length == 0) {
      this.meterTypeOptionNodes = await this.xConfClient.getReferencedNodesWithCache('_x_bms_metertypes_root', '_x_bms_metertypes_root', '_x_bms_metertypes_root', [], '', 1);
      this.meterTypeOptionNodes = this.meterTypeOptionNodes.sort((a, b) => {
        return a.name > b.name ? 1 : -1;
      });
    }
    this.importDatafileMetertype = this.selectedExportDataValueMeterType;
    this.importDatafileExportPeriod = this.selectedDataBasisExportPeriod;
    this.showImportData = true;
  }

  async exportData() {
    if (this.selectedExportDataValueMeterType == 'ALL METERTYPES') {
      this.alertService.error('No Meter type selected!');
    }
    else {
      let periodStart = this.dateHelper.utils.addMinutes(this.dataBasisOverviewCustomerInfo.exportPeriods[this.selectedDataBasisExportPeriod].start, -this.dataBasisOverviewCustomerInfo.exportPeriods[this.selectedDataBasisExportPeriod].start.getTimezoneOffset());
      let periodEnd = this.dateHelper.utils.addMinutes(this.dataBasisOverviewCustomerInfo.exportPeriods[this.selectedDataBasisExportPeriod].end, -this.dataBasisOverviewCustomerInfo.exportPeriods[this.selectedDataBasisExportPeriod].end.getTimezoneOffset());

      this.bmsExportClient.exportDataExportValues(this.dataBasisOverviewCustomerInfo.customerId, this.dataBasisOverviewCustomerInfo.customerName,
        periodStart, periodEnd, this.selectedExportDataValueMeterType);
    }
  }


  uploadDataFile(file) {
    if (file.files.length > 0) {
      this.importDatafile = file.files[0];
    }
  }

  async onImportDataFile() {
    try {
      if (this.importDatafile) {
        if (this.importDatafileMetertype == 'ALL METERTYPES') {
          this.alertService.error('No Meter type selected!');
        }
        else {
          let customerId = this.dataBasisOverviewCustomerInfo.customerId;
          let periodStart = this.dateHelper.utils.addMinutes(this.dataBasisOverviewCustomerInfo.exportPeriods[this.importDatafileExportPeriod].start, -this.dataBasisOverviewCustomerInfo.exportPeriods[this.importDatafileExportPeriod].start.getTimezoneOffset());
          let periodEnd = this.dateHelper.utils.addMinutes(this.dataBasisOverviewCustomerInfo.exportPeriods[this.importDatafileExportPeriod].end, -this.dataBasisOverviewCustomerInfo.exportPeriods[this.importDatafileExportPeriod].end.getTimezoneOffset());

          let result = await this.bmsState.importDataFile(this.importDatafile, customerId, this.importDatafileMetertype,
            this.formatByString(periodStart, 'yyyy-MM-dd HH:mm:ss', true), this.formatByString(periodEnd, 'yyyy-MM-dd HH:mm:ss', true));

          if (result) {
            this.alertService.info('Data exportvalues imported ok.');
            this.updateDataBasis();
          }
          else {
            this.alertService.error('Error import data exportvalues.');
          }
        }
      }
    }
    finally {
      this.showImportData = false;
    }
  }

  clearFilters() {
    this.tableColumns.forEach(column => {
      if (column.field != 'name') {     //Trustee list, better way?
        if (column.customFilter) {
          //@ts-ignore
          for (let i = 0; i < column.filters._all.length; i++) {
            //@ts-ignore
            let filter = column.filters._all[i].filter;
            if (filter instanceof DataExportStatusFilterComponent) {
              (filter as DataExportStatusFilterComponent).all();
            }
            else if (filter instanceof DataExportExternalidFilterComponent) {
              (filter as DataExportExternalidFilterComponent).all();
            }
            else {
              //@ts-ignore
              filter.value = '';
            }
          }
        }

        column.filterValue = ''
      }
    });
  }

  setFilterSelected() {
    if (this.selectedExportDataValues.length > 0) {
      this.tableColumns.forEach(column => {
        if (column.field == 'meterId') {
          let meterIdFilterValues : string[] = [];
          this.selectedExportDataValues.forEach(x => {
            meterIdFilterValues.push('_' + x.meterId)
          });
          let meterIdFilterValue = meterIdFilterValues.join(',');
          this.clearFilters();

          //@ts-ignore
          for (let i = 0; i < column.filters._all.length; i++) {
            //@ts-ignore
            let filter = column.filters._all[i].filter;
            if (filter.filterFn instanceof MeterIdFilter) {
              //@ts-ignore
              filter.value = meterIdFilterValue;
            }
          }
        }
      });
    }
  }

  editOperationInfo() {
    if (this.dataBasisOverviewCustomerInfo?.customerConfig) {
      this.copyOperationInfo = this.dataBasisOverviewCustomerInfo.customerConfig.operationInfo;
      this.editOperationMode = true;
    }
  }

  async saveOperationInfo() {
    if (this.dataBasisOverviewCustomerInfo?.customerConfig) {
      this.dataBasisOverviewCustomerInfo.customerConfig.operationInfo = this.copyOperationInfo;
      let result = await this.adminService.saveCustomerConfigInfos(this.dataBasisOverviewCustomerInfo.customerConfig);

      if (result.result) {
        this.editOperationMode = false;
      }
    }
  }

  editInvoiceInfo() {
    if (this.dataBasisOverviewCustomerInfo?.customerConfig) {
      this.copyInvoiceInfo = this.dataBasisOverviewCustomerInfo.customerConfig.invoiceInfo;
      this.editInvoiceMode = true;
    }
  }

  editInvoiceInfoList(invoiceInfo : string) {
      this.copyInvoiceInfo = invoiceInfo;
      this.editInvoiceMode = true;
  }


  async saveInvoiceInfo() {
    if (this.dataBasisOverviewCustomerInfo?.customerConfig) {
      this.dataBasisOverviewCustomerInfo.customerConfig.invoiceInfo = this.copyInvoiceInfo;
      let result = await this.adminService.saveCustomerConfigInfos(this.dataBasisOverviewCustomerInfo.customerConfig);

      if (result.result) {
        this.editInvoiceMode = false;
      }
    }
  }

  async saveInvoiceInfoList(customerConfig : BmsCustomerConfig) {
    if (customerConfig) {
      customerConfig.invoiceInfo = this.copyInvoiceInfo;
      let result = await this.adminService.saveCustomerConfigInfos(customerConfig);

      if (result.result) {
        this.editInvoiceMode = false;
      }
    }
  }

  async selectNextDataExportCustomer() {
    if (this.dataBasisOverviewCustomerInfo) {
      let index = this.overviewCustomerInfos.findIndex(x => x == this.dataBasisOverviewCustomerInfo);
      if (index > -1) {
        if (index == this.overviewCustomerInfos.length - 1) {
          this.dataBasisOverviewCustomerInfo = this.overviewCustomerInfos[0];
        }
        else {
          this.dataBasisOverviewCustomerInfo = this.overviewCustomerInfos[index + 1];
        }

        await this.selectedDataBasisCustomerChange(null);
      }
    }
  }

  async selectPrevDataExportCustomer() {
    if (this.dataBasisOverviewCustomerInfo) {
      let index = this.overviewCustomerInfos.findIndex(x => x == this.dataBasisOverviewCustomerInfo);
      if (index > -1) {
        if (index == 0) {
          this.dataBasisOverviewCustomerInfo = this.overviewCustomerInfos[this.overviewCustomerInfos.length - 1];
        }
        else {
          this.dataBasisOverviewCustomerInfo = this.overviewCustomerInfos[index - 1];
        }

        await this.selectedDataBasisCustomerChange(null);
      }
    }
  }

}
