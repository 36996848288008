import { Pipe, PipeTransform } from '@angular/core';
import {  DateHelper, XDataType } from 'xproj-lib';
import { BmsEditMode } from "@features/bms/models/bms-edit.mode";
import { BmsQueryDataColumn } from './bms-meter-admin.component';

@Pipe({
  name: 'bmsmeteradmineditpipe'
})
export class BmsMeterAdminEditPipe implements PipeTransform {

  constructor(private dateHelper : DateHelper) {}

  transform(value: unknown, index: any, ...args: unknown[]): string {

    let columnData = value as BmsQueryDataColumn;
    let dataIndex = index as number;

    switch (columnData.editMode) {
      case BmsEditMode.Boolean:
        let b = columnData.data[index];
        return (b == 0 || b == '0' || b == false) ? 'False' : 'True';

      case BmsEditMode.XDatatype:
        return XDataType[columnData.data[index]];

      case BmsEditMode.Enum:
        if (columnData.enumMembers[+columnData.data[index]]) {
          return columnData.enumMembers[+columnData.data[index]].name;
        }
        else {
          return this.prettyPrint(columnData.data[index]);
        }

      default:
        return this.prettyPrint(columnData.data[index]);
    }

  }

  formatDate(date : Date) : string {
    return this.dateHelper.utils.formatByString(date, "yyyy-MM-dd HH:mm");
  }

  prettyPrint(obj : Object) : string {
    if(obj == null || obj === undefined)
        return "";

    if(obj instanceof Date)
    {
        return this.formatDate(obj as Date);
    }
    if(obj instanceof Boolean)
    {
        let x = obj as Boolean;
        if(x)
            return "True";
        return "False";

    }

    if( obj instanceof String || typeof obj =='string')
    {
      return obj.toString();
    }

    if( Array.isArray(obj) )
    {
      return obj.toString();
    }

    if( Object.keys(obj).length > 0  )
    {
        let nrKeys = Object.keys(obj).length;
        if(nrKeys == 1)
        {
            return obj[ Object.keys(obj)[0] ];
        }

        return nrKeys.toString() + " keys";
    }


    return obj.toString();
}
}
