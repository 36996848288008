<table class="table table-vertical" *ngIf="meter && !showData">
  <caption style="margin-bottom: 1em;">
    Meter info
    <button class="btn btn-sm" style="float: right;" (click)="viewInBMS();">View in BMS <clr-icon
        shape="arrow" directions="left" style="transform: rotate(90deg);"></clr-icon></button>
  </caption>
  <tbody>
    <tr>
      <th class="left">Identifier</th>
      <td class="left">{{meter.identifier}}</td>
    </tr>
    <tr>
      <th class="left">Vendor</th>
      <td class="left">{{meter.manufacturer}}</td>
    </tr>
    <tr>
      <th class="left">Unit</th>
      <td class="left">{{meter.unit}}</td>
    </tr>
    <tr>
      <th class="left">Model</th>
      <td class="left">{{meter.model}}</td>
    </tr>
    <tr>
      <th class="left">Type Id</th>
      <td class="left">{{meter.typeId}}</td>
    </tr>
    <tr>
      <th class="left">Meter Type</th>
      <td class="left">{{meter.meterType}}</td>
    </tr>
    <tr>
      <th class="left">State</th>
      <td class="left">{{BmsMeterState[meter.state]}}</td>
    </tr>
    <tr>
      <th class="left">Create At</th>
      <td class="left">{{dateHelper.utils.format(meter.createdAt, 'keyboardDateTime')}}</td>
    </tr>
    <tr>
      <th class="left">Modified At</th>
      <td class="left">{{dateHelper.utils.format(meter.modifiedAt, 'keyboardDateTime')}}</td>
    </tr>
    <tr>
      <th class="left">Meter Id</th>
      <td class="left">{{meter.id}}</td>
    </tr>
  </tbody>
</table>

<div class="meterdata-content-area" *ngIf="meter && showData" >
  <xproj-dashboard #dashboardMeterData [editMode]="false" [showDashboardSettings]="false"
    [dashboardId]="'bms_meter'" [enableExport]="false" [responsiveWidth]="responsiveWidth * 0.85"
    [dashboardOutputParameters]="dashboardOutputParameters"></xproj-dashboard>
</div>

<p *ngIf="!meter" > Meter info not available.</p>
