import { Pipe, PipeTransform } from '@angular/core';
import { EventHistory } from '@core/xprojector_backend/proto/xprojector.modulebms.events.pb';

@Pipe({
  name: 'bmscustomereventactivepipe'
})
export class BmsCustomerEventActivePipe implements PipeTransform {

  constructor() {}

  transform(active: boolean, ...args: unknown[]): string {

    return  active ? $localize `:@@customerevents_active:Active` : $localize `:@@customerevents_inactive:Inactive`;
  }

}
