import { Injectable } from '@angular/core';
import { BmsMeterExchange } from '@features/bms/models/bms-meter-exchange';
import { NGXLogger } from 'ngx-logger';
import { ArrayUtils, BaseQuery, BaseQueryInputColumnDescription, ColumnFilteringNumerical, ColumnFilteringString, ColumnFilteringTimestamp, FilterComparator, FilterLogicalGroup, FilterLogicalGroupType, ProjectionColumnDescription, SetDataColumnDescription, SetDataQuery, XDataType, XProjectorClient } from 'xproj-lib';
import { flakeId } from '../utils/bms-data-utils';

export interface BmsMeterExchangeParameters {
  customerId? : string,
  apartmentId?: number,
  facilityId?: number,
  exchangeDateAfter?: Date,
  maxItems?: number
}

const METER_EXCHANGE_PROJECTIONID: string = 'hs-meterexchange';

@Injectable({
  providedIn: 'root'
})
export class BmsMeterExchangeService {

  meterExchangeColumns: ProjectionColumnDescription[] = [];

  private setColumns: SetDataColumnDescription[] = [
    {
      columnname: 'id',
      datatype: XDataType.Int64,
      indexintypedvector: 0
    },
    {
      columnname: 'customerid',
      datatype: XDataType.String,
      indexintypedvector: 0
    },
    {
      columnname: 'oldmeterid',
      datatype: XDataType.Int64,
      indexintypedvector: 1
    },
    {
      columnname: 'newmeterid',
      datatype: XDataType.Int64,
      indexintypedvector: 2
    },
    {
      columnname: 'createdat',
      datatype: XDataType.Timestamp,
      indexintypedvector: 0
    },
    {
      columnname: 'modifiedat',
      datatype: XDataType.Timestamp,
      indexintypedvector: 1
    },
    {
      columnname: 'deleted',
      datatype: XDataType.UInt8,
      indexintypedvector: 3
    },
    {
      columnname: 'deletedat',
      datatype: XDataType.Timestamp,
      indexintypedvector: 2
    },
    {
      columnname: 'exchangedate',
      datatype: XDataType.Timestamp,
      indexintypedvector: 3
    },
        {
      columnname: 'newmeterstartvalue',
      datatype: XDataType.Float32,
      indexintypedvector: 4
    },
    {
      columnname: 'oldmeterendvalue',
      datatype: XDataType.Float32,
      indexintypedvector: 5
    }
  ];

  constructor(
    private xprojClient: XProjectorClient,
    private logger: NGXLogger
  ) {

  }

  async setMeterExchanges(meterExchanges: BmsMeterExchange[]): Promise<boolean> {
    let query: SetDataQuery = new SetDataQuery();

    await ArrayUtils.AsyncForEach(meterExchanges, async (meterExchange) => {
      if (!meterExchange.id || meterExchange.id == 0) {
        meterExchange.id = await flakeId.nextId();
      }
    });

    query.datastrings = [
      meterExchanges.map(t => t.customerId),
    ];
    query.datanumbers = [
      meterExchanges.map(t => t.id),
      meterExchanges.map(t => t.oldMeterId ?? 0),
      meterExchanges.map(t => t.newMeterId ?? 0),
      meterExchanges.map(t => t.deleted ? 1 : 0),
      meterExchanges.map(t => t.newMeterStartValue ?? 0),
      meterExchanges.map(t => t.oldMeterEndValue ?? 0),
    ];
    query.datatimestamps = [
      meterExchanges.map(t => t.createdAt ?? new Date()),
      meterExchanges.map(t => t.modifiedAt ?? new Date()),
      meterExchanges.map(t => t.deletedAt ?? new Date(0)),
      meterExchanges.map(t => t.exchangeDate ?? new Date())
    ];

    query.projectionid = METER_EXCHANGE_PROJECTIONID;
    query.columns = this.setColumns;

    return await this.xprojClient.RequestSetData(query);
  }

  async getMeterExchanges({
    customerId = undefined,
    exchangeDateAfter = undefined,
    maxItems = 10
  }: BmsMeterExchangeParameters): Promise<BmsMeterExchange[]> {

    let result: BmsMeterExchange[] = [];
    if (customerId) {
      if (this.meterExchangeColumns.length == 0) {
        this.meterExchangeColumns = await this.xprojClient.RequestListQueryableProjectionColumns(METER_EXCHANGE_PROJECTIONID, '', 0, 100);
      }

      let query: BaseQuery = new BaseQuery();
      query.targetprojectionid = METER_EXCHANGE_PROJECTIONID;
      query.maxitems = maxItems;
      query.targetgroup = [];

      let filterId = 0;
      query.filter.type = FilterLogicalGroupType.AND;

      if (exchangeDateAfter) {
        let timestampFiltering = new ColumnFilteringTimestamp();
        timestampFiltering.columnname = 'exchangedate';
        timestampFiltering.comparator = FilterComparator.GreatherThanOrEquals;
        timestampFiltering.value = exchangeDateAfter;
        timestampFiltering.queryfilterid = ++filterId;
        query.filter.filters.push(timestampFiltering.queryfilterid);
        query.timestampfilters.push(timestampFiltering);
      }

      let deletedFiltering = new ColumnFilteringNumerical();
      deletedFiltering.columnname = 'deleted';
      deletedFiltering.comparator = FilterComparator.Equals;
      deletedFiltering.value = 0;
      deletedFiltering.queryfilterid = ++filterId;
      query.filter.filters.push(deletedFiltering.queryfilterid);
      query.numericalfilters.push(deletedFiltering);

      this.meterExchangeColumns.filter(c => c.columnname != 'customerid').forEach(c => {
        let inCol = new BaseQueryInputColumnDescription();
        inCol.columnname = c.columnname;
        inCol.columnoutname = c.columnname;
        query.columns.push(inCol);
      });

      query.sorting.columnname = 'exchangedate';
      query.sorting.descending = true;

      query.targetgroup = [customerId];

      try {
        let queryResult = await this.xprojClient.RequestQueryBaseQuery(query, true);

        let numericaldata = queryResult.datanumbers;
        let timestampdata = queryResult.datatimestamps;
        let stringdata = queryResult.datastrings;

        let rowCount = 0;
        if (numericaldata.length > 0) {
          rowCount = numericaldata[0].length;
        }

        for (let row = 0; row < rowCount; row++) {
          let meterExchange = new BmsMeterExchange();
          meterExchange.customerId = customerId;

          for (let i = 0; i < queryResult.columns.length; i++) {
            let it = queryResult.columns[i];
            let typ = it.datatype;
            let data = [];
            if (typ == XDataType.Number) {
              data = numericaldata[it.indexintypedvector];
            }
            if (typ == XDataType.String) {
              data = stringdata[it.indexintypedvector];
            }
            if (typ == XDataType.Timestamp) {
              data = timestampdata[it.indexintypedvector];
            }

            switch (it.columnoutname) {
              case 'id':
                meterExchange.id = data[row];
                break;
              case 'oldmeterid':
                meterExchange.oldMeterId = data[row];
                break;
              case 'newmeterid':
                meterExchange.newMeterId = data[row];
                break;
              case 'exchangedate':
                meterExchange.exchangeDate = data[row];
                break;
              case 'createdat':
                meterExchange.createdAt = data[row];
                break;
              case 'modifiedat':
                meterExchange.modifiedAt = data[row];
                break;
            }
          }

          result.push(meterExchange);
        }
      }
      catch (err) {
        this.logger.error(err);
      }
    }
    return result;
  }

  async deleteMeterExchange(meterExchange : BmsMeterExchange) : Promise<boolean> {
    meterExchange.deleted = true;
    meterExchange.deletedAt = new Date();

    return this.setMeterExchanges([meterExchange]);
  }
}
