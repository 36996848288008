export class BmsRealestate {
  id : number;

  svlantPropertyDesignation : string;
  description : string;
  latitude : number;
  longitude : number;

  createdAt : Date;
  modifiedAt : Date;
}
