<clr-modal [(clrModalOpen)]="showEditBillingTariff" [clrModalClosable]="false">
  <h3 class="modal-title">Edit billing tariff</h3>
  <div class="modal-body">
    <form clrForm *ngIf="selectedTariff">
      <clr-input-container>
        <label>Tariff:</label>
        <input clrInput type="number" min="0" max="100000" step="0.1" placeholder="1" name="setbillingtariff" [(ngModel)]="selectedTariff.value" />
      </clr-input-container>

      <clr-input-container>
        <label>Active from:</label>
        <input clrInput type="date" step="1" placeholder="" name="setbillingactivefrom" [(ngModel)]="selectedActiveFromString" />
      </clr-input-container>

      <clr-input-container>
        <label>VAT Rate:</label>
        <input clrInput type="number" min="0" max="1" step="0.01" placeholder="0.2" name="setbillingvatrate" [(ngModel)]="selectedTariff.vatRate" />
      </clr-input-container>

      <!-- <clr-checkbox-container>
        <clr-checkbox-wrapper>
          <input type="checkbox" clrCheckbox [(ngModel)]="selectedTariff.includeVat"
            name="setbillingincludevat" />
          <label>Include VAT</label>
        </clr-checkbox-wrapper>
      </clr-checkbox-container> -->

      <clr-input-container>
        <label>Currency:</label>
        <input clrInput placeholder="SEK" name="setbillingcurrency" [(ngModel)]="selectedTariff.currency" />
      </clr-input-container>

    </form>
  </div>
  <div class="modal-footer">
    <button type="button" class="btn btn-primary" (click)="showEditBillingTariff = false">Cancel</button>
    <button type="button" class="btn btn-primary"
      (click)="showEditBillingTariff = false; onExecuteEditBillingTariff()">Ok</button>
  </div>
</clr-modal>

<table class="table ">
  <caption>
    Tariff info
  </caption>
  <thead>
    <tr>
      <th class="left">Active From</th>
      <th class="left">Value</th>
      <th class="left">Currency</th>
      <!-- <th class="left">Include VAT</th> -->
      <th class="left">VAT Rate</th>
      <th *ngIf="allowEdit"  class="left"></th>
    </tr>
  </thead>
  <tbody>
    <tr *ngFor="let tariff of tariffs">
      <td class="left" >{{dateHelper.utils.format(tariff.activeFrom, 'keyboardDate')}}</td>
      <td class="left" >{{tariff.value.toFixed(decimals)}}</td>
      <td class="left">{{tariff.currency}}</td>
      <!-- <td class="left">{{tariff.includeVat}}</td> -->
      <td class="left">{{tariff.vatRate.toFixed(decimals)}}</td>
      <td *ngIf="allowEdit"><clr-icon shape="pencil" (click)="editTariff(tariff)" [style.cursor]="'pointer'"></clr-icon></td>
    </tr>
  </tbody>
</table>
