export enum BmsGatewayStatus {
  Undefined = 0,
  Online = 1,
  Offline = 2
}

export class BmsGatewayInfo {
  id : number;
  customerId : string;
  customerName : string;
  name : string;
  status : BmsGatewayStatus;
  meterCount : number;
  meterCountMissingValue : number;
  lastMeterValueAt : Date;
  lastOnlineAt : Date;
  modifiedAt : Date;
}
