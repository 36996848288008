import { ChangeDetectorRef, Component, HostListener, OnInit, QueryList, ViewChild, ViewChildren, ViewEncapsulation } from '@angular/core';
import { Customer, CustomerUser, GrpcNode } from '@xprojectorcore/xprojector_backend/proto/xprojector.grpc.models.pb';
import { XProjectorSysAdminClient } from '@xprojectorcore/xprojector_backend/xprojector-sysadmin-client';
import { DashboardOutputChangeParameters, XprojAlertService, OutputDataType, XprojModalService, DateHelper, ArrayUtils, LinkedWidgetChangeParameters, LinkedWidgetSelectParameters } from 'xproj-lib';
import { XProjectorXConfClient } from '@xprojectorcore/xprojector_backend/xprojector-xconf-client';
import { BmsCustomerConfig } from '@features/bms/models/bms-customer-config';
import { BmsDataUtils } from '@features/bms/bms-admin/utils/bms-data-utils';
import { ClrDatagridComparatorInterface, ClrDatagridSortOrder, ClrLoadingState, ClrTabs } from '@clr/angular';
import { GrpcDataSourceInstance, GrpcNodeType, SearchNodesRequest, SearchProperty } from '@xprojectorcore/xprojector_backend/proto/xprojector.xconf.pb';
import { SplitAreaDirective } from 'angular-split';
import { ConfigurationDataSourceComponent, DropDownItem } from '@xprojectorfeatures/xconf/components/configuration-datasource/configuration-datasource.component';
import { BmsMeterAdminFilter, BmsMeterListComponent } from '../bms-meter-list/bms-meter-list.component';
import { BmsMeter, BmsMeterState } from '@features/bms/models/bms-meter';
import { EditTreenodeComponent } from '@xprojectorfeatures/xconf/components/edit-treenode/edit-treenode.component';
import { BmsBillingTariffViewComponent } from '../bms-billing-tariff-view/bms-billing-tariff-view.component';
import { BmsTariffService } from '@features/bms/bms-admin/services/bms-tariff-service';
import { BmsResidentExchangeService } from '@features/bms/bms-admin/services/bms-resident-exchange-service';
import { BmsMeterStateEventService } from '@features/bms/bms-admin/services/bms-meter-state-event-service';
import { BmsMeterExchangeService } from '@features/bms/bms-admin/services/bms-meter-exchange-service';
import { BmsBillingTariff } from '@features/bms/models/bms-billing-tariff';
import { BmsResidentExchange } from '@features/bms/models/bms-resident-exchange';
import { BmsMeterExchange } from '@features/bms/models/bms-meter-exchange';
import { BmsResidentExchangViewComponent } from '../bms-resident-exchang-view/bms-resident-exchang-view.component';
import { ActivatedRoute } from '@angular/router';
import { map } from 'rxjs/operators';
import { BmsAdminService } from '@features/bms/bms-admin/services/bms-admin-service';
import { BmsCustomer } from '@features/bms/models/bms-customer';
import { BmsCustomerData } from '@features/bms/models/bms-customer-data';
import { BmsBillingPeriod, BmsMonthOfYear } from '@core/models/bms-export-bot';
import { BmsMeterData } from '@features/bms/models/bms-meter-data';
import { NGXLogger } from 'ngx-logger';
import { BmsDataExportService } from '@features/bms/bms-admin/services/bms-data-export-service';
import { UnitConversionsService } from '@xprojectorcore/services/unit-conversions.service';
import { XProjectorBmsExportClient } from '@core/xprojector_backend/xprojector-bms-export-client';
import { BmsMasterDataImportResult } from '@features/bms/models/bms-master-data-import-result';
import { StateService } from '@xprojectorcore/services/state-service';
import { BmsMeterStateEvent } from '@features/bms/models/bms-meter-state-event';
import { BmsInvoiceInfo } from '@features/bms/models/bms-invoice-info';
import { bmsDistricts } from '@features/bms/models/bms-districts';
import { BmsExternalCustomersComponent } from '../bms-external-customers/bms-external-customers.component';
import { BmsStateService } from '@core/services/bms-state-service';
import { BmsDataCollectorViewComponent } from '../bms-data-collector-view/bms-data-collector-view.component';
import { PageData } from '@xprojectorcore/models/page-data';
import { XEdgeToken } from '@xprojectorcore/models/xedge-token';
import { XProjectorXEdgeClient } from '@xprojectorcore/xprojector_backend/xprojector-xedge-client';
import { XEdgeSelectXAutoVarsComponent } from '@xprojectorfeatures/xedge/components/xedge-select-xauto-vars/xedge-select-xauto-vars.component';
import { SessionStorageService } from '@xprojectorcore/services/session-storage.service';
import { XProjectorBmsCustomerAdminClient } from '@app/core/xprojector_backend/xprojector-bms-customeradmin-client';
import { BmsCustomerFlag } from '@app/features/bms/models/bms-customer-flag';
import { XconfTreeNode } from '@xprojectorfeatures/xconf/models/xconf-tree-node';
import { BmsErrmeterTimestampfrom } from '@app/features/bms/models/bms-errmeter-timestampfrom';

declare var XPROJECTOR_HOST;
declare var XEDGE_APP_HOST;



class ExternalIdComparator implements ClrDatagridComparatorInterface<BmsMeterData> {
  compare(a: BmsMeterData, b: BmsMeterData) {
    if (a && b && a.externalId && b.externalId) {
      return a.externalId.padStart(10, '0') > b.externalId.padStart(10, '0') ? 1 : -1;
    }
    else {
      return a ? 1 : -1
    }
  }
}

const TABINDEX_OVERVIEW = 0;
const TABINDEX_BMS = 1;
const TABINDEX_METERS = 2;
const TABINDEX_TRAIFFS = 3;
const TABINDEX_GRID = 4;
const TABINDEX_SETTINGS = 5;
const TABINDEX_USERS = 6;

@Component({
  selector: 'app-bms-customers-admin',
  templateUrl: './bms-customers-admin.component.html',
  styleUrls: ['./bms-customers-admin.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class BmsCustomersAdminComponent implements OnInit {

  @ViewChildren(SplitAreaDirective) areasEl: QueryList<SplitAreaDirective>
  @ViewChild("bmsMeterList", { read: BmsMeterListComponent, static: false }) bmsMeterList: BmsMeterListComponent;
  @ViewChild("gatewayMeterList", { read: BmsMeterListComponent, static: false }) gatewayMeterList: BmsMeterListComponent;
  @ViewChild("editTreeNode", { read: EditTreenodeComponent, static: false }) editTreeNode: EditTreenodeComponent;
  @ViewChild("editGridNode", { read: EditTreenodeComponent, static: false }) editGridNode: EditTreenodeComponent;
  @ViewChild("billingTariffView", { read: BmsBillingTariffViewComponent, static: false }) billingTariffView: BmsBillingTariffViewComponent;
  @ViewChild("residentExchangeView", { read: BmsResidentExchangViewComponent, static: false }) residentExchangeView: BmsResidentExchangViewComponent;
  @ViewChild("bmsConfigurationDatasource", { read: ConfigurationDataSourceComponent, static: false }) bmsConfigurationDatasource: ConfigurationDataSourceComponent;
  @ViewChild("metersDatasourceInstance", { read: ConfigurationDataSourceComponent, static: false }) metersDatasourceInstance: ConfigurationDataSourceComponent;
  @ViewChild("externalCustomers", { read: BmsExternalCustomersComponent, static: false }) externalCustomers: BmsExternalCustomersComponent;
  @ViewChild(ClrTabs) private readonly tabs: ClrTabs;
  @ViewChild("bmsDataCollectorView", { read: BmsDataCollectorViewComponent, static: false }) bmsDataCollectorView: BmsDataCollectorViewComponent;
  @ViewChild("selectXEdgeMeters", { read: XEdgeSelectXAutoVarsComponent, static: false }) selectXEdgeMeters: XEdgeSelectXAutoVarsComponent;

  ascSort = ClrDatagridSortOrder.ASC;
  externalIdSort = new ExternalIdComparator();

  customers: Customer[];
  bmsCustomers: BmsCustomer[];
  loadingCustomers: boolean = false;
  showDisabledCustomers : boolean = false;

  showRemoveCustomer: boolean = false;

  selectedCustomer: Customer;
  selectedBmsCustomer: BmsCustomer;
  selectedCustomerTrustees: Customer[];
  selectedCustomerData: BmsCustomerData;
  selectedMeterDatas: BmsMeterData[] = [];
  loadingMeterData: boolean = false;
  showInactiveMeters : boolean = false;
  inparamCustomerId: string;
  selectedCustomerConfig: BmsCustomerConfig;
  selectedCustomerRealestateNodes: GrpcNode[] = [];

  selectedCustomerWelcomeFlag : BmsCustomerFlag;

  get lateInvoice() : boolean {
    return this.selectedCustomerConfig?.invoiceDay >= 15;
  }
  set lateInvoice(value : boolean) {
    if (this.selectedCustomerConfig) {
      this.selectedCustomerConfig.invoiceDay = value ? 15 : 1;
    }
  }

  inparamModule: string = '';
  inparamNodeId: string = '';
  inparamNodeLabel: string = '';
  bmsSelectedPath: string[] = [];
  metersSelectedPath: string[] = [];

  selectedCustomerDataCollectorNodes: GrpcNode[] = [];

  bmsDataSourceInstance: GrpcDataSourceInstance;
  selectedBmsTreeNode: GrpcNode;
  selectedMeter: BmsMeter;
  showEditRealestates: boolean = false;
  realestateDataSourceInstance: GrpcDataSourceInstance;

  showEditNodeModal: boolean = false;
  dataCollectorsNodeTypes: GrpcNodeType[] = [];
  allNodeTypes: GrpcNodeType[] = [];

  gridDetailState: any = null;
  showGridEditModal: boolean = false;
  currentGridEditNode: GrpcNode;
  currentGridEditNodeId: string = '';
  gridResponsiveWidth: number = 834;
  overviewResponsiveWidth: number = 834;
  tariffsResponsiveWidth: number = 834;

  showViewGatewayMeters: boolean = false;
  gatewayFilter: BmsMeterAdminFilter;
  dataCollectorsDataSourceInstance: GrpcDataSourceInstance;
  selectedDataCollectorNode: GrpcNode;
  selectedDataCollectorNodeId: string;

  showSetBillingTariff: boolean = false;
  setBiilingTariffInfo: { allcustomerMeters: boolean, tariff: BmsBillingTariff, meterType: string, activeFromString: string };
  showDeleteBillingTariff: boolean = false;
  deleteBiilingTariffInfo: { allcustomerMeters: boolean, tariff: BmsBillingTariff, meterType: string, deleteFromString: string };
  showSetResidentExchange: boolean = false;
  setResidentExchangeInfo: { residentExchange: BmsResidentExchange, exchangeDateString: string };

  showSetMeterStateEvent: boolean = false;
  meterStateEventInfo: { node: XconfTreeNode, meterId: number, meterState: BmsMeterState, activeFrom: Date, activeFromString: string };

  searchValue: string = '';
  searching: boolean = false;

  geoLocationUpdateOverwrite: boolean = false;
  geoLocationState: ClrLoadingState = ClrLoadingState.DEFAULT;

  adminUsersExpanded : boolean = true;
  externalUsersExpanded : boolean = false;
  administraionUsersExpanded : boolean = false;

  showManualMeterReading : boolean = false;
  setManualMeterReadingInfo: {
    meterId : string,
    timestamp : Date,
    timestampString : string,
    value : number,
    unit : string,
    datapointUnit : string
  };

  showSetMeterExchange: boolean = false;
  setMeterExchangeInfo: {
    meterExchange: BmsMeterExchange,
    exchangeDateString: string,
    manufacturer: string,
    showManufacturer: boolean,
    identifier: string,
    meterType: string,
    unit?: string,
    datapointUnit?: string,
    oldMeterNode?: XconfTreeNode,
    gatewayNode?: GrpcNode,
    parentNode?: XconfTreeNode,
    lastExportedValue?: string
    lastExportedValueDate?: string
  };

  districts : string[] = bmsDistricts;
  showGridEditBuildingAddresses: boolean = false;
  setBuildingAddressesInfo: {
    postalCode : string,
    city : string,
    district : string
  }

  meterTypes: string[] = [];

  dashboardOverviewOutputParameters: DashboardOutputChangeParameters[] = [];
  dashboardGridDetailOutputParameters: DashboardOutputChangeParameters[] = [];

  customerGroupingDataSourceInstance: GrpcDataSourceInstance;
  customerGroupingSelectedPath: string[] = [];

  dashboardsDataSourceInstance: GrpcDataSourceInstance;
  eventSettingsDataSourceInstance: GrpcDataSourceInstance;

  showSetInvoiceInfo : boolean = false;
  invoiceInfos: BmsInvoiceInfo[];
  selectedInvoiceInfo : BmsInvoiceInfo;

  showXEdgeSelectMetersModal : boolean = false;
  xedgeRemoteNodeId : string;

  showCustomerConfigurationsModal : boolean = false;

  dropDownItems: DropDownItem[] = [
    {
      name: 'View in Meters',
      shape: 'two-way-arrows',
      nodeTypeLabel: '_x_bms_meter',
      onClick: this.onMeterViewInMeters.bind(this)
    },
    {
      name: 'Meter exchange...',
      shape: 'two-way-arrows',
      nodeTypeLabel: '_x_bms_meter',
      onClick: this.onMeterExchange.bind(this)
    },
    {
      name: 'Manual meter reading...',
      shape: 'two-way-arrows',
      nodeTypeLabel: '_x_bms_meter',
      onClick: this.onAddManualMeterReading.bind(this)
    },
    {
      name: 'Resident exchange...',
      shape: 'two-way-arrows',
      nodeTypeLabel: '_x_bms_apartment',
      onClick: this.onResidentExchange.bind(this)
    },
    {
      name: 'Touch apartments and facilities',
      shape: 'cursor-hand-open',
      nodeTypeLabel: '_x_bms_realestate',
      onClick: this.onTouchCustomerApartmentsAndFacilities.bind(this)
    },
  ];

  dropDownItemsMeters: DropDownItem[] = [
    {
      name: 'View in BMS',
      shape: 'two-way-arrows',
      nodeTypeLabel: '_x_bms_meter',
      onClick: this.onMeterViewInBMS.bind(this)
    },
    {
      name: 'Manual meter reading...',
      shape: 'two-way-arrows',
      nodeTypeLabel: '_x_bms_meter',
      onClick: this.onAddManualMeterReading.bind(this)
    },
    {
      name: 'Meter state event...',
      shape: 'two-way-arrows',
      nodeTypeLabel: '_x_bms_meter',
      onClick: this.onMeterStateEvent.bind(this)
    },
    {
      name: 'Touch meters',
      shape: 'cursor-hand-open',
      nodeTypeLabel: '_x_datacollectors_group',
      onClick: this.onTouchCustomerMeters.bind(this)
    },
    {
      name: 'Rerun errorflags on meter',
      shape: 'times',
      nodeTypeLabel: '_x_bms_meter',
      onClick: this.onResetErrorTimestampFrom.bind(this)
    },
    {
      name: 'Rerun errorflags on all meters',
      shape: 'times',
      nodeTypeLabel: '_x_datacollectors_group',
      onClick: this.onResetErrorTimestampFrom.bind(this)
    },
    {
      name: 'XEdge Config',
      shape: 'cog',
      nodeTypeId: '_x_xedge_server',
      onClick: this.viewXEdge.bind(this)
    },
    {
      name: 'Select meters...',
      shape: 'dashboard',
      nodeTypeId: '_x_xedge_server',
      onClick: this.xedgeSelectMeters.bind(this)
    }
  ];

  importMeterfile: any;
  importAparmtentfile: any;
  importDatafile: any;
  importMeterExportAddressesfile : any;
  importingState: ClrLoadingState = ClrLoadingState.DEFAULT;
  exportingState: ClrLoadingState = ClrLoadingState.DEFAULT;
  importResult: BmsMasterDataImportResult;

  orgNoFilter: string[] = [];
  orgNoFilterString: string = '';

  ClrLoadingState = ClrLoadingState;

  rightPanelWidth: number = 300;
  bmsRightPanelWidth: number = 300;
  meterRightPanelWidth : number = 300;

  _overviewActive: boolean = false;
  get overviewActive(): boolean {
    return this._overviewActive;
  }
  set overviewActive(value: boolean) {
    this._overviewActive = value;
    if (this._overviewActive) {

    }
  }

  _settingsActive: boolean = false;
  get settingsActive(): boolean {
    return this._settingsActive;
  }
  set settingsActive(value: boolean) {
    if (value) {
      this.updateSelectedCustomerConfig();
    }
    this._settingsActive = value;
  }

  _bmsActive: boolean = false;
  get bmsActive(): boolean {
    return this._bmsActive;
  }
  set bmsActive(value: boolean) {
    if (value != this._bmsActive) {
      this.updateSelectedCustomerConfig();
      this.updateSelectedCustomerRealestates();
      this._bmsActive = value;
    }
  }

  _gridActive: boolean = false;
  get gridActive(): boolean {
    return this._gridActive;
  }
  set gridActive(value: boolean) {
    if (value) {
      this.updateCustomerData();
    }
    this._gridActive = value;
  }

  _dataCollectorsActive: boolean = false;
  get dataCollectorsActive(): boolean {
    return this._dataCollectorsActive;
  }
  set dataCollectorsActive(value: boolean) {
    if (value != this._dataCollectorsActive) {
      this.updateSelectedCustomerDataCollectors();
      this._dataCollectorsActive = value;
    }
  }

  _customersListActive: boolean = true;
  get customersListActive(): boolean {
    return this._customersListActive;
  }
  set customersListActive(value: boolean) {
    this._customersListActive = value;
    if (this._customersListActive) {
      localStorage.setItem("xprojector-customers-admin-navigation", '0');
    }
  }

  _customersListHideAssociationId: boolean = true;
  get customersListHideAssociationId(): boolean {
    return this._customersListHideAssociationId;
  }
  set customersListHideAssociationId(value: boolean) {
    this._customersListHideAssociationId = value;
    localStorage.setItem("xprojector-customers-admin-customerslisthideassociationid", value ? '1' : '0');
  }

  _customersTreeActive: boolean = false;
  get customersTreeActive(): boolean {
    return this._customersTreeActive;
  }
  set customersTreeActive(value: boolean) {
    this._customersTreeActive = value;
    if (this._customersTreeActive) {
      localStorage.setItem("xprojector-customers-admin-navigation", '1');
      this.updateCustomerGroupingDatasource();
    }
  }

  _usersActive: boolean = false;
  get usersActive(): boolean {
    return this._usersActive;
  }
  set usersActive(value: boolean) {
    if (value) {

    }
    this._usersActive = value;
  }

  _exportImportActive: boolean = false;
  get exportImportActive(): boolean {
    return this._exportImportActive;
  }
  set exportImportActive(value: boolean) {
    if (value && value != this._exportImportActive) {
      this.updateViperDataImportEnabled();
    }
    this._exportImportActive = value;
  }

  _tariffGroupViewOpen: boolean = false;
  get tariffGroupViewOpen(): boolean {
    return this._tariffGroupViewOpen;
  }
  set tariffGroupViewOpen(value: boolean) {
    if (value) {

    }
    this._tariffGroupViewOpen = value;
  }

  _oldTariffViewOpen: boolean = false;
  get oldTariffViewOpen(): boolean {
    return this._oldTariffViewOpen;
  }
  set oldTariffViewOpen(value: boolean) {
    if (value) {
      this.tariffsResponsiveWidth = 100;
      setTimeout(() => {
        this.tariffsResponsiveWidth = this.overviewResponsiveWidth;
      });
    }
    this._oldTariffViewOpen = value;
  }

  viperDataImportEnabled: boolean = true;

  tariffsActive: boolean = false;
  customerTariffData : PageData;

  customerAdministrationData : PageData;

  BmsBillingPeriod = BmsBillingPeriod;
  BmsMonthOfYear = BmsMonthOfYear;
  BmsMeterState = BmsMeterState;

  constructor(
    private state: StateService,
    private bmsState: BmsStateService,
    private sysAdminClient: XProjectorSysAdminClient,
    private xconfClient: XProjectorXConfClient,
    private alertService: XprojAlertService,
    private modalService: XprojModalService,
    private tariffService: BmsTariffService,
    private residentExchangeService: BmsResidentExchangeService,
    private meterExchangeService: BmsMeterExchangeService,
    private dataExportService: BmsDataExportService,
    private adminService: BmsAdminService,
    private unitConversions: UnitConversionsService,
    private bmsExportClient: XProjectorBmsExportClient,
    private bmsMeterStateService: BmsMeterStateEventService,
    private sessionStorageService : SessionStorageService,
    private xedgeClient : XProjectorXEdgeClient,
    private bmsCustomerAdminClient: XProjectorBmsCustomerAdminClient,
    private cdr: ChangeDetectorRef,
    private logger: NGXLogger,
    public dateHelper: DateHelper,
    private route: ActivatedRoute) { }

  async ngOnInit() {
    this.route.params.pipe(map(p => p.id)).subscribe(async (id) => {
      if (id) {
        this.logger.info('ngOnInit customerId', id);
        this.inparamCustomerId = id;
      }
    });
    this.route.params.pipe(map(p => p.module)).subscribe(async (module) => {
      if (module) {
        this.inparamModule = module;
      }
    });
    this.route.params.pipe(map(p => p.nodeid)).subscribe(async (nodeid) => {
      if (nodeid) {
        this.inparamNodeId = nodeid;
      }
    });
    this.route.params.pipe(map(p => p.nodelabel)).subscribe(async (nodelabel) => {
      if (nodelabel) {
        this.inparamNodeLabel = nodelabel;
      }
    });

    let lsrightPanelWidth = Number.parseInt(localStorage.getItem("xprojector-customeradmin-bmsrightPanelWidth") || this.bmsRightPanelWidth.toString());
    if (lsrightPanelWidth != this.rightPanelWidth) {
      this.bmsRightPanelWidth = lsrightPanelWidth;
    }
    lsrightPanelWidth = Number.parseInt(localStorage.getItem("xprojector-customeradmin-rightPanelWidth") || this.rightPanelWidth.toString());
    if (lsrightPanelWidth != this.rightPanelWidth) {
      this.rightPanelWidth = lsrightPanelWidth;
    }
    lsrightPanelWidth = Number.parseInt(localStorage.getItem("xprojector-customeradmin-meterrightPanelWidth") || this.meterRightPanelWidth.toString());
    if (lsrightPanelWidth != this.meterRightPanelWidth) {
      this.meterRightPanelWidth = lsrightPanelWidth;
    }

    let customersNavigation = Number.parseInt(localStorage.getItem("xprojector-customers-admin-navigation") || '0');
    this._customersListActive = customersNavigation == 0;
    this._customersTreeActive = !this._customersListActive;
    let hideAssociationId = Number.parseInt(localStorage.getItem("xprojector-customers-admin-customerslisthideassociationid") || '1');
    this._customersListHideAssociationId = hideAssociationId > 0;
    this._customersListActive = customersNavigation == 0;

    this.cdr.detectChanges();

    if (!this.inparamCustomerId) {
      let lastCustomerId = localStorage.getItem("xprojector-customers-admin-lastcustomerid");
      if (lastCustomerId) {
        this.logger.info('last customerId', lastCustomerId);
        this.inparamCustomerId = lastCustomerId;
      }
    }

    await this.getCustomers();

    await this.checkInparams();

    await this.updateWidth();
  }

  @HostListener('window:resize', ['$event'])
  onResize(event) {
    this.updateWidth();
  }

  async checkInparams() {
    if (this.inparamModule == 'bms') {
      this.bmsSelectedPath = await this.xconfClient.getShortestPath('_x_bms_realestates_root_' + this.inparamCustomerId, '_x_bms_realestates_root', this.inparamNodeId, this.inparamNodeLabel, 10);
      if (this.bmsSelectedPath.length > 1) {
        this.bmsSelectedPath = this.bmsSelectedPath.slice(1);
      }

      // delay so Angular doesn't complain
      setTimeout(() => {
        this.tabs.tabLinkDirectives[TABINDEX_BMS].activate();
        this.logger.info('BMS Tab Active');
        this.bmsActive = true;
        this.inparamModule = '';
      });
    }
    else if (this.inparamModule == 'meters') {
      this.metersSelectedPath = await this.xconfClient.getShortestPath('_x_bms_datacollectors_root_' + this.inparamCustomerId, '_x_bms_datacollectors_root', this.inparamNodeId, this.inparamNodeLabel, 10);
      if (this.metersSelectedPath.length > 1) {
        this.metersSelectedPath = this.metersSelectedPath.slice(1);
      }

      // delay so Angular doesn't complain
      setTimeout(() => {
        this.tabs.tabLinkDirectives[TABINDEX_METERS].activate();
        this.logger.info('Meters Tab Active');
        this.dataCollectorsActive = true;
        this.inparamModule = '';
      });
    }
    else if (this.inparamModule == 'users') {
      // delay so Angular doesn't complain
      setTimeout(() => {
        this.tabs.tabLinkDirectives[TABINDEX_USERS].activate();
        this.logger.info('Users Tab Active');
        this.usersActive = true;
        this.inparamModule = '';
      });
    }
  }

  async onBmsSplitDragEnd($event) {
    let treePaneArea = this.getPaneArea(12);

    if (treePaneArea) {
      this.bmsRightPanelWidth = treePaneArea.elRef.nativeElement.clientWidth;
      localStorage.setItem("xprojector-customeradmin-bmsrightPanelWidth", this.bmsRightPanelWidth.toString());
    }
  }

  async onMeterSplitDragEnd($event) {
    let treePaneArea = this.getPaneArea(22);

    if (treePaneArea) {
      this.meterRightPanelWidth = treePaneArea.elRef.nativeElement.clientWidth;
      localStorage.setItem("xprojector-customeradmin-meterrightPanelWidth", this.meterRightPanelWidth.toString());
    }

    this.bmsDataCollectorView?.setWidth(this.meterRightPanelWidth);
  }

  async updateWidth() {
    await this.onSplitDragEnd();
  }

  async onSplitDragEnd() {
    let treePaneArea = this.getPaneArea(2);

    if (treePaneArea) {
      this.rightPanelWidth = treePaneArea.elRef.nativeElement.clientWidth;
      localStorage.setItem("xprojector-customeradmin-rightPanelWidth", this.rightPanelWidth.toString());
    }

    let mainPaneArea = this.getPaneArea(1);

    if (mainPaneArea) {
      this.gridResponsiveWidth = ((mainPaneArea.elRef.nativeElement.clientWidth * 2) / 3) - 75;
      this.overviewResponsiveWidth = mainPaneArea.elRef.nativeElement.clientWidth;
    }
  }

  getPaneArea(order: number): SplitAreaDirective {
    let result: SplitAreaDirective = undefined;
    this.areasEl.forEach(area => {
      if (area.order == order) {
        result = area;
      }
    });

    return result;
  }

  async getCustomers() {
    try {
      this.loadingCustomers = true;
      this.customers = await this.sysAdminClient.getCustomers(false);
      this.bmsCustomers = await this.adminService.getCustomers();
      if (!this.showDisabledCustomers) {
        this.bmsCustomers = this.bmsCustomers.filter(x => !x.disabled);
      }
      if (this.inparamCustomerId?.length > 0) {
        this.selectedBmsCustomer = this.bmsCustomers.find(customer => customer.customerId == this.inparamCustomerId);
        this.selectedCustomer = this.customers.find(customer => customer.id == this.inparamCustomerId);
        this.selectedCustomerData = new BmsCustomerData();
        if (this.selectedBmsCustomer) {
          this.selectedCustomerData.customerId = this.selectedBmsCustomer.customerId;
          this.selectedCustomerTrustees = await this.getSelectedCustomerTrustees();
          this.updateSelectedCustomerConfig();
        }

        this.updateSelectedCustomerOutputs();
      }
    }
    finally {
      this.loadingCustomers = false;
    }
  }

  async getSelectedCustomerTrustees(): Promise<Customer[]> {
    let result: Customer[] = [];
    let nodes = await this.xconfClient.getRefereringNodes(this.selectedBmsCustomer.customerId, '_x_datasource', [], ['_x_bms_trustee_root'], 2);

    nodes.forEach(node => {
      let trustee = this.customers.find(c => node.id.includes(c.id));
      if (trustee) {
        result.push(trustee);
      }
    });

    return result;
  }

  updateSelectedCustomerOutputs() {
    this.dashboardOverviewOutputParameters = [];
    if (this.selectedBmsCustomer) {
      let bmsCustomer = this.bmsCustomers.find(bmsCustomer => bmsCustomer.customerId == this.selectedBmsCustomer.customerId);

      let out = new DashboardOutputChangeParameters();
      out.outputParameterName = 'customerid';
      out.value = this.selectedBmsCustomer.customerId;
      out.datatype = OutputDataType.String;
      this.dashboardOverviewOutputParameters.push(out);

      if (bmsCustomer) {
        out = new DashboardOutputChangeParameters();
        out.outputParameterName = 'customerxdbid';
        out.value = bmsCustomer.id;
        out.datatype = OutputDataType.Int64;
        this.dashboardOverviewOutputParameters.push(out);

        out = new DashboardOutputChangeParameters();
        out.outputParameterName = 'customername';
        out.value = bmsCustomer.customerName;
        out.datatype = OutputDataType.String;
        this.dashboardOverviewOutputParameters.push(out);
      }
    }
  }

  async updateSelectedCustomerConfig() {
    if (this.allNodeTypes.length == 0) {
      this.allNodeTypes = await this.xconfClient.getNodeTypes();
    }

    if (this.selectedBmsCustomer && !this.selectedCustomerConfig) {
      this.selectedCustomerConfig = await this.adminService.getCustomerConfig(this.selectedBmsCustomer.customerId, this.allNodeTypes);
      this.lateInvoice = this.selectedCustomerConfig.invoiceDay >= 15;
      this.selectedCustomerWelcomeFlag = await this.adminService.getCustomerFlag(this.selectedBmsCustomer.customerId, 'welcomemail');
    }

    if (this.selectedBmsCustomer && !this.dashboardsDataSourceInstance) {
      this.dashboardsDataSourceInstance = await this.xconfClient.getDataSourceInstance('_x_bms_dashboards_' + this.selectedBmsCustomer.customerId);
    }

    if (this.selectedBmsCustomer && !this.eventSettingsDataSourceInstance) {
      this.eventSettingsDataSourceInstance = await this.xconfClient.getDataSourceInstance('_x_bms_events_' + this.selectedBmsCustomer.customerId);
    }

    if (!this.realestateDataSourceInstance) {
      this.realestateDataSourceInstance = await this.xconfClient.getDataSourceInstance('_x_bms_realestates_' + this.selectedBmsCustomer.customerId);
    }

    if (this.selectedBmsCustomer && !this.invoiceInfos) {
      this.invoiceInfos = await this.adminService.getInvoiceInfos(this.selectedBmsCustomer.customerId);
    }
  }

  async updateSelectedCustomerRealestates() {
    if (this.selectedBmsCustomer && this.selectedCustomerRealestateNodes.length == 0) {
      this.selectedCustomerRealestateNodes = await this.xconfClient.getReferencedNodes('_x_bms_realestates_root_' + this.selectedBmsCustomer.customerId, '_x_bms_realestates_root', [], '', 1);

      if (!this.bmsDataSourceInstance) {
        this.bmsDataSourceInstance = await this.xconfClient.getDataSourceInstance('_x_bms_datasource');
      }

      if (!this.realestateDataSourceInstance) {
        this.realestateDataSourceInstance = await this.xconfClient.getDataSourceInstance('_x_bms_realestates_' + this.selectedBmsCustomer.customerId);
      }
    }
  }

  async updateSelectedCustomerDataCollectors() {
    if (this.selectedBmsCustomer) {
      let request = new SearchNodesRequest();
      request.rootId = '_x_bms_datacollectors_root_' + this.selectedBmsCustomer.customerId;
      request.rootLabel = '_x_bms_datacollectors_root';
      request.limit = 100;
      request.maxHops = 10;
      request.skip = 0;
      request.label = '_x_datacollectors_group';

      let searchResult = await this.xconfClient.searchNodes(request);

      this.selectedCustomerDataCollectorNodes = searchResult.nodes;

      if (!this.dataCollectorsDataSourceInstance) {
        this.dataCollectorsDataSourceInstance = await this.xconfClient.getDataSourceInstance('_x_datacollectors_datasource');
      }
      if (this.dataCollectorsDataSourceInstance && this.dataCollectorsNodeTypes.length == 0) {
        let dataSourceDefinition = await this.xconfClient.getDataSourceDefinition(this.dataCollectorsDataSourceInstance.dataSourceDefinitionId, true);
        if (this.allNodeTypes.length == 0) {
          this.allNodeTypes = await this.xconfClient.getNodeTypes();
        }

        this.dataCollectorsNodeTypes = this.allNodeTypes.filter(nt => {
          let result = false;
          dataSourceDefinition.treeNodeDefinitions.forEach(t => {
            if (t.treeNodeReferenceDefinitions.findIndex(tref => tref.nodeTypeId == nt.id) > -1) {
              result = true;
            }
          })
          return result;
        });
      }
    }
  }

  async updateCustomerGroupingDatasource() {
    if (!this.customerGroupingDataSourceInstance) {
      this.customerGroupingDataSourceInstance = await this.xconfClient.getDataSourceInstance('_x_bms_customergrouping');
      let pathString = localStorage.getItem("xprojector-customers-admin-navigation-path") || '';
      this.customerGroupingSelectedPath = pathString.split(',');
    }

  }

  async updateViperDataImportEnabled() {
    if (this.selectedBmsCustomer) {
      this.viperDataImportEnabled = false;
      let request = new SearchNodesRequest();
      request.rootId = '_x_bms_datacollectors_root_' + this.selectedBmsCustomer.customerId;
      request.rootLabel = '_x_bms_datacollectors_root';
      request.limit = 100;
      request.maxHops = 10;
      request.skip = 0;
      request.label = '_x_datacollectors_group';

      let searchResult = await this.xconfClient.searchNodes(request);

      await ArrayUtils.AsyncForEach(searchResult.nodes, async (node) => {
        let request = new SearchNodesRequest();
        request.rootId = node.id;
        request.rootLabel = node.nodeTypeLabel;
        request.limit = 100;
        request.maxHops = 10;
        request.skip = 0;
        request.label = '_x_datacollectors_collector';

        let searchResult = await this.xconfClient.searchNodes(request);

        searchResult.nodes.forEach(node => {
          let propValue = node.propertyValues.find(v => v.key == 'model');
          if (propValue) {
            if (propValue.value.toLocaleLowerCase() == 'viper') {
              this.viperDataImportEnabled = true;
            }
          }
        });

      });
    }
  }

  async selectedCustomerChanged() {
    this.updateSelectedCustomerOutputs();

    if (this.inparamModule.length == 0) {
      this.overviewActive = true;
    }

    this.settingsActive = false;
    this.bmsActive = false;
    this.dataCollectorsActive = false;

    this.selectedMeter = null;
    this.dashboardsDataSourceInstance = null;
    this.eventSettingsDataSourceInstance = null;
    this.realestateDataSourceInstance = null;

    this.selectedCustomerConfig = null;
    this.selectedCustomerRealestateNodes = [];
    this.selectedCustomerDataCollectorNodes = [];
    this.bmsSelectedPath = [];
    this.metersSelectedPath = [];
    this.searchValue = '';
    this.invoiceInfos = null;

    if (this.selectedBmsCustomer) {
      localStorage.setItem("xprojector-customers-admin-lastcustomerid", this.selectedBmsCustomer?.customerId);
    }

    this.selectedCustomerData = new BmsCustomerData();
    if (this.selectedBmsCustomer) {
      this.selectedCustomer = this.customers.find(c => c.id == this.selectedBmsCustomer.customerId);
      this.selectedCustomerData.customerId = this.selectedBmsCustomer.customerId;
      this.selectedCustomerTrustees = await this.getSelectedCustomerTrustees();
      this.customerTariffData = new PageData();
      this.customerTariffData.customerId = this.selectedCustomer.id;

      this.customerAdministrationData = new PageData();
      this.customerAdministrationData.customerId = this.selectedCustomer.id;

      this.updateSelectedCustomerConfig();
    }
  }

  async removeSelectedCustomer() {
    if (this.selectedBmsCustomer) {
      this.showRemoveCustomer = true;
    }
  }

  async doRemoveSelectedCustomer() {
    if (this.selectedBmsCustomer) {
      let result = await this.sysAdminClient.deleteCustomer(this.selectedBmsCustomer.customerId);
      if (result.result) {
        try {
          await this.adminService.deleteCustomerConfig(this.selectedCustomer.id);
        }
        catch {}

        this.customers = this.customers.filter(c => c.id != this.selectedBmsCustomer.customerId);
        this.bmsCustomers = this.bmsCustomers.filter(c => c.customerId != this.selectedBmsCustomer.customerId);
        this.selectedCustomer = null;
        this.selectedBmsCustomer = null;
        this.selectedCustomerTrustees = [];
        this.selectedCustomerData = new BmsCustomerData();
        this.alertService.success('Customer deleted.');
        this.cdr.detectChanges();
      } else {
        this.alertService.error(result.message);
      }
    }
  }

  async saveSelectedCustomer() {
    if (!this.selectedCustomer?.isEnabled) {
      this.modalService.ShowConfirmModal({ header: 'Disable customer', description: 'Disable customer will inactivate all customer meters. Continue?' }, async (result) => {
        if (result) {
          await this._saveSelectedCustomer();
        }
      });
    }
    else {
      await this._saveSelectedCustomer();
    }
  }

  private async _saveSelectedCustomer() {
    if (this.selectedCustomer) {
      let modifyResult = await this.sysAdminClient.modifyCustomer(this.selectedCustomer);
      if (modifyResult.result) {
        this.alertService.success('Customer updated.');
        await this.saveSelectedCustomerConfig();
        this.selectedCustomerConfig.disabled = !this.selectedCustomer.isEnabled;
        this.selectedBmsCustomer.disabled = !this.selectedCustomer.isEnabled;
      } else {
        this.alertService.error(modifyResult.message);
      }
    }
  }

  async saveSelectedCustomerConfig() {
    if (this.selectedCustomerConfig) {
      let result = await this.adminService.saveCustomerConfig(this.selectedCustomerConfig);

      if (result.result) {
        if (this.selectedCustomer && this.selectedCustomerConfig.organisationNumber != this.selectedCustomer.externalId) {
          this.selectedCustomer.externalId = this.selectedCustomerConfig.organisationNumber;
          await this.sysAdminClient.modifyCustomer(this.selectedCustomer);
        }
        this.alertService.success('Customer settings updated.');
      } else {
        this.alertService.error(result.message);
      }
    }
  }

  async onTreeNodeSelect(item: { treeNode: XconfTreeNode, nodeType: GrpcNodeType }) {
    this.selectedMeter = null;
    if (item.treeNode.node.nodeTypeLabel == '_x_bms_meter') {
      let parent: XconfTreeNode = item.treeNode.parent as XconfTreeNode;
      this.selectedBmsTreeNode = parent.node;
    }
    else {
      this.selectedBmsTreeNode = item.treeNode.node;

      if (item.treeNode.node.nodeTypeLabel == '_x_bms_apartment') {
        this.onSelectApartment(item.treeNode.node);
      }
    }

    this.cdr.detectChanges();
    this.bmsMeterList?.search();
  }

  async onDataCollectorsTreeNodeSelect(item: { treeNode: XconfTreeNode, nodeType: GrpcNodeType }) {
    this.bmsDataCollectorView?.setDataNode(item.treeNode.node, this.meterRightPanelWidth, this.selectedBmsCustomer, this.selectedCustomer);

    // this.cdr.detectChanges();
    // this.bmsMeterList?.search();
  }

  async onSelectMeter(meter: BmsMeter) {
    this.selectedMeter = meter;
    if (this.selectedMeter && this.selectedMeter.id) {
      this.cdr.detectChanges();
      await this.billingTariffView.update(this.selectedMeter.id, this.selectedCustomerConfig.tariffDecimalCount);
    }

  }

  async onSelectApartment(node: GrpcNode) {
    this.selectedMeter = null;
    setTimeout(async () => {
      await this.residentExchangeView.update(+node.id, '_x_bms_apartment', this.selectedBmsCustomer.customerId);
    });
  }

  async addMeter() {
    this.alertService.error('Not implemented!');
  }

  async deleteSelectedMeter() {
    if (this.selectedMeter) {
      this.alertService.error('Not implemented!');
    }
  }

  async editRealestates() {
    if (this.selectedBmsCustomer) {
      this.showEditRealestates = true;
    }
  }

  async onCloseEditRealestates() {
    this.showEditRealestates = false;
    this.selectedCustomerRealestateNodes = await this.xconfClient.getReferencedNodes('_x_bms_realestates_root_' + this.selectedBmsCustomer.customerId, '_x_bms_realestates_root', [], '', 1);
  }

  async omEditSelectedDataCollectorNode() {
    if (this.selectedDataCollectorNode) {
      this.selectedDataCollectorNodeId = this.selectedDataCollectorNode.id;
      this.showEditNodeModal = true;
    }
  }

  async omViewMetersSelectedDataCollectorNode() {
    if (this.selectedDataCollectorNode) {
      this.gatewayFilter = new BmsMeterAdminFilter();
      this.gatewayFilter.gatewayIds.push(+this.selectedDataCollectorNode.id);
      this.showViewGatewayMeters = true;
      this.cdr.detectChanges();
      this.gatewayMeterList.search();
    }
  }

  async updateNode(node: GrpcNode, oldId: string): Promise<boolean> {
    let result = await this.xconfClient.updateNode(node, oldId, '', this.selectedBmsCustomer?.customerId);
    return result.result;
  }

  async saveEditNode() {
    if (this.editTreeNode) {
      this.editTreeNode.savePropertyValues();
      let result = await this.updateNode(this.selectedDataCollectorNode, this.selectedDataCollectorNodeId);
      if (result) {
        this.alertService.info('Node updated.');
      }
      else {
        this.alertService.error('Error update node!');
      }
    }

    this.showEditNodeModal = false;
  }

  closeEditNode() {
    this.showEditNodeModal = false;
  }

  private getTariffMeterFilter(treeNode: XconfTreeNode): BmsBillingTariff {
    let tariff = new BmsBillingTariff();

    if (treeNode) {
      tariff.realestateId = +BmsDataUtils.getParamterValue(treeNode.node, 'realestateid', this.dateHelper);
      tariff.buildingId = +BmsDataUtils.getParamterValue(treeNode.node, 'buildingid', this.dateHelper);
      tariff.buildingAddressId = +BmsDataUtils.getParamterValue(treeNode.node, 'buildingaddressid', this.dateHelper);
      tariff.apartmentId = +BmsDataUtils.getParamterValue(treeNode.node, 'apartmentid', this.dateHelper);
      tariff.facilityId = +BmsDataUtils.getParamterValue(treeNode.node, 'facilityid', this.dateHelper);
      //let meterId = +BmsDataUtils.getParamterValue(treeNode.node, 'meterid');

      switch (treeNode.node.nodeTypeId) {
        case '_x_bms_realestate':
          tariff.realestateId = +treeNode.node.id;
          break;
        case '_x_bms_building':
          tariff.buildingId = +treeNode.node.id;
          break;
        case '_x_bms_buildingaddress':
          tariff.buildingAddressId = +treeNode.node.id;
          break;
        case '_x_bms_apartment':
          tariff.apartmentId = +treeNode.node.id;
          break;
        case '_x_bms_facility':
          tariff.facilityId = +treeNode.node.id;
          break;
      }
    }

    return tariff;
  }

  async onSetNewBillingTariff(treeNode: XconfTreeNode) {
    if (this.meterTypes.length == 0) {
      this.meterTypes = await this.adminService.getMeterTypes();
    }
    let tariff = this.getTariffMeterFilter(treeNode);

    tariff.value = 0;
    tariff.vatRate = 0.2;
    tariff.includeVat = false;
    tariff.activeFrom = new Date();
    tariff.currency = 'SEK';

    this.setBiilingTariffInfo = { allcustomerMeters: treeNode == null, tariff: tariff, meterType: '', activeFromString: this.dateHelper.utils.formatByString(tariff.activeFrom, 'yyyy-MM-dd') };
    this.showSetBillingTariff = true;
  }

  async onExecuteSetBillingTariff() {
    try {
      if (this.setBiilingTariffInfo.tariff?.value >= 0 && this.setBiilingTariffInfo.meterType?.length > 0) {
        this.setBiilingTariffInfo.tariff.activeFrom = this.dateHelper.utils.parse(this.setBiilingTariffInfo.activeFromString, 'yyyy-MM-dd');

        this.setBiilingTariffInfo.tariff.activeFrom = this.dateHelper.utils.addMinutes(this.setBiilingTariffInfo.tariff.activeFrom, -this.setBiilingTariffInfo.tariff.activeFrom.getTimezoneOffset());

        let result: { result: boolean, meterCount: number };

        if (this.setBiilingTariffInfo.allcustomerMeters) {
          result = await this.tariffService.setNewBillingTariffs({ customerId: this.selectedBmsCustomer.customerId, meterType: this.setBiilingTariffInfo.meterType }, [this.setBiilingTariffInfo.tariff]);
        }
        else {
          result = await this.tariffService.setNewBillingTariffs({
            realestateId: this.setBiilingTariffInfo.tariff.realestateId,
            buildingId: this.setBiilingTariffInfo.tariff.buildingId,
            buildingAddressId: this.setBiilingTariffInfo.tariff.buildingAddressId,
            apartmentId: this.setBiilingTariffInfo.tariff.apartmentId,
            facilityId: this.setBiilingTariffInfo.tariff.facilityId,
            //meterId : meterId,
            meterType: this.setBiilingTariffInfo.meterType
          }, [this.setBiilingTariffInfo.tariff]);
        }
        if (result.result) {
          await this.bmsExportClient.touchMeters(this.selectedBmsCustomer.customerId, this.setBiilingTariffInfo.meterType);
          await this.bmsExportClient.touchApartmentsAndFacilities(this.selectedBmsCustomer.customerId);
          //this.alertService.info('Billing tariffs updated ok, ' + result.meterCount + ' meter tariffs updated.');
          this.modalService.ShowConfirmModal({ header: 'Tariff changes', description: 'Billing tariffs updated ok, ' + result.meterCount + ' meter tariffs updated. It could take up to a minute before tables are updated!', showCancel: false }, (result) => { });
        }
        else {
          this.alertService.error('Error update billing tariffs.');
          this.modalService.ShowConfirmModal({ header: 'Tariff changes error', description: 'Error update billing tariffs.', showCancel: false }, (result) => { });
        }
      }
      else {
        this.alertService.warn('No tariff or metertype selected.');
      }
    }
    catch (err) {
      this.alertService.error(err);
    }
  }

  async onDeleteBillingTariffs(treeNode: XconfTreeNode) {
    if (this.meterTypes.length == 0) {
      this.meterTypes = await this.adminService.getMeterTypes();
    }

    let tariff = this.getTariffMeterFilter(treeNode);

    this.deleteBiilingTariffInfo = { allcustomerMeters: treeNode == null, tariff: tariff, meterType: '', deleteFromString: this.dateHelper.utils.formatByString(new Date(), 'yyyy-MM-dd') };
    this.showDeleteBillingTariff = true;
  }

  async onExecuteDeletetBillingTariff() {
    try {
      if (this.deleteBiilingTariffInfo) {
        let deleteFrom = this.dateHelper.utils.parse(this.deleteBiilingTariffInfo.deleteFromString, 'yyyy-MM-dd');

        let result: { result: boolean, meterCount: number };
        if (this.deleteBiilingTariffInfo.allcustomerMeters) {
          result = await this.tariffService.deleteBillingTariffs({
            customerId: this.selectedBmsCustomer.customerId,
            meterType: this.deleteBiilingTariffInfo.meterType
          }, deleteFrom);
        }
        else {
          result = await this.tariffService.deleteBillingTariffs({
            realestateId: this.deleteBiilingTariffInfo.tariff.realestateId,
            buildingId: this.deleteBiilingTariffInfo.tariff.buildingId,
            buildingAddressId: this.deleteBiilingTariffInfo.tariff.buildingAddressId,
            apartmentId: this.deleteBiilingTariffInfo.tariff.apartmentId,
            facilityId: this.deleteBiilingTariffInfo.tariff.facilityId,
            meterType: this.deleteBiilingTariffInfo.meterType
          }, deleteFrom);
        }

        if (result.result) {
          //this.alertService.info(result.meterCount + ' meter tariffs deleted.');
          this.modalService.ShowConfirmModal({ header: 'Tariff delete', description: result.meterCount + ' meter tariffs deleted. It could take up to a minute before tables are updated!', showCancel: false }, (result) => { });
        }
        else {
          this.alertService.error('Error delete billing tariffs.');
        }
      }
    }
    catch (err) {
      this.alertService.error(err);
    }
  }

  async onResidentExchange(treeNode: XconfTreeNode) {
    if (treeNode && treeNode.node) {
      if (treeNode.node.nodeTypeId == '_x_bms_apartment') {
        var residentExchange = new BmsResidentExchange();

        residentExchange.exchangeDate = new Date();
        residentExchange.apartmentId = +treeNode.node.id;
        residentExchange.facilityId = 0;

        this.setResidentExchangeInfo = { residentExchange: residentExchange, exchangeDateString: this.dateHelper.utils.formatByString(residentExchange.exchangeDate, 'yyyy-MM-dd') };
        this.showSetResidentExchange = true;
      }
      else {
        this.alertService.error('Only apartment nodes allowed.');
      }
    }
  }

  async onExecuteSetResidentExchange() {
    try {
      if (this.setResidentExchangeInfo) {
        this.setResidentExchangeInfo.residentExchange.exchangeDate = this.dateHelper.utils.parse(this.setResidentExchangeInfo.exchangeDateString, 'yyyy-MM-dd');

        this.setResidentExchangeInfo.residentExchange.exchangeDate = this.dateHelper.utils.addMinutes(this.setResidentExchangeInfo.residentExchange.exchangeDate, -this.setResidentExchangeInfo.residentExchange.exchangeDate.getTimezoneOffset());

        this.setResidentExchangeInfo.residentExchange.customerId = this.selectedBmsCustomer.customerId;
        this.setResidentExchangeInfo.residentExchange.createdAt = new Date();
        this.setResidentExchangeInfo.residentExchange.modifiedAt = new Date();
        this.setResidentExchangeInfo.residentExchange.deleted = false;

        let result = await this.residentExchangeService.setResidentExchanges([this.setResidentExchangeInfo.residentExchange]);

        if (result) {
          this.alertService.info('Resident exchange set ok.');
          this.residentExchangeView?.update(+this.setResidentExchangeInfo.residentExchange.apartmentId, '_x_bms_apartment', this.selectedBmsCustomer.customerId);
        }
        else {
          this.alertService.error('Error set resident exchange.');
        }
      }
    }
    catch (err) {
      this.alertService.error(err);
    }
  }

  async onAddManualMeterReading(treeNode : XconfTreeNode) {
    if (treeNode && treeNode.node) {
      if (treeNode.node.nodeTypeLabel == '_x_bms_meter') {


        this.setManualMeterReadingInfo = {
          meterId : treeNode.node.id,
          timestamp : new Date(),
          timestampString : this.dateHelper.utils.formatByString(new Date(), "yyyy-MM-dd'T'HH:mm:ss"),
          value : -1,
          unit : treeNode.node.propertyValues.find(p => p.key == 'unit').value,
          datapointUnit: treeNode.node.propertyValues.find(p => p.key == 'datapointvalueunit').value
         };
        this.showManualMeterReading = true;
      }
      else {
        this.alertService.error('Only meter nodes allowed.');
      }
    }
  }

  async onExecuteAddManualMeterReading() {
    try {
      if (this.setManualMeterReadingInfo) {
        let timestamp = this.dateHelper.utils.parse(this.setManualMeterReadingInfo.timestampString, "yyyy-MM-dd'T'HH:mm:ss");
        if (!this.dateHelper.utils.isValid(timestamp)) {
          timestamp = this.dateHelper.utils.parse(this.setManualMeterReadingInfo.timestampString, "yyyy-MM-dd'T'HH:mm");
          if (!this.dateHelper.utils.isValid(timestamp)) {
            timestamp = this.dateHelper.utils.parse(this.setManualMeterReadingInfo.timestampString, "yyyy-MM-dd");
          }
        }

        this.setManualMeterReadingInfo.timestamp = timestamp;// this.dateHelper.utils.addMinutes(timestamp, -timestamp.getTimezoneOffset());
        this.setManualMeterReadingInfo.value = await this.unitConversions.unitConversionToDerived(this.setManualMeterReadingInfo.value, this.setManualMeterReadingInfo.unit, this.setManualMeterReadingInfo.datapointUnit);

        let result = await this.bmsExportClient.addDatapointValue(this.setManualMeterReadingInfo.meterId, this.setManualMeterReadingInfo.timestamp, this.setManualMeterReadingInfo.value);

        if (result) {
          this.alertService.info('Manual meterreading added ok.');
          await this.bmsExportClient.touchApartmentsAndFacilities(this.selectedBmsCustomer?.customerId);
          await this.metersDatasourceInstance?.refresh();
        }
        else {
          this.alertService.error('Error add manual meter reading.');
        }
      }
    }
    catch (err) {
      this.alertService.error(err);
    }
  }

  async onMeterExchange(treeNode: XconfTreeNode) {
    if (treeNode && treeNode.node) {
      if (treeNode.node.nodeTypeId == '_x_bms_mbus_meter' || treeNode.node.nodeTypeId == '_x_lorawan_meter') {
        var meterExchange = new BmsMeterExchange();

        meterExchange.exchangeDate = new Date();
        meterExchange.oldMeterId = +treeNode.node.id;
        meterExchange.newMeterStartValue = 0;

        let gatewayNodes = await this.xconfClient.getRefereringNodes(treeNode.node.id, treeNode.node.nodeTypeLabel, [], ['_x_datacollectors_collector'], 1);

        let lastExportedValue = await this.dataExportService.getLastDataExportValue(this.selectedBmsCustomer.customerId, meterExchange.oldMeterId);

        this.setMeterExchangeInfo = {
          meterExchange: meterExchange,
          exchangeDateString: this.dateHelper.utils.formatByString(meterExchange.exchangeDate, 'yyyy-MM-dd'),
          manufacturer: '', //treeNode.node.propertyValues.find(p => p.key == 'manufacturer')?.value,
          showManufacturer: treeNode.node.nodeTypeId == '_x_bms_mbus_meter',
          identifier: '',
          meterType : treeNode.node.propertyValues.find(p => p.key == 'metertype')?.value,
          oldMeterNode: treeNode,
          gatewayNode: gatewayNodes.length > 0 ? gatewayNodes[0] : undefined,
          parentNode: treeNode.parent as XconfTreeNode,
          lastExportedValue: lastExportedValue != null ? lastExportedValue.endValue.toFixed(this.selectedCustomerConfig.reportDataPointDecimalCount) + ' ' + lastExportedValue.unit : '',
          lastExportedValueDate: lastExportedValue != null ? this.dateHelper.utils.formatByString(this.dateHelper.utils.addSeconds(lastExportedValue.end, -1), 'yyyy-MM-dd') : '',
          unit: treeNode.node.propertyValues.find(p => p.key == 'unit').value,
          datapointUnit: treeNode.node.propertyValues.find(p => p.key == 'datapointvalueunit').value
        };

        this.showSetMeterExchange = true;
      }
      else {
        this.alertService.error('Only meter nodes allowed.');
      }
    }
  }

  async onExecuteSetMeterExchange() {
    try {
      //if (this.setMeterExchangeInfo.identifier.length == 8 && this.setMeterExchangeInfo.manufacturer.length == 3) {
        //Add new meter

        //Meter already added
        let request = new SearchNodesRequest();
        request.rootId = this.selectedCustomer.id;// this.setMeterExchangeInfo.gatewayNode.id;
        request.rootLabel = '_x_datacollectors_group'; //this.setMeterExchangeInfo.gatewayNode.nodeTypeLabel;
        request.limit = 10;
        request.label = '_x_bms_meter';
        request.maxHops = -1;
        request.skip = 0;
        request.propertiesOperatorAnd = false;

        let idSearchProperty = new SearchProperty();
        idSearchProperty.typeName = 'string';
        idSearchProperty.key = 'seconadaryaddress';
        idSearchProperty.value = this.setMeterExchangeInfo.identifier;
        idSearchProperty.operator = SearchProperty.SearchPropertyOperator.Equals;
        request.properties.push(idSearchProperty);

        let idLoRaWANSearchProperty = new SearchProperty();
        idLoRaWANSearchProperty.typeName = 'string';
        idLoRaWANSearchProperty.key = 'deveui';
        idLoRaWANSearchProperty.value = this.setMeterExchangeInfo.identifier.toLowerCase();
        idLoRaWANSearchProperty.operator = SearchProperty.SearchPropertyOperator.Equals;
        request.properties.push(idLoRaWANSearchProperty);

        // let manSearchProperty = new SearchProperty();
        // manSearchProperty.typeName = 'string';
        // manSearchProperty.key = 'manufacturer';
        // manSearchProperty.value = this.setMeterExchangeInfo.manufacturer;
        // manSearchProperty.operator = SearchProperty.SearchPropertyOperator.Equals;
        // request.properties.push(manSearchProperty);

        let searchResult = await this.xconfClient.searchNodes(request)

        let newNode: GrpcNode = searchResult.nodes.find(node => {
          let meterType = node.propertyValues.find(p => p.key == 'metertype')?.value;
          return meterType == this.setMeterExchangeInfo.meterType;
        });

        if (!newNode) {
          if (searchResult.nodes.length > 0) {
            this.alertService.error('Metertype on new meter doesn´t match.');
          }
          else if (this.setMeterExchangeInfo.identifier.length == 8 && this.setMeterExchangeInfo.manufacturer.length == 3) {
            let oldManufacturer = this.setMeterExchangeInfo.oldMeterNode.node.propertyValues.find(p => p.key == 'manufacturer').value
            let oldIdentifier = this.setMeterExchangeInfo.oldMeterNode.node.propertyValues.find(p => p.key == 'seconadaryaddress').value
            this.setMeterExchangeInfo.oldMeterNode.node.propertyValues.find(p => p.key == 'manufacturer').value = this.setMeterExchangeInfo.manufacturer;
            this.setMeterExchangeInfo.oldMeterNode.node.propertyValues.find(p => p.key == 'seconadaryaddress').value = this.setMeterExchangeInfo.identifier;

            let result = await this.xconfClient.createReferencedNode(this.setMeterExchangeInfo.oldMeterNode.node, this.setMeterExchangeInfo.gatewayNode.id,
              this.setMeterExchangeInfo.gatewayNode.nodeTypeLabel, '_x_bms_mbus_hasmeter', false, null, '', this.selectedBmsCustomer?.customerId);

            this.setMeterExchangeInfo.oldMeterNode.node.propertyValues.find(p => p.key == 'manufacturer').value = oldManufacturer;
            this.setMeterExchangeInfo.oldMeterNode.node.propertyValues.find(p => p.key == 'seconadaryaddress').value = oldIdentifier;
            if (result.result) {
              newNode = result.node;

              let tariffs = await this.tariffService.getBillingTariffs({ meterId: +this.setMeterExchangeInfo.oldMeterNode.id });
              if (tariffs.length > 0) {
                //tariffs.sort((a, b) => a.activeFrom > b.activeFrom ? 1 : 0);
                await this.tariffService.setNewBillingTariffs({ meterId: +newNode.id }, tariffs);
              }
            }
          }
          else {
            this.alertService.error('Incorrect identifier or manufacturer.');
          }
        }

        if (newNode) {
          this.setMeterExchangeInfo.oldMeterNode.node.propertyValues.find(p => p.key == 'state').value = BmsMeterState[BmsMeterState.InactiveReplaced];
          await this.xconfClient.updateNode(this.setMeterExchangeInfo.oldMeterNode.node, this.setMeterExchangeInfo.oldMeterNode.node.id, '', this.selectedBmsCustomer?.customerId);

          if (this.setMeterExchangeInfo.parentNode.children.findIndex(child => child.id == newNode.id) < 0) {
            await this.xconfClient.createReference(this.setMeterExchangeInfo.parentNode.node.id, this.setMeterExchangeInfo.parentNode.node.nodeTypeLabel,
              newNode.id, newNode.nodeTypeLabel, '_x_bms_hasmeter', true, null, '', this.selectedBmsCustomer?.customerId);

            let treenode = new XconfTreeNode();
            treenode.node = newNode;
            treenode.id = newNode.id;
            treenode.parent = this.setMeterExchangeInfo.parentNode;
            treenode.edgeType = this.setMeterExchangeInfo.oldMeterNode.edgeType;
            treenode.name = await this.bmsConfigurationDatasource?.getNodeDisplayName(newNode);
            treenode.shape = this.setMeterExchangeInfo.oldMeterNode.shape;
            this.setMeterExchangeInfo.parentNode.children.push(treenode);
          }

          this.setMeterExchangeInfo.parentNode.expanded = !this.setMeterExchangeInfo.parentNode.expanded;
          let parent = this.setMeterExchangeInfo.parentNode;

          setTimeout(() => {
            parent.expanded = !parent.expanded;
          });

          this.setMeterExchangeInfo.meterExchange.newMeterId = +newNode.id;
          this.setMeterExchangeInfo.meterExchange.exchangeDate = this.dateHelper.utils.parse(this.setMeterExchangeInfo.exchangeDateString, 'yyyy-MM-dd');

          this.setMeterExchangeInfo.meterExchange.exchangeDate = this.dateHelper.utils.addMinutes(this.setMeterExchangeInfo.meterExchange.exchangeDate, -this.setMeterExchangeInfo.meterExchange.exchangeDate.getTimezoneOffset());

          this.setMeterExchangeInfo.meterExchange.customerId = this.selectedBmsCustomer.customerId;
          this.setMeterExchangeInfo.meterExchange.createdAt = new Date();
          this.setMeterExchangeInfo.meterExchange.modifiedAt = new Date();
          this.setMeterExchangeInfo.meterExchange.deleted = false;

          this.setMeterExchangeInfo.meterExchange.newMeterStartValue = await this.unitConversions.unitConversionToDerived(this.setMeterExchangeInfo.meterExchange.newMeterStartValue, this.setMeterExchangeInfo.unit, this.setMeterExchangeInfo.datapointUnit);
          this.setMeterExchangeInfo.meterExchange.oldMeterEndValue = await this.unitConversions.unitConversionToDerived(this.setMeterExchangeInfo.meterExchange.oldMeterEndValue, this.setMeterExchangeInfo.unit, this.setMeterExchangeInfo.datapointUnit);

          let addMeterExchangeResult = await this.meterExchangeService.setMeterExchanges([this.setMeterExchangeInfo.meterExchange]);

          if (addMeterExchangeResult) {
            if (this.setMeterExchangeInfo.meterExchange.oldMeterEndValue > 0) {
              await this.bmsExportClient.addDatapointValue(this.setMeterExchangeInfo.meterExchange.oldMeterId + '', this.setMeterExchangeInfo.meterExchange.exchangeDate, this.setMeterExchangeInfo.meterExchange.oldMeterEndValue);
            }
            this.alertService.success('Meter exchange ok.');
          }
          else {
            this.alertService.error('Error set meter exchange.');
          }
        }

      // }
      // else {
      //   this.alertService.error('Incorrect identifier or manufacturer.');
      // }
    }
    catch (err) {
      this.alertService.error(err);
    }
  }

  async onMeterViewInBMS(treeNode: XconfTreeNode) {
    await this.viewInBms(treeNode.id);
  }

  async onMeterViewInMeters(treeNode: XconfTreeNode) {
    await this.viewInMeters(treeNode.id);
  }

  async viewInBms(meterId: string) {
    this.bmsSelectedPath = await this.xconfClient.getShortestPath('_x_bms_realestates_root_' + this.selectedBmsCustomer.customerId, '_x_bms_realestates_root', meterId, '_x_bms_meter', 10);
    if (this.bmsSelectedPath.length > 1) {
      this.bmsSelectedPath = this.bmsSelectedPath.slice(1);
    }
    if (this.bmsConfigurationDatasource) {
      this.bmsConfigurationDatasource.selectedPath = this.bmsSelectedPath;
      this.bmsConfigurationDatasource.initDataSourceInstance(false, false);
    }
    this.bmsActive = true;
    this.tabs.tabLinkDirectives[TABINDEX_BMS].activate();

  }

  async viewInMeters(meterId: string) {
    this.metersSelectedPath = await this.xconfClient.getShortestPath('_x_bms_datacollectors_root_' + this.selectedBmsCustomer.customerId, '_x_bms_datacollectors_root', meterId, '_x_bms_meter', 10);
    if (this.metersSelectedPath.length > 1) {
      this.metersSelectedPath = this.metersSelectedPath.slice(1);
    }
    if (this.metersDatasourceInstance) {
      this.metersDatasourceInstance.selectedPath = this.metersSelectedPath;
      this.metersDatasourceInstance.initDataSourceInstance(false, false);
    }
    this.dataCollectorsActive = true;
    this.tabs.tabLinkDirectives[TABINDEX_METERS].activate();
  }

  async onCustomerGroupingTreeNodeSelect(item: { treeNode: XconfTreeNode, nodeType: GrpcNodeType }) {
    if (item.treeNode.node.nodeTypeLabel == '_x_datasource') {
      this.selectedBmsCustomer = this.bmsCustomers.find(customer => customer.customerId == item.treeNode.node.id);
      this.selectedCustomer = this.customers.find(customer => customer.id == item.treeNode.node.id);
      this.selectedCustomerData = new BmsCustomerData();
      if (this.selectedBmsCustomer) {
        this.selectedCustomerData.customerId = this.selectedBmsCustomer.customerId;
        this.selectedCustomerTrustees = await this.getSelectedCustomerTrustees();

        let path: string[] = [];
        let treeNode = item.treeNode;
        while (treeNode.parent) {
          path.unshift(treeNode.node.id);
          treeNode = treeNode.parent as XconfTreeNode;
        }
        path.unshift(treeNode.node.id);

        localStorage.setItem("xprojector-customers-admin-navigation-path", path.join(','));

        this.selectedCustomerChanged();
      }
    }
  }

  async updateCustomerData(forceReload: boolean = false) {
    if (this.selectedCustomerData?.customerId?.length > 0) {
      try {
        this.loadingMeterData = true;
        if (forceReload || this.selectedCustomerData.id <= 0) {
          let customer = await this.adminService.getCustomer(this.selectedCustomerData.customerId, forceReload);
          if (customer) {
            this.selectedCustomerData.id = customer.id;
          }
        }

        if (this.selectedCustomerData.id > 0 && (forceReload || this.selectedCustomerData.realestates.length == 0)) {
          this.selectedCustomerData.realestates = await this.adminService.getRealestates(this.selectedCustomerData.id, forceReload);
          this.selectedCustomerData.buildings = await this.adminService.getBuildings(this.selectedCustomerData.id, forceReload);
          this.selectedCustomerData.buildingAddresses = await this.adminService.getBuildingAddresses(this.selectedCustomerData.id, forceReload);
          this.selectedCustomerData.apartments = await this.adminService.getApartments(this.selectedCustomerData.id, forceReload);
          this.selectedCustomerData.facilities = await this.adminService.getFacilities(this.selectedCustomerData.id, forceReload);
          this.selectedCustomerData.gateways = await this.adminService.getGateways(this.selectedCustomerData.customerId);
          this.selectedCustomerData.meters = await this.adminService.getMeters(this.selectedCustomerData.id, !this.showInactiveMeters, forceReload);
          this.selectedCustomerData.systems = await this.adminService.getSystems(this.selectedCustomerData.customerId, forceReload);

          this.selectedCustomerData.meterDatas = [];
          this.selectedCustomerData.meters.forEach(meter => {
            let meterData = new BmsMeterData();
            meterData.meterId = meter.id;
            meterData.realestateId = meter.realestateId;
            meterData.buildingId = meter.buildingId;
            meterData.buildingAddressId = meter.buildingAddressId;
            meterData.apartmentId = meter.apartmentId;
            meterData.facilityId = meter.facilityId;
            meterData.gatewayId = meter.gatewayId;
            meterData.systemId1 = meter.systemId1;
            meterData.systemId2 = meter.systemId2;
            meterData.systemId3 = meter.systemId3;

            let realestate = this.selectedCustomerData.realestates.find(r => r.id == meterData.realestateId);
            if (realestate) {
              meterData.svlantPropertyDesignation = realestate.svlantPropertyDesignation;
            }

            let building = this.selectedCustomerData.buildings.find(b => b.id == meterData.buildingId);
            if (building) {
              meterData.svLantBuildingNo = building.svLantBuildingNo;
            }

            let buildingAddress = this.selectedCustomerData.buildingAddresses.find(b => b.id == meterData.buildingAddressId);
            if (buildingAddress) {
              meterData.street = buildingAddress.street;
              meterData.housenumber = buildingAddress.housenumber;
              meterData.postalcode = buildingAddress.postalcode;
              meterData.city = buildingAddress.city;
              meterData.district = buildingAddress.district;
            }

            let apartment = this.selectedCustomerData.apartments.find(a => a.id == meterData.apartmentId);
            if (apartment) {
              meterData.svlantApartmentno = apartment.svlantApartmentno;
              meterData.externalId = apartment.externalId;
              meterData.name = apartment.externalId;
              meterData.prefix = apartment.prefix;
              meterData.area = apartment.area;
              meterData.size = apartment.size;
            }

            let facility = this.selectedCustomerData.facilities.find(f => f.id == meterData.facilityId);
            if (facility) {
              meterData.facilityType = facility.facilityType;
              meterData.externalId = facility.externalId;
              meterData.name = facility.name;
              meterData.prefix = facility.prefix;
              meterData.area = facility.area;
              meterData.size = facility.size;
            }

            let gateway = this.selectedCustomerData.gateways.find(g => g.id == meterData.gatewayId);
            if (gateway) {
              meterData.gw_serialnumber = gateway.serialnumber;
              meterData.gw_vendor = gateway.vendor;
            }

            if (meterData.systemId1 > 0) {
              let system = this.selectedCustomerData.systems.find(a => a.id == meterData.systemId1);
              if (system) {
                meterData.system1 = system.name;
              }
            }

            if (meterData.systemId2 > 0) {
              let system = this.selectedCustomerData.systems.find(a => a.id == meterData.systemId2);
              if (system) {
                meterData.system2 = system.name;
              }
            }

            if (meterData.systemId3 > 0) {
              let system = this.selectedCustomerData.systems.find(a => a.id == meterData.systemId3);
              if (system) {
                meterData.system3 = system.name;
              }
            }

            meterData.seconadaryAddress = meter.identifier;
            meterData.manufacturer = meter.manufacturer;
            meterData.unit = meter.unit;
            meterData.datapointValueUnit = meter.datapointValueUnit;
            meterData.variable = meter.variable;
            meterData.index = meter.index;
            meterData.state = BmsMeterState[meter.state];
            meterData.meterType = meter.meterType;
            meterData.tariffGroup = meter.tariffGroup;
            meterData.meterSubtype = meter.meterSubtype;

            this.selectedCustomerData.meterDatas.push(meterData);
          });
        }
      }
      finally {
        this.loadingMeterData = false;
      }
    }
  }

  async onGridRefresh() {
    this.updateCustomerData(true);
  }

  async onGridEditMeters() {
    if (this.selectedMeterDatas?.length == 1) {
      let meterData = this.selectedMeterDatas[0];

      let meterNode = await this.xconfClient.getNode(meterData.meterId + '', '_x_bms_meter');
      if (meterNode) {
        if (this.allNodeTypes.length == 0) {
          this.allNodeTypes = await this.xconfClient.getNodeTypes();
        }

        this.currentGridEditNodeId = meterNode.id;
        this.currentGridEditNode = meterNode;
        this.showGridEditModal = true;
      }
    }
  }

  async onGridEditViewInBMS() {
    let meterData = this.selectedMeterDatas[0];
    if (meterData) {
      await this.viewInBms(meterData.meterId + '');
    }
  }

  async onGridEditRealestates() {
    if (this.selectedMeterDatas?.length == 1) {
      let meterData = this.selectedMeterDatas[0];

      let realestateNode = await this.xconfClient.getNode(meterData.realestateId + '', '_x_bms_realestate');
      if (realestateNode) {
        if (this.allNodeTypes.length == 0) {
          this.allNodeTypes = await this.xconfClient.getNodeTypes();
        }

        this.currentGridEditNodeId = realestateNode.id;
        this.currentGridEditNode = realestateNode;
        this.showGridEditModal = true;
      }
    }
  }

  async onGridEditBuildings() {
    if (this.selectedMeterDatas?.length == 1) {
      let meterData = this.selectedMeterDatas[0];

      let buildingNode = await this.xconfClient.getNode(meterData.buildingId + '', '_x_bms_building');
      if (buildingNode) {
        if (this.allNodeTypes.length == 0) {
          this.allNodeTypes = await this.xconfClient.getNodeTypes();
        }

        this.currentGridEditNodeId = buildingNode.id;
        this.currentGridEditNode = buildingNode;
        this.showGridEditModal = true;
      }
    }
  }

  async onGridEditBuildingAddresses() {
    if (this.selectedMeterDatas?.length == 1) {
      let meterData = this.selectedMeterDatas[0];

      let buildingAddressNode = await this.xconfClient.getNode(meterData.buildingAddressId + '', '_x_bms_buildingaddress');
      if (buildingAddressNode) {
        if (this.allNodeTypes.length == 0) {
          this.allNodeTypes = await this.xconfClient.getNodeTypes();
        }

        this.currentGridEditNodeId = buildingAddressNode.id;
        this.currentGridEditNode = buildingAddressNode;
        this.showGridEditModal = true;
      }
    }
  }

  async onGridEditApartmentsOrFacilities() {
    if (this.selectedMeterDatas?.length == 1) {
      let meterData = this.selectedMeterDatas[0];

      let node = await this.xconfClient.getNode(
        (meterData.apartmentId > 0 ? meterData.apartmentId : meterData.facilityId) + '',
        meterData.apartmentId > 0 ? '_x_bms_apartment' : '_x_bms_facility');
      if (node) {
        if (this.allNodeTypes.length == 0) {
          this.allNodeTypes = await this.xconfClient.getNodeTypes();
        }

        this.currentGridEditNodeId = node.id;
        this.currentGridEditNode = node;
        this.showGridEditModal = true;
      }
    }
  }

  async onGridExport() {
    this.selectedCustomerData.meterDatas.sort((a: BmsMeterData, b: BmsMeterData) => {
      if (a && b && a.externalId && b.externalId) {
        return a.externalId.padStart(10, '0') > b.externalId.padStart(10, '0') ? 1 : -1;
      }
      else {
        return a ? 1 : -1
      }
    });

    await this.adminService.exportToExcel(this.selectedCustomerData.meterDatas, this.selectedBmsCustomer.customerName + '.xlsx');
  }

  async saveGridEditNode() {
    if (this.editGridNode) {
      this.editGridNode.savePropertyValues();
      let result = await this.updateNode(this.currentGridEditNode, this.currentGridEditNodeId);
      if (result) {
        this.alertService.info('Node updated.');
        this.updateCustomerData(true);
      }
      else {
        this.alertService.error('Error update node!');
      }
    }

    this.showGridEditModal = false;
  }

  async onGridDetailChange(meterData: BmsMeterData) {
    this.dashboardGridDetailOutputParameters = [];
    if (meterData != null) {
      let out = new DashboardOutputChangeParameters();
      out.outputParameterName = 'Id';
      out.value = meterData.meterId;
      out.datatype = OutputDataType.Int64;
      this.dashboardGridDetailOutputParameters.push(out);
    }
  }

  closeGridEditNode() {
    this.showGridEditModal = false;
  }

  async search() {
    try {
      await this.updateSelectedCustomerRealestates();
      await ArrayUtils.AsyncForEach(this.selectedCustomerRealestateNodes, async (realestateNode: GrpcNode) => {
        this.searching = true;
        let request = new SearchNodesRequest();
        request.rootId = realestateNode.id;
        request.rootLabel = realestateNode.nodeTypeLabel;
        request.propertiesOperatorAnd = false;
        request.limit = 10;
        request.maxHops = -1;
        request.skip = 0;

        let idSearchProperty = new SearchProperty();
        idSearchProperty.typeName = 'string';
        idSearchProperty.key = '_id';
        idSearchProperty.value = this.searchValue;
        idSearchProperty.operator = SearchProperty.SearchPropertyOperator.Equals;
        request.properties.push(idSearchProperty);

        let searchMeterProperty = new SearchProperty();
        searchMeterProperty.typeName = 'string';
        searchMeterProperty.key = 'seconadaryaddress';
        searchMeterProperty.value = this.searchValue;
        searchMeterProperty.operator = SearchProperty.SearchPropertyOperator.Equals;
        request.properties.push(searchMeterProperty);

        let searchApartmentProperty = new SearchProperty();
        searchApartmentProperty.typeName = 'string';
        searchApartmentProperty.key = 'externalid';
        searchApartmentProperty.value = this.searchValue;
        searchApartmentProperty.operator = SearchProperty.SearchPropertyOperator.Equals;
        request.properties.push(searchApartmentProperty);

        let searchResult = await this.xconfClient.searchNodes(request)

        if (searchResult.nodes.length > 0) {
          let node = searchResult.nodes[0];

          this.bmsSelectedPath = await this.xconfClient.getShortestPath(realestateNode.id, realestateNode.nodeTypeLabel, node.id, node.nodeTypeLabel, 10);
          if (this.bmsSelectedPath.length > 1) {
            //this.bmsSelectedPath = this.bmsSelectedPath.slice(1);
          }

          if (this.bmsConfigurationDatasource) {
            this.bmsConfigurationDatasource.selectedPath = this.bmsSelectedPath;
            await this.bmsConfigurationDatasource.initDataSourceInstance(false, false);
          }

          this.tabs.tabLinkDirectives[TABINDEX_BMS].activate();
          this.bmsActive = true;
        }
      });
    }
    finally {
      this.searching = false;
    }
  }

  async geoLocationUpdate() {
    try {
      this.geoLocationState = ClrLoadingState.LOADING;
      let result = await this.sysAdminClient.updateGeoLocations(this.selectedBmsCustomer.customerId, this.geoLocationUpdateOverwrite);
      if (result) {
        this.alertService.success('Geo location updated ok.');
        this.geoLocationState = ClrLoadingState.SUCCESS;
      }
      else {
        this.alertService.error('Error update all geo locations');
        this.geoLocationState = ClrLoadingState.ERROR;
      }
    }
    catch {
      this.geoLocationState = ClrLoadingState.ERROR;
    }

  }

  async exportMeters() {
    try {
      this.exportingState = ClrLoadingState.LOADING;

      if (this.selectedBmsCustomer) {
        await this.bmsExportClient.createMeterExportFile(this.selectedBmsCustomer.customerId, this.selectedBmsCustomer.customerName)
      }
    }
    finally {
      this.exportingState = ClrLoadingState.SUCCESS;
    }
  }

  async exportApartmentsAndFacilities() {
    try {
      this.exportingState = ClrLoadingState.LOADING;

      if (this.selectedBmsCustomer) {
        await this.bmsExportClient.createApartmentAndFacilityExportFile(this.selectedBmsCustomer.customerId, this.selectedBmsCustomer.customerName)
      }
    }
    finally {
      this.exportingState = ClrLoadingState.SUCCESS;
    }
  }

  async exportMeterExportAddresses() {
    try {
      this.exportingState = ClrLoadingState.LOADING;

      if (this.selectedBmsCustomer) {
        await this.bmsExportClient.createMeterExportAddressesExportFile(this.selectedBmsCustomer.customerId, this.selectedBmsCustomer.customerName)
      }
    }
    finally {
      this.exportingState = ClrLoadingState.SUCCESS;
    }
  }

  uploadMeterExportAddressesFile(importmeterexportaddressesfile) {
    if (importmeterexportaddressesfile.files.length > 0) {
      this.importMeterExportAddressesfile = importmeterexportaddressesfile.files[0];
    }
  }


  uploadMeterFile(file) {
    if (file.files.length > 0) {
      this.importMeterfile = file.files[0];
    }
  }

  uploadApartmentFile(aptfile) {
    if (aptfile.files.length > 0) {
      this.importAparmtentfile = aptfile.files[0];
    }
  }

  uploadDataFile(datafile) {
    if (datafile.files.length > 0) {
      this.importDatafile = datafile.files[0];
    }
  }

  async importMeterFiles() {
    if (this.selectedBmsCustomer && this.importMeterfile) {
      try {
        this.importingState = ClrLoadingState.LOADING;

        this.importResult = await this.bmsState.importMeterDataFile(this.importMeterfile, this.selectedBmsCustomer.customerId);

        if (this.importResult.ok) {
          this.modalService.ShowConfirmModal({
            header: 'Meter import',
            description: ['Meter imported ok.',
              ' Meters count: ' + this.importResult.meterCount,
              ' Meters updated: ' + this.importResult.metersUpdated,
              ' Meters moved: ' + this.importResult.metersReferencedChanged,
              ' Meters added: ' + this.importResult.metersAdded,
              ' Meters deleted: ' + this.importResult.metersDeleted]
            , showCancel: false
          }, (result) => { });
          this.importingState = ClrLoadingState.SUCCESS;
        }
        else {
          let errorMessages: string[] = ['Meter import error!'];
          if (this.importResult.message?.length > 0) {
            errorMessages.push(this.importResult.message);
          }
          this.importResult.customerErrors.forEach(ce => {
            errorMessages.push(ce.errorMessage);
          });
          //this.alertService.error('Error import file:', this.importResult.message);
          this.modalService.ShowConfirmModal({
            header: 'Meter import',
            description: errorMessages, showCancel: false
          }, (result) => { });
          this.importingState = ClrLoadingState.ERROR;
        }
      }
      catch (err) {
        this.alertService.error('Error import file:', err);
        this.importingState = ClrLoadingState.ERROR;
        this.importResult = new BmsMasterDataImportResult();
        this.importResult.ok = false;
        this.importResult.message = err.message;
      }
    }
  }

  async importApartmentFiles() {
    if (this.selectedBmsCustomer && this.importAparmtentfile) {
      try {
        this.importingState = ClrLoadingState.LOADING;

        this.importResult = await this.bmsState.importApartmentDataFile(this.importAparmtentfile, this.selectedBmsCustomer.customerId);

        if (this.importResult.ok) {
          this.modalService.ShowConfirmModal({
            header: 'Apartment/Facility import',
            description: ['Meter imported ok.',
              ' Apartment count: ' + this.importResult.apartmentCount,
              ' Apartments updated: ' + this.importResult.apartmentsUpdated,
              ' Apartments moved: ' + this.importResult.apartmentsMoved,
              ' Apartments added: ' + this.importResult.metersAdded,
              ' Facility count: ' + this.importResult.facilityCount,
              ' Facility updated: ' + this.importResult.facilityUpdated,
              ' Facility moved: ' + this.importResult.facilityMoved,
              ' Facility added: ' + this.importResult.facilityAdded]
            , showCancel: false
          }, (result) => { });
          this.importingState = ClrLoadingState.SUCCESS;
        }
        else {
          let errorMessages: string[] = ['Apartment import error!'];
          this.importResult.customerErrors.forEach(ce => {
            errorMessages.push(ce.errorMessage);
          });
          //this.alertService.error('Error import file:', this.importResult.message);
          this.modalService.ShowConfirmModal({
            header: 'Apartment import',
            description: errorMessages, showCancel: false
          }, (result) => { });
          this.importingState = ClrLoadingState.ERROR;
        }
      }
      catch (err) {
        this.alertService.error('Error import file:', err);
        this.importingState = ClrLoadingState.ERROR;
        this.importResult = new BmsMasterDataImportResult();
        this.importResult.ok = false;
        this.importResult.message = err.message;
      }
    }
  }

  async importMeterExportAddressesFiles() {
    if (this.selectedBmsCustomer && this.importMeterExportAddressesfile) {
      try {
        this.importingState = ClrLoadingState.LOADING;

        this.importResult = await this.bmsState.importMeterExportAddressesFile(this.importMeterExportAddressesfile, this.selectedBmsCustomer.customerId);

        if (this.importResult.ok) {
          this.modalService.ShowConfirmModal({
            header: 'Meter Export Addresses import',
            description: ['Import ok.',
              ' Created count: ' + this.importResult.metersAdded,
              ' Updated updated: ' + this.importResult.metersUpdated]
            , showCancel: false
          }, (result) => { });
          this.importingState = ClrLoadingState.SUCCESS;
        }
        else {
          let errorMessages: string[] = ['Import error!'];
          this.importResult.customerErrors.forEach(ce => {
            errorMessages.push(ce.errorMessage);
          });
          //this.alertService.error('Error import file:', this.importResult.message);
          this.modalService.ShowConfirmModal({
            header: 'Meter Export Addresses import',
            description: errorMessages, showCancel: false
          }, (result) => { });
          this.importingState = ClrLoadingState.ERROR;
        }
      }
      catch (err) {
        this.alertService.error('Error import file:', err);
        this.importingState = ClrLoadingState.ERROR;
        this.importResult = new BmsMasterDataImportResult();
        this.importResult.ok = false;
        this.importResult.message = err.message;
      }
    }
  }

  async importDataFiles() {
    if (this.selectedBmsCustomer && this.importDatafile) {
      try {
        this.importingState = ClrLoadingState.LOADING;

        let count = await this.bmsState.importViperDataFile(this.importDatafile, this.importDatafile.name, this.selectedBmsCustomer.customerId);

        this.modalService.ShowConfirmModal({
          header: 'Data import',
          description: ['Data imported ok.',
            ' DataPoints imported: ' + count]
          , showCancel: false
        }, (result) => { });
        this.importingState = ClrLoadingState.SUCCESS;
      }
      catch (err) {
        this.alertService.error('Error import file:', err);
        this.importingState = ClrLoadingState.ERROR;
        this.importResult = new BmsMasterDataImportResult();
        this.importResult.ok = false;
        this.importResult.message = err.message;
      }
    }
  }

  async onMeterStateEvent(treeNode: XconfTreeNode) {
    if (treeNode && treeNode.node) {
      if (treeNode.node.nodeTypeLabel == '_x_bms_meter') {
        let activeFrom = new Date();
        this.meterStateEventInfo = { node: treeNode, meterId: +treeNode.node.id, meterState: BmsMeterState.Inactive, activeFrom: activeFrom, activeFromString: this.dateHelper.utils.formatByString(activeFrom, 'yyyy-MM-dd') };
        this.showSetMeterStateEvent = true;
      }
      else {
        this.alertService.error('Only meter nodes allowed.');
      }
    }
  }

  async onTouchCustomerMeters(treeNode: XconfTreeNode) {
    if (this.selectedBmsCustomer) {
      let result = await this.bmsExportClient.touchMeters(this.selectedBmsCustomer.customerId);
      if (result.ok) {
        this.alertService.success(result.meterCount + ' meters touched.');
      }
    }
  }

  async onResetErrorTimestampFrom(treeNode: XconfTreeNode) {
    if (treeNode && treeNode.node) {
      if (treeNode.node.nodeTypeLabel == '_x_bms_meter') {
        let request = new BmsErrmeterTimestampfrom();

        request.meterid = +treeNode.node.id;
        request.status = 1;
        request.status2 = 1;
        request.timestampFrom = this.dateHelper.utils.addYears(new Date(), -100);
        let result = await this.bmsMeterStateService.setErrMeterTimestampFrom([request]);

        if (result) {
          this.alertService.success('Run errorcorrection on all values queued.');
        }
      }
      else if (treeNode.node.nodeTypeLabel == '_x_datacollectors_group') {
        if (this.selectedCustomerData.id < 0) {
          let customer = await this.adminService.getCustomer(this.selectedCustomerData.customerId, true);
          if (customer) {
            this.selectedCustomerData.id = customer.id;
          }
        }

        let meters = await this.adminService.getMeters(this.selectedCustomerData.id, false, true);
        let timestampfrom = this.dateHelper.utils.addYears(new Date(), -100);
        let requests : BmsErrmeterTimestampfrom[] = [];
        meters.forEach(meter => {
          let request = new BmsErrmeterTimestampfrom();

          request.meterid = meter.id;
          request.status = 1;
          request.status2 = 1;
          request.timestampFrom = timestampfrom;

          requests.push(request);
        });
        let result = await this.bmsMeterStateService.setErrMeterTimestampFrom(requests);

        if (result) {
          this.alertService.success('Run errorcorrection on all customer meters queued.');
        }
      }
      else {
        //this.alertService.error('Only meter nodes allowed.');
      }
    }
  }

  async onTouchCustomerApartmentsAndFacilities(treeNode: XconfTreeNode) {
    if (this.selectedBmsCustomer) {
      let result = await this.bmsExportClient.touchApartmentsAndFacilities(this.selectedBmsCustomer.customerId);
      if (result.ok) {
        this.alertService.success(result.nodeCount + ' apartments and facilities touched.');
      }
    }
  }

  async onExecuteSetMeterStateEvent() {
    if (this.meterStateEventInfo) {
      this.meterStateEventInfo.activeFrom = this.dateHelper.utils.parse(this.meterStateEventInfo.activeFromString, 'yyyy-MM-dd');
      this.meterStateEventInfo.activeFrom = this.dateHelper.utils.addMinutes(this.meterStateEventInfo.activeFrom, -this.meterStateEventInfo.activeFrom.getTimezoneOffset());

      let meterStateEvent = new BmsMeterStateEvent();
      meterStateEvent.meterId = this.meterStateEventInfo.meterId;
      meterStateEvent.activeFrom = this.meterStateEventInfo.activeFrom;
      meterStateEvent.createdAt = new Date();
      meterStateEvent.modifiedAt = new Date();
      meterStateEvent.deleted = false;

      let result = await this.bmsMeterStateService.setMeterStateEvent([meterStateEvent]);

      if (result) {
        //touch meter
        let p = this.meterStateEventInfo.node.node.propertyValues.find(p => p.key == 'modifiedat');
        if (p) {
          let d = new Date();
          p.value = BmsDataUtils.getPropertyValue(p.valueType, this.dateHelper.utils.addMinutes(d, -d.getTimezoneOffset()), this.dateHelper);
        }
        await this.xconfClient.updateNode(this.meterStateEventInfo.node.node, this.meterStateEventInfo.node.node.id, '', this.selectedBmsCustomer?.customerId);

        this.alertService.info('Meter state set ok.');
      }
      else {
        this.alertService.error('Error set meter state event.');
      }
    }
  }

  async addInvoiceInfo(invoiceDisabled : boolean = false) {
    if (this.meterTypes.length == 0) {
      this.meterTypes = await this.adminService.getMeterTypes();
    }

    if (this.selectedBmsCustomer) {
      this.selectedInvoiceInfo = new BmsInvoiceInfo();
      this.selectedInvoiceInfo.customerId = this.selectedBmsCustomer.customerId;
      this.selectedInvoiceInfo.activeFrom = new Date();
      this.selectedInvoiceInfo.activeFromString = this.dateHelper.utils.formatByString(this.selectedInvoiceInfo.activeFrom, 'yyyy-MM-dd');
      this.selectedInvoiceInfo.invoiceDisabled = invoiceDisabled;

      this.showSetInvoiceInfo = true;
    }
  }

  async editInvoiceInfo(invoiceInfo: BmsInvoiceInfo) {
    if (this.meterTypes.length == 0) {
      this.meterTypes = await this.adminService.getMeterTypes();
    }

    this.selectedInvoiceInfo = invoiceInfo;
    this.selectedInvoiceInfo.activeFromString = this.dateHelper.utils.formatByString(this.selectedInvoiceInfo.activeFrom, 'yyyy-MM-dd');
    this.showSetInvoiceInfo = true;
  }

  async saveSelectedInvoiceInfo() {
    if (this.selectedBmsCustomer && this.selectedInvoiceInfo) {
      this.selectedInvoiceInfo.activeFrom = this.dateHelper.utils.parse(this.selectedInvoiceInfo.activeFromString, 'yyyy-MM-dd');
      this.selectedInvoiceInfo.activeFrom = this.dateHelper.utils.addMinutes(this.selectedInvoiceInfo.activeFrom, -this.selectedInvoiceInfo.activeFrom.getTimezoneOffset());
      if (await this.adminService.setDataInvoiceInfos([this.selectedInvoiceInfo])) {
        this.invoiceInfos = await this.adminService.getInvoiceInfos(this.selectedBmsCustomer.customerId);
      }
    }
  }

  async deleteInvoiceInfo(invoiceInfo: BmsInvoiceInfo, start : boolean = true) {
    if (this.selectedBmsCustomer && invoiceInfo) {
      let description = start ? 'Delete start invoice info, are you sure?' : 'Delete stop invoice info, are you sure?'
      this.modalService.ShowConfirmModal({ header: 'Delete start invoice', description: description }, async (result) => {
        if (result) {
          invoiceInfo.deleted = true;
          invoiceInfo.deletedAt = new Date();
          await this.adminService.setDataInvoiceInfos([invoiceInfo]);

          this.invoiceInfos = this.invoiceInfos.filter(i => i.id != invoiceInfo.id);
        }
      });

    }
  }

  async usersCustomerUserSelected(customerUser : CustomerUser) {
    this.externalCustomers?.setCustomerUser(customerUser);
  }

  async onOverviewDashboardValueChanged(parameters: LinkedWidgetSelectParameters) {
    this.logger.debug(parameters);

    let meterIdCol = parameters.values.find(x => x.columnname == 'MeterId');
    if (meterIdCol) {
      this.searchValue = meterIdCol.value;
      this.search();
    }
  }

  showEditBuildingAddresses() {
    let city : string = '';
    let district : string = '';
    let postalCode : string = '';

    if (this.selectedCustomerData?.meterDatas?.length > 0) {
      let meterData = this.selectedCustomerData.meterDatas.find(m => m.city?.length > 0);
      if (meterData) {
        city = meterData.city;
        district = meterData.district;
        postalCode = meterData.postalcode;
      }
    }

    this.setBuildingAddressesInfo = {city : city, district : district, postalCode : postalCode};
    this.showGridEditBuildingAddresses = true;
  }

  async executeSetBuildingAddresses() {
    if (this.setBuildingAddressesInfo) {
      let buildingAddressNodes = await this.xconfClient.getReferencedNodes('_x_bms_realestates_root_' + this.selectedBmsCustomer.customerId, '_x_bms_realestates_root', [], '_x_bms_buildingaddress', 3);

      await ArrayUtils.AsyncForEach(buildingAddressNodes, async (n : GrpcNode) => {
        n.propertyValues.find(v => v.key == 'city').value = this.setBuildingAddressesInfo.city;
        n.propertyValues.find(v => v.key == 'district').value = this.setBuildingAddressesInfo.district;
        n.propertyValues.find(v => v.key == 'postalcode').value = this.setBuildingAddressesInfo.postalCode;

        await this.xconfClient.updateNode(n, n.id, '', this.selectedBmsCustomer.customerId);
      });

      this.updateCustomerData(true);

      this.alertService.success(buildingAddressNodes.length + ' building addresses updated.');
    }
  }

  //XEdge
  async viewXEdge(treeNode: XconfTreeNode) {
    //console.log("laucnhign viewedge for", treeNode);
    let token = new XEdgeToken();
    let session = await this.state.getActiveSession();
    token.token = session?.sessionKey ?? '';
    token.remoteNodeId = treeNode.id;
    token.host = XPROJECTOR_HOST;
    token.name = treeNode.name;
    //console.log("setting token", token);
    this.sessionStorageService.setXEdgeToken(token);

    setTimeout(() => {
      //console.log("opening window", XEDGE_APP_HOST);
      let wnd = window.open(XEDGE_APP_HOST);
      token.timestamp = new Date().toLocaleTimeString();
      wnd.sessionStorage.setItem('xedgetoken', JSON.stringify(token));
    }, 100);
  }

  async xedgeSelectMeters(treeNode: XconfTreeNode) {
    this.xedgeRemoteNodeId = treeNode.id;
    this.showXEdgeSelectMetersModal = true;
  }

  async addSelectedXEdgeMeters() {
    this.showXEdgeSelectMetersModal = false;
    let toAdd = this.selectXEdgeMeters?.selectedXAutoVars;

    if (toAdd) {
      let result = await this.xedgeClient.addXEdgeXAutoMeters(this.selectedCustomer?.id, this.xedgeRemoteNodeId, toAdd);
      if (result.ok) {
        this.alertService.success(result.addedCount + ' meters added.');
        this.metersDatasourceInstance?.refreshTreeView();
      }
      else {
        this.alertService.error(result.message);
      }
    }
  }

  async sendWelcomeMail() {
    if (this.selectedCustomerWelcomeFlag) {
      let sendMail = true;
      if (this.selectedCustomerWelcomeFlag.status > 0) {
        sendMail = await this.modalService.ShowConfirmModalAsync({
          header: 'Send welcome mail',
          description: 'Welcome mail already sent, send again?',
          ok: 'Send',
          cancel: 'Cancel'
        });
      }
      else {
        sendMail = await this.modalService.ShowConfirmModalAsync({
          header: 'Send welcome mail',
          description: 'Send customer welcome mail, are you sure?',
          ok: 'Send',
          cancel: 'Cancel'
        });
      }

      if (sendMail) {
        let result = await this.bmsCustomerAdminClient.sendWelcomeMail(this.selectedCustomerConfig.customerId, true);
        if (result.ok) {
          this.selectedCustomerWelcomeFlag = await this.adminService.getCustomerFlag(this.selectedBmsCustomer.customerId, 'welcomemail');
          let info : string[] = ['Customer welcome mail sent to:'];
          info = info.concat(result.sentTo);

          this.modalService.ShowConfirmModal({
            header: 'Customer welcome mail',
            description: info,
            showCancel: false
          }, (result) => { });
        }
        else {
          this.alertService.error('Customer welcome mail error: ' + result.message);
        }
      }
    }
  }
}
