<form clrForm clrLayout="vertical" autocomplete="off">

  <clr-checkbox-container>
    <clr-checkbox-wrapper>
      <input type="checkbox" clrCheckbox [(ngModel)]="geoLocationUpdateOverwrite" name="geolocationupdateoverwrite" />
      <label>Geo location overwrite</label>
    </clr-checkbox-wrapper>
  </clr-checkbox-container>
</form>

<button type="button" class="btn btn-outline" [clrLoading]="geoLocationState" (click)="geoLocationUpdateAll()">
  <clr-icon shape="plus-circle"></clr-icon>
  Update all geo locations
</button>
