import { Component, OnDestroy, OnInit } from '@angular/core';
import { StateService } from '@xprojectorcore/services/state-service';
import { XProjectorClient, XprojMediaService } from 'xproj-lib';
import { UserRights } from '@xprojectorcore/models/user-rights';
import { Subscription } from 'rxjs';
import { SocialAuthService } from 'angularx-social-login';
import { environment } from 'src/environments/environment';

declare var VERSION;

@Component({
  selector: 'app-main-nav',
  templateUrl: './main-nav.component.html',
  styleUrls: ['./main-nav.component.scss']
})
export class MainNavComponent implements OnInit, OnDestroy {

  user: UserRights;

  version: string = VERSION;

  private userSubscription: Subscription;

  isMobile: boolean = false;

  customerHeaders: { id: String, name: string }[] = [];

  mediaService = new XprojMediaService();
  mediaSubscription: any;

  logo: string;
  title: string;

  constructor(public state: StateService,
    public xprojClient: XProjectorClient,
    private authService: SocialAuthService) {
    this.logo = environment.logo;
    this.title = environment.maintitle;
    this.mediaService.setQuery('(max-width: 762px)');
    this.mediaSubscription = this.mediaService.match$.subscribe(async (isMobile) => {
      this.isMobile = isMobile;
    });
  }

  ngOnInit(): void {
    this.userSubscription = this.state.user$.subscribe(async (user) => {
      if (user) {
        this.xprojClient.Connect(user.sessionKey);
      }
      this.user = user;
    });

    // this.customerHeaders = [];
    // this.state.userFacilityNodes.forEach(n => {
    //   this.customerHeaders.push({ id: n.id, name: n.name });
    // });

  }

  ngOnDestroy(): void {
    this.userSubscription?.unsubscribe();
    this.mediaSubscription?.unsubscribe();
  }

  async logout() {
    try {
      await this.state.logout();
      await this.authService.signOut();
    }
    catch {}
  }
}
