import { Component, Input, OnInit } from '@angular/core';
import { BmsCustomer } from '@features/bms/models/bms-customer';
import { CustomerUser, GrpcNode } from '@xprojectorcore/xprojector_backend/proto/xprojector.grpc.models.pb';
import { XProjectorXConfClient } from '@xprojectorcore/xprojector_backend/xprojector-xconf-client';
import { ArrayUtils } from 'xproj-lib';
import { BmsAdminService } from '@features/bms/bms-admin/services/bms-admin-service';

@Component({
  selector: 'app-bms-external-customers',
  templateUrl: './bms-external-customers.component.html',
  styleUrls: ['./bms-external-customers.component.scss']
})
export class BmsExternalCustomersComponent implements OnInit {

  customerUser: CustomerUser;

  externalCustomers: GrpcNode[] = [];
  loadingCustomers: boolean = false;
  selectedCustomers: [] = [];

  showAddCustomerModal: boolean = false;
  addNewCustomerId: string = '';
  customers: BmsCustomer[] = [];
  filteredCustomers: BmsCustomer[] = [];


  constructor(
    private xConfClient: XProjectorXConfClient,
    private adminService: BmsAdminService,
  ) { }

  ngOnInit(): void {
  }

  async setCustomerUser(user: CustomerUser) {
    this.customerUser = user;
    await this.updateExternalCustomers();
  }

  async updateExternalCustomers() {
    this.externalCustomers = [];
    try {
      this.loadingCustomers = true;
      if (this.customerUser) {
        this.externalCustomers = await this.xConfClient.getReferencedNodes(
          this.customerUser.id,
          '_x_customerusers_user',
          [],
          '_x_datasource'
        );

        this.externalCustomers.sort((a, b) => a.name > b.name ? 1 : -1);
      }
    }
    finally {
      this.loadingCustomers = false;
    }
  }

  async showAddCustomer() {
    if (this.customers.length == 0) {
      this.customers = await this.adminService.getCustomers();
    }
    this.filteredCustomers = this.customers.filter(customer => this.externalCustomers.findIndex(tc => tc.id == customer.customerId) == -1);
    this.addNewCustomerId = '';
    this.showAddCustomerModal = true;
  }

  async addCustomer() {
    if (this.customerUser) {
      if (this.addNewCustomerId != '') {
        let result = await this.xConfClient.createReference(this.customerUser.id, '_x_customerusers_user', this.addNewCustomerId,
               '_x_datasource', '_x_node_customeruser_hascustomerid', true, null, '', this.customerUser?.customerId);
        if (result.result) {
          await this.updateExternalCustomers();
        }
        else {
          //this.alertService.error(result.message);
        }
      }
    }
  }

  async removeSelectedCustomers() {
    if (this.customerUser) {
      await ArrayUtils.AsyncForEach(this.selectedCustomers, async (i) => {
        await this.xConfClient.deleteReference(this.customerUser.id, '_x_customerusers_user', this.externalCustomers[i].id,
              '_x_datasource', '_x_node_customeruser_hascustomerid', '', this.customerUser?.customerId);
      });
      this.selectedCustomers = [];
      await this.updateExternalCustomers();
    }
  }

}
