<div class="searchbar-container" *ngIf="showSearch">
  <div class="icons-search-wrapper">
    <label class="searchbar-label">
      <clr-icon shape="search" size="24"></clr-icon>
      <input type="text" class="searchbar-input" placeholder="Search..." (keyup.enter)="search()"
        [(ngModel)]="nameFilter">
        <clr-spinner *ngIf="searching" [clrSmall]="true" [clrInline]="true"></clr-spinner>
    </label>
  </div>
</div>

<clr-datagrid #grid [(clrDgSingleSelected)]="selected" (clrDgRefresh)="load($event)" [clrDgLoading]="loading"
  (clrDgSingleSelectedChange)="selectedChanged($event)" [style.max-height.px]="maxHeight" [style.min-height.px]="minHeight">

  <clr-dg-column [clrDgField]="item.columnname" *ngFor="let item of tableData.columns">
    <ng-container *clrDgHideableColumn="{hidden: item.hidden}">
      {{item.label}}
    </ng-container>
    <clr-dg-filter *ngIf="item.datatype == 7">
      <xproj-datagrid-string-filter [group]="item.group" [projectionid]="item.projectionid"
        [columnname]="item.columnname" [startWithFilter]="false"></xproj-datagrid-string-filter>
    </clr-dg-filter>
  </clr-dg-column>

  <clr-dg-row *ngFor="let obj of tableData.data_col0 ; index as i" [clrDgItem]="i+1">
    <clr-dg-cell *ngFor="let item of tableData.columns"> {{ item | bmsmeteradmineditpipe : i}}
    </clr-dg-cell>
  </clr-dg-row>

  <clr-dg-footer>
    <clr-dg-pagination #pagination id="pagination" [clrDgPageSize]="sizeOptions[1]" [(clrDgPage)]="currentPage"
      [clrDgTotalItems]="queryNrTotalRows">
      <clr-dg-page-size [clrPageSizeOptions]="sizeOptions">Rows per page</clr-dg-page-size>
      <!-- {{pagination.firstItem + 1}} - {{pagination.lastItem + 1}} of {{queryNrTotalRows}} Rows -->
    </clr-dg-pagination>
  </clr-dg-footer>

</clr-datagrid>
