import { Component, Input, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { XProjectorBmsCustomerAdminClient } from '@core/xprojector_backend/xprojector-bms-customeradmin-client';
import { NGXLogger } from 'ngx-logger';
import { map } from 'rxjs/operators';
import { DateHelper, XprojAlertService, XprojModalService } from 'xproj-lib';
import { CustomerAdminApartmentInfo, CustomerAdminCustomerUser } from '../bms-customer-admin/bms-customer-admin.component';
import { BmsCustomerUser } from '@app/core/xprojector_backend/proto/xprojector.modulebms.customeradmin.pb';

@Component({
    selector: 'app-bms-customer-admin-user',
    templateUrl: './bms-customer-admin-user.component.html',
    styleUrls: ['./bms-customer-admin-user.component.scss']
})
export class BmsCustomerAdminUserComponent implements OnInit {

    @Input() customerId: string = '';
    @Input() apartmentInfo: CustomerAdminApartmentInfo;

    loading: boolean = false;

    newUser: CustomerAdminCustomerUser;
    showAddUser: boolean = false;

    editUser: CustomerAdminCustomerUser;
    showEditUser: boolean = false;

    constructor(
        private route: ActivatedRoute,
        private bmsCustomerAdminClient: XProjectorBmsCustomerAdminClient,
        private logger: NGXLogger,
        public dateHelper: DateHelper,
        private modalService: XprojModalService,
        private alertService: XprojAlertService
    ) { }

    ngOnInit(): void {
        this.route.params.pipe(map(p => p.customerid)).subscribe(async (customerId) => {
            if (customerId) {
                this.logger.info('ngOnInit customerId', customerId);
                this.customerId = customerId;
            }
        });

        this.updateCustomerUser();
    }

    async updateCustomerUser(force: boolean = false) {
        try {
            this.loading = true;
            if (force || this.apartmentInfo.customerUsers.length == 0) {
                this.apartmentInfo.customerUsers = [];
                let response = await this.bmsCustomerAdminClient.getApartmentCustomerUsers(this.customerId, this.apartmentInfo.apartment.id);
                let residents : string[] = [];
                response.customerUsers.forEach((customerUser: BmsCustomerUser) => {
                    let user = new CustomerAdminCustomerUser();
                    user.id = customerUser.id;
                    user.firstname = customerUser.firstname;
                    user.surname = customerUser.surname;
                    user.email = customerUser.email;
                    user.activeFrom = customerUser.activeFrom.toDate();
                    user.activeFromString = this.dateHelper.utils.formatByString(user.activeFrom, 'yyyy-MM-dd')
                    this.apartmentInfo.customerUsers.push(user);

                    residents.push(customerUser.firstname + ' ' + customerUser.surname);
                });

                this.apartmentInfo.residents = residents.join('; ');
            }
        }
        finally {
            this.loading = false;
        }
    }

    addCustomerUser() {
        this.newUser = new CustomerAdminCustomerUser();
        this.newUser.activeFromString = this.dateHelper.utils.formatByString(new Date(), 'yyyy-MM-dd');
        this.showAddUser = true;
    }

    async saveNewUser() {
        if (this.newUser) {
            if (this.newUser.firstname.length > 0 && this.newUser.surname.length > 0 && this.newUser.email.length > 0) {
                let response = await this.bmsCustomerAdminClient.addCustomerUser(this.customerId, this.apartmentInfo.apartment.id, this.newUser);
                if (response.ok) {
                    this.updateCustomerUser(true);
                }
                else {
                  this.alertService.error($localize `:@@customeradminuser_saveerror:Error add user, email already in use.`);
                }
            }
        }
    }

    async deleteCustomerUser(user: CustomerAdminCustomerUser) {
        if (user) {
            this.modalService.ShowConfirmModal({
              header: $localize `:@@customeradminuser_deleteuser:Delete user`,
              description: $localize `:@@customeradminuser_deleteuserquestion:Delete user, are you sure?`},
              async (result) => {
                if (result) {
                    let response = await this.bmsCustomerAdminClient.deleteCustomerUser(this.customerId, user.id);
                    if (response.ok) {
                        this.updateCustomerUser(true);
                    }
                }
            });
        }
    }

    async editCustomerUser(user: CustomerAdminCustomerUser) {
        this.editUser = user;
        this.editUser.activeFromString = this.dateHelper.utils.formatByString(this.editUser.activeFrom, 'yyyy-MM-dd');
        this.showEditUser = true;
    }

    async saveEditUser() {
        if (this.editUser) {
            if (this.editUser.firstname.length > 0 && this.editUser.surname.length > 0 && this.editUser.email.length > 0) {
                let response = await this.bmsCustomerAdminClient.updateCustomerUser(this.customerId, this.apartmentInfo.apartment.id, this.editUser);
            }
            this.updateCustomerUser(true);
        }
    }
    async resetPassword(user: CustomerAdminCustomerUser) {
        this.modalService.ShowConfirmModal({
          header: $localize `:@@customeradminuser_resetpassword:Reset password`,
          description: $localize `:@@customeradminuser_resetpasswordquestion_1:Reset password for user` + ' "' + user.email + '", '
                       + $localize `:@@customeradminuser_resetpasswordquestion_2:are you sure?`},
          async (confirmed) => {
            if (confirmed) {
                let response = await this.bmsCustomerAdminClient.resetPassword(this.customerId, this.editUser.id);

                if (response.ok) {
                    this.alertService.success($localize `:@@customeradminuser_passwordresetok:Password reseted.`);
                }
                else {
                    this.alertService.error($localize `:@@customeradminuser_passwordreseterror:Error reset password!`);
                }
            }
        });
    }
}
