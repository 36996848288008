import { Component, OnInit } from '@angular/core';
import { BmsMasterDataImportResult } from '@features/bms/models/bms-master-data-import-result';
import { StateService } from '@xprojectorcore/services/state-service';
import { ClrLoadingState } from '@clr/angular';
import { XprojAlertService } from 'xproj-lib';
import { saveAs } from 'file-saver';
import { BmsStateService } from '@core/services/bms-state-service';
import { XProjectorSysAdminClient } from '@xprojectorcore/xprojector_backend/xprojector-sysadmin-client';
import { BmsAdminService } from '../../services/bms-admin-service';

@Component({
  selector: 'app-bms-master-data',
  templateUrl: './bms-master-data.component.html',
  styleUrls: ['./bms-master-data.component.scss']
})
export class BmsMasterDataComponent implements OnInit {

  files: any[] = [];
  overwrite : boolean = false;
  onlymeters : boolean = false;
  importingState : ClrLoadingState = ClrLoadingState.DEFAULT;
  importResult : BmsMasterDataImportResult;
  filesProcessed : number;

  orgNoFilter : string[] = [];
  orgNoFilterString : string = '';

  ClrLoadingState = ClrLoadingState;

  constructor(
    private state : StateService,
    private bmsState: BmsStateService,
    private sysAdminClient: XProjectorSysAdminClient,
    private adminService: BmsAdminService,
    private alertService: XprojAlertService) { }

  ngOnInit(): void {
  }

  async importFiles() {
    try {
      this.importingState = ClrLoadingState.LOADING;
      this.importResult = null;
      this.filesProcessed = 0;

      let filesToImport : any[] = [];
      for (let i = 0; i < this.files.length; i++) {
        filesToImport.push(this.files[i]);
        if (filesToImport.length >= 1) {
          let result = await this.bmsState.importMasterDataFiles(this.overwrite, this.onlymeters, filesToImport);
          this.filesProcessed += filesToImport.length;
          this.mergeResults(result);
          filesToImport = [];
        }
      }

      if (filesToImport.length > 0) {
        let result = await this.bmsState.importMasterDataFiles(this.overwrite, this.onlymeters, filesToImport);
        this.mergeResults(result);
        this.filesProcessed += filesToImport.length;
      }

      if (this.importResult.ok) {
        this.alertService.info('Master data file imported ok.');
        this.importingState = ClrLoadingState.SUCCESS;
          //Update customers cache
        await this.sysAdminClient.getCustomers(true);
        await this.adminService.getCustomers(true);
        await this.adminService.getTrustees(true);
      }
      else {
        this.alertService.error('Error import file:', this.importResult.message);
        this.importingState = ClrLoadingState.ERROR;
      }
    }
    catch (err) {
      this.alertService.error('Error import file:', err);
      this.importingState = ClrLoadingState.ERROR;
      this.importResult = new BmsMasterDataImportResult();
      this.importResult.ok = false;
      this.importResult.message = err.message;
    }
  }

  private mergeResults(toMerge : BmsMasterDataImportResult) {
    if (!this.importResult) {
      this.importResult = toMerge;
    }
    else {
      this.importResult.ok &&= toMerge.ok;
      this.importResult.realestateCount += toMerge.realestateCount;
      this.importResult.realestatesAdded += toMerge.realestatesAdded;
      this.importResult.realestatesDeleted += toMerge.realestatesDeleted;

      this.importResult.buildingAddressCount += toMerge.buildingAddressCount;
      this.importResult.buildingAddressesAdded += toMerge.buildingAddressesAdded;
      this.importResult.buildingAddressesDeleted += toMerge.buildingAddressesDeleted;

      this.importResult.apartmentCount += toMerge.apartmentCount;
      this.importResult.apartmentsAdded += toMerge.apartmentsAdded;
      this.importResult.apartmentsDeleted += toMerge.apartmentsDeleted;

      this.importResult.facilityCount += toMerge.facilityCount;
      this.importResult.facilityAdded += toMerge.facilityAdded;
      this.importResult.facilityDeleted += toMerge.facilityDeleted;

      this.importResult.meterCount += toMerge.meterCount;
      this.importResult.metersAdded += toMerge.metersAdded;
      this.importResult.metersDeleted += toMerge.metersDeleted;

      this.importResult.gatewayCount += toMerge.gatewayCount;
      this.importResult.gatewaysAdded += toMerge.gatewaysAdded;
      this.importResult.gatewaysDeleted += toMerge.gatewaysDeleted;

      toMerge.customerErrors.forEach(err => this.importResult.customerErrors.push(err));
    }
  }

  async importFromXdb() {
    try {
      this.importingState = ClrLoadingState.LOADING;

      if (this.orgNoFilterString.length > 5) {
        this.orgNoFilter = this.orgNoFilterString.split(',');
      }
      else {
        this.orgNoFilter = [];
      }

      this.importResult = null;
      this.importResult = await this.bmsState.importMasterDataFromXdb(this.overwrite, this.orgNoFilter);

      if (this.importResult.ok) {
        this.alertService.info('Master data xdb imported ok.');
        this.importingState = ClrLoadingState.SUCCESS;
      }
      else {
        this.alertService.error('Error import xdb:', this.importResult.message);
        this.importingState = ClrLoadingState.ERROR;
      }
    }
    catch (err) {
      this.alertService.error('Error import xdb:', err);
      this.importingState = ClrLoadingState.ERROR;
      this.importResult = new BmsMasterDataImportResult();
      this.importResult.ok = false;
      this.importResult.message = err.message;
    }
  }

  uploadFile(files) {
    if (files.length > 0) {
      this.files = files;
    }
    else {
      this.files = [];
    }
  }

  async downloadImportResult() {
    if (this.importResult) {
      let json: string = JSON.stringify(this.importResult);
      var blob = new Blob([json], { type: "text/plain;charset=utf-8" });
      saveAs(blob, 'MasterDataImportResult.json');
    }
  }

}
