<clr-modal [(clrModalOpen)]="showAddUser" [clrModalClosable]="false">
    <h3 i18n='@@customeradminuser_adduser' class="modal-title">Add user</h3>
    <div class="modal-body">
        <form clrForm *ngIf="newUser" autocomplete="off">
            <clr-input-container>
                <label i18n='@@customeradminuser_firstname' class="clr-col-3">Firstname:</label>
                <input clrInput class="clr-col-9" type="text" placeholder="" size="40" name="newuserfirstname"
                    [(ngModel)]="newUser.firstname" />
            </clr-input-container>
            <clr-input-container>
                <label i18n='@@customeradminuser_surname' class="clr-col-3">Surname:</label>
                <input clrInput class="clr-col-9" type="text" placeholder="" size="40" name="newusersurname"
                    [(ngModel)]="newUser.surname" />
            </clr-input-container>
            <clr-input-container>
                <label i18n='@@customeradminuser_email' class="clr-col-3">Email:</label>
                <input clrInput class="clr-col-9" type="text" placeholder="" size="45" name="newuseremail" [(ngModel)]="newUser.email" />
            </clr-input-container>
            <clr-input-container>
                <label i18n='@@customeradminuser_movedin' class="clr-col-3">Moved in</label>
                <input clrInput class="clr-col-9" type="date" step="1" [(ngModel)]="newUser.activeFromString" name="newusermovingin">
            </clr-input-container>
        </form>
    </div>
    <div class="modal-footer">
        <button i18n='@@customeradminuser_cancel' type="button" class="btn btn-outline" (click)="showAddUser = false">Cancel</button>
        <button i18n='@@customeradminuser_save' type="button" class="btn btn-primary" (click)="showAddUser = false; saveNewUser()">Save</button>
    </div>
</clr-modal>

<clr-modal [(clrModalOpen)]="showEditUser" [clrModalClosable]="false">
    <h3 i18n='@@customeradminuser_edituser' class="modal-title">Edit user</h3>
    <div class="modal-body">
        <form clrForm *ngIf="editUser" autocomplete="off">
            <clr-input-container>
                <label class="clr-col-3" i18n='@@customeradminuser_firstname'>Firstname:</label>
                <input class="clr-col-9" clrInput type="text" placeholder="" size="40" name="edituserfirstname"
                    [(ngModel)]="editUser.firstname" />
            </clr-input-container>
            <clr-input-container>
                <label class="clr-col-3" i18n='@@customeradminuser_surname'>Surname:</label>
                <input class="clr-col-9" clrInput type="text" placeholder="" size="40" name="editusersurname"
                    [(ngModel)]="editUser.surname" />
            </clr-input-container>
            <clr-input-container>
                <label class="clr-col-3" i18n='@@customeradminuser_movedin'>Moved in</label>
                <input class="clr-col-9" clrInput type="date" step="1" [(ngModel)]="editUser.activeFromString"
                    name="editusermovingin">
            </clr-input-container>
            <clr-input-container>
                <label class="clr-col-3" i18n='@@customeradminuser_email' >Email:</label>
                <input class="clr-col-9" clrInput type="text" placeholder="" size="40" name="edituseremail" disabled="true"
                    [(ngModel)]="editUser.email" />
            </clr-input-container>
        </form>
    </div>
    <div class="modal-footer">
        <button i18n='@@customeradminuser_resetpassword' type="button" class="btn btn-outline btn-warning" (click)="showEditUser = false; resetPassword(editUser);">Reset
            password</button>
        <button i18n='@@customeradminuser_cancel' type="button" class="btn btn-outline"
            (click)="showEditUser = false; updateCustomerUser(true);">Cancel</button>
        <button i18n='@@customeradminuser_save' type="button" class="btn btn-primary" (click)="showEditUser = false; saveEditUser()">Save</button>
    </div>
</clr-modal>


<div [clrLoading]="loading">
    <table class="table table-noborder">
        <thead>
            <tr>
                <th i18n='@@customeradminuser_name' class="left">Name</th>
                <th i18n='@@customeradminuser_email' class="left">Email</th>
                <th i18n='@@customeradminuser_movedin' class="left">Moved in</th>
                <th class="left"></th>
            </tr>
        </thead>
        <tbody>
            <tr *ngFor="let user of apartmentInfo.customerUsers">
                <td class="left">{{user.firstname}} {{user.surname}}</td>
                <td class="left">{{user.email}}</td>
                <td class="left">{{user.activeFromString}}</td>
                <td class="left">
                    <cds-icon (click)="editCustomerUser(user);" shape="pencil" size="sm" [style.cursor]="'pointer'">
                    </cds-icon>
                    <cds-icon (click)="deleteCustomerUser(user);" shape="trash" size="sm" [style.cursor]="'pointer'"
                        [style.margin-left.em]="2">
                    </cds-icon>
                </td>
            </tr>
        </tbody>
    </table>

    <button i18n='@@customeradminuser_adduser' type="button" class="btn btn-sm addbutton" (click)="addCustomerUser()">
        <clr-icon shape="plus"></clr-icon> Add user
    </button>

</div>
