import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { BmsCustomerEventsHistoryComponent } from './components/bms-customer-events-history/bms-customer-events-history.component';
import { BmsCustomerEventsComponent } from './components/bms-customer-events/bms-customer-events.component';

const routes: Routes = [
  {
      path: '', component: BmsCustomerEventsComponent,
      children: [
           { path: 'events', component: BmsCustomerEventsComponent },
           { path: 'events/:customerid/:eventId', component: BmsCustomerEventsHistoryComponent }

      ]
  }
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class BmsCustomerRoutingModule { }
