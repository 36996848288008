<div class="content-area">
  <div class="card" *ngIf="customer">
    <div class="card-header">
      External users
    </div>
    <div class="card-block">
      <div class="limit-height">
        <clr-datagrid [(clrDgSingleSelected)]="selectedExternalUser"
          (clrDgSingleSelectedChange)="selectedExternalUserChanged($event)">

          <clr-dg-action-bar>
            <button class="btn btn-sm btn-secondary" (click)="viewCustomer();"
            [disabled]="!selectedExternalUser">View customer<clr-icon shape="arrow" directions="left"
              style="transform: rotate(90deg);"></clr-icon></button>
          </clr-dg-action-bar>

          <clr-dg-column [clrDgField]="'firstname'">
            <ng-container *clrDgHideableColumn="{hidden: false}">Firstname</ng-container>
          </clr-dg-column>
          <clr-dg-column [clrDgField]="'surname'">
            <ng-container *clrDgHideableColumn="{hidden: false}">Surname</ng-container>
          </clr-dg-column>
          <clr-dg-column [clrDgField]="'email'">
            <ng-container *clrDgHideableColumn="{hidden: false}">Email</ng-container>
          </clr-dg-column>
          <clr-dg-column [clrDgField]="'customerName'">
            <ng-container *clrDgHideableColumn="{hidden: false}">Customer</ng-container>
          </clr-dg-column>

          <!-- <clr-dg-column>Admin</clr-dg-column> -->
          <clr-dg-column> </clr-dg-column>

          <clr-dg-row *clrDgItems="let externalUser of externalUsers; let i = index" [clrDgItem]="externalUser">
            <clr-dg-cell>
              {{externalUser.firstname}}
            </clr-dg-cell>
            <clr-dg-cell>
              {{externalUser.surname}}
            </clr-dg-cell>
            <clr-dg-cell>
              {{externalUser.email}}
            </clr-dg-cell>
            <clr-dg-cell>
              {{externalUser.customerName}}
            </clr-dg-cell>
            <!-- <clr-dg-cell>
            {{user.isAdmin ? 'True' : 'False'}}
          </clr-dg-cell> -->
            <clr-dg-cell>
              <clr-icon (click)="removeExternalUser(externalUser);" shape="trash" [style.cursor]="'pointer'"></clr-icon>
            </clr-dg-cell>
          </clr-dg-row>

          <clr-dg-footer>
            <clr-dg-pagination #pagination [clrDgPageSize]="10">
              <clr-dg-page-size [clrPageSizeOptions]="[10,20,50,100]">Rows per page</clr-dg-page-size>
              {{pagination.firstItem + 1}} - {{pagination.lastItem + 1}} of {{pagination.totalItems}} rows
            </clr-dg-pagination>
          </clr-dg-footer>

        </clr-datagrid>
      </div>
    </div>
  </div>
</div>
