export enum BmsClass {
  A = 0,
  B = 1,
  C = 2
}

export class BmsEvent {
  id : number;
  customerId : string;
  meterId : number;
  class : BmsClass;
  category : string;
  eventId : string;
  active : boolean;
  high : boolean;
  acknowledged : boolean;
  value : number;
  count : number;
  message : string;
  comment : string;
  timestamp : Date;
  timestampActive : Date;
  timestampAcknowledged : Date;
  timestampCleared : Date;
  acknowledgedBy : string;
  clearedBy : string;
}
