import { AfterViewInit, ChangeDetectorRef, Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { BmsEditMode } from '@features/bms/models/bms-edit.mode';
import { BmsEnumMember } from '@features/bms/models/bms-enum-member';
import { BmsMeter } from '@features/bms/models/bms-meter';
import { StateService } from '@xprojectorcore/services/state-service';
import { GrpcNode } from '@xprojectorcore/xprojector_backend/proto/xprojector.grpc.models.pb';
import { SearchNodesRequest, SearchNodesResponse, SearchProperty } from '@xprojectorcore/xprojector_backend/proto/xprojector.xconf.pb';
import { XProjectorXConfClient } from '@xprojectorcore/xprojector_backend/xprojector-xconf-client';
import { ClrDatagridStateInterface } from '@clr/angular';
import { CacheService } from 'xproj-lib';
import { NGXLogger } from 'ngx-logger';
import { BaseQuery, BaseQueryInputColumnDescription, ColumnFilteringNumerical, ColumnFilteringString, ColumnFilteringTimestamp, FilterComparator, FilterLogicalGroup, FilterLogicalGroupType, MsgPackCloneObject, ProjectionColumnDescription, XDataType, XprojAlertService, XprojDatagridstringfilterComponent, XProjectorClient } from 'xproj-lib';
import { BmsDataUtils } from '@features/bms/bms-admin/utils/bms-data-utils';

const METERLIST_FILTER_CACHEKEY: string = 'bmsmeterlistfilter';

export class BmsMeterAdminFilter {
  realestateIds: number[] = [];
  buildingids: number[] = [];
  buildingaddressIds: number[] = [];
  apartmentIds: number[] = [];
  facilityIds: number[] = [];
  gatewayIds: number[] = [];
  meterIds: number[] = [];
}

export class BmsQueryDataColumn {
  id: string;
  columnname: string;
  label: string;
  datatype: XDataType;
  hidden: boolean = false;
  data: any[] = [];
  editMode: BmsEditMode = BmsEditMode.String;
  enumMembers: BmsEnumMember[] = [];
}

class TableData {
  columns: BmsQueryDataColumn[] = [];
  data_col0: any = [];
  initilized: boolean = false;
  oldInitilized: boolean = false;
}

class ColumnConfig {
  public columnName: string = '';
  public label: string = '';
  public columnOutName: string = '';
  public hidden: boolean = false;
  public datatype: XDataType = XDataType.Number;
  public unit: string = '';
  public tags: string[] = [];
  public editMode: BmsEditMode = BmsEditMode.String;
  public enumMembers: BmsEnumMember[] = [];
}


@Component({
  selector: 'app-bms-meter-list',
  templateUrl: './bms-meter-list.component.html',
  styleUrls: ['./bms-meter-list.component.scss']
})
export class BmsMeterListComponent implements OnInit, AfterViewInit {

  @Input() showSearch: boolean = false;
  @Input() subordinateNodes: boolean = true;
  @Input() maxHeight: number = 400;
  @Input() minHeight: number = 100;

  _selectedNode: GrpcNode;
  get selectedNode(): GrpcNode {
    return this._selectedNode;
  }
  @Input() set selectedNode(value: GrpcNode) {
    this._selectedNode = value;
    if (this._selectedNode) {
      //this.search();
    }
  }

  _inputFilter: BmsMeterAdminFilter;
  get inputFilter(): BmsMeterAdminFilter {
    return this._inputFilter;
  }
  @Input() set inputFilter(value: BmsMeterAdminFilter) {
    this._inputFilter = value;
    if (this._inputFilter) {
      //this.search();
    }
  }

  @Input() selectedNodeParent: GrpcNode;

  @Output() onSelectMeter = new EventEmitter<BmsMeter>();

  columns: BaseQueryInputColumnDescription[];
  columnConfigs: ColumnConfig[] = [];
  tableData: TableData = new TableData();
  sizeOptions = [10, 20, 50, 100];
  projectioncolumns: ProjectionColumnDescription[] = [];

  dataGridState: any;
  currentPage: number = 1;
  loadingQuery: boolean = false;
  loading: boolean = true;
  queryNrRows: number = 0;
  queryNrTotalRows: number = 0;
  selected = -1;
  selectedMeter: BmsMeter;
  filter: BmsMeterAdminFilter = new BmsMeterAdminFilter();

  searching: boolean = false;
  searchResult: SearchNodesResponse;
  nameFilter: string;
  filterNodes: GrpcNode[] = [];

  showColumns: { id: string, label: string }[] = [
    {
      id: 'subaddress1',
      label: 'Identifier'
    },
    {
      id: 'subaddress2',
      label: 'Manufacturer'
    },
    {
      id: 'unit',
      label: 'Unit'
    },
    {
      id: 'metertype',
      label: 'Meter type'
    },
    {
      id: 'state',
      label: 'State'
    },
    {
      id: 'id',
      label: 'Meter Id'
    }
  ];

  constructor(
    private readonly cache: CacheService,
    private xprojClient: XProjectorClient,
    private xconfClient: XProjectorXConfClient,
    private logger: NGXLogger,
    private cdr: ChangeDetectorRef) {

  }

  ngOnInit(): void {
    if (this.cache.has(METERLIST_FILTER_CACHEKEY)) {
      let filter = this.cache.get(METERLIST_FILTER_CACHEKEY);
      this.nameFilter = filter.nameFilter;
      this.filterNodes = filter.filterNodes;
      this.tableData = filter.tableData;

      this.updateFilter();
    }
  }

  ngAfterViewInit(): void {
    // setTimeout(() => {
    //   this.load(this.state);
    // }, 500);
  }

  selectedChanged($event) {
    this.logger.info($event, this.selected);

    this.selectedMeter = new BmsMeter();
    this.tableData.columns.forEach(col => {
      switch (col.columnname) {
        case "id":
          this.selectedMeter.id = col.data[+this.selected - 1];
          break;
        case "apartmentid":
          this.selectedMeter.apartmentId = col.data[+this.selected - 1];
          break;
        case "facilityid":
          this.selectedMeter.facilityId = col.data[+this.selected - 1];
          break;
        case "buildingaddressid":
          this.selectedMeter.buildingAddressId = col.data[+this.selected - 1];
          break;
        case "buildingid":
          this.selectedMeter.buildingId = col.data[+this.selected - 1];
          break;
        case "realestateid":
          this.selectedMeter.realestateId = col.data[+this.selected - 1];
          break;
      }
    });

    this.onSelectMeter.next(this.selectedMeter);
  }

  async refresh() {
    this.updateFilter();

    this.currentPage = 1;
    this.selected = -1;
    this.selectedMeter = null;
    await this.load(this.dataGridState);
  }

  async load(state?: ClrDatagridStateInterface, showLoading: boolean = true) {
    this.dataGridState = state;
    if (!this.loadingQuery) {
      let updatedTableData: TableData = MsgPackCloneObject(this.tableData) as TableData;
      let newData: boolean = false;
      try {
        this.loadingQuery = true;
        this.loading = showLoading;

        let sortingDefined: boolean = false;

        await this.initColumns();

        let query = this.getQuery();

        if (state) {
          let filterId = 20000;
          if (state.filters) {
            for (let filter of state.filters) {
              if (filter instanceof XprojDatagridstringfilterComponent) {
                let dynfilter = filter as XprojDatagridstringfilterComponent;
                let col = dynfilter.columnname;
                let strings = dynfilter.SelectedStrings;
                let gr = new FilterLogicalGroup();
                gr.type = FilterLogicalGroupType.OR;
                gr.queryfilterid = filterId++;
                for (let str of strings) {
                  let newFilter = new ColumnFilteringString();
                  newFilter.queryfilterid = filterId++;
                  newFilter.columnname = col;
                  newFilter.comparator = FilterComparator.Equals;
                  newFilter.value = str;
                  gr.filters.push(newFilter.queryfilterid);
                  query.stringfilters.push(newFilter);
                }
                query.filter.filters.push(gr.queryfilterid);
                query.subfiltergroups.push(gr);
                continue;
              }

              let columname = filter.property;
              let value = filter.value;

              for (let col of query.columns) {
                if (col.columnoutname != columname)
                  continue;

                let scol = updatedTableData.columns.find(c => c.columnname == col.columnname);

                if (scol) {
                  switch (scol.datatype) {
                    case XDataType.Number:
                    case XDataType.Float32:
                    case XDataType.Float64:
                    case XDataType.UInt8:
                    case XDataType.Int32:
                    case XDataType.Int64:
                      {
                        let newFilter = new ColumnFilteringNumerical();
                        newFilter.columnname = scol.columnname;
                        newFilter.comparator = FilterComparator.Equals;
                        newFilter.queryfilterid = ++filterId;
                        newFilter.value = parseFloat(value);
                        query.filter.filters.push(newFilter.queryfilterid);
                        query.numericalfilters.push(newFilter);
                        break;
                      }
                    case XDataType.Timestamp:
                      {
                        let newFilter = new ColumnFilteringTimestamp();
                        newFilter.columnname = scol.columnname;
                        newFilter.comparator = FilterComparator.Equals;
                        newFilter.queryfilterid = ++filterId;
                        newFilter.value = new Date(value);
                        query.filter.filters.push(newFilter.queryfilterid);
                        query.timestampfilters.push(newFilter);
                        break;
                      }
                    case XDataType.String:
                      {
                        let newFilter = new ColumnFilteringString();
                        newFilter.columnname = scol.columnname;
                        newFilter.comparator = FilterComparator.Equals;
                        newFilter.queryfilterid = ++filterId;
                        newFilter.value = value;
                        query.filter.filters.push(newFilter.queryfilterid);
                        query.stringfilters.push(newFilter);
                        break;
                      }
                  }
                }
              }
            }
          }
          if (state.sort) {
            let scol = query.columns.find(c => c.columnoutname == state.sort.by);

            if (scol) {
              sortingDefined = true;
              query.sorting.columnname = scol.columnname;
              query.sorting.descending = state.sort.reverse;
            }
          }

          query.seekoffset = state.page.size * (state.page.current - 1);
          query.maxitems = state.page.size;
        }
        else {
          query.seekoffset = 0;
          query.maxitems = this.sizeOptions[1];
        }

        await this.reQuery(query, updatedTableData.columns);

      }
      finally {
        this.cdr.detach();

        this.updateTableData(updatedTableData);

        //To prevent changedetection errors on init.
        setTimeout(() => this.loading = false);
        this.cdr.reattach();
      }
    }
  }

  updateTableData(updatedTableData: TableData) {
    if (this.tableData.columns.length != updatedTableData.columns.length) {
      this.tableData = updatedTableData;

      this.loadingQuery = false;
      this.tableData.initilized = this.tableData.columns.length > 0;
      this.tableData.data_col0 = this.tableData.columns.length > 0 ? this.tableData.columns[0].data : [];
    }
    else {
      this.tableData.data_col0 = [];

      setTimeout(() => {
        let i = 0;
        this.tableData.columns.forEach(col => {
          col.data = updatedTableData.columns[i].data;
          i++;
        });

        this.loadingQuery = false;
        this.tableData.initilized = this.tableData.columns.length > 0;
        this.tableData.data_col0 = this.tableData.columns.length > 0 ? this.tableData.columns[0].data : [];
      });
    }
  }

  getQuery(): BaseQuery {
    let query: BaseQuery = new BaseQuery();

    query.targetprojectionid = 'hs-meter';
    query.maxitems = 20;
    query.targetgroup = [];

    let filterId = 0;

    query.filter.type = FilterLogicalGroupType.AND;

    if (this.filterNodes.length > 0 || this.inputFilter) {
      if (this.filter.realestateIds.length > 0) {
        let realestateFilter = new FilterLogicalGroup();
        realestateFilter.type = FilterLogicalGroupType.OR;
        realestateFilter.queryfilterid = 1000;

        this.filter.realestateIds.forEach(id => {
          let columnFiltering = new ColumnFilteringNumerical();
          columnFiltering.columnname = 'realestateid';
          columnFiltering.comparator = FilterComparator.Equals;
          columnFiltering.value = id;
          columnFiltering.queryfilterid = ++filterId;
          realestateFilter.filters.push(columnFiltering.queryfilterid);

          query.numericalfilters.push(columnFiltering);
        });

        query.subfiltergroups.push(realestateFilter);
        query.filter.filters.push(realestateFilter.queryfilterid)
      }

      if (this.filter.buildingids.length > 0) {
        let buildingFilter = new FilterLogicalGroup();
        buildingFilter.type = FilterLogicalGroupType.OR;
        buildingFilter.queryfilterid = 1001;

        this.filter.buildingids.forEach(id => {
          let columnFiltering = new ColumnFilteringNumerical();
          columnFiltering.columnname = 'buildingid';
          columnFiltering.comparator = FilterComparator.Equals;
          columnFiltering.value = id;
          columnFiltering.queryfilterid = ++filterId;
          buildingFilter.filters.push(columnFiltering.queryfilterid);

          query.numericalfilters.push(columnFiltering);
        });

        query.subfiltergroups.push(buildingFilter);
        query.filter.filters.push(buildingFilter.queryfilterid)
      }

      if (this.filter.buildingaddressIds.length > 0) {
        let buildingAddressFilter = new FilterLogicalGroup();
        buildingAddressFilter.type = FilterLogicalGroupType.OR;
        buildingAddressFilter.queryfilterid = 1002;

        this.filter.buildingaddressIds.forEach(id => {
          let columnFiltering = new ColumnFilteringNumerical();
          columnFiltering.columnname = 'buildingaddressid';
          columnFiltering.comparator = FilterComparator.Equals;
          columnFiltering.value = id;
          columnFiltering.queryfilterid = ++filterId;
          buildingAddressFilter.filters.push(columnFiltering.queryfilterid);

          query.numericalfilters.push(columnFiltering);
        });

        query.subfiltergroups.push(buildingAddressFilter);
        query.filter.filters.push(buildingAddressFilter.queryfilterid)
      }

      if (this.filter.apartmentIds.length > 0) {
        let apartmentFilter = new FilterLogicalGroup();
        apartmentFilter.type = FilterLogicalGroupType.OR;
        apartmentFilter.queryfilterid = 1003;

        this.filter.apartmentIds.forEach(id => {
          let columnFiltering = new ColumnFilteringNumerical();
          columnFiltering.columnname = 'apartmentid';
          columnFiltering.comparator = FilterComparator.Equals;
          columnFiltering.value = id;
          columnFiltering.queryfilterid = ++filterId;
          apartmentFilter.filters.push(columnFiltering.queryfilterid);

          query.numericalfilters.push(columnFiltering);
        });

        query.subfiltergroups.push(apartmentFilter);
        query.filter.filters.push(apartmentFilter.queryfilterid)
      }

      if (this.filter.facilityIds.length > 0) {
        let apartmentFilter = new FilterLogicalGroup();
        apartmentFilter.type = FilterLogicalGroupType.OR;
        apartmentFilter.queryfilterid = 1004;

        this.filter.facilityIds.forEach(id => {
          let columnFiltering = new ColumnFilteringNumerical();
          columnFiltering.columnname = 'facilityid';
          columnFiltering.comparator = FilterComparator.Equals;
          columnFiltering.value = id;
          columnFiltering.queryfilterid = ++filterId;
          apartmentFilter.filters.push(columnFiltering.queryfilterid);

          query.numericalfilters.push(columnFiltering);
        });

        query.subfiltergroups.push(apartmentFilter);
        query.filter.filters.push(apartmentFilter.queryfilterid)
      }

      if (this.filter.gatewayIds.length > 0) {
        let apartmentFilter = new FilterLogicalGroup();
        apartmentFilter.type = FilterLogicalGroupType.OR;
        apartmentFilter.queryfilterid = 1005;

        this.filter.gatewayIds.forEach(id => {
          let columnFiltering = new ColumnFilteringNumerical();
          columnFiltering.columnname = 'gatewayid';
          columnFiltering.comparator = FilterComparator.Equals;
          columnFiltering.value = id;
          columnFiltering.queryfilterid = ++filterId;
          apartmentFilter.filters.push(columnFiltering.queryfilterid);

          query.numericalfilters.push(columnFiltering);
        });

        query.subfiltergroups.push(apartmentFilter);
        query.filter.filters.push(apartmentFilter.queryfilterid)
      }

      if (this.filter.meterIds.length > 0) {
        let apartmentFilter = new FilterLogicalGroup();
        apartmentFilter.type = FilterLogicalGroupType.OR;
        apartmentFilter.queryfilterid = 1006;

        this.filter.meterIds.forEach(id => {
          let columnFiltering = new ColumnFilteringNumerical();
          columnFiltering.columnname = 'id';
          columnFiltering.comparator = FilterComparator.Equals;
          columnFiltering.value = id;
          columnFiltering.queryfilterid = ++filterId;
          apartmentFilter.filters.push(columnFiltering.queryfilterid);

          query.numericalfilters.push(columnFiltering);
        });

        query.subfiltergroups.push(apartmentFilter);
        query.filter.filters.push(apartmentFilter.queryfilterid)
      }
    }
    else {
      let noneFiltering = new ColumnFilteringNumerical();
      noneFiltering.columnname = 'id';
      noneFiltering.comparator = FilterComparator.LessThan;
      noneFiltering.value = 0;
      noneFiltering.queryfilterid = ++filterId;
      query.filter.filters.push(noneFiltering.queryfilterid);
      query.numericalfilters.push(noneFiltering);
    }

    let deletedFiltering = new ColumnFilteringNumerical();
    deletedFiltering.columnname = 'deleted';
    deletedFiltering.comparator = FilterComparator.Equals;
    deletedFiltering.value = 0;
    deletedFiltering.queryfilterid = ++filterId;
    query.filter.filters.push(deletedFiltering.queryfilterid);
    query.numericalfilters.push(deletedFiltering);

    query.columns = this.columns;

    return query;
  }

  async initColumns() {
    if (this.projectioncolumns.length == 0) {
      this.projectioncolumns = await this.xprojClient.RequestListQueryableProjectionColumns('hs-meter', '', 0, 100);

      this.columns = [];
      this.columnConfigs = [];

      for (let col of this.projectioncolumns) {
        let t = new ColumnConfig();
        t.columnName = col.columnname;
        t.columnOutName = col.columnname;
        t.label = col.columnname;
        t.datatype = col.datatype;
        t.unit = col.unit;
        t.tags = col.tags;
        t.hidden = true;
        t.editMode = BmsDataUtils.getEditMode(t.tags, t.datatype);
        t.enumMembers = t.editMode == BmsEditMode.Enum ? BmsDataUtils.getEnumMembers(t.tags) : [];

        this.columnConfigs.push(t);

        let inCol = new BaseQueryInputColumnDescription();
        inCol.columnname = t.columnName;
        inCol.columnoutname = t.columnOutName;
        this.columns.push(inCol);
      }

      let hiddenCols = [];

      this.showColumns.forEach(sc => {
        let colConfig = this.columnConfigs.find(c => c.columnName == sc.id);
        if (colConfig) {
          colConfig.hidden = false;
          colConfig.label = sc.label;
        }
      });
    }
  }

  async reQuery(query: BaseQuery, queryData: BmsQueryDataColumn[]) {
    try {
      let queryResult = await this.xprojClient.RequestQueryBaseQuery(query, true);

      let numericaldata = queryResult.datanumbers;
      let timestampdata = queryResult.datatimestamps;
      let stringdata = queryResult.datastrings;

      for (let i = 0; i < queryResult.columns.length; i++) {
        let it = queryResult.columns[i];
        let typ = it.datatype;
        let data = [];
        if (typ == XDataType.Number) {
          //data = WidgetUtils.FormatNumbers(numericaldata[it.indexintypedvector], this.globalWidgetSettings.Decimals);
          data = numericaldata[it.indexintypedvector];
        }
        if (typ == XDataType.String) {
          data = stringdata[it.indexintypedvector];
        }
        if (typ == XDataType.Timestamp) {
          data = timestampdata[it.indexintypedvector];
        }

        let colname = it.columnoutname;
        let columnConfig = this.columnConfigs.find(c => c.columnOutName == colname);
        let colData = queryData.find(d => d.columnname == colname);

        if (colData) {
          colData.datatype = it.datatype;
          colData.data = data;
          colData.hidden = columnConfig?.hidden;
        }
        else {
          colData = new BmsQueryDataColumn();
          colData.columnname = colname;
          colData.label = columnConfig.label;
          colData.data = data;
          colData.hidden = columnConfig?.hidden;
          colData.datatype = it.datatype;
          colData.editMode = columnConfig.editMode;
          colData.enumMembers = columnConfig.enumMembers;

          queryData.push(colData);
        }
      }

      this.queryNrRows = queryResult.nrpoints;
      this.queryNrTotalRows = queryResult.nroriginalpoints;
    }
    catch (err) {
      queryData.length = 0;
    }

    if (queryData.length == 0) {
      this.showColumns.forEach(sc => {
        let columnConfig = this.columnConfigs.find(c => c.columnName == sc.id);
        if (columnConfig) {
          let colData = new BmsQueryDataColumn();
          colData.columnname = columnConfig.columnOutName;
          colData.label = columnConfig.label;
          colData.data = [];
          colData.hidden = columnConfig?.hidden;
          colData.datatype = columnConfig.datatype;
          colData.editMode = columnConfig.editMode;
          colData.enumMembers = columnConfig.enumMembers;

          queryData.push(colData);
        }
      });

      this.columnConfigs.filter(c => this.showColumns.findIndex(sc => c.columnName == sc.id) == -1).forEach(columnConfig => {
        let colData = new BmsQueryDataColumn();
        colData.columnname = columnConfig.columnOutName;
        colData.label = columnConfig.label;
        colData.data = [];
        colData.hidden = columnConfig?.hidden;
        colData.datatype = columnConfig.datatype;
        colData.editMode = columnConfig.editMode;
        colData.enumMembers = columnConfig.enumMembers;

        queryData.push(colData);
      });
    }
  }

  async search() {
    this.filterNodes = [];
    if (this.nameFilter?.length > 0) {
      try {
        this.searching = true;
        let request = new SearchNodesRequest();
        request.rootId = this.selectedNodeParent ? this.selectedNodeParent.id : '_x_bms_root';
        request.rootLabel = this.selectedNodeParent ? this.selectedNodeParent.nodeTypeLabel : '_x_bms_root';
        request.limit = 100;
        request.maxHops = -1;
        request.skip = 0;
        request.propertiesOperatorAnd = false;

        let searchProperty = new SearchProperty();
        searchProperty.typeName = 'string';
        searchProperty.key = '_name';
        searchProperty.value = this.nameFilter;
        searchProperty.operator = SearchProperty.SearchPropertyOperator.Contains;
        request.properties.push(searchProperty);

        let identifierProperty = new SearchProperty();
        identifierProperty.typeName = 'string';
        identifierProperty.key = 'seconadaryaddress';
        identifierProperty.value = this.nameFilter;
        identifierProperty.operator = SearchProperty.SearchPropertyOperator.Equals;
        request.properties.push(identifierProperty);

        let devEuiProperty = new SearchProperty();
        devEuiProperty.typeName = 'string';
        devEuiProperty.key = 'deveui';
        devEuiProperty.value = this.nameFilter.toLowerCase();
        devEuiProperty.operator = SearchProperty.SearchPropertyOperator.Equals;
        request.properties.push(devEuiProperty);

        let idProperty = new SearchProperty();
        idProperty.typeName = 'string';
        idProperty.key = '_id';
        idProperty.value = this.nameFilter;
        idProperty.operator = SearchProperty.SearchPropertyOperator.Equals;
        request.properties.push(idProperty);

        this.searchResult = await this.xconfClient.searchNodes(request);

        // if (this.searchResult.nodes.length == 0) {
        //   request = new SearchNodesRequest();
        //   request.rootId = this.selectedNodeParent ? this.selectedNodeParent.id : '_x_datacollectors_root';
        //   request.rootLabel = this.selectedNodeParent ? this.selectedNodeParent.nodeTypeLabel : '_x_datacollectors_root';
        //   request.limit = 100;
        //   request.maxHops = -1;
        //   request.skip = 0;

        //   searchProperty = new SearchProperty();
        //   searchProperty.typeName = 'string';
        //   searchProperty.key = 'subaddress1';
        //   searchProperty.value = this.nameFilter;
        //   searchProperty.operator = SearchProperty.SearchPropertyOperator.Equals;
        //   request.properties.push(searchProperty);

        //   this.searchResult = await this.xconfClient.searchNodes(request);
        // }

        this.searchResult.nodes.forEach(node => {
          this.filterNodes.push(node);
        });
      }
      finally {
        this.searching = false;
      }
    }
    else {
      this.searchResult = null;
      if (this.selectedNode) {
        this.filterNodes.push(this.selectedNode);

        if (this.selectedNode.nodeTypeLabel == '_x_bms_area') {
          let realestateNodes = await this.xconfClient.getReferencedNodes(this.selectedNode.id, this.selectedNode.nodeTypeLabel, [], '_x_bms_realestate', 10);
          realestateNodes.forEach(node => {
            this.filterNodes.push(node);
          });
        }
      }
    }

    this.cache.set(METERLIST_FILTER_CACHEKEY, {nameFilter : this.nameFilter, filterNodes: this.filterNodes, tableData : this.tableData });

    await this.refresh();
  }

  updateFilter() {
    if (this.filterNodes.length > 0) {
      this.filter = new BmsMeterAdminFilter();
      this.filterNodes.forEach(node => {
        if (node.nodeTypeLabel == '_x_bms_realestate') {
          this.filter.realestateIds.push(+node.id);
          if (!this.subordinateNodes) {
            this.filter.buildingids.push(0);
            this.filter.buildingaddressIds.push(0);
            this.filter.apartmentIds.push(0);
            this.filter.facilityIds.push(0);
          }
        }
        else if (node.nodeTypeLabel == '_x_bms_building') {
          this.filter.buildingids.push(+node.id);
          if (!this.subordinateNodes) {
            this.filter.buildingaddressIds.push(0);
            this.filter.apartmentIds.push(0);
            this.filter.facilityIds.push(0);
          }
        }
        else if (node.nodeTypeLabel == '_x_bms_buildingaddress') {
          this.filter.buildingaddressIds.push(+node.id);
          if (!this.subordinateNodes) {
            this.filter.apartmentIds.push(0);
            this.filter.facilityIds.push(0);
          }
        }
        else if (node.nodeTypeLabel == '_x_bms_apartment') {
          this.filter.apartmentIds.push(+node.id);
          this.filter.facilityIds.push(0);
        }
        else if (node.nodeTypeLabel == '_x_bms_facility') {
          this.filter.facilityIds.push(+node.id);
          this.filter.apartmentIds.push(0);
        }
        else if (node.nodeTypeLabel == '_x_bms_meter') {
          this.filter.meterIds.push(+node.id);
        }
      });
    }
    else if (this.inputFilter) {
      this.filter = this.inputFilter;
    }
  }
}
