<button type="button" class="btn btn-link" (click)="all();">
  All
</button>
<button type="button" class="btn btn-link" (click)="none();">
  None
</button>

<clr-checkbox-container>
  <clr-checkbox-wrapper *ngFor="let item of statusList; index as i">
    <input type="checkbox" clrCheckbox [(ngModel)]="item.status" name="status_{{i}}" (change)="statusChanged(item.status, item.flag)"/>
    <label>{{item.text}}</label>
  </clr-checkbox-wrapper>
</clr-checkbox-container>
