import { Injectable } from '@angular/core';
import { BmsBillingTariff } from '@features/bms/models/bms-billing-tariff';
import { BmsMeter } from '@features/bms/models/bms-meter';
import { NGXLogger } from 'ngx-logger';
import { ArrayUtils, BaseQuery, BaseQueryInputColumnDescription, ColumnFilteringNumerical, ColumnFilteringString, ColumnFilteringTimestamp, DateHelper, FilterComparator, FilterLogicalGroup, FilterLogicalGroupType, ProjectionColumnDescription, SetDataColumnDescription, SetDataQuery, XDataType, XProjectorClient } from 'xproj-lib';
import { flakeId } from '../utils/bms-data-utils';
import { XProjectorXConfClient } from '@xprojectorcore/xprojector_backend/xprojector-xconf-client';

export interface BmsTariffParameters {
  customerId?: string,
  realestateId?: number,
  buildingId?: number,
  buildingAddressId?: number,
  apartmentId?: number,
  facilityId?: number,
  meterId?: number,
  activeFrom?: Date,
  maxItems?: number
}

export interface BmsMeterParameters {
  customerId?: string,
  realestateId?: number,
  buildingId?: number,
  buildingAddressId?: number,
  apartmentId?: number,
  facilityId?: number,
  meterId?: number,
  meterType?: string
}

const TARIFFS_PROJECTIONID: string = 'hs-billingtariff';
const METERS_PROJECTIONID: string = 'hs-meter';
const CUSTOMERREALESTATE_PROJECTIONID: string = 'hs-customer-realestate';

@Injectable({
  providedIn: 'root'
})
export class BmsTariffService {

  meterColumns: ProjectionColumnDescription[] = [];

  tariffColumns: ProjectionColumnDescription[] = [];

  customerRealestateColumns: ProjectionColumnDescription[] = [];

  private setColumns: SetDataColumnDescription[] = [
    {
      columnname: 'id',
      datatype: XDataType.Int64,
      indexintypedvector: 0
    },
    {
      columnname: 'realestateid',
      datatype: XDataType.Int64,
      indexintypedvector: 1
    },
    {
      columnname: 'buildingid',
      datatype: XDataType.Int64,
      indexintypedvector: 2
    },
    {
      columnname: 'buildingaddressid',
      datatype: XDataType.Int64,
      indexintypedvector: 3
    },
    {
      columnname: 'apartmentid',
      datatype: XDataType.Int64,
      indexintypedvector: 4
    },
    {
      columnname: 'facilityid',
      datatype: XDataType.Int64,
      indexintypedvector: 5
    },
    {
      columnname: 'meterid',
      datatype: XDataType.Int64,
      indexintypedvector: 6
    },
    {
      columnname: 'createdat',
      datatype: XDataType.Timestamp,
      indexintypedvector: 0
    },
    {
      columnname: 'modifiedat',
      datatype: XDataType.Timestamp,
      indexintypedvector: 1
    },
    {
      columnname: 'deleted',
      datatype: XDataType.UInt8,
      indexintypedvector: 7
    },
    {
      columnname: 'deletedat',
      datatype: XDataType.Timestamp,
      indexintypedvector: 2
    },
    {
      columnname: 'value',
      datatype: XDataType.Float32,
      indexintypedvector: 8
    },
    {
      columnname: 'vatrate',
      datatype: XDataType.Float32,
      indexintypedvector: 9
    },
    {
      columnname: 'activefrom',
      datatype: XDataType.Timestamp,
      indexintypedvector: 3
    },
    {
      columnname: 'currency',
      datatype: XDataType.String,
      indexintypedvector: 0
    },
    {
      columnname: 'includevat',
      datatype: XDataType.UInt8,
      indexintypedvector: 10
    }
  ];

  constructor(
    private xprojClient: XProjectorClient,
    private logger: NGXLogger
  ) {

  }

  async setNewBillingTariffs({
    customerId = undefined,
    realestateId = undefined,
    buildingId = undefined,
    buildingAddressId = undefined,
    apartmentId = undefined,
    facilityId = undefined,
    meterId = undefined,
    meterType: meterType = undefined
  }: BmsMeterParameters, billingTariffs: BmsBillingTariff[]): Promise<{ result: boolean, meterCount: number }> {
    let meters: BmsMeter[];

    if (customerId) {
      meters = await this.getCustomerMeters({ customerId: customerId, meterId: meterId, meterType: meterType });
    }
    else {
      meters = await this.getMeters({
        realestateId: realestateId, buildingId: buildingId, buildingAddressId: buildingAddressId,
        apartmentId: apartmentId, facilityId: facilityId, meterId: meterId, meterType: meterType
      });
    }

    let tariffs = [];

    meters.forEach(meter => {
      billingTariffs.forEach(billingTariff => {
        let tariff = new BmsBillingTariff();
        tariff.realestateId = meter.realestateId;
        tariff.buildingId = meter.buildingId;
        tariff.buildingAddressId = meter.buildingAddressId;
        tariff.apartmentId = meter.apartmentId;
        tariff.facilityId = meter.facilityId;
        tariff.meterId = meter.id;

        tariff.value = billingTariff.value;
        tariff.vatRate = billingTariff.vatRate;
        tariff.includeVat = billingTariff.includeVat;
        tariff.currency = billingTariff.currency;
        tariff.activeFrom = billingTariff.activeFrom;

        tariff.createdAt = new Date();
        tariff.modifiedAt = tariff.createdAt;

        tariffs.push(tariff);
      });
    });

    let result = await this.setBillingTariffs(tariffs);

    return { result: result, meterCount: tariffs.length };
  }

  async deleteBillingTariffs({
    customerId = undefined,
    realestateId = undefined,
    buildingId = undefined,
    buildingAddressId = undefined,
    apartmentId = undefined,
    facilityId = undefined,
    meterId = undefined,
    meterType: meterType = undefined
  }: BmsMeterParameters, deleteFrom: Date): Promise<{ result: boolean, meterCount: number }> {

    let meters: BmsMeter[];

    if (customerId) {
      meters = await this.getCustomerMeters({ customerId: customerId, meterId: meterId, meterType: meterType });
    }
    else {
      meters = await this.getMeters({
        realestateId: realestateId,
        buildingId: buildingId,
        buildingAddressId: buildingAddressId,
        apartmentId: apartmentId,
        facilityId: facilityId,
        meterId: meterId,
        meterType: meterType
      });
    }

    let tariffs = await this.getBillingTariffs({
      customerId: customerId,
      realestateId: realestateId,
      buildingId: buildingId,
      buildingAddressId: buildingAddressId,
      apartmentId: apartmentId,
      facilityId: facilityId,
      meterId: meterId,
      activeFrom: deleteFrom,
      maxItems: 10000
    });

    tariffs = tariffs.filter(tariff => meters.findIndex(m => m.id == tariff.meterId) > -1);

    let now = new Date();
    tariffs.forEach(tariff => {
      tariff.deleted = true;
      tariff.deletedAt = now;
      tariff.modifiedAt = now;
    });

    let result = await this.setBillingTariffs(tariffs);

    return { result: result, meterCount: tariffs.length };
  }

  async getBillingTariffs({
    customerId = undefined,
    realestateId = undefined,
    buildingId = undefined,
    buildingAddressId = undefined,
    apartmentId = undefined,
    facilityId = undefined,
    meterId = undefined,
    activeFrom = undefined,
    maxItems = 10
  }: BmsTariffParameters): Promise<BmsBillingTariff[]> {

    let result: BmsBillingTariff[] = [];
    if (realestateId || buildingId || buildingAddressId || apartmentId || facilityId || meterId || activeFrom) {
      if (this.tariffColumns.length == 0) {
        this.tariffColumns = await this.xprojClient.RequestListQueryableProjectionColumns(TARIFFS_PROJECTIONID, '', 0, 100);
      }

      let query: BaseQuery = new BaseQuery();
      query.targetprojectionid = TARIFFS_PROJECTIONID;
      query.maxitems = maxItems;
      query.targetgroup = [];

      let filterId = 0;
      query.filter.type = FilterLogicalGroupType.AND;

      if (customerId) {
        let realestateIds = await this.getRealestateIds(customerId);
        let realestatesGroup = new FilterLogicalGroup();
        realestatesGroup.type = FilterLogicalGroupType.OR;
        realestatesGroup.queryfilterid = filterId++;

        realestateIds.forEach(id => {
          let idFiltering = new ColumnFilteringNumerical();
          idFiltering.columnname = 'realestateid';
          idFiltering.comparator = FilterComparator.Equals;
          idFiltering.value = id;
          idFiltering.queryfilterid = ++filterId;
          realestatesGroup.filters.push(idFiltering.queryfilterid);
          query.numericalfilters.push(idFiltering);
        });

        query.filter.filters.push(realestatesGroup.queryfilterid);
        query.subfiltergroups.push(realestatesGroup);
      }
      else {
        if (realestateId) {
          let idFiltering = new ColumnFilteringNumerical();
          idFiltering.columnname = 'realestateid';
          idFiltering.comparator = FilterComparator.Equals;
          idFiltering.value = realestateId;
          idFiltering.queryfilterid = ++filterId;
          query.filter.filters.push(idFiltering.queryfilterid);
          query.numericalfilters.push(idFiltering);
        }

        if (buildingId) {
          let idFiltering = new ColumnFilteringNumerical();
          idFiltering.columnname = 'buildingid';
          idFiltering.comparator = FilterComparator.Equals;
          idFiltering.value = buildingId;
          idFiltering.queryfilterid = ++filterId;
          query.filter.filters.push(idFiltering.queryfilterid);
          query.numericalfilters.push(idFiltering);
        }

        if (buildingAddressId) {
          let idFiltering = new ColumnFilteringNumerical();
          idFiltering.columnname = 'buildingaddressid';
          idFiltering.comparator = FilterComparator.Equals;
          idFiltering.value = buildingAddressId;
          idFiltering.queryfilterid = ++filterId;
          query.filter.filters.push(idFiltering.queryfilterid);
          query.numericalfilters.push(idFiltering);
        }

        if (apartmentId) {
          let idFiltering = new ColumnFilteringNumerical();
          idFiltering.columnname = 'apartmentid';
          idFiltering.comparator = FilterComparator.Equals;
          idFiltering.value = apartmentId;
          idFiltering.queryfilterid = ++filterId;
          query.filter.filters.push(idFiltering.queryfilterid);
          query.numericalfilters.push(idFiltering);
        }

        if (facilityId) {
          let idFiltering = new ColumnFilteringNumerical();
          idFiltering.columnname = 'facilityid';
          idFiltering.comparator = FilterComparator.Equals;
          idFiltering.value = facilityId;
          idFiltering.queryfilterid = ++filterId;
          query.filter.filters.push(idFiltering.queryfilterid);
          query.numericalfilters.push(idFiltering);
        }
      }

      if (meterId) {
        let idFiltering = new ColumnFilteringNumerical();
        idFiltering.columnname = 'meterid';
        idFiltering.comparator = FilterComparator.Equals;
        idFiltering.value = meterId;
        idFiltering.queryfilterid = ++filterId;
        query.filter.filters.push(idFiltering.queryfilterid);
        query.numericalfilters.push(idFiltering);
      }

      if (activeFrom) {
        let timestampFiltering = new ColumnFilteringTimestamp();
        timestampFiltering.columnname = 'activefrom';
        timestampFiltering.comparator = FilterComparator.GreatherThanOrEquals;
        timestampFiltering.value = activeFrom;
        timestampFiltering.queryfilterid = ++filterId;
        query.filter.filters.push(timestampFiltering.queryfilterid);
        query.timestampfilters.push(timestampFiltering);
      }

      let deletedFiltering = new ColumnFilteringNumerical();
      deletedFiltering.columnname = 'deleted';
      deletedFiltering.comparator = FilterComparator.Equals;
      deletedFiltering.value = 0;
      deletedFiltering.queryfilterid = ++filterId;
      query.filter.filters.push(deletedFiltering.queryfilterid);
      query.numericalfilters.push(deletedFiltering);

      this.tariffColumns.forEach(c => {
        let inCol = new BaseQueryInputColumnDescription();
        inCol.columnname = c.columnname;
        inCol.columnoutname = c.columnname;
        query.columns.push(inCol);
      });

      query.sorting.columnname = 'activefrom';
      query.sorting.descending = true;

      try {
        let queryResult = await this.xprojClient.RequestQueryBaseQuery(query, true);

        let numericaldata = queryResult.datanumbers;
        let timestampdata = queryResult.datatimestamps;
        let stringdata = queryResult.datastrings;

        let rowCount = 0;
        if (numericaldata.length > 0) {
          rowCount = numericaldata[0].length;
        }

        for (let row = 0; row < rowCount; row++) {
          let tariff = new BmsBillingTariff();
          for (let i = 0; i < queryResult.columns.length; i++) {
            let it = queryResult.columns[i];
            let typ = it.datatype;
            let data = [];
            if (typ == XDataType.Number) {
              data = numericaldata[it.indexintypedvector];
            }
            if (typ == XDataType.String) {
              data = stringdata[it.indexintypedvector];
            }
            if (typ == XDataType.Timestamp) {
              data = timestampdata[it.indexintypedvector];
            }

            switch (it.columnoutname) {
              case 'id':
                tariff.id = data[row];
                break;
              case 'meterid':
                tariff.meterId = data[row];
                break;
              case 'apartmentid':
                tariff.apartmentId = data[row];
                break;
              case 'facilityid':
                tariff.facilityId = data[row];
                break;
              case 'buildingid':
                tariff.buildingId = data[row];
                break;
              case 'buildingaddressid':
                tariff.buildingAddressId = data[row];
                break;
              case 'realestateid':
                tariff.realestateId = data[row];
                break;
              case 'activefrom':
                tariff.activeFrom = data[row];
                break;
              case 'value':
                tariff.value = data[row];
                break;
              case 'currency':
                tariff.currency = data[row];
                break;
              case 'includevat':
                tariff.includeVat = data[row] > 0 ? true : false;
                break;
              case 'vatrate':
                tariff.vatRate = data[row];
                break;
              case 'createdat':
                tariff.createdAt = data[row];
                break;
              case 'modifiedat':
                tariff.modifiedAt = data[row];
                break;
            }
          }

          result.push(tariff);
        }
      }
      catch (err) {
        this.logger.error(err);
      }
    }
    return result;
  }

  async setBillingTariffs(tariffs: BmsBillingTariff[]): Promise<boolean> {
    let query: SetDataQuery = new SetDataQuery();

    await ArrayUtils.AsyncForEach(tariffs, async (tariff) => {
      if (!tariff.id || tariff.id == 0) {
        tariff.id = await flakeId.nextId();
      }
    });

    query.datastrings = [
      tariffs.map(t => t.currency)
    ];
    query.datanumbers = [
      tariffs.map(t => t.id),
      tariffs.map(t => t.realestateId),
      tariffs.map(t => t.buildingId),
      tariffs.map(t => t.buildingAddressId),
      tariffs.map(t => t.apartmentId ?? 0),
      tariffs.map(t => t.facilityId ?? 0),
      tariffs.map(t => t.meterId),
      tariffs.map(t => t.deleted ? 1 : 0),
      tariffs.map(t => t.value),
      tariffs.map(t => t.vatRate),
      tariffs.map(t => t.includeVat ? 1 : 0),
    ];
    query.datatimestamps = [
      tariffs.map(t => t.createdAt ?? new Date()),
      tariffs.map(t => t.modifiedAt ?? new Date()),
      tariffs.map(t => t.deletedAt ?? new Date(0)),
      tariffs.map(t => t.activeFrom ?? new Date())
    ];

    query.projectionid = TARIFFS_PROJECTIONID;
    query.columns = this.setColumns;

    return await this.xprojClient.RequestSetData(query);
  }

  async getCustomerMeters({
    customerId = undefined,
    meterId = undefined,
    meterType: meterType = undefined
  }: BmsMeterParameters): Promise<BmsMeter[]> {
    let result: BmsMeter[] = [];

    try {
      let realestateIds = await this.getRealestateIds(customerId);
      await ArrayUtils.AsyncForEach(realestateIds, async (realestateId) => {
        let meters = await this.getMeters({
          realestateId: realestateId,
          meterId: meterId,
          meterType: meterType
        });
        result = result.concat(meters);
      });
    }
    catch (err) {
      this.logger.error(err);
    }

    return result;
  }

  async getRealestateIds(customerId: string): Promise<number[]> {
    let result: number[] = [];
    if (this.customerRealestateColumns.length == 0) {
      this.customerRealestateColumns = await this.xprojClient.RequestListQueryableProjectionColumns(CUSTOMERREALESTATE_PROJECTIONID, '', 0, 100);
    }

    let query: BaseQuery = new BaseQuery();
    query.targetprojectionid = CUSTOMERREALESTATE_PROJECTIONID;
    query.maxitems = 10000;
    query.targetgroup = [];

    let filterId = 0;
    query.filter.type = FilterLogicalGroupType.AND;

    let idFiltering = new ColumnFilteringString();
    idFiltering.columnname = 'customerid';
    idFiltering.comparator = FilterComparator.Equals;
    idFiltering.value = customerId;
    idFiltering.queryfilterid = ++filterId;
    query.filter.filters.push(idFiltering.queryfilterid);
    query.stringfilters.push(idFiltering);

    let deletedFiltering = new ColumnFilteringNumerical();
    deletedFiltering.columnname = 'deleted';
    deletedFiltering.comparator = FilterComparator.Equals;
    deletedFiltering.value = 0;
    deletedFiltering.queryfilterid = ++filterId;
    query.filter.filters.push(deletedFiltering.queryfilterid);
    query.numericalfilters.push(deletedFiltering);

    this.customerRealestateColumns.filter(c => c.columnname == 'realestateid').forEach(c => {
      let inCol = new BaseQueryInputColumnDescription();
      inCol.columnname = c.columnname;
      inCol.columnoutname = c.columnname;
      query.columns.push(inCol);
    });

    try {
      let queryResult = await this.xprojClient.RequestQueryBaseQuery(query, true);

      let numericaldata = queryResult.datanumbers;
      let timestampdata = queryResult.datatimestamps;
      let stringdata = queryResult.datastrings;

      let rowCount = 0;
      if (numericaldata.length > 0) {
        rowCount = numericaldata[0].length;
      }

      for (let row = 0; row < rowCount; row++) {
        for (let i = 0; i < queryResult.columns.length; i++) {
          let it = queryResult.columns[i];
          let typ = it.datatype;
          let data = [];
          if (typ == XDataType.Number) {
            data = numericaldata[it.indexintypedvector];
          }
          if (typ == XDataType.String) {
            data = stringdata[it.indexintypedvector];
          }
          if (typ == XDataType.Timestamp) {
            data = timestampdata[it.indexintypedvector];
          }

          switch (it.columnoutname) {
            case 'realestateid':
              result.push(data[row]);
              break;
          }
        }
      }
    }
    catch (err) {
      this.logger.error(err);
    }

    return result;
  }

  async getMeters({
    realestateId = undefined,
    buildingId = undefined,
    buildingAddressId = undefined,
    apartmentId = undefined,
    facilityId = undefined,
    meterId = undefined,
    meterType: meterType = undefined
  }: BmsMeterParameters): Promise<BmsMeter[]> {

    let result: BmsMeter[] = [];
    if (realestateId || buildingId || buildingAddressId || apartmentId || facilityId || meterId) {
      if (this.meterColumns.length == 0) {
        this.meterColumns = await this.xprojClient.RequestListQueryableProjectionColumns(METERS_PROJECTIONID, '', 0, 100);
      }

      let query: BaseQuery = new BaseQuery();
      query.targetprojectionid = METERS_PROJECTIONID;
      query.maxitems = 10000;
      query.targetgroup = [];

      let filterId = 0;
      query.filter.type = FilterLogicalGroupType.AND;

      if (realestateId) {
        let idFiltering = new ColumnFilteringNumerical();
        idFiltering.columnname = 'realestateid';
        idFiltering.comparator = FilterComparator.Equals;
        idFiltering.value = realestateId;
        idFiltering.queryfilterid = ++filterId;
        query.filter.filters.push(idFiltering.queryfilterid);
        query.numericalfilters.push(idFiltering);
      }

      if (buildingId) {
        let idFiltering = new ColumnFilteringNumerical();
        idFiltering.columnname = 'buildingid';
        idFiltering.comparator = FilterComparator.Equals;
        idFiltering.value = buildingId;
        idFiltering.queryfilterid = ++filterId;
        query.filter.filters.push(idFiltering.queryfilterid);
        query.numericalfilters.push(idFiltering);
      }

      if (buildingAddressId) {
        let idFiltering = new ColumnFilteringNumerical();
        idFiltering.columnname = 'buildingaddressid';
        idFiltering.comparator = FilterComparator.Equals;
        idFiltering.value = buildingAddressId;
        idFiltering.queryfilterid = ++filterId;
        query.filter.filters.push(idFiltering.queryfilterid);
        query.numericalfilters.push(idFiltering);
      }

      if (apartmentId) {
        let idFiltering = new ColumnFilteringNumerical();
        idFiltering.columnname = 'apartmentid';
        idFiltering.comparator = FilterComparator.Equals;
        idFiltering.value = apartmentId;
        idFiltering.queryfilterid = ++filterId;
        query.filter.filters.push(idFiltering.queryfilterid);
        query.numericalfilters.push(idFiltering);
      }

      if (facilityId) {
        let idFiltering = new ColumnFilteringNumerical();
        idFiltering.columnname = 'facilityid';
        idFiltering.comparator = FilterComparator.Equals;
        idFiltering.value = facilityId;
        idFiltering.queryfilterid = ++filterId;
        query.filter.filters.push(idFiltering.queryfilterid);
        query.numericalfilters.push(idFiltering);
      }

      if (meterId) {
        let idFiltering = new ColumnFilteringNumerical();
        idFiltering.columnname = 'id';
        idFiltering.comparator = FilterComparator.Equals;
        idFiltering.value = meterId;
        idFiltering.queryfilterid = ++filterId;
        query.filter.filters.push(idFiltering.queryfilterid);
        query.numericalfilters.push(idFiltering);
      }

      if (meterType) {
        let meterTypeFiltering = new ColumnFilteringString();
        meterTypeFiltering.columnname = 'metertype';
        meterTypeFiltering.comparator = FilterComparator.Equals;
        meterTypeFiltering.value = meterType;
        meterTypeFiltering.queryfilterid = ++filterId;
        query.filter.filters.push(meterTypeFiltering.queryfilterid);
        query.stringfilters.push(meterTypeFiltering);
      }

      let deletedFiltering = new ColumnFilteringNumerical();
      deletedFiltering.columnname = 'deleted';
      deletedFiltering.comparator = FilterComparator.Equals;
      deletedFiltering.value = 0;
      deletedFiltering.queryfilterid = ++filterId;
      query.filter.filters.push(deletedFiltering.queryfilterid);
      query.numericalfilters.push(deletedFiltering);

      this.meterColumns.forEach(c => {
        let inCol = new BaseQueryInputColumnDescription();
        inCol.columnname = c.columnname;
        inCol.columnoutname = c.columnname;
        query.columns.push(inCol);
      });

      try {
        let queryResult = await this.xprojClient.RequestQueryBaseQuery(query, true);

        let numericaldata = queryResult.datanumbers;
        let timestampdata = queryResult.datatimestamps;
        let stringdata = queryResult.datastrings;

        let rowCount = 0;
        if (numericaldata.length > 0) {
          rowCount = numericaldata[0].length;
        }

        for (let row = 0; row < rowCount; row++) {
          let meter = new BmsMeter();
          for (let i = 0; i < queryResult.columns.length; i++) {
            let it = queryResult.columns[i];
            let typ = it.datatype;
            let data = [];
            if (typ == XDataType.Number) {
              data = numericaldata[it.indexintypedvector];
            }
            if (typ == XDataType.String) {
              data = stringdata[it.indexintypedvector];
            }
            if (typ == XDataType.Timestamp) {
              data = timestampdata[it.indexintypedvector];
            }

            switch (it.columnoutname) {
              case 'id':
                meter.id = data[row];
                break;
              case 'subaddress1':
                meter.identifier = data[row];
                break;
              case 'apartmentid':
                meter.apartmentId = data[row];
                break;
              case 'facilityid':
                meter.facilityId = data[row];
                break;
              case 'buildingid':
                meter.buildingId = data[row];
                break;
              case 'buildingaddressid':
                meter.buildingAddressId = data[row];
                break;
              case 'realestateid':
                meter.realestateId = data[row];
                break;
              case 'subaddress2':
                meter.manufacturer = data[row];
                break;
              case 'unit':
                meter.unit = data[row];
                break;
              case 'state':
                meter.state = data[row];
                break;
              case 'model':
                meter.model = data[row];
                break;
              case 'metertype':
                meter.meterType = data[row];
                break;
              case 'tariffgroup':
                meter.tariffGroup = data[row];
                break;
              case 'metersubtype':
                meter.meterSubtype = data[row];
                break;
              case 'typeid':
                meter.typeId = data[row];
                break;
              case 'coeffiecent':
                meter.coeffiecent = data[row];
                break;
              case 'createdat':
                meter.createdAt = data[row];
                break;
              case 'modifiedat':
                meter.modifiedAt = data[row];
                break;
              case 'systemid1':
                meter.systemId1 = data[row];
                break;
              case 'systemid2':
                meter.systemId2 = data[row];
                break;
              case 'systemid3':
                meter.systemId3 = data[row];
                break;
            }
          }

          result.push(meter);
        }
      }
      catch (err) {
        this.logger.error(err);
      }
    }
    return result;
  }
}
