import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import { XProjectorBmsEventsClient } from '@core/xprojector_backend/xprojector-bms-events-client';
import { NGXLogger } from 'ngx-logger';
import { DateHelper, XprojModalService } from 'xproj-lib';
import { EscalationInfo, Event, EventUser } from '@core/xprojector_backend/proto/xprojector.modulebms.events.pb';
import { StateService } from '@xprojectorcore/services/state-service';
import { Subscription, timer } from 'rxjs';
import { ClrDatagridComparatorInterface, ClrDatagridSortOrder, ClrLoadingState } from '@clr/angular';
import { XProjectorSysAdminClient } from '@xprojectorcore/xprojector_backend/xprojector-sysadmin-client';
import { Customer } from '@xprojectorcore/xprojector_backend/proto/xprojector.grpc.models.pb';
import * as XLSX from 'xlsx';

export enum BmsEventStatus {
  None = 0,
  New = 1,
  Open = 2,
  Closed = 4,
  Muted = 8
}

export class BmsCustomerEventsEvent {
  event: Event;
  class: string;
  statusText: string;
  status: BmsEventStatus;
  customerName: string;
  customerId: string;
  gateway: string;
  gatewayId: number;
  customer: Customer;
  editMode: boolean = false;
  copyComment: string = '';
}

class ExportEvent {
  Customer: string;
  Gateway: string;
  Message: string;
  Time: string;
  Status: string;
  Comment: string;
}

class EventTimeComparator implements ClrDatagridComparatorInterface<BmsCustomerEventsEvent> {
  compare(a: BmsCustomerEventsEvent, b: BmsCustomerEventsEvent) {
    if (a && b && a.event.timestamp && b.event.timestamp) {
      return a.event.timestamp.toDate() > b.event.timestamp.toDate() ? 1 : -1;
    }
    else {
      return a ? 1 : -1
    }
  }
}

@Component({
  selector: 'app-bms-gateway-events',
  templateUrl: './bms-gateway-events.component.html',
  styleUrls: ['./bms-gateway-events.component.scss']
})
export class BmsGatewayEventsComponent implements OnInit, OnDestroy {

  eventsCustomerId: string = 'admin_gateway_offline';

  descSort = ClrDatagridSortOrder.DESC;
  eventTimeSort = new EventTimeComparator();

  events: BmsCustomerEventsEvent[] = [];
  escalationInfos: EscalationInfo[] = [];
  customers: Customer[] = [];

  // private updateTimerSource;
  // private updateTimerSubscription: Subscription;

  loadingEvents: ClrLoadingState = ClrLoadingState.DEFAULT;
  saveInProgress: boolean = false;

  constructor(
    private logger: NGXLogger,
    private state: StateService,
    private modalService: XprojModalService,
    private eventsClient: XProjectorBmsEventsClient,
    private sysAdminClient: XProjectorSysAdminClient,
    public dateHelper: DateHelper
  ) {
  }

  async ngOnInit() {
    this.updateEvents();

    // this.updateTimerSource = timer(60000, 60000);
    // this.updateTimerSubscription = this.updateTimerSource.subscribe($event => {
    //   this.updateEvents();
    // });

  }

  ngOnDestroy(): void {
    //this.updateTimerSubscription?.unsubscribe();
  }

  async initPage() {
    await this.updateEvents();
  }

  async updateEvents() {
    try {
      this.loadingEvents = ClrLoadingState.LOADING;

      let events = await this.eventsClient.getEvents(this.eventsCustomerId);
      this.customers = await this.sysAdminClient.getCustomers();

      let activeCount: number = 0;
      events.forEach(e => {
        let bmsEvent = new BmsCustomerEventsEvent();
        bmsEvent.customerId = e.location;
        let customer = this.customers.find(x => x.id == bmsEvent.customerId);
        bmsEvent.customerName = customer?.name ?? bmsEvent.customerId;
        let messageSplits = e.message.split('|');
        if (messageSplits.length > 1) {
          bmsEvent.gateway = messageSplits[0];
          e.message = messageSplits[1];
        }
        try {
          bmsEvent.gatewayId = +e.meterId;
        }
        catch {
          bmsEvent.gatewayId = -1;
        }


        bmsEvent.customer = customer;
        bmsEvent.event = e;
        bmsEvent.class = e.muted ? 'dot-muted' : e.active ? (e.acknowledged ? 'dot-open' : 'dot-new') : 'dot-closed';
        bmsEvent.status = e.muted ? BmsEventStatus.Muted : e.active ? (e.acknowledged ? BmsEventStatus.Open : BmsEventStatus.New) : BmsEventStatus.Closed;
        bmsEvent.statusText = e.muted ? 'Muted' : e.active ? (e.acknowledged ? 'Open' : 'New') : 'Closed';
        if (e.active) {
          activeCount++;
        }

        let ev = this.events.find(x => x.event.id == e.id);
        if (ev) {
          ev.event = e;
          ev.class = bmsEvent.class;
          ev.status = bmsEvent.status;
          ev.statusText = bmsEvent.statusText;
        }
        else {
          this.events.push(bmsEvent);
        }
      });
    }
    finally {
      this.loadingEvents = ClrLoadingState.DEFAULT;
    }
    //this.events.sort((a, b) => !a.event.active ? 1 : -1);
  }

  async acknowledgeEvent(bmsEvent: BmsCustomerEventsEvent) {
    if (bmsEvent) {
      let result = await this.modalService.ShowInputModalAsync({
        header: 'Acknowledge',
        description: 'Input comment:',
        value: '',
        ok: 'Acknowledge',
        cancel: 'Cancel',
      });
      if (result.result) {
        await this.eventsClient.acknowledgeEvent(bmsEvent.event.customerId, bmsEvent.event.id, result.value, this.state.userId, '_x_users_user');
        this.updateEvents();
      }
    }
  }

  async closeEvent(bmsEvent: BmsCustomerEventsEvent) {
    if (bmsEvent) {
      let result = await this.modalService.ShowInputModalAsync({
        header: 'Close event',
        description: 'Input comment:',
        value: '',
        ok: 'Close event',
        cancel: 'Cancel',
      });
      if (result.result) {
        await this.eventsClient.clearEvent(bmsEvent.event.customerId, bmsEvent.event.id, result.value, this.state.userId, '_x_users_user');
        this.updateEvents();
      }
    }
  }

  async muteEvent(bmsEvent: BmsCustomerEventsEvent) {
    if (bmsEvent) {
      let result = await this.modalService.ShowInputModalAsync({
        header: 'Mute event',
        description: 'Input comment:',
        value: '',
        ok: 'Mute event',
        cancel: 'Cancel',
      });
      if (result.result) {
        await this.eventsClient.muteEvent(bmsEvent.event.customerId, bmsEvent.event.id, result.value, this.state.userId, '_x_users_user');
        this.updateEvents();
      }
    }
  }

  async unmuteEvent(bmsEvent: BmsCustomerEventsEvent) {
    if (bmsEvent) {
      let result = await this.modalService.ShowInputModalAsync({
        header: 'Unmute event',
        description: 'Input comment:',
        value: '',
        ok: 'Unmute event',
        cancel: 'Cancel',
      });
      if (result.result) {
        await this.eventsClient.unmuteEvent(bmsEvent.event.customerId, bmsEvent.event.id, result.value, this.state.userId, '_x_users_user');
        this.updateEvents();
      }
    }
  }

  editComment(bmsEvent: BmsCustomerEventsEvent) {
    if (bmsEvent) {
      bmsEvent.copyComment = bmsEvent.event.comment;
      bmsEvent.editMode = true;
    }
  }

  async saveComment(bmsEvent: BmsCustomerEventsEvent) {
    if (bmsEvent) {
      bmsEvent.event.comment = bmsEvent.copyComment;

      let result = await this.eventsClient.updateEvent(bmsEvent.event);
      if (result) {
        bmsEvent.editMode = false;
      }
    }
  }

  async exportEvents() {
    var headers: string[] = ['Customer', 'Gateway', 'Message', 'Time', 'Status', 'Comment'];

    let exportEvents: ExportEvent[] = []

    this.events.forEach(x => {
      let exportEvent: ExportEvent = new ExportEvent();
      exportEvent.Customer = x.customerName;
      exportEvent.Gateway = x.gateway;
      exportEvent.Message = x.event.message;
      exportEvent.Time = this.dateHelper.utils.format(x.event.timestamp.toDate(), 'keyboardDateTime');
      exportEvent.Status = x.statusText;
      exportEvent.Comment = x.event.comment;
      exportEvents.push(exportEvent);
    });

    const ws: XLSX.WorkSheet = XLSX.utils.json_to_sheet(exportEvents, { header: headers });

    const wb: XLSX.WorkBook = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(wb, ws, 'Gateway status');

    let filename: string = 'gateway_status.xlsx';

    XLSX.writeFile(wb, filename);
  }
}
