import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { GrpcNode } from '@xprojectorcore/xprojector_backend/proto/xprojector.grpc.models.pb';
import { XProjectorXConfClient } from '@xprojectorcore/xprojector_backend/xprojector-xconf-client';
import { DateHelper, XprojAlertService, XProjectorClient } from 'xproj-lib';
import { BmsRealestate } from '@features/bms/models/bms-realestate';
import { BmsDataUtils } from '@features/bms/bms-admin/utils/bms-data-utils';

@Component({
  selector: 'app-bms-realestate-view',
  templateUrl: './bms-realestate-view.component.html',
  styleUrls: ['./bms-realestate-view.component.scss']
})
export class BmsRealestateViewComponent implements OnInit {

  realestate: BmsRealestate = null;
  realestateNode: GrpcNode;
  customerId : string;

  constructor(
    private router: Router,
    private xconfClient: XProjectorXConfClient,
    public dateHelper: DateHelper
  ) { }

  ngOnInit(): void {
  }

  async update(realestateId: number, customerId : string) {
    this.customerId = customerId;
    if (realestateId && realestateId > 0) {
      this.realestateNode = await this.xconfClient.getNode(realestateId.toString(), '_x_bms_realestate');
      if (this.realestateNode) {
        this.realestate = new BmsRealestate();
        this.realestateNode.propertyValues.forEach(p => {
          switch (p.key) {

            case 'svlantpropertydesignation':
              this.realestate.svlantPropertyDesignation = BmsDataUtils.getValue(p.valueType, p.value, p, this.dateHelper);
              break;
            case 'description':
              this.realestate.description = BmsDataUtils.getValue(p.valueType, p.value, p, this.dateHelper);
              break;
            case 'latitude':
              this.realestate.latitude = BmsDataUtils.getValue(p.valueType, p.value, p, this.dateHelper);
              break;
            case 'longitude':
              this.realestate.longitude = BmsDataUtils.getValue(p.valueType, p.value, p, this.dateHelper);
              break;
            case 'createdat':
              this.realestate.createdAt = BmsDataUtils.getValue(p.valueType, p.value, p, this.dateHelper);
              break;
            case 'modifiedat':
              this.realestate.modifiedAt = BmsDataUtils.getValue(p.valueType, p.value, p, this.dateHelper);
              break;

          }
        });
      }
    }
    else {
      this.realestate = null;
    }
  }

  async viewInBMS() {
    this.router.navigateByUrl('/bmsadmin/customers/' + this.customerId + '/bms/' + this.realestateNode.id + '/' + this.realestateNode.nodeTypeLabel);
  }

}
