
export class BmsInvoiceInfo {
  id : number;
  customerId : string;
  meterType : string;
  activeFrom : Date;
  createdAt : Date;
  modifiedAt : Date;
  deleted : boolean;
  deletedAt : Date;
  invoiceDisabled : boolean;

  activeFromString : string;
}
