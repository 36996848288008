import { Component, OnInit } from '@angular/core';
import { BmsTrustee } from '@features/bms/models/bms-trustee';
import { XProjectorBmsExportClient } from '@core/xprojector_backend/xprojector-bms-export-client';
import { GrpcNode } from '@xprojectorcore/xprojector_backend/proto/xprojector.grpc.models.pb';
import { SearchNodesRequest, SearchProperty } from '@xprojectorcore/xprojector_backend/proto/xprojector.xconf.pb';
import { XProjectorXConfClient } from '@xprojectorcore/xprojector_backend/xprojector-xconf-client';
import { ClrLoadingState } from '@clr/angular';

@Component({
  selector: 'app-bms-metering-api',
  templateUrl: './bms-metering-api.component.html',
  styleUrls: ['./bms-metering-api.component.scss']
})
export class BmsMeteringApiComponent implements OnInit {

  trustees: BmsTrustee[] = [];
  selectedTrustee : BmsTrustee;
  meterLoadingState : ClrLoadingState = ClrLoadingState.DEFAULT;

  filteredCustomers: GrpcNode[] = [];

  constructor(
    private bmsExportClient: XProjectorBmsExportClient,
    private xConfClient: XProjectorXConfClient) { }

  async ngOnInit() {
    this.updateTrustees();
  }

  async getMetersReport() {
    if (this.selectedTrustee) {
      try {
        this.meterLoadingState = ClrLoadingState.LOADING;
        this.bmsExportClient.getMeteringApiFile(this.selectedTrustee.id, this.selectedTrustee.name);
      }
      finally {
        this.meterLoadingState = ClrLoadingState.SUCCESS;
      }
    }
  }

  async updateTrustees() {
    this.meterLoadingState = ClrLoadingState.LOADING;
    try {
      let request = new SearchNodesRequest();
      request.rootId = '_x_xconf_customers';
      request.rootLabel = '_x_datasource';
      request.label = '_x_bms_customerconfig_root';
      let p = new SearchProperty();
      p.key = 'customeristrustee';
      p.value = 'true';
      p.typeName = 'boolean';

      request.properties = [p];
      request.propertiesOperatorAnd = true;
      request.limit = 500;
      request.maxHops = 5;
      request.skip = 0;

      let trusteeConfigNodes = await this.xConfClient.searchNodes(request);

      let customrIds: string[] = [];
      trusteeConfigNodes.nodes.forEach(node => {
        let i = node.id.lastIndexOf('_');
        customrIds.push(node.id.substring(i + 1));
      });

      request.rootId = '_x_xconf_customers';
      request.rootLabel = '_x_datasource';
      request.label = '_x_datasource';
      request.properties = [];
      request.limit = 500;
      request.maxHops = 1;
      request.skip = 0;
      request.propertiesOperatorAnd = false;

      customrIds.forEach(id => {
        let p = new SearchProperty();
        p.key = '_id';
        p.value = id;
        p.typeName = 'string';
        request.properties.push(p);
      });

      let customerNodes = await this.xConfClient.searchNodes(request);

      this.trustees = [];
      customerNodes.nodes.forEach(node => {
        let trustee = new BmsTrustee();
        trustee.id = node.id;
        trustee.name = node.name;
        this.trustees.push(trustee);
      });

      this.trustees.sort((a, b) => a.name > b.name ? 1 : -1);
    }
    finally {
      this.meterLoadingState = ClrLoadingState.DEFAULT;
    }
  }
}
