import { EventsClient } from './proto/xprojector.modulebms.events.pbsc';
import { Injectable, OnDestroy, OnInit } from '@angular/core';
import { StateService } from '@xprojectorcore/services/state-service';
import { Timestamp } from './proto/google/protobuf/timestamp.pb';
import { BmsBillingPeriod } from '../models/bms-export-bot';
import { AcknowledgeEventRequest, ClearEventRequest, GetEventRequest, Event, UpdateEventRequest, GetEventsRequest, GetEventSettingsRequest, EscalationInfo, UpdateEventSettingsRequest, MuteEventRequest, UnmuteEventRequest, GetEventsHistoryRequest, EventHistory, GetEventResponse, UnsubscribeEventsRequest, SendSmsRequest } from './proto/xprojector.modulebms.events.pb';

@Injectable({
  providedIn: 'root'
})
export class XProjectorBmsEventsClient implements OnInit, OnDestroy {

  constructor(
    private eventsClient: EventsClient,
    private state: StateService) {
  }

  ngOnDestroy(): void {
  }

  ngOnInit(): void {

  }

  async acknowledgeEvent(customerId: string, eventId: string, comment : string, userId : string, userTypeId : string) : Promise<boolean> {
    let request: AcknowledgeEventRequest = new AcknowledgeEventRequest({
      customerId: customerId,
      eventId: eventId,
      comment : comment,
      user : userId,
      userTypeId : userTypeId
    });

    let result = await this.eventsClient.acknowledgeEvent(request, this.state.metadata).toPromise();

    return result.ok;
  }

  async clearEvent(customerId: string, eventId: string, comment : string, userId : string, userTypeId : string) : Promise<boolean> {
    let request: ClearEventRequest = new ClearEventRequest({
      customerId: customerId,
      eventId: eventId,
      comment : comment,
      user : userId,
      userTypeId : userTypeId
    });

    let result = await this.eventsClient.clearEvent(request, this.state.metadata).toPromise();

    return result.ok;
  }

  async muteEvent(customerId: string, eventId: string, comment : string, userId : string, userTypeId : string) : Promise<boolean> {
    let request: MuteEventRequest = new MuteEventRequest({
      customerId: customerId,
      eventId: eventId,
      comment : comment,
      user : userId,
      userTypeId : userTypeId
    });

    let result = await this.eventsClient.muteEvent(request, this.state.metadata).toPromise();

    return result.ok;
  }

   async unmuteEvent(customerId: string, eventId: string, comment : string, userId : string, userTypeId : string) : Promise<boolean> {
    let request: UnmuteEventRequest = new UnmuteEventRequest({
      customerId: customerId,
      eventId: eventId,
      comment : comment,
      user : userId,
      userTypeId : userTypeId
    });

    let result = await this.eventsClient.unmuteEvent(request, this.state.metadata).toPromise();

    return result.ok;
  }

  async unsubscribeEvents(customerId: string, email: string, phone : string) : Promise<boolean> {
    let request: UnsubscribeEventsRequest = new UnsubscribeEventsRequest({
      customerId: customerId,
      email: email,
      phone : phone
    });

    let result = await this.eventsClient.unsubscribeEvents(request, this.state.metadata).toPromise();

    return result.ok;
  }

  async getEvent(customerId: string, eventId: string) : Promise<GetEventResponse> {
    let request: GetEventRequest = new GetEventRequest({
      customerId: customerId,
      eventId: eventId
    });

    return await this.eventsClient.getEvent(request, this.state.metadata).toPromise();
  }

  async updateEvent(event: Event) : Promise<boolean> {
    let request: UpdateEventRequest = new UpdateEventRequest({
      event: event
    });

    let result = await this.eventsClient.updateEvent(request, this.state.metadata).toPromise();

    return result.ok;
  }

  async getEvents(customerId: string) : Promise<Event[]> {
    let request: GetEventsRequest = new GetEventsRequest({
      customerId: customerId
    });

    let result = await this.eventsClient.getEvents(request, this.state.metadata).toPromise();

    return result.events;
  }

  async getEventsHistory(customerId: string, eventObjectId: string, from: Date = null, to: Date = null, maxItems : number = 100 ) : Promise<EventHistory[]> {
    let request: GetEventsHistoryRequest = new GetEventsHistoryRequest({
      customerId: customerId,
      eventObjectId: eventObjectId,
      from : from ? Timestamp.fromDate(from) : null,
      to : to ? Timestamp.fromDate(to) : null,
      maxItems : maxItems
    });

    let result = await this.eventsClient.getEventsHistory(request, this.state.metadata).toPromise();

    return result.events;
  }

  async getEventSettings(customerId: string) : Promise<EscalationInfo[]> {
    let request: GetEventSettingsRequest = new GetEventSettingsRequest({
      customerId: customerId
    });

    let result = await this.eventsClient.getEventSettings(request, this.state.metadata).toPromise();

    return result.escalationInfos;
  }

  async updateEventSettings(customerId: string, escalationInfos: EscalationInfo[]) : Promise<boolean> {
    let request: UpdateEventSettingsRequest = new UpdateEventSettingsRequest({
      customerId: customerId,
      escalationInfos: escalationInfos
    });

    let result = await this.eventsClient.updateEventSettings(request, this.state.metadata).toPromise();

    return result.ok;
  }

  async sendSmsMessage(to : string, message : string, customerId: string = '', userId : string = '', userTypeId : string = '') : Promise<{ok : boolean, message : string}> {
    let request: SendSmsRequest = new SendSmsRequest({
      to: to,
      message: message,
      customerId: customerId,
      userId: userId,
      userTypeId: userTypeId

    });

    let result = await this.eventsClient.sendSms(request, this.state.metadata).toPromise();

    return {ok : result.ok, message: result.message};
  }
}
