<clr-modal [(clrModalOpen)]="showEditResidentExchange" [clrModalClosable]="false">
  <h3 class="modal-title">Edit resident exchange</h3>
  <div class="modal-body">
    <form clrForm *ngIf="selectedResidentExchange">

      <clr-input-container>
        <label>Resident exchange date:</label>
        <input clrInput type="date" step="1" placeholder="" name="setexchangedate" [(ngModel)]="selectedExchangeDateString" />
      </clr-input-container>
    </form>
  </div>
  <div class="modal-footer">
    <button type="button" class="btn btn-danger-outline" (click)="showEditResidentExchange = false; deleteTariff(selectedResidentExchange)">Delete</button>
    <button type="button" class="btn btn-outline" (click)="showEditResidentExchange = false">Cancel</button>
    <button type="button" class="btn btn-primary"
      (click)="showEditResidentExchange = false; onExecuteEditBillingTariff()">Ok</button>
  </div>
</clr-modal>

<table class="table ">
  <caption>
    Resident exchange info
  </caption>
  <thead>
    <tr>
      <th class="left">Exchange date</th>
      <th *ngIf="allowEdit"  class="left"></th>
    </tr>
  </thead>
  <tbody>
    <tr *ngFor="let residentExchange of residentExchanges">
      <td class="left" >{{dateHelper.utils.format(residentExchange.exchangeDate, 'keyboardDate')}}</td>
      <td *ngIf="allowEdit"><clr-icon shape="pencil" (click)="editResidentExchange(residentExchange)" [style.cursor]="'pointer'"></clr-icon></td>
    </tr>
  </tbody>
</table>
