import { Component, OnInit, inject } from '@angular/core';
import { BmsDataCollectorViewData } from '@core/models/bms-data-collector-view-data';
import { DashboardOutputChangeParameters, XprojAlertService, ArrayUtils, DateHelper, XprojModalService } from 'xproj-lib';
import { BmsDataCollectorComponent } from '@features/bms/bms-admin/bms-data-collector-view.component';
import { XProjectorXConfClient } from '@xprojectorcore/xprojector_backend/xprojector-xconf-client';
import { FileInfo } from '@xprojectorcore/models/file-info';
import { XProjectorFilesClient } from '@xprojectorcore/xprojector_backend/xprojector-files-client';
import { saveAs } from 'file-saver';
import { GrpcNode } from '@xprojectorcore/xprojector_backend/proto/xprojector.grpc.models.pb';
import { BmsDataExportBotExecution } from '@features/bms/models/bms-data-export-bot';
import { XbotExecutionService } from '@xprojectorcore/services/xbot-execution.service';
import { StateService } from '@xprojectorcore/services/state-service';
import { XbotExecutionResult } from '@xprojectorcore/models/xbot-execution-queue-item';
import { NGXLogger } from 'ngx-logger';
import { XProjectorBmsCustomerAdminClient } from '@app/core/xprojector_backend/xprojector-bms-customeradmin-client';
import { GrpcMeter } from '@app/core/xprojector_backend/proto/xprojector.modulebms.customeradmin.pb';
import { BmsDataUtils } from '@features/bms/bms-admin/utils/bms-data-utils';

@Component({
  selector: 'app-bms-data-export-bot-data-collector-view',
  templateUrl: './bms-data-export-bot-data-collector-view.component.html',
  styleUrls: ['./bms-data-export-bot-data-collector-view.component.scss']
})
export class BmsDataExportBotDataCollectorViewComponent implements OnInit, BmsDataCollectorComponent {

  private filesClient: XProjectorFilesClient = inject(XProjectorFilesClient);
  private xconfClient: XProjectorXConfClient = inject(XProjectorXConfClient);
  private alertService: XprojAlertService = inject(XprojAlertService);
  public dateHelper: DateHelper = inject(DateHelper);
  private xbotExecutionService: XbotExecutionService = inject(XbotExecutionService);
  private state: StateService = inject(StateService);
  private logger: NGXLogger = inject(NGXLogger);
  private modalService: XprojModalService = inject(XprojModalService);
  private bmsCustomerAdminClient: XProjectorBmsCustomerAdminClient = inject(XProjectorBmsCustomerAdminClient);

  public static NodeTypeId : string = '_x_bms_node_dataexportconfig';

  data: BmsDataCollectorViewData;
  visible: boolean;

  responsiveWidth : number = 600;

  meterParameters : DashboardOutputChangeParameters[] = [];

  execActive : boolean = true;
  logssActive : boolean = false;
  metersActive : boolean = false;

  viewMeter : boolean = false;
  meterDashboardOutputParameters: DashboardOutputChangeParameters[] = [];

  fileInfos : FileInfo[] = [];

  dataExportBotExecution : BmsDataExportBotExecution = new BmsDataExportBotExecution();
  botRunning: boolean = false;
  execStatusMessage: string = '';

  loadingMeters: boolean = false;
  meters : GrpcMeter[] = [];
  meterTypes : string[] = [];
  facilityType : string = '';
  includeAllCustomerRealestates : boolean = false;

  constructor() { }

  ngOnInit(): void {
  }


  async initDataCollectorView() {
    this.responsiveWidth = this.data.width;

    await this.updateFileInfos();
    this.updateMeters();
  }

  setWidth(width: number) {
    if (this.data) {
      this.data.width = width;
    }

    this.responsiveWidth = width;
  }

  async updateMeters() {
    try {
      this.loadingMeters = true;
      this.meterTypes = BmsDataUtils.getParamterValue(this.data.node, 'metertypes', this.dateHelper);
      this.facilityType = BmsDataUtils.getParamterValue(this.data.node, 'facilitytype', this.dateHelper);
      this.includeAllCustomerRealestates = BmsDataUtils.getParamterValue(this.data.node, 'includeallcustomerrealestates', this.dateHelper);

      this.meters = await this.bmsCustomerAdminClient.getMeters(this.data.customer.id, this.data.node.id, this.data.node.nodeTypeLabel,
        this.meterTypes, this.facilityType, this.includeAllCustomerRealestates);
    }
    finally {
      this.loadingMeters = false;
    }
  }

  async updateFileInfos() {
    this.fileInfos = [];
    let fileNodes = await this.xconfClient.getReferencedNodes(this.data.node.id, this.data.node.nodeTypeLabel, [], '_x_node_dataexportoutput', 1);

    await ArrayUtils.AsyncForEach(fileNodes, async (node : GrpcNode) => {
      var fileInfo = await this.filesClient.getFileInfo('lastoutputfile_' + node.id, this.data.customer.id);
      this.fileInfos.push(fileInfo);
    });

  }

  displayMeter(meterId : string) {
    this.meterDashboardOutputParameters = [];

    let out = new DashboardOutputChangeParameters();
    out.outputParameterName = 'id';
    out.value = meterId;
    this.meterDashboardOutputParameters.push(out);

    this.viewMeter = true;
  }

  async downloadFile(fileInfo : FileInfo) {
    if (this.data && fileInfo) {
      this.filesClient.getFile(fileInfo.id, this.data.customer.id)
      .then(blob => {
        saveAs(blob, fileInfo.name);
      })
      .catch(error => {
        this.alertService.error(error);
      });
    }
  }

  async executeBot(outputEnabled : boolean) {

    let doExecute = true;
    if (outputEnabled) {
      doExecute = await this.modalService.ShowConfirmModalAsync({
        header: 'Execute',
        description: 'Execute, are you sure?',
        ok: 'Execute',
        cancel: 'Cancel'
      });
    }

    if (doExecute) {
      this.execStatusMessage = '';
      this.dataExportBotExecution.configNodeId = this.data.node.id;
      this.dataExportBotExecution.outputEnabled = outputEnabled;

      this.botRunning = true;
      this.xbotExecutionService.executeBot('bms_dataexport', JSON.stringify(this.dataExportBotExecution), 1800, 100, 'bms-data-export-bot-data-collector-view', this.state.userId).subscribe(
        result => {
          if (result.result == XbotExecutionResult.STATUS) {
            this.execStatusMessage = result.message;
            this.logger.info(result.message);
          }
          else if (result.result == XbotExecutionResult.OK) {
            this.alertService.info(result.message);
            this.logger.info(result.message);
          }
          else if (result.result == XbotExecutionResult.CANCELED) {
            this.alertService.error('Execution canceled.');
            this.logger.info(result.message);
          }
          else {
            this.alertService.error(result.message);
            this.logger.info(result.message);
          }
        },
        error => {
          this.botRunning = false;
          this.alertService.error(error.message);
          this.logger.info(error.message);
        },
        () => {
          this.botRunning = false;
          this.updateFileInfos();
        }
      )
    }
  }
}
