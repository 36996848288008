import { Component, HostListener, inject, Input, OnDestroy, OnInit } from '@angular/core';
import { XProjectorBmsCustomerAdminClient } from '@core/xprojector_backend/xprojector-bms-customeradmin-client';
import { NGXLogger } from 'ngx-logger';
import { DateHelper, XprojAlertService, XprojModalService } from 'xproj-lib';
import { PageComponent } from '@xprojectorfeatures/portal/models/page.component';
import { PageData } from '@xprojectorcore/models/page-data';
import { StateService } from '@xprojectorcore/services/state-service';
import { PortalInteractService } from '@xprojectorfeatures/portal/services/portal-interact.service';
import { ClrDatagridComparatorInterface, ClrDatagridSortOrder, ClrLoadingState } from '@clr/angular';
import { Apartment } from '@app/core/xprojector_backend/proto/xprojector.modulebms.customeradmin.pb';
import { NgxFileDropEntry } from 'ngx-file-drop';
import { BmsStateService } from '@app/core/services/bms-state-service';

export class CustomerAdminCustomerUser {
  id: string;
  firstname: string;
  surname: string;
  email: string;
  activeFrom: Date;
  activeFromString: string;
}

export class CustomerAdminApartmentInfo {
  apartment: Apartment;
  customerUsers: CustomerAdminCustomerUser[] = [];
  residents: string = '';
  expanded: boolean = false;
}

class CustomerAdminExternalIdComparator implements ClrDatagridComparatorInterface<CustomerAdminApartmentInfo> {
  compare(a: CustomerAdminApartmentInfo, b: CustomerAdminApartmentInfo) {
    if (a && b && a.apartment.externalId && b.apartment.externalId) {
      return a.apartment.externalId.padStart(10, '0') > b.apartment.externalId.padStart(10, '0') ? 1 : -1;
    }
    else {
      return a ? 1 : -1
    }
  }
}


@Component({
  selector: 'app-bms-customer-admin',
  templateUrl: './bms-customer-admin.component.html',
  styleUrls: ['./bms-customer-admin.component.scss']
})
export class BmsCustomerAdminComponent implements OnInit, OnDestroy, PageComponent {
  public static PAGEID = 'customeradmin';

  @Input() data: PageData;
  @Input() visible: boolean = false;
  @Input() admin: boolean = false;

  descSort = ClrDatagridSortOrder.DESC;
  ascSort = ClrDatagridSortOrder.ASC;
  externalIdSort = new CustomerAdminExternalIdComparator();

  responsiveWidth: number = 600;

  apartmentInfos: CustomerAdminApartmentInfo[] = [];
  loadingApartmentInfos: boolean = false;
  showImportDialog: boolean = false;
  importingState: ClrLoadingState = ClrLoadingState.DEFAULT;
  exportingState: ClrLoadingState = ClrLoadingState.DEFAULT;
  importResult: boolean;

  customerName: string = '';

  constructor(
    private logger: NGXLogger,
    private state: StateService,
    private bmsState: BmsStateService,
    private modalService: XprojModalService,
    private alertService: XprojAlertService,
    public dateHelper: DateHelper,
    private portalInteractService: PortalInteractService,
    private bmsCustomerAdminClient: XProjectorBmsCustomerAdminClient
  ) {
  }

  ngOnInit() {
    this.updateWidth();
    this.updateApartments();
  }

  ngOnDestroy(): void {
    //this.updateTimerSubscription?.unsubscribe();
  }

  async initPage() {

  }

  @HostListener('window:resize', ['$event'])
  onResize(event) {
    this.updateWidth();
  }

  async updateWidth() {
    this.responsiveWidth = window.innerWidth - 400;
  }

  async updateApartments() {
    try {
      this.loadingApartmentInfos = true;
      this.apartmentInfos = [];
      let response = await this.bmsCustomerAdminClient.getApartments(this.data.customerId);
      response.apartments.forEach(apartment => {
        let apartmentInfo = new CustomerAdminApartmentInfo();
        apartmentInfo.apartment = apartment;
        let residents: string[] = [];
        apartment.customerUsers.forEach(customerUser => {
          let user = new CustomerAdminCustomerUser();
          user.id = customerUser.id;
          user.firstname = customerUser.firstname;
          user.surname = customerUser.surname;
          user.email = customerUser.email;
          user.activeFrom = customerUser.activeFrom.toDate();
          user.activeFromString = this.dateHelper.utils.formatByString(user.activeFrom, 'yyyy-MM-dd')
          apartmentInfo.customerUsers.push(user);

          residents.push(customerUser.firstname + ' ' + customerUser.surname);
        });

        apartmentInfo.residents = residents.join('; ');

        this.apartmentInfos.push(apartmentInfo);
      });
    }
    finally {
      this.loadingApartmentInfos = false;
    }
  }

  async exportCustomerUsersAndApartments() {
    try {
      this.exportingState = ClrLoadingState.LOADING;
      if (!this.customerName) {
        let customerInfo = await this.bmsCustomerAdminClient.getCustomerInfo(this.data.customerId);
        if (customerInfo) {
          this.customerName = customerInfo.customerName;
        }
      }
      let headers : string [] = [
        $localize `:@@customeradmin_exportheader_apartmentid:Apartment Id`,
        $localize `:@@customeradmin_exportheader_address:Address`,
        $localize `:@@customeradmin_exportheader_apartmentno:Apartment No`,
        $localize `:@@customeradmin_exportheader_firstname:Firstname`,
        $localize `:@@customeradmin_exportheader_surname:Surname`,
        $localize `:@@customeradmin_exportheader_email:Email`,
        $localize `:@@customeradmin_exportheader_movedin:Moved in`
      ];
      await this.bmsCustomerAdminClient.exportApartmentsAndCustomerUsers(this.data.customerId, this.customerName, headers)
    } finally {
      this.exportingState = ClrLoadingState.SUCCESS;
    }
  }

  async importCustomerUsersAndApartments() {
    this.showImportDialog = true;
  }

  async dropped(files: NgxFileDropEntry[]) {

    let doImport = await this.modalService.ShowConfirmModalAsync({ header: 'Import dashboard', description: 'Import file, are you sure?' });

    this.showImportDialog = false;
    if (doImport) {
      for (const droppedFile of files) {

        if (droppedFile.fileEntry.isFile) {
          const fileEntry = droppedFile.fileEntry as FileSystemFileEntry;
            fileEntry.file(async (file: File) => {
              try {
                this.importingState = ClrLoadingState.LOADING;
                this.importResult = false;
                let result = await this.bmsState.importApartmentCustomerUsersFile(file , this.data.customerId);
                if (!result.ok) {
                  this.alertService.error('Error import file' + result.duplicateEmails ? ': duplicated emails in file' : '');
                }
                else {
                  this.alertService.success('Import success, added: ' + result.addCount + ', updated: ' + result.updateCount + ', deleted: ' + result.deleteCount);
                }
              }
              finally {
                this.importingState = ClrLoadingState.SUCCESS;
                this.updateApartments();
              }
            });
        }
      }
    }
  }
}
