import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import { XProjectorBmsEventsClient } from '@core/xprojector_backend/xprojector-bms-events-client';
import { NGXLogger } from 'ngx-logger';
import { map } from 'rxjs/operators';
import { DateHelper, XprojModalService } from 'xproj-lib';
import { EscalationInfo, Event, EventUser } from '@core/xprojector_backend/proto/xprojector.modulebms.events.pb';
import { PageComponent } from '@xprojectorfeatures/portal/models/page.component';
import { PageData } from '@xprojectorcore/models/page-data';
import { StateService } from '@xprojectorcore/services/state-service';
import { info } from 'console';
import { F_OK } from 'constants';
import { PortalInteractService } from '@xprojectorfeatures/portal/services/portal-interact.service';
import { Subscription, timer } from 'rxjs';
import { ClrDatagridComparatorInterface, ClrDatagridSortOrder } from '@clr/angular';


export class BmsCustomerEventsEvent {
  event : Event;
  class : string;
  status : string;
}

class EventTimeComparator implements ClrDatagridComparatorInterface<BmsCustomerEventsEvent> {
  compare(a: BmsCustomerEventsEvent, b: BmsCustomerEventsEvent) {
    if (a && b && a.event.timestamp && b.event.timestamp) {
      return a.event.timestamp.toDate() > b.event.timestamp.toDate() ? 1 : -1;
    }
    else {
      return a ? 1 : -1
    }
  }
}

@Component({
  selector: 'app-bms-customer-events',
  templateUrl: './bms-customer-events.component.html',
  styleUrls: ['./bms-customer-events.component.scss']
})
export class BmsCustomerEventsComponent implements OnInit, OnDestroy, PageComponent {
  public static PAGEID = 'customerevents';

  @Input() data: PageData;
  @Input() visible: boolean = false;

  descSort = ClrDatagridSortOrder.DESC;
  eventTimeSort = new EventTimeComparator();

  events: BmsCustomerEventsEvent[] = [];
  escalationInfos: EscalationInfo[] = [];

  private updateTimerSource;
  private updateTimerSubscription: Subscription;

  escalationInfosByUser : { user : EventUser, expanded : boolean, escalationInfos : {info : EscalationInfo, enabled : boolean}[]}[] = [];

  loadingEvents : boolean = false;
  saveInProgress : boolean = false;

  constructor(
    private logger: NGXLogger,
    private state: StateService,
    private modalService: XprojModalService,
    private eventsClient: XProjectorBmsEventsClient,
    public dateHelper: DateHelper,
    private portalInteractService : PortalInteractService,
  ) {
  }

  async ngOnInit() {
    this.updateEvents();
    this.updateEventSettings();

    this.updateTimerSource = timer(10000, 10000);
    this.updateTimerSubscription = this.updateTimerSource.subscribe($event => {
      this.updateEvents();
    });

  }

   ngOnDestroy(): void {
    this.updateTimerSubscription?.unsubscribe();
  }

  async initPage() {
    await this.updateEvents();
  }

  async updateEvents() {
    let events = await this.eventsClient.getEvents(this.data.customerId);

    let activeCount : number = 0;
    events.forEach(e => {
      let bmsEvent = new BmsCustomerEventsEvent();
      bmsEvent.event = e;
      bmsEvent.class = e.muted ? 'dot-muted' : e.active ? (e.acknowledged ? 'dot-open' : 'dot-new') : 'dot-closed';
      bmsEvent.status = e.muted ? $localize `:@@customerevents_muted:Muted` : e.active ? (e.acknowledged ? $localize `:@@customerevents_open:Open` : $localize `:@@customerevents_new:New`) : $localize `:@@customerevents_closed:Closed`;
      if (e.active) {
        activeCount++;
      }

      let ev = this.events.find(x => x.event.id == e.id);
      if (ev) {
        ev.event = e;
        ev.class = bmsEvent.class;
        ev.status = bmsEvent.status;
      }
      else {
        this.events.push(bmsEvent);
      }
    });

    //this.events.sort((a, b) => !a.event.active ? 1 : -1);

    this.portalInteractService.EventsUpdate(BmsCustomerEventsComponent.PAGEID, activeCount);
  }

  async updateEventSettings() {
    this.escalationInfos = await this.eventsClient.getEventSettings(this.data.customerId);

    this.escalationInfosByUser = [];

    this.escalationInfos.forEach(info => {
      info.users.forEach(user => {
        if (this.escalationInfosByUser.findIndex(x => x.user.email == user.email) < 0) {
          this.escalationInfosByUser.push({user: user, expanded: false, escalationInfos : []});
        }
      });
    });

    this.escalationInfos.forEach(info => {
      this.escalationInfosByUser.forEach(byUser => {
        if (info.users.findIndex(u => u.email == byUser.user.email) >= 0) {
          byUser.escalationInfos.push({ info : info, enabled : true });
        }
        else {
          byUser.escalationInfos.push({ info : info, enabled : false });
        }
      });
    });
  }

  addNewUser() {
    this.escalationInfosByUser.forEach(x => {
      x.expanded = false;
    });

    let user : EventUser = new EventUser();
    user.name = '';
    user.typeId = '_x_bms_events_externaluser';

    let toAdd = {user : user, expanded: true, escalationInfos : []};

    this.escalationInfos.forEach(x => toAdd.escalationInfos.push({info : x, enabled : false}));
    this.escalationInfosByUser.push(toAdd);
  }

  deleteUser(user : EventUser) {
    this.modalService.ShowConfirmModal({ header: $localize `:@@customerevents_removeuser:Remove user`, description: $localize `:@@customerevents_removeusermessage:Remove user, are you sure?` }, async (ok) => {
        if (ok) {
          this.escalationInfosByUser = this.escalationInfosByUser.filter(x => x.user.email != user.email);
          await this.saveSettings();
        }
      });
  }

  addEscalationInfoToUser(userWithInfos : { user : EventUser,  escalationInfos : {info :EscalationInfo, enabled : boolean}[]}, escalationInfo: EscalationInfo ) {
    let u = userWithInfos.escalationInfos.find(x => x.info.id == escalationInfo.id);
    if (u) {
      u.enabled = true;
    }
  }

  removeEscalationInfoFromUser(userWithInfos : { user : EventUser,  escalationInfos : {info :EscalationInfo, enabled : boolean}[]}, escalationInfo: EscalationInfo ) {
    userWithInfos.escalationInfos = userWithInfos.escalationInfos.filter(x => x.info.id != escalationInfo.id);
  }

  async saveSettings() {
    try {
      this.saveInProgress = true;

      this.escalationInfos.forEach(info => {
        info.users = [];
      });

      this.escalationInfosByUser.forEach(x => {
        if (x.user.name?.length > 0 && (x.user.email.length > 0 || x.user.phone.length > 0)) {
          x.escalationInfos.forEach(infoEnabled => {
            if (infoEnabled.enabled) {
              infoEnabled.info.users.push(x.user);
            }
          });
        }
      });

      await this.eventsClient.updateEventSettings(this.data.customerId, this.escalationInfos);

      this.escalationInfosByUser.forEach(x => {
        x.expanded = false;
      });
    }
    finally {
      this.saveInProgress = false;
    }

  }

  async acknowledgeEvent(bmsEvent : BmsCustomerEventsEvent) {
    if (bmsEvent) {
      let result = await this.modalService.ShowInputModalAsync({
        header: $localize `:@@customerevents_acknowledge:Acknowledge`,
        description: $localize `:@@customerevents_inputcomment:Input comment:`,
        value: '',
        ok: $localize `:@@customerevents_acknowledge:Acknowledge`,
        cancel: $localize `:@@customerevents_cancel:Cancel`,
      });
      if (result.result) {
        await this.eventsClient.acknowledgeEvent(bmsEvent.event.customerId, bmsEvent.event.id, result.value, this.state.userId, '_x_customerusers_user');
        this.updateEvents();
      }
    }
  }

  async closeEvent(bmsEvent : BmsCustomerEventsEvent) {
    if (bmsEvent) {
      let result = await this.modalService.ShowInputModalAsync({
        header: $localize `:@@customerevents_closeevent:Close event`,
        description: $localize `:@@customerevents_inputcomment:Input comment:`,
        value: '',
        ok: $localize `:@@customerevents_closeevent:Close event`,
        cancel: $localize `:@@customerevents_cancel:Cancel`,
      });
      if (result.result) {
        await this.eventsClient.clearEvent(bmsEvent.event.customerId, bmsEvent.event.id, result.value, this.state.userId, '_x_customerusers_user');
        this.updateEvents();
      }
    }
  }

  async muteEvent(bmsEvent : BmsCustomerEventsEvent) {
    if (bmsEvent) {
      let result = await this.modalService.ShowInputModalAsync({
        header: $localize `:@@customerevents_muteevent:Mute event`,
        description: $localize `:@@customerevents_inputcomment:Input comment:`,
        value: '',
        ok: $localize `:@@customerevents_muteevent:Mute event`,
        cancel: $localize `:@@customerevents_cancel:Cancel`,
      });
      if (result.result) {
        await this.eventsClient.muteEvent(bmsEvent.event.customerId, bmsEvent.event.id, result.value, this.state.userId, '_x_customerusers_user');
        this.updateEvents();
      }
    }
  }

  async unmuteEvent(bmsEvent : BmsCustomerEventsEvent) {
    if (bmsEvent) {
      let result = await this.modalService.ShowInputModalAsync({
        header: $localize `:@@customerevents_unmuteevent:Unmute event`,
        description: $localize `:@@customerevents_inputcomment:Input comment:`,
        value: '',
        ok: $localize `:@@customerevents_unmuteevent:Unmute event`,
        cancel: $localize `:@@customerevents_cancel:Cancel`,
      });
      if (result.result) {
        await this.eventsClient.unmuteEvent(bmsEvent.event.customerId, bmsEvent.event.id, result.value, this.state.userId, '_x_customerusers_user');
        this.updateEvents();
      }
    }
  }

}
