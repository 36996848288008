import { Component, inject, OnInit } from '@angular/core';
import { BmsDataCollectorViewData } from '@core/models/bms-data-collector-view-data';
import { BmsDataCollectorComponent } from '@features/bms/bms-admin/bms-data-collector-view.component';
import { BmsLorawanMultiMeter } from '@core/models/bms-lorawan-multimeter';
import { BmsAdminService } from '../../../services/bms-admin-service';
import { BmsLorawanDeviceInfo } from '@app/core/models/lorawan-device-info';
import { DashboardOutputChangeParameters, DateHelper, OutputDataType, XprojModalService, WidgetExportParameters, XprojAlertService } from 'xproj-lib';
import { XProjectorSysAdminClient } from '@xprojectorcore/xprojector_backend/xprojector-sysadmin-client';
import { XProjectorCustomerUsersClient } from '@xprojectorcore/xprojector_backend/xprojector-customerusers-client';
import { XProjectorBmsLoRaWANClient } from '@app/core/xprojector_backend/xprojector-bms-lorawan-client';
import { ConfiguredDownlinkItem, ConfiguredDownlinkSequence, Device, DownlinkItem } from '@app/core/xprojector_backend/proto/xprojector.modulebms.lorawan.pb';
import { ClrLoadingState } from '@clr/angular';

@Component({
  selector: 'app-bms-lorawan-multi-meter-data-collector-view',
  templateUrl: './bms-lorawan-multi-meter-data-collector-view.component.html',
  styleUrls: ['./bms-lorawan-multi-meter-data-collector-view.component.scss']
})
export class BmsLorawanMultiMeterDataCollectorViewComponent implements OnInit {

  public static NodeTypeId : string = '_x_lorawan_multimeter';

  private adminService : BmsAdminService = inject(BmsAdminService);
  private sysAdminClient : XProjectorSysAdminClient = inject(XProjectorSysAdminClient);
  public dateHelper: DateHelper = inject(DateHelper);
  private modalService: XprojModalService = inject(XprojModalService);
  private customerUserClient: XProjectorCustomerUsersClient = inject(XProjectorCustomerUsersClient);
  private loRaWANClient: XProjectorBmsLoRaWANClient = inject(XProjectorBmsLoRaWANClient);
  private alertService: XprojAlertService = inject(XprojAlertService);

  data: BmsDataCollectorViewData;
  visible: boolean;

  lorawanMultiMeter : BmsLorawanMultiMeter;
  lorawanDeviceInfo : BmsLorawanDeviceInfo;
  lorawanDevice : Device;
  loadingLorawanDevice : boolean = false;
  lorawanDeviceState: ClrLoadingState = ClrLoadingState.DEFAULT;

  responsiveWidth : number = 600;
  meterParameters : DashboardOutputChangeParameters[] = [];

  deviceInfoActive : boolean = true;
  logActive : boolean = false;

  _configurationActive: boolean = false;
  get configurationActive(): boolean {
    return this._configurationActive;
  }
  set configurationActive(value: boolean) {
    if (value) {
      this.updateDevice();
    }
    this._configurationActive = value;
  }

  _downlinkActive: boolean = false;
  get downlinkActive(): boolean {
    return this._downlinkActive;
  }
  set downlinkActive(value: boolean) {
    if (value) {
      this.updateConfiguredDownlinkItems();
      this.updateConfiguredDownlinkSequences();
      this.updateDownLinkItems();
    }
    this._downlinkActive = value;
  }

  nodeLoggingEnabled : boolean = false;
  nodeLoggingExpiresAt : Date;

  configuredDownlinkItems : ConfiguredDownlinkItem[] = [];
  selectedConfiguredDownlinkItem : ConfiguredDownlinkItem = null;
  configuredDownlinkItemsDeviceType : string = '';

  downlinkItems : DownlinkItem[];
  loadingDownlinkitems : boolean = true;

  configuredDownlinkSequences : ConfiguredDownlinkSequence[] = [];
  selectedConfiguredDownlinkSequence : ConfiguredDownlinkSequence = null;
  configuredDownlinkSequencesDeviceType : string = '';

  downlinkOverrideTimeout : number = -1;
  downlinkSendEmail : boolean = false;

  operatorId : string = '';

  constructor(
  ) { }

  ngOnInit(): void {
  }


  async initDataCollectorView() {
    this.responsiveWidth = this.data.width;
    this.lorawanMultiMeter = this.adminService.getLorawanMultiMeter(this.data.node);
    let lorawanDeviceInfos = await this.adminService.getLoraWANDeviceInfos([this.lorawanMultiMeter?.devEui]);
    if (lorawanDeviceInfos?.length > 0) {
      this.lorawanDeviceInfo = lorawanDeviceInfos[0];
    }
    this.updateDashboardOutputs();

    this.updateNodeLogging();
  }

  updateDashboardOutputs() {
    this.meterParameters = [];
    if (this.data.bmsCustomer) {

      let out = new DashboardOutputChangeParameters();
      out.outputParameterName = 'customerid';
      out.value = this.data.bmsCustomer.customerId;
      out.datatype = OutputDataType.String;
      this.meterParameters.push(out);

      out = new DashboardOutputChangeParameters();
      out.outputParameterName = 'customerxdbid';
      out.value = this.data.bmsCustomer.id;
      out.datatype = OutputDataType.Int64;
      this.meterParameters.push(out);

      out = new DashboardOutputChangeParameters();
      out.outputParameterName = 'deveui';
      out.value = this.lorawanMultiMeter?.devEui;
      out.datatype = OutputDataType.String;
      this.meterParameters.push(out);
    }
  }

  setWidth(width: number) {
    if (this.data) {
      this.data.width = width;
    }

    this.responsiveWidth = width;
  }

  async updateNodeLogging() {
    let nodeLogging = await this.sysAdminClient.getNodeLogging(this.data.node?.id);
    if (nodeLogging) {
      this.nodeLoggingEnabled = nodeLogging.enabled;
      this.nodeLoggingExpiresAt = nodeLogging.enabled ? nodeLogging.expiry.toDate() : new Date();
    }
  }

  async enableNodeLogging() {
    let enableResult = await this.modalService.ShowInputModalAsync({
      header: 'Enable logging',
      description: 'Input enabled logging for days (1-30):',
      value: '1',
      ok: 'Enable',
      cancel: 'Cancel'
    });
    if (enableResult.result) {
      let ttlDays : number = 1;
      try {
        ttlDays = +enableResult.value
      }
      catch (error) {
        ttlDays = 1;
      }
      if (ttlDays <= 0) {
        ttlDays = 1;
      }
      else if (ttlDays > 30) {
        ttlDays = 30;
      }
      let ttlSeconds = ttlDays * 3600 * 24;
      await this.sysAdminClient.setNodeLogging(this.data.node.id, true, ttlSeconds);
      await this.updateNodeLogging();
    }
  }

  async disableNodeLogging() {
    await this.sysAdminClient.setNodeLogging(this.data.node.id, false);
    await this.updateNodeLogging();
  }

  async onWidgetExport(parameters: WidgetExportParameters) {
    let result = await this.customerUserClient.exportToExcel(parameters);
  }

  async updateConfiguredDownlinkItems() {
    if (this.configuredDownlinkItems.length == 0 || this.configuredDownlinkItemsDeviceType != this.lorawanMultiMeter.deviceType) {
      this.configuredDownlinkItems = await this.loRaWANClient.getConfiguredDownlinkItems(this.lorawanMultiMeter.deviceType);
      this.configuredDownlinkItemsDeviceType = this.lorawanMultiMeter.deviceType;
    }
  }

  async updateConfiguredDownlinkSequences() {
    if (this.configuredDownlinkSequences.length == 0 || this.configuredDownlinkSequencesDeviceType != this.lorawanMultiMeter.deviceType) {
      this.configuredDownlinkSequences = await this.loRaWANClient.getConfiguredDownlinkSequences(this.lorawanMultiMeter.deviceType);
      this.configuredDownlinkSequencesDeviceType = this.lorawanMultiMeter.deviceType;
    }
  }

  async updateDownLinkItems(force : boolean = false) {
    if (!this.downlinkItems || force) {
      try {
        this.loadingDownlinkitems = true;

        this.downlinkItems = await this.loRaWANClient.getDownlinkItems(this.data.bmsCustomer.customerId, this.data.node.id,
          this.data.node.nodeTypeId, this.operatorId);
      }
      finally {
        this.loadingDownlinkitems = false;
      }
    }
  }

  async clearDownlinkQueue() {
    let doClear = await this.modalService.ShowConfirmModalAsync({
      header: 'Clear downlink queue',
      description: 'Clear downlink queue, are you sure?',
      ok: 'Clear',
      cancel: 'Cancel'
    });

    if (doClear) {
      let result = await this.loRaWANClient.clearDownlinkQueue(this.data.bmsCustomer.customerId, this.data.node.id,
        this.data.node.nodeTypeId, this.operatorId);

        if (result.ok) {
          this.alertService.success('Downlink queue cleared.');
        }
        else {
          this.alertService.error('Error clear downlink queue.');
        }

        await this.updateDownLinkItems(true);
    }
  }

  async enqueueDownlinkItem() {
    if (this.selectedConfiguredDownlinkItem) {
      let doEnqueue = await this.modalService.ShowConfirmModalAsync({
        header: 'Enqueue downlink item',
        description: 'Enqueue downlink item to device, are you sure?',
        ok: 'Enqueue',
        cancel: 'Cancel'
      });

      if (doEnqueue) {
        let result = await this.loRaWANClient.enqueueDownlinkItem(this.data.bmsCustomer.customerId, this.data.node.id,
              this.data.node.nodeTypeId, this.operatorId, this.selectedConfiguredDownlinkItem);

        if (result.ok && result.deviceCount > 0) {
          this.alertService.success('Downlink item enqueued.');
        }
        else {
          this.alertService.error('Enqueue downlink item failure');
        }

        await this.updateDownLinkItems(true);
      }
    }
  }

  downlinkSequenceChanged() {
    if (this.selectedConfiguredDownlinkSequence) {
      this.downlinkOverrideTimeout = this.selectedConfiguredDownlinkSequence.timeout;
      this.downlinkSendEmail = false;
    }
  }

  async enqueueDownlinkSequence() {
    if (this.selectedConfiguredDownlinkSequence) {
      let doEnqueue = await this.modalService.ShowConfirmModalAsync({
        header: 'Enqueue downlink sequence',
        description: 'Enqueue downlink sequence to all devices, are you sure?',
        ok: 'Enqueue',
        cancel: 'Cancel'
      });

      if (doEnqueue) {
        let result = await this.loRaWANClient.enqueueDownlinkSequence(this.data.bmsCustomer.customerId, this.data.node.id,
              this.data.node.nodeTypeId, 'chirpstack', this.selectedConfiguredDownlinkSequence.id, null, this.downlinkOverrideTimeout, this.downlinkSendEmail);

        if (result.ok) {
          this.modalService.ShowConfirmModalAsync({
            header: 'Enqueue downlink sequence success',
            description: 'Downlink sequence enqueued to ' + result.deviceCount + ' devices.',
            showCancel: false
          });
        }
        else {
          this.modalService.ShowConfirmModalAsync({
            header: 'Enqueue downlink sequence failure',
            description: result.message,
            showCancel: false
          });

        }

      }
    }
  }

  async updateDevice(force : boolean = false) {
    if (!this.lorawanDevice || force) {
      try {
        this.loadingLorawanDevice = true;

        this.lorawanDevice = await this.loRaWANClient.getDevice(this.data.bmsCustomer.customerId, this.data.node.id,
          this.data.node.nodeTypeId, this.operatorId);
      }
      finally {
        this.loadingLorawanDevice = false;
      }
    }
  }

  async saveLoRaWANDevice() {
    if (this.lorawanDevice) {
      try {
        this.lorawanDeviceState = ClrLoadingState.LOADING;
        let result = await this.loRaWANClient.updateDevice(this.data.bmsCustomer.customerId, this.data.node.id,
          this.data.node.nodeTypeId, this.operatorId, this.lorawanDevice);

          if (result.ok) {
            this.alertService.success('Device updated ok.');
          }
          else {
            this.alertService.error('Error update device!');
          }
      }
      finally {
        this.lorawanDeviceState = ClrLoadingState.DEFAULT;
      }
    }
  }

}
