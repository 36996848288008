import { Component, OnInit } from '@angular/core';
import { XProjectorSysAdminClient } from '@xprojectorcore/xprojector_backend/xprojector-sysadmin-client';
import { ClrLoadingState } from '@clr/angular';
import { XprojAlertService } from 'xproj-lib';

@Component({
  selector: 'app-bms-geo-location',
  templateUrl: './bms-geo-location.component.html',
  styleUrls: ['./bms-geo-location.component.scss']
})
export class BmsGeoLocationComponent implements OnInit {

  geoLocationUpdateOverwrite : boolean = false;
  geoLocationState : ClrLoadingState = ClrLoadingState.DEFAULT;

  constructor(
    private sysAdminClient: XProjectorSysAdminClient,
    private alertService: XprojAlertService
  ) { }

  ngOnInit(): void {
  }

  async geoLocationUpdateAll() {
    try {
      this.geoLocationState = ClrLoadingState.LOADING;
      let result = await this.sysAdminClient.updateGeoLocations('', this.geoLocationUpdateOverwrite);
      if (result) {
        this.alertService.success('Geo location updated ok.');
        this.geoLocationState = ClrLoadingState.SUCCESS;
      }
      else {
        this.alertService.error('Error update all geo locations');
        this.geoLocationState = ClrLoadingState.ERROR;
      }
    }
    catch {
      this.geoLocationState = ClrLoadingState.ERROR;
    }

  }
}
