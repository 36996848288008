
export class BmsBuilding {
  id : number;
  realestateId : number;

  svLantBuildingNo : number;
  description : string;
  buildingtype : string;
  latitude : number;
  longitude : number;

  createdAt : Date;
  modifiedAt : Date;
}
