import { Component, OnInit } from '@angular/core';
import { XProjectorBmsEventsClient } from '@core/xprojector_backend/xprojector-bms-events-client';
import { NGXLogger } from 'ngx-logger';
import { XprojAlertService } from 'xproj-lib';

@Component({
  selector: 'app-bms-message-admin',
  templateUrl: './bms-message-admin.component.html',
  styleUrls: ['./bms-message-admin.component.scss']
})
export class BmsMessageAdminComponent implements OnInit {

  smsTo : string = '';
  smsMessage : string = '';

  constructor(
    private logger: NGXLogger,
    private eventsClient : XProjectorBmsEventsClient,
    private alertService: XprojAlertService
  ) { }

  ngOnInit(): void {
  }

  clear() {
    this.smsTo = '';
    this.smsMessage = '';
  }

  async sendSms() {
    if (this.smsTo?.length > 0) {
      let result = await this.eventsClient.sendSmsMessage(this.smsTo, this.smsMessage);
      if (result.ok) {
        this.alertService.success('Message sent ok.');
        this.clear();
      }
      else {
        this.alertService.error('Error send message: ' + result.message);
      }
    }
  }

}
