export class BmsApartment {
  id : number;
  buildingAddressId : number;
  buildingId : number;
  realestateId : number;

  svlantApartmentno : number;
  externalId : string;
  prefix : string;
  area : number;
  size : number;

  createdAt : Date;
  modifiedAt : Date;
}
