<div [clrLoading]="loading">
  <table class="table table-noborder">
    <thead>
      <tr>
        <th i18n='@@customereventshistory_timestamp' class="left">Timestamp</th>
        <th i18n='@@customereventshistory_status' class="left">Status</th>
        <th i18n='@@customereventshistory_high' class="left">High</th>
        <th i18n='@@customereventshistory_text' class="left">Text</th>
      </tr>
    </thead>
    <tbody>
      <tr *ngFor="let item of events">
        <td class="left">{{dateHelper.utils.format(item.timestamp.toDate(), 'keyboardDateTime')}}</td>
        <td class="left">{{item | bmscustomereventstatuspipe}}</td>
        <td class="left">{{item.high | bmscustomereventactivepipe}}</td>
        <td class="left">{{item.comment}}</td>
      </tr>
    </tbody>
  </table>
</div>
