<div class="customers-content-area" *ngIf="!diffMeter">
  <xproj-dashboard #dashboardOverview [editMode]="false" [showDashboardSettings]="false" [systemDashboard]="true"
    [dashboardId]="'bms_meter'" [enableExport]="true" [dashboardTag]="'latest'" [responsiveWidth]="responsiveWidth"
    [dashboardOutputParameters]="meterParameters" (onWidgetExport)="onWidgetExport($event)"
    (onWidgetValueSelected)="meterDashboardValueChanged($event)"></xproj-dashboard>
</div>

<div class="customers-content-area" *ngIf="diffMeter">
  <clr-tabs>
    <clr-tab>
      <button clrTabLink>Meter values</button>
      <ng-template [(clrIfActive)]="meterValuesActive">
        <clr-tab-content>
          <xproj-dashboard #dashboardOverview [editMode]="false" [showDashboardSettings]="false" [systemDashboard]="true"
            [dashboardId]="'bms_meter'" [enableExport]="true" [dashboardTag]="'latest'"
            [responsiveWidth]="responsiveWidth" [dashboardOutputParameters]="meterParameters"
            (onWidgetExport)="onWidgetExport($event)"
            (onWidgetValueSelected)="meterDashboardValueChanged($event)"></xproj-dashboard>
        </clr-tab-content>
      </ng-template>
    </clr-tab>
    <clr-tab>
      <button clrTabLink>Sessions</button>
      <ng-template [(clrIfActive)]="sessionsActive">
        <clr-tab-content>
          <xproj-dashboard #dashboardSessions [editMode]="false" [showDashboardSettings]="false"
            [systemDashboard]="true" [dashboardId]="'bms_meter_sessions'" [enableExport]="true"
            [dashboardTag]="'latest'" [responsiveWidth]="responsiveWidth" [dashboardOutputParameters]="meterParameters"
            (onWidgetExport)="onWidgetExport($event)"
            (onWidgetValueSelected)="meterDashboardValueChanged($event)"></xproj-dashboard>
        </clr-tab-content>
      </ng-template>
    </clr-tab>
  </clr-tabs>

</div>
