import { Component, HostListener, inject, Input, OnDestroy, OnInit } from '@angular/core';
import { XProjectorBmsEventsClient } from '@core/xprojector_backend/xprojector-bms-events-client';
import { NGXLogger } from 'ngx-logger';
import { DashboardOutputChangeParameters, DateHelper, LinkedWidgetSelectParameters, OutputDataType, XprojModalService } from 'xproj-lib';
import { PageComponent } from '@xprojectorfeatures/portal/models/page.component';
import { PageData } from '@xprojectorcore/models/page-data';
import { StateService } from '@xprojectorcore/services/state-service';
import { PortalInteractService } from '@xprojectorfeatures/portal/services/portal-interact.service';
import { Subscription, timer } from 'rxjs';
import { ClrDatagridComparatorInterface, ClrDatagridSortOrder } from '@clr/angular';
import { BmsCustomerEventStatus } from '../../../models/bms-customer-event-status';
import { BmsEventsService } from '../../../bms-admin/services/bms-events-service';


class ExternalIdComparator implements ClrDatagridComparatorInterface<BmsCustomerEventStatus> {
  compare(a: BmsCustomerEventStatus, b: BmsCustomerEventStatus) {
    if (a && b && a.externalId && b.externalId) {
      return a.externalId.padStart(10, '0') > b.externalId.padStart(10, '0') ? 1 : -1;
    }
    else {
      return a ? 1 : -1
    }
  }
}

class ClassComparator implements ClrDatagridComparatorInterface<BmsCustomerEventStatus> {
  compare(a: BmsCustomerEventStatus, b: BmsCustomerEventStatus) {
    if (a && b) {
      return a.class < b.class ? 1 : -1;
    }
    else {
      return a ? 1 : -1
    }
  }
}

@Component({
  selector: 'app-bms-customer-event-status',
  templateUrl: './bms-customer-event-status.component.html',
  styleUrls: ['./bms-customer-event-status.component.scss']
})
export class BmsCustomerEventStatusComponent implements OnInit, OnDestroy, PageComponent {
  public static PAGEID_SMOKE = 'customereventstatus_smoke';
  public static PAGEID_LEAKAGE = 'customereventstatus_leakage';
  public static CATEGORY_SMOKE = 'SMOKE_DETECTOR';
  public static CATEGORY_LEAKAGE = 'LEAKAGE_DETECTOR';


  private eventsService : BmsEventsService = inject(BmsEventsService);

  @Input() data: PageData;
  @Input() visible: boolean = false;

  descSort = ClrDatagridSortOrder.DESC;
  ascSort = ClrDatagridSortOrder.ASC;
  externalIdSort = new ExternalIdComparator();
  messageSort = new ClassComparator();

  eventStatuses: BmsCustomerEventStatus[] = [];

  responsiveWidth : number = 600;

  meterParameters : DashboardOutputChangeParameters[] = [];
  selectedIndex: number = -1;
  selectedEventStatus : BmsCustomerEventStatus;
  dashboardId : string = '';

  // private updateTimerSource;
  // private updateTimerSubscription: Subscription;

  loadingEventStatuses : boolean = false;

  constructor(
    private logger: NGXLogger,
    private state: StateService,
    private modalService: XprojModalService,
    public dateHelper: DateHelper,
    private portalInteractService : PortalInteractService,
  ) {
  }

  async ngOnInit() {
    this.updateEventStatuses();

    // this.updateTimerSource = timer(10000, 10000);
    // this.updateTimerSubscription = this.updateTimerSource.subscribe($event => {
    //   this.updateEventStatuses();
    // });

    this.updateWidth();
    this.dashboardId = this.data.pageData == 'SMOKE_DETECTOR' ? 'bms_event_status_smoke' : 'bms_event_status_leakage'
  }

   ngOnDestroy(): void {
    //this.updateTimerSubscription?.unsubscribe();
  }

  async initPage() {
    await this.updateEventStatuses();
    this.updateDashboardOutputs();
  }

  @HostListener('window:resize', ['$event'])
  onResize(event) {
    this.updateWidth();
  }

  async updateWidth() {
    this.responsiveWidth = window.innerWidth - 400;
  }

  async meterDashboardValueChanged(parameters: LinkedWidgetSelectParameters) {

  }

  updateDashboardOutputs() {
    this.meterParameters = [];
    if (this.data) {

      let out = new DashboardOutputChangeParameters();
      out.outputParameterName = 'customerid';
      out.value = this.data.customerId;
      out.datatype = OutputDataType.String;
      this.meterParameters.push(out);

      // out = new DashboardOutputChangeParameters();
      // out.outputParameterName = 'customerxdbid';
      // out.value = this.data.bmsCustomer.id;
      // out.datatype = OutputDataType.Int64;
      // this.meterParameters.push(out);

      out = new DashboardOutputChangeParameters();
      out.outputParameterName = 'id';
      out.value = this.selectedEventStatus?.meterId;
      out.datatype = OutputDataType.Int64;
      this.meterParameters.push(out);
    }
  }


  async updateEventStatuses() {
    let activeCount = 0;
    let utcNow = new Date();
    if (this.data) {
      this.eventStatuses = await this.eventsService.getEventStatuses(this.data.customerId, this.data.pageData);
      this.eventStatuses.forEach(eventStatus => {
        if (this.dateHelper.utils.addHours(eventStatus.timestamp, 24) < utcNow) {
          eventStatus.class = 0;
          eventStatus.message = $localize `:@@customereventstatus_lostconnection:No connection`;
        }

        switch (eventStatus.class) {
          case 0:
            eventStatus.stylingClass = 'dot-new';
            activeCount++;
            break;
          case 1:
            eventStatus.stylingClass = 'dot-open';
            break;
          default:
            eventStatus.stylingClass = 'dot-ok';
            break;
        }
      });

      this.portalInteractService.EventsUpdate(this.data.pageData == 'SMOKE_DETECTOR' ? BmsCustomerEventStatusComponent.PAGEID_SMOKE : BmsCustomerEventStatusComponent.PAGEID_LEAKAGE, activeCount);
    }
  }

  async onCellClick(eventStatus : BmsCustomerEventStatus, i : number) {
    if (this.selectedIndex != i) {
      this.selectedIndex = i;
      this.selectedEventStatus = eventStatus;
      this.updateDashboardOutputs();
    }
    else {
      this.selectedIndex = -1;
      this.selectedEventStatus = null;
    }
  }

}
