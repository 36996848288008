export class BmsCustomerEventStatus {
  id : number;
  customerId : string;
  meterId : number;
  category : string;
  class : number;
  location : string;
  svlantApartmentNo : string;
  externalId : string;
  value : number;
  message : string;
  timestamp : Date;

  stylingClass : string = '';
}
