import { AfterViewInit, ChangeDetectorRef, Component, Input, OnInit, ViewChild } from '@angular/core';
import { Router } from '@angular/router';
import { XProjectorXConfClient } from '@xprojectorcore/xprojector_backend/xprojector-xconf-client';
import { NGXLogger } from 'ngx-logger';
import { BaseQuery, BaseQueryInputColumnDescription, ColumnFilteringNumerical, DashboardOutputChangeParameters, DateHelper, FilterComparator, FilterLogicalGroupType, OutputDataType, ProjectionColumnDescription, XDataType, XprojAlertService, XProjectorClient } from 'xproj-lib';
import { BmsMeter, BmsMeterState } from '@features/bms/models/bms-meter';
import { BmsDataUtils } from '@features/bms/bms-admin/utils/bms-data-utils';

@Component({
  selector: 'app-bms-meter-view',
  templateUrl: './bms-meter-view.component.html',
  styleUrls: ['./bms-meter-view.component.scss']
})
export class BmsMeterViewComponent implements OnInit, AfterViewInit {

  @Input() showData: boolean = false;
  @Input() responsiveWidth: number = 834;

  meterColumns: ProjectionColumnDescription[] = [];
  meter: BmsMeter;
  customerId: string;

  dashboardOutputParameters: DashboardOutputChangeParameters[] = [];

  BmsMeterState = BmsMeterState;
  BmsDataUtils = BmsDataUtils;

  constructor(
    private router: Router,
    private xprojClient: XProjectorClient,
    private logger: NGXLogger,
    public dateHelper: DateHelper
  ) {

  }

  async ngOnInit() {

  }

  ngAfterViewInit(): void {

  }

  async update(meterId: number, customerId: string) {
    this.customerId = customerId;
    if (meterId && meterId > 0) {
      let outputs: DashboardOutputChangeParameters[] = [];
      let out = new DashboardOutputChangeParameters();
      out.outputParameterName = 'Id';
      out.datatype = OutputDataType.Int64;
      out.value = meterId;
      outputs.push(out);

      // let outStart = new DashboardOutputChangeParameters();
      // outStart.outputParameterName = 'Start';
      // outStart.value = this.dateHelper.utils.addDays(this.selectedExportDataValueCopy.start, -1);
      // outputs.push(outStart);

      this.dashboardOutputParameters = outputs;

      if (this.meterColumns.length == 0) {
        this.meterColumns = await this.xprojClient.RequestListQueryableProjectionColumns('hs-meter', '', 0, 100);
      }

      let query: BaseQuery = new BaseQuery();
      query.targetprojectionid = 'hs-meter';
      query.maxitems = 1;
      query.targetgroup = [];

      let filterId = 0;
      query.filter.type = FilterLogicalGroupType.AND;

      let idFiltering = new ColumnFilteringNumerical();
      idFiltering.columnname = 'id';
      idFiltering.comparator = FilterComparator.Equals;
      idFiltering.value = meterId;
      idFiltering.queryfilterid = ++filterId;
      query.filter.filters.push(idFiltering.queryfilterid);
      query.numericalfilters.push(idFiltering);

      let deletedFiltering = new ColumnFilteringNumerical();
      deletedFiltering.columnname = 'deleted';
      deletedFiltering.comparator = FilterComparator.Equals;
      deletedFiltering.value = 0;
      deletedFiltering.queryfilterid = ++filterId;
      query.filter.filters.push(deletedFiltering.queryfilterid);
      query.numericalfilters.push(deletedFiltering);

      this.meterColumns.forEach(c => {
        let inCol = new BaseQueryInputColumnDescription();
        inCol.columnname = c.columnname;
        inCol.columnoutname = c.columnname;
        query.columns.push(inCol);
      });

      try {
        let queryResult = await this.xprojClient.RequestQueryBaseQuery(query, true);

        this.meter = new BmsMeter();

        let numericaldata = queryResult.datanumbers;
        let timestampdata = queryResult.datatimestamps;
        let stringdata = queryResult.datastrings;

        for (let i = 0; i < queryResult.columns.length; i++) {
          let it = queryResult.columns[i];
          let typ = it.datatype;
          let data = [];
          if (typ == XDataType.Number) {
            //data = WidgetUtils.FormatNumbers(numericaldata[it.indexintypedvector], this.globalWidgetSettings.Decimals);
            data = numericaldata[it.indexintypedvector];
          }
          if (typ == XDataType.String) {
            data = stringdata[it.indexintypedvector];
          }
          if (typ == XDataType.Timestamp) {
            data = timestampdata[it.indexintypedvector];
          }

          switch (it.columnoutname) {
            case 'subaddress1':
              this.meter.identifier = data[0];
              break;
            case 'subaddress2':
              this.meter.manufacturer = data[0];
              break;
            case 'unit':
              this.meter.unit = data[0];
              break;
            case 'state':
              this.meter.state = data[0];
              break;
            case 'model':
              this.meter.model = data[0];
              break;
            case 'metertype':
              this.meter.meterType = data[0];
              break;
            case 'tariffgroup':
              this.meter.tariffGroup = data[0];
              break;
            case 'metersubtype':
              this.meter.meterSubtype = data[0];
              break;
            case 'typeid':
              this.meter.typeId = data[0];
              break;
            case 'coeffiecent':
              this.meter.coeffiecent = data[0];
              break;
            case 'createdat':
              this.meter.createdAt = data[0];
              break;
            case 'modifiedat':
              this.meter.modifiedAt = data[0];
              break;
            case 'id':
              this.meter.id = data[0];
              break;
          }
        }
      }
      catch (err) {
        this.logger.error(err);
      }
    }
    else {
      this.meter = null;
    }
  }

  async viewInBMS() {
    this.router.navigateByUrl('/bmsadmin/customers/' + this.customerId + '/bms/' + this.meter.id + '/' + '_x_bms_meter');
  }

}
