/* tslint:disable */
/* eslint-disable */
// @ts-nocheck
//
// THIS IS A GENERATED FILE
// DO NOT MODIFY IT! YOUR CHANGES WILL BE LOST
import { Inject, Injectable, Optional } from '@angular/core';
import {
  GrpcCallType,
  GrpcClient,
  GrpcClientFactory,
  GrpcEvent,
  GrpcMetadata
} from '@ngx-grpc/common';
import {
  GRPC_CLIENT_FACTORY,
  GrpcHandler,
  takeMessages,
  throwStatusErrors
} from '@ngx-grpc/core';
import { Observable } from 'rxjs';
import * as thisProto from './xprojector.modulebms.events.pb';
import * as googleProtobuf000 from './google/protobuf/timestamp.pb';
import { GRPC_EVENTS_CLIENT_SETTINGS } from './xprojector.modulebms.events.pbconf';
/**
 * Service client implementation for modulebms.events.Events
 */
@Injectable({ providedIn: 'root' })
export class EventsClient {
  private client: GrpcClient<any>;

  /**
   * Raw RPC implementation for each service client method.
   * The raw methods provide more control on the incoming data and events. E.g. they can be useful to read status `OK` metadata.
   * Attention: these methods do not throw errors when non-zero status codes are received.
   */
  $raw = {
    /**
     * Unary call: /modulebms.events.Events/AcknowledgeEvent
     *
     * @param requestMessage Request message
     * @param requestMetadata Request metadata
     * @returns Observable<GrpcEvent<thisProto.AcknowledgeEventResponse>>
     */
    acknowledgeEvent: (
      requestData: thisProto.AcknowledgeEventRequest,
      requestMetadata = new GrpcMetadata()
    ): Observable<GrpcEvent<thisProto.AcknowledgeEventResponse>> => {
      return this.handler.handle({
        type: GrpcCallType.unary,
        client: this.client,
        path: '/modulebms.events.Events/AcknowledgeEvent',
        requestData,
        requestMetadata,
        requestClass: thisProto.AcknowledgeEventRequest,
        responseClass: thisProto.AcknowledgeEventResponse
      });
    },
    /**
     * Unary call: /modulebms.events.Events/ClearEvent
     *
     * @param requestMessage Request message
     * @param requestMetadata Request metadata
     * @returns Observable<GrpcEvent<thisProto.ClearEventResponse>>
     */
    clearEvent: (
      requestData: thisProto.ClearEventRequest,
      requestMetadata = new GrpcMetadata()
    ): Observable<GrpcEvent<thisProto.ClearEventResponse>> => {
      return this.handler.handle({
        type: GrpcCallType.unary,
        client: this.client,
        path: '/modulebms.events.Events/ClearEvent',
        requestData,
        requestMetadata,
        requestClass: thisProto.ClearEventRequest,
        responseClass: thisProto.ClearEventResponse
      });
    },
    /**
     * Unary call: /modulebms.events.Events/MuteEvent
     *
     * @param requestMessage Request message
     * @param requestMetadata Request metadata
     * @returns Observable<GrpcEvent<thisProto.MuteEventResponse>>
     */
    muteEvent: (
      requestData: thisProto.MuteEventRequest,
      requestMetadata = new GrpcMetadata()
    ): Observable<GrpcEvent<thisProto.MuteEventResponse>> => {
      return this.handler.handle({
        type: GrpcCallType.unary,
        client: this.client,
        path: '/modulebms.events.Events/MuteEvent',
        requestData,
        requestMetadata,
        requestClass: thisProto.MuteEventRequest,
        responseClass: thisProto.MuteEventResponse
      });
    },
    /**
     * Unary call: /modulebms.events.Events/UnmuteEvent
     *
     * @param requestMessage Request message
     * @param requestMetadata Request metadata
     * @returns Observable<GrpcEvent<thisProto.UnmuteEventResponse>>
     */
    unmuteEvent: (
      requestData: thisProto.UnmuteEventRequest,
      requestMetadata = new GrpcMetadata()
    ): Observable<GrpcEvent<thisProto.UnmuteEventResponse>> => {
      return this.handler.handle({
        type: GrpcCallType.unary,
        client: this.client,
        path: '/modulebms.events.Events/UnmuteEvent',
        requestData,
        requestMetadata,
        requestClass: thisProto.UnmuteEventRequest,
        responseClass: thisProto.UnmuteEventResponse
      });
    },
    /**
     * Unary call: /modulebms.events.Events/UnsubscribeEvents
     *
     * @param requestMessage Request message
     * @param requestMetadata Request metadata
     * @returns Observable<GrpcEvent<thisProto.UnsubscribeEventsResponse>>
     */
    unsubscribeEvents: (
      requestData: thisProto.UnsubscribeEventsRequest,
      requestMetadata = new GrpcMetadata()
    ): Observable<GrpcEvent<thisProto.UnsubscribeEventsResponse>> => {
      return this.handler.handle({
        type: GrpcCallType.unary,
        client: this.client,
        path: '/modulebms.events.Events/UnsubscribeEvents',
        requestData,
        requestMetadata,
        requestClass: thisProto.UnsubscribeEventsRequest,
        responseClass: thisProto.UnsubscribeEventsResponse
      });
    },
    /**
     * Unary call: /modulebms.events.Events/GetEvent
     *
     * @param requestMessage Request message
     * @param requestMetadata Request metadata
     * @returns Observable<GrpcEvent<thisProto.GetEventResponse>>
     */
    getEvent: (
      requestData: thisProto.GetEventRequest,
      requestMetadata = new GrpcMetadata()
    ): Observable<GrpcEvent<thisProto.GetEventResponse>> => {
      return this.handler.handle({
        type: GrpcCallType.unary,
        client: this.client,
        path: '/modulebms.events.Events/GetEvent',
        requestData,
        requestMetadata,
        requestClass: thisProto.GetEventRequest,
        responseClass: thisProto.GetEventResponse
      });
    },
    /**
     * Unary call: /modulebms.events.Events/UpdateEvent
     *
     * @param requestMessage Request message
     * @param requestMetadata Request metadata
     * @returns Observable<GrpcEvent<thisProto.UpdateEventResponse>>
     */
    updateEvent: (
      requestData: thisProto.UpdateEventRequest,
      requestMetadata = new GrpcMetadata()
    ): Observable<GrpcEvent<thisProto.UpdateEventResponse>> => {
      return this.handler.handle({
        type: GrpcCallType.unary,
        client: this.client,
        path: '/modulebms.events.Events/UpdateEvent',
        requestData,
        requestMetadata,
        requestClass: thisProto.UpdateEventRequest,
        responseClass: thisProto.UpdateEventResponse
      });
    },
    /**
     * Unary call: /modulebms.events.Events/GetEvents
     *
     * @param requestMessage Request message
     * @param requestMetadata Request metadata
     * @returns Observable<GrpcEvent<thisProto.GetEventsResponse>>
     */
    getEvents: (
      requestData: thisProto.GetEventsRequest,
      requestMetadata = new GrpcMetadata()
    ): Observable<GrpcEvent<thisProto.GetEventsResponse>> => {
      return this.handler.handle({
        type: GrpcCallType.unary,
        client: this.client,
        path: '/modulebms.events.Events/GetEvents',
        requestData,
        requestMetadata,
        requestClass: thisProto.GetEventsRequest,
        responseClass: thisProto.GetEventsResponse
      });
    },
    /**
     * Unary call: /modulebms.events.Events/GetEventsHistory
     *
     * @param requestMessage Request message
     * @param requestMetadata Request metadata
     * @returns Observable<GrpcEvent<thisProto.GetEventsHistoryResponse>>
     */
    getEventsHistory: (
      requestData: thisProto.GetEventsHistoryRequest,
      requestMetadata = new GrpcMetadata()
    ): Observable<GrpcEvent<thisProto.GetEventsHistoryResponse>> => {
      return this.handler.handle({
        type: GrpcCallType.unary,
        client: this.client,
        path: '/modulebms.events.Events/GetEventsHistory',
        requestData,
        requestMetadata,
        requestClass: thisProto.GetEventsHistoryRequest,
        responseClass: thisProto.GetEventsHistoryResponse
      });
    },
    /**
     * Unary call: /modulebms.events.Events/GetEventSettings
     *
     * @param requestMessage Request message
     * @param requestMetadata Request metadata
     * @returns Observable<GrpcEvent<thisProto.GetEventSettingsResponse>>
     */
    getEventSettings: (
      requestData: thisProto.GetEventSettingsRequest,
      requestMetadata = new GrpcMetadata()
    ): Observable<GrpcEvent<thisProto.GetEventSettingsResponse>> => {
      return this.handler.handle({
        type: GrpcCallType.unary,
        client: this.client,
        path: '/modulebms.events.Events/GetEventSettings',
        requestData,
        requestMetadata,
        requestClass: thisProto.GetEventSettingsRequest,
        responseClass: thisProto.GetEventSettingsResponse
      });
    },
    /**
     * Unary call: /modulebms.events.Events/UpdateEventSettings
     *
     * @param requestMessage Request message
     * @param requestMetadata Request metadata
     * @returns Observable<GrpcEvent<thisProto.UpdateEventSettingsResponse>>
     */
    updateEventSettings: (
      requestData: thisProto.UpdateEventSettingsRequest,
      requestMetadata = new GrpcMetadata()
    ): Observable<GrpcEvent<thisProto.UpdateEventSettingsResponse>> => {
      return this.handler.handle({
        type: GrpcCallType.unary,
        client: this.client,
        path: '/modulebms.events.Events/UpdateEventSettings',
        requestData,
        requestMetadata,
        requestClass: thisProto.UpdateEventSettingsRequest,
        responseClass: thisProto.UpdateEventSettingsResponse
      });
    },
    /**
     * Unary call: /modulebms.events.Events/SendSms
     *
     * @param requestMessage Request message
     * @param requestMetadata Request metadata
     * @returns Observable<GrpcEvent<thisProto.SendSmsResponse>>
     */
    sendSms: (
      requestData: thisProto.SendSmsRequest,
      requestMetadata = new GrpcMetadata()
    ): Observable<GrpcEvent<thisProto.SendSmsResponse>> => {
      return this.handler.handle({
        type: GrpcCallType.unary,
        client: this.client,
        path: '/modulebms.events.Events/SendSms',
        requestData,
        requestMetadata,
        requestClass: thisProto.SendSmsRequest,
        responseClass: thisProto.SendSmsResponse
      });
    }
  };

  constructor(
    @Optional() @Inject(GRPC_EVENTS_CLIENT_SETTINGS) settings: any,
    @Inject(GRPC_CLIENT_FACTORY) clientFactory: GrpcClientFactory<any>,
    private handler: GrpcHandler
  ) {
    this.client = clientFactory.createClient(
      'modulebms.events.Events',
      settings
    );
  }

  /**
   * Unary call @/modulebms.events.Events/AcknowledgeEvent
   *
   * @param requestMessage Request message
   * @param requestMetadata Request metadata
   * @returns Observable<thisProto.AcknowledgeEventResponse>
   */
  acknowledgeEvent(
    requestData: thisProto.AcknowledgeEventRequest,
    requestMetadata = new GrpcMetadata()
  ): Observable<thisProto.AcknowledgeEventResponse> {
    return this.$raw
      .acknowledgeEvent(requestData, requestMetadata)
      .pipe(throwStatusErrors(), takeMessages());
  }

  /**
   * Unary call @/modulebms.events.Events/ClearEvent
   *
   * @param requestMessage Request message
   * @param requestMetadata Request metadata
   * @returns Observable<thisProto.ClearEventResponse>
   */
  clearEvent(
    requestData: thisProto.ClearEventRequest,
    requestMetadata = new GrpcMetadata()
  ): Observable<thisProto.ClearEventResponse> {
    return this.$raw
      .clearEvent(requestData, requestMetadata)
      .pipe(throwStatusErrors(), takeMessages());
  }

  /**
   * Unary call @/modulebms.events.Events/MuteEvent
   *
   * @param requestMessage Request message
   * @param requestMetadata Request metadata
   * @returns Observable<thisProto.MuteEventResponse>
   */
  muteEvent(
    requestData: thisProto.MuteEventRequest,
    requestMetadata = new GrpcMetadata()
  ): Observable<thisProto.MuteEventResponse> {
    return this.$raw
      .muteEvent(requestData, requestMetadata)
      .pipe(throwStatusErrors(), takeMessages());
  }

  /**
   * Unary call @/modulebms.events.Events/UnmuteEvent
   *
   * @param requestMessage Request message
   * @param requestMetadata Request metadata
   * @returns Observable<thisProto.UnmuteEventResponse>
   */
  unmuteEvent(
    requestData: thisProto.UnmuteEventRequest,
    requestMetadata = new GrpcMetadata()
  ): Observable<thisProto.UnmuteEventResponse> {
    return this.$raw
      .unmuteEvent(requestData, requestMetadata)
      .pipe(throwStatusErrors(), takeMessages());
  }

  /**
   * Unary call @/modulebms.events.Events/UnsubscribeEvents
   *
   * @param requestMessage Request message
   * @param requestMetadata Request metadata
   * @returns Observable<thisProto.UnsubscribeEventsResponse>
   */
  unsubscribeEvents(
    requestData: thisProto.UnsubscribeEventsRequest,
    requestMetadata = new GrpcMetadata()
  ): Observable<thisProto.UnsubscribeEventsResponse> {
    return this.$raw
      .unsubscribeEvents(requestData, requestMetadata)
      .pipe(throwStatusErrors(), takeMessages());
  }

  /**
   * Unary call @/modulebms.events.Events/GetEvent
   *
   * @param requestMessage Request message
   * @param requestMetadata Request metadata
   * @returns Observable<thisProto.GetEventResponse>
   */
  getEvent(
    requestData: thisProto.GetEventRequest,
    requestMetadata = new GrpcMetadata()
  ): Observable<thisProto.GetEventResponse> {
    return this.$raw
      .getEvent(requestData, requestMetadata)
      .pipe(throwStatusErrors(), takeMessages());
  }

  /**
   * Unary call @/modulebms.events.Events/UpdateEvent
   *
   * @param requestMessage Request message
   * @param requestMetadata Request metadata
   * @returns Observable<thisProto.UpdateEventResponse>
   */
  updateEvent(
    requestData: thisProto.UpdateEventRequest,
    requestMetadata = new GrpcMetadata()
  ): Observable<thisProto.UpdateEventResponse> {
    return this.$raw
      .updateEvent(requestData, requestMetadata)
      .pipe(throwStatusErrors(), takeMessages());
  }

  /**
   * Unary call @/modulebms.events.Events/GetEvents
   *
   * @param requestMessage Request message
   * @param requestMetadata Request metadata
   * @returns Observable<thisProto.GetEventsResponse>
   */
  getEvents(
    requestData: thisProto.GetEventsRequest,
    requestMetadata = new GrpcMetadata()
  ): Observable<thisProto.GetEventsResponse> {
    return this.$raw
      .getEvents(requestData, requestMetadata)
      .pipe(throwStatusErrors(), takeMessages());
  }

  /**
   * Unary call @/modulebms.events.Events/GetEventsHistory
   *
   * @param requestMessage Request message
   * @param requestMetadata Request metadata
   * @returns Observable<thisProto.GetEventsHistoryResponse>
   */
  getEventsHistory(
    requestData: thisProto.GetEventsHistoryRequest,
    requestMetadata = new GrpcMetadata()
  ): Observable<thisProto.GetEventsHistoryResponse> {
    return this.$raw
      .getEventsHistory(requestData, requestMetadata)
      .pipe(throwStatusErrors(), takeMessages());
  }

  /**
   * Unary call @/modulebms.events.Events/GetEventSettings
   *
   * @param requestMessage Request message
   * @param requestMetadata Request metadata
   * @returns Observable<thisProto.GetEventSettingsResponse>
   */
  getEventSettings(
    requestData: thisProto.GetEventSettingsRequest,
    requestMetadata = new GrpcMetadata()
  ): Observable<thisProto.GetEventSettingsResponse> {
    return this.$raw
      .getEventSettings(requestData, requestMetadata)
      .pipe(throwStatusErrors(), takeMessages());
  }

  /**
   * Unary call @/modulebms.events.Events/UpdateEventSettings
   *
   * @param requestMessage Request message
   * @param requestMetadata Request metadata
   * @returns Observable<thisProto.UpdateEventSettingsResponse>
   */
  updateEventSettings(
    requestData: thisProto.UpdateEventSettingsRequest,
    requestMetadata = new GrpcMetadata()
  ): Observable<thisProto.UpdateEventSettingsResponse> {
    return this.$raw
      .updateEventSettings(requestData, requestMetadata)
      .pipe(throwStatusErrors(), takeMessages());
  }

  /**
   * Unary call @/modulebms.events.Events/SendSms
   *
   * @param requestMessage Request message
   * @param requestMetadata Request metadata
   * @returns Observable<thisProto.SendSmsResponse>
   */
  sendSms(
    requestData: thisProto.SendSmsRequest,
    requestMetadata = new GrpcMetadata()
  ): Observable<thisProto.SendSmsResponse> {
    return this.$raw
      .sendSms(requestData, requestMetadata)
      .pipe(throwStatusErrors(), takeMessages());
  }
}
