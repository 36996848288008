import { Component, OnInit, Input, AfterViewInit, ViewChild, ElementRef, Output, EventEmitter, Inject, ChangeDetectorRef } from '@angular/core';
import { ChartWidgetConfig, ChartWidgetQuery, YAxesConfig } from '../chart-widget-config/xproj-chart-widget-config-service';
import { Projection, ProjectionColumnDescription, FilterLogicalGroupType, BaseQuery, BaseQueryInputColumnDescription, Transformation, Aggregation, ColumnGroupingDescription, XProjectorClient, LuaQueryColumn, LuaExportColumn } from '../../../XProjector/xprojector-client-service';
import { DataFilter } from '../../../filters/data-filter/data-filter-service';
import { XprojProjectionFilterComponent } from '../../../filters/projection-filter/xproj-projection-filter.component';
import { YAxisId } from '../chart-widget-config/xproj-chart-widget-config-service';
import { TypedJSON } from 'typedjson';
import { ArrayUtils } from '../../../utils/array-utils-service';
import { XprojGroupSelectionComponent } from '../../../filters/group-selection/xproj-group-selection.component';
import { GroupSelectionTypes, OutputDataType, WidgetConfig, WidgetInputParameter, WidgetPrQueryColumnConfig } from '../../widget-config-service';
import { LOGGERSERVICE, XprojLoggerService } from '../../../logger/xproj-logger-service';

export class QueryProjectionData {
  projection: Projection;
  queryableSelectedGroup: string[] = [];
  columns: ProjectionColumnDescription[] = [];
  selectedGroup: string[] = [];
  xaxis: string = "";
  usegrouping: boolean = false;
  xaxistransform: Transformation = Transformation.NONE;
  transformInputParameterId: string = '';
  useTransformInputParameter: boolean = false;
  yaxises: string[] = [];
  splitby : string = null;
  stackLeft : boolean = false;
  stackRight : boolean = false;
  maxItems : number = 1000;

  yaxisesAggregation: Aggregation[] = [];
  yAxesConfigs: YAxesConfig[] = [];
  timefiltercolumn: string;
  useProjectionInputParameter: boolean;
  projectionInputParameterId: string = '';
  //useGroupInputParameter: boolean;
  groupSelectionType : GroupSelectionTypes = GroupSelectionTypes.GROUP;
  groupInputParameterId: string = '';
  groupInputParameterIds: string[] = [];
  scriptedcolumnspostaggregation : LuaQueryColumn[] = [];
  preQueryColumnConfigs : { columnname : string, columnConfig : WidgetPrQueryColumnConfig }[] = [];
}


@Component({
  selector: 'xproj-chart-widget-query-config',
  templateUrl: './xproj-chart-widget-query-config.component.html',
  styleUrls: ['./xproj-chart-widget-query-config.component.scss']
})
export class XprojChartWidgetQueryConfigComponent implements OnInit, AfterViewInit {
  @ViewChild("projectionFilter", { read: XprojProjectionFilterComponent, static: false }) projectionFilter: XprojProjectionFilterComponent;
  @ViewChild("groupSelect", { read: XprojGroupSelectionComponent, static: false }) groupSelect: XprojGroupSelectionComponent;

  @Input() widgetQuery: ChartWidgetQuery;
  @Input() projections: Projection[] = [];
  @Input() inputParameters: WidgetInputParameter[] = [];
  @Input() widgets: WidgetConfig[];
  @Input() widgetConfig: ChartWidgetConfig;

  @Output() onQueryRemoved = new EventEmitter<ChartWidgetQuery>();
  @Output() onDuplicateQuery = new EventEmitter<ChartWidgetQuery>();

  queryData: QueryProjectionData = new QueryProjectionData();
  selectedYAxis: any = null;


  Transformation = Transformation;
  Aggregation = Aggregation;
  YAxisId = YAxisId;
  OutputDataType = OutputDataType;
  GroupSelectionTypes = GroupSelectionTypes;

  constructor(
    @Inject(LOGGERSERVICE) private logger: XprojLoggerService,
     private xprojClient: XProjectorClient,
     private cdr: ChangeDetectorRef) {

  }

  ngOnInit() {

    this.queryData.selectedGroup = this.widgetQuery.Query.targetgroup
    this.queryData.xaxis = this.widgetQuery.Xaxis;
    this.queryData.yaxises = this.widgetQuery.Yaxises;
    this.queryData.yAxesConfigs = this.widgetQuery.YAxesConfigs;

    this.queryData.usegrouping = this.widgetQuery.UseGrouping;
    this.queryData.timefiltercolumn = this.widgetQuery.timestampColumnName;
    this.queryData.xaxistransform = this.widgetQuery.XaxisTransform;
    this.queryData.yaxisesAggregation = this.widgetQuery.YaxisesTransforms;
    this.queryData.useTransformInputParameter = this.widgetQuery.UseTransformInputParameter;
    this.queryData.transformInputParameterId = this.widgetQuery.TransformInputParameterId;
    this.queryData.useProjectionInputParameter = this.widgetQuery.UseProjectionInputParameter;
    this.queryData.projectionInputParameterId = this.widgetQuery.ProjectionInputParameterId;
    this.queryData.groupSelectionType = this.widgetQuery.GroupSelectionType;
    this.queryData.groupInputParameterId = this.widgetQuery.GroupInputParameterId;
    this.queryData.groupInputParameterIds = this.widgetQuery.GroupInputParameterIds;
    this.queryData.splitby = this.widgetQuery.SplitBy;
    this.queryData.stackLeft = this.widgetQuery.StackLeft;
    this.queryData.stackRight = this.widgetQuery.StackRight;
    this.queryData.maxItems = this.widgetQuery.MaxItems;


    this.queryData.yAxesConfigs.forEach(config => {
      if (config.BorderDash.length == 0) {
        config.BorderDash = [0, 0];
      }
    });

    this.widgetQuery.Query.scriptedcolumnspostaggregation.forEach(scriptcol => {
      this.queryData.scriptedcolumnspostaggregation.push(TypedJSON.parse(JSON.stringify(scriptcol), LuaQueryColumn));
    })
  }

  async ngAfterViewInit() {
    if (this.projections?.length == 0) {
      this.projections = await this.xprojClient.RequestListQueryableProjections(0, 10000);
    }

    this.queryData.projection = this.projections.find(p => p.projectionid == this.widgetQuery.Query.targetprojectionid);

    await this.selectedProjectionGroupChange(this.queryData.selectedGroup);
  }

  addYaxis() {
    this.queryData.yaxises.push('');
    this.queryData.yaxisesAggregation.push(Aggregation.NONE);
    this.queryData.yAxesConfigs.push(new YAxesConfig());
  }

  async selectedProjectionChange(projection: Projection) {
    this.widgetQuery.sampledSeries = {};
    this.logger.info("Reseted sampled series (projection changed)");
    if (projection) {
      this.queryData.selectedGroup = null;
      this.queryData.queryableSelectedGroup.length = 0;
      await this.queryColumns(this.queryData.projection.projectionid, null);
    }
  }

  async selectedProjectionGroupChange(group: any) {
    this.widgetQuery.sampledSeries = {};
    this.logger.info("Reseted sampled series (projection group)");
    if (this.queryData.projection) {
      this.queryData.selectedGroup = group;
      this.queryData.queryableSelectedGroup.length = 0;
      for (let gr of group) {
        this.queryData.queryableSelectedGroup.push(gr);
      }
      //this.logger.info('selectedProjectionGroupChange', group);
      await this.queryColumns(this.queryData.projection.projectionid, group);
    }
  }

  async queryColumns(projectionId: string, group: Array<string>) {
    //this.loadingProjectionColumns = true;
    let groupstr = "";
    if (group) {
      groupstr = group.join(",");
    }

    this.queryData.columns = await this.xprojClient.RequestListQueryableProjectionColumns(projectionId, groupstr, 0, 500);

    this.queryData.preQueryColumnConfigs = [];
    this.widgetConfig.WidgetPreQueryConfigs.forEach(preConfig => {
      preConfig.ColumnConfigs.forEach(c => this.queryData.preQueryColumnConfigs.push({ columnname: (preConfig.Prefix?.length > 0 ? preConfig.Prefix : preConfig.Name) + ':' + c.ColumnOutName, columnConfig: c }));
    });

    //this.loadingProjectionColumns = false;

  }

  removeQuery() {
    this.onQueryRemoved?.emit(this.widgetQuery);
  }

  duplicateQuery() {
    this.onDuplicateQuery?.emit(this.widgetQuery);
  }

  moveColumnUp(index: number) {
    ArrayUtils.MoveItemUp(this.queryData.yaxises, index);
    ArrayUtils.MoveItemUp(this.queryData.yAxesConfigs, index);
    this.refreshColumnConfigs();
  }
  moveColumnDown(index: number) {
    ArrayUtils.MoveItemDown(this.queryData.yaxises, index);
    ArrayUtils.MoveItemDown(this.queryData.yAxesConfigs, index);
    this.refreshColumnConfigs();
  }

  private refreshColumnConfigs() {
    let copy = [...this.queryData.yaxises];
    let copy2 = [...this.queryData.yAxesConfigs];
    this.queryData.yaxises = [];
    this.queryData.yAxesConfigs = [];
    this.cdr.detectChanges();
    this.queryData.yaxises = copy;
    this.queryData.yAxesConfigs = copy2;
  }

  removeSelectedYAxis() {
    for (let i = 0; i < this.queryData.yAxesConfigs.length; i++) {
      if (this.queryData.yAxesConfigs[i] == this.selectedYAxis) {
        this.removeYAxis(i);
        this.selectedYAxis = null;
        return;
      }
    }
  }

  removeYAxis(index: number) {
    ArrayUtils.RemoveItemAt(this.queryData.yaxises, index);
    ArrayUtils.RemoveItemAt(this.queryData.yAxesConfigs, index);
  }

  onYAxisSelect($event, index) {
    let column = this.queryData.columns.find(col => col.columnname == $event);
    if (column) {
      this.queryData.yAxesConfigs[index].ColumnName = column.columnname;
      if (column.unit?.length > 0 && index < this.queryData.yAxesConfigs.length) {
        this.queryData.yAxesConfigs[index].Unit = column.unit;
      }
    }
    else {
      let column = this.queryData.preQueryColumnConfigs.find(col => col.columnname == $event);
      if (column) {
        this.queryData.yAxesConfigs[index].ColumnName = column.columnname;
        if (column.columnConfig.Unit?.length > 0 && index < this.queryData.yAxesConfigs.length) {
          this.queryData.yAxesConfigs[index].Unit = column.columnConfig.Unit;
        }
      }
      else {
        let column = this.queryData.scriptedcolumnspostaggregation.find(col => 'script:' + col.columnoutname == $event);
        if (column) {
          this.queryData.yAxesConfigs[index].ColumnName = column.columnoutname;
        }
      }
    }
  }

  addScriptedColumnsPostAggregation() {
    this.queryData.scriptedcolumnspostaggregation.push(new LuaQueryColumn());
  }

  onLuaQueryColumnRemoved(queryColumn: LuaQueryColumn) {
    this.queryData.scriptedcolumnspostaggregation = this.queryData.scriptedcolumnspostaggregation.filter(q => q != queryColumn);
  }

  updateInputParameters(inputs: WidgetInputParameter[]) {
    this.inputParameters = inputs;
    if (this.projectionFilter) {
      this.projectionFilter.inputParameters = this.inputParameters;
    }
  }

  async SaveQuery() {
    if (this.queryData.xaxis == '') {
      this.logger.debug('SaveQuery - empty xaxis.')
      return;
    }

    //convert from literal object to class object
    this.widgetQuery.DataFilters.forEach(filter => {
      filter.ColumnDescriptor = TypedJSON.parse(JSON.stringify(filter.ColumnDescriptor), ProjectionColumnDescription);
    });

    this.widgetQuery.Query = this.projectionFilter.GetQuery();
    this.widgetQuery.DataFilters = this.projectionFilter.datafilters;
    this.widgetQuery.FilterLogicalGroupType = this.projectionFilter.filterLogicalGroupType;
    this.widgetQuery.Query.maxitems = this.queryData.maxItems;;
    this.widgetQuery.Query.scriptedcolumnspostaggregation = this.queryData.scriptedcolumnspostaggregation;

    let xcol = new BaseQueryInputColumnDescription();
    xcol.columnname = this.queryData.xaxis;
    xcol.columnoutname = xcol.columnname;

    if (!this.queryData.usegrouping) {
      this.widgetQuery.Query.columns.push(xcol);
      this.widgetQuery.Query.sorting.columnname = xcol.columnname;
      this.widgetQuery.Query.sorting.descending = false;
      this.widgetQuery.Query.grouping = new ColumnGroupingDescription();
    }
    else {
      this.widgetQuery.Query.grouping.columnname = xcol.columnname;
      this.widgetQuery.Query.grouping.columntransformation = this.queryData.xaxistransform;
      this.widgetQuery.Query.grouping.columnoutname = xcol.columnoutname;
    }

    if (this.queryData.projection) {
      this.widgetQuery.Query.targetprojectionid = this.queryData.projection.projectionid;
    }

    if (this.queryData.yaxises.length == 0 || this.queryData.yaxises[0] == "") {
      return;
    }

    let yindex = 0;
    for (let yaxis of this.queryData.yaxises) {
      let ycol = new BaseQueryInputColumnDescription();
      ycol.columnname = yaxis;
      let yAxesConfig : YAxesConfig;
      if (yindex < this.queryData.yAxesConfigs.length) {
        yAxesConfig = this.queryData.yAxesConfigs[yindex];
      }
      if (yAxesConfig) {
        if (yAxesConfig.Label?.length > 0) {
          ycol.columnoutname = yAxesConfig.ColumnOutName = yAxesConfig.Label;
        }
        else {
          ycol.columnoutname = yAxesConfig.ColumnOutName = this.queryData.usegrouping ? "y" + yindex.toString() : ycol.columnname;
        }

        ycol.columntransformation = yAxesConfig.Transformation;
      }
      else {
        ycol.columnoutname = "y" + yindex.toString();
      }

      if (this.queryData.usegrouping) {
        ycol.columnaggregation = this.queryData.yaxisesAggregation[yindex];
      }

      yindex++;
      this.widgetQuery.Query.columns.push(ycol);
    }
    this.widgetQuery.Xaxis = this.queryData.xaxis;
    this.widgetQuery.SplitBy = this.queryData.splitby;
    this.widgetQuery.StackLeft = this.queryData.stackLeft;
    this.widgetQuery.StackRight  = this.queryData.stackRight;
    this.widgetQuery.MaxItems = this.queryData.maxItems;

    this.widgetQuery.Yaxises = this.queryData.yaxises;
    this.widgetQuery.YAxesConfigs = this.queryData.yAxesConfigs;

    this.widgetQuery.UseGrouping = this.queryData.usegrouping;
    this.widgetQuery.timestampColumnName = this.queryData.timefiltercolumn;
    this.widgetQuery.XaxisTransform = this.queryData.xaxistransform;
    this.widgetQuery.YaxisesTransforms = this.queryData.yaxisesAggregation;
    this.widgetQuery.UseTransformInputParameter = this.queryData.useTransformInputParameter;
    this.widgetQuery.TransformInputParameterId = this.queryData.transformInputParameterId;
    this.widgetQuery.UseProjectionInputParameter = this.queryData.useProjectionInputParameter;
    this.widgetQuery.ProjectionInputParameterId = this.queryData.projectionInputParameterId;
    this.widgetQuery.GroupSelectionType = this.queryData.groupSelectionType;
    this.widgetQuery.GroupInputParameterId = this.queryData.groupInputParameterId;
    this.widgetQuery.GroupInputParameterIds = this.queryData.groupInputParameterIds;

    if (this.groupSelect) {
      this.widgetQuery.Query.targetgroup = this.groupSelect.getSelectedGroup();
    }

  }

}
