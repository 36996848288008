import { Pipe, PipeTransform } from '@angular/core';
import { BmsCustomerConfig } from '../../../models/bms-customer-config';

@Pipe({
  name: 'bmscustomerenabledpipe'
})
export class BmsCustomerEnabledPipe implements PipeTransform {

  transform(items: any[], ...args: unknown[]): any[] {

    if (!items) return [];

    return items.filter(x => !x.customerConfig || !x.customerConfig.disabled);
  }

}
