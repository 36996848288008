<main class="content-area">
  <router-outlet></router-outlet>
</main>

<clr-vertical-nav [clr-nav-level]="2" [clrVerticalNavCollapsible]="true" [(clrVerticalNavCollapsed)]="collapsed">
  <clr-vertical-nav-group routerLinkActive="false">
    <clr-icon clrVerticalNavIcon shape="helix"></clr-icon>
    Customers

    <clr-vertical-nav-group-children>
      <a clrVerticalNavLink [routerLink]="['customers']" routerLinkActive="active">
        Customers view
      </a>
      <a clrVerticalNavLink [routerLink]="['addcustomer']" routerLinkActive="active">
        Add new customer
      </a>
    </clr-vertical-nav-group-children>
  </clr-vertical-nav-group>

  <clr-vertical-nav-group routerLinkActive="false">
    <clr-icon clrVerticalNavIcon shape="helix"></clr-icon>
    Trustees

    <clr-vertical-nav-group-children>
      <a clrVerticalNavLink [routerLink]="['trusteeoverview']" routerLinkActive="active">
        Trustees overview
      </a>
      <a clrVerticalNavLink [routerLink]="['trusteeadmin']" routerLinkActive="active">
        Trustees view
      </a>
    </clr-vertical-nav-group-children>
  </clr-vertical-nav-group>

  <clr-vertical-nav-group routerLinkActive="false">
    <clr-icon clrVerticalNavIcon shape="helix"></clr-icon>
    Meters

    <clr-vertical-nav-group-children>
      <a clrVerticalNavLink [routerLink]="['gatewayadmin']" routerLinkActive="active">
        Gateways view
      </a>
    </clr-vertical-nav-group-children>

    <clr-vertical-nav-group-children>
      <a clrVerticalNavLink [routerLink]="['meteradmin']" routerLinkActive="active">
        Meters view
      </a>
    </clr-vertical-nav-group-children>
  </clr-vertical-nav-group>

  <clr-vertical-nav-group routerLinkActive="false">
    <clr-icon clrVerticalNavIcon shape="helix"></clr-icon>
    Dashboards
    <clr-vertical-nav-group-children>
      <app-bms-admin-dashboards></app-bms-admin-dashboards>
    </clr-vertical-nav-group-children>
  </clr-vertical-nav-group>

  <clr-vertical-nav-group routerLinkActive="false">
    <clr-icon clrVerticalNavIcon shape="helix"></clr-icon>
    Bots

    <clr-vertical-nav-group-children>
      <a clrVerticalNavLink [routerLink]="['botsadmin']" routerLinkActive="active">
        Bots admin
      </a>
    </clr-vertical-nav-group-children>
  </clr-vertical-nav-group>

  <clr-vertical-nav-group routerLinkActive="false">
    <clr-icon clrVerticalNavIcon shape="helix"></clr-icon>
    Configuration

    <clr-vertical-nav-group-children>
      <a clrVerticalNavLink [routerLink]="['masterdata']" routerLinkActive="active">
        Import master data
      </a>
      <a clrVerticalNavLink [routerLink]="['geolocation']" routerLinkActive="active">
        Geo location
      </a>
      <a clrVerticalNavLink [routerLink]="['meteringapi']" routerLinkActive="active">
        Metering API
      </a>
      <a clrVerticalNavLink [routerLink]="['messageview']" routerLinkActive="active">
        Message view
      </a>
      <a clrVerticalNavLink [routerLink]="['lorawan']" routerLinkActive="active">
        LoRaWAN
      </a>
    </clr-vertical-nav-group-children>
  </clr-vertical-nav-group>

  <clr-vertical-nav-group routerLinkActive="false">
    <clr-icon clrVerticalNavIcon shape="helix"></clr-icon>
    Users

    <clr-vertical-nav-group-children>
      <a clrVerticalNavLink [routerLink]="['users/true']" routerLinkActive="active">
        Customer users
      </a>
      <a clrVerticalNavLink [routerLink]="['users']" routerLinkActive="active">
        Admin users
      </a>
      <!-- <a clrVerticalNavLink [routerLink]="['addcustomer']" routerLinkActive="active">
        Add new admin user
      </a> -->
    </clr-vertical-nav-group-children>
  </clr-vertical-nav-group>

</clr-vertical-nav>
