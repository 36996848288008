import { BmsRealestate } from './bms-realestate';
import { BmsBuilding } from './bms-building';
import { BmsBuildingAddress } from './bms-buildingaddress';
import { BmsApartment } from './bms-apartment';
import { BmsFacility } from './bms-facility';
import { BmsMeter } from './bms-meter';
import { BmsGateway } from './bms-gateway';
import { BmsMeterData } from './bms-meter-data';
import { BmsSystem } from './bms-system';

export class BmsCustomerData {
  id : number = -1;
  customerId : string;

  realestates : BmsRealestate[] = [];
  buildings : BmsBuilding[] = [];
  buildingAddresses : BmsBuildingAddress[] = [];
  apartments : BmsApartment[] = [];
  facilities : BmsFacility[] = [];
  meters : BmsMeter[] = [];
  gateways : BmsGateway[] = [];
  systems : BmsSystem[] = [];

  meterDatas : BmsMeterData[] = [];
}
