import { Component, OnInit, inject } from '@angular/core';
import { BmsDataExportValue, BmsDataExportValueStatus } from '@app/features/bms/models/bms-data-export-value';
import { ClrDatagridFilter, ClrDatagridStringFilterInterface } from '@clr/angular';
import { Subject } from 'rxjs';
import { LOGGERSERVICE, XprojLoggerService } from 'xproj-lib';

@Component({
  selector: 'app-data-export-status-filter',
  templateUrl: './data-export-status-filter.component.html',
  styleUrls: ['./data-export-status-filter.component.scss']
})
export class DataExportStatusFilterComponent implements OnInit, ClrDatagridStringFilterInterface<BmsDataExportValue> {

  private logger: XprojLoggerService = inject(LOGGERSERVICE);

  private statusFiltersAll = BmsDataExportValueStatus.AutomaticEstimate |
                              BmsDataExportValueStatus.ManualEstimate |
                              BmsDataExportValueStatus.MissingEndValue |
                              BmsDataExportValueStatus.MissingStartValue |
                              BmsDataExportValueStatus.MissingTariff |
                              BmsDataExportValueStatus.TariffExchange |
                              BmsDataExportValueStatus.Error |
                              BmsDataExportValueStatus.Warning |
                              BmsDataExportValueStatus.Signed |
                              BmsDataExportValueStatus.Invoiced |
                              BmsDataExportValueStatus.NegativeConsumption |
                              BmsDataExportValueStatus.HighConsumption |
                              BmsDataExportValueStatus.LowConsumption |
                              BmsDataExportValueStatus.Interpolated;

  private statusFilters : number = this.statusFiltersAll;
  private statusFilterOk : boolean = true;

  changes = new Subject<any>();

  statusList : {text : string, status : boolean, flag : BmsDataExportValueStatus}[] = [
    {
      text : 'OK',
      status : true,
      flag : BmsDataExportValueStatus.None
    },
    {
      text : 'AUT_EST',
      status : true,
      flag : BmsDataExportValueStatus.AutomaticEstimate
    },
    {
      text : 'MAN_EST',
      status : true,
      flag : BmsDataExportValueStatus.ManualEstimate
    },
    {
      text : 'NO_START',
      status : true,
      flag : BmsDataExportValueStatus.MissingStartValue
    },
    {
      text : 'NO_END',
      status : true,
      flag : BmsDataExportValueStatus.MissingEndValue
    },
    {
      text : 'NO_TARIFF',
      status : true,
      flag : BmsDataExportValueStatus.MissingTariff
    },
    {
      text : 'ERROR',
      status : true,
      flag : BmsDataExportValueStatus.Error
    },
    {
      text : 'WARNING',
      status : true,
      flag : BmsDataExportValueStatus.Warning
    },
    {
      text : 'SIGNED',
      status : true,
      flag : BmsDataExportValueStatus.Signed
    },
    {
      text : 'INVOICED',
      status : true,
      flag : BmsDataExportValueStatus.Invoiced
    },
    {
      text : 'NEG',
      status : true,
      flag : BmsDataExportValueStatus.NegativeConsumption
    },
    {
      text : 'HIGH',
      status : true,
      flag : BmsDataExportValueStatus.HighConsumption
    },
    {
      text : 'LOW',
      status : true,
      flag : BmsDataExportValueStatus.LowConsumption
    },
    {
      text : 'INTERPOLATED',
      status : true,
      flag : BmsDataExportValueStatus.Interpolated
    },
    {
      text : 'TARIFF EXCHANGE',
      status : true,
      flag : BmsDataExportValueStatus.TariffExchange
    }
  ];

  constructor(private filterContainer: ClrDatagridFilter) {
    filterContainer.setFilter(this);
  }

  change(): void {
    this.changes.next(true);
  }

  isActive(): boolean {
     return true;
  }

  accepts(item: BmsDataExportValue): boolean {
    return (this.statusFilterOk && item.status <= BmsDataExportValueStatus.ManualInput) || (item.status & this.statusFilters) > 0;
  }

  ngOnInit() {
  }

  statusChanged(checked : boolean, flag : BmsDataExportValueStatus) {
    if (checked) {
      this.statusFilters |= flag;
      if (flag == BmsDataExportValueStatus.None) {
        this.statusFilterOk = true;
      }
    }
    else {
      this.statusFilters &= ~flag;
      if (flag == BmsDataExportValueStatus.None) {
        this.statusFilterOk = false;
      }
    }
    this.change();
  }

  none() {
    this.statusList.forEach(x => x.status = false);
    this.statusFilters = 0;
    this.statusFilterOk = false;
    this.change();
  }

  all() {
    this.statusList.forEach(x => x.status = true);
    this.statusFilters = this.statusFiltersAll;
    this.statusFilterOk = true;
    this.change();
  }
}
