import { Injectable } from '@angular/core';
import { BmsMeterStateEvent } from '@features/bms/models/bms-meter-state-event';
import { BmsErrmeterTimestampfrom } from '@features/bms/models/bms-errmeter-timestampfrom';
import { NGXLogger } from 'ngx-logger';
import { ArrayUtils, BaseQuery, BaseQueryInputColumnDescription, ColumnFilteringNumerical, ColumnFilteringString, ColumnFilteringTimestamp, FilterComparator, FilterLogicalGroup, FilterLogicalGroupType, ProjectionColumnDescription, SetDataColumnDescription, SetDataQuery, XDataType, XProjectorClient } from 'xproj-lib';
import { flakeId } from '../utils/bms-data-utils';

export interface BmsMeterStateEventParameters {
  meterId: number,
  activeFromAfter?: Date,
  maxItems?: number
}

const METER_STATE_PROJECTIONID: string = 'hs-meterstate-event';
const ERRMETERS_TIMESTAMPFROM_PROJECTIONID: string = 'hs-errmeters-timestampfrom';

@Injectable({
  providedIn: 'root'
})
export class BmsMeterStateEventService {

  meterStateColumns: ProjectionColumnDescription[] = [];

  private setColumns: SetDataColumnDescription[] = [
    {
      columnname: 'id',
      datatype: XDataType.Int64,
      indexintypedvector: 0
    },
    {
      columnname: 'meterid',
      datatype: XDataType.Int64,
      indexintypedvector: 1
    },
    {
      columnname: 'createdat',
      datatype: XDataType.Timestamp,
      indexintypedvector: 0
    },
    {
      columnname: 'modifiedat',
      datatype: XDataType.Timestamp,
      indexintypedvector: 1
    },
    {
      columnname: 'deleted',
      datatype: XDataType.UInt8,
      indexintypedvector: 2
    },
    {
      columnname: 'deletedat',
      datatype: XDataType.Timestamp,
      indexintypedvector: 2
    },
    {
      columnname: 'activefrom',
      datatype: XDataType.Timestamp,
      indexintypedvector: 3
    },
        {
      columnname: 'meterstate',
      datatype: XDataType.UInt8,
      indexintypedvector: 3
    }
  ];

  errMetersFromColumns: ProjectionColumnDescription[] = [];

  private setRrrMetersFromColumns: SetDataColumnDescription[] = [
    {
      columnname: 'meterid',
      datatype: XDataType.Int64,
      indexintypedvector: 0
    },
    {
      columnname: 'status',
      datatype: XDataType.UInt8,
      indexintypedvector: 1
    },
    {
      columnname: 'status2',
      datatype: XDataType.UInt8,
      indexintypedvector: 2
    },
    {
      columnname: 'timestamp_from',
      datatype: XDataType.Timestamp,
      indexintypedvector: 0
    }
  ];

  constructor(
    private xprojClient: XProjectorClient,
    private logger: NGXLogger
  ) {

  }

  async setMeterStateEvent(meterStateEvents: BmsMeterStateEvent[]): Promise<boolean> {
    let query: SetDataQuery = new SetDataQuery();

    await ArrayUtils.AsyncForEach(meterStateEvents, async (meterExchange) => {
      if (!meterExchange.id || meterExchange.id == 0) {
        meterExchange.id = await flakeId.nextId();
      }
    });

    query.datastrings = [
    ];
    query.datanumbers = [
      meterStateEvents.map(t => t.id),
      meterStateEvents.map(t => t.meterId ?? 0),
      meterStateEvents.map(t => t.deleted ? 1 : 0),
      meterStateEvents.map(t => t.meterState ?? 0)
    ];
    query.datatimestamps = [
      meterStateEvents.map(t => t.createdAt ?? new Date()),
      meterStateEvents.map(t => t.modifiedAt ?? new Date()),
      meterStateEvents.map(t => t.deletedAt ?? new Date(0)),
      meterStateEvents.map(t => t.activeFrom ?? new Date())
    ];

    query.projectionid = METER_STATE_PROJECTIONID;
    query.columns = this.setColumns;

    return await this.xprojClient.RequestSetData(query);
  }

  async getMeterStateEvents({
    meterId,
    activeFromAfter = undefined,
    maxItems = 10
  }: BmsMeterStateEventParameters): Promise<BmsMeterStateEvent[]> {

    let result: BmsMeterStateEvent[] = [];
    if (meterId) {
      if (this.meterStateColumns.length == 0) {
        this.meterStateColumns = await this.xprojClient.RequestListQueryableProjectionColumns(METER_STATE_PROJECTIONID, '', 0, 100);
      }

      let query: BaseQuery = new BaseQuery();
      query.targetprojectionid = METER_STATE_PROJECTIONID;
      query.maxitems = maxItems;
      query.targetgroup = [];

      let filterId = 0;
      query.filter.type = FilterLogicalGroupType.AND;

      if (activeFromAfter) {
        let timestampFiltering = new ColumnFilteringTimestamp();
        timestampFiltering.columnname = 'activefrom';
        timestampFiltering.comparator = FilterComparator.GreatherThanOrEquals;
        timestampFiltering.value = activeFromAfter;
        timestampFiltering.queryfilterid = ++filterId;
        query.filter.filters.push(timestampFiltering.queryfilterid);
        query.timestampfilters.push(timestampFiltering);
      }

      let deletedFiltering = new ColumnFilteringNumerical();
      deletedFiltering.columnname = 'deleted';
      deletedFiltering.comparator = FilterComparator.Equals;
      deletedFiltering.value = 0;
      deletedFiltering.queryfilterid = ++filterId;
      query.filter.filters.push(deletedFiltering.queryfilterid);
      query.numericalfilters.push(deletedFiltering);

      this.meterStateColumns.forEach(c => {
        let inCol = new BaseQueryInputColumnDescription();
        inCol.columnname = c.columnname;
        inCol.columnoutname = c.columnname;
        query.columns.push(inCol);
      });

      query.sorting.columnname = 'activefrom';
      query.sorting.descending = true;

      query.targetgroup = [];

      try {
        let queryResult = await this.xprojClient.RequestQueryBaseQuery(query, true);

        let numericaldata = queryResult.datanumbers;
        let timestampdata = queryResult.datatimestamps;
        let stringdata = queryResult.datastrings;

        let rowCount = 0;
        if (numericaldata.length > 0) {
          rowCount = numericaldata[0].length;
        }

        for (let row = 0; row < rowCount; row++) {
          let meterStateEvent = new BmsMeterStateEvent();

          for (let i = 0; i < queryResult.columns.length; i++) {
            let it = queryResult.columns[i];
            let typ = it.datatype;
            let data = [];
            if (typ == XDataType.Number) {
              data = numericaldata[it.indexintypedvector];
            }
            if (typ == XDataType.String) {
              data = stringdata[it.indexintypedvector];
            }
            if (typ == XDataType.Timestamp) {
              data = timestampdata[it.indexintypedvector];
            }

            switch (it.columnoutname) {
              case 'id':
                meterStateEvent.id = data[row];
                break;
              case 'meterid':
                meterStateEvent.meterId = data[row];
                break;
              case 'meterstate':
                meterStateEvent.meterState = data[row];
                break;
              case 'activefrom':
                meterStateEvent.activeFrom = data[row];
                break;
              case 'createdat':
                meterStateEvent.createdAt = data[row];
                break;
              case 'modifiedat':
                meterStateEvent.modifiedAt = data[row];
                break;
            }
          }

          result.push(meterStateEvent);
        }
      }
      catch (err) {
        this.logger.error(err);
      }
    }
    return result;
  }

  async deleteMeterStateEvent(meterStateEvent : BmsMeterStateEvent) : Promise<boolean> {
    meterStateEvent.deleted = true;
    meterStateEvent.deletedAt = new Date();

    return this.setMeterStateEvent([meterStateEvent]);
  }

  async setErrMeterTimestampFrom(errmeterTimestampFroms: BmsErrmeterTimestampfrom[]): Promise<boolean> {
    let query: SetDataQuery = new SetDataQuery();

    query.datastrings = [
    ];
    query.datanumbers = [
      errmeterTimestampFroms.map(t => t.meterid),
      errmeterTimestampFroms.map(t => t.status),
      errmeterTimestampFroms.map(t => t.status2)
    ];
    query.datatimestamps = [
      errmeterTimestampFroms.map(t => t.timestampFrom ?? new Date())
    ];

    query.projectionid = ERRMETERS_TIMESTAMPFROM_PROJECTIONID;
    query.columns = this.setRrrMetersFromColumns;

    return await this.xprojClient.RequestSetData(query);
  }
}
