
<table class="table table-vertical" *ngIf="realestate">
  <caption style="margin-bottom: 1em;">
    Realestate info
    <button class="btn btn-sm" style="float: right;" (click)="viewInBMS();">View in BMS <clr-icon shape="arrow" directions="left" style="transform: rotate(90deg);"></clr-icon></button>
  </caption>
  <tbody>
    <tr>
      <th class="left">Sv Lant Id</th>
      <td class="left">{{realestate.svlantPropertyDesignation}}</td>
    </tr>
    <tr>
      <th class="left">Description</th>
      <td class="left">{{realestate.description}}</td>
    </tr>
    <tr>
      <th class="left">Latitude</th>
      <td class="left">{{realestate.latitude}}</td>
    </tr>
    <tr>
      <th class="left">Longitude</th>
      <td class="left">{{realestate.longitude}}</td>
    </tr>
    <tr>
      <th class="left">Create At</th>
      <td class="left">{{dateHelper.utils.format(realestate.createdAt, 'keyboardDateTime')}}</td>
    </tr>
    <tr>
      <th class="left">Modified At</th>
      <td class="left">{{dateHelper.utils.format(realestate.modifiedAt, 'keyboardDateTime')}}</td>
    </tr>
  </tbody>
</table>
<p *ngIf="!realestate" > Realestate info not available.</p>
