import { ModuleWithProviders, NgModule, Optional, PLATFORM_ID, SkipSelf } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { CommonModule } from '@angular/common';
import { RouterModule } from '@angular/router';

import { ClarityModule } from '@clr/angular';
import { HttpClientModule } from '@angular/common/http';
import { NgxFileDropModule } from 'ngx-file-drop';
import { CacheModule } from './cache/cache.module';
import { BrowserTransferStateModule, TransferState } from '@angular/platform-browser';

import { GridsterModule } from 'angular-gridster2';
import { AngularSplitModule } from 'angular-split';
import { LMarkdownEditorModule } from 'ngx-markdown-editor';

import { NgxGaugeModule } from 'ngx-gauge';
import { NgxSvgModule } from 'ngx-svg';

import { XprojCommonStringsService } from './i18n/xproj-common-strings.service';

import { XprojFilterpipePipe } from './pipes/xproj-filterpipe.pipe';
import { XprojFilternumericalcolumnsPipe } from './pipes/xproj-filternumericalcolumns.pipe';
import { XprojFiltertimestampcolumnsPipe } from './pipes/xproj-filtertimestampcolumns.pipe';
import { XprojDataeditorFiltertimestampcolumnsPipe } from './widgets/projection-dataeditor/projection-dataeditor-widget-config/xproj-dataeditor-filtertimestampcolumns.pipe';
import { XprojDatagridstringfilterComponent } from './filters/datagrid-string-filter/xproj-datagrid-string-filter.component';
import { XprojDatagridNumericFilterComponent } from './filters/datagrid-numeric-filter/xproj-datagrid-numeric-filter.component';
import { XprojProjectionFilterComponent } from './filters/projection-filter/xproj-projection-filter.component';
import { XprojDataFilterComponent } from './filters/data-filter/xproj-data-filter.component';
import { XprojChartWidgetComponent } from './widgets/chart/chart-widget/xproj-chart-widget.component';
import { XprojWidgetService } from './widgets/xproj-widget-service';
import { XprojDashboardInteractService } from './dashboard/xproj-dashboard-interact.service';
import { XprojDashboardComponent } from './dashboard/xproj-dashboard.component';
import { XprojChartWidgetConfigComponent } from './widgets/chart/chart-widget-config/xproj-chart-widget-config.component';
import { XprojChartWidgetQueryConfigComponent } from './widgets/chart/chart-widget-query-config/xproj-chart-widget-query-config.component';
import { XprojTypeofPipe } from './pipes/xproj-typeof.pipe';
import { XprojTableWidgetComponent } from './widgets/table/table-widget/xproj-table-widget.component';
import { XprojTableWidgetConfigComponent } from './widgets/table/table-widget-config/xproj-table-widget-config.component';
import { XprojWidgetConfigComponent } from './widgets/widget-config/xproj-widget-config.component';
import { XprojDashboardConfigComponent } from './dashboard/dashboard-config/xproj-dashboard-config.component';
import { XprojTableWidgetQueryConfigComponent } from './widgets/table/table-widget-query-config/xproj-table-widget-query-config.component';
import { XprojMasterWidgetComponent } from './widgets/master/master-widget/xproj-master-widget.component';
import { XprojMasterWidgetConfigComponent } from './widgets/master/master-widget-config/xproj-master-widget-config.component';
import { XprojMasterWidgetTimeComponent } from './widgets/master/master-widget-time/xproj-master-widget-time.component';
import { XprojGroupSelectionComponent } from './filters/group-selection/xproj-group-selection.component';
import { cacheTTLSeconds } from './misc-service';
import { XprojPiechartWidgetComponent } from './widgets/piechart/piechart-widget/xproj-piechart-widget.component';
import { XprojPiechartWidgetConfigComponent } from './widgets/piechart/piechart-widget-config/xproj-piechart-widget-config.component';
import { XprojLabelWidgetComponent } from './widgets/label/label-widget/xproj-label-widget.component';
import { XprojLabelWidgetConfigComponent } from './widgets/label/label-widget-config/xproj-label-widget-config.component';
import { XprojSpectrumAnalyzerWidgetComponent } from './widgets/spectrum-analyzer/spectrum-analyzer-widget/xproj-spectrum-analyzer-widget.component';
import { XprojSpectrumAnalyzerWidgetConfigComponent } from './widgets/spectrum-analyzer/spectrum-analyzer-widget-config/xproj-spectrum-analyzer-widget-config.component';
import { XprojSpectrumAnalyzerWidgetQueryConfigComponent } from './widgets/spectrum-analyzer/spectrum-analyzer-widget-query-config/xproj-spectrum-analyzer-widget-query-config.component';
import { XprojSpcWidgetComponent } from './widgets/spc/spc-widget/xproj-spc-widget.component';
import { XprojSpcWidgetConfigComponent } from './widgets/spc/spc-widget-config/xproj-spc-widget-config.component';
import { XprojSpcWidgetQueryConfigComponent } from './widgets/spc/spc-widget-query-config/xproj-spc-widget-query-config.component';
import { XprojLabelWidgetQueryConfigComponent } from './widgets/label/label-widget-query-config/xproj-label-widget-query-config.component';
import { XProjectorClient } from './XProjector/xprojector-client-service';
import { XprojPrettyDataType, XprojPrettyDSPipe, XprojPrettyPipe, XprojPrettyProjectionColumnDescription, XprojPrettyProjectionColumnGroupColumnDescription, XprojPrettyTruncateString, XprojPrettyProjectionExecutionEntry } from './pipes/xproj-pretty.pipe';
import { XprojTextWidgetComponent } from './widgets/text/text-widget/xproj-text-widget.component';
import { XprojTextWidgetConfigComponent } from './widgets/text/text-widget-config/xproj-text-widget-config.component';
import { XprojMapWidgetComponent } from './widgets/map/map-widget/xproj-map-widget.component';
import { XprojMapWidgetConfigComponent } from './widgets/map/map-widget-config/xproj-map-widget-config.component';
import { XprojMapWidgetQueryConfigComponent } from './widgets/map/map-widget-query-config/xproj-map-widget-query-config.component';
import { MonacoEditorModule } from 'ngx-monaco-editor';
import { XprojSvgWidgetComponent } from './widgets/svg/svg-widget/xproj-svg-widget.component';
import { XprojSvgWidgetConfigComponent } from './widgets/svg/svg-widget-config/xproj-svg-widget-config.component';
import { XprojScripted3ddWidgetComponent } from './widgets/scripted3d/scripted3d-widget/xproj-scripted3d-widget.component';
import { XprojScripted3dWidgetConfigComponent } from './widgets/scripted3d/scripted3d-widget-config/xproj-scripted3d-widget-config.component';
import { XprojOutputControllerWidgetComponent } from './widgets/output/output-controller-widget/xproj-output-controller-widget.component';
import { XprojOutputControllerWidgetConfigComponent } from './widgets/output/output-controller-widget-config/xproj-output-controller-widget-config.component';
import { XprojOutputRangeControllerComponent } from './widgets/output/output-controller-widget-config/controllers/range/xproj-output-range-controller/xproj-output-range-controller.component';
import { XprojOutputRangeControllerConfigComponent } from './widgets/output/output-controller-widget-config/controllers/range/xproj-output-range-controller-config/xproj-output-range-controller-config.component';
import { XprojOutputButtonControllerComponent } from './widgets/output/output-controller-widget-config/controllers/button/xproj-output-button-controller/xproj-output-button-controller.component';
import { XprojOutputButtonControllerConfigComponent } from './widgets/output/output-controller-widget-config/controllers/button/xproj-output-button-controller-config/xproj-output-button-controller-config.component';
import { XprojOutputComboboxControllerComponent } from './widgets/output/output-controller-widget-config/controllers/combobox/xproj-output-combobox-controller/xproj-output-combobox-controller.component';
import { XprojOutputComboboxControllerConfigComponent } from './widgets/output/output-controller-widget-config/controllers/combobox/xproj-output-combobox-controller-config/xproj-output-combobox-controller-config.component';
import { XprojWidgetComponent } from './widgets/xproj-widget/xproj-widget.component';
import { XprojOutputAggregationControllerComponent } from './widgets/output/output-controller-widget-config/controllers/aggregation/xproj-output-aggregation-controller/xproj-output-aggregation-controller.component';
import { XprojOutputAggregationControllerConfigComponent } from './widgets/output/output-controller-widget-config/controllers/aggregation/xproj-output-aggregation-controller-config/xproj-output-aggregation-controller-config.component';
import { XprojOutputTransformControllerComponent } from './widgets/output/output-controller-widget-config/controllers/transform/xproj-output-transform-controller/xproj-output-transform-controller.component';
import { XprojOutputTransformControllerConfigComponent } from './widgets/output/output-controller-widget-config/controllers/transform/xproj-output-transform-controller-config/xproj-output-transform-controller-config.component';
import { XprojOutputProjectionControllerComponent } from './widgets/output/output-controller-widget-config/controllers/projection/xproj-output-projection-controller/xproj-output-projection-controller.component';
import { XprojOutputProjectionControllerConfigComponent } from './widgets/output/output-controller-widget-config/controllers/projection/xproj-output-projection-controller-config/xproj-output-projection-controller-config.component';
import { XprojOutputTimeControllerComponent } from './widgets/output/output-controller-widget-config/controllers/time/xproj-output-time-controller/xproj-output-time-controller.component';
import { XprojOutputTimeControllerConfigComponent } from './widgets/output/output-controller-widget-config/controllers/time/xproj-output-time-controller-config/xproj-output-time-controller-config.component';
import { XprojOutputToggleControllerComponent } from './widgets/output/output-controller-widget-config/controllers/toggle/xproj-output-toggle-controller/xproj-output-toggle-controller.component';
import { XprojOutputToggleControllerConfigComponent } from './widgets/output/output-controller-widget-config/controllers/toggle/xproj-output-toggle-controller-config/xproj-output-toggle-controller-config.component';
import { XprojOutputRadiobuttonControllerComponent } from './widgets/output/output-controller-widget-config/controllers/radiobutton/xproj-output-radio-button-controller/xproj-output-radiobutton-controller.component';
import { XprojOutputRadiobuttonControllerConfigComponent } from './widgets/output/output-controller-widget-config/controllers/radiobutton/xproj-output-radio-button-controller-config/xproj-output-radiobutton-controller-config.component';
import { XprojProjectionDataEditorWidgetComponent } from './widgets/projection-dataeditor/projection-dataeditor-widget/xproj-projection-dataeditor-widget.component';
import { XprojProjectionDataEditorWidgetConfigComponent } from './widgets/projection-dataeditor/projection-dataeditor-widget-config/xproj-projection-dataeditor-widget-config.component';


import { XprojModalComponent } from './modals/xproj-modal/xproj-modal.component';
import { XprojModalService } from './modals/xproj-modal-service.service';
import { XprojFilterInputParametersPipe } from './pipes/xproj-filter-input-parameters.pipe';
import { XprojFilterFilterTypeofpipePipe } from './pipes/xproj-filter-typeof.pipe.ts.pipe';
import { XprojSvgWidgetQueryConfigComponent } from './widgets/svg/xproj-svg-widget-query-config/xproj-svg-widget-query-config.component'
import { XprojScripted3dWidgetQueryConfigComponent } from './widgets/scripted3d/scripted3d-widget-query-config/xproj-scripted3d-widget-query-config.component'
import { XProjectClientSettings, XPROJECTORCLIENTSETTINGS } from './XProjector/xprojector-client-settings-service';
import { XProjDynclrMiniComponent } from './helpers/dynclr-mini/xproj-dynclr-mini.component';

import { XProjListprojectionsComponent } from './data/listprojections/xproj-listprojections.component';
import { XProjListdatasourcesComponent } from './data/listdatasources/xproj-listdatasources.component';
import { XProjDatasourcequeryproberComponent } from './data/datasourcequeryprober/xproj-datasourcequeryprober.component';
import { XprojEditcolumndescriptionComponent } from './data/editcolumndescription/xproj-editcolumndescription.component';
import { XProjEditdatasourceComponent } from './data/editdatasource/xproj-editdatasource.component';
import { XProjEditprojectionComponent } from './data/editprojection/xproj-editprojection.component';
import { XProjScriptinghelpComponent } from './data/scriptinghelp/xproj-scriptinghelp.component';
import { XProjWstestComponent } from './data/wstest/xproj-wstest.component';
import { XprojEditLuaQueryColumnComponent } from './filters/edit-lua-query-column/xproj-edit-lua-query-column.component';
import { XprojProjectionDataEditPipe } from './widgets/projection-dataeditor/projection-dataeditor-widget/xproj-projection-data-edit.pipe';
import { XprojTableDataPipe } from './widgets/table/table-widget/xproj-table-data.pipe';
import { XprojContainerWidgetComponent } from './widgets/container/container-widget/xproj-container-widget.component';
import { XprojContainerWidgetConfigComponent } from './widgets/container/container-widget-config/xproj-container-widget-config.component';
import { XprojContainerWidgetSwitchComponent } from './widgets/container/xproj-container-widget-switch/xproj-container-widget-switch.component';
import { XprojPiechartWidgetQueryConfigComponent } from './widgets/piechart/piechart-widget-query-config/xproj-piechart-widget-query-config.component';
import { DateHelper } from './helpers/date-helper-service';
import { XprojOutputComboboxControllerQueryConfigComponent } from './widgets/output/output-controller-widget-config/controllers/combobox/xproj-output-combobox-controller-query-config/xproj-output-combobox-controller-query-config.component';
import { XprojWidgetPrequeryConfigComponent } from './widgets/widget-prequery-config/xproj-widget-prequery-config.component';
import { EditplcprogramComponent } from './xautomation/editplcprogram/editplcprogram.component';
import { ListplcprogramsComponent } from './xautomation/listplcprograms/listplcprograms.component';
import { EditplcprogramBasicComponent } from './xautomation/editplcprogram-basic/editplcprogram-basic.component';
import { EditplcprogramIoComponent } from './xautomation/editplcprogram-io/editplcprogram-io.component';
import { EditVariablesComponent } from './xautomation/edit-variables/edit-variables.component';
import { EditplcprogramProgramcodeComponent } from './xautomation/editplcprogram-programcode/editplcprogram-programcode.component';
import { EditplcprogramSchedulingComponent } from './xautomation/editplcprogram-scheduling/editplcprogram-scheduling.component';
import { EditplcprogramErrorlogsComponent } from './xautomation/editplcprogram-errorlogs/editplcprogram-errorlogs.component';
import { EditplcprogramStatisticsComponent } from './xautomation/editplcprogram-statistics/editplcprogram-statistics.component';
import { EditplcprogramSimulationComponent } from './xautomation/editplcprogram-simulation/editplcprogram-simulation.component';
import { EditconnectorsModbusComponent } from './xautomation/editconnectors-modbus/editconnectors-modbus.component';
import { EditconnectorsMqttComponent } from './xautomation/editconnectors-mqtt/editconnectors-mqtt.component';
import { EditconnectorsExternalComponent } from './xautomation/editconnectors-external/editconnectors-external.component';
import { EditconnectorsRedisComponent } from './xautomation/editconnectors-redis/editconnectors-redis.component';
import { EditplcprogramService } from './xautomation/editplcprogram.service';
import { EditplcvariableComponent } from './xautomation/editplcvariable/editplcvariable.component';
import { EditprocessgraphsComponent } from './xautomation/editprocessgraphs/editprocessgraphs.component';
import { XprojSafePipe } from './pipes/xproj-safe.pipe';
import { ColorHelper } from './helpers/color-helper-service';
import { CacheService } from './cache';
import { EditconnectorsPhysicalComponent } from './xautomation/editconnectors-physical/editconnectors-physical.component';
import { EditxautolinksComponent } from './xautomation/editxautolinks/editxautolinks.component';
import { EditplcprogramBlockcodeComponent } from './xautomation/editplcprogram-blockcode/editplcprogram-blockcode.component';
import { EditconnectorsBacnetComponent } from './xautomation/editconnectors-bacnet/editconnectors-bacnet.component';
import { PlcprogramlogsComponent } from './xautomation/plcprogramlogs/plcprogramlogs.component';
import { XprojRenderDashboardComponent } from './xproj-render-dashboard/xproj-render-dashboard.component';
import { EditconnectorsCollectdComponent } from './xautomation/editconnectors-collectd/editconnectors-collectd.component';
import { EditconnectorsOpcuaComponent } from './xautomation/editconnectors-opcua/editconnectors-opcua.component';
import { XProjEditScriptFilesComponent } from './data/editscriptfiles/editscriptfiles.component';
import { XProjLuadebuggerComponent } from './data/luadebugger/luadebugger.component';
import { EditconnectorsMidiComponent } from './xautomation/editconnectors-midi/editconnectors-midi.component';
import { XautomationSettingsComponent } from './xautomation/xautomation-settings/xautomation-settings.component';
import { XdbSettingsComponent } from './data/xdb-settings/xdb-settings.component';
import { XdbBackupComponent } from './settings/xdb-backup/xdb-backup.component';
import { XdbInternalAclComponent } from './settings/xdb-internal-acl/xdb-internal-acl.component';
import { XAutoService } from './xautomation/xauto.service';
import { EditconnectorsMbusComponent } from './xautomation/editconnectors-mbus/editconnectors-mbus.component';
import { XprojBimService } from './services/xproj-bim.service';
import { EditintegrationserversMqttComponent } from './xautomation/editintegrationservers-mqtt/editintegrationservers-mqtt.component';
import { EditintegrationserversOpcuaComponent } from './xautomation/editintegrationservers-opcua/editintegrationservers-opcua.component';
import { EditintegrationserversModbusComponent } from './xautomation/editintegrationservers-modbus/editintegrationservers-modbus.component';
import { EditintegrationserversBacnetComponent } from './xautomation/editintegrationservers-bacnet/editintegrationservers-bacnet.component';
import { EditintegrationserversExternalComponent } from './xautomation/editintegrationservers-external/editintegrationservers-external.component';

@NgModule({
  declarations: [
    XprojFilterpipePipe,
    XprojFilternumericalcolumnsPipe,
    XprojFiltertimestampcolumnsPipe,
    XprojDataeditorFiltertimestampcolumnsPipe,
    XprojDatagridstringfilterComponent,
    XprojDatagridNumericFilterComponent,
    XprojProjectionFilterComponent,
    XprojDataFilterComponent,
    XprojChartWidgetComponent,
    XprojDashboardComponent,
    XprojChartWidgetConfigComponent,
    XprojChartWidgetQueryConfigComponent,
    XprojTypeofPipe,
    XprojTableWidgetComponent,
    XprojTableWidgetConfigComponent,
    XprojWidgetConfigComponent,
    XprojWidgetPrequeryConfigComponent,
    XprojDashboardConfigComponent,
    XprojTableWidgetQueryConfigComponent,
    XprojMasterWidgetComponent,
    XprojMasterWidgetConfigComponent,
    XprojMasterWidgetTimeComponent,
    XprojGroupSelectionComponent,
    XprojPiechartWidgetComponent,
    XprojPiechartWidgetConfigComponent,
    XprojLabelWidgetComponent,
    XprojLabelWidgetConfigComponent,
    XprojSpectrumAnalyzerWidgetComponent,
    XprojSpectrumAnalyzerWidgetConfigComponent,
    XprojSpectrumAnalyzerWidgetQueryConfigComponent,
    XprojSpcWidgetComponent,
    XprojSpcWidgetConfigComponent,
    XprojSpcWidgetQueryConfigComponent,
    XprojLabelWidgetQueryConfigComponent,
    XprojPrettyPipe,
    XprojPrettyDSPipe,
    XprojPrettyDataType,
    XprojPrettyProjectionExecutionEntry,
    XprojPrettyProjectionColumnDescription,
    XprojPrettyProjectionColumnGroupColumnDescription,
    XprojPrettyTruncateString,
    XprojTextWidgetComponent,
    XprojTextWidgetConfigComponent,
    XprojMapWidgetComponent,
    XprojMapWidgetConfigComponent,
    XprojMapWidgetQueryConfigComponent,
    XprojSvgWidgetComponent,
    XprojSvgWidgetConfigComponent,
    XprojSvgWidgetComponent,
    XprojSvgWidgetConfigComponent,
    XprojScripted3ddWidgetComponent,
    XprojScripted3dWidgetConfigComponent,
    XprojScripted3ddWidgetComponent,
    XprojScripted3dWidgetConfigComponent,
    XprojOutputControllerWidgetComponent,
    XprojOutputControllerWidgetConfigComponent,
    XprojOutputRangeControllerComponent,
    XprojOutputRangeControllerConfigComponent,
    XprojOutputButtonControllerComponent,
    XprojOutputButtonControllerConfigComponent,
    XprojOutputComboboxControllerComponent,
    XprojOutputComboboxControllerConfigComponent,
    XprojWidgetComponent,
    XprojOutputAggregationControllerComponent,
    XprojOutputAggregationControllerConfigComponent,
    XprojOutputTransformControllerComponent,
    XprojOutputTransformControllerConfigComponent,
    XprojOutputProjectionControllerComponent,
    XprojOutputProjectionControllerConfigComponent,
    XprojOutputTimeControllerComponent,
    XprojOutputTimeControllerConfigComponent,
    XprojOutputToggleControllerComponent,
    XprojOutputToggleControllerConfigComponent,
    XprojOutputRadiobuttonControllerComponent,
    XprojOutputRadiobuttonControllerConfigComponent,
    XprojModalComponent,
    XprojFilterInputParametersPipe,
    XprojFilterFilterTypeofpipePipe,
    XprojSvgWidgetQueryConfigComponent,
    XprojScripted3dWidgetQueryConfigComponent,
    XprojProjectionDataEditorWidgetComponent,
    XprojProjectionDataEditorWidgetConfigComponent,

    XProjDynclrMiniComponent,
    XProjListprojectionsComponent,
    XProjListdatasourcesComponent,
    XProjDatasourcequeryproberComponent,
    XprojEditcolumndescriptionComponent,
    XProjEditdatasourceComponent,
    XProjEditprojectionComponent,
    XProjScriptinghelpComponent,
    XProjWstestComponent,
    XprojEditLuaQueryColumnComponent,
    XprojProjectionDataEditPipe,
    XprojTableDataPipe,
    XprojContainerWidgetComponent,
    XprojContainerWidgetConfigComponent,
    XprojContainerWidgetSwitchComponent,
    XprojPiechartWidgetQueryConfigComponent,
    XprojOutputComboboxControllerQueryConfigComponent,
    EditplcprogramComponent,
    ListplcprogramsComponent,
    EditplcprogramBasicComponent,
    EditplcprogramIoComponent,
    EditplcprogramProgramcodeComponent,
    EditplcprogramSchedulingComponent,
    EditplcprogramErrorlogsComponent,
    EditplcprogramStatisticsComponent,
    EditplcprogramSimulationComponent,
    EditconnectorsModbusComponent,
    EditconnectorsMqttComponent,
    EditconnectorsExternalComponent,
    EditconnectorsRedisComponent,
    EditplcvariableComponent,
    EditprocessgraphsComponent,
    XprojSafePipe,
    EditconnectorsPhysicalComponent,
    EditxautolinksComponent,
    EditplcprogramBlockcodeComponent,
    EditconnectorsBacnetComponent,
    PlcprogramlogsComponent,
    XprojRenderDashboardComponent,
    EditconnectorsCollectdComponent,
    EditconnectorsOpcuaComponent,
    XProjEditScriptFilesComponent,
    XProjLuadebuggerComponent,
    EditconnectorsMidiComponent,
    XautomationSettingsComponent,
    XdbBackupComponent,
    XdbInternalAclComponent,
    XdbSettingsComponent,
    EditconnectorsMbusComponent,
    EditVariablesComponent,
    EditintegrationserversMqttComponent,
    EditintegrationserversOpcuaComponent,
    EditintegrationserversModbusComponent,
    EditintegrationserversBacnetComponent,
    EditintegrationserversExternalComponent
  ],
  imports: [
    CommonModule,
    ClarityModule,
    FormsModule,
    HttpClientModule,
    NgxFileDropModule,
    BrowserTransferStateModule,
    GridsterModule,
    AngularSplitModule.forRoot(),
    LMarkdownEditorModule,
    MonacoEditorModule.forRoot(),
    NgxSvgModule,
    NgxGaugeModule,
    RouterModule
  ],
  exports: [
    XprojRenderDashboardComponent,
    XprojDashboardComponent,
    XprojDashboardConfigComponent,
    XprojChartWidgetComponent,
    XprojChartWidgetConfigComponent,
    XprojChartWidgetQueryConfigComponent,
    XprojLabelWidgetComponent,
    XprojLabelWidgetConfigComponent,
    XprojLabelWidgetQueryConfigComponent,
    XprojMasterWidgetComponent,
    XprojMasterWidgetConfigComponent,
    XprojMasterWidgetTimeComponent,
    XprojPiechartWidgetComponent,
    XprojPiechartWidgetConfigComponent,
    XprojTableWidgetComponent,
    XprojTableWidgetConfigComponent,
    XprojTableWidgetQueryConfigComponent,
    XprojSpectrumAnalyzerWidgetComponent,
    XprojSpectrumAnalyzerWidgetConfigComponent,
    XprojSpectrumAnalyzerWidgetQueryConfigComponent,
    XprojSpcWidgetComponent,
    XprojSpcWidgetConfigComponent,
    XprojSpcWidgetQueryConfigComponent,
    XprojWidgetConfigComponent,
    XprojProjectionFilterComponent,
    XprojDatagridstringfilterComponent,
    XprojDatagridNumericFilterComponent,
    XprojDataFilterComponent,
    XprojGroupSelectionComponent,
    XprojPrettyPipe,
    XprojPrettyDataType,
    XprojPrettyDSPipe,
    XprojPrettyProjectionExecutionEntry,
    XprojPrettyProjectionColumnDescription,
    XprojPrettyTruncateString,
    XprojPrettyProjectionColumnGroupColumnDescription,
    XprojFilterpipePipe,
    XprojFilternumericalcolumnsPipe,
    XprojFiltertimestampcolumnsPipe,
    XprojModalComponent,
    XprojProjectionDataEditorWidgetComponent,
    XprojProjectionDataEditorWidgetConfigComponent,
    XprojContainerWidgetComponent,
    XprojContainerWidgetConfigComponent,

    XProjDynclrMiniComponent,
    XProjListprojectionsComponent,
    XProjListdatasourcesComponent,
    XProjDatasourcequeryproberComponent,
    XprojEditcolumndescriptionComponent,
    XProjEditdatasourceComponent,
    XProjEditprojectionComponent,
    XProjEditScriptFilesComponent,
    XProjScriptinghelpComponent,
    XProjWstestComponent,

    ClarityModule
],
  providers: [
    XprojWidgetService,
    XprojDashboardInteractService,
    DateHelper,
    ColorHelper,
    EditplcprogramService,
    XAutoService
  ]
})
export class XprojModule { }

@NgModule({
  imports: [
    CacheModule.forRoot({
      key: 'XPROJ_CACHE',
      lifeSpan: {
        "expiry": null,
        "TTL": cacheTTLSeconds
      }
    })
  ],
  providers: [ CacheService ]
})
export class XprojModuleCache { }
