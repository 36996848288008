import { Pipe, PipeTransform } from '@angular/core';
import { EventHistory } from '@core/xprojector_backend/proto/xprojector.modulebms.events.pb';

@Pipe({
  name: 'bmseventactivepipe'
})
export class BmsEventActivePipe implements PipeTransform {

  constructor() {}

  transform(active: boolean, ...args: unknown[]): string {

    return  active ? 'Active' : 'Inactive';
  }

}
