export class BmsMeter {
  id : number;
  apartmentId : number;
  facilityId : number;
  buildingAddressId : number;
  buildingId : number;
  realestateId : number;
  gatewayId : number;

  systemId1 : number;
  systemId2 : number;
  systemId3 : number;

  identifier : string;
  manufacturer : string;
  unit : string;
  datapointValueUnit : string;
  variable : string;
  index : number;
  state : BmsMeterState;
  model : string;
  typeId : string;
  meterType : string;
  tariffGroup : string;
  meterSubtype : string;
  coeffiecent : number;
  inputMeterType : BmsMeterInputType;

  createdAt : Date;
  modifiedAt : Date;
}


export enum BmsMeterState {
  Inactive = 0,
  InactiveReplaced = 1,
  Test = 20,
  ActiveBilling = 40,
  ActiveExport = 41,
  ActiveNoBilling = 60
}

export enum BmsMeterInputType {
  Meter = 0,
  DiffMeter = 1
}
