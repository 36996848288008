/* tslint:disable */
/* eslint-disable */
// @ts-nocheck
//
// THIS IS A GENERATED FILE
// DO NOT MODIFY IT! YOUR CHANGES WILL BE LOST
import {
  GrpcMessage,
  RecursivePartial,
  ToProtobufJSONOptions,
  uint8ArrayToBase64
} from '@ngx-grpc/common';
import { BinaryReader, BinaryWriter, ByteSource } from 'google-protobuf';
import * as googleProtobuf000 from './google/protobuf/timestamp.pb';
/**
 * Message implementation for modulebms.customeradmin.GetCustomerInfoRequest
 */
export class GetCustomerInfoRequest implements GrpcMessage {
  static id = 'modulebms.customeradmin.GetCustomerInfoRequest';

  /**
   * Deserialize binary data to message
   * @param instance message instance
   */
  static deserializeBinary(bytes: ByteSource) {
    const instance = new GetCustomerInfoRequest();
    GetCustomerInfoRequest.deserializeBinaryFromReader(
      instance,
      new BinaryReader(bytes)
    );
    return instance;
  }

  /**
   * Check all the properties and set default protobuf values if necessary
   * @param _instance message instance
   */
  static refineValues(_instance: GetCustomerInfoRequest) {
    _instance.customerId = _instance.customerId || '';
  }

  /**
   * Deserializes / reads binary message into message instance using provided binary reader
   * @param _instance message instance
   * @param _reader binary reader instance
   */
  static deserializeBinaryFromReader(
    _instance: GetCustomerInfoRequest,
    _reader: BinaryReader
  ) {
    while (_reader.nextField()) {
      if (_reader.isEndGroup()) break;

      switch (_reader.getFieldNumber()) {
        case 1:
          _instance.customerId = _reader.readString();
          break;
        default:
          _reader.skipField();
      }
    }

    GetCustomerInfoRequest.refineValues(_instance);
  }

  /**
   * Serializes a message to binary format using provided binary reader
   * @param _instance message instance
   * @param _writer binary writer instance
   */
  static serializeBinaryToWriter(
    _instance: GetCustomerInfoRequest,
    _writer: BinaryWriter
  ) {
    if (_instance.customerId) {
      _writer.writeString(1, _instance.customerId);
    }
  }

  private _customerId?: string;

  /**
   * Message constructor. Initializes the properties and applies default Protobuf values if necessary
   * @param _value initial values object or instance of GetCustomerInfoRequest to deeply clone from
   */
  constructor(_value?: RecursivePartial<GetCustomerInfoRequest.AsObject>) {
    _value = _value || {};
    this.customerId = _value.customerId;
    GetCustomerInfoRequest.refineValues(this);
  }
  get customerId(): string | undefined {
    return this._customerId;
  }
  set customerId(value: string | undefined) {
    this._customerId = value;
  }

  /**
   * Serialize message to binary data
   * @param instance message instance
   */
  serializeBinary() {
    const writer = new BinaryWriter();
    GetCustomerInfoRequest.serializeBinaryToWriter(this, writer);
    return writer.getResultBuffer();
  }

  /**
   * Cast message to standard JavaScript object (all non-primitive values are deeply cloned)
   */
  toObject(): GetCustomerInfoRequest.AsObject {
    return {
      customerId: this.customerId
    };
  }

  /**
   * Convenience method to support JSON.stringify(message), replicates the structure of toObject()
   */
  toJSON() {
    return this.toObject();
  }

  /**
   * Cast message to JSON using protobuf JSON notation: https://developers.google.com/protocol-buffers/docs/proto3#json
   * Attention: output differs from toObject() e.g. enums are represented as names and not as numbers, Timestamp is an ISO Date string format etc.
   * If the message itself or some of descendant messages is google.protobuf.Any, you MUST provide a message pool as options. If not, the messagePool is not required
   */
  toProtobufJSON(
    // @ts-ignore
    options?: ToProtobufJSONOptions
  ): GetCustomerInfoRequest.AsProtobufJSON {
    return {
      customerId: this.customerId
    };
  }
}
export module GetCustomerInfoRequest {
  /**
   * Standard JavaScript object representation for GetCustomerInfoRequest
   */
  export interface AsObject {
    customerId?: string;
  }

  /**
   * Protobuf JSON representation for GetCustomerInfoRequest
   */
  export interface AsProtobufJSON {
    customerId?: string;
  }
}

/**
 * Message implementation for modulebms.customeradmin.GetCustomerInfoResponse
 */
export class GetCustomerInfoResponse implements GrpcMessage {
  static id = 'modulebms.customeradmin.GetCustomerInfoResponse';

  /**
   * Deserialize binary data to message
   * @param instance message instance
   */
  static deserializeBinary(bytes: ByteSource) {
    const instance = new GetCustomerInfoResponse();
    GetCustomerInfoResponse.deserializeBinaryFromReader(
      instance,
      new BinaryReader(bytes)
    );
    return instance;
  }

  /**
   * Check all the properties and set default protobuf values if necessary
   * @param _instance message instance
   */
  static refineValues(_instance: GetCustomerInfoResponse) {
    _instance.customerId = _instance.customerId || '';
    _instance.customerName = _instance.customerName || '';
    _instance.billingPeriod = _instance.billingPeriod || 0;
    _instance.billingPeriodStartMonth = _instance.billingPeriodStartMonth || 0;
    _instance.tariffDecimalCount = _instance.tariffDecimalCount || 0;
  }

  /**
   * Deserializes / reads binary message into message instance using provided binary reader
   * @param _instance message instance
   * @param _reader binary reader instance
   */
  static deserializeBinaryFromReader(
    _instance: GetCustomerInfoResponse,
    _reader: BinaryReader
  ) {
    while (_reader.nextField()) {
      if (_reader.isEndGroup()) break;

      switch (_reader.getFieldNumber()) {
        case 1:
          _instance.customerId = _reader.readString();
          break;
        case 2:
          _instance.customerName = _reader.readString();
          break;
        case 3:
          _instance.billingPeriod = _reader.readInt32();
          break;
        case 4:
          _instance.billingPeriodStartMonth = _reader.readInt32();
          break;
        case 5:
          _instance.tariffDecimalCount = _reader.readInt32();
          break;
        default:
          _reader.skipField();
      }
    }

    GetCustomerInfoResponse.refineValues(_instance);
  }

  /**
   * Serializes a message to binary format using provided binary reader
   * @param _instance message instance
   * @param _writer binary writer instance
   */
  static serializeBinaryToWriter(
    _instance: GetCustomerInfoResponse,
    _writer: BinaryWriter
  ) {
    if (_instance.customerId) {
      _writer.writeString(1, _instance.customerId);
    }
    if (_instance.customerName) {
      _writer.writeString(2, _instance.customerName);
    }
    if (_instance.billingPeriod) {
      _writer.writeInt32(3, _instance.billingPeriod);
    }
    if (_instance.billingPeriodStartMonth) {
      _writer.writeInt32(4, _instance.billingPeriodStartMonth);
    }
    if (_instance.tariffDecimalCount) {
      _writer.writeInt32(5, _instance.tariffDecimalCount);
    }
  }

  private _customerId?: string;
  private _customerName?: string;
  private _billingPeriod?: number;
  private _billingPeriodStartMonth?: number;
  private _tariffDecimalCount?: number;

  /**
   * Message constructor. Initializes the properties and applies default Protobuf values if necessary
   * @param _value initial values object or instance of GetCustomerInfoResponse to deeply clone from
   */
  constructor(_value?: RecursivePartial<GetCustomerInfoResponse.AsObject>) {
    _value = _value || {};
    this.customerId = _value.customerId;
    this.customerName = _value.customerName;
    this.billingPeriod = _value.billingPeriod;
    this.billingPeriodStartMonth = _value.billingPeriodStartMonth;
    this.tariffDecimalCount = _value.tariffDecimalCount;
    GetCustomerInfoResponse.refineValues(this);
  }
  get customerId(): string | undefined {
    return this._customerId;
  }
  set customerId(value: string | undefined) {
    this._customerId = value;
  }
  get customerName(): string | undefined {
    return this._customerName;
  }
  set customerName(value: string | undefined) {
    this._customerName = value;
  }
  get billingPeriod(): number | undefined {
    return this._billingPeriod;
  }
  set billingPeriod(value: number | undefined) {
    this._billingPeriod = value;
  }
  get billingPeriodStartMonth(): number | undefined {
    return this._billingPeriodStartMonth;
  }
  set billingPeriodStartMonth(value: number | undefined) {
    this._billingPeriodStartMonth = value;
  }
  get tariffDecimalCount(): number | undefined {
    return this._tariffDecimalCount;
  }
  set tariffDecimalCount(value: number | undefined) {
    this._tariffDecimalCount = value;
  }

  /**
   * Serialize message to binary data
   * @param instance message instance
   */
  serializeBinary() {
    const writer = new BinaryWriter();
    GetCustomerInfoResponse.serializeBinaryToWriter(this, writer);
    return writer.getResultBuffer();
  }

  /**
   * Cast message to standard JavaScript object (all non-primitive values are deeply cloned)
   */
  toObject(): GetCustomerInfoResponse.AsObject {
    return {
      customerId: this.customerId,
      customerName: this.customerName,
      billingPeriod: this.billingPeriod,
      billingPeriodStartMonth: this.billingPeriodStartMonth,
      tariffDecimalCount: this.tariffDecimalCount
    };
  }

  /**
   * Convenience method to support JSON.stringify(message), replicates the structure of toObject()
   */
  toJSON() {
    return this.toObject();
  }

  /**
   * Cast message to JSON using protobuf JSON notation: https://developers.google.com/protocol-buffers/docs/proto3#json
   * Attention: output differs from toObject() e.g. enums are represented as names and not as numbers, Timestamp is an ISO Date string format etc.
   * If the message itself or some of descendant messages is google.protobuf.Any, you MUST provide a message pool as options. If not, the messagePool is not required
   */
  toProtobufJSON(
    // @ts-ignore
    options?: ToProtobufJSONOptions
  ): GetCustomerInfoResponse.AsProtobufJSON {
    return {
      customerId: this.customerId,
      customerName: this.customerName,
      billingPeriod: this.billingPeriod,
      billingPeriodStartMonth: this.billingPeriodStartMonth,
      tariffDecimalCount: this.tariffDecimalCount
    };
  }
}
export module GetCustomerInfoResponse {
  /**
   * Standard JavaScript object representation for GetCustomerInfoResponse
   */
  export interface AsObject {
    customerId?: string;
    customerName?: string;
    billingPeriod?: number;
    billingPeriodStartMonth?: number;
    tariffDecimalCount?: number;
  }

  /**
   * Protobuf JSON representation for GetCustomerInfoResponse
   */
  export interface AsProtobufJSON {
    customerId?: string;
    customerName?: string;
    billingPeriod?: number;
    billingPeriodStartMonth?: number;
    tariffDecimalCount?: number;
  }
}

/**
 * Message implementation for modulebms.customeradmin.GetApartmentsRequest
 */
export class GetApartmentsRequest implements GrpcMessage {
  static id = 'modulebms.customeradmin.GetApartmentsRequest';

  /**
   * Deserialize binary data to message
   * @param instance message instance
   */
  static deserializeBinary(bytes: ByteSource) {
    const instance = new GetApartmentsRequest();
    GetApartmentsRequest.deserializeBinaryFromReader(
      instance,
      new BinaryReader(bytes)
    );
    return instance;
  }

  /**
   * Check all the properties and set default protobuf values if necessary
   * @param _instance message instance
   */
  static refineValues(_instance: GetApartmentsRequest) {
    _instance.customerId = _instance.customerId || '';
    _instance.fromItem = _instance.fromItem || 0;
    _instance.maxItems = _instance.maxItems || 0;
  }

  /**
   * Deserializes / reads binary message into message instance using provided binary reader
   * @param _instance message instance
   * @param _reader binary reader instance
   */
  static deserializeBinaryFromReader(
    _instance: GetApartmentsRequest,
    _reader: BinaryReader
  ) {
    while (_reader.nextField()) {
      if (_reader.isEndGroup()) break;

      switch (_reader.getFieldNumber()) {
        case 1:
          _instance.customerId = _reader.readString();
          break;
        case 2:
          _instance.fromItem = _reader.readUint32();
          break;
        case 3:
          _instance.maxItems = _reader.readUint32();
          break;
        default:
          _reader.skipField();
      }
    }

    GetApartmentsRequest.refineValues(_instance);
  }

  /**
   * Serializes a message to binary format using provided binary reader
   * @param _instance message instance
   * @param _writer binary writer instance
   */
  static serializeBinaryToWriter(
    _instance: GetApartmentsRequest,
    _writer: BinaryWriter
  ) {
    if (_instance.customerId) {
      _writer.writeString(1, _instance.customerId);
    }
    if (_instance.fromItem) {
      _writer.writeUint32(2, _instance.fromItem);
    }
    if (_instance.maxItems) {
      _writer.writeUint32(3, _instance.maxItems);
    }
  }

  private _customerId?: string;
  private _fromItem?: number;
  private _maxItems?: number;

  /**
   * Message constructor. Initializes the properties and applies default Protobuf values if necessary
   * @param _value initial values object or instance of GetApartmentsRequest to deeply clone from
   */
  constructor(_value?: RecursivePartial<GetApartmentsRequest.AsObject>) {
    _value = _value || {};
    this.customerId = _value.customerId;
    this.fromItem = _value.fromItem;
    this.maxItems = _value.maxItems;
    GetApartmentsRequest.refineValues(this);
  }
  get customerId(): string | undefined {
    return this._customerId;
  }
  set customerId(value: string | undefined) {
    this._customerId = value;
  }
  get fromItem(): number | undefined {
    return this._fromItem;
  }
  set fromItem(value: number | undefined) {
    this._fromItem = value;
  }
  get maxItems(): number | undefined {
    return this._maxItems;
  }
  set maxItems(value: number | undefined) {
    this._maxItems = value;
  }

  /**
   * Serialize message to binary data
   * @param instance message instance
   */
  serializeBinary() {
    const writer = new BinaryWriter();
    GetApartmentsRequest.serializeBinaryToWriter(this, writer);
    return writer.getResultBuffer();
  }

  /**
   * Cast message to standard JavaScript object (all non-primitive values are deeply cloned)
   */
  toObject(): GetApartmentsRequest.AsObject {
    return {
      customerId: this.customerId,
      fromItem: this.fromItem,
      maxItems: this.maxItems
    };
  }

  /**
   * Convenience method to support JSON.stringify(message), replicates the structure of toObject()
   */
  toJSON() {
    return this.toObject();
  }

  /**
   * Cast message to JSON using protobuf JSON notation: https://developers.google.com/protocol-buffers/docs/proto3#json
   * Attention: output differs from toObject() e.g. enums are represented as names and not as numbers, Timestamp is an ISO Date string format etc.
   * If the message itself or some of descendant messages is google.protobuf.Any, you MUST provide a message pool as options. If not, the messagePool is not required
   */
  toProtobufJSON(
    // @ts-ignore
    options?: ToProtobufJSONOptions
  ): GetApartmentsRequest.AsProtobufJSON {
    return {
      customerId: this.customerId,
      fromItem: this.fromItem,
      maxItems: this.maxItems
    };
  }
}
export module GetApartmentsRequest {
  /**
   * Standard JavaScript object representation for GetApartmentsRequest
   */
  export interface AsObject {
    customerId?: string;
    fromItem?: number;
    maxItems?: number;
  }

  /**
   * Protobuf JSON representation for GetApartmentsRequest
   */
  export interface AsProtobufJSON {
    customerId?: string;
    fromItem?: number;
    maxItems?: number;
  }
}

/**
 * Message implementation for modulebms.customeradmin.GetApartmentsResponse
 */
export class GetApartmentsResponse implements GrpcMessage {
  static id = 'modulebms.customeradmin.GetApartmentsResponse';

  /**
   * Deserialize binary data to message
   * @param instance message instance
   */
  static deserializeBinary(bytes: ByteSource) {
    const instance = new GetApartmentsResponse();
    GetApartmentsResponse.deserializeBinaryFromReader(
      instance,
      new BinaryReader(bytes)
    );
    return instance;
  }

  /**
   * Check all the properties and set default protobuf values if necessary
   * @param _instance message instance
   */
  static refineValues(_instance: GetApartmentsResponse) {
    _instance.apartments = _instance.apartments || [];
  }

  /**
   * Deserializes / reads binary message into message instance using provided binary reader
   * @param _instance message instance
   * @param _reader binary reader instance
   */
  static deserializeBinaryFromReader(
    _instance: GetApartmentsResponse,
    _reader: BinaryReader
  ) {
    while (_reader.nextField()) {
      if (_reader.isEndGroup()) break;

      switch (_reader.getFieldNumber()) {
        case 1:
          const messageInitializer1 = new Apartment();
          _reader.readMessage(
            messageInitializer1,
            Apartment.deserializeBinaryFromReader
          );
          (_instance.apartments = _instance.apartments || []).push(
            messageInitializer1
          );
          break;
        default:
          _reader.skipField();
      }
    }

    GetApartmentsResponse.refineValues(_instance);
  }

  /**
   * Serializes a message to binary format using provided binary reader
   * @param _instance message instance
   * @param _writer binary writer instance
   */
  static serializeBinaryToWriter(
    _instance: GetApartmentsResponse,
    _writer: BinaryWriter
  ) {
    if (_instance.apartments && _instance.apartments.length) {
      _writer.writeRepeatedMessage(
        1,
        _instance.apartments as any,
        Apartment.serializeBinaryToWriter
      );
    }
  }

  private _apartments?: Apartment[];

  /**
   * Message constructor. Initializes the properties and applies default Protobuf values if necessary
   * @param _value initial values object or instance of GetApartmentsResponse to deeply clone from
   */
  constructor(_value?: RecursivePartial<GetApartmentsResponse.AsObject>) {
    _value = _value || {};
    this.apartments = (_value.apartments || []).map(m => new Apartment(m));
    GetApartmentsResponse.refineValues(this);
  }
  get apartments(): Apartment[] | undefined {
    return this._apartments;
  }
  set apartments(value: Apartment[] | undefined) {
    this._apartments = value;
  }

  /**
   * Serialize message to binary data
   * @param instance message instance
   */
  serializeBinary() {
    const writer = new BinaryWriter();
    GetApartmentsResponse.serializeBinaryToWriter(this, writer);
    return writer.getResultBuffer();
  }

  /**
   * Cast message to standard JavaScript object (all non-primitive values are deeply cloned)
   */
  toObject(): GetApartmentsResponse.AsObject {
    return {
      apartments: (this.apartments || []).map(m => m.toObject())
    };
  }

  /**
   * Convenience method to support JSON.stringify(message), replicates the structure of toObject()
   */
  toJSON() {
    return this.toObject();
  }

  /**
   * Cast message to JSON using protobuf JSON notation: https://developers.google.com/protocol-buffers/docs/proto3#json
   * Attention: output differs from toObject() e.g. enums are represented as names and not as numbers, Timestamp is an ISO Date string format etc.
   * If the message itself or some of descendant messages is google.protobuf.Any, you MUST provide a message pool as options. If not, the messagePool is not required
   */
  toProtobufJSON(
    // @ts-ignore
    options?: ToProtobufJSONOptions
  ): GetApartmentsResponse.AsProtobufJSON {
    return {
      apartments: (this.apartments || []).map(m => m.toProtobufJSON(options))
    };
  }
}
export module GetApartmentsResponse {
  /**
   * Standard JavaScript object representation for GetApartmentsResponse
   */
  export interface AsObject {
    apartments?: Apartment.AsObject[];
  }

  /**
   * Protobuf JSON representation for GetApartmentsResponse
   */
  export interface AsProtobufJSON {
    apartments?: Apartment.AsProtobufJSON[] | null;
  }
}

/**
 * Message implementation for modulebms.customeradmin.GetCustomerUsersRequest
 */
export class GetCustomerUsersRequest implements GrpcMessage {
  static id = 'modulebms.customeradmin.GetCustomerUsersRequest';

  /**
   * Deserialize binary data to message
   * @param instance message instance
   */
  static deserializeBinary(bytes: ByteSource) {
    const instance = new GetCustomerUsersRequest();
    GetCustomerUsersRequest.deserializeBinaryFromReader(
      instance,
      new BinaryReader(bytes)
    );
    return instance;
  }

  /**
   * Check all the properties and set default protobuf values if necessary
   * @param _instance message instance
   */
  static refineValues(_instance: GetCustomerUsersRequest) {
    _instance.customerId = _instance.customerId || '';
    _instance.fromItem = _instance.fromItem || 0;
    _instance.maxItems = _instance.maxItems || 0;
    _instance.adminsOnly = _instance.adminsOnly || false;
  }

  /**
   * Deserializes / reads binary message into message instance using provided binary reader
   * @param _instance message instance
   * @param _reader binary reader instance
   */
  static deserializeBinaryFromReader(
    _instance: GetCustomerUsersRequest,
    _reader: BinaryReader
  ) {
    while (_reader.nextField()) {
      if (_reader.isEndGroup()) break;

      switch (_reader.getFieldNumber()) {
        case 1:
          _instance.customerId = _reader.readString();
          break;
        case 2:
          _instance.fromItem = _reader.readUint32();
          break;
        case 3:
          _instance.maxItems = _reader.readUint32();
          break;
        case 4:
          _instance.adminsOnly = _reader.readBool();
          break;
        default:
          _reader.skipField();
      }
    }

    GetCustomerUsersRequest.refineValues(_instance);
  }

  /**
   * Serializes a message to binary format using provided binary reader
   * @param _instance message instance
   * @param _writer binary writer instance
   */
  static serializeBinaryToWriter(
    _instance: GetCustomerUsersRequest,
    _writer: BinaryWriter
  ) {
    if (_instance.customerId) {
      _writer.writeString(1, _instance.customerId);
    }
    if (_instance.fromItem) {
      _writer.writeUint32(2, _instance.fromItem);
    }
    if (_instance.maxItems) {
      _writer.writeUint32(3, _instance.maxItems);
    }
    if (_instance.adminsOnly) {
      _writer.writeBool(4, _instance.adminsOnly);
    }
  }

  private _customerId?: string;
  private _fromItem?: number;
  private _maxItems?: number;
  private _adminsOnly?: boolean;

  /**
   * Message constructor. Initializes the properties and applies default Protobuf values if necessary
   * @param _value initial values object or instance of GetCustomerUsersRequest to deeply clone from
   */
  constructor(_value?: RecursivePartial<GetCustomerUsersRequest.AsObject>) {
    _value = _value || {};
    this.customerId = _value.customerId;
    this.fromItem = _value.fromItem;
    this.maxItems = _value.maxItems;
    this.adminsOnly = _value.adminsOnly;
    GetCustomerUsersRequest.refineValues(this);
  }
  get customerId(): string | undefined {
    return this._customerId;
  }
  set customerId(value: string | undefined) {
    this._customerId = value;
  }
  get fromItem(): number | undefined {
    return this._fromItem;
  }
  set fromItem(value: number | undefined) {
    this._fromItem = value;
  }
  get maxItems(): number | undefined {
    return this._maxItems;
  }
  set maxItems(value: number | undefined) {
    this._maxItems = value;
  }
  get adminsOnly(): boolean | undefined {
    return this._adminsOnly;
  }
  set adminsOnly(value: boolean | undefined) {
    this._adminsOnly = value;
  }

  /**
   * Serialize message to binary data
   * @param instance message instance
   */
  serializeBinary() {
    const writer = new BinaryWriter();
    GetCustomerUsersRequest.serializeBinaryToWriter(this, writer);
    return writer.getResultBuffer();
  }

  /**
   * Cast message to standard JavaScript object (all non-primitive values are deeply cloned)
   */
  toObject(): GetCustomerUsersRequest.AsObject {
    return {
      customerId: this.customerId,
      fromItem: this.fromItem,
      maxItems: this.maxItems,
      adminsOnly: this.adminsOnly
    };
  }

  /**
   * Convenience method to support JSON.stringify(message), replicates the structure of toObject()
   */
  toJSON() {
    return this.toObject();
  }

  /**
   * Cast message to JSON using protobuf JSON notation: https://developers.google.com/protocol-buffers/docs/proto3#json
   * Attention: output differs from toObject() e.g. enums are represented as names and not as numbers, Timestamp is an ISO Date string format etc.
   * If the message itself or some of descendant messages is google.protobuf.Any, you MUST provide a message pool as options. If not, the messagePool is not required
   */
  toProtobufJSON(
    // @ts-ignore
    options?: ToProtobufJSONOptions
  ): GetCustomerUsersRequest.AsProtobufJSON {
    return {
      customerId: this.customerId,
      fromItem: this.fromItem,
      maxItems: this.maxItems,
      adminsOnly: this.adminsOnly
    };
  }
}
export module GetCustomerUsersRequest {
  /**
   * Standard JavaScript object representation for GetCustomerUsersRequest
   */
  export interface AsObject {
    customerId?: string;
    fromItem?: number;
    maxItems?: number;
    adminsOnly?: boolean;
  }

  /**
   * Protobuf JSON representation for GetCustomerUsersRequest
   */
  export interface AsProtobufJSON {
    customerId?: string;
    fromItem?: number;
    maxItems?: number;
    adminsOnly?: boolean;
  }
}

/**
 * Message implementation for modulebms.customeradmin.GetCustomerUsersResponse
 */
export class GetCustomerUsersResponse implements GrpcMessage {
  static id = 'modulebms.customeradmin.GetCustomerUsersResponse';

  /**
   * Deserialize binary data to message
   * @param instance message instance
   */
  static deserializeBinary(bytes: ByteSource) {
    const instance = new GetCustomerUsersResponse();
    GetCustomerUsersResponse.deserializeBinaryFromReader(
      instance,
      new BinaryReader(bytes)
    );
    return instance;
  }

  /**
   * Check all the properties and set default protobuf values if necessary
   * @param _instance message instance
   */
  static refineValues(_instance: GetCustomerUsersResponse) {
    _instance.customerUsers = _instance.customerUsers || [];
  }

  /**
   * Deserializes / reads binary message into message instance using provided binary reader
   * @param _instance message instance
   * @param _reader binary reader instance
   */
  static deserializeBinaryFromReader(
    _instance: GetCustomerUsersResponse,
    _reader: BinaryReader
  ) {
    while (_reader.nextField()) {
      if (_reader.isEndGroup()) break;

      switch (_reader.getFieldNumber()) {
        case 1:
          const messageInitializer1 = new BmsCustomerUser();
          _reader.readMessage(
            messageInitializer1,
            BmsCustomerUser.deserializeBinaryFromReader
          );
          (_instance.customerUsers = _instance.customerUsers || []).push(
            messageInitializer1
          );
          break;
        default:
          _reader.skipField();
      }
    }

    GetCustomerUsersResponse.refineValues(_instance);
  }

  /**
   * Serializes a message to binary format using provided binary reader
   * @param _instance message instance
   * @param _writer binary writer instance
   */
  static serializeBinaryToWriter(
    _instance: GetCustomerUsersResponse,
    _writer: BinaryWriter
  ) {
    if (_instance.customerUsers && _instance.customerUsers.length) {
      _writer.writeRepeatedMessage(
        1,
        _instance.customerUsers as any,
        BmsCustomerUser.serializeBinaryToWriter
      );
    }
  }

  private _customerUsers?: BmsCustomerUser[];

  /**
   * Message constructor. Initializes the properties and applies default Protobuf values if necessary
   * @param _value initial values object or instance of GetCustomerUsersResponse to deeply clone from
   */
  constructor(_value?: RecursivePartial<GetCustomerUsersResponse.AsObject>) {
    _value = _value || {};
    this.customerUsers = (_value.customerUsers || []).map(
      m => new BmsCustomerUser(m)
    );
    GetCustomerUsersResponse.refineValues(this);
  }
  get customerUsers(): BmsCustomerUser[] | undefined {
    return this._customerUsers;
  }
  set customerUsers(value: BmsCustomerUser[] | undefined) {
    this._customerUsers = value;
  }

  /**
   * Serialize message to binary data
   * @param instance message instance
   */
  serializeBinary() {
    const writer = new BinaryWriter();
    GetCustomerUsersResponse.serializeBinaryToWriter(this, writer);
    return writer.getResultBuffer();
  }

  /**
   * Cast message to standard JavaScript object (all non-primitive values are deeply cloned)
   */
  toObject(): GetCustomerUsersResponse.AsObject {
    return {
      customerUsers: (this.customerUsers || []).map(m => m.toObject())
    };
  }

  /**
   * Convenience method to support JSON.stringify(message), replicates the structure of toObject()
   */
  toJSON() {
    return this.toObject();
  }

  /**
   * Cast message to JSON using protobuf JSON notation: https://developers.google.com/protocol-buffers/docs/proto3#json
   * Attention: output differs from toObject() e.g. enums are represented as names and not as numbers, Timestamp is an ISO Date string format etc.
   * If the message itself or some of descendant messages is google.protobuf.Any, you MUST provide a message pool as options. If not, the messagePool is not required
   */
  toProtobufJSON(
    // @ts-ignore
    options?: ToProtobufJSONOptions
  ): GetCustomerUsersResponse.AsProtobufJSON {
    return {
      customerUsers: (this.customerUsers || []).map(m =>
        m.toProtobufJSON(options)
      )
    };
  }
}
export module GetCustomerUsersResponse {
  /**
   * Standard JavaScript object representation for GetCustomerUsersResponse
   */
  export interface AsObject {
    customerUsers?: BmsCustomerUser.AsObject[];
  }

  /**
   * Protobuf JSON representation for GetCustomerUsersResponse
   */
  export interface AsProtobufJSON {
    customerUsers?: BmsCustomerUser.AsProtobufJSON[] | null;
  }
}

/**
 * Message implementation for modulebms.customeradmin.GetApartmentCustomerUsersRequest
 */
export class GetApartmentCustomerUsersRequest implements GrpcMessage {
  static id = 'modulebms.customeradmin.GetApartmentCustomerUsersRequest';

  /**
   * Deserialize binary data to message
   * @param instance message instance
   */
  static deserializeBinary(bytes: ByteSource) {
    const instance = new GetApartmentCustomerUsersRequest();
    GetApartmentCustomerUsersRequest.deserializeBinaryFromReader(
      instance,
      new BinaryReader(bytes)
    );
    return instance;
  }

  /**
   * Check all the properties and set default protobuf values if necessary
   * @param _instance message instance
   */
  static refineValues(_instance: GetApartmentCustomerUsersRequest) {
    _instance.customerId = _instance.customerId || '';
    _instance.apartmentId = _instance.apartmentId || '';
    _instance.fromItem = _instance.fromItem || 0;
    _instance.maxItems = _instance.maxItems || 0;
  }

  /**
   * Deserializes / reads binary message into message instance using provided binary reader
   * @param _instance message instance
   * @param _reader binary reader instance
   */
  static deserializeBinaryFromReader(
    _instance: GetApartmentCustomerUsersRequest,
    _reader: BinaryReader
  ) {
    while (_reader.nextField()) {
      if (_reader.isEndGroup()) break;

      switch (_reader.getFieldNumber()) {
        case 1:
          _instance.customerId = _reader.readString();
          break;
        case 2:
          _instance.apartmentId = _reader.readString();
          break;
        case 3:
          _instance.fromItem = _reader.readUint32();
          break;
        case 4:
          _instance.maxItems = _reader.readUint32();
          break;
        default:
          _reader.skipField();
      }
    }

    GetApartmentCustomerUsersRequest.refineValues(_instance);
  }

  /**
   * Serializes a message to binary format using provided binary reader
   * @param _instance message instance
   * @param _writer binary writer instance
   */
  static serializeBinaryToWriter(
    _instance: GetApartmentCustomerUsersRequest,
    _writer: BinaryWriter
  ) {
    if (_instance.customerId) {
      _writer.writeString(1, _instance.customerId);
    }
    if (_instance.apartmentId) {
      _writer.writeString(2, _instance.apartmentId);
    }
    if (_instance.fromItem) {
      _writer.writeUint32(3, _instance.fromItem);
    }
    if (_instance.maxItems) {
      _writer.writeUint32(4, _instance.maxItems);
    }
  }

  private _customerId?: string;
  private _apartmentId?: string;
  private _fromItem?: number;
  private _maxItems?: number;

  /**
   * Message constructor. Initializes the properties and applies default Protobuf values if necessary
   * @param _value initial values object or instance of GetApartmentCustomerUsersRequest to deeply clone from
   */
  constructor(
    _value?: RecursivePartial<GetApartmentCustomerUsersRequest.AsObject>
  ) {
    _value = _value || {};
    this.customerId = _value.customerId;
    this.apartmentId = _value.apartmentId;
    this.fromItem = _value.fromItem;
    this.maxItems = _value.maxItems;
    GetApartmentCustomerUsersRequest.refineValues(this);
  }
  get customerId(): string | undefined {
    return this._customerId;
  }
  set customerId(value: string | undefined) {
    this._customerId = value;
  }
  get apartmentId(): string | undefined {
    return this._apartmentId;
  }
  set apartmentId(value: string | undefined) {
    this._apartmentId = value;
  }
  get fromItem(): number | undefined {
    return this._fromItem;
  }
  set fromItem(value: number | undefined) {
    this._fromItem = value;
  }
  get maxItems(): number | undefined {
    return this._maxItems;
  }
  set maxItems(value: number | undefined) {
    this._maxItems = value;
  }

  /**
   * Serialize message to binary data
   * @param instance message instance
   */
  serializeBinary() {
    const writer = new BinaryWriter();
    GetApartmentCustomerUsersRequest.serializeBinaryToWriter(this, writer);
    return writer.getResultBuffer();
  }

  /**
   * Cast message to standard JavaScript object (all non-primitive values are deeply cloned)
   */
  toObject(): GetApartmentCustomerUsersRequest.AsObject {
    return {
      customerId: this.customerId,
      apartmentId: this.apartmentId,
      fromItem: this.fromItem,
      maxItems: this.maxItems
    };
  }

  /**
   * Convenience method to support JSON.stringify(message), replicates the structure of toObject()
   */
  toJSON() {
    return this.toObject();
  }

  /**
   * Cast message to JSON using protobuf JSON notation: https://developers.google.com/protocol-buffers/docs/proto3#json
   * Attention: output differs from toObject() e.g. enums are represented as names and not as numbers, Timestamp is an ISO Date string format etc.
   * If the message itself or some of descendant messages is google.protobuf.Any, you MUST provide a message pool as options. If not, the messagePool is not required
   */
  toProtobufJSON(
    // @ts-ignore
    options?: ToProtobufJSONOptions
  ): GetApartmentCustomerUsersRequest.AsProtobufJSON {
    return {
      customerId: this.customerId,
      apartmentId: this.apartmentId,
      fromItem: this.fromItem,
      maxItems: this.maxItems
    };
  }
}
export module GetApartmentCustomerUsersRequest {
  /**
   * Standard JavaScript object representation for GetApartmentCustomerUsersRequest
   */
  export interface AsObject {
    customerId?: string;
    apartmentId?: string;
    fromItem?: number;
    maxItems?: number;
  }

  /**
   * Protobuf JSON representation for GetApartmentCustomerUsersRequest
   */
  export interface AsProtobufJSON {
    customerId?: string;
    apartmentId?: string;
    fromItem?: number;
    maxItems?: number;
  }
}

/**
 * Message implementation for modulebms.customeradmin.GetApartmentCustomerUsersResponse
 */
export class GetApartmentCustomerUsersResponse implements GrpcMessage {
  static id = 'modulebms.customeradmin.GetApartmentCustomerUsersResponse';

  /**
   * Deserialize binary data to message
   * @param instance message instance
   */
  static deserializeBinary(bytes: ByteSource) {
    const instance = new GetApartmentCustomerUsersResponse();
    GetApartmentCustomerUsersResponse.deserializeBinaryFromReader(
      instance,
      new BinaryReader(bytes)
    );
    return instance;
  }

  /**
   * Check all the properties and set default protobuf values if necessary
   * @param _instance message instance
   */
  static refineValues(_instance: GetApartmentCustomerUsersResponse) {
    _instance.customerUsers = _instance.customerUsers || [];
  }

  /**
   * Deserializes / reads binary message into message instance using provided binary reader
   * @param _instance message instance
   * @param _reader binary reader instance
   */
  static deserializeBinaryFromReader(
    _instance: GetApartmentCustomerUsersResponse,
    _reader: BinaryReader
  ) {
    while (_reader.nextField()) {
      if (_reader.isEndGroup()) break;

      switch (_reader.getFieldNumber()) {
        case 1:
          const messageInitializer1 = new BmsCustomerUser();
          _reader.readMessage(
            messageInitializer1,
            BmsCustomerUser.deserializeBinaryFromReader
          );
          (_instance.customerUsers = _instance.customerUsers || []).push(
            messageInitializer1
          );
          break;
        default:
          _reader.skipField();
      }
    }

    GetApartmentCustomerUsersResponse.refineValues(_instance);
  }

  /**
   * Serializes a message to binary format using provided binary reader
   * @param _instance message instance
   * @param _writer binary writer instance
   */
  static serializeBinaryToWriter(
    _instance: GetApartmentCustomerUsersResponse,
    _writer: BinaryWriter
  ) {
    if (_instance.customerUsers && _instance.customerUsers.length) {
      _writer.writeRepeatedMessage(
        1,
        _instance.customerUsers as any,
        BmsCustomerUser.serializeBinaryToWriter
      );
    }
  }

  private _customerUsers?: BmsCustomerUser[];

  /**
   * Message constructor. Initializes the properties and applies default Protobuf values if necessary
   * @param _value initial values object or instance of GetApartmentCustomerUsersResponse to deeply clone from
   */
  constructor(
    _value?: RecursivePartial<GetApartmentCustomerUsersResponse.AsObject>
  ) {
    _value = _value || {};
    this.customerUsers = (_value.customerUsers || []).map(
      m => new BmsCustomerUser(m)
    );
    GetApartmentCustomerUsersResponse.refineValues(this);
  }
  get customerUsers(): BmsCustomerUser[] | undefined {
    return this._customerUsers;
  }
  set customerUsers(value: BmsCustomerUser[] | undefined) {
    this._customerUsers = value;
  }

  /**
   * Serialize message to binary data
   * @param instance message instance
   */
  serializeBinary() {
    const writer = new BinaryWriter();
    GetApartmentCustomerUsersResponse.serializeBinaryToWriter(this, writer);
    return writer.getResultBuffer();
  }

  /**
   * Cast message to standard JavaScript object (all non-primitive values are deeply cloned)
   */
  toObject(): GetApartmentCustomerUsersResponse.AsObject {
    return {
      customerUsers: (this.customerUsers || []).map(m => m.toObject())
    };
  }

  /**
   * Convenience method to support JSON.stringify(message), replicates the structure of toObject()
   */
  toJSON() {
    return this.toObject();
  }

  /**
   * Cast message to JSON using protobuf JSON notation: https://developers.google.com/protocol-buffers/docs/proto3#json
   * Attention: output differs from toObject() e.g. enums are represented as names and not as numbers, Timestamp is an ISO Date string format etc.
   * If the message itself or some of descendant messages is google.protobuf.Any, you MUST provide a message pool as options. If not, the messagePool is not required
   */
  toProtobufJSON(
    // @ts-ignore
    options?: ToProtobufJSONOptions
  ): GetApartmentCustomerUsersResponse.AsProtobufJSON {
    return {
      customerUsers: (this.customerUsers || []).map(m =>
        m.toProtobufJSON(options)
      )
    };
  }
}
export module GetApartmentCustomerUsersResponse {
  /**
   * Standard JavaScript object representation for GetApartmentCustomerUsersResponse
   */
  export interface AsObject {
    customerUsers?: BmsCustomerUser.AsObject[];
  }

  /**
   * Protobuf JSON representation for GetApartmentCustomerUsersResponse
   */
  export interface AsProtobufJSON {
    customerUsers?: BmsCustomerUser.AsProtobufJSON[] | null;
  }
}

/**
 * Message implementation for modulebms.customeradmin.AddCustomerUserRequest
 */
export class AddCustomerUserRequest implements GrpcMessage {
  static id = 'modulebms.customeradmin.AddCustomerUserRequest';

  /**
   * Deserialize binary data to message
   * @param instance message instance
   */
  static deserializeBinary(bytes: ByteSource) {
    const instance = new AddCustomerUserRequest();
    AddCustomerUserRequest.deserializeBinaryFromReader(
      instance,
      new BinaryReader(bytes)
    );
    return instance;
  }

  /**
   * Check all the properties and set default protobuf values if necessary
   * @param _instance message instance
   */
  static refineValues(_instance: AddCustomerUserRequest) {
    _instance.customerId = _instance.customerId || '';
    _instance.customerUser = _instance.customerUser || undefined;
    _instance.apartmentId = _instance.apartmentId || '';
  }

  /**
   * Deserializes / reads binary message into message instance using provided binary reader
   * @param _instance message instance
   * @param _reader binary reader instance
   */
  static deserializeBinaryFromReader(
    _instance: AddCustomerUserRequest,
    _reader: BinaryReader
  ) {
    while (_reader.nextField()) {
      if (_reader.isEndGroup()) break;

      switch (_reader.getFieldNumber()) {
        case 1:
          _instance.customerId = _reader.readString();
          break;
        case 2:
          _instance.customerUser = new BmsCustomerUser();
          _reader.readMessage(
            _instance.customerUser,
            BmsCustomerUser.deserializeBinaryFromReader
          );
          break;
        case 3:
          _instance.apartmentId = _reader.readString();
          break;
        default:
          _reader.skipField();
      }
    }

    AddCustomerUserRequest.refineValues(_instance);
  }

  /**
   * Serializes a message to binary format using provided binary reader
   * @param _instance message instance
   * @param _writer binary writer instance
   */
  static serializeBinaryToWriter(
    _instance: AddCustomerUserRequest,
    _writer: BinaryWriter
  ) {
    if (_instance.customerId) {
      _writer.writeString(1, _instance.customerId);
    }
    if (_instance.customerUser) {
      _writer.writeMessage(
        2,
        _instance.customerUser as any,
        BmsCustomerUser.serializeBinaryToWriter
      );
    }
    if (_instance.apartmentId) {
      _writer.writeString(3, _instance.apartmentId);
    }
  }

  private _customerId?: string;
  private _customerUser?: BmsCustomerUser;
  private _apartmentId?: string;

  /**
   * Message constructor. Initializes the properties and applies default Protobuf values if necessary
   * @param _value initial values object or instance of AddCustomerUserRequest to deeply clone from
   */
  constructor(_value?: RecursivePartial<AddCustomerUserRequest.AsObject>) {
    _value = _value || {};
    this.customerId = _value.customerId;
    this.customerUser = _value.customerUser
      ? new BmsCustomerUser(_value.customerUser)
      : undefined;
    this.apartmentId = _value.apartmentId;
    AddCustomerUserRequest.refineValues(this);
  }
  get customerId(): string | undefined {
    return this._customerId;
  }
  set customerId(value: string | undefined) {
    this._customerId = value;
  }
  get customerUser(): BmsCustomerUser | undefined {
    return this._customerUser;
  }
  set customerUser(value: BmsCustomerUser | undefined) {
    this._customerUser = value;
  }
  get apartmentId(): string | undefined {
    return this._apartmentId;
  }
  set apartmentId(value: string | undefined) {
    this._apartmentId = value;
  }

  /**
   * Serialize message to binary data
   * @param instance message instance
   */
  serializeBinary() {
    const writer = new BinaryWriter();
    AddCustomerUserRequest.serializeBinaryToWriter(this, writer);
    return writer.getResultBuffer();
  }

  /**
   * Cast message to standard JavaScript object (all non-primitive values are deeply cloned)
   */
  toObject(): AddCustomerUserRequest.AsObject {
    return {
      customerId: this.customerId,
      customerUser: this.customerUser
        ? this.customerUser.toObject()
        : undefined,
      apartmentId: this.apartmentId
    };
  }

  /**
   * Convenience method to support JSON.stringify(message), replicates the structure of toObject()
   */
  toJSON() {
    return this.toObject();
  }

  /**
   * Cast message to JSON using protobuf JSON notation: https://developers.google.com/protocol-buffers/docs/proto3#json
   * Attention: output differs from toObject() e.g. enums are represented as names and not as numbers, Timestamp is an ISO Date string format etc.
   * If the message itself or some of descendant messages is google.protobuf.Any, you MUST provide a message pool as options. If not, the messagePool is not required
   */
  toProtobufJSON(
    // @ts-ignore
    options?: ToProtobufJSONOptions
  ): AddCustomerUserRequest.AsProtobufJSON {
    return {
      customerId: this.customerId,
      customerUser: this.customerUser
        ? this.customerUser.toProtobufJSON(options)
        : null,
      apartmentId: this.apartmentId
    };
  }
}
export module AddCustomerUserRequest {
  /**
   * Standard JavaScript object representation for AddCustomerUserRequest
   */
  export interface AsObject {
    customerId?: string;
    customerUser?: BmsCustomerUser.AsObject;
    apartmentId?: string;
  }

  /**
   * Protobuf JSON representation for AddCustomerUserRequest
   */
  export interface AsProtobufJSON {
    customerId?: string;
    customerUser?: BmsCustomerUser.AsProtobufJSON | null;
    apartmentId?: string;
  }
}

/**
 * Message implementation for modulebms.customeradmin.AddCustomerUserResponse
 */
export class AddCustomerUserResponse implements GrpcMessage {
  static id = 'modulebms.customeradmin.AddCustomerUserResponse';

  /**
   * Deserialize binary data to message
   * @param instance message instance
   */
  static deserializeBinary(bytes: ByteSource) {
    const instance = new AddCustomerUserResponse();
    AddCustomerUserResponse.deserializeBinaryFromReader(
      instance,
      new BinaryReader(bytes)
    );
    return instance;
  }

  /**
   * Check all the properties and set default protobuf values if necessary
   * @param _instance message instance
   */
  static refineValues(_instance: AddCustomerUserResponse) {
    _instance.ok = _instance.ok || false;
    _instance.message = _instance.message || '';
  }

  /**
   * Deserializes / reads binary message into message instance using provided binary reader
   * @param _instance message instance
   * @param _reader binary reader instance
   */
  static deserializeBinaryFromReader(
    _instance: AddCustomerUserResponse,
    _reader: BinaryReader
  ) {
    while (_reader.nextField()) {
      if (_reader.isEndGroup()) break;

      switch (_reader.getFieldNumber()) {
        case 1:
          _instance.ok = _reader.readBool();
          break;
        case 2:
          _instance.message = _reader.readString();
          break;
        default:
          _reader.skipField();
      }
    }

    AddCustomerUserResponse.refineValues(_instance);
  }

  /**
   * Serializes a message to binary format using provided binary reader
   * @param _instance message instance
   * @param _writer binary writer instance
   */
  static serializeBinaryToWriter(
    _instance: AddCustomerUserResponse,
    _writer: BinaryWriter
  ) {
    if (_instance.ok) {
      _writer.writeBool(1, _instance.ok);
    }
    if (_instance.message) {
      _writer.writeString(2, _instance.message);
    }
  }

  private _ok?: boolean;
  private _message?: string;

  /**
   * Message constructor. Initializes the properties and applies default Protobuf values if necessary
   * @param _value initial values object or instance of AddCustomerUserResponse to deeply clone from
   */
  constructor(_value?: RecursivePartial<AddCustomerUserResponse.AsObject>) {
    _value = _value || {};
    this.ok = _value.ok;
    this.message = _value.message;
    AddCustomerUserResponse.refineValues(this);
  }
  get ok(): boolean | undefined {
    return this._ok;
  }
  set ok(value: boolean | undefined) {
    this._ok = value;
  }
  get message(): string | undefined {
    return this._message;
  }
  set message(value: string | undefined) {
    this._message = value;
  }

  /**
   * Serialize message to binary data
   * @param instance message instance
   */
  serializeBinary() {
    const writer = new BinaryWriter();
    AddCustomerUserResponse.serializeBinaryToWriter(this, writer);
    return writer.getResultBuffer();
  }

  /**
   * Cast message to standard JavaScript object (all non-primitive values are deeply cloned)
   */
  toObject(): AddCustomerUserResponse.AsObject {
    return {
      ok: this.ok,
      message: this.message
    };
  }

  /**
   * Convenience method to support JSON.stringify(message), replicates the structure of toObject()
   */
  toJSON() {
    return this.toObject();
  }

  /**
   * Cast message to JSON using protobuf JSON notation: https://developers.google.com/protocol-buffers/docs/proto3#json
   * Attention: output differs from toObject() e.g. enums are represented as names and not as numbers, Timestamp is an ISO Date string format etc.
   * If the message itself or some of descendant messages is google.protobuf.Any, you MUST provide a message pool as options. If not, the messagePool is not required
   */
  toProtobufJSON(
    // @ts-ignore
    options?: ToProtobufJSONOptions
  ): AddCustomerUserResponse.AsProtobufJSON {
    return {
      ok: this.ok,
      message: this.message
    };
  }
}
export module AddCustomerUserResponse {
  /**
   * Standard JavaScript object representation for AddCustomerUserResponse
   */
  export interface AsObject {
    ok?: boolean;
    message?: string;
  }

  /**
   * Protobuf JSON representation for AddCustomerUserResponse
   */
  export interface AsProtobufJSON {
    ok?: boolean;
    message?: string;
  }
}

/**
 * Message implementation for modulebms.customeradmin.DeleteCustomerUserRequest
 */
export class DeleteCustomerUserRequest implements GrpcMessage {
  static id = 'modulebms.customeradmin.DeleteCustomerUserRequest';

  /**
   * Deserialize binary data to message
   * @param instance message instance
   */
  static deserializeBinary(bytes: ByteSource) {
    const instance = new DeleteCustomerUserRequest();
    DeleteCustomerUserRequest.deserializeBinaryFromReader(
      instance,
      new BinaryReader(bytes)
    );
    return instance;
  }

  /**
   * Check all the properties and set default protobuf values if necessary
   * @param _instance message instance
   */
  static refineValues(_instance: DeleteCustomerUserRequest) {
    _instance.customerId = _instance.customerId || '';
    _instance.customerUserId = _instance.customerUserId || '';
  }

  /**
   * Deserializes / reads binary message into message instance using provided binary reader
   * @param _instance message instance
   * @param _reader binary reader instance
   */
  static deserializeBinaryFromReader(
    _instance: DeleteCustomerUserRequest,
    _reader: BinaryReader
  ) {
    while (_reader.nextField()) {
      if (_reader.isEndGroup()) break;

      switch (_reader.getFieldNumber()) {
        case 1:
          _instance.customerId = _reader.readString();
          break;
        case 2:
          _instance.customerUserId = _reader.readString();
          break;
        default:
          _reader.skipField();
      }
    }

    DeleteCustomerUserRequest.refineValues(_instance);
  }

  /**
   * Serializes a message to binary format using provided binary reader
   * @param _instance message instance
   * @param _writer binary writer instance
   */
  static serializeBinaryToWriter(
    _instance: DeleteCustomerUserRequest,
    _writer: BinaryWriter
  ) {
    if (_instance.customerId) {
      _writer.writeString(1, _instance.customerId);
    }
    if (_instance.customerUserId) {
      _writer.writeString(2, _instance.customerUserId);
    }
  }

  private _customerId?: string;
  private _customerUserId?: string;

  /**
   * Message constructor. Initializes the properties and applies default Protobuf values if necessary
   * @param _value initial values object or instance of DeleteCustomerUserRequest to deeply clone from
   */
  constructor(_value?: RecursivePartial<DeleteCustomerUserRequest.AsObject>) {
    _value = _value || {};
    this.customerId = _value.customerId;
    this.customerUserId = _value.customerUserId;
    DeleteCustomerUserRequest.refineValues(this);
  }
  get customerId(): string | undefined {
    return this._customerId;
  }
  set customerId(value: string | undefined) {
    this._customerId = value;
  }
  get customerUserId(): string | undefined {
    return this._customerUserId;
  }
  set customerUserId(value: string | undefined) {
    this._customerUserId = value;
  }

  /**
   * Serialize message to binary data
   * @param instance message instance
   */
  serializeBinary() {
    const writer = new BinaryWriter();
    DeleteCustomerUserRequest.serializeBinaryToWriter(this, writer);
    return writer.getResultBuffer();
  }

  /**
   * Cast message to standard JavaScript object (all non-primitive values are deeply cloned)
   */
  toObject(): DeleteCustomerUserRequest.AsObject {
    return {
      customerId: this.customerId,
      customerUserId: this.customerUserId
    };
  }

  /**
   * Convenience method to support JSON.stringify(message), replicates the structure of toObject()
   */
  toJSON() {
    return this.toObject();
  }

  /**
   * Cast message to JSON using protobuf JSON notation: https://developers.google.com/protocol-buffers/docs/proto3#json
   * Attention: output differs from toObject() e.g. enums are represented as names and not as numbers, Timestamp is an ISO Date string format etc.
   * If the message itself or some of descendant messages is google.protobuf.Any, you MUST provide a message pool as options. If not, the messagePool is not required
   */
  toProtobufJSON(
    // @ts-ignore
    options?: ToProtobufJSONOptions
  ): DeleteCustomerUserRequest.AsProtobufJSON {
    return {
      customerId: this.customerId,
      customerUserId: this.customerUserId
    };
  }
}
export module DeleteCustomerUserRequest {
  /**
   * Standard JavaScript object representation for DeleteCustomerUserRequest
   */
  export interface AsObject {
    customerId?: string;
    customerUserId?: string;
  }

  /**
   * Protobuf JSON representation for DeleteCustomerUserRequest
   */
  export interface AsProtobufJSON {
    customerId?: string;
    customerUserId?: string;
  }
}

/**
 * Message implementation for modulebms.customeradmin.DeleteCustomerUserResponse
 */
export class DeleteCustomerUserResponse implements GrpcMessage {
  static id = 'modulebms.customeradmin.DeleteCustomerUserResponse';

  /**
   * Deserialize binary data to message
   * @param instance message instance
   */
  static deserializeBinary(bytes: ByteSource) {
    const instance = new DeleteCustomerUserResponse();
    DeleteCustomerUserResponse.deserializeBinaryFromReader(
      instance,
      new BinaryReader(bytes)
    );
    return instance;
  }

  /**
   * Check all the properties and set default protobuf values if necessary
   * @param _instance message instance
   */
  static refineValues(_instance: DeleteCustomerUserResponse) {
    _instance.ok = _instance.ok || false;
    _instance.message = _instance.message || '';
  }

  /**
   * Deserializes / reads binary message into message instance using provided binary reader
   * @param _instance message instance
   * @param _reader binary reader instance
   */
  static deserializeBinaryFromReader(
    _instance: DeleteCustomerUserResponse,
    _reader: BinaryReader
  ) {
    while (_reader.nextField()) {
      if (_reader.isEndGroup()) break;

      switch (_reader.getFieldNumber()) {
        case 1:
          _instance.ok = _reader.readBool();
          break;
        case 2:
          _instance.message = _reader.readString();
          break;
        default:
          _reader.skipField();
      }
    }

    DeleteCustomerUserResponse.refineValues(_instance);
  }

  /**
   * Serializes a message to binary format using provided binary reader
   * @param _instance message instance
   * @param _writer binary writer instance
   */
  static serializeBinaryToWriter(
    _instance: DeleteCustomerUserResponse,
    _writer: BinaryWriter
  ) {
    if (_instance.ok) {
      _writer.writeBool(1, _instance.ok);
    }
    if (_instance.message) {
      _writer.writeString(2, _instance.message);
    }
  }

  private _ok?: boolean;
  private _message?: string;

  /**
   * Message constructor. Initializes the properties and applies default Protobuf values if necessary
   * @param _value initial values object or instance of DeleteCustomerUserResponse to deeply clone from
   */
  constructor(_value?: RecursivePartial<DeleteCustomerUserResponse.AsObject>) {
    _value = _value || {};
    this.ok = _value.ok;
    this.message = _value.message;
    DeleteCustomerUserResponse.refineValues(this);
  }
  get ok(): boolean | undefined {
    return this._ok;
  }
  set ok(value: boolean | undefined) {
    this._ok = value;
  }
  get message(): string | undefined {
    return this._message;
  }
  set message(value: string | undefined) {
    this._message = value;
  }

  /**
   * Serialize message to binary data
   * @param instance message instance
   */
  serializeBinary() {
    const writer = new BinaryWriter();
    DeleteCustomerUserResponse.serializeBinaryToWriter(this, writer);
    return writer.getResultBuffer();
  }

  /**
   * Cast message to standard JavaScript object (all non-primitive values are deeply cloned)
   */
  toObject(): DeleteCustomerUserResponse.AsObject {
    return {
      ok: this.ok,
      message: this.message
    };
  }

  /**
   * Convenience method to support JSON.stringify(message), replicates the structure of toObject()
   */
  toJSON() {
    return this.toObject();
  }

  /**
   * Cast message to JSON using protobuf JSON notation: https://developers.google.com/protocol-buffers/docs/proto3#json
   * Attention: output differs from toObject() e.g. enums are represented as names and not as numbers, Timestamp is an ISO Date string format etc.
   * If the message itself or some of descendant messages is google.protobuf.Any, you MUST provide a message pool as options. If not, the messagePool is not required
   */
  toProtobufJSON(
    // @ts-ignore
    options?: ToProtobufJSONOptions
  ): DeleteCustomerUserResponse.AsProtobufJSON {
    return {
      ok: this.ok,
      message: this.message
    };
  }
}
export module DeleteCustomerUserResponse {
  /**
   * Standard JavaScript object representation for DeleteCustomerUserResponse
   */
  export interface AsObject {
    ok?: boolean;
    message?: string;
  }

  /**
   * Protobuf JSON representation for DeleteCustomerUserResponse
   */
  export interface AsProtobufJSON {
    ok?: boolean;
    message?: string;
  }
}

/**
 * Message implementation for modulebms.customeradmin.UpdateCustomerUserRequest
 */
export class UpdateCustomerUserRequest implements GrpcMessage {
  static id = 'modulebms.customeradmin.UpdateCustomerUserRequest';

  /**
   * Deserialize binary data to message
   * @param instance message instance
   */
  static deserializeBinary(bytes: ByteSource) {
    const instance = new UpdateCustomerUserRequest();
    UpdateCustomerUserRequest.deserializeBinaryFromReader(
      instance,
      new BinaryReader(bytes)
    );
    return instance;
  }

  /**
   * Check all the properties and set default protobuf values if necessary
   * @param _instance message instance
   */
  static refineValues(_instance: UpdateCustomerUserRequest) {
    _instance.customerId = _instance.customerId || '';
    _instance.customerUser = _instance.customerUser || undefined;
    _instance.apartmentId = _instance.apartmentId || '';
  }

  /**
   * Deserializes / reads binary message into message instance using provided binary reader
   * @param _instance message instance
   * @param _reader binary reader instance
   */
  static deserializeBinaryFromReader(
    _instance: UpdateCustomerUserRequest,
    _reader: BinaryReader
  ) {
    while (_reader.nextField()) {
      if (_reader.isEndGroup()) break;

      switch (_reader.getFieldNumber()) {
        case 1:
          _instance.customerId = _reader.readString();
          break;
        case 2:
          _instance.customerUser = new BmsCustomerUser();
          _reader.readMessage(
            _instance.customerUser,
            BmsCustomerUser.deserializeBinaryFromReader
          );
          break;
        case 3:
          _instance.apartmentId = _reader.readString();
          break;
        default:
          _reader.skipField();
      }
    }

    UpdateCustomerUserRequest.refineValues(_instance);
  }

  /**
   * Serializes a message to binary format using provided binary reader
   * @param _instance message instance
   * @param _writer binary writer instance
   */
  static serializeBinaryToWriter(
    _instance: UpdateCustomerUserRequest,
    _writer: BinaryWriter
  ) {
    if (_instance.customerId) {
      _writer.writeString(1, _instance.customerId);
    }
    if (_instance.customerUser) {
      _writer.writeMessage(
        2,
        _instance.customerUser as any,
        BmsCustomerUser.serializeBinaryToWriter
      );
    }
    if (_instance.apartmentId) {
      _writer.writeString(3, _instance.apartmentId);
    }
  }

  private _customerId?: string;
  private _customerUser?: BmsCustomerUser;
  private _apartmentId?: string;

  /**
   * Message constructor. Initializes the properties and applies default Protobuf values if necessary
   * @param _value initial values object or instance of UpdateCustomerUserRequest to deeply clone from
   */
  constructor(_value?: RecursivePartial<UpdateCustomerUserRequest.AsObject>) {
    _value = _value || {};
    this.customerId = _value.customerId;
    this.customerUser = _value.customerUser
      ? new BmsCustomerUser(_value.customerUser)
      : undefined;
    this.apartmentId = _value.apartmentId;
    UpdateCustomerUserRequest.refineValues(this);
  }
  get customerId(): string | undefined {
    return this._customerId;
  }
  set customerId(value: string | undefined) {
    this._customerId = value;
  }
  get customerUser(): BmsCustomerUser | undefined {
    return this._customerUser;
  }
  set customerUser(value: BmsCustomerUser | undefined) {
    this._customerUser = value;
  }
  get apartmentId(): string | undefined {
    return this._apartmentId;
  }
  set apartmentId(value: string | undefined) {
    this._apartmentId = value;
  }

  /**
   * Serialize message to binary data
   * @param instance message instance
   */
  serializeBinary() {
    const writer = new BinaryWriter();
    UpdateCustomerUserRequest.serializeBinaryToWriter(this, writer);
    return writer.getResultBuffer();
  }

  /**
   * Cast message to standard JavaScript object (all non-primitive values are deeply cloned)
   */
  toObject(): UpdateCustomerUserRequest.AsObject {
    return {
      customerId: this.customerId,
      customerUser: this.customerUser
        ? this.customerUser.toObject()
        : undefined,
      apartmentId: this.apartmentId
    };
  }

  /**
   * Convenience method to support JSON.stringify(message), replicates the structure of toObject()
   */
  toJSON() {
    return this.toObject();
  }

  /**
   * Cast message to JSON using protobuf JSON notation: https://developers.google.com/protocol-buffers/docs/proto3#json
   * Attention: output differs from toObject() e.g. enums are represented as names and not as numbers, Timestamp is an ISO Date string format etc.
   * If the message itself or some of descendant messages is google.protobuf.Any, you MUST provide a message pool as options. If not, the messagePool is not required
   */
  toProtobufJSON(
    // @ts-ignore
    options?: ToProtobufJSONOptions
  ): UpdateCustomerUserRequest.AsProtobufJSON {
    return {
      customerId: this.customerId,
      customerUser: this.customerUser
        ? this.customerUser.toProtobufJSON(options)
        : null,
      apartmentId: this.apartmentId
    };
  }
}
export module UpdateCustomerUserRequest {
  /**
   * Standard JavaScript object representation for UpdateCustomerUserRequest
   */
  export interface AsObject {
    customerId?: string;
    customerUser?: BmsCustomerUser.AsObject;
    apartmentId?: string;
  }

  /**
   * Protobuf JSON representation for UpdateCustomerUserRequest
   */
  export interface AsProtobufJSON {
    customerId?: string;
    customerUser?: BmsCustomerUser.AsProtobufJSON | null;
    apartmentId?: string;
  }
}

/**
 * Message implementation for modulebms.customeradmin.UpdateCustomerUserResponse
 */
export class UpdateCustomerUserResponse implements GrpcMessage {
  static id = 'modulebms.customeradmin.UpdateCustomerUserResponse';

  /**
   * Deserialize binary data to message
   * @param instance message instance
   */
  static deserializeBinary(bytes: ByteSource) {
    const instance = new UpdateCustomerUserResponse();
    UpdateCustomerUserResponse.deserializeBinaryFromReader(
      instance,
      new BinaryReader(bytes)
    );
    return instance;
  }

  /**
   * Check all the properties and set default protobuf values if necessary
   * @param _instance message instance
   */
  static refineValues(_instance: UpdateCustomerUserResponse) {
    _instance.ok = _instance.ok || false;
    _instance.message = _instance.message || '';
  }

  /**
   * Deserializes / reads binary message into message instance using provided binary reader
   * @param _instance message instance
   * @param _reader binary reader instance
   */
  static deserializeBinaryFromReader(
    _instance: UpdateCustomerUserResponse,
    _reader: BinaryReader
  ) {
    while (_reader.nextField()) {
      if (_reader.isEndGroup()) break;

      switch (_reader.getFieldNumber()) {
        case 1:
          _instance.ok = _reader.readBool();
          break;
        case 2:
          _instance.message = _reader.readString();
          break;
        default:
          _reader.skipField();
      }
    }

    UpdateCustomerUserResponse.refineValues(_instance);
  }

  /**
   * Serializes a message to binary format using provided binary reader
   * @param _instance message instance
   * @param _writer binary writer instance
   */
  static serializeBinaryToWriter(
    _instance: UpdateCustomerUserResponse,
    _writer: BinaryWriter
  ) {
    if (_instance.ok) {
      _writer.writeBool(1, _instance.ok);
    }
    if (_instance.message) {
      _writer.writeString(2, _instance.message);
    }
  }

  private _ok?: boolean;
  private _message?: string;

  /**
   * Message constructor. Initializes the properties and applies default Protobuf values if necessary
   * @param _value initial values object or instance of UpdateCustomerUserResponse to deeply clone from
   */
  constructor(_value?: RecursivePartial<UpdateCustomerUserResponse.AsObject>) {
    _value = _value || {};
    this.ok = _value.ok;
    this.message = _value.message;
    UpdateCustomerUserResponse.refineValues(this);
  }
  get ok(): boolean | undefined {
    return this._ok;
  }
  set ok(value: boolean | undefined) {
    this._ok = value;
  }
  get message(): string | undefined {
    return this._message;
  }
  set message(value: string | undefined) {
    this._message = value;
  }

  /**
   * Serialize message to binary data
   * @param instance message instance
   */
  serializeBinary() {
    const writer = new BinaryWriter();
    UpdateCustomerUserResponse.serializeBinaryToWriter(this, writer);
    return writer.getResultBuffer();
  }

  /**
   * Cast message to standard JavaScript object (all non-primitive values are deeply cloned)
   */
  toObject(): UpdateCustomerUserResponse.AsObject {
    return {
      ok: this.ok,
      message: this.message
    };
  }

  /**
   * Convenience method to support JSON.stringify(message), replicates the structure of toObject()
   */
  toJSON() {
    return this.toObject();
  }

  /**
   * Cast message to JSON using protobuf JSON notation: https://developers.google.com/protocol-buffers/docs/proto3#json
   * Attention: output differs from toObject() e.g. enums are represented as names and not as numbers, Timestamp is an ISO Date string format etc.
   * If the message itself or some of descendant messages is google.protobuf.Any, you MUST provide a message pool as options. If not, the messagePool is not required
   */
  toProtobufJSON(
    // @ts-ignore
    options?: ToProtobufJSONOptions
  ): UpdateCustomerUserResponse.AsProtobufJSON {
    return {
      ok: this.ok,
      message: this.message
    };
  }
}
export module UpdateCustomerUserResponse {
  /**
   * Standard JavaScript object representation for UpdateCustomerUserResponse
   */
  export interface AsObject {
    ok?: boolean;
    message?: string;
  }

  /**
   * Protobuf JSON representation for UpdateCustomerUserResponse
   */
  export interface AsProtobufJSON {
    ok?: boolean;
    message?: string;
  }
}

/**
 * Message implementation for modulebms.customeradmin.ResetPasswordRequest
 */
export class ResetPasswordRequest implements GrpcMessage {
  static id = 'modulebms.customeradmin.ResetPasswordRequest';

  /**
   * Deserialize binary data to message
   * @param instance message instance
   */
  static deserializeBinary(bytes: ByteSource) {
    const instance = new ResetPasswordRequest();
    ResetPasswordRequest.deserializeBinaryFromReader(
      instance,
      new BinaryReader(bytes)
    );
    return instance;
  }

  /**
   * Check all the properties and set default protobuf values if necessary
   * @param _instance message instance
   */
  static refineValues(_instance: ResetPasswordRequest) {
    _instance.customerId = _instance.customerId || '';
    _instance.customerUserId = _instance.customerUserId || '';
  }

  /**
   * Deserializes / reads binary message into message instance using provided binary reader
   * @param _instance message instance
   * @param _reader binary reader instance
   */
  static deserializeBinaryFromReader(
    _instance: ResetPasswordRequest,
    _reader: BinaryReader
  ) {
    while (_reader.nextField()) {
      if (_reader.isEndGroup()) break;

      switch (_reader.getFieldNumber()) {
        case 1:
          _instance.customerId = _reader.readString();
          break;
        case 2:
          _instance.customerUserId = _reader.readString();
          break;
        default:
          _reader.skipField();
      }
    }

    ResetPasswordRequest.refineValues(_instance);
  }

  /**
   * Serializes a message to binary format using provided binary reader
   * @param _instance message instance
   * @param _writer binary writer instance
   */
  static serializeBinaryToWriter(
    _instance: ResetPasswordRequest,
    _writer: BinaryWriter
  ) {
    if (_instance.customerId) {
      _writer.writeString(1, _instance.customerId);
    }
    if (_instance.customerUserId) {
      _writer.writeString(2, _instance.customerUserId);
    }
  }

  private _customerId?: string;
  private _customerUserId?: string;

  /**
   * Message constructor. Initializes the properties and applies default Protobuf values if necessary
   * @param _value initial values object or instance of ResetPasswordRequest to deeply clone from
   */
  constructor(_value?: RecursivePartial<ResetPasswordRequest.AsObject>) {
    _value = _value || {};
    this.customerId = _value.customerId;
    this.customerUserId = _value.customerUserId;
    ResetPasswordRequest.refineValues(this);
  }
  get customerId(): string | undefined {
    return this._customerId;
  }
  set customerId(value: string | undefined) {
    this._customerId = value;
  }
  get customerUserId(): string | undefined {
    return this._customerUserId;
  }
  set customerUserId(value: string | undefined) {
    this._customerUserId = value;
  }

  /**
   * Serialize message to binary data
   * @param instance message instance
   */
  serializeBinary() {
    const writer = new BinaryWriter();
    ResetPasswordRequest.serializeBinaryToWriter(this, writer);
    return writer.getResultBuffer();
  }

  /**
   * Cast message to standard JavaScript object (all non-primitive values are deeply cloned)
   */
  toObject(): ResetPasswordRequest.AsObject {
    return {
      customerId: this.customerId,
      customerUserId: this.customerUserId
    };
  }

  /**
   * Convenience method to support JSON.stringify(message), replicates the structure of toObject()
   */
  toJSON() {
    return this.toObject();
  }

  /**
   * Cast message to JSON using protobuf JSON notation: https://developers.google.com/protocol-buffers/docs/proto3#json
   * Attention: output differs from toObject() e.g. enums are represented as names and not as numbers, Timestamp is an ISO Date string format etc.
   * If the message itself or some of descendant messages is google.protobuf.Any, you MUST provide a message pool as options. If not, the messagePool is not required
   */
  toProtobufJSON(
    // @ts-ignore
    options?: ToProtobufJSONOptions
  ): ResetPasswordRequest.AsProtobufJSON {
    return {
      customerId: this.customerId,
      customerUserId: this.customerUserId
    };
  }
}
export module ResetPasswordRequest {
  /**
   * Standard JavaScript object representation for ResetPasswordRequest
   */
  export interface AsObject {
    customerId?: string;
    customerUserId?: string;
  }

  /**
   * Protobuf JSON representation for ResetPasswordRequest
   */
  export interface AsProtobufJSON {
    customerId?: string;
    customerUserId?: string;
  }
}

/**
 * Message implementation for modulebms.customeradmin.ResetPasswordResponse
 */
export class ResetPasswordResponse implements GrpcMessage {
  static id = 'modulebms.customeradmin.ResetPasswordResponse';

  /**
   * Deserialize binary data to message
   * @param instance message instance
   */
  static deserializeBinary(bytes: ByteSource) {
    const instance = new ResetPasswordResponse();
    ResetPasswordResponse.deserializeBinaryFromReader(
      instance,
      new BinaryReader(bytes)
    );
    return instance;
  }

  /**
   * Check all the properties and set default protobuf values if necessary
   * @param _instance message instance
   */
  static refineValues(_instance: ResetPasswordResponse) {
    _instance.ok = _instance.ok || false;
    _instance.message = _instance.message || '';
  }

  /**
   * Deserializes / reads binary message into message instance using provided binary reader
   * @param _instance message instance
   * @param _reader binary reader instance
   */
  static deserializeBinaryFromReader(
    _instance: ResetPasswordResponse,
    _reader: BinaryReader
  ) {
    while (_reader.nextField()) {
      if (_reader.isEndGroup()) break;

      switch (_reader.getFieldNumber()) {
        case 1:
          _instance.ok = _reader.readBool();
          break;
        case 2:
          _instance.message = _reader.readString();
          break;
        default:
          _reader.skipField();
      }
    }

    ResetPasswordResponse.refineValues(_instance);
  }

  /**
   * Serializes a message to binary format using provided binary reader
   * @param _instance message instance
   * @param _writer binary writer instance
   */
  static serializeBinaryToWriter(
    _instance: ResetPasswordResponse,
    _writer: BinaryWriter
  ) {
    if (_instance.ok) {
      _writer.writeBool(1, _instance.ok);
    }
    if (_instance.message) {
      _writer.writeString(2, _instance.message);
    }
  }

  private _ok?: boolean;
  private _message?: string;

  /**
   * Message constructor. Initializes the properties and applies default Protobuf values if necessary
   * @param _value initial values object or instance of ResetPasswordResponse to deeply clone from
   */
  constructor(_value?: RecursivePartial<ResetPasswordResponse.AsObject>) {
    _value = _value || {};
    this.ok = _value.ok;
    this.message = _value.message;
    ResetPasswordResponse.refineValues(this);
  }
  get ok(): boolean | undefined {
    return this._ok;
  }
  set ok(value: boolean | undefined) {
    this._ok = value;
  }
  get message(): string | undefined {
    return this._message;
  }
  set message(value: string | undefined) {
    this._message = value;
  }

  /**
   * Serialize message to binary data
   * @param instance message instance
   */
  serializeBinary() {
    const writer = new BinaryWriter();
    ResetPasswordResponse.serializeBinaryToWriter(this, writer);
    return writer.getResultBuffer();
  }

  /**
   * Cast message to standard JavaScript object (all non-primitive values are deeply cloned)
   */
  toObject(): ResetPasswordResponse.AsObject {
    return {
      ok: this.ok,
      message: this.message
    };
  }

  /**
   * Convenience method to support JSON.stringify(message), replicates the structure of toObject()
   */
  toJSON() {
    return this.toObject();
  }

  /**
   * Cast message to JSON using protobuf JSON notation: https://developers.google.com/protocol-buffers/docs/proto3#json
   * Attention: output differs from toObject() e.g. enums are represented as names and not as numbers, Timestamp is an ISO Date string format etc.
   * If the message itself or some of descendant messages is google.protobuf.Any, you MUST provide a message pool as options. If not, the messagePool is not required
   */
  toProtobufJSON(
    // @ts-ignore
    options?: ToProtobufJSONOptions
  ): ResetPasswordResponse.AsProtobufJSON {
    return {
      ok: this.ok,
      message: this.message
    };
  }
}
export module ResetPasswordResponse {
  /**
   * Standard JavaScript object representation for ResetPasswordResponse
   */
  export interface AsObject {
    ok?: boolean;
    message?: string;
  }

  /**
   * Protobuf JSON representation for ResetPasswordResponse
   */
  export interface AsProtobufJSON {
    ok?: boolean;
    message?: string;
  }
}

/**
 * Message implementation for modulebms.customeradmin.Apartment
 */
export class Apartment implements GrpcMessage {
  static id = 'modulebms.customeradmin.Apartment';

  /**
   * Deserialize binary data to message
   * @param instance message instance
   */
  static deserializeBinary(bytes: ByteSource) {
    const instance = new Apartment();
    Apartment.deserializeBinaryFromReader(instance, new BinaryReader(bytes));
    return instance;
  }

  /**
   * Check all the properties and set default protobuf values if necessary
   * @param _instance message instance
   */
  static refineValues(_instance: Apartment) {
    _instance.id = _instance.id || '';
    _instance.externalId = _instance.externalId || '';
    _instance.address = _instance.address || '';
    _instance.apartmentNo = _instance.apartmentNo || '';
    _instance.customerUsers = _instance.customerUsers || [];
  }

  /**
   * Deserializes / reads binary message into message instance using provided binary reader
   * @param _instance message instance
   * @param _reader binary reader instance
   */
  static deserializeBinaryFromReader(
    _instance: Apartment,
    _reader: BinaryReader
  ) {
    while (_reader.nextField()) {
      if (_reader.isEndGroup()) break;

      switch (_reader.getFieldNumber()) {
        case 1:
          _instance.id = _reader.readString();
          break;
        case 2:
          _instance.externalId = _reader.readString();
          break;
        case 3:
          _instance.address = _reader.readString();
          break;
        case 4:
          _instance.apartmentNo = _reader.readString();
          break;
        case 5:
          const messageInitializer5 = new BmsCustomerUser();
          _reader.readMessage(
            messageInitializer5,
            BmsCustomerUser.deserializeBinaryFromReader
          );
          (_instance.customerUsers = _instance.customerUsers || []).push(
            messageInitializer5
          );
          break;
        default:
          _reader.skipField();
      }
    }

    Apartment.refineValues(_instance);
  }

  /**
   * Serializes a message to binary format using provided binary reader
   * @param _instance message instance
   * @param _writer binary writer instance
   */
  static serializeBinaryToWriter(_instance: Apartment, _writer: BinaryWriter) {
    if (_instance.id) {
      _writer.writeString(1, _instance.id);
    }
    if (_instance.externalId) {
      _writer.writeString(2, _instance.externalId);
    }
    if (_instance.address) {
      _writer.writeString(3, _instance.address);
    }
    if (_instance.apartmentNo) {
      _writer.writeString(4, _instance.apartmentNo);
    }
    if (_instance.customerUsers && _instance.customerUsers.length) {
      _writer.writeRepeatedMessage(
        5,
        _instance.customerUsers as any,
        BmsCustomerUser.serializeBinaryToWriter
      );
    }
  }

  private _id?: string;
  private _externalId?: string;
  private _address?: string;
  private _apartmentNo?: string;
  private _customerUsers?: BmsCustomerUser[];

  /**
   * Message constructor. Initializes the properties and applies default Protobuf values if necessary
   * @param _value initial values object or instance of Apartment to deeply clone from
   */
  constructor(_value?: RecursivePartial<Apartment.AsObject>) {
    _value = _value || {};
    this.id = _value.id;
    this.externalId = _value.externalId;
    this.address = _value.address;
    this.apartmentNo = _value.apartmentNo;
    this.customerUsers = (_value.customerUsers || []).map(
      m => new BmsCustomerUser(m)
    );
    Apartment.refineValues(this);
  }
  get id(): string | undefined {
    return this._id;
  }
  set id(value: string | undefined) {
    this._id = value;
  }
  get externalId(): string | undefined {
    return this._externalId;
  }
  set externalId(value: string | undefined) {
    this._externalId = value;
  }
  get address(): string | undefined {
    return this._address;
  }
  set address(value: string | undefined) {
    this._address = value;
  }
  get apartmentNo(): string | undefined {
    return this._apartmentNo;
  }
  set apartmentNo(value: string | undefined) {
    this._apartmentNo = value;
  }
  get customerUsers(): BmsCustomerUser[] | undefined {
    return this._customerUsers;
  }
  set customerUsers(value: BmsCustomerUser[] | undefined) {
    this._customerUsers = value;
  }

  /**
   * Serialize message to binary data
   * @param instance message instance
   */
  serializeBinary() {
    const writer = new BinaryWriter();
    Apartment.serializeBinaryToWriter(this, writer);
    return writer.getResultBuffer();
  }

  /**
   * Cast message to standard JavaScript object (all non-primitive values are deeply cloned)
   */
  toObject(): Apartment.AsObject {
    return {
      id: this.id,
      externalId: this.externalId,
      address: this.address,
      apartmentNo: this.apartmentNo,
      customerUsers: (this.customerUsers || []).map(m => m.toObject())
    };
  }

  /**
   * Convenience method to support JSON.stringify(message), replicates the structure of toObject()
   */
  toJSON() {
    return this.toObject();
  }

  /**
   * Cast message to JSON using protobuf JSON notation: https://developers.google.com/protocol-buffers/docs/proto3#json
   * Attention: output differs from toObject() e.g. enums are represented as names and not as numbers, Timestamp is an ISO Date string format etc.
   * If the message itself or some of descendant messages is google.protobuf.Any, you MUST provide a message pool as options. If not, the messagePool is not required
   */
  toProtobufJSON(
    // @ts-ignore
    options?: ToProtobufJSONOptions
  ): Apartment.AsProtobufJSON {
    return {
      id: this.id,
      externalId: this.externalId,
      address: this.address,
      apartmentNo: this.apartmentNo,
      customerUsers: (this.customerUsers || []).map(m =>
        m.toProtobufJSON(options)
      )
    };
  }
}
export module Apartment {
  /**
   * Standard JavaScript object representation for Apartment
   */
  export interface AsObject {
    id?: string;
    externalId?: string;
    address?: string;
    apartmentNo?: string;
    customerUsers?: BmsCustomerUser.AsObject[];
  }

  /**
   * Protobuf JSON representation for Apartment
   */
  export interface AsProtobufJSON {
    id?: string;
    externalId?: string;
    address?: string;
    apartmentNo?: string;
    customerUsers?: BmsCustomerUser.AsProtobufJSON[] | null;
  }
}

/**
 * Message implementation for modulebms.customeradmin.BmsCustomerUser
 */
export class BmsCustomerUser implements GrpcMessage {
  static id = 'modulebms.customeradmin.BmsCustomerUser';

  /**
   * Deserialize binary data to message
   * @param instance message instance
   */
  static deserializeBinary(bytes: ByteSource) {
    const instance = new BmsCustomerUser();
    BmsCustomerUser.deserializeBinaryFromReader(
      instance,
      new BinaryReader(bytes)
    );
    return instance;
  }

  /**
   * Check all the properties and set default protobuf values if necessary
   * @param _instance message instance
   */
  static refineValues(_instance: BmsCustomerUser) {
    _instance.id = _instance.id || '';
    _instance.firstname = _instance.firstname || '';
    _instance.surname = _instance.surname || '';
    _instance.email = _instance.email || '';
    _instance.phone = _instance.phone || '';
    _instance.gdprConsent = _instance.gdprConsent || false;
    _instance.activeFrom = _instance.activeFrom || undefined;
    _instance.isAdmin = _instance.isAdmin || false;
    _instance.isExternalUser = _instance.isExternalUser || false;
  }

  /**
   * Deserializes / reads binary message into message instance using provided binary reader
   * @param _instance message instance
   * @param _reader binary reader instance
   */
  static deserializeBinaryFromReader(
    _instance: BmsCustomerUser,
    _reader: BinaryReader
  ) {
    while (_reader.nextField()) {
      if (_reader.isEndGroup()) break;

      switch (_reader.getFieldNumber()) {
        case 1:
          _instance.id = _reader.readString();
          break;
        case 2:
          _instance.firstname = _reader.readString();
          break;
        case 3:
          _instance.surname = _reader.readString();
          break;
        case 4:
          _instance.email = _reader.readString();
          break;
        case 5:
          _instance.phone = _reader.readString();
          break;
        case 6:
          _instance.gdprConsent = _reader.readBool();
          break;
        case 7:
          _instance.activeFrom = new googleProtobuf000.Timestamp();
          _reader.readMessage(
            _instance.activeFrom,
            googleProtobuf000.Timestamp.deserializeBinaryFromReader
          );
          break;
        case 8:
          _instance.isAdmin = _reader.readBool();
          break;
        case 9:
          _instance.isExternalUser = _reader.readBool();
          break;
        default:
          _reader.skipField();
      }
    }

    BmsCustomerUser.refineValues(_instance);
  }

  /**
   * Serializes a message to binary format using provided binary reader
   * @param _instance message instance
   * @param _writer binary writer instance
   */
  static serializeBinaryToWriter(
    _instance: BmsCustomerUser,
    _writer: BinaryWriter
  ) {
    if (_instance.id) {
      _writer.writeString(1, _instance.id);
    }
    if (_instance.firstname) {
      _writer.writeString(2, _instance.firstname);
    }
    if (_instance.surname) {
      _writer.writeString(3, _instance.surname);
    }
    if (_instance.email) {
      _writer.writeString(4, _instance.email);
    }
    if (_instance.phone) {
      _writer.writeString(5, _instance.phone);
    }
    if (_instance.gdprConsent) {
      _writer.writeBool(6, _instance.gdprConsent);
    }
    if (_instance.activeFrom) {
      _writer.writeMessage(
        7,
        _instance.activeFrom as any,
        googleProtobuf000.Timestamp.serializeBinaryToWriter
      );
    }
    if (_instance.isAdmin) {
      _writer.writeBool(8, _instance.isAdmin);
    }
    if (_instance.isExternalUser) {
      _writer.writeBool(9, _instance.isExternalUser);
    }
  }

  private _id?: string;
  private _firstname?: string;
  private _surname?: string;
  private _email?: string;
  private _phone?: string;
  private _gdprConsent?: boolean;
  private _activeFrom?: googleProtobuf000.Timestamp;
  private _isAdmin?: boolean;
  private _isExternalUser?: boolean;

  /**
   * Message constructor. Initializes the properties and applies default Protobuf values if necessary
   * @param _value initial values object or instance of BmsCustomerUser to deeply clone from
   */
  constructor(_value?: RecursivePartial<BmsCustomerUser.AsObject>) {
    _value = _value || {};
    this.id = _value.id;
    this.firstname = _value.firstname;
    this.surname = _value.surname;
    this.email = _value.email;
    this.phone = _value.phone;
    this.gdprConsent = _value.gdprConsent;
    this.activeFrom = _value.activeFrom
      ? new googleProtobuf000.Timestamp(_value.activeFrom)
      : undefined;
    this.isAdmin = _value.isAdmin;
    this.isExternalUser = _value.isExternalUser;
    BmsCustomerUser.refineValues(this);
  }
  get id(): string | undefined {
    return this._id;
  }
  set id(value: string | undefined) {
    this._id = value;
  }
  get firstname(): string | undefined {
    return this._firstname;
  }
  set firstname(value: string | undefined) {
    this._firstname = value;
  }
  get surname(): string | undefined {
    return this._surname;
  }
  set surname(value: string | undefined) {
    this._surname = value;
  }
  get email(): string | undefined {
    return this._email;
  }
  set email(value: string | undefined) {
    this._email = value;
  }
  get phone(): string | undefined {
    return this._phone;
  }
  set phone(value: string | undefined) {
    this._phone = value;
  }
  get gdprConsent(): boolean | undefined {
    return this._gdprConsent;
  }
  set gdprConsent(value: boolean | undefined) {
    this._gdprConsent = value;
  }
  get activeFrom(): googleProtobuf000.Timestamp | undefined {
    return this._activeFrom;
  }
  set activeFrom(value: googleProtobuf000.Timestamp | undefined) {
    this._activeFrom = value;
  }
  get isAdmin(): boolean | undefined {
    return this._isAdmin;
  }
  set isAdmin(value: boolean | undefined) {
    this._isAdmin = value;
  }
  get isExternalUser(): boolean | undefined {
    return this._isExternalUser;
  }
  set isExternalUser(value: boolean | undefined) {
    this._isExternalUser = value;
  }

  /**
   * Serialize message to binary data
   * @param instance message instance
   */
  serializeBinary() {
    const writer = new BinaryWriter();
    BmsCustomerUser.serializeBinaryToWriter(this, writer);
    return writer.getResultBuffer();
  }

  /**
   * Cast message to standard JavaScript object (all non-primitive values are deeply cloned)
   */
  toObject(): BmsCustomerUser.AsObject {
    return {
      id: this.id,
      firstname: this.firstname,
      surname: this.surname,
      email: this.email,
      phone: this.phone,
      gdprConsent: this.gdprConsent,
      activeFrom: this.activeFrom ? this.activeFrom.toObject() : undefined,
      isAdmin: this.isAdmin,
      isExternalUser: this.isExternalUser
    };
  }

  /**
   * Convenience method to support JSON.stringify(message), replicates the structure of toObject()
   */
  toJSON() {
    return this.toObject();
  }

  /**
   * Cast message to JSON using protobuf JSON notation: https://developers.google.com/protocol-buffers/docs/proto3#json
   * Attention: output differs from toObject() e.g. enums are represented as names and not as numbers, Timestamp is an ISO Date string format etc.
   * If the message itself or some of descendant messages is google.protobuf.Any, you MUST provide a message pool as options. If not, the messagePool is not required
   */
  toProtobufJSON(
    // @ts-ignore
    options?: ToProtobufJSONOptions
  ): BmsCustomerUser.AsProtobufJSON {
    return {
      id: this.id,
      firstname: this.firstname,
      surname: this.surname,
      email: this.email,
      phone: this.phone,
      gdprConsent: this.gdprConsent,
      activeFrom: this.activeFrom
        ? this.activeFrom.toProtobufJSON(options)
        : null,
      isAdmin: this.isAdmin,
      isExternalUser: this.isExternalUser
    };
  }
}
export module BmsCustomerUser {
  /**
   * Standard JavaScript object representation for BmsCustomerUser
   */
  export interface AsObject {
    id?: string;
    firstname?: string;
    surname?: string;
    email?: string;
    phone?: string;
    gdprConsent?: boolean;
    activeFrom?: googleProtobuf000.Timestamp.AsObject;
    isAdmin?: boolean;
    isExternalUser?: boolean;
  }

  /**
   * Protobuf JSON representation for BmsCustomerUser
   */
  export interface AsProtobufJSON {
    id?: string;
    firstname?: string;
    surname?: string;
    email?: string;
    phone?: string;
    gdprConsent?: boolean;
    activeFrom?: googleProtobuf000.Timestamp.AsProtobufJSON | null;
    isAdmin?: boolean;
    isExternalUser?: boolean;
  }
}

/**
 * Message implementation for modulebms.customeradmin.ExportApartmentsAndCustomerUsersRequest
 */
export class ExportApartmentsAndCustomerUsersRequest implements GrpcMessage {
  static id = 'modulebms.customeradmin.ExportApartmentsAndCustomerUsersRequest';

  /**
   * Deserialize binary data to message
   * @param instance message instance
   */
  static deserializeBinary(bytes: ByteSource) {
    const instance = new ExportApartmentsAndCustomerUsersRequest();
    ExportApartmentsAndCustomerUsersRequest.deserializeBinaryFromReader(
      instance,
      new BinaryReader(bytes)
    );
    return instance;
  }

  /**
   * Check all the properties and set default protobuf values if necessary
   * @param _instance message instance
   */
  static refineValues(_instance: ExportApartmentsAndCustomerUsersRequest) {
    _instance.customerId = _instance.customerId || '';
    _instance.chunkSize = _instance.chunkSize || 0;
    _instance.headers = _instance.headers || [];
  }

  /**
   * Deserializes / reads binary message into message instance using provided binary reader
   * @param _instance message instance
   * @param _reader binary reader instance
   */
  static deserializeBinaryFromReader(
    _instance: ExportApartmentsAndCustomerUsersRequest,
    _reader: BinaryReader
  ) {
    while (_reader.nextField()) {
      if (_reader.isEndGroup()) break;

      switch (_reader.getFieldNumber()) {
        case 1:
          _instance.customerId = _reader.readString();
          break;
        case 2:
          _instance.chunkSize = _reader.readInt32();
          break;
        case 3:
          (_instance.headers = _instance.headers || []).push(
            _reader.readString()
          );
          break;
        default:
          _reader.skipField();
      }
    }

    ExportApartmentsAndCustomerUsersRequest.refineValues(_instance);
  }

  /**
   * Serializes a message to binary format using provided binary reader
   * @param _instance message instance
   * @param _writer binary writer instance
   */
  static serializeBinaryToWriter(
    _instance: ExportApartmentsAndCustomerUsersRequest,
    _writer: BinaryWriter
  ) {
    if (_instance.customerId) {
      _writer.writeString(1, _instance.customerId);
    }
    if (_instance.chunkSize) {
      _writer.writeInt32(2, _instance.chunkSize);
    }
    if (_instance.headers && _instance.headers.length) {
      _writer.writeRepeatedString(3, _instance.headers);
    }
  }

  private _customerId?: string;
  private _chunkSize?: number;
  private _headers?: string[];

  /**
   * Message constructor. Initializes the properties and applies default Protobuf values if necessary
   * @param _value initial values object or instance of ExportApartmentsAndCustomerUsersRequest to deeply clone from
   */
  constructor(
    _value?: RecursivePartial<ExportApartmentsAndCustomerUsersRequest.AsObject>
  ) {
    _value = _value || {};
    this.customerId = _value.customerId;
    this.chunkSize = _value.chunkSize;
    this.headers = (_value.headers || []).slice();
    ExportApartmentsAndCustomerUsersRequest.refineValues(this);
  }
  get customerId(): string | undefined {
    return this._customerId;
  }
  set customerId(value: string | undefined) {
    this._customerId = value;
  }
  get chunkSize(): number | undefined {
    return this._chunkSize;
  }
  set chunkSize(value: number | undefined) {
    this._chunkSize = value;
  }
  get headers(): string[] | undefined {
    return this._headers;
  }
  set headers(value: string[] | undefined) {
    this._headers = value;
  }

  /**
   * Serialize message to binary data
   * @param instance message instance
   */
  serializeBinary() {
    const writer = new BinaryWriter();
    ExportApartmentsAndCustomerUsersRequest.serializeBinaryToWriter(
      this,
      writer
    );
    return writer.getResultBuffer();
  }

  /**
   * Cast message to standard JavaScript object (all non-primitive values are deeply cloned)
   */
  toObject(): ExportApartmentsAndCustomerUsersRequest.AsObject {
    return {
      customerId: this.customerId,
      chunkSize: this.chunkSize,
      headers: (this.headers || []).slice()
    };
  }

  /**
   * Convenience method to support JSON.stringify(message), replicates the structure of toObject()
   */
  toJSON() {
    return this.toObject();
  }

  /**
   * Cast message to JSON using protobuf JSON notation: https://developers.google.com/protocol-buffers/docs/proto3#json
   * Attention: output differs from toObject() e.g. enums are represented as names and not as numbers, Timestamp is an ISO Date string format etc.
   * If the message itself or some of descendant messages is google.protobuf.Any, you MUST provide a message pool as options. If not, the messagePool is not required
   */
  toProtobufJSON(
    // @ts-ignore
    options?: ToProtobufJSONOptions
  ): ExportApartmentsAndCustomerUsersRequest.AsProtobufJSON {
    return {
      customerId: this.customerId,
      chunkSize: this.chunkSize,
      headers: (this.headers || []).slice()
    };
  }
}
export module ExportApartmentsAndCustomerUsersRequest {
  /**
   * Standard JavaScript object representation for ExportApartmentsAndCustomerUsersRequest
   */
  export interface AsObject {
    customerId?: string;
    chunkSize?: number;
    headers?: string[];
  }

  /**
   * Protobuf JSON representation for ExportApartmentsAndCustomerUsersRequest
   */
  export interface AsProtobufJSON {
    customerId?: string;
    chunkSize?: number;
    headers?: string[];
  }
}

/**
 * Message implementation for modulebms.customeradmin.CustomerAdminDataChunk
 */
export class CustomerAdminDataChunk implements GrpcMessage {
  static id = 'modulebms.customeradmin.CustomerAdminDataChunk';

  /**
   * Deserialize binary data to message
   * @param instance message instance
   */
  static deserializeBinary(bytes: ByteSource) {
    const instance = new CustomerAdminDataChunk();
    CustomerAdminDataChunk.deserializeBinaryFromReader(
      instance,
      new BinaryReader(bytes)
    );
    return instance;
  }

  /**
   * Check all the properties and set default protobuf values if necessary
   * @param _instance message instance
   */
  static refineValues(_instance: CustomerAdminDataChunk) {
    _instance.data = _instance.data || new Uint8Array();
  }

  /**
   * Deserializes / reads binary message into message instance using provided binary reader
   * @param _instance message instance
   * @param _reader binary reader instance
   */
  static deserializeBinaryFromReader(
    _instance: CustomerAdminDataChunk,
    _reader: BinaryReader
  ) {
    while (_reader.nextField()) {
      if (_reader.isEndGroup()) break;

      switch (_reader.getFieldNumber()) {
        case 1:
          _instance.data = _reader.readBytes();
          break;
        default:
          _reader.skipField();
      }
    }

    CustomerAdminDataChunk.refineValues(_instance);
  }

  /**
   * Serializes a message to binary format using provided binary reader
   * @param _instance message instance
   * @param _writer binary writer instance
   */
  static serializeBinaryToWriter(
    _instance: CustomerAdminDataChunk,
    _writer: BinaryWriter
  ) {
    if (_instance.data && _instance.data.length) {
      _writer.writeBytes(1, _instance.data);
    }
  }

  private _data?: Uint8Array;

  /**
   * Message constructor. Initializes the properties and applies default Protobuf values if necessary
   * @param _value initial values object or instance of CustomerAdminDataChunk to deeply clone from
   */
  constructor(_value?: RecursivePartial<CustomerAdminDataChunk.AsObject>) {
    _value = _value || {};
    this.data = _value.data;
    CustomerAdminDataChunk.refineValues(this);
  }
  get data(): Uint8Array | undefined {
    return this._data;
  }
  set data(value: Uint8Array | undefined) {
    this._data = value;
  }

  /**
   * Serialize message to binary data
   * @param instance message instance
   */
  serializeBinary() {
    const writer = new BinaryWriter();
    CustomerAdminDataChunk.serializeBinaryToWriter(this, writer);
    return writer.getResultBuffer();
  }

  /**
   * Cast message to standard JavaScript object (all non-primitive values are deeply cloned)
   */
  toObject(): CustomerAdminDataChunk.AsObject {
    return {
      data: this.data ? this.data.subarray(0) : new Uint8Array()
    };
  }

  /**
   * Convenience method to support JSON.stringify(message), replicates the structure of toObject()
   */
  toJSON() {
    return this.toObject();
  }

  /**
   * Cast message to JSON using protobuf JSON notation: https://developers.google.com/protocol-buffers/docs/proto3#json
   * Attention: output differs from toObject() e.g. enums are represented as names and not as numbers, Timestamp is an ISO Date string format etc.
   * If the message itself or some of descendant messages is google.protobuf.Any, you MUST provide a message pool as options. If not, the messagePool is not required
   */
  toProtobufJSON(
    // @ts-ignore
    options?: ToProtobufJSONOptions
  ): CustomerAdminDataChunk.AsProtobufJSON {
    return {
      data: this.data ? uint8ArrayToBase64(this.data) : ''
    };
  }
}
export module CustomerAdminDataChunk {
  /**
   * Standard JavaScript object representation for CustomerAdminDataChunk
   */
  export interface AsObject {
    data?: Uint8Array;
  }

  /**
   * Protobuf JSON representation for CustomerAdminDataChunk
   */
  export interface AsProtobufJSON {
    data?: string;
  }
}

/**
 * Message implementation for modulebms.customeradmin.BillingTariffchangeRequest
 */
export class BillingTariffchangeRequest implements GrpcMessage {
  static id = 'modulebms.customeradmin.BillingTariffchangeRequest';

  /**
   * Deserialize binary data to message
   * @param instance message instance
   */
  static deserializeBinary(bytes: ByteSource) {
    const instance = new BillingTariffchangeRequest();
    BillingTariffchangeRequest.deserializeBinaryFromReader(
      instance,
      new BinaryReader(bytes)
    );
    return instance;
  }

  /**
   * Check all the properties and set default protobuf values if necessary
   * @param _instance message instance
   */
  static refineValues(_instance: BillingTariffchangeRequest) {
    _instance.id = _instance.id || '0';
    _instance.customerId = _instance.customerId || '';
    _instance.userId = _instance.userId || '';
    _instance.activeFrom = _instance.activeFrom || undefined;
    _instance.value = _instance.value || 0;
    _instance.vatrate = _instance.vatrate || 0;
    _instance.includeVat = _instance.includeVat || false;
    _instance.unit = _instance.unit || '';
    _instance.currency = _instance.currency || '';
    _instance.meterType = _instance.meterType || '';
    _instance.tariffGroup = _instance.tariffGroup || '';
    _instance.requestType = _instance.requestType || 0;
    _instance.status = _instance.status || 0;
    _instance.createdAt = _instance.createdAt || undefined;
    _instance.modifiedAt = _instance.modifiedAt || undefined;
    _instance.deletedAt = _instance.deletedAt || undefined;
    _instance.deleted = _instance.deleted || false;
  }

  /**
   * Deserializes / reads binary message into message instance using provided binary reader
   * @param _instance message instance
   * @param _reader binary reader instance
   */
  static deserializeBinaryFromReader(
    _instance: BillingTariffchangeRequest,
    _reader: BinaryReader
  ) {
    while (_reader.nextField()) {
      if (_reader.isEndGroup()) break;

      switch (_reader.getFieldNumber()) {
        case 1:
          _instance.id = _reader.readInt64String();
          break;
        case 2:
          _instance.customerId = _reader.readString();
          break;
        case 3:
          _instance.userId = _reader.readString();
          break;
        case 4:
          _instance.activeFrom = new googleProtobuf000.Timestamp();
          _reader.readMessage(
            _instance.activeFrom,
            googleProtobuf000.Timestamp.deserializeBinaryFromReader
          );
          break;
        case 5:
          _instance.value = _reader.readDouble();
          break;
        case 6:
          _instance.vatrate = _reader.readDouble();
          break;
        case 7:
          _instance.includeVat = _reader.readBool();
          break;
        case 8:
          _instance.unit = _reader.readString();
          break;
        case 9:
          _instance.currency = _reader.readString();
          break;
        case 10:
          _instance.meterType = _reader.readString();
          break;
        case 11:
          _instance.tariffGroup = _reader.readString();
          break;
        case 12:
          _instance.requestType = _reader.readInt32();
          break;
        case 13:
          _instance.status = _reader.readInt32();
          break;
        case 14:
          _instance.createdAt = new googleProtobuf000.Timestamp();
          _reader.readMessage(
            _instance.createdAt,
            googleProtobuf000.Timestamp.deserializeBinaryFromReader
          );
          break;
        case 15:
          _instance.modifiedAt = new googleProtobuf000.Timestamp();
          _reader.readMessage(
            _instance.modifiedAt,
            googleProtobuf000.Timestamp.deserializeBinaryFromReader
          );
          break;
        case 16:
          _instance.deletedAt = new googleProtobuf000.Timestamp();
          _reader.readMessage(
            _instance.deletedAt,
            googleProtobuf000.Timestamp.deserializeBinaryFromReader
          );
          break;
        case 17:
          _instance.deleted = _reader.readBool();
          break;
        default:
          _reader.skipField();
      }
    }

    BillingTariffchangeRequest.refineValues(_instance);
  }

  /**
   * Serializes a message to binary format using provided binary reader
   * @param _instance message instance
   * @param _writer binary writer instance
   */
  static serializeBinaryToWriter(
    _instance: BillingTariffchangeRequest,
    _writer: BinaryWriter
  ) {
    if (_instance.id) {
      _writer.writeInt64String(1, _instance.id);
    }
    if (_instance.customerId) {
      _writer.writeString(2, _instance.customerId);
    }
    if (_instance.userId) {
      _writer.writeString(3, _instance.userId);
    }
    if (_instance.activeFrom) {
      _writer.writeMessage(
        4,
        _instance.activeFrom as any,
        googleProtobuf000.Timestamp.serializeBinaryToWriter
      );
    }
    if (_instance.value) {
      _writer.writeDouble(5, _instance.value);
    }
    if (_instance.vatrate) {
      _writer.writeDouble(6, _instance.vatrate);
    }
    if (_instance.includeVat) {
      _writer.writeBool(7, _instance.includeVat);
    }
    if (_instance.unit) {
      _writer.writeString(8, _instance.unit);
    }
    if (_instance.currency) {
      _writer.writeString(9, _instance.currency);
    }
    if (_instance.meterType) {
      _writer.writeString(10, _instance.meterType);
    }
    if (_instance.tariffGroup) {
      _writer.writeString(11, _instance.tariffGroup);
    }
    if (_instance.requestType) {
      _writer.writeInt32(12, _instance.requestType);
    }
    if (_instance.status) {
      _writer.writeInt32(13, _instance.status);
    }
    if (_instance.createdAt) {
      _writer.writeMessage(
        14,
        _instance.createdAt as any,
        googleProtobuf000.Timestamp.serializeBinaryToWriter
      );
    }
    if (_instance.modifiedAt) {
      _writer.writeMessage(
        15,
        _instance.modifiedAt as any,
        googleProtobuf000.Timestamp.serializeBinaryToWriter
      );
    }
    if (_instance.deletedAt) {
      _writer.writeMessage(
        16,
        _instance.deletedAt as any,
        googleProtobuf000.Timestamp.serializeBinaryToWriter
      );
    }
    if (_instance.deleted) {
      _writer.writeBool(17, _instance.deleted);
    }
  }

  private _id?: string;
  private _customerId?: string;
  private _userId?: string;
  private _activeFrom?: googleProtobuf000.Timestamp;
  private _value?: number;
  private _vatrate?: number;
  private _includeVat?: boolean;
  private _unit?: string;
  private _currency?: string;
  private _meterType?: string;
  private _tariffGroup?: string;
  private _requestType?: number;
  private _status?: number;
  private _createdAt?: googleProtobuf000.Timestamp;
  private _modifiedAt?: googleProtobuf000.Timestamp;
  private _deletedAt?: googleProtobuf000.Timestamp;
  private _deleted?: boolean;

  /**
   * Message constructor. Initializes the properties and applies default Protobuf values if necessary
   * @param _value initial values object or instance of BillingTariffchangeRequest to deeply clone from
   */
  constructor(_value?: RecursivePartial<BillingTariffchangeRequest.AsObject>) {
    _value = _value || {};
    this.id = _value.id;
    this.customerId = _value.customerId;
    this.userId = _value.userId;
    this.activeFrom = _value.activeFrom
      ? new googleProtobuf000.Timestamp(_value.activeFrom)
      : undefined;
    this.value = _value.value;
    this.vatrate = _value.vatrate;
    this.includeVat = _value.includeVat;
    this.unit = _value.unit;
    this.currency = _value.currency;
    this.meterType = _value.meterType;
    this.tariffGroup = _value.tariffGroup;
    this.requestType = _value.requestType;
    this.status = _value.status;
    this.createdAt = _value.createdAt
      ? new googleProtobuf000.Timestamp(_value.createdAt)
      : undefined;
    this.modifiedAt = _value.modifiedAt
      ? new googleProtobuf000.Timestamp(_value.modifiedAt)
      : undefined;
    this.deletedAt = _value.deletedAt
      ? new googleProtobuf000.Timestamp(_value.deletedAt)
      : undefined;
    this.deleted = _value.deleted;
    BillingTariffchangeRequest.refineValues(this);
  }
  get id(): string | undefined {
    return this._id;
  }
  set id(value: string | undefined) {
    this._id = value;
  }
  get customerId(): string | undefined {
    return this._customerId;
  }
  set customerId(value: string | undefined) {
    this._customerId = value;
  }
  get userId(): string | undefined {
    return this._userId;
  }
  set userId(value: string | undefined) {
    this._userId = value;
  }
  get activeFrom(): googleProtobuf000.Timestamp | undefined {
    return this._activeFrom;
  }
  set activeFrom(value: googleProtobuf000.Timestamp | undefined) {
    this._activeFrom = value;
  }
  get value(): number | undefined {
    return this._value;
  }
  set value(value: number | undefined) {
    this._value = value;
  }
  get vatrate(): number | undefined {
    return this._vatrate;
  }
  set vatrate(value: number | undefined) {
    this._vatrate = value;
  }
  get includeVat(): boolean | undefined {
    return this._includeVat;
  }
  set includeVat(value: boolean | undefined) {
    this._includeVat = value;
  }
  get unit(): string | undefined {
    return this._unit;
  }
  set unit(value: string | undefined) {
    this._unit = value;
  }
  get currency(): string | undefined {
    return this._currency;
  }
  set currency(value: string | undefined) {
    this._currency = value;
  }
  get meterType(): string | undefined {
    return this._meterType;
  }
  set meterType(value: string | undefined) {
    this._meterType = value;
  }
  get tariffGroup(): string | undefined {
    return this._tariffGroup;
  }
  set tariffGroup(value: string | undefined) {
    this._tariffGroup = value;
  }
  get requestType(): number | undefined {
    return this._requestType;
  }
  set requestType(value: number | undefined) {
    this._requestType = value;
  }
  get status(): number | undefined {
    return this._status;
  }
  set status(value: number | undefined) {
    this._status = value;
  }
  get createdAt(): googleProtobuf000.Timestamp | undefined {
    return this._createdAt;
  }
  set createdAt(value: googleProtobuf000.Timestamp | undefined) {
    this._createdAt = value;
  }
  get modifiedAt(): googleProtobuf000.Timestamp | undefined {
    return this._modifiedAt;
  }
  set modifiedAt(value: googleProtobuf000.Timestamp | undefined) {
    this._modifiedAt = value;
  }
  get deletedAt(): googleProtobuf000.Timestamp | undefined {
    return this._deletedAt;
  }
  set deletedAt(value: googleProtobuf000.Timestamp | undefined) {
    this._deletedAt = value;
  }
  get deleted(): boolean | undefined {
    return this._deleted;
  }
  set deleted(value: boolean | undefined) {
    this._deleted = value;
  }

  /**
   * Serialize message to binary data
   * @param instance message instance
   */
  serializeBinary() {
    const writer = new BinaryWriter();
    BillingTariffchangeRequest.serializeBinaryToWriter(this, writer);
    return writer.getResultBuffer();
  }

  /**
   * Cast message to standard JavaScript object (all non-primitive values are deeply cloned)
   */
  toObject(): BillingTariffchangeRequest.AsObject {
    return {
      id: this.id,
      customerId: this.customerId,
      userId: this.userId,
      activeFrom: this.activeFrom ? this.activeFrom.toObject() : undefined,
      value: this.value,
      vatrate: this.vatrate,
      includeVat: this.includeVat,
      unit: this.unit,
      currency: this.currency,
      meterType: this.meterType,
      tariffGroup: this.tariffGroup,
      requestType: this.requestType,
      status: this.status,
      createdAt: this.createdAt ? this.createdAt.toObject() : undefined,
      modifiedAt: this.modifiedAt ? this.modifiedAt.toObject() : undefined,
      deletedAt: this.deletedAt ? this.deletedAt.toObject() : undefined,
      deleted: this.deleted
    };
  }

  /**
   * Convenience method to support JSON.stringify(message), replicates the structure of toObject()
   */
  toJSON() {
    return this.toObject();
  }

  /**
   * Cast message to JSON using protobuf JSON notation: https://developers.google.com/protocol-buffers/docs/proto3#json
   * Attention: output differs from toObject() e.g. enums are represented as names and not as numbers, Timestamp is an ISO Date string format etc.
   * If the message itself or some of descendant messages is google.protobuf.Any, you MUST provide a message pool as options. If not, the messagePool is not required
   */
  toProtobufJSON(
    // @ts-ignore
    options?: ToProtobufJSONOptions
  ): BillingTariffchangeRequest.AsProtobufJSON {
    return {
      id: this.id,
      customerId: this.customerId,
      userId: this.userId,
      activeFrom: this.activeFrom
        ? this.activeFrom.toProtobufJSON(options)
        : null,
      value: this.value,
      vatrate: this.vatrate,
      includeVat: this.includeVat,
      unit: this.unit,
      currency: this.currency,
      meterType: this.meterType,
      tariffGroup: this.tariffGroup,
      requestType: this.requestType,
      status: this.status,
      createdAt: this.createdAt ? this.createdAt.toProtobufJSON(options) : null,
      modifiedAt: this.modifiedAt
        ? this.modifiedAt.toProtobufJSON(options)
        : null,
      deletedAt: this.deletedAt ? this.deletedAt.toProtobufJSON(options) : null,
      deleted: this.deleted
    };
  }
}
export module BillingTariffchangeRequest {
  /**
   * Standard JavaScript object representation for BillingTariffchangeRequest
   */
  export interface AsObject {
    id?: string;
    customerId?: string;
    userId?: string;
    activeFrom?: googleProtobuf000.Timestamp.AsObject;
    value?: number;
    vatrate?: number;
    includeVat?: boolean;
    unit?: string;
    currency?: string;
    meterType?: string;
    tariffGroup?: string;
    requestType?: number;
    status?: number;
    createdAt?: googleProtobuf000.Timestamp.AsObject;
    modifiedAt?: googleProtobuf000.Timestamp.AsObject;
    deletedAt?: googleProtobuf000.Timestamp.AsObject;
    deleted?: boolean;
  }

  /**
   * Protobuf JSON representation for BillingTariffchangeRequest
   */
  export interface AsProtobufJSON {
    id?: string;
    customerId?: string;
    userId?: string;
    activeFrom?: googleProtobuf000.Timestamp.AsProtobufJSON | null;
    value?: number;
    vatrate?: number;
    includeVat?: boolean;
    unit?: string;
    currency?: string;
    meterType?: string;
    tariffGroup?: string;
    requestType?: number;
    status?: number;
    createdAt?: googleProtobuf000.Timestamp.AsProtobufJSON | null;
    modifiedAt?: googleProtobuf000.Timestamp.AsProtobufJSON | null;
    deletedAt?: googleProtobuf000.Timestamp.AsProtobufJSON | null;
    deleted?: boolean;
  }
}

/**
 * Message implementation for modulebms.customeradmin.GetBillingTariffChangeRequestsRequest
 */
export class GetBillingTariffChangeRequestsRequest implements GrpcMessage {
  static id = 'modulebms.customeradmin.GetBillingTariffChangeRequestsRequest';

  /**
   * Deserialize binary data to message
   * @param instance message instance
   */
  static deserializeBinary(bytes: ByteSource) {
    const instance = new GetBillingTariffChangeRequestsRequest();
    GetBillingTariffChangeRequestsRequest.deserializeBinaryFromReader(
      instance,
      new BinaryReader(bytes)
    );
    return instance;
  }

  /**
   * Check all the properties and set default protobuf values if necessary
   * @param _instance message instance
   */
  static refineValues(_instance: GetBillingTariffChangeRequestsRequest) {
    _instance.customerId = _instance.customerId || '';
    _instance.from = _instance.from || undefined;
    _instance.to = _instance.to || undefined;
    _instance.meterType = _instance.meterType || '';
    _instance.tariffGroup = _instance.tariffGroup || '';
    _instance.includeHistory = _instance.includeHistory || false;
  }

  /**
   * Deserializes / reads binary message into message instance using provided binary reader
   * @param _instance message instance
   * @param _reader binary reader instance
   */
  static deserializeBinaryFromReader(
    _instance: GetBillingTariffChangeRequestsRequest,
    _reader: BinaryReader
  ) {
    while (_reader.nextField()) {
      if (_reader.isEndGroup()) break;

      switch (_reader.getFieldNumber()) {
        case 1:
          _instance.customerId = _reader.readString();
          break;
        case 2:
          _instance.from = new googleProtobuf000.Timestamp();
          _reader.readMessage(
            _instance.from,
            googleProtobuf000.Timestamp.deserializeBinaryFromReader
          );
          break;
        case 3:
          _instance.to = new googleProtobuf000.Timestamp();
          _reader.readMessage(
            _instance.to,
            googleProtobuf000.Timestamp.deserializeBinaryFromReader
          );
          break;
        case 4:
          _instance.meterType = _reader.readString();
          break;
        case 5:
          _instance.tariffGroup = _reader.readString();
          break;
        case 6:
          _instance.includeHistory = _reader.readBool();
          break;
        default:
          _reader.skipField();
      }
    }

    GetBillingTariffChangeRequestsRequest.refineValues(_instance);
  }

  /**
   * Serializes a message to binary format using provided binary reader
   * @param _instance message instance
   * @param _writer binary writer instance
   */
  static serializeBinaryToWriter(
    _instance: GetBillingTariffChangeRequestsRequest,
    _writer: BinaryWriter
  ) {
    if (_instance.customerId) {
      _writer.writeString(1, _instance.customerId);
    }
    if (_instance.from) {
      _writer.writeMessage(
        2,
        _instance.from as any,
        googleProtobuf000.Timestamp.serializeBinaryToWriter
      );
    }
    if (_instance.to) {
      _writer.writeMessage(
        3,
        _instance.to as any,
        googleProtobuf000.Timestamp.serializeBinaryToWriter
      );
    }
    if (_instance.meterType) {
      _writer.writeString(4, _instance.meterType);
    }
    if (_instance.tariffGroup) {
      _writer.writeString(5, _instance.tariffGroup);
    }
    if (_instance.includeHistory) {
      _writer.writeBool(6, _instance.includeHistory);
    }
  }

  private _customerId?: string;
  private _from?: googleProtobuf000.Timestamp;
  private _to?: googleProtobuf000.Timestamp;
  private _meterType?: string;
  private _tariffGroup?: string;
  private _includeHistory?: boolean;

  /**
   * Message constructor. Initializes the properties and applies default Protobuf values if necessary
   * @param _value initial values object or instance of GetBillingTariffChangeRequestsRequest to deeply clone from
   */
  constructor(
    _value?: RecursivePartial<GetBillingTariffChangeRequestsRequest.AsObject>
  ) {
    _value = _value || {};
    this.customerId = _value.customerId;
    this.from = _value.from
      ? new googleProtobuf000.Timestamp(_value.from)
      : undefined;
    this.to = _value.to
      ? new googleProtobuf000.Timestamp(_value.to)
      : undefined;
    this.meterType = _value.meterType;
    this.tariffGroup = _value.tariffGroup;
    this.includeHistory = _value.includeHistory;
    GetBillingTariffChangeRequestsRequest.refineValues(this);
  }
  get customerId(): string | undefined {
    return this._customerId;
  }
  set customerId(value: string | undefined) {
    this._customerId = value;
  }
  get from(): googleProtobuf000.Timestamp | undefined {
    return this._from;
  }
  set from(value: googleProtobuf000.Timestamp | undefined) {
    this._from = value;
  }
  get to(): googleProtobuf000.Timestamp | undefined {
    return this._to;
  }
  set to(value: googleProtobuf000.Timestamp | undefined) {
    this._to = value;
  }
  get meterType(): string | undefined {
    return this._meterType;
  }
  set meterType(value: string | undefined) {
    this._meterType = value;
  }
  get tariffGroup(): string | undefined {
    return this._tariffGroup;
  }
  set tariffGroup(value: string | undefined) {
    this._tariffGroup = value;
  }
  get includeHistory(): boolean | undefined {
    return this._includeHistory;
  }
  set includeHistory(value: boolean | undefined) {
    this._includeHistory = value;
  }

  /**
   * Serialize message to binary data
   * @param instance message instance
   */
  serializeBinary() {
    const writer = new BinaryWriter();
    GetBillingTariffChangeRequestsRequest.serializeBinaryToWriter(this, writer);
    return writer.getResultBuffer();
  }

  /**
   * Cast message to standard JavaScript object (all non-primitive values are deeply cloned)
   */
  toObject(): GetBillingTariffChangeRequestsRequest.AsObject {
    return {
      customerId: this.customerId,
      from: this.from ? this.from.toObject() : undefined,
      to: this.to ? this.to.toObject() : undefined,
      meterType: this.meterType,
      tariffGroup: this.tariffGroup,
      includeHistory: this.includeHistory
    };
  }

  /**
   * Convenience method to support JSON.stringify(message), replicates the structure of toObject()
   */
  toJSON() {
    return this.toObject();
  }

  /**
   * Cast message to JSON using protobuf JSON notation: https://developers.google.com/protocol-buffers/docs/proto3#json
   * Attention: output differs from toObject() e.g. enums are represented as names and not as numbers, Timestamp is an ISO Date string format etc.
   * If the message itself or some of descendant messages is google.protobuf.Any, you MUST provide a message pool as options. If not, the messagePool is not required
   */
  toProtobufJSON(
    // @ts-ignore
    options?: ToProtobufJSONOptions
  ): GetBillingTariffChangeRequestsRequest.AsProtobufJSON {
    return {
      customerId: this.customerId,
      from: this.from ? this.from.toProtobufJSON(options) : null,
      to: this.to ? this.to.toProtobufJSON(options) : null,
      meterType: this.meterType,
      tariffGroup: this.tariffGroup,
      includeHistory: this.includeHistory
    };
  }
}
export module GetBillingTariffChangeRequestsRequest {
  /**
   * Standard JavaScript object representation for GetBillingTariffChangeRequestsRequest
   */
  export interface AsObject {
    customerId?: string;
    from?: googleProtobuf000.Timestamp.AsObject;
    to?: googleProtobuf000.Timestamp.AsObject;
    meterType?: string;
    tariffGroup?: string;
    includeHistory?: boolean;
  }

  /**
   * Protobuf JSON representation for GetBillingTariffChangeRequestsRequest
   */
  export interface AsProtobufJSON {
    customerId?: string;
    from?: googleProtobuf000.Timestamp.AsProtobufJSON | null;
    to?: googleProtobuf000.Timestamp.AsProtobufJSON | null;
    meterType?: string;
    tariffGroup?: string;
    includeHistory?: boolean;
  }
}

/**
 * Message implementation for modulebms.customeradmin.GetBillingTariffChangeRequestsResponse
 */
export class GetBillingTariffChangeRequestsResponse implements GrpcMessage {
  static id = 'modulebms.customeradmin.GetBillingTariffChangeRequestsResponse';

  /**
   * Deserialize binary data to message
   * @param instance message instance
   */
  static deserializeBinary(bytes: ByteSource) {
    const instance = new GetBillingTariffChangeRequestsResponse();
    GetBillingTariffChangeRequestsResponse.deserializeBinaryFromReader(
      instance,
      new BinaryReader(bytes)
    );
    return instance;
  }

  /**
   * Check all the properties and set default protobuf values if necessary
   * @param _instance message instance
   */
  static refineValues(_instance: GetBillingTariffChangeRequestsResponse) {
    _instance.billingTariffCahngeRequests =
      _instance.billingTariffCahngeRequests || [];
  }

  /**
   * Deserializes / reads binary message into message instance using provided binary reader
   * @param _instance message instance
   * @param _reader binary reader instance
   */
  static deserializeBinaryFromReader(
    _instance: GetBillingTariffChangeRequestsResponse,
    _reader: BinaryReader
  ) {
    while (_reader.nextField()) {
      if (_reader.isEndGroup()) break;

      switch (_reader.getFieldNumber()) {
        case 1:
          const messageInitializer1 = new BillingTariffchangeRequest();
          _reader.readMessage(
            messageInitializer1,
            BillingTariffchangeRequest.deserializeBinaryFromReader
          );
          (_instance.billingTariffCahngeRequests =
            _instance.billingTariffCahngeRequests || []).push(
            messageInitializer1
          );
          break;
        default:
          _reader.skipField();
      }
    }

    GetBillingTariffChangeRequestsResponse.refineValues(_instance);
  }

  /**
   * Serializes a message to binary format using provided binary reader
   * @param _instance message instance
   * @param _writer binary writer instance
   */
  static serializeBinaryToWriter(
    _instance: GetBillingTariffChangeRequestsResponse,
    _writer: BinaryWriter
  ) {
    if (
      _instance.billingTariffCahngeRequests &&
      _instance.billingTariffCahngeRequests.length
    ) {
      _writer.writeRepeatedMessage(
        1,
        _instance.billingTariffCahngeRequests as any,
        BillingTariffchangeRequest.serializeBinaryToWriter
      );
    }
  }

  private _billingTariffCahngeRequests?: BillingTariffchangeRequest[];

  /**
   * Message constructor. Initializes the properties and applies default Protobuf values if necessary
   * @param _value initial values object or instance of GetBillingTariffChangeRequestsResponse to deeply clone from
   */
  constructor(
    _value?: RecursivePartial<GetBillingTariffChangeRequestsResponse.AsObject>
  ) {
    _value = _value || {};
    this.billingTariffCahngeRequests = (
      _value.billingTariffCahngeRequests || []
    ).map(m => new BillingTariffchangeRequest(m));
    GetBillingTariffChangeRequestsResponse.refineValues(this);
  }
  get billingTariffCahngeRequests(): BillingTariffchangeRequest[] | undefined {
    return this._billingTariffCahngeRequests;
  }
  set billingTariffCahngeRequests(
    value: BillingTariffchangeRequest[] | undefined
  ) {
    this._billingTariffCahngeRequests = value;
  }

  /**
   * Serialize message to binary data
   * @param instance message instance
   */
  serializeBinary() {
    const writer = new BinaryWriter();
    GetBillingTariffChangeRequestsResponse.serializeBinaryToWriter(
      this,
      writer
    );
    return writer.getResultBuffer();
  }

  /**
   * Cast message to standard JavaScript object (all non-primitive values are deeply cloned)
   */
  toObject(): GetBillingTariffChangeRequestsResponse.AsObject {
    return {
      billingTariffCahngeRequests: (
        this.billingTariffCahngeRequests || []
      ).map(m => m.toObject())
    };
  }

  /**
   * Convenience method to support JSON.stringify(message), replicates the structure of toObject()
   */
  toJSON() {
    return this.toObject();
  }

  /**
   * Cast message to JSON using protobuf JSON notation: https://developers.google.com/protocol-buffers/docs/proto3#json
   * Attention: output differs from toObject() e.g. enums are represented as names and not as numbers, Timestamp is an ISO Date string format etc.
   * If the message itself or some of descendant messages is google.protobuf.Any, you MUST provide a message pool as options. If not, the messagePool is not required
   */
  toProtobufJSON(
    // @ts-ignore
    options?: ToProtobufJSONOptions
  ): GetBillingTariffChangeRequestsResponse.AsProtobufJSON {
    return {
      billingTariffCahngeRequests: (
        this.billingTariffCahngeRequests || []
      ).map(m => m.toProtobufJSON(options))
    };
  }
}
export module GetBillingTariffChangeRequestsResponse {
  /**
   * Standard JavaScript object representation for GetBillingTariffChangeRequestsResponse
   */
  export interface AsObject {
    billingTariffCahngeRequests?: BillingTariffchangeRequest.AsObject[];
  }

  /**
   * Protobuf JSON representation for GetBillingTariffChangeRequestsResponse
   */
  export interface AsProtobufJSON {
    billingTariffCahngeRequests?:
      | BillingTariffchangeRequest.AsProtobufJSON[]
      | null;
  }
}

/**
 * Message implementation for modulebms.customeradmin.CreateBillingTariffChangeRequestRequest
 */
export class CreateBillingTariffChangeRequestRequest implements GrpcMessage {
  static id = 'modulebms.customeradmin.CreateBillingTariffChangeRequestRequest';

  /**
   * Deserialize binary data to message
   * @param instance message instance
   */
  static deserializeBinary(bytes: ByteSource) {
    const instance = new CreateBillingTariffChangeRequestRequest();
    CreateBillingTariffChangeRequestRequest.deserializeBinaryFromReader(
      instance,
      new BinaryReader(bytes)
    );
    return instance;
  }

  /**
   * Check all the properties and set default protobuf values if necessary
   * @param _instance message instance
   */
  static refineValues(_instance: CreateBillingTariffChangeRequestRequest) {
    _instance.customerId = _instance.customerId || '';
    _instance.meterType = _instance.meterType || '';
    _instance.tariffGroup = _instance.tariffGroup || '';
    _instance.activeFrom = _instance.activeFrom || undefined;
    _instance.value = _instance.value || 0;
    _instance.vatrate = _instance.vatrate || 0;
    _instance.includeVat = _instance.includeVat || false;
    _instance.currency = _instance.currency || '';
  }

  /**
   * Deserializes / reads binary message into message instance using provided binary reader
   * @param _instance message instance
   * @param _reader binary reader instance
   */
  static deserializeBinaryFromReader(
    _instance: CreateBillingTariffChangeRequestRequest,
    _reader: BinaryReader
  ) {
    while (_reader.nextField()) {
      if (_reader.isEndGroup()) break;

      switch (_reader.getFieldNumber()) {
        case 1:
          _instance.customerId = _reader.readString();
          break;
        case 2:
          _instance.meterType = _reader.readString();
          break;
        case 3:
          _instance.tariffGroup = _reader.readString();
          break;
        case 4:
          _instance.activeFrom = new googleProtobuf000.Timestamp();
          _reader.readMessage(
            _instance.activeFrom,
            googleProtobuf000.Timestamp.deserializeBinaryFromReader
          );
          break;
        case 5:
          _instance.value = _reader.readFloat();
          break;
        case 6:
          _instance.vatrate = _reader.readFloat();
          break;
        case 7:
          _instance.includeVat = _reader.readBool();
          break;
        case 8:
          _instance.currency = _reader.readString();
          break;
        default:
          _reader.skipField();
      }
    }

    CreateBillingTariffChangeRequestRequest.refineValues(_instance);
  }

  /**
   * Serializes a message to binary format using provided binary reader
   * @param _instance message instance
   * @param _writer binary writer instance
   */
  static serializeBinaryToWriter(
    _instance: CreateBillingTariffChangeRequestRequest,
    _writer: BinaryWriter
  ) {
    if (_instance.customerId) {
      _writer.writeString(1, _instance.customerId);
    }
    if (_instance.meterType) {
      _writer.writeString(2, _instance.meterType);
    }
    if (_instance.tariffGroup) {
      _writer.writeString(3, _instance.tariffGroup);
    }
    if (_instance.activeFrom) {
      _writer.writeMessage(
        4,
        _instance.activeFrom as any,
        googleProtobuf000.Timestamp.serializeBinaryToWriter
      );
    }
    if (_instance.value) {
      _writer.writeFloat(5, _instance.value);
    }
    if (_instance.vatrate) {
      _writer.writeFloat(6, _instance.vatrate);
    }
    if (_instance.includeVat) {
      _writer.writeBool(7, _instance.includeVat);
    }
    if (_instance.currency) {
      _writer.writeString(8, _instance.currency);
    }
  }

  private _customerId?: string;
  private _meterType?: string;
  private _tariffGroup?: string;
  private _activeFrom?: googleProtobuf000.Timestamp;
  private _value?: number;
  private _vatrate?: number;
  private _includeVat?: boolean;
  private _currency?: string;

  /**
   * Message constructor. Initializes the properties and applies default Protobuf values if necessary
   * @param _value initial values object or instance of CreateBillingTariffChangeRequestRequest to deeply clone from
   */
  constructor(
    _value?: RecursivePartial<CreateBillingTariffChangeRequestRequest.AsObject>
  ) {
    _value = _value || {};
    this.customerId = _value.customerId;
    this.meterType = _value.meterType;
    this.tariffGroup = _value.tariffGroup;
    this.activeFrom = _value.activeFrom
      ? new googleProtobuf000.Timestamp(_value.activeFrom)
      : undefined;
    this.value = _value.value;
    this.vatrate = _value.vatrate;
    this.includeVat = _value.includeVat;
    this.currency = _value.currency;
    CreateBillingTariffChangeRequestRequest.refineValues(this);
  }
  get customerId(): string | undefined {
    return this._customerId;
  }
  set customerId(value: string | undefined) {
    this._customerId = value;
  }
  get meterType(): string | undefined {
    return this._meterType;
  }
  set meterType(value: string | undefined) {
    this._meterType = value;
  }
  get tariffGroup(): string | undefined {
    return this._tariffGroup;
  }
  set tariffGroup(value: string | undefined) {
    this._tariffGroup = value;
  }
  get activeFrom(): googleProtobuf000.Timestamp | undefined {
    return this._activeFrom;
  }
  set activeFrom(value: googleProtobuf000.Timestamp | undefined) {
    this._activeFrom = value;
  }
  get value(): number | undefined {
    return this._value;
  }
  set value(value: number | undefined) {
    this._value = value;
  }
  get vatrate(): number | undefined {
    return this._vatrate;
  }
  set vatrate(value: number | undefined) {
    this._vatrate = value;
  }
  get includeVat(): boolean | undefined {
    return this._includeVat;
  }
  set includeVat(value: boolean | undefined) {
    this._includeVat = value;
  }
  get currency(): string | undefined {
    return this._currency;
  }
  set currency(value: string | undefined) {
    this._currency = value;
  }

  /**
   * Serialize message to binary data
   * @param instance message instance
   */
  serializeBinary() {
    const writer = new BinaryWriter();
    CreateBillingTariffChangeRequestRequest.serializeBinaryToWriter(
      this,
      writer
    );
    return writer.getResultBuffer();
  }

  /**
   * Cast message to standard JavaScript object (all non-primitive values are deeply cloned)
   */
  toObject(): CreateBillingTariffChangeRequestRequest.AsObject {
    return {
      customerId: this.customerId,
      meterType: this.meterType,
      tariffGroup: this.tariffGroup,
      activeFrom: this.activeFrom ? this.activeFrom.toObject() : undefined,
      value: this.value,
      vatrate: this.vatrate,
      includeVat: this.includeVat,
      currency: this.currency
    };
  }

  /**
   * Convenience method to support JSON.stringify(message), replicates the structure of toObject()
   */
  toJSON() {
    return this.toObject();
  }

  /**
   * Cast message to JSON using protobuf JSON notation: https://developers.google.com/protocol-buffers/docs/proto3#json
   * Attention: output differs from toObject() e.g. enums are represented as names and not as numbers, Timestamp is an ISO Date string format etc.
   * If the message itself or some of descendant messages is google.protobuf.Any, you MUST provide a message pool as options. If not, the messagePool is not required
   */
  toProtobufJSON(
    // @ts-ignore
    options?: ToProtobufJSONOptions
  ): CreateBillingTariffChangeRequestRequest.AsProtobufJSON {
    return {
      customerId: this.customerId,
      meterType: this.meterType,
      tariffGroup: this.tariffGroup,
      activeFrom: this.activeFrom
        ? this.activeFrom.toProtobufJSON(options)
        : null,
      value: this.value,
      vatrate: this.vatrate,
      includeVat: this.includeVat,
      currency: this.currency
    };
  }
}
export module CreateBillingTariffChangeRequestRequest {
  /**
   * Standard JavaScript object representation for CreateBillingTariffChangeRequestRequest
   */
  export interface AsObject {
    customerId?: string;
    meterType?: string;
    tariffGroup?: string;
    activeFrom?: googleProtobuf000.Timestamp.AsObject;
    value?: number;
    vatrate?: number;
    includeVat?: boolean;
    currency?: string;
  }

  /**
   * Protobuf JSON representation for CreateBillingTariffChangeRequestRequest
   */
  export interface AsProtobufJSON {
    customerId?: string;
    meterType?: string;
    tariffGroup?: string;
    activeFrom?: googleProtobuf000.Timestamp.AsProtobufJSON | null;
    value?: number;
    vatrate?: number;
    includeVat?: boolean;
    currency?: string;
  }
}

/**
 * Message implementation for modulebms.customeradmin.CreateBillingTariffChangeRequestResponse
 */
export class CreateBillingTariffChangeRequestResponse implements GrpcMessage {
  static id =
    'modulebms.customeradmin.CreateBillingTariffChangeRequestResponse';

  /**
   * Deserialize binary data to message
   * @param instance message instance
   */
  static deserializeBinary(bytes: ByteSource) {
    const instance = new CreateBillingTariffChangeRequestResponse();
    CreateBillingTariffChangeRequestResponse.deserializeBinaryFromReader(
      instance,
      new BinaryReader(bytes)
    );
    return instance;
  }

  /**
   * Check all the properties and set default protobuf values if necessary
   * @param _instance message instance
   */
  static refineValues(_instance: CreateBillingTariffChangeRequestResponse) {
    _instance.ok = _instance.ok || false;
    _instance.message = _instance.message || '';
  }

  /**
   * Deserializes / reads binary message into message instance using provided binary reader
   * @param _instance message instance
   * @param _reader binary reader instance
   */
  static deserializeBinaryFromReader(
    _instance: CreateBillingTariffChangeRequestResponse,
    _reader: BinaryReader
  ) {
    while (_reader.nextField()) {
      if (_reader.isEndGroup()) break;

      switch (_reader.getFieldNumber()) {
        case 1:
          _instance.ok = _reader.readBool();
          break;
        case 2:
          _instance.message = _reader.readString();
          break;
        default:
          _reader.skipField();
      }
    }

    CreateBillingTariffChangeRequestResponse.refineValues(_instance);
  }

  /**
   * Serializes a message to binary format using provided binary reader
   * @param _instance message instance
   * @param _writer binary writer instance
   */
  static serializeBinaryToWriter(
    _instance: CreateBillingTariffChangeRequestResponse,
    _writer: BinaryWriter
  ) {
    if (_instance.ok) {
      _writer.writeBool(1, _instance.ok);
    }
    if (_instance.message) {
      _writer.writeString(2, _instance.message);
    }
  }

  private _ok?: boolean;
  private _message?: string;

  /**
   * Message constructor. Initializes the properties and applies default Protobuf values if necessary
   * @param _value initial values object or instance of CreateBillingTariffChangeRequestResponse to deeply clone from
   */
  constructor(
    _value?: RecursivePartial<CreateBillingTariffChangeRequestResponse.AsObject>
  ) {
    _value = _value || {};
    this.ok = _value.ok;
    this.message = _value.message;
    CreateBillingTariffChangeRequestResponse.refineValues(this);
  }
  get ok(): boolean | undefined {
    return this._ok;
  }
  set ok(value: boolean | undefined) {
    this._ok = value;
  }
  get message(): string | undefined {
    return this._message;
  }
  set message(value: string | undefined) {
    this._message = value;
  }

  /**
   * Serialize message to binary data
   * @param instance message instance
   */
  serializeBinary() {
    const writer = new BinaryWriter();
    CreateBillingTariffChangeRequestResponse.serializeBinaryToWriter(
      this,
      writer
    );
    return writer.getResultBuffer();
  }

  /**
   * Cast message to standard JavaScript object (all non-primitive values are deeply cloned)
   */
  toObject(): CreateBillingTariffChangeRequestResponse.AsObject {
    return {
      ok: this.ok,
      message: this.message
    };
  }

  /**
   * Convenience method to support JSON.stringify(message), replicates the structure of toObject()
   */
  toJSON() {
    return this.toObject();
  }

  /**
   * Cast message to JSON using protobuf JSON notation: https://developers.google.com/protocol-buffers/docs/proto3#json
   * Attention: output differs from toObject() e.g. enums are represented as names and not as numbers, Timestamp is an ISO Date string format etc.
   * If the message itself or some of descendant messages is google.protobuf.Any, you MUST provide a message pool as options. If not, the messagePool is not required
   */
  toProtobufJSON(
    // @ts-ignore
    options?: ToProtobufJSONOptions
  ): CreateBillingTariffChangeRequestResponse.AsProtobufJSON {
    return {
      ok: this.ok,
      message: this.message
    };
  }
}
export module CreateBillingTariffChangeRequestResponse {
  /**
   * Standard JavaScript object representation for CreateBillingTariffChangeRequestResponse
   */
  export interface AsObject {
    ok?: boolean;
    message?: string;
  }

  /**
   * Protobuf JSON representation for CreateBillingTariffChangeRequestResponse
   */
  export interface AsProtobufJSON {
    ok?: boolean;
    message?: string;
  }
}

/**
 * Message implementation for modulebms.customeradmin.DeleteBillingTariffChangeRequestRequest
 */
export class DeleteBillingTariffChangeRequestRequest implements GrpcMessage {
  static id = 'modulebms.customeradmin.DeleteBillingTariffChangeRequestRequest';

  /**
   * Deserialize binary data to message
   * @param instance message instance
   */
  static deserializeBinary(bytes: ByteSource) {
    const instance = new DeleteBillingTariffChangeRequestRequest();
    DeleteBillingTariffChangeRequestRequest.deserializeBinaryFromReader(
      instance,
      new BinaryReader(bytes)
    );
    return instance;
  }

  /**
   * Check all the properties and set default protobuf values if necessary
   * @param _instance message instance
   */
  static refineValues(_instance: DeleteBillingTariffChangeRequestRequest) {
    _instance.customerId = _instance.customerId || '';
    _instance.requestId = _instance.requestId || '0';
  }

  /**
   * Deserializes / reads binary message into message instance using provided binary reader
   * @param _instance message instance
   * @param _reader binary reader instance
   */
  static deserializeBinaryFromReader(
    _instance: DeleteBillingTariffChangeRequestRequest,
    _reader: BinaryReader
  ) {
    while (_reader.nextField()) {
      if (_reader.isEndGroup()) break;

      switch (_reader.getFieldNumber()) {
        case 1:
          _instance.customerId = _reader.readString();
          break;
        case 2:
          _instance.requestId = _reader.readInt64String();
          break;
        default:
          _reader.skipField();
      }
    }

    DeleteBillingTariffChangeRequestRequest.refineValues(_instance);
  }

  /**
   * Serializes a message to binary format using provided binary reader
   * @param _instance message instance
   * @param _writer binary writer instance
   */
  static serializeBinaryToWriter(
    _instance: DeleteBillingTariffChangeRequestRequest,
    _writer: BinaryWriter
  ) {
    if (_instance.customerId) {
      _writer.writeString(1, _instance.customerId);
    }
    if (_instance.requestId) {
      _writer.writeInt64String(2, _instance.requestId);
    }
  }

  private _customerId?: string;
  private _requestId?: string;

  /**
   * Message constructor. Initializes the properties and applies default Protobuf values if necessary
   * @param _value initial values object or instance of DeleteBillingTariffChangeRequestRequest to deeply clone from
   */
  constructor(
    _value?: RecursivePartial<DeleteBillingTariffChangeRequestRequest.AsObject>
  ) {
    _value = _value || {};
    this.customerId = _value.customerId;
    this.requestId = _value.requestId;
    DeleteBillingTariffChangeRequestRequest.refineValues(this);
  }
  get customerId(): string | undefined {
    return this._customerId;
  }
  set customerId(value: string | undefined) {
    this._customerId = value;
  }
  get requestId(): string | undefined {
    return this._requestId;
  }
  set requestId(value: string | undefined) {
    this._requestId = value;
  }

  /**
   * Serialize message to binary data
   * @param instance message instance
   */
  serializeBinary() {
    const writer = new BinaryWriter();
    DeleteBillingTariffChangeRequestRequest.serializeBinaryToWriter(
      this,
      writer
    );
    return writer.getResultBuffer();
  }

  /**
   * Cast message to standard JavaScript object (all non-primitive values are deeply cloned)
   */
  toObject(): DeleteBillingTariffChangeRequestRequest.AsObject {
    return {
      customerId: this.customerId,
      requestId: this.requestId
    };
  }

  /**
   * Convenience method to support JSON.stringify(message), replicates the structure of toObject()
   */
  toJSON() {
    return this.toObject();
  }

  /**
   * Cast message to JSON using protobuf JSON notation: https://developers.google.com/protocol-buffers/docs/proto3#json
   * Attention: output differs from toObject() e.g. enums are represented as names and not as numbers, Timestamp is an ISO Date string format etc.
   * If the message itself or some of descendant messages is google.protobuf.Any, you MUST provide a message pool as options. If not, the messagePool is not required
   */
  toProtobufJSON(
    // @ts-ignore
    options?: ToProtobufJSONOptions
  ): DeleteBillingTariffChangeRequestRequest.AsProtobufJSON {
    return {
      customerId: this.customerId,
      requestId: this.requestId
    };
  }
}
export module DeleteBillingTariffChangeRequestRequest {
  /**
   * Standard JavaScript object representation for DeleteBillingTariffChangeRequestRequest
   */
  export interface AsObject {
    customerId?: string;
    requestId?: string;
  }

  /**
   * Protobuf JSON representation for DeleteBillingTariffChangeRequestRequest
   */
  export interface AsProtobufJSON {
    customerId?: string;
    requestId?: string;
  }
}

/**
 * Message implementation for modulebms.customeradmin.DeleteBillingTariffChangeRequestResponse
 */
export class DeleteBillingTariffChangeRequestResponse implements GrpcMessage {
  static id =
    'modulebms.customeradmin.DeleteBillingTariffChangeRequestResponse';

  /**
   * Deserialize binary data to message
   * @param instance message instance
   */
  static deserializeBinary(bytes: ByteSource) {
    const instance = new DeleteBillingTariffChangeRequestResponse();
    DeleteBillingTariffChangeRequestResponse.deserializeBinaryFromReader(
      instance,
      new BinaryReader(bytes)
    );
    return instance;
  }

  /**
   * Check all the properties and set default protobuf values if necessary
   * @param _instance message instance
   */
  static refineValues(_instance: DeleteBillingTariffChangeRequestResponse) {
    _instance.ok = _instance.ok || false;
    _instance.message = _instance.message || '';
  }

  /**
   * Deserializes / reads binary message into message instance using provided binary reader
   * @param _instance message instance
   * @param _reader binary reader instance
   */
  static deserializeBinaryFromReader(
    _instance: DeleteBillingTariffChangeRequestResponse,
    _reader: BinaryReader
  ) {
    while (_reader.nextField()) {
      if (_reader.isEndGroup()) break;

      switch (_reader.getFieldNumber()) {
        case 1:
          _instance.ok = _reader.readBool();
          break;
        case 2:
          _instance.message = _reader.readString();
          break;
        default:
          _reader.skipField();
      }
    }

    DeleteBillingTariffChangeRequestResponse.refineValues(_instance);
  }

  /**
   * Serializes a message to binary format using provided binary reader
   * @param _instance message instance
   * @param _writer binary writer instance
   */
  static serializeBinaryToWriter(
    _instance: DeleteBillingTariffChangeRequestResponse,
    _writer: BinaryWriter
  ) {
    if (_instance.ok) {
      _writer.writeBool(1, _instance.ok);
    }
    if (_instance.message) {
      _writer.writeString(2, _instance.message);
    }
  }

  private _ok?: boolean;
  private _message?: string;

  /**
   * Message constructor. Initializes the properties and applies default Protobuf values if necessary
   * @param _value initial values object or instance of DeleteBillingTariffChangeRequestResponse to deeply clone from
   */
  constructor(
    _value?: RecursivePartial<DeleteBillingTariffChangeRequestResponse.AsObject>
  ) {
    _value = _value || {};
    this.ok = _value.ok;
    this.message = _value.message;
    DeleteBillingTariffChangeRequestResponse.refineValues(this);
  }
  get ok(): boolean | undefined {
    return this._ok;
  }
  set ok(value: boolean | undefined) {
    this._ok = value;
  }
  get message(): string | undefined {
    return this._message;
  }
  set message(value: string | undefined) {
    this._message = value;
  }

  /**
   * Serialize message to binary data
   * @param instance message instance
   */
  serializeBinary() {
    const writer = new BinaryWriter();
    DeleteBillingTariffChangeRequestResponse.serializeBinaryToWriter(
      this,
      writer
    );
    return writer.getResultBuffer();
  }

  /**
   * Cast message to standard JavaScript object (all non-primitive values are deeply cloned)
   */
  toObject(): DeleteBillingTariffChangeRequestResponse.AsObject {
    return {
      ok: this.ok,
      message: this.message
    };
  }

  /**
   * Convenience method to support JSON.stringify(message), replicates the structure of toObject()
   */
  toJSON() {
    return this.toObject();
  }

  /**
   * Cast message to JSON using protobuf JSON notation: https://developers.google.com/protocol-buffers/docs/proto3#json
   * Attention: output differs from toObject() e.g. enums are represented as names and not as numbers, Timestamp is an ISO Date string format etc.
   * If the message itself or some of descendant messages is google.protobuf.Any, you MUST provide a message pool as options. If not, the messagePool is not required
   */
  toProtobufJSON(
    // @ts-ignore
    options?: ToProtobufJSONOptions
  ): DeleteBillingTariffChangeRequestResponse.AsProtobufJSON {
    return {
      ok: this.ok,
      message: this.message
    };
  }
}
export module DeleteBillingTariffChangeRequestResponse {
  /**
   * Standard JavaScript object representation for DeleteBillingTariffChangeRequestResponse
   */
  export interface AsObject {
    ok?: boolean;
    message?: string;
  }

  /**
   * Protobuf JSON representation for DeleteBillingTariffChangeRequestResponse
   */
  export interface AsProtobufJSON {
    ok?: boolean;
    message?: string;
  }
}

/**
 * Message implementation for modulebms.customeradmin.GetMeterTypesRequest
 */
export class GetMeterTypesRequest implements GrpcMessage {
  static id = 'modulebms.customeradmin.GetMeterTypesRequest';

  /**
   * Deserialize binary data to message
   * @param instance message instance
   */
  static deserializeBinary(bytes: ByteSource) {
    const instance = new GetMeterTypesRequest();
    GetMeterTypesRequest.deserializeBinaryFromReader(
      instance,
      new BinaryReader(bytes)
    );
    return instance;
  }

  /**
   * Check all the properties and set default protobuf values if necessary
   * @param _instance message instance
   */
  static refineValues(_instance: GetMeterTypesRequest) {
    _instance.customerId = _instance.customerId || '';
    _instance.activeBilling = _instance.activeBilling || false;
  }

  /**
   * Deserializes / reads binary message into message instance using provided binary reader
   * @param _instance message instance
   * @param _reader binary reader instance
   */
  static deserializeBinaryFromReader(
    _instance: GetMeterTypesRequest,
    _reader: BinaryReader
  ) {
    while (_reader.nextField()) {
      if (_reader.isEndGroup()) break;

      switch (_reader.getFieldNumber()) {
        case 1:
          _instance.customerId = _reader.readString();
          break;
        case 2:
          _instance.activeBilling = _reader.readBool();
          break;
        default:
          _reader.skipField();
      }
    }

    GetMeterTypesRequest.refineValues(_instance);
  }

  /**
   * Serializes a message to binary format using provided binary reader
   * @param _instance message instance
   * @param _writer binary writer instance
   */
  static serializeBinaryToWriter(
    _instance: GetMeterTypesRequest,
    _writer: BinaryWriter
  ) {
    if (_instance.customerId) {
      _writer.writeString(1, _instance.customerId);
    }
    if (_instance.activeBilling) {
      _writer.writeBool(2, _instance.activeBilling);
    }
  }

  private _customerId?: string;
  private _activeBilling?: boolean;

  /**
   * Message constructor. Initializes the properties and applies default Protobuf values if necessary
   * @param _value initial values object or instance of GetMeterTypesRequest to deeply clone from
   */
  constructor(_value?: RecursivePartial<GetMeterTypesRequest.AsObject>) {
    _value = _value || {};
    this.customerId = _value.customerId;
    this.activeBilling = _value.activeBilling;
    GetMeterTypesRequest.refineValues(this);
  }
  get customerId(): string | undefined {
    return this._customerId;
  }
  set customerId(value: string | undefined) {
    this._customerId = value;
  }
  get activeBilling(): boolean | undefined {
    return this._activeBilling;
  }
  set activeBilling(value: boolean | undefined) {
    this._activeBilling = value;
  }

  /**
   * Serialize message to binary data
   * @param instance message instance
   */
  serializeBinary() {
    const writer = new BinaryWriter();
    GetMeterTypesRequest.serializeBinaryToWriter(this, writer);
    return writer.getResultBuffer();
  }

  /**
   * Cast message to standard JavaScript object (all non-primitive values are deeply cloned)
   */
  toObject(): GetMeterTypesRequest.AsObject {
    return {
      customerId: this.customerId,
      activeBilling: this.activeBilling
    };
  }

  /**
   * Convenience method to support JSON.stringify(message), replicates the structure of toObject()
   */
  toJSON() {
    return this.toObject();
  }

  /**
   * Cast message to JSON using protobuf JSON notation: https://developers.google.com/protocol-buffers/docs/proto3#json
   * Attention: output differs from toObject() e.g. enums are represented as names and not as numbers, Timestamp is an ISO Date string format etc.
   * If the message itself or some of descendant messages is google.protobuf.Any, you MUST provide a message pool as options. If not, the messagePool is not required
   */
  toProtobufJSON(
    // @ts-ignore
    options?: ToProtobufJSONOptions
  ): GetMeterTypesRequest.AsProtobufJSON {
    return {
      customerId: this.customerId,
      activeBilling: this.activeBilling
    };
  }
}
export module GetMeterTypesRequest {
  /**
   * Standard JavaScript object representation for GetMeterTypesRequest
   */
  export interface AsObject {
    customerId?: string;
    activeBilling?: boolean;
  }

  /**
   * Protobuf JSON representation for GetMeterTypesRequest
   */
  export interface AsProtobufJSON {
    customerId?: string;
    activeBilling?: boolean;
  }
}

/**
 * Message implementation for modulebms.customeradmin.MeterTypeInfo
 */
export class MeterTypeInfo implements GrpcMessage {
  static id = 'modulebms.customeradmin.MeterTypeInfo';

  /**
   * Deserialize binary data to message
   * @param instance message instance
   */
  static deserializeBinary(bytes: ByteSource) {
    const instance = new MeterTypeInfo();
    MeterTypeInfo.deserializeBinaryFromReader(
      instance,
      new BinaryReader(bytes)
    );
    return instance;
  }

  /**
   * Check all the properties and set default protobuf values if necessary
   * @param _instance message instance
   */
  static refineValues(_instance: MeterTypeInfo) {
    _instance.meterType = _instance.meterType || '';
    _instance.maxTariffPerUnit = _instance.maxTariffPerUnit || 0;
    _instance.unit = _instance.unit || '';
  }

  /**
   * Deserializes / reads binary message into message instance using provided binary reader
   * @param _instance message instance
   * @param _reader binary reader instance
   */
  static deserializeBinaryFromReader(
    _instance: MeterTypeInfo,
    _reader: BinaryReader
  ) {
    while (_reader.nextField()) {
      if (_reader.isEndGroup()) break;

      switch (_reader.getFieldNumber()) {
        case 1:
          _instance.meterType = _reader.readString();
          break;
        case 2:
          _instance.maxTariffPerUnit = _reader.readFloat();
          break;
        case 3:
          _instance.unit = _reader.readString();
          break;
        default:
          _reader.skipField();
      }
    }

    MeterTypeInfo.refineValues(_instance);
  }

  /**
   * Serializes a message to binary format using provided binary reader
   * @param _instance message instance
   * @param _writer binary writer instance
   */
  static serializeBinaryToWriter(
    _instance: MeterTypeInfo,
    _writer: BinaryWriter
  ) {
    if (_instance.meterType) {
      _writer.writeString(1, _instance.meterType);
    }
    if (_instance.maxTariffPerUnit) {
      _writer.writeFloat(2, _instance.maxTariffPerUnit);
    }
    if (_instance.unit) {
      _writer.writeString(3, _instance.unit);
    }
  }

  private _meterType?: string;
  private _maxTariffPerUnit?: number;
  private _unit?: string;

  /**
   * Message constructor. Initializes the properties and applies default Protobuf values if necessary
   * @param _value initial values object or instance of MeterTypeInfo to deeply clone from
   */
  constructor(_value?: RecursivePartial<MeterTypeInfo.AsObject>) {
    _value = _value || {};
    this.meterType = _value.meterType;
    this.maxTariffPerUnit = _value.maxTariffPerUnit;
    this.unit = _value.unit;
    MeterTypeInfo.refineValues(this);
  }
  get meterType(): string | undefined {
    return this._meterType;
  }
  set meterType(value: string | undefined) {
    this._meterType = value;
  }
  get maxTariffPerUnit(): number | undefined {
    return this._maxTariffPerUnit;
  }
  set maxTariffPerUnit(value: number | undefined) {
    this._maxTariffPerUnit = value;
  }
  get unit(): string | undefined {
    return this._unit;
  }
  set unit(value: string | undefined) {
    this._unit = value;
  }

  /**
   * Serialize message to binary data
   * @param instance message instance
   */
  serializeBinary() {
    const writer = new BinaryWriter();
    MeterTypeInfo.serializeBinaryToWriter(this, writer);
    return writer.getResultBuffer();
  }

  /**
   * Cast message to standard JavaScript object (all non-primitive values are deeply cloned)
   */
  toObject(): MeterTypeInfo.AsObject {
    return {
      meterType: this.meterType,
      maxTariffPerUnit: this.maxTariffPerUnit,
      unit: this.unit
    };
  }

  /**
   * Convenience method to support JSON.stringify(message), replicates the structure of toObject()
   */
  toJSON() {
    return this.toObject();
  }

  /**
   * Cast message to JSON using protobuf JSON notation: https://developers.google.com/protocol-buffers/docs/proto3#json
   * Attention: output differs from toObject() e.g. enums are represented as names and not as numbers, Timestamp is an ISO Date string format etc.
   * If the message itself or some of descendant messages is google.protobuf.Any, you MUST provide a message pool as options. If not, the messagePool is not required
   */
  toProtobufJSON(
    // @ts-ignore
    options?: ToProtobufJSONOptions
  ): MeterTypeInfo.AsProtobufJSON {
    return {
      meterType: this.meterType,
      maxTariffPerUnit: this.maxTariffPerUnit,
      unit: this.unit
    };
  }
}
export module MeterTypeInfo {
  /**
   * Standard JavaScript object representation for MeterTypeInfo
   */
  export interface AsObject {
    meterType?: string;
    maxTariffPerUnit?: number;
    unit?: string;
  }

  /**
   * Protobuf JSON representation for MeterTypeInfo
   */
  export interface AsProtobufJSON {
    meterType?: string;
    maxTariffPerUnit?: number;
    unit?: string;
  }
}

/**
 * Message implementation for modulebms.customeradmin.GetMeterTypesResponse
 */
export class GetMeterTypesResponse implements GrpcMessage {
  static id = 'modulebms.customeradmin.GetMeterTypesResponse';

  /**
   * Deserialize binary data to message
   * @param instance message instance
   */
  static deserializeBinary(bytes: ByteSource) {
    const instance = new GetMeterTypesResponse();
    GetMeterTypesResponse.deserializeBinaryFromReader(
      instance,
      new BinaryReader(bytes)
    );
    return instance;
  }

  /**
   * Check all the properties and set default protobuf values if necessary
   * @param _instance message instance
   */
  static refineValues(_instance: GetMeterTypesResponse) {
    _instance.metertypes = _instance.metertypes || [];
    _instance.meterTypeInfos = _instance.meterTypeInfos || [];
  }

  /**
   * Deserializes / reads binary message into message instance using provided binary reader
   * @param _instance message instance
   * @param _reader binary reader instance
   */
  static deserializeBinaryFromReader(
    _instance: GetMeterTypesResponse,
    _reader: BinaryReader
  ) {
    while (_reader.nextField()) {
      if (_reader.isEndGroup()) break;

      switch (_reader.getFieldNumber()) {
        case 1:
          (_instance.metertypes = _instance.metertypes || []).push(
            _reader.readString()
          );
          break;
        case 2:
          const messageInitializer2 = new MeterTypeInfo();
          _reader.readMessage(
            messageInitializer2,
            MeterTypeInfo.deserializeBinaryFromReader
          );
          (_instance.meterTypeInfos = _instance.meterTypeInfos || []).push(
            messageInitializer2
          );
          break;
        default:
          _reader.skipField();
      }
    }

    GetMeterTypesResponse.refineValues(_instance);
  }

  /**
   * Serializes a message to binary format using provided binary reader
   * @param _instance message instance
   * @param _writer binary writer instance
   */
  static serializeBinaryToWriter(
    _instance: GetMeterTypesResponse,
    _writer: BinaryWriter
  ) {
    if (_instance.metertypes && _instance.metertypes.length) {
      _writer.writeRepeatedString(1, _instance.metertypes);
    }
    if (_instance.meterTypeInfos && _instance.meterTypeInfos.length) {
      _writer.writeRepeatedMessage(
        2,
        _instance.meterTypeInfos as any,
        MeterTypeInfo.serializeBinaryToWriter
      );
    }
  }

  private _metertypes?: string[];
  private _meterTypeInfos?: MeterTypeInfo[];

  /**
   * Message constructor. Initializes the properties and applies default Protobuf values if necessary
   * @param _value initial values object or instance of GetMeterTypesResponse to deeply clone from
   */
  constructor(_value?: RecursivePartial<GetMeterTypesResponse.AsObject>) {
    _value = _value || {};
    this.metertypes = (_value.metertypes || []).slice();
    this.meterTypeInfos = (_value.meterTypeInfos || []).map(
      m => new MeterTypeInfo(m)
    );
    GetMeterTypesResponse.refineValues(this);
  }
  get metertypes(): string[] | undefined {
    return this._metertypes;
  }
  set metertypes(value: string[] | undefined) {
    this._metertypes = value;
  }
  get meterTypeInfos(): MeterTypeInfo[] | undefined {
    return this._meterTypeInfos;
  }
  set meterTypeInfos(value: MeterTypeInfo[] | undefined) {
    this._meterTypeInfos = value;
  }

  /**
   * Serialize message to binary data
   * @param instance message instance
   */
  serializeBinary() {
    const writer = new BinaryWriter();
    GetMeterTypesResponse.serializeBinaryToWriter(this, writer);
    return writer.getResultBuffer();
  }

  /**
   * Cast message to standard JavaScript object (all non-primitive values are deeply cloned)
   */
  toObject(): GetMeterTypesResponse.AsObject {
    return {
      metertypes: (this.metertypes || []).slice(),
      meterTypeInfos: (this.meterTypeInfos || []).map(m => m.toObject())
    };
  }

  /**
   * Convenience method to support JSON.stringify(message), replicates the structure of toObject()
   */
  toJSON() {
    return this.toObject();
  }

  /**
   * Cast message to JSON using protobuf JSON notation: https://developers.google.com/protocol-buffers/docs/proto3#json
   * Attention: output differs from toObject() e.g. enums are represented as names and not as numbers, Timestamp is an ISO Date string format etc.
   * If the message itself or some of descendant messages is google.protobuf.Any, you MUST provide a message pool as options. If not, the messagePool is not required
   */
  toProtobufJSON(
    // @ts-ignore
    options?: ToProtobufJSONOptions
  ): GetMeterTypesResponse.AsProtobufJSON {
    return {
      metertypes: (this.metertypes || []).slice(),
      meterTypeInfos: (this.meterTypeInfos || []).map(m =>
        m.toProtobufJSON(options)
      )
    };
  }
}
export module GetMeterTypesResponse {
  /**
   * Standard JavaScript object representation for GetMeterTypesResponse
   */
  export interface AsObject {
    metertypes?: string[];
    meterTypeInfos?: MeterTypeInfo.AsObject[];
  }

  /**
   * Protobuf JSON representation for GetMeterTypesResponse
   */
  export interface AsProtobufJSON {
    metertypes?: string[];
    meterTypeInfos?: MeterTypeInfo.AsProtobufJSON[] | null;
  }
}

/**
 * Message implementation for modulebms.customeradmin.GetTariffGroupsRequest
 */
export class GetTariffGroupsRequest implements GrpcMessage {
  static id = 'modulebms.customeradmin.GetTariffGroupsRequest';

  /**
   * Deserialize binary data to message
   * @param instance message instance
   */
  static deserializeBinary(bytes: ByteSource) {
    const instance = new GetTariffGroupsRequest();
    GetTariffGroupsRequest.deserializeBinaryFromReader(
      instance,
      new BinaryReader(bytes)
    );
    return instance;
  }

  /**
   * Check all the properties and set default protobuf values if necessary
   * @param _instance message instance
   */
  static refineValues(_instance: GetTariffGroupsRequest) {
    _instance.customerId = _instance.customerId || '';
    _instance.activeBilling = _instance.activeBilling || false;
    _instance.allGroups = _instance.allGroups || false;
  }

  /**
   * Deserializes / reads binary message into message instance using provided binary reader
   * @param _instance message instance
   * @param _reader binary reader instance
   */
  static deserializeBinaryFromReader(
    _instance: GetTariffGroupsRequest,
    _reader: BinaryReader
  ) {
    while (_reader.nextField()) {
      if (_reader.isEndGroup()) break;

      switch (_reader.getFieldNumber()) {
        case 1:
          _instance.customerId = _reader.readString();
          break;
        case 2:
          _instance.activeBilling = _reader.readBool();
          break;
        case 3:
          _instance.allGroups = _reader.readBool();
          break;
        default:
          _reader.skipField();
      }
    }

    GetTariffGroupsRequest.refineValues(_instance);
  }

  /**
   * Serializes a message to binary format using provided binary reader
   * @param _instance message instance
   * @param _writer binary writer instance
   */
  static serializeBinaryToWriter(
    _instance: GetTariffGroupsRequest,
    _writer: BinaryWriter
  ) {
    if (_instance.customerId) {
      _writer.writeString(1, _instance.customerId);
    }
    if (_instance.activeBilling) {
      _writer.writeBool(2, _instance.activeBilling);
    }
    if (_instance.allGroups) {
      _writer.writeBool(3, _instance.allGroups);
    }
  }

  private _customerId?: string;
  private _activeBilling?: boolean;
  private _allGroups?: boolean;

  /**
   * Message constructor. Initializes the properties and applies default Protobuf values if necessary
   * @param _value initial values object or instance of GetTariffGroupsRequest to deeply clone from
   */
  constructor(_value?: RecursivePartial<GetTariffGroupsRequest.AsObject>) {
    _value = _value || {};
    this.customerId = _value.customerId;
    this.activeBilling = _value.activeBilling;
    this.allGroups = _value.allGroups;
    GetTariffGroupsRequest.refineValues(this);
  }
  get customerId(): string | undefined {
    return this._customerId;
  }
  set customerId(value: string | undefined) {
    this._customerId = value;
  }
  get activeBilling(): boolean | undefined {
    return this._activeBilling;
  }
  set activeBilling(value: boolean | undefined) {
    this._activeBilling = value;
  }
  get allGroups(): boolean | undefined {
    return this._allGroups;
  }
  set allGroups(value: boolean | undefined) {
    this._allGroups = value;
  }

  /**
   * Serialize message to binary data
   * @param instance message instance
   */
  serializeBinary() {
    const writer = new BinaryWriter();
    GetTariffGroupsRequest.serializeBinaryToWriter(this, writer);
    return writer.getResultBuffer();
  }

  /**
   * Cast message to standard JavaScript object (all non-primitive values are deeply cloned)
   */
  toObject(): GetTariffGroupsRequest.AsObject {
    return {
      customerId: this.customerId,
      activeBilling: this.activeBilling,
      allGroups: this.allGroups
    };
  }

  /**
   * Convenience method to support JSON.stringify(message), replicates the structure of toObject()
   */
  toJSON() {
    return this.toObject();
  }

  /**
   * Cast message to JSON using protobuf JSON notation: https://developers.google.com/protocol-buffers/docs/proto3#json
   * Attention: output differs from toObject() e.g. enums are represented as names and not as numbers, Timestamp is an ISO Date string format etc.
   * If the message itself or some of descendant messages is google.protobuf.Any, you MUST provide a message pool as options. If not, the messagePool is not required
   */
  toProtobufJSON(
    // @ts-ignore
    options?: ToProtobufJSONOptions
  ): GetTariffGroupsRequest.AsProtobufJSON {
    return {
      customerId: this.customerId,
      activeBilling: this.activeBilling,
      allGroups: this.allGroups
    };
  }
}
export module GetTariffGroupsRequest {
  /**
   * Standard JavaScript object representation for GetTariffGroupsRequest
   */
  export interface AsObject {
    customerId?: string;
    activeBilling?: boolean;
    allGroups?: boolean;
  }

  /**
   * Protobuf JSON representation for GetTariffGroupsRequest
   */
  export interface AsProtobufJSON {
    customerId?: string;
    activeBilling?: boolean;
    allGroups?: boolean;
  }
}

/**
 * Message implementation for modulebms.customeradmin.TariffGroupInfo
 */
export class TariffGroupInfo implements GrpcMessage {
  static id = 'modulebms.customeradmin.TariffGroupInfo';

  /**
   * Deserialize binary data to message
   * @param instance message instance
   */
  static deserializeBinary(bytes: ByteSource) {
    const instance = new TariffGroupInfo();
    TariffGroupInfo.deserializeBinaryFromReader(
      instance,
      new BinaryReader(bytes)
    );
    return instance;
  }

  /**
   * Check all the properties and set default protobuf values if necessary
   * @param _instance message instance
   */
  static refineValues(_instance: TariffGroupInfo) {
    _instance.id = _instance.id || '';
    _instance.name = _instance.name || '';
    _instance.unit = _instance.unit || '';
    _instance.maxTariffPerUnit = _instance.maxTariffPerUnit || 0;
    _instance.description = _instance.description || '';
  }

  /**
   * Deserializes / reads binary message into message instance using provided binary reader
   * @param _instance message instance
   * @param _reader binary reader instance
   */
  static deserializeBinaryFromReader(
    _instance: TariffGroupInfo,
    _reader: BinaryReader
  ) {
    while (_reader.nextField()) {
      if (_reader.isEndGroup()) break;

      switch (_reader.getFieldNumber()) {
        case 1:
          _instance.id = _reader.readString();
          break;
        case 2:
          _instance.name = _reader.readString();
          break;
        case 3:
          _instance.unit = _reader.readString();
          break;
        case 4:
          _instance.maxTariffPerUnit = _reader.readDouble();
          break;
        case 5:
          _instance.description = _reader.readString();
          break;
        default:
          _reader.skipField();
      }
    }

    TariffGroupInfo.refineValues(_instance);
  }

  /**
   * Serializes a message to binary format using provided binary reader
   * @param _instance message instance
   * @param _writer binary writer instance
   */
  static serializeBinaryToWriter(
    _instance: TariffGroupInfo,
    _writer: BinaryWriter
  ) {
    if (_instance.id) {
      _writer.writeString(1, _instance.id);
    }
    if (_instance.name) {
      _writer.writeString(2, _instance.name);
    }
    if (_instance.unit) {
      _writer.writeString(3, _instance.unit);
    }
    if (_instance.maxTariffPerUnit) {
      _writer.writeDouble(4, _instance.maxTariffPerUnit);
    }
    if (_instance.description) {
      _writer.writeString(5, _instance.description);
    }
  }

  private _id?: string;
  private _name?: string;
  private _unit?: string;
  private _maxTariffPerUnit?: number;
  private _description?: string;

  /**
   * Message constructor. Initializes the properties and applies default Protobuf values if necessary
   * @param _value initial values object or instance of TariffGroupInfo to deeply clone from
   */
  constructor(_value?: RecursivePartial<TariffGroupInfo.AsObject>) {
    _value = _value || {};
    this.id = _value.id;
    this.name = _value.name;
    this.unit = _value.unit;
    this.maxTariffPerUnit = _value.maxTariffPerUnit;
    this.description = _value.description;
    TariffGroupInfo.refineValues(this);
  }
  get id(): string | undefined {
    return this._id;
  }
  set id(value: string | undefined) {
    this._id = value;
  }
  get name(): string | undefined {
    return this._name;
  }
  set name(value: string | undefined) {
    this._name = value;
  }
  get unit(): string | undefined {
    return this._unit;
  }
  set unit(value: string | undefined) {
    this._unit = value;
  }
  get maxTariffPerUnit(): number | undefined {
    return this._maxTariffPerUnit;
  }
  set maxTariffPerUnit(value: number | undefined) {
    this._maxTariffPerUnit = value;
  }
  get description(): string | undefined {
    return this._description;
  }
  set description(value: string | undefined) {
    this._description = value;
  }

  /**
   * Serialize message to binary data
   * @param instance message instance
   */
  serializeBinary() {
    const writer = new BinaryWriter();
    TariffGroupInfo.serializeBinaryToWriter(this, writer);
    return writer.getResultBuffer();
  }

  /**
   * Cast message to standard JavaScript object (all non-primitive values are deeply cloned)
   */
  toObject(): TariffGroupInfo.AsObject {
    return {
      id: this.id,
      name: this.name,
      unit: this.unit,
      maxTariffPerUnit: this.maxTariffPerUnit,
      description: this.description
    };
  }

  /**
   * Convenience method to support JSON.stringify(message), replicates the structure of toObject()
   */
  toJSON() {
    return this.toObject();
  }

  /**
   * Cast message to JSON using protobuf JSON notation: https://developers.google.com/protocol-buffers/docs/proto3#json
   * Attention: output differs from toObject() e.g. enums are represented as names and not as numbers, Timestamp is an ISO Date string format etc.
   * If the message itself or some of descendant messages is google.protobuf.Any, you MUST provide a message pool as options. If not, the messagePool is not required
   */
  toProtobufJSON(
    // @ts-ignore
    options?: ToProtobufJSONOptions
  ): TariffGroupInfo.AsProtobufJSON {
    return {
      id: this.id,
      name: this.name,
      unit: this.unit,
      maxTariffPerUnit: this.maxTariffPerUnit,
      description: this.description
    };
  }
}
export module TariffGroupInfo {
  /**
   * Standard JavaScript object representation for TariffGroupInfo
   */
  export interface AsObject {
    id?: string;
    name?: string;
    unit?: string;
    maxTariffPerUnit?: number;
    description?: string;
  }

  /**
   * Protobuf JSON representation for TariffGroupInfo
   */
  export interface AsProtobufJSON {
    id?: string;
    name?: string;
    unit?: string;
    maxTariffPerUnit?: number;
    description?: string;
  }
}

/**
 * Message implementation for modulebms.customeradmin.GetTariffGroupsResponse
 */
export class GetTariffGroupsResponse implements GrpcMessage {
  static id = 'modulebms.customeradmin.GetTariffGroupsResponse';

  /**
   * Deserialize binary data to message
   * @param instance message instance
   */
  static deserializeBinary(bytes: ByteSource) {
    const instance = new GetTariffGroupsResponse();
    GetTariffGroupsResponse.deserializeBinaryFromReader(
      instance,
      new BinaryReader(bytes)
    );
    return instance;
  }

  /**
   * Check all the properties and set default protobuf values if necessary
   * @param _instance message instance
   */
  static refineValues(_instance: GetTariffGroupsResponse) {
    _instance.tariffGroupsInfos = _instance.tariffGroupsInfos || [];
  }

  /**
   * Deserializes / reads binary message into message instance using provided binary reader
   * @param _instance message instance
   * @param _reader binary reader instance
   */
  static deserializeBinaryFromReader(
    _instance: GetTariffGroupsResponse,
    _reader: BinaryReader
  ) {
    while (_reader.nextField()) {
      if (_reader.isEndGroup()) break;

      switch (_reader.getFieldNumber()) {
        case 1:
          const messageInitializer1 = new TariffGroupInfo();
          _reader.readMessage(
            messageInitializer1,
            TariffGroupInfo.deserializeBinaryFromReader
          );
          (_instance.tariffGroupsInfos =
            _instance.tariffGroupsInfos || []).push(messageInitializer1);
          break;
        default:
          _reader.skipField();
      }
    }

    GetTariffGroupsResponse.refineValues(_instance);
  }

  /**
   * Serializes a message to binary format using provided binary reader
   * @param _instance message instance
   * @param _writer binary writer instance
   */
  static serializeBinaryToWriter(
    _instance: GetTariffGroupsResponse,
    _writer: BinaryWriter
  ) {
    if (_instance.tariffGroupsInfos && _instance.tariffGroupsInfos.length) {
      _writer.writeRepeatedMessage(
        1,
        _instance.tariffGroupsInfos as any,
        TariffGroupInfo.serializeBinaryToWriter
      );
    }
  }

  private _tariffGroupsInfos?: TariffGroupInfo[];

  /**
   * Message constructor. Initializes the properties and applies default Protobuf values if necessary
   * @param _value initial values object or instance of GetTariffGroupsResponse to deeply clone from
   */
  constructor(_value?: RecursivePartial<GetTariffGroupsResponse.AsObject>) {
    _value = _value || {};
    this.tariffGroupsInfos = (_value.tariffGroupsInfos || []).map(
      m => new TariffGroupInfo(m)
    );
    GetTariffGroupsResponse.refineValues(this);
  }
  get tariffGroupsInfos(): TariffGroupInfo[] | undefined {
    return this._tariffGroupsInfos;
  }
  set tariffGroupsInfos(value: TariffGroupInfo[] | undefined) {
    this._tariffGroupsInfos = value;
  }

  /**
   * Serialize message to binary data
   * @param instance message instance
   */
  serializeBinary() {
    const writer = new BinaryWriter();
    GetTariffGroupsResponse.serializeBinaryToWriter(this, writer);
    return writer.getResultBuffer();
  }

  /**
   * Cast message to standard JavaScript object (all non-primitive values are deeply cloned)
   */
  toObject(): GetTariffGroupsResponse.AsObject {
    return {
      tariffGroupsInfos: (this.tariffGroupsInfos || []).map(m => m.toObject())
    };
  }

  /**
   * Convenience method to support JSON.stringify(message), replicates the structure of toObject()
   */
  toJSON() {
    return this.toObject();
  }

  /**
   * Cast message to JSON using protobuf JSON notation: https://developers.google.com/protocol-buffers/docs/proto3#json
   * Attention: output differs from toObject() e.g. enums are represented as names and not as numbers, Timestamp is an ISO Date string format etc.
   * If the message itself or some of descendant messages is google.protobuf.Any, you MUST provide a message pool as options. If not, the messagePool is not required
   */
  toProtobufJSON(
    // @ts-ignore
    options?: ToProtobufJSONOptions
  ): GetTariffGroupsResponse.AsProtobufJSON {
    return {
      tariffGroupsInfos: (this.tariffGroupsInfos || []).map(m =>
        m.toProtobufJSON(options)
      )
    };
  }
}
export module GetTariffGroupsResponse {
  /**
   * Standard JavaScript object representation for GetTariffGroupsResponse
   */
  export interface AsObject {
    tariffGroupsInfos?: TariffGroupInfo.AsObject[];
  }

  /**
   * Protobuf JSON representation for GetTariffGroupsResponse
   */
  export interface AsProtobufJSON {
    tariffGroupsInfos?: TariffGroupInfo.AsProtobufJSON[] | null;
  }
}

/**
 * Message implementation for modulebms.customeradmin.GetLastInvoicedDateRequest
 */
export class GetLastInvoicedDateRequest implements GrpcMessage {
  static id = 'modulebms.customeradmin.GetLastInvoicedDateRequest';

  /**
   * Deserialize binary data to message
   * @param instance message instance
   */
  static deserializeBinary(bytes: ByteSource) {
    const instance = new GetLastInvoicedDateRequest();
    GetLastInvoicedDateRequest.deserializeBinaryFromReader(
      instance,
      new BinaryReader(bytes)
    );
    return instance;
  }

  /**
   * Check all the properties and set default protobuf values if necessary
   * @param _instance message instance
   */
  static refineValues(_instance: GetLastInvoicedDateRequest) {
    _instance.customerId = _instance.customerId || '';
    _instance.meterType = _instance.meterType || '';
    _instance.includeSigned = _instance.includeSigned || false;
  }

  /**
   * Deserializes / reads binary message into message instance using provided binary reader
   * @param _instance message instance
   * @param _reader binary reader instance
   */
  static deserializeBinaryFromReader(
    _instance: GetLastInvoicedDateRequest,
    _reader: BinaryReader
  ) {
    while (_reader.nextField()) {
      if (_reader.isEndGroup()) break;

      switch (_reader.getFieldNumber()) {
        case 1:
          _instance.customerId = _reader.readString();
          break;
        case 2:
          _instance.meterType = _reader.readString();
          break;
        case 3:
          _instance.includeSigned = _reader.readBool();
          break;
        default:
          _reader.skipField();
      }
    }

    GetLastInvoicedDateRequest.refineValues(_instance);
  }

  /**
   * Serializes a message to binary format using provided binary reader
   * @param _instance message instance
   * @param _writer binary writer instance
   */
  static serializeBinaryToWriter(
    _instance: GetLastInvoicedDateRequest,
    _writer: BinaryWriter
  ) {
    if (_instance.customerId) {
      _writer.writeString(1, _instance.customerId);
    }
    if (_instance.meterType) {
      _writer.writeString(2, _instance.meterType);
    }
    if (_instance.includeSigned) {
      _writer.writeBool(3, _instance.includeSigned);
    }
  }

  private _customerId?: string;
  private _meterType?: string;
  private _includeSigned?: boolean;

  /**
   * Message constructor. Initializes the properties and applies default Protobuf values if necessary
   * @param _value initial values object or instance of GetLastInvoicedDateRequest to deeply clone from
   */
  constructor(_value?: RecursivePartial<GetLastInvoicedDateRequest.AsObject>) {
    _value = _value || {};
    this.customerId = _value.customerId;
    this.meterType = _value.meterType;
    this.includeSigned = _value.includeSigned;
    GetLastInvoicedDateRequest.refineValues(this);
  }
  get customerId(): string | undefined {
    return this._customerId;
  }
  set customerId(value: string | undefined) {
    this._customerId = value;
  }
  get meterType(): string | undefined {
    return this._meterType;
  }
  set meterType(value: string | undefined) {
    this._meterType = value;
  }
  get includeSigned(): boolean | undefined {
    return this._includeSigned;
  }
  set includeSigned(value: boolean | undefined) {
    this._includeSigned = value;
  }

  /**
   * Serialize message to binary data
   * @param instance message instance
   */
  serializeBinary() {
    const writer = new BinaryWriter();
    GetLastInvoicedDateRequest.serializeBinaryToWriter(this, writer);
    return writer.getResultBuffer();
  }

  /**
   * Cast message to standard JavaScript object (all non-primitive values are deeply cloned)
   */
  toObject(): GetLastInvoicedDateRequest.AsObject {
    return {
      customerId: this.customerId,
      meterType: this.meterType,
      includeSigned: this.includeSigned
    };
  }

  /**
   * Convenience method to support JSON.stringify(message), replicates the structure of toObject()
   */
  toJSON() {
    return this.toObject();
  }

  /**
   * Cast message to JSON using protobuf JSON notation: https://developers.google.com/protocol-buffers/docs/proto3#json
   * Attention: output differs from toObject() e.g. enums are represented as names and not as numbers, Timestamp is an ISO Date string format etc.
   * If the message itself or some of descendant messages is google.protobuf.Any, you MUST provide a message pool as options. If not, the messagePool is not required
   */
  toProtobufJSON(
    // @ts-ignore
    options?: ToProtobufJSONOptions
  ): GetLastInvoicedDateRequest.AsProtobufJSON {
    return {
      customerId: this.customerId,
      meterType: this.meterType,
      includeSigned: this.includeSigned
    };
  }
}
export module GetLastInvoicedDateRequest {
  /**
   * Standard JavaScript object representation for GetLastInvoicedDateRequest
   */
  export interface AsObject {
    customerId?: string;
    meterType?: string;
    includeSigned?: boolean;
  }

  /**
   * Protobuf JSON representation for GetLastInvoicedDateRequest
   */
  export interface AsProtobufJSON {
    customerId?: string;
    meterType?: string;
    includeSigned?: boolean;
  }
}

/**
 * Message implementation for modulebms.customeradmin.GetLastInvoicedDateResponse
 */
export class GetLastInvoicedDateResponse implements GrpcMessage {
  static id = 'modulebms.customeradmin.GetLastInvoicedDateResponse';

  /**
   * Deserialize binary data to message
   * @param instance message instance
   */
  static deserializeBinary(bytes: ByteSource) {
    const instance = new GetLastInvoicedDateResponse();
    GetLastInvoicedDateResponse.deserializeBinaryFromReader(
      instance,
      new BinaryReader(bytes)
    );
    return instance;
  }

  /**
   * Check all the properties and set default protobuf values if necessary
   * @param _instance message instance
   */
  static refineValues(_instance: GetLastInvoicedDateResponse) {
    _instance.lastInvoicedAt = _instance.lastInvoicedAt || undefined;
  }

  /**
   * Deserializes / reads binary message into message instance using provided binary reader
   * @param _instance message instance
   * @param _reader binary reader instance
   */
  static deserializeBinaryFromReader(
    _instance: GetLastInvoicedDateResponse,
    _reader: BinaryReader
  ) {
    while (_reader.nextField()) {
      if (_reader.isEndGroup()) break;

      switch (_reader.getFieldNumber()) {
        case 1:
          _instance.lastInvoicedAt = new googleProtobuf000.Timestamp();
          _reader.readMessage(
            _instance.lastInvoicedAt,
            googleProtobuf000.Timestamp.deserializeBinaryFromReader
          );
          break;
        default:
          _reader.skipField();
      }
    }

    GetLastInvoicedDateResponse.refineValues(_instance);
  }

  /**
   * Serializes a message to binary format using provided binary reader
   * @param _instance message instance
   * @param _writer binary writer instance
   */
  static serializeBinaryToWriter(
    _instance: GetLastInvoicedDateResponse,
    _writer: BinaryWriter
  ) {
    if (_instance.lastInvoicedAt) {
      _writer.writeMessage(
        1,
        _instance.lastInvoicedAt as any,
        googleProtobuf000.Timestamp.serializeBinaryToWriter
      );
    }
  }

  private _lastInvoicedAt?: googleProtobuf000.Timestamp;

  /**
   * Message constructor. Initializes the properties and applies default Protobuf values if necessary
   * @param _value initial values object or instance of GetLastInvoicedDateResponse to deeply clone from
   */
  constructor(_value?: RecursivePartial<GetLastInvoicedDateResponse.AsObject>) {
    _value = _value || {};
    this.lastInvoicedAt = _value.lastInvoicedAt
      ? new googleProtobuf000.Timestamp(_value.lastInvoicedAt)
      : undefined;
    GetLastInvoicedDateResponse.refineValues(this);
  }
  get lastInvoicedAt(): googleProtobuf000.Timestamp | undefined {
    return this._lastInvoicedAt;
  }
  set lastInvoicedAt(value: googleProtobuf000.Timestamp | undefined) {
    this._lastInvoicedAt = value;
  }

  /**
   * Serialize message to binary data
   * @param instance message instance
   */
  serializeBinary() {
    const writer = new BinaryWriter();
    GetLastInvoicedDateResponse.serializeBinaryToWriter(this, writer);
    return writer.getResultBuffer();
  }

  /**
   * Cast message to standard JavaScript object (all non-primitive values are deeply cloned)
   */
  toObject(): GetLastInvoicedDateResponse.AsObject {
    return {
      lastInvoicedAt: this.lastInvoicedAt
        ? this.lastInvoicedAt.toObject()
        : undefined
    };
  }

  /**
   * Convenience method to support JSON.stringify(message), replicates the structure of toObject()
   */
  toJSON() {
    return this.toObject();
  }

  /**
   * Cast message to JSON using protobuf JSON notation: https://developers.google.com/protocol-buffers/docs/proto3#json
   * Attention: output differs from toObject() e.g. enums are represented as names and not as numbers, Timestamp is an ISO Date string format etc.
   * If the message itself or some of descendant messages is google.protobuf.Any, you MUST provide a message pool as options. If not, the messagePool is not required
   */
  toProtobufJSON(
    // @ts-ignore
    options?: ToProtobufJSONOptions
  ): GetLastInvoicedDateResponse.AsProtobufJSON {
    return {
      lastInvoicedAt: this.lastInvoicedAt
        ? this.lastInvoicedAt.toProtobufJSON(options)
        : null
    };
  }
}
export module GetLastInvoicedDateResponse {
  /**
   * Standard JavaScript object representation for GetLastInvoicedDateResponse
   */
  export interface AsObject {
    lastInvoicedAt?: googleProtobuf000.Timestamp.AsObject;
  }

  /**
   * Protobuf JSON representation for GetLastInvoicedDateResponse
   */
  export interface AsProtobufJSON {
    lastInvoicedAt?: googleProtobuf000.Timestamp.AsProtobufJSON | null;
  }
}

/**
 * Message implementation for modulebms.customeradmin.SendWelcomeMailRequest
 */
export class SendWelcomeMailRequest implements GrpcMessage {
  static id = 'modulebms.customeradmin.SendWelcomeMailRequest';

  /**
   * Deserialize binary data to message
   * @param instance message instance
   */
  static deserializeBinary(bytes: ByteSource) {
    const instance = new SendWelcomeMailRequest();
    SendWelcomeMailRequest.deserializeBinaryFromReader(
      instance,
      new BinaryReader(bytes)
    );
    return instance;
  }

  /**
   * Check all the properties and set default protobuf values if necessary
   * @param _instance message instance
   */
  static refineValues(_instance: SendWelcomeMailRequest) {
    _instance.customerId = _instance.customerId || '';
    _instance.force = _instance.force || false;
  }

  /**
   * Deserializes / reads binary message into message instance using provided binary reader
   * @param _instance message instance
   * @param _reader binary reader instance
   */
  static deserializeBinaryFromReader(
    _instance: SendWelcomeMailRequest,
    _reader: BinaryReader
  ) {
    while (_reader.nextField()) {
      if (_reader.isEndGroup()) break;

      switch (_reader.getFieldNumber()) {
        case 1:
          _instance.customerId = _reader.readString();
          break;
        case 2:
          _instance.force = _reader.readBool();
          break;
        default:
          _reader.skipField();
      }
    }

    SendWelcomeMailRequest.refineValues(_instance);
  }

  /**
   * Serializes a message to binary format using provided binary reader
   * @param _instance message instance
   * @param _writer binary writer instance
   */
  static serializeBinaryToWriter(
    _instance: SendWelcomeMailRequest,
    _writer: BinaryWriter
  ) {
    if (_instance.customerId) {
      _writer.writeString(1, _instance.customerId);
    }
    if (_instance.force) {
      _writer.writeBool(2, _instance.force);
    }
  }

  private _customerId?: string;
  private _force?: boolean;

  /**
   * Message constructor. Initializes the properties and applies default Protobuf values if necessary
   * @param _value initial values object or instance of SendWelcomeMailRequest to deeply clone from
   */
  constructor(_value?: RecursivePartial<SendWelcomeMailRequest.AsObject>) {
    _value = _value || {};
    this.customerId = _value.customerId;
    this.force = _value.force;
    SendWelcomeMailRequest.refineValues(this);
  }
  get customerId(): string | undefined {
    return this._customerId;
  }
  set customerId(value: string | undefined) {
    this._customerId = value;
  }
  get force(): boolean | undefined {
    return this._force;
  }
  set force(value: boolean | undefined) {
    this._force = value;
  }

  /**
   * Serialize message to binary data
   * @param instance message instance
   */
  serializeBinary() {
    const writer = new BinaryWriter();
    SendWelcomeMailRequest.serializeBinaryToWriter(this, writer);
    return writer.getResultBuffer();
  }

  /**
   * Cast message to standard JavaScript object (all non-primitive values are deeply cloned)
   */
  toObject(): SendWelcomeMailRequest.AsObject {
    return {
      customerId: this.customerId,
      force: this.force
    };
  }

  /**
   * Convenience method to support JSON.stringify(message), replicates the structure of toObject()
   */
  toJSON() {
    return this.toObject();
  }

  /**
   * Cast message to JSON using protobuf JSON notation: https://developers.google.com/protocol-buffers/docs/proto3#json
   * Attention: output differs from toObject() e.g. enums are represented as names and not as numbers, Timestamp is an ISO Date string format etc.
   * If the message itself or some of descendant messages is google.protobuf.Any, you MUST provide a message pool as options. If not, the messagePool is not required
   */
  toProtobufJSON(
    // @ts-ignore
    options?: ToProtobufJSONOptions
  ): SendWelcomeMailRequest.AsProtobufJSON {
    return {
      customerId: this.customerId,
      force: this.force
    };
  }
}
export module SendWelcomeMailRequest {
  /**
   * Standard JavaScript object representation for SendWelcomeMailRequest
   */
  export interface AsObject {
    customerId?: string;
    force?: boolean;
  }

  /**
   * Protobuf JSON representation for SendWelcomeMailRequest
   */
  export interface AsProtobufJSON {
    customerId?: string;
    force?: boolean;
  }
}

/**
 * Message implementation for modulebms.customeradmin.SendWelcomeMailResponse
 */
export class SendWelcomeMailResponse implements GrpcMessage {
  static id = 'modulebms.customeradmin.SendWelcomeMailResponse';

  /**
   * Deserialize binary data to message
   * @param instance message instance
   */
  static deserializeBinary(bytes: ByteSource) {
    const instance = new SendWelcomeMailResponse();
    SendWelcomeMailResponse.deserializeBinaryFromReader(
      instance,
      new BinaryReader(bytes)
    );
    return instance;
  }

  /**
   * Check all the properties and set default protobuf values if necessary
   * @param _instance message instance
   */
  static refineValues(_instance: SendWelcomeMailResponse) {
    _instance.ok = _instance.ok || false;
    _instance.message = _instance.message || '';
    _instance.sentTo = _instance.sentTo || [];
  }

  /**
   * Deserializes / reads binary message into message instance using provided binary reader
   * @param _instance message instance
   * @param _reader binary reader instance
   */
  static deserializeBinaryFromReader(
    _instance: SendWelcomeMailResponse,
    _reader: BinaryReader
  ) {
    while (_reader.nextField()) {
      if (_reader.isEndGroup()) break;

      switch (_reader.getFieldNumber()) {
        case 1:
          _instance.ok = _reader.readBool();
          break;
        case 2:
          _instance.message = _reader.readString();
          break;
        case 3:
          (_instance.sentTo = _instance.sentTo || []).push(
            _reader.readString()
          );
          break;
        default:
          _reader.skipField();
      }
    }

    SendWelcomeMailResponse.refineValues(_instance);
  }

  /**
   * Serializes a message to binary format using provided binary reader
   * @param _instance message instance
   * @param _writer binary writer instance
   */
  static serializeBinaryToWriter(
    _instance: SendWelcomeMailResponse,
    _writer: BinaryWriter
  ) {
    if (_instance.ok) {
      _writer.writeBool(1, _instance.ok);
    }
    if (_instance.message) {
      _writer.writeString(2, _instance.message);
    }
    if (_instance.sentTo && _instance.sentTo.length) {
      _writer.writeRepeatedString(3, _instance.sentTo);
    }
  }

  private _ok?: boolean;
  private _message?: string;
  private _sentTo?: string[];

  /**
   * Message constructor. Initializes the properties and applies default Protobuf values if necessary
   * @param _value initial values object or instance of SendWelcomeMailResponse to deeply clone from
   */
  constructor(_value?: RecursivePartial<SendWelcomeMailResponse.AsObject>) {
    _value = _value || {};
    this.ok = _value.ok;
    this.message = _value.message;
    this.sentTo = (_value.sentTo || []).slice();
    SendWelcomeMailResponse.refineValues(this);
  }
  get ok(): boolean | undefined {
    return this._ok;
  }
  set ok(value: boolean | undefined) {
    this._ok = value;
  }
  get message(): string | undefined {
    return this._message;
  }
  set message(value: string | undefined) {
    this._message = value;
  }
  get sentTo(): string[] | undefined {
    return this._sentTo;
  }
  set sentTo(value: string[] | undefined) {
    this._sentTo = value;
  }

  /**
   * Serialize message to binary data
   * @param instance message instance
   */
  serializeBinary() {
    const writer = new BinaryWriter();
    SendWelcomeMailResponse.serializeBinaryToWriter(this, writer);
    return writer.getResultBuffer();
  }

  /**
   * Cast message to standard JavaScript object (all non-primitive values are deeply cloned)
   */
  toObject(): SendWelcomeMailResponse.AsObject {
    return {
      ok: this.ok,
      message: this.message,
      sentTo: (this.sentTo || []).slice()
    };
  }

  /**
   * Convenience method to support JSON.stringify(message), replicates the structure of toObject()
   */
  toJSON() {
    return this.toObject();
  }

  /**
   * Cast message to JSON using protobuf JSON notation: https://developers.google.com/protocol-buffers/docs/proto3#json
   * Attention: output differs from toObject() e.g. enums are represented as names and not as numbers, Timestamp is an ISO Date string format etc.
   * If the message itself or some of descendant messages is google.protobuf.Any, you MUST provide a message pool as options. If not, the messagePool is not required
   */
  toProtobufJSON(
    // @ts-ignore
    options?: ToProtobufJSONOptions
  ): SendWelcomeMailResponse.AsProtobufJSON {
    return {
      ok: this.ok,
      message: this.message,
      sentTo: (this.sentTo || []).slice()
    };
  }
}
export module SendWelcomeMailResponse {
  /**
   * Standard JavaScript object representation for SendWelcomeMailResponse
   */
  export interface AsObject {
    ok?: boolean;
    message?: string;
    sentTo?: string[];
  }

  /**
   * Protobuf JSON representation for SendWelcomeMailResponse
   */
  export interface AsProtobufJSON {
    ok?: boolean;
    message?: string;
    sentTo?: string[];
  }
}

/**
 * Message implementation for modulebms.customeradmin.SendCustomerMailRequest
 */
export class SendCustomerMailRequest implements GrpcMessage {
  static id = 'modulebms.customeradmin.SendCustomerMailRequest';

  /**
   * Deserialize binary data to message
   * @param instance message instance
   */
  static deserializeBinary(bytes: ByteSource) {
    const instance = new SendCustomerMailRequest();
    SendCustomerMailRequest.deserializeBinaryFromReader(
      instance,
      new BinaryReader(bytes)
    );
    return instance;
  }

  /**
   * Check all the properties and set default protobuf values if necessary
   * @param _instance message instance
   */
  static refineValues(_instance: SendCustomerMailRequest) {
    _instance.customerId = _instance.customerId || '';
    _instance.force = _instance.force || false;
    _instance.mailId = _instance.mailId || '';
    _instance.data = _instance.data || '';
  }

  /**
   * Deserializes / reads binary message into message instance using provided binary reader
   * @param _instance message instance
   * @param _reader binary reader instance
   */
  static deserializeBinaryFromReader(
    _instance: SendCustomerMailRequest,
    _reader: BinaryReader
  ) {
    while (_reader.nextField()) {
      if (_reader.isEndGroup()) break;

      switch (_reader.getFieldNumber()) {
        case 1:
          _instance.customerId = _reader.readString();
          break;
        case 2:
          _instance.force = _reader.readBool();
          break;
        case 3:
          _instance.mailId = _reader.readString();
          break;
        case 4:
          _instance.data = _reader.readString();
          break;
        default:
          _reader.skipField();
      }
    }

    SendCustomerMailRequest.refineValues(_instance);
  }

  /**
   * Serializes a message to binary format using provided binary reader
   * @param _instance message instance
   * @param _writer binary writer instance
   */
  static serializeBinaryToWriter(
    _instance: SendCustomerMailRequest,
    _writer: BinaryWriter
  ) {
    if (_instance.customerId) {
      _writer.writeString(1, _instance.customerId);
    }
    if (_instance.force) {
      _writer.writeBool(2, _instance.force);
    }
    if (_instance.mailId) {
      _writer.writeString(3, _instance.mailId);
    }
    if (_instance.data) {
      _writer.writeString(4, _instance.data);
    }
  }

  private _customerId?: string;
  private _force?: boolean;
  private _mailId?: string;
  private _data?: string;

  /**
   * Message constructor. Initializes the properties and applies default Protobuf values if necessary
   * @param _value initial values object or instance of SendCustomerMailRequest to deeply clone from
   */
  constructor(_value?: RecursivePartial<SendCustomerMailRequest.AsObject>) {
    _value = _value || {};
    this.customerId = _value.customerId;
    this.force = _value.force;
    this.mailId = _value.mailId;
    this.data = _value.data;
    SendCustomerMailRequest.refineValues(this);
  }
  get customerId(): string | undefined {
    return this._customerId;
  }
  set customerId(value: string | undefined) {
    this._customerId = value;
  }
  get force(): boolean | undefined {
    return this._force;
  }
  set force(value: boolean | undefined) {
    this._force = value;
  }
  get mailId(): string | undefined {
    return this._mailId;
  }
  set mailId(value: string | undefined) {
    this._mailId = value;
  }
  get data(): string | undefined {
    return this._data;
  }
  set data(value: string | undefined) {
    this._data = value;
  }

  /**
   * Serialize message to binary data
   * @param instance message instance
   */
  serializeBinary() {
    const writer = new BinaryWriter();
    SendCustomerMailRequest.serializeBinaryToWriter(this, writer);
    return writer.getResultBuffer();
  }

  /**
   * Cast message to standard JavaScript object (all non-primitive values are deeply cloned)
   */
  toObject(): SendCustomerMailRequest.AsObject {
    return {
      customerId: this.customerId,
      force: this.force,
      mailId: this.mailId,
      data: this.data
    };
  }

  /**
   * Convenience method to support JSON.stringify(message), replicates the structure of toObject()
   */
  toJSON() {
    return this.toObject();
  }

  /**
   * Cast message to JSON using protobuf JSON notation: https://developers.google.com/protocol-buffers/docs/proto3#json
   * Attention: output differs from toObject() e.g. enums are represented as names and not as numbers, Timestamp is an ISO Date string format etc.
   * If the message itself or some of descendant messages is google.protobuf.Any, you MUST provide a message pool as options. If not, the messagePool is not required
   */
  toProtobufJSON(
    // @ts-ignore
    options?: ToProtobufJSONOptions
  ): SendCustomerMailRequest.AsProtobufJSON {
    return {
      customerId: this.customerId,
      force: this.force,
      mailId: this.mailId,
      data: this.data
    };
  }
}
export module SendCustomerMailRequest {
  /**
   * Standard JavaScript object representation for SendCustomerMailRequest
   */
  export interface AsObject {
    customerId?: string;
    force?: boolean;
    mailId?: string;
    data?: string;
  }

  /**
   * Protobuf JSON representation for SendCustomerMailRequest
   */
  export interface AsProtobufJSON {
    customerId?: string;
    force?: boolean;
    mailId?: string;
    data?: string;
  }
}

/**
 * Message implementation for modulebms.customeradmin.SendCustomerMailResponse
 */
export class SendCustomerMailResponse implements GrpcMessage {
  static id = 'modulebms.customeradmin.SendCustomerMailResponse';

  /**
   * Deserialize binary data to message
   * @param instance message instance
   */
  static deserializeBinary(bytes: ByteSource) {
    const instance = new SendCustomerMailResponse();
    SendCustomerMailResponse.deserializeBinaryFromReader(
      instance,
      new BinaryReader(bytes)
    );
    return instance;
  }

  /**
   * Check all the properties and set default protobuf values if necessary
   * @param _instance message instance
   */
  static refineValues(_instance: SendCustomerMailResponse) {
    _instance.ok = _instance.ok || false;
    _instance.message = _instance.message || '';
    _instance.sentTo = _instance.sentTo || [];
  }

  /**
   * Deserializes / reads binary message into message instance using provided binary reader
   * @param _instance message instance
   * @param _reader binary reader instance
   */
  static deserializeBinaryFromReader(
    _instance: SendCustomerMailResponse,
    _reader: BinaryReader
  ) {
    while (_reader.nextField()) {
      if (_reader.isEndGroup()) break;

      switch (_reader.getFieldNumber()) {
        case 1:
          _instance.ok = _reader.readBool();
          break;
        case 2:
          _instance.message = _reader.readString();
          break;
        case 3:
          (_instance.sentTo = _instance.sentTo || []).push(
            _reader.readString()
          );
          break;
        default:
          _reader.skipField();
      }
    }

    SendCustomerMailResponse.refineValues(_instance);
  }

  /**
   * Serializes a message to binary format using provided binary reader
   * @param _instance message instance
   * @param _writer binary writer instance
   */
  static serializeBinaryToWriter(
    _instance: SendCustomerMailResponse,
    _writer: BinaryWriter
  ) {
    if (_instance.ok) {
      _writer.writeBool(1, _instance.ok);
    }
    if (_instance.message) {
      _writer.writeString(2, _instance.message);
    }
    if (_instance.sentTo && _instance.sentTo.length) {
      _writer.writeRepeatedString(3, _instance.sentTo);
    }
  }

  private _ok?: boolean;
  private _message?: string;
  private _sentTo?: string[];

  /**
   * Message constructor. Initializes the properties and applies default Protobuf values if necessary
   * @param _value initial values object or instance of SendCustomerMailResponse to deeply clone from
   */
  constructor(_value?: RecursivePartial<SendCustomerMailResponse.AsObject>) {
    _value = _value || {};
    this.ok = _value.ok;
    this.message = _value.message;
    this.sentTo = (_value.sentTo || []).slice();
    SendCustomerMailResponse.refineValues(this);
  }
  get ok(): boolean | undefined {
    return this._ok;
  }
  set ok(value: boolean | undefined) {
    this._ok = value;
  }
  get message(): string | undefined {
    return this._message;
  }
  set message(value: string | undefined) {
    this._message = value;
  }
  get sentTo(): string[] | undefined {
    return this._sentTo;
  }
  set sentTo(value: string[] | undefined) {
    this._sentTo = value;
  }

  /**
   * Serialize message to binary data
   * @param instance message instance
   */
  serializeBinary() {
    const writer = new BinaryWriter();
    SendCustomerMailResponse.serializeBinaryToWriter(this, writer);
    return writer.getResultBuffer();
  }

  /**
   * Cast message to standard JavaScript object (all non-primitive values are deeply cloned)
   */
  toObject(): SendCustomerMailResponse.AsObject {
    return {
      ok: this.ok,
      message: this.message,
      sentTo: (this.sentTo || []).slice()
    };
  }

  /**
   * Convenience method to support JSON.stringify(message), replicates the structure of toObject()
   */
  toJSON() {
    return this.toObject();
  }

  /**
   * Cast message to JSON using protobuf JSON notation: https://developers.google.com/protocol-buffers/docs/proto3#json
   * Attention: output differs from toObject() e.g. enums are represented as names and not as numbers, Timestamp is an ISO Date string format etc.
   * If the message itself or some of descendant messages is google.protobuf.Any, you MUST provide a message pool as options. If not, the messagePool is not required
   */
  toProtobufJSON(
    // @ts-ignore
    options?: ToProtobufJSONOptions
  ): SendCustomerMailResponse.AsProtobufJSON {
    return {
      ok: this.ok,
      message: this.message,
      sentTo: (this.sentTo || []).slice()
    };
  }
}
export module SendCustomerMailResponse {
  /**
   * Standard JavaScript object representation for SendCustomerMailResponse
   */
  export interface AsObject {
    ok?: boolean;
    message?: string;
    sentTo?: string[];
  }

  /**
   * Protobuf JSON representation for SendCustomerMailResponse
   */
  export interface AsProtobufJSON {
    ok?: boolean;
    message?: string;
    sentTo?: string[];
  }
}

/**
 * Message implementation for modulebms.customeradmin.CreteTariffGroupRequest
 */
export class CreteTariffGroupRequest implements GrpcMessage {
  static id = 'modulebms.customeradmin.CreteTariffGroupRequest';

  /**
   * Deserialize binary data to message
   * @param instance message instance
   */
  static deserializeBinary(bytes: ByteSource) {
    const instance = new CreteTariffGroupRequest();
    CreteTariffGroupRequest.deserializeBinaryFromReader(
      instance,
      new BinaryReader(bytes)
    );
    return instance;
  }

  /**
   * Check all the properties and set default protobuf values if necessary
   * @param _instance message instance
   */
  static refineValues(_instance: CreteTariffGroupRequest) {
    _instance.customerId = _instance.customerId || '';
    _instance.tariffGroup = _instance.tariffGroup || undefined;
  }

  /**
   * Deserializes / reads binary message into message instance using provided binary reader
   * @param _instance message instance
   * @param _reader binary reader instance
   */
  static deserializeBinaryFromReader(
    _instance: CreteTariffGroupRequest,
    _reader: BinaryReader
  ) {
    while (_reader.nextField()) {
      if (_reader.isEndGroup()) break;

      switch (_reader.getFieldNumber()) {
        case 1:
          _instance.customerId = _reader.readString();
          break;
        case 2:
          _instance.tariffGroup = new TariffGroupInfo();
          _reader.readMessage(
            _instance.tariffGroup,
            TariffGroupInfo.deserializeBinaryFromReader
          );
          break;
        default:
          _reader.skipField();
      }
    }

    CreteTariffGroupRequest.refineValues(_instance);
  }

  /**
   * Serializes a message to binary format using provided binary reader
   * @param _instance message instance
   * @param _writer binary writer instance
   */
  static serializeBinaryToWriter(
    _instance: CreteTariffGroupRequest,
    _writer: BinaryWriter
  ) {
    if (_instance.customerId) {
      _writer.writeString(1, _instance.customerId);
    }
    if (_instance.tariffGroup) {
      _writer.writeMessage(
        2,
        _instance.tariffGroup as any,
        TariffGroupInfo.serializeBinaryToWriter
      );
    }
  }

  private _customerId?: string;
  private _tariffGroup?: TariffGroupInfo;

  /**
   * Message constructor. Initializes the properties and applies default Protobuf values if necessary
   * @param _value initial values object or instance of CreteTariffGroupRequest to deeply clone from
   */
  constructor(_value?: RecursivePartial<CreteTariffGroupRequest.AsObject>) {
    _value = _value || {};
    this.customerId = _value.customerId;
    this.tariffGroup = _value.tariffGroup
      ? new TariffGroupInfo(_value.tariffGroup)
      : undefined;
    CreteTariffGroupRequest.refineValues(this);
  }
  get customerId(): string | undefined {
    return this._customerId;
  }
  set customerId(value: string | undefined) {
    this._customerId = value;
  }
  get tariffGroup(): TariffGroupInfo | undefined {
    return this._tariffGroup;
  }
  set tariffGroup(value: TariffGroupInfo | undefined) {
    this._tariffGroup = value;
  }

  /**
   * Serialize message to binary data
   * @param instance message instance
   */
  serializeBinary() {
    const writer = new BinaryWriter();
    CreteTariffGroupRequest.serializeBinaryToWriter(this, writer);
    return writer.getResultBuffer();
  }

  /**
   * Cast message to standard JavaScript object (all non-primitive values are deeply cloned)
   */
  toObject(): CreteTariffGroupRequest.AsObject {
    return {
      customerId: this.customerId,
      tariffGroup: this.tariffGroup ? this.tariffGroup.toObject() : undefined
    };
  }

  /**
   * Convenience method to support JSON.stringify(message), replicates the structure of toObject()
   */
  toJSON() {
    return this.toObject();
  }

  /**
   * Cast message to JSON using protobuf JSON notation: https://developers.google.com/protocol-buffers/docs/proto3#json
   * Attention: output differs from toObject() e.g. enums are represented as names and not as numbers, Timestamp is an ISO Date string format etc.
   * If the message itself or some of descendant messages is google.protobuf.Any, you MUST provide a message pool as options. If not, the messagePool is not required
   */
  toProtobufJSON(
    // @ts-ignore
    options?: ToProtobufJSONOptions
  ): CreteTariffGroupRequest.AsProtobufJSON {
    return {
      customerId: this.customerId,
      tariffGroup: this.tariffGroup
        ? this.tariffGroup.toProtobufJSON(options)
        : null
    };
  }
}
export module CreteTariffGroupRequest {
  /**
   * Standard JavaScript object representation for CreteTariffGroupRequest
   */
  export interface AsObject {
    customerId?: string;
    tariffGroup?: TariffGroupInfo.AsObject;
  }

  /**
   * Protobuf JSON representation for CreteTariffGroupRequest
   */
  export interface AsProtobufJSON {
    customerId?: string;
    tariffGroup?: TariffGroupInfo.AsProtobufJSON | null;
  }
}

/**
 * Message implementation for modulebms.customeradmin.CreateTariffGroupResponse
 */
export class CreateTariffGroupResponse implements GrpcMessage {
  static id = 'modulebms.customeradmin.CreateTariffGroupResponse';

  /**
   * Deserialize binary data to message
   * @param instance message instance
   */
  static deserializeBinary(bytes: ByteSource) {
    const instance = new CreateTariffGroupResponse();
    CreateTariffGroupResponse.deserializeBinaryFromReader(
      instance,
      new BinaryReader(bytes)
    );
    return instance;
  }

  /**
   * Check all the properties and set default protobuf values if necessary
   * @param _instance message instance
   */
  static refineValues(_instance: CreateTariffGroupResponse) {
    _instance.ok = _instance.ok || false;
    _instance.message = _instance.message || '';
  }

  /**
   * Deserializes / reads binary message into message instance using provided binary reader
   * @param _instance message instance
   * @param _reader binary reader instance
   */
  static deserializeBinaryFromReader(
    _instance: CreateTariffGroupResponse,
    _reader: BinaryReader
  ) {
    while (_reader.nextField()) {
      if (_reader.isEndGroup()) break;

      switch (_reader.getFieldNumber()) {
        case 1:
          _instance.ok = _reader.readBool();
          break;
        case 2:
          _instance.message = _reader.readString();
          break;
        default:
          _reader.skipField();
      }
    }

    CreateTariffGroupResponse.refineValues(_instance);
  }

  /**
   * Serializes a message to binary format using provided binary reader
   * @param _instance message instance
   * @param _writer binary writer instance
   */
  static serializeBinaryToWriter(
    _instance: CreateTariffGroupResponse,
    _writer: BinaryWriter
  ) {
    if (_instance.ok) {
      _writer.writeBool(1, _instance.ok);
    }
    if (_instance.message) {
      _writer.writeString(2, _instance.message);
    }
  }

  private _ok?: boolean;
  private _message?: string;

  /**
   * Message constructor. Initializes the properties and applies default Protobuf values if necessary
   * @param _value initial values object or instance of CreateTariffGroupResponse to deeply clone from
   */
  constructor(_value?: RecursivePartial<CreateTariffGroupResponse.AsObject>) {
    _value = _value || {};
    this.ok = _value.ok;
    this.message = _value.message;
    CreateTariffGroupResponse.refineValues(this);
  }
  get ok(): boolean | undefined {
    return this._ok;
  }
  set ok(value: boolean | undefined) {
    this._ok = value;
  }
  get message(): string | undefined {
    return this._message;
  }
  set message(value: string | undefined) {
    this._message = value;
  }

  /**
   * Serialize message to binary data
   * @param instance message instance
   */
  serializeBinary() {
    const writer = new BinaryWriter();
    CreateTariffGroupResponse.serializeBinaryToWriter(this, writer);
    return writer.getResultBuffer();
  }

  /**
   * Cast message to standard JavaScript object (all non-primitive values are deeply cloned)
   */
  toObject(): CreateTariffGroupResponse.AsObject {
    return {
      ok: this.ok,
      message: this.message
    };
  }

  /**
   * Convenience method to support JSON.stringify(message), replicates the structure of toObject()
   */
  toJSON() {
    return this.toObject();
  }

  /**
   * Cast message to JSON using protobuf JSON notation: https://developers.google.com/protocol-buffers/docs/proto3#json
   * Attention: output differs from toObject() e.g. enums are represented as names and not as numbers, Timestamp is an ISO Date string format etc.
   * If the message itself or some of descendant messages is google.protobuf.Any, you MUST provide a message pool as options. If not, the messagePool is not required
   */
  toProtobufJSON(
    // @ts-ignore
    options?: ToProtobufJSONOptions
  ): CreateTariffGroupResponse.AsProtobufJSON {
    return {
      ok: this.ok,
      message: this.message
    };
  }
}
export module CreateTariffGroupResponse {
  /**
   * Standard JavaScript object representation for CreateTariffGroupResponse
   */
  export interface AsObject {
    ok?: boolean;
    message?: string;
  }

  /**
   * Protobuf JSON representation for CreateTariffGroupResponse
   */
  export interface AsProtobufJSON {
    ok?: boolean;
    message?: string;
  }
}

/**
 * Message implementation for modulebms.customeradmin.UpdateTariffGroupRequest
 */
export class UpdateTariffGroupRequest implements GrpcMessage {
  static id = 'modulebms.customeradmin.UpdateTariffGroupRequest';

  /**
   * Deserialize binary data to message
   * @param instance message instance
   */
  static deserializeBinary(bytes: ByteSource) {
    const instance = new UpdateTariffGroupRequest();
    UpdateTariffGroupRequest.deserializeBinaryFromReader(
      instance,
      new BinaryReader(bytes)
    );
    return instance;
  }

  /**
   * Check all the properties and set default protobuf values if necessary
   * @param _instance message instance
   */
  static refineValues(_instance: UpdateTariffGroupRequest) {
    _instance.customerId = _instance.customerId || '';
    _instance.tariffGroup = _instance.tariffGroup || undefined;
  }

  /**
   * Deserializes / reads binary message into message instance using provided binary reader
   * @param _instance message instance
   * @param _reader binary reader instance
   */
  static deserializeBinaryFromReader(
    _instance: UpdateTariffGroupRequest,
    _reader: BinaryReader
  ) {
    while (_reader.nextField()) {
      if (_reader.isEndGroup()) break;

      switch (_reader.getFieldNumber()) {
        case 1:
          _instance.customerId = _reader.readString();
          break;
        case 2:
          _instance.tariffGroup = new TariffGroupInfo();
          _reader.readMessage(
            _instance.tariffGroup,
            TariffGroupInfo.deserializeBinaryFromReader
          );
          break;
        default:
          _reader.skipField();
      }
    }

    UpdateTariffGroupRequest.refineValues(_instance);
  }

  /**
   * Serializes a message to binary format using provided binary reader
   * @param _instance message instance
   * @param _writer binary writer instance
   */
  static serializeBinaryToWriter(
    _instance: UpdateTariffGroupRequest,
    _writer: BinaryWriter
  ) {
    if (_instance.customerId) {
      _writer.writeString(1, _instance.customerId);
    }
    if (_instance.tariffGroup) {
      _writer.writeMessage(
        2,
        _instance.tariffGroup as any,
        TariffGroupInfo.serializeBinaryToWriter
      );
    }
  }

  private _customerId?: string;
  private _tariffGroup?: TariffGroupInfo;

  /**
   * Message constructor. Initializes the properties and applies default Protobuf values if necessary
   * @param _value initial values object or instance of UpdateTariffGroupRequest to deeply clone from
   */
  constructor(_value?: RecursivePartial<UpdateTariffGroupRequest.AsObject>) {
    _value = _value || {};
    this.customerId = _value.customerId;
    this.tariffGroup = _value.tariffGroup
      ? new TariffGroupInfo(_value.tariffGroup)
      : undefined;
    UpdateTariffGroupRequest.refineValues(this);
  }
  get customerId(): string | undefined {
    return this._customerId;
  }
  set customerId(value: string | undefined) {
    this._customerId = value;
  }
  get tariffGroup(): TariffGroupInfo | undefined {
    return this._tariffGroup;
  }
  set tariffGroup(value: TariffGroupInfo | undefined) {
    this._tariffGroup = value;
  }

  /**
   * Serialize message to binary data
   * @param instance message instance
   */
  serializeBinary() {
    const writer = new BinaryWriter();
    UpdateTariffGroupRequest.serializeBinaryToWriter(this, writer);
    return writer.getResultBuffer();
  }

  /**
   * Cast message to standard JavaScript object (all non-primitive values are deeply cloned)
   */
  toObject(): UpdateTariffGroupRequest.AsObject {
    return {
      customerId: this.customerId,
      tariffGroup: this.tariffGroup ? this.tariffGroup.toObject() : undefined
    };
  }

  /**
   * Convenience method to support JSON.stringify(message), replicates the structure of toObject()
   */
  toJSON() {
    return this.toObject();
  }

  /**
   * Cast message to JSON using protobuf JSON notation: https://developers.google.com/protocol-buffers/docs/proto3#json
   * Attention: output differs from toObject() e.g. enums are represented as names and not as numbers, Timestamp is an ISO Date string format etc.
   * If the message itself or some of descendant messages is google.protobuf.Any, you MUST provide a message pool as options. If not, the messagePool is not required
   */
  toProtobufJSON(
    // @ts-ignore
    options?: ToProtobufJSONOptions
  ): UpdateTariffGroupRequest.AsProtobufJSON {
    return {
      customerId: this.customerId,
      tariffGroup: this.tariffGroup
        ? this.tariffGroup.toProtobufJSON(options)
        : null
    };
  }
}
export module UpdateTariffGroupRequest {
  /**
   * Standard JavaScript object representation for UpdateTariffGroupRequest
   */
  export interface AsObject {
    customerId?: string;
    tariffGroup?: TariffGroupInfo.AsObject;
  }

  /**
   * Protobuf JSON representation for UpdateTariffGroupRequest
   */
  export interface AsProtobufJSON {
    customerId?: string;
    tariffGroup?: TariffGroupInfo.AsProtobufJSON | null;
  }
}

/**
 * Message implementation for modulebms.customeradmin.UpdateTariffGroupResponse
 */
export class UpdateTariffGroupResponse implements GrpcMessage {
  static id = 'modulebms.customeradmin.UpdateTariffGroupResponse';

  /**
   * Deserialize binary data to message
   * @param instance message instance
   */
  static deserializeBinary(bytes: ByteSource) {
    const instance = new UpdateTariffGroupResponse();
    UpdateTariffGroupResponse.deserializeBinaryFromReader(
      instance,
      new BinaryReader(bytes)
    );
    return instance;
  }

  /**
   * Check all the properties and set default protobuf values if necessary
   * @param _instance message instance
   */
  static refineValues(_instance: UpdateTariffGroupResponse) {
    _instance.ok = _instance.ok || false;
    _instance.message = _instance.message || '';
  }

  /**
   * Deserializes / reads binary message into message instance using provided binary reader
   * @param _instance message instance
   * @param _reader binary reader instance
   */
  static deserializeBinaryFromReader(
    _instance: UpdateTariffGroupResponse,
    _reader: BinaryReader
  ) {
    while (_reader.nextField()) {
      if (_reader.isEndGroup()) break;

      switch (_reader.getFieldNumber()) {
        case 1:
          _instance.ok = _reader.readBool();
          break;
        case 2:
          _instance.message = _reader.readString();
          break;
        default:
          _reader.skipField();
      }
    }

    UpdateTariffGroupResponse.refineValues(_instance);
  }

  /**
   * Serializes a message to binary format using provided binary reader
   * @param _instance message instance
   * @param _writer binary writer instance
   */
  static serializeBinaryToWriter(
    _instance: UpdateTariffGroupResponse,
    _writer: BinaryWriter
  ) {
    if (_instance.ok) {
      _writer.writeBool(1, _instance.ok);
    }
    if (_instance.message) {
      _writer.writeString(2, _instance.message);
    }
  }

  private _ok?: boolean;
  private _message?: string;

  /**
   * Message constructor. Initializes the properties and applies default Protobuf values if necessary
   * @param _value initial values object or instance of UpdateTariffGroupResponse to deeply clone from
   */
  constructor(_value?: RecursivePartial<UpdateTariffGroupResponse.AsObject>) {
    _value = _value || {};
    this.ok = _value.ok;
    this.message = _value.message;
    UpdateTariffGroupResponse.refineValues(this);
  }
  get ok(): boolean | undefined {
    return this._ok;
  }
  set ok(value: boolean | undefined) {
    this._ok = value;
  }
  get message(): string | undefined {
    return this._message;
  }
  set message(value: string | undefined) {
    this._message = value;
  }

  /**
   * Serialize message to binary data
   * @param instance message instance
   */
  serializeBinary() {
    const writer = new BinaryWriter();
    UpdateTariffGroupResponse.serializeBinaryToWriter(this, writer);
    return writer.getResultBuffer();
  }

  /**
   * Cast message to standard JavaScript object (all non-primitive values are deeply cloned)
   */
  toObject(): UpdateTariffGroupResponse.AsObject {
    return {
      ok: this.ok,
      message: this.message
    };
  }

  /**
   * Convenience method to support JSON.stringify(message), replicates the structure of toObject()
   */
  toJSON() {
    return this.toObject();
  }

  /**
   * Cast message to JSON using protobuf JSON notation: https://developers.google.com/protocol-buffers/docs/proto3#json
   * Attention: output differs from toObject() e.g. enums are represented as names and not as numbers, Timestamp is an ISO Date string format etc.
   * If the message itself or some of descendant messages is google.protobuf.Any, you MUST provide a message pool as options. If not, the messagePool is not required
   */
  toProtobufJSON(
    // @ts-ignore
    options?: ToProtobufJSONOptions
  ): UpdateTariffGroupResponse.AsProtobufJSON {
    return {
      ok: this.ok,
      message: this.message
    };
  }
}
export module UpdateTariffGroupResponse {
  /**
   * Standard JavaScript object representation for UpdateTariffGroupResponse
   */
  export interface AsObject {
    ok?: boolean;
    message?: string;
  }

  /**
   * Protobuf JSON representation for UpdateTariffGroupResponse
   */
  export interface AsProtobufJSON {
    ok?: boolean;
    message?: string;
  }
}

/**
 * Message implementation for modulebms.customeradmin.DeleteTariffGroupRequest
 */
export class DeleteTariffGroupRequest implements GrpcMessage {
  static id = 'modulebms.customeradmin.DeleteTariffGroupRequest';

  /**
   * Deserialize binary data to message
   * @param instance message instance
   */
  static deserializeBinary(bytes: ByteSource) {
    const instance = new DeleteTariffGroupRequest();
    DeleteTariffGroupRequest.deserializeBinaryFromReader(
      instance,
      new BinaryReader(bytes)
    );
    return instance;
  }

  /**
   * Check all the properties and set default protobuf values if necessary
   * @param _instance message instance
   */
  static refineValues(_instance: DeleteTariffGroupRequest) {
    _instance.customerId = _instance.customerId || '';
    _instance.tariffGroupId = _instance.tariffGroupId || '';
  }

  /**
   * Deserializes / reads binary message into message instance using provided binary reader
   * @param _instance message instance
   * @param _reader binary reader instance
   */
  static deserializeBinaryFromReader(
    _instance: DeleteTariffGroupRequest,
    _reader: BinaryReader
  ) {
    while (_reader.nextField()) {
      if (_reader.isEndGroup()) break;

      switch (_reader.getFieldNumber()) {
        case 1:
          _instance.customerId = _reader.readString();
          break;
        case 2:
          _instance.tariffGroupId = _reader.readString();
          break;
        default:
          _reader.skipField();
      }
    }

    DeleteTariffGroupRequest.refineValues(_instance);
  }

  /**
   * Serializes a message to binary format using provided binary reader
   * @param _instance message instance
   * @param _writer binary writer instance
   */
  static serializeBinaryToWriter(
    _instance: DeleteTariffGroupRequest,
    _writer: BinaryWriter
  ) {
    if (_instance.customerId) {
      _writer.writeString(1, _instance.customerId);
    }
    if (_instance.tariffGroupId) {
      _writer.writeString(2, _instance.tariffGroupId);
    }
  }

  private _customerId?: string;
  private _tariffGroupId?: string;

  /**
   * Message constructor. Initializes the properties and applies default Protobuf values if necessary
   * @param _value initial values object or instance of DeleteTariffGroupRequest to deeply clone from
   */
  constructor(_value?: RecursivePartial<DeleteTariffGroupRequest.AsObject>) {
    _value = _value || {};
    this.customerId = _value.customerId;
    this.tariffGroupId = _value.tariffGroupId;
    DeleteTariffGroupRequest.refineValues(this);
  }
  get customerId(): string | undefined {
    return this._customerId;
  }
  set customerId(value: string | undefined) {
    this._customerId = value;
  }
  get tariffGroupId(): string | undefined {
    return this._tariffGroupId;
  }
  set tariffGroupId(value: string | undefined) {
    this._tariffGroupId = value;
  }

  /**
   * Serialize message to binary data
   * @param instance message instance
   */
  serializeBinary() {
    const writer = new BinaryWriter();
    DeleteTariffGroupRequest.serializeBinaryToWriter(this, writer);
    return writer.getResultBuffer();
  }

  /**
   * Cast message to standard JavaScript object (all non-primitive values are deeply cloned)
   */
  toObject(): DeleteTariffGroupRequest.AsObject {
    return {
      customerId: this.customerId,
      tariffGroupId: this.tariffGroupId
    };
  }

  /**
   * Convenience method to support JSON.stringify(message), replicates the structure of toObject()
   */
  toJSON() {
    return this.toObject();
  }

  /**
   * Cast message to JSON using protobuf JSON notation: https://developers.google.com/protocol-buffers/docs/proto3#json
   * Attention: output differs from toObject() e.g. enums are represented as names and not as numbers, Timestamp is an ISO Date string format etc.
   * If the message itself or some of descendant messages is google.protobuf.Any, you MUST provide a message pool as options. If not, the messagePool is not required
   */
  toProtobufJSON(
    // @ts-ignore
    options?: ToProtobufJSONOptions
  ): DeleteTariffGroupRequest.AsProtobufJSON {
    return {
      customerId: this.customerId,
      tariffGroupId: this.tariffGroupId
    };
  }
}
export module DeleteTariffGroupRequest {
  /**
   * Standard JavaScript object representation for DeleteTariffGroupRequest
   */
  export interface AsObject {
    customerId?: string;
    tariffGroupId?: string;
  }

  /**
   * Protobuf JSON representation for DeleteTariffGroupRequest
   */
  export interface AsProtobufJSON {
    customerId?: string;
    tariffGroupId?: string;
  }
}

/**
 * Message implementation for modulebms.customeradmin.DeleteTariffGroupResponse
 */
export class DeleteTariffGroupResponse implements GrpcMessage {
  static id = 'modulebms.customeradmin.DeleteTariffGroupResponse';

  /**
   * Deserialize binary data to message
   * @param instance message instance
   */
  static deserializeBinary(bytes: ByteSource) {
    const instance = new DeleteTariffGroupResponse();
    DeleteTariffGroupResponse.deserializeBinaryFromReader(
      instance,
      new BinaryReader(bytes)
    );
    return instance;
  }

  /**
   * Check all the properties and set default protobuf values if necessary
   * @param _instance message instance
   */
  static refineValues(_instance: DeleteTariffGroupResponse) {
    _instance.ok = _instance.ok || false;
    _instance.message = _instance.message || '';
  }

  /**
   * Deserializes / reads binary message into message instance using provided binary reader
   * @param _instance message instance
   * @param _reader binary reader instance
   */
  static deserializeBinaryFromReader(
    _instance: DeleteTariffGroupResponse,
    _reader: BinaryReader
  ) {
    while (_reader.nextField()) {
      if (_reader.isEndGroup()) break;

      switch (_reader.getFieldNumber()) {
        case 1:
          _instance.ok = _reader.readBool();
          break;
        case 2:
          _instance.message = _reader.readString();
          break;
        default:
          _reader.skipField();
      }
    }

    DeleteTariffGroupResponse.refineValues(_instance);
  }

  /**
   * Serializes a message to binary format using provided binary reader
   * @param _instance message instance
   * @param _writer binary writer instance
   */
  static serializeBinaryToWriter(
    _instance: DeleteTariffGroupResponse,
    _writer: BinaryWriter
  ) {
    if (_instance.ok) {
      _writer.writeBool(1, _instance.ok);
    }
    if (_instance.message) {
      _writer.writeString(2, _instance.message);
    }
  }

  private _ok?: boolean;
  private _message?: string;

  /**
   * Message constructor. Initializes the properties and applies default Protobuf values if necessary
   * @param _value initial values object or instance of DeleteTariffGroupResponse to deeply clone from
   */
  constructor(_value?: RecursivePartial<DeleteTariffGroupResponse.AsObject>) {
    _value = _value || {};
    this.ok = _value.ok;
    this.message = _value.message;
    DeleteTariffGroupResponse.refineValues(this);
  }
  get ok(): boolean | undefined {
    return this._ok;
  }
  set ok(value: boolean | undefined) {
    this._ok = value;
  }
  get message(): string | undefined {
    return this._message;
  }
  set message(value: string | undefined) {
    this._message = value;
  }

  /**
   * Serialize message to binary data
   * @param instance message instance
   */
  serializeBinary() {
    const writer = new BinaryWriter();
    DeleteTariffGroupResponse.serializeBinaryToWriter(this, writer);
    return writer.getResultBuffer();
  }

  /**
   * Cast message to standard JavaScript object (all non-primitive values are deeply cloned)
   */
  toObject(): DeleteTariffGroupResponse.AsObject {
    return {
      ok: this.ok,
      message: this.message
    };
  }

  /**
   * Convenience method to support JSON.stringify(message), replicates the structure of toObject()
   */
  toJSON() {
    return this.toObject();
  }

  /**
   * Cast message to JSON using protobuf JSON notation: https://developers.google.com/protocol-buffers/docs/proto3#json
   * Attention: output differs from toObject() e.g. enums are represented as names and not as numbers, Timestamp is an ISO Date string format etc.
   * If the message itself or some of descendant messages is google.protobuf.Any, you MUST provide a message pool as options. If not, the messagePool is not required
   */
  toProtobufJSON(
    // @ts-ignore
    options?: ToProtobufJSONOptions
  ): DeleteTariffGroupResponse.AsProtobufJSON {
    return {
      ok: this.ok,
      message: this.message
    };
  }
}
export module DeleteTariffGroupResponse {
  /**
   * Standard JavaScript object representation for DeleteTariffGroupResponse
   */
  export interface AsObject {
    ok?: boolean;
    message?: string;
  }

  /**
   * Protobuf JSON representation for DeleteTariffGroupResponse
   */
  export interface AsProtobufJSON {
    ok?: boolean;
    message?: string;
  }
}

/**
 * Message implementation for modulebms.customeradmin.GetMetersRequest
 */
export class GetMetersRequest implements GrpcMessage {
  static id = 'modulebms.customeradmin.GetMetersRequest';

  /**
   * Deserialize binary data to message
   * @param instance message instance
   */
  static deserializeBinary(bytes: ByteSource) {
    const instance = new GetMetersRequest();
    GetMetersRequest.deserializeBinaryFromReader(
      instance,
      new BinaryReader(bytes)
    );
    return instance;
  }

  /**
   * Check all the properties and set default protobuf values if necessary
   * @param _instance message instance
   */
  static refineValues(_instance: GetMetersRequest) {
    _instance.customerId = _instance.customerId || '';
    _instance.sourceNodeId = _instance.sourceNodeId || '';
    _instance.sourceNodeTypeLabel = _instance.sourceNodeTypeLabel || '';
    _instance.meterTypes = _instance.meterTypes || [];
    _instance.facilityType = _instance.facilityType || '';
    _instance.includeAllCustomerRealestates =
      _instance.includeAllCustomerRealestates || false;
  }

  /**
   * Deserializes / reads binary message into message instance using provided binary reader
   * @param _instance message instance
   * @param _reader binary reader instance
   */
  static deserializeBinaryFromReader(
    _instance: GetMetersRequest,
    _reader: BinaryReader
  ) {
    while (_reader.nextField()) {
      if (_reader.isEndGroup()) break;

      switch (_reader.getFieldNumber()) {
        case 1:
          _instance.customerId = _reader.readString();
          break;
        case 2:
          _instance.sourceNodeId = _reader.readString();
          break;
        case 3:
          _instance.sourceNodeTypeLabel = _reader.readString();
          break;
        case 4:
          (_instance.meterTypes = _instance.meterTypes || []).push(
            _reader.readString()
          );
          break;
        case 5:
          _instance.facilityType = _reader.readString();
          break;
        case 6:
          _instance.includeAllCustomerRealestates = _reader.readBool();
          break;
        default:
          _reader.skipField();
      }
    }

    GetMetersRequest.refineValues(_instance);
  }

  /**
   * Serializes a message to binary format using provided binary reader
   * @param _instance message instance
   * @param _writer binary writer instance
   */
  static serializeBinaryToWriter(
    _instance: GetMetersRequest,
    _writer: BinaryWriter
  ) {
    if (_instance.customerId) {
      _writer.writeString(1, _instance.customerId);
    }
    if (_instance.sourceNodeId) {
      _writer.writeString(2, _instance.sourceNodeId);
    }
    if (_instance.sourceNodeTypeLabel) {
      _writer.writeString(3, _instance.sourceNodeTypeLabel);
    }
    if (_instance.meterTypes && _instance.meterTypes.length) {
      _writer.writeRepeatedString(4, _instance.meterTypes);
    }
    if (_instance.facilityType) {
      _writer.writeString(5, _instance.facilityType);
    }
    if (_instance.includeAllCustomerRealestates) {
      _writer.writeBool(6, _instance.includeAllCustomerRealestates);
    }
  }

  private _customerId?: string;
  private _sourceNodeId?: string;
  private _sourceNodeTypeLabel?: string;
  private _meterTypes?: string[];
  private _facilityType?: string;
  private _includeAllCustomerRealestates?: boolean;

  /**
   * Message constructor. Initializes the properties and applies default Protobuf values if necessary
   * @param _value initial values object or instance of GetMetersRequest to deeply clone from
   */
  constructor(_value?: RecursivePartial<GetMetersRequest.AsObject>) {
    _value = _value || {};
    this.customerId = _value.customerId;
    this.sourceNodeId = _value.sourceNodeId;
    this.sourceNodeTypeLabel = _value.sourceNodeTypeLabel;
    this.meterTypes = (_value.meterTypes || []).slice();
    this.facilityType = _value.facilityType;
    this.includeAllCustomerRealestates = _value.includeAllCustomerRealestates;
    GetMetersRequest.refineValues(this);
  }
  get customerId(): string | undefined {
    return this._customerId;
  }
  set customerId(value: string | undefined) {
    this._customerId = value;
  }
  get sourceNodeId(): string | undefined {
    return this._sourceNodeId;
  }
  set sourceNodeId(value: string | undefined) {
    this._sourceNodeId = value;
  }
  get sourceNodeTypeLabel(): string | undefined {
    return this._sourceNodeTypeLabel;
  }
  set sourceNodeTypeLabel(value: string | undefined) {
    this._sourceNodeTypeLabel = value;
  }
  get meterTypes(): string[] | undefined {
    return this._meterTypes;
  }
  set meterTypes(value: string[] | undefined) {
    this._meterTypes = value;
  }
  get facilityType(): string | undefined {
    return this._facilityType;
  }
  set facilityType(value: string | undefined) {
    this._facilityType = value;
  }
  get includeAllCustomerRealestates(): boolean | undefined {
    return this._includeAllCustomerRealestates;
  }
  set includeAllCustomerRealestates(value: boolean | undefined) {
    this._includeAllCustomerRealestates = value;
  }

  /**
   * Serialize message to binary data
   * @param instance message instance
   */
  serializeBinary() {
    const writer = new BinaryWriter();
    GetMetersRequest.serializeBinaryToWriter(this, writer);
    return writer.getResultBuffer();
  }

  /**
   * Cast message to standard JavaScript object (all non-primitive values are deeply cloned)
   */
  toObject(): GetMetersRequest.AsObject {
    return {
      customerId: this.customerId,
      sourceNodeId: this.sourceNodeId,
      sourceNodeTypeLabel: this.sourceNodeTypeLabel,
      meterTypes: (this.meterTypes || []).slice(),
      facilityType: this.facilityType,
      includeAllCustomerRealestates: this.includeAllCustomerRealestates
    };
  }

  /**
   * Convenience method to support JSON.stringify(message), replicates the structure of toObject()
   */
  toJSON() {
    return this.toObject();
  }

  /**
   * Cast message to JSON using protobuf JSON notation: https://developers.google.com/protocol-buffers/docs/proto3#json
   * Attention: output differs from toObject() e.g. enums are represented as names and not as numbers, Timestamp is an ISO Date string format etc.
   * If the message itself or some of descendant messages is google.protobuf.Any, you MUST provide a message pool as options. If not, the messagePool is not required
   */
  toProtobufJSON(
    // @ts-ignore
    options?: ToProtobufJSONOptions
  ): GetMetersRequest.AsProtobufJSON {
    return {
      customerId: this.customerId,
      sourceNodeId: this.sourceNodeId,
      sourceNodeTypeLabel: this.sourceNodeTypeLabel,
      meterTypes: (this.meterTypes || []).slice(),
      facilityType: this.facilityType,
      includeAllCustomerRealestates: this.includeAllCustomerRealestates
    };
  }
}
export module GetMetersRequest {
  /**
   * Standard JavaScript object representation for GetMetersRequest
   */
  export interface AsObject {
    customerId?: string;
    sourceNodeId?: string;
    sourceNodeTypeLabel?: string;
    meterTypes?: string[];
    facilityType?: string;
    includeAllCustomerRealestates?: boolean;
  }

  /**
   * Protobuf JSON representation for GetMetersRequest
   */
  export interface AsProtobufJSON {
    customerId?: string;
    sourceNodeId?: string;
    sourceNodeTypeLabel?: string;
    meterTypes?: string[];
    facilityType?: string;
    includeAllCustomerRealestates?: boolean;
  }
}

/**
 * Message implementation for modulebms.customeradmin.GrpcMeter
 */
export class GrpcMeter implements GrpcMessage {
  static id = 'modulebms.customeradmin.GrpcMeter';

  /**
   * Deserialize binary data to message
   * @param instance message instance
   */
  static deserializeBinary(bytes: ByteSource) {
    const instance = new GrpcMeter();
    GrpcMeter.deserializeBinaryFromReader(instance, new BinaryReader(bytes));
    return instance;
  }

  /**
   * Check all the properties and set default protobuf values if necessary
   * @param _instance message instance
   */
  static refineValues(_instance: GrpcMeter) {
    _instance.id = _instance.id || '0';
    _instance.realestateId = _instance.realestateId || '0';
    _instance.buildingId = _instance.buildingId || '0';
    _instance.buildingaddressId = _instance.buildingaddressId || '0';
    _instance.apartmentId = _instance.apartmentId || '0';
    _instance.facilityId = _instance.facilityId || '0';
    _instance.gatewayId = _instance.gatewayId || '0';
    _instance.systemId1 = _instance.systemId1 || '0';
    _instance.systemId2 = _instance.systemId2 || '0';
    _instance.systemId3 = _instance.systemId3 || '0';
    _instance.systemFacilityid1 = _instance.systemFacilityid1 || '0';
    _instance.systemFacilityid2 = _instance.systemFacilityid2 || '0';
    _instance.systemFacilityid3 = _instance.systemFacilityid3 || '0';
    _instance.name = _instance.name || '';
    _instance.meterType = _instance.meterType || '';
    _instance.unit = _instance.unit || '';
    _instance.tag = _instance.tag || '';
    _instance.tariffGroup = _instance.tariffGroup || '';
    _instance.createdAt = _instance.createdAt || undefined;
    _instance.modifiedAt = _instance.modifiedAt || undefined;
    _instance.typeId = _instance.typeId || '';
    _instance.subaddress1 = _instance.subaddress1 || '';
    _instance.subaddress2 = _instance.subaddress2 || '';
    _instance.subaddress3 = _instance.subaddress3 || '';
    _instance.subaddress4 = _instance.subaddress4 || '';
    _instance.subaddress5 = _instance.subaddress5 || '';
    _instance.state = _instance.state || 0;
  }

  /**
   * Deserializes / reads binary message into message instance using provided binary reader
   * @param _instance message instance
   * @param _reader binary reader instance
   */
  static deserializeBinaryFromReader(
    _instance: GrpcMeter,
    _reader: BinaryReader
  ) {
    while (_reader.nextField()) {
      if (_reader.isEndGroup()) break;

      switch (_reader.getFieldNumber()) {
        case 1:
          _instance.id = _reader.readInt64String();
          break;
        case 2:
          _instance.realestateId = _reader.readInt64String();
          break;
        case 3:
          _instance.buildingId = _reader.readInt64String();
          break;
        case 4:
          _instance.buildingaddressId = _reader.readInt64String();
          break;
        case 5:
          _instance.apartmentId = _reader.readInt64String();
          break;
        case 6:
          _instance.facilityId = _reader.readInt64String();
          break;
        case 7:
          _instance.gatewayId = _reader.readInt64String();
          break;
        case 8:
          _instance.systemId1 = _reader.readInt64String();
          break;
        case 9:
          _instance.systemId2 = _reader.readInt64String();
          break;
        case 10:
          _instance.systemId3 = _reader.readInt64String();
          break;
        case 11:
          _instance.systemFacilityid1 = _reader.readInt64String();
          break;
        case 12:
          _instance.systemFacilityid2 = _reader.readInt64String();
          break;
        case 13:
          _instance.systemFacilityid3 = _reader.readInt64String();
          break;
        case 14:
          _instance.name = _reader.readString();
          break;
        case 15:
          _instance.meterType = _reader.readString();
          break;
        case 16:
          _instance.unit = _reader.readString();
          break;
        case 17:
          _instance.tag = _reader.readString();
          break;
        case 18:
          _instance.tariffGroup = _reader.readString();
          break;
        case 19:
          _instance.createdAt = new googleProtobuf000.Timestamp();
          _reader.readMessage(
            _instance.createdAt,
            googleProtobuf000.Timestamp.deserializeBinaryFromReader
          );
          break;
        case 20:
          _instance.modifiedAt = new googleProtobuf000.Timestamp();
          _reader.readMessage(
            _instance.modifiedAt,
            googleProtobuf000.Timestamp.deserializeBinaryFromReader
          );
          break;
        case 21:
          _instance.typeId = _reader.readString();
          break;
        case 22:
          _instance.subaddress1 = _reader.readString();
          break;
        case 23:
          _instance.subaddress2 = _reader.readString();
          break;
        case 24:
          _instance.subaddress3 = _reader.readString();
          break;
        case 25:
          _instance.subaddress4 = _reader.readString();
          break;
        case 26:
          _instance.subaddress5 = _reader.readString();
          break;
        case 27:
          _instance.state = _reader.readEnum();
          break;
        default:
          _reader.skipField();
      }
    }

    GrpcMeter.refineValues(_instance);
  }

  /**
   * Serializes a message to binary format using provided binary reader
   * @param _instance message instance
   * @param _writer binary writer instance
   */
  static serializeBinaryToWriter(_instance: GrpcMeter, _writer: BinaryWriter) {
    if (_instance.id) {
      _writer.writeInt64String(1, _instance.id);
    }
    if (_instance.realestateId) {
      _writer.writeInt64String(2, _instance.realestateId);
    }
    if (_instance.buildingId) {
      _writer.writeInt64String(3, _instance.buildingId);
    }
    if (_instance.buildingaddressId) {
      _writer.writeInt64String(4, _instance.buildingaddressId);
    }
    if (_instance.apartmentId) {
      _writer.writeInt64String(5, _instance.apartmentId);
    }
    if (_instance.facilityId) {
      _writer.writeInt64String(6, _instance.facilityId);
    }
    if (_instance.gatewayId) {
      _writer.writeInt64String(7, _instance.gatewayId);
    }
    if (_instance.systemId1) {
      _writer.writeInt64String(8, _instance.systemId1);
    }
    if (_instance.systemId2) {
      _writer.writeInt64String(9, _instance.systemId2);
    }
    if (_instance.systemId3) {
      _writer.writeInt64String(10, _instance.systemId3);
    }
    if (_instance.systemFacilityid1) {
      _writer.writeInt64String(11, _instance.systemFacilityid1);
    }
    if (_instance.systemFacilityid2) {
      _writer.writeInt64String(12, _instance.systemFacilityid2);
    }
    if (_instance.systemFacilityid3) {
      _writer.writeInt64String(13, _instance.systemFacilityid3);
    }
    if (_instance.name) {
      _writer.writeString(14, _instance.name);
    }
    if (_instance.meterType) {
      _writer.writeString(15, _instance.meterType);
    }
    if (_instance.unit) {
      _writer.writeString(16, _instance.unit);
    }
    if (_instance.tag) {
      _writer.writeString(17, _instance.tag);
    }
    if (_instance.tariffGroup) {
      _writer.writeString(18, _instance.tariffGroup);
    }
    if (_instance.createdAt) {
      _writer.writeMessage(
        19,
        _instance.createdAt as any,
        googleProtobuf000.Timestamp.serializeBinaryToWriter
      );
    }
    if (_instance.modifiedAt) {
      _writer.writeMessage(
        20,
        _instance.modifiedAt as any,
        googleProtobuf000.Timestamp.serializeBinaryToWriter
      );
    }
    if (_instance.typeId) {
      _writer.writeString(21, _instance.typeId);
    }
    if (_instance.subaddress1) {
      _writer.writeString(22, _instance.subaddress1);
    }
    if (_instance.subaddress2) {
      _writer.writeString(23, _instance.subaddress2);
    }
    if (_instance.subaddress3) {
      _writer.writeString(24, _instance.subaddress3);
    }
    if (_instance.subaddress4) {
      _writer.writeString(25, _instance.subaddress4);
    }
    if (_instance.subaddress5) {
      _writer.writeString(26, _instance.subaddress5);
    }
    if (_instance.state) {
      _writer.writeEnum(27, _instance.state);
    }
  }

  private _id?: string;
  private _realestateId?: string;
  private _buildingId?: string;
  private _buildingaddressId?: string;
  private _apartmentId?: string;
  private _facilityId?: string;
  private _gatewayId?: string;
  private _systemId1?: string;
  private _systemId2?: string;
  private _systemId3?: string;
  private _systemFacilityid1?: string;
  private _systemFacilityid2?: string;
  private _systemFacilityid3?: string;
  private _name?: string;
  private _meterType?: string;
  private _unit?: string;
  private _tag?: string;
  private _tariffGroup?: string;
  private _createdAt?: googleProtobuf000.Timestamp;
  private _modifiedAt?: googleProtobuf000.Timestamp;
  private _typeId?: string;
  private _subaddress1?: string;
  private _subaddress2?: string;
  private _subaddress3?: string;
  private _subaddress4?: string;
  private _subaddress5?: string;
  private _state?: GrpcMeter.meterstate;

  /**
   * Message constructor. Initializes the properties and applies default Protobuf values if necessary
   * @param _value initial values object or instance of GrpcMeter to deeply clone from
   */
  constructor(_value?: RecursivePartial<GrpcMeter.AsObject>) {
    _value = _value || {};
    this.id = _value.id;
    this.realestateId = _value.realestateId;
    this.buildingId = _value.buildingId;
    this.buildingaddressId = _value.buildingaddressId;
    this.apartmentId = _value.apartmentId;
    this.facilityId = _value.facilityId;
    this.gatewayId = _value.gatewayId;
    this.systemId1 = _value.systemId1;
    this.systemId2 = _value.systemId2;
    this.systemId3 = _value.systemId3;
    this.systemFacilityid1 = _value.systemFacilityid1;
    this.systemFacilityid2 = _value.systemFacilityid2;
    this.systemFacilityid3 = _value.systemFacilityid3;
    this.name = _value.name;
    this.meterType = _value.meterType;
    this.unit = _value.unit;
    this.tag = _value.tag;
    this.tariffGroup = _value.tariffGroup;
    this.createdAt = _value.createdAt
      ? new googleProtobuf000.Timestamp(_value.createdAt)
      : undefined;
    this.modifiedAt = _value.modifiedAt
      ? new googleProtobuf000.Timestamp(_value.modifiedAt)
      : undefined;
    this.typeId = _value.typeId;
    this.subaddress1 = _value.subaddress1;
    this.subaddress2 = _value.subaddress2;
    this.subaddress3 = _value.subaddress3;
    this.subaddress4 = _value.subaddress4;
    this.subaddress5 = _value.subaddress5;
    this.state = _value.state;
    GrpcMeter.refineValues(this);
  }
  get id(): string | undefined {
    return this._id;
  }
  set id(value: string | undefined) {
    this._id = value;
  }
  get realestateId(): string | undefined {
    return this._realestateId;
  }
  set realestateId(value: string | undefined) {
    this._realestateId = value;
  }
  get buildingId(): string | undefined {
    return this._buildingId;
  }
  set buildingId(value: string | undefined) {
    this._buildingId = value;
  }
  get buildingaddressId(): string | undefined {
    return this._buildingaddressId;
  }
  set buildingaddressId(value: string | undefined) {
    this._buildingaddressId = value;
  }
  get apartmentId(): string | undefined {
    return this._apartmentId;
  }
  set apartmentId(value: string | undefined) {
    this._apartmentId = value;
  }
  get facilityId(): string | undefined {
    return this._facilityId;
  }
  set facilityId(value: string | undefined) {
    this._facilityId = value;
  }
  get gatewayId(): string | undefined {
    return this._gatewayId;
  }
  set gatewayId(value: string | undefined) {
    this._gatewayId = value;
  }
  get systemId1(): string | undefined {
    return this._systemId1;
  }
  set systemId1(value: string | undefined) {
    this._systemId1 = value;
  }
  get systemId2(): string | undefined {
    return this._systemId2;
  }
  set systemId2(value: string | undefined) {
    this._systemId2 = value;
  }
  get systemId3(): string | undefined {
    return this._systemId3;
  }
  set systemId3(value: string | undefined) {
    this._systemId3 = value;
  }
  get systemFacilityid1(): string | undefined {
    return this._systemFacilityid1;
  }
  set systemFacilityid1(value: string | undefined) {
    this._systemFacilityid1 = value;
  }
  get systemFacilityid2(): string | undefined {
    return this._systemFacilityid2;
  }
  set systemFacilityid2(value: string | undefined) {
    this._systemFacilityid2 = value;
  }
  get systemFacilityid3(): string | undefined {
    return this._systemFacilityid3;
  }
  set systemFacilityid3(value: string | undefined) {
    this._systemFacilityid3 = value;
  }
  get name(): string | undefined {
    return this._name;
  }
  set name(value: string | undefined) {
    this._name = value;
  }
  get meterType(): string | undefined {
    return this._meterType;
  }
  set meterType(value: string | undefined) {
    this._meterType = value;
  }
  get unit(): string | undefined {
    return this._unit;
  }
  set unit(value: string | undefined) {
    this._unit = value;
  }
  get tag(): string | undefined {
    return this._tag;
  }
  set tag(value: string | undefined) {
    this._tag = value;
  }
  get tariffGroup(): string | undefined {
    return this._tariffGroup;
  }
  set tariffGroup(value: string | undefined) {
    this._tariffGroup = value;
  }
  get createdAt(): googleProtobuf000.Timestamp | undefined {
    return this._createdAt;
  }
  set createdAt(value: googleProtobuf000.Timestamp | undefined) {
    this._createdAt = value;
  }
  get modifiedAt(): googleProtobuf000.Timestamp | undefined {
    return this._modifiedAt;
  }
  set modifiedAt(value: googleProtobuf000.Timestamp | undefined) {
    this._modifiedAt = value;
  }
  get typeId(): string | undefined {
    return this._typeId;
  }
  set typeId(value: string | undefined) {
    this._typeId = value;
  }
  get subaddress1(): string | undefined {
    return this._subaddress1;
  }
  set subaddress1(value: string | undefined) {
    this._subaddress1 = value;
  }
  get subaddress2(): string | undefined {
    return this._subaddress2;
  }
  set subaddress2(value: string | undefined) {
    this._subaddress2 = value;
  }
  get subaddress3(): string | undefined {
    return this._subaddress3;
  }
  set subaddress3(value: string | undefined) {
    this._subaddress3 = value;
  }
  get subaddress4(): string | undefined {
    return this._subaddress4;
  }
  set subaddress4(value: string | undefined) {
    this._subaddress4 = value;
  }
  get subaddress5(): string | undefined {
    return this._subaddress5;
  }
  set subaddress5(value: string | undefined) {
    this._subaddress5 = value;
  }
  get state(): GrpcMeter.meterstate | undefined {
    return this._state;
  }
  set state(value: GrpcMeter.meterstate | undefined) {
    this._state = value;
  }

  /**
   * Serialize message to binary data
   * @param instance message instance
   */
  serializeBinary() {
    const writer = new BinaryWriter();
    GrpcMeter.serializeBinaryToWriter(this, writer);
    return writer.getResultBuffer();
  }

  /**
   * Cast message to standard JavaScript object (all non-primitive values are deeply cloned)
   */
  toObject(): GrpcMeter.AsObject {
    return {
      id: this.id,
      realestateId: this.realestateId,
      buildingId: this.buildingId,
      buildingaddressId: this.buildingaddressId,
      apartmentId: this.apartmentId,
      facilityId: this.facilityId,
      gatewayId: this.gatewayId,
      systemId1: this.systemId1,
      systemId2: this.systemId2,
      systemId3: this.systemId3,
      systemFacilityid1: this.systemFacilityid1,
      systemFacilityid2: this.systemFacilityid2,
      systemFacilityid3: this.systemFacilityid3,
      name: this.name,
      meterType: this.meterType,
      unit: this.unit,
      tag: this.tag,
      tariffGroup: this.tariffGroup,
      createdAt: this.createdAt ? this.createdAt.toObject() : undefined,
      modifiedAt: this.modifiedAt ? this.modifiedAt.toObject() : undefined,
      typeId: this.typeId,
      subaddress1: this.subaddress1,
      subaddress2: this.subaddress2,
      subaddress3: this.subaddress3,
      subaddress4: this.subaddress4,
      subaddress5: this.subaddress5,
      state: this.state
    };
  }

  /**
   * Convenience method to support JSON.stringify(message), replicates the structure of toObject()
   */
  toJSON() {
    return this.toObject();
  }

  /**
   * Cast message to JSON using protobuf JSON notation: https://developers.google.com/protocol-buffers/docs/proto3#json
   * Attention: output differs from toObject() e.g. enums are represented as names and not as numbers, Timestamp is an ISO Date string format etc.
   * If the message itself or some of descendant messages is google.protobuf.Any, you MUST provide a message pool as options. If not, the messagePool is not required
   */
  toProtobufJSON(
    // @ts-ignore
    options?: ToProtobufJSONOptions
  ): GrpcMeter.AsProtobufJSON {
    return {
      id: this.id,
      realestateId: this.realestateId,
      buildingId: this.buildingId,
      buildingaddressId: this.buildingaddressId,
      apartmentId: this.apartmentId,
      facilityId: this.facilityId,
      gatewayId: this.gatewayId,
      systemId1: this.systemId1,
      systemId2: this.systemId2,
      systemId3: this.systemId3,
      systemFacilityid1: this.systemFacilityid1,
      systemFacilityid2: this.systemFacilityid2,
      systemFacilityid3: this.systemFacilityid3,
      name: this.name,
      meterType: this.meterType,
      unit: this.unit,
      tag: this.tag,
      tariffGroup: this.tariffGroup,
      createdAt: this.createdAt ? this.createdAt.toProtobufJSON(options) : null,
      modifiedAt: this.modifiedAt
        ? this.modifiedAt.toProtobufJSON(options)
        : null,
      typeId: this.typeId,
      subaddress1: this.subaddress1,
      subaddress2: this.subaddress2,
      subaddress3: this.subaddress3,
      subaddress4: this.subaddress4,
      subaddress5: this.subaddress5,
      state:
        GrpcMeter.meterstate[
          this.state === null || this.state === undefined ? 0 : this.state
        ]
    };
  }
}
export module GrpcMeter {
  /**
   * Standard JavaScript object representation for GrpcMeter
   */
  export interface AsObject {
    id?: string;
    realestateId?: string;
    buildingId?: string;
    buildingaddressId?: string;
    apartmentId?: string;
    facilityId?: string;
    gatewayId?: string;
    systemId1?: string;
    systemId2?: string;
    systemId3?: string;
    systemFacilityid1?: string;
    systemFacilityid2?: string;
    systemFacilityid3?: string;
    name?: string;
    meterType?: string;
    unit?: string;
    tag?: string;
    tariffGroup?: string;
    createdAt?: googleProtobuf000.Timestamp.AsObject;
    modifiedAt?: googleProtobuf000.Timestamp.AsObject;
    typeId?: string;
    subaddress1?: string;
    subaddress2?: string;
    subaddress3?: string;
    subaddress4?: string;
    subaddress5?: string;
    state?: GrpcMeter.meterstate;
  }

  /**
   * Protobuf JSON representation for GrpcMeter
   */
  export interface AsProtobufJSON {
    id?: string;
    realestateId?: string;
    buildingId?: string;
    buildingaddressId?: string;
    apartmentId?: string;
    facilityId?: string;
    gatewayId?: string;
    systemId1?: string;
    systemId2?: string;
    systemId3?: string;
    systemFacilityid1?: string;
    systemFacilityid2?: string;
    systemFacilityid3?: string;
    name?: string;
    meterType?: string;
    unit?: string;
    tag?: string;
    tariffGroup?: string;
    createdAt?: googleProtobuf000.Timestamp.AsProtobufJSON | null;
    modifiedAt?: googleProtobuf000.Timestamp.AsProtobufJSON | null;
    typeId?: string;
    subaddress1?: string;
    subaddress2?: string;
    subaddress3?: string;
    subaddress4?: string;
    subaddress5?: string;
    state?: string;
  }
  export enum Meterstate {
    UNSPECIFIED = 0,
    INACTIVE = 1,
    INACTIVEREPLACED = 2,
    TEST = 21,
    ACTIVEBILLING = 41,
    ACTIVEEXPORT = 42,
    ACTIVENOBILLING = 61
  }
}

/**
 * Message implementation for modulebms.customeradmin.GetMetersResponse
 */
export class GetMetersResponse implements GrpcMessage {
  static id = 'modulebms.customeradmin.GetMetersResponse';

  /**
   * Deserialize binary data to message
   * @param instance message instance
   */
  static deserializeBinary(bytes: ByteSource) {
    const instance = new GetMetersResponse();
    GetMetersResponse.deserializeBinaryFromReader(
      instance,
      new BinaryReader(bytes)
    );
    return instance;
  }

  /**
   * Check all the properties and set default protobuf values if necessary
   * @param _instance message instance
   */
  static refineValues(_instance: GetMetersResponse) {
    _instance.meters = _instance.meters || [];
  }

  /**
   * Deserializes / reads binary message into message instance using provided binary reader
   * @param _instance message instance
   * @param _reader binary reader instance
   */
  static deserializeBinaryFromReader(
    _instance: GetMetersResponse,
    _reader: BinaryReader
  ) {
    while (_reader.nextField()) {
      if (_reader.isEndGroup()) break;

      switch (_reader.getFieldNumber()) {
        case 1:
          const messageInitializer1 = new GrpcMeter();
          _reader.readMessage(
            messageInitializer1,
            GrpcMeter.deserializeBinaryFromReader
          );
          (_instance.meters = _instance.meters || []).push(messageInitializer1);
          break;
        default:
          _reader.skipField();
      }
    }

    GetMetersResponse.refineValues(_instance);
  }

  /**
   * Serializes a message to binary format using provided binary reader
   * @param _instance message instance
   * @param _writer binary writer instance
   */
  static serializeBinaryToWriter(
    _instance: GetMetersResponse,
    _writer: BinaryWriter
  ) {
    if (_instance.meters && _instance.meters.length) {
      _writer.writeRepeatedMessage(
        1,
        _instance.meters as any,
        GrpcMeter.serializeBinaryToWriter
      );
    }
  }

  private _meters?: GrpcMeter[];

  /**
   * Message constructor. Initializes the properties and applies default Protobuf values if necessary
   * @param _value initial values object or instance of GetMetersResponse to deeply clone from
   */
  constructor(_value?: RecursivePartial<GetMetersResponse.AsObject>) {
    _value = _value || {};
    this.meters = (_value.meters || []).map(m => new GrpcMeter(m));
    GetMetersResponse.refineValues(this);
  }
  get meters(): GrpcMeter[] | undefined {
    return this._meters;
  }
  set meters(value: GrpcMeter[] | undefined) {
    this._meters = value;
  }

  /**
   * Serialize message to binary data
   * @param instance message instance
   */
  serializeBinary() {
    const writer = new BinaryWriter();
    GetMetersResponse.serializeBinaryToWriter(this, writer);
    return writer.getResultBuffer();
  }

  /**
   * Cast message to standard JavaScript object (all non-primitive values are deeply cloned)
   */
  toObject(): GetMetersResponse.AsObject {
    return {
      meters: (this.meters || []).map(m => m.toObject())
    };
  }

  /**
   * Convenience method to support JSON.stringify(message), replicates the structure of toObject()
   */
  toJSON() {
    return this.toObject();
  }

  /**
   * Cast message to JSON using protobuf JSON notation: https://developers.google.com/protocol-buffers/docs/proto3#json
   * Attention: output differs from toObject() e.g. enums are represented as names and not as numbers, Timestamp is an ISO Date string format etc.
   * If the message itself or some of descendant messages is google.protobuf.Any, you MUST provide a message pool as options. If not, the messagePool is not required
   */
  toProtobufJSON(
    // @ts-ignore
    options?: ToProtobufJSONOptions
  ): GetMetersResponse.AsProtobufJSON {
    return {
      meters: (this.meters || []).map(m => m.toProtobufJSON(options))
    };
  }
}
export module GetMetersResponse {
  /**
   * Standard JavaScript object representation for GetMetersResponse
   */
  export interface AsObject {
    meters?: GrpcMeter.AsObject[];
  }

  /**
   * Protobuf JSON representation for GetMetersResponse
   */
  export interface AsProtobufJSON {
    meters?: GrpcMeter.AsProtobufJSON[] | null;
  }
}
