/* tslint:disable */
/* eslint-disable */
// @ts-nocheck
//
// THIS IS A GENERATED FILE
// DO NOT MODIFY IT! YOUR CHANGES WILL BE LOST
import { Inject, Injectable, Optional } from '@angular/core';
import {
  GrpcCallType,
  GrpcClient,
  GrpcClientFactory,
  GrpcEvent,
  GrpcMetadata
} from '@ngx-grpc/common';
import {
  GRPC_CLIENT_FACTORY,
  GrpcHandler,
  takeMessages,
  throwStatusErrors
} from '@ngx-grpc/core';
import { Observable } from 'rxjs';
import * as thisProto from './xprojector.modulebms.export.pb';
import * as googleProtobuf000 from './google/protobuf/timestamp.pb';
import { GRPC_EXPORT_CLIENT_SETTINGS } from './xprojector.modulebms.export.pbconf';
/**
 * Service client implementation for modulebms.export.Export
 */
@Injectable({ providedIn: 'root' })
export class ExportClient {
  private client: GrpcClient<any>;

  /**
   * Raw RPC implementation for each service client method.
   * The raw methods provide more control on the incoming data and events. E.g. they can be useful to read status `OK` metadata.
   * Attention: these methods do not throw errors when non-zero status codes are received.
   */
  $raw = {
    /**
     * Unary call: /modulebms.export.Export/GetExtrapolatedValue
     *
     * @param requestMessage Request message
     * @param requestMetadata Request metadata
     * @returns Observable<GrpcEvent<thisProto.GetExtrapolatedValueResponse>>
     */
    getExtrapolatedValue: (
      requestData: thisProto.GetExtrapolatedValueRequest,
      requestMetadata = new GrpcMetadata()
    ): Observable<GrpcEvent<thisProto.GetExtrapolatedValueResponse>> => {
      return this.handler.handle({
        type: GrpcCallType.unary,
        client: this.client,
        path: '/modulebms.export.Export/GetExtrapolatedValue',
        requestData,
        requestMetadata,
        requestClass: thisProto.GetExtrapolatedValueRequest,
        responseClass: thisProto.GetExtrapolatedValueResponse
      });
    },
    /**
     * Unary call: /modulebms.export.Export/GetExportInfo
     *
     * @param requestMessage Request message
     * @param requestMetadata Request metadata
     * @returns Observable<GrpcEvent<thisProto.GetExportInfoResponse>>
     */
    getExportInfo: (
      requestData: thisProto.GetExportInfoRequest,
      requestMetadata = new GrpcMetadata()
    ): Observable<GrpcEvent<thisProto.GetExportInfoResponse>> => {
      return this.handler.handle({
        type: GrpcCallType.unary,
        client: this.client,
        path: '/modulebms.export.Export/GetExportInfo',
        requestData,
        requestMetadata,
        requestClass: thisProto.GetExportInfoRequest,
        responseClass: thisProto.GetExportInfoResponse
      });
    },
    /**
     * Server streaming: /modulebms.export.Export/CreateMeterExportFile
     *
     * @param requestMessage Request message
     * @param requestMetadata Request metadata
     * @returns Observable<GrpcEvent<thisProto.DataChunk>>
     */
    createMeterExportFile: (
      requestData: thisProto.CreateMeterExportFileRequest,
      requestMetadata = new GrpcMetadata()
    ): Observable<GrpcEvent<thisProto.DataChunk>> => {
      return this.handler.handle({
        type: GrpcCallType.serverStream,
        client: this.client,
        path: '/modulebms.export.Export/CreateMeterExportFile',
        requestData,
        requestMetadata,
        requestClass: thisProto.CreateMeterExportFileRequest,
        responseClass: thisProto.DataChunk
      });
    },
    /**
     * Server streaming: /modulebms.export.Export/CreateApartmentAndFacilityExportFile
     *
     * @param requestMessage Request message
     * @param requestMetadata Request metadata
     * @returns Observable<GrpcEvent<thisProto.DataChunk>>
     */
    createApartmentAndFacilityExportFile: (
      requestData: thisProto.CreateApartmentAndFacilityExportFileRequest,
      requestMetadata = new GrpcMetadata()
    ): Observable<GrpcEvent<thisProto.DataChunk>> => {
      return this.handler.handle({
        type: GrpcCallType.serverStream,
        client: this.client,
        path: '/modulebms.export.Export/CreateApartmentAndFacilityExportFile',
        requestData,
        requestMetadata,
        requestClass: thisProto.CreateApartmentAndFacilityExportFileRequest,
        responseClass: thisProto.DataChunk
      });
    },
    /**
     * Server streaming: /modulebms.export.Export/GetMeteringApiFile
     *
     * @param requestMessage Request message
     * @param requestMetadata Request metadata
     * @returns Observable<GrpcEvent<thisProto.DataChunk>>
     */
    getMeteringApiFile: (
      requestData: thisProto.GetMeteringApiFileRequest,
      requestMetadata = new GrpcMetadata()
    ): Observable<GrpcEvent<thisProto.DataChunk>> => {
      return this.handler.handle({
        type: GrpcCallType.serverStream,
        client: this.client,
        path: '/modulebms.export.Export/GetMeteringApiFile',
        requestData,
        requestMetadata,
        requestClass: thisProto.GetMeteringApiFileRequest,
        responseClass: thisProto.DataChunk
      });
    },
    /**
     * Unary call: /modulebms.export.Export/AddDatapointValue
     *
     * @param requestMessage Request message
     * @param requestMetadata Request metadata
     * @returns Observable<GrpcEvent<thisProto.AddDatapointValueResponse>>
     */
    addDatapointValue: (
      requestData: thisProto.AddDatapointValueRequest,
      requestMetadata = new GrpcMetadata()
    ): Observable<GrpcEvent<thisProto.AddDatapointValueResponse>> => {
      return this.handler.handle({
        type: GrpcCallType.unary,
        client: this.client,
        path: '/modulebms.export.Export/AddDatapointValue',
        requestData,
        requestMetadata,
        requestClass: thisProto.AddDatapointValueRequest,
        responseClass: thisProto.AddDatapointValueResponse
      });
    },
    /**
     * Unary call: /modulebms.export.Export/TouchMeters
     *
     * @param requestMessage Request message
     * @param requestMetadata Request metadata
     * @returns Observable<GrpcEvent<thisProto.TouchMetersResponse>>
     */
    touchMeters: (
      requestData: thisProto.TouchMetersRequest,
      requestMetadata = new GrpcMetadata()
    ): Observable<GrpcEvent<thisProto.TouchMetersResponse>> => {
      return this.handler.handle({
        type: GrpcCallType.unary,
        client: this.client,
        path: '/modulebms.export.Export/TouchMeters',
        requestData,
        requestMetadata,
        requestClass: thisProto.TouchMetersRequest,
        responseClass: thisProto.TouchMetersResponse
      });
    },
    /**
     * Unary call: /modulebms.export.Export/TouchApartmentsAndFacilities
     *
     * @param requestMessage Request message
     * @param requestMetadata Request metadata
     * @returns Observable<GrpcEvent<thisProto.TouchApartmentsAndFacilitiesResponse>>
     */
    touchApartmentsAndFacilities: (
      requestData: thisProto.TouchApartmentsAndFacilitiesRequest,
      requestMetadata = new GrpcMetadata()
    ): Observable<
      GrpcEvent<thisProto.TouchApartmentsAndFacilitiesResponse>
    > => {
      return this.handler.handle({
        type: GrpcCallType.unary,
        client: this.client,
        path: '/modulebms.export.Export/TouchApartmentsAndFacilities',
        requestData,
        requestMetadata,
        requestClass: thisProto.TouchApartmentsAndFacilitiesRequest,
        responseClass: thisProto.TouchApartmentsAndFacilitiesResponse
      });
    },
    /**
     * Unary call: /modulebms.export.Export/AssertCountyAndDistrict
     *
     * @param requestMessage Request message
     * @param requestMetadata Request metadata
     * @returns Observable<GrpcEvent<thisProto.AssertCountyAndDistrictResponse>>
     */
    assertCountyAndDistrict: (
      requestData: thisProto.AssertCountyAndDistrictRequest,
      requestMetadata = new GrpcMetadata()
    ): Observable<GrpcEvent<thisProto.AssertCountyAndDistrictResponse>> => {
      return this.handler.handle({
        type: GrpcCallType.unary,
        client: this.client,
        path: '/modulebms.export.Export/AssertCountyAndDistrict',
        requestData,
        requestMetadata,
        requestClass: thisProto.AssertCountyAndDistrictRequest,
        responseClass: thisProto.AssertCountyAndDistrictResponse
      });
    },
    /**
     * Server streaming: /modulebms.export.Export/ExportGatewayConfig
     *
     * @param requestMessage Request message
     * @param requestMetadata Request metadata
     * @returns Observable<GrpcEvent<thisProto.DataChunk>>
     */
    exportGatewayConfig: (
      requestData: thisProto.ExportGatewayConfigRequest,
      requestMetadata = new GrpcMetadata()
    ): Observable<GrpcEvent<thisProto.DataChunk>> => {
      return this.handler.handle({
        type: GrpcCallType.serverStream,
        client: this.client,
        path: '/modulebms.export.Export/ExportGatewayConfig',
        requestData,
        requestMetadata,
        requestClass: thisProto.ExportGatewayConfigRequest,
        responseClass: thisProto.DataChunk
      });
    },
    /**
     * Server streaming: /modulebms.export.Export/ExportDataExportValues
     *
     * @param requestMessage Request message
     * @param requestMetadata Request metadata
     * @returns Observable<GrpcEvent<thisProto.DataChunk>>
     */
    exportDataExportValues: (
      requestData: thisProto.ExportDataExportValuesRequest,
      requestMetadata = new GrpcMetadata()
    ): Observable<GrpcEvent<thisProto.DataChunk>> => {
      return this.handler.handle({
        type: GrpcCallType.serverStream,
        client: this.client,
        path: '/modulebms.export.Export/ExportDataExportValues',
        requestData,
        requestMetadata,
        requestClass: thisProto.ExportDataExportValuesRequest,
        responseClass: thisProto.DataChunk
      });
    },
    /**
     * Server streaming: /modulebms.export.Export/CreateMeterExportAddressesExportFile
     *
     * @param requestMessage Request message
     * @param requestMetadata Request metadata
     * @returns Observable<GrpcEvent<thisProto.DataChunk>>
     */
    createMeterExportAddressesExportFile: (
      requestData: thisProto.CreateMeterExportAddressesExportFileRequest,
      requestMetadata = new GrpcMetadata()
    ): Observable<GrpcEvent<thisProto.DataChunk>> => {
      return this.handler.handle({
        type: GrpcCallType.serverStream,
        client: this.client,
        path: '/modulebms.export.Export/CreateMeterExportAddressesExportFile',
        requestData,
        requestMetadata,
        requestClass: thisProto.CreateMeterExportAddressesExportFileRequest,
        responseClass: thisProto.DataChunk
      });
    }
  };

  constructor(
    @Optional() @Inject(GRPC_EXPORT_CLIENT_SETTINGS) settings: any,
    @Inject(GRPC_CLIENT_FACTORY) clientFactory: GrpcClientFactory<any>,
    private handler: GrpcHandler
  ) {
    this.client = clientFactory.createClient(
      'modulebms.export.Export',
      settings
    );
  }

  /**
   * Unary call @/modulebms.export.Export/GetExtrapolatedValue
   *
   * @param requestMessage Request message
   * @param requestMetadata Request metadata
   * @returns Observable<thisProto.GetExtrapolatedValueResponse>
   */
  getExtrapolatedValue(
    requestData: thisProto.GetExtrapolatedValueRequest,
    requestMetadata = new GrpcMetadata()
  ): Observable<thisProto.GetExtrapolatedValueResponse> {
    return this.$raw
      .getExtrapolatedValue(requestData, requestMetadata)
      .pipe(throwStatusErrors(), takeMessages());
  }

  /**
   * Unary call @/modulebms.export.Export/GetExportInfo
   *
   * @param requestMessage Request message
   * @param requestMetadata Request metadata
   * @returns Observable<thisProto.GetExportInfoResponse>
   */
  getExportInfo(
    requestData: thisProto.GetExportInfoRequest,
    requestMetadata = new GrpcMetadata()
  ): Observable<thisProto.GetExportInfoResponse> {
    return this.$raw
      .getExportInfo(requestData, requestMetadata)
      .pipe(throwStatusErrors(), takeMessages());
  }

  /**
   * Server streaming @/modulebms.export.Export/CreateMeterExportFile
   *
   * @param requestMessage Request message
   * @param requestMetadata Request metadata
   * @returns Observable<thisProto.DataChunk>
   */
  createMeterExportFile(
    requestData: thisProto.CreateMeterExportFileRequest,
    requestMetadata = new GrpcMetadata()
  ): Observable<thisProto.DataChunk> {
    return this.$raw
      .createMeterExportFile(requestData, requestMetadata)
      .pipe(throwStatusErrors(), takeMessages());
  }

  /**
   * Server streaming @/modulebms.export.Export/CreateApartmentAndFacilityExportFile
   *
   * @param requestMessage Request message
   * @param requestMetadata Request metadata
   * @returns Observable<thisProto.DataChunk>
   */
  createApartmentAndFacilityExportFile(
    requestData: thisProto.CreateApartmentAndFacilityExportFileRequest,
    requestMetadata = new GrpcMetadata()
  ): Observable<thisProto.DataChunk> {
    return this.$raw
      .createApartmentAndFacilityExportFile(requestData, requestMetadata)
      .pipe(throwStatusErrors(), takeMessages());
  }

  /**
   * Server streaming @/modulebms.export.Export/GetMeteringApiFile
   *
   * @param requestMessage Request message
   * @param requestMetadata Request metadata
   * @returns Observable<thisProto.DataChunk>
   */
  getMeteringApiFile(
    requestData: thisProto.GetMeteringApiFileRequest,
    requestMetadata = new GrpcMetadata()
  ): Observable<thisProto.DataChunk> {
    return this.$raw
      .getMeteringApiFile(requestData, requestMetadata)
      .pipe(throwStatusErrors(), takeMessages());
  }

  /**
   * Unary call @/modulebms.export.Export/AddDatapointValue
   *
   * @param requestMessage Request message
   * @param requestMetadata Request metadata
   * @returns Observable<thisProto.AddDatapointValueResponse>
   */
  addDatapointValue(
    requestData: thisProto.AddDatapointValueRequest,
    requestMetadata = new GrpcMetadata()
  ): Observable<thisProto.AddDatapointValueResponse> {
    return this.$raw
      .addDatapointValue(requestData, requestMetadata)
      .pipe(throwStatusErrors(), takeMessages());
  }

  /**
   * Unary call @/modulebms.export.Export/TouchMeters
   *
   * @param requestMessage Request message
   * @param requestMetadata Request metadata
   * @returns Observable<thisProto.TouchMetersResponse>
   */
  touchMeters(
    requestData: thisProto.TouchMetersRequest,
    requestMetadata = new GrpcMetadata()
  ): Observable<thisProto.TouchMetersResponse> {
    return this.$raw
      .touchMeters(requestData, requestMetadata)
      .pipe(throwStatusErrors(), takeMessages());
  }

  /**
   * Unary call @/modulebms.export.Export/TouchApartmentsAndFacilities
   *
   * @param requestMessage Request message
   * @param requestMetadata Request metadata
   * @returns Observable<thisProto.TouchApartmentsAndFacilitiesResponse>
   */
  touchApartmentsAndFacilities(
    requestData: thisProto.TouchApartmentsAndFacilitiesRequest,
    requestMetadata = new GrpcMetadata()
  ): Observable<thisProto.TouchApartmentsAndFacilitiesResponse> {
    return this.$raw
      .touchApartmentsAndFacilities(requestData, requestMetadata)
      .pipe(throwStatusErrors(), takeMessages());
  }

  /**
   * Unary call @/modulebms.export.Export/AssertCountyAndDistrict
   *
   * @param requestMessage Request message
   * @param requestMetadata Request metadata
   * @returns Observable<thisProto.AssertCountyAndDistrictResponse>
   */
  assertCountyAndDistrict(
    requestData: thisProto.AssertCountyAndDistrictRequest,
    requestMetadata = new GrpcMetadata()
  ): Observable<thisProto.AssertCountyAndDistrictResponse> {
    return this.$raw
      .assertCountyAndDistrict(requestData, requestMetadata)
      .pipe(throwStatusErrors(), takeMessages());
  }

  /**
   * Server streaming @/modulebms.export.Export/ExportGatewayConfig
   *
   * @param requestMessage Request message
   * @param requestMetadata Request metadata
   * @returns Observable<thisProto.DataChunk>
   */
  exportGatewayConfig(
    requestData: thisProto.ExportGatewayConfigRequest,
    requestMetadata = new GrpcMetadata()
  ): Observable<thisProto.DataChunk> {
    return this.$raw
      .exportGatewayConfig(requestData, requestMetadata)
      .pipe(throwStatusErrors(), takeMessages());
  }

  /**
   * Server streaming @/modulebms.export.Export/ExportDataExportValues
   *
   * @param requestMessage Request message
   * @param requestMetadata Request metadata
   * @returns Observable<thisProto.DataChunk>
   */
  exportDataExportValues(
    requestData: thisProto.ExportDataExportValuesRequest,
    requestMetadata = new GrpcMetadata()
  ): Observable<thisProto.DataChunk> {
    return this.$raw
      .exportDataExportValues(requestData, requestMetadata)
      .pipe(throwStatusErrors(), takeMessages());
  }

  /**
   * Server streaming @/modulebms.export.Export/CreateMeterExportAddressesExportFile
   *
   * @param requestMessage Request message
   * @param requestMetadata Request metadata
   * @returns Observable<thisProto.DataChunk>
   */
  createMeterExportAddressesExportFile(
    requestData: thisProto.CreateMeterExportAddressesExportFileRequest,
    requestMetadata = new GrpcMetadata()
  ): Observable<thisProto.DataChunk> {
    return this.$raw
      .createMeterExportAddressesExportFile(requestData, requestMetadata)
      .pipe(throwStatusErrors(), takeMessages());
  }
}
