<clr-modal [(clrModalOpen)]="showRemoveCustomer">
  <h3 class="modal-title">Remove customer</h3>
  <div class="modal-body">
    <p>Are you sure?</p>
  </div>
  <div class="modal-footer">
    <button type="button" class="btn btn-outline" (click)="showRemoveCustomer = false">Cancel</button>
    <button type="button" class="btn btn-primary"
      (click)="showRemoveCustomer = false; doRemoveSelectedCustomer()">Ok</button>
  </div>
</clr-modal>

<clr-modal [(clrModalOpen)]="showEditNodeModal">
  <h3 class="modal-title">Edit node</h3>
  <div class="modal-body">
    <app-edit-treenode #editTreeNode *ngIf="showEditNodeModal && selectedDataCollectorNode" [customerId]="selectedCustomer?.id"
      [node]="selectedDataCollectorNode" [nodeTypes]="dataCollectorsNodeTypes"></app-edit-treenode>
  </div>
  <div class="modal-footer">
    <button class="btn btn-outline" (click)="closeEditNode()">Cancel</button>
    <button class="btn btn-primary" (click)="saveEditNode()">Save</button>
  </div>
</clr-modal>

<clr-modal [(clrModalOpen)]="showViewGatewayMeters" [clrModalSize]="'xl'" [clrModalClosable]="false">
  <h3 class="modal-title">Gateway meters</h3>
  <div class="modal-body" style="min-height: 400px;">
    <app-bms-meter-list #gatewayMeterList [inputFilter]="gatewayFilter" [subordinateNodes]="false" [minHeight]="380"
      [maxHeight]="380" (onSelectMeter)="onSelectMeter($event)"></app-bms-meter-list>
  </div>
  <div class="modal-footer">
    <button type="button" class="btn btn-primary" (click)="showViewGatewayMeters=false;">Close</button>
  </div>
</clr-modal>

<clr-modal [(clrModalOpen)]="showSetBillingTariff" [clrModalClosable]="false">
  <h3 class="modal-title">Set billing tariffs</h3>
  <div class="modal-body">
    <form clrForm *ngIf="setBiilingTariffInfo">
      <clr-input-container>
        <label>Tariff:</label>
        <input clrInput type="number" min="0" max="100000" step="0.1" placeholder="" name="setbillingtariff"
          [(ngModel)]="setBiilingTariffInfo.tariff.value" />
      </clr-input-container>

      <clr-input-container>
        <label>Active from:</label>
        <input clrInput type="date" step="1" placeholder="" name="setbillingactivefrom"
          [(ngModel)]="setBiilingTariffInfo.activeFromString" />
      </clr-input-container>

      <clr-input-container>
        <label>VAT Rate:</label>
        <input clrInput type="number" min="0" max="1" step="0.01" placeholder="0.2" name="setbillingvatrate"
          [(ngModel)]="setBiilingTariffInfo.tariff.vatRate" />
      </clr-input-container>

      <!-- <clr-checkbox-container>
        <clr-checkbox-wrapper>
          <input type="checkbox" clrCheckbox [(ngModel)]="setBiilingTariffInfo.tariff.includeVat"
            name="setbillingincludevat" />
          <label>Include VAT</label>
        </clr-checkbox-wrapper>
      </clr-checkbox-container> -->

      <clr-input-container>
        <label>Currency:</label>
        <input clrInput type="text" placeholder="SEK" name="setbillingcurrency"
          [(ngModel)]="setBiilingTariffInfo.tariff.currency" />
      </clr-input-container>

      <clr-select-container>
        <label>Meter type</label>
        <select clrSelect name="setbillingmetertype" [(ngModel)]="setBiilingTariffInfo.meterType">
          <option *ngFor="let meterType of meterTypes" [ngValue]="meterType">{{meterType}}</option>
        </select>
      </clr-select-container>
    </form>
  </div>
  <div class="modal-footer">
    <button type="button" class="btn btn-outline" (click)="showSetBillingTariff = false">Cancel</button>
    <button type="button" class="btn btn-primary"
      (click)="showSetBillingTariff = false; onExecuteSetBillingTariff()">Ok</button>
  </div>
</clr-modal>

<clr-modal [(clrModalOpen)]="showDeleteBillingTariff" [clrModalClosable]="false">
  <h3 class="modal-title">Delete billing tariffs</h3>
  <div class="modal-body">
    <form clrForm *ngIf="deleteBiilingTariffInfo">
      <clr-input-container>
        <label>Delete from:</label>
        <input clrInput type="date" step="1" placeholder="" name="deletebillingfrom"
          [(ngModel)]="deleteBiilingTariffInfo.deleteFromString" />
      </clr-input-container>

      <clr-select-container>
        <label>Meter type</label>
        <select clrSelect name="deletebillingmetertype" [(ngModel)]="deleteBiilingTariffInfo.meterType">
          <option *ngFor="let meterType of meterTypes" [ngValue]="meterType">{{meterType}}</option>
        </select>
      </clr-select-container>
    </form>
  </div>
  <div class="modal-footer">
    <button type="button" class="btn btn-outline" (click)="showDeleteBillingTariff = false">Cancel</button>
    <button type="button" class="btn btn-primary"
      (click)="showDeleteBillingTariff = false; onExecuteDeletetBillingTariff()">Ok</button>
  </div>
</clr-modal>

<clr-modal [(clrModalOpen)]="showSetResidentExchange" [clrModalClosable]="false">
  <h3 class="modal-title">Set resident exchange</h3>
  <div class="modal-body">
    <form clrForm *ngIf="setResidentExchangeInfo">

      <clr-input-container>
        <label>Exchange date:</label>
        <input clrInput type="date" step="1" placeholder="" name="setresidentexchangedate"
          [(ngModel)]="setResidentExchangeInfo.exchangeDateString" />
      </clr-input-container>
    </form>
  </div>
  <div class="modal-footer">
    <button type="button" class="btn btn-outline" (click)="showSetResidentExchange = false">Cancel</button>
    <button type="button" class="btn btn-primary"
      (click)="showSetResidentExchange = false; onExecuteSetResidentExchange()">Ok</button>
  </div>
</clr-modal>

<clr-modal [(clrModalOpen)]="showSetMeterExchange" [clrModalClosable]="false">
  <h3 class="modal-title">Set meter exchange</h3>
  <div class="modal-body">
    <form clrForm *ngIf="setMeterExchangeInfo">

      <clr-input-container>
        <label class="clr-col-4">Exchange date:</label>
        <input class="clr-col-8" clrInput type="date" step="1" placeholder="" name="setmeterexchangedate"
          [(ngModel)]="setMeterExchangeInfo.exchangeDateString" />
      </clr-input-container>

      <clr-input-container>
        <label class="clr-col-4">Identifier:</label>
        <input class="clr-col-8" clrInput type="text" maxlength="50" placeholder="" name="setmeterexchangeidentifier"
          [(ngModel)]="setMeterExchangeInfo.identifier" />
      </clr-input-container>

      <clr-input-container *ngIf="setMeterExchangeInfo.showManufacturer">
        <label class="clr-col-4">Manufacturer:</label>
        <input class="clr-col-8" clrInput type="text" maxlength="3" placeholder="" name="setmeterexchangemanufacturer"
          [(ngModel)]="setMeterExchangeInfo.manufacturer" />
      </clr-input-container>

      <clr-input-container>
        <label class="clr-col-4">Start value ({{setMeterExchangeInfo.unit}}):</label>
        <input class="clr-col-8" clrInput type="number" min="0" max="999999999" placeholder=""
          name="setmeterexchangestartvalue" [(ngModel)]="setMeterExchangeInfo.meterExchange.newMeterStartValue" />
      </clr-input-container>

      <clr-input-container>
        <label class="clr-col-4">End value, old meter ({{setMeterExchangeInfo.unit}}):</label>
        <input class="clr-col-8" clrInput type="number" min="0" max="999999999" placeholder=""
          name="setmeterexchangeoldmeterendvalue" [(ngModel)]="setMeterExchangeInfo.meterExchange.oldMeterEndValue" />
      </clr-input-container>

      <clr-input-container>
        <label class="clr-col-4">Last exported date:</label>
        <input class="clr-col-8" clrInput type="text" placeholder="" name="setmeterexchangelastexportedvaluedate"
          [disabled]="true" [(ngModel)]="setMeterExchangeInfo.lastExportedValueDate" />
      </clr-input-container>

      <clr-input-container>
        <label class="clr-col-4">Last exported value:</label>
        <input class="clr-col-8" clrInput type="text" placeholder="" name="setmeterexchangelastexportedvalue"
          [disabled]="true" [(ngModel)]="setMeterExchangeInfo.lastExportedValue" />
      </clr-input-container>

    </form>
  </div>
  <div class="modal-footer">
    <button type="button" class="btn btn-outline" (click)="showSetMeterExchange = false">Cancel</button>
    <button type="button" class="btn btn-primary"
      (click)="showSetMeterExchange = false; onExecuteSetMeterExchange()">Ok</button>
  </div>
</clr-modal>

<clr-modal [(clrModalOpen)]="showManualMeterReading" [clrModalClosable]="false">
  <h3 class="modal-title">Add manual meter reading</h3>
  <div class="modal-body">
    <form clrForm *ngIf="setManualMeterReadingInfo">

      <clr-input-container>
        <label class="clr-col-4">Timestamp:</label>
        <input class="clr-col-8" clrInput type="datetime-local" step="1" placeholder=""
          name="addmanualmeterreadingtimestamp" [(ngModel)]="setManualMeterReadingInfo.timestampString" />
      </clr-input-container>

      <clr-input-container>
        <label class="clr-col-4">Value ({{setManualMeterReadingInfo.unit}}):</label>
        <input class="clr-col-8" clrInput type="number" min="0" max="999999999" placeholder=""
          name="addmanualmeterreadingvalue" [(ngModel)]="setManualMeterReadingInfo.value" />
      </clr-input-container>

    </form>
  </div>
  <div class="modal-footer">
    <button type="button" class="btn btn-outline" (click)="showManualMeterReading = false">Cancel</button>
    <button type="button" class="btn btn-primary"
      (click)="showManualMeterReading = false; this.onExecuteAddManualMeterReading()">Ok</button>
  </div>
</clr-modal>

<clr-modal [(clrModalOpen)]="showGridEditModal">
  <h3 class="modal-title">Edit node</h3>
  <div class="modal-body">
    <app-edit-treenode #editGridNode *ngIf="showGridEditModal && currentGridEditNode" [node]="currentGridEditNode"
      [nodeTypes]="allNodeTypes" [customerId]="selectedCustomer?.id"></app-edit-treenode>

  </div>
  <div class="modal-footer">
    <button class="btn btn-outline" (click)="closeGridEditNode()">Cancel</button>
    <button class="btn btn-primary" (click)="saveGridEditNode()">Save</button>
  </div>
</clr-modal>

<clr-modal [(clrModalOpen)]="showGridEditBuildingAddresses" [clrModalClosable]="false">
  <h3 class="modal-title">Edit building addresses</h3>
  <div class="modal-body">
    <form clrForm *ngIf="setBuildingAddressesInfo">
      <clr-input-container>
        <label>City:</label>
        <input clrInput type="text" placeholder="" name="setbuildingaddressescity"
          [(ngModel)]="setBuildingAddressesInfo.city" />
      </clr-input-container>
      <clr-select-container>
        <label>District</label>
        <select clrSelect name="setbuildingaddressesdistrict" [(ngModel)]="setBuildingAddressesInfo.district">
          <option *ngFor="let item of districts" [ngValue]="item">{{item}}</option>
        </select>
      </clr-select-container>
      <clr-input-container>
        <label>Postal code:</label>
        <input clrInput type="text" placeholder="" name="setbuildingaddressespostalcode"
          [(ngModel)]="setBuildingAddressesInfo.postalCode" />
      </clr-input-container>
    </form>
  </div>
  <div class="modal-footer">
    <button type="button" class="btn btn-outline" (click)="showGridEditBuildingAddresses = false">Cancel</button>
    <button type="button" class="btn btn-primary"
      (click)="showGridEditBuildingAddresses = false; executeSetBuildingAddresses()">Ok</button>
  </div>
</clr-modal>

<clr-modal [(clrModalOpen)]="showSetMeterStateEvent" [clrModalClosable]="false">
  <h3 class="modal-title">Set meter state event</h3>
  <div class="modal-body">
    <form clrForm *ngIf="meterStateEventInfo">

      <clr-select-container>
        <label>Meter state</label>
        <select clrSelect name="meterstateeventmeterstate" [(ngModel)]="meterStateEventInfo.meterState">
          <option [ngValue]="BmsMeterState.Inactive">Inactive</option>
          <option [ngValue]="BmsMeterState.InactiveReplaced">InactiveReplaced</option>
          <option [ngValue]="BmsMeterState.Test">Test</option>
          <option [ngValue]="BmsMeterState.ActiveBilling">ActiveBilling</option>
          <option [ngValue]="BmsMeterState.ActiveExport">ActiveExport</option>
          <option [ngValue]="BmsMeterState.ActiveNoBilling">ActiveNoBilling</option>
        </select>
      </clr-select-container>

      <clr-input-container>
        <label>Change meter state date:</label>
        <input clrInput type="date" step="1" placeholder="" name="setmeterstateeventdate"
          [(ngModel)]="meterStateEventInfo.activeFromString" />
      </clr-input-container>
    </form>
  </div>
  <div class="modal-footer">
    <button type="button" class="btn btn-outline" (click)="showSetMeterStateEvent = false">Cancel</button>
    <button type="button" class="btn btn-primary"
      (click)="showSetMeterStateEvent = false; onExecuteSetMeterStateEvent()">Ok</button>
  </div>
</clr-modal>

<clr-modal [(clrModalOpen)]="showSetInvoiceInfo" [clrModalClosable]="false">
  <h3 *ngIf="selectedInvoiceInfo?.invoiceDisabled" class="modal-title">Set stop invoice</h3>
  <h3 *ngIf="selectedInvoiceInfo && !selectedInvoiceInfo.invoiceDisabled" class="modal-title">Set start invoice</h3>
  <div class="modal-body">
    <form clrForm *ngIf="selectedInvoiceInfo">
      <clr-select-container>
        <label class="clr-col-4">Meter type</label>
        <select class="clr-col-8" clrSelect name="setinvoiceinfometertype" [(ngModel)]="selectedInvoiceInfo.meterType">
          <option ngValue="">All metertypes</option>
          <option *ngFor="let meterType of meterTypes" [ngValue]="meterType">{{meterType}}</option>
        </select>
      </clr-select-container>

      <clr-input-container>
        <label *ngIf="selectedInvoiceInfo?.invoiceDisabled" class="clr-col-4">Stop invoice from:</label>
        <label *ngIf="selectedInvoiceInfo && !selectedInvoiceInfo.invoiceDisabled" class="clr-col-4">Start invoice from:</label>
        <input class="clr-col-8" clrInput type="date" step="1" placeholder="" name="setinvoiceinfoactivefrom"
          [(ngModel)]="selectedInvoiceInfo.activeFromString" />
      </clr-input-container>
    </form>
  </div>
  <div class="modal-footer">
    <button type="button" class="btn btn-outline" (click)="showSetInvoiceInfo = false">Cancel</button>
    <button type="button" class="btn btn-primary"
      (click)="showSetInvoiceInfo = false; saveSelectedInvoiceInfo()">Ok</button>
  </div>
</clr-modal>

<clr-modal [(clrModalOpen)]="showXEdgeSelectMetersModal" [clrModalSize]="'xl'" *ngIf="showXEdgeSelectMetersModal"
  [clrModalClosable]="false">
  <h3 class="modal-title">Add XEdge meters</h3>
  <div class="modal-body xedge-select-meter-modal">
    <app-xedge-select-xauto-vars #selectXEdgeMeters [remoteNodeId]="xedgeRemoteNodeId"></app-xedge-select-xauto-vars>
  </div>
  <div class="modal-footer">
    <button type="button" class="btn btn-secondary" (click)="showXEdgeSelectMetersModal=false;">Cancel</button>
    <button type="button" class="btn btn-primary" [disabled]="selectXEdgeMeters?.selectedXAutoVars?.length < 1"
      (click)="addSelectedXEdgeMeters()">Add selected meters ({{selectXEdgeMeters?.selectedXAutoVars?.length}})</button>
  </div>
</clr-modal>

<clr-modal [(clrModalOpen)]="showCustomerConfigurationsModal" [clrModalSize]="'xl'" *ngIf="showCustomerConfigurationsModal && selectedBmsCustomer"
  [clrModalClosable]="false">
  <h3 class="modal-title">Customer configurations</h3>
  <div class="modal-body customer-configurations-modal">
    <app-bms-customer-configurations [selectedBmsCustomer]="selectedBmsCustomer" [selectedCustomer]="selectedCustomer"></app-bms-customer-configurations>
  </div>
  <div class="modal-footer">
    <button type="button" class="btn btn-secondary" (click)="showCustomerConfigurationsModal=false;">Close</button>
  </div>
</clr-modal>

<as-split direction="horizontal" [unit]="'pixel'" (dragEnd)="onSplitDragEnd()" class="main-area" [gutterSize]="7">
  <as-split-area [order]="1">
    <div class="clr-row">
      <div class="clr-col-8">
        <h3 *ngIf="selectedBmsCustomer" class="sensitive">{{selectedBmsCustomer.customerName}}
          ({{selectedCustomerConfig?.contractType}})
          {{selectedCustomerConfig?.disabled ? ' (Disabled)' : ''}}
        </h3>
      </div>
      <div class="clr-col-4">
        <div class="searchbar-container">
          <div class="icons-search-wrapper">
            <label class="searchbar-label">
              <clr-icon shape="search" size="24"></clr-icon>
              <input type="text" class="searchbar-input" placeholder="Search..." (keyup.enter)="search()"
                [(ngModel)]="searchValue">
            </label>
          </div>
        </div>
      </div>
    </div>

    <clr-tabs #tabs>
      <clr-tab>
        <button clrTabLink>Overview</button>
        <ng-template [(clrIfActive)]="overviewActive">
          <clr-tab-content>
            <div class="customers-content-area">
              <xproj-dashboard #dashboardOverview [editMode]="false" [showDashboardSettings]="false"
                [systemDashboard]="true" [dashboardId]="'bms_customers_overview'" [enableExport]="false"
                [dashboardTag]="'latest'" [responsiveWidth]="overviewResponsiveWidth"
                [dashboardOutputParameters]="dashboardOverviewOutputParameters"
                (onWidgetValueSelected)="onOverviewDashboardValueChanged($event)"></xproj-dashboard>
            </div>
          </clr-tab-content>
        </ng-template>
      </clr-tab>
      <clr-tab>
        <button clrTabLink (click)="bmsActive = true;">BMS</button>
        <div class="tab-area" *ngIf="bmsActive">
          <clr-tab-content>
            <div class="customers-content-area">
              <as-split direction="horizontal" [unit]="'pixel'" (dragEnd)="onBmsSplitDragEnd($event)" [gutterSize]="7">
                <as-split-area [order]="11">
                  <app-configuration-datasource #bmsConfigurationDatasource
                    [sensitiveNodes]="['_x_bms_realestate', '_x_bms_building', '_x_bms_buildingaddress']"
                    *ngIf="bmsDataSourceInstance && selectedCustomerRealestateNodes?.length > 0" [lazy]="false"
                    [dataSourceInstance]="bmsDataSourceInstance" [rootNodes]="selectedCustomerRealestateNodes"
                    [expandRoot]="true" [customerId]="selectedBmsCustomer.customerId" [sortByName]="true"
                    [sortPaddingZeros]="10" [highlightSelected]="true" (onTreeNodeSelect)="onTreeNodeSelect($event)"
                    [dropDownItems]="dropDownItems" [moveEnabled]="true" [selectedPath]="bmsSelectedPath"
                    [searchParameterIds]="[{typeName : 'string', key : 'seconadaryaddress'}, {typeName : 'string', key : 'deveui'}]"
                    [moveEnabledNodeTypeIds]="['_x_bms_building', '_x_bms_buildingaddress', '_x_bms_facility', '_x_bms_apartment']"
                    [singletonReferences]="true" [loadTreeNodeTypeIds]="['_x_bms_building']">
                  </app-configuration-datasource>
                </as-split-area>

                <as-split-area [(size)]="bmsRightPanelWidth" [order]="12">
                  <!-- <app-bms-3d-configuration *ngIf="selectedBmsTreeNode && selectedBmsTreeNode.nodeTypeLabel == '_x_bms_building'"
                      [bmsCustomer]="selectedBmsCustomer" [node]="selectedBmsTreeNode" [customer]="selectedCustomer" >
                  </app-bms-3d-configuration>
                  <div *ngIf="this.selectedBmsTreeNode && this.selectedBmsTreeNode.nodeTypeLabel != '_x_bms_building'">
                    <app-bms-meter-list #bmsMeterList [selectedNode]="selectedBmsTreeNode" [subordinateNodes]="false"
                    [maxHeight]="300" (onSelectMeter)="onSelectMeter($event)"></app-bms-meter-list> -->
                    <div *ngIf="this.selectedBmsTreeNode">
                      <app-bms-meter-list #bmsMeterList [selectedNode]="selectedBmsTreeNode" [subordinateNodes]="false"
                      [maxHeight]="300" (onSelectMeter)="onSelectMeter($event)"></app-bms-meter-list>

                  <!-- <button type="button" class="btn btn-link" (click)="addMeter()">
                    <clr-icon shape="plus-circle"></clr-icon>
                    Add meter
                  </button>
                  <button *ngIf="selectedMeter" type="button" class="btn btn-link" (click)="deleteSelectedMeter()">
                    <clr-icon shape="minus-circle"></clr-icon>
                    Remove selected meter
                  </button> -->
                  <app-bms-billing-tariff-view #billingTariffView *ngIf="selectedMeter" [allowEdit]="true">
                  </app-bms-billing-tariff-view>
                  <app-bms-resident-exchang-view #residentExchangeView *ngIf="!selectedMeter" [allowEdit]="true">
                  </app-bms-resident-exchang-view>
                  </div>
                </as-split-area>
              </as-split>
            </div>
          </clr-tab-content>
        </div>
      </clr-tab>
      <clr-tab>
        <button clrTabLink (click)="dataCollectorsActive = true;">Meters</button>
        <div class="tab-area" *ngIf="dataCollectorsActive">
          <clr-tab-content>
            <div class="customers-content-area">
              <as-split direction="horizontal" [unit]="'pixel'" (dragEnd)="onMeterSplitDragEnd($event)"
                class="main-area" [gutterSize]="7">
                <as-split-area [order]="21">
                  <app-configuration-datasource #metersDatasourceInstance [sensitiveNodes]="['_x_datacollectors_group']"
                    *ngIf="dataCollectorsDataSourceInstance && selectedCustomerDataCollectorNodes?.length > 0"
                    [dataSourceInstance]="dataCollectorsDataSourceInstance" [lazy]="false" [treeMaxHops]="10"
                    [rootNodes]="selectedCustomerDataCollectorNodes" [sortByName]="true" [sortPaddingZeros]="10"
                    [highlightSelected]="true" [customerId]="this.selectedBmsCustomer?.customerId"
                    (onTreeNodeSelect)="onDataCollectorsTreeNodeSelect($event)" [moveEnabled]="true"
                    [moveEnabledNodeTypeIds]="['_x_bms_mbus_meter', '_x_lorawan_multimeter']" [expandRoot]="true" [eventLogdEnabled]="true"
                    [selectedPath]="metersSelectedPath" [dropDownItems]="dropDownItemsMeters" [loadTreeNodeTypeIds]="['_x_lorawan_group']">
                  </app-configuration-datasource>
                </as-split-area>
                <as-split-area [(size)]="meterRightPanelWidth" [order]="22">
                  <app-bms-data-collector-view #bmsDataCollectorView>
                  </app-bms-data-collector-view>
                </as-split-area>
              </as-split>

            </div>
          </clr-tab-content>
        </div>
      </clr-tab>
      <clr-tab>
        <button clrTabLink>Tariffs</button>
        <ng-template [(clrIfActive)]="tariffsActive">
          <div class="tab-area">
            <clr-tab-content>
              <div class="customers-content-area">
                <h4>Customer billing tariffs</h4>
                <app-bms-customer-tariffs [data]="customerTariffData" [admin]="true"></app-bms-customer-tariffs>

                <clr-accordion class="tariff-groups">
                  <clr-accordion-panel [(clrAccordionPanelOpen)]="tariffGroupViewOpen">
                    <clr-accordion-title>Tariff groups</clr-accordion-title>
                    <clr-accordion-content *clrIfExpanded>
                      <app-bms-customers-admin-tariffgroups [customerId]="selectedCustomer?.id" [customerXdbId]="selectedBmsCustomer?.id"></app-bms-customers-admin-tariffgroups>
                    </clr-accordion-content>
                  </clr-accordion-panel>
                  <!-- <clr-accordion-panel [(clrAccordionPanelOpen)]="oldTariffViewOpen">
                    <clr-accordion-title>Old tariff view</clr-accordion-title>
                    <clr-accordion-content *clrIfExpanded>
                      <div class="old-tariff-view">
                        <div class="clr-row">
                          <div class="clr-col-2">
                            <button class="btn" style="margin-top: 2em;" (click)="onSetNewBillingTariff(null)">Set new
                              tariff...</button>
                          </div>
                          <div class="clr-col-2" style="margin-left: 1em;">
                            <button class="btn" style="margin-top: 2em;" (click)="onDeleteBillingTariffs(null)">Delete
                              tariff...</button>
                          </div>
                        </div>
                        <xproj-dashboard #dashboardTariffs style="margin-top: 2em; margin-left: 2em;" [editMode]="false"
                          [showDashboardSettings]="false" [dashboardId]="'bms_customers_tariff'" [enableExport]="false"
                          [responsiveWidth]="tariffsResponsiveWidth" [dashboardTag]="'latest'" [systemDashboard]="true"
                          [dashboardOutputParameters]="dashboardOverviewOutputParameters"></xproj-dashboard>
                      </div>
                    </clr-accordion-content>
                  </clr-accordion-panel> -->
                </clr-accordion>
              </div>
            </clr-tab-content>
          </div>
        </ng-template>
      </clr-tab>
      <clr-tab>
        <button clrTabLink>Grid</button>
        <ng-template [(clrIfActive)]="gridActive">
          <div class="grid-area" *ngIf="selectedCustomerData">
            <clr-tab-content>
              <clr-datagrid [(clrDgSelected)]="selectedMeterDatas" [clrDgLoading]="loadingMeterData">

                <clr-dg-action-bar>
                  <div class="btn-group">
                    <!-- <button type="button" class="btn btn-sm btn-secondary" (click)="onGridEditRealestates()"
                      [disabled]="selectedMeterDatas?.length != 1">
                      <clr-icon shape="pencil"></clr-icon> Edit realestate{{selectedMeterDatas.length > 1 ? 's' :
                      ''}}
                      ({{selectedMeterDatas.length}})
                    </button>
                    <button type="button" class="btn btn-sm btn-secondary" (click)="onGridEditBuildings()"
                      [disabled]="selectedMeterDatas?.length != 1">
                      <clr-icon shape="pencil"></clr-icon> Edit building{{selectedMeterDatas.length > 1 ? 's' : ''}}
                      ({{selectedMeterDatas.length}})
                    </button>
                    <button type="button" class="btn btn-sm btn-secondary" (click)="onGridEditBuildingAddresses()"
                      [disabled]="selectedMeterDatas?.length != 1">
                      <clr-icon shape="pencil"></clr-icon> Edit building address{{selectedMeterDatas.length > 1 ?
                      's'
                      : ''}} ({{selectedMeterDatas.length}})
                    </button> -->
                    <button type="button" class="btn btn-sm btn-secondary" (click)="onGridEditApartmentsOrFacilities()"
                      [disabled]="selectedMeterDatas?.length != 1">
                      <clr-icon shape="pencil"></clr-icon> Edit apartment/facility{{selectedMeterDatas.length > 1 ?
                      's' : ''}} ({{selectedMeterDatas.length}})
                    </button>
                    <button type="button" class="btn btn-sm btn-secondary" (click)="onGridEditMeters()"
                      [disabled]="selectedMeterDatas?.length != 1">
                      <clr-icon shape="pencil"></clr-icon> Edit meter{{selectedMeterDatas.length > 1 ? 's' : ''}}
                      ({{selectedMeterDatas.length}})
                    </button>
                  </div>

                  <clr-button-group class="btn-sm btn-secondary">
                    <clr-button (click)="onGridEditViewInBMS();" [disabled]="selectedMeterDatas?.length != 1">View in
                      BMS
                      <clr-icon shape="arrow" directions="left" style="transform: rotate(90deg);"></clr-icon>
                    </clr-button>
                    <clr-button (click)="onGridExport()">
                      <clr-icon shape="export"></clr-icon> Export
                    </clr-button>
                    <clr-button (click)="onGridRefresh()">
                      <clr-icon shape="refresh"></clr-icon> Refresh
                    </clr-button>
                    <clr-button [clrInMenu]="true" (click)="showEditBuildingAddresses()">
                      Edit building addresses...</clr-button>
                  </clr-button-group>
                  <clr-toggle-container class="showall-container">
                    <clr-toggle-wrapper>
                      <input type="checkbox" clrToggle [(ngModel)]="showInactiveMeters" (change)="onGridRefresh()"
                        name="showInactiveMeters" />
                      <label>Show all meters</label>
                    </clr-toggle-wrapper>
                  </clr-toggle-container>
                </clr-dg-action-bar>

                <clr-dg-column [clrDgField]="'seconadaryAddress'">
                  <ng-container *clrDgHideableColumn="{hidden: false}">Identifier</ng-container>
                </clr-dg-column>
                <clr-dg-column [clrDgField]="'externalId'" [clrDgSortBy]="externalIdSort" [clrDgSortOrder]="ascSort">
                  <ng-container *clrDgHideableColumn="{hidden: false}">External Id</ng-container>
                </clr-dg-column>
                <clr-dg-column [clrDgField]="'svlantApartmentno'">
                  <ng-container *clrDgHideableColumn="{hidden: false}">Apart. No</ng-container>
                </clr-dg-column>
                <clr-dg-column [clrDgField]="'name'">
                  <ng-container *clrDgHideableColumn="{hidden: true}">Name</ng-container>
                </clr-dg-column>
                <clr-dg-column [clrDgField]="'meterId'">
                  <ng-container *clrDgHideableColumn="{hidden: true}">Meter Id</ng-container>
                </clr-dg-column>
                <clr-dg-column [clrDgField]="'gw_serialnumber'">
                  <ng-container *clrDgHideableColumn="{hidden: true}">GW Serial</ng-container>
                </clr-dg-column>
                <clr-dg-column [clrDgField]="'manufacturer'">
                  <ng-container *clrDgHideableColumn="{hidden: false}">Vendor</ng-container>
                </clr-dg-column>
                <clr-dg-column [clrDgField]="'unit'">
                  <ng-container *clrDgHideableColumn="{hidden: false}">Unit</ng-container>
                </clr-dg-column>
                <clr-dg-column [clrDgField]="'state'">
                  <ng-container *clrDgHideableColumn="{hidden: false}">Meter state</ng-container>
                </clr-dg-column>
                <clr-dg-column [clrDgField]="'meterType'">
                  <ng-container *clrDgHideableColumn="{hidden: false}">Meter type</ng-container>
                </clr-dg-column>
                <clr-dg-column [clrDgField]="'tariffGroup'">
                  <ng-container *clrDgHideableColumn="{hidden: false}">Tariff group</ng-container>
                </clr-dg-column>
                <clr-dg-column [clrDgField]="'meterSubtype'">
                  <ng-container *clrDgHideableColumn="{hidden: false}">Meter subtype</ng-container>
                </clr-dg-column>
                <clr-dg-column [clrDgField]="'variable'">
                  <ng-container *clrDgHideableColumn="{hidden: true}">Variable</ng-container>
                </clr-dg-column>
                <clr-dg-column [clrDgField]="'svlantPropertyDesignation'" style="min-width: 15em;">
                  <ng-container *clrDgHideableColumn="{hidden: false}">Prop. Designation</ng-container>
                </clr-dg-column>
                <clr-dg-column [clrDgField]="'svLantBuildingNo'">
                  <ng-container *clrDgHideableColumn="{hidden: false}">Building No</ng-container>
                </clr-dg-column>
                <clr-dg-column [clrDgField]="'street'">
                  <ng-container *clrDgHideableColumn="{hidden: false}">Street</ng-container>
                </clr-dg-column>
                <clr-dg-column [clrDgField]="'housenumber'">
                  <ng-container *clrDgHideableColumn="{hidden: false}">House No.</ng-container>
                </clr-dg-column>
                <clr-dg-column [clrDgField]="'postalcode'">
                  <ng-container *clrDgHideableColumn="{hidden: false}">Postal code</ng-container>
                </clr-dg-column>
                <clr-dg-column [clrDgField]="'postalcode'">
                  <ng-container *clrDgHideableColumn="{hidden: false}">City</ng-container>
                </clr-dg-column>
                <clr-dg-column [clrDgField]="'district'">
                  <ng-container *clrDgHideableColumn="{hidden: false}">District</ng-container>
                </clr-dg-column>
                <clr-dg-column [clrDgField]="'system1'">
                  <ng-container *clrDgHideableColumn="{hidden: true}">System1</ng-container>
                </clr-dg-column>
                <clr-dg-column [clrDgField]="'system2'">
                  <ng-container *clrDgHideableColumn="{hidden: true}">System2</ng-container>
                </clr-dg-column>
                <clr-dg-column [clrDgField]="'system3'">
                  <ng-container *clrDgHideableColumn="{hidden: true}">System3</ng-container>
                </clr-dg-column>

                <clr-dg-row *clrDgItems="let meterData of selectedCustomerData.meterDatas; let i = index"
                  [clrDgItem]="meterData">
                  <clr-dg-cell>{{meterData.seconadaryAddress}}</clr-dg-cell>
                  <clr-dg-cell>{{meterData.externalId}}</clr-dg-cell>
                  <clr-dg-cell>{{meterData.svlantApartmentno}}</clr-dg-cell>
                  <clr-dg-cell>{{meterData.name}}</clr-dg-cell>
                  <clr-dg-cell>{{meterData.meterId}}</clr-dg-cell>
                  <clr-dg-cell>{{meterData.gw_serialnumber}}</clr-dg-cell>
                  <clr-dg-cell>{{meterData.manufacturer}}</clr-dg-cell>
                  <clr-dg-cell>{{meterData.unit}}</clr-dg-cell>
                  <clr-dg-cell>{{meterData.state}}</clr-dg-cell>
                  <clr-dg-cell>{{meterData.meterType}}</clr-dg-cell>
                  <clr-dg-cell>{{meterData.tariffGroup}}</clr-dg-cell>
                  <clr-dg-cell>{{meterData.meterSubtype}}</clr-dg-cell>
                  <clr-dg-cell>{{meterData.variable}}</clr-dg-cell>
                  <clr-dg-cell>{{meterData.svlantPropertyDesignation}}</clr-dg-cell>
                  <clr-dg-cell>{{meterData.svLantBuildingNo}}</clr-dg-cell>
                  <clr-dg-cell>{{meterData.street}}</clr-dg-cell>
                  <clr-dg-cell>{{meterData.housenumber}}</clr-dg-cell>
                  <clr-dg-cell>{{meterData.postalcode}}</clr-dg-cell>
                  <clr-dg-cell>{{meterData.city}}</clr-dg-cell>
                  <clr-dg-cell>{{meterData.district}}</clr-dg-cell>
                  <clr-dg-cell>{{meterData.system1}}</clr-dg-cell>
                  <clr-dg-cell>{{meterData.system2}}</clr-dg-cell>
                  <clr-dg-cell>{{meterData.system3}}</clr-dg-cell>
                </clr-dg-row>

                <ng-template [(clrIfDetail)]="gridDetailState" let-dataIndex
                  (clrIfDetailChange)="onGridDetailChange($event)">
                  <!-- <ng-template clrIfDetail let-dataIndex (clrIfDetailChange)="onDetailChange($event)"> -->
                  <clr-dg-detail>
                    <clr-dg-detail-header>Meter data</clr-dg-detail-header>
                    <clr-dg-detail-body>
                      <div class="meter-data-area">
                        <xproj-dashboard #dashboardGridDetail [editMode]="false" [showDashboardSettings]="false"
                          [systemDashboard]="true" [dashboardId]="'bms_meter'" [dashboardTag]="'latest'"
                          [enableExport]="false" [responsiveWidth]="gridResponsiveWidth"
                          [dashboardOutputParameters]="dashboardGridDetailOutputParameters"></xproj-dashboard>
                      </div>
                    </clr-dg-detail-body>
                  </clr-dg-detail>
                </ng-template>

                <clr-dg-footer>
                  <clr-dg-pagination #pagination [clrDgPageSize]="20">
                    <clr-dg-page-size [clrPageSizeOptions]="[10,20,50,100]">Meters per page</clr-dg-page-size>
                    {{pagination.firstItem + 1}} - {{pagination.lastItem + 1}} of {{pagination.totalItems}} meters
                  </clr-dg-pagination>
                </clr-dg-footer>
              </clr-datagrid>
            </clr-tab-content>
          </div>
        </ng-template>
      </clr-tab>
      <clr-tab>
        <button clrTabLink>Settings</button>
        <ng-template [(clrIfActive)]="settingsActive">
          <clr-tab-content>
            <div class="customers-content-area">
              <div class="clr-row sensitive">
                <div class="clr-col-5" style="margin-left: 1em;">
                  <div class="clr-row">
                    <div class="card">
                      <div class="card-header">
                        Customer Settings
                      </div>
                      <div class="card-block">
                        <div class="card-text">
                          <form clrForm clrLayout="vertical" autocomplete="off" spellcheck="false" *ngIf="selectedCustomerConfig">
                            <clr-input-container>
                              <label class="clr-col-12">Organisation number:</label>
                              <input clrInput class="clr-col-12" required placeholder="" name="organisationNumber"
                                size="20" type="text" [(ngModel)]="selectedCustomerConfig.organisationNumber" />
                            </clr-input-container>

                            <clr-input-container>
                              <label class="clr-col-12">Association Id (used by trustee):</label>
                              <input clrInput class="clr-col-12" required placeholder="" name="associationId" size="40"
                                type="text" [(ngModel)]="selectedCustomerConfig.associationId" />
                            </clr-input-container>

                            <clr-input-container>
                              <label class="clr-col-12">Association Id 2:</label>
                              <input clrInput class="clr-col-12" required placeholder="" name="associationId2" size="40"
                                type="text" [(ngModel)]="selectedCustomerConfig.associationId2" />
                            </clr-input-container>

                            <clr-textarea-container>
                              <label class="clr-col-12">Information</label>
                              <textarea clrTextarea class="clr-col-12" name="information" [rows]="8" [cols]="50"
                                [(ngModel)]="selectedCustomerConfig.information"></textarea>
                            </clr-textarea-container>

                            <clr-textarea-container>
                              <label class="clr-col-12">Operation info</label>
                              <textarea clrTextarea class="clr-col-12" name="operationinfo" [rows]="4" [cols]="50"
                                [(ngModel)]="selectedCustomerConfig.operationInfo"></textarea>
                            </clr-textarea-container>

                            <clr-textarea-container>
                              <label class="clr-col-12">Invoice info</label>
                              <textarea clrTextarea class="clr-col-12" name="invoiceinfo" [rows]="4" [cols]="50"
                                [(ngModel)]="selectedCustomerConfig.invoiceInfo"></textarea>
                            </clr-textarea-container>

                            <app-string-option-input
                              *ngIf="this.selectedCustomerConfig.contractTypeStringOptionsProperty"
                              [stringOptionsProperty]="selectedCustomerConfig.contractTypeStringOptionsProperty"
                              [(value)]="selectedCustomerConfig.contractType" [showHelp]="false">
                            </app-string-option-input>

                            <app-string-option-input
                              *ngIf="this.selectedCustomerConfig.partnerCompensationStringOptionsProperty"
                              [stringOptionsProperty]="selectedCustomerConfig.partnerCompensationStringOptionsProperty"
                              [(value)]="selectedCustomerConfig.partnerCompensation" [showHelp]="false">
                            </app-string-option-input>

                            <clr-input-container>
                              <label class="clr-col-12">SIM cards:</label>
                              <input clrInput class="clr-col-12" required placeholder="" name="simCardCount"
                                type="number" min="0" max="10000" [(ngModel)]="selectedCustomerConfig.simCardCount" />
                            </clr-input-container>

                            <clr-input-container>
                              <label class="clr-col-12">Tariff decimal count:</label>
                              <input clrInput class="clr-col-12" required placeholder="" name="tariffDecimalCount"
                                type="number" min="0" max="10"
                                [(ngModel)]="selectedCustomerConfig.tariffDecimalCount" />
                            </clr-input-container>

                            <clr-input-container>
                              <label class="clr-col-12">Report datapoint decimal count:</label>
                              <input clrInput class="clr-col-12" required placeholder=""
                                name="reportDataPointDecimalCount" type="number" min="0" max="10"
                                [(ngModel)]="selectedCustomerConfig.reportDataPointDecimalCount" />
                            </clr-input-container>

                            <clr-checkbox-container *ngIf="!selectedCustomerConfig.customerIsTrustee">
                              <clr-checkbox-wrapper>
                                <input type="checkbox" clrCheckbox
                                  [(ngModel)]="selectedCustomerConfig.paddingExternalIdOverrideTrustee"
                                  name="paddingExternalIdOverrideTrustee" />
                                <label>Override trustee padding settings.</label>
                              </clr-checkbox-wrapper>
                            </clr-checkbox-container>

                            <clr-input-container>
                              <label class="clr-col-12">ExternalId length (ex 4: '1' -> '0001' ):</label>
                              <input clrInput class="clr-col-12" required placeholder="" name="externalidlength"
                                type="number" min="0" max="20"
                                [disabled]="!selectedCustomerConfig.customerIsTrustee && !selectedCustomerConfig.paddingExternalIdOverrideTrustee"
                                [(ngModel)]="selectedCustomerConfig.paddingExternalIdCount" />
                            </clr-input-container>

                            <clr-checkbox-container *ngIf="!selectedCustomerConfig?.customerIsTrustee">
                              <clr-checkbox-wrapper>
                                <input type="checkbox" clrCheckbox
                                  [(ngModel)]="selectedCustomerConfig.mergeChargingStationsWithEl"
                                  name="mergechargingstationswithel" />
                                <label>Merge CHARGINGSTATIONS/ENGINEHEATER with EL when invoiced.</label>
                              </clr-checkbox-wrapper>
                            </clr-checkbox-container>

                            <clr-checkbox-container>
                              <clr-checkbox-wrapper>
                                <input type="checkbox" clrCheckbox [(ngModel)]="selectedCustomerConfig.eventsEnabled"
                                  name="eventsenabled" />
                                <label>Notifications enabled.</label>
                              </clr-checkbox-wrapper>
                            </clr-checkbox-container>

                            <clr-checkbox-container>
                              <clr-checkbox-wrapper>
                                <input type="checkbox" clrCheckbox [(ngModel)]="lateInvoice" name="lateinvoce" />
                                <label>Invoice after 15th.</label>
                              </clr-checkbox-wrapper>
                            </clr-checkbox-container>

                            <clr-input-container>
                              <label class="clr-col-12">Extrapolate coefficient:</label>
                              <input clrInput class="clr-col-12" required placeholder="" name="extapolatecoefficient"
                                type="number" min="0" max="1" step="0.01"
                                [(ngModel)]="selectedCustomerConfig.extrapolateCoefficient" />
                            </clr-input-container>

                            <clr-select-container>
                              <label class="clr-col-12">Billing period</label>
                              <select clrSelect class="clr-col-12" name="billingperiod"
                                [(ngModel)]="selectedCustomerConfig.billingPeriod">
                                <option [ngValue]="BmsBillingPeriod.Week"> Week </option>
                                <option [ngValue]="BmsBillingPeriod.Month"> Month </option>
                                <option [ngValue]="BmsBillingPeriod.Quarter"> Quarter </option>
                                <option [ngValue]="BmsBillingPeriod.Year"> Year </option>
                              </select>
                            </clr-select-container>

                            <clr-select-container>
                              <label class="clr-col-12">Billing quarter start month</label>
                              <select clrSelect class="clr-col-12" name="billingperiodstartmonth"
                                [(ngModel)]="selectedCustomerConfig.billingPeriodStartMonth"
                                [disabled]="selectedCustomerConfig.billingPeriod != BmsBillingPeriod.Quarter">
                                <option [ngValue]="BmsMonthOfYear.January"> January </option>
                                <option [ngValue]="BmsMonthOfYear.February"> February </option>
                                <option [ngValue]="BmsMonthOfYear.March"> March </option>
                                <option [ngValue]="BmsMonthOfYear.April"> April </option>
                                <option [ngValue]="BmsMonthOfYear.May"> May </option>
                                <option [ngValue]="BmsMonthOfYear.June"> June </option>
                                <option [ngValue]="BmsMonthOfYear.July"> July </option>
                                <option [ngValue]="BmsMonthOfYear.August"> August </option>
                                <option [ngValue]="BmsMonthOfYear.September"> September </option>
                                <option [ngValue]="BmsMonthOfYear.October"> October </option>
                                <option [ngValue]="BmsMonthOfYear.November"> November </option>
                                <option [ngValue]="BmsMonthOfYear.December"> December </option>
                              </select>
                            </clr-select-container>

                            <clr-input-container>
                              <label>Billing period changed at:</label>
                              <input clrInput type="date" step="1" placeholder="" name="billingperiodchangedat"
                                [(ngModel)]="selectedCustomerConfig.billingPeriodChangedAtString" />
                            </clr-input-container>

                            <clr-checkbox-container>
                              <clr-checkbox-wrapper>
                                <input type="checkbox" clrCheckbox
                                  [(ngModel)]="selectedCustomerConfig.customerIsTrustee" name="customerIsTrustee" />
                                <label>Is trustee</label>
                              </clr-checkbox-wrapper>
                            </clr-checkbox-container>

                            <clr-checkbox-container *ngIf="selectedCustomerConfig.customerIsTrustee">
                              <clr-checkbox-wrapper>
                                <input type="checkbox" clrCheckbox [(ngModel)]="selectedCustomerConfig.billingEnabled"
                                  name="billingEnabled" />
                                <label>Trustee billing enabled</label>
                              </clr-checkbox-wrapper>
                            </clr-checkbox-container>
                          </form>
                        </div>
                      </div>
                      <div class="card-footer">
                        <div class="btn-group btn-primary">
                          <button type="submit" class="btn btn-outline" (click)="saveSelectedCustomerConfig();">
                            <clr-icon shape="floppy"></clr-icon>
                            Save
                          </button>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div class="clr-row">
                    <div class="card">
                      <div class="card-header">
                        General configuration
                      </div>
                      <div class="card-block">
                        <div class="card-text">
                          <form clrForm clrLayout="vertical" autocomplete="off">
                            <clr-input-container>
                              <label class="clr-col-12">Customer id:</label>
                              <input clrInput class="clr-col-12" required placeholder="customerid" name="customerid"
                                size="40" type="text" [(ngModel)]="selectedCustomer.id" [disabled]="true" />
                            </clr-input-container>
                            <clr-input-container>
                              <label class="clr-col-12">Customer username:</label>
                              <input clrInput class="clr-col-12" required placeholder="customerid" size="40"
                                name="customerusername" type="text" [(ngModel)]="selectedCustomer.username" />
                            </clr-input-container>
                            <clr-input-container>
                              <label class="clr-col-12">Customer name:</label>
                              <input clrInput class="clr-col-12" required placeholder="customername" name="customername"
                                size="40" type="text" [(ngModel)]="selectedCustomer.name" />
                            </clr-input-container>
                            <clr-input-container>
                              <label class="clr-col-12">Customer description:</label>
                              <input clrInput class="clr-col-12" required placeholder="customerdescription" size="40"
                                name="customerdescription" type="text" [(ngModel)]="selectedCustomer.description" />
                            </clr-input-container>
                            <!-- <clr-input-container>
                            <label class="clr-col-12">External id:</label>
                            <input clrInput class="clr-col-12" placeholder="" name="customerexternalid" type="text"
                              [(ngModel)]="selectedCustomer.externalId" />
                          </clr-input-container> -->
                            <clr-checkbox-container>
                              <clr-checkbox-wrapper>
                                <input type="checkbox" clrCheckbox [(ngModel)]="selectedCustomer.isEnabled"
                                  name="customerenabled" />
                                <label>Enabled</label>
                              </clr-checkbox-wrapper>
                            </clr-checkbox-container>
                            <clr-checkbox-container>
                              <clr-checkbox-wrapper>
                                <input type="checkbox" clrCheckbox [(ngModel)]="selectedCustomer.sandboxEnabled"
                                  name="customersandboxEnabled" />
                                <label>Sandbox Enabled</label>
                              </clr-checkbox-wrapper>
                            </clr-checkbox-container>
                          </form>
                        </div>
                      </div>
                      <div class="card-footer">
                        <div class="btn-group btn-primary">
                          <button type="submit" class="btn btn-outline" (click)="saveSelectedCustomer();">
                            <clr-icon shape="floppy"></clr-icon>
                            Save
                          </button>
                          <button type="submit" class="btn btn-danger-outline" (click)="removeSelectedCustomer();">
                            <clr-icon shape="trash"></clr-icon>
                            Delete
                          </button>
                        </div>
                        <div class="btn-group btn-outline">
                          <button type="button" class="btn btn-outline" (click)="showCustomerConfigurationsModal = true;">
                            <clr-icon shape="cog"></clr-icon>
                            Advanced...
                          </button>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div class="clr-col-5" style="margin-left: 1em;">
                  <div class="clr-row">
                    <div class="card">
                      <div class="card-header">
                        Customer Trustees
                      </div>
                      <div class="card-block">
                        <div class="card-text">
                          <table class="table">
                            <caption>
                              Trustees
                            </caption>
                            <thead>
                              <tr>
                                <th class="left">Trustee</th>
                              </tr>
                            </thead>
                            <tbody>
                              <tr *ngFor="let trustee of selectedCustomerTrustees">
                                <td class="left"><a [routerLink]="['/bmsadmin/trusteeadmin/' + trustee.id]"
                                    routerLinkActive="active">{{trustee.name}}</a></td>
                              </tr>
                            </tbody>
                          </table>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div class="clr-row">
                    <div class="card">
                      <div class="card-header">
                        Welcome mail
                      </div>
                      <div class="card-block" *ngIf="selectedCustomerWelcomeFlag">
                        <div class="card-text">
                          <div>Status: {{selectedCustomerWelcomeFlag.status > 0 ? 'Sent' : 'Not sent'}}</div>
                          <div class="welcomeMailStatus">Sent at: {{selectedCustomerWelcomeFlag.status > 0 ? dateHelper.utils.formatByString(selectedCustomerWelcomeFlag.modifiedAt, 'yyyy-MM-dd HH:mm') : '---'}}</div>
                          <button type="button" class="btn btn-outline" (click)="sendWelcomeMail()">
                            <clr-icon shape="email"></clr-icon>
                            Send welcome mail...
                          </button>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div class="clr-row">
                    <div class="card">
                      <div class="card-header">
                        Start/Stop invoice
                      </div>
                      <div class="card-block" *ngIf="invoiceInfos">
                        <div class="card-text">
                          <p><b>Start invoice</b></p>
                          <table class="table">
                            <thead>
                              <tr>
                                <th class="left">Metertype</th>
                                <th class="left">Start invoice from</th>
                                <th></th>
                              </tr>
                            </thead>
                            <tbody>
                              <tr *ngFor="let invoiceInfo of invoiceInfos | filterBy: ['invoiceDisabled']: 0">
                                <td class="left">{{invoiceInfo.meterType.length > 0 ? invoiceInfo.meterType : 'All
                                  metertypes'}}</td>
                                <td class="left">{{dateHelper.utils.formatByString(invoiceInfo.activeFrom,
                                  'yyyy-MM-dd')}}</td>
                                <td class="left">
                                  <clr-icon (click)="editInvoiceInfo(invoiceInfo);" shape="pencil"
                                    [style.cursor]="'pointer'">
                                  </clr-icon>
                                  <clr-icon (click)="deleteInvoiceInfo(invoiceInfo, true);" shape="trash"
                                    [style.cursor]="'pointer'" [style.margin-left.em]="1">
                                  </clr-icon>
                                </td>
                              </tr>
                            </tbody>
                          </table>
                          <button type="button" class="btn btn-link" (click)="addInvoiceInfo(false)">
                            <clr-icon shape="plus-circle"></clr-icon>
                            Add start invoice
                          </button>

                          <p><b>Stop invoice</b></p>
                          <table class="table">
                            <thead>
                              <tr>
                                <th class="left">Metertype</th>
                                <th class="left">Stop invoice from</th>
                                <th></th>
                              </tr>
                            </thead>
                            <tbody>
                              <tr *ngFor="let invoiceInfo of invoiceInfos | filterBy: ['invoiceDisabled']: 1">
                                <td class="left">{{invoiceInfo.meterType.length > 0 ? invoiceInfo.meterType : 'All
                                  metertypes'}}</td>
                                <td class="left">{{dateHelper.utils.formatByString(invoiceInfo.activeFrom,
                                  'yyyy-MM-dd')}}</td>
                                <td class="left">
                                  <clr-icon (click)="editInvoiceInfo(invoiceInfo);" shape="pencil"
                                    [style.cursor]="'pointer'">
                                  </clr-icon>
                                  <clr-icon (click)="deleteInvoiceInfo(invoiceInfo, false);" shape="trash"
                                    [style.cursor]="'pointer'" [style.margin-left.em]="1">
                                  </clr-icon>
                                </td>
                              </tr>
                            </tbody>
                          </table>
                          <button type="button" class="btn btn-link" (click)="addInvoiceInfo(true)">
                            <clr-icon shape="plus-circle"></clr-icon>
                            Add stop invoice
                          </button>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div class="clr-row">
                    <div class="card">
                      <div class="card-header">
                        Geo location
                      </div>
                      <div class="card-block">
                        <form clrForm clrLayout="vertical" autocomplete="off">

                          <clr-checkbox-container>
                            <clr-checkbox-wrapper>
                              <input type="checkbox" clrCheckbox [(ngModel)]="geoLocationUpdateOverwrite"
                                name="geolocationupdateoverwrite" />
                              <label>Geo location overwrite</label>
                            </clr-checkbox-wrapper>
                          </clr-checkbox-container>
                        </form>

                        <button type="button" class="btn btn-outline" [clrLoading]="geoLocationState"
                          (click)="geoLocationUpdate()">
                          <clr-icon shape="plus-circle"></clr-icon>
                          Update geo location
                        </button>
                      </div>
                    </div>
                  </div>
                  <div class="clr-row">
                    <div class="card dashboards">
                      <div class="card-header">
                        Dashboards
                      </div>
                      <div class="card-block">
                        <app-configuration-datasource *ngIf="dashboardsDataSourceInstance" [lazy]="false"
                          [dataSourceInstance]="dashboardsDataSourceInstance" [highlightSelected]="false">
                        </app-configuration-datasource>
                      </div>
                    </div>
                  </div>
                  <div class="clr-row">
                    <div class="card dashboards">
                      <div class="card-header">
                        Event settings
                      </div>
                      <div class="card-block">
                        <app-configuration-datasource *ngIf="eventSettingsDataSourceInstance" [lazy]="false"
                          [dataSourceInstance]="eventSettingsDataSourceInstance" [highlightSelected]="false">
                        </app-configuration-datasource>
                      </div>
                    </div>
                    <div class="card dashboards">
                      <div class="card-header">
                        Realestates
                      </div>
                      <div class="card-block">
                        <app-configuration-datasource *ngIf="realestateDataSourceInstance" [sortByName]="true"
                          [expandRoot]="true" [customerId]="selectedBmsCustomer.customerId" [lazy]="false"
                          [dataSourceInstance]="realestateDataSourceInstance" [highlightSelected]="false">
                        </app-configuration-datasource>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </clr-tab-content>
        </ng-template>
      </clr-tab>
      <clr-tab>
        <button clrTabLink>Users</button>
        <ng-template [(clrIfActive)]="usersActive">
          <clr-tab-content>
            <div class="customers-content-area sensitive">
              <clr-accordion [clrAccordionMultiPanel]="true">
                <clr-accordion-panel [(clrAccordionPanelOpen)]="adminUsersExpanded">
                  <clr-accordion-title>Admin users</clr-accordion-title>
                  <clr-accordion-content>
                    <app-sys-admin-user-profiles [customerId]="this.selectedCustomer?.id"
                      (onUserSelected)="usersCustomerUserSelected($event)"></app-sys-admin-user-profiles>
                    <app-bms-external-customers #externalCustomers></app-bms-external-customers>
                  </clr-accordion-content>
                </clr-accordion-panel>
                <clr-accordion-panel [(clrAccordionPanelOpen)]="externalUsersExpanded">
                  <clr-accordion-title>External users</clr-accordion-title>
                  <clr-accordion-content>
                    <app-bms-external-users [customer]="this.selectedBmsCustomer" [customers]="customers">
                    </app-bms-external-users>
                  </clr-accordion-content>
                </clr-accordion-panel>
                <clr-accordion-panel [(clrAccordionPanelOpen)]="administraionUsersExpanded">
                  <clr-accordion-title>Users Administration</clr-accordion-title>
                  <clr-accordion-content>
                    <app-bms-customer-admin [data]="customerAdministrationData" [admin]="true"></app-bms-customer-admin>
                  </clr-accordion-content>
                </clr-accordion-panel>
              </clr-accordion>
            </div>
          </clr-tab-content>
        </ng-template>
      </clr-tab>
      <clr-tab>
        <button clrTabLink>Export / Import</button>
        <ng-template [(clrIfActive)]="exportImportActive">
          <clr-tab-content>
            <div class="customers-content-area">
              <div class="card">
                <div class="card-header">
                  Meters
                </div>
                <div class="card-block">
                  <div class="card-text">
                    <button class="btn btn-outline" [clrLoading]="exportingState" (click)="exportMeters()">
                      <clr-icon shape="export"></clr-icon>
                      Export meters
                    </button>

                    <form clrForm clrLayout="vertical" autocomplete="off">
                      <clr-input-container>
                        <label class="clr-col-12">Import meter file, select or drop file.</label>
                        <input clrInput class="clr-col-12" name="importmeterfile_file" #file type="file"
                          (change)="uploadMeterFile(file)" />
                      </clr-input-container>
                    </form>

                    <button type="button" class="btn btn-outline" [clrLoading]="importingState"
                      [disabled]="!importMeterfile" (click)="importMeterFiles()">
                      <clr-icon shape="import"></clr-icon>
                      Import meter file
                    </button>
                  </div>
                </div>
              </div>
              <div class="card">
                <div class="card-header">
                  Apartments and facilities
                </div>
                <div class="card-block">
                  <div class="card-text">
                    <button class="btn btn-outline" [clrLoading]="exportingState"
                      (click)="exportApartmentsAndFacilities()">
                      <clr-icon shape="export"></clr-icon>
                      Export Apartments and Facilities
                    </button>

                    <form clrForm clrLayout="vertical" autocomplete="off">
                      <clr-input-container>
                        <label class="clr-col-12">Import apartment file, select or drop file.</label>
                        <input clrInput class="clr-col-12" name="importaptfile_file" #aptfile type="file"
                          (change)="uploadApartmentFile(aptfile)" />
                      </clr-input-container>
                    </form>

                    <button type="button" class="btn btn-outline" [clrLoading]="importingState"
                      [disabled]="!importAparmtentfile" (click)="importApartmentFiles()">
                      <clr-icon shape="import"></clr-icon>
                      Import apartment file
                    </button>
                  </div>
                </div>
              </div>
              <div class="card">
                <div class="card-header">
                  Meter Export Addresses
                </div>
                <div class="card-block">
                  <div class="card-text">
                    <button class="btn btn-outline" [clrLoading]="exportingState"
                      (click)="exportMeterExportAddresses()">
                      <clr-icon shape="export"></clr-icon>
                      Export Meter Export Addresses
                    </button>

                    <form clrForm clrLayout="vertical" autocomplete="off">
                      <clr-input-container>
                        <label class="clr-col-12">Import meter export addresses file, select or drop file.</label>
                        <input clrInput class="clr-col-12" name="importmeterexportaddressesfile_file" #importmeterexportaddressesfile type="file"
                          (change)="uploadMeterExportAddressesFile(importmeterexportaddressesfile)" />
                      </clr-input-container>
                    </form>

                    <button type="button" class="btn btn-outline" [clrLoading]="importingState"
                      [disabled]="!importMeterExportAddressesfile" (click)="importMeterExportAddressesFiles()">
                      <clr-icon shape="import"></clr-icon>
                      Import meter export addresses file
                    </button>
                  </div>
                </div>
              </div>
              <!-- <div class="card" *ngIf="viperDataImportEnabled"> -->
              <div class="card">
                <div class="card-header">
                  Data import
                </div>
                <div class="card-block">
                  <div class="card-text">
                    <form clrForm clrLayout="vertical" autocomplete="off">
                      <clr-input-container>
                        <label class="clr-col-12">Import data file, select or drop file.</label>
                        <input clrInput class="clr-col-12" name="importdatafile_file" #datafile type="file"
                          (change)="uploadDataFile(datafile)" />
                      </clr-input-container>
                    </form>

                    <button type="button" class="btn btn-outline" [clrLoading]="importingState"
                      [disabled]="!importDatafile" (click)="importDataFiles()">
                      <clr-icon shape="import"></clr-icon>
                      Import data file
                    </button>
                  </div>
                </div>
              </div>
              <div class="card">
                <div class="card-header">
                  Thingsboard import
                </div>
                <div class="card-block">
                  <div class="card-text">
                    <app-bms-thingsboard [customer]="selectedCustomer" [bmsCustomer]="selectedBmsCustomer"></app-bms-thingsboard>
                  </div>
                </div>
              </div>
            </div>
          </clr-tab-content>
        </ng-template>
      </clr-tab>
    </clr-tabs>
  </as-split-area>
  <as-split-area [(size)]="rightPanelWidth" [order]="2">
    <clr-tabs>
      <clr-tab>
        <button clrTabLink>List</button>
        <ng-template [(clrIfActive)]="customersListActive">
          <!-- <div class="tab-area"> -->
          <clr-tab-content class="tab-area">
            <clr-datagrid class="customers-grid" [(clrDgSingleSelected)]="selectedBmsCustomer"
              [clrDgRowSelection]="true" (clrDgSingleSelectedChange)="selectedCustomerChanged()"
              [clrDgLoading]="loadingCustomers">
              <clr-dg-column [clrDgField]="'customerName'" [clrDgSortOrder]="ascSort">Name</clr-dg-column>
              <clr-dg-column [clrDgField]="'associationId'">
                <ng-template clrDgHideableColumn [(clrDgHidden)]="customersListHideAssociationId">Association Id
                </ng-template>
              </clr-dg-column>
              <clr-dg-column [clrDgField]="'organisationNumber'">
                <ng-container *clrDgHideableColumn="{hidden: true}">Org No</ng-container>
              </clr-dg-column>

              <clr-dg-row *clrDgItems="let customer of bmsCustomers; let i = index" [clrDgItem]="customer"
                class="sensitive">
                <clr-dg-cell>
                  {{customer.customerName}} {{customer.disabled ? '(disabled)' : ''}}
                </clr-dg-cell>
                <clr-dg-cell>
                  {{customer.associationId}}
                </clr-dg-cell>
                <clr-dg-cell>
                  {{customer.organisationNumber}}
                </clr-dg-cell>
              </clr-dg-row>

              <clr-dg-footer>
                <clr-dg-pagination #pagination [clrDgPageSize]="20">
                  <clr-dg-page-size [clrPageSizeOptions]="[10,20,50,100]">Customers per page</clr-dg-page-size>
                  {{pagination.firstItem + 1}} - {{pagination.lastItem + 1}} of {{pagination.totalItems}} customers
                </clr-dg-pagination>
              </clr-dg-footer>
            </clr-datagrid>
          </clr-tab-content>
          <!-- </div> -->
        </ng-template>
      </clr-tab>
      <clr-tab>
        <button clrTabLink>Tree</button>
        <ng-template [(clrIfActive)]="customersTreeActive">
          <clr-tab-content>
            <app-configuration-datasource [dataSourceInstance]="customerGroupingDataSourceInstance" [sortByName]="true"
              [expandRoot]="true" [highlightSelected]="true" [selectedPath]="customerGroupingSelectedPath" [lazy]="true"
              (onTreeNodeSelect)="onCustomerGroupingTreeNodeSelect($event)">
            </app-configuration-datasource>
          </clr-tab-content>
        </ng-template>
      </clr-tab>
      <clr-tab>
        <button clrTabLink>Filter</button>
        <clr-tab-content>
          <form clrForm>
            <clr-toggle-container>
              <clr-toggle-wrapper>
                <input type="checkbox" clrToggle [(ngModel)]="showDisabledCustomers" name="showdisabledcustomersoption"
                  (change)="getCustomers()" />
                <label>Show disabled customers</label>
              </clr-toggle-wrapper>
            </clr-toggle-container>
          </form>
        </clr-tab-content>
      </clr-tab>
    </clr-tabs>
  </as-split-area>
</as-split>
