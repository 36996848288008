<clr-main-container>
  <clr-header class="header-6" *ngIf="!user">
    <div class="branding">
      <img src="{{logo}}" height="44px" />
      <span class="title" *ngIf="title != ''">{{title}}</span>
    </div>
  </clr-header>

  <div class="background">
    <div class="card centered" *ngIf="event">
      <div class="card-header">{{customerName}}</div>
      <div class="card-block">
        <div class="card-text">
          <table class="table table-vertical">
            <!-- <caption style="margin-bottom: 1em;">
              Event info
            </caption> -->
            <tbody>
              <tr>
                <th i18n='@@bmsevent_category' class="left">Category</th>
                <td class="left">{{event.category}}</td>
              </tr>
              <tr>
                <th i18n='@@bmsevent_time' class="left">Time</th>
                <td class="left">{{dateHelper.utils.format(event.timestamp.toDate(), 'keyboardDateTime')}}</td>
              </tr>
              <tr>
                <th i18n='@@bmsevent_location' class="left">Location</th>
                <td class="left">{{event.location}}</td>
              </tr>
              <tr>
                <th i18n='@@bmsevent_message' class="left">Message</th>
                <td class="left">{{event.message}}</td>
              </tr>
              <tr>
                <th i18n='@@customerevents_severity' class="left">Severity</th>
                <td class="left">{{event.pbClass}}</td>
              </tr>
              <tr>
                <th i18n='@@customerevents_status' class="left">Status</th>
                <td class="left">{{status}}</td>
              </tr>
              <tr>
                <th i18n='@@customerevents_comment' class="left">Comment</th>
                <td class="left">{{event.comment}}
                </td>
              </tr>
            </tbody>
          </table>
          <p i18n='@@bmsevent_notfound' *ngIf="initiated && !event"> Event info not available.</p>
        </div>
      </div>
      <div class="card-footer">
        <!-- <button i18n='@@bmsevent_clear' class="btn btn" style="float: right;" (click)="clear();">Clear event</button> -->
        <button i18n='@@bmsevent_acknowledge' class="btn btn" style="float: right;" [disabled]="event.acknowledged" (click)="acknowledge();">Acknowledge</button>
      </div>
    </div>
  </div>

</clr-main-container>
