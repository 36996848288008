import { ChangeDetectorRef, Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { BmsApartment } from '@features/bms/models/bms-apartment';
import { GrpcNode } from '@xprojectorcore/xprojector_backend/proto/xprojector.grpc.models.pb';
import { XProjectorXConfClient } from '@xprojectorcore/xprojector_backend/xprojector-xconf-client';
import { DateHelper, XprojAlertService, XProjectorClient } from 'xproj-lib';
import { BmsDataUtils } from '@features/bms/bms-admin/utils/bms-data-utils';

@Component({
  selector: 'app-bms-apartment-view',
  templateUrl: './bms-apartment-view.component.html',
  styleUrls: ['./bms-apartment-view.component.scss']
})
export class BmsApartmentViewComponent implements OnInit {

  apartment: BmsApartment = null;
  apartmentNode: GrpcNode;
  customerId : string;

  constructor(
    private router: Router,
    private xconfClient: XProjectorXConfClient,
    public dateHelper: DateHelper,
    private cdr: ChangeDetectorRef
  ) { }

  ngOnInit(): void {
  }

  async update(apartmentId: number, customerId : string) {
    this.customerId = customerId;
    if (apartmentId && apartmentId > 0) {
      this.apartmentNode = await this.xconfClient.getNode(apartmentId.toString(), '_x_bms_apartment');
      if (this.apartmentNode) {
        this.apartment = new BmsApartment();
        this.apartmentNode.propertyValues.forEach(p => {
          switch (p.key) {
            case 'svlantapartmentno':
              this.apartment.svlantApartmentno = BmsDataUtils.getValue(p.valueType, p.value, p, this.dateHelper);
              break;
            case 'externalid':
              this.apartment.externalId = BmsDataUtils.getValue(p.valueType, p.value, p, this.dateHelper);
              break;
            case 'area':
              this.apartment.area = BmsDataUtils.getValue(p.valueType, p.value, p, this.dateHelper);
              break;
            case 'size':
              this.apartment.size = BmsDataUtils.getValue(p.valueType, p.value, p, this.dateHelper);
              break;
            case 'createdat':
              this.apartment.createdAt = BmsDataUtils.getValue(p.valueType, p.value, p, this.dateHelper);
              break;
            case 'modifiedat':
              this.apartment.modifiedAt = BmsDataUtils.getValue(p.valueType, p.value, p, this.dateHelper);
              break;

          }
        });
      }
    }
    else {
      this.apartment = null;
    }
    this.cdr.detectChanges();
  }

  async viewInBMS() {
    this.router.navigateByUrl('/bmsadmin/customers/' + this.customerId + '/bms/' + this.apartmentNode.id + '/' + this.apartmentNode.nodeTypeLabel);
  }
}

// if (this.apartment == null && this.meter && this.meter.apartmentId > 0) {
    //   this.apartment = new BmsApartment();
    //   this.apartment.id = this.meter.apartmentId;
    //   this.apartment.buildingAddressId = this.meter.buildingAddressId;
    //   this.apartment.buildingId = this.meter.buildingId;
    //   this.apartment.realestateId = this.meter.realestateId;

    //   if (this.apartmentColumns.length == 0) {
    //     this.apartmentColumns = await this.xprojClient.RequestListQueryableProjectionColumns('hs-apartment', '', 0, 100);
    //   }

    //   let query: BaseQuery = new BaseQuery();
    //   query.targetprojectionid = 'hs-apartment';
    //   query.maxitems = 1;
    //   query.targetgroup = [];

    //   let filterId = 0;
    //   query.filter.type = FilterLogicalGroupType.AND;

    //   let deletedFiltering = new ColumnFilteringNumerical();
    //   deletedFiltering.columnname = 'deleted';
    //   deletedFiltering.comparator = FilterComparator.Equals;
    //   deletedFiltering.value = 0;
    //   deletedFiltering.queryfilterid = ++filterId;
    //   query.filter.filters.push(deletedFiltering.queryfilterid);
    //   query.numericalfilters.push(deletedFiltering);

    //   let idFiltering = new ColumnFilteringNumerical();
    //   idFiltering.columnname = 'id';
    //   idFiltering.comparator = FilterComparator.Equals;
    //   idFiltering.value = this.meter.apartmentId;
    //   idFiltering.queryfilterid = ++filterId;
    //   query.filter.filters.push(idFiltering.queryfilterid);
    //   query.numericalfilters.push(idFiltering);

    //   this.apartmentColumns.forEach(c => {
    //     let inCol = new BaseQueryInputColumnDescription();
    //     inCol.columnname = c.columnname;
    //     inCol.columnoutname = c.columnname;
    //     query.columns.push(inCol);
    //   });

    //   try {
    //     let queryResult = await this.xprojClient.RequestQueryBaseQuery(query, true);

    //     let numericaldata = queryResult.datanumbers;
    //     let timestampdata = queryResult.datatimestamps;
    //     let stringdata = queryResult.datastrings;

    //     for (let i = 0; i < queryResult.columns.length; i++) {
    //       let it = queryResult.columns[i];
    //       let typ = it.datatype;
    //       let data = [];
    //       if (typ == XDataType.Number) {
    //         //data = WidgetUtils.FormatNumbers(numericaldata[it.indexintypedvector], this.globalWidgetSettings.Decimals);
    //         data = numericaldata[it.indexintypedvector];
    //       }
    //       if (typ == XDataType.String) {
    //         data = stringdata[it.indexintypedvector];
    //       }
    //       if (typ == XDataType.Timestamp) {
    //         data = timestampdata[it.indexintypedvector];
    //       }

    //       switch (it.columnoutname) {
    //         case 'svlant-apartmentno':
    //           this.apartment.svlantApartmentno = data[0];
    //           break;
    //         case 'externalid':
    //           this.apartment.externalId = data[0];
    //           break;
    //         case 'area':
    //           this.apartment.area = data[0];
    //           break;
    //         case 'size':
    //           this.apartment.size = data[0];
    //           break;
    //         case 'createdat':
    //           this.apartment.createdAt = data[0];
    //           break;
    //         case 'modifiedat':
    //           this.apartment.modifiedAt = data[0];
    //           break;
    //       }
    //     }
    //   }
    //   catch (err) {
    //     console.log(err);
    //   }
    // }
