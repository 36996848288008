import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { GrpcNode } from '@xprojectorcore/xprojector_backend/proto/xprojector.grpc.models.pb';
import { XProjectorXConfClient } from '@xprojectorcore/xprojector_backend/xprojector-xconf-client';
import { DateHelper, XprojAlertService, XProjectorClient } from 'xproj-lib';
import { BmsBuilding } from '@features/bms/models/bms-building';
import { BmsDataUtils } from '@features/bms/bms-admin/utils/bms-data-utils';

@Component({
  selector: 'app-bms-building-view',
  templateUrl: './bms-building-view.component.html',
  styleUrls: ['./bms-building-view.component.scss']
})
export class BmsBuildingViewComponent implements OnInit {

  building: BmsBuilding = null;
  buildingNode: GrpcNode;
  customerId : string;

  constructor(
    private router: Router,
    private xconfClient: XProjectorXConfClient,
    public dateHelper: DateHelper

  ) { }

  ngOnInit(): void {
  }

  async update(buildingId: number, customerId : string) {
    this.customerId = customerId;
    if (buildingId && buildingId > 0) {
      this.buildingNode = await this.xconfClient.getNode(buildingId.toString(), '_x_bms_building');
      if (this.buildingNode) {
        this.building = new BmsBuilding();
        this.buildingNode.propertyValues.forEach(p => {
          switch (p.key) {
            case 'svlantbuildingno':
              this.building.svLantBuildingNo = BmsDataUtils.getValue(p.valueType, p.value, p, this.dateHelper);
              break;
            case 'description':
              this.building.description = BmsDataUtils.getValue(p.valueType, p.value, p, this.dateHelper);
              break;
            case 'latitude':
              this.building.latitude = BmsDataUtils.getValue(p.valueType, p.value, p, this.dateHelper);
              break;
            case 'longitude':
              this.building.longitude = BmsDataUtils.getValue(p.valueType, p.value, p, this.dateHelper);
              break;
            case 'modifiedat':
              this.building.modifiedAt = BmsDataUtils.getValue(p.valueType, p.value, p, this.dateHelper);
              break;
            case 'createdat':
              this.building.createdAt = BmsDataUtils.getValue(p.valueType, p.value, p, this.dateHelper);
              break;
          }
        });
      }
    }
    else {
      this.building = null;
    }
  }

  async viewInBMS() {
    this.router.navigateByUrl('/bmsadmin/customers/' + this.customerId + '/bms/' + this.buildingNode.id + '/' + this.buildingNode.nodeTypeLabel);
  }
}
