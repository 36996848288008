<div class="content-area">
  <div class="card">
    <div class="card-header">
      Send sms
    </div>
    <div class="card-block">
      <form clrForm clrLayout="vertical" autocomplete="off">
        <clr-input-container>
          <label class="clr-col-12">To:</label>
          <input clrInput style="width: 90%;" class="clr-col-12" type="text" [(ngModel)]="smsTo" name="smsto" />
        </clr-input-container>
        <clr-textarea-container>
          <label class="clr-col-12">Message:</label>
          <textarea clrTextarea style="width: 90%;" class="clr-col-12" [rows]="8" [cols]="50" [(ngModel)]="smsMessage"
            name="smsmessage" [maxlength]="160"></textarea>
          <clr-control-helper>{{smsMessage.length}}/160</clr-control-helper>
        </clr-textarea-container>
      </form>
    </div>

    <div class="card-footer">
      <button type="button" class="btn btn-outline" (click)="clear()">
        <clr-icon shape="times"></clr-icon>
        Clear
      </button>

      <button type="button" class="btn btn-primary" [disabled]="smsTo.length == 0" (click)="sendSms()">
        <clr-icon shape="envelope"></clr-icon>
        Send
      </button>
    </div>
  </div>
</div>
