<clr-main-container>
  <clr-header class="header-6" *ngIf="!user">
    <div class="branding">
      <img src="{{logo}}" height="44px" />
      <span class="title" *ngIf="title != ''">{{title}}</span>
    </div>
  </clr-header>

  <div class="background">
    <div class="card centered">
      <div class="card-header">{{customerName}}</div>
      <div class="card-block">
        <div class="card-text">
          <p i18n='@@bmsunsubscribeevents_info'>Click 'Unsubscribe' to stop future events from this customer.</p>
        </div>
      </div>
      <div class="card-footer">
        <button i18n='@@bmsunsubscribeevents_unsubscribe' class="btn btn" style="float: right;" (click)="unsubscribeEvents();">Unsubscribe</button>
      </div>
    </div>
  </div>

</clr-main-container>
