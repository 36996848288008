import { Component, inject, OnInit } from '@angular/core';
import { BmsDataCollectorViewData } from '@core/models/bms-data-collector-view-data';
import { DashboardOutputChangeParameters, OutputDataType, LinkedWidgetSelectParameters, WidgetExportParameters, XprojAlertService, DateHelper, XprojModalService } from 'xproj-lib';
import { BmsDataCollectorComponent } from '@features/bms/bms-admin/bms-data-collector-view.component';
import { ClrLoadingState } from '@clr/angular';
import { StateService } from '@xprojectorcore/services/state-service';
import { XProjectorFilesClient } from '@xprojectorcore/xprojector_backend/xprojector-files-client';
import { XProjectorCustomerUsersClient } from '@xprojectorcore/xprojector_backend/xprojector-customerusers-client';
import { FileInfo } from '@xprojectorcore/models/file-info';
import { saveAs } from 'file-saver';
import { BmsAdminService } from '../../../services/bms-admin-service';

@Component({
  selector: 'app-bms-group-data-collector-view',
  templateUrl: './bms-group-data-collector-view.component.html',
  styleUrls: ['./bms-group-data-collector-view.component.scss']
})
export class BmsGroupDataCollectorViewComponent implements OnInit, BmsDataCollectorComponent {

  public static NodeTypeId : string = '_x_datacollectors_group';

  private adminService: BmsAdminService = inject(BmsAdminService);

  private alertService: XprojAlertService = inject(XprojAlertService);
  public dateHelper: DateHelper = inject(DateHelper);
  private modalService: XprojModalService = inject(XprojModalService);
  private customerUserClient: XProjectorCustomerUsersClient = inject(XProjectorCustomerUsersClient);

  data: BmsDataCollectorViewData;
  visible: boolean;

  responsiveWidth : number = 600;

  meterParameters : DashboardOutputChangeParameters[] = [];

  metersSummary : {meterType : string, count : number}[] = [];

  constructor(

  ) { }

  ngOnInit(): void {
  }


  async initDataCollectorView() {
    this.responsiveWidth = this.data.width;
    this.updateDashboardOutputs();
    this.metersSummary = await this.adminService.getMetersSummary(this.data.bmsCustomer.id);
    console.log('this.metersSummary', this.metersSummary);
  }

  setWidth(width: number) {
    if (this.data) {
      this.data.width = width;
    }

    this.responsiveWidth = width;
  }

  updateDashboardOutputs() {
    this.meterParameters = [];
    if (this.data.bmsCustomer) {

      let out = new DashboardOutputChangeParameters();
      out.outputParameterName = 'customerid';
      out.value = this.data.bmsCustomer.customerId;
      out.datatype = OutputDataType.String;
      this.meterParameters.push(out);

      out = new DashboardOutputChangeParameters();
      out.outputParameterName = 'customerxdbid';
      out.value = this.data.bmsCustomer.id;
      out.datatype = OutputDataType.Int64;
      this.meterParameters.push(out);

      out = new DashboardOutputChangeParameters();
      out.outputParameterName = 'id';
      out.value = +this.data.node.id;
      out.datatype = OutputDataType.Int64;
      this.meterParameters.push(out);
    }
  }

  async meterDashboardValueChanged(parameters: LinkedWidgetSelectParameters) {

  }


  async onWidgetExport(parameters: WidgetExportParameters) {
    let result = await this.customerUserClient.exportToExcel(parameters);

    if (!result) {
      this.alertService.error('Error export to excel!');
    }
  }

}
