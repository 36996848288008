import { Component, OnInit, ViewChild, inject } from '@angular/core';
import { EditTreenodeComponent } from '@xprojectorfeatures/xconf/components/edit-treenode/edit-treenode.component';
import { BmsDataCollectorViewData } from '@core/models/bms-data-collector-view-data';
import { BmsLorawanMultiMeter } from '@core/models/bms-lorawan-multimeter';
import { BmsAdminService } from '../../../services/bms-admin-service';
import { GrpcNode } from '@xprojectorcore/xprojector_backend/proto/xprojector.grpc.models.pb';
import { GrpcNodeType, SearchNodesRequest } from '@xprojectorcore/xprojector_backend/proto/xprojector.xconf.pb';
import { XProjectorXConfClient } from '@xprojectorcore/xprojector_backend/xprojector-xconf-client';
import { DashboardOutputChangeParameters, DateHelper, OutputDataType, XprojAlertService, XprojModalService } from 'xproj-lib';
import { BmsDataCollectorComponent } from '@features/bms/bms-admin/bms-data-collector-view.component';
import { BmsLorawanDeviceInfo } from '@app/core/models/lorawan-device-info';
import * as XLSX from 'xlsx';
import { XProjectorBmsLoRaWANClient } from '@app/core/xprojector_backend/xprojector-bms-lorawan-client';
import { ConfiguredDownlinkItem, ConfiguredDownlinkSequence, DownlinkInfo, DownlinkItem } from '@app/core/xprojector_backend/proto/xprojector.modulebms.lorawan.pb';
import { ClrDatagridSortOrder } from '@clr/angular';
import { BmsLatestValue } from '@app/core/models/bms-latestvalue';

@Component({
  selector: 'app-bms-lorawan-meters-group-data-collector-view',
  templateUrl: './bms-lorawan-meters-group-data-collector-view.component.html',
  styleUrls: ['./bms-lorawan-meters-group-data-collector-view.component.scss']
})
export class BmsLorawanMetersGroupDataCollectorViewComponent implements OnInit, BmsDataCollectorComponent {

  @ViewChild("editNode", { read: EditTreenodeComponent, static: false }) editNode: EditTreenodeComponent;

  private xconfClient: XProjectorXConfClient = inject(XProjectorXConfClient);
  private adminService: BmsAdminService = inject(BmsAdminService);
  public dateHelper: DateHelper = inject(DateHelper);
  private alertService: XprojAlertService = inject(XprojAlertService);
  private modalService: XprojModalService = inject(XprojModalService);
  private loRaWANClient: XProjectorBmsLoRaWANClient = inject(XProjectorBmsLoRaWANClient);

  public static NodeTypeId: string = '_x_lorawan_group';

  data: BmsDataCollectorViewData;
  visible: boolean;

  infoActive: boolean = true;
  downlinkActive: boolean = false;
  locationActive: boolean = false;

  lorawanMultiMeters: BmsLorawanMultiMeter[] = [];
  lorawanDeviceInfos: BmsLorawanDeviceInfo[] = [];
  latestValues : BmsLatestValue[] = [];
  loadingMeters: boolean = false;
  selectedMultiMeter: { meter: BmsLorawanMultiMeter, deviceInfo: BmsLorawanDeviceInfo };

  lorawanMultiMetersInfo: { meter: BmsLorawanMultiMeter, deviceInfo: BmsLorawanDeviceInfo }[] = [];

  allNodeTypes: GrpcNodeType[] = [];
  currentEditNodeId: string;
  currentEditNode: GrpcNode;
  showEditModal: boolean = false;

  responsiveWidth: number = 600;
  meterParameters: DashboardOutputChangeParameters[] = [];
  gatewayParameters: DashboardOutputChangeParameters[] = [];

  loadingGateway: boolean = false;

  lorawanDeviceSpreadingfactorsStat: Map<number, number>;

  configuredDownlinkItems: ConfiguredDownlinkItem[] = [];
  selectedConfiguredDownlinkItem: ConfiguredDownlinkItem = null;

  configuredDownlinkSequences: ConfiguredDownlinkSequence[] = [];
  selectedConfiguredDownlinkSequence: ConfiguredDownlinkSequence = null;
  queueAllEnabled : boolean = true;
  downlinkFailedAfter : Date = new Date();
  downlinkFailedAfterString : string;
  downlinkOverrideTimeout : number = -1;
  downlinkSendEmail : boolean = false;

  showDownlinkInfoModal: boolean = false;
  downlinkInfoId : string;
  downlinkInfoLabel : string;
  downlinkInfos: {
    devEui: string,
    timestamp: string,
    result: string,
    message: string
  }[] = [];

  ascSort = ClrDatagridSortOrder.ASC;
  descSort = ClrDatagridSortOrder.DESC;

  constructor(
  ) { }

  ngOnInit(): void {
  }


  async initDataCollectorView() {
    await this.update();
  }

  async update() {
    try {
      this.responsiveWidth = this.data.width;
      this.loadingMeters = true;
      this.lorawanMultiMetersInfo = [];
      this.lorawanMultiMeters = await this.adminService.getLorawanMultiMeters(this.data.node.id);
      this.lorawanDeviceInfos = await this.adminService.getLoraWANDeviceInfos(this.lorawanMultiMeters.map(x => x.devEui));
      this.lorawanDeviceSpreadingfactorsStat = new Map<number, number>();
      //Optimize : Map deviceinfo first for faster lookup
      this.lorawanMultiMeters.forEach(meter => {
        let deviceInfo = this.lorawanDeviceInfos.find(x => x.deveui == meter.devEui);
        this.lorawanMultiMetersInfo.push({ meter: meter, deviceInfo: deviceInfo });
        let sf = deviceInfo?.spreadingfactor ?? 0;
        if (this.lorawanDeviceSpreadingfactorsStat.has(sf)) {
          this.lorawanDeviceSpreadingfactorsStat.set(sf, this.lorawanDeviceSpreadingfactorsStat.get(sf) + 1);
        }
        else {
          this.lorawanDeviceSpreadingfactorsStat.set(sf, 1);
        }
      });

      this.updateDownlinkItems();
      this.updateDownlinkSequences();
      this.updateDashboardOutputs();

      this.updateLatestValues();
    }
    finally {
      this.loadingMeters = false;
    }
  }

  async updateLatestValues() {
    let gatewayIdsMissingLatest : number[] = [];
    this.latestValues = await this.adminService.getLatestValues(this.data.bmsCustomer.customerId);
    this.lorawanMultiMeters.forEach(meter => {
      let gatewayId = +meter.id;
      let latestValue = this.latestValues.find(x => x.gatewayId == gatewayId);
      if (latestValue) {
        meter.lastValueAt = latestValue.timestamp;
      }
      else {
        gatewayIdsMissingLatest.push(gatewayId);
      }
    });

      //Search för meters not connected to the BMS-tree
    if (gatewayIdsMissingLatest.length > 0) {
      let latestValuesTmp = await this.adminService.getLatestValues('', gatewayIdsMissingLatest);
      this.lorawanMultiMeters.forEach(meter => {
        let gatewayId = +meter.id;
        let latestValue = latestValuesTmp.find(x => x.gatewayId == gatewayId);
        if (latestValue) {
          meter.lastValueAt = latestValue.timestamp;
        }
      });
    }
  }

  async editMultiMeter() {
    if (this.selectedMultiMeter) {
      let node = await this.xconfClient.getNode(
        this.selectedMultiMeter.meter.id,
        '_x_lorawan_multimeter');
      if (node) {
        if (this.allNodeTypes.length == 0) {
          this.allNodeTypes = await this.xconfClient.getNodeTypes();
        }

        this.currentEditNodeId = node.id;
        this.currentEditNode = node;
        this.showEditModal = true;
      }
    }
  }

  async updateNode(node: GrpcNode, oldId: string): Promise<boolean> {
    let result = await this.xconfClient.updateNode(node, oldId, '', this.data.customer.id);
    return result.result;
  }

  updateDashboardOutputs() {
    this.meterParameters = [];
    if (this.data.bmsCustomer && this.selectedMultiMeter) {

      let out = new DashboardOutputChangeParameters();
      out.outputParameterName = 'customerid';
      out.value = this.data.bmsCustomer.customerId;
      out.datatype = OutputDataType.String;
      this.meterParameters.push(out);

      out = new DashboardOutputChangeParameters();
      out.outputParameterName = 'customerxdbid';
      out.value = this.data.bmsCustomer.id;
      out.datatype = OutputDataType.Int64;
      this.meterParameters.push(out);

      out = new DashboardOutputChangeParameters();
      out.outputParameterName = 'deveui';
      out.value = this.selectedMultiMeter.meter.devEui;
      out.datatype = OutputDataType.String;
      this.meterParameters.push(out);
    }

    this.gatewayParameters = [];
    if (this.data.bmsCustomer) {
      let out = new DashboardOutputChangeParameters();
      out.outputParameterName = 'customerid';
      out.value = this.data.bmsCustomer.customerId;
      out.datatype = OutputDataType.String;
      this.gatewayParameters.push(out);

      out = new DashboardOutputChangeParameters();
      out.outputParameterName = 'gatewayid';
      out.value = +this.data.node.id;
      out.datatype = OutputDataType.Int64;
      this.gatewayParameters.push(out);
    }
  }

  setWidth(width: number) {
    if (this.data) {
      this.data.width = width;
    }

    this.responsiveWidth = width;
  }

  async saveEditNode() {
    if (this.editNode) {
      this.editNode.savePropertyValues();
      let result = await this.updateNode(this.currentEditNode, this.currentEditNodeId);
      if (result) {
        this.alertService.info('Node updated.');
        this.update();
      }
      else {
        this.alertService.error('Error update node!');
      }
    }

    this.showEditModal = false;
  }

  async updateGateway(force: boolean = false) {
    try {
      this.loadingGateway = true;
      await this.loRaWANClient.getGateway(this.data.bmsCustomer.customerId, this.data.node.id,
        this.data.node.nodeTypeId, '', '', true, force);
    }
    finally {
      this.loadingGateway = false;
    }
  }

  async updateDownlinkItems() {
    this.configuredDownlinkItems = await this.loRaWANClient.getConfiguredDownlinkItems();
  }

  async updateDownlinkSequences() {
    this.configuredDownlinkSequences = await this.loRaWANClient.getConfiguredDownlinkSequences();
    this.downlinkFailedAfter = this.dateHelper.utils.addDays(new Date(), -3);
    this.downlinkFailedAfterString = this.dateHelper.utils.formatByString(this.downlinkFailedAfter, 'yyyy-MM-dd');
  }


  async enqueueDownlinkItem() {
    if (this.selectedConfiguredDownlinkItem) {
      let doEnqueue = await this.modalService.ShowConfirmModalAsync({
        header: 'Enqueue downlink item',
        description: 'Enqueue downlink item to all devices, are you sure?',
        ok: 'Enqueue',
        cancel: 'Cancel'
      });

      if (doEnqueue) {
        let result = await this.loRaWANClient.enqueueDownlinkItem(this.data.bmsCustomer.customerId, this.data.node.id,
          this.data.node.nodeTypeId, '', this.selectedConfiguredDownlinkItem);

        if (result.ok) {
          this.modalService.ShowConfirmModalAsync({
            header: 'Enqueue downlink item success',
            description: 'Downlink item enqueued to ' + result.deviceCount + ' devices.',
            showCancel: false
          });
        }
        else {
          this.modalService.ShowConfirmModalAsync({
            header: 'Enqueue downlink item failure',
            description: result.message,
            showCancel: false
          });

        }

      }
    }
  }

  async enqueueDownlinkSequence() {
    if (this.selectedConfiguredDownlinkSequence) {
      let doEnqueue = await this.modalService.ShowConfirmModalAsync({
        header: 'Enqueue downlink sequence',
        description: 'Enqueue downlink sequence to all devices, are you sure?',
        ok: 'Enqueue',
        cancel: 'Cancel'
      });

      if (doEnqueue) {
        if (!this.queueAllEnabled) {
          this.downlinkFailedAfter = this.dateHelper.utils.parse(this.downlinkFailedAfterString, 'yyyy-MM-dd');
        }
        let result = await this.loRaWANClient.enqueueDownlinkSequence(this.data.bmsCustomer.customerId, this.data.node.id,
          this.data.node.nodeTypeId, 'chirpstack', this.selectedConfiguredDownlinkSequence.id,
          this.queueAllEnabled ? null : this.downlinkFailedAfter, this.downlinkOverrideTimeout, this.downlinkSendEmail);

        if (result.ok) {
          this.modalService.ShowConfirmModalAsync({
            header: 'Enqueue downlink sequence success',
            description: 'Downlink sequence enqueued to ' + result.deviceCount + ' devices.',
            showCancel: false
          });
        }
        else {
          this.modalService.ShowConfirmModalAsync({
            header: 'Enqueue downlink sequence failure',
            description: result.message,
            showCancel: false
          });

        }

      }
    }
  }

  downlinkSequenceChanged() {
    if (this.selectedConfiguredDownlinkSequence) {
      this.downlinkOverrideTimeout = this.selectedConfiguredDownlinkSequence.timeout;
      this.downlinkSendEmail = false;
    }
  }

  private async _showDownlinkInfos(nodeId : string, nodeLabel: string) {
    this.downlinkInfoId = nodeId;
    this.downlinkInfoLabel = nodeLabel;

    this.downlinkInfos = [];
    let infos = await this.loRaWANClient.getDownlinkInfos(this.data.bmsCustomer.customerId, this.data.node.id, this.data.node.nodeTypeLabel,
      nodeId, nodeLabel);

    infos.forEach(x => {
      this.downlinkInfos.push({
        devEui: x.devEui,
        timestamp: x.timestamp.nanos > 0 ? this.dateHelper.formatDate(x.timestamp.toDate(), 'keyboardDateTime') : '',
        result: x.result,
        message: x.message
      });
    });
    this.showDownlinkInfoModal = true;
  }

  async showDownlinkSequenceInfos() {
    if (this.selectedConfiguredDownlinkSequence) {
      await this._showDownlinkInfos(this.selectedConfiguredDownlinkSequence.id, '_x_lorawan_lorawansequence');
    }
  }

  async showDownlinkInfos() {
    if (this.selectedConfiguredDownlinkItem) {
      await this._showDownlinkInfos(this.selectedConfiguredDownlinkItem.id, '_x_lorawan_lorawandownlink');
    }
  }

  async exportDownlinkInfos() {
    let result = await this.loRaWANClient.exportDownlinkInfos(this.data.bmsCustomer.customerId, this.data.node.id, this.data.node.nodeTypeLabel,
      this.downlinkInfoId, this.downlinkInfoLabel, 'DownlinkInfo');

    if (!result) {
      this.alertService.error('Error export to excel!');
    }
  }

  closeEditNode() {
    this.showEditModal = false;
  }

  async addMultiMeter() {

  }

  async exportMultiMeters() {

  }

  async importMultiMeters() {

  }

  async refresh() {
    await this.update();
  }

  async export() {
    var headers: string[] = ['DevEui', 'Name', 'LastActive', 'LastValueAt', 'RSSI', 'SNR', 'SF', "FCnt", 'MultiMeterType'];

    let meterDatas: { DevEui: string, Name: string, LastActive: string, LastValueAt : string, RSSI: number, SNR: number, SF: number, FCnt : number, MultiMeterType: string }[] = [];

    this.lorawanMultiMetersInfo.forEach(x => {
      if (x.deviceInfo) {
        meterDatas.push({
          DevEui: x.meter.devEui,
          Name: x.meter.name,
          LastActive: this.dateHelper.formatByString(x.deviceInfo?.modifiedAt, 'yyyy-MM-dd HH:mm:ss', '---'),
          LastValueAt: this.dateHelper.formatByString(x.meter?.lastValueAt, 'yyyy-MM-dd HH:mm:ss', '---'),
          RSSI: x.deviceInfo?.rssi,
          SNR: +x.deviceInfo?.snr.toFixed(1),
          SF: x.deviceInfo?.spreadingfactor,
          FCnt: x.deviceInfo.fCnt,
          MultiMeterType: x.meter.multiMeterType
        })
      }
      else {
        meterDatas.push({
          DevEui: x.meter.devEui,
          Name: x.meter.name,
          LastActive: '---',
          LastValueAt: '---',
          RSSI: 0,
          SNR: 0,
          SF: 0,
          FCnt: 0,
          MultiMeterType: x.meter.multiMeterType
        })
      }
    });

    const ws: XLSX.WorkSheet = XLSX.utils.json_to_sheet(meterDatas, { header: headers });

    const wb: XLSX.WorkBook = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(wb, ws, 'LoRaWAN Info');

    XLSX.writeFile(wb, this.data.customer.name + '_lorawan.xlsx');
  }
}
