<div class="customers-content-area">
  <div class="summary-table">
    <table class="table table-vertical" *ngIf="metersSummary">
      <caption style="margin-bottom: 1em;">
        Meters summary
      </caption>
      <tbody>
        <tr *ngFor="let item of metersSummary">
          <th class="left col-metertype">{{item.meterType}}</th>
          <td class="left col-count">{{item.count}}</td>
        </tr>
      </tbody>
    </table>
  </div>

</div>
