import { Injectable } from '@angular/core';
import { NGXLogger } from 'ngx-logger';
import { ArrayUtils, BaseQuery, BaseQueryInputColumnDescription, ColumnFilteringNumerical, ColumnFilteringString, ColumnFilteringTimestamp, FilterComparator, FilterLogicalGroup, FilterLogicalGroupType, ProjectionColumnDescription, SetDataColumnDescription, SetDataQuery, XDataType, XProjectorClient } from 'xproj-lib';
import { BmsEvent } from '../../models/bms-event';
import { BmsCustomerEventStatus } from '../../models/bms-customer-event-status';
import { flakeId } from '../utils/bms-data-utils';

export interface BmsEventsParameters {
  customerId?: string,
  maxItems?: number,
  onlyActive?: boolean
}

const EVENT_PROJECTIONID: string = 'hs-event';
const EVENT_STATUS_PROJECTIONID: string = 'hs-event-status';

@Injectable({
  providedIn: 'root'
})
export class BmsEventsService {

  eventsColumns: ProjectionColumnDescription[] = [];

  constructor(
    private xprojClient: XProjectorClient,
    private logger: NGXLogger
  ) {

  }

  async getEvents({
    customerId = undefined,
    maxItems = 10,
    onlyActive = true
  }: BmsEventsParameters): Promise<BmsEvent[]> {

    let result: BmsEvent[] = [];
    if (customerId) {
      if (this.eventsColumns.length == 0) {
        this.eventsColumns = await this.xprojClient.RequestListQueryableProjectionColumns(EVENT_PROJECTIONID, '', 0, 100);
      }

      let query: BaseQuery = new BaseQuery();
      query.targetprojectionid = EVENT_PROJECTIONID;
      query.maxitems = maxItems;
      query.targetgroup = [customerId];

      let filterId = 0;
      query.filter.type = FilterLogicalGroupType.AND;

      if (onlyActive) {
        let activeFiltering = new ColumnFilteringNumerical();
        activeFiltering.columnname = 'active';
        activeFiltering.comparator = FilterComparator.Equals;
        activeFiltering.value = 1;
        activeFiltering.queryfilterid = ++filterId;
        query.filter.filters.push(activeFiltering.queryfilterid);
        query.numericalfilters.push(activeFiltering);
      }

      // let deletedFiltering = new ColumnFilteringNumerical();
      // deletedFiltering.columnname = 'deleted';
      // deletedFiltering.comparator = FilterComparator.Equals;
      // deletedFiltering.value = 0;
      // deletedFiltering.queryfilterid = ++filterId;
      // query.filter.filters.push(deletedFiltering.queryfilterid);
      // query.numericalfilters.push(deletedFiltering);

      this.eventsColumns.filter(c => c.columnname != 'customerid').forEach(c => {
        let inCol = new BaseQueryInputColumnDescription();
        inCol.columnname = c.columnname;
        inCol.columnoutname = c.columnname;
        query.columns.push(inCol);
      });

      query.sorting.columnname = 'timestamp';
      query.sorting.descending = true;

      try {
        let queryResult = await this.xprojClient.RequestQueryBaseQuery(query, true);

        let numericaldata = queryResult.datanumbers;
        let timestampdata = queryResult.datatimestamps;
        let stringdata = queryResult.datastrings;

        let rowCount = 0;
        if (numericaldata.length > 0) {
          rowCount = numericaldata[0].length;
        }

        for (let row = 0; row < rowCount; row++) {
          let event = new BmsEvent();
          event.customerId = customerId;

          for (let i = 0; i < queryResult.columns.length; i++) {
            let it = queryResult.columns[i];
            let typ = it.datatype;
            let data = [];
            if (typ == XDataType.Number) {
              data = numericaldata[it.indexintypedvector];
            }
            if (typ == XDataType.String) {
              data = stringdata[it.indexintypedvector];
            }
            if (typ == XDataType.Timestamp) {
              data = timestampdata[it.indexintypedvector];
            }

            switch (it.columnoutname) {
              case 'id':
                event.id = data[row];
                break;
              case 'meterid':
                event.meterId = data[row];
                break;
              case 'class':
                event.class = data[row];
                break;
              case 'eventid':
                event.eventId = data[row];
                break;
              case 'category':
                event.category = data[row];
                break;
              case 'active':
                event.active = data[row] > 0;
                break;
              case 'high':
                event.high = data[row] > 0;
                break;
              case 'acknowledged':
                event.acknowledged = data[row] > 0;
                break;
              case 'value':
                event.value = data[row];
                break;
              case 'count':
                event.count = data[row];
                break;
              case 'message':
                event.message = data[row];
                break;
              case 'comment':
                event.comment = data[row];
                break;
              case 'timestamp':
                event.timestamp = data[row];
                break;
              case 'timestampactive':
                event.timestampActive = data[row];
                break;
              case 'timestampacknowledged':
                event.timestampAcknowledged = data[row];
                break;
              case 'timestampcleared':
                event.timestampCleared = data[row];
                break;
              case 'acknowledgedby':
                event.acknowledgedBy = data[row];
                break;
              case 'clearedby':
                event.clearedBy = data[row];
                break;
            }
          }

          result.push(event);
        }
      }
      catch (err) {
        this.logger.error(err);
      }
    }
    return result;
  }

  async getEventStatuses(customerId : string, category : string = undefined): Promise<BmsCustomerEventStatus[]> {

    let result: BmsCustomerEventStatus[] = [];
    if (customerId) {
      if (this.eventsColumns.length == 0) {
        this.eventsColumns = await this.xprojClient.RequestListQueryableProjectionColumns(EVENT_STATUS_PROJECTIONID, '', 0, 100);
      }

      let query: BaseQuery = new BaseQuery();
      query.targetprojectionid = EVENT_STATUS_PROJECTIONID;
      query.maxitems = 5000;
      //query.targetgroup = [customerId];

      let filterId = 0;
      query.filter.type = FilterLogicalGroupType.AND;

      let customerFiltering = new ColumnFilteringString();
      customerFiltering.columnname = 'customerid';
      customerFiltering.comparator = FilterComparator.Equals;
      customerFiltering.value = customerId;
      customerFiltering.queryfilterid = ++filterId;
      query.filter.filters.push(customerFiltering.queryfilterid);
      query.stringfilters.push(customerFiltering);

      if (category) {
        let categoryFiltering = new ColumnFilteringString();
        categoryFiltering.columnname = 'category';
        categoryFiltering.comparator = FilterComparator.Equals;
        categoryFiltering.value = category;
        categoryFiltering.queryfilterid = ++filterId;
        query.filter.filters.push(categoryFiltering.queryfilterid);
        query.stringfilters.push(categoryFiltering);
      }

      let deletedFiltering = new ColumnFilteringNumerical();
      deletedFiltering.columnname = 'deleted';
      deletedFiltering.comparator = FilterComparator.Equals;
      deletedFiltering.value = 0;
      deletedFiltering.queryfilterid = ++filterId;
      query.filter.filters.push(deletedFiltering.queryfilterid);
      query.numericalfilters.push(deletedFiltering);

      this.eventsColumns.forEach(c => {
        let inCol = new BaseQueryInputColumnDescription();
        inCol.columnname = c.columnname;
        inCol.columnoutname = c.columnname;
        query.columns.push(inCol);
      });

      query.sorting.columnname = 'externalid';
      query.sorting.descending = false;

      try {
        let queryResult = await this.xprojClient.RequestQueryBaseQuery(query, true);

        let numericaldata = queryResult.datanumbers;
        let timestampdata = queryResult.datatimestamps;
        let stringdata = queryResult.datastrings;

        let rowCount = 0;
        if (numericaldata.length > 0) {
          rowCount = numericaldata[0].length;
        }

        for (let row = 0; row < rowCount; row++) {
          let eventStatus = new BmsCustomerEventStatus();
          eventStatus.customerId = customerId;

          for (let i = 0; i < queryResult.columns.length; i++) {
            let it = queryResult.columns[i];
            let typ = it.datatype;
            let data = [];
            if (typ == XDataType.Number) {
              data = numericaldata[it.indexintypedvector];
            }
            if (typ == XDataType.String) {
              data = stringdata[it.indexintypedvector];
            }
            if (typ == XDataType.Timestamp) {
              data = timestampdata[it.indexintypedvector];
            }

            switch (it.columnoutname) {
              case 'id':
                eventStatus.id = data[row];
                break;
              case 'meterid':
                eventStatus.meterId = data[row];
                break;
              case 'class':
                eventStatus.class = data[row];
                break;
              case 'category':
                eventStatus.category = data[row];
                break;
              case 'location':
                eventStatus.location = data[row];
                break;
              case 'svlant-apartmentno':
                eventStatus.svlantApartmentNo = data[row];
                break;
              case 'externalid':
                eventStatus.externalId = data[row];
                break;
              case 'value':
                eventStatus.value = data[row];
                break;
              case 'message':
                eventStatus.message = data[row];
                break;
              case 'timestamp':
                eventStatus.timestamp = data[row];
                break;
            }
          }

          result.push(eventStatus);
        }
      }
      catch (err) {
        this.logger.error(err);
      }
    }
    return result;
  }

}
