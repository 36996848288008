<clr-modal [(clrModalOpen)]="showViewInvoiced" [clrModalSize]="''" [clrModalClosable]="true">
  <h3 class="modal-title">Invoiced customers</h3>
  <div class="modal-body" style="min-height: 100px;">
    <ul>
      <li *ngFor="let customerName of invoicedCustomers" class="sensitive">{{customerName}}</li>
    </ul>
  </div>
  <div class="modal-footer">
    <button type="button" class="btn btn-primary" (click)="showViewInvoiced=false;">Ok</button>
  </div>
</clr-modal>

<clr-modal [(clrModalOpen)]="showEditExportConfigs" [clrModalSize]="'lg'" [clrModalClosable]="false">
  <h3 class="modal-title">Edit export configs</h3>
  <div class="modal-body" style="min-height: 400px;">
    <clr-tabs>
      <clr-tab>
        <button clrTabLink>Export</button>
        <clr-tab-content>
          <app-configuration-datasource *ngIf="botsDataSourceInstance" [dataSourceInstance]="botsDataSourceInstance"
            [highlightSelected]="false"></app-configuration-datasource>
        </clr-tab-content>
      </clr-tab>
      <clr-tab>
        <button clrTabLink>Data report</button>
        <clr-tab-content>
          <app-configuration-datasource *ngIf="dataReportsDataSourceInstance"
            [dataSourceInstance]="dataReportsDataSourceInstance" [highlightSelected]="false">
          </app-configuration-datasource>
        </clr-tab-content>
      </clr-tab>
    </clr-tabs>
  </div>
  <div class="modal-footer">
    <button type="button" class="btn btn-primary" (click)="onCloseEditExportConfigs()">Close</button>
  </div>
</clr-modal>

<clr-modal [(clrModalOpen)]="showEditExportProperties" [clrModalClosable]="false">
  <h3 class="modal-title">Export properties</h3>
  <div class="modal-body">
    <form clrForm *ngIf="botExecConfig">

      <clr-tabs>
        <clr-tab>
          <button clrTabLink>Export</button>
          <clr-tab-content>
            <clr-select-container>
              <label>Export period</label>
              <select clrSelect name="exportperiodselect" [(ngModel)]="selectedExecExportPeriods">
                <option *ngFor="let period of execExportPeriods; let i = index" [ngValue]="i">
                  {{formatByString(period.start,
                  'yyyy-MM-dd') + ' - ' + formatByString(period.end, 'yyyy-MM-dd') }}</option>
              </select>
            </clr-select-container>

            <clr-combobox-container>
              <label class="clr-col-12">Meter types</label>
              <clr-combobox [(ngModel)]="botExecConfig.meterTypes" name="metertypes" clrMulti="true">
                <ng-container *clrOptionSelected="let selected">
                  {{selected}}
                </ng-container>
                <clr-options>
                  <clr-option *clrOptionItems="let optionNode of meterTypeOptionNodes; field:'name'"
                    [clrValue]="optionNode.name">
                    {{optionNode.name}}
                  </clr-option>
                </clr-options>
              </clr-combobox>
              <clr-control-helper>Empty = all available meter types.</clr-control-helper>
            </clr-combobox-container>

            <clr-input-container>
              <label>Retry</label>
              <input clrInput type="number" min="0" max="10000" placeholder="" name="retry"
                [disabled]="!botExecConfig.createPeriodDataReport" [(ngModel)]="botExecConfig.retry" />
            </clr-input-container>

            <clr-checkbox-container>
              <clr-checkbox-wrapper>
                <input type="checkbox" clrCheckbox [(ngModel)]="botExecConfig.createPeriodDataBasis"
                  name="botexecconfigcreateperioddatabasis" />
                <label>Create period data basis.</label>
              </clr-checkbox-wrapper>
            </clr-checkbox-container>

            <clr-checkbox-container>
              <clr-checkbox-wrapper>
                <input type="checkbox" clrCheckbox [(ngModel)]="botExecConfig.createPeriodDataReport"
                  name="botexecconfigcreateperioddatareport" />
                <label>Create period data reports.</label>
              </clr-checkbox-wrapper>
            </clr-checkbox-container>

            <clr-checkbox-container>
              <clr-checkbox-wrapper>
                <input type="checkbox" clrCheckbox [(ngModel)]="botExecConfig.outputOnlySigned"
                  name="botexecconfigcoutputonlysigned" />
                <label>All data should be signed.</label>
              </clr-checkbox-wrapper>
            </clr-checkbox-container>

            <clr-checkbox-container>
              <clr-checkbox-wrapper>
                <input type="checkbox" clrCheckbox [(ngModel)]="botExecConfig.outputOnlyNotInvoiced"
                  name="botexecconfigoutputonlynotinvoiced" />
                <label>All data should be not invoiced for output.</label>
              </clr-checkbox-wrapper>
            </clr-checkbox-container>

            <clr-checkbox-container>
              <clr-checkbox-wrapper>
                <input type="checkbox" clrCheckbox [(ngModel)]="botExecConfig.setInvoicedFlag"
                  [disabled]="!botExecConfig.enableOutput" name="botexecconfigsetinvoicedflag" />
                <label>Mark as invoiced after execution.</label>
              </clr-checkbox-wrapper>
            </clr-checkbox-container>

            <clr-input-container>
              <label>Customers per output (mail/ftp)</label>
              <input clrInput type="number" min="-1" max="10000" placeholder="" name="customersPerOutput"
                [disabled]="!botExecConfig.enableOutput" [(ngModel)]="botExecConfig.customersPerOutput" />
            </clr-input-container>

            <clr-checkbox-container>
              <clr-checkbox-wrapper>
                <input type="checkbox" clrCheckbox [(ngModel)]="botExecConfig.storeOnClient"
                  [disabled]="!botExecConfig.createPeriodDataReport" name="botexecconfigstoreonclient" />
                <label>Download result</label>
              </clr-checkbox-wrapper>
            </clr-checkbox-container>

            <clr-checkbox-container>
              <clr-checkbox-wrapper>
                <input type="checkbox" clrCheckbox [(ngModel)]="botExecConfig.enableOutput"
                  name="botexecconfigenableOutput" />
                <label>Enable output (mail/ftp).</label>
              </clr-checkbox-wrapper>
            </clr-checkbox-container>
          </clr-tab-content>
        </clr-tab>

        <clr-tab>
          <button clrTabLink>Filter</button>
          <clr-tab-content>
            <clr-input-container>
              <label>Invoice day at or after</label>
              <input clrInput type="number" min="0" max="1000" placeholder="" name="invoicedaymin"
                [(ngModel)]="botExecConfig.invoiceDayMin" />
            </clr-input-container>

            <clr-input-container>
              <label>Invoice day at or before</label>
              <input clrInput type="number" min="0" max="1000" placeholder="" name="invoicedaymax"
                [(ngModel)]="botExecConfig.invoiceDayMax" />
            </clr-input-container>

            <clr-toggle-container>
              <clr-toggle-wrapper>
                <input type="checkbox" clrToggle name="invoicedaywaittariffset"
                [(ngModel)]="botExecConfig.invoiceDayWaitTariffSet"/>
                <label>Require tariff to be set before invoice.</label>
              </clr-toggle-wrapper>
            </clr-toggle-container>

          </clr-tab-content>
        </clr-tab>

        <clr-tab>
          <button clrTabLink>State</button>
          <clr-tab-content>
            <clr-checkbox-container>
              <clr-checkbox-wrapper>
                <input type="checkbox" clrCheckbox [(ngModel)]="botExecConfig.overwriteSignedAndInvoiced"
                  [disabled]="!botExecConfig.createPeriodDataBasis" name="botexecconfigoverwriteSignedAndInvoiced" />
                <label>Overwrite signed and invoiced values.</label>
              </clr-checkbox-wrapper>
            </clr-checkbox-container>

            <clr-checkbox-container>
              <clr-checkbox-wrapper>
                <input type="checkbox" clrCheckbox [(ngModel)]="botExecConfig.includeStateActiveBilling"
                  name="includestateactivebilling" />
                <label>Include state ActiveBilling</label>
              </clr-checkbox-wrapper>
            </clr-checkbox-container>

            <clr-checkbox-container>
              <clr-checkbox-wrapper>
                <input type="checkbox" clrCheckbox [(ngModel)]="botExecConfig.includeStateActiveNoBilling"
                  name="includestateactivenobilling" />
                <label>Include state ActiveNoBilling</label>
              </clr-checkbox-wrapper>
            </clr-checkbox-container>

            <clr-checkbox-container>
              <clr-checkbox-wrapper>
                <input type="checkbox" clrCheckbox [(ngModel)]="botExecConfig.includeStateActiveExport"
                  name="includestateactiveexport" />
                <label>Include state ActiveExport</label>
              </clr-checkbox-wrapper>
            </clr-checkbox-container>

            <clr-checkbox-container>
              <clr-checkbox-wrapper>
                <input type="checkbox" clrCheckbox [(ngModel)]="botExecConfig.includeStateTest"
                  name="includestatetest" />
                <label>Include state Test</label>
              </clr-checkbox-wrapper>
            </clr-checkbox-container>
          </clr-tab-content>
        </clr-tab>
        <clr-tab>
          <button clrTabLink>QA</button>
          <clr-tab-content>
            <clr-checkbox-container>
              <clr-checkbox-wrapper>
                <input type="checkbox" clrCheckbox [(ngModel)]="botExecConfig.qaExportEnabled" name="qaExportEnabled" />
                <label>QA function enabled.</label>
              </clr-checkbox-wrapper>
            </clr-checkbox-container>

            <clr-checkbox-container>
              <clr-checkbox-wrapper>
                <input type="checkbox" clrCheckbox [(ngModel)]="botExecConfig.signOkDatavalues"
                  name="signOkDatavalues" />
                <label>Sign values flagged with no errors.</label>
              </clr-checkbox-wrapper>
            </clr-checkbox-container>
          </clr-tab-content>
        </clr-tab>
        <clr-tab>
          <button clrTabLink>Test</button>
          <clr-tab-content>
            <clr-checkbox-container>
              <clr-checkbox-wrapper>
                <input type="checkbox" clrCheckbox [(ngModel)]="botExecConfig.testExportEnabled"
                  name="testexportenabled" />
                <label>Test export enabled.</label>
              </clr-checkbox-wrapper>
            </clr-checkbox-container>
            <clr-select-container>
              <label>Export period</label>
              <select clrSelect name="testexportperiodselect" [(ngModel)]="selectedExecExportPeriods"
                [disabled]="!botExecConfig.testExportEnabled">
                <option *ngFor="let period of execExportPeriods; let i = index" [ngValue]="i">
                  {{formatByString(period.start,
                  'yyyy-MM-dd') + ' - ' + formatByString(period.end, 'yyyy-MM-dd') }}</option>
              </select>
            </clr-select-container>
            <clr-combobox-container>
              <label class="clr-col-12">Meter types</label>
              <clr-combobox [(ngModel)]="botExecConfig.meterTypes" name="testexportmetertypes" clrMulti="true"
                [disabled]="!botExecConfig.testExportEnabled">
                <ng-container *clrOptionSelected="let selected">
                  {{selected}}
                </ng-container>
                <clr-options>
                  <clr-option *clrOptionItems="let optionNode of meterTypeOptionNodes; field:'name'"
                    [clrValue]="optionNode.name">
                    {{optionNode.name}}
                  </clr-option>
                </clr-options>
              </clr-combobox>
              <clr-control-helper>Empty = all available meter types.</clr-control-helper>
            </clr-combobox-container>
          </clr-tab-content>
        </clr-tab>
      </clr-tabs>
    </form>
  </div>
  <div class="modal-footer">
    <button type="button" class="btn btn-outline" (click)="showEditExportProperties = false">Cancel</button>
    <button type="button" class="btn btn-primary"
      (click)="showEditExportProperties = false; onCloseEditExportProperties()">Run</button>
  </div>
</clr-modal>

<clr-modal [(clrModalOpen)]="showExportedFiles" [clrModalSize]="'lg'" [clrModalClosable]="false">
  <div class="modal-body">
    <app-bms-export-files-view *ngIf="showExportedFiles" [trusteeId]="selectedTrustee?.id" [trustees]="trustees"
      [exportPeriods]="execExportPeriods" [billingPeriod]="selectedExportConfig?.billingPeriod"
      [customerConfigs]="customeConfigsByCustomer"></app-bms-export-files-view>
  </div>
  <div class="modal-footer">
    <button type="button" class="btn btn-primary" (click)="showExportedFiles=false;">Close</button>
  </div>
</clr-modal>

<clr-modal [(clrModalOpen)]="showAddCustomer" [clrModalClosable]="false">
  <h3 class="modal-title">Select customer</h3>
  <div class="modal-body">
    <form clrForm>
      <clr-combobox-container>
        <label>Customer</label>
        <clr-combobox [(ngModel)]="addNewCustomerNode" name="addnewcustomerselect2">
          <clr-options>
            <clr-option *clrOptionItems="let customer of filteredCustomers; field:'name'" [clrValue]="customer">
              {{customer.name}}
            </clr-option>
          </clr-options>
      </clr-combobox>
      </clr-combobox-container>

    </form>
  </div>
  <div class="modal-footer">
    <button type="button" class="btn btn-outline" (click)="showAddCustomer = false">Cancel</button>
    <button type="button" class="btn btn-primary" (click)="showAddCustomer = false; onAddCustomer()">Add</button>
  </div>
</clr-modal>

<clr-modal [(clrModalOpen)]="showImportData" [clrModalClosable]="true">
  <h3 class="modal-title">Import data
    <clr-signpost>
      <clr-signpost-content *clrIfOpen>
        <h3>File format</h3>
        <p>The file should be in a csv format with columns 'externalid', 'startvalue', 'stopvalue'. </p>
      </clr-signpost-content>
    </clr-signpost>
  </h3>
  <div class="modal-body">
    <form clrForm>
      <clr-select-container *ngIf="dataBasisOverviewCustomerInfo">
        <label>Export period</label>
        <select clrSelect name="datarimportexportperiodselect" [(ngModel)]="importDatafileExportPeriod">
          <option *ngFor="let period of dataBasisOverviewCustomerInfo.exportPeriods; let i = index" [ngValue]="i">
            {{formatByString(period.start,
            'yyyy-MM-dd') + ' - ' + formatByString(period.end, 'yyyy-MM-dd') }}</option>
        </select>
      </clr-select-container>

      <clr-select-container>
        <label>Metertype</label>
        <select clrSelect name="importdatafilemetertypeselect" [(ngModel)]="importDatafileMetertype">
          <option *ngFor="let meterTypeOptionNode of meterTypeOptionNodes; let i = index"
            [ngValue]="meterTypeOptionNode.name">
            {{meterTypeOptionNode.name}}
          </option>
        </select>
      </clr-select-container>

      <clr-input-container>
        <label class="clr-col-12">Import data file, select or drop file.</label>
        <input clrInput class="clr-col-12" name="importdatafile_file" #datafile type="file"
          (change)="uploadDataFile(datafile)" />
      </clr-input-container>
    </form>
  </div>
  <div class="modal-footer">
    <button type="button" class="btn btn-outline" (click)="showImportData = false">Close</button>
    <button type="button" class="btn btn-primary" [disabled]="!importDatafile"
      (click)="showAddCustomer = false; onImportDataFile()">Import</button>
  </div>
</clr-modal>

<clr-modal [(clrModalOpen)]="showEditAllExportStartDataValues" [clrModalClosable]="false">
  <h3 class="modal-title">Edit all unsigned export data values</h3>
  <div class="modal-body">
    <h4>Start value</h4>
    <form clrForm clrLayout="vertical" autocomplete="off">
      <select clrSelect id="setToFirstPeriodValue" name="setToFirstPeriodValue"
        [(ngModel)]="editAllData.setToFirstPeriodValue">
        <option [ngValue]="true"> Take first period value </option>
        <option [ngValue]="false"> Set specific value </option>
      </select>
      <clr-input-container>
        <label class="clr-col-12">Set value</label>
        <input clrInput class="clr-col-12" name="editallstartvalue" type="number" min="0" max="999999999999999"
          [step]="dataPointValueStep" [disabled]="editAllData.setToFirstPeriodValue"
          [(ngModel)]="editAllData.startValue" />
      </clr-input-container>
      <clr-checkbox-container>
        <clr-checkbox-wrapper>
          <input type="checkbox" clrCheckbox [(ngModel)]="editAllData.onlySelected"
            [disabled]="selectedExportDataValues.length == 0" name="onlySelected" />
          <label>Only selected datavalues ({{selectedExportDataValues.length}} rows).</label>
        </clr-checkbox-wrapper>
      </clr-checkbox-container>
      <clr-checkbox-container>
        <clr-checkbox-wrapper>
          <input type="checkbox" clrCheckbox [(ngModel)]="editAllData.overridePreviousValues"
            name="overridePreviousValuesStart" />
          <label>Override already set vales.</label>
        </clr-checkbox-wrapper>
      </clr-checkbox-container>
    </form>
  </div>
  <div class="modal-footer">
    <button type="button" class="btn btn-outline" (click)="showEditAllExportStartDataValues = false">Cancel</button>
    <button type="button" class="btn btn-primary"
      (click)="showEditAllExportStartDataValues = false; onSaveEditAllExportDataValues(EditAllMode.StartValue)">Ok</button>
  </div>
</clr-modal>

<clr-modal [(clrModalOpen)]="showEditAllExportEndDataValues" [clrModalClosable]="false">
  <h3 class="modal-title">Edit all unsigned export data values</h3>
  <div class="modal-body">
    <h4>End value</h4>
    <form clrForm clrLayout="vertical" autocomplete="off">
      <clr-input-container>
        <label class="clr-col-12">Select endvalue date</label>
        <input clrInput class="clr-col-12" type="date" step="1" placeholder="" name="setendvaluedate"
          [(ngModel)]="editAllData.endValueDateString" />
      </clr-input-container>
      <clr-checkbox-container>
        <clr-checkbox-wrapper>
          <input type="checkbox" clrCheckbox [(ngModel)]="editAllData.onlySelected"
            [disabled]="selectedExportDataValues.length == 0" name="onlySelected" />
          <label>Only selected datavalues ({{selectedExportDataValues.length}} rows).</label>
        </clr-checkbox-wrapper>
      </clr-checkbox-container>
      <clr-checkbox-container>
        <clr-checkbox-wrapper>
          <input type="checkbox" clrCheckbox [(ngModel)]="editAllData.overridePreviousValues"
            name="overridePreviousValuesEnd" />
          <label>Override already set vales.</label>
        </clr-checkbox-wrapper>
      </clr-checkbox-container>
    </form>
  </div>
  <div class="modal-footer">
    <button type="button" class="btn btn-outline" (click)="showEditAllExportEndDataValues = false">Cancel</button>
    <button type="button" class="btn btn-primary"
      (click)="showEditAllExportEndDataValues = false; onSaveEditAllExportDataValues(EditAllMode.EndValue)">Ok</button>
  </div>
</clr-modal>

<clr-modal [(clrModalOpen)]="showEditAllExportConsumptions" [clrModalClosable]="false">
  <h3 class="modal-title">Edit all unsigned export data values</h3>
  <div class="modal-body">
    <h4>Consumption</h4>
    <form clrForm clrLayout="vertical" autocomplete="off">
      <clr-input-container>
        <label class="clr-col-12">Set consumption</label>
        <input clrInput class="clr-col-12" name="editallconsumptionvalue" type="number" min="0" max="999999999999999"
          [step]="1" [(ngModel)]="editAllData.startValue" />
      </clr-input-container>
      <clr-checkbox-container>
        <clr-checkbox-wrapper>
          <input type="checkbox" clrCheckbox [(ngModel)]="editAllData.onlySelected"
            [disabled]="selectedExportDataValues.length == 0" name="onlySelected" />
          <label>Only selected datavalues ({{selectedExportDataValues.length}} rows).</label>
        </clr-checkbox-wrapper>
      </clr-checkbox-container>
      <clr-checkbox-container>
        <clr-checkbox-wrapper>
          <input type="checkbox" clrCheckbox [(ngModel)]="editAllData.overridePreviousValues"
            name="overridePreviousValuesEnd" />
          <label>Override already set vales.</label>
        </clr-checkbox-wrapper>
      </clr-checkbox-container>
    </form>
  </div>
  <div class="modal-footer">
    <button type="button" class="btn btn-outline" (click)="showEditAllExportConsumptions = false">Cancel</button>
    <button type="button" class="btn btn-primary"
      (click)="showEditAllExportConsumptions = false; onSaveEditAllExportDataValues(EditAllMode.Consumption)">Ok</button>
  </div>
</clr-modal>

<clr-modal [(clrModalOpen)]="showEditAllExportTariffs" [clrModalClosable]="false">
  <h3 class="modal-title">Edit all unsigned export tariffs</h3>
  <div class="modal-body">
    <h4>Tariff</h4>
    <form clrForm clrLayout="vertical" autocomplete="off">
      <clr-input-container>
        <label class="clr-col-12">Tariff</label>
        <input clrInput class="clr-col-12" name="editalltariff" type="number" min="0" max="999999999999999"
          [step]="tariffValueStep" [(ngModel)]="editAllData.tariff" />
      </clr-input-container>
    </form>
  </div>
  <div class="modal-footer">
    <button type="button" class="btn btn-outline" (click)="showEditAllExportTariffs = false">Cancel</button>
    <button type="button" class="btn btn-primary"
      (click)="showEditAllExportTariffs = false; onSaveEditAllExportDataValues(EditAllMode.Tariff)">Ok</button>
  </div>
</clr-modal>

<clr-modal [(clrModalOpen)]="showEditExportDataValue" [clrModalSize]="'xl'" [clrModalClosable]="false">
  <h3 class="modal-title">Edit export data value</h3>
  <div class="modal-body" *ngIf="showEditExportDataValue">
    <div class="clr-row">
      <div class="clr-col-4">
        <form clrForm clrLayout="vertical" autocomplete="off" *ngIf="selectedExportDataValueCopy">
          <clr-input-container>
            <label class="clr-col-12">External Id</label>
            <input clrInput class="clr-col-12" name="editexternalid" type="text"
              [(ngModel)]="selectedExportDataValueCopy.externalId" />
          </clr-input-container>
          <clr-input-container>
            <label class="clr-col-12">Prefix</label>
            <input clrInput class="clr-col-12" name="editprefix" type="text"
              [(ngModel)]="selectedExportDataValueCopy.prefix" />
          </clr-input-container>
          <clr-input-container>
            <label class="clr-col-12">Start value</label>
            <input clrInput class="clr-col-12" name="editstartvalue" type="number" min="0" max="999999999999999"
              [step]="dataPointValueStep" [(ngModel)]="selectedExportDataValueCopy.startValue" />
          </clr-input-container>
          <clr-input-container>
            <label class="clr-col-12">End value</label>
            <input clrInput class="clr-col-12" name="editendvalue" type="number" min="0" max="999999999999999"
              [step]="dataPointValueStep" [(ngModel)]="selectedExportDataValueCopy.endValue" />
          </clr-input-container>
          <clr-input-container>
            <label class="clr-col-12">Tariff</label>
            <input clrInput class="clr-col-12" name="edittariff" type="number" min="0" max="100000"
              [step]="tariffValueStep" [(ngModel)]="selectedExportDataValueCopy.tariff" />
          </clr-input-container>
          <clr-input-container>
            <label class="clr-col-12">VAT Rate</label>
            <input clrInput class="clr-col-12" name="editvatrate" type="number" min="0" max="100000"
              [step]="tariffValueStep" [(ngModel)]="selectedExportDataValueCopy.vatRate" />
          </clr-input-container>
          <!-- <clr-checkbox-container>
            <clr-checkbox-wrapper>
              <input type="checkbox" clrCheckbox [(ngModel)]="selectedExportDataValueCopy.includeVAT"
                name="editincludevat" />
              <label>Include VAT.</label>
            </clr-checkbox-wrapper>
          </clr-checkbox-container> -->
        </form>
      </div>
      <div class="clr-col-8">
        <div class="clr-row">
          <div class="clr-col-3">
            <clr-input-container>
              <label>Extrapolate period from:</label>
              <input clrInput type="date" step="1" placeholder="" name="extrapolateperiodfrom"
                [(ngModel)]="editExtrapolateStartString" />
            </clr-input-container>
          </div>
          <div class="clr-col-3">
            <clr-input-container>
              <label>Extrapolate period to:</label>
              <input clrInput type="date" step="1" placeholder="" name="extrapolateperiodto"
                [(ngModel)]="editExtrapolateEndString" />
            </clr-input-container>
          </div>
          <div class="clr-col-6" style="padding-top: 2em;">
            <clr-button-group class="btn-sm btn-secondary">
              <clr-button type="button" class="btn btn-outline"
                (click)="setExtrapolatePeriodLastYear()">{{periodLastYearSet ? 'Reset' : 'Last year'}}</clr-button>
            </clr-button-group>

            <clr-button-group class="btn-sm btn-secondary">
              <clr-button type="button" class="btn btn-outline"
                (click)="editExtrapolateEndValue(false)">Extrapolate</clr-button>
              <clr-button [clrInMenu]="true" [disabled]="this.selectedExportDataValueMeterType == 'ALL METERTYPES'"
                (click)="extrapolateAllEndValues(false)">
                Extrapolate all...</clr-button>
              <clr-button [clrInMenu]="true" (click)="editExtrapolateEndValue(true)">
                Extrapolate from last period end</clr-button>
              <clr-button [clrInMenu]="true" [disabled]="this.selectedExportDataValueMeterType == 'ALL METERTYPES'"
                (click)="extrapolateAllEndValues(true)">
                Extrapolate all from last period end...</clr-button>
              <clr-button [clrInMenu]="true"
                (click)="selectedExportDataValueCopy.endValue = selectedExportDataValueCopy.startValue">
                Set zero consumption</clr-button>
            </clr-button-group>
          </div>
        </div>
        <div class="clr-row edit-export-data-dashboard">
          <xproj-dashboard #dashboardEdit [editMode]="false" [showDashboardSettings]="false"
            [dashboardId]="'bms_export_edit'" [enableExport]="false" [responsiveWidth]="responsiveWidthEdit"
            [dashboardOutputParameters]="dashboardGridDetailOutputParameters"
            (onWidgetValueChanged)="onWidgetValueChangedEdit($event)"></xproj-dashboard>
        </div>
      </div>
    </div>
  </div>
  <div class="modal-footer">
    <button type="button" class="btn btn-outline"
      (click)="showEditExportDataValue = false; selectedExportDataValueCopy = null">Cancel</button>
    <button type="button" class="btn btn-primary" (click)="saveSelectedDataExportvalue();">
      <clr-icon shape="floppy"></clr-icon>
      Save
    </button>
  </div>
</clr-modal>

<clr-modal [(clrModalOpen)]="showEditManualExportDataValue" [clrModalSize]="'md'" [clrModalClosable]="false">
  <h3 class="modal-title">Edit export data value</h3>
  <div class="modal-body" *ngIf="showEditManualExportDataValue">
    <div class="clr-row">
      <div class="clr-col-6">
        <form clrForm clrLayout="vertical" autocomplete="off" *ngIf="selectedExportDataValueCopy">
          <clr-input-container>
            <label class="clr-col-12">External Id</label>
            <input clrInput class="clr-col-12" name="editexternalidmanual" type="text"
              [(ngModel)]="selectedExportDataValueCopy.externalId" />
          </clr-input-container>
          <clr-input-container>
            <label class="clr-col-12">Prefix</label>
            <input clrInput class="clr-col-12" name="editprefixmanual" type="text"
              [(ngModel)]="selectedExportDataValueCopy.prefix" />
          </clr-input-container>
          <clr-input-container>
            <label class="clr-col-12">SV Lant ApartmentNo</label>
            <input clrInput class="clr-col-12" name="edittsvlantapartmentnoanual" type="number" min="0" max="9999"
              step="1" [(ngModel)]="selectedExportDataValueCopy.svLantApartmentNo" />
          </clr-input-container>
          <clr-input-container>
            <label class="clr-col-12">Tariff</label>
            <input clrInput class="clr-col-12" name="edittariffmanual" type="number" min="0" max="100000"
              [step]="tariffValueStep" [(ngModel)]="selectedExportDataValueCopy.tariff" />
          </clr-input-container>
          <clr-input-container>
            <label class="clr-col-12">VAT Rate</label>
            <input clrInput class="clr-col-12" name="editvatratemanual" type="number" min="0" max="100000"
              [step]="tariffValueStep" [(ngModel)]="selectedExportDataValueCopy.vatRate" />
          </clr-input-container>
        </form>
      </div>
      <div class="clr-col-6">
        <form clrForm clrLayout="vertical" autocomplete="off" *ngIf="selectedExportDataValueCopy">
          <clr-input-container>
            <label class="clr-col-12">Start date</label>
            <input clrInput class="clr-col-12" type="date" step="1" placeholder="" name="editstartmanual"
              [(ngModel)]="selectedExportDataValueCopy.startDateString" />
          </clr-input-container>
          <clr-input-container>
            <label class="clr-col-12">Start value</label>
            <input clrInput class="clr-col-12" name="editstartvaluemanual" type="number" min="0" max="999999999999999"
              [step]="dataPointValueStep" [(ngModel)]="selectedExportDataValueCopy.startValue" />
          </clr-input-container>
          <clr-input-container>
            <label class="clr-col-12">End date</label>
            <input clrInput class="clr-col-12" type="date" step="1" placeholder="" name="editendmanual"
              [(ngModel)]="selectedExportDataValueCopy.endDateString" />
          </clr-input-container>
          <clr-input-container>
            <label class="clr-col-12">End value</label>
            <input clrInput class="clr-col-12" name="editendvaluemanual" type="number" min="0" max="999999999999999"
              [step]="dataPointValueStep" [(ngModel)]="selectedExportDataValueCopy.endValue" />
          </clr-input-container>
        </form>
      </div>
    </div>
    <clr-textarea-container>
      <label class="clr-col-12">Comment</label>
      <textarea clrTextarea class="clr-col-12" name="editcommentmanual" [rows]="2" [cols]="50"
        [(ngModel)]="selectedExportDataValueCopy.comment"></textarea>
    </clr-textarea-container>
  </div>
  <div class="modal-footer">
    <button type="button" class="btn btn-outline"
      (click)="showEditManualExportDataValue = false; selectedExportDataValueCopy = null">Cancel</button>
    <button type="button" class="btn btn-primary" (click)="saveManualDataExportvalue();">
      <clr-icon shape="floppy"></clr-icon>
      Save
    </button>
  </div>
</clr-modal>

<clr-modal [(clrModalOpen)]="extrapolateAllRunning" [clrModalSize]="''" [clrModalClosable]="true">
  <h3 class="modal-title">Extrapolate all running</h3>
  <div class="modal-body" style="min-height: 50px;">
    <div class="progress labeled">
      <progress [value]="extrapolateAllPercent" max="100"></progress>
      <span>{{extrapolateAllPercent}}%</span>
    </div>
  </div>
</clr-modal>

<as-split direction="horizontal" [unit]="'pixel'" (dragEnd)="onSplitDragEnd($event)" class="meter-view"
  [gutterSize]="7">
  <as-split-area [order]="1">
    <div class="detail-pane">
      <h3 *ngIf="selectedTrustee" class="sensitive">{{selectedTrustee.name}}</h3>
      <clr-tabs>
        <clr-tab>
          <button clrTabLink>Overview</button>
          <ng-template [(clrIfActive)]="overviewActive">
            <clr-tab-content *ngIf="!selectedTrustee?.billingEnabled">
              <p>Billing not enabled for this trustee.</p>
            </clr-tab-content>
            <clr-tab-content *ngIf="selectedTrustee?.billingEnabled && overviewLoading">
              <clr-spinner>Loading ...</clr-spinner>
            </clr-tab-content>

            <clr-tab-content *ngIf="selectedTrustee?.billingEnabled && !overviewLoading">
              <div *ngFor="let billingPeriodConfig of billingPeriodConfigs; let ii = index">
                <div class="clr-row">
                  <div class="clr-col-8">
                    <form clrForm>
                      <clr-select-container>
                        <label class="clr-col-4">Export period
                          ({{getBmsBillingPeriodString(billingPeriodConfig.billingPeriod)}})</label>
                        <select clrSelect class="clr-col-8" name="datareportbasisexportperiodselect{{ii}}"
                          [(ngModel)]="billingPeriodConfig.selectedExecExportPeriods"
                          (ngModelChange)="selectedOverviewPeriodChange(billingPeriodConfig)">
                          <option *ngFor="let period of billingPeriodConfig.exportPeriods; let i = index" [ngValue]="i">
                            {{formatByString(period.start,
                            'yyyy-MM-dd') + ' - ' + formatByString(period.end, 'yyyy-MM-dd') }}</option>
                        </select>
                      </clr-select-container>
                    </form>
                  </div>
                  <div class="clr-col-4">
                    <clr-toggle-container clrInline>
                      <clr-toggle-wrapper>
                        <input type="checkbox" clrToggle [(ngModel)]="billingPeriodConfig.filterLateInvoice"
                          name="filterlateinvoice_{{ii}}" />
                        <label>After 15th</label>
                      </clr-toggle-wrapper>
                      <clr-toggle-wrapper>
                        <input type="checkbox" clrToggle [(ngModel)]="billingPeriodConfig.filterSpecialInvoice"
                          name="filterspecialinvoice_{{ii}}" />
                        <label>Special</label>
                      </clr-toggle-wrapper>
                    </clr-toggle-container>
                  </div>
                </div>

                <table class="table overviewTable">
                  <caption>
                    Customers status
                  </caption>
                  <thead>
                    <tr>
                      <th class="left">Customer</th>
                      <th class="left">Signed</th>
                      <th class="left">Invoiced</th>
                      <th class="left">Status</th>
                      <th class="center">After 15th</th>
                      <th class="center" *ngIf="!billingPeriodConfig.filterSpecialInvoice">Special</th>
                      <th class="left" *ngIf="billingPeriodConfig.filterSpecialInvoice">Special</th>
                      <th></th>
                    </tr>
                  </thead>
                  <tbody>
                    <ng-template ngFor let-customerInfo [ngForOf]="billingPeriodConfig.overviewCustomerInfos">
                      <tr
                        *ngIf="customerInfo.active && (!customerInfo.customerConfig || ((customerInfo.customerConfig?.invoiceDay > billingPeriodConfig.filterLateInvoice ? 15 : 0)
                              && (!billingPeriodConfig.filterSpecialInvoice || customerInfo.customerConfig?.invoiceInfo?.length > 0) && (!customerInfo.customerConfig.disabled || customerInfo.customerConfig.disabledAt > billingPeriodConfig.exportPeriods[billingPeriodConfig.selectedExecExportPeriods].end)))">
                        <td class="left sensitive"><a [routerLink]="['/bmsadmin/customers/' + customerInfo.customerId]"
                            routerLinkActive="active">{{customerInfo.customerName}}</a></td>
                        <td class="left">
                          <clr-icon shape="check" *ngIf="customerInfo.allSigned"></clr-icon>
                        </td>
                        <td class="left">
                          <clr-icon shape="check" *ngIf="customerInfo.allInvoiced"></clr-icon>
                        </td>
                        <td class="left">{{customerInfo.initiated ? getDataExportValueStatusText(customerInfo.status,
                          'OK') : '...'}}
                          ({{customerInfo.errorCount}})</td>
                        <td class="center">
                          <cds-icon shape="exclamation-circle" status="danger" size="sm" solid="false"
                            *ngIf="customerInfo.initiated && customerInfo.customerConfig?.invoiceDay >= 15 && !customerInfo.tariffsSet"></cds-icon>
                          <cds-icon shape="check-circle" status="success" size="sm" solid="true"
                            *ngIf="customerInfo.initiated && customerInfo.customerConfig?.invoiceDay >= 15 && customerInfo.tariffsSet"></cds-icon>
                        </td>
                        <td class="center" *ngIf="!billingPeriodConfig.filterSpecialInvoice">
                          <div class="signpost-item" *ngIf="customerInfo.customerConfig?.invoiceInfo?.length > 0">
                            <clr-signpost>
                              <cds-icon shape="bubble-exclamation" clrSignpostTrigger status="warning"
                                (click)="editInvoiceMode=false" size="sm"></cds-icon>
                              <clr-signpost-content [clrPosition]="'right-middle'" *clrIfOpen>
                                <div class="info-paragraph" *ngIf="!editInvoiceMode">
                                  <cds-icon shape="pencil" size="sm" class="edit-mode"
                                    (click)="editInvoiceInfoList(customerInfo.customerConfig.invoiceInfo)"></cds-icon>
                                  {{customerInfo.customerConfig.invoiceInfo}}
                                </div>
                                <form clrForm clrLayout="vertical" autocomplete="off" spellcheck="false"
                                  *ngIf="editInvoiceMode">
                                  <clr-textarea-container>
                                    <label class="clr-col-12">Invoice info</label>
                                    <textarea clrTextarea class="clr-col-12" name="editinvoiceinfolist" [rows]="6"
                                      [cols]="50" [(ngModel)]="copyInvoiceInfo"></textarea>
                                  </clr-textarea-container>
                                </form>
                                <div class="edit-buttons" *ngIf="editInvoiceMode">
                                  <button type="button" class="btn btn-secondary" (click)="editInvoiceMode = false">
                                    <clr-icon shape="times"></clr-icon>
                                    Cancel
                                  </button>
                                  <button type="submit" class="btn btn-secondary"
                                    (click)="saveInvoiceInfoList(customerInfo.customerConfig);">
                                    <clr-icon shape="floppy"></clr-icon>
                                    Save
                                  </button>
                                </div>
                              </clr-signpost-content>
                            </clr-signpost>
                          </div>
                        </td>
                        <td class="left invoice-specail-col" *ngIf="billingPeriodConfig.filterSpecialInvoice">
                          <cds-icon shape="bubble-exclamation" status="warning" size="sm"></cds-icon>
                          {{customerInfo.customerConfig.invoiceInfo}}
                        </td>
                        <td class="left">
                          <cds-icon shape="eye" size="sm" [style.cursor]="'pointer'"
                            (click)="viewCustomerDataExportValues(billingPeriodConfig, customerInfo)"></cds-icon>
                        </td>
                      </tr>
                    </ng-template>
                  </tbody>
                </table>
              </div>
            </clr-tab-content>
          </ng-template>
        </clr-tab>
        <clr-tab>
          <button clrTabLink>Config / Export</button>
          <ng-template [(clrIfActive)]="configActive">
            <clr-tab-content>
              <clr-datagrid #grid class="customer-list" [(clrDgSelected)]="selectedCustomers"
                [clrDgLoading]="loadingCustomer" (clrDgSelectedChange)="selectedCustomerChanged($event)">

                <clr-dg-action-bar>
                  <div class="btn-group">
                    <button type="button" class="btn btn-sm btn-secondary" (click)="onShowAddCustomer()">
                      <clr-icon shape="plus"></clr-icon> Add customer
                    </button>
                    <button type="button" class="btn btn-sm btn-danger-outline" (click)="onRemoveSelectedCustomers()"
                      [disabled]="selectedCustomers.length == 0">
                      <clr-icon shape="trash"></clr-icon> Remove customers... ({{selectedCustomers.length}})
                    </button>
                  </div>
                  <div class="btn-group">
                    <button type="button" class="btn btn-sm btn-secondary" (click)="selectedCustomers=[]"
                      [disabled]="selectedCustomers.length == 0">
                      <clr-icon shape="times"></clr-icon> Clear selected
                    </button>
                    <span *ngIf="selectedCustomers.length" class="selected-customers">Selected customers: {{selectedCustomers.length}}</span>
                  </div>
                </clr-dg-action-bar>

                <clr-dg-column [clrDgField]="'customerName'">
                  <ng-container>Customers</ng-container>
                </clr-dg-column>
                <clr-dg-column [clrDgField]="'customerConfig.invoiceDay'">
                  <ng-container>Info</ng-container>
                </clr-dg-column>

                <clr-dg-row *clrDgItems="let customer of overviewCustomerInfos | bmscustomerenabledpipe ; index as i"
                  [clrDgItem]="customer">
                  <clr-dg-cell class="sensitive"> {{customer.customerName}}</clr-dg-cell>
                  <clr-dg-cell > {{customer.customerConfig.invoiceDay >= 15 ? 'After 15th' : ''}}</clr-dg-cell>
                </clr-dg-row>

              </clr-datagrid>

              <div *ngIf="selectedTrustee?.billingEnabled">
                <h3>Exports</h3>
                <clr-datagrid #grid class="meter-list" [(clrDgSingleSelected)]="selectedExport"
                  [clrDgLoading]="loadingExport" (clrDgSingleSelectedChange)="selectedExportChanged($event)">

                  <clr-dg-action-bar>
                    <div class="btn-group">
                      <button type="button" class="btn btn-sm btn-secondary" (click)="onEditExportConfigs()">
                        <clr-icon shape="pencil"></clr-icon> Edit config
                      </button>
                    </div>

                    <div class="btn-group">
                      <button type="button" class="btn btn-sm btn-secondary" (click)="viewExportedFiles()"
                        [disabled]="!selectedExportConfig">
                        <clr-icon shape="file-group"></clr-icon> View files
                      </button>
                    </div>

                    <div class="btn-group">
                      <button type="button" class="btn btn-sm btn-secondary" (click)="manualExport()"
                        [disabled]="!selectedExportConfig" [clrLoading]="execCreateFiles">
                        <clr-icon shape="export"></clr-icon> Manual exec...
                      </button>
                      <label style="margin-left: 1em;">{{execStatusMessage}}</label>
                    </div>
                    <!--
                  <div *ngIf="execStatusMessage?.length > 0">
                    {{execStatusMessage}}
                  </div> -->

                  </clr-dg-action-bar>
                  <clr-dg-column>
                    <ng-container>
                      Export config
                    </ng-container>
                  </clr-dg-column>

                  <clr-dg-row *ngFor="let exportConfig of exportConfigs ; index as i" [clrDgItem]="i">
                    <clr-dg-cell class="sensitive"> {{exportConfig.name}}
                    </clr-dg-cell>
                  </clr-dg-row>

                </clr-datagrid>
              </div>
            </clr-tab-content>
          </ng-template>
        </clr-tab>
        <clr-tab>
          <button clrTabLink (click)="onSelectTabDataReportBasis($event)">Data export values</button>
          <ng-template [(clrIfActive)]="dataReportBasisActive">
            <div class="grid-area">
              <clr-tab-content *ngIf="!selectedTrustee?.billingEnabled">
                <p>Billing not enabled for this trustee.</p>
              </clr-tab-content>
              <clr-tab-content *ngIf="selectedTrustee?.billingEnabled">
                <div class="clr-row">
                  <div class="clr-col-2">

                  </div>
                  <div class="clr-col-8 data-export-header noselect">
                    <cds-icon shape="play" solid="true" size="md" flip="horizontal" class="icon-button"
                      (click)="selectPrevDataExportCustomer()"></cds-icon>
                    <cds-icon shape="play" solid="true" size="md" class="icon-button"
                      (click)="selectNextDataExportCustomer()"></cds-icon>
                    <label> {{dataBasisOverviewCustomerInfo?.customerName}} ({{hasData ? (allSigned ? 'Signed' :
                      'Unsigned') : ' --- '}}{{allInvoiced ? ', Invoiced' : ''}}) </label>
                  </div>
                  <div class="clr-col-2 data-export-header noselect">
                    <cds-icon shape="exclamation-circle" status="danger" size="md" solid="false" class="icon-button"
                      *ngIf="dataBasisOverviewCustomerInfo?.initiated && dataBasisOverviewCustomerInfo?.customerConfig?.invoiceDay >= 15 && !dataBasisOverviewCustomerInfo?.tariffsSet"></cds-icon>
                    <cds-icon shape="check-circle" status="success" size="md" solid="true" class="icon-button"
                      *ngIf="dataBasisOverviewCustomerInfo?.initiated && dataBasisOverviewCustomerInfo?.customerConfig?.invoiceDay >= 15 && dataBasisOverviewCustomerInfo?.tariffsSet"></cds-icon>
                    <label class="header2"
                      *ngIf="dataBasisOverviewCustomerInfo?.initiated && dataBasisOverviewCustomerInfo?.customerConfig?.invoiceDay >= 15">
                      After 15th</label>
                  </div>
                </div>
                <div class="clr-row">
                  <div class="clr-col-5">
                    <form clrForm>

                      <clr-select-container>
                        <label>Customer</label>
                        <select clrSelect name="datareportbasiscustomerselect" class="sensitive"
                          [(ngModel)]="dataBasisOverviewCustomerInfo"
                          (ngModelChange)="selectedDataBasisCustomerChange($event)">
                          <option *ngFor="let info of overviewCustomerInfos" [ngValue]="info">
                            {{info.customerName}} {{info.customerConfig.disabled ? ' (disabled)' : ''}}
                          </option>
                        </select>
                      </clr-select-container>

                      <clr-select-container *ngIf="dataBasisOverviewCustomerInfo">
                        <label>Export period</label>
                        <select clrSelect name="datareportbasisexportperiodselect"
                          [(ngModel)]="selectedDataBasisExportPeriod"
                          (ngModelChange)="selectedDataBasisPeriodChange($event)">
                          <option *ngFor="let period of dataBasisOverviewCustomerInfo.exportPeriods; let i = index"
                            [ngValue]="i">
                            {{formatByString(period.start,
                            'yyyy-MM-dd') + ' - ' + formatByString(period.end, 'yyyy-MM-dd') }}</option>
                        </select>
                      </clr-select-container>

                      <clr-select-container>
                        <label>Metertype</label>
                        <select clrSelect name="datareportbasisexportmetertypeselect"
                          [(ngModel)]="selectedExportDataValueMeterType" (ngModelChange)="selectedMeterTypeChanged()">
                          <option *ngFor="let meterType of dataExportValuesMeterTypes; let i = index"
                            [ngValue]="meterType">
                            {{meterType}}
                          </option>
                        </select>
                      </clr-select-container>
                    </form>
                  </div>
                  <div class="clr-col-3">
                    <form clrForm>
                      <clr-select-container>
                        <label>Signed</label>
                        <select clrSelect name="datareportbasissignedselect"
                          [(ngModel)]="selectedExportSignedFlagStatus" (ngModelChange)="updateDataBasis()">
                          <option [ngValue]="FlagStatus.All">All</option>
                          <option [ngValue]="FlagStatus.OnlyTrue">Signed</option>
                          <option [ngValue]="FlagStatus.OnlyFalse">Not signed</option>
                        </select>
                      </clr-select-container>

                      <clr-select-container>
                        <label>Values</label>
                        <select clrSelect name="datareportbasismissingselect"
                          [(ngModel)]="selectedExportMissingValueFlagStatus" (ngModelChange)="updateDataBasis()">
                          <option [ngValue]="FlagStatus.All">All</option>
                          <option [ngValue]="FlagStatus.OnlyTrue">Has values</option>
                          <option [ngValue]="FlagStatus.OnlyFalse">Missing value</option>
                        </select>
                      </clr-select-container>

                      <clr-select-container>
                        <label>Errors</label>
                        <select clrSelect name="datareportbasiserrorselect" [(ngModel)]="selectedExportErrorFlagStatus"
                          (ngModelChange)="updateDataBasis()">
                          <option [ngValue]="FlagStatus.All">All</option>
                          <option [ngValue]="FlagStatus.OnlyTrue">Has errors</option>
                          <option [ngValue]="FlagStatus.OnlyFalse">No errors</option>
                        </select>
                      </clr-select-container>

                      <!-- <clr-select-container>
                        <label>Warnings</label>
                        <select clrSelect name="datareportbasiswarningselect" [(ngModel)]="selectedExportWarningFlagStatus"
                          (ngModelChange)="updateDataBasis()">
                          <option [ngValue]="FlagStatus.All">All</option>
                          <option [ngValue]="FlagStatus.OnlyTrue">Has warnings</option>
                          <option [ngValue]="FlagStatus.OnlyFalse">No warnings</option>
                        </select>
                      </clr-select-container> -->

                    </form>
                  </div>
                  <div class="clr-col-4">
                    <div class="invoice-info">
                      <div class="info-paragraph" *ngIf="!editInvoiceMode">
                        <cds-icon shape="pencil" size="sm" class="edit-mode" (click)="editInvoiceInfo()"></cds-icon>
                        <b> Invoice info</b>
                      </div>
                      <div class="info-paragraph" *ngIf="!editInvoiceMode">
                        {{dataBasisOverviewCustomerInfo?.customerConfig?.invoiceInfo}}
                      </div>
                      <form clrForm clrLayout="vertical" autocomplete="off" spellcheck="false"
                        *ngIf="dataBasisOverviewCustomerInfo?.customerConfig && editInvoiceMode">
                        <clr-textarea-container>
                          <label class="clr-col-12">Invoice info</label>
                          <textarea clrTextarea class="clr-col-12" name="editinvoiceinfo" [rows]="6" [cols]="50"
                            [(ngModel)]="copyInvoiceInfo"></textarea>
                        </clr-textarea-container>
                      </form>
                      <div class="edit-buttons" *ngIf="editInvoiceMode">
                        <button type="button" class="btn btn-secondary" (click)="editInvoiceMode = false">
                          <clr-icon shape="times"></clr-icon>
                          Cancel
                        </button>
                        <button type="submit" class="btn btn-secondary" (click)="saveInvoiceInfo();">
                          <clr-icon shape="floppy"></clr-icon>
                          Save
                        </button>
                      </div>

                      <div class="info-paragraph" *ngIf="!editOperationMode">
                        <cds-icon shape="pencil" size="sm" class="edit-mode" (click)="editOperationInfo()"></cds-icon>
                        <b> Operation info</b>
                      </div>
                      <div class="info-paragraph" *ngIf="!editOperationMode">
                        {{dataBasisOverviewCustomerInfo?.customerConfig?.operationInfo}}
                      </div>
                      <form clrForm clrLayout="vertical" autocomplete="off" spellcheck="false"
                        *ngIf="dataBasisOverviewCustomerInfo?.customerConfig && editOperationMode">
                        <clr-textarea-container>
                          <label class="clr-col-12">Operation info</label>
                          <textarea clrTextarea class="clr-col-12" name="editoperationinfo" [rows]="6" [cols]="50"
                            [(ngModel)]="copyOperationInfo"></textarea>
                        </clr-textarea-container>
                      </form>
                      <div class="edit-buttons" *ngIf="editOperationMode">
                        <button type="button" class="btn btn-secondary" (click)="editOperationMode = false">
                          <clr-icon shape="times"></clr-icon>
                          Cancel
                        </button>
                        <button type="submit" class="btn btn-secondary" (click)="saveOperationInfo();">
                          <clr-icon shape="floppy"></clr-icon>
                          Save
                        </button>
                      </div>
                    </div>
                  </div>
                </div>

                <h3>Export data values</h3>
                <div class="searchbar-container">
                  <div class="icons-search-wrapper">
                    <label class="searchbar-label">
                      <clr-icon shape="search" size="24"></clr-icon>
                      <input type="text" class="searchbar-input" placeholder="Device id..." (keyup.enter)="search()"
                        [(ngModel)]="deviceIdFilter">
                    </label>
                  </div>
                </div>
                <clr-datagrid #exportDataValuesGrid *ngIf="dataExportValues"
                  class="datagrid-compact export-datavalues-grid" [(clrDgSelected)]="selectedExportDataValues"
                  [clrDgLoading]="loadingDataExportValues"
                  (clrDgSingleSelectedChange)="selectedExportDataValueChanged($event)">

                  <clr-dg-action-bar>
                    <clr-button-group class="btn-sm btn-secondary">
                      <clr-button type="button" class="btn btn-sm btn-secondary" (click)="setFilterSelected()">
                        <clr-icon shape="filter-grid-circle"></clr-icon> Filter selected
                      </clr-button>
                      <clr-button type="button" class="btn btn-sm btn-secondary" (click)="clearFilters()">
                        <clr-icon shape="times"></clr-icon> Clear filter
                      </clr-button>
                    </clr-button-group>
                    <clr-button-group class="btn-sm btn-secondary">
                      <clr-button type="button" class="btn btn-sm btn-secondary" (click)="onEditSelected()"
                        [disabled]="selectedExportDataValues.length != 1 || allAreSignedOrInvoiced(selectedExportDataValues)">
                        <clr-icon shape="pencil"></clr-icon> Edit
                      </clr-button>
                      <clr-button type="button" class="btn btn-sm btn-secondary" (click)="onSignSelected()"
                        [disabled]="selectedExportDataValues.length == 0 || allAreSigned(selectedExportDataValues)">
                        <clr-icon shape="digital-signature"></clr-icon> Sign ({{selectedExportDataValues.length}})
                      </clr-button>
                      <clr-button type="button" class="btn btn-sm btn-secondary" (click)="onUnsignSelected()"
                        [disabled]="selectedExportDataValues.length == 0 || !hasSigned(selectedExportDataValues)">
                        <clr-icon shape="digital-signature"></clr-icon> Unsign ({{selectedExportDataValues.length}})
                      </clr-button>
                      <clr-button type="button" class="btn btn-sm btn-outline-danger" (click)="onDeleteSelected()"
                        [disabled]="selectedExportDataValues.length == 0 || allAreSignedOrInvoiced(selectedExportDataValues)">
                        <clr-icon shape="trash"></clr-icon> Delete ({{selectedExportDataValues.length}}) ...
                      </clr-button>
                      <clr-button [clrInMenu]="true" type="button" class="btn btn-sm btn-secondary"
                        (click)="onComment()"
                        [disabled]="selectedExportDataValues.length == 0 || allAreSigned(selectedExportDataValues)">
                        <clr-icon shape="digital-signature"></clr-icon> Comment ({{selectedExportDataValues.length}})
                        ...
                      </clr-button>
                      <clr-button [clrInMenu]="true" type="button" class="btn btn-sm btn-secondary"
                        (click)="doShowEditAllExportStartDataValues()">
                        <clr-icon shape="pencil"></clr-icon> Start values...
                      </clr-button>
                      <clr-button [clrInMenu]="true" type="button" class="btn btn-sm btn-secondary"
                        (click)="doShowEditAllExportEndDataValues()">
                        <clr-icon shape="pencil"></clr-icon> End values...
                      </clr-button>
                      <clr-button [clrInMenu]="true" type="button" class="btn btn-sm btn-secondary"
                        (click)="doShowEditAllExportConsumptions()">
                        <clr-icon shape="pencil"></clr-icon> Consumptions...
                      </clr-button>
                      <clr-button [clrInMenu]="true" type="button" class="btn btn-sm btn-secondary"
                        (click)="showEditAllExportTariffs = true;">
                        <clr-icon shape="pencil"></clr-icon> Tariffs...
                      </clr-button>
                      <clr-button [clrInMenu]="true" type="button" class="btn btn-sm btn-secondary"
                        (click)="addEmptyRow()">
                        <clr-icon shape="plus"></clr-icon> Add new value...
                      </clr-button>
                    </clr-button-group>

                    <clr-button-group class="btn-sm btn-secondary">
                      <clr-button type="button" class="btn btn-sm btn-secondary" (click)="onSignAll()">
                        <clr-icon shape="digital-signature"></clr-icon> Sign all
                      </clr-button>
                      <clr-button type="button" class="btn btn-sm btn-secondary" (click)="onUnsignAll()">
                        <clr-icon shape="digital-signature"></clr-icon> Unsign all
                      </clr-button>
                      <clr-button [clrInMenu]="true" type="button" class="btn btn-sm btn-secondary"
                        (click)="onUnsetInvoicedAll()">
                        <clr-icon shape="envelope"></clr-icon> Unset invoiced all...
                      </clr-button>
                      <clr-button [clrInMenu]="true" type="button" class="btn btn-sm btn-secondary"
                        (click)="onSetInvoicedAll()">
                        <clr-icon shape="envelope"></clr-icon> Set invoiced all...
                      </clr-button>
                      <clr-button [clrInMenu]="true" type="button" class="btn btn-sm btn-outline-danger"
                        (click)="onDeleteAll()">
                        <clr-icon shape="trash"></clr-icon> Delete all...
                      </clr-button>
                      <clr-button [clrInMenu]="true" type="button" class="btn btn-sm btn-secondary"
                        (click)="exportData();"><clr-icon shape="export"></clr-icon> Export data
                      </clr-button>
                      <clr-button [clrInMenu]="true" type="button" class="btn btn-sm btn-secondary"
                        (click)="showImportDatafile();"><clr-icon shape="import"></clr-icon> Import data...
                      </clr-button>
                    </clr-button-group>

                    <div class="btn-group">
                      <button class="btn btn-sm btn-secondary" (click)="onViewInBMS();"
                        [disabled]="selectedExportDataValues.length != 1">View in BMS <clr-icon shape="arrow"
                          directions="left" style="transform: rotate(90deg);"></clr-icon></button>
                    </div>
                  </clr-dg-action-bar>

                  <clr-dg-column [clrDgField]="'externalId'" [clrDgSortBy]="externalIdSort" [clrDgSortOrder]="ascSort">
                    <ng-container *clrDgHideableColumn="{hidden: false}">External Id</ng-container>
                    <clr-dg-string-filter [clrDgStringFilter]="externalIdFilter"></clr-dg-string-filter>
                    <!-- <clr-dg-filter>
                      <app-data-export-externalid-filter [exportValues]="dataExportValues"></app-data-export-externalid-filter>
                    </clr-dg-filter> -->
                  </clr-dg-column>
                  <clr-dg-column [clrDgField]="'id'">
                    <ng-container *clrDgHideableColumn="{hidden: true}">Id</ng-container>
                  </clr-dg-column>
                  <clr-dg-column [clrDgField]="'meterId'">
                    <ng-container *clrDgHideableColumn="{hidden: true}">Meter Id</ng-container>
                    <clr-dg-string-filter [clrDgStringFilter]="meterIdFilter"></clr-dg-string-filter>
                  </clr-dg-column>
                  <clr-dg-column [clrDgField]="'name'">
                    <ng-container *clrDgHideableColumn="{hidden: true}">Name</ng-container>
                  </clr-dg-column>
                  <clr-dg-column [clrDgField]="'start'" class="dateColumn">
                    <ng-container *clrDgHideableColumn="{hidden: true}">Start</ng-container>
                  </clr-dg-column>
                  <clr-dg-column [clrDgField]="'end'" class="dateColumn">
                    <ng-container *clrDgHideableColumn="{hidden: true}">End</ng-container>
                  </clr-dg-column>
                  <clr-dg-column [clrDgField]="'svLantApartmentNo'">
                    <ng-container *clrDgHideableColumn="{hidden: false}">SV Lant Apartment No</ng-container>
                    <clr-dg-string-filter [clrDgStringFilter]="svLantFilter"></clr-dg-string-filter>
                  </clr-dg-column>
                  <clr-dg-column [clrDgField]="'startValue'">
                    <ng-container *clrDgHideableColumn="{hidden: false}">Start Value</ng-container>
                  </clr-dg-column>
                  <clr-dg-column [clrDgField]="'endValue'">
                    <ng-container *clrDgHideableColumn="{hidden: false}">End Value</ng-container>
                  </clr-dg-column>
                  <clr-dg-column [clrDgField]="'diff'">
                    <ng-container *clrDgHideableColumn="{hidden: false}">Diff</ng-container>
                    <clr-dg-numeric-filter [clrDgNumericFilter]="diffFilter"></clr-dg-numeric-filter>
                  </clr-dg-column>
                  <clr-dg-column [clrDgField]="'status'" class="statusColumn">
                    <ng-container *clrDgHideableColumn="{hidden: false}">Status</ng-container>
                    <clr-dg-filter>
                      <app-data-export-status-filter></app-data-export-status-filter>
                    </clr-dg-filter>
                  </clr-dg-column>
                  <clr-dg-column [clrDgField]="'meterType'">
                    <ng-container *clrDgHideableColumn="{hidden: false}">Meter Type</ng-container>
                  </clr-dg-column>
                  <clr-dg-column [clrDgField]="'comment'" class="commentColumn">
                    <ng-container *clrDgHideableColumn="{hidden: false}">Comment</ng-container>
                  </clr-dg-column>
                  <clr-dg-column [clrDgField]="'unit'">
                    <ng-container *clrDgHideableColumn="{hidden: false}">Unit</ng-container>
                  </clr-dg-column>
                  <clr-dg-column [clrDgField]="'tariff'">
                    <ng-container *clrDgHideableColumn="{hidden: false}">Tariff</ng-container>
                  </clr-dg-column>
                  <clr-dg-column [clrDgField]="'vatRate'">
                    <ng-container *clrDgHideableColumn="{hidden: false}">VAT Rate</ng-container>
                  </clr-dg-column>
                  <clr-dg-column [clrDgField]="'prefix'">
                    <ng-container *clrDgHideableColumn="{hidden: false}">Prefix</ng-container>
                  </clr-dg-column>
                  <clr-dg-column [clrDgField]="'createdAt'" class="dateColumn">
                    <ng-container *clrDgHideableColumn="{hidden: false}">Created At</ng-container>
                  </clr-dg-column>
                  <clr-dg-column [clrDgField]="'modifiedAt'" class="dateColumn">
                    <ng-container *clrDgHideableColumn="{hidden: false}">Modified At
                    </ng-container>
                  </clr-dg-column>

                  <clr-dg-row *clrDgItems="let dataExportValue of dataExportValues; let i = index"
                    [clrDgItem]="dataExportValue">
                    <clr-dg-cell>{{dataExportValue.externalId}}</clr-dg-cell>
                    <clr-dg-cell>{{dataExportValue.id}}</clr-dg-cell>
                    <clr-dg-cell>{{dataExportValue.meterId}}</clr-dg-cell>
                    <clr-dg-cell>{{dataExportValue.name}}</clr-dg-cell>
                    <clr-dg-cell>{{formatByString(dataExportValue.start, 'yyyy-MM-dd', true)}}</clr-dg-cell>
                    <clr-dg-cell>{{formatByString(dataExportValue.end, 'yyyy-MM-dd', true)}}</clr-dg-cell>
                    <clr-dg-cell>{{dataExportValue.svLantApartmentNo}}</clr-dg-cell>
                    <clr-dg-cell>{{dataExportValue.startValue
                      < 0 ? '---' : dataExportValue.startValue}}</clr-dg-cell>
                        <clr-dg-cell>{{dataExportValue.endValue
                          < 0 ? '---' : dataExportValue.endValue}}</clr-dg-cell>
                            <clr-dg-cell>{{dataExportValue.diff
                              <=- 9999999999 ? '---' : dataExportValue.diff}}</clr-dg-cell>
                                <clr-dg-cell>{{getDataExportValueStatusText(dataExportValue.status, 'OK')}}
                                </clr-dg-cell>
                                <clr-dg-cell>{{dataExportValue.meterType}}</clr-dg-cell>
                                <clr-dg-cell>{{dataExportValue.comment}}</clr-dg-cell>
                                <clr-dg-cell>{{dataExportValue.unit}}</clr-dg-cell>
                                <clr-dg-cell>{{dataExportValue.tariff}}</clr-dg-cell>
                                <clr-dg-cell>{{dataExportValue.vatRate}}</clr-dg-cell>
                                <clr-dg-cell>{{dataExportValue.prefix}}</clr-dg-cell>

                                <clr-dg-cell>{{formatByString(dataExportValue.createdAt, 'yy-MM-dd HH:mm')}}
                                </clr-dg-cell>
                                <clr-dg-cell>{{formatByString(dataExportValue.modifiedAt, 'yy-MM-dd HH:mm')}}
                                </clr-dg-cell>
                  </clr-dg-row>

                  <ng-template [(clrIfDetail)]="gridDetailState" let-dataIndex
                    (clrIfDetailChange)="onGridDetailChange($event)">
                    <clr-dg-detail>
                      <clr-dg-detail-header>Meter data</clr-dg-detail-header>
                      <clr-dg-detail-body>
                        <div class="meterdata-content-area">
                          <xproj-dashboard
                            *ngIf="!dashboardGridDetailManualDataValue && dashboardGridDetailDataExportValue.unit == 'kWh'"
                            #dashboardGridDetail [editMode]="false" [showDashboardSettings]="false"
                            [dashboardId]="'bms_meter_invoice_kwh'" [enableExport]="false"
                            [responsiveWidth]="responsiveWidth"
                            [dashboardOutputParameters]="dashboardGridDetailOutputParameters"></xproj-dashboard>
                          <xproj-dashboard
                            *ngIf="!dashboardGridDetailManualDataValue && dashboardGridDetailDataExportValue.unit != 'kWh'"
                            #dashboardGridDetail [editMode]="false" [showDashboardSettings]="false"
                            [dashboardId]="'bms_meter_invoice'" [enableExport]="false"
                            [responsiveWidth]="responsiveWidth"
                            [dashboardOutputParameters]="dashboardGridDetailOutputParameters"></xproj-dashboard>

                          <p *ngIf="dashboardGridDetailManualDataValue">Manual edit value, no meter reference.</p>
                        </div>
                      </clr-dg-detail-body>
                    </clr-dg-detail>
                  </ng-template>

                  <clr-dg-footer>
                    <clr-dg-pagination #pagination [clrDgPageSize]="20">
                      <clr-dg-page-size [clrPageSizeOptions]="[10,20,50,100,200]">Rows per page</clr-dg-page-size>
                      {{pagination.firstItem + 1}} - {{pagination.lastItem + 1}} of {{pagination.totalItems}} rows
                    </clr-dg-pagination>
                  </clr-dg-footer>
                </clr-datagrid>
              </clr-tab-content>
            </div>
          </ng-template>
        </clr-tab>
      </clr-tabs>
    </div>
  </as-split-area>
  <as-split-area [(size)]="rightPanelWidth" [order]="2">
    <clr-datagrid [(clrDgSingleSelected)]="selectedTrustee" [clrDgLoading]="loading"
      (clrDgSingleSelectedChange)="selectedTrusteeChanged($event)">

      <clr-dg-column [clrDgField]="'name'" [clrDgSortOrder]="ascSort">Trustee</clr-dg-column>

      <clr-dg-row *clrDgItems="let trustee of trustees" [clrDgItem]="trustee">
        <clr-dg-cell class="sensitive"> {{trustee.name}}
        </clr-dg-cell>
      </clr-dg-row>

    </clr-datagrid>
  </as-split-area>
</as-split>
