import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Inject, Injectable, OnDestroy, OnInit } from '@angular/core';
import { StateService } from '@xprojectorcore/services/state-service';
import { XProjectBackendSettings, XPROJECTORBACKENDCLIENTSETTINGS } from '@xprojectorcore/xprojector_backend/xprojector-backend-settings-service';
import { XProjectorSysAdminClient } from '@xprojectorcore/xprojector_backend/xprojector-sysadmin-client';
import { NGXLogger, NgxLoggerLevel } from 'ngx-logger';
import { BmsMasterDataImportResult } from '@features/bms/models/bms-master-data-import-result';
import { BmsImportApartmentCustomerUsersResult } from '@features/bms/models/bms-apartment-customerusers-import-result';
import { BmsLoRaWANProvsionDevicesResult } from '@features/bms/models/bms-lorwan-provisiondevices-result';
import { BmsDataCollectorView } from '../models/bms-data-collector-view';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})
export class BmsStateService implements OnDestroy {

  private dataCollectorViews: BmsDataCollectorView[] = [];

  public constructor(
    private logger: NGXLogger,
    private sysAdminClient: XProjectorSysAdminClient,
    @Inject(XPROJECTORBACKENDCLIENTSETTINGS) private settings: XProjectBackendSettings,
    private state: StateService) {

      this.state.currentLogLevel = (!environment.production && !environment.logoff) ? NgxLoggerLevel.DEBUG : NgxLoggerLevel.OFF;
      this.state.currentLogLevelServer = NgxLoggerLevel.OFF;
  }

  ngOnDestroy(): void {

  }

  async importMasterDataFiles(overwrite: boolean, onlymeters : boolean, files: any[]): Promise<BmsMasterDataImportResult> {
    let formData = new FormData();
    formData.append('overwrite', overwrite ? 'true' : 'false');
    formData.append('onlymeters', onlymeters ? 'true' : 'false');
    try {
      files.forEach(file => {
        formData.append('file', file, 'dummy');
      });

    }
    catch {
    }

    let httpOptions = {
      headers: new HttpHeaders({
        //'Content-Type': 'multipart/form-data',
        'Access-Control-Allow-Origin': '*',
      }),
      withCredentials: true
    }

    return this.state.http.post<BmsMasterDataImportResult>(this.settings.host + '/api/v1/bms/import/masterdatafile', formData, httpOptions).toPromise();
  }

  async importMasterDataFromXdb(overwrite: boolean, orgNoFilter: string[] = []): Promise<BmsMasterDataImportResult> {
    let httpOptions = {
      headers: new HttpHeaders({
        //'Content-Type': 'multipart/form-data',
        'Access-Control-Allow-Origin': '*',
      }),
      withCredentials: true
    }
    let url = this.settings.host + '/api/v1/bms/import/xdb';
    return this.state.http.post<BmsMasterDataImportResult>(url, { overwrite: overwrite, orgNoFilter: orgNoFilter }, httpOptions).toPromise();
  }

  async importMeterDataFile(file: any, customerId : string): Promise<BmsMasterDataImportResult> {
    let formData = new FormData();
    formData.append('customerid', customerId);
    try {
        formData.append('file', file, 'dummy');
    }
    catch {
    }

    let httpOptions = {
      headers: new HttpHeaders({
        //'Content-Type': 'multipart/form-data',
        'Access-Control-Allow-Origin': '*',
      }),
      withCredentials: true
    }

    return this.state.http.post<BmsMasterDataImportResult>(this.settings.host + '/api/v1/bms/import/meterexportfile', formData, httpOptions).toPromise();
  }

  async imporFile(file: any, importId : string, importTypeId : string, nodeId :string, nodeLabel : string, customerId : string): Promise<boolean> {
    let formData = new FormData();
    formData.append('customerid', customerId);
    formData.append('importid', importId);
    formData.append('importtypeid', importTypeId);
    formData.append('nodeid', nodeId);
    formData.append('nodelabel', nodeLabel);
    try {
        formData.append('file', file, 'dummy');
    }
    catch {
    }

    let httpOptions = {
      headers: new HttpHeaders({
        //'Content-Type': 'multipart/form-data',
        'Access-Control-Allow-Origin': '*',
      }),
      withCredentials: true
    }

    return this.state.http.post<boolean>(this.settings.host + '/api/v1/bms/import/modulefile', formData, httpOptions).toPromise();
  }

  async importApartmentDataFile(file: any, customerId : string): Promise<BmsMasterDataImportResult> {
    let formData = new FormData();
    formData.append('customerid', customerId);
    try {
        formData.append('file', file, 'dummy');
    }
    catch {
    }

    let httpOptions = {
      headers: new HttpHeaders({
        //'Content-Type': 'multipart/form-data',
        'Access-Control-Allow-Origin': '*',
      }),
      withCredentials: true
    }

    return this.state.http.post<BmsMasterDataImportResult>(this.settings.host + '/api/v1/bms/import/apartmentexportfile', formData, httpOptions).toPromise();
  }

  async importMeterExportAddressesFile(file: any, customerId : string): Promise<BmsMasterDataImportResult> {
    let formData = new FormData();
    formData.append('customerid', customerId);
    try {
        formData.append('file', file, 'dummy');
    }
    catch {
    }

    let httpOptions = {
      headers: new HttpHeaders({
        //'Content-Type': 'multipart/form-data',
        'Access-Control-Allow-Origin': '*',
      }),
      withCredentials: true
    }

    return this.state.http.post<BmsMasterDataImportResult>(this.settings.host + '/api/v1/bms/import/meterexportaddressesexportfile', formData, httpOptions).toPromise();
  }

  async importDataFile(file: any, customerId : string, meterType : string, periodStart : string, periodEnd : string): Promise<boolean> {
    let formData = new FormData();
    formData.append('customerid', customerId);
    formData.append('metertype', meterType);
    formData.append('periodstart', periodStart);
    formData.append('periodend', periodEnd);
    try {
        formData.append('file', file, 'dummy');
    }
    catch {
    }

    let httpOptions = {
      headers: new HttpHeaders({
        //'Content-Type': 'multipart/form-data',
        'Access-Control-Allow-Origin': '*',
      }),
      withCredentials: true
    }

    return this.state.http.post<boolean>(this.settings.host + '/api/v1/bms/import/dataexportfile', formData, httpOptions).toPromise();
  }

  async importViperDataFile(file: any, filename : string, customerId : string): Promise<number> {
    let formData = new FormData();
    formData.append('customerid', customerId);
    try {
        formData.append('file', file, filename);
    }
    catch {
    }

    let httpOptions = {
      headers: new HttpHeaders({
        //'Content-Type': 'multipart/form-data',
        'Access-Control-Allow-Origin': '*',
      }),
      withCredentials: true
    }

    return this.state.http.post<number>(this.settings.host + '/api/v1/bms/import/viperfile', formData, httpOptions).toPromise();
  }

  async importApartmentCustomerUsersFile(file: any, customerId : string): Promise<BmsImportApartmentCustomerUsersResult> {
    let formData = new FormData();
    formData.append('customerid', customerId);
    try {
        formData.append('file', file, 'dummy');
    }
    catch {
    }

    let httpOptions = {
      headers: new HttpHeaders({
        //'Content-Type': 'multipart/form-data',
        'Access-Control-Allow-Origin': '*',
      }),
      withCredentials: true
    }

    return this.state.http.post<BmsImportApartmentCustomerUsersResult>(this.settings.host + '/api/v1/bms/import/apartmentsandcustomerusers', formData, httpOptions).toPromise();
  }

  async provisionLoRaWANDevicesFile(file: any, operatorId : string, applicationId : string): Promise<BmsLoRaWANProvsionDevicesResult> {
    let formData = new FormData();
    formData.append('operatorid', operatorId);
    formData.append('applicationId', applicationId);
    try {
        formData.append('file', file, 'dummy');
    }
    catch {
    }

    let httpOptions = {
      headers: new HttpHeaders({
        //'Content-Type': 'multipart/form-data',
        'Access-Control-Allow-Origin': '*',
      }),
      withCredentials: true
    }

    return this.state.http.post<BmsLoRaWANProvsionDevicesResult>(this.settings.host + '/api/v1/bmslorawan/devices/provision', formData, httpOptions).toPromise();
  }


  addDataCollectorView(page: BmsDataCollectorView) {
    if (this.dataCollectorViews.findIndex(p => p.id == page.id)) {
      this.dataCollectorViews.push(page);
    }
  }

  getDataCollectoViews(): BmsDataCollectorView[] {
    return this.dataCollectorViews;
  }

}
