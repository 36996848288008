import { Component, OnInit, inject } from '@angular/core';
import { ClrDatagridFilter, ClrDatagridStringFilterInterface } from '@clr/angular';
import { Subject } from 'rxjs';
import { LOGGERSERVICE, XprojLoggerService } from 'xproj-lib';
import { BmsEventStatus, BmsCustomerEventsEvent } from '../../components/bms-gateway-admin/bms-gateway-events/bms-gateway-events.component';

@Component({
  selector: 'app-event-status-filter',
  templateUrl: './event-status-filter.component.html',
  styleUrls: ['./event-status-filter.component.scss']
})
export class EventStatusFilterComponent implements OnInit, ClrDatagridStringFilterInterface<BmsCustomerEventsEvent> {

  private logger: XprojLoggerService = inject(LOGGERSERVICE);

  private _new : string = 'New';
  private _open : string = 'Open';
  private _closed : string = 'Closed';
  private _muted : string = 'Muted';

  private statusFiltersAll = BmsEventStatus.New | BmsEventStatus.Open | BmsEventStatus.Closed;

  private statusFilters : number = BmsEventStatus.New | BmsEventStatus.Open;
  private statusFilterOk : boolean = true;

  changes = new Subject<any>();

  statusList : {text : string, status : boolean, flag : BmsEventStatus}[] = [
    {
      text : this._new,
      status : true,
      flag : BmsEventStatus.New
    },
    {
      text : this._open,
      status : true,
      flag : BmsEventStatus.Open
    },
    {
      text : this._closed,
      status : true,
      flag : BmsEventStatus.Closed
    }
  ];

  constructor(private filterContainer: ClrDatagridFilter) {
    filterContainer.setFilter(this);
  }

  change(): void {
    this.changes.next(true);
  }

  isActive(): boolean {
     return true;
  }

  accepts(item: BmsCustomerEventsEvent): boolean {
    return (this.statusFilterOk && ((item.status & this.statusFilters) > 0));
  }

  ngOnInit() {
  }

  statusChanged(checked : boolean, flag : BmsEventStatus) {
    if (checked) {
      this.statusFilters |= flag;
      this.statusFilterOk = true;
    }
    else {
      this.statusFilters &= ~flag;
      if (flag == BmsEventStatus.None) {
        this.statusFilterOk = false;
      }
    }
    this.change();
  }

  none() {
    this.statusList.forEach(x => x.status = false);
    this.statusFilters = 0;
    this.statusFilterOk = false;
    this.change();
  }

  all() {
    this.statusList.forEach(x => x.status = true);
    this.statusFilters = this.statusFiltersAll;
    this.statusFilterOk = true;
    this.change();
  }
}
