import { Component, ComponentFactoryResolver, ComponentRef, Input, OnDestroy, OnInit, ViewChild, ViewEncapsulation } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { BmsDataCollectorView } from '@core/models/bms-data-collector-view';
import { BmsDataCollectorViewData } from '@core/models/bms-data-collector-view-data';
import { BmsStateService } from '@core/services/bms-state-service';
import { BmsDataCollectorComponent } from '@features/bms/bms-admin/bms-data-collector-view.component';
import { BmsDataCollectorViewDirective } from '@features/bms/bms-admin/directives/bms-data-collector-view.directive';
import { NGXLogger } from 'ngx-logger';
import { Subject } from 'rxjs';
import { BmsCustomer } from '@features/bms/models/bms-customer';
import { Customer, GrpcNode } from '@xprojectorcore/xprojector_backend/proto/xprojector.grpc.models.pb';
import { takeUntil } from 'rxjs/operators';

@Component({
  selector: 'app-bms-data-collector-view',
  templateUrl: './bms-data-collector-view.component.html',
  styleUrls: ['./bms-data-collector-view.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class BmsDataCollectorViewComponent implements OnInit, OnDestroy {

  @ViewChild(BmsDataCollectorViewDirective, { static: true }) dataCollectorViewHost!: BmsDataCollectorViewDirective;

  // @Input() dataCollectorId: string = '';
  // @Input() nodeId: string = '';

  ngUnsubscribe = new Subject<void>();

  dataCollectorViews: BmsDataCollectorView[] = [];

  componentRef : ComponentRef<BmsDataCollectorComponent>;

  selectedNode: GrpcNode;
  areaWidth : number;

  selectedBmsCustomer: BmsCustomer;
  selectedCustomer: Customer;

  constructor(
    public bmsState: BmsStateService,
    private route: ActivatedRoute,
    private logger: NGXLogger,
    private componentFactoryResolver: ComponentFactoryResolver
  ) { }

  ngOnInit(): void {

    this.loadComponent();
  }

  ngOnDestroy(): void {
    this.ngUnsubscribe.next();
    this.ngUnsubscribe.complete();
  }


  async setDataNode(selectedDataCollectorsTreeNode: GrpcNode, width : number, bmsCustomer : BmsCustomer, customer : Customer) {
    this.selectedBmsCustomer = bmsCustomer;
    this.selectedCustomer = customer;
    this.areaWidth = width;
    this.selectedNode = selectedDataCollectorsTreeNode;
    await this.loadComponent();
  }

  setWidth(width : number) {
    if (this.componentRef) {
      this.componentRef.instance.setWidth(width);
    }
  }

  async loadComponent() {
    if (this.dataCollectorViews.length == 0) {
      this.dataCollectorViews = this.bmsState.getDataCollectoViews();
    }

    if (this.selectedNode) {
      let dataCollectorView = this.dataCollectorViews.find(p => p.id == this.selectedNode.nodeTypeId);

      let viewContainerRef = this.dataCollectorViewHost.viewContainerRef;
      viewContainerRef.clear();

      if (!dataCollectorView) {
        dataCollectorView = this.dataCollectorViews.find(p => p.id == this.selectedNode.nodeTypeLabel);
      }

      if (dataCollectorView) {
        this.componentRef = viewContainerRef.createComponent<BmsDataCollectorComponent>(dataCollectorView.component);
        let data = new BmsDataCollectorViewData();
        data.node = this.selectedNode;
        data.customer = this.selectedCustomer;
        data.bmsCustomer = this.selectedBmsCustomer;
        data.width = this.areaWidth;
        this.componentRef.instance.visible = true;
        this.componentRef.instance.data = data;

        await this.componentRef.instance.initDataCollectorView();
      }
    }
  }
}
