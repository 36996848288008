import { ExportClient } from './proto/xprojector.modulebms.export.pbsc';
import { Injectable, OnDestroy, OnInit } from '@angular/core';
import { StateService } from '@xprojectorcore/services/state-service';
import { GetMeteringApiFileRequest, GetExtrapolatedValueRequest, GetExtrapolatedValueResponse, GetExportInfoRequest, GetExportInfoResponse, DataChunk, CreateMeterExportFileRequest, CreateApartmentAndFacilityExportFileRequest, TouchMetersRequest, AssertCountyAndDistrictRequest, TouchApartmentsAndFacilitiesRequest, AddDatapointValueRequest, ExportGatewayConfigRequest, ExportDataExportValuesRequest, CreateMeterExportAddressesExportFileRequest } from './proto/xprojector.modulebms.export.pb';
import { Timestamp } from './proto/google/protobuf/timestamp.pb';
import { BmsBillingPeriod } from '../models/bms-export-bot';
import { saveAs } from 'file-saver';
import { DateHelper } from 'xproj-lib';

@Injectable({
  providedIn: 'root'
})
export class XProjectorBmsExportClient implements OnInit, OnDestroy {

  constructor(
    private exportClient: ExportClient,
    private state: StateService,
    private dateHelper: DateHelper) {
  }

  ngOnDestroy(): void {
  }

  ngOnInit(): void {

  }

  async touchMeters(customerId: string, meterType: string = '') {
    let request: TouchMetersRequest = new TouchMetersRequest({
      customerId: customerId,
      meterType: meterType
    });

    return await this.exportClient.touchMeters(request, this.state.metadata).toPromise();
  }

  async touchApartmentsAndFacilities(customerId: string) {
    let request: TouchApartmentsAndFacilitiesRequest = new TouchApartmentsAndFacilitiesRequest({
      customerId: customerId
    });

    return await this.exportClient.touchApartmentsAndFacilities(request, this.state.metadata).toPromise();
  }


  async getExtrapolatedValue(customerId: string, meterId: number, periodStart: Date, periodEnd: Date, extrapolateFrom: Date, extrapolateTo: Date, forceLastPeriodEndValue : boolean = false): Promise<GetExtrapolatedValueResponse> {
    let request: GetExtrapolatedValueRequest = new GetExtrapolatedValueRequest({
      customerId: customerId,
      meterId: meterId + '',
      periodStart: Timestamp.fromDate(periodStart),
      periodEnd: Timestamp.fromDate(periodEnd),
      extrapolateFrom: Timestamp.fromDate(extrapolateFrom),
      extrapolateTo: Timestamp.fromDate(extrapolateTo),
      forceLastPeriodEndValue: forceLastPeriodEndValue
    });

    return await this.exportClient.getExtrapolatedValue(request, this.state.metadata).toPromise();
  }

  async getExportInfo(billingPeriod: BmsBillingPeriod, periodStart: Date, periodEnd: Date, invoiceDataAfter : number = 0, onlyInvoiceInfo : boolean = false): Promise<GetExportInfoResponse> {
    let request: GetExportInfoRequest = new GetExportInfoRequest({
      billingPeriod: GetExportInfoRequest.BillingPeriods[GetExportInfoRequest.BillingPeriods[billingPeriod]],
      periodStart: Timestamp.fromDate(periodStart),
      periodEnd: Timestamp.fromDate(periodEnd),
      invoiceDayAfter: invoiceDataAfter,
      onlyInvoiceInfo: onlyInvoiceInfo
    });

    return await this.exportClient.getExportInfo(request, this.state.metadata).toPromise();
  }

  async createMeterExportFile(customerId: string, customerName: string): Promise<boolean> {
    return new Promise((resolve, reject) => {
      let request: CreateMeterExportFileRequest = new CreateMeterExportFileRequest({
        customerId: customerId
      });

      let dataChunks: Uint8Array[] = [];
      let i: number = 0;

      this.exportClient.createMeterExportFile(request, this.state.metadata).subscribe(
        ((event: any) => {
          //console.log('event', event);

          let datachunk: Uint8Array = event.data;
          if (datachunk) {
            dataChunks.push(datachunk);
          }
        }),
        ((error) => {
          //console.log('error', error);
          reject(error);
        }),
        (() => {
          if (dataChunks.length > 0) {
            //console.log('complete', data);
            var blob = new Blob(dataChunks);
            saveAs(blob, customerName + "_meters.xlsx");

            resolve(true);
          }
          else {
            resolve(false);
          }
        })

      );
    });
  }

  async createApartmentAndFacilityExportFile(customerId: string, customerName: string): Promise<boolean> {
    return new Promise((resolve, reject) => {
      let request: CreateApartmentAndFacilityExportFileRequest = new CreateApartmentAndFacilityExportFileRequest({
        customerId: customerId
      });

      let dataChunks: Uint8Array[] = [];
      let i: number = 0;

      this.exportClient.createApartmentAndFacilityExportFile(request, this.state.metadata).subscribe(
        ((event: any) => {
          //console.log('event', event);

          let datachunk: Uint8Array = event.data;
          if (datachunk) {
            dataChunks.push(datachunk);
          }
        }),
        ((error) => {
          //console.log('error', error);
          reject(error);
        }),
        (() => {
          if (dataChunks.length > 0) {
            //console.log('complete', data);
            var blob = new Blob(dataChunks);
            saveAs(blob, customerName + "_apartments.xlsx");

            resolve(true);
          }
          else {
            resolve(false);
          }
        })

      );
    });
  }

  async assertCountyAndDistrict(customerId: string, customerName: string, county : string, district : string ) : Promise<boolean> {
    let request: AssertCountyAndDistrictRequest = new AssertCountyAndDistrictRequest({
      customerName : customerName,
      customerId: customerId,
      county: county,
      district : district
    });

    let result = await this.exportClient.assertCountyAndDistrict(request, this.state.metadata).toPromise();

    return result.ok;
  }

  async getMeteringApiFile(customerId: string, customerName: string): Promise<boolean> {
    return new Promise((resolve, reject) => {
      let request: GetMeteringApiFileRequest = new GetMeteringApiFileRequest({
        customerId: customerId
      });

      let dataChunks: Uint8Array[] = [];
      let i: number = 0;

      this.exportClient.getMeteringApiFile(request, this.state.metadata).subscribe(
        ((event: any) => {
          //console.log('event', event);

          let datachunk: Uint8Array = event.data;
          if (datachunk) {
            dataChunks.push(datachunk);
          }
        }),
        ((error) => {
          //console.log('error', error);
          reject(error);
        }),
        (() => {
          if (dataChunks.length > 0) {
            //console.log('complete', data);
            var blob = new Blob(dataChunks);
            saveAs(blob, customerName + "_MeteringApiReport.xlsx");

            resolve(true);
          }
          else {
            resolve(false);
          }
        })

      );
    });
  }

  async addDatapointValue(meterId : string, timestamp : Date, value : number) {
    let request: AddDatapointValueRequest = new AddDatapointValueRequest({
      meterId: meterId,
      timestamp: Timestamp.fromISOString(timestamp.toISOString()),
      value : value
    });

    return await this.exportClient.addDatapointValue(request, this.state.metadata).toPromise();
  }

  async exportGatewayConfig(customerId: string, customerName: string, gatewayId : number, configTypeId : string): Promise<boolean> {
    return new Promise((resolve, reject) => {
      let request: ExportGatewayConfigRequest = new ExportGatewayConfigRequest({
        customerId: customerId,
        gatewayId: gatewayId + '',
        configTypeId: configTypeId
      });

      let dataChunks: Uint8Array[] = [];
      let i: number = 0;

      this.exportClient.exportGatewayConfig(request, this.state.metadata).subscribe(
        ((event: any) => {
          //console.log('event', event);

          let datachunk: Uint8Array = event.data;
          if (datachunk) {
            dataChunks.push(datachunk);
          }
        }),
        ((error) => {
          //console.log('error', error);
          reject(error);
        }),
        (() => {
          if (dataChunks.length > 0) {
            //console.log('complete', data);
            var blob = new Blob(dataChunks);
            saveAs(blob, customerName + '_' + gatewayId + '.zip');

            resolve(true);
          }
          else {
            resolve(false);
          }
        })

      );
    });
  }

  async exportDataExportValues(customerId: string, customerName: string, periodStart : Date, periodEnd : Date, meterType : string): Promise<boolean> {
    return new Promise((resolve, reject) => {
      let request: ExportDataExportValuesRequest = new ExportDataExportValuesRequest({
        customerId: customerId,
        meterType: meterType,
        periodStart: Timestamp.fromDate(periodStart),
        periodEnd: Timestamp.fromDate(periodEnd)
      });

      let dataChunks: Uint8Array[] = [];
      let i: number = 0;

      this.exportClient.exportDataExportValues(request, this.state.metadata).subscribe(
        ((event: any) => {
          //console.log('event', event);

          let datachunk: Uint8Array = event.data;
          if (datachunk) {
            dataChunks.push(datachunk);
          }
        }),
        ((error) => {
          //console.log('error', error);
          reject(error);
        }),
        (() => {
          if (dataChunks.length > 0) {
            //console.log('complete', data);
            var blob = new Blob(dataChunks);
            saveAs(blob, customerName + "_" + meterType + "_" + this.dateHelper.utils.formatByString(periodStart, 'yyyyMMdd')
                  + "_" + this.dateHelper.utils.formatByString(periodEnd, 'yyyyMMdd') + ".xlsx");

            resolve(true);
          }
          else {
            resolve(false);
          }
        })

      );
    });
  }

  async createMeterExportAddressesExportFile(customerId: string, customerName: string): Promise<boolean> {
    return new Promise((resolve, reject) => {
      let request: CreateMeterExportAddressesExportFileRequest = new CreateMeterExportAddressesExportFileRequest({
        customerId: customerId
      });

      let dataChunks: Uint8Array[] = [];
      let i: number = 0;

      this.exportClient.createMeterExportAddressesExportFile(request, this.state.metadata).subscribe(
        ((event: any) => {
          //console.log('event', event);

          let datachunk: Uint8Array = event.data;
          if (datachunk) {
            dataChunks.push(datachunk);
          }
        }),
        ((error) => {
          //console.log('error', error);
          reject(error);
        }),
        (() => {
          if (dataChunks.length > 0) {
            //console.log('complete', data);
            var blob = new Blob(dataChunks);
            saveAs(blob, customerName + "_meterexportaddresses.xlsx");

            resolve(true);
          }
          else {
            resolve(false);
          }
        })

      );
    });
  }
}
