import { AfterViewInit, ChangeDetectorRef, Component, Input, OnInit } from '@angular/core';
import { NGXLogger } from 'ngx-logger';
import { BmsDataExportService } from '@features/bms/bms-admin/services/bms-data-export-service';
import { XprojAlertService, DateHelper } from 'xproj-lib';
import { StateService } from '@xprojectorcore/services/state-service';
import { XProjectorFilesClient } from '@xprojectorcore/xprojector_backend/xprojector-files-client';
import { XProjectorXConfClient } from '@xprojectorcore/xprojector_backend/xprojector-xconf-client';
import JSZip from 'jszip';
import { saveAs } from 'file-saver';
import { FileInfo } from '@xprojectorcore/models/file-info';
import { GrpcNode } from '@xprojectorcore/xprojector_backend/proto/xprojector.grpc.models.pb';
import { BmsTrustee } from '@features/bms/models/bms-trustee';
import { ClrDatagridSortOrder } from '@clr/angular';
import { BmsCustomerConfig } from '@features/bms/models/bms-customer-config';
import { BmsBillingPeriod } from '@core/models/bms-export-bot';

class CustomerInfo {
  customerId: string;
  customerName: string;
}

@Component({
  selector: 'app-bms-export-files-view',
  templateUrl: './bms-export-files-view.component.html',
  styleUrls: ['./bms-export-files-view.component.scss']
})
export class BmsExportFilesViewComponent implements OnInit, AfterViewInit {
  @Input() trusteeId: string = '';
  @Input() trustees: BmsTrustee[] = [];
  @Input() exportPeriods: { start: Date, end: Date }[] = [];
  @Input() billingPeriod : BmsBillingPeriod = BmsBillingPeriod.Month;
  @Input() customerConfigs : Map<string, BmsCustomerConfig>;

  exportedFileInfos: FileInfo[] = [];
  selectedExportFileInfos: FileInfo[] = [];

  files: FileInfo[] = [];

  selectedTrustee: BmsTrustee = null;
  selectedTrusteeCustomers: GrpcNode[] = [];
  customerInfos: CustomerInfo[] = [];
  customerInfo: CustomerInfo;
  selectedExportPeriodIndex: number;
  selectedExportPeriod: { start: Date, end: Date };

  ascSort = ClrDatagridSortOrder.ASC;
  descSort = ClrDatagridSortOrder.DESC;

  constructor(
    private state: StateService,
    private alertService: XprojAlertService,
    private xConfClient: XProjectorXConfClient,
    private xProjectorFilesClient: XProjectorFilesClient,
    private dataExportService: BmsDataExportService,
    private cdr: ChangeDetectorRef,
    private logger: NGXLogger,
    private dateHelper: DateHelper

  ) { }


  async ngOnInit() {

  }

  async ngAfterViewInit() {
    if (this.trustees.length > 0) {
      if (this.trusteeId?.length > 0) {
        this.selectedTrustee = this.trustees.find(t => t.id == this.trusteeId);
      }
      if (!this.selectedTrustee) {
        this.selectedTrustee = this.trustees[0];
      }

      await this.updateSelectedCustomers();
      await this.updateExportedFiles();

      this.exportPeriods = [{ start: undefined, end: undefined }].concat(this.exportPeriods);

      this.selectedExportPeriodIndex = 0;
      this.selectedExportPeriod = this.exportPeriods[0];
    }
  }

  async omDownloadExportedFiles(downloadAll : boolean) {
    if (!downloadAll && this.selectedExportFileInfos.length == 1) {
      this.xProjectorFilesClient.getFile(this.selectedExportFileInfos[0].id, this.selectedTrustee.id)
        .then(blob => {
          saveAs(blob, this.selectedExportFileInfos[0].name);
        })
        .catch(error => {
          this.alertService.error(error);
        });
    }
    else {
      let zip = new JSZip();
      let name = '';
      if (this.customerInfo && this.customerInfo.customerId.length > 0) {
        name = this.customerInfo.customerName;
      }
      else if (this.selectedTrustee) {
        name = this.selectedTrustee.name;
      }
      else {
        name = 'files';
      }

      if (this.selectedExportPeriod && this.selectedExportPeriod.start != undefined) {
        name = name + '_' + this.formatByString(this.selectedExportPeriod.start, 'yyyy-MM-dd') + '_' + this.formatByString(this.selectedExportPeriod.end, 'yyyy-MM-dd');
      }

      name = name + '.zip';
      let fileInfos : FileInfo[] = downloadAll ? this.exportedFileInfos : this.selectedExportFileInfos;
      for (let i = 0; i < fileInfos.length; i++) {
        let fileInfo = fileInfos[i];
        let fileData: any = await this.xProjectorFilesClient.getFile(fileInfo.id, this.selectedTrustee.id);
        let b: any = new Blob([fileData], { type: '' + fileData.type + '' });
        zip.file(fileInfo.name, b);
      }

      zip.generateAsync({ type: 'blob' }).then((content) => {
        if (content) {
          saveAs(content, name);
        }
      });
    }
  }

  async updateSelectedCustomers() {
    this.selectedTrusteeCustomers = [];
    if (this.selectedTrustee) {
      this.selectedTrusteeCustomers = await this.xConfClient.getReferencedNodes(
        '_x_bms_trustee_root_' + this.selectedTrustee.id,
        '_x_bms_trustee_root',
        [],
        '_x_datasource'
      );

      this.initCustomerStatusInfo();
    }
  }

  async initCustomerStatusInfo() {
    if (this.selectedTrusteeCustomers) {
      this.customerInfos = [{
        customerId: '',
        customerName: 'All customers',
      }];
      this.selectedTrusteeCustomers.forEach(customer => {
        let customerConfig = this.customerConfigs?.get(customer.id);
        if (!customerConfig || customerConfig.billingPeriod == this.billingPeriod) {
          this.customerInfos.push({
            customerId: customer.id,
            customerName: customer.name
          });
        }
      });
      this.customerInfo = this.customerInfos[0];
    }
  }

  async updateExportedFiles() {
    this.selectedExportFileInfos = [];
    if (this.selectedTrustee) {
      if (this.files.length == 0) {
        let fileInfos = await this.xProjectorFilesClient.getFileInfos(['bmsexport'], 200, 0, this.selectedTrustee.id);
        this.files = fileInfos.files;
      }

      this.exportedFileInfos = this.files.filter(info => info.tags.includes('bmsexport'));
      if (this.customerInfo && this.customerInfo.customerId.length > 0) {
        this.exportedFileInfos = this.exportedFileInfos.filter(info => info.tags.includes('customerid:' + this.customerInfo.customerId));
      }

      if (this.selectedExportPeriod && this.selectedExportPeriod.start != undefined) {
        let start = this.formatByString(this.selectedExportPeriod.start, 'yyyy-MM-dd');
        let end = this.formatByString(this.selectedExportPeriod.end, 'yyyy-MM-dd');
        this.exportedFileInfos = this.exportedFileInfos.filter(info => info.tags.includes('start:' + start) && info.tags.includes('end:' + end));
      }
    }
  }

  selectedExportFileInfoChanged(fileInfo: FileInfo) {

  }

  selectedCustomerChange(info: CustomerInfo) {
    this.updateExportedFiles();
  }

  selectedExportPeriodChange(index: number) {
    if (this.exportPeriods && index < this.exportPeriods.length) {
      this.selectedExportPeriod = this.exportPeriods[index];
    }
    this.updateExportedFiles();
  }

  formatDate(date: Date, displayUTC: boolean = false): string {
    return this.dateHelper.utils.formatByString(displayUTC ? this.dateHelper.utils.addMinutes(date, -date.getTimezoneOffset()) : date, "yyyy-MM-dd HH:mm:ss");
  }

  formatByString(date: Date, format: string, displayUTC: boolean = false): string {
    if (date) {
      let d = displayUTC ? this.dateHelper.utils.addMinutes(date, date.getTimezoneOffset()) : date;
      return this.dateHelper.utils.formatByString(d, format);
    }
    else {
      return '';
    }
  }



}
