<div class="customers-content-area">
  <clr-tabs>
    <clr-tab>
      <button clrTabLink>Execute</button>
      <ng-template [(clrIfActive)]="execActive">
        <clr-tab-content>
          <h3 *ngIf="fileInfos?.length > 0" class="output-file">Latest files</h3>
          <table *ngFor="let fileInfo of fileInfos" class="table table-compact table-vertical output-file">
            <tbody>
              <tr>
                <th class="left" style="width: 100px;">Output file</th>
                <td class="left">{{fileInfo.name}}</td>
                <td style="width: 5px;" class="right">
                  <clr-icon shape="download-cloud" style="cursor: pointer"
                    (click)="downloadFile(fileInfo)"></clr-icon>
                </td>
              </tr>
              <tr>
                <th class="left" style="width: 100px;">Time</th>
                <td class="left">{{fileInfo ? dateHelper.utils.format(fileInfo.lastModified,
                  'keyboardDateTime')
                  :
                  '---'}}</td>
                <td style="width: 5px;" class="right"></td>
              </tr>
            </tbody>
          </table>

          <div class="execute-bot">
            <button type="button" class="btn btn-primary" (click)="executeBot(true)" [clrLoading]="botRunning">
              <clr-icon shape="play"></clr-icon>
              Execute...
            </button>
            <label style="margin-left: 1em;">{{execStatusMessage}}</label>
          </div>

          <div class="execute-bot">
            <button type="button" class="btn" (click)="executeBot(false)" [clrLoading]="botRunning">
              <clr-icon shape="play"></clr-icon>
              Execute disabled output
            </button>
            <label style="margin-left: 1em;">{{execStatusMessage}}</label>
          </div>

        </clr-tab-content>
      </ng-template>
    </clr-tab>
    <clr-tab>
      <button clrTabLink>Meters</button>
      <ng-template [(clrIfActive)]="metersActive">
        <clr-tab-content>
          <div *ngIf="viewMeter" class="modal-body view-dashboard">
            <div class="view-meter-back" (click)="viewMeter = false" [style.cursor]="'pointer'">
              <cds-icon shape="arrow" size="md" direction="left"></cds-icon> Back
            </div>
            <xproj-dashboard #dashboard [editMode]="false" [showDashboardSettings]="false" [dashboardId]="'bms_meter'"
              [enableExport]="false" [dashboardOutputParameters]="meterDashboardOutputParameters"
              [responsiveWidth]="responsiveWidth"></xproj-dashboard>
          </div>

          <clr-datagrid *ngIf="!viewMeter" #grid class="datagrid-compact meter-list" [clrDgLoading]="loadingMeters">

          <clr-dg-action-bar>
            <div class="btn-group">
              <button type="button" class="btn btn-sm btn-secondary" (click)="updateMeters()">
                <clr-icon shape="refresh"></clr-icon> Refresh
              </button>
            </div>
          </clr-dg-action-bar>

          <clr-dg-column [clrDgField]="'id'">
            <ng-container *clrDgHideableColumn="{hidden: true}">Id</ng-container>
          </clr-dg-column>
          <clr-dg-column [clrDgField]="'name'">
            <ng-container *clrDgHideableColumn="{hidden: true}">Name</ng-container>
          </clr-dg-column>
          <clr-dg-column [clrDgField]="'subaddress1'">
            <ng-container *clrDgHideableColumn="{hidden: false}">Identifier</ng-container>
          </clr-dg-column>
          <clr-dg-column [clrDgField]="'subaddress3'">
            <ng-container *clrDgHideableColumn="{hidden: false}">Variable</ng-container>
          </clr-dg-column>
          <clr-dg-column [clrDgField]="'meterType'">
            <ng-container *clrDgHideableColumn="{hidden: false}">Meter type</ng-container>
          </clr-dg-column>
          <clr-dg-column [style.width.px]="15">
            <ng-container></ng-container>
          </clr-dg-column>

          <clr-dg-row *clrDgItems="let meter of meters ; index as i"[clrDgItem]="meter">
            <clr-dg-cell> {{meter.id}}</clr-dg-cell>
            <clr-dg-cell> {{meter.name}}</clr-dg-cell>
            <clr-dg-cell> {{meter.subaddress1}}</clr-dg-cell>
            <clr-dg-cell> {{meter.subaddress3}}</clr-dg-cell>
            <clr-dg-cell> {{meter.meterType}}</clr-dg-cell>
            <clr-dg-cell> <clr-icon shape="eye" size="16" [style.cursor]="'pointer'" (click)="displayMeter(meter.id)" ></clr-icon> </clr-dg-cell>
          </clr-dg-row>

          <clr-dg-footer>
            <clr-dg-pagination #pagination [clrDgPageSize]="10">
              <clr-dg-page-size [clrPageSizeOptions]="[10,20,50,100,200]">Meters per page</clr-dg-page-size>
              {{pagination.firstItem + 1}} - {{pagination.lastItem + 1}} of {{pagination.totalItems}} meters
            </clr-dg-pagination>
          </clr-dg-footer>
        </clr-datagrid>


        </clr-tab-content>
      </ng-template>
    </clr-tab>
    <clr-tab>
      <button clrTabLink>Logs</button>
      <ng-template [(clrIfActive)]="logssActive">
        <clr-tab-content>
          <app-view-node-logs [node]="data?.node"></app-view-node-logs>
        </clr-tab-content>
      </ng-template>
    </clr-tab>

  </clr-tabs>

</div>
