<table class="table table-vertical" *ngIf="bmsCustomer">
  <caption style="margin-bottom: 1em;">
    Customer info
    <button class="btn btn-sm" style="float: right;" (click)="viewInBMS();">View <clr-icon shape="arrow" directions="left" style="transform: rotate(90deg);"></clr-icon></button>
  </caption>
  <tbody>
    <tr>
      <th class="left">Customer Id</th>
      <td class="left">{{bmsCustomer.customerId}}</td>
    </tr>
    <tr>
      <th class="left">Customer Xdb Id</th>
      <td class="left">{{bmsCustomer.id}}</td>
    </tr>
    <tr>
      <th class="left">Name</th>
      <td class="left">{{bmsCustomer.customerName}}</td>
    </tr>
    <tr>
      <th class="left">Association Id</th>
      <td class="left">{{bmsCustomer.associationId}}</td>
    </tr>
  </tbody>
</table>
<p *ngIf="!bmsCustomer"> Customer info not available.</p>
