import { Component, Input, OnInit } from '@angular/core';
import { BmsResidentExchange } from '@features/bms/models/bms-resident-exchange';
import { XProjectorXConfClient } from '@xprojectorcore/xprojector_backend/xprojector-xconf-client';
import { DateHelper, ProjectionColumnDescription, XprojAlertService, XProjectorClient } from 'xproj-lib';
import { BmsResidentExchangeService } from '@features/bms/bms-admin/services/bms-resident-exchange-service';
import { BmsDataUtils } from '@features/bms/bms-admin/utils/bms-data-utils';

@Component({
  selector: 'app-bms-resident-exchang-view',
  templateUrl: './bms-resident-exchang-view.component.html',
  styleUrls: ['./bms-resident-exchang-view.component.scss']
})
export class BmsResidentExchangViewComponent implements OnInit {

  @Input() allowEdit : boolean = false;

  tariffColumns: ProjectionColumnDescription[] = [];
  residentExchanges: BmsResidentExchange[] = [];
  nodeId : number;
  nodeTypeId : string;
  customerId : string;

  showEditResidentExchange : boolean = false;
  selectedResidentExchange : BmsResidentExchange;
  selectedExchangeDateString : string;

  BmsDataUtils = BmsDataUtils;

  constructor(
    private alertService: XprojAlertService,
    private xprojClient: XProjectorClient,
    private xconfClient: XProjectorXConfClient,
    private residentExchangeService : BmsResidentExchangeService,
    public dateHelper : DateHelper
  ) { }

  ngOnInit(): void {
  }

  async update(nodeId: number, nodeTypeId: string, customerId : string) {

    this.nodeId = nodeId;
    this.nodeTypeId = nodeTypeId;
    this.customerId = customerId;

    this.residentExchanges = await this.residentExchangeService.getResidenExchanges({apartmentId : this.nodeTypeId == '_x_bms_apartment' ? this.nodeId : undefined
            , facilityId : this.nodeTypeId == '_x_bms_apartment' ? undefined : this.nodeId
            , customerId : customerId});
  }

  async editResidentExchange(residentExchange : BmsResidentExchange) {
    if (residentExchange) {
      this.selectedResidentExchange = residentExchange;
      this.selectedExchangeDateString = this.dateHelper.utils.formatByString(residentExchange.exchangeDate, 'yyyy-MM-dd');
      this.showEditResidentExchange = true;
    }
  }

  async onExecuteEditBillingTariff() {
    try {
      if (this.selectedResidentExchange) {
        this.selectedResidentExchange.exchangeDate = this.dateHelper.utils.parse(this.selectedExchangeDateString, 'yyyy-MM-dd');
        this.selectedResidentExchange.modifiedAt = new Date();

        let result = await this.residentExchangeService.setResidentExchanges([this.selectedResidentExchange]);

        if (result) {
          this.alertService.info('Resident exchange updated ok.');
        }
        else {
          this.alertService.error('Error update resident exchange');
          this.update(this.nodeId, this.nodeTypeId, this.customerId);
        }
      }
    }
    catch (err) {
      this.alertService.error(err);
    }
  }

  async deleteTariff(residentExchange : BmsResidentExchange) {
    if (residentExchange) {
      await this.residentExchangeService.deleteResidentExchange(residentExchange);
      await this.update(this.nodeId, this.nodeTypeId, this.customerId);
    }
  }

}
