import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { SysAdminUserProfilesComponent } from '@xprojectorfeatures/sysadmin/components/sys-admin-user-profiles/sys-admin-user-profiles.component';
import { BmsAdminDashboardComponent } from './components/bms-admin-dashboard/bms-admin-dashboard.component';
import { BmsAdminDashboardsComponent } from './components/bms-admin-dashboards/bms-admin-dashboards.component';

import { BmsAdminWorkspaceComponent } from './components/bms-admin-workspace/bms-admin-workspace.component';
import { BmsBotsAdminComponent } from './components/bms-bots-admin/bms-bots-admin.component';
import { BmsCustomerAddComponent } from './components/bms-customer-add/bms-customer-add.component';
import { BmsCustomersAdminComponent } from './components/bms-customers-admin/bms-customers-admin.component';
import { BmsGeoLocationComponent } from './components/bms-geo-location/bms-geo-location.component';
import { BmsMasterDataComponent } from './components/bms-master-data/bms-master-data.component';
import { BmsMeterAdminComponent } from './components/bms-meter-admin/bms-meter-admin.component';
import { BmsTrusteeAdminComponent } from './components/bms-trustee-admin/bms-trustee-admin.component';
import { BmsTrusteeOverviewComponent } from './components/bms-trustee-overview/bms-trustee-overview.component';
import { BmsMeteringApiComponent } from './components/bms-metering-api/bms-metering-api.component';
import { BmsMessageAdminComponent } from './components/bms-message-admin/bms-message-admin.component';
import { BmsGatewayEventsComponent } from './components/bms-gateway-admin/bms-gateway-events/bms-gateway-events.component';
import { BmsLorawanComponent } from './components/bms-lorawan/bms-lorawan.component';

const routes: Routes = [
  {
      path: '', component: BmsAdminWorkspaceComponent,
      children: [
          { path: 'customers', component: BmsCustomersAdminComponent },
          { path: 'customers/:id', component: BmsCustomersAdminComponent },
          { path: 'customers/:id/:module', component: BmsCustomersAdminComponent },
          { path: 'customers/:id/:module/:nodeid/:nodelabel', component: BmsCustomersAdminComponent },

          { path: 'addcustomer', component: BmsCustomerAddComponent },

          { path: 'masterdata', component: BmsMasterDataComponent },
          { path: 'geolocation', component: BmsGeoLocationComponent },
          { path: 'meteringapi', component: BmsMeteringApiComponent },
          { path: 'messageview', component: BmsMessageAdminComponent },
          { path: 'lorawan', component: BmsLorawanComponent },

          { path: 'meteradmin', component: BmsMeterAdminComponent },

          { path: 'trusteeoverview', component: BmsTrusteeOverviewComponent },

          { path: 'trusteeadmin', component: BmsTrusteeAdminComponent },
          { path: 'trusteeadmin/:id', component: BmsTrusteeAdminComponent },
          { path: 'trusteeadmin/:id/:customerid', component: BmsTrusteeAdminComponent },

          { path: 'users', component: SysAdminUserProfilesComponent },
          { path: 'users/:searchform', component: SysAdminUserProfilesComponent },

          { path: 'botsadmin', component: BmsBotsAdminComponent },

          { path: 'bmsadmindashboards', component: BmsAdminDashboardsComponent },
          { path: 'bmsadmindashboards/:id', component: BmsAdminDashboardComponent },

          { path: 'gatewayadmin', component: BmsGatewayEventsComponent },
      ]
  }
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class BmsAdminRoutingModule { }
