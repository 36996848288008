/* tslint:disable */
/* eslint-disable */
// @ts-nocheck
//
// THIS IS A GENERATED FILE
// DO NOT MODIFY IT! YOUR CHANGES WILL BE LOST
import { Inject, Injectable, Optional } from '@angular/core';
import {
  GrpcCallType,
  GrpcClient,
  GrpcClientFactory,
  GrpcEvent,
  GrpcMetadata
} from '@ngx-grpc/common';
import {
  GRPC_CLIENT_FACTORY,
  GrpcHandler,
  takeMessages,
  throwStatusErrors
} from '@ngx-grpc/core';
import { Observable } from 'rxjs';
import * as thisProto from './xprojector.modulebms.customeradmin.pb';
import * as googleProtobuf000 from './google/protobuf/timestamp.pb';
import { GRPC_BMS_CUSTOMER_ADMIN_CLIENT_SETTINGS } from './xprojector.modulebms.customeradmin.pbconf';
/**
 * Service client implementation for modulebms.customeradmin.BmsCustomerAdmin
 */
@Injectable({ providedIn: 'root' })
export class BmsCustomerAdminClient {
  private client: GrpcClient<any>;

  /**
   * Raw RPC implementation for each service client method.
   * The raw methods provide more control on the incoming data and events. E.g. they can be useful to read status `OK` metadata.
   * Attention: these methods do not throw errors when non-zero status codes are received.
   */
  $raw = {
    /**
     * Unary call: /modulebms.customeradmin.BmsCustomerAdmin/GetCustomerInfo
     *
     * @param requestMessage Request message
     * @param requestMetadata Request metadata
     * @returns Observable<GrpcEvent<thisProto.GetCustomerInfoResponse>>
     */
    getCustomerInfo: (
      requestData: thisProto.GetCustomerInfoRequest,
      requestMetadata = new GrpcMetadata()
    ): Observable<GrpcEvent<thisProto.GetCustomerInfoResponse>> => {
      return this.handler.handle({
        type: GrpcCallType.unary,
        client: this.client,
        path: '/modulebms.customeradmin.BmsCustomerAdmin/GetCustomerInfo',
        requestData,
        requestMetadata,
        requestClass: thisProto.GetCustomerInfoRequest,
        responseClass: thisProto.GetCustomerInfoResponse
      });
    },
    /**
     * Unary call: /modulebms.customeradmin.BmsCustomerAdmin/GetApartments
     *
     * @param requestMessage Request message
     * @param requestMetadata Request metadata
     * @returns Observable<GrpcEvent<thisProto.GetApartmentsResponse>>
     */
    getApartments: (
      requestData: thisProto.GetApartmentsRequest,
      requestMetadata = new GrpcMetadata()
    ): Observable<GrpcEvent<thisProto.GetApartmentsResponse>> => {
      return this.handler.handle({
        type: GrpcCallType.unary,
        client: this.client,
        path: '/modulebms.customeradmin.BmsCustomerAdmin/GetApartments',
        requestData,
        requestMetadata,
        requestClass: thisProto.GetApartmentsRequest,
        responseClass: thisProto.GetApartmentsResponse
      });
    },
    /**
     * Unary call: /modulebms.customeradmin.BmsCustomerAdmin/GetCustomerUsers
     *
     * @param requestMessage Request message
     * @param requestMetadata Request metadata
     * @returns Observable<GrpcEvent<thisProto.GetCustomerUsersResponse>>
     */
    getCustomerUsers: (
      requestData: thisProto.GetCustomerUsersRequest,
      requestMetadata = new GrpcMetadata()
    ): Observable<GrpcEvent<thisProto.GetCustomerUsersResponse>> => {
      return this.handler.handle({
        type: GrpcCallType.unary,
        client: this.client,
        path: '/modulebms.customeradmin.BmsCustomerAdmin/GetCustomerUsers',
        requestData,
        requestMetadata,
        requestClass: thisProto.GetCustomerUsersRequest,
        responseClass: thisProto.GetCustomerUsersResponse
      });
    },
    /**
     * Unary call: /modulebms.customeradmin.BmsCustomerAdmin/AddCustomerUser
     *
     * @param requestMessage Request message
     * @param requestMetadata Request metadata
     * @returns Observable<GrpcEvent<thisProto.AddCustomerUserResponse>>
     */
    addCustomerUser: (
      requestData: thisProto.AddCustomerUserRequest,
      requestMetadata = new GrpcMetadata()
    ): Observable<GrpcEvent<thisProto.AddCustomerUserResponse>> => {
      return this.handler.handle({
        type: GrpcCallType.unary,
        client: this.client,
        path: '/modulebms.customeradmin.BmsCustomerAdmin/AddCustomerUser',
        requestData,
        requestMetadata,
        requestClass: thisProto.AddCustomerUserRequest,
        responseClass: thisProto.AddCustomerUserResponse
      });
    },
    /**
     * Unary call: /modulebms.customeradmin.BmsCustomerAdmin/DeleteCustomerUser
     *
     * @param requestMessage Request message
     * @param requestMetadata Request metadata
     * @returns Observable<GrpcEvent<thisProto.DeleteCustomerUserResponse>>
     */
    deleteCustomerUser: (
      requestData: thisProto.DeleteCustomerUserRequest,
      requestMetadata = new GrpcMetadata()
    ): Observable<GrpcEvent<thisProto.DeleteCustomerUserResponse>> => {
      return this.handler.handle({
        type: GrpcCallType.unary,
        client: this.client,
        path: '/modulebms.customeradmin.BmsCustomerAdmin/DeleteCustomerUser',
        requestData,
        requestMetadata,
        requestClass: thisProto.DeleteCustomerUserRequest,
        responseClass: thisProto.DeleteCustomerUserResponse
      });
    },
    /**
     * Unary call: /modulebms.customeradmin.BmsCustomerAdmin/UpdateCustomerUser
     *
     * @param requestMessage Request message
     * @param requestMetadata Request metadata
     * @returns Observable<GrpcEvent<thisProto.UpdateCustomerUserResponse>>
     */
    updateCustomerUser: (
      requestData: thisProto.UpdateCustomerUserRequest,
      requestMetadata = new GrpcMetadata()
    ): Observable<GrpcEvent<thisProto.UpdateCustomerUserResponse>> => {
      return this.handler.handle({
        type: GrpcCallType.unary,
        client: this.client,
        path: '/modulebms.customeradmin.BmsCustomerAdmin/UpdateCustomerUser',
        requestData,
        requestMetadata,
        requestClass: thisProto.UpdateCustomerUserRequest,
        responseClass: thisProto.UpdateCustomerUserResponse
      });
    },
    /**
     * Unary call: /modulebms.customeradmin.BmsCustomerAdmin/ResetPassword
     *
     * @param requestMessage Request message
     * @param requestMetadata Request metadata
     * @returns Observable<GrpcEvent<thisProto.ResetPasswordResponse>>
     */
    resetPassword: (
      requestData: thisProto.ResetPasswordRequest,
      requestMetadata = new GrpcMetadata()
    ): Observable<GrpcEvent<thisProto.ResetPasswordResponse>> => {
      return this.handler.handle({
        type: GrpcCallType.unary,
        client: this.client,
        path: '/modulebms.customeradmin.BmsCustomerAdmin/ResetPassword',
        requestData,
        requestMetadata,
        requestClass: thisProto.ResetPasswordRequest,
        responseClass: thisProto.ResetPasswordResponse
      });
    },
    /**
     * Unary call: /modulebms.customeradmin.BmsCustomerAdmin/GetApartmentCustomerUsers
     *
     * @param requestMessage Request message
     * @param requestMetadata Request metadata
     * @returns Observable<GrpcEvent<thisProto.GetApartmentCustomerUsersResponse>>
     */
    getApartmentCustomerUsers: (
      requestData: thisProto.GetApartmentCustomerUsersRequest,
      requestMetadata = new GrpcMetadata()
    ): Observable<GrpcEvent<thisProto.GetApartmentCustomerUsersResponse>> => {
      return this.handler.handle({
        type: GrpcCallType.unary,
        client: this.client,
        path:
          '/modulebms.customeradmin.BmsCustomerAdmin/GetApartmentCustomerUsers',
        requestData,
        requestMetadata,
        requestClass: thisProto.GetApartmentCustomerUsersRequest,
        responseClass: thisProto.GetApartmentCustomerUsersResponse
      });
    },
    /**
     * Server streaming: /modulebms.customeradmin.BmsCustomerAdmin/ExportApartmentsAndCustomerUsers
     *
     * @param requestMessage Request message
     * @param requestMetadata Request metadata
     * @returns Observable<GrpcEvent<thisProto.CustomerAdminDataChunk>>
     */
    exportApartmentsAndCustomerUsers: (
      requestData: thisProto.ExportApartmentsAndCustomerUsersRequest,
      requestMetadata = new GrpcMetadata()
    ): Observable<GrpcEvent<thisProto.CustomerAdminDataChunk>> => {
      return this.handler.handle({
        type: GrpcCallType.serverStream,
        client: this.client,
        path:
          '/modulebms.customeradmin.BmsCustomerAdmin/ExportApartmentsAndCustomerUsers',
        requestData,
        requestMetadata,
        requestClass: thisProto.ExportApartmentsAndCustomerUsersRequest,
        responseClass: thisProto.CustomerAdminDataChunk
      });
    },
    /**
     * Unary call: /modulebms.customeradmin.BmsCustomerAdmin/GetMeterTypes
     *
     * @param requestMessage Request message
     * @param requestMetadata Request metadata
     * @returns Observable<GrpcEvent<thisProto.GetMeterTypesResponse>>
     */
    getMeterTypes: (
      requestData: thisProto.GetMeterTypesRequest,
      requestMetadata = new GrpcMetadata()
    ): Observable<GrpcEvent<thisProto.GetMeterTypesResponse>> => {
      return this.handler.handle({
        type: GrpcCallType.unary,
        client: this.client,
        path: '/modulebms.customeradmin.BmsCustomerAdmin/GetMeterTypes',
        requestData,
        requestMetadata,
        requestClass: thisProto.GetMeterTypesRequest,
        responseClass: thisProto.GetMeterTypesResponse
      });
    },
    /**
     * Unary call: /modulebms.customeradmin.BmsCustomerAdmin/GetTariffGroups
     *
     * @param requestMessage Request message
     * @param requestMetadata Request metadata
     * @returns Observable<GrpcEvent<thisProto.GetTariffGroupsResponse>>
     */
    getTariffGroups: (
      requestData: thisProto.GetTariffGroupsRequest,
      requestMetadata = new GrpcMetadata()
    ): Observable<GrpcEvent<thisProto.GetTariffGroupsResponse>> => {
      return this.handler.handle({
        type: GrpcCallType.unary,
        client: this.client,
        path: '/modulebms.customeradmin.BmsCustomerAdmin/GetTariffGroups',
        requestData,
        requestMetadata,
        requestClass: thisProto.GetTariffGroupsRequest,
        responseClass: thisProto.GetTariffGroupsResponse
      });
    },
    /**
     * Unary call: /modulebms.customeradmin.BmsCustomerAdmin/GetMeters
     *
     * @param requestMessage Request message
     * @param requestMetadata Request metadata
     * @returns Observable<GrpcEvent<thisProto.GetMetersResponse>>
     */
    getMeters: (
      requestData: thisProto.GetMetersRequest,
      requestMetadata = new GrpcMetadata()
    ): Observable<GrpcEvent<thisProto.GetMetersResponse>> => {
      return this.handler.handle({
        type: GrpcCallType.unary,
        client: this.client,
        path: '/modulebms.customeradmin.BmsCustomerAdmin/GetMeters',
        requestData,
        requestMetadata,
        requestClass: thisProto.GetMetersRequest,
        responseClass: thisProto.GetMetersResponse
      });
    },
    /**
     * Unary call: /modulebms.customeradmin.BmsCustomerAdmin/GetBillingTariffChangeRequests
     *
     * @param requestMessage Request message
     * @param requestMetadata Request metadata
     * @returns Observable<GrpcEvent<thisProto.GetBillingTariffChangeRequestsResponse>>
     */
    getBillingTariffChangeRequests: (
      requestData: thisProto.GetBillingTariffChangeRequestsRequest,
      requestMetadata = new GrpcMetadata()
    ): Observable<
      GrpcEvent<thisProto.GetBillingTariffChangeRequestsResponse>
    > => {
      return this.handler.handle({
        type: GrpcCallType.unary,
        client: this.client,
        path:
          '/modulebms.customeradmin.BmsCustomerAdmin/GetBillingTariffChangeRequests',
        requestData,
        requestMetadata,
        requestClass: thisProto.GetBillingTariffChangeRequestsRequest,
        responseClass: thisProto.GetBillingTariffChangeRequestsResponse
      });
    },
    /**
     * Unary call: /modulebms.customeradmin.BmsCustomerAdmin/CreateBillingTariffChangeRequest
     *
     * @param requestMessage Request message
     * @param requestMetadata Request metadata
     * @returns Observable<GrpcEvent<thisProto.CreateBillingTariffChangeRequestResponse>>
     */
    createBillingTariffChangeRequest: (
      requestData: thisProto.CreateBillingTariffChangeRequestRequest,
      requestMetadata = new GrpcMetadata()
    ): Observable<
      GrpcEvent<thisProto.CreateBillingTariffChangeRequestResponse>
    > => {
      return this.handler.handle({
        type: GrpcCallType.unary,
        client: this.client,
        path:
          '/modulebms.customeradmin.BmsCustomerAdmin/CreateBillingTariffChangeRequest',
        requestData,
        requestMetadata,
        requestClass: thisProto.CreateBillingTariffChangeRequestRequest,
        responseClass: thisProto.CreateBillingTariffChangeRequestResponse
      });
    },
    /**
     * Unary call: /modulebms.customeradmin.BmsCustomerAdmin/DeleteBillingTariffChangeRequest
     *
     * @param requestMessage Request message
     * @param requestMetadata Request metadata
     * @returns Observable<GrpcEvent<thisProto.DeleteBillingTariffChangeRequestResponse>>
     */
    deleteBillingTariffChangeRequest: (
      requestData: thisProto.DeleteBillingTariffChangeRequestRequest,
      requestMetadata = new GrpcMetadata()
    ): Observable<
      GrpcEvent<thisProto.DeleteBillingTariffChangeRequestResponse>
    > => {
      return this.handler.handle({
        type: GrpcCallType.unary,
        client: this.client,
        path:
          '/modulebms.customeradmin.BmsCustomerAdmin/DeleteBillingTariffChangeRequest',
        requestData,
        requestMetadata,
        requestClass: thisProto.DeleteBillingTariffChangeRequestRequest,
        responseClass: thisProto.DeleteBillingTariffChangeRequestResponse
      });
    },
    /**
     * Unary call: /modulebms.customeradmin.BmsCustomerAdmin/GetLastInvoicedDate
     *
     * @param requestMessage Request message
     * @param requestMetadata Request metadata
     * @returns Observable<GrpcEvent<thisProto.GetLastInvoicedDateResponse>>
     */
    getLastInvoicedDate: (
      requestData: thisProto.GetLastInvoicedDateRequest,
      requestMetadata = new GrpcMetadata()
    ): Observable<GrpcEvent<thisProto.GetLastInvoicedDateResponse>> => {
      return this.handler.handle({
        type: GrpcCallType.unary,
        client: this.client,
        path: '/modulebms.customeradmin.BmsCustomerAdmin/GetLastInvoicedDate',
        requestData,
        requestMetadata,
        requestClass: thisProto.GetLastInvoicedDateRequest,
        responseClass: thisProto.GetLastInvoicedDateResponse
      });
    },
    /**
     * Unary call: /modulebms.customeradmin.BmsCustomerAdmin/SendWelcomeMail
     *
     * @param requestMessage Request message
     * @param requestMetadata Request metadata
     * @returns Observable<GrpcEvent<thisProto.SendWelcomeMailResponse>>
     */
    sendWelcomeMail: (
      requestData: thisProto.SendWelcomeMailRequest,
      requestMetadata = new GrpcMetadata()
    ): Observable<GrpcEvent<thisProto.SendWelcomeMailResponse>> => {
      return this.handler.handle({
        type: GrpcCallType.unary,
        client: this.client,
        path: '/modulebms.customeradmin.BmsCustomerAdmin/SendWelcomeMail',
        requestData,
        requestMetadata,
        requestClass: thisProto.SendWelcomeMailRequest,
        responseClass: thisProto.SendWelcomeMailResponse
      });
    },
    /**
     * Unary call: /modulebms.customeradmin.BmsCustomerAdmin/SendCustomerMail
     *
     * @param requestMessage Request message
     * @param requestMetadata Request metadata
     * @returns Observable<GrpcEvent<thisProto.SendCustomerMailResponse>>
     */
    sendCustomerMail: (
      requestData: thisProto.SendCustomerMailRequest,
      requestMetadata = new GrpcMetadata()
    ): Observable<GrpcEvent<thisProto.SendCustomerMailResponse>> => {
      return this.handler.handle({
        type: GrpcCallType.unary,
        client: this.client,
        path: '/modulebms.customeradmin.BmsCustomerAdmin/SendCustomerMail',
        requestData,
        requestMetadata,
        requestClass: thisProto.SendCustomerMailRequest,
        responseClass: thisProto.SendCustomerMailResponse
      });
    },
    /**
     * Unary call: /modulebms.customeradmin.BmsCustomerAdmin/CreateTariffGroup
     *
     * @param requestMessage Request message
     * @param requestMetadata Request metadata
     * @returns Observable<GrpcEvent<thisProto.CreateTariffGroupResponse>>
     */
    createTariffGroup: (
      requestData: thisProto.CreteTariffGroupRequest,
      requestMetadata = new GrpcMetadata()
    ): Observable<GrpcEvent<thisProto.CreateTariffGroupResponse>> => {
      return this.handler.handle({
        type: GrpcCallType.unary,
        client: this.client,
        path: '/modulebms.customeradmin.BmsCustomerAdmin/CreateTariffGroup',
        requestData,
        requestMetadata,
        requestClass: thisProto.CreteTariffGroupRequest,
        responseClass: thisProto.CreateTariffGroupResponse
      });
    },
    /**
     * Unary call: /modulebms.customeradmin.BmsCustomerAdmin/UpdateTariffGroup
     *
     * @param requestMessage Request message
     * @param requestMetadata Request metadata
     * @returns Observable<GrpcEvent<thisProto.UpdateTariffGroupResponse>>
     */
    updateTariffGroup: (
      requestData: thisProto.UpdateTariffGroupRequest,
      requestMetadata = new GrpcMetadata()
    ): Observable<GrpcEvent<thisProto.UpdateTariffGroupResponse>> => {
      return this.handler.handle({
        type: GrpcCallType.unary,
        client: this.client,
        path: '/modulebms.customeradmin.BmsCustomerAdmin/UpdateTariffGroup',
        requestData,
        requestMetadata,
        requestClass: thisProto.UpdateTariffGroupRequest,
        responseClass: thisProto.UpdateTariffGroupResponse
      });
    },
    /**
     * Unary call: /modulebms.customeradmin.BmsCustomerAdmin/DeleteTariffGroup
     *
     * @param requestMessage Request message
     * @param requestMetadata Request metadata
     * @returns Observable<GrpcEvent<thisProto.DeleteTariffGroupResponse>>
     */
    deleteTariffGroup: (
      requestData: thisProto.DeleteTariffGroupRequest,
      requestMetadata = new GrpcMetadata()
    ): Observable<GrpcEvent<thisProto.DeleteTariffGroupResponse>> => {
      return this.handler.handle({
        type: GrpcCallType.unary,
        client: this.client,
        path: '/modulebms.customeradmin.BmsCustomerAdmin/DeleteTariffGroup',
        requestData,
        requestMetadata,
        requestClass: thisProto.DeleteTariffGroupRequest,
        responseClass: thisProto.DeleteTariffGroupResponse
      });
    }
  };

  constructor(
    @Optional() @Inject(GRPC_BMS_CUSTOMER_ADMIN_CLIENT_SETTINGS) settings: any,
    @Inject(GRPC_CLIENT_FACTORY) clientFactory: GrpcClientFactory<any>,
    private handler: GrpcHandler
  ) {
    this.client = clientFactory.createClient(
      'modulebms.customeradmin.BmsCustomerAdmin',
      settings
    );
  }

  /**
   * Unary call @/modulebms.customeradmin.BmsCustomerAdmin/GetCustomerInfo
   *
   * @param requestMessage Request message
   * @param requestMetadata Request metadata
   * @returns Observable<thisProto.GetCustomerInfoResponse>
   */
  getCustomerInfo(
    requestData: thisProto.GetCustomerInfoRequest,
    requestMetadata = new GrpcMetadata()
  ): Observable<thisProto.GetCustomerInfoResponse> {
    return this.$raw
      .getCustomerInfo(requestData, requestMetadata)
      .pipe(throwStatusErrors(), takeMessages());
  }

  /**
   * Unary call @/modulebms.customeradmin.BmsCustomerAdmin/GetApartments
   *
   * @param requestMessage Request message
   * @param requestMetadata Request metadata
   * @returns Observable<thisProto.GetApartmentsResponse>
   */
  getApartments(
    requestData: thisProto.GetApartmentsRequest,
    requestMetadata = new GrpcMetadata()
  ): Observable<thisProto.GetApartmentsResponse> {
    return this.$raw
      .getApartments(requestData, requestMetadata)
      .pipe(throwStatusErrors(), takeMessages());
  }

  /**
   * Unary call @/modulebms.customeradmin.BmsCustomerAdmin/GetCustomerUsers
   *
   * @param requestMessage Request message
   * @param requestMetadata Request metadata
   * @returns Observable<thisProto.GetCustomerUsersResponse>
   */
  getCustomerUsers(
    requestData: thisProto.GetCustomerUsersRequest,
    requestMetadata = new GrpcMetadata()
  ): Observable<thisProto.GetCustomerUsersResponse> {
    return this.$raw
      .getCustomerUsers(requestData, requestMetadata)
      .pipe(throwStatusErrors(), takeMessages());
  }

  /**
   * Unary call @/modulebms.customeradmin.BmsCustomerAdmin/AddCustomerUser
   *
   * @param requestMessage Request message
   * @param requestMetadata Request metadata
   * @returns Observable<thisProto.AddCustomerUserResponse>
   */
  addCustomerUser(
    requestData: thisProto.AddCustomerUserRequest,
    requestMetadata = new GrpcMetadata()
  ): Observable<thisProto.AddCustomerUserResponse> {
    return this.$raw
      .addCustomerUser(requestData, requestMetadata)
      .pipe(throwStatusErrors(), takeMessages());
  }

  /**
   * Unary call @/modulebms.customeradmin.BmsCustomerAdmin/DeleteCustomerUser
   *
   * @param requestMessage Request message
   * @param requestMetadata Request metadata
   * @returns Observable<thisProto.DeleteCustomerUserResponse>
   */
  deleteCustomerUser(
    requestData: thisProto.DeleteCustomerUserRequest,
    requestMetadata = new GrpcMetadata()
  ): Observable<thisProto.DeleteCustomerUserResponse> {
    return this.$raw
      .deleteCustomerUser(requestData, requestMetadata)
      .pipe(throwStatusErrors(), takeMessages());
  }

  /**
   * Unary call @/modulebms.customeradmin.BmsCustomerAdmin/UpdateCustomerUser
   *
   * @param requestMessage Request message
   * @param requestMetadata Request metadata
   * @returns Observable<thisProto.UpdateCustomerUserResponse>
   */
  updateCustomerUser(
    requestData: thisProto.UpdateCustomerUserRequest,
    requestMetadata = new GrpcMetadata()
  ): Observable<thisProto.UpdateCustomerUserResponse> {
    return this.$raw
      .updateCustomerUser(requestData, requestMetadata)
      .pipe(throwStatusErrors(), takeMessages());
  }

  /**
   * Unary call @/modulebms.customeradmin.BmsCustomerAdmin/ResetPassword
   *
   * @param requestMessage Request message
   * @param requestMetadata Request metadata
   * @returns Observable<thisProto.ResetPasswordResponse>
   */
  resetPassword(
    requestData: thisProto.ResetPasswordRequest,
    requestMetadata = new GrpcMetadata()
  ): Observable<thisProto.ResetPasswordResponse> {
    return this.$raw
      .resetPassword(requestData, requestMetadata)
      .pipe(throwStatusErrors(), takeMessages());
  }

  /**
   * Unary call @/modulebms.customeradmin.BmsCustomerAdmin/GetApartmentCustomerUsers
   *
   * @param requestMessage Request message
   * @param requestMetadata Request metadata
   * @returns Observable<thisProto.GetApartmentCustomerUsersResponse>
   */
  getApartmentCustomerUsers(
    requestData: thisProto.GetApartmentCustomerUsersRequest,
    requestMetadata = new GrpcMetadata()
  ): Observable<thisProto.GetApartmentCustomerUsersResponse> {
    return this.$raw
      .getApartmentCustomerUsers(requestData, requestMetadata)
      .pipe(throwStatusErrors(), takeMessages());
  }

  /**
   * Server streaming @/modulebms.customeradmin.BmsCustomerAdmin/ExportApartmentsAndCustomerUsers
   *
   * @param requestMessage Request message
   * @param requestMetadata Request metadata
   * @returns Observable<thisProto.CustomerAdminDataChunk>
   */
  exportApartmentsAndCustomerUsers(
    requestData: thisProto.ExportApartmentsAndCustomerUsersRequest,
    requestMetadata = new GrpcMetadata()
  ): Observable<thisProto.CustomerAdminDataChunk> {
    return this.$raw
      .exportApartmentsAndCustomerUsers(requestData, requestMetadata)
      .pipe(throwStatusErrors(), takeMessages());
  }

  /**
   * Unary call @/modulebms.customeradmin.BmsCustomerAdmin/GetMeterTypes
   *
   * @param requestMessage Request message
   * @param requestMetadata Request metadata
   * @returns Observable<thisProto.GetMeterTypesResponse>
   */
  getMeterTypes(
    requestData: thisProto.GetMeterTypesRequest,
    requestMetadata = new GrpcMetadata()
  ): Observable<thisProto.GetMeterTypesResponse> {
    return this.$raw
      .getMeterTypes(requestData, requestMetadata)
      .pipe(throwStatusErrors(), takeMessages());
  }

  /**
   * Unary call @/modulebms.customeradmin.BmsCustomerAdmin/GetTariffGroups
   *
   * @param requestMessage Request message
   * @param requestMetadata Request metadata
   * @returns Observable<thisProto.GetTariffGroupsResponse>
   */
  getTariffGroups(
    requestData: thisProto.GetTariffGroupsRequest,
    requestMetadata = new GrpcMetadata()
  ): Observable<thisProto.GetTariffGroupsResponse> {
    return this.$raw
      .getTariffGroups(requestData, requestMetadata)
      .pipe(throwStatusErrors(), takeMessages());
  }

  /**
   * Unary call @/modulebms.customeradmin.BmsCustomerAdmin/GetMeters
   *
   * @param requestMessage Request message
   * @param requestMetadata Request metadata
   * @returns Observable<thisProto.GetMetersResponse>
   */
  getMeters(
    requestData: thisProto.GetMetersRequest,
    requestMetadata = new GrpcMetadata()
  ): Observable<thisProto.GetMetersResponse> {
    return this.$raw
      .getMeters(requestData, requestMetadata)
      .pipe(throwStatusErrors(), takeMessages());
  }

  /**
   * Unary call @/modulebms.customeradmin.BmsCustomerAdmin/GetBillingTariffChangeRequests
   *
   * @param requestMessage Request message
   * @param requestMetadata Request metadata
   * @returns Observable<thisProto.GetBillingTariffChangeRequestsResponse>
   */
  getBillingTariffChangeRequests(
    requestData: thisProto.GetBillingTariffChangeRequestsRequest,
    requestMetadata = new GrpcMetadata()
  ): Observable<thisProto.GetBillingTariffChangeRequestsResponse> {
    return this.$raw
      .getBillingTariffChangeRequests(requestData, requestMetadata)
      .pipe(throwStatusErrors(), takeMessages());
  }

  /**
   * Unary call @/modulebms.customeradmin.BmsCustomerAdmin/CreateBillingTariffChangeRequest
   *
   * @param requestMessage Request message
   * @param requestMetadata Request metadata
   * @returns Observable<thisProto.CreateBillingTariffChangeRequestResponse>
   */
  createBillingTariffChangeRequest(
    requestData: thisProto.CreateBillingTariffChangeRequestRequest,
    requestMetadata = new GrpcMetadata()
  ): Observable<thisProto.CreateBillingTariffChangeRequestResponse> {
    return this.$raw
      .createBillingTariffChangeRequest(requestData, requestMetadata)
      .pipe(throwStatusErrors(), takeMessages());
  }

  /**
   * Unary call @/modulebms.customeradmin.BmsCustomerAdmin/DeleteBillingTariffChangeRequest
   *
   * @param requestMessage Request message
   * @param requestMetadata Request metadata
   * @returns Observable<thisProto.DeleteBillingTariffChangeRequestResponse>
   */
  deleteBillingTariffChangeRequest(
    requestData: thisProto.DeleteBillingTariffChangeRequestRequest,
    requestMetadata = new GrpcMetadata()
  ): Observable<thisProto.DeleteBillingTariffChangeRequestResponse> {
    return this.$raw
      .deleteBillingTariffChangeRequest(requestData, requestMetadata)
      .pipe(throwStatusErrors(), takeMessages());
  }

  /**
   * Unary call @/modulebms.customeradmin.BmsCustomerAdmin/GetLastInvoicedDate
   *
   * @param requestMessage Request message
   * @param requestMetadata Request metadata
   * @returns Observable<thisProto.GetLastInvoicedDateResponse>
   */
  getLastInvoicedDate(
    requestData: thisProto.GetLastInvoicedDateRequest,
    requestMetadata = new GrpcMetadata()
  ): Observable<thisProto.GetLastInvoicedDateResponse> {
    return this.$raw
      .getLastInvoicedDate(requestData, requestMetadata)
      .pipe(throwStatusErrors(), takeMessages());
  }

  /**
   * Unary call @/modulebms.customeradmin.BmsCustomerAdmin/SendWelcomeMail
   *
   * @param requestMessage Request message
   * @param requestMetadata Request metadata
   * @returns Observable<thisProto.SendWelcomeMailResponse>
   */
  sendWelcomeMail(
    requestData: thisProto.SendWelcomeMailRequest,
    requestMetadata = new GrpcMetadata()
  ): Observable<thisProto.SendWelcomeMailResponse> {
    return this.$raw
      .sendWelcomeMail(requestData, requestMetadata)
      .pipe(throwStatusErrors(), takeMessages());
  }

  /**
   * Unary call @/modulebms.customeradmin.BmsCustomerAdmin/SendCustomerMail
   *
   * @param requestMessage Request message
   * @param requestMetadata Request metadata
   * @returns Observable<thisProto.SendCustomerMailResponse>
   */
  sendCustomerMail(
    requestData: thisProto.SendCustomerMailRequest,
    requestMetadata = new GrpcMetadata()
  ): Observable<thisProto.SendCustomerMailResponse> {
    return this.$raw
      .sendCustomerMail(requestData, requestMetadata)
      .pipe(throwStatusErrors(), takeMessages());
  }

  /**
   * Unary call @/modulebms.customeradmin.BmsCustomerAdmin/CreateTariffGroup
   *
   * @param requestMessage Request message
   * @param requestMetadata Request metadata
   * @returns Observable<thisProto.CreateTariffGroupResponse>
   */
  createTariffGroup(
    requestData: thisProto.CreteTariffGroupRequest,
    requestMetadata = new GrpcMetadata()
  ): Observable<thisProto.CreateTariffGroupResponse> {
    return this.$raw
      .createTariffGroup(requestData, requestMetadata)
      .pipe(throwStatusErrors(), takeMessages());
  }

  /**
   * Unary call @/modulebms.customeradmin.BmsCustomerAdmin/UpdateTariffGroup
   *
   * @param requestMessage Request message
   * @param requestMetadata Request metadata
   * @returns Observable<thisProto.UpdateTariffGroupResponse>
   */
  updateTariffGroup(
    requestData: thisProto.UpdateTariffGroupRequest,
    requestMetadata = new GrpcMetadata()
  ): Observable<thisProto.UpdateTariffGroupResponse> {
    return this.$raw
      .updateTariffGroup(requestData, requestMetadata)
      .pipe(throwStatusErrors(), takeMessages());
  }

  /**
   * Unary call @/modulebms.customeradmin.BmsCustomerAdmin/DeleteTariffGroup
   *
   * @param requestMessage Request message
   * @param requestMetadata Request metadata
   * @returns Observable<thisProto.DeleteTariffGroupResponse>
   */
  deleteTariffGroup(
    requestData: thisProto.DeleteTariffGroupRequest,
    requestMetadata = new GrpcMetadata()
  ): Observable<thisProto.DeleteTariffGroupResponse> {
    return this.$raw
      .deleteTariffGroup(requestData, requestMetadata)
      .pipe(throwStatusErrors(), takeMessages());
  }
}
