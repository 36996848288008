import { Component, Input, OnInit } from '@angular/core';
import { BmsBillingPeriod, BmsMonthOfYear } from '@app/core/models/bms-export-bot';
import { BmsStateService } from '@app/core/services/bms-state-service';
import { BillingTariffchangeRequest, BmsCustomerUser, GetCustomerInfoResponse, MeterTypeInfo, TariffGroupInfo } from '@app/core/xprojector_backend/proto/xprojector.modulebms.customeradmin.pb';
import { XProjectorBmsCustomerAdminClient } from '@app/core/xprojector_backend/xprojector-bms-customeradmin-client';
import { BmsBillingTariff } from '@app/features/bms/models/bms-billing-tariff';
import { ClrDatagridSortOrder } from '@clr/angular';
import { PageData } from '@xprojectorcore/models/page-data';
import { StateService } from '@xprojectorcore/services/state-service';
import { PortalInteractService } from '@xprojectorfeatures/portal/services/portal-interact.service';
import { NGXLogger } from 'ngx-logger';
import { ArrayUtils, DateHelper, XprojAlertService, XprojModalService } from 'xproj-lib';

export class CustomerTariffInfo {
  tariff: BillingTariffchangeRequest;
  nextTariff: BillingTariffchangeRequest;
  activeFromString: string = '';
  nextActiveFromString: string = '';
  expanded: boolean = false;
  allTariffs : BillingTariffchangeRequest[] = [];
  displayMeterType : string = '';
}

export class CustomerTariffChangeRequestInfo {
  meterType : string;
  tariffGroup : string;
  user : string;
  value : number;
  unit : string;
  modifiedAt : Date;
  createdAt : Date;
  activeFromDate : Date;
  requestTypeString : string;
}

export class MeterTypeValue {
  name : string;
  displayName : string;
  unit : string;
  isTariffgroup: boolean;
}

@Component({
  selector: 'app-bms-customer-tariffs',
  templateUrl: './bms-customer-tariffs.component.html',
  styleUrls: ['./bms-customer-tariffs.component.scss']
})
export class BmsCustomerTariffsComponent implements OnInit {

  public static PAGEID = 'customertariffs';

  @Input() data: PageData;
  @Input() visible: boolean = false;
  @Input() admin: boolean = false;

  customerInfo : GetCustomerInfoResponse;
  tariffChangeRequests: BillingTariffchangeRequest[] = [];
  tariffChangeRequestsHistoryByMeterType:{ name: string, displayName : string, active : boolean, tariffChangeRequestInfos: CustomerTariffChangeRequestInfo[] }[] = [];
  tariffChangeRequestsByMeterType: { meterType: string, tariffChangeRequests: BillingTariffchangeRequest[] }[] = [];
  tariffChangeRequestsByTariffGroup: { meterType: string, tariffChangeRequests: BillingTariffchangeRequest[] }[] = [];
  currentTariffChangeRequests: CustomerTariffInfo[] = [];
  adminUsers : Map<string, BmsCustomerUser>;
  loadingTariffChangerequests: boolean = false;
  loadingTariffChangerequestsHistory: boolean = false;
  meterTypeInfos : MeterTypeInfo[] = []
  tariffGroupInfos : TariffGroupInfo[] = []
  showNewTariffModal : boolean = false;
  tariffChangeDates : Date[] = [];
  lastInvoicedAt : Date = new Date();
  overrideLastInvoicedAt : boolean = false;

  displayMeterTypes : Map<string, string>;

  setBiilingTariffInfo: {
    value : number,
    meterTypeValue: MeterTypeValue,
    activeFromIndex : number,
    activeFromUtc : Date,
    unit : string};

  meterTypeValues: MeterTypeValue[] = [];

  descSort = ClrDatagridSortOrder.DESC;
  ascSort = ClrDatagridSortOrder.ASC;

  _auditLogActive: boolean = false;
  get auditLogActive(): boolean {
    return this._auditLogActive;
  }
  set auditLogActive(value: boolean) {
    this._auditLogActive = value;
    if (this._auditLogActive) {
      this.updateBillingTariffChangeRequestsHistory();
    }
  }

  requestTypeStringById : Map<number, string>;

  constructor(
    private logger: NGXLogger,
    private state: StateService,
    private bmsState: BmsStateService,
    private modalService: XprojModalService,
    private alertService: XprojAlertService,
    public dateHelper: DateHelper,
    private portalInteractService: PortalInteractService,
    private bmsCustomerAdminClient: XProjectorBmsCustomerAdminClient
  ) {
    this.initRequestTypesById();
    this.initDisplayMeterTypes();
  }

  ngOnInit(): void {
    this.updateBillingTariffChangeRequests();
    this.updateMeterTypeValues();
    this.updateCustomerInfo();
    this.intiSetBillingInfo();
  }

  async initPage() {
  }

  async updateCustomerInfo() {
    this.customerInfo = await this.bmsCustomerAdminClient.getCustomerInfo(this.data.customerId);
    let includeSigned : boolean = this.customerInfo.billingPeriod == BmsBillingPeriod.Quarter;
    this.lastInvoicedAt = await this.bmsCustomerAdminClient.getLastInvoicedDate(this.data.customerId, includeSigned);
    if (!this.lastInvoicedAt) {
      this.lastInvoicedAt = new Date();
    }
    let users = await this.bmsCustomerAdminClient.getCustomerUsers(this.data.customerId, true);
    this.adminUsers = new Map<string, BmsCustomerUser>();
    users.forEach(user => {
      this.adminUsers.set(user.id, user);
    });

    this.tariffChangeDates = this.getTariffChangeDates();
  }

  async updateMeterTypeValues() {
    if (this.meterTypeInfos.length == 0) {
      this.meterTypeInfos = await this.bmsCustomerAdminClient.getMeterTypes(this.data.customerId, true);
    }
    if (this.tariffGroupInfos.length == 0) {
      this.tariffGroupInfos = await this.bmsCustomerAdminClient.getTariffGroups(this.data.customerId, true);
    }
    this.meterTypeValues = [];
    this.meterTypeInfos.forEach(x => {
      var meterTypeValue = new MeterTypeValue();
      meterTypeValue.name = x.meterType;
      meterTypeValue.displayName = this.getDisplayMeterType(x.meterType);// + ' (' + x.unit + ')';
      meterTypeValue.isTariffgroup = false;
      meterTypeValue.unit = '';
      this.meterTypeValues.push(meterTypeValue);
    });
    this.tariffGroupInfos.forEach(x => {
      var meterTypeValue = new MeterTypeValue();
      meterTypeValue.name = x.name;
      meterTypeValue.displayName = x.name;// + ' (' + x.unit + ')';
      meterTypeValue.unit = x.unit;
      meterTypeValue.isTariffgroup = true;
      this.meterTypeValues.push(meterTypeValue);
    });
  }

  async updateBillingTariffChangeRequests() {
    this.loadingTariffChangerequests = true;
    try {
      let now = new Date();
      this.tariffChangeRequests = await this.bmsCustomerAdminClient.getBillingTariffChangeRequests(this.data.customerId, false);
      let tariffChangeRequestsByMeterTypeTmp: Map<string, BillingTariffchangeRequest[]> = ArrayUtils.GroupBy(this.tariffChangeRequests.filter(x => x.meterType?.length > 0).sort((a, b) => a.activeFrom > b.activeFrom ? -1 : 1), 'meterType');

      this.tariffChangeRequestsByMeterType = Object.entries(tariffChangeRequestsByMeterTypeTmp).map(([meterType, tariffChangeRequests]) => ({ meterType, tariffChangeRequests }));
      this.currentTariffChangeRequests = [];
      this.tariffChangeRequestsByMeterType.forEach(x => {
        let current = x.tariffChangeRequests.sort((a, b) => a.activeFrom.toDate() > b.activeFrom.toDate() ? -1 : 1).find(x => x.activeFrom.toDate() < now);
        if (current) {
          let tariffInfo = new CustomerTariffInfo();
          tariffInfo.tariff = current;
          tariffInfo.activeFromString = this.dateHelper.utils.formatByString(current.activeFrom.toDate(), 'yyyy-MM-dd');
          tariffInfo.expanded = false;
          tariffInfo.nextTariff = x.tariffChangeRequests.sort((a, b) => a.activeFrom.toDate() < b.activeFrom.toDate() ? -1 : 1).find(x => x.activeFrom.toDate() > now)
          tariffInfo.allTariffs = x.tariffChangeRequests.sort((a, b) => a.activeFrom.toDate() > b.activeFrom.toDate() ? -1 : 1);
          tariffInfo.displayMeterType = tariffInfo.tariff?.tariffGroup?.length > 0 ? tariffInfo.tariff?.tariffGroup : this.getDisplayMeterType(tariffInfo.tariff?.meterType);
          this.currentTariffChangeRequests.push(tariffInfo);
        }
      })

      let tariffChangeRequestsByTariffGroupTmp: Map<string, BillingTariffchangeRequest[]> = ArrayUtils.GroupBy(this.tariffChangeRequests.filter(x => x.tariffGroup?.length > 0).sort((a, b) => a.activeFrom > b.activeFrom ? -1 : 1), 'tariffGroup');
      this.tariffChangeRequestsByTariffGroup = Object.entries(tariffChangeRequestsByTariffGroupTmp).map(([meterType, tariffChangeRequests]) => ({ meterType, tariffChangeRequests }));
      this.tariffChangeRequestsByTariffGroup.forEach(x => {
        let current = x.tariffChangeRequests.sort((a, b) => a.activeFrom.toDate() > b.activeFrom.toDate() ? -1 : 1).find(x => x.activeFrom.toDate() < now);
        if (current) {
          let tariffInfo = new CustomerTariffInfo();
          tariffInfo.tariff = current;
          tariffInfo.activeFromString = this.dateHelper.utils.formatByString(current.activeFrom.toDate(), 'yyyy-MM-dd');
          tariffInfo.expanded = false;
          tariffInfo.nextTariff = x.tariffChangeRequests.sort((a, b) => a.activeFrom.toDate() < b.activeFrom.toDate() ? -1 : 1).find(x => x.activeFrom.toDate() > now)
          tariffInfo.allTariffs = x.tariffChangeRequests.sort((a, b) => a.activeFrom.toDate() > b.activeFrom.toDate() ? -1 : 1);
          tariffInfo.displayMeterType = tariffInfo.tariff?.tariffGroup?.length > 0 ? tariffInfo.tariff?.tariffGroup : this.getDisplayMeterType(tariffInfo.tariff?.meterType);
          this.currentTariffChangeRequests.push(tariffInfo);
        }
      })
    }
    finally {
      this.loadingTariffChangerequests = false;
    }
  }

  async updateBillingTariffChangeRequestsHistory() {
    this.loadingTariffChangerequestsHistory = true;
    try {
      let now = new Date();
      let tariffChangeRequestsHistory = await this.bmsCustomerAdminClient.getBillingTariffChangeRequests(this.data.customerId, true);
      let tariffChangeRequestsHistoryByMeterTypeTmp: Map<string, BillingTariffchangeRequest[]> = ArrayUtils.GroupBy(tariffChangeRequestsHistory.filter(x => x.meterType?.length > 0), 'meterType');
      let tariffChangeRequestsHistoryByMeterType = Object.entries(tariffChangeRequestsHistoryByMeterTypeTmp).map(([meterType, tariffChangeRequests]) => ({ meterType, tariffChangeRequests }));
      this.tariffChangeRequestsHistoryByMeterType = [];
      tariffChangeRequestsHistoryByMeterType.forEach(x => {
        let infos : CustomerTariffChangeRequestInfo[] = [];
        x.tariffChangeRequests.forEach(changeRequest => {
          let info = new CustomerTariffChangeRequestInfo();
          info.meterType = changeRequest.meterType;
          info.tariffGroup = changeRequest.tariffGroup;
          info.activeFromDate = changeRequest.activeFrom.toDate(),
          info.modifiedAt = changeRequest.modifiedAt.toDate(),
          info.createdAt = changeRequest.createdAt.seconds?.startsWith('-') ? changeRequest.modifiedAt.toDate() : changeRequest.createdAt.toDate(),
          info.value = changeRequest.value;
          info.requestTypeString = this.requestTypeStringById.get(changeRequest.requestType) ?? '';
          info.user = this.getCustomerUserDisplayname(changeRequest.userId);
          info.unit = changeRequest.unit;

          infos.push(info);
        });

        this.tariffChangeRequestsHistoryByMeterType.push({name : x.meterType, displayName : this.getDisplayMeterType(x.meterType), active: this.tariffChangeRequestsHistoryByMeterType.length == 0, tariffChangeRequestInfos : infos});
      });

      let tariffChangeRequestsHistoryByTariffGroupTmp: Map<string, BillingTariffchangeRequest[]> = ArrayUtils.GroupBy(tariffChangeRequestsHistory.filter(x => x.tariffGroup?.length > 0), 'tariffGroup');
      let tariffChangeRequestsHistoryByTariffGroup = Object.entries(tariffChangeRequestsHistoryByTariffGroupTmp).map(([tariffGroup, tariffChangeRequests]) => ({ tariffGroup, tariffChangeRequests }));
      tariffChangeRequestsHistoryByTariffGroup.forEach(x => {
        let infos : CustomerTariffChangeRequestInfo[] = [];
        x.tariffChangeRequests.forEach(changeRequest => {
          let info = new CustomerTariffChangeRequestInfo();
          info.meterType = changeRequest.meterType;
          info.tariffGroup = changeRequest.tariffGroup;
          info.activeFromDate = changeRequest.activeFrom.toDate(),
          info.modifiedAt = changeRequest.modifiedAt.toDate(),
          info.createdAt = changeRequest.createdAt.seconds?.startsWith('-') ? changeRequest.modifiedAt.toDate() : changeRequest.createdAt.toDate(),
          info.value = changeRequest.value;
          info.requestTypeString = this.requestTypeStringById.get(changeRequest.requestType) ?? '';
          info.user = this.getCustomerUserDisplayname(changeRequest.userId);
          info.unit = changeRequest.unit;

          infos.push(info);
        });

        this.tariffChangeRequestsHistoryByMeterType.push({name : x.tariffGroup, displayName : x.tariffGroup, active: this.tariffChangeRequestsHistoryByMeterType.length == 0, tariffChangeRequestInfos : infos});
      });

    }
    finally {
      this.loadingTariffChangerequestsHistory = false;
    }
  }

  getDisplayMeterType(meterType : string) : string {
    let result = this.displayMeterTypes.get(meterType);
    if (!result) {
      result = meterType;
    }
    return result;
  }

  intiSetBillingInfo() {
    this.setBiilingTariffInfo = {
      value : 0,
      activeFromIndex : 0,
      meterTypeValue : null,
      activeFromUtc : new Date(),
      unit : ''
    };
  }

  initDisplayMeterTypes() {
    this.displayMeterTypes = new Map<string, string>();
    this.displayMeterTypes.set('EL', $localize `:@@customertariffs_metertype_el:El`);
    this.displayMeterTypes.set('COLDWATER', $localize `:@@customertariffs_metertype_coldwter:Coldwater`);
    this.displayMeterTypes.set('HOTWATER', $localize `:@@customertariffs_metertype_hotwater:Hotwater`);
    this.displayMeterTypes.set('CHARGINGSTATION', $localize `:@@customertariffs_metertype_chargingstation:Chargingstation`);
    this.displayMeterTypes.set('ENGINEHEATER', $localize `:@@customertariffs_metertype_engineheater:Engine heater`);
    this.displayMeterTypes.set('HEAT', $localize `:@@customertariffs_metertype_heat:Heat`);
  }

  initRequestTypesById() {
    this.requestTypeStringById = new Map<number, string>();
    this.requestTypeStringById.set(0, $localize `:@@customertariffs_requesttypeadd:Add`);
    this.requestTypeStringById.set(1, $localize `:@@customertariffs_requesttypemodify:Modify`);
    this.requestTypeStringById.set(2, $localize `:@@customertariffs_requesttypedelete:Delete`);
  }

  getCustomerUserDisplayname(userId : string) : string {
    let bmsCustomerUser = this.adminUsers?.get(userId)
    if (bmsCustomerUser) {
      return bmsCustomerUser.firstname + ' ' + bmsCustomerUser.surname;
    }

    return 'admin';
  }

  createNewTariff() {
    if (this.setBiilingTariffInfo && this.setBiilingTariffInfo.meterTypeValue) {
      if (this.setBiilingTariffInfo.meterTypeValue.isTariffgroup) {
        if (this.setBiilingTariffInfo.meterTypeValue.name?.length > 0) {
          let tariffGroupInfo = this.tariffGroupInfos.find(x => x.name == this.setBiilingTariffInfo.meterTypeValue.name);
          if (tariffGroupInfo &&  tariffGroupInfo.maxTariffPerUnit > 0 && this.setBiilingTariffInfo.value > tariffGroupInfo.maxTariffPerUnit) {
            let hedaer = $localize `:@@customertariffs_hightariffheader:High tariff`;
            let description = $localize `:@@customertariffs_hightariffdescription:To high tariff input, please check value.`;
            this.modalService.ShowConfirmModal({ header: hedaer, description: description, showCancel: false }, (result) => {
            });
          }
          else {
            this.setBiilingTariffInfo.activeFromUtc = this.tariffChangeDates[this.setBiilingTariffInfo.activeFromIndex];
            if (tariffGroupInfo && tariffGroupInfo.unit?.length > 0) {
              this.setBiilingTariffInfo.unit = tariffGroupInfo.unit;
            }
            else {
              let tariffChangeRequests = this.tariffChangeRequestsByMeterType.find(x => x.meterType == this.setBiilingTariffInfo.meterTypeValue.name);
              if (tariffChangeRequests?.tariffChangeRequests?.length > 0) {
                this.setBiilingTariffInfo.unit = tariffChangeRequests.tariffChangeRequests.find(x => x.unit?.length > 0)?.unit ?? '';
              }
              else {
                tariffChangeRequests = this.tariffChangeRequestsByTariffGroup.find(x => x.meterType == this.setBiilingTariffInfo.meterTypeValue.name);
                if (tariffChangeRequests?.tariffChangeRequests?.length > 0) {
                  this.setBiilingTariffInfo.unit = tariffChangeRequests.tariffChangeRequests.find(x => x.unit?.length > 0)?.unit ?? '';
                }
              }
            }
            this.showNewTariffModal = true;
          }
        }
        else {
          let hedaer = $localize `:@@customertariffs_nometertypeheader:Missing metertype`;
          let description = $localize `:@@customertariffs_nometertypedescription:No metertype selected.`;
          this.modalService.ShowConfirmModal({ header: hedaer, description: description, showCancel: false }, (result) => {
          });
        }
      }
      else {
        if (this.setBiilingTariffInfo.meterTypeValue.name?.length > 0) {
          let meterTypeInfo = this.meterTypeInfos.find(x => x.meterType == this.setBiilingTariffInfo.meterTypeValue.name);
          if (meterTypeInfo &&  meterTypeInfo.maxTariffPerUnit > 0 && this.setBiilingTariffInfo.value > meterTypeInfo.maxTariffPerUnit) {
            let hedaer = $localize `:@@customertariffs_hightariffheader:High tariff`;
            let description = $localize `:@@customertariffs_hightariffdescription:To high tariff input, please check value.`;
            this.modalService.ShowConfirmModal({ header: hedaer, description: description, showCancel: false }, (result) => {
            });
          }
          else {
            this.setBiilingTariffInfo.activeFromUtc = this.tariffChangeDates[this.setBiilingTariffInfo.activeFromIndex];
            let tariffChangeRequests = this.tariffChangeRequestsByMeterType.find(x => x.meterType == this.setBiilingTariffInfo.meterTypeValue.name);
            if (tariffChangeRequests?.tariffChangeRequests?.length > 0) {
              this.setBiilingTariffInfo.unit = tariffChangeRequests.tariffChangeRequests.find(x => x.unit?.length > 0)?.unit ?? '';
            }
            else {
              tariffChangeRequests = this.tariffChangeRequestsByTariffGroup.find(x => x.meterType == this.setBiilingTariffInfo.meterTypeValue.name);
              if (tariffChangeRequests?.tariffChangeRequests?.length > 0) {
                this.setBiilingTariffInfo.unit = tariffChangeRequests.tariffChangeRequests.find(x => x.unit?.length > 0)?.unit ?? '';
              }
            }

            this.showNewTariffModal = true;
          }
        }
        else {
          let hedaer = $localize `:@@customertariffs_nometertypeheader:Missing metertype`;
          let description = $localize `:@@customertariffs_nometertypedescription:No metertype selected.`;
          this.modalService.ShowConfirmModal({ header: hedaer, description: description, showCancel: false }, (result) => {
          });
        }
      }
    }
    else {
      let hedaer = $localize `:@@customertariffs_nometertypeheader:Missing metertype`;
      let description = $localize `:@@customertariffs_nometertypedescription:No metertype selected.`;
      this.modalService.ShowConfirmModal({ header: hedaer, description: description, showCancel: false }, (result) => {
      });
    }
  }

  cancelNewTariffRequest() {
    this.showNewTariffModal = false;
    this.intiSetBillingInfo();
  }

  async saveNewTariffRequest() {
    this.showNewTariffModal = false;
    if (this.setBiilingTariffInfo.value >= 0 && this.setBiilingTariffInfo.meterTypeValue?.name?.length > 0) {
      let activeFromUtc = this.dateHelper.utils.addMinutes(this.setBiilingTariffInfo.activeFromUtc, -this.setBiilingTariffInfo.activeFromUtc.getTimezoneOffset());
      let result = await this.bmsCustomerAdminClient.createBillingTariffChangeRequest({
        activeFrom : activeFromUtc,
        value : this.setBiilingTariffInfo.value,
        meterType : this.setBiilingTariffInfo.meterTypeValue.isTariffgroup ? null : this.setBiilingTariffInfo.meterTypeValue.name,
        tariffGroup : this.setBiilingTariffInfo.meterTypeValue.isTariffgroup ? this.setBiilingTariffInfo.meterTypeValue.name : null,
        vatrate : 0.2,
        currency : 'SEK',
        customerId : this.data.customerId,
        includeVAT : true,
      });

      if (result.ok) {
        await this.updateBillingTariffChangeRequests();
        let hedaer = $localize `:@@customertariffs_confirmheader:New tariff set`;
        let description = $localize `:@@customertariffs_confirmdescription:New tariff registered ok.`;
        this.modalService.ShowConfirmModal({ header: hedaer, description: description, showCancel: false }, (result) => {

        });
      }
      else {
        let hedaer = $localize `:@@customertariffs_errorheader:Error create new tariff`;
        let description = $localize `:@@customertariffs_errordescription:An error occurred when set new tariff, pleas try again or contact support.`;
        this.modalService.ShowConfirmModal({ header: hedaer, description: description, showCancel: false }, (result) => {
        });
      }
    }
  }

  async deleteTariff(tariffInfo : CustomerTariffInfo, tariff : BillingTariffchangeRequest) {
    if (tariff) {
      let hedaer = $localize `:@@customertariffs_deleteheader:Delete tariff`;
      let description = $localize `:@@customertariffs_deletedescription:Delete tariff, are yuo sure.`;
      let cancelText = $localize `:@@customertariffs_deletecancel:Cancel`;
      let okText = $localize `:@@customertariffs_deleteok:Ok`;
      let result = await this.modalService.ShowConfirmModalAsync({ header: hedaer, description: description, ok : okText, cancel : cancelText});
      if (result) {
        let result = await this.bmsCustomerAdminClient.deleteBillingTariffChangeRequest(this.data.customerId, tariff.id)
        if (result) {
          let now = new Date();
          tariffInfo.allTariffs = tariffInfo.allTariffs.filter(x => x.id != tariff.id);
          tariffInfo.nextTariff = tariffInfo.allTariffs.sort((a, b) => a.activeFrom.toDate() < b.activeFrom.toDate() ? -1 : 1).find(x => x.activeFrom.toDate() > now)
          tariffInfo.allTariffs = tariffInfo.allTariffs.sort((a, b) => a.activeFrom.toDate() > b.activeFrom.toDate() ? -1 : 1);
        }
      }
    }
  }

  overrideLastInvoicedAtChange() {
    this.tariffChangeDates = this.getTariffChangeDates();
  }

  getTariffChangeDates(): Date[] {
    let result = [];
    let minStart = this.dateHelper.utils.addMonths(new Date(), -6);
    let startNow = this.dateHelper.utils.addDays(this.lastInvoicedAt, 1);
    if (startNow < minStart || this.overrideLastInvoicedAt) {
      startNow = minStart;
    }

    for (let i = 0; i < 10; i++) {
      let month = this.dateHelper.utils.addMonths(startNow, i);
      let startOfMonth = this.dateHelper.utils.startOfMonth(month);
      result.push(startOfMonth);
    }


    return result;
  }

  getTariffChangeDates2(billingPeriod: BmsBillingPeriod, startMonth: BmsMonthOfYear): Date[] {
    let result = [];
    let now = new Date();

    switch (billingPeriod ?? BmsBillingPeriod.Month) {
      // case BmsBillingPeriod.Week:
      //   let startOfWeek = this.dateHelper.utils.startOfWeek(now);
      //   let endOfWeek = this.dateHelper.utils.endOfWeek(now);
      //   for (let i = 0; i < 13; i++) {
      //     result.push({ start: this.dateHelper.utils.addWeeks(startOfWeek, -i), end: this.dateHelper.utils.addWeeks(endOfWeek, -i) });
      //   }
      //   break;

      case BmsBillingPeriod.Month:
        for (let i = 0; i < 10; i++) {
          let month = this.dateHelper.utils.addMonths(now, i);
          let startOfMonth = this.dateHelper.utils.startOfMonth(month);
          result.push(startOfMonth);
        }
        break;

      case BmsBillingPeriod.Quarter:
        let month = this.dateHelper.utils.getMonth(now);
        let offset = 0 + ((month + 4 - (1 + ((startMonth - 1) % 3))) % 3);
        let startNow = this.dateHelper.utils.addMonths(now, -offset);

        for (let i = 0; i < 10; i++) {
          let month = this.dateHelper.utils.addMonths(startNow, i);
          let startOfMonth = this.dateHelper.utils.startOfMonth(month);
          result.push(startOfMonth);
        }
        break;

      default:
        for (let i = 1; i < 25; i++) {
          let month = this.dateHelper.utils.addMonths(now, i);
          let startOfMonth = this.dateHelper.utils.startOfMonth(month);
          result.push(startOfMonth);
        }
        break;
    }

    return result;
  }
}
