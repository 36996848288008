import { Component, inject, OnInit } from '@angular/core';
import { BmsDataCollectorViewData } from '@core/models/bms-data-collector-view-data';
import { DashboardOutputChangeParameters, OutputDataType, LinkedWidgetSelectParameters, WidgetExportParameters, XprojAlertService, DateHelper, XprojModalService } from 'xproj-lib';
import { BmsDataCollectorComponent } from '@features/bms/bms-admin/bms-data-collector-view.component';
import { ClrLoadingState } from '@clr/angular';
import { StateService } from '@xprojectorcore/services/state-service';
import { XProjectorFilesClient } from '@xprojectorcore/xprojector_backend/xprojector-files-client';
import { XProjectorCustomerUsersClient } from '@xprojectorcore/xprojector_backend/xprojector-customerusers-client';
import { FileInfo } from '@xprojectorcore/models/file-info';
import { saveAs } from 'file-saver';
import { BmsStateService } from '@app/core/services/bms-state-service';
import { XProjectorBmsExportClient } from '@app/core/xprojector_backend/xprojector-bms-export-client';

@Component({
  selector: 'app-bms-gateway-data-collector-view',
  templateUrl: './bms-gateway-data-collector-view.component.html',
  styleUrls: ['./bms-gateway-data-collector-view.component.scss']
})
export class BmsGatewayDataCollectorViewComponent implements OnInit, BmsDataCollectorComponent {

  public static NodeTypeId : string = '_x_datacollectors_collector';

  private alertService: XprojAlertService = inject(XprojAlertService);
  private state : StateService = inject(StateService);
  private bmsExportClient: XProjectorBmsExportClient = inject(XProjectorBmsExportClient);
  private filesClient: XProjectorFilesClient = inject(XProjectorFilesClient);
  public dateHelper: DateHelper = inject(DateHelper);
  private modalService: XprojModalService = inject(XprojModalService);
  private customerUserClient: XProjectorCustomerUsersClient = inject(XProjectorCustomerUsersClient);

  data: BmsDataCollectorViewData;
  visible: boolean;

  responsiveWidth : number = 600;

  meterParameters : DashboardOutputChangeParameters[] = [];

  uploadFile: any;
  uploadingState: ClrLoadingState = ClrLoadingState.DEFAULT;
  backupFileInfo : FileInfo;

  exportConfigId : string = 'Piigab';
  exportConfigState: ClrLoadingState = ClrLoadingState.DEFAULT;

  constructor(

  ) { }

  ngOnInit(): void {
  }


  initDataCollectorView() {
    this.responsiveWidth = this.data.width;
    this.updateDashboardOutputs();
    this.updateGatewayBackupFileInfo();
  }

  setWidth(width: number) {
    if (this.data) {
      this.data.width = width;
    }

    this.responsiveWidth = width;
  }

  updateDashboardOutputs() {
    this.meterParameters = [];
    if (this.data.bmsCustomer) {

      let out = new DashboardOutputChangeParameters();
      out.outputParameterName = 'customerid';
      out.value = this.data.bmsCustomer.customerId;
      out.datatype = OutputDataType.String;
      this.meterParameters.push(out);

      out = new DashboardOutputChangeParameters();
      out.outputParameterName = 'customerxdbid';
      out.value = this.data.bmsCustomer.id;
      out.datatype = OutputDataType.Int64;
      this.meterParameters.push(out);

      out = new DashboardOutputChangeParameters();
      out.outputParameterName = 'id';
      out.value = +this.data.node.id;
      out.datatype = OutputDataType.Int64;
      this.meterParameters.push(out);
    }
  }

  async meterDashboardValueChanged(parameters: LinkedWidgetSelectParameters) {

  }

  selectGatewayFile(file) {
    if (file.files.length > 0) {
      this.uploadFile = file.files[0];
    }
  }

  async uploadGatewayFile() {
    if (this.data && this.uploadFile) {
      try {
        this.uploadingState = ClrLoadingState.LOADING;

        let result = await this.state.upsertFile('bmsgatewaybackup_' + this.data.node.id, this.uploadFile.name,
                this.uploadFile.type, ['bmsgatewaybackup'], false, this.uploadFile, this.data.customer.id);

        if (result) {
          this.uploadingState = ClrLoadingState.SUCCESS;
          this.alertService.success('Upload file ok!');
          await this.updateGatewayBackupFileInfo();
        }
        else {

          this.uploadingState = ClrLoadingState.ERROR;
        }
      }
      catch (err) {
        this.alertService.error('Error import file:', err);
      }
    }
  }

  async updateGatewayBackupFileInfo() {
    if (this.data) {
      this.backupFileInfo = await this.filesClient.getFileInfo('bmsgatewaybackup_' + this.data.node.id, this.data.customer.id);
    }

  }

  async downloadGatewayFile() {
    if (this.data && this.backupFileInfo) {
      this.filesClient.getFile('bmsgatewaybackup_' + this.data.node.id, this.data.customer.id)
      .then(blob => {
        saveAs(blob, this.backupFileInfo.name);
      })
      .catch(error => {
        this.alertService.error(error);
      });
    }
  }

  async deleteGatewayFile() {
    if (this.data && this.backupFileInfo) {
      let doDelete = await this.modalService.ShowConfirmModalAsync({
        header: 'Delete file',
        description: 'Delete backup file, are you sure?',
        ok: 'Delete',
        cancel: 'Cancel'
      });

      if (doDelete) {
        this.filesClient.deleteFileInfo('bmsgatewaybackup_' + this.data.node.id, this.data.customer.id)
        .then(result => {
          this.backupFileInfo = undefined;
          this.alertService.success('File deleted');
        })
        .catch(error => {
          this.alertService.error(error);
        });
      }
    }
  }

  async onWidgetExport(parameters: WidgetExportParameters) {
    let result = await this.customerUserClient.exportToExcel(parameters);

    if (!result) {
      this.alertService.error('Error export to excel!');
    }
  }

  async getGatewayConfig() {
    if (this.data) {
      this.bmsExportClient.exportGatewayConfig(this.data.customer.id, this.data.customer.name, +this.data.node.id, this.exportConfigId)
    }
  }
}
