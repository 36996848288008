<form clrForm clrLayout="vertical" autocomplete="off">
  <clr-select-container>
    <label>Trustee</label>
    <select clrSelect name="selecttrustee" [(ngModel)]="selectedTrustee">
      <option *ngFor="let trustee of trustees" [ngValue]="trustee">
        {{trustee.name}}
      </option>
    </select>
  </clr-select-container>
</form>

<button type="button" class="btn btn-outline" [clrLoading]="meterLoadingState" (click)="getMetersReport()">
  <clr-icon shape="export"></clr-icon>
  Get api report
</button>
