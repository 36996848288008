<as-split direction="horizontal" [unit]="'pixel'" (dragEnd)="onSplitDragEnd($event)" class="meter-view" [gutterSize]="7">
    <as-split-area [order]="1">
        <div class="detail-pane">
            <div class="meter-list">
                <app-bms-meter-list #bmsMeterList [selectedNode]="selectedNode" [selectedNodeParent]="selectedNodeParent" [showSearch]="true" (onSelectMeter)="onSelectMeter($event)">
                </app-bms-meter-list>
            </div>
            <div>
                <div class="card sensitive" *ngIf="selectedMeter">
                    <div class="card-header">
                        Detail view
                    </div>
                    <div class="card-block">

                        <clr-accordion [clrAccordionMultiPanel]="true">
                            <clr-accordion-panel [(clrAccordionPanelOpen)]="meterExpanded">
                                <clr-accordion-title>Meter Info</clr-accordion-title>
                                <clr-accordion-content>
                                    <app-bms-meter-view #meterViev [showData]="false"></app-bms-meter-view>
                                </clr-accordion-content>
                            </clr-accordion-panel>

                            <clr-accordion-panel [(clrAccordionPanelOpen)]="meterDataExpanded">
                                <clr-accordion-title>Meter Data</clr-accordion-title>
                                <clr-accordion-content>
                                    <app-bms-meter-view #meterVievData [showData]="true" [responsiveWidth]="contentPanelWidth">
                                    </app-bms-meter-view>
                                </clr-accordion-content>
                            </clr-accordion-panel>

                            <clr-accordion-panel [(clrAccordionPanelOpen)]="billingTariffExpanded">
                                <clr-accordion-title>Tariff</clr-accordion-title>
                                <clr-accordion-content>
                                    <app-bms-billing-tariff-view #billingTariffView></app-bms-billing-tariff-view>
                                </clr-accordion-content>
                            </clr-accordion-panel>

                            <clr-accordion-panel [(clrAccordionPanelOpen)]="apartmentExpanded">
                                <clr-accordion-title>Apartment</clr-accordion-title>
                                <clr-accordion-content>
                                    <app-bms-apartment-view #apartmentView></app-bms-apartment-view>
                                </clr-accordion-content>
                            </clr-accordion-panel>

                            <clr-accordion-panel [(clrAccordionPanelOpen)]="buildingAddressExpanded">
                                <clr-accordion-title>Building address</clr-accordion-title>
                                <clr-accordion-content>
                                    <app-bms-building-address-view #buildingAddressView></app-bms-building-address-view>
                                </clr-accordion-content>
                            </clr-accordion-panel>

                            <clr-accordion-panel [(clrAccordionPanelOpen)]="buildingExpanded">
                                <clr-accordion-title>Building</clr-accordion-title>
                                <clr-accordion-content>
                                    <app-bms-building-view #buildingView></app-bms-building-view>
                                </clr-accordion-content>
                            </clr-accordion-panel>

                            <clr-accordion-panel [(clrAccordionPanelOpen)]="realestateExpanded">
                                <clr-accordion-title>Realestate</clr-accordion-title>
                                <clr-accordion-content>
                                    <app-bms-realestate-view #realestateView></app-bms-realestate-view>
                                </clr-accordion-content>
                            </clr-accordion-panel>

                            <clr-accordion-panel [(clrAccordionPanelOpen)]="customerExpanded">
                                <clr-accordion-title>Customer</clr-accordion-title>
                                <clr-accordion-content>
                                    <app-bms-customer-view #customerView></app-bms-customer-view>
                                </clr-accordion-content>
                            </clr-accordion-panel>

                        </clr-accordion>
                    </div>
                </div>
            </div>
        </div>
    </as-split-area>
    <as-split-area [(size)]="rightPanelWidth" [order]="2">
        <!-- <app-bms-treeview (onTreeNodeSelect)="onSelectBmsTreeNode($event)"></app-bms-treeview> -->
        <app-configuration-datasource #bmsTree [sensitiveNodes]="['_x_bms_customer','_x_bms_realestate', '_x_bms_building', '_x_bms_buildingaddress']" *ngIf="bmsDataSourceInstance" [dataSourceInstance]="bmsDataSourceInstance" [highlightSelected]="true" [sortByName]="true"
            (onTreeNodeSelect)="onSelectBmsTreeNode($event)" [selectedPath]="bmsSelectedPath">
        </app-configuration-datasource>
    </as-split-area>
</as-split>