<div *ngIf="eventStatuses">
  <h3 i18n='@@customereventstatus_information' class="information-area">Events information</h3>
  <!-- <p i18n='@@customereventstatus_instructions' class="information-area">Click on the circles to confirm or close the message.
  </p> -->
  <clr-datagrid [clrDgLoading]="loadingEventStatuses" class="event-table">
    <clr-dg-action-bar>
      <div class="btn-group" style="float: right;">
        <button class="btn btn-sm btn-secondary" (click)="updateEventStatuses();"
          i18n='@@customereventstatus_refresh'>Refresh <clr-icon shape="refresh" directions="left"></clr-icon></button>
      </div>
    </clr-dg-action-bar>

    <clr-dg-column [clrDgField]="'location'" [style.min-width.px]="200">
      <ng-container i18n='@@customereventstatus_location'>Address</ng-container>
    </clr-dg-column>
    <clr-dg-column [clrDgField]="'externalId'" [clrDgSortBy]="externalIdSort" [clrDgSortOrder]="ascSort">
      <ng-container i18n='@@customereventstatus_apartmentno'>Apartment No</ng-container>
    </clr-dg-column>
    <clr-dg-column [clrDgField]="'svlantApartmentNo'" [style.min-width.px]="150">
      <ng-container i18n='@@customereventstatus_svlant'>Sv lant</ng-container>
    </clr-dg-column>
    <clr-dg-column class="col1">
      <ng-container></ng-container>
    </clr-dg-column>
    <clr-dg-column [clrDgField]="'message'" [style.min-width.px]="150" [clrDgSortBy]="messageSort">
      <ng-container i18n='@@customereventstatus_message'>Message</ng-container>
    </clr-dg-column>
    <clr-dg-column [clrDgField]="'timestamp'" [style.min-width.px]="170">
      <ng-container i18n='@@customereventstatus_time'>Time</ng-container>
    </clr-dg-column>

    <clr-dg-row *clrDgItems="let item of eventStatuses; let i = index" [clrDgItem]="item" [style.cursor]="'pointer'"
      [style.backgroundColor]="selectedIndex == i ? 'hsl(201, 29%, 88%)' : ''" (click)="onCellClick(item, i)">
      <clr-dg-cell class="left">{{item.location}}</clr-dg-cell>
      <clr-dg-cell class="left">{{item.externalId}}</clr-dg-cell>
      <clr-dg-cell class="left">{{item.svlantApartmentNo}}</clr-dg-cell>
      <clr-dg-cell class="col1">
        <clr-dropdown>
          <button [class]="item.stylingClass" clrDropdownTrigger></button>
          <!-- <clr-dropdown-menu clrPosition="bottom-left" *clrIfOpen>
            <div i18n='@@customereventstatus_ackn' aria-label="Dropdown header Acknowledge" clrDropdownItem
              (click)="acknowledgeEvent(item)" [class.disabled]="!item.event.active || item.event.acknowledged">
              Ackn.</div>
            <div i18n='@@customereventstatus_close' aria-label="Dropdown header Close" clrDropdownItem
              (click)="closeEvent(item)" [class.disabled]="!item.event.active">Close</div>
            <div i18n='@@customereventstatus_mute' *ngIf="!item.event.muted" aria-label="Dropdown header Mute"
              clrDropdownItem (click)="muteEvent(item)">Mute</div>
            <div i18n='@@customereventstatus_unute' *ngIf="item.event.muted" aria-label="Dropdown header Mute"
              clrDropdownItem (click)="unmuteEvent(item)">Unmute</div>
          </clr-dropdown-menu> -->
        </clr-dropdown>
      </clr-dg-cell>
      <clr-dg-cell class="left">{{item.message}}</clr-dg-cell>
      <clr-dg-cell class="left">{{dateHelper.utils.format(item.timestamp, 'keyboardDateTime')}}
      </clr-dg-cell>
    </clr-dg-row>

    <clr-dg-footer>
      <clr-dg-pagination #pagination [clrDgPageSize]="10">
        <clr-dg-page-size i18n='@@customereventstatus_sensors' [clrPageSizeOptions]="[10,20,50,100]">
          sensors</clr-dg-page-size>
        {{pagination.firstItem + 1}} - {{pagination.lastItem + 1}} of {{pagination.totalItems}} notifications
      </clr-dg-pagination>
    </clr-dg-footer>

  </clr-datagrid>

  <div class="customers-content-area" *ngIf="selectedEventStatus">
    <xproj-dashboard #dashboardMeter [editMode]="false" [showDashboardSettings]="false" [systemDashboard]="true"
      [dashboardId]="dashboardId" [enableExport]="false" [dashboardTag]="'latest'" [responsiveWidth]="responsiveWidth"
      [dashboardOutputParameters]="meterParameters" (onWidgetValueSelected)="meterDashboardValueChanged($event)">
    </xproj-dashboard>
  </div>
</div>
