import { Component, Input, OnInit, inject } from '@angular/core';
import { DeviceGroup } from '@app/core/xprojector_backend/proto/xprojector.module.thingsboard.pb';
import { ThingsboardClient } from '@app/core/xprojector_backend/proto/xprojector.module.thingsboard.pbsc';
import { XProjectorBmsThingsboardClient } from '@app/core/xprojector_backend/xprojector-bms-thingsboard-client';
import { BmsCustomer } from '@app/features/bms/models/bms-customer';
import { ClrLoadingState } from '@clr/angular';
import { StateService } from '@xprojectorcore/services/state-service';
import { Customer } from '@xprojectorcore/xprojector_backend/proto/xprojector.grpc.models.pb';
import { XprojAlertService, XprojModalService } from 'xproj-lib';

@Component({
  selector: 'app-bms-thingsboard',
  templateUrl: './bms-thingsboard.component.html',
  styleUrls: ['./bms-thingsboard.component.scss']
})
export class BmsThingsboardComponent implements OnInit {

  private state : StateService = inject(StateService);
  private alertService: XprojAlertService = inject(XprojAlertService);
  private modalService: XprojModalService = inject(XprojModalService);
  private thingsboardClient: XProjectorBmsThingsboardClient = inject(XProjectorBmsThingsboardClient);

  @Input() customer: Customer;
  @Input() bmsCustomer: BmsCustomer;

  deviceGroups : DeviceGroup[] = [];
  selectedDeviceGroup : DeviceGroup;

  loadingState: ClrLoadingState = ClrLoadingState.DEFAULT;


  constructor() { }

  ngOnInit() {
  }

  async updateDeviceGroups() {
    if (this.bmsCustomer) {
      let response = await this.thingsboardClient.getDeviceGroups(this.bmsCustomer.associationId);
      this.deviceGroups = response.deviceGroups;
    }
  }

  async importDeviceGroup() {
    if (this.bmsCustomer && this.selectedDeviceGroup) {
      let doAddOrUpdate = await this.modalService.ShowConfirmModalAsync({
        header: 'Import from thingsboard',
        description: 'Import "' + this.selectedDeviceGroup.name +'" from thingsboard , are you sure?',
        ok: 'Import',
        cancel: 'Cancel'
      });


      if (doAddOrUpdate) {
        try {
          this.loadingState = ClrLoadingState.LOADING;
          let response = await this.thingsboardClient.upsertDeviceGroup(this.bmsCustomer.customerId, this.selectedDeviceGroup.id);
          if (response.ok) {
            this.modalService.ShowConfirmModalAsync({ header: 'Import success', description: 'Device imported: ' + response.deviceCount });
          } else {
            this.modalService.ShowConfirmModalAsync({ header: 'Import error', description: 'Error: ' + response.message });
          }
        }
        finally {
          this.loadingState = ClrLoadingState.SUCCESS;
        }
      }
    }
  }

  async importData() {
    if (this.bmsCustomer && this.selectedDeviceGroup) {
      let doImport = await this.modalService.ShowConfirmModalAsync({
        header: 'Import data from thingsboard',
        description: 'Import data for "' + this.selectedDeviceGroup.name +'" from thingsboard , are you sure?',
        ok: 'Import',
        cancel: 'Cancel'
      });


      if (doImport) {
        try {
          this.loadingState = ClrLoadingState.LOADING;
          let response = await this.thingsboardClient.importData(this.selectedDeviceGroup.id);
          if (response.ok) {
            this.modalService.ShowConfirmModalAsync({ header: 'Import success', description: 'Device data imported: ' + response.deviceCount });
          } else {
            this.modalService.ShowConfirmModalAsync({ header: 'Import error', description: 'Error: ' + response.message });
          }
        }
        finally {
          this.loadingState = ClrLoadingState.SUCCESS;
        }
      }
    }
  }

}
