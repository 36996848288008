export class BmsFacility {
  id : number;
  buildingAddressId : number;
  buildingId : number;
  realestateId : number;

  name : string;
  facilityType : string;
  externalId : string;
  prefix : string;
  area : number;
  size : number;

  createdAt : Date;
  modifiedAt : Date;
}
