import { Injectable } from '@angular/core';
import { BmsCustomerConfig } from '@features/bms/models/bms-customer-config';
import { BmsCustomer } from '@features/bms/models/bms-customer';
import { XProjectorXConfClient } from '@xprojectorcore/xprojector_backend/xprojector-xconf-client';
import { resourceLimits } from 'worker_threads';
import { Aggregation, ArrayUtils, BaseQuery, BaseQueryInputColumnDescription, BaseQueryResult, ColumnFilteringNumerical, ColumnFilteringString, ColumnGroupingDescription, DateHelper, FilterComparator, FilterLogicalGroup, FilterLogicalGroupType, LifeSpan, ProjectionColumnDescription, ReturnType, SetDataColumnDescription, SetDataQuery, Transformation, XDataType, XProjectorClient } from 'xproj-lib';
import { BmsDataUtils, flakeId } from '../utils/bms-data-utils';
import { BmsBillingPeriod, BmsMonthOfYear } from '@core/models/bms-export-bot';
import { BmsRealestate } from '@features/bms/models/bms-realestate';
import { BmsBuilding } from '@features/bms/models/bms-building';
import { BmsBuildingAddress } from '@features/bms/models/bms-buildingaddress';
import { BmsSystem } from '@features/bms/models/bms-system';
import { BmsApartment } from '@features/bms/models/bms-apartment';
import { BmsFacility } from '@features/bms/models/bms-facility';
import { BmsMeter, BmsMeterState } from '@features/bms/models/bms-meter';
import { BmsGateway } from '@features/bms/models/bms-gateway';
import { NGXLogger } from 'ngx-logger';
import { BasicResponse, GrpcNode } from '@xprojectorcore/xprojector_backend/proto/xprojector.grpc.models.pb';
import { BmsMeterData } from '@features/bms/models/bms-meter-data';
import { BmsInvoiceInfo } from '@features/bms/models/bms-invoice-info'
import * as XLSX from 'xlsx';
import { GrpcNodeType, SearchNodesRequest, SearchProperty } from '@xprojectorcore/xprojector_backend/proto/xprojector.xconf.pb';
import { CacheService } from 'xproj-lib';
import { BmsTrustee } from '../../models/bms-trustee';
import { BmsLorawanMultiMeter } from '@app/core/models/bms-lorawan-multimeter';
import { BmsLorawanDeviceInfo } from '@app/core/models/lorawan-device-info';
import { BmsCustomerFlag } from '@features/bms/models/bms-customer-flag';
import { BmsLatestValue } from '@core/models/bms-latestvalue';
import { BmsGatewayInfo } from '@features/bms/models/bms-gateway-info';
import { XprojBimBuildingService } from '@xprojectorfeatures/bim/services/xproj-bim-building.service';
import { BimBuildingaddress } from '@xprojectorfeatures/bim/models/bim-buildingaddress';

const CUSTOMER_PROJECTIONID: string = 'hs-customer';
const CUSTOMER_REALESTATE_PROJECTIONID: string = 'hs-customer-realestate';
const REALESTATE_PROJECTIONID: string = 'hs-realestate';
const BUILDING_PROJECTIONID: string = 'hs-building';
const BUILDINGADDRESS_PROJECTIONID: string = 'hs-buildingaddress';
const APARTMENT_PROJECTIONID: string = 'hs-apartment';
const FACILITY_PROJECTIONID: string = 'hs-facility';
const METER_PROJECTIONID: string = 'hs-meter';
const INVOICEINFO_PROJECTIONID: string = 'hs-invoiceinfo';
const CUSTOMER_FLAGS_PROJECTIONID: string = 'hs-customer-flags';
const DATAPOINTLATEST_PROJECTIONID: string = 'hs-datapointvalue-latest';
const GATEWAYINFO_PROJECTIONID: string = 'hs-gateway-info';
const SYSTEM_PROJECTIONID: string = 'hs-system';

const CUSTOMERSCACHEKEY: string = 'adminservice-customers';
const TRUSTEESCACHEKEY: string = 'adminservice-trustees';

const LORAWANDEVICEINFO_PROJECTIONID: string = 'lw-lorawandeviceinfo';

const cacheLifeSpan: LifeSpan = { TTL: 600 };

@Injectable({
  providedIn: 'root'
})
export class BmsAdminService implements XprojBimBuildingService {
  customerColumns: ProjectionColumnDescription[] = [];
  customerRealestateColumns: ProjectionColumnDescription[] = [];
  realestateColumns: ProjectionColumnDescription[] = [];
  buildingColumns: ProjectionColumnDescription[] = [];
  buildingAddressColumns: ProjectionColumnDescription[] = [];
  apartmentColumns: ProjectionColumnDescription[] = [];
  facilityColumns: ProjectionColumnDescription[] = [];
  meterColumns: ProjectionColumnDescription[] = [];
  invoiceInfoColumns: ProjectionColumnDescription[] = [];
  loraWanDeviceInfoColumns: ProjectionColumnDescription[] = [];
  customerFlagsColumns: ProjectionColumnDescription[] = [];
  latestValuesColumns: ProjectionColumnDescription[] = [];
  gatewayInfoColumns: ProjectionColumnDescription[] = [];
  systemColumns: ProjectionColumnDescription[] = [];

  private setDataInvoiceInfoColumns: SetDataColumnDescription[] = [
    {
      columnname: 'id',
      datatype: XDataType.Int64,
      indexintypedvector: 0
    },
    {
      columnname: 'customerid',
      datatype: XDataType.String,
      indexintypedvector: 0
    },
    {
      columnname: 'metertype',
      datatype: XDataType.String,
      indexintypedvector: 1
    },
    {
      columnname: 'activefrom',
      datatype: XDataType.Timestamp,
      indexintypedvector: 0
    },
    {
      columnname: 'createdat',
      datatype: XDataType.Timestamp,
      indexintypedvector: 1
    },
    {
      columnname: 'modifiedat',
      datatype: XDataType.Timestamp,
      indexintypedvector: 2
    },
    {
      columnname: 'deletedat',
      datatype: XDataType.Timestamp,
      indexintypedvector: 3
    },
    {
      columnname: 'deleted',
      datatype: XDataType.UInt8,
      indexintypedvector: 1
    },
    {
      columnname: 'invoicedisabled',
      datatype: XDataType.UInt8,
      indexintypedvector: 2
    }
  ];

  constructor(
    private xconfClient: XProjectorXConfClient,
    private xprojClient: XProjectorClient,
    private dateHelper: DateHelper,
    private readonly cache: CacheService,
    private logger: NGXLogger
  ) {

  }

  async getBimBuildingaddresses(buildingId: number): Promise<BimBuildingaddress[]> {
    let result: BimBuildingaddress[] = [];
    let buildingAddresses = await this.getBuildingAddressesByBuilding(buildingId, true);

    buildingAddresses.forEach(x => {
      let bimBuildingAddress = new BimBuildingaddress();
      bimBuildingAddress.id = x.id;
      bimBuildingAddress.description = x.description;
      bimBuildingAddress.northof = x.northOf;
      bimBuildingAddress.eastof = x.eastOf;
      bimBuildingAddress.southof = x.southOf;
      bimBuildingAddress.westof = x.westOf;

      result.push(bimBuildingAddress);
    });

    return result;
  }

  async setBimBuildingaddresses(buildingId: number, bimBuildingAddresses: BimBuildingaddress[], customerId: string): Promise<boolean> {
    let buildingAddressesNodes = await this.xconfClient.getReferencedNodes(buildingId + '', '_x_bms_building', [], '_x_bms_buildingaddress', 1);

    await ArrayUtils.AsyncForEach(bimBuildingAddresses, (async (x: BimBuildingaddress) => {
      let buildingAddressesNode = buildingAddressesNodes.find(ba => ba.id == x.id + '');
      if (buildingAddressesNode) {
        let nodeChanged: boolean = false;
        buildingAddressesNode.propertyValues.forEach(p => {
          switch (p.key) {
            case 'northof':
              let prevNorthOf = BmsDataUtils.getValue(p.valueType, p.value, p, this.dateHelper);
              if (prevNorthOf != x.northof) {
                p.value = BmsDataUtils.getPropertyValue(p.valueType, x.northof, this.dateHelper);
                nodeChanged = true;
              }
              break;
            case 'eastof':
              let prevEastOf = BmsDataUtils.getValue(p.valueType, p.value, p, this.dateHelper);
              if (prevEastOf != x.eastof) {
                p.value = BmsDataUtils.getPropertyValue(p.valueType, x.eastof, this.dateHelper);
                nodeChanged = true;
              }
              break;
            case 'southof':
              let prevSouthOf = BmsDataUtils.getValue(p.valueType, p.value, p, this.dateHelper);
              if (prevSouthOf != x.southof) {
                p.value = BmsDataUtils.getPropertyValue(p.valueType, x.southof, this.dateHelper);
                nodeChanged = true;
              }
              break;
            case 'westof':
              let prevWestOf = BmsDataUtils.getValue(p.valueType, p.value, p, this.dateHelper);
              if (prevWestOf != x.westof) {
                p.value = BmsDataUtils.getPropertyValue(p.valueType, x.westof, this.dateHelper);
                nodeChanged = true;
              }
              break;
          }
        });

        if (nodeChanged) {
          await this.xconfClient.updateNode(buildingAddressesNode, buildingAddressesNode.id, '', customerId);
        }
      }
    }));


    return true;
  }

  async getCustomerConfigs(cusomterIds: string[] = null, nodeTypes: GrpcNodeType[] = null): Promise<BmsCustomerConfig[]> {
    let result: BmsCustomerConfig[] = [];

    let request = new SearchNodesRequest();
    request.limit = 2000;
    request.skip = 0;
    request.label = '_x_bms_customerconfig_root';

    let searchResult = await this.xconfClient.searchNodes(request);

    searchResult.nodes.forEach(node => {
      let i = node.id.lastIndexOf('_');
      let customerId = node.id.substring(i + 1);
      if (cusomterIds == null || cusomterIds.findIndex(x => x == customerId) > -1) {
        result.push(this.nodeToCustomerConfig(node, customerId, nodeTypes));
      }
    });
    return result;
  }

  async getCustomerConfig(customerId: string, nodeTypes: GrpcNodeType[] = null): Promise<BmsCustomerConfig> {
    let customerConfigNode = await this.xconfClient.getNode('_x_bms_customerconfig_root_' + customerId, '_x_bms_customerconfig_root');
    return this.nodeToCustomerConfig(customerConfigNode, customerId, nodeTypes);
  }

  nodeToCustomerConfig(customerConfigNode: GrpcNode, customerId: string, nodeTypes: GrpcNodeType[] = null): BmsCustomerConfig {
    let result: BmsCustomerConfig = new BmsCustomerConfig();
    result.customerId = customerId;

    if (customerConfigNode) {

      customerConfigNode.propertyValues.forEach(p => {
        switch (p.key) {

          case 'organisationnumber':
            result.organisationNumber = BmsDataUtils.getValue(p.valueType, p.value, p, this.dateHelper);
            break;
          case 'associationid':
            result.associationId = BmsDataUtils.getValue(p.valueType, p.value, p, this.dateHelper);
            break;
          case 'associationid2':
            result.associationId2 = BmsDataUtils.getValue(p.valueType, p.value, p, this.dateHelper);
            break;
          case 'tariffdecimalcount':
            result.tariffDecimalCount = BmsDataUtils.getValue(p.valueType, p.value, p, this.dateHelper);
            break;
          case 'reportdatapointdecimalcount':
            result.reportDataPointDecimalCount = BmsDataUtils.getValue(p.valueType, p.value, p, this.dateHelper);
            break;
          case 'trustee':
            result.trustee = BmsDataUtils.getValue(p.valueType, p.value, p, this.dateHelper);
            break;
          case 'customeristrustee':
            result.customerIsTrustee = BmsDataUtils.getValue(p.valueType, p.value, p, this.dateHelper);
            break;
          case 'mergechargingstationswithel':
            result.mergeChargingStationsWithEl = BmsDataUtils.getValue(p.valueType, p.value, p, this.dateHelper);
            break;
          case 'billingenabled':
            result.billingEnabled = BmsDataUtils.getValue(p.valueType, p.value, p, this.dateHelper);
            break;
          case 'billingperiod':
            result.billingPeriod = +BmsDataUtils.getValue(p.valueType, p.value, p, this.dateHelper);
            if (isNaN(result.billingPeriod)) {
              result.billingPeriod = BmsBillingPeriod[BmsDataUtils.getValue(p.valueType, p.value, p, this.dateHelper) as string];
            }
            break;
          case 'billingperiodchangedat':
            result.billingPeriodChangedAt = BmsDataUtils.getValue(p.valueType, p.value, p, this.dateHelper);
            result.billingPeriodChangedAtString = this.dateHelper.utils.formatByString(result.billingPeriodChangedAt, 'yyyy-MM-dd');
            break;
          case 'billingperiodstartmonth':
            result.billingPeriodStartMonth = +BmsDataUtils.getValue(p.valueType, p.value, p, this.dateHelper);
            if (isNaN(result.billingPeriodStartMonth)) {
              result.billingPeriodStartMonth = BmsMonthOfYear[BmsDataUtils.getValue(p.valueType, p.value, p, this.dateHelper) as string];
            }
            break;
          case 'disabled':
            result.disabled = BmsDataUtils.getValue(p.valueType, p.value, p, this.dateHelper);
            break;
          case 'disabledat':
            result.disabledAt = BmsDataUtils.getValue(p.valueType, p.value, p, this.dateHelper);
            break;
          case 'extrapolatecoefficient':
            result.extrapolateCoefficient = BmsDataUtils.getValue(p.valueType, p.value, p, this.dateHelper);
            break;
          case 'paddingexternalidcount':
            result.paddingExternalIdCount = BmsDataUtils.getValue(p.valueType, p.value, p, this.dateHelper);
            break;
          case 'paddingexternalidoverridetrustee':
            result.paddingExternalIdOverrideTrustee = BmsDataUtils.getValue(p.valueType, p.value, p, this.dateHelper);
            break;
          case 'simcardcount':
            result.simCardCount = BmsDataUtils.getValue(p.valueType, p.value, p, this.dateHelper);
            break;
          case 'contracttype':
            result.contractType = BmsDataUtils.getValue(p.valueType, p.value, p, this.dateHelper);
            if (nodeTypes) {
              let nodeType = nodeTypes.find(nt => nt.id == '_x_bms_customerconfig_root');
              if (nodeType) {
                result.contractTypeStringOptionsProperty = nodeType.stringOptionsProperties.find(sp => sp.id == p.key);
              }
            }
            break;
          case 'eventsenabled':
            result.eventsEnabled = BmsDataUtils.getValue(p.valueType, p.value, p, this.dateHelper);
            break;
          case 'information':
            result.information = BmsDataUtils.getValue(p.valueType, p.value, p, this.dateHelper);
            break;
          case 'operationinfo':
            result.operationInfo = BmsDataUtils.getValue(p.valueType, p.value, p, this.dateHelper);
            break;
          case 'invoiceday':
            result.invoiceDay = BmsDataUtils.getValue(p.valueType, p.value, p, this.dateHelper);
            break;
          case 'invoiceinfo':
            result.invoiceInfo = BmsDataUtils.getValue(p.valueType, p.value, p, this.dateHelper);
            break;
          case 'partnercompensation':
            result.partnerCompensation = BmsDataUtils.getValue(p.valueType, p.value, p, this.dateHelper);
            if (nodeTypes) {
              let nodeType = nodeTypes.find(nt => nt.id == '_x_bms_customerconfig_root');
              if (nodeType) {
                result.partnerCompensationStringOptionsProperty = nodeType.stringOptionsProperties.find(sp => sp.id == p.key);
              }
            }
            break;
        }
      });
    }

    return result;
  }

  async saveCustomerConfig(customerConfig: BmsCustomerConfig, customerConfigNode: GrpcNode = undefined): Promise<BasicResponse> {
    if (!customerConfigNode) {
      customerConfigNode = await this.xconfClient.getNode('_x_bms_customerconfig_root_' + customerConfig.customerId, '_x_bms_customerconfig_root');
    }

    if (customerConfigNode) {
      customerConfigNode.propertyValues.forEach(p => {
        switch (p.key) {

          case 'organisationnumber':
            p.value = BmsDataUtils.getPropertyValue(p.valueType, customerConfig.organisationNumber, this.dateHelper);
            break;
          case 'associationid':
            p.value = BmsDataUtils.getPropertyValue(p.valueType, customerConfig.associationId, this.dateHelper);
            break;
          case 'associationid2':
            p.value = BmsDataUtils.getPropertyValue(p.valueType, customerConfig.associationId2, this.dateHelper);
            break;
          case 'tariffdecimalcount':
            p.value = BmsDataUtils.getPropertyValue(p.valueType, customerConfig.tariffDecimalCount, this.dateHelper);
            break;
          case 'reportdatapointdecimalcount':
            p.value = BmsDataUtils.getPropertyValue(p.valueType, customerConfig.reportDataPointDecimalCount, this.dateHelper);
            break;
          case 'trustee':
            p.value = BmsDataUtils.getPropertyValue(p.valueType, customerConfig.trustee, this.dateHelper);
            break;
          case 'customeristrustee':
            p.value = BmsDataUtils.getPropertyValue(p.valueType, customerConfig.customerIsTrustee, this.dateHelper);
            break;
          case 'mergechargingstationswithel':
            p.value = BmsDataUtils.getPropertyValue(p.valueType, customerConfig.mergeChargingStationsWithEl, this.dateHelper);
            break;
          case 'billingenabled':
            p.value = BmsDataUtils.getPropertyValue(p.valueType, customerConfig.billingEnabled, this.dateHelper);
            break;
          case 'billingperiod':
            p.value = BmsDataUtils.getPropertyValue(p.valueType, customerConfig.billingPeriod, this.dateHelper);
            break;
          case 'billingperiodchangedat':
            customerConfig.billingPeriodChangedAt = this.dateHelper.utils.parse(customerConfig.billingPeriodChangedAtString, 'yyyy-MM-dd');
            p.value = BmsDataUtils.getPropertyValue(p.valueType, this.dateHelper.utils.addMinutes(customerConfig.billingPeriodChangedAt, -customerConfig.billingPeriodChangedAt.getTimezoneOffset()), this.dateHelper);
            break;
          case 'billingperiodstartmonth':
            p.value = BmsDataUtils.getPropertyValue(p.valueType, customerConfig.billingPeriodStartMonth, this.dateHelper);
            break;
          case 'extrapolatecoefficient':
            p.value = BmsDataUtils.getPropertyValue(p.valueType, customerConfig.extrapolateCoefficient, this.dateHelper);
            break;
          case 'disabled':
            p.value = BmsDataUtils.getPropertyValue(p.valueType, customerConfig.disabled, this.dateHelper);
            break;
          case 'paddingexternalidcount':
            p.value = BmsDataUtils.getPropertyValue(p.valueType, customerConfig.paddingExternalIdCount, this.dateHelper);
            break;
          case 'paddingexternalidoverridetrustee':
            p.value = BmsDataUtils.getPropertyValue(p.valueType, customerConfig.paddingExternalIdOverrideTrustee, this.dateHelper);
            break;
          case 'simcardcount':
            p.value = BmsDataUtils.getPropertyValue(p.valueType, customerConfig.simCardCount, this.dateHelper);
            break;
          case 'contracttype':
            p.value = BmsDataUtils.getPropertyValue(p.valueType, customerConfig.contractType, this.dateHelper);
            break;
          case 'eventsenabled':
            p.value = BmsDataUtils.getPropertyValue(p.valueType, customerConfig.eventsEnabled, this.dateHelper);
            break;
          case 'information':
            p.value = BmsDataUtils.getPropertyValue(p.valueType, customerConfig.information, this.dateHelper);
            break;
          case 'operationinfo':
            p.value = BmsDataUtils.getPropertyValue(p.valueType, customerConfig.operationInfo, this.dateHelper);
            break;
          case 'invoiceday':
            p.value = BmsDataUtils.getPropertyValue(p.valueType, customerConfig.invoiceDay, this.dateHelper);
            break;
          case 'invoiceinfo':
            p.value = BmsDataUtils.getPropertyValue(p.valueType, customerConfig.invoiceInfo, this.dateHelper);
            break;
          case 'partnercompensation':
            p.value = BmsDataUtils.getPropertyValue(p.valueType, customerConfig.partnerCompensation, this.dateHelper);
            break;
        }
      });

      this.cache.remove(CUSTOMERSCACHEKEY);
      this.cache.remove(TRUSTEESCACHEKEY);
      return this.xconfClient.updateNode(customerConfigNode, customerConfigNode.id, '', customerConfig.customerId);
    }
    else {
      return new BasicResponse({ result: false, message: 'Error get customer config node!' });
    }
  }

  async deleteCustomerConfig(customerId: string): Promise<BasicResponse> {
    return await this.xconfClient.deleteNode('_x_bms_customerconfig_root_' + customerId, '_x_bms_customerconfig_root', '', customerId);
  }

  async saveCustomerConfigInfos(customerConfig: BmsCustomerConfig): Promise<BasicResponse> {
    let customerConfigNode = await this.xconfClient.getNode('_x_bms_customerconfig_root_' + customerConfig.customerId, '_x_bms_customerconfig_root');

    if (customerConfigNode) {
      customerConfigNode.propertyValues.forEach(p => {
        switch (p.key) {
          case 'operationinfo':
            p.value = BmsDataUtils.getPropertyValue(p.valueType, customerConfig.operationInfo, this.dateHelper);
            break;
          case 'invoiceinfo':
            p.value = BmsDataUtils.getPropertyValue(p.valueType, customerConfig.invoiceInfo, this.dateHelper);
            break;
        }
      });
      return this.xconfClient.updateNode(customerConfigNode, customerConfigNode.id, '', customerConfig.customerId);
    }
    else {
      return new BasicResponse({ result: false, message: 'Error get customer config node!' });
    }
  }

  async getCustomers(forceReload: boolean = false): Promise<BmsCustomer[]> {
    return this._getCustomers(forceReload);
  }

  async getCustomer(customerId: string, forceReload: boolean = false): Promise<BmsCustomer> {
    let result: BmsCustomer;
    if (this.customerColumns.length == 0) {
      this.customerColumns = await this.xprojClient.RequestListQueryableProjectionColumns(CUSTOMER_PROJECTIONID, '', 0, 100);
    }

    let query: BaseQuery = new BaseQuery();
    query.targetprojectionid = CUSTOMER_PROJECTIONID;
    query.maxitems = 1;
    query.targetgroup = [];

    let filterId = 0;
    query.filter.type = FilterLogicalGroupType.AND;

    let customerIdFiltering = new ColumnFilteringString();
    customerIdFiltering.columnname = 'customerid';
    customerIdFiltering.comparator = FilterComparator.Equals;
    customerIdFiltering.value = customerId;
    customerIdFiltering.queryfilterid = ++filterId;
    query.filter.filters.push(customerIdFiltering.queryfilterid);
    query.stringfilters.push(customerIdFiltering);

    let deletedFiltering = new ColumnFilteringNumerical();
    deletedFiltering.columnname = 'deleted';
    deletedFiltering.comparator = FilterComparator.Equals;
    deletedFiltering.value = 0;
    deletedFiltering.queryfilterid = ++filterId;
    query.filter.filters.push(deletedFiltering.queryfilterid);
    query.numericalfilters.push(deletedFiltering);

    this.customerColumns.forEach(c => {
      let inCol = new BaseQueryInputColumnDescription();
      inCol.columnname = c.columnname;
      inCol.columnoutname = c.columnname;
      query.columns.push(inCol);
    });

    query.sorting.columnname = 'customername';
    query.sorting.descending = false;

    try {
      let queryResult = await this.xprojClient.RequestQueryBaseQuery(query, forceReload);

      let customerResults = this._getCustomersFromQueryResult(queryResult);

      if (customerResults.length > 0) {
        result = customerResults[0];
      }
    }
    catch (err) {
      this.logger.error(err);
    }

    return result;
  }

  private async _getCustomers(forceReload: boolean = false): Promise<BmsCustomer[]> {
    if (!forceReload && this.cache.has(CUSTOMERSCACHEKEY)) {
      return this.cache.get(CUSTOMERSCACHEKEY);
    }
    else {
      let result: BmsCustomer[] = [];
      if (this.customerColumns.length == 0) {
        this.customerColumns = await this.xprojClient.RequestListQueryableProjectionColumns(CUSTOMER_PROJECTIONID, '', 0, 100);
      }

      let query: BaseQuery = new BaseQuery();
      query.targetprojectionid = CUSTOMER_PROJECTIONID;
      query.maxitems = 5000;
      query.targetgroup = [];

      let filterId = 0;
      query.filter.type = FilterLogicalGroupType.AND;

      let deletedFiltering = new ColumnFilteringNumerical();
      deletedFiltering.columnname = 'deleted';
      deletedFiltering.comparator = FilterComparator.Equals;
      deletedFiltering.value = 0;
      deletedFiltering.queryfilterid = ++filterId;
      query.filter.filters.push(deletedFiltering.queryfilterid);
      query.numericalfilters.push(deletedFiltering);

      this.customerColumns.forEach(c => {
        let inCol = new BaseQueryInputColumnDescription();
        inCol.columnname = c.columnname;
        inCol.columnoutname = c.columnname;
        query.columns.push(inCol);
      });

      query.sorting.columnname = 'customername';
      query.sorting.descending = false;

      try {
        let queryResult = await this.xprojClient.RequestQueryBaseQuery(query, forceReload);

        result = this._getCustomersFromQueryResult(queryResult);
      }
      catch (err) {
        this.logger.error(err);
      }

      if (result.length > 0) {
        this.cache.set(CUSTOMERSCACHEKEY, result, ReturnType.Scalar, cacheLifeSpan);
      }

      return result;
    }
  }

  private _getCustomersFromQueryResult(queryResult: BaseQueryResult): BmsCustomer[] {
    let result: BmsCustomer[] = [];
    try {
      let numericaldata = queryResult.datanumbers;
      let timestampdata = queryResult.datatimestamps;
      let stringdata = queryResult.datastrings;

      let rowCount = 0;
      if (numericaldata.length > 0) {
        rowCount = numericaldata[0].length;
      }

      for (let row = 0; row < rowCount; row++) {
        let customer = new BmsCustomer();
        for (let i = 0; i < queryResult.columns.length; i++) {
          let it = queryResult.columns[i];
          let typ = it.datatype;
          let data = [];
          if (typ == XDataType.Number) {
            data = numericaldata[it.indexintypedvector];
          }
          if (typ == XDataType.String) {
            data = stringdata[it.indexintypedvector];
          }
          if (typ == XDataType.Timestamp) {
            data = timestampdata[it.indexintypedvector];
          }

          switch (it.columnoutname) {
            case 'id':
              customer.id = data[row];
              break;
            case 'customerid':
              customer.customerId = data[row];
              break;
            case 'organisationnumber':
              customer.organisationNumber = data[row];
              break;
            case 'customername':
              customer.customerName = data[row];
              break;
            case 'associationid':
              customer.associationId = data[row];
              break;
            case 'customeristrustee':
              customer.customerIsTrustee = data[row] > 0;
              break;
            case 'extrapolateenabled':
              customer.extrapolateEnabled = data[row] > 0;
              break;
            case 'extrapolatecoefficient':
              customer.extrapolateCoefficient = data[row];
              break;
            case 'tariffdecimalcount':
              customer.tariffDecimalCount = data[row];
              break;
            case 'extrapolatecoefficient':
              customer.extrapolateCoefficient = data[row];
              break;
            case 'disabled':
              customer.disabled = data[row] > 0;
              break;
          }
        }

        result.push(customer);
      }
    }
    catch (err) {
      this.logger.error(err);
    }

    return result;
  }

  async getTrustees(forceReload: boolean = false): Promise<BmsTrustee[]> {
    if (!forceReload && this.cache.has(TRUSTEESCACHEKEY)) {
      return this.cache.get(TRUSTEESCACHEKEY);
    }
    else {
      let request = new SearchNodesRequest();
      request.rootId = '_x_xconf_customers';
      request.rootLabel = '_x_datasource';
      request.label = '_x_bms_customerconfig_root';
      let p = new SearchProperty();
      p.key = 'customeristrustee';
      p.value = 'true';
      p.typeName = 'boolean';

      request.properties = [p];
      request.propertiesOperatorAnd = true;
      request.limit = 500;
      request.maxHops = 5;
      request.skip = 0;

      let trusteeConfigNodes = await this.xconfClient.searchNodes(request);

      let customrIds: string[] = [];
      trusteeConfigNodes.nodes.forEach(node => {
        let i = node.id.lastIndexOf('_');
        customrIds.push(node.id.substring(i + 1));
      });

      request.rootId = '_x_xconf_customers';
      request.rootLabel = '_x_datasource';
      request.label = '_x_datasource';
      request.properties = [];
      request.limit = 500;
      request.maxHops = 1;
      request.skip = 0;
      request.propertiesOperatorAnd = false;

      customrIds.forEach(id => {
        let p = new SearchProperty();
        p.key = '_id';
        p.value = id;
        p.typeName = 'string';
        request.properties.push(p);
      });

      let customerNodes = await this.xconfClient.searchNodes(request);

      let result: BmsTrustee[] = [];
      customerNodes.nodes.forEach(node => {
        let trustee = new BmsTrustee();
        trustee.id = node.id;
        trustee.name = node.name;
        let trusteeConfigNode = trusteeConfigNodes.nodes.find(n => n.id.endsWith('_' + node.id));
        trustee.billingEnabled = BmsDataUtils.getParamterValue(trusteeConfigNode, 'billingenabled', null);
        trustee.enabled = !BmsDataUtils.getParamterValue(trusteeConfigNode, 'disabled', null);
        if (trustee.enabled) {
          result.push(trustee);
        }
      });

      if (result.length > 0) {
        this.cache.set(TRUSTEESCACHEKEY, result, ReturnType.Scalar, cacheLifeSpan);
      }

      return result;
    }
  }

  async getCustomerIdByRealestate(realestateId: number): Promise<string> {

    let result: string = '';
    let query: BaseQuery = new BaseQuery();
    query.targetprojectionid = CUSTOMER_REALESTATE_PROJECTIONID;
    query.maxitems = 1;
    query.targetgroup = [];

    let filterId = 0;
    query.filter.type = FilterLogicalGroupType.AND;

    let realestateFiltering = new ColumnFilteringNumerical();
    realestateFiltering.columnname = 'realestateid';
    realestateFiltering.comparator = FilterComparator.Equals;
    realestateFiltering.value = realestateId;
    realestateFiltering.queryfilterid = ++filterId;
    query.filter.filters.push(realestateFiltering.queryfilterid);
    query.numericalfilters.push(realestateFiltering);

    let deletedFiltering = new ColumnFilteringNumerical();
    deletedFiltering.columnname = 'deleted';
    deletedFiltering.comparator = FilterComparator.Equals;
    deletedFiltering.value = 0;
    deletedFiltering.queryfilterid = ++filterId;
    query.filter.filters.push(deletedFiltering.queryfilterid);
    query.numericalfilters.push(deletedFiltering);

    let inCol = new BaseQueryInputColumnDescription();
    inCol.columnname = 'customerid';
    inCol.columnoutname = 'customerid';
    query.columns.push(inCol);

    try {
      let queryResult = await this.xprojClient.RequestQueryBaseQuery(query, true);

      if (queryResult.datastrings.length > 0 && queryResult.datastrings[0].length > 0) {
        result = queryResult.datastrings[0][0];
      }

    }
    catch (err) {
      this.logger.error(err);
    }

    return result;
  }

  async getRealestates(customerXdbId: number, forceReload: boolean = false): Promise<BmsRealestate[]> {
    let result: BmsRealestate[] = [];

    let realestateIds = await this.getRealestateXdbIds(customerXdbId, false);

    if (realestateIds.length > 0) {
      if (this.realestateColumns.length == 0) {
        this.realestateColumns = await this.xprojClient.RequestListQueryableProjectionColumns(REALESTATE_PROJECTIONID, '', 0, 100);
      }

      let query: BaseQuery = new BaseQuery();
      query.targetprojectionid = REALESTATE_PROJECTIONID;
      query.maxitems = 5000;
      query.targetgroup = [];

      let filterId = 0;
      query.filter.type = FilterLogicalGroupType.AND;

      let realesateFilterGroup = new FilterLogicalGroup();
      realesateFilterGroup.type = FilterLogicalGroupType.OR;
      realesateFilterGroup.queryfilterid = filterId++;

      realestateIds.forEach(realestateId => {
        let realestateFiltering = new ColumnFilteringNumerical();
        realestateFiltering.columnname = 'id';
        realestateFiltering.comparator = FilterComparator.Equals;
        realestateFiltering.value = realestateId;
        realestateFiltering.queryfilterid = ++filterId;
        realesateFilterGroup.filters.push(realestateFiltering.queryfilterid);
        query.numericalfilters.push(realestateFiltering);
      });
      query.filter.filters.push(realesateFilterGroup.queryfilterid);
      query.subfiltergroups.push(realesateFilterGroup);

      let deletedFiltering = new ColumnFilteringNumerical();
      deletedFiltering.columnname = 'deleted';
      deletedFiltering.comparator = FilterComparator.Equals;
      deletedFiltering.value = 0;
      deletedFiltering.queryfilterid = ++filterId;
      query.filter.filters.push(deletedFiltering.queryfilterid);
      query.numericalfilters.push(deletedFiltering);

      this.realestateColumns.forEach(c => {
        let inCol = new BaseQueryInputColumnDescription();
        inCol.columnname = c.columnname;
        inCol.columnoutname = c.columnname;
        query.columns.push(inCol);
      });

      query.sorting.columnname = 'svlant-propertydesignation';
      query.sorting.descending = false;

      try {
        let queryResult = await this.xprojClient.RequestQueryBaseQuery(query, forceReload);

        let numericaldata = queryResult.datanumbers;
        let timestampdata = queryResult.datatimestamps;
        let stringdata = queryResult.datastrings;

        let rowCount = 0;
        if (numericaldata.length > 0) {
          rowCount = numericaldata[0].length;
        }

        for (let row = 0; row < rowCount; row++) {
          let realestate = new BmsRealestate();
          for (let i = 0; i < queryResult.columns.length; i++) {
            let it = queryResult.columns[i];
            let typ = it.datatype;
            let data = [];
            if (typ == XDataType.Number) {
              data = numericaldata[it.indexintypedvector];
            }
            if (typ == XDataType.String) {
              data = stringdata[it.indexintypedvector];
            }
            if (typ == XDataType.Timestamp) {
              data = timestampdata[it.indexintypedvector];
            }

            switch (it.columnoutname) {
              case 'id':
                realestate.id = data[row];
                break;
              case 'svlant-propertydesignation':
                realestate.svlantPropertyDesignation = data[row];
                break;
              case 'description':
                realestate.description = data[row];
                break;
              case 'latitude':
                realestate.latitude = data[row];
                break;
              case 'longitude':
                realestate.longitude = data[row];
                break;
              case 'createdat':
                realestate.createdAt = data[row];
                break;
              case 'modifiedat':
                realestate.modifiedAt = data[row];
                break;
            }
          }

          result.push(realestate);
        }
      }
      catch (err) {
        this.logger.error(err);
      }
    }

    return result;
  }

  async getRealestateXdbIds(customerXdbId: number, forceReload: boolean = false): Promise<number[]> {
    let result: number[] = [];
    if (this.customerRealestateColumns.length == 0) {
      this.customerRealestateColumns = await this.xprojClient.RequestListQueryableProjectionColumns(CUSTOMER_REALESTATE_PROJECTIONID, '', 0, 100);
    }

    let query: BaseQuery = new BaseQuery();
    query.targetprojectionid = CUSTOMER_REALESTATE_PROJECTIONID;
    query.maxitems = 5000;
    query.targetgroup = [];

    let filterId = 0;
    query.filter.type = FilterLogicalGroupType.AND;

    let customerFiltering = new ColumnFilteringNumerical();
    customerFiltering.columnname = 'customerxdbid';
    customerFiltering.comparator = FilterComparator.Equals;
    customerFiltering.value = customerXdbId;
    customerFiltering.queryfilterid = ++filterId;
    query.filter.filters.push(customerFiltering.queryfilterid);
    query.numericalfilters.push(customerFiltering);

    let deletedFiltering = new ColumnFilteringNumerical();
    deletedFiltering.columnname = 'deleted';
    deletedFiltering.comparator = FilterComparator.Equals;
    deletedFiltering.value = 0;
    deletedFiltering.queryfilterid = ++filterId;
    query.filter.filters.push(deletedFiltering.queryfilterid);
    query.numericalfilters.push(deletedFiltering);

    this.customerRealestateColumns.forEach(c => {
      let inCol = new BaseQueryInputColumnDescription();
      inCol.columnname = c.columnname;
      inCol.columnoutname = c.columnname;
      query.columns.push(inCol);
    });

    try {
      let queryResult = await this.xprojClient.RequestQueryBaseQuery(query, forceReload);

      let numericaldata = queryResult.datanumbers;

      let rowCount = 0;
      if (numericaldata.length > 0) {
        rowCount = queryResult.datanumbers[0].length;
      }

      let realestateIdColumn = queryResult.columns.find(col => col.columnoutname == 'realestateid');
      for (let row = 0; row < rowCount; row++) {
        result.push(numericaldata[realestateIdColumn.indexintypedvector][row]);
      }
    }
    catch (err) {
      this.logger.error(err);
    }

    return result;
  }

  async getBuildings(customerXdbId: number, forceReload: boolean = false): Promise<BmsBuilding[]> {
    let result: BmsBuilding[] = [];

    let realestateIds = await this.getRealestateXdbIds(customerXdbId, false);

    if (realestateIds.length > 0) {
      if (this.buildingColumns.length == 0) {
        this.buildingColumns = await this.xprojClient.RequestListQueryableProjectionColumns(BUILDING_PROJECTIONID, '', 0, 100);
      }

      let query: BaseQuery = new BaseQuery();
      query.targetprojectionid = BUILDING_PROJECTIONID;
      query.maxitems = 5000;
      query.targetgroup = [];

      let filterId = 0;
      query.filter.type = FilterLogicalGroupType.AND;

      let realesateFilterGroup = new FilterLogicalGroup();
      realesateFilterGroup.type = FilterLogicalGroupType.OR;
      realesateFilterGroup.queryfilterid = filterId++;

      realestateIds.forEach(realestateId => {
        let realestateFiltering = new ColumnFilteringNumerical();
        realestateFiltering.columnname = 'realestateid';
        realestateFiltering.comparator = FilterComparator.Equals;
        realestateFiltering.value = realestateId;
        realestateFiltering.queryfilterid = ++filterId;
        realesateFilterGroup.filters.push(realestateFiltering.queryfilterid);
        query.numericalfilters.push(realestateFiltering);
      });
      query.filter.filters.push(realesateFilterGroup.queryfilterid);
      query.subfiltergroups.push(realesateFilterGroup);

      let deletedFiltering = new ColumnFilteringNumerical();
      deletedFiltering.columnname = 'deleted';
      deletedFiltering.comparator = FilterComparator.Equals;
      deletedFiltering.value = 0;
      deletedFiltering.queryfilterid = ++filterId;
      query.filter.filters.push(deletedFiltering.queryfilterid);
      query.numericalfilters.push(deletedFiltering);

      this.buildingColumns.forEach(c => {
        let inCol = new BaseQueryInputColumnDescription();
        inCol.columnname = c.columnname;
        inCol.columnoutname = c.columnname;
        query.columns.push(inCol);
      });

      query.sorting.columnname = 'svlant-buildingno';
      query.sorting.descending = false;

      try {
        let queryResult = await this.xprojClient.RequestQueryBaseQuery(query, forceReload);

        let numericaldata = queryResult.datanumbers;
        let timestampdata = queryResult.datatimestamps;
        let stringdata = queryResult.datastrings;

        let rowCount = 0;
        if (numericaldata.length > 0) {
          rowCount = numericaldata[0].length;
        }

        for (let row = 0; row < rowCount; row++) {
          let building = new BmsBuilding();
          for (let i = 0; i < queryResult.columns.length; i++) {
            let it = queryResult.columns[i];
            let typ = it.datatype;
            let data = [];
            if (typ == XDataType.Number) {
              data = numericaldata[it.indexintypedvector];
            }
            if (typ == XDataType.String) {
              data = stringdata[it.indexintypedvector];
            }
            if (typ == XDataType.Timestamp) {
              data = timestampdata[it.indexintypedvector];
            }

            switch (it.columnoutname) {
              case 'id':
                building.id = data[row];
                break;
              case 'realestateid':
                building.realestateId = data[row];
                break;
              case 'svlant-buildingno':
                building.svLantBuildingNo = data[row];
                break;
              case 'description':
                building.description = data[row];
                break;
              case 'buildingtype':
                building.buildingtype = data[row];
                break;
              case 'latitude':
                building.latitude = data[row];
                break;
              case 'longitude':
                building.longitude = data[row];
                break;
              case 'createdat':
                building.createdAt = data[row];
                break;
              case 'modifiedat':
                building.modifiedAt = data[row];
                break;
            }
          }

          result.push(building);
        }
      }
      catch (err) {
        this.logger.error(err);
      }
    }

    return result;
  }

  async getBuildingAddresses(customerXdbId: number, forceReload: boolean = false): Promise<BmsBuildingAddress[]> {
    let result: BmsBuildingAddress[] = [];

    let realestateIds = await this.getRealestateXdbIds(customerXdbId, false);

    if (realestateIds.length > 0) {
      if (this.buildingAddressColumns.length == 0) {
        this.buildingAddressColumns = await this.xprojClient.RequestListQueryableProjectionColumns(BUILDINGADDRESS_PROJECTIONID, '', 0, 100);
      }

      let query: BaseQuery = new BaseQuery();
      query.targetprojectionid = BUILDINGADDRESS_PROJECTIONID;
      query.maxitems = 5000;
      query.targetgroup = [];

      let filterId = 0;
      query.filter.type = FilterLogicalGroupType.AND;

      let realesateFilterGroup = new FilterLogicalGroup();
      realesateFilterGroup.type = FilterLogicalGroupType.OR;
      realesateFilterGroup.queryfilterid = filterId++;

      realestateIds.forEach(realestateId => {
        let realestateFiltering = new ColumnFilteringNumerical();
        realestateFiltering.columnname = 'realestateid';
        realestateFiltering.comparator = FilterComparator.Equals;
        realestateFiltering.value = realestateId;
        realestateFiltering.queryfilterid = ++filterId;
        realesateFilterGroup.filters.push(realestateFiltering.queryfilterid);
        query.numericalfilters.push(realestateFiltering);
      });
      query.filter.filters.push(realesateFilterGroup.queryfilterid);
      query.subfiltergroups.push(realesateFilterGroup);

      let deletedFiltering = new ColumnFilteringNumerical();
      deletedFiltering.columnname = 'deleted';
      deletedFiltering.comparator = FilterComparator.Equals;
      deletedFiltering.value = 0;
      deletedFiltering.queryfilterid = ++filterId;
      query.filter.filters.push(deletedFiltering.queryfilterid);
      query.numericalfilters.push(deletedFiltering);

      this.buildingAddressColumns.forEach(c => {
        let inCol = new BaseQueryInputColumnDescription();
        inCol.columnname = c.columnname;
        inCol.columnoutname = c.columnname;
        query.columns.push(inCol);
      });

      query.sorting.columnname = 'street';
      query.sorting.descending = false;

      try {
        let queryResult = await this.xprojClient.RequestQueryBaseQuery(query, forceReload);
        result = this.getBuildingAddressesFromQueryResult(queryResult);
      }
      catch (err) {
        this.logger.error(err);
      }
    }

    return result;
  }

  async getBuildingAddressesByBuilding(buildingId: number, forceReload: boolean = false): Promise<BmsBuildingAddress[]> {
    let result: BmsBuildingAddress[] = [];

    if (this.buildingAddressColumns.length == 0) {
      this.buildingAddressColumns = await this.xprojClient.RequestListQueryableProjectionColumns(BUILDINGADDRESS_PROJECTIONID, '', 0, 100);
    }

    let query: BaseQuery = new BaseQuery();
    query.targetprojectionid = BUILDINGADDRESS_PROJECTIONID;
    query.maxitems = 5000;
    query.targetgroup = [];

    let filterId = 0;
    query.filter.type = FilterLogicalGroupType.AND;

    let customerFiltering = new ColumnFilteringNumerical();
    customerFiltering.columnname = 'buildingid';
    customerFiltering.comparator = FilterComparator.Equals;
    customerFiltering.value = buildingId;
    customerFiltering.queryfilterid = ++filterId;
    query.filter.filters.push(customerFiltering.queryfilterid);
    query.numericalfilters.push(customerFiltering);

    let deletedFiltering = new ColumnFilteringNumerical();
    deletedFiltering.columnname = 'deleted';
    deletedFiltering.comparator = FilterComparator.Equals;
    deletedFiltering.value = 0;
    deletedFiltering.queryfilterid = ++filterId;
    query.filter.filters.push(deletedFiltering.queryfilterid);
    query.numericalfilters.push(deletedFiltering);

    this.buildingAddressColumns.forEach(c => {
      let inCol = new BaseQueryInputColumnDescription();
      inCol.columnname = c.columnname;
      inCol.columnoutname = c.columnname;
      query.columns.push(inCol);
    });

    query.sorting.columnname = 'street';
    query.sorting.descending = false;

    try {
      let queryResult = await this.xprojClient.RequestQueryBaseQuery(query, forceReload);

      result = this.getBuildingAddressesFromQueryResult(queryResult);
    }
    catch (err) {
      this.logger.error(err);
    }

    return result;
  }

  private getBuildingAddressesFromQueryResult(queryResult: BaseQueryResult): BmsBuildingAddress[] {
    let result: BmsBuildingAddress[] = [];

    let numericaldata = queryResult.datanumbers;
    let timestampdata = queryResult.datatimestamps;
    let stringdata = queryResult.datastrings;

    let rowCount = 0;
    if (numericaldata.length > 0) {
      rowCount = numericaldata[0].length;
    }

    for (let row = 0; row < rowCount; row++) {
      let buildingAddress = new BmsBuildingAddress();
      for (let i = 0; i < queryResult.columns.length; i++) {
        let it = queryResult.columns[i];
        let typ = it.datatype;
        let data = [];
        if (typ == XDataType.Number) {
          data = numericaldata[it.indexintypedvector];
        }
        if (typ == XDataType.String) {
          data = stringdata[it.indexintypedvector];
        }
        if (typ == XDataType.Timestamp) {
          data = timestampdata[it.indexintypedvector];
        }

        switch (it.columnoutname) {
          case 'id':
            buildingAddress.id = data[row];
            break;
          case 'buildingid':
            buildingAddress.buildingId = data[row];
            break;
          case 'realestateid':
            buildingAddress.realestateId = data[row];
            break;
          case 'description':
            buildingAddress.description = data[row];
            break;
          case 'street':
            buildingAddress.street = data[row];
            break;
          case 'latitude':
            buildingAddress.latitude = data[row];
            break;
          case 'longitude':
            buildingAddress.longitude = data[row];
            break;
          case 'housenumber':
            buildingAddress.housenumber = data[row];
            break;
          case 'postalcode':
            buildingAddress.postalcode = data[row];
            break;
          case 'city':
            buildingAddress.city = data[row];
            break;
          case 'district':
            buildingAddress.district = data[row];
            break;
          case 'country':
            buildingAddress.country = data[row];
            break;
          case 'northof':
            buildingAddress.northOf = data[row];
            break;
          case 'eastof':
            buildingAddress.eastOf = data[row];
            break;
          case 'southof':
            buildingAddress.southOf = data[row];
            break;
          case 'westof':
            buildingAddress.westOf = data[row];
            break;
          case 'createdat':
            buildingAddress.createdAt = data[row];
            break;
          case 'modifiedat':
            buildingAddress.modifiedAt = data[row];
            break;
        }
      }

      result.push(buildingAddress);
    }

    return result;
  }

  async getSystems(customerId: string, forceReload: boolean = false): Promise<BmsSystem[]> {
    let result: BmsSystem[] = [];


    if (this.systemColumns.length == 0) {
      this.systemColumns = await this.xprojClient.RequestListQueryableProjectionColumns(SYSTEM_PROJECTIONID, '', 0, 100);
    }

    let query: BaseQuery = new BaseQuery();
    query.targetprojectionid = SYSTEM_PROJECTIONID;
    query.maxitems = 5000;
    query.targetgroup = [];

    let filterId = 0;
    query.filter.type = FilterLogicalGroupType.AND;

    let customerFiltering = new ColumnFilteringString();
    customerFiltering.columnname = 'customerid';
    customerFiltering.comparator = FilterComparator.Equals;
    customerFiltering.value = customerId;
    customerFiltering.queryfilterid = ++filterId;
    query.filter.filters.push(customerFiltering.queryfilterid);
    query.stringfilters.push(customerFiltering);

    let deletedFiltering = new ColumnFilteringNumerical();
    deletedFiltering.columnname = 'deleted';
    deletedFiltering.comparator = FilterComparator.Equals;
    deletedFiltering.value = 0;
    deletedFiltering.queryfilterid = ++filterId;
    query.filter.filters.push(deletedFiltering.queryfilterid);
    query.numericalfilters.push(deletedFiltering);

    this.systemColumns.forEach(c => {
      let inCol = new BaseQueryInputColumnDescription();
      inCol.columnname = c.columnname;
      inCol.columnoutname = c.columnname;
      query.columns.push(inCol);
    });

    query.sorting.columnname = 'name';
    query.sorting.descending = false;

    try {
      let queryResult = await this.xprojClient.RequestQueryBaseQuery(query, forceReload);

      let numericaldata = queryResult.datanumbers;
      let timestampdata = queryResult.datatimestamps;
      let stringdata = queryResult.datastrings;

      let rowCount = 0;
      if (numericaldata.length > 0) {
        rowCount = numericaldata[0].length;
      }

      for (let row = 0; row < rowCount; row++) {
        let system = new BmsSystem();
        for (let i = 0; i < queryResult.columns.length; i++) {
          let it = queryResult.columns[i];
          let typ = it.datatype;
          let data = [];
          if (typ == XDataType.Number) {
            data = numericaldata[it.indexintypedvector];
          }
          if (typ == XDataType.String) {
            data = stringdata[it.indexintypedvector];
          }
          if (typ == XDataType.Timestamp) {
            data = timestampdata[it.indexintypedvector];
          }

          switch (it.columnoutname) {
            case 'id':
              system.id = data[row];
              break;
            case 'description':
              system.description = data[row];
              break;
            case 'name':
              system.name = data[row];
            case 'createdat':
              system.createdAt = data[row];
              break;
            case 'modifiedat':
              system.modifiedAt = data[row];
              break;
          }
        }

        result.push(system);
      }
    }
    catch (err) {
      this.logger.error(err);
    }

    return result;
  }

  async getApartments(customerXdbId: number, forceReload: boolean = false): Promise<BmsApartment[]> {
    let result: BmsApartment[] = [];

    let realestateIds = await this.getRealestateXdbIds(customerXdbId, false);

    if (realestateIds.length > 0) {
      if (this.apartmentColumns.length == 0) {
        this.apartmentColumns = await this.xprojClient.RequestListQueryableProjectionColumns(APARTMENT_PROJECTIONID, '', 0, 100);
      }

      let query: BaseQuery = new BaseQuery();
      query.targetprojectionid = APARTMENT_PROJECTIONID;
      query.maxitems = 5000;
      query.targetgroup = [];

      let filterId = 0;
      query.filter.type = FilterLogicalGroupType.AND;

      let realesateFilterGroup = new FilterLogicalGroup();
      realesateFilterGroup.type = FilterLogicalGroupType.OR;
      realesateFilterGroup.queryfilterid = filterId++;

      realestateIds.forEach(realestateId => {
        let realestateFiltering = new ColumnFilteringNumerical();
        realestateFiltering.columnname = 'realestateid';
        realestateFiltering.comparator = FilterComparator.Equals;
        realestateFiltering.value = realestateId;
        realestateFiltering.queryfilterid = ++filterId;
        realesateFilterGroup.filters.push(realestateFiltering.queryfilterid);
        query.numericalfilters.push(realestateFiltering);
      });
      query.filter.filters.push(realesateFilterGroup.queryfilterid);
      query.subfiltergroups.push(realesateFilterGroup);

      let deletedFiltering = new ColumnFilteringNumerical();
      deletedFiltering.columnname = 'deleted';
      deletedFiltering.comparator = FilterComparator.Equals;
      deletedFiltering.value = 0;
      deletedFiltering.queryfilterid = ++filterId;
      query.filter.filters.push(deletedFiltering.queryfilterid);
      query.numericalfilters.push(deletedFiltering);

      this.apartmentColumns.forEach(c => {
        let inCol = new BaseQueryInputColumnDescription();
        inCol.columnname = c.columnname;
        inCol.columnoutname = c.columnname;
        query.columns.push(inCol);
      });

      query.sorting.columnname = 'svlant-apartmentno';
      query.sorting.descending = false;

      try {
        let queryResult = await this.xprojClient.RequestQueryBaseQuery(query, forceReload);

        let numericaldata = queryResult.datanumbers;
        let timestampdata = queryResult.datatimestamps;
        let stringdata = queryResult.datastrings;

        let rowCount = 0;
        if (numericaldata.length > 0) {
          rowCount = numericaldata[0].length;
        }

        for (let row = 0; row < rowCount; row++) {
          let apartment = new BmsApartment();
          for (let i = 0; i < queryResult.columns.length; i++) {
            let it = queryResult.columns[i];
            let typ = it.datatype;
            let data = [];
            if (typ == XDataType.Number) {
              data = numericaldata[it.indexintypedvector];
            }
            if (typ == XDataType.String) {
              data = stringdata[it.indexintypedvector];
            }
            if (typ == XDataType.Timestamp) {
              data = timestampdata[it.indexintypedvector];
            }

            switch (it.columnoutname) {
              case 'id':
                apartment.id = data[row];
                break;
              case 'buildingid':
                apartment.buildingId = data[row];
                break;
              case 'realestateid':
                apartment.realestateId = data[row];
                break;
              case 'buildingaddressid':
                apartment.buildingAddressId = data[row];
                break;
              case 'externalid':
                apartment.externalId = data[row];
                break;
              case 'prefix':
                apartment.prefix = data[row];
                break;
              case 'svlant-apartmentno':
                apartment.svlantApartmentno = data[row];
                break;
              case 'area':
                apartment.area = data[row];
                break;
              case 'size':
                apartment.size = data[row];
                break;
              case 'createdat':
                apartment.createdAt = data[row];
                break;
              case 'modifiedat':
                apartment.modifiedAt = data[row];
                break;
            }
          }

          result.push(apartment);
        }
      }
      catch (err) {
        this.logger.error(err);
      }
    }

    return result;
  }

  async getFacilities(customerXdbId: number, forceReload: boolean = false): Promise<BmsFacility[]> {
    let result: BmsFacility[] = [];

    let realestateIds = await this.getRealestateXdbIds(customerXdbId, false);

    if (realestateIds.length > 0) {
      if (this.facilityColumns.length == 0) {
        this.facilityColumns = await this.xprojClient.RequestListQueryableProjectionColumns(FACILITY_PROJECTIONID, '', 0, 100);
      }

      let query: BaseQuery = new BaseQuery();
      query.targetprojectionid = FACILITY_PROJECTIONID;
      query.maxitems = 5000;
      query.targetgroup = [];

      let filterId = 0;
      query.filter.type = FilterLogicalGroupType.AND;

      let realesateFilterGroup = new FilterLogicalGroup();
      realesateFilterGroup.type = FilterLogicalGroupType.OR;
      realesateFilterGroup.queryfilterid = filterId++;

      realestateIds.forEach(realestateId => {
        let realestateFiltering = new ColumnFilteringNumerical();
        realestateFiltering.columnname = 'realestateid';
        realestateFiltering.comparator = FilterComparator.Equals;
        realestateFiltering.value = realestateId;
        realestateFiltering.queryfilterid = ++filterId;
        realesateFilterGroup.filters.push(realestateFiltering.queryfilterid);
        query.numericalfilters.push(realestateFiltering);
      });
      query.filter.filters.push(realesateFilterGroup.queryfilterid);
      query.subfiltergroups.push(realesateFilterGroup);

      let deletedFiltering = new ColumnFilteringNumerical();
      deletedFiltering.columnname = 'deleted';
      deletedFiltering.comparator = FilterComparator.Equals;
      deletedFiltering.value = 0;
      deletedFiltering.queryfilterid = ++filterId;
      query.filter.filters.push(deletedFiltering.queryfilterid);
      query.numericalfilters.push(deletedFiltering);

      this.facilityColumns.forEach(c => {
        let inCol = new BaseQueryInputColumnDescription();
        inCol.columnname = c.columnname;
        inCol.columnoutname = c.columnname;
        query.columns.push(inCol);
      });

      query.sorting.columnname = 'externalid';
      query.sorting.descending = false;

      try {
        let queryResult = await this.xprojClient.RequestQueryBaseQuery(query, forceReload);

        let numericaldata = queryResult.datanumbers;
        let timestampdata = queryResult.datatimestamps;
        let stringdata = queryResult.datastrings;

        let rowCount = 0;
        if (numericaldata.length > 0) {
          rowCount = numericaldata[0].length;
        }

        for (let row = 0; row < rowCount; row++) {
          let facility = new BmsFacility();
          for (let i = 0; i < queryResult.columns.length; i++) {
            let it = queryResult.columns[i];
            let typ = it.datatype;
            let data = [];
            if (typ == XDataType.Number) {
              data = numericaldata[it.indexintypedvector];
            }
            if (typ == XDataType.String) {
              data = stringdata[it.indexintypedvector];
            }
            if (typ == XDataType.Timestamp) {
              data = timestampdata[it.indexintypedvector];
            }

            switch (it.columnoutname) {
              case 'id':
                facility.id = data[row];
                break;
              case 'buildingid':
                facility.buildingId = data[row];
                break;
              case 'realestateid':
                facility.realestateId = data[row];
                break;
              case 'buildingaddressid':
                facility.buildingAddressId = data[row];
                break;
              case 'externalid':
                facility.externalId = data[row];
                break;
              case 'name':
                facility.name = data[row];
                break;
              case 'prefix':
                facility.prefix = data[row];
                break;
              case 'facilitytype':
                facility.facilityType = data[row];
                break;
              case 'area':
                facility.area = data[row];
                break;
              case 'size':
                facility.size = data[row];
                break;
              case 'createdat':
                facility.createdAt = data[row];
                break;
              case 'modifiedat':
                facility.modifiedAt = data[row];
                break;
            }
          }

          result.push(facility);
        }
      }
      catch (err) {
        this.logger.error(err);
      }
    }

    return result;
  }

  async getGateways(customerId: string): Promise<BmsGateway[]> {
    let result: BmsGateway[] = [];

    try {
      let gatewayNodes = await this.xconfClient.getReferencedNodes('_x_bms_datacollectors_root_' + customerId, '_x_bms_datacollectors_root', [], '_x_datacollectors_collector', 5);
      gatewayNodes.forEach(gatewayNode => {
        let gateway = new BmsGateway();
        gatewayNode.propertyValues.forEach(p => {
          switch (p.key) {
            case 'id':
              gateway.id = +BmsDataUtils.getValue(p.valueType, p.value, p, this.dateHelper);
              break;
            case 'endpointurl':
              gateway.endpointURL = BmsDataUtils.getValue(p.valueType, p.value, p, this.dateHelper);
              break;
            case 'serialnumber':
              gateway.serialnumber = BmsDataUtils.getValue(p.valueType, p.value, p, this.dateHelper);
              break;
            case 'Vendor':
              gateway.vendor = BmsDataUtils.getValue(p.valueType, p.value, p, this.dateHelper);
              break;
            case 'model':
              gateway.model = BmsDataUtils.getValue(p.valueType, p.value, p, this.dateHelper);
              break;
            case 'placing':
              gateway.placing = BmsDataUtils.getValue(p.valueType, p.value, p, this.dateHelper);
              break;
          }
        });

        result.push(gateway);
      });
    }
    catch (err) {
      this.logger.error(err);
    }

    return result;
  }

  async getMeterTypes(): Promise<string[]> {
    let result: string[] = [];

    try {
      let meterTypeNodes = await this.xconfClient.getReferencedNodes('_x_bms_metertypes_root', '_x_bms_metertypes_root', [], '_x_bms_metertypes_type', 1);
      meterTypeNodes.forEach(meterTypeNode => {
        result.push(meterTypeNode.name);
      });
    }
    catch (err) {
      this.logger.error(err);
    }

    return result;
  }

  async getMeters(customerXdbId: number, filterActiveMeters : boolean = false, forceReload: boolean = false): Promise<BmsMeter[]> {
    let result: BmsMeter[] = [];

    let realestateIds = await this.getRealestateXdbIds(customerXdbId, false);

    if (realestateIds.length > 0) {
      if (this.meterColumns.length == 0) {
        this.meterColumns = await this.xprojClient.RequestListQueryableProjectionColumns(METER_PROJECTIONID, '', 0, 100);
      }

      let query: BaseQuery = new BaseQuery();
      query.targetprojectionid = METER_PROJECTIONID;
      query.maxitems = 5000;
      query.targetgroup = [];

      let filterId = 0;
      query.filter.type = FilterLogicalGroupType.AND;

      let realesateFilterGroup = new FilterLogicalGroup();
      realesateFilterGroup.type = FilterLogicalGroupType.OR;
      realesateFilterGroup.queryfilterid = filterId++;

      realestateIds.forEach(realestateId => {
        let realestateFiltering = new ColumnFilteringNumerical();
        realestateFiltering.columnname = 'realestateid';
        realestateFiltering.comparator = FilterComparator.Equals;
        realestateFiltering.value = realestateId;
        realestateFiltering.queryfilterid = ++filterId;
        realesateFilterGroup.filters.push(realestateFiltering.queryfilterid);
        query.numericalfilters.push(realestateFiltering);
      });
      query.filter.filters.push(realesateFilterGroup.queryfilterid);
      query.subfiltergroups.push(realesateFilterGroup);

      if (filterActiveMeters) {
        let stateFiltering = new ColumnFilteringNumerical();
        stateFiltering.columnname = 'state';
        stateFiltering.comparator = FilterComparator.GreatherThanOrEquals;
        stateFiltering.value = 20;
        stateFiltering.queryfilterid = ++filterId;
        query.filter.filters.push(stateFiltering.queryfilterid);
        query.numericalfilters.push(stateFiltering);
      }

      let deletedFiltering = new ColumnFilteringNumerical();
      deletedFiltering.columnname = 'deleted';
      deletedFiltering.comparator = FilterComparator.Equals;
      deletedFiltering.value = 0;
      deletedFiltering.queryfilterid = ++filterId;
      query.filter.filters.push(deletedFiltering.queryfilterid);
      query.numericalfilters.push(deletedFiltering);

      this.meterColumns.forEach(c => {
        let inCol = new BaseQueryInputColumnDescription();
        inCol.columnname = c.columnname;
        inCol.columnoutname = c.columnname;
        query.columns.push(inCol);
      });

      query.sorting.columnname = 'subaddress1';
      query.sorting.descending = false;

      try {
        let queryResult = await this.xprojClient.RequestQueryBaseQuery(query, forceReload);

        let rowCount = 0;
        if (queryResult.datanumbers.length > 0) {
          rowCount = queryResult.datanumbers[0].length;
        }

        for (let row = 0; row < rowCount; row++) {
          let meter = this._getMeter(queryResult, row);
          result.push(meter);
        }
      }
      catch (err) {
        this.logger.error(err);
      }
    }

    return result;
  }

  async getMetersSummary(customerXdbId: number, forceReload: boolean = false): Promise<{ meterType: string, count: number }[]> {
    let result: { meterType: string, count: number }[] = [];

    let realestateIds = await this.getRealestateXdbIds(customerXdbId, false);

    if (realestateIds.length > 0) {
      let query: BaseQuery = new BaseQuery();
      query.targetprojectionid = METER_PROJECTIONID;
      query.maxitems = 5000;
      query.targetgroup = [];

      let filterId = 0;
      query.filter.type = FilterLogicalGroupType.AND;

      let realesateFilterGroup = new FilterLogicalGroup();
      realesateFilterGroup.type = FilterLogicalGroupType.OR;
      realesateFilterGroup.queryfilterid = filterId++;

      realestateIds.forEach(realestateId => {
        let realestateFiltering = new ColumnFilteringNumerical();
        realestateFiltering.columnname = 'realestateid';
        realestateFiltering.comparator = FilterComparator.Equals;
        realestateFiltering.value = realestateId;
        realestateFiltering.queryfilterid = ++filterId;
        realesateFilterGroup.filters.push(realestateFiltering.queryfilterid);
        query.numericalfilters.push(realestateFiltering);
      });
      query.filter.filters.push(realesateFilterGroup.queryfilterid);
      query.subfiltergroups.push(realesateFilterGroup);

      let stateFiltering = new ColumnFilteringNumerical();
      stateFiltering.columnname = 'state';
      stateFiltering.comparator = FilterComparator.GreatherThanOrEquals;
      stateFiltering.value = 40;
      stateFiltering.queryfilterid = ++filterId;
      query.filter.filters.push(stateFiltering.queryfilterid);
      query.numericalfilters.push(stateFiltering);

      let deletedFiltering = new ColumnFilteringNumerical();
      deletedFiltering.columnname = 'deleted';
      deletedFiltering.comparator = FilterComparator.Equals;
      deletedFiltering.value = 0;
      deletedFiltering.queryfilterid = ++filterId;
      query.filter.filters.push(deletedFiltering.queryfilterid);
      query.numericalfilters.push(deletedFiltering);

      query.grouping = new ColumnGroupingDescription();
      query.grouping.columnname = 'metertype';
      query.grouping.columnoutname = 'metertype';
      query.grouping.columntransformation = Transformation.NONE;

      let countTypeCol = new BaseQueryInputColumnDescription();
      countTypeCol.columnname = 'metertype';
      countTypeCol.columnoutname = 'count';
      countTypeCol.columnaggregation = Aggregation.COUNT;
      query.columns.push(countTypeCol);

      query.sorting.columnname = 'metertype';
      query.sorting.descending = false;

      try {
        let queryResult = await this.xprojClient.RequestQueryBaseQuery(query, forceReload);

        let rowCount = 0;
        if (queryResult.datanumbers.length > 0) {
          rowCount = queryResult.datanumbers[0].length;
        }

        for (let row = 0; row < rowCount; row++) {
          let it = queryResult.columns[0];
          let meterType = queryResult.datastrings[it.indexintypedvector][row];
          it = queryResult.columns[1];
          let meterCount = queryResult.datanumbers[it.indexintypedvector][row];

          result.push({ meterType: meterType, count: meterCount });
        }
      }
      catch (err) {
        this.logger.error(err);
      }
    }

    return result;
  }

  async getMeter(meterId: number): Promise<BmsMeter> {
    let result: BmsMeter;
    if (this.meterColumns.length == 0) {
      this.meterColumns = await this.xprojClient.RequestListQueryableProjectionColumns(METER_PROJECTIONID, '', 0, 100);
    }

    let query: BaseQuery = new BaseQuery();
    query.targetprojectionid = METER_PROJECTIONID;
    query.maxitems = 1;
    query.targetgroup = [];

    let filterId = 0;
    query.filter.type = FilterLogicalGroupType.AND;

    let idFiltering = new ColumnFilteringNumerical();
    idFiltering.columnname = 'id';
    idFiltering.comparator = FilterComparator.Equals;
    idFiltering.value = meterId;
    idFiltering.queryfilterid = ++filterId;
    query.filter.filters.push(idFiltering.queryfilterid);
    query.numericalfilters.push(idFiltering);

    let deletedFiltering = new ColumnFilteringNumerical();
    deletedFiltering.columnname = 'deleted';
    deletedFiltering.comparator = FilterComparator.Equals;
    deletedFiltering.value = 0;
    deletedFiltering.queryfilterid = ++filterId;
    query.filter.filters.push(deletedFiltering.queryfilterid);
    query.numericalfilters.push(deletedFiltering);

    this.meterColumns.forEach(c => {
      let inCol = new BaseQueryInputColumnDescription();
      inCol.columnname = c.columnname;
      inCol.columnoutname = c.columnname;
      query.columns.push(inCol);
    });

    query.sorting.columnname = 'id';
    query.sorting.descending = false;

    try {
      let queryResult = await this.xprojClient.RequestQueryBaseQuery(query, true);

      let rowCount = 0;
      if (queryResult.datanumbers.length > 0) {
        result = this._getMeter(queryResult, 0);
      }
    }
    catch (err) {
      this.logger.error(err);
    }

    return result;
  }

  async getMeterByIdentifier(identifier: string): Promise<BmsMeter> {
    let result: BmsMeter;
    if (this.meterColumns.length == 0) {
      this.meterColumns = await this.xprojClient.RequestListQueryableProjectionColumns(METER_PROJECTIONID, '', 0, 100);
    }

    let query: BaseQuery = new BaseQuery();
    query.targetprojectionid = METER_PROJECTIONID;
    query.maxitems = 1;
    query.targetgroup = [];

    let filterId = 0;
    query.filter.type = FilterLogicalGroupType.AND;

    let subAddress1Filtering = new ColumnFilteringString();
    subAddress1Filtering.columnname = 'subaddress1';
    subAddress1Filtering.comparator = FilterComparator.Equals;
    subAddress1Filtering.value = identifier;
    subAddress1Filtering.queryfilterid = ++filterId;
    query.filter.filters.push(subAddress1Filtering.queryfilterid);
    query.stringfilters.push(subAddress1Filtering);

    let deletedFiltering = new ColumnFilteringNumerical();
    deletedFiltering.columnname = 'deleted';
    deletedFiltering.comparator = FilterComparator.Equals;
    deletedFiltering.value = 0;
    deletedFiltering.queryfilterid = ++filterId;
    query.filter.filters.push(deletedFiltering.queryfilterid);
    query.numericalfilters.push(deletedFiltering);

    this.meterColumns.forEach(c => {
      let inCol = new BaseQueryInputColumnDescription();
      inCol.columnname = c.columnname;
      inCol.columnoutname = c.columnname;
      query.columns.push(inCol);
    });

    query.sorting.columnname = 'subaddress1';
    query.sorting.descending = false;

    try {
      let queryResult = await this.xprojClient.RequestQueryBaseQuery(query, true);

      let rowCount = 0;
      if (queryResult.datanumbers.length > 0) {
        result = this._getMeter(queryResult, 0);
      }
    }
    catch (err) {
      this.logger.error(err);
    }

    return result;
  }

  _getMeter(queryResult: BaseQueryResult, row: number): BmsMeter {
    let meter = new BmsMeter();

    for (let i = 0; i < queryResult.columns.length; i++) {
      let it = queryResult.columns[i];
      let typ = it.datatype;
      let data = [];
      if (typ == XDataType.Number) {
        data = queryResult.datanumbers[it.indexintypedvector];
      }
      if (typ == XDataType.String) {
        data = queryResult.datastrings[it.indexintypedvector];
      }
      if (typ == XDataType.Timestamp) {
        data = queryResult.datatimestamps[it.indexintypedvector];
      }

      switch (it.columnoutname) {
        case 'id':
          meter.id = data[row];
          break;
        case 'buildingid':
          meter.buildingId = data[row];
          break;
        case 'realestateid':
          meter.realestateId = data[row];
          break;
        case 'buildingaddressid':
          meter.buildingAddressId = data[row];
          break;
        case 'apartmentid':
          meter.apartmentId = data[row];
          break;
        case 'facilityid':
          meter.facilityId = data[row];
          break;
        case 'subaddress1':
          meter.identifier = data[row];
          break;
        case 'subaddress2':
          meter.manufacturer = data[row];
          break;
        case 'subaddress3':
          meter.variable = data[row];
          break;
        case 'subaddress4':
          meter.index = +data[row];
          break;
        case 'unit':
          meter.unit = data[row];
          break;
        case 'datapointvalueunit':
          meter.datapointValueUnit = data[row];
          break;
        case 'state':
          meter.state = data[row];
          break;
        case 'model':
          meter.model = data[row];
          break;
        case 'metertype':
          meter.meterType = data[row];
          break;
        case 'tariffgroup':
          meter.tariffGroup = data[row];
          break;
        case 'metersubtype':
          meter.meterSubtype = data[row];
          break;
        case 'typeid':
          meter.typeId = data[row];
          break;
        case 'coeffiecent':
          meter.coeffiecent = data[row];
          break;
        case 'createdat':
          meter.createdAt = data[row];
          break;
        case 'modifiedat':
          meter.modifiedAt = data[row];
          break;
        case 'inputmetertype':
          meter.inputMeterType = data[row];
          break;
        case 'systemid1':
          meter.systemId1 = data[row];
          break;
        case 'systemid2':
          meter.systemId2 = data[row];
          break;
        case 'realestateid':
          meter.systemId3 = data[row];
          break;

      }
    }

    return meter;
  }

  async exportToExcel(meterDatas: BmsMeterData[], filename: string) {
    var headers: string[] = ['svlantPropertyDesignation', 'externalId', 'name', 'svlantApartmentno', 'facilityType', 'area', 'size',
      'svLantBuildingNo', 'street', 'housenumber', 'city', 'district', 'gw_serialnumber', 'gw_vendor', 'seconadaryAddress', 'manufacturer', 'unit',
      'datapointValueUnit', 'variable', 'meterType', 'meterSubtype', 'tariffGroup', 'index', 'state', 'system1', 'system2', 'system3'];
    const ws: XLSX.WorkSheet = XLSX.utils.json_to_sheet(meterDatas, { header: headers });

    const wb: XLSX.WorkBook = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(wb, ws, 'Sheet1');

    XLSX.writeFile(wb, filename);
  }

  async getInvoiceInfos(customerId: string): Promise<BmsInvoiceInfo[]> {
    let result: BmsInvoiceInfo[] = [];

    let query: BaseQuery = new BaseQuery();
    query.targetprojectionid = INVOICEINFO_PROJECTIONID;
    query.maxitems = 5000;
    query.targetgroup = [];

    let filterId = 0;
    query.filter.type = FilterLogicalGroupType.AND;

    let customerIdFiltering = new ColumnFilteringString();
    customerIdFiltering.columnname = 'customerid';
    customerIdFiltering.comparator = FilterComparator.Equals;
    customerIdFiltering.value = customerId;
    customerIdFiltering.queryfilterid = ++filterId;
    query.filter.filters.push(customerIdFiltering.queryfilterid);
    query.stringfilters.push(customerIdFiltering);


    let deletedFiltering = new ColumnFilteringNumerical();
    deletedFiltering.columnname = 'deleted';
    deletedFiltering.comparator = FilterComparator.Equals;
    deletedFiltering.value = 0;
    deletedFiltering.queryfilterid = ++filterId;
    query.filter.filters.push(deletedFiltering.queryfilterid);
    query.numericalfilters.push(deletedFiltering);

    if (this.invoiceInfoColumns.length == 0) {
      this.invoiceInfoColumns = await this.xprojClient.RequestListQueryableProjectionColumns(INVOICEINFO_PROJECTIONID, '', 0, 100);
    }

    this.invoiceInfoColumns.forEach(c => {
      let inCol = new BaseQueryInputColumnDescription();
      inCol.columnname = c.columnname;
      inCol.columnoutname = c.columnname;
      query.columns.push(inCol);
    });

    query.sorting.columnname = 'activefrom';
    query.sorting.descending = true;

    try {
      let queryResult = await this.xprojClient.RequestQueryBaseQuery(query, true);

      let numericaldata = queryResult.datanumbers;
      let timestampdata = queryResult.datatimestamps;
      let stringdata = queryResult.datastrings;

      let rowCount = 0;
      if (numericaldata.length > 0) {
        rowCount = numericaldata[0].length;
      }

      for (let row = 0; row < rowCount; row++) {
        let invoiceInfo = new BmsInvoiceInfo();
        for (let i = 0; i < queryResult.columns.length; i++) {
          let it = queryResult.columns[i];
          let typ = it.datatype;
          let data = [];
          if (typ == XDataType.Number) {
            data = numericaldata[it.indexintypedvector];
          }
          if (typ == XDataType.String) {
            data = stringdata[it.indexintypedvector];
          }
          if (typ == XDataType.Timestamp) {
            data = timestampdata[it.indexintypedvector];
          }

          switch (it.columnoutname) {
            case 'id':
              invoiceInfo.id = data[row];
              break;
            case 'customerid':
              invoiceInfo.customerId = data[row];
              break;
            case 'activefrom':
              invoiceInfo.activeFrom = data[row];
              break;
            case 'metertype':
              invoiceInfo.meterType = data[row];
              break;
            case 'createdat':
              invoiceInfo.createdAt = data[row];
              break;
            case 'modifiedat':
              invoiceInfo.modifiedAt = data[row];
              break;
            case 'deletedat':
              invoiceInfo.deletedAt = data[row];
              break;
            case 'deleted':
              invoiceInfo.deleted = data[row];
              break;
            case 'invoicedisabled':
              invoiceInfo.invoiceDisabled = data[row];
              break;
          }
        }

        result.push(invoiceInfo);
      }
    }
    catch (err) {
      this.logger.error(err);
    }

    return result;
  }

  async setDataInvoiceInfos(invoiceInfos: BmsInvoiceInfo[]): Promise<boolean> {
    let query: SetDataQuery = new SetDataQuery();

    let now = new Date();
    await ArrayUtils.AsyncForEach(invoiceInfos, async (invoiceInfo: BmsInvoiceInfo) => {
      if (!invoiceInfo.id || invoiceInfo.id == 0) {
        invoiceInfo.id = await flakeId.nextId();
        invoiceInfo.createdAt = now;
      }
      invoiceInfo.modifiedAt = now;
    });

    query.datastrings = [
      invoiceInfos.map(t => t.customerId),
      invoiceInfos.map(t => t.meterType),
    ];
    query.datanumbers = [
      invoiceInfos.map(t => t.id),
      invoiceInfos.map(t => t.deleted ? 1 : 0),
      invoiceInfos.map(t => t.invoiceDisabled ? 1 : 0),
    ];
    query.datatimestamps = [
      invoiceInfos.map(t => t.activeFrom ?? new Date()),
      invoiceInfos.map(t => t.createdAt ?? new Date()),
      invoiceInfos.map(t => t.modifiedAt ?? new Date()),
      invoiceInfos.map(t => t.deletedAt ?? new Date(0))
    ];

    query.projectionid = INVOICEINFO_PROJECTIONID;
    query.columns = this.setDataInvoiceInfoColumns;

    return await this.xprojClient.RequestSetData(query);
  }

  async getLorawanMultiMeters(lorawanGroupId: string): Promise<BmsLorawanMultiMeter[]> {
    let result: BmsLorawanMultiMeter[] = [];

    try {
      let request = new SearchNodesRequest();
      request.rootId = lorawanGroupId;
      request.rootLabel = '_x_lorawan_group';
      request.limit = 2000;
      request.maxHops = 10;
      request.skip = 0;
      request.label = '_x_lorawan_multimeter';

      let searchResult = await this.xconfClient.searchNodes(request);

      searchResult.nodes.forEach(node => {
        let lorawanMultiMeter = this.getLorawanMultiMeter(node);
        result.push(lorawanMultiMeter);
      });
    }
    catch (err) {
      this.logger.error(err);
    }

    return result;
  }

  getLorawanMultiMeter(node: GrpcNode): BmsLorawanMultiMeter {
    let lorawanMultiMeter = new BmsLorawanMultiMeter();
    lorawanMultiMeter.id = node.id;
    lorawanMultiMeter.name = node.name;
    node.propertyValues.forEach(p => {
      switch (p.key) {
        case 'loramultimetertype':
          lorawanMultiMeter.multiMeterType = BmsDataUtils.getValue(p.valueType, p.value, p, this.dateHelper);
          break;
        case 'appkey':
          lorawanMultiMeter.appKey = BmsDataUtils.getValue(p.valueType, p.value, p, this.dateHelper);
          break;
        case 'deveui':
          lorawanMultiMeter.devEui = BmsDataUtils.getValue(p.valueType, p.value, p, this.dateHelper);
          break;
        case 'externalid':
          lorawanMultiMeter.externalId = BmsDataUtils.getValue(p.valueType, p.value, p, this.dateHelper);
          break;
        case 'provisioned':
          lorawanMultiMeter.provisioned = BmsDataUtils.getValue(p.valueType, p.value, p, this.dateHelper);
          break;
        case 'createdat':
          lorawanMultiMeter.createdAt = BmsDataUtils.getValue(p.valueType, p.value, p, this.dateHelper);
          break;
        case 'modifiedat':
          lorawanMultiMeter.modifiedAt = BmsDataUtils.getValue(p.valueType, p.value, p, this.dateHelper);
          break;
        case 'loradevicetype':
          lorawanMultiMeter.deviceType = BmsDataUtils.getValue(p.valueType, p.value, p, this.dateHelper);
          break;

      }
    });

    return lorawanMultiMeter;
  }

  async getLoraWANDeviceInfos(deveuis: string[]): Promise<BmsLorawanDeviceInfo[]> {

    let result: BmsLorawanDeviceInfo[] = [];
    let query: BaseQuery = new BaseQuery();
    query.targetprojectionid = LORAWANDEVICEINFO_PROJECTIONID;
    query.maxitems = deveuis.length;
    query.targetgroup = [];

    let filterId = 0;
    query.filter.type = FilterLogicalGroupType.AND;

    let deveuiGroup = new FilterLogicalGroup();
    deveuiGroup.type = FilterLogicalGroupType.OR;
    deveuiGroup.queryfilterid = filterId++;
    for (let deveui of deveuis.filter(x => x?.length > 0)) {
      let newFilter = new ColumnFilteringString();
      newFilter.queryfilterid = filterId++;
      newFilter.columnname = 'deveui';
      newFilter.comparator = FilterComparator.Equals;
      newFilter.value = deveui;
      deveuiGroup.filters.push(newFilter.queryfilterid);
      query.stringfilters.push(newFilter);
    }
    query.filter.filters.push(deveuiGroup.queryfilterid);
    query.subfiltergroups.push(deveuiGroup);

    let deletedFiltering = new ColumnFilteringNumerical();
    deletedFiltering.columnname = 'deleted';
    deletedFiltering.comparator = FilterComparator.Equals;
    deletedFiltering.value = 0;
    deletedFiltering.queryfilterid = ++filterId;
    query.filter.filters.push(deletedFiltering.queryfilterid);
    query.numericalfilters.push(deletedFiltering);

    if (this.loraWanDeviceInfoColumns.length == 0) {
      this.loraWanDeviceInfoColumns = await this.xprojClient.RequestListQueryableProjectionColumns(LORAWANDEVICEINFO_PROJECTIONID, '', 0, 100);
    }

    this.loraWanDeviceInfoColumns.forEach(c => {
      let inCol = new BaseQueryInputColumnDescription();
      inCol.columnname = c.columnname;
      inCol.columnoutname = c.columnname;
      query.columns.push(inCol);
    });

    try {
      let queryResult = await this.xprojClient.RequestQueryBaseQuery(query, true);

      let numericaldata = queryResult.datanumbers;
      let timestampdata = queryResult.datatimestamps;
      let stringdata = queryResult.datastrings;

      let rowCount = 0;
      if (numericaldata.length > 0) {
        rowCount = numericaldata[0].length;
      }

      for (let row = 0; row < rowCount; row++) {
        let deviceInfo = new BmsLorawanDeviceInfo();
        for (let i = 0; i < queryResult.columns.length; i++) {
          let it = queryResult.columns[i];
          let typ = it.datatype;
          let data = [];
          if (typ == XDataType.Number) {
            data = numericaldata[it.indexintypedvector];
          }
          if (typ == XDataType.String) {
            data = stringdata[it.indexintypedvector];
          }
          if (typ == XDataType.Timestamp) {
            data = timestampdata[it.indexintypedvector];
          }

          switch (it.columnoutname) {
            case 'id':
              deviceInfo.id = data[row];
              break;
            case 'batterylevel':
              deviceInfo.batteryLevel = data[row];
              break;
            case 'deveui':
              deviceInfo.deveui = data[row];
              break;
            case 'frequency':
              deviceInfo.frequency = data[row];
              break;
            case 'gatewaycount':
              deviceInfo.gatewayCount = data[row];
              break;
            case 'modifiedat':
              deviceInfo.modifiedAt = data[row];
              break;
            case 'gatewayidentifier':
              deviceInfo.gatewayIdentifier = data[row];
              break;
            case 'gatewayidentifiers':
              deviceInfo.gatewayIdentifiers = data[row];
              break;
            case 'latitude':
              deviceInfo.latitude = data[row];
              break;
            case 'longitude':
              deviceInfo.longitude = data[row];
              break;
            case 'rssi':
              deviceInfo.rssi = data[row];
              break;
            case 'snr':
              deviceInfo.snr = data[row];
              break;
            case 'fcnt':
              deviceInfo.fCnt = data[row];
              break;
            case 'spreadingfactor':
              deviceInfo.spreadingfactor = data[row];
              break;
          }
        }

        result.push(deviceInfo);
      }
    }
    catch (err) {
      this.logger.error(err);
    }

    return result;
  }

  async getCustomerFlag(customerId: string, flagId: string, nodeId: number = -1): Promise<BmsCustomerFlag> {
    let result: BmsCustomerFlag;
    if (this.customerFlagsColumns.length == 0) {
      this.customerFlagsColumns = await this.xprojClient.RequestListQueryableProjectionColumns(CUSTOMER_FLAGS_PROJECTIONID, '', 0, 100);
    }

    let query: BaseQuery = new BaseQuery();
    query.targetprojectionid = CUSTOMER_FLAGS_PROJECTIONID;
    query.maxitems = 1;
    query.targetgroup = [];

    let filterId = 0;
    query.filter.type = FilterLogicalGroupType.AND;

    let customerIdFiltering = new ColumnFilteringString();
    customerIdFiltering.columnname = 'customerid';
    customerIdFiltering.comparator = FilterComparator.Equals;
    customerIdFiltering.value = customerId;
    customerIdFiltering.queryfilterid = ++filterId;
    query.filter.filters.push(customerIdFiltering.queryfilterid);
    query.stringfilters.push(customerIdFiltering);

    let flagIdFiltering = new ColumnFilteringString();
    flagIdFiltering.columnname = 'flagid';
    flagIdFiltering.comparator = FilterComparator.Equals;
    flagIdFiltering.value = flagId;
    flagIdFiltering.queryfilterid = ++filterId;
    query.filter.filters.push(flagIdFiltering.queryfilterid);
    query.stringfilters.push(flagIdFiltering);

    if (nodeId > 0) {
      let nodeIdFiltering = new ColumnFilteringNumerical();
      nodeIdFiltering.columnname = 'nodeid';
      nodeIdFiltering.comparator = FilterComparator.Equals;
      nodeIdFiltering.value = nodeId;
      nodeIdFiltering.queryfilterid = ++filterId;
      query.filter.filters.push(nodeIdFiltering.queryfilterid);
      query.numericalfilters.push(nodeIdFiltering);
    }

    query.sorting.columnname = 'customerid';
    query.sorting.descending = false;

    this.customerFlagsColumns.forEach(c => {
      let inCol = new BaseQueryInputColumnDescription();
      inCol.columnname = c.columnname;
      inCol.columnoutname = c.columnname;
      query.columns.push(inCol);
    });

    try {
      let queryResult = await this.xprojClient.RequestQueryBaseQuery(query, true);

      let customerFlagsResults = this._getCustomerFlagsFromQueryResult(queryResult);

      if (customerFlagsResults.length > 0) {
        result = customerFlagsResults[0];
      }
      else {
        result = new BmsCustomerFlag();
        result.id = -1;
        result.customerId = customerId;
        result.flagId = flagId;
        result.status = 0;
        result.modifiedAt = new Date(0);
      }
    }
    catch (err) {
      this.logger.error(err);
    }

    return result;
  }

  private _getCustomerFlagsFromQueryResult(queryResult: BaseQueryResult): BmsCustomerFlag[] {
    let result: BmsCustomerFlag[] = [];
    try {
      let numericaldata = queryResult.datanumbers;
      let timestampdata = queryResult.datatimestamps;
      let stringdata = queryResult.datastrings;

      let rowCount = 0;
      if (numericaldata.length > 0) {
        rowCount = numericaldata[0].length;
      }

      for (let row = 0; row < rowCount; row++) {
        let customer = new BmsCustomerFlag();
        for (let i = 0; i < queryResult.columns.length; i++) {
          let it = queryResult.columns[i];
          let typ = it.datatype;
          let data = [];
          if (typ == XDataType.Number) {
            data = numericaldata[it.indexintypedvector];
          }
          if (typ == XDataType.String) {
            data = stringdata[it.indexintypedvector];
          }
          if (typ == XDataType.Timestamp) {
            data = timestampdata[it.indexintypedvector];
          }

          switch (it.columnoutname) {
            case 'id':
              customer.id = data[row];
              break;
            case 'customerid':
              customer.customerId = data[row];
              break;
            case 'nodeid':
              customer.nodeId = data[row];
              break;
            case 'flagid':
              customer.flagId = data[row];
              break;
            case 'status':
              customer.status = data[row];
              break;
            case 'modifiedat':
              customer.modifiedAt = data[row];
              break;
          }
        }

        result.push(customer);
      }
    }
    catch (err) {
      this.logger.error(err);
    }

    return result;
  }

  async getLatestValues(customerId: string, gatewayIds : number[] = [], forceReload: boolean = false): Promise<BmsLatestValue[]> {
    let result: BmsLatestValue[] = [];

    if (this.latestValuesColumns.length == 0) {
      this.latestValuesColumns = await this.xprojClient.RequestListQueryableProjectionColumns(DATAPOINTLATEST_PROJECTIONID, '', 0, 100);
    }

    let query: BaseQuery = new BaseQuery();
    query.targetprojectionid = DATAPOINTLATEST_PROJECTIONID;
    query.maxitems = 5000;
    query.targetgroup = [];

    let filterId = 0;
    query.filter.type = FilterLogicalGroupType.AND;

    if (customerId?.length > 0) {
      let customerFiltering = new ColumnFilteringString();
      customerFiltering.columnname = 'customerid';
      customerFiltering.comparator = FilterComparator.Equals;
      customerFiltering.value = customerId;
      customerFiltering.queryfilterid = ++filterId;
      query.filter.filters.push(customerFiltering.queryfilterid);
      query.stringfilters.push(customerFiltering);
    }

    if (gatewayIds?.length > 0) {
      let gatewayIdFilter = new FilterLogicalGroup();
      gatewayIdFilter.type = FilterLogicalGroupType.OR;
      gatewayIdFilter.queryfilterid = 10001;

      gatewayIds.forEach(x => {
        let gatewayIdFiltering = new ColumnFilteringNumerical();
        gatewayIdFiltering.columnname = 'gatewayid';
        gatewayIdFiltering.comparator = FilterComparator.Equals;
        gatewayIdFiltering.value = x;
        gatewayIdFiltering.queryfilterid = ++filterId;
        gatewayIdFilter.filters.push(gatewayIdFiltering.queryfilterid);

        query.numericalfilters.push(gatewayIdFiltering);
      });

      query.subfiltergroups.push(gatewayIdFilter);
      query.filter.filters.push(gatewayIdFilter.queryfilterid)
    }

    let deletedFiltering = new ColumnFilteringNumerical();
    deletedFiltering.columnname = 'deleted';
    deletedFiltering.comparator = FilterComparator.Equals;
    deletedFiltering.value = 0;
    deletedFiltering.queryfilterid = ++filterId;
    query.filter.filters.push(deletedFiltering.queryfilterid);
    query.numericalfilters.push(deletedFiltering);

    this.latestValuesColumns.forEach(c => {
      let inCol = new BaseQueryInputColumnDescription();
      inCol.columnname = c.columnname;
      inCol.columnoutname = c.columnname;
      query.columns.push(inCol);
    });

    query.sorting.columnname = 'timestamp';
    query.sorting.descending = true;

    try {
      let queryResult = await this.xprojClient.RequestQueryBaseQuery(query, forceReload);

      let numericaldata = queryResult.datanumbers;
      let timestampdata = queryResult.datatimestamps;
      let stringdata = queryResult.datastrings;

      let rowCount = 0;
      if (numericaldata.length > 0) {
        rowCount = numericaldata[0].length;
      }

      for (let row = 0; row < rowCount; row++) {
        let latestValue = new BmsLatestValue();
        for (let i = 0; i < queryResult.columns.length; i++) {
          let it = queryResult.columns[i];
          let typ = it.datatype;
          let data = [];
          if (typ == XDataType.Number) {
            data = numericaldata[it.indexintypedvector];
          }
          if (typ == XDataType.String) {
            data = stringdata[it.indexintypedvector];
          }
          if (typ == XDataType.Timestamp) {
            data = timestampdata[it.indexintypedvector];
          }

          switch (it.columnoutname) {
            case 'meterid':
              latestValue.meterId = data[row];
              break;
            case 'buildingid':
              latestValue.buildingId = data[row];
              break;
            case 'realestateid':
              latestValue.realestateId = data[row];
              break;
            case 'buildingaddressid':
              latestValue.buildingAddressId = data[row];
              break;
            case 'customerid':
              latestValue.customerId = data[row];
              break;
            case 'facilityid':
              latestValue.facilityId = data[row];
              break;
            case 'gatewayid':
              latestValue.gatewayId = data[row];
              break;
            case 'metersubtype':
              latestValue.meterSubtype = data[row];
              break;
            case 'metertype':
              latestValue.meterType = data[row];
              break;
            case 'modifiedat':
              latestValue.modifiedAt = data[row];
              break;
            case 'timestamp':
              latestValue.timestamp = data[row];
              break;
            case 'unit':
              latestValue.unit = data[row];
              break;
            case 'value':
              latestValue.value = data[row];
              break;

          }
        }

        result.push(latestValue);
      }
    }
    catch (err) {
      this.logger.error(err);
    }

    return result;
  }

  async getGatewayInfo(gatewayId: number, forceReload: boolean = false): Promise<BmsGatewayInfo> {
    let result: BmsGatewayInfo;

    if (this.gatewayInfoColumns.length == 0) {
      this.gatewayInfoColumns = await this.xprojClient.RequestListQueryableProjectionColumns(GATEWAYINFO_PROJECTIONID, '', 0, 100);
    }

    let query: BaseQuery = new BaseQuery();
    query.targetprojectionid = GATEWAYINFO_PROJECTIONID;
    query.maxitems = 1;
    query.targetgroup = [];

    let filterId = 0;
    query.filter.type = FilterLogicalGroupType.AND;

    let idFiltering = new ColumnFilteringNumerical();
    idFiltering.columnname = 'id';
    idFiltering.comparator = FilterComparator.Equals;
    idFiltering.value = gatewayId;
    idFiltering.queryfilterid = ++filterId;
    query.filter.filters.push(idFiltering.queryfilterid);
    query.numericalfilters.push(idFiltering);

    let deletedFiltering = new ColumnFilteringNumerical();
    deletedFiltering.columnname = 'deleted';
    deletedFiltering.comparator = FilterComparator.Equals;
    deletedFiltering.value = 0;
    deletedFiltering.queryfilterid = ++filterId;
    query.filter.filters.push(deletedFiltering.queryfilterid);
    query.numericalfilters.push(deletedFiltering);

    this.gatewayInfoColumns.forEach(c => {
      let inCol = new BaseQueryInputColumnDescription();
      inCol.columnname = c.columnname;
      inCol.columnoutname = c.columnname;
      query.columns.push(inCol);
    });

    query.sorting.columnname = 'modifiedat';
    query.sorting.descending = true;

    try {
      let queryResult = await this.xprojClient.RequestQueryBaseQuery(query, forceReload);

      let numericaldata = queryResult.datanumbers;
      let timestampdata = queryResult.datatimestamps;
      let stringdata = queryResult.datastrings;

      let rowCount = 0;
      if (numericaldata.length > 0) {
        rowCount = numericaldata[0].length;
      }

      if (rowCount > 0) {
        result = new BmsGatewayInfo();
        for (let i = 0; i < queryResult.columns.length; i++) {
          let it = queryResult.columns[i];
          let typ = it.datatype;
          let data = [];
          if (typ == XDataType.Number) {
            data = numericaldata[it.indexintypedvector];
          }
          if (typ == XDataType.String) {
            data = stringdata[it.indexintypedvector];
          }
          if (typ == XDataType.Timestamp) {
            data = timestampdata[it.indexintypedvector];
          }

          switch (it.columnoutname) {
            case 'id':
              result.id = data[0];
              break;
            case 'customerid':
              result.customerId = data[0];
              break;
            case 'customername':
              result.customerName = data[0];
              break;
            case 'name':
              result.name = data[0];
              break;
            case 'status':
              result.status = data[0];
              break;
            case 'metercount':
              result.meterCount = data[0];
              break;
            case 'metercountmissingvalue':
              result.meterCountMissingValue = data[0];
              break;
            case 'lastmetervalueat':
              result.lastMeterValueAt = data[0];
              break;
            case 'lastonlineat':
              result.lastOnlineAt = data[0];
              break;
            case 'modifiedat':
              result.modifiedAt = data[0];
              break;
          }
        }
      }
    }
    catch (err) {
      this.logger.error(err);
    }

    return result;
  }

}
