<clr-modal [(clrModalOpen)]="showRemoveUser">
  <h3 class="modal-title">Remove user</h3>
  <div class="modal-body">
    <p>Are you sure?</p>
  </div>
  <div class="modal-footer">
    <button type="button" class="btn btn-outline" (click)="showRemoveUser = false">Cancel</button>
    <button type="button" class="btn btn-primary" (click)="showRemoveUser = false; doRemoveSelectedUser()">Ok</button>
  </div>
</clr-modal>

<clr-modal [(clrModalOpen)]="showRemoveCustomerUser">
  <h3 class="modal-title">Remove user</h3>
  <div class="modal-body">
    <p>Are you sure?</p>
  </div>
  <div class="modal-footer">
    <button type="button" class="btn btn-outline" (click)="showRemoveCustomerUser = false">Cancel</button>
    <button type="button" class="btn btn-primary"
      (click)="showRemoveCustomerUser = false; doRemoveSelectedCustomerUser()">Ok</button>
  </div>
</clr-modal>

<clr-modal [(clrModalOpen)]="showAddUserModal">
  <h3 class="modal-title">Add user</h3>
  <div class="modal-body">
    <form clrForm (ngSubmit)="addCustomerUser()" *ngIf="customerId?.length > 0">
      <clr-input-container>
        <label>Firstname:</label>
        <input clrInput required placeholder="firstname" name="firstname" type="text" size="35"
          [(ngModel)]="newCustomerUser.firstname" />
        <clr-control-error>Please input firstname.</clr-control-error>
      </clr-input-container>
      <clr-input-container>
        <label>Surname:</label>
        <input clrInput required placeholder="surname" name="surname" type="text" size="35"
          [(ngModel)]="newCustomerUser.surname" />
        <clr-control-error>Please input surname.</clr-control-error>
      </clr-input-container>
      <clr-input-container>
        <label>Email:</label>
        <input clrInput required placeholder="email" name="email" type="text" size="45"
          [(ngModel)]="newCustomerUser.email" />
        <clr-control-error>Please input email.</clr-control-error>
      </clr-input-container>
      <clr-checkbox-container>
        <clr-checkbox-wrapper>
          <input type="checkbox" clrCheckbox [(ngModel)]="newCustomerUserSetPassword"
            name="newcustomerusersetpassword" />
          <label>Set password</label>
        </clr-checkbox-wrapper>
      </clr-checkbox-container>
      <clr-input-container *ngIf="newCustomerUserSetPassword">
        <label>Password:</label>
        <input clrInput placeholder="password" name="password" type="password" [(ngModel)]="newCustomerUserPassword" />
        <clr-control-error>Please input password.</clr-control-error>
      </clr-input-container>
      <!-- <clr-checkbox-container>
        <clr-checkbox-wrapper>
          <input type="checkbox" clrCheckbox [(ngModel)]="newUser.isAdmin" name="isadmin" />
          <label>Admin</label>
        </clr-checkbox-wrapper>
      </clr-checkbox-container> -->
    </form>

    <form clrForm (ngSubmit)="addUser()" *ngIf="customerId == undefined">
      <clr-input-container>
        <label>Firstname:</label>
        <input clrInput required placeholder="firstname" name="firstname" type="text" [(ngModel)]="newUser.firstname"
          size="45" />
        <clr-control-error>Please input firstname.</clr-control-error>
      </clr-input-container>
      <clr-input-container>
        <label>Surname:</label>
        <input clrInput required placeholder="surname" name="surname" type="text" [(ngModel)]="newUser.surname"
          size="45" />
        <clr-control-error>Please input surname.</clr-control-error>
      </clr-input-container>
      <clr-input-container>
        <label>Email:</label>
        <input clrInput required placeholder="email" name="email" type="text" [(ngModel)]="newUser.email" size="45" />
        <clr-control-error>Please input email.</clr-control-error>
      </clr-input-container>
      <!-- <clr-checkbox-container>
          <clr-checkbox-wrapper>
            <input type="checkbox" clrCheckbox [(ngModel)]="newUser.isAdmin" name="isadmin" />
            <label>Admin</label>
          </clr-checkbox-wrapper>
        </clr-checkbox-container> -->
    </form>
  </div>
  <div class="modal-footer">
    <button type="button" class="btn btn-outline" (click)="showAddUserModal = false">Cancel</button>
    <button *ngIf="customerId?.length > 0" type="button" class="btn btn-primary" (click)="addCustomerUser()">Add
      user</button>
    <button *ngIf="customerId == undefined" ype="button" class="btn btn-primary" (click)="addUser()">Add user</button>
  </div>
</clr-modal>

<div class="content-area" *ngIf="searchForm">
  <div class="card">
    <div class="card-block">
      <form clrForm autocomplete="off">
        <clr-input-container>
          <label>Firstname:</label>
          <input clrInput placeholder="firstname filter" name="firstnamefilter" type="text"
            [(ngModel)]="searchCustomerUser.firstname" />
        </clr-input-container>
        <clr-input-container>
          <label>Surname:</label>
          <input clrInput placeholder="surname filter" name="surnamefilter" type="text"
            [(ngModel)]="searchCustomerUser.surname" />
        </clr-input-container>
        <clr-input-container>
          <label>Email:</label>
          <input clrInput placeholder="email filter" name="emailfilter" type="text"
            [(ngModel)]="searchCustomerUser.email" />
        </clr-input-container>
        <clr-toggle-container>
          <clr-toggle-wrapper>
            <label>Customeradmins only</label>
            <input type="checkbox" clrToggle [(ngModel)]="searchCustomerUser.isAdmin" name="isadminfilter" />
          </clr-toggle-wrapper>
        </clr-toggle-container>
      </form>
      <button class="btn btn-outline" (click)="searchUsers()" style="margin-top: 1em;">
        <clr-icon shape="search"></clr-icon>
        Search
      </button>
      <button class="btn btn-outline-danger" (click)="clearSearchUsersForm()" style="margin-top: 1em;">
        <clr-icon shape="times"></clr-icon>
        Clear
      </button>
    </div>
  </div>
  <div class="card" *ngIf="customerUsers">
    <div class="card-header">
      Users
    </div>
    <div class="card-block">
      <div class="limit-height">
        <clr-datagrid>
          <clr-dg-column [clrDgField]="'customerUser.firstname'">
            <ng-container *clrDgHideableColumn="{hidden: false}">Firstname</ng-container>
          </clr-dg-column>
          <clr-dg-column [clrDgField]="'customerUser.surname'">
            <ng-container *clrDgHideableColumn="{hidden: false}">Surname</ng-container>
          </clr-dg-column>
          <clr-dg-column [clrDgField]="'customerUser.email'">
            <ng-container *clrDgHideableColumn="{hidden: false}">Email</ng-container>
          </clr-dg-column>
          <clr-dg-column [clrDgField]="'customerName'">
            <ng-container *clrDgHideableColumn="{hidden: false}">Customer</ng-container>
          </clr-dg-column>
          <clr-dg-column [clrDgField]="'customerUser.isAdmin'">
            <ng-container *clrDgHideableColumn="{hidden: false}">Admin</ng-container>
          </clr-dg-column>

          <clr-dg-column> </clr-dg-column>

          <clr-dg-row *clrDgItems="let user of customerUserWithCustomers; let i = index" [clrDgItem]="user">
            <clr-dg-cell>
              {{user.customerUser.firstname}}
            </clr-dg-cell>
            <clr-dg-cell>
              {{user.customerUser.surname}}
            </clr-dg-cell>
            <clr-dg-cell>
              {{user.customerUser.email}}
            </clr-dg-cell>
            <clr-dg-cell>
              {{user.customerName}}
            </clr-dg-cell>
            <clr-dg-cell>
              {{user.customerUser.isAdmin ? 'True' : 'False'}}
            </clr-dg-cell>
            <clr-dg-cell>
              <clr-icon (click)="viewCustomer(user);" shape="eye" [style.cursor]="'pointer'"></clr-icon>
            </clr-dg-cell>
          </clr-dg-row>

          <clr-dg-footer>
            <clr-dg-pagination #pagination [clrDgPageSize]="10">
              <clr-dg-page-size [clrPageSizeOptions]="[10,20,50,100]">Rows per page</clr-dg-page-size>
              {{pagination.firstItem + 1}} - {{pagination.lastItem + 1}} of {{pagination.totalItems}} rows
            </clr-dg-pagination>
          </clr-dg-footer>
        </clr-datagrid>
      </div>
    </div>
  </div>
</div>

<div class="content-area" *ngIf="customerId?.length > 0 && !searchForm">
  <div class="card" *ngIf="customerUsers">
    <div class="card-header">
      Admin Users
    </div>
    <div class="card-block">
      <div class="limit-height">
        <clr-datagrid [(clrDgSingleSelected)]="selectedCustomerUser"
          (clrDgSingleSelectedChange)="selectedCustomerUserChanged($event)">
          <clr-dg-column [clrDgField]="'firstname'">
            <ng-container *clrDgHideableColumn="{hidden: false}">Firstname</ng-container>
          </clr-dg-column>
          <clr-dg-column [clrDgField]="'surname'">
            <ng-container *clrDgHideableColumn="{hidden: false}">Surname</ng-container>
          </clr-dg-column>
          <clr-dg-column [clrDgField]="'email'">
            <ng-container *clrDgHideableColumn="{hidden: false}">Email</ng-container>
          </clr-dg-column>

          <!-- <clr-dg-column>Admin</clr-dg-column> -->
          <clr-dg-column> </clr-dg-column>

          <clr-dg-row *clrDgItems="let customerUser of customerUsers; let i = index" [clrDgItem]="customerUser">
            <clr-dg-cell>
              {{customerUser.firstname}}
            </clr-dg-cell>
            <clr-dg-cell>
              {{customerUser.surname}}
            </clr-dg-cell>
            <clr-dg-cell>
              {{customerUser.email}}
            </clr-dg-cell>
            <!-- <clr-dg-cell>
              {{user.isAdmin ? 'True' : 'False'}}
            </clr-dg-cell> -->
            <clr-dg-cell>
              <clr-icon (click)="removeCustomerUser(customerUser);" shape="trash" [style.cursor]="'pointer'"></clr-icon>
            </clr-dg-cell>
          </clr-dg-row>

          <clr-dg-footer>
            <clr-dg-pagination #pagination [clrDgPageSize]="10">
              <clr-dg-page-size [clrPageSizeOptions]="[10,20,50,100]">Rows per page</clr-dg-page-size>
              {{pagination.firstItem + 1}} - {{pagination.lastItem + 1}} of {{pagination.totalItems}} rows
            </clr-dg-pagination>
          </clr-dg-footer>
        </clr-datagrid>
      </div>
      <button class="btn btn-outline" (click)="showAddUserModal = true" style="margin-top: 1em;">
        <clr-icon shape="plus-circle"></clr-icon>
        Add user
      </button>
    </div>
  </div>

  <div class="card" *ngIf="selectedCustomerUser">
    <div class="card-header">
      {{selectedCustomerUser.firstname}} {{selectedCustomerUser.surname}}
    </div>
    <div class="card-block">
      <div class="clr-row">
        <div class="clr-col-6">
          <clr-input-container>
            <label>Firstname:</label>
            <input clrInput placeholder="firstname" name="editfirstname" type="text" size="35"
              [(ngModel)]="selectedCustomerUser.firstname" />
            <clr-control-error>Please input firstname.</clr-control-error>
          </clr-input-container>
          <clr-input-container>
            <label>Surname:</label>
            <input clrInput placeholder="surname" name="editsurname" type="text" size="35"
              [(ngModel)]="selectedCustomerUser.surname" />
            <clr-control-error>Please input surname.</clr-control-error>
          </clr-input-container>

          <button type="button" class="btn btn-outline reset-password"
            (click)="resetPassword(selectedCustomerUser)">Reset
            password...</button>
        </div>
        <div class="clr-col-6">
          <clr-input-container>
            <label>ApiKey </label>
            <input clrInput type="text" [(ngModel)]="selectedCustomerUser.apiKey" name="apikey" size="60"
              [disabled]="true" />
          </clr-input-container>
          <div style="margin-top: 1em;">
            <button type="button" class="btn btn-outline-danger"
              (click)="deleteApiKey(selectedCustomerUser)">Delete</button>
            <button type="button" class="btn btn-outline" (click)="generateNewApiKey(selectedCustomerUser)">Generate
              new</button>
          </div>
        </div>
      </div>
    </div>
    <div class="card-footer">
      <button type="button" class="btn btn-primary" (click)="saveCustomerUser(selectedCustomerUser)">Save</button>
    </div>
  </div>
</div>

<div class="content-area" *ngIf="customerId == undefined  && !searchForm">
  <div class="card" *ngIf="users">
    <div class="card-header">
      Admin Users
    </div>
    <div class="card-block">
      <div class="limit-height">
        <clr-datagrid>
          <clr-dg-column [clrDgField]="'firstname'">
            <ng-container *clrDgHideableColumn="{hidden: false}">Firstname</ng-container>
          </clr-dg-column>
          <clr-dg-column [clrDgField]="'surname'">
            <ng-container *clrDgHideableColumn="{hidden: false}">Surname</ng-container>
          </clr-dg-column>
          <clr-dg-column [clrDgField]="'email'">
            <ng-container *clrDgHideableColumn="{hidden: false}">Email</ng-container>
          </clr-dg-column>
          <!-- <clr-dg-column>Admin</clr-dg-column> -->
          <clr-dg-column> </clr-dg-column>

          <clr-dg-row *clrDgItems="let user of users; let i = index" [clrDgItem]="user">
            <clr-dg-cell>
              {{user.firstname}}
            </clr-dg-cell>
            <clr-dg-cell>
              {{user.surname}}
            </clr-dg-cell>
            <clr-dg-cell>
              {{user.email}}
            </clr-dg-cell>
            <!-- <clr-dg-cell>
                {{user.isAdmin ? 'True' : 'False'}}
              </clr-dg-cell> -->
            <clr-dg-cell>
              <clr-icon (click)="removeUser(user);" shape="trash" [style.cursor]="'pointer'"
                *ngIf="user.id != currentUserRights.session.userId"></clr-icon>
            </clr-dg-cell>
          </clr-dg-row>

          <clr-dg-footer>
            <clr-dg-pagination #pagination [clrDgPageSize]="10">
              <clr-dg-page-size [clrPageSizeOptions]="[10,20,50,100]">Rows per page</clr-dg-page-size>
              {{pagination.firstItem + 1}} - {{pagination.lastItem + 1}} of {{pagination.totalItems}} rows
            </clr-dg-pagination>
          </clr-dg-footer>
        </clr-datagrid>
      </div>
      <button class="btn btn-outline" (click)="showAddUserModal = true" style="margin-top: 1em;">
        <clr-icon shape="plus-circle"></clr-icon>
        Add user
      </button>
    </div>
  </div>
</div>
