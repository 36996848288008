import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { XProjectorBmsEventsClient } from '@core/xprojector_backend/xprojector-bms-events-client';
import { UserRights } from '@xprojectorcore/models/user-rights';
import { StateService } from '@xprojectorcore/services/state-service';
import { NGXLogger } from 'ngx-logger';
import { Subscription } from 'rxjs';
import { map } from 'rxjs/operators';
import { environment } from 'src/environments/environment';
import { DateHelper, XprojModalService } from 'xproj-lib';

@Component({
  selector: 'app-bms-unsubscribe-events',
  templateUrl: './bms-unsubscribe-events.component.html',
  styleUrls: ['./bms-unsubscribe-events.component.scss']
})
export class BmsUnsubscribeEventsComponent implements OnInit {

  logo: string;
  title: string;

  customerId : string = '';
  userEmail : string = '';
  userPhone : string = '';
  customerName : string;

  user: UserRights;
  private userSubscription: Subscription;

  initiated : boolean = false;

  constructor(private route: ActivatedRoute,
    private state: StateService,
    private logger: NGXLogger,
    private modalService: XprojModalService,
    private eventsClient : XProjectorBmsEventsClient,
    public dateHelper: DateHelper) {
      this.logo = environment.logo;
      this.title = environment.maintitle;
     }

  ngOnInit(): void {
    this.userSubscription = this.state.user$.subscribe(async (user) => {
      this.user = user;
    });

    this.route.params.pipe(map(p => p.customerid)).subscribe(async (customerId) => {
      if (customerId) {
        this.logger.info('ngOnInit customerId', customerId);
        this.customerId = customerId;
      }
    });

    this.userEmail = this.route.snapshot.queryParams['email'] ?? '';
    this.userPhone = this.route.snapshot.queryParams['phone'] ?? '';
    this.customerName = this.route.snapshot.queryParams['customername'] ?? '';
  }

  ngAfterViewInit(): void {

  }

  ngOnDestroy(): void {
    this.userSubscription?.unsubscribe();
  }

  async unsubscribeEvents() {
    let result = await this.eventsClient.unsubscribeEvents(this.customerId, this.userEmail, this.userPhone);
  }

}
