<table class="table table-vertical" *ngIf="buildingAddress">
  <caption style="margin-bottom: 1em;">
    Building address info
    <button class="btn btn-sm" style="float: right;" (click)="viewInBMS();">View in BMS <clr-icon
        shape="arrow" directions="left" style="transform: rotate(90deg);"></clr-icon></button>
  </caption>
  <tbody>
    <tr>
      <th class="left">Street</th>
      <td class="left">{{buildingAddress.street}}</td>
    </tr>
    <tr>
      <th class="left">Hosue number</th>
      <td class="left">{{buildingAddress.housenumber}}</td>
    </tr>
    <tr>
      <th class="left">Postal code</th>
      <td class="left">{{buildingAddress.postalcode}}</td>
    </tr>
    <tr>
      <th class="left">City</th>
      <td class="left">{{buildingAddress.city}}</td>
    </tr>
    <tr>
      <th class="left">Country</th>
      <td class="left">{{buildingAddress.country}}</td>
    </tr>
    <tr>
      <th class="left">Description</th>
      <td class="left">{{buildingAddress.description}}</td>
    </tr>
    <tr>
      <th class="left">Latitude</th>
      <td class="left">{{buildingAddress.latitude}}</td>
    </tr>
    <tr>
      <th class="left">Longitude</th>
      <td class="left">{{buildingAddress.longitude}}</td>
    </tr>
    <tr>
      <th class="left">Created</th>
      <td class="left">{{dateHelper.utils.format(buildingAddress.createdAt, 'keyboardDateTime')}}</td>
    </tr>
    <tr>
      <th class="left">Modified</th>
      <td class="left">{{dateHelper.utils.format(buildingAddress.modifiedAt, 'keyboardDateTime')}}</td>
    </tr>
  </tbody>
</table>

<p *ngIf="!buildingAddress"> Building address info not available.</p>
