import { BmsEditMode } from "@features/bms/models/bms-edit.mode";
import { BmsEnumMember } from "@features/bms/models/bms-enum-member";
import { GrpcNode, GrpcNodeProperty } from "@xprojectorcore/xprojector_backend/proto/xprojector.grpc.models.pb";
import { GrpcDateTimeProperty } from "@xprojectorcore/xprojector_backend/proto/xprojector.xconf.pb";
import { DateHelper, XDataType } from "xproj-lib";

import createFlakeID53 from "flakeid53";
export const flakeId = createFlakeID53({
  epoch: +new Date(),
  workerId: 2,
});

export class BmsDataUtils {

  static getEditMode(tags: string[], datatype : XDataType): BmsEditMode {
    let result: BmsEditMode;
    if (tags?.length > 0) {
      let tag = tags.find(tag => tag.startsWith('EDIT='));
      if (tag) {
        let s = tag.substr(5);
        switch (s) {
          case 'XDATATYPE':
            result = BmsEditMode.XDatatype;
            break;

          case 'BOOLEAN':
            result = BmsEditMode.Boolean;
            break;

          case 'ENUM':
            result = BmsEditMode.Enum;
            break;
        }
      }
    }

    if (!result) {
      switch (datatype) {
        case XDataType.UInt8:
        case XDataType.Float32:
        case XDataType.Float64:
        case XDataType.Int32:
        case XDataType.Int64:
        case XDataType.Number:
          result = BmsEditMode.Number;
          break;

        case XDataType.Timestamp:
          result = BmsEditMode.Timestamp;
          break


        default:
          result = BmsEditMode.String;
          break;
      }
    }

    return result;
  }

  static getEnumMembers(tags: string[]): BmsEnumMember[] {
    let result : BmsEnumMember[] = [];
    if (tags?.length > 0) {
      let tag = tags.find(tag => tag.startsWith('ENUMVALUES:'));
      if (tag) {
        let members = tag.substr(11).split(';');
        members.forEach(member => {
          let s = member.split('=');
          if (s.length == 2) {
            let member : BmsEnumMember = new BmsEnumMember();
            member.value = +s[0];
            member.name = s[1];
            result.push(member);
          }
        });
      }
    }

    return result;
  }

  static getValue(valueType: GrpcNodeProperty.ValueTypes, stringValue: string, property : any, dateHelper : DateHelper): any {

    switch (valueType) {
      case GrpcNodeProperty.ValueTypes.STRING:
        return stringValue;

      case GrpcNodeProperty.ValueTypes.STRINGARRAY:
        return stringValue.split('|');
      //return stringValue;

      case GrpcNodeProperty.ValueTypes.DOUBLE:
        return parseFloat(stringValue);

      case GrpcNodeProperty.ValueTypes.BOOLEAN:
        return stringValue.toLowerCase() == 'true';

      case GrpcNodeProperty.ValueTypes.DATETIME:
        let date: Date;
        date = dateHelper.utils.parse(stringValue, "yyyy-MM-dd HH:mm:ss");
        if (!dateHelper.utils.isValid(date)) {
          date = dateHelper.utils.parse(stringValue, "yyyy-MM-dd HH:mm");
          if (!dateHelper.utils.isValid(date)) {
            date = dateHelper.utils.parse(stringValue, "yyyy-MM-dd");
            if (!dateHelper.utils.isValid(date)) {
              date = dateHelper.utils.date(stringValue);
            }
          }
        }

        return date;
        // let dateTimeProperty: GrpcDateTimeProperty = property;
        // return dateTimeProperty.dateOnly ? dateHelper.utils.formatByString(date, 'yyyy-MM-dd') : dateTimeProperty.timeOnly ? dateHelper.utils.formatByString(date, 'HH:mm:ss') :
        //        dateHelper.utils.formatByString(date, "yyyy-MM-dd'T'HH:mm:ss");

      case GrpcNodeProperty.ValueTypes.STRINGOPTIONS:
        return stringValue;

      case GrpcNodeProperty.ValueTypes.DASHBOARD:
        return stringValue;
    }

    return stringValue;
  }

  static getPropertyValue(valueType: GrpcNodeProperty.ValueTypes, value: any, dateHelper : DateHelper): any {

    switch (valueType) {
      case GrpcNodeProperty.ValueTypes.STRING:
        return value + '';

      case GrpcNodeProperty.ValueTypes.STRINGARRAY:
        try {
          let arr: string[] = value;
          return arr.join('|');
        }
        catch {
          return value + '';
        }

      case GrpcNodeProperty.ValueTypes.DOUBLE:
        return value + '';

      case GrpcNodeProperty.ValueTypes.BOOLEAN:
        return value == true ? "true" : "false";

      case GrpcNodeProperty.ValueTypes.DATETIME:
        let date: Date = value;

        date = dateHelper.utils.parse(value, "yyyy-MM-dd'T'HH:mm:ss");
        if (!dateHelper.utils.isValid(date)) {
          date = dateHelper.utils.parse(value, "yyyy-MM-dd'T'HH:mm");
          if (!dateHelper.utils.isValid(date)) {
            date = dateHelper.utils.parse(value, "yyyy-MM-dd");
            if (!dateHelper.utils.isValid(date)) {
              date = dateHelper.utils.date(value);
            }
          }
        }
        return date.toUTCString();

      case GrpcNodeProperty.ValueTypes.STRINGOPTIONS:
        return value + '';

      case GrpcNodeProperty.ValueTypes.DASHBOARD:
        return value + '';

    }

    return value;
  }

  static getParamterValue(node : GrpcNode, key : string, dateHelper : DateHelper): any {
    let result : any = undefined;
    let property = node.propertyValues.find(p => p.key == key);
    if (property) {
      return this.getValue(property.valueType, property.value, property, dateHelper);
    }

    return result;
  }
}
