import { AfterViewInit, ChangeDetectorRef, Component, HostListener, OnInit, ViewChild } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { StateService } from '@xprojectorcore/services/state-service';
import { XProjectorCustomerUsersClient } from '@xprojectorcore/xprojector_backend/xprojector-customerusers-client';
import { NGXLogger } from 'ngx-logger';
import { map } from 'rxjs/operators';
import { WidgetExportParameters, XprojAlertService, XprojDashboardComponent } from 'xproj-lib';

@Component({
  selector: 'app-bms-admin-dashboard',
  templateUrl: './bms-admin-dashboard.component.html',
  styleUrls: ['./bms-admin-dashboard.component.scss']
})
export class BmsAdminDashboardComponent implements OnInit, AfterViewInit {

  @ViewChild("dashboard", { read: XprojDashboardComponent, static: false }) xprojDashboard: XprojDashboardComponent;

  responsiveWidth: number = 834;

  dashboardId: string;

  constructor(
    public state: StateService,
    private logger: NGXLogger,
    private customerUserClient: XProjectorCustomerUsersClient,
    private alertService: XprojAlertService,
    private cdr: ChangeDetectorRef,
    private route: ActivatedRoute) {

  }

  async ngOnInit() {
    this.route.params.pipe(map(p => p.id)).subscribe(async (dashboardId) => {
      this.dashboardId = dashboardId;
      this.cdr.detectChanges();
      await this.xprojDashboard?.setDashboardId(this.dashboardId, -1, '', true);
    });
  }

  ngAfterViewInit(): void {
    this.applyNewWidth(window.innerWidth);
  }

  @HostListener('window:resize', ['$event'])
  onResize(event) {
    this.applyNewWidth(event.target.innerWidth);
    this.logger.debug('applyNewWidth resize', event.target.innerWidth);
  }

  applyNewWidth(width) {
    this.logger.info("applying width: ", width);
    if (width > 850) {
      this.responsiveWidth = width - 300;
      return;
    }
    this.responsiveWidth = width;
  }

  async onWidgetExport(parameters: WidgetExportParameters) {
    let result = await this.customerUserClient.exportToExcel(parameters, 100000);

    if (!result) {
      this.alertService.error('Error export to excel!');
    }
  }

}
