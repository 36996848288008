import { EventEmitter, Inject, Injectable, OnDestroy, Output } from '@angular/core';
import { encode,decode } from "@msgpack/msgpack";
import { CacheService } from '../cache/cache-service';

import 'reflect-metadata';
import { Observable, Subject, timer } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { jsonObject, jsonMember, jsonArrayMember, jsonMapMember, TypedJSON } from 'typedjson';
import { cacheTTLSeconds, cacheEnabled } from '../misc-service'
import { XProjectClientSettings, XPROJECTORCLIENTSETTINGS } from './xprojector-client-settings-service';
import { LOGGERSERVICE, XprojLoggerService } from '../logger/xproj-logger-service';

export interface PromiseResolve {
	resolve : (response ?: Object) => void;
	reject : (reason ?: any) => void;
};

export function MsgPackCloneObject(data : Object) : Object
{
  return decode(encode(data));
}

// This one is just marked for export level because of crap reasons right now
export class XProjectorLowlevelClient
{
  currentMessageLength : number = 0;
  currentMessageBuffer : Uint8Array;
  currentMessageFetchedBytes : number = 0;
  requestid : number = 0;

  private token : string = '';
  private clientRemotenodeid : string = null;

  MessageHeaderBuffer;
  MessageHeaderBytesFetched;

  constructor(private settings : XProjectClientSettings,
    @Inject(LOGGERSERVICE) private logger: XprojLoggerService) {
    this.MessageHeaderBuffer = new Uint8Array(4);
    this.MessageHeaderBytesFetched = 0;
  }

  public socket : WebSocket = null;
  public isConnected:boolean = false;
  public isAuthenticated:boolean = false;
  public SocketState : string = "NOT CONNECTED";

  public OnAuthenticated : () => void;
  public OnDisconnected : () => void;
  public OnMessage : (Data : Object) => void;

  public SetHost(host : string) {
    if (this.settings) {
      this.settings.host = host;
    }
  }

  public GetNextMessageID(): number{
    return this.requestid++;
  }

  public async SendMessageIgnoreID(data : Object, ignoreTargetRemoteId? : boolean)
  {
    if(!ignoreTargetRemoteId)
    {
      if(this.clientRemotenodeid?.length > 0)
      {
        this.logger.debug("Sending data to target remotenodeid:", this.clientRemotenodeid, data);
        data["targetnodeid"] = this.clientRemotenodeid;
      }
    }

    this.logger.debug("SendMessageIgnoreID", this.clientRemotenodeid);
    const encoded: Uint8Array = encode(data);

    let lenarr = new ArrayBuffer(4 + encoded.byteLength);
    let lenview = new DataView(lenarr);
    lenview.setUint32(0, encoded.byteLength, true);
    //this.logger.info("sending msgpack with length " + encoded.byteLength.toString());


    for(let i = 0; i < encoded.byteLength; i++)
    {
      lenview.setUint8(i+4, encoded[i] );
    }

    this.socket.send(lenarr);
  }

  public async SendMessage(data : Object)
  {
    data["messageid"] = this.GetNextMessageID();
    if(this.clientRemotenodeid?.length > 0)
    {
      this.logger.debug("Sending data to target remotenodeid:", this.clientRemotenodeid);
      data["targetnodeid"] = this.clientRemotenodeid;
    }
    this.SendMessageIgnoreID(data);
  }

  SendLoginInformation()
  {
    let message = {
      request: "authenticate",
      token: this.token
    }
    message["messageid"] = this.GetNextMessageID();
    this.SendMessageIgnoreID(message, true /*ignore remote node id */);
  }

  async HandleOnConnect()
  {
    this.SocketState = "OPEN";
    this.logger.info("on connect");
    this.SendLoginInformation();
    this.isConnected = true;
    this.isAuthenticated = false;
    this.logger.info('OPEN');
  }

  async HandleOnClose()
  {
    this.SocketState = "CLOSED";
    this.isConnected = false;
    this.isAuthenticated = false;
    this.OnDisconnected();
    this.logger.info('CLOSED ONCLOSE');
  }

  async HandleOnError(ev : Event)
  {
    this.SocketState = "CLOSED";
    this.isConnected = false;
    this.isAuthenticated = false;
    this.OnDisconnected();

    this.logger.info('CLOSED ERROR');
  }

  HandleAuthenticationMessage(data : Object)
  {
    this.logger.info("Got authentication answer:");
    if(data["method"] != "authenticate")
    {
      this.logger.info("Incorrect response in authentication");
      this.socket.close();
      return;
    }
    if(data["success"] != true)
    {
      this.logger.info("Could not log in");
      this.socket.close();
      return;
    }

    this.logger.info("Authenticated!");
    this.isAuthenticated = true;
    this.OnAuthenticated();
  }

  HandleMessage(data : Object)
  {
    //  isAuthenticated
    if(!this.isAuthenticated)
    {
      this.HandleAuthenticationMessage(data);
      return;
    }

    //this.logger.info('HANDLE MESSAGE', data);

    this.OnMessage(data);
  }


  DataReceived( data: number) : void
  {
    if(this.MessageHeaderBytesFetched < this.MessageHeaderBuffer.byteLength)
    {
      this.MessageHeaderBuffer[this.MessageHeaderBytesFetched++] = data;

      if(this.MessageHeaderBytesFetched < this.MessageHeaderBuffer.byteLength)
      return;

      let view = new DataView(this.MessageHeaderBuffer.buffer);
      this.currentMessageLength = view.getUint32(0, true);
      //this.logger.info("starting to receive message with lenght size of " + this.currentMessageLength.toString());
      this.currentMessageBuffer = new Uint8Array(this.currentMessageLength);

      return;
    }

    this.currentMessageBuffer[this.currentMessageFetchedBytes++] = data;

    if(this.currentMessageFetchedBytes < this.currentMessageLength)
    return;

    try
    {
      let NewMessage = decode(this.currentMessageBuffer);
      //this.logger.info("completed receving message with lenght size of " +
      //this.currentMessageLength.toString() +
      //" unpacked size: " + JSON.stringify(NewMessage).length );
      this.HandleMessage(NewMessage);
    }
    catch(err)
    {
      this.logger.info("error in messagepack parsing", err)
    }

    this.currentMessageFetchedBytes = 0;
    this.currentMessageBuffer = null;
    this.currentMessageLength = 0;
    this.MessageHeaderBytesFetched = 0;
  }

  HandleOnMessage(message : MessageEvent) : void
  {
    let view = new DataView(message.data);
    for(let i = 0; i < message.data.byteLength; i++)
    {
      this.DataReceived( view.getUint8(i) );
    }
  }


  public SetActiveRemoteNodeID(remotenodeid: string)
  {
    this.logger.info("Setting target remotenodeid:", this.clientRemotenodeid);
    this.clientRemotenodeid = remotenodeid;
  }

  public async Connect(token : string = '')
  {

    this.Disconnect();
    if (token?.length > 0) {
      this.token = token;
    }

    this.logger.info('Connect()', this.token);

    this.isAuthenticated =false;
    this.isConnected = false;
    this.socket = new WebSocket(this.settings.host);
    this.socket.binaryType = 'arraybuffer';

    this.socket.onopen = this.HandleOnConnect.bind(this);
    this.socket.onmessage = this.HandleOnMessage.bind(this);
    this.socket.onclose = this.HandleOnClose.bind(this);
    this.socket.onerror = this.HandleOnError.bind(this);
  }


  public Disconnect()
  {
    if (this.socket) {
      this.socket.close();
    }
  }
}

// Enums
export enum XDataType
{
  Float32 = 0,
  Float64 = 1,
  UInt8 = 2,
  Int32 = 3,
  Int64 = 4,
  Number = 5,
  Timestamp = 6,
  String = 7,
  UInt128=8
}

export enum FilterComparator
{
    Equals = 0,
    NotEquals = 1,
    LessThan = 2,
    LessThanOrEquals = 3,
    GreaterThan = 4,
    GreatherThanOrEquals = 5,
    BitAnd = 6,
    BitExlusiveOr = 7
};

export enum FilterLogicalGroupType
{
    AND = 0,
    OR = 1
};

export enum RelativeTimestampOrigo
{
    NOW                         = 0,
    NOWUTC                      = 1,
    EXACT                       = 2,
    BEGINNING_OF_THIS_YEAR      = 3,
    BEGINNING_OF_THIS_MONTH     = 4,
    BEGINNING_OF_THIS_DAY       = 5,
    BEGINNING_OF_THIS_HOUR      = 6,
    BEGINNING_OF_THIS_MINUTE    = 7,
    BEGINNING_OF_THIS_YEARUTC   = 8,
    BEGINNING_OF_THIS_MONTHUTC  = 9,
    BEGINNING_OF_THIS_DAYUTC    = 10,
    BEGINNING_OF_THIS_HOURUTC   = 11,
    BEGINNING_OF_THIS_MINUTEUTC = 12

};

export enum OffsetType
{
    YEAR      = 0,
    MONTH     = 1,
    DAY       = 2,
    HOUR      = 3,
    MINUTE    = 4,
    SECOND    = 5
};

export enum DownSampleMethod
{
    Average = 0,
    NearestNeighbour =1,
    Max = 2,
    Min = 3
};

export enum DFTMethod
{
  AUTO                      = 0,
  DFT_GAP_FILL_ZERO         = 1,
  DFTP_GAP_REPEAT           = 2,
  DFT_GAP_INTERPOLATE       = 3,
  DFT_NON_UNIFORM           = 4
};

export enum Transformation
{
    NONE                                = 0,

    TIMESTAMP_RESOLUTION_YEAR           = 1,
    TIMESTAMP_RESOLUTION_MONTH          = 2,
    TIMESTAMP_RESOLUTION_DAY            = 3,
    TIMESTAMP_RESOLUTION_HOUR           = 4,
    TIMESTAMP_RESOLUTION_MINUTE         = 5,
    TIMESTAMP_RESOLUTION_SECOND         = 6,

    TIMESTAMP_DAYOFYEAR                 = 7,
    TIMESTAMP_DAYOFMONTH                = 8,
    TIMESTAMP_DAYOFWEEK                 = 9,
    TIMESTAMP_SECONDS_OF_DAY            = 10,
    TIMESTAMP_MINUTES_OF_DAY            = 11,
    TIMESTAMP_HOURS                     = 12,
    TIMESTAMP_MINUTES                   = 13,
    TIMESTAMP_SECONDS                   = 14,

    FIXED_BINSIZE                       = 15,

    FORWARD_DIFF                        = 16,

    ABSOLUTE_VALUE                      = 18
};

export enum Aggregation
{
    // None is actually a non-useable agg func (not like none in transforms)
    NONE                = 0,
    COUNT               = 1,
    SUM                 = 2,
    MAX                 = 3,
    MIN                 = 4,
    FIRST               = 5,
    LAST                = 6,
    CHECKSUM            = 7,
    FORWARD_DIFF        = 8,
    DISTINCT_COUNT      = 10,

    MEAN_ARITHMETIC     = 20,
    MEAN_GEOMETRIC      = 21,
    MEAN_HARMONIC       = 22,

    MEDIAN              = 40,

    SD_SAMPLED_UNCORRECTED          = 50,
    SD_SAMPLED_CORRECTED            = 51,
    SD_SAMPLED_UNBIASED_APPROX_ND   = 52,

    MAD_ORIGO_MEAN_ARITHMETIC    = 70,
    MAD_ORIGO_MEAN_GEOMETRIC     = 71,
    MAD_ORIGO_MEAN_HARMONIC      = 72,

    MAD_ORIGO_MEDIAN    = 80,

    VARIANCE_ND         = 100,
    S2_ND               = 101
};

// Communications classes

@jsonObject
export class ColumnFiltering
{
  @jsonMember
  queryfilterid : number = 0;

  @jsonMember
  comparator : FilterComparator;

  @jsonMember
  columnname : string = '';
}

@jsonObject
export class ColumnFilteringNumerical extends ColumnFiltering
{
  @jsonMember
  value : number;
}

@jsonObject
export class ColumnFilteringString extends ColumnFiltering
{
  @jsonMember
  value : string;
}
@jsonObject
export class ColumnFilteringTimestamp extends ColumnFiltering
{
  @jsonMember
  value : Date;
}

@jsonObject
export class ColumnFilteringRelativeTimestamp extends ColumnFiltering
{
  @jsonMember
  origo : RelativeTimestampOrigo = RelativeTimestampOrigo.NOW;

  @jsonMember
  exactorigo : Date;

  @jsonMember
  offsetyears : number = 0;

  @jsonMember
  offsetmonths : number = 0;

  @jsonMember
  offsetdays : number = 0;

  @jsonMember
  offsethours : number = 0;

  @jsonMember
  offsetminutes : number = 0;

  @jsonMember
  offsetseconds : number = 0;

}

@jsonObject
export class FilterLogicalGroup
{
    @jsonMember
    public queryfilterid : number = 0;

    @jsonMember
    public type : FilterLogicalGroupType = FilterLogicalGroupType.AND;

    @jsonArrayMember(Number)
    public filters : Array<number> = new Array<number>();
}


@jsonObject
export class ColumnSortingDescription
{
  @jsonMember
  public columnname : string = "";

  @jsonMember
  public descending:boolean =false;
}

@jsonObject
export class ColumnGroupingDescription
{
  @jsonMember
  public columnname : string = '';

  @jsonMember
  public columnoutname: string = '';

  @jsonMember
  public columntransformation : Transformation =Transformation.NONE;

  @jsonMember
  public parameter : number = 0.0;
}

@jsonObject
export class BaseQueryInputColumnDescription
{
  @jsonMember
  public columnname : string = '';

  @jsonMember
  public columnoutname: string = '';

  @jsonMember
  public columnaggregation : Aggregation;

  @jsonMember
  public columntransformation : Transformation = Transformation.NONE;

}

@jsonObject
export class LuaExportColumn {
  @jsonMember
  public columnname : string = '';

  @jsonMember
  public scriptname : string = '';
}

@jsonObject
export class LuaQueryColumn {
  @jsonArrayMember(LuaExportColumn)
  public inputcolumns : LuaExportColumn[] = [];

  @jsonMember
  public datatypeout : XDataType = XDataType.String;

  @jsonMember
  public columnoutname : string = '';

  @jsonMember
  public luascript : string = '';
}


@jsonObject
export class BaseQuery
{
  @jsonMember
  public targetprojectionid : string;

  @jsonArrayMember(String)
  public targetgroup: Array<string> = new Array<string>();

  @jsonArrayMember(BaseQueryInputColumnDescription)
  public columns: Array<BaseQueryInputColumnDescription> = new Array<BaseQueryInputColumnDescription>();

  @jsonArrayMember(LuaQueryColumn)
  public scriptedcolumnspostaggregation : LuaQueryColumn[] = [];

  @jsonMember
  public filter : FilterLogicalGroup = new FilterLogicalGroup();

  @jsonArrayMember(FilterLogicalGroup)
  public subfiltergroups : Array<FilterLogicalGroup> = new Array<FilterLogicalGroup> ();

  @jsonArrayMember(ColumnFilteringString)
  public stringfilters : Array<ColumnFilteringString> = new Array<ColumnFilteringString>();

  @jsonArrayMember(ColumnFilteringTimestamp)
  public timestampfilters : Array<ColumnFilteringTimestamp> = new Array<ColumnFilteringTimestamp>();

  @jsonArrayMember(ColumnFilteringRelativeTimestamp)
  public relativetimestampfilters : Array<ColumnFilteringRelativeTimestamp> = new Array<ColumnFilteringRelativeTimestamp>();

  @jsonArrayMember(ColumnFilteringNumerical)
  public numericalfilters : Array<ColumnFilteringNumerical> = new Array<ColumnFilteringNumerical>();

  @jsonMember
  public sorting : ColumnSortingDescription = new ColumnSortingDescription();

  @jsonMember
  public grouping : ColumnGroupingDescription = new ColumnGroupingDescription();

  @jsonMember
  public seekoffset : number = 0;

  @jsonMember
  public maxitems : number = 0;

  public Clone() : BaseQuery {
	  return TypedJSON.parse(TypedJSON.stringify(this, BaseQuery), BaseQuery);
  }


}

export class BaseQueryOutputColumnDescription
{
  public columnname : string;
  public columnoutname : string;
  public ColumnTransformation : number;
  public datatype : XDataType;
  public primarykey : boolean;
  public writable : boolean;
  public indexintypedvector : number;
}

export class BaseQueryResult
{
  public nrpoints : number;
  public nroriginalpoints : number;
  public columns : Array<BaseQueryOutputColumnDescription>;
  public datanumbers : Array<Array<number>>;
  public datatimestamps : Array<Array<Date>>;
  public datastrings : Array<Array<string>>;
}

export class TextSearchQuery
{
    public targetprojectionid: string = "";
    public targetgroup: Array<string> = new Array<string>();
    public columnname: string = ""; // If empty : Search all columns
    public searchvalue : string = "";
    public seekoffset: number = 0;
    public maxitems : number = 100;
}

export class TextSearchQueryResult
{
    public strings : Array<string>;
    public nrpoints : number;
    public nroriginalpoints : number;
}


export class DownSampleQuery
{
    public query  :BaseQuery = new BaseQuery();
    public method : DownSampleMethod = DownSampleMethod.Average;
}



export enum MultiSeriesQueryType
{
    DIMENSIONAL_JOIN = 0,
    FUZZY      = 100
};

export enum MultiSeriesFuzzyMatchType
{
    NUMTIME_CLOSEST                     = 0,
    NUMTIME_SORT_SWEEP                  = 10,
    NUMTIME_SORT_ALIGN_START_THEN_SWEEP = 20,
    NUMTIME_SORT_VERIFY_SIGN_SWEEP      = 30,

    STRING_MATCH_START_CASE_SENSITIVE       = 1000,
    STRING_MATCH_START_CASE_INSENSITIVE     = 1001,
    STRING_MATCH_END_CASE_SENSITIVE         = 1002,
    STRING_MATCH_END_CASE_INSENSITIVE       = 1003,
    STRING_MATCH_CONTAINS_CASE_SENSITIVE    = 1004,
    STRING_MATCH_CONTAINS_CASE_INSENSITIVE  = 1005
};

export class MultiseriesQuery
{
    public type : MultiSeriesQueryType = MultiSeriesQueryType.DIMENSIONAL_JOIN;
    public fuzzytype : MultiSeriesFuzzyMatchType = MultiSeriesFuzzyMatchType.NUMTIME_CLOSEST;
    public queries : BaseQuery[] = [];
    public sorting : ColumnSortingDescription = new ColumnSortingDescription();
    public seekoffset: number = 0;
    public maxitems : number = 100;
    public joinablecolumns : string[] = [];
    public scriptedcolumnspostjoin : LuaQueryColumn[] = [];
}

export class DFTQuery
{
    public query  :BaseQuery = new BaseQuery();
    public method : DFTMethod = DFTMethod.AUTO;
}


export class SPCQueryDistributionConfig
{
    public mean : number = 0.0;
    public stddev : number = 0.0;
};

export enum SPCChartType
{
    // Distribution specific
    XBAR        = 0,
    SIGMA       = 1,
    INDIVIDUALS = 2,
    P           = 3,
    NP          = 4,
    RANGE       = 5,
    EWMA        = 6
    // Distribution free
};

export class SPCQuery
{
    type : SPCChartType = SPCChartType.XBAR;
    distribution : SPCQueryDistributionConfig;
    query : BaseQuery;
    nelsonsignal1_enabled : boolean = false;
    nelsonsignal2_enabled : boolean = false;
    nelsonsignal3_enabled : boolean = false;
    nelsonsignal4_enabled : boolean = false;
    nelsonsignal5_enabled : boolean = false;
    nelsonsignal6_enabled : boolean = false;
    nelsonsignal7_enabled : boolean = false;
    nelsonsignal8_enabled : boolean = false;
    seekoffset : number = 0;
    maxitems: number = 1000;

    public constructor()
    {
    }
}

export class GroupsQuery
{
    public targetprojectionid : string;
    public joinedmatch : string = "";
    public groups : Array<string> = new Array<string>();
    public seekoffset : number = 0;
    public maxitems : number = 0;
}

export class GroupResult
{
    public group : Array<string>;
    public hasdata : boolean;
    public haschildren : boolean;
};

export class GroupsQueryResult
{
    public groups : Array<GroupResult>;
    public nrgroups : number;
    public nroriginalgroups : number;
};

export class SetDataColumnDescription {
  public columnname : string;
  public datatype : XDataType;
  public indexintypedvector : number;
}

export class SetDataQuery {
  public projectionid : string;
  public columns : Array<SetDataColumnDescription> = new Array<SetDataColumnDescription>();
  public datanumbers : Array<Array<number>>;
  public datatimestamps : Array<Array<Date>>;
  public datastrings : Array<Array<string>>

}

export class SetSplittedDataQuery {
  public projectionid : string;
  public group: string[] = [];
  public columns : Array<SetDataColumnDescription> = new Array<SetDataColumnDescription>();
  public datanumbers : Array<Array<number>>;
  public datatimestamps : Array<Array<Date>>;
  public datastrings : Array<Array<string>>

}

export class ClientSideIterativeQueryLevel
{
  public Query: Array<BaseQuery> = new Array<BaseQuery>();
}

export class ClientSideIterativeQuery
{

}

@jsonObject
export class ProjectionColumnDescription
{
  @jsonMember
  public projectioncolumndescriptionid : string = "";

  @jsonMember
  public createdwhenutc : Date = new Date();

  @jsonMember
  public lastmodifiedutc : Date= new Date();

  @jsonMember
  public projectionid : string = "";

  @jsonMember
  public columnname : string = "";

  @jsonMember
  public scriptname : string = "";

  @jsonMember
  public primarykey : boolean = false ;

  @jsonMember
  public datatype : XDataType = XDataType.Number;

  @jsonMember
  public unit : string = "";

  @jsonMember
  public writable : boolean = false;

  @jsonMember
  public luapostprocessingscript : string = "";

  @jsonArrayMember(String)
  public group: Array<string> = new Array<string>();

  @jsonArrayMember(String)
  public tags: Array<string> = new Array<string>();

  //@jsonMapMember(String, String)
  public externalkeys : Map<string,string> = new Map<string,string>();

  @jsonMember
  public luascriptorder : number = 0;

}

export class ProjectionColumnGroupColumnDescription
{
  public projectioncolumndescriptionid : string;
  public transformluascript : string;
}

export class ProjectionColumnGrouping
{
  public projectioncolumngroupid : string;
  public groupcolumns : Array<ProjectionColumnGroupColumnDescription> = new Array<ProjectionColumnGroupColumnDescription>();
  public outputcolumns : Array<ProjectionColumnDescription>  = new  Array<ProjectionColumnDescription>();
  public transformcolumndescriptionluascript: string ="";
}

export class ProjectionDependencyProjection
{
  public projectionid : string;
  public scriptname : string;
}

export class ProjectionDependencyRemote
{
  public remotenodeid : string;
  public scriptname : string;
}

export class ProjectionDependencies
{
    public globalluascriptids : Array<string> = new Array<string>();
    public datasourceids : Array<string> = new Array<string>();
    public projections : Array<ProjectionDependencyProjection> = new Array<ProjectionDependencyProjection> ();
    public remotes : Array<ProjectionDependencyProjection> = new Array<ProjectionDependencyProjection> ();
};

export enum ProjectionExecutionEntry
{
  DataSourceQuery     = 0,
  ProjectionCursor    = 1,
  LuaMainFunction     = 2
}
export class ProjectionExecutionDataSourceQuery
{
  public projectionexecutiondatasourcequeryid : string = "";
  public projectionexecutionid : string = "";
  public entrypointdatasourceid : string = "";
  public entrypointdatasourcequery : string = "";
  public modifiedatfieldname : string = "";
  public deletedfieldname : string = "";
}

export class ProjectionExecutionLuaMainFunction
{
  public projectionexecutionluamainfunctionid : string = "";
  public projectionexecutionid : string = "";
  public luascript : string = "";
}

export class ProjectionExecution
{
  public projectionexecutionid : string ="";
  public projectionid : string = "";
  public isdefaultexecution : boolean = false;
  public entrypoint : ProjectionExecutionEntry;

  public grouping : ProjectionColumnGrouping = new ProjectionColumnGrouping();
}


export class ProjectionExecutionLog
{
  public projectionexecutionlogid: string;
  public projectionid : string;
  public startedutc : Date;
  public finishedutc : Date;
  public beganwaitingfordatasourcesutc : Date;
  public finishedwaitingfordatasourcesutc: Date;
  public finishedwaitingforprojectionsutc : Date;
  public succesful: boolean;
  public log : string;
}

export class ProjectionScheduling
{
    public minintervalseconds : number = 60*5;
    public autocollect : boolean = false;
    public maxrows : number = 0;
}

export class Projection
{
  public projectionid : string;
  public parentprojectionid : string ="";
  public schedulingqueue : string = "";
  public projectiondatapath : string = "";
  public enabled : boolean;
  public createdwhenutc : Date;
  public lastmodifiedutc : Date;
  public lastprojectedutc : Date;
  public name : string;
  public prescriptlua : string = "";
  public postscriptlua : string = "";
  public description : string;
  public dependencies : ProjectionDependencies = new ProjectionDependencies();
  public tags: Array<string> = new Array<string>();
  public externalkeys : Map<string,string> = new Map<string,string>();
  //public execution: ProjectionExecution = new ProjectionExecution();
  public scheduling : ProjectionScheduling = new ProjectionScheduling();
}

export enum DataSourceDriver
{
  Broken      = 0,
  MongoDB     = 1,
  SQLServer   = 2,
  Oracle      = 3,
  PostgreSQL  = 4,
  MySQL       = 5,
  SQLite      = 6,
}


export class DataSource
{
  public datasourceid : string;
  public createdwhenutc : Date = new Date();
  public lastmodifiedutc : Date = new Date();
  public name : string = "";
  public enabled: boolean = true;
  public driver: DataSourceDriver = DataSourceDriver.Broken;
  public maxconcurrentqueries : number = 1;
  public maxconcurrentprojectionjobs : number = 1;
  public writeable : boolean = false;
  public tags: Array<string> = new Array<string>();
  public externalkeys : Map<string,string> = new Map<string,string>();
}

export class DataSourceSQLServer
{
  public datasourceid : string;
  public datasourcesqlserverid : string;
  public createdwhenutc : Date = new Date();
  public lastmodifiedutc : Date = new Date();
  public databasename : string ="";
  public hostname : string = "";
  public port : number = 1433;
  public username : string = "";
  public password : string = "";
  public serverencoding : string = "";
}

export class DataSourcePostgreSQL
{
  public datasourceid : string;
  public datasourcepostgresqlid : string;
  public createdwhenutc : Date = new Date();
  public lastmodifiedutc : Date = new Date();
  public databasename : string ="";
  public hostname : string = "";
  public port : number = 5432;
  public username : string = "";
  public password : string = "";
}

export class DataSourceMySQL
{
  public datasourceid : string;
  public datasourcemysqlid : string;
  public createdwhenutc : Date = new Date();
  public lastmodifiedutc : Date = new Date();
  public databasename : string ="";
  public hostname : string = "";
  public port : number = 5432;
  public username : string = "";
  public password : string = "";
}


export class DataSourceSQLite
{
  public datasourceid : string;
  public datasourcesqliteid : string;
  public createdwhenutc : Date = new Date();
  public lastmodifiedutc : Date = new Date();
  public databasename : string ="";
}

export class DataSourceMongoDB
{
  public datasourceid : string;
  public datasourcemongodbid : string;
  public createdwhenutc : Date = new Date();
  public lastmodifiedutc : Date = new Date();
  public databasename : string ="";
  public hostname : string = "";
  public port : number = 27017;
  public username : string = "";
  public password : string = "";
}

export class DataSourceProbeQuery
{
  public targetdatasourceid : string;
  public query : string;
}

export class DataSourceProbeQueryColumnDescription
{
  public columnname : string;
  public datatype : XDataType;
  public indexintypedvector : number;
}

export class DataSourceProbeQueryResult
{
  public nrpoints : number =0;
  public columns : Array<DataSourceProbeQueryColumnDescription> = new Array<DataSourceProbeQueryColumnDescription>();
  public datanumbers : Array<Array<number>>;
  public datatimestamps : Array<Array<Date>>;
  public datastrings : Array<Array<string>>;
}

export class ResetProjectionDataQuery
{
  public projectionid : string;
  public group : string[]
}

export class VersionResponse
{
  public branch : string;
  public commithash : string;
  public uncommittedchanges : boolean;
  public commitdate : Date;
  public builddate : Date;
}

export class Blob
{
  public id: string;
  public data : Uint8Array; // Does this work with messagepack?
  public hash : string;
}

export class SetBlob
{
  public id: string;
  public data : Uint8Array; // Does this work with messagepack?
  public previous_hash : string;
}

// Debugger

export class LuaDebuggerCommandAttach
{
  public sid: string;
}

export class LuaDebuggerCommandRelease
{
  public sid: string;
}
export class LuaDebuggerCommandRun
{
  public sid: string;
}


export class LuaDebuggerCommandStepInto
{
  public sid: string;
}


export class LuaDebuggerCommandStepOver
{
  public sid: string;
}


export class LuaDebuggerCommandSetBreakpoints
{
  public sid: string;
  public lines: number[];
}


export class LuaDebuggerCommandGetValues
{
  public sid: string;
  public instances: string[];
}

export class LuaDebuggerCommandGetValuesResponse
{
  public sid: string;
  public values: Map<string, string>;
}

export class LuaDebuggerCommandGetSource
{
  public sid: string;
}

export class LuaDebuggerCommandGetSourceResponse
{
  public sid: string;
  public sourcehash: number;
  public source: string;
}

export class LuaDebuggerCommandGetStatus
{
  public sid: string;
}

export enum LuaDebuggerState
{
    NotRunning  = 0,
    Running     = 10,
    Paused      = 20,
    Aborted     = 200
};

export class LuaDebuggerCommandGetStatusResponse
{
  public sid: string;
  public state: LuaDebuggerState;
  public pausedatsourcehash : number;
  public pausedatline : number;
}

// XAUTOMATION
export class XAUTO_XAutoProcessGraph
{
  public xautoprocessgraphid : string = "";
  public name : string = "";
  public graphxml : string = "";
  public svg : string = "";
  public svgbindings : string = "";
  public xautogroup : string = "";
  public xgroup : string = "";
  public description : string = "";
  public lastmodified : Date = new Date();
  public numericalmapxautovariables :  Array<XAUTO_PLCVariableNumericalMapXAutoVariable> = new Array<XAUTO_PLCVariableNumericalMapXAutoVariable>();
};

export class XAUTO_XAutoProcess3DGraph
{
  public xautoprocess3dgraphid : string = "";
  public name : string = "";
  public ifc : string = "";
  public fcstd : string = "";
  public babylon : string = "";
  public xautogroup : string = "";
  public xgroup : string = "";
  public description : string = "";
  public numericalmapxautovariables :  Array<XAUTO_PLCVariableNumericalMapXAutoVariable> = new Array<XAUTO_PLCVariableNumericalMapXAutoVariable>();
  public lastmodified : Date = new Date();
}


@jsonObject
export class XAUTO_PLCVariableNumericalMapXAutoVariable
{
    @jsonMember
    public scriptname : string = "";
    @jsonMember
    public description: string = "";

    // These must resolve to a 1:1 identifier
    @jsonMember
    public xautovariableid : string = ""; // either by id
    // or by combination of the three entries below
    @jsonMember
    public xname : string = "";
    @jsonMember
    public xautogroup : string = "";
    @jsonMember
    public xgroup : string= "";

   // public type : XDataType =XDataType.Float32;
   // public writable: boolean = false;

    @jsonMember
    flushiferror : boolean = false;
    @jsonMember
    flushfromblock : boolean = false;
};

export enum XAUTO_XAutoProgramType
{
    LUA                            = 0,
    //BLOCK                          = 10,
};

@jsonObject
export class XAUTO_XAutoProgram
{
  @jsonMember
  public type : XAUTO_XAutoProgramType;
  @jsonMember
  public xautoprogramid : string = "";
  @jsonMember
  public xautogroup : string = "";
  @jsonMember
  public xgroup : string = "";
  @jsonMember
  public name : string = "";
  @jsonMember
  public description : string = "";

  @jsonMember
  public lastmodifiedutc : Date = new Date();
  @jsonMember
  public laststartedutc : Date = new Date(1970,1,1);
  @jsonMember
  public lastfinishedutc : Date = new Date(1970,1,1);
  @jsonMember
  public lasterrorutc : Date = new Date(1970,1,1);
  @jsonMember
  public lasterror : string = "";

  @jsonMember
  public enabled : boolean = false;

  @jsonArrayMember(XAUTO_PLCVariableNumericalMapXAutoVariable)
  public numericalmapxautovariables : Array<XAUTO_PLCVariableNumericalMapXAutoVariable> = new Array<XAUTO_PLCVariableNumericalMapXAutoVariable>();
};

@jsonObject
export class XAUTO_XAutoProgramLua
{
  @jsonMember
  public xautoprogramid : string = "";
  @jsonMember
  public luascript_oninit : string = "";
  @jsonMember
  public luascript_onerror : string ="";
  @jsonMember
  public luascript_loop : string = "";
};

export enum XAUTO_XAutoProgramSchedulingType
{
    Interval = 0
};

@jsonObject
export class XAUTO_XAutoProgramScheduling
{
    public xautoprogramschedulingid : string = "";
    public name : string = "";
    public description: string = "";
    public type : XAUTO_XAutoProgramSchedulingType = XAUTO_XAutoProgramSchedulingType.Interval;
    public xautoprogramid : string = "";
    public xautogroup : string = "";
    public xgroup : string = "";
    public enabled : boolean = false;
}

@jsonObject
export class XAUTO_XAutoProgramSchedulingInterval
{
    public xautoprogramschedulingid : string = "";
    public hertz : number = 10;
    public laststartedutc : Date;
}

export enum XAUTO_DriverType
{
    EXTERNAL    = 0,
    REDIS       = 10,
    BACNET      = 90,
    MODBUS      = 100,
    MBUS        = 120,
    OPCUA       = 130,
    PROFIBUS    = 200,
    CANBUS      = 220,
    MQTT        = 300,
    LORAWAN_MQTT = 399,
    GPIO        = 400,
    COLLECTD    = 500,
    MIDI        = 600
};

export enum XAUTO_DriverMainErrors
{
  BADCONNECTION           = 1,
  BADAUTHENTICATION       = 2,
  BADDATA                 = 3,
  BADCONFIG               = 4
};

export class XAutoVariableTransformation
{
  // line equation: y = kx + m
  // when write, it is inverse so that y_flush = (y - m)/k
  public UseLineTransform : boolean = false;
  public LineTransform_K : number = 1;
  public LineTransform_M : number = 0;
  public LuaIn : string = "";
  public LuaOut : string = "";
};


export class XAUTO_XAutoVariableTransformation
{
  public uselinetransform : boolean = false;
  public linetransform_k : number = 1;
  public linetransform_m : number = 0;
  public luain : string = "";
  public luaout : string  = "";
};

export class XAUTO_XAutoVariable
{
  public xautodriverid : string = "";
  public xautovariableid: string = "";
  public xautogroup : string  = "";
  public xgroup : string  = "";
  public name : string  = "";
  public description : string = "";
  public defaultscriptname : string = "";
  public unit : string = "";
  public enabled: boolean = false;
  public systemlocked : boolean  = false;
  public type : XDataType = XDataType.Float32;
  public lastsampledutc : Date;
  public lastflushedutc : Date;
  public lasterrorutc : Date;
  public sampleintervalseconds : Number = 0;
  public flushintervalseconds : Number = 0;
  public writable : boolean = false;
  public transform: XAUTO_XAutoVariableTransformation = new XAUTO_XAutoVariableTransformation();
};

export class XAUTO_XAutoDriver
{
  public xautodriverid : string;
  public enabled: boolean =false;
  public systemlocked: boolean = false;
  public driver: XAUTO_DriverType;
  public lasterror : XAUTO_DriverMainErrors;

  public name : string = "";
  public description : string = "";
  public defaultxautogroup : string = "";
  public defaultxgroup : string = "";

  public lastactiveutc : Date;
  public lastconnectedutc : Date;
  public lasterrorutc : Date;
};

export enum XAUTO_ModbusType
{
  TCPIP_MASTER       = 0,
  TCPIP_SLAVE       = 1,
  // UDP_MASTER        = 2,
  // UDP_SLAVE         = 3,

  RTU_MASTER        = 10,
  RTU_SLAVE         = 11
};

export enum XAUTO_ModbusRTUMode
{
    RS485       = 0,
    RS232       = 1
};

export enum XAUTO_ModbusRTUParity
{
    None    = 0,
    Even    = 1,
    Odd     = 2
};


export class XAUTO_XAutoDriverModbus
{
  public xautodriverid : string;
  public type : XAUTO_ModbusType;
  public host : string;
  public port : number;
  public slave : number;

  public rtudevice : string;
  public rtubaud : number;
  public rtuparity : XAUTO_ModbusRTUParity;
  public rtudatabit : number;
  public rtustopbit : number;
  public rtumode : XAUTO_ModbusRTUMode;
}

export enum XAUTO_ModbusVariableType
{
  Bits        = 0,

  Int8        = 10,
  UInt8       = 11,

  Int16       = 20,
  UInt16      = 21,

  Int32       = 30,
  UInt32      = 31,

  Int64       = 40,
  UInt64      = 41,

  Float32     = 50,
  Float64     = 51,
};

export enum XAUTO_ModbusVariableByteOrder
{
  LEFT_TO_RIGHT   = 0,
  RIGHT_TO_LEFT   = 1,
};

export enum XAUTO_ModbusVariableRegisterOrder
{
  LEFT_TO_RIGHT   = 0,
  RIGHT_TO_LEFT   = 1,
};

export class XAUTO_XAutoVariableModbus
{
  public xautodriverid : string = "";
  public xautovariableid : string = "";
  public variabletype : XAUTO_ModbusVariableType = XAUTO_ModbusVariableType.Int16;
  public variableregisterorder : XAUTO_ModbusVariableRegisterOrder = XAUTO_ModbusVariableRegisterOrder.LEFT_TO_RIGHT;
  public variablebyteorder : XAUTO_ModbusVariableByteOrder = XAUTO_ModbusVariableByteOrder.LEFT_TO_RIGHT;
  public input : boolean = false;
  public address : number = 0;
  public size : number = 1;
  public bitsoffset : number = 0;
};

export class XAUTO_XAutoDriverGpio
{
    public xautodriverid : string = "";
    public chipsetname : string = "";
};

export enum XAUTO_XAutoVariableGpioMode
{
    AnalogueIn    = 0,
    DigitalIn     = 1,
    PulseCount    = 2,
    Frequency     = 3,

    AnalogueOut   = 20,
    DigitalOut    = 21
};

export enum XAutoVariableGpioAdressingMode
{
    FileDescriptor  = 0,
    ChipsLineOffset = 1
};


export class XAUTO_XAutoVariableGpio
{
    public xautodriverid : string;
    public xautovariableid : string;
    public mode : XAUTO_XAutoVariableGpioMode;
    public filename : string = "";
    public lineoffset : number;
    public voltagelow : number;
    public voltagehigh : number;
    public maxvoltage : number;
    public minvoltage : number;
    public normalized : boolean;
    public nrdecimals : number = 0;
    public commaseparator : string = ".";
};

export class XAUTO_XAutoDriverMidi
{
  public xautodriverid : string;
  public portname: string = "";
  public portnumber : number = -1;
}

export class XAUTO_XAutoVariableMidi
{
  public xautodriverid : string;
  public xautovariableid : string;
  public statusbyte : number;
  public controllerid: number;
  public continuousramp : boolean;
  public continuousrampupdatabytevalue: number;
  public continuousrampdowndatabytevalue : number;
  public continuousrampgain : number;
  public mirrorvaluetocontroller : boolean;
}


export enum XAUTO_MbusType
{
  TCPIP_MASTER=0,
  SERIAL_MASTER=10,
  SERIAL_MASTER_TO_GATEWAY=20
};

export class XAUTO_XAutoDriverMbus
{
  public xautodriverid : string;
  public tcphost: string = "";
  public tcpport : number = -1;
  public serialdevice: string = "";
  public serialbaud : number = -1;
  public type : XAUTO_MbusType;

}

export class XAUTO_XAutoVariableMbus
{
  public xautodriverid : string;
  public xautovariableid : string;

  public serialbaud : number = 0;
  public addresssecondaryid : number = 0;
  public manufacturer : string = "";
  public firmwareversion : number = 0;
  public medium : number = 0;
}

export class XAUTO_XAutoDriverCollectd
{
  public xautodriverid : string;
  public unixsocketpath : string;
};

export class XAUTO_XAutoVariableCollectd
{
  public xautodriverid : string;
  public xautovariableid : string;
  public identifier : string;
}

export class XAUTO_XAutoDriverOpcua
{
  public xautodriverid : string;
  public uri : string;
  public username : string;
  public password : string;
};
export enum XAUTO_OPCUANODEIDTYPE
{
    INT32      = 0,
    GUID       = 1,
    STRING     = 2,
    BASE64     = 3
};

export class XAUTO_XAutoVariableOpcua
{
  public xautodriverid : string;
  public xautovariableid : string;
  public nodeidtype : XAUTO_OPCUANODEIDTYPE;
  public nodeidstring : string;
  public nodeidguid : string;
  public nodeidint32 : number;
  public nodeidbase64 : string;
}

export enum XAUTO_MQTTDataFormat
{
    JSON            = 0
};

export enum XAUTO_MQTTPayloadEncoding
{
    RAW            = 0,
    BASE64         = 1,
    HEX            = 2
};
export enum XAUTO_MQTTVariableEncoding
{
    JSON            = 0,
    CAYENNELPP      = 1,
    CUSTOM_LUA      = 2
};


export class XAUTO_XAutoDriverMqtt
{
    public xautodriverid : string = "";
    public uri: string = "";
    public clientid : string = "";
    public username : string;
    public password : string;
    public validateservercertificate : boolean = true;

    // example: application/1/device/0018b20000020feb/event/up
    // topic in: application/1/device/+/event/up
    public subscribetopicin : string;
    // example: application/1/device/{IDENTIFIER}/event/up
    public extractidentifierfromtopicin : string;
    // https://stackoverflow.com/questions/11627440/regex-c-extract-substring
    public topicout : string; // Keywords: {IDENTIFIER}

    public dataattributepointer : string; // Example: /data
    // https://rapidjson.org/md_doc_pointer.html#JsonPointer

    public format : XAUTO_MQTTDataFormat = XAUTO_MQTTDataFormat.JSON;
    public payloaddataencoding  : XAUTO_MQTTPayloadEncoding = XAUTO_MQTTPayloadEncoding.BASE64;
};

export class XAUTO_XAutoVariableMqtt
{
    public xautodriverid: string = "";
    public xautovariableid : string = "";

    public encoding : XAUTO_MQTTVariableEncoding = XAUTO_MQTTVariableEncoding.JSON;
    public identifier : string = "";

    // when mqttdataformat is json, key is JSON pointers
    public strfilters = {};
    public numfilters = {};

    public jsondataattributepointer : string = "";
};


export class XAUTO_XAutoDriverExternal
{
    public xautodriverid:string = "";
};

export class XAUTO_XAutoVariableExternal
{
  public xautodriverid : string ="";
  public xautovariableid : string ="";
};

export class XAUTO_XAutoDriverRedis
{
  public xautodriverid : string = "";
};

export class XAUTO_XAutoVariableRedis
{
  public xautodriverid : string ="";
  public xautovariableid : string = ""
};

export enum XAUTO_IntegrationServerType
{
  XAUTO	= 0,
  MQTT 	= 10,
  BACNET	= 20,
  MODBUS	= 30,
  OPCUA	= 40
};

export enum XAUTO_IntegrationServerDirection
{
  IN 		= 0,
  OUT 	= 1,
  BI 		= 2
};

export class XAUTO_XAutoGroupLinkIntegrationServer
{
  public xautogroup: string = "";
  public xgroup: string = "";
};

export class XAUTO_XAutoIntegrationServer
{
  public xautointegrationserverid: string = "";
  public name:string = "";
  public description: string = "";
  public enable: boolean = false;
  public servertype: XAUTO_IntegrationServerType = XAUTO_IntegrationServerType.MQTT;
  public direction: XAUTO_IntegrationServerDirection = XAUTO_IntegrationServerDirection.OUT;
  public xautogroupfilter: Array <XAUTO_XAutoGroupLinkIntegrationServer>=new Array<XAUTO_XAutoGroupLinkIntegrationServer>();
  public requireexplicitmapping : boolean = false;
};

export class XAUTO_IntegrationServerMqtt
{
  public xautointegrationserverid : string = "";
  public uri : string = "";
  public clientid:string = "";
  public username:string = "";
  public password:string = "";
  public validateservercertificate: boolean = true;
  public luascriptdatain : string = "";
  public luascriptdataout : string = "";
};

export class XAUTO_IntegrationServerMqttVariableMapping
{
  public integrationservermqttvariablemappingid :string = "";
  public xautointegrationserverid : string = "";
  public xautogroupfilter : Array<XAUTO_XAutoGroupLinkIntegrationServer> = new Array<XAUTO_XAutoGroupLinkIntegrationServer>();
  public xautovariableid : string = "";
  public externalid = "";
  public topic = "";
  public key = "";  
  public luascriptout = "";
  public luascriptin = "";  
}

// Highlevel client to be used by consumers
@Injectable({
  providedIn: 'root'
})
export class XProjectorClient implements OnDestroy
{
  public authenticatedSubject: Subject<void> = new Subject();
  public disconnectedSubject: Subject<void> = new Subject();
  public messageSubject: Subject<Object> = new Subject();

  public projectionDataChangedSubject: Subject<string> = new Subject();
  public projectionDataResetedSubject: Subject<string> = new Subject();
  public projectionChangedSubject: Subject<string> = new Subject();
  public datasourceChangedSubject: Subject<string> = new Subject();

  private client : XProjectorLowlevelClient;

  private ngUnsubscribe = new Subject<void>();
  private keepAliveTimerSource;
  private keepAliveActive : boolean = false;

  public constructor(private readonly cache: CacheService,
        @Inject(LOGGERSERVICE) private logger: XprojLoggerService,
        @Inject(XPROJECTORCLIENTSETTINGS) public settings : XProjectClientSettings)
  {
    if (settings.cacheEnabled == undefined) {
      settings.cacheEnabled = cacheEnabled;
    }
    if (settings.cacheTTLSeconds == undefined) {
      settings.cacheTTLSeconds = cacheTTLSeconds;
    }
    if (settings.keepAlive == undefined) {
      settings.keepAlive = true;
    }
    if (settings.keepAliveInterval == undefined) {
      settings.keepAliveInterval = 5000;
    }

    this.client  = new XProjectorLowlevelClient(settings, logger);
    this.client.OnMessage = this.HandleOnMessage.bind(this);
    this.client.OnDisconnected = this.HandleOnDisconnect.bind(this);
    this.client.OnAuthenticated = this.HandleOnAuthenticated.bind(this);
  }

  ngOnDestroy(): void {
    this.ngUnsubscribe.next();
    this.ngUnsubscribe.complete();
  }

  public SetHostAndToken(host : string, token : string) {
    this.client?.SetHost(host);
    if (this.client?.isConnected) {
      this.client.Disconnect();
    }
    this.Connect(token);
  }

  async HandleOnAuthenticated()
  {
    this.authenticatedSubject.next();
    this.stopKeepAlive();
  }

  async HandleOnDisconnect()
  {

    for(let [key, value] of this.ActiveResponsesResolve)
    {
      let promiseresolver =  this.ActiveResponsesResolve.get(key);
      promiseresolver.reject('Disconnected');
    }

    this.ActiveResponsesResolve.clear();

    this.disconnectedSubject.next();

    this.startKeepAlive();
  }

  async Connect(token : string = '')
  {
    this.ActiveResponsesResolve.clear();

    if ((!token || token.length == 0) && this.settings.defaultToken?.length > 0) {
      this.client.Connect(this.settings.defaultToken);
    }
    else {
      this.client.Connect(token);
    }

    this.ActiveResponsesResolve.clear();
  }

  SetActiveRemoteNodeID(remotenodeid: string)
  {
    this.client.SetActiveRemoteNodeID(remotenodeid);
  }

  IsConnected() : boolean {
    return this.client.isConnected;
  }

  async Disconnect()
  {
    this.client.Disconnect();
  }

  startKeepAlive() {
    if (this.settings.keepAlive && !this.keepAliveActive) {
      this.keepAliveActive = true;
      this.keepAliveTimerSource = timer(this.settings.keepAliveInterval, this.settings.keepAliveInterval);

      this.keepAliveTimerSource.pipe(takeUntil(this.ngUnsubscribe)).subscribe(async (event) => {
        if (!this.client.isConnected || !this.client.isAuthenticated) {
          this.Connect();
        }
      });
    }
  }

  stopKeepAlive() {
    this.ngUnsubscribe.next();
    this.keepAliveActive = false;
  }


  private ActiveResponsesResolve : Map<number, PromiseResolve> = new Map<number, PromiseResolve>();

  async HandleEvent(EventType: string, Event: Object)
  {
    if(EventType == "projectiondatachanged")
    {
      let projectionId = Event["projectionid"];
      this.projectionDataChangedSubject.next(projectionId);
      this.logger.debug("I got a projection data changed on projection " + projectionId);
    }
    if(EventType == "projectiondataresetted")
    {
      let projectionId = Event["projectionid"];
      this.projectionDataResetedSubject.next(projectionId);
      this.logger.debug("I got a projection data resetted on projection " + projectionId);
    }
    if(EventType == "projectionchanged")
    {
      let projectionId = Event["projectionid"];
      this.projectionChangedSubject.next(projectionId);
      this.logger.debug("I got a projection config changed " + projectionId);
    }
    if(EventType == "datasourcechanged")
    {
      let datasourceId = Event["datasourceid"];
      this.datasourceChangedSubject.next(datasourceId);
      this.logger.debug("I got a datasource changed on datasource " + datasourceId);
    }

  }

  async HandleOnMessage(Data:Object)
  {
    // Todo: Check if its event type first
    let EventType = Data["eventtype"];
    if(EventType && EventType != "")
    {
      await this.HandleEvent(EventType, Data);

      // Beware this may break stuff?
      this.messageSubject.next(Data);

      return;
    }

    let MessageBody = Data["XProjector::MessagePack::Response"];
    let ContentBody = Data["Data"];

    if(!MessageBody)
      MessageBody = Data;

	  let msgid=MessageBody["messageid"];

    if(this.ActiveResponsesResolve.has(msgid))
    {
      let promiseresolver = this.ActiveResponsesResolve.get(msgid);
      if(promiseresolver)
      {
		    let succes : boolean = MessageBody["success"];
		  //this.logger.info('success', succes);

		  if (succes) {
			  promiseresolver.resolve( ContentBody );
		  }
		  else {
        //this.logger.info(Data);
			  let errormessage = MessageBody["errormessage"];
			  promiseresolver.reject(errormessage?.length > 0 ? errormessage :  'unknown error');
		  }
	  }
	  this.ActiveResponsesResolve.delete(msgid);
      return;
    }

    this.messageSubject.next(Data);
  }


  async RequestDevDatasourceProbeqQuery( query: DataSourceProbeQuery ) : Promise<DataSourceProbeQueryResult>
  {
    let msg= {
      request: "dev-datasource-probequery",
      data: query
    };

    let rval = await this.Request(msg);
    return rval[0] as BaseQueryResult;
  }

  async RequestQueryGroups(query:GroupsQuery) : Promise<GroupsQueryResult>
  {
    if (!query.targetprojectionid || query.targetprojectionid.length == 0) {
      return Promise.reject('No projectionid provided.');
    }

    let msg= {
      request: "query-groups",
      data: query
    };

    let rval = (await this.Request(msg))[0] as GroupsQueryResult;
    //this.logger.info(rval)
    return rval;
  }

  async RequestQueryBaseQuery( query: BaseQuery, forcereload : boolean = false, sender : string = '', senderId : string = '') : Promise<BaseQueryResult>
  {
    if (!query.targetprojectionid || query.targetprojectionid.length == 0) {
      return Promise.reject('No projectionid provided.');
    }

    let msg= {
      request: "query-basequery",
      data: query
    };
    this.logger.debug('RequestQueryBaseQuery', 'sender: ' + sender, 'senderid: ' + senderId, query);

    let rval = (await this.RequestWithCache(msg, forcereload))[0] as BaseQueryResult;
    //this.logger.info(rval)
    this.logger.debug('RequestQueryBaseQuery response', 'sender: ' + sender, 'senderid: ' + senderId, rval);
    return rval;
  }

  async RequestQueryDownSampleQuery( query: DownSampleQuery, forcereload : boolean = false, sender : string = '', senderId : string = '' ) : Promise<BaseQueryResult>
  {
    if (!query.query?.targetprojectionid || query.query.targetprojectionid.length == 0) {
      return Promise.reject('No projectionid provided.');
    }

    let msg= {
      request: "query-downsamplequery",
      data: query
    };
    this.logger.debug('RequestQueryDownSampleQuery', 'sender: ' + sender, 'senderid: ' + senderId, query);
    let rval = (await this.RequestWithCache(msg, forcereload))[0] as BaseQueryResult;
    this.logger.debug('RequestQueryDownSampleQuery response', 'sender: ' + sender, 'senderid: ' + senderId, rval);
    return rval;
  }

  async RequestQueryDFTQuery( query: DFTQuery, forcereload : boolean = false, sender : string = '', senderId : string = '' ) : Promise<BaseQueryResult>
  {
    if (!query.query.targetprojectionid || query.query.targetprojectionid.length == 0) {
      return Promise.reject('No projectionid provided.');
    }

    let msg= {
      request: "query-dftquery",
      data: query
    };

    this.logger.debug('RequestQueryDFTQuery', 'sender: ' + sender, 'senderid: ' + senderId, query);
    let rval =  (await this.RequestWithCache(msg, forcereload))[0] as BaseQueryResult;
    this.logger.debug('RequestQueryDFTQuery response', 'sender: ' + sender, 'senderid: ' + senderId, rval);
    //this.logger.info(rval);
    return rval;
  }


  async RequestQuerySPCQuery( query: SPCQuery, forcereload : boolean = false, sender : string = '', senderId : string = '' ) : Promise<BaseQueryResult>
  {
    if (!query.query.targetprojectionid || query.query.targetprojectionid.length == 0) {
      return Promise.reject('No projectionid provided.');
    }

    let msg= {
      request: "query-spcquery",
      data: query
    };

    this.logger.debug('RequestQuerySPCQuery', 'sender: ' + sender, 'senderid: ' + senderId, query);
    let rval =  (await this.RequestWithCache(msg, forcereload))[0] as BaseQueryResult;
    this.logger.debug('RequestQuerySPCQuery response', 'sender: ' + sender, 'senderid: ' + senderId, rval);
    //this.logger.info(rval);
    return rval;
  }

  async RequestQueryMultiseriesQuery( query: MultiseriesQuery, forcereload : boolean = false, sender : string = '', senderId : string = '' ) : Promise<BaseQueryResult>
  {
    query.queries.forEach(query => {
      if (!query.targetprojectionid || query.targetprojectionid.length == 0) {
        return Promise.reject('No projectionid provided.');
      }
    });

    let msg= {
      request: "query-multiseries",
      data: query
    };
    this.logger.debug('RequestQueryMultiseriesQuery', 'sender: ' + sender, 'senderid: ' + senderId, query);
    let rval = (await this.RequestWithCache(msg, forcereload))[0] as BaseQueryResult;
    this.logger.debug('RequestQueryMultiseriesQuery response', 'sender: ' + sender, 'senderid: ' + senderId, rval);

    return rval;
  }

  async RequestQueryTextsearch( query: TextSearchQuery ) : Promise<TextSearchQueryResult>
  {
    if (!query.targetprojectionid || query.targetprojectionid.length == 0) {
      return Promise.reject('No projectionid provided.');
    }

    let msg= {
      request: "query-textsearch",
      data: query
    };

    return (await this.Request(msg))[0] as TextSearchQueryResult;
  }



  async RequestSaveProjection( projection: Projection, overridelastprojected:boolean ) : Promise<Projection>
  {
    let msg= {
      request: "save-config-projection",
      overridelastprojectedatutc : overridelastprojected,
      data: projection
    };

    return (await this.Request(msg))[0] as Projection;
  }

  async RequestSaveProjectionColumnDescription( column: ProjectionColumnDescription ) : Promise<ProjectionColumnDescription>
  {
    let msg= {
      request: "save-config-projection-column",
      data: column
    };

    return (await this.Request(msg))[0] as ProjectionColumnDescription;
  }

  async RequestListConfigSchedulingQueues() : Promise<Array<String>>
  {
    let msg= {
      request: "list-config-schedulingqueues",
      path: "/",
    };

    let result = (await this.Request(msg)) as Array<String>;

    return result;
  }

  async RequestListConfigDataPaths() : Promise<Array<String>>
  {
    let msg= {
      request: "list-config-datapaths",
      path: "/",
    };

    let result = (await this.Request(msg)) as Array<String>;

    return result;
  }

  async RequestListQueryableProjections(from: number, maxitems: number) : Promise<Array<Projection>>
  {
    let msg= {
      request: "list-queryable-projections",
      maxitems: maxitems,
      seekoffset: from,
      path: "/",
    };

    let result = (await this.Request(msg)) as Array<Projection>;

    return result.sort((a, b) => a.name.toLocaleLowerCase() > b.name.toLocaleLowerCase() ? 1 : -1);
  }

  async RequestSaveDatasource( datasource: DataSource ) : Promise<DataSource>
  {
    let msg= {
      request: "save-config-datasource",
      data: datasource
    };

    return (await this.Request(msg))[0] as DataSource;
  }
  async RequestSaveDatasourceSQLServer( datasource: DataSourceSQLServer ) : Promise<DataSourceSQLServer>
  {
    let msg= {
      request: "save-config-datasource-sqlserver",
      data: datasource
    };

    return (await this.Request(msg))[0] as DataSourceSQLServer;
  }
  async RequestSaveDatasourcePostgreSQL( datasource: DataSourcePostgreSQL ) : Promise<DataSourcePostgreSQL>
  {
    let msg= {
      request: "save-config-datasource-postgresql",
      data: datasource
    };

    return (await this.Request(msg))[0] as DataSourcePostgreSQL;
  }
  async RequestSaveDatasourceMySQL( datasource: DataSourceMySQL ) : Promise<DataSourceMySQL>
  {
    let msg= {
      request: "save-config-datasource-mysql",
      data: datasource
    };

    return (await this.Request(msg))[0] as DataSourceMySQL;
  }
  async RequestSaveDatasourceSQLite( datasource: DataSourceSQLite ) : Promise<DataSourceSQLite>
  {
    let msg= {
      request: "save-config-datasource-sqlite",
      data: datasource
    };

    return (await this.Request(msg))[0] as DataSourceSQLite;
  }
  async RequestSaveDatasourceMongoDB( datasource: DataSourceMongoDB ) : Promise<DataSourceMongoDB>
  {
    let msg= {
      request: "save-config-datasource-mongodb",
      data: datasource
    };

    return (await this.Request(msg))[0] as DataSourceMongoDB;
  }

  async RequestSaveProjectionExecution( exec: ProjectionExecution ) : Promise<ProjectionExecution>
  {
    let msg= {
      request: "save-config-projection-execution",
      data: exec
    };

    return (await this.Request(msg))[0] as ProjectionExecution;
  }

  async RequestSaveProjectionExecutionDataSourceQuery( execds: ProjectionExecutionDataSourceQuery ) : Promise<ProjectionExecutionDataSourceQuery>
  {
    let msg= {
      request: "save-config-projection-execution-datasourcequery",
      data: execds
    };

    return (await this.Request(msg))[0] as ProjectionExecutionDataSourceQuery;
  }

  async RequestSaveProjectionExecutionLuaMainfunction( execlmf: ProjectionExecutionLuaMainFunction ) : Promise<ProjectionExecutionLuaMainFunction>
  {
    let msg= {
      request: "save-config-projection-execution-luamainfunction",
      data: execlmf
    };

    return (await this.Request(msg))[0] as ProjectionExecutionLuaMainFunction;
  }

  async RequestGetConfigDatasource(Datasourceid : string) : Promise<DataSource>
  {
    let msg= {
      request: "get-config-datasource",
      datasourceid: Datasourceid,
      path: "/",
    };

    return (await this.Request(msg))[0] as DataSource;
  }

  async RequestGetConfigDatasourceSQLServer(Datasourceid : string) : Promise<DataSourceSQLServer>
  {
    let msg= {
      request: "get-config-datasource-sqlserver",
      datasourceid: Datasourceid,
      path: "/",
    };

    return (await this.Request(msg))[0] as DataSourceSQLServer;
  }

  async RequestGetConfigDatasourcePostgreSQL(Datasourceid : string) : Promise<DataSourcePostgreSQL>
  {
    let msg= {
      request: "get-config-datasource-postgresql",
      datasourceid: Datasourceid,
      path: "/",
    };

    return (await this.Request(msg))[0] as DataSourcePostgreSQL;
  }

  async RequestGetConfigDatasourceMySQL(Datasourceid : string) : Promise<DataSourceMySQL>
  {
    let msg= {
      request: "get-config-datasource-mysql",
      datasourceid: Datasourceid,
      path: "/",
    };

    return (await this.Request(msg))[0] as DataSourceMySQL;
  }

  async RequestGetConfigDatasourceSQLite(Datasourceid : string) : Promise<DataSourceSQLite>
  {
    let msg= {
      request: "get-config-datasource-sqlite",
      datasourceid: Datasourceid,
      path: "/",
    };

    return (await this.Request(msg))[0] as DataSourceSQLite;
  }

  async RequestGetConfigDatasourceMongoDB(Datasourceid : string) : Promise<DataSourceMongoDB>
  {
    let msg= {
      request: "get-config-datasource-mongodb",
      datasourceid: Datasourceid,
      path: "/",
    };

    return (await this.Request(msg))[0] as DataSourceMongoDB;
  }

  async RequestListConfigDatasources(from: number, maxitems: number) : Promise<Array<DataSource>>
  {
    let msg= {
      request: "list-config-datasources",
      maxitems: maxitems,
      seekoffset: from,
      path: "/",
    };

    let result = (await this.Request(msg)) as Array<DataSource>;
    return result.sort((a, b) => a.name.toLocaleLowerCase() > b.name.toLocaleLowerCase() ? 1 : -1);
  }

  async RequestListConfigProjections(from: number, maxitems: number) : Promise<Array<Projection>>
  {
    let msg= {
      request: "list-config-projections",
      maxitems: maxitems,
      seekoffset: from,
      path: "/"
    };

    let result = (await this.Request(msg)) as Array<Projection>;
    return result.sort((a, b) => a.name.toLocaleLowerCase() > b.name.toLocaleLowerCase() ? 1 : -1);
  }

  async RequestListQueryableProjectionGroups(projectionId:string, from: number, maxitems: number) : Promise<Array<Array<string>>>
  {
    let msg= {
      request: "list-queryable-projection-groups",
      projectionid: projectionId,
      maxitems: maxitems,
      seekoffset: from,
      path: "/",
    };

    return (await this.Request(msg)) as Array<Array<string>>;
  }

  async RequestListQueryableProjectionColumns(projectionId:string, group: string, from: number, maxitems: number) : Promise<Array<ProjectionColumnDescription>>
  {
    let msg= {
      request: "list-queryable-projection-columns",
      projectionid: projectionId,
      maxitems: maxitems,
      seekoffset: from,
      group: group,
      path: "/",
    };

    let result = (await this.Request(msg)) as Array<ProjectionColumnDescription>;
    return result.sort((a, b) => a.columnname.toLocaleLowerCase() > b.columnname.toLocaleLowerCase() ? 1 : -1);
  }

  async RequestListConfigProjectionExecutions(projectionId:string, from: number, maxitems: number) : Promise<Array<ProjectionExecution>>
  {
    let msg= {
      request: "list-config-projection-executions",
      projectionid: projectionId,
      maxitems: maxitems,
      seekoffset: from,
      path: "/",
    };

    return (await this.Request(msg)) as Array<ProjectionExecution>;
  }

  async RequestListConfigProjectionExecutionDataSourceQueries(projectionExecutionId:string, from: number, maxitems: number) : Promise<Array<ProjectionExecutionDataSourceQuery>>
  {
    let msg= {
      request: "list-config-projection-executions-datasourcequery",
      projectionexecutionid: projectionExecutionId,
      maxitems: maxitems,
      seekoffset: from,
      path: "/",
    };

    return (await this.Request(msg)) as Array<ProjectionExecutionDataSourceQuery>;
  }

  async RequestListConfigProjectionExecutionLuaMainFunctions(projectionExecutionId:string, from: number, maxitems: number) : Promise<Array<ProjectionExecutionLuaMainFunction>>
  {
    let msg= {
      request: "list-config-projection-executions-luamainfunction",
      projectionexecutionid: projectionExecutionId,
      maxitems: maxitems,
      seekoffset: from,
      path: "/",
    };

    return (await this.Request(msg)) as Array<ProjectionExecutionLuaMainFunction>;
  }


  async RequestGetConfigProjection(projectionId:string) : Promise<Projection>
  {
    let msg= {
      request: "get-config-projection",
      projectionid: projectionId,
      path: "/",
    };

    return (await this.Request(msg))[0] as Projection;
  }

  async RequestListConfigProjectionColumns(projectionId:string, from: number, maxitems: number) : Promise<Array<ProjectionColumnDescription>>
  {
    let msg= {
      request: "list-config-projection-columns",
      projectionid: projectionId,
      seekoffset: from,
      maxitems: maxitems,
      path: "/",
    };

    let result = (await this.Request(msg)) as Array<ProjectionColumnDescription>;
    return result.sort((a, b) => a.columnname.toLocaleLowerCase() > b.columnname.toLocaleLowerCase() ? 1 : -1);
  }

  async RequestSubscribeProjectionData(projectionId: string, group : string[] = []) : Promise<boolean>
  {
    let msg= {
      request: "subscribe-queryable-data",
      projectionid: projectionId,
      group: group.join(','),
      path: "/",
    };

    await this.Request(msg);
    return true;
  }

  async RequestSubscribeConfigProjection(projectionId: string) : Promise<boolean>
  {
    let msg= {
      request: "subscribe-config-projection",
      projectionid: projectionId,
      path: "/",
    };

    await this.Request(msg);
    return true;
  }
  async RequestSubscribeConfigDatasource(datasourceId: string) : Promise<boolean>
  {
    let msg= {
      request: "subscribe-config-datasource",
      datasourceid: datasourceId,
      path: "/",
    };

    await this.Request(msg);
    return true;
  }

  async RequestUnsubscribeProjectionData(projectionId: string, group : string[] = []) : Promise<boolean>
  {
    let msg= {
      request: "unsubscribe-queryable-data",
      projectionid: projectionId,
      group: group.join(','),
      path: "/",
    };

    await this.Request(msg);
    return true;
  }

  async RequestUnsubscribeConfigProjection(projectionId: string) : Promise<boolean>
  {
    let msg= {
      request: "unsubscribe-config-projection",
      projectionid: projectionId,
      path: "/",
    };

    await this.Request(msg);
    return true;
  }
  async RequestUnsubscribeConfigDatasource(datasourceId: string) : Promise<boolean>
  {
    let msg= {
      request: "unsubscribe-config-datasource",
      datasourceid: datasourceId,
      path: "/",
    };

    await this.Request(msg);
    return true;
  }
  async RequestRemoveConfigProjectionColumn(columnId: string) : Promise<boolean>
  {
    let msg= {
      request: "remove-config-projection-column",
      projectioncolumndescriptionid: columnId,
      path: "/",
    };

    await this.Request(msg);
    return true;
  }

  async RequestRunConfigProjection(projectionId: string) : Promise<ProjectionExecutionLog>
  {
    let msg= {
      request: "run-config-projection",
      projectionid: projectionId,
      path: "/",
    };

    return (await this.Request(msg))[0] as ProjectionExecutionLog;
  }

  async RequestResetConfigProjectionData(projectionId: string) : Promise<boolean>
  {
    let msg= {
      request: "reset-config-projection-data",
      projectionid: projectionId,
      path: "/",
    };

    await this.Request(msg);
    return true;
  }

  async RequestResetConfigProjectionGroupData(resetProjectionData : ResetProjectionDataQuery) : Promise<boolean>
  {
    let msg= {
      request: "reset-config-projection-group-data",
      data: resetProjectionData,

      path: "/",
    };

    await this.Request(msg);
    return true;
  }

  async RequestRemoveConfigProjection(projectionId: string) : Promise<boolean>
  {
    let msg= {
      request: "remove-config-projection",
      projectionid: projectionId,
      path: "/",
    };

    await this.Request(msg);
    return true;
  }

  async RequstRemoveConfigProjectionExecution(projectionexecutionid: string) : Promise<boolean>
  {
    let msg= {
      request: "remove-config-projection-execution",
      projectionexecutionid: projectionexecutionid,
      path: "/",
    };

    await this.Request(msg);
    return true;
  }
  async RequstRemoveConfigProjectionExecutionDataSourceQuery(projectionexecutiondatasourcequeryid: string) : Promise<boolean>
  {
    let msg= {
      request: "remove-config-projection-execution-datasourcequery",
      projectionexecutiondatasourcequeryid: projectionexecutiondatasourcequeryid,
      path: "/",
    };

    await this.Request(msg);
    return true;
  }
  async RequstRemoveConfigProjectionExecutionLuaMainFunction(projectionexecutionluamainfunctionid: string) : Promise<boolean>
  {
    let msg= {
      request: "remove-config-projection-execution-luamainfunction",
      projectionexecutionluamainfunctionid: projectionexecutionluamainfunctionid,
      path: "/",
    };

    await this.Request(msg);
    return true;
  }

  async RequestSetData(query : SetDataQuery, sender : string = '', senderId : string = '') : Promise<boolean>
  {
    let msg= {
      request: "query-setdata",
      data: query,
      path: "/",
    };

    this.logger.debug('RequestSetData', 'sender: ' + sender, 'senderid: ' + senderId, query);

    let rval = await this.Request(msg);

    this.logger.debug('RequestSetData response', 'sender: ' + sender, 'senderid: ' + senderId, rval);

    return true;
  }

  async RequestSetSplittedData(query : SetSplittedDataQuery) : Promise<boolean>
  {
    let msg= {
      request: "query-setsplitteddata",
      data: query,
      path: "/",
    };

    await this.Request(msg);
    return true;
  }

  async RequestGetVersion() : Promise<VersionResponse>
  {
    let msg= {
      request: "get-version",
      path: "/",
    };

    let result = (await this.Request(msg)) as Array<VersionResponse>;
    return result[0];
  }

  async RequestGetBlob(blobid : string) : Promise<Blob>
  {
    let msg= {
      request: "blobs-get-blob",
      blobid: blobid,
      path: "/",
    };

    let result = (await this.Request(msg)) as Array<Blob>;
    return result[0];
  }

  async RequestGetBlobIDs(from : number, maxitems : number) : Promise<Array<string>>
  {
    let msg= {
      request: "blobs-list-blobidentifiers",
      path: "/",
    };

    let result = (await this.Request(msg) as unknown) as Array<string>;
    return result;
  }


  async RequestSaveBlob(blob : SetBlob) : Promise<boolean>
  {
    let msg= {
      request: "blobs-save-blob",
      data: blob,
      path: "/",
    };

    await this.Request(msg);
    return true;
  }

  async RequestRemoveBlob(blobid : string) : Promise<boolean>
  {
    let msg= {
      request: "blobs-remove-blob",
      blobid: blobid,
      path: "/",
    };

    await this.Request(msg);
    return true;
  }

  async LuaDebuggerAttach(SID: string): Promise<boolean>
  {
    let command = new LuaDebuggerCommandAttach();
    command.sid = SID;
    let msg= {
      request: "scripts-lua-debugger-attach",
      data: command,
      path: "/",
    };

    await this.Request(msg);
    return true;
  }

  async LuaDebuggerRun(SID: string): Promise<boolean>
  {
    let command = new LuaDebuggerCommandRun();
    command.sid = SID;
    let msg= {
      request: "scripts-lua-debugger-run",
      data: command,
      path: "/",
    };

    await this.Request(msg);
    return true;
  }

  async LuaDebuggerRelease(SID: string): Promise<boolean>
  {
    let command = new LuaDebuggerCommandRelease();
    command.sid = SID;
    let msg= {
      request: "scripts-lua-debugger-release",
      data: command,
      path: "/",
    };

    await this.Request(msg);
    return true;
  }

  async LuaDebuggerReleaseAll(): Promise<boolean>
  {
    let msg= {
      request: "scripts-lua-debugger-release-all",
      path: "/",
    };

    await this.Request(msg);
    return true;
  }

  async LuaDebuggerStepInto(SID: string): Promise<boolean>
  {
    let command = new LuaDebuggerCommandStepInto();
    command.sid = SID;
    let msg= {
      request: "scripts-lua-debugger-step-into",
      data: command,
      path: "/",
    };

    await this.Request(msg);
    return true;
  }

  async LuaDebuggerStepOver(SID: string): Promise<boolean>
  {
    let command = new LuaDebuggerCommandStepOver();
    command.sid = SID;
    let msg= {
      request: "scripts-lua-debugger-step-over",
      data: command,
      path: "/",
    };

    await this.Request(msg);
    return true;
  }

  async LuaDebuggerSetBreakpoints(SID: string, lines: number[]): Promise<boolean>
  {
    let command = new LuaDebuggerCommandSetBreakpoints();
    command.lines = lines;
    command.sid = SID;
    let msg= {
      request: "scripts-lua-debugger-set-breakpoints",
      data: command,
      path: "/",
    };

    await this.Request(msg);
    return true;
  }

  async LuaDebuggerGetValues(SID: string, Instances: string[]): Promise<LuaDebuggerCommandGetValuesResponse>
  {
    let command = new LuaDebuggerCommandGetValues();

    command.sid = SID;
    command.instances = Instances;
    let msg= {
      request: "scripts-lua-debugger-get-values",
      data: command,
      path: "/",
    };

    return (await this.Request(msg))[0] as LuaDebuggerCommandGetValuesResponse;
  }

  async LuaDebuggerGetSource(SID: string): Promise<LuaDebuggerCommandGetSourceResponse>
  {
    let command = new LuaDebuggerCommandGetSource();
    command.sid = SID;
    let msg= {
      request: "scripts-lua-debugger-get-source",
      data: command,
      path: "/",
    };

    return (await this.Request(msg))[0] as LuaDebuggerCommandGetSourceResponse;
  }

  async LuaDebuggerGetStatus(SID: string): Promise<LuaDebuggerCommandGetStatusResponse>
  {
    let command = new LuaDebuggerCommandGetStatusResponse();

    command.sid = SID;
    let msg= {
      request: "scripts-lua-debugger-get-status",
      data: command,
      path: "/",
    };

    return (await this.Request(msg))[0] as LuaDebuggerCommandGetStatusResponse;
  }


  async LuaDebuggerListPotentialIdentifiers(seekoffset: number, maxitems: number): Promise<Array<string>>
  {
    let msg= {
      request: "scripts-lua-debugger-list-identifiers",
      seekoffset: seekoffset,
      maxitems: maxitems,
      path: "/",
    };

    return ((await this.Request(msg)) as unknown) as Array<string>;
  }

  async delay(ms: number) : Promise<any>
  {
      return new Promise( resolve => setTimeout(resolve, ms) );
  }

  async SendMessage(data : Object) {
    this.client.SendMessage(data);
  }

  GetNrOfPoints(queryResult : BaseQueryResult) : number {
    let result = queryResult.nrpoints;
    if (queryResult.datanumbers.length > 0) {
      result = queryResult.datanumbers[0].length;
    }
    else if (queryResult.datastrings.length > 0) {
      result = queryResult.datastrings[0].length;
    }
    else if (queryResult.datatimestamps.length > 0) {
      result = queryResult.datatimestamps[0].length;
    }

    return result;
  }

  private async RequestWithCache(msg: any, forcereload : boolean = false) : Promise<Array<object>>
  {
    if (this.settings.cacheEnabled) {
      let key = JSON.stringify(msg);

      if (!forcereload && this.cache.has(key)) {
        this.logger.info('request: returned cached value');
        return Promise.resolve(this.cache.get(key));
      }
      else {
        let result = await this.Request(msg);
        this.cache.set(key, result);
        let that = this;
        setTimeout(() => {
          try {
            that.cache.get(key);
          }
          catch {}
          }, (this.settings.cacheTTLSeconds + 10)*1000);
        return Promise.resolve(result);
      }
    }
    else {
      return this.Request(msg);
    }
  }

  private async Request(msg: any) : Promise<Array<object>>
  {
    let nrRetries = 500;
    for(let i = 0; i < nrRetries; i++)
    {
      if(this.client.isAuthenticated)
        break;

      if(i == 0 )
      {
        this.logger.info("request: waiting for connection");
      }
      //this.logger.info("request: waiting for connection");
      await this.delay(50);
    }

    if(!this.client.isAuthenticated)
    {
      this.logger.info("request: still not authenticated");
      throw new Error("Not connected");
    }



    let Resolves = this.ActiveResponsesResolve;
    let that = this;
    let RVal = new Promise< Array<Object>> (
      function (resolve, reject)
      {
        let msgid = that.client.GetNextMessageID();
        msg["messageid"] = msgid;
        Resolves.set(msgid, {resolve: resolve, reject: reject});
        that.client.SendMessageIgnoreID(msg);
      });

      return RVal;
  }

    // XAUTO
    async XAUTO_SaveProcessGraph( graph: XAUTO_XAutoProcessGraph ) : Promise<boolean>
    {
      let msg= {
        request: "xauto-save-processgraph",
        data: graph
      };

      await this.Request(msg);
      return true;
    }
    async XAUTO_GetProcessGraphs( seekoffset : number, maxitems : number) : Promise<Array<XAUTO_XAutoProcessGraph>>
    {
      let msg= {
        request: "xauto-list-processgraphs",
        seekoffset: seekoffset,
        maxitems: maxitems
      };

      let result = (await this.Request(msg)) as Array<XAUTO_XAutoProcessGraph>;
      return result;
    }

    async XAUTO_GetProcessGraph( xautoprocessgraphid: string ) : Promise<XAUTO_XAutoProcessGraph>
    {
      let msg= {
        request: "xauto-get-processgraph",
        xautoprocessgraphid: xautoprocessgraphid
      };

      let result = ((await this.Request(msg)) as Array<XAUTO_XAutoProcessGraph>)[0];
      return result;
    }

    async XAUTO_RemoveProcessGraph( xautoprocessgraphid: string ) : Promise<boolean>
    {
      let msg= {
        request: "xauto-remove-processgraph",
        xautoprocessgraphid: xautoprocessgraphid
      };

      await this.Request(msg);
      return true;
    }

    async XAUTO_GetProcessGraphSVG( xautoprocessgraphid: string ) : Promise<string>
    {
      let msg= {
        request: "xauto-get-processgraph-svg",
        xautoprocessgraphid: xautoprocessgraphid
      };

      let result = ((await this.Request(msg) as unknown) as Array<string>)[0];
      return result;
    }
    async XAUTO_SaveProcess3DGraph( graph: XAUTO_XAutoProcess3DGraph ) : Promise<boolean>
    {
      let msg= {
        request: "xauto-save-process3dgraph",
        data: graph
      };

      await this.Request(msg);
      return true;
    }
    async XAUTO_GetProcess3DGraphs( seekoffset : number, maxitems : number) : Promise<Array<XAUTO_XAutoProcess3DGraph>>
    {
      let msg= {
        request: "xauto-list-process3dgraphs",
        seekoffset: seekoffset,
        maxitems: maxitems
      };

      let result = (await this.Request(msg)) as Array<XAUTO_XAutoProcess3DGraph>;
      return result;
    }

    async XAUTO_GetProcess3DGraph( xautoprocess3dgraphid: string ) : Promise<XAUTO_XAutoProcess3DGraph>
    {
      let msg= {
        request: "xauto-get-process3Dgraph",
        xautoprocess3dgraphid: xautoprocess3dgraphid
      };

      let result = ((await this.Request(msg)) as Array<XAUTO_XAutoProcess3DGraph>)[0];
      return result;
    }

    async XAUTO_RemoveProcess3DGraph( xautoprocess3dgraphid: string ) : Promise<boolean>
    {
      let msg= {
        request: "xauto-remove-process3dgraph",
        xautoprocess3dgraphid: xautoprocess3dgraphid
      };

      await this.Request(msg);
      return true;
    }

    async XAUTO_GetProcessGraphBabylon( xautoprocess3dgraphid: string ) : Promise<string>
    {
      let msg= {
        request: "xauto-get-processgraph-babylon",
        xautoprocess3dgraphid: xautoprocess3dgraphid
      };

      let result = ((await this.Request(msg) as unknown) as Array<string>)[0];
      return result;
    }

    async XAUTO_SaveProgram( program: XAUTO_XAutoProgram ) : Promise<boolean>
    {
      let msg= {
        request: "xauto-save-program",
        data: program
      };

      await this.Request(msg);
      return true;
    }
    async XAUTO_SaveProgramLua( program: XAUTO_XAutoProgramLua ) : Promise<boolean>
    {
      let msg= {
        request: "xauto-save-program-lua",
        data: program
      };

      await this.Request(msg);
      return true;
    }
    async XAUTO_ExecuteLuaProgram( program: XAUTO_XAutoProgram, programlua: XAUTO_XAutoProgramLua ) : Promise<string>
    {
      let msg= {
        request: "xauto-execute-program-lua",
        data: {"program": program, "programlua": programlua}
      };

      let result = ((await this.Request(msg)) as Array<unknown>) as Array<string>;
      return result[0];
    }
    async XAUTO_GetPrograms( seekoffset, maxitems ) : Promise<Array<XAUTO_XAutoProgram>>
    {
      let msg= {
        request: "xauto-list-programs",
        seekoffset: seekoffset,
        maxitems: maxitems
      };

      let result = (await this.Request(msg)) as Array<XAUTO_XAutoProgram>;
      return result;
    }

    async XAUTO_GetProgram( xautoprogramid: string ) : Promise<XAUTO_XAutoProgram>
    {
      let msg= {
        request: "xauto-get-program",
        xautoprogramid: xautoprogramid
      };

      let result = ((await this.Request(msg)) as Array<XAUTO_XAutoProgram>)[0];
      return result;
    }

    async XAUTO_GetProgramLua( xautoprogramid: string ) : Promise<XAUTO_XAutoProgramLua>
    {
      let msg= {
        request: "xauto-get-program-lua",
        xautoprogramid: xautoprogramid
      };

      let result = ((await this.Request(msg)) as Array<XAUTO_XAutoProgramLua>)[0];
      return result;
    }

    async XAUTO_SaveProgramsScheduling( program: XAUTO_XAutoProgramScheduling ) : Promise<boolean>
    {
      let msg= {
        request: "xauto-save-programsscheduling",
        data: program
      };

      await this.Request(msg);
      return true;
    }
    async XAUTO_SaveProgramsSchedulingInterval( program: XAUTO_XAutoProgramSchedulingInterval ) : Promise<boolean>
    {
      let msg= {
        request: "xauto-save-programsscheduling-interval",
        data: program
      };

      await this.Request(msg);
      return true;
    }

    async XAUTO_GetProgramsSchedulings( seekoffset, maxitems ) : Promise<Array<XAUTO_XAutoProgramScheduling>>
    {
      let msg= {
        request: "xauto-list-programsschedulings",
        seekoffset: seekoffset,
        maxitems: maxitems
      };

      let result = (await this.Request(msg)) as Array<XAUTO_XAutoProgramScheduling>;
      return result;
    }

    async XAUTO_GetProgramsScheduling( xautoprogramschedulingid: string ) : Promise<XAUTO_XAutoProgramScheduling>
    {
      let msg= {
        request: "xauto-get-programsscheduling",
        xautoprogramschedulingid: xautoprogramschedulingid
      };

      let result = ((await this.Request(msg)) as Array<XAUTO_XAutoProgramScheduling>)[0];
      return result;
    }

    async XAUTO_GetProgramsSchedulingInterval( xautoprogramschedulingid: string ) : Promise<XAUTO_XAutoProgramSchedulingInterval>
    {
      let msg= {
        request: "xauto-get-programsscheduling-interval",
        xautoprogramschedulingid: xautoprogramschedulingid
      };

      let result = ((await this.Request(msg)) as Array<XAUTO_XAutoProgramSchedulingInterval>)[0];
      return result;
    }

    async XAUTO_RemoveProgramsScheduling( xautoprogramschedulingid: string ) : Promise<boolean>
    {
      let msg= {
        request: "xauto-remove-programsscheduling",
        xautoprogramschedulingid: xautoprogramschedulingid
      };

      await this.Request(msg);
      return true;
    }

    async XAUTO_SaveDriver( driver: XAUTO_XAutoDriver ) : Promise<boolean>
    {
      let msg= {
        request: "xauto-save-driver",
        data: driver
      };

      await this.Request(msg);
      return true;
    }
    async XAUTO_SaveVariable( variable: XAUTO_XAutoVariable ) : Promise<boolean>
    {
      let msg= {
        request: "xauto-save-variable",
        data: variable
      };

      await this.Request(msg);
      return true;
    }

    async XAUTO_GetDrivers( seekoffset, maxitems ) : Promise<Array<XAUTO_XAutoDriver>>
    {
      let msg= {
        request: "xauto-list-drivers",
        seekoffset: seekoffset,
        maxitems: maxitems
      };

      let result = (await this.Request(msg)) as Array<XAUTO_XAutoDriver>;
      return result;
    }

    async XAUTO_GetVariables( seekoffset, maxitems, xautodriverid="" ) : Promise<Array<XAUTO_XAutoVariable>>
    {
      let msg= {
        request: "xauto-list-variables",
        xautodriverid,
        seekoffset: seekoffset,
        maxitems: maxitems
      };

      let result = (await this.Request(msg)) as Array<XAUTO_XAutoVariable>;
      return result;
    }



    // XAUTO MODBUS
    async XAUTO_SaveDriverModbus( driver: XAUTO_XAutoDriverModbus ) : Promise<boolean>
    {
      let msg= {
        request: "xauto-save-driver-modbus",
        data: driver
      };

      await this.Request(msg);
      return true;
    }
    async XAUTO_SaveVariableModbus( variable: XAUTO_XAutoVariableModbus ) : Promise<boolean>
    {
      let msg= {
        request: "xauto-save-variable-modbus",
        data: variable
      };

      await this.Request(msg);
      return true;
    }
    async XAUTO_GetDriverModbus( xautodriverid: string ) : Promise<XAUTO_XAutoDriverModbus>
    {
      let msg= {
        request: "xauto-get-driver-modbus",
        xautodriverid: xautodriverid
      };

      let result = ((await this.Request(msg)) as Array<XAUTO_XAutoDriverModbus>)[0];
      return result;
    }
    async XAUTO_GetVariableModbus( xautovariableid: string ) : Promise<XAUTO_XAutoVariableModbus>
    {
      let msg= {
        request: "xauto-get-variable-modbus",
        xautovariableid: xautovariableid
      };

      let result = ((await this.Request(msg)) as Array<XAUTO_XAutoVariableModbus>)[0];
      return result;
    }

    // XAUTO MQTT
    async XAUTO_SaveDriverMQTT( driver: XAUTO_XAutoDriverMqtt ) : Promise<boolean>
    {
      let msg= {
        request: "xauto-save-driver-mqtt",
        data: driver
      };

      await this.Request(msg);
      return true;
    }
    async XAUTO_SaveVariableMQTT( variable: XAUTO_XAutoVariableMqtt ) : Promise<boolean>
    {
      let msg= {
        request: "xauto-save-variable-mqtt",
        data: variable
      };

      await this.Request(msg);
      return true;
    }
    async XAUTO_GetDriverMqtt( xautodriverid: string ) : Promise<XAUTO_XAutoDriverMqtt>
    {
      let msg= {
        request: "xauto-get-driver-mqtt",
        xautodriverid: xautodriverid
      };

      let result = ((await this.Request(msg)) as Array<XAUTO_XAutoDriverMqtt>)[0];
      return result;
    }
    async XAUTO_GetVariableMqtt( xautovariableid: string ) : Promise<XAUTO_XAutoVariableMqtt>
    {
      let msg= {
        request: "xauto-get-variable-mqtt",
        xautovariableid: xautovariableid
      };

      let result = ((await this.Request(msg)) as Array<XAUTO_XAutoVariableMqtt>)[0];
      return result;
    }

     // XAUTO External
     async XAUTO_SaveDriverExternal( driver: XAUTO_XAutoDriverExternal ) : Promise<boolean>
     {
       let msg= {
         request: "xauto-save-driver-external",
         data: driver
       };

       await this.Request(msg);
       return true;
     }
     async XAUTO_SaveVariableExternal( variable: XAUTO_XAutoVariableExternal ) : Promise<boolean>
     {
       let msg= {
         request: "xauto-save-variable-external",
         data: variable
       };

       await this.Request(msg);
       return true;
     }
     async XAUTO_GetDriverExternal( xautodriverid: string ) : Promise<XAUTO_XAutoDriverExternal>
     {
       let msg= {
         request: "xauto-get-driver-external",
         xautodriverid: xautodriverid
       };

       let result = ((await this.Request(msg)) as Array<XAUTO_XAutoDriverExternal>)[0];
       return result;
     }
     async XAUTO_GetVariableExternal( xautovariableid: string ) : Promise<XAUTO_XAutoVariableExternal>
     {
       let msg= {
         request: "xauto-get-variable-external",
         xautovariableid: xautovariableid
       };

       let result = ((await this.Request(msg)) as Array<XAUTO_XAutoVariableExternal>)[0];
       return result;
     }

    // XAUTO GPIO
    async XAUTO_SaveDriverGpio( driver: XAUTO_XAutoDriverGpio ) : Promise<boolean>
    {
      let msg= {
        request: "xauto-save-driver-gpio",
        data: driver
      };

      await this.Request(msg);
      return true;
    }
    async XAUTO_SaveVariableGpio( variable: XAUTO_XAutoVariableGpio ) : Promise<boolean>
    {
      let msg= {
        request: "xauto-save-variable-gpio",
        data: variable
      };

      await this.Request(msg);
      return true;
    }
    async XAUTO_GetDriverGpio( xautodriverid: string ) : Promise<XAUTO_XAutoDriverGpio>
    {
      let msg= {
        request: "xauto-get-driver-gpio",
        xautodriverid: xautodriverid
      };

      let result = ((await this.Request(msg)) as Array<XAUTO_XAutoDriverGpio>)[0];
      return result;
    }
    async XAUTO_GetVariableGpio( xautovariableid: string ) : Promise<XAUTO_XAutoVariableGpio>
    {
      let msg= {
        request: "xauto-get-variable-gpio",
        xautovariableid: xautovariableid
      };

      let result = ((await this.Request(msg)) as Array<XAUTO_XAutoVariableGpio>)[0];
      return result;
    }

    // XAUTO Redis
    async XAUTO_SaveDriverRedis( driver: XAUTO_XAutoDriverRedis ) : Promise<boolean>
    {
      let msg= {
        request: "xauto-save-driver-redis",
        data: driver
      };

      await this.Request(msg);
      return true;
    }
    async XAUTO_SaveVariableRedis( variable: XAUTO_XAutoVariableRedis ) : Promise<boolean>
    {
      let msg= {
        request: "xauto-save-variable-redis",
        data: variable
      };

      await this.Request(msg);
      return true;
    }
    async XAUTO_GetDriverRedis( xautodriverid: string ) : Promise<XAUTO_XAutoDriverRedis>
    {
      let msg= {
        request: "xauto-get-driver-redis",
        xautodriverid: xautodriverid
      };

      let result = ((await this.Request(msg)) as Array<XAUTO_XAutoDriverRedis>)[0];
      return result;
    }
    async XAUTO_GetVariableRedis( xautovariableid: string ) : Promise<XAUTO_XAutoVariableRedis>
    {
      let msg= {
        request: "xauto-get-variable-redis",
        xautovariableid: xautovariableid
      };

      let result = ((await this.Request(msg)) as Array<XAUTO_XAutoVariableRedis>)[0];
      return result;
    }
    // XAUTO COLLECTD
    async XAUTO_SaveDriverCollectd( driver: XAUTO_XAutoDriverCollectd ) : Promise<boolean>
    {
      let msg= {
        request: "xauto-save-driver-collectd",
        data: driver
      };

      await this.Request(msg);
      return true;
    }
    async XAUTO_SaveVariableCollectd( variable: XAUTO_XAutoVariableCollectd ) : Promise<boolean>
    {
      let msg= {
        request: "xauto-save-variable-collectd",
        data: variable
      };

      await this.Request(msg);
      return true;
    }
    async XAUTO_GetDriverCollectd( xautodriverid: string ) : Promise<XAUTO_XAutoDriverCollectd>
    {
      let msg= {
        request: "xauto-get-driver-collectd",
        xautodriverid: xautodriverid
      };

      let result = ((await this.Request(msg)) as Array<XAUTO_XAutoDriverCollectd>)[0];
      return result;
    }
    async XAUTO_GetVariableCollectd( xautovariableid: string ) : Promise<XAUTO_XAutoVariableCollectd>
    {
      let msg= {
        request: "xauto-get-variable-collectd",
        xautovariableid: xautovariableid
      };

      let result = ((await this.Request(msg)) as Array<XAUTO_XAutoVariableCollectd>)[0];
      return result;
    }
    async XAUTO_HelperProvisionSignalsCollectd( xautodriverid: string ) : Promise<boolean>
    {
      let msg= {
        request: "xauto-helper-collectd-provisionsignals",
        xautodriverid: xautodriverid
      };

      let result = await this.Request(msg);
      return result[0] as unknown as boolean;
    }

    // XAUTO OPCUA
    async XAUTO_SaveDriverOpcua( driver: XAUTO_XAutoDriverOpcua ) : Promise<boolean>
    {
      let msg= {
        request: "xauto-save-driver-opcua",
        data: driver
      };

      await this.Request(msg);
      return true;
    }
    async XAUTO_SaveVariableOpcua( variable: XAUTO_XAutoVariableOpcua ) : Promise<boolean>
    {
      let msg= {
        request: "xauto-save-variable-opcua",
        data: variable
      };

      await this.Request(msg);
      return true;
    }
    async XAUTO_GetDriverOpcua( xautodriverid: string ) : Promise<XAUTO_XAutoDriverOpcua>
    {
      let msg= {
        request: "xauto-get-driver-opcua",
        xautodriverid: xautodriverid
      };

      let result = ((await this.Request(msg)) as Array<XAUTO_XAutoDriverOpcua>)[0];
      return result;
    }
    async XAUTO_GetVariableOpcua( xautovariableid: string ) : Promise<XAUTO_XAutoVariableOpcua>
    {
      let msg= {
        request: "xauto-get-variable-opcua",
        xautovariableid: xautovariableid
      };

      let result = ((await this.Request(msg)) as Array<XAUTO_XAutoVariableOpcua>)[0];
      return result;
    }
    async XAUTO_HelperProvisionSignalsOpcua( xautodriverid: string ) : Promise<boolean>
    {
      let msg= {
        request: "xauto-helper-opcua-provisionsignals",
        xautodriverid: xautodriverid
      };

      let result = await this.Request(msg);
      return result[0] as unknown as boolean;
    }

    // XAUTO MIDI
    async XAUTO_SaveDriverMidi( driver: XAUTO_XAutoDriverMidi ) : Promise<boolean>
    {
      let msg= {
        request: "xauto-save-driver-midi",
        data: driver
      };

      await this.Request(msg);
      return true;
    }
    async XAUTO_SaveVariableMidi( variable: XAUTO_XAutoVariableMidi ) : Promise<boolean>
    {
      let msg= {
        request: "xauto-save-variable-midi",
        data: variable
      };

      await this.Request(msg);
      return true;
    }
    async XAUTO_GetDriverMidi( xautodriverid: string ) : Promise<XAUTO_XAutoDriverMidi>
    {
      let msg= {
        request: "xauto-get-driver-midi",
        xautodriverid: xautodriverid
      };

      let result = ((await this.Request(msg)) as Array<XAUTO_XAutoDriverMidi>)[0];
      return result;
    }
    async XAUTO_GetVariableMidi( xautovariableid: string ) : Promise<XAUTO_XAutoVariableMidi>
    {
      let msg= {
        request: "xauto-get-variable-midi",
        xautovariableid: xautovariableid
      };

      let result = ((await this.Request(msg)) as Array<XAUTO_XAutoVariableMidi>)[0];
      return result;
    }


    // XAUTO MBUS
    async XAUTO_SaveDriverMbus( driver: XAUTO_XAutoDriverMbus ) : Promise<boolean>
    {
      let msg= {
        request: "xauto-save-driver-mbus",
        data: driver
      };

      await this.Request(msg);
      return true;
    }
    async XAUTO_SaveVariableMbus( variable: XAUTO_XAutoVariableMbus ) : Promise<boolean>
    {
      let msg= {
        request: "xauto-save-variable-mbus",
        data: variable
      };

      await this.Request(msg);
      return true;
    }
    async XAUTO_GetDriverMbus( xautodriverid: string ) : Promise<XAUTO_XAutoDriverMbus>
    {
      let msg= {
        request: "xauto-get-driver-mbus",
        xautodriverid: xautodriverid
      };

      let result = ((await this.Request(msg)) as Array<XAUTO_XAutoDriverMbus>)[0];
      return result;
    }
    async XAUTO_GetVariableMbus( xautovariableid: string ) : Promise<XAUTO_XAutoVariableMbus>
    {
      let msg= {
        request: "xauto-get-variable-mbus",
        xautovariableid: xautovariableid
      };

      let result = ((await this.Request(msg)) as Array<XAUTO_XAutoVariableMbus>)[0];
      return result;
    }

    // XAUTO INTEGRATION SERVERS

    async XAUTO_GetIntegrationServers( seekoffset, maxitems ) : Promise<Array<XAUTO_XAutoIntegrationServer>>
    {
      let msg= {
        request: "xauto-list-integrationservers",
        seekoffset: seekoffset,
        maxitems: maxitems
      };

      let result = (await this.Request(msg)) as Array<XAUTO_XAutoIntegrationServer>;
      return result;
    }

    async XAUTO_SaveIntegrationServer( server: XAUTO_XAutoIntegrationServer ) : Promise<boolean>
    {
      let msg= {
        request: "xauto-save-integrationserver",
        data: server
      };

      await this.Request(msg);
      return true;
    }
    async XAUTO_RemoveIntegrationServer( serverid: string ) : Promise<boolean>
    {
      let msg= {
        request: "xauto-remove-integrationserver",
        xautointegrationserverid: serverid
      };

      await this.Request(msg);
      return true;
    }
    async XAUTO_SaveIntegrationServerMQTT( server: XAUTO_IntegrationServerMqtt ) : Promise<boolean>
    {
      let msg= {
        request: "xauto-save-integrationserver-mqtt",
        data: server
      };

      await this.Request(msg);
      return true;
    }
    async XAUTO_SaveIntegrationServerMQTTVariableMapping( mapping: XAUTO_IntegrationServerMqttVariableMapping ) : Promise<boolean>
    {
      let msg= {
        request: "xauto-save-integrationserver-mqtt-variablemapping",
        data: mapping
      };

      await this.Request(msg);
      return true;
    }




}
