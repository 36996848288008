<div class="login-wrapper">
  <form class="login" (ngSubmit)="login()">
    <section class="title">
      <h3 i18n='@@login_welcome' class="welcome">Welcome to</h3>
      <ng-container>{{title}}</ng-container>
      <h5 i18n='@@login_hint' class="hint">From data to insights</h5>
    </section>
    <div class="login-group">
      <!-- <clr-select-container *ngIf="!adminLogin" >
        <label i18n='@@login_selectauth'>Select auth method</label>
        <select clrSelect name="logintype" [(ngModel)]="loginProvider">
          <option *ngFor="let item of loginProviders" [ngValue]="item">{{item.displayName}}</option>
        </select>
      </clr-select-container> -->
      <clr-input-container *ngIf="loginProvider?.usernamePassword || adminLogin" >
        <label i18n='@@login_username' class="clr-sr-only">Email</label>
        <input i18n-placeholder="@@login_username_placeholder" type="text" name="username" clrInput placeholder="Email" [(ngModel)]="username" />
      </clr-input-container>
      <clr-password-container *ngIf="loginProvider?.usernamePassword || adminLogin">
        <label i18n='@@login_password' class="clr-sr-only">Password</label>
        <input i18n-placeholder="@@login_password_placeholder" type="password" name="password" clrPassword placeholder="Password" [(ngModel)]="password" />
      </clr-password-container>

      <!-- <clr-checkbox-container>
        <clr-checkbox-wrapper>
          <input type="checkbox" clrCheckbox [(ngModel)]="adminLogin" name="adminlogin" />
          <label i18n='@@login_admin'>Admin</label>
        </clr-checkbox-wrapper>
      </clr-checkbox-container> -->

      <!-- <clr-checkbox-wrapper>
              <label>Remember me</label>
              <input type="checkbox" name="rememberMe" clrCheckbox [(ngModel)]="form.rememberMe"/>
          </clr-checkbox-wrapper> -->
      <div i18n='@@login_invalidpassword' class="error" [class.active]="invalidlogin">
        Invalid user name or password
      </div>
      <button *ngIf="!adminLogin" i18n='@@login_login' type="submit" class="btn btn-primary">Login</button>
      <button *ngIf="adminLogin"  i18n='@@login_adminlogin' type="submit" class="btn btn-primary">Admin Login</button>
      <a i18n='@@login_forgotpassword' [routerLink]="['/resetpassword']" routerLinkActive="active" class="signup">Forgot password?</a>
    </div>
  </form>
</div>
