<clr-tabs>
  <clr-tab>
    <button clrTabLink>Export report</button>
    <clr-tab-content>
      <form clrForm>
        <clr-select-container>
          <label>Billing period</label>
          <select clrSelect name="billingperiodsselect" [(ngModel)]="selectedBillingPeriod"
            (ngModelChange)="selectedBillingPeriodChange()">
            <option [ngValue]="BmsBillingPeriod.Month"> Month</option>
            <option [ngValue]="BmsBillingPeriod.Quarter"> Quarter</option>
          </select>
        </clr-select-container>
        <clr-select-container>
          <label>Export period</label>
          <select clrSelect name="exportperiodsselect" [(ngModel)]="selectedExportPeriod">
            <option *ngFor="let period of exportPeriods; let i = index" [ngValue]="i">
              {{formatByString(period.start,
              'yyyy-MM-dd') + ' - ' + formatByString(period.end, 'yyyy-MM-dd') }}</option>
          </select>
        </clr-select-container>

        <br>
        <button type="button" class="btn btn-secondary" (click)="onViewReport()"
          [clrLoading]="generatingExportReport">Generate report</button>

        <table *ngIf="exportInfo" class="table table-vertical exportInfo-table">
          <caption>
            Export status report
          </caption>
          <tbody>
            <tr>
              <th class="left">Timestamp</th>
              <td class="left">{{formatByString(exportInfo.timestamp, 'yyyy-MM-dd HH:mm:ss')}}</td>
            </tr>
            <tr>
              <th class="left">Signed</th>
              <td class="left">{{exportInfo.data.signedCount}} / {{exportInfo.data.totalCount}}</td>
            </tr>
            <tr>
              <th class="left">Invoiced</th>
              <td class="left">{{exportInfo.data.invoicedCount}} / {{exportInfo.data.totalCount}}</td>
            </tr>
          </tbody>
        </table>

        <clr-datagrid #trusteesgrid class="trustees-list" *ngIf="exportInfo">
          <clr-dg-column>
            <ng-container>Trustee</ng-container>
          </clr-dg-column>
          <clr-dg-column>
            <ng-container>Signed</ng-container>
          </clr-dg-column>
          <clr-dg-column>
            <ng-container>Invoiced</ng-container>
          </clr-dg-column>

          <clr-dg-row *ngFor="let trusteeInfo of exportInfo.data.trustees;">
            <clr-dg-cell> {{trusteeInfo.name}}
            </clr-dg-cell>
            <clr-dg-cell> {{trusteeInfo.signedCount}} / {{trusteeInfo.totalCount}}
            </clr-dg-cell>
            <clr-dg-cell> {{trusteeInfo.invoicedCount}} / {{trusteeInfo.totalCount}}
            </clr-dg-cell>

            <clr-dg-row-detail *clrIfExpanded>
              <table class="table customerInfoTable">
                <caption>
                  Customers status
                </caption>
                <thead>
                  <tr>
                    <th class="left">Customer</th>
                    <th class="left">Signed</th>
                    <th class="left">Invoiced</th>
                    <th class="left">Count</th>
                    <th class="left">Errors</th>
                    <th></th>
                  </tr>
                </thead>
                <tbody>
                  <tr *ngFor="let customerInfo of trusteeInfo.customers">
                    <td class="left"><a [routerLink]="['/bmsadmin/customers/' + customerInfo.customerId]"
                        routerLinkActive="active">{{customerInfo.name}}</a></td>
                    <td class="left">
                      <clr-icon shape="check" *ngIf="customerInfo.signed"></clr-icon>
                    </td>
                    <td class="left">
                      <clr-icon shape="check" *ngIf="customerInfo.invoiced"></clr-icon>
                    </td>
                    <td class="left">{{customerInfo.meterCount}}</td>
                    <td class="left">
                      {{customerInfo.meterErrorCount}}
                    </td>
                    <td class="left">
                      <a [routerLink]="['/bmsadmin/trusteeadmin/' + trusteeInfo.customerId + '/' + customerInfo.customerId]"
                        routerLinkActive="active">
                        <clr-icon shape="eye" size="16" [style.cursor]="'pointer'"></clr-icon>
                      </a>
                    </td>
                  </tr>
                </tbody>
              </table>
            </clr-dg-row-detail>
          </clr-dg-row>

        </clr-datagrid>
      </form>
    </clr-tab-content>
  </clr-tab>
  <clr-tab>
    <button clrTabLink>Export special report</button>
    <clr-tab-content>
      <form clrForm>
        <clr-select-container>
          <label>Billing period</label>
          <select clrSelect name="billingperiodsselectspecial" [(ngModel)]="selectedBillingPeriodSpecial"
            (ngModelChange)="selectedBillingPeriodSpecialChange()">
            <option [ngValue]="BmsBillingPeriod.Month"> Month</option>
            <option [ngValue]="BmsBillingPeriod.Quarter"> Quarter</option>
          </select>
        </clr-select-container>
        <clr-select-container>
          <label>Export period</label>
          <select clrSelect name="exportperiodsselectspecial" [(ngModel)]="selectedExportPeriodSpecial">
            <option *ngFor="let period of exportPeriodsSpecial; let i = index" [ngValue]="i">
              {{formatByString(period.start,
              'yyyy-MM-dd') + ' - ' + formatByString(period.end, 'yyyy-MM-dd') }}</option>
          </select>
        </clr-select-container>

        <br>
        <button type="button" class="btn btn-secondary" (click)="onViewSpecialReport()"
          [clrLoading]="generatingExportReport">Generate special report</button>

        <div class="trustees-list-special">
          <div class="clr-row">
            <div class="clr-col-10">
              <table *ngIf="exportSpecialInfo" class="table table-vertical exportInfo-table">
                <caption>
                  Export status report
                </caption>
                <tbody>
                  <tr>
                    <th class="left">Timestamp</th>
                    <td class="left">{{formatByString(exportSpecialInfo.timestamp, 'yyyy-MM-dd HH:mm:ss')}}</td>
                  </tr>
                  <tr>
                    <th class="left">Signed</th>
                    <td class="left">{{exportSpecialInfo.data.signedCount}} / {{exportSpecialInfo.data.totalCount}}</td>
                  </tr>
                  <tr>
                    <th class="left">Invoiced</th>
                    <td class="left">{{exportSpecialInfo.data.invoicedCount}} / {{exportSpecialInfo.data.totalCount}}</td>
                  </tr>
                </tbody>
              </table>
            </div>
            <div class="clr-col-2 export-container">
              <button *ngIf="exportSpecialInfo" type="button" class="btn btn-secondary export-button"
                (click)="exportSpecialReport()">
                <clr-icon shape="export"></clr-icon> Export</button>
            </div>
          </div>

          <clr-datagrid #trusteesgrid class="" *ngIf="exportSpecialInfo">
            <clr-dg-column>
              <ng-container>Trustee</ng-container>
            </clr-dg-column>
            <clr-dg-column>
              <ng-container>Signed</ng-container>
            </clr-dg-column>
            <clr-dg-column>
              <ng-container>Invoiced</ng-container>
            </clr-dg-column>

            <clr-dg-row *ngFor="let trusteeInfo of exportSpecialInfo.data.trustees;">
              <clr-dg-cell> {{trusteeInfo.name}}
              </clr-dg-cell>
              <clr-dg-cell> {{trusteeInfo.signedCount}} / {{trusteeInfo.totalCount}}
              </clr-dg-cell>
              <clr-dg-cell> {{trusteeInfo.invoicedCount}} / {{trusteeInfo.totalCount}}
              </clr-dg-cell>

              <clr-dg-row-detail *clrIfExpanded>
                <table class="table customerInfoTable-special">
                  <caption>
                    Customers status
                  </caption>
                  <thead>
                    <tr>
                      <th class="left">Customer</th>
                      <th class="left">Signed</th>
                      <th class="left">Invoiced</th>
                      <th class="left">Count</th>
                      <th class="left">Errors</th>
                      <th class="left">After 15:e</th>
                      <th class="left">Special</th>
                      <th></th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr *ngFor="let customerInfo of trusteeInfo.customers">
                      <td class="left"><a [routerLink]="['/bmsadmin/customers/' + customerInfo.customerId]"
                          routerLinkActive="active">{{customerInfo.name}}</a></td>
                      <td class="left">
                        <clr-icon shape="check" *ngIf="customerInfo.signed"></clr-icon>
                      </td>
                      <td class="left">
                        <clr-icon shape="check" *ngIf="customerInfo.invoiced"></clr-icon>
                      </td>
                      <td class="left">{{customerInfo.meterCount}}</td>
                      <td class="left">
                        {{customerInfo.meterErrorCount}}
                      </td>
                      <td class="center">
                        <cds-icon shape="exclamation-circle" status="danger" size="sm"
                          *ngIf="customerInfo.invoiceDay >= 15"></cds-icon>
                      </td>
                      <td class="left invoice-specail-col" *ngIf="customerInfo.invoiceInfo?.length > 0">
                        <cds-icon shape="bubble-exclamation" status="warning" size="sm"></cds-icon>
                        {{customerInfo.invoiceInfo}}
                      </td>
                      <td *ngIf="!customerInfo.invoiceInfo || customerInfo.invoiceInfo.length == 0"></td>
                      <td class="left">
                        <a [routerLink]="['/bmsadmin/trusteeadmin/' + trusteeInfo.customerId + '/' + customerInfo.customerId]"
                          routerLinkActive="active">
                          <clr-icon shape="eye" size="16" [style.cursor]="'pointer'"></clr-icon>
                        </a>
                      </td>
                    </tr>
                  </tbody>
                </table>
              </clr-dg-row-detail>
            </clr-dg-row>
          </clr-datagrid>
        </div>
      </form>
    </clr-tab-content>
  </clr-tab>
  <clr-tab>
    <button clrTabLink>Trustees report</button>
    <clr-tab-content>
      <ng-template [(clrIfActive)]="trusteeCustomerActive">
        <div class="trustee-report-area">
          <app-dashboard #dashboardTrusteeCustomers [editMode]="false" [showDashboardSettings]="false"
            [dashboardId]="'bms_trustee_customers'" [enableExport]="true"></app-dashboard>
        </div>
      </ng-template>
    </clr-tab-content>
  </clr-tab>
</clr-tabs>
