/* tslint:disable */
/* eslint-disable */
// @ts-nocheck
//
// THIS IS A GENERATED FILE
// DO NOT MODIFY IT! YOUR CHANGES WILL BE LOST
import { Inject, Injectable, Optional } from '@angular/core';
import {
  GrpcCallType,
  GrpcClient,
  GrpcClientFactory,
  GrpcEvent,
  GrpcMetadata
} from '@ngx-grpc/common';
import {
  GRPC_CLIENT_FACTORY,
  GrpcHandler,
  takeMessages,
  throwStatusErrors
} from '@ngx-grpc/core';
import { Observable } from 'rxjs';
import * as thisProto from './xprojector.module.thingsboard.pb';
import * as googleProtobuf000 from './google/protobuf/timestamp.pb';
import { GRPC_THINGSBOARD_CLIENT_SETTINGS } from './xprojector.module.thingsboard.pbconf';
/**
 * Service client implementation for module.thingsboard.Thingsboard
 */
@Injectable({ providedIn: 'root' })
export class ThingsboardClient {
  private client: GrpcClient<any>;

  /**
   * Raw RPC implementation for each service client method.
   * The raw methods provide more control on the incoming data and events. E.g. they can be useful to read status `OK` metadata.
   * Attention: these methods do not throw errors when non-zero status codes are received.
   */
  $raw = {
    /**
     * Unary call: /module.thingsboard.Thingsboard/GetDeviceGroups
     *
     * @param requestMessage Request message
     * @param requestMetadata Request metadata
     * @returns Observable<GrpcEvent<thisProto.GetDeviceGroupsResponse>>
     */
    getDeviceGroups: (
      requestData: thisProto.GetDeviceGroupsRequest,
      requestMetadata = new GrpcMetadata()
    ): Observable<GrpcEvent<thisProto.GetDeviceGroupsResponse>> => {
      return this.handler.handle({
        type: GrpcCallType.unary,
        client: this.client,
        path: '/module.thingsboard.Thingsboard/GetDeviceGroups',
        requestData,
        requestMetadata,
        requestClass: thisProto.GetDeviceGroupsRequest,
        responseClass: thisProto.GetDeviceGroupsResponse
      });
    },
    /**
     * Unary call: /module.thingsboard.Thingsboard/GetDevices
     *
     * @param requestMessage Request message
     * @param requestMetadata Request metadata
     * @returns Observable<GrpcEvent<thisProto.GetDevicesResponse>>
     */
    getDevices: (
      requestData: thisProto.GetDevicesRequest,
      requestMetadata = new GrpcMetadata()
    ): Observable<GrpcEvent<thisProto.GetDevicesResponse>> => {
      return this.handler.handle({
        type: GrpcCallType.unary,
        client: this.client,
        path: '/module.thingsboard.Thingsboard/GetDevices',
        requestData,
        requestMetadata,
        requestClass: thisProto.GetDevicesRequest,
        responseClass: thisProto.GetDevicesResponse
      });
    },
    /**
     * Unary call: /module.thingsboard.Thingsboard/UpsertDeviceGroup
     *
     * @param requestMessage Request message
     * @param requestMetadata Request metadata
     * @returns Observable<GrpcEvent<thisProto.UpsertDeviceGroupResponse>>
     */
    upsertDeviceGroup: (
      requestData: thisProto.UpsertDeviceGroupRequest,
      requestMetadata = new GrpcMetadata()
    ): Observable<GrpcEvent<thisProto.UpsertDeviceGroupResponse>> => {
      return this.handler.handle({
        type: GrpcCallType.unary,
        client: this.client,
        path: '/module.thingsboard.Thingsboard/UpsertDeviceGroup',
        requestData,
        requestMetadata,
        requestClass: thisProto.UpsertDeviceGroupRequest,
        responseClass: thisProto.UpsertDeviceGroupResponse
      });
    },
    /**
     * Unary call: /module.thingsboard.Thingsboard/ImportData
     *
     * @param requestMessage Request message
     * @param requestMetadata Request metadata
     * @returns Observable<GrpcEvent<thisProto.ImportDataResponse>>
     */
    importData: (
      requestData: thisProto.ImportDataRequest,
      requestMetadata = new GrpcMetadata()
    ): Observable<GrpcEvent<thisProto.ImportDataResponse>> => {
      return this.handler.handle({
        type: GrpcCallType.unary,
        client: this.client,
        path: '/module.thingsboard.Thingsboard/ImportData',
        requestData,
        requestMetadata,
        requestClass: thisProto.ImportDataRequest,
        responseClass: thisProto.ImportDataResponse
      });
    }
  };

  constructor(
    @Optional() @Inject(GRPC_THINGSBOARD_CLIENT_SETTINGS) settings: any,
    @Inject(GRPC_CLIENT_FACTORY) clientFactory: GrpcClientFactory<any>,
    private handler: GrpcHandler
  ) {
    this.client = clientFactory.createClient(
      'module.thingsboard.Thingsboard',
      settings
    );
  }

  /**
   * Unary call @/module.thingsboard.Thingsboard/GetDeviceGroups
   *
   * @param requestMessage Request message
   * @param requestMetadata Request metadata
   * @returns Observable<thisProto.GetDeviceGroupsResponse>
   */
  getDeviceGroups(
    requestData: thisProto.GetDeviceGroupsRequest,
    requestMetadata = new GrpcMetadata()
  ): Observable<thisProto.GetDeviceGroupsResponse> {
    return this.$raw
      .getDeviceGroups(requestData, requestMetadata)
      .pipe(throwStatusErrors(), takeMessages());
  }

  /**
   * Unary call @/module.thingsboard.Thingsboard/GetDevices
   *
   * @param requestMessage Request message
   * @param requestMetadata Request metadata
   * @returns Observable<thisProto.GetDevicesResponse>
   */
  getDevices(
    requestData: thisProto.GetDevicesRequest,
    requestMetadata = new GrpcMetadata()
  ): Observable<thisProto.GetDevicesResponse> {
    return this.$raw
      .getDevices(requestData, requestMetadata)
      .pipe(throwStatusErrors(), takeMessages());
  }

  /**
   * Unary call @/module.thingsboard.Thingsboard/UpsertDeviceGroup
   *
   * @param requestMessage Request message
   * @param requestMetadata Request metadata
   * @returns Observable<thisProto.UpsertDeviceGroupResponse>
   */
  upsertDeviceGroup(
    requestData: thisProto.UpsertDeviceGroupRequest,
    requestMetadata = new GrpcMetadata()
  ): Observable<thisProto.UpsertDeviceGroupResponse> {
    return this.$raw
      .upsertDeviceGroup(requestData, requestMetadata)
      .pipe(throwStatusErrors(), takeMessages());
  }

  /**
   * Unary call @/module.thingsboard.Thingsboard/ImportData
   *
   * @param requestMessage Request message
   * @param requestMetadata Request metadata
   * @returns Observable<thisProto.ImportDataResponse>
   */
  importData(
    requestData: thisProto.ImportDataRequest,
    requestMetadata = new GrpcMetadata()
  ): Observable<thisProto.ImportDataResponse> {
    return this.$raw
      .importData(requestData, requestMetadata)
      .pipe(throwStatusErrors(), takeMessages());
  }
}
