import { Component, Input, OnInit } from '@angular/core';
import { XProjectorXConfClient } from '@xprojectorcore/xprojector_backend/xprojector-xconf-client';
import { BaseQuery, BaseQueryInputColumnDescription, ColumnFilteringNumerical, DateHelper, FilterComparator, FilterLogicalGroupType, ProjectionColumnDescription, XDataType, XprojAlertService, XProjectorClient } from 'xproj-lib';
import { BmsBillingTariff } from '@features/bms/models/bms-billing-tariff';
import { BmsDataUtils } from '@features/bms/bms-admin/utils/bms-data-utils';
import { BmsTariffService } from '@features/bms/bms-admin/services/bms-tariff-service';

@Component({
  selector: 'app-bms-billing-tariff-view',
  templateUrl: './bms-billing-tariff-view.component.html',
  styleUrls: ['./bms-billing-tariff-view.component.scss']
})
export class BmsBillingTariffViewComponent implements OnInit {

  @Input() allowEdit : boolean = false;

  tariffColumns: ProjectionColumnDescription[] = [];
  tariffs: BmsBillingTariff[] = [];
  decimals : number = 3;
  meterId : number;

  showEditBillingTariff : boolean = false;
  selectedTariff : BmsBillingTariff;
  selectedActiveFromString : string;

  BmsDataUtils = BmsDataUtils;

  constructor(
    private alertService: XprojAlertService,
    private xprojClient: XProjectorClient,
    private xconfClient: XProjectorXConfClient,
    private tariffService : BmsTariffService,
    public dateHelper :  DateHelper
  ) { }

  ngOnInit(): void {
  }

  async update(meterId: number, decimals : number = 3) {
    this.decimals = decimals;
    this.meterId = meterId;
    this.tariffs = await this.tariffService.getBillingTariffs({meterId : meterId});
    this.tariffs.forEach(tariff => {
      tariff.value = +tariff.value.toFixed(this.decimals);
      tariff.vatRate = +tariff.vatRate.toFixed(this.decimals);
    });
  }

  async editTariff(tariff : BmsBillingTariff) {
    if (tariff) {
      this.selectedTariff = tariff;
      this.selectedActiveFromString = this.dateHelper.utils.formatByString(tariff.activeFrom, 'yyyy-MM-dd');
      this.showEditBillingTariff = true;
    }
  }

  async onExecuteEditBillingTariff() {
    try {
      if (this.selectedTariff?.value >= 0) {
        this.selectedTariff.activeFrom = this.dateHelper.utils.parse(this.selectedActiveFromString, 'yyyy-MM-dd');
        this.selectedTariff.activeFrom = this.dateHelper.utils.addMinutes(this.selectedTariff.activeFrom, -this.selectedTariff.activeFrom.getTimezoneOffset());
        this.selectedTariff.modifiedAt = new Date();

        let result = await this.tariffService.setBillingTariffs([this.selectedTariff]);

        if (result) {
          this.alertService.info('Billing tariff updated ok.');
        }
        else {
          this.alertService.error('Error update billing tariffs.');
          await this.update(this.meterId);
        }
      }
    }
    catch (err) {
      this.alertService.error(err);
    }
  }

  async deleteTariff(tariff : BmsBillingTariff) {
    if (tariff) {

    }
  }
}
