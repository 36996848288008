<table class="table table-vertical" *ngIf="building">
  <caption style="margin-bottom: 1em;">
    Building info
    <button class="btn btn-sm" style="float: right;" (click)="viewInBMS();">View in BMS<clr-icon
        shape="arrow" directions="left" style="transform: rotate(90deg);"></clr-icon></button>
  </caption>
  <tbody>
    <tr>
      <th class="left">Sv lant Building No</th>
      <td class="left">{{building.svLantBuildingNo}}</td>
    </tr>
    <tr>
      <th class="left">Description</th>
      <td class="left">{{building.description}}</td>
    </tr>
    <tr>
      <th class="left">Latitude</th>
      <td class="left">{{building.latitude}}</td>
    </tr>
    <tr>
      <th class="left">Longitude</th>
      <td class="left">{{building.longitude}}</td>
    </tr>
    <tr>
      <th class="left">Created</th>
      <td class="left">{{dateHelper.utils.format(building.createdAt, 'keyboardDateTime')}}</td>
    </tr>
    <tr>
      <th class="left">Modified</th>
      <td class="left">{{dateHelper.utils.format(building.modifiedAt, 'keyboardDateTime')}}</td>
    </tr>
  </tbody>
</table>

<p *ngIf="!building"> Building info not available.</p>
