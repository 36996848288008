<clr-modal [(clrModalOpen)]="showEditModal">
  <h3 class="modal-title">Edit node</h3>
  <div class="modal-body">
    <app-edit-treenode #editNode *ngIf="showEditModal && currentEditNode" [node]="currentEditNode"
      [nodeTypes]="allNodeTypes"></app-edit-treenode>

  </div>
  <div class="modal-footer">
    <button class="btn btn-outline" (click)="closeEditNode()">Cancel</button>
    <button class="btn btn-primary" (click)="saveEditNode()">Save</button>
  </div>
</clr-modal>

<clr-modal *ngIf="showDownlinkInfos" [(clrModalOpen)]="showDownlinkInfoModal" [clrModalSize]="'lg'" [clrModalClosable]="false">
  <h3 class="modal-title">Downlink info</h3>
  <div class="modal-body">
    <clr-datagrid>

      <clr-dg-action-bar>
        <div class="btn-group" style="float: right;">
          <button class="btn btn-sm btn-secondary" (click)="exportDownlinkInfos();"><clr-icon shape="export"
              directions="left"></clr-icon> Export</button>
        </div>
      </clr-dg-action-bar>


      <clr-dg-column [clrDgField]="'devEui'" [clrDgSortOrder]="ascSort">
        <ng-container *clrDgHideableColumn="{hidden: false}">DevEui</ng-container>
      </clr-dg-column>

      <clr-dg-column [clrDgField]="'timestamp'">
        <ng-container *clrDgHideableColumn="{hidden: false}">Timestamp</ng-container>
      </clr-dg-column>

      <clr-dg-column [clrDgField]="'result'">
        <ng-container *clrDgHideableColumn="{hidden: false}">Result</ng-container>
      </clr-dg-column>

      <clr-dg-column [clrDgField]="'message'">
        <ng-container *clrDgHideableColumn="{hidden: false}">Message</ng-container>
      </clr-dg-column>

      <clr-dg-row *clrDgItems="let downlinkInfo of downlinkInfos; let i = index" [clrDgItem]="downlinkInfo">
        <clr-dg-cell>{{downlinkInfo.devEui}}</clr-dg-cell>
        <clr-dg-cell>{{downlinkInfo.timestamp}}</clr-dg-cell>
        <clr-dg-cell>{{downlinkInfo.result}}</clr-dg-cell>
        <clr-dg-cell>{{downlinkInfo.message}}</clr-dg-cell>
      </clr-dg-row>

      <clr-dg-footer>
        <clr-dg-pagination #paginationDownlinkInfos [clrDgPageSize]="10">
          <clr-dg-page-size [clrPageSizeOptions]="[10,20,50,100]">Rows per page</clr-dg-page-size>
          {{paginationDownlinkInfos.firstItem + 1}} - {{paginationDownlinkInfos.lastItem + 1}} of {{paginationDownlinkInfos.totalItems}} rows
        </clr-dg-pagination>
      </clr-dg-footer>

    </clr-datagrid>
  </div>
  <div class="modal-footer">
    <button class="btn btn-primary" (click)="showDownlinkInfoModal=false;">Close</button>
  </div>
</clr-modal>

<clr-tabs>
  <clr-tab>
    <button clrTabLink>Info</button>
    <ng-template [(clrIfActive)]="infoActive">
      <clr-tab-content>
        <div class="detail-area">
          <table class="table table-vertical sf-stat" *ngIf="lorawanDeviceSpreadingfactorsStat">
            <caption style="margin-bottom: 1em;">
              Spreading factors
            </caption>
            <tbody>
              <tr *ngFor="let sf of lorawanDeviceSpreadingfactorsStat | keyvalue">
                <th class="left sf-stat-key">SF {{sf.key}}</th>
                <td class="left sf-stat-value">{{(sf.value * 100 / lorawanMultiMetersInfo.length).toFixed(0)}} % (
                  {{sf.value}} ) </td>
              </tr>
            </tbody>
          </table>

          <clr-datagrid [(clrDgSingleSelected)]="selectedMultiMeter" [clrDgLoading]="loadingMeters"
            (clrDgSingleSelectedChange)="updateDashboardOutputs()" class="datagrid-compact meterGrid">
            <clr-dg-action-bar>
              <div class="btn-group">
                <button class="btn btn-sm btn-secondary" (click)="editMultiMeter()" [disabled]="!selectedMultiMeter">
                  <clr-icon shape="pencil" directions="left"></clr-icon> Edit
                </button>
                <!-- <button class="btn btn-sm btn-secondary" (click)="addMultiMeter()">
                  <clr-icon shape="plus" directions="left"></clr-icon> Add...
                </button> -->
              </div>

              <!-- <div class="btn-group">
                      <button class="btn btn-sm btn-secondary" (click)="exportMultiMeters()">
                          <clr-icon shape="export" directions="left"></clr-icon> Export
                      </button>
                      <button type="button" class="btn btn-sm btn-secondary" (click)="importMultiMeters()">
                          <clr-icon shape="import"></clr-icon> Import...
                      </button>
                  </div> -->

              <div class="btn-group">
                <button class="btn btn-sm btn-secondary" (click)="refresh()">
                  <clr-icon shape="refresh" directions="left"></clr-icon> Refresh
                </button>
                <button class="btn btn-sm btn-secondary" (click)="export()">
                  <clr-icon shape="export" directions="left"></clr-icon> Export
                </button>
              </div>
            </clr-dg-action-bar>

            <clr-dg-column [clrDgField]="'meter.id'" [style.min-width.px]="200">
              <ng-container *clrDgHideableColumn="{hidden: true}">Id</ng-container>
            </clr-dg-column>
            <clr-dg-column [clrDgField]="'meter.devEui'" [style.min-width.px]="150">
              <ng-container *clrDgHideableColumn="{hidden: false}">DevEui</ng-container>
            </clr-dg-column>
            <clr-dg-column [clrDgField]="'meter.name'" [style.min-width.px]="200">
              <ng-container *clrDgHideableColumn="{hidden: false}">Name</ng-container>
            </clr-dg-column>
            <!-- <clr-dg-column [clrDgField]="'meter.provisioned'">
                  <ng-container *clrDgHideableColumn="{hidden: false}">Provisioned</ng-container>
              </clr-dg-column> -->
            <clr-dg-column [clrDgField]="'deviceInfo.modifiedAt'" [style.min-width.px]="200">
              <ng-container *clrDgHideableColumn="{hidden: false}">Last active</ng-container>
            </clr-dg-column>
            <clr-dg-column [clrDgField]="'meter.lastValueAt'" [style.min-width.px]="200">
              <ng-container *clrDgHideableColumn="{hidden: true}">Latest value at</ng-container>
            </clr-dg-column>
            <clr-dg-column [clrDgField]="'deviceInfo.rssi'" [style.min-width.px]="50">
              <ng-container *clrDgHideableColumn="{hidden: false}">RSSI</ng-container>
            </clr-dg-column>
            <clr-dg-column [clrDgField]="'deviceInfo.snr'" [style.min-width.px]="50">
              <ng-container *clrDgHideableColumn="{hidden: false}">SNR</ng-container>
            </clr-dg-column>
            <clr-dg-column [clrDgField]="'deviceInfo.spreadingfactor'" [style.min-width.px]="50">
              <ng-container *clrDgHideableColumn="{hidden: false}">SF</ng-container>
            </clr-dg-column>
            <clr-dg-column [clrDgField]="'deviceInfo.fCnt'" [style.min-width.px]="50">
              <ng-container *clrDgHideableColumn="{hidden: false}">FCnt</ng-container>
            </clr-dg-column>
            <clr-dg-column [clrDgField]="'deviceInfo.batteryLevel'" [style.min-width.px]="50">
              <ng-container *clrDgHideableColumn="{hidden: false}">Battery</ng-container>
            </clr-dg-column>
            <!-- <clr-dg-column [clrDgField]="'meter.externalId'" [style.min-width.px]="150">
                  <ng-container *clrDgHideableColumn="{hidden: false}">External Id</ng-container>
              </clr-dg-column> -->
            <clr-dg-column [clrDgField]="'meter.multiMeterType'" [style.min-width.px]="100">
              <ng-container *clrDgHideableColumn="{hidden: false}">MultiMeterType</ng-container>
            </clr-dg-column>
            <!-- <clr-dg-column [clrDgField]="'meter.appKey'" [style.min-width.px]="200">
                  <ng-container *clrDgHideableColumn="{hidden: false}">AppKey</ng-container>
              </clr-dg-column> -->
            <!-- <clr-dg-column [clrDgField]="'meter.createdAt'" [style.min-width.px]="150">
                  <ng-container *clrDgHideableColumn="{hidden: false}">Created At</ng-container>
              </clr-dg-column>
              <clr-dg-column [clrDgField]="'meter.modifiedAt'" [style.min-width.px]="150">
                  <ng-container *clrDgHideableColumn="{hidden: false}">Modified At</ng-container>
              </clr-dg-column> -->

            <clr-dg-row *clrDgItems="let item of lorawanMultiMetersInfo; let i = index" [clrDgItem]="item">
              <clr-dg-cell class="left">{{item.meter.id}}</clr-dg-cell>
              <clr-dg-cell class="left">{{item.meter.devEui}}</clr-dg-cell>
              <clr-dg-cell class="left">{{item.meter.name}}</clr-dg-cell>
              <!-- <clr-dg-cell class="left">{{item.meter.provisioned}}</clr-dg-cell> -->
              <clr-dg-cell class="left">{{dateHelper.formatByString(item.deviceInfo?.modifiedAt,
                'yyyy-MM-dd HH:mm:ss', '---')}}</clr-dg-cell>
              <clr-dg-cell class="left">{{dateHelper.formatByString(item.meter?.lastValueAt,
                'yyyy-MM-dd HH:mm:ss', '---')}}</clr-dg-cell>
              <!-- <clr-dg-cell class="left">{{item.meter.externalId}}</clr-dg-cell> -->
              <clr-dg-cell class="left">{{item.deviceInfo?.rssi}}</clr-dg-cell>
              <clr-dg-cell class="left">{{item.deviceInfo?.snr.toFixed(1)}}</clr-dg-cell>
              <clr-dg-cell class="left">{{item.deviceInfo?.spreadingfactor}}</clr-dg-cell>
              <clr-dg-cell class="left">{{item.deviceInfo?.fCnt}}</clr-dg-cell>
              <clr-dg-cell class="left">{{item.deviceInfo | bmslorawanmetersgroupbatterylevel }}</clr-dg-cell>
              <clr-dg-cell class="left">{{item.meter.multiMeterType}}</clr-dg-cell>
            </clr-dg-row>

            <clr-dg-footer>
              <clr-dg-pagination #pagination [clrDgPageSize]="20">
                <clr-dg-page-size [clrPageSizeOptions]="[10,20,50,100]">
                  Meters</clr-dg-page-size>
                {{pagination.firstItem + 1}} - {{pagination.lastItem + 1}} of {{pagination.totalItems}} meters
              </clr-dg-pagination>
            </clr-dg-footer>

          </clr-datagrid>

          <div class="customers-content-area" *ngIf="selectedMultiMeter">
            <xproj-dashboard #dashboardMultiMeter [editMode]="false" [showDashboardSettings]="false"
              [systemDashboard]="true" [dashboardId]="'bms_lorawan_multimeter'" [enableExport]="false"
              [dashboardTag]="'latest'" [responsiveWidth]="responsiveWidth"
              [dashboardOutputParameters]="meterParameters"></xproj-dashboard>
          </div>
        </div>
      </clr-tab-content>
    </ng-template>
  </clr-tab>
  <clr-tab>
    <button clrTabLink>Location</button>
    <ng-template [(clrIfActive)]="locationActive">
      <clr-tab-content>
        <div class="customers-content-area gateway-location">
          <button class="btn btn-secondary" (click)="updateGateway(true)">
            <clr-icon shape="refresh" directions="left"></clr-icon> Update location from NS
          </button>

          <xproj-dashboard #dashboardLocation *ngIf="!loadingGateway" [editMode]="false" [showDashboardSettings]="false"
            [systemDashboard]="true" [dashboardId]="'lorawan_gateway_map'" [enableExport]="false"
            [dashboardTag]="'latest'" [responsiveWidth]="responsiveWidth"
            [dashboardOutputParameters]="gatewayParameters"></xproj-dashboard>
        </div>
      </clr-tab-content>
    </ng-template>
  </clr-tab>
  <clr-tab>
    <button clrTabLink>Downlink</button>
    <ng-template [(clrIfActive)]="downlinkActive">
      <clr-tab-content>
        <div class="detail-area">
          <div class="card centered">
            <div class="card-header">Send downlink</div>
            <div class="card-block">
              <div class="card-text">
                <form clrForm>
                  <clr-select-container>
                    <label class="clr-col-12">Select downlink item:</label>
                    <select class="clr-col-12" clrSelect name="selectedDownlinkItem"
                      [(ngModel)]="selectedConfiguredDownlinkItem">
                      <option *ngFor="let item of configuredDownlinkItems" [ngValue]="item">{{item.name}}
                        ({{item.deviceType}})</option>
                    </select>
                  </clr-select-container>
                </form>
                <button class="btn btn-outline enqueueButton" [disabled]="!selectedConfiguredDownlinkItem"
                  (click)="enqueueDownlinkItem();">
                  <clr-icon shape="export"></clr-icon>
                  Enqueue downlink item...
                </button>
                <button class="btn btn-outline enqueueButton" [disabled]="!selectedConfiguredDownlinkItem"
                  (click)="showDownlinkInfos();">
                  <clr-icon shape="export"></clr-icon>
                  View downlink results
                </button>
              </div>
            </div>
          </div>
          <div class="card centered">
            <div class="card-header">Send downlink sequence</div>
            <div class="card-block">
              <div class="card-text">
                <form clrForm>
                  <clr-select-container>
                    <label class="clr-col-12">Select downlink sequence:</label>
                    <select class="clr-col-12" clrSelect name="selectedDownlinkSequence"
                      [(ngModel)]="selectedConfiguredDownlinkSequence" (ngModelChange)="downlinkSequenceChanged()">
                      <option *ngFor="let item of configuredDownlinkSequences" [ngValue]="item">{{item.name}}
                        ({{item.deviceType}})</option>
                    </select>
                  </clr-select-container>
                  <clr-input-container *ngIf="selectedConfiguredDownlinkSequence">
                    <label class="clr-col-4">Timeout (s):</label>
                    <input class="clr-col-8" clrInput type="number" min="0" max="999999999" placeholder=""
                      name="downlinkoverridetimeout" [(ngModel)]="downlinkOverrideTimeout" />
                  </clr-input-container>

                  <clr-toggle-container *ngIf="selectedConfiguredDownlinkSequence">
                    <clr-toggle-wrapper>
                      <input type="checkbox" clrToggle [(ngModel)]="queueAllEnabled"
                        name="queueallenabled" />
                      <label>Enqueue on all devices with the right devicetype.</label>
                    </clr-toggle-wrapper>
                  </clr-toggle-container>

                  <clr-input-container *ngIf="selectedConfiguredDownlinkSequence && !queueAllEnabled">
                    <label class="clr-col-4">Only devices failed after:</label>
                    <input class="clr-col-8" clrInput type="date" step="1" placeholder="" name="failedafter"
                      [(ngModel)]="downlinkFailedAfterString" />
                  </clr-input-container>
                  <clr-toggle-container *ngIf="selectedConfiguredDownlinkSequence">
                    <clr-toggle-wrapper>
                      <input type="checkbox" clrToggle [(ngModel)]="downlinkSendEmail"
                        name="downlinksendemail" />
                      <label>Send email when done.</label>
                    </clr-toggle-wrapper>
                  </clr-toggle-container>

                </form>
                <button class="btn btn-outline enqueueButton" [disabled]="!selectedConfiguredDownlinkSequence"
                  (click)="enqueueDownlinkSequence();">
                  <clr-icon shape="export"></clr-icon>
                  Enqueue downlink seq...
                </button>
                <button class="btn btn-outline enqueueButton" [disabled]="!selectedConfiguredDownlinkSequence"
                  (click)="showDownlinkSequenceInfos();">
                  <clr-icon shape="export"></clr-icon>
                  View downlink results
                </button>

              </div>
            </div>
          </div>
        </div>
      </clr-tab-content>
    </ng-template>
  </clr-tab>
</clr-tabs>
