import { Pipe, PipeTransform } from '@angular/core';
import { EventHistory } from '@core/xprojector_backend/proto/xprojector.modulebms.events.pb';

@Pipe({
  name: 'bmseventstatuspipe'
})
export class BmsEventStatusPipe implements PipeTransform {

  constructor() {}

  transform(e: EventHistory, ...args: unknown[]): string {

    return  e.muted ? 'Muted' : e.active ? (e.acknowledged ? 'Open' : 'New') : 'Closed';
  }

}
