/* tslint:disable */
/* eslint-disable */
// @ts-nocheck
//
// THIS IS A GENERATED FILE
// DO NOT MODIFY IT! YOUR CHANGES WILL BE LOST
import { Inject, Injectable, Optional } from '@angular/core';
import {
  GrpcCallType,
  GrpcClient,
  GrpcClientFactory,
  GrpcEvent,
  GrpcMetadata
} from '@ngx-grpc/common';
import {
  GRPC_CLIENT_FACTORY,
  GrpcHandler,
  takeMessages,
  throwStatusErrors
} from '@ngx-grpc/core';
import { Observable } from 'rxjs';
import * as thisProto from './xprojector.modulebms.lorawan.pb';
import * as googleProtobuf000 from './google/protobuf/empty.pb';
import * as googleProtobuf001 from './google/protobuf/timestamp.pb';
import { GRPC_LO_RA_WAN_CLIENT_SETTINGS } from './xprojector.modulebms.lorawan.pbconf';
/**
 * Service client implementation for modulebms.lorawan.LoRaWAN
 */
@Injectable({ providedIn: 'root' })
export class LoRaWANClient {
  private client: GrpcClient<any>;

  /**
   * Raw RPC implementation for each service client method.
   * The raw methods provide more control on the incoming data and events. E.g. they can be useful to read status `OK` metadata.
   * Attention: these methods do not throw errors when non-zero status codes are received.
   */
  $raw = {
    /**
     * Unary call: /modulebms.lorawan.LoRaWAN/GetOperators
     *
     * @param requestMessage Request message
     * @param requestMetadata Request metadata
     * @returns Observable<GrpcEvent<thisProto.GetOperatorsResponse>>
     */
    getOperators: (
      requestData: googleProtobuf000.Empty,
      requestMetadata = new GrpcMetadata()
    ): Observable<GrpcEvent<thisProto.GetOperatorsResponse>> => {
      return this.handler.handle({
        type: GrpcCallType.unary,
        client: this.client,
        path: '/modulebms.lorawan.LoRaWAN/GetOperators',
        requestData,
        requestMetadata,
        requestClass: googleProtobuf000.Empty,
        responseClass: thisProto.GetOperatorsResponse
      });
    },
    /**
     * Unary call: /modulebms.lorawan.LoRaWAN/GetApplications
     *
     * @param requestMessage Request message
     * @param requestMetadata Request metadata
     * @returns Observable<GrpcEvent<thisProto.GetApplicationsResponse>>
     */
    getApplications: (
      requestData: thisProto.GetApplicationsRequest,
      requestMetadata = new GrpcMetadata()
    ): Observable<GrpcEvent<thisProto.GetApplicationsResponse>> => {
      return this.handler.handle({
        type: GrpcCallType.unary,
        client: this.client,
        path: '/modulebms.lorawan.LoRaWAN/GetApplications',
        requestData,
        requestMetadata,
        requestClass: thisProto.GetApplicationsRequest,
        responseClass: thisProto.GetApplicationsResponse
      });
    },
    /**
     * Unary call: /modulebms.lorawan.LoRaWAN/GetConfiguredDownlinkItems
     *
     * @param requestMessage Request message
     * @param requestMetadata Request metadata
     * @returns Observable<GrpcEvent<thisProto.GetConfiguredDownlinkItemsResponse>>
     */
    getConfiguredDownlinkItems: (
      requestData: thisProto.GetConfiguredDownlinkItemsRequest,
      requestMetadata = new GrpcMetadata()
    ): Observable<GrpcEvent<thisProto.GetConfiguredDownlinkItemsResponse>> => {
      return this.handler.handle({
        type: GrpcCallType.unary,
        client: this.client,
        path: '/modulebms.lorawan.LoRaWAN/GetConfiguredDownlinkItems',
        requestData,
        requestMetadata,
        requestClass: thisProto.GetConfiguredDownlinkItemsRequest,
        responseClass: thisProto.GetConfiguredDownlinkItemsResponse
      });
    },
    /**
     * Unary call: /modulebms.lorawan.LoRaWAN/EnqueueDownlinkItem
     *
     * @param requestMessage Request message
     * @param requestMetadata Request metadata
     * @returns Observable<GrpcEvent<thisProto.EnqueueDownlinkItemResponse>>
     */
    enqueueDownlinkItem: (
      requestData: thisProto.EnqueueDownlinkItemRequest,
      requestMetadata = new GrpcMetadata()
    ): Observable<GrpcEvent<thisProto.EnqueueDownlinkItemResponse>> => {
      return this.handler.handle({
        type: GrpcCallType.unary,
        client: this.client,
        path: '/modulebms.lorawan.LoRaWAN/EnqueueDownlinkItem',
        requestData,
        requestMetadata,
        requestClass: thisProto.EnqueueDownlinkItemRequest,
        responseClass: thisProto.EnqueueDownlinkItemResponse
      });
    },
    /**
     * Unary call: /modulebms.lorawan.LoRaWAN/ClearDownlinkQueue
     *
     * @param requestMessage Request message
     * @param requestMetadata Request metadata
     * @returns Observable<GrpcEvent<thisProto.ClearDownlinkQueueResponse>>
     */
    clearDownlinkQueue: (
      requestData: thisProto.ClearDownlinkQueueRequest,
      requestMetadata = new GrpcMetadata()
    ): Observable<GrpcEvent<thisProto.ClearDownlinkQueueResponse>> => {
      return this.handler.handle({
        type: GrpcCallType.unary,
        client: this.client,
        path: '/modulebms.lorawan.LoRaWAN/ClearDownlinkQueue',
        requestData,
        requestMetadata,
        requestClass: thisProto.ClearDownlinkQueueRequest,
        responseClass: thisProto.ClearDownlinkQueueResponse
      });
    },
    /**
     * Unary call: /modulebms.lorawan.LoRaWAN/GetDownlinkItems
     *
     * @param requestMessage Request message
     * @param requestMetadata Request metadata
     * @returns Observable<GrpcEvent<thisProto.GetDownlinkItemsResponse>>
     */
    getDownlinkItems: (
      requestData: thisProto.GetDownlinkItemsRequest,
      requestMetadata = new GrpcMetadata()
    ): Observable<GrpcEvent<thisProto.GetDownlinkItemsResponse>> => {
      return this.handler.handle({
        type: GrpcCallType.unary,
        client: this.client,
        path: '/modulebms.lorawan.LoRaWAN/GetDownlinkItems',
        requestData,
        requestMetadata,
        requestClass: thisProto.GetDownlinkItemsRequest,
        responseClass: thisProto.GetDownlinkItemsResponse
      });
    },
    /**
     * Unary call: /modulebms.lorawan.LoRaWAN/GetGateway
     *
     * @param requestMessage Request message
     * @param requestMetadata Request metadata
     * @returns Observable<GrpcEvent<thisProto.GetGatewayResponse>>
     */
    getGateway: (
      requestData: thisProto.GetGatewayRequest,
      requestMetadata = new GrpcMetadata()
    ): Observable<GrpcEvent<thisProto.GetGatewayResponse>> => {
      return this.handler.handle({
        type: GrpcCallType.unary,
        client: this.client,
        path: '/modulebms.lorawan.LoRaWAN/GetGateway',
        requestData,
        requestMetadata,
        requestClass: thisProto.GetGatewayRequest,
        responseClass: thisProto.GetGatewayResponse
      });
    },
    /**
     * Unary call: /modulebms.lorawan.LoRaWAN/GetDevice
     *
     * @param requestMessage Request message
     * @param requestMetadata Request metadata
     * @returns Observable<GrpcEvent<thisProto.GetDeviceResponse>>
     */
    getDevice: (
      requestData: thisProto.GetDeviceRequest,
      requestMetadata = new GrpcMetadata()
    ): Observable<GrpcEvent<thisProto.GetDeviceResponse>> => {
      return this.handler.handle({
        type: GrpcCallType.unary,
        client: this.client,
        path: '/modulebms.lorawan.LoRaWAN/GetDevice',
        requestData,
        requestMetadata,
        requestClass: thisProto.GetDeviceRequest,
        responseClass: thisProto.GetDeviceResponse
      });
    },
    /**
     * Unary call: /modulebms.lorawan.LoRaWAN/UpdateDevice
     *
     * @param requestMessage Request message
     * @param requestMetadata Request metadata
     * @returns Observable<GrpcEvent<thisProto.UpdateDeviceResponse>>
     */
    updateDevice: (
      requestData: thisProto.UpdateDeviceRequest,
      requestMetadata = new GrpcMetadata()
    ): Observable<GrpcEvent<thisProto.UpdateDeviceResponse>> => {
      return this.handler.handle({
        type: GrpcCallType.unary,
        client: this.client,
        path: '/modulebms.lorawan.LoRaWAN/UpdateDevice',
        requestData,
        requestMetadata,
        requestClass: thisProto.UpdateDeviceRequest,
        responseClass: thisProto.UpdateDeviceResponse
      });
    },
    /**
     * Unary call: /modulebms.lorawan.LoRaWAN/GetConfiguredDownlinkSequences
     *
     * @param requestMessage Request message
     * @param requestMetadata Request metadata
     * @returns Observable<GrpcEvent<thisProto.GetConfiguredDownlinkSequencesResponse>>
     */
    getConfiguredDownlinkSequences: (
      requestData: thisProto.GetConfiguredDownlinkSequencesRequest,
      requestMetadata = new GrpcMetadata()
    ): Observable<
      GrpcEvent<thisProto.GetConfiguredDownlinkSequencesResponse>
    > => {
      return this.handler.handle({
        type: GrpcCallType.unary,
        client: this.client,
        path: '/modulebms.lorawan.LoRaWAN/GetConfiguredDownlinkSequences',
        requestData,
        requestMetadata,
        requestClass: thisProto.GetConfiguredDownlinkSequencesRequest,
        responseClass: thisProto.GetConfiguredDownlinkSequencesResponse
      });
    },
    /**
     * Unary call: /modulebms.lorawan.LoRaWAN/EnqueueDownlinkSequence
     *
     * @param requestMessage Request message
     * @param requestMetadata Request metadata
     * @returns Observable<GrpcEvent<thisProto.EnqueueDownlinkSequenceResponse>>
     */
    enqueueDownlinkSequence: (
      requestData: thisProto.EnqueueDownlinkSequenceRequest,
      requestMetadata = new GrpcMetadata()
    ): Observable<GrpcEvent<thisProto.EnqueueDownlinkSequenceResponse>> => {
      return this.handler.handle({
        type: GrpcCallType.unary,
        client: this.client,
        path: '/modulebms.lorawan.LoRaWAN/EnqueueDownlinkSequence',
        requestData,
        requestMetadata,
        requestClass: thisProto.EnqueueDownlinkSequenceRequest,
        responseClass: thisProto.EnqueueDownlinkSequenceResponse
      });
    },
    /**
     * Unary call: /modulebms.lorawan.LoRaWAN/GetDownlinkInfos
     *
     * @param requestMessage Request message
     * @param requestMetadata Request metadata
     * @returns Observable<GrpcEvent<thisProto.GetDownlinkInfosResponse>>
     */
    getDownlinkInfos: (
      requestData: thisProto.GetDownlinkInfosRequest,
      requestMetadata = new GrpcMetadata()
    ): Observable<GrpcEvent<thisProto.GetDownlinkInfosResponse>> => {
      return this.handler.handle({
        type: GrpcCallType.unary,
        client: this.client,
        path: '/modulebms.lorawan.LoRaWAN/GetDownlinkInfos',
        requestData,
        requestMetadata,
        requestClass: thisProto.GetDownlinkInfosRequest,
        responseClass: thisProto.GetDownlinkInfosResponse
      });
    },
    /**
     * Server streaming: /modulebms.lorawan.LoRaWAN/ExportDownlinkInfos
     *
     * @param requestMessage Request message
     * @param requestMetadata Request metadata
     * @returns Observable<GrpcEvent<thisProto.DataChunk>>
     */
    exportDownlinkInfos: (
      requestData: thisProto.ExportDownlinkInfosRequest,
      requestMetadata = new GrpcMetadata()
    ): Observable<GrpcEvent<thisProto.DataChunk>> => {
      return this.handler.handle({
        type: GrpcCallType.serverStream,
        client: this.client,
        path: '/modulebms.lorawan.LoRaWAN/ExportDownlinkInfos',
        requestData,
        requestMetadata,
        requestClass: thisProto.ExportDownlinkInfosRequest,
        responseClass: thisProto.DataChunk
      });
    }
  };

  constructor(
    @Optional() @Inject(GRPC_LO_RA_WAN_CLIENT_SETTINGS) settings: any,
    @Inject(GRPC_CLIENT_FACTORY) clientFactory: GrpcClientFactory<any>,
    private handler: GrpcHandler
  ) {
    this.client = clientFactory.createClient(
      'modulebms.lorawan.LoRaWAN',
      settings
    );
  }

  /**
   * Unary call @/modulebms.lorawan.LoRaWAN/GetOperators
   *
   * @param requestMessage Request message
   * @param requestMetadata Request metadata
   * @returns Observable<thisProto.GetOperatorsResponse>
   */
  getOperators(
    requestData: googleProtobuf000.Empty,
    requestMetadata = new GrpcMetadata()
  ): Observable<thisProto.GetOperatorsResponse> {
    return this.$raw
      .getOperators(requestData, requestMetadata)
      .pipe(throwStatusErrors(), takeMessages());
  }

  /**
   * Unary call @/modulebms.lorawan.LoRaWAN/GetApplications
   *
   * @param requestMessage Request message
   * @param requestMetadata Request metadata
   * @returns Observable<thisProto.GetApplicationsResponse>
   */
  getApplications(
    requestData: thisProto.GetApplicationsRequest,
    requestMetadata = new GrpcMetadata()
  ): Observable<thisProto.GetApplicationsResponse> {
    return this.$raw
      .getApplications(requestData, requestMetadata)
      .pipe(throwStatusErrors(), takeMessages());
  }

  /**
   * Unary call @/modulebms.lorawan.LoRaWAN/GetConfiguredDownlinkItems
   *
   * @param requestMessage Request message
   * @param requestMetadata Request metadata
   * @returns Observable<thisProto.GetConfiguredDownlinkItemsResponse>
   */
  getConfiguredDownlinkItems(
    requestData: thisProto.GetConfiguredDownlinkItemsRequest,
    requestMetadata = new GrpcMetadata()
  ): Observable<thisProto.GetConfiguredDownlinkItemsResponse> {
    return this.$raw
      .getConfiguredDownlinkItems(requestData, requestMetadata)
      .pipe(throwStatusErrors(), takeMessages());
  }

  /**
   * Unary call @/modulebms.lorawan.LoRaWAN/EnqueueDownlinkItem
   *
   * @param requestMessage Request message
   * @param requestMetadata Request metadata
   * @returns Observable<thisProto.EnqueueDownlinkItemResponse>
   */
  enqueueDownlinkItem(
    requestData: thisProto.EnqueueDownlinkItemRequest,
    requestMetadata = new GrpcMetadata()
  ): Observable<thisProto.EnqueueDownlinkItemResponse> {
    return this.$raw
      .enqueueDownlinkItem(requestData, requestMetadata)
      .pipe(throwStatusErrors(), takeMessages());
  }

  /**
   * Unary call @/modulebms.lorawan.LoRaWAN/ClearDownlinkQueue
   *
   * @param requestMessage Request message
   * @param requestMetadata Request metadata
   * @returns Observable<thisProto.ClearDownlinkQueueResponse>
   */
  clearDownlinkQueue(
    requestData: thisProto.ClearDownlinkQueueRequest,
    requestMetadata = new GrpcMetadata()
  ): Observable<thisProto.ClearDownlinkQueueResponse> {
    return this.$raw
      .clearDownlinkQueue(requestData, requestMetadata)
      .pipe(throwStatusErrors(), takeMessages());
  }

  /**
   * Unary call @/modulebms.lorawan.LoRaWAN/GetDownlinkItems
   *
   * @param requestMessage Request message
   * @param requestMetadata Request metadata
   * @returns Observable<thisProto.GetDownlinkItemsResponse>
   */
  getDownlinkItems(
    requestData: thisProto.GetDownlinkItemsRequest,
    requestMetadata = new GrpcMetadata()
  ): Observable<thisProto.GetDownlinkItemsResponse> {
    return this.$raw
      .getDownlinkItems(requestData, requestMetadata)
      .pipe(throwStatusErrors(), takeMessages());
  }

  /**
   * Unary call @/modulebms.lorawan.LoRaWAN/GetGateway
   *
   * @param requestMessage Request message
   * @param requestMetadata Request metadata
   * @returns Observable<thisProto.GetGatewayResponse>
   */
  getGateway(
    requestData: thisProto.GetGatewayRequest,
    requestMetadata = new GrpcMetadata()
  ): Observable<thisProto.GetGatewayResponse> {
    return this.$raw
      .getGateway(requestData, requestMetadata)
      .pipe(throwStatusErrors(), takeMessages());
  }

  /**
   * Unary call @/modulebms.lorawan.LoRaWAN/GetDevice
   *
   * @param requestMessage Request message
   * @param requestMetadata Request metadata
   * @returns Observable<thisProto.GetDeviceResponse>
   */
  getDevice(
    requestData: thisProto.GetDeviceRequest,
    requestMetadata = new GrpcMetadata()
  ): Observable<thisProto.GetDeviceResponse> {
    return this.$raw
      .getDevice(requestData, requestMetadata)
      .pipe(throwStatusErrors(), takeMessages());
  }

  /**
   * Unary call @/modulebms.lorawan.LoRaWAN/UpdateDevice
   *
   * @param requestMessage Request message
   * @param requestMetadata Request metadata
   * @returns Observable<thisProto.UpdateDeviceResponse>
   */
  updateDevice(
    requestData: thisProto.UpdateDeviceRequest,
    requestMetadata = new GrpcMetadata()
  ): Observable<thisProto.UpdateDeviceResponse> {
    return this.$raw
      .updateDevice(requestData, requestMetadata)
      .pipe(throwStatusErrors(), takeMessages());
  }

  /**
   * Unary call @/modulebms.lorawan.LoRaWAN/GetConfiguredDownlinkSequences
   *
   * @param requestMessage Request message
   * @param requestMetadata Request metadata
   * @returns Observable<thisProto.GetConfiguredDownlinkSequencesResponse>
   */
  getConfiguredDownlinkSequences(
    requestData: thisProto.GetConfiguredDownlinkSequencesRequest,
    requestMetadata = new GrpcMetadata()
  ): Observable<thisProto.GetConfiguredDownlinkSequencesResponse> {
    return this.$raw
      .getConfiguredDownlinkSequences(requestData, requestMetadata)
      .pipe(throwStatusErrors(), takeMessages());
  }

  /**
   * Unary call @/modulebms.lorawan.LoRaWAN/EnqueueDownlinkSequence
   *
   * @param requestMessage Request message
   * @param requestMetadata Request metadata
   * @returns Observable<thisProto.EnqueueDownlinkSequenceResponse>
   */
  enqueueDownlinkSequence(
    requestData: thisProto.EnqueueDownlinkSequenceRequest,
    requestMetadata = new GrpcMetadata()
  ): Observable<thisProto.EnqueueDownlinkSequenceResponse> {
    return this.$raw
      .enqueueDownlinkSequence(requestData, requestMetadata)
      .pipe(throwStatusErrors(), takeMessages());
  }

  /**
   * Unary call @/modulebms.lorawan.LoRaWAN/GetDownlinkInfos
   *
   * @param requestMessage Request message
   * @param requestMetadata Request metadata
   * @returns Observable<thisProto.GetDownlinkInfosResponse>
   */
  getDownlinkInfos(
    requestData: thisProto.GetDownlinkInfosRequest,
    requestMetadata = new GrpcMetadata()
  ): Observable<thisProto.GetDownlinkInfosResponse> {
    return this.$raw
      .getDownlinkInfos(requestData, requestMetadata)
      .pipe(throwStatusErrors(), takeMessages());
  }

  /**
   * Server streaming @/modulebms.lorawan.LoRaWAN/ExportDownlinkInfos
   *
   * @param requestMessage Request message
   * @param requestMetadata Request metadata
   * @returns Observable<thisProto.DataChunk>
   */
  exportDownlinkInfos(
    requestData: thisProto.ExportDownlinkInfosRequest,
    requestMetadata = new GrpcMetadata()
  ): Observable<thisProto.DataChunk> {
    return this.$raw
      .exportDownlinkInfos(requestData, requestMetadata)
      .pipe(throwStatusErrors(), takeMessages());
  }
}
