<clr-modal [(clrModalOpen)]="showAddCustomerModal" [clrModalClosable]="false">
  <h3 class="modal-title">Select customer</h3>
  <div class="modal-body">
    <form clrForm>
      <clr-select-container>
        <label>Customer</label>
        <select clrSelect name="addnewcustomerselect" [(ngModel)]="addNewCustomerId">
          <option *ngFor="let customer of filteredCustomers" [ngValue]="customer.customerId">
            {{customer.customerName}}
          </option>
        </select>
      </clr-select-container>
    </form>
  </div>
  <div class="modal-footer">
    <button type="button" class="btn btn-outline" (click)="showAddCustomerModal = false">Cancel</button>
    <button type="button" class="btn btn-primary" (click)="showAddCustomerModal = false; addCustomer()">Add</button>
  </div>
</clr-modal>

<div class="content-area">
  <div class="card" *ngIf="customerUser">
    <div class="card-header">
      External customers
    </div>
    <div class="card-block">
      <div class="limit-height">
        <clr-datagrid #grid class="meter-list" [(clrDgSelected)]="selectedCustomers"
        [clrDgLoading]="loadingCustomers" >

        <clr-dg-action-bar>
          <div class="btn-group">
            <button type="button" class="btn btn-sm btn-secondary" (click)="showAddCustomer()">
              <clr-icon shape="plus"></clr-icon> Add
            </button>
            <button type="button" class="btn btn-sm btn-danger-outline" (click)="removeSelectedCustomers()"
              [disabled]="selectedCustomers.length == 0">
              <clr-icon shape="trash"></clr-icon> Remove ({{selectedCustomers.length}})
            </button>
          </div>
        </clr-dg-action-bar>

        <clr-dg-column>
          <ng-container>
            External Customers
          </ng-container>
        </clr-dg-column>

        <clr-dg-row *ngFor="let customer of externalCustomers ; index as i" [clrDgItem]="i">
          <clr-dg-cell class="sensitive"> {{customer.name}}
          </clr-dg-cell>
        </clr-dg-row>

      </clr-datagrid>
      </div>
    </div>
  </div>
</div>
