/* tslint:disable */
/* eslint-disable */
// @ts-nocheck
//
// THIS IS A GENERATED FILE
// DO NOT MODIFY IT! YOUR CHANGES WILL BE LOST
import {
  GrpcMessage,
  RecursivePartial,
  ToProtobufJSONOptions
} from '@ngx-grpc/common';
import { BinaryReader, BinaryWriter, ByteSource } from 'google-protobuf';
import * as googleProtobuf000 from './google/protobuf/timestamp.pb';
/**
 * Message implementation for module.thingsboard.DeviceGroup
 */
export class DeviceGroup implements GrpcMessage {
  static id = 'module.thingsboard.DeviceGroup';

  /**
   * Deserialize binary data to message
   * @param instance message instance
   */
  static deserializeBinary(bytes: ByteSource) {
    const instance = new DeviceGroup();
    DeviceGroup.deserializeBinaryFromReader(instance, new BinaryReader(bytes));
    return instance;
  }

  /**
   * Check all the properties and set default protobuf values if necessary
   * @param _instance message instance
   */
  static refineValues(_instance: DeviceGroup) {
    _instance.id = _instance.id || '';
    _instance.name = _instance.name || '';
  }

  /**
   * Deserializes / reads binary message into message instance using provided binary reader
   * @param _instance message instance
   * @param _reader binary reader instance
   */
  static deserializeBinaryFromReader(
    _instance: DeviceGroup,
    _reader: BinaryReader
  ) {
    while (_reader.nextField()) {
      if (_reader.isEndGroup()) break;

      switch (_reader.getFieldNumber()) {
        case 1:
          _instance.id = _reader.readString();
          break;
        case 2:
          _instance.name = _reader.readString();
          break;
        default:
          _reader.skipField();
      }
    }

    DeviceGroup.refineValues(_instance);
  }

  /**
   * Serializes a message to binary format using provided binary reader
   * @param _instance message instance
   * @param _writer binary writer instance
   */
  static serializeBinaryToWriter(
    _instance: DeviceGroup,
    _writer: BinaryWriter
  ) {
    if (_instance.id) {
      _writer.writeString(1, _instance.id);
    }
    if (_instance.name) {
      _writer.writeString(2, _instance.name);
    }
  }

  private _id?: string;
  private _name?: string;

  /**
   * Message constructor. Initializes the properties and applies default Protobuf values if necessary
   * @param _value initial values object or instance of DeviceGroup to deeply clone from
   */
  constructor(_value?: RecursivePartial<DeviceGroup.AsObject>) {
    _value = _value || {};
    this.id = _value.id;
    this.name = _value.name;
    DeviceGroup.refineValues(this);
  }
  get id(): string | undefined {
    return this._id;
  }
  set id(value: string | undefined) {
    this._id = value;
  }
  get name(): string | undefined {
    return this._name;
  }
  set name(value: string | undefined) {
    this._name = value;
  }

  /**
   * Serialize message to binary data
   * @param instance message instance
   */
  serializeBinary() {
    const writer = new BinaryWriter();
    DeviceGroup.serializeBinaryToWriter(this, writer);
    return writer.getResultBuffer();
  }

  /**
   * Cast message to standard JavaScript object (all non-primitive values are deeply cloned)
   */
  toObject(): DeviceGroup.AsObject {
    return {
      id: this.id,
      name: this.name
    };
  }

  /**
   * Convenience method to support JSON.stringify(message), replicates the structure of toObject()
   */
  toJSON() {
    return this.toObject();
  }

  /**
   * Cast message to JSON using protobuf JSON notation: https://developers.google.com/protocol-buffers/docs/proto3#json
   * Attention: output differs from toObject() e.g. enums are represented as names and not as numbers, Timestamp is an ISO Date string format etc.
   * If the message itself or some of descendant messages is google.protobuf.Any, you MUST provide a message pool as options. If not, the messagePool is not required
   */
  toProtobufJSON(
    // @ts-ignore
    options?: ToProtobufJSONOptions
  ): DeviceGroup.AsProtobufJSON {
    return {
      id: this.id,
      name: this.name
    };
  }
}
export module DeviceGroup {
  /**
   * Standard JavaScript object representation for DeviceGroup
   */
  export interface AsObject {
    id?: string;
    name?: string;
  }

  /**
   * Protobuf JSON representation for DeviceGroup
   */
  export interface AsProtobufJSON {
    id?: string;
    name?: string;
  }
}

/**
 * Message implementation for module.thingsboard.Device
 */
export class Device implements GrpcMessage {
  static id = 'module.thingsboard.Device';

  /**
   * Deserialize binary data to message
   * @param instance message instance
   */
  static deserializeBinary(bytes: ByteSource) {
    const instance = new Device();
    Device.deserializeBinaryFromReader(instance, new BinaryReader(bytes));
    return instance;
  }

  /**
   * Check all the properties and set default protobuf values if necessary
   * @param _instance message instance
   */
  static refineValues(_instance: Device) {
    _instance.id = _instance.id || '';
    _instance.name = _instance.name || '';
    _instance.label = _instance.label || '';
    _instance.deviceType = _instance.deviceType || '';
  }

  /**
   * Deserializes / reads binary message into message instance using provided binary reader
   * @param _instance message instance
   * @param _reader binary reader instance
   */
  static deserializeBinaryFromReader(_instance: Device, _reader: BinaryReader) {
    while (_reader.nextField()) {
      if (_reader.isEndGroup()) break;

      switch (_reader.getFieldNumber()) {
        case 1:
          _instance.id = _reader.readString();
          break;
        case 2:
          _instance.name = _reader.readString();
          break;
        case 3:
          _instance.label = _reader.readString();
          break;
        case 4:
          _instance.deviceType = _reader.readString();
          break;
        default:
          _reader.skipField();
      }
    }

    Device.refineValues(_instance);
  }

  /**
   * Serializes a message to binary format using provided binary reader
   * @param _instance message instance
   * @param _writer binary writer instance
   */
  static serializeBinaryToWriter(_instance: Device, _writer: BinaryWriter) {
    if (_instance.id) {
      _writer.writeString(1, _instance.id);
    }
    if (_instance.name) {
      _writer.writeString(2, _instance.name);
    }
    if (_instance.label) {
      _writer.writeString(3, _instance.label);
    }
    if (_instance.deviceType) {
      _writer.writeString(4, _instance.deviceType);
    }
  }

  private _id?: string;
  private _name?: string;
  private _label?: string;
  private _deviceType?: string;

  /**
   * Message constructor. Initializes the properties and applies default Protobuf values if necessary
   * @param _value initial values object or instance of Device to deeply clone from
   */
  constructor(_value?: RecursivePartial<Device.AsObject>) {
    _value = _value || {};
    this.id = _value.id;
    this.name = _value.name;
    this.label = _value.label;
    this.deviceType = _value.deviceType;
    Device.refineValues(this);
  }
  get id(): string | undefined {
    return this._id;
  }
  set id(value: string | undefined) {
    this._id = value;
  }
  get name(): string | undefined {
    return this._name;
  }
  set name(value: string | undefined) {
    this._name = value;
  }
  get label(): string | undefined {
    return this._label;
  }
  set label(value: string | undefined) {
    this._label = value;
  }
  get deviceType(): string | undefined {
    return this._deviceType;
  }
  set deviceType(value: string | undefined) {
    this._deviceType = value;
  }

  /**
   * Serialize message to binary data
   * @param instance message instance
   */
  serializeBinary() {
    const writer = new BinaryWriter();
    Device.serializeBinaryToWriter(this, writer);
    return writer.getResultBuffer();
  }

  /**
   * Cast message to standard JavaScript object (all non-primitive values are deeply cloned)
   */
  toObject(): Device.AsObject {
    return {
      id: this.id,
      name: this.name,
      label: this.label,
      deviceType: this.deviceType
    };
  }

  /**
   * Convenience method to support JSON.stringify(message), replicates the structure of toObject()
   */
  toJSON() {
    return this.toObject();
  }

  /**
   * Cast message to JSON using protobuf JSON notation: https://developers.google.com/protocol-buffers/docs/proto3#json
   * Attention: output differs from toObject() e.g. enums are represented as names and not as numbers, Timestamp is an ISO Date string format etc.
   * If the message itself or some of descendant messages is google.protobuf.Any, you MUST provide a message pool as options. If not, the messagePool is not required
   */
  toProtobufJSON(
    // @ts-ignore
    options?: ToProtobufJSONOptions
  ): Device.AsProtobufJSON {
    return {
      id: this.id,
      name: this.name,
      label: this.label,
      deviceType: this.deviceType
    };
  }
}
export module Device {
  /**
   * Standard JavaScript object representation for Device
   */
  export interface AsObject {
    id?: string;
    name?: string;
    label?: string;
    deviceType?: string;
  }

  /**
   * Protobuf JSON representation for Device
   */
  export interface AsProtobufJSON {
    id?: string;
    name?: string;
    label?: string;
    deviceType?: string;
  }
}

/**
 * Message implementation for module.thingsboard.GetDeviceGroupsRequest
 */
export class GetDeviceGroupsRequest implements GrpcMessage {
  static id = 'module.thingsboard.GetDeviceGroupsRequest';

  /**
   * Deserialize binary data to message
   * @param instance message instance
   */
  static deserializeBinary(bytes: ByteSource) {
    const instance = new GetDeviceGroupsRequest();
    GetDeviceGroupsRequest.deserializeBinaryFromReader(
      instance,
      new BinaryReader(bytes)
    );
    return instance;
  }

  /**
   * Check all the properties and set default protobuf values if necessary
   * @param _instance message instance
   */
  static refineValues(_instance: GetDeviceGroupsRequest) {
    _instance.customerId = _instance.customerId || '';
  }

  /**
   * Deserializes / reads binary message into message instance using provided binary reader
   * @param _instance message instance
   * @param _reader binary reader instance
   */
  static deserializeBinaryFromReader(
    _instance: GetDeviceGroupsRequest,
    _reader: BinaryReader
  ) {
    while (_reader.nextField()) {
      if (_reader.isEndGroup()) break;

      switch (_reader.getFieldNumber()) {
        case 1:
          _instance.customerId = _reader.readString();
          break;
        default:
          _reader.skipField();
      }
    }

    GetDeviceGroupsRequest.refineValues(_instance);
  }

  /**
   * Serializes a message to binary format using provided binary reader
   * @param _instance message instance
   * @param _writer binary writer instance
   */
  static serializeBinaryToWriter(
    _instance: GetDeviceGroupsRequest,
    _writer: BinaryWriter
  ) {
    if (_instance.customerId) {
      _writer.writeString(1, _instance.customerId);
    }
  }

  private _customerId?: string;

  /**
   * Message constructor. Initializes the properties and applies default Protobuf values if necessary
   * @param _value initial values object or instance of GetDeviceGroupsRequest to deeply clone from
   */
  constructor(_value?: RecursivePartial<GetDeviceGroupsRequest.AsObject>) {
    _value = _value || {};
    this.customerId = _value.customerId;
    GetDeviceGroupsRequest.refineValues(this);
  }
  get customerId(): string | undefined {
    return this._customerId;
  }
  set customerId(value: string | undefined) {
    this._customerId = value;
  }

  /**
   * Serialize message to binary data
   * @param instance message instance
   */
  serializeBinary() {
    const writer = new BinaryWriter();
    GetDeviceGroupsRequest.serializeBinaryToWriter(this, writer);
    return writer.getResultBuffer();
  }

  /**
   * Cast message to standard JavaScript object (all non-primitive values are deeply cloned)
   */
  toObject(): GetDeviceGroupsRequest.AsObject {
    return {
      customerId: this.customerId
    };
  }

  /**
   * Convenience method to support JSON.stringify(message), replicates the structure of toObject()
   */
  toJSON() {
    return this.toObject();
  }

  /**
   * Cast message to JSON using protobuf JSON notation: https://developers.google.com/protocol-buffers/docs/proto3#json
   * Attention: output differs from toObject() e.g. enums are represented as names and not as numbers, Timestamp is an ISO Date string format etc.
   * If the message itself or some of descendant messages is google.protobuf.Any, you MUST provide a message pool as options. If not, the messagePool is not required
   */
  toProtobufJSON(
    // @ts-ignore
    options?: ToProtobufJSONOptions
  ): GetDeviceGroupsRequest.AsProtobufJSON {
    return {
      customerId: this.customerId
    };
  }
}
export module GetDeviceGroupsRequest {
  /**
   * Standard JavaScript object representation for GetDeviceGroupsRequest
   */
  export interface AsObject {
    customerId?: string;
  }

  /**
   * Protobuf JSON representation for GetDeviceGroupsRequest
   */
  export interface AsProtobufJSON {
    customerId?: string;
  }
}

/**
 * Message implementation for module.thingsboard.GetDeviceGroupsResponse
 */
export class GetDeviceGroupsResponse implements GrpcMessage {
  static id = 'module.thingsboard.GetDeviceGroupsResponse';

  /**
   * Deserialize binary data to message
   * @param instance message instance
   */
  static deserializeBinary(bytes: ByteSource) {
    const instance = new GetDeviceGroupsResponse();
    GetDeviceGroupsResponse.deserializeBinaryFromReader(
      instance,
      new BinaryReader(bytes)
    );
    return instance;
  }

  /**
   * Check all the properties and set default protobuf values if necessary
   * @param _instance message instance
   */
  static refineValues(_instance: GetDeviceGroupsResponse) {
    _instance.deviceGroups = _instance.deviceGroups || [];
  }

  /**
   * Deserializes / reads binary message into message instance using provided binary reader
   * @param _instance message instance
   * @param _reader binary reader instance
   */
  static deserializeBinaryFromReader(
    _instance: GetDeviceGroupsResponse,
    _reader: BinaryReader
  ) {
    while (_reader.nextField()) {
      if (_reader.isEndGroup()) break;

      switch (_reader.getFieldNumber()) {
        case 1:
          const messageInitializer1 = new DeviceGroup();
          _reader.readMessage(
            messageInitializer1,
            DeviceGroup.deserializeBinaryFromReader
          );
          (_instance.deviceGroups = _instance.deviceGroups || []).push(
            messageInitializer1
          );
          break;
        default:
          _reader.skipField();
      }
    }

    GetDeviceGroupsResponse.refineValues(_instance);
  }

  /**
   * Serializes a message to binary format using provided binary reader
   * @param _instance message instance
   * @param _writer binary writer instance
   */
  static serializeBinaryToWriter(
    _instance: GetDeviceGroupsResponse,
    _writer: BinaryWriter
  ) {
    if (_instance.deviceGroups && _instance.deviceGroups.length) {
      _writer.writeRepeatedMessage(
        1,
        _instance.deviceGroups as any,
        DeviceGroup.serializeBinaryToWriter
      );
    }
  }

  private _deviceGroups?: DeviceGroup[];

  /**
   * Message constructor. Initializes the properties and applies default Protobuf values if necessary
   * @param _value initial values object or instance of GetDeviceGroupsResponse to deeply clone from
   */
  constructor(_value?: RecursivePartial<GetDeviceGroupsResponse.AsObject>) {
    _value = _value || {};
    this.deviceGroups = (_value.deviceGroups || []).map(
      m => new DeviceGroup(m)
    );
    GetDeviceGroupsResponse.refineValues(this);
  }
  get deviceGroups(): DeviceGroup[] | undefined {
    return this._deviceGroups;
  }
  set deviceGroups(value: DeviceGroup[] | undefined) {
    this._deviceGroups = value;
  }

  /**
   * Serialize message to binary data
   * @param instance message instance
   */
  serializeBinary() {
    const writer = new BinaryWriter();
    GetDeviceGroupsResponse.serializeBinaryToWriter(this, writer);
    return writer.getResultBuffer();
  }

  /**
   * Cast message to standard JavaScript object (all non-primitive values are deeply cloned)
   */
  toObject(): GetDeviceGroupsResponse.AsObject {
    return {
      deviceGroups: (this.deviceGroups || []).map(m => m.toObject())
    };
  }

  /**
   * Convenience method to support JSON.stringify(message), replicates the structure of toObject()
   */
  toJSON() {
    return this.toObject();
  }

  /**
   * Cast message to JSON using protobuf JSON notation: https://developers.google.com/protocol-buffers/docs/proto3#json
   * Attention: output differs from toObject() e.g. enums are represented as names and not as numbers, Timestamp is an ISO Date string format etc.
   * If the message itself or some of descendant messages is google.protobuf.Any, you MUST provide a message pool as options. If not, the messagePool is not required
   */
  toProtobufJSON(
    // @ts-ignore
    options?: ToProtobufJSONOptions
  ): GetDeviceGroupsResponse.AsProtobufJSON {
    return {
      deviceGroups: (this.deviceGroups || []).map(m =>
        m.toProtobufJSON(options)
      )
    };
  }
}
export module GetDeviceGroupsResponse {
  /**
   * Standard JavaScript object representation for GetDeviceGroupsResponse
   */
  export interface AsObject {
    deviceGroups?: DeviceGroup.AsObject[];
  }

  /**
   * Protobuf JSON representation for GetDeviceGroupsResponse
   */
  export interface AsProtobufJSON {
    deviceGroups?: DeviceGroup.AsProtobufJSON[] | null;
  }
}

/**
 * Message implementation for module.thingsboard.GetDevicesRequest
 */
export class GetDevicesRequest implements GrpcMessage {
  static id = 'module.thingsboard.GetDevicesRequest';

  /**
   * Deserialize binary data to message
   * @param instance message instance
   */
  static deserializeBinary(bytes: ByteSource) {
    const instance = new GetDevicesRequest();
    GetDevicesRequest.deserializeBinaryFromReader(
      instance,
      new BinaryReader(bytes)
    );
    return instance;
  }

  /**
   * Check all the properties and set default protobuf values if necessary
   * @param _instance message instance
   */
  static refineValues(_instance: GetDevicesRequest) {
    _instance.deviceGroupId = _instance.deviceGroupId || '';
  }

  /**
   * Deserializes / reads binary message into message instance using provided binary reader
   * @param _instance message instance
   * @param _reader binary reader instance
   */
  static deserializeBinaryFromReader(
    _instance: GetDevicesRequest,
    _reader: BinaryReader
  ) {
    while (_reader.nextField()) {
      if (_reader.isEndGroup()) break;

      switch (_reader.getFieldNumber()) {
        case 1:
          _instance.deviceGroupId = _reader.readString();
          break;
        default:
          _reader.skipField();
      }
    }

    GetDevicesRequest.refineValues(_instance);
  }

  /**
   * Serializes a message to binary format using provided binary reader
   * @param _instance message instance
   * @param _writer binary writer instance
   */
  static serializeBinaryToWriter(
    _instance: GetDevicesRequest,
    _writer: BinaryWriter
  ) {
    if (_instance.deviceGroupId) {
      _writer.writeString(1, _instance.deviceGroupId);
    }
  }

  private _deviceGroupId?: string;

  /**
   * Message constructor. Initializes the properties and applies default Protobuf values if necessary
   * @param _value initial values object or instance of GetDevicesRequest to deeply clone from
   */
  constructor(_value?: RecursivePartial<GetDevicesRequest.AsObject>) {
    _value = _value || {};
    this.deviceGroupId = _value.deviceGroupId;
    GetDevicesRequest.refineValues(this);
  }
  get deviceGroupId(): string | undefined {
    return this._deviceGroupId;
  }
  set deviceGroupId(value: string | undefined) {
    this._deviceGroupId = value;
  }

  /**
   * Serialize message to binary data
   * @param instance message instance
   */
  serializeBinary() {
    const writer = new BinaryWriter();
    GetDevicesRequest.serializeBinaryToWriter(this, writer);
    return writer.getResultBuffer();
  }

  /**
   * Cast message to standard JavaScript object (all non-primitive values are deeply cloned)
   */
  toObject(): GetDevicesRequest.AsObject {
    return {
      deviceGroupId: this.deviceGroupId
    };
  }

  /**
   * Convenience method to support JSON.stringify(message), replicates the structure of toObject()
   */
  toJSON() {
    return this.toObject();
  }

  /**
   * Cast message to JSON using protobuf JSON notation: https://developers.google.com/protocol-buffers/docs/proto3#json
   * Attention: output differs from toObject() e.g. enums are represented as names and not as numbers, Timestamp is an ISO Date string format etc.
   * If the message itself or some of descendant messages is google.protobuf.Any, you MUST provide a message pool as options. If not, the messagePool is not required
   */
  toProtobufJSON(
    // @ts-ignore
    options?: ToProtobufJSONOptions
  ): GetDevicesRequest.AsProtobufJSON {
    return {
      deviceGroupId: this.deviceGroupId
    };
  }
}
export module GetDevicesRequest {
  /**
   * Standard JavaScript object representation for GetDevicesRequest
   */
  export interface AsObject {
    deviceGroupId?: string;
  }

  /**
   * Protobuf JSON representation for GetDevicesRequest
   */
  export interface AsProtobufJSON {
    deviceGroupId?: string;
  }
}

/**
 * Message implementation for module.thingsboard.GetDevicesResponse
 */
export class GetDevicesResponse implements GrpcMessage {
  static id = 'module.thingsboard.GetDevicesResponse';

  /**
   * Deserialize binary data to message
   * @param instance message instance
   */
  static deserializeBinary(bytes: ByteSource) {
    const instance = new GetDevicesResponse();
    GetDevicesResponse.deserializeBinaryFromReader(
      instance,
      new BinaryReader(bytes)
    );
    return instance;
  }

  /**
   * Check all the properties and set default protobuf values if necessary
   * @param _instance message instance
   */
  static refineValues(_instance: GetDevicesResponse) {
    _instance.devices = _instance.devices || [];
  }

  /**
   * Deserializes / reads binary message into message instance using provided binary reader
   * @param _instance message instance
   * @param _reader binary reader instance
   */
  static deserializeBinaryFromReader(
    _instance: GetDevicesResponse,
    _reader: BinaryReader
  ) {
    while (_reader.nextField()) {
      if (_reader.isEndGroup()) break;

      switch (_reader.getFieldNumber()) {
        case 1:
          const messageInitializer1 = new Device();
          _reader.readMessage(
            messageInitializer1,
            Device.deserializeBinaryFromReader
          );
          (_instance.devices = _instance.devices || []).push(
            messageInitializer1
          );
          break;
        default:
          _reader.skipField();
      }
    }

    GetDevicesResponse.refineValues(_instance);
  }

  /**
   * Serializes a message to binary format using provided binary reader
   * @param _instance message instance
   * @param _writer binary writer instance
   */
  static serializeBinaryToWriter(
    _instance: GetDevicesResponse,
    _writer: BinaryWriter
  ) {
    if (_instance.devices && _instance.devices.length) {
      _writer.writeRepeatedMessage(
        1,
        _instance.devices as any,
        Device.serializeBinaryToWriter
      );
    }
  }

  private _devices?: Device[];

  /**
   * Message constructor. Initializes the properties and applies default Protobuf values if necessary
   * @param _value initial values object or instance of GetDevicesResponse to deeply clone from
   */
  constructor(_value?: RecursivePartial<GetDevicesResponse.AsObject>) {
    _value = _value || {};
    this.devices = (_value.devices || []).map(m => new Device(m));
    GetDevicesResponse.refineValues(this);
  }
  get devices(): Device[] | undefined {
    return this._devices;
  }
  set devices(value: Device[] | undefined) {
    this._devices = value;
  }

  /**
   * Serialize message to binary data
   * @param instance message instance
   */
  serializeBinary() {
    const writer = new BinaryWriter();
    GetDevicesResponse.serializeBinaryToWriter(this, writer);
    return writer.getResultBuffer();
  }

  /**
   * Cast message to standard JavaScript object (all non-primitive values are deeply cloned)
   */
  toObject(): GetDevicesResponse.AsObject {
    return {
      devices: (this.devices || []).map(m => m.toObject())
    };
  }

  /**
   * Convenience method to support JSON.stringify(message), replicates the structure of toObject()
   */
  toJSON() {
    return this.toObject();
  }

  /**
   * Cast message to JSON using protobuf JSON notation: https://developers.google.com/protocol-buffers/docs/proto3#json
   * Attention: output differs from toObject() e.g. enums are represented as names and not as numbers, Timestamp is an ISO Date string format etc.
   * If the message itself or some of descendant messages is google.protobuf.Any, you MUST provide a message pool as options. If not, the messagePool is not required
   */
  toProtobufJSON(
    // @ts-ignore
    options?: ToProtobufJSONOptions
  ): GetDevicesResponse.AsProtobufJSON {
    return {
      devices: (this.devices || []).map(m => m.toProtobufJSON(options))
    };
  }
}
export module GetDevicesResponse {
  /**
   * Standard JavaScript object representation for GetDevicesResponse
   */
  export interface AsObject {
    devices?: Device.AsObject[];
  }

  /**
   * Protobuf JSON representation for GetDevicesResponse
   */
  export interface AsProtobufJSON {
    devices?: Device.AsProtobufJSON[] | null;
  }
}

/**
 * Message implementation for module.thingsboard.UpsertDeviceGroupRequest
 */
export class UpsertDeviceGroupRequest implements GrpcMessage {
  static id = 'module.thingsboard.UpsertDeviceGroupRequest';

  /**
   * Deserialize binary data to message
   * @param instance message instance
   */
  static deserializeBinary(bytes: ByteSource) {
    const instance = new UpsertDeviceGroupRequest();
    UpsertDeviceGroupRequest.deserializeBinaryFromReader(
      instance,
      new BinaryReader(bytes)
    );
    return instance;
  }

  /**
   * Check all the properties and set default protobuf values if necessary
   * @param _instance message instance
   */
  static refineValues(_instance: UpsertDeviceGroupRequest) {
    _instance.customerId = _instance.customerId || '';
    _instance.deviceGroupId = _instance.deviceGroupId || '';
  }

  /**
   * Deserializes / reads binary message into message instance using provided binary reader
   * @param _instance message instance
   * @param _reader binary reader instance
   */
  static deserializeBinaryFromReader(
    _instance: UpsertDeviceGroupRequest,
    _reader: BinaryReader
  ) {
    while (_reader.nextField()) {
      if (_reader.isEndGroup()) break;

      switch (_reader.getFieldNumber()) {
        case 1:
          _instance.customerId = _reader.readString();
          break;
        case 2:
          _instance.deviceGroupId = _reader.readString();
          break;
        default:
          _reader.skipField();
      }
    }

    UpsertDeviceGroupRequest.refineValues(_instance);
  }

  /**
   * Serializes a message to binary format using provided binary reader
   * @param _instance message instance
   * @param _writer binary writer instance
   */
  static serializeBinaryToWriter(
    _instance: UpsertDeviceGroupRequest,
    _writer: BinaryWriter
  ) {
    if (_instance.customerId) {
      _writer.writeString(1, _instance.customerId);
    }
    if (_instance.deviceGroupId) {
      _writer.writeString(2, _instance.deviceGroupId);
    }
  }

  private _customerId?: string;
  private _deviceGroupId?: string;

  /**
   * Message constructor. Initializes the properties and applies default Protobuf values if necessary
   * @param _value initial values object or instance of UpsertDeviceGroupRequest to deeply clone from
   */
  constructor(_value?: RecursivePartial<UpsertDeviceGroupRequest.AsObject>) {
    _value = _value || {};
    this.customerId = _value.customerId;
    this.deviceGroupId = _value.deviceGroupId;
    UpsertDeviceGroupRequest.refineValues(this);
  }
  get customerId(): string | undefined {
    return this._customerId;
  }
  set customerId(value: string | undefined) {
    this._customerId = value;
  }
  get deviceGroupId(): string | undefined {
    return this._deviceGroupId;
  }
  set deviceGroupId(value: string | undefined) {
    this._deviceGroupId = value;
  }

  /**
   * Serialize message to binary data
   * @param instance message instance
   */
  serializeBinary() {
    const writer = new BinaryWriter();
    UpsertDeviceGroupRequest.serializeBinaryToWriter(this, writer);
    return writer.getResultBuffer();
  }

  /**
   * Cast message to standard JavaScript object (all non-primitive values are deeply cloned)
   */
  toObject(): UpsertDeviceGroupRequest.AsObject {
    return {
      customerId: this.customerId,
      deviceGroupId: this.deviceGroupId
    };
  }

  /**
   * Convenience method to support JSON.stringify(message), replicates the structure of toObject()
   */
  toJSON() {
    return this.toObject();
  }

  /**
   * Cast message to JSON using protobuf JSON notation: https://developers.google.com/protocol-buffers/docs/proto3#json
   * Attention: output differs from toObject() e.g. enums are represented as names and not as numbers, Timestamp is an ISO Date string format etc.
   * If the message itself or some of descendant messages is google.protobuf.Any, you MUST provide a message pool as options. If not, the messagePool is not required
   */
  toProtobufJSON(
    // @ts-ignore
    options?: ToProtobufJSONOptions
  ): UpsertDeviceGroupRequest.AsProtobufJSON {
    return {
      customerId: this.customerId,
      deviceGroupId: this.deviceGroupId
    };
  }
}
export module UpsertDeviceGroupRequest {
  /**
   * Standard JavaScript object representation for UpsertDeviceGroupRequest
   */
  export interface AsObject {
    customerId?: string;
    deviceGroupId?: string;
  }

  /**
   * Protobuf JSON representation for UpsertDeviceGroupRequest
   */
  export interface AsProtobufJSON {
    customerId?: string;
    deviceGroupId?: string;
  }
}

/**
 * Message implementation for module.thingsboard.UpsertDeviceGroupResponse
 */
export class UpsertDeviceGroupResponse implements GrpcMessage {
  static id = 'module.thingsboard.UpsertDeviceGroupResponse';

  /**
   * Deserialize binary data to message
   * @param instance message instance
   */
  static deserializeBinary(bytes: ByteSource) {
    const instance = new UpsertDeviceGroupResponse();
    UpsertDeviceGroupResponse.deserializeBinaryFromReader(
      instance,
      new BinaryReader(bytes)
    );
    return instance;
  }

  /**
   * Check all the properties and set default protobuf values if necessary
   * @param _instance message instance
   */
  static refineValues(_instance: UpsertDeviceGroupResponse) {
    _instance.ok = _instance.ok || false;
    _instance.message = _instance.message || '';
    _instance.deviceCount = _instance.deviceCount || 0;
  }

  /**
   * Deserializes / reads binary message into message instance using provided binary reader
   * @param _instance message instance
   * @param _reader binary reader instance
   */
  static deserializeBinaryFromReader(
    _instance: UpsertDeviceGroupResponse,
    _reader: BinaryReader
  ) {
    while (_reader.nextField()) {
      if (_reader.isEndGroup()) break;

      switch (_reader.getFieldNumber()) {
        case 1:
          _instance.ok = _reader.readBool();
          break;
        case 2:
          _instance.message = _reader.readString();
          break;
        case 3:
          _instance.deviceCount = _reader.readInt32();
          break;
        default:
          _reader.skipField();
      }
    }

    UpsertDeviceGroupResponse.refineValues(_instance);
  }

  /**
   * Serializes a message to binary format using provided binary reader
   * @param _instance message instance
   * @param _writer binary writer instance
   */
  static serializeBinaryToWriter(
    _instance: UpsertDeviceGroupResponse,
    _writer: BinaryWriter
  ) {
    if (_instance.ok) {
      _writer.writeBool(1, _instance.ok);
    }
    if (_instance.message) {
      _writer.writeString(2, _instance.message);
    }
    if (_instance.deviceCount) {
      _writer.writeInt32(3, _instance.deviceCount);
    }
  }

  private _ok?: boolean;
  private _message?: string;
  private _deviceCount?: number;

  /**
   * Message constructor. Initializes the properties and applies default Protobuf values if necessary
   * @param _value initial values object or instance of UpsertDeviceGroupResponse to deeply clone from
   */
  constructor(_value?: RecursivePartial<UpsertDeviceGroupResponse.AsObject>) {
    _value = _value || {};
    this.ok = _value.ok;
    this.message = _value.message;
    this.deviceCount = _value.deviceCount;
    UpsertDeviceGroupResponse.refineValues(this);
  }
  get ok(): boolean | undefined {
    return this._ok;
  }
  set ok(value: boolean | undefined) {
    this._ok = value;
  }
  get message(): string | undefined {
    return this._message;
  }
  set message(value: string | undefined) {
    this._message = value;
  }
  get deviceCount(): number | undefined {
    return this._deviceCount;
  }
  set deviceCount(value: number | undefined) {
    this._deviceCount = value;
  }

  /**
   * Serialize message to binary data
   * @param instance message instance
   */
  serializeBinary() {
    const writer = new BinaryWriter();
    UpsertDeviceGroupResponse.serializeBinaryToWriter(this, writer);
    return writer.getResultBuffer();
  }

  /**
   * Cast message to standard JavaScript object (all non-primitive values are deeply cloned)
   */
  toObject(): UpsertDeviceGroupResponse.AsObject {
    return {
      ok: this.ok,
      message: this.message,
      deviceCount: this.deviceCount
    };
  }

  /**
   * Convenience method to support JSON.stringify(message), replicates the structure of toObject()
   */
  toJSON() {
    return this.toObject();
  }

  /**
   * Cast message to JSON using protobuf JSON notation: https://developers.google.com/protocol-buffers/docs/proto3#json
   * Attention: output differs from toObject() e.g. enums are represented as names and not as numbers, Timestamp is an ISO Date string format etc.
   * If the message itself or some of descendant messages is google.protobuf.Any, you MUST provide a message pool as options. If not, the messagePool is not required
   */
  toProtobufJSON(
    // @ts-ignore
    options?: ToProtobufJSONOptions
  ): UpsertDeviceGroupResponse.AsProtobufJSON {
    return {
      ok: this.ok,
      message: this.message,
      deviceCount: this.deviceCount
    };
  }
}
export module UpsertDeviceGroupResponse {
  /**
   * Standard JavaScript object representation for UpsertDeviceGroupResponse
   */
  export interface AsObject {
    ok?: boolean;
    message?: string;
    deviceCount?: number;
  }

  /**
   * Protobuf JSON representation for UpsertDeviceGroupResponse
   */
  export interface AsProtobufJSON {
    ok?: boolean;
    message?: string;
    deviceCount?: number;
  }
}

/**
 * Message implementation for module.thingsboard.ImportDataRequest
 */
export class ImportDataRequest implements GrpcMessage {
  static id = 'module.thingsboard.ImportDataRequest';

  /**
   * Deserialize binary data to message
   * @param instance message instance
   */
  static deserializeBinary(bytes: ByteSource) {
    const instance = new ImportDataRequest();
    ImportDataRequest.deserializeBinaryFromReader(
      instance,
      new BinaryReader(bytes)
    );
    return instance;
  }

  /**
   * Check all the properties and set default protobuf values if necessary
   * @param _instance message instance
   */
  static refineValues(_instance: ImportDataRequest) {
    _instance.deviceGroupId = _instance.deviceGroupId || '';
  }

  /**
   * Deserializes / reads binary message into message instance using provided binary reader
   * @param _instance message instance
   * @param _reader binary reader instance
   */
  static deserializeBinaryFromReader(
    _instance: ImportDataRequest,
    _reader: BinaryReader
  ) {
    while (_reader.nextField()) {
      if (_reader.isEndGroup()) break;

      switch (_reader.getFieldNumber()) {
        case 1:
          _instance.deviceGroupId = _reader.readString();
          break;
        default:
          _reader.skipField();
      }
    }

    ImportDataRequest.refineValues(_instance);
  }

  /**
   * Serializes a message to binary format using provided binary reader
   * @param _instance message instance
   * @param _writer binary writer instance
   */
  static serializeBinaryToWriter(
    _instance: ImportDataRequest,
    _writer: BinaryWriter
  ) {
    if (_instance.deviceGroupId) {
      _writer.writeString(1, _instance.deviceGroupId);
    }
  }

  private _deviceGroupId?: string;

  /**
   * Message constructor. Initializes the properties and applies default Protobuf values if necessary
   * @param _value initial values object or instance of ImportDataRequest to deeply clone from
   */
  constructor(_value?: RecursivePartial<ImportDataRequest.AsObject>) {
    _value = _value || {};
    this.deviceGroupId = _value.deviceGroupId;
    ImportDataRequest.refineValues(this);
  }
  get deviceGroupId(): string | undefined {
    return this._deviceGroupId;
  }
  set deviceGroupId(value: string | undefined) {
    this._deviceGroupId = value;
  }

  /**
   * Serialize message to binary data
   * @param instance message instance
   */
  serializeBinary() {
    const writer = new BinaryWriter();
    ImportDataRequest.serializeBinaryToWriter(this, writer);
    return writer.getResultBuffer();
  }

  /**
   * Cast message to standard JavaScript object (all non-primitive values are deeply cloned)
   */
  toObject(): ImportDataRequest.AsObject {
    return {
      deviceGroupId: this.deviceGroupId
    };
  }

  /**
   * Convenience method to support JSON.stringify(message), replicates the structure of toObject()
   */
  toJSON() {
    return this.toObject();
  }

  /**
   * Cast message to JSON using protobuf JSON notation: https://developers.google.com/protocol-buffers/docs/proto3#json
   * Attention: output differs from toObject() e.g. enums are represented as names and not as numbers, Timestamp is an ISO Date string format etc.
   * If the message itself or some of descendant messages is google.protobuf.Any, you MUST provide a message pool as options. If not, the messagePool is not required
   */
  toProtobufJSON(
    // @ts-ignore
    options?: ToProtobufJSONOptions
  ): ImportDataRequest.AsProtobufJSON {
    return {
      deviceGroupId: this.deviceGroupId
    };
  }
}
export module ImportDataRequest {
  /**
   * Standard JavaScript object representation for ImportDataRequest
   */
  export interface AsObject {
    deviceGroupId?: string;
  }

  /**
   * Protobuf JSON representation for ImportDataRequest
   */
  export interface AsProtobufJSON {
    deviceGroupId?: string;
  }
}

/**
 * Message implementation for module.thingsboard.ImportDataResponse
 */
export class ImportDataResponse implements GrpcMessage {
  static id = 'module.thingsboard.ImportDataResponse';

  /**
   * Deserialize binary data to message
   * @param instance message instance
   */
  static deserializeBinary(bytes: ByteSource) {
    const instance = new ImportDataResponse();
    ImportDataResponse.deserializeBinaryFromReader(
      instance,
      new BinaryReader(bytes)
    );
    return instance;
  }

  /**
   * Check all the properties and set default protobuf values if necessary
   * @param _instance message instance
   */
  static refineValues(_instance: ImportDataResponse) {
    _instance.ok = _instance.ok || false;
    _instance.message = _instance.message || '';
    _instance.deviceCount = _instance.deviceCount || 0;
  }

  /**
   * Deserializes / reads binary message into message instance using provided binary reader
   * @param _instance message instance
   * @param _reader binary reader instance
   */
  static deserializeBinaryFromReader(
    _instance: ImportDataResponse,
    _reader: BinaryReader
  ) {
    while (_reader.nextField()) {
      if (_reader.isEndGroup()) break;

      switch (_reader.getFieldNumber()) {
        case 1:
          _instance.ok = _reader.readBool();
          break;
        case 2:
          _instance.message = _reader.readString();
          break;
        case 3:
          _instance.deviceCount = _reader.readInt32();
          break;
        default:
          _reader.skipField();
      }
    }

    ImportDataResponse.refineValues(_instance);
  }

  /**
   * Serializes a message to binary format using provided binary reader
   * @param _instance message instance
   * @param _writer binary writer instance
   */
  static serializeBinaryToWriter(
    _instance: ImportDataResponse,
    _writer: BinaryWriter
  ) {
    if (_instance.ok) {
      _writer.writeBool(1, _instance.ok);
    }
    if (_instance.message) {
      _writer.writeString(2, _instance.message);
    }
    if (_instance.deviceCount) {
      _writer.writeInt32(3, _instance.deviceCount);
    }
  }

  private _ok?: boolean;
  private _message?: string;
  private _deviceCount?: number;

  /**
   * Message constructor. Initializes the properties and applies default Protobuf values if necessary
   * @param _value initial values object or instance of ImportDataResponse to deeply clone from
   */
  constructor(_value?: RecursivePartial<ImportDataResponse.AsObject>) {
    _value = _value || {};
    this.ok = _value.ok;
    this.message = _value.message;
    this.deviceCount = _value.deviceCount;
    ImportDataResponse.refineValues(this);
  }
  get ok(): boolean | undefined {
    return this._ok;
  }
  set ok(value: boolean | undefined) {
    this._ok = value;
  }
  get message(): string | undefined {
    return this._message;
  }
  set message(value: string | undefined) {
    this._message = value;
  }
  get deviceCount(): number | undefined {
    return this._deviceCount;
  }
  set deviceCount(value: number | undefined) {
    this._deviceCount = value;
  }

  /**
   * Serialize message to binary data
   * @param instance message instance
   */
  serializeBinary() {
    const writer = new BinaryWriter();
    ImportDataResponse.serializeBinaryToWriter(this, writer);
    return writer.getResultBuffer();
  }

  /**
   * Cast message to standard JavaScript object (all non-primitive values are deeply cloned)
   */
  toObject(): ImportDataResponse.AsObject {
    return {
      ok: this.ok,
      message: this.message,
      deviceCount: this.deviceCount
    };
  }

  /**
   * Convenience method to support JSON.stringify(message), replicates the structure of toObject()
   */
  toJSON() {
    return this.toObject();
  }

  /**
   * Cast message to JSON using protobuf JSON notation: https://developers.google.com/protocol-buffers/docs/proto3#json
   * Attention: output differs from toObject() e.g. enums are represented as names and not as numbers, Timestamp is an ISO Date string format etc.
   * If the message itself or some of descendant messages is google.protobuf.Any, you MUST provide a message pool as options. If not, the messagePool is not required
   */
  toProtobufJSON(
    // @ts-ignore
    options?: ToProtobufJSONOptions
  ): ImportDataResponse.AsProtobufJSON {
    return {
      ok: this.ok,
      message: this.message,
      deviceCount: this.deviceCount
    };
  }
}
export module ImportDataResponse {
  /**
   * Standard JavaScript object representation for ImportDataResponse
   */
  export interface AsObject {
    ok?: boolean;
    message?: string;
    deviceCount?: number;
  }

  /**
   * Protobuf JSON representation for ImportDataResponse
   */
  export interface AsProtobufJSON {
    ok?: boolean;
    message?: string;
    deviceCount?: number;
  }
}
