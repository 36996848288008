import { Component, Input, OnInit, QueryList, ViewChild, ViewChildren, inject } from '@angular/core';
import { GrpcDataSourceInstance, GrpcNodeType } from '@xprojectorcore/xprojector_backend/proto/xprojector.xconf.pb';
import { XProjectorXConfClient } from '@xprojectorcore/xprojector_backend/xprojector-xconf-client';
import { ViewDataSourceInstanceComponent } from '@xprojectorfeatures/xconf/components/view-datasource-instance/view-datasource-instance.component';
import { BmsDataCollectorViewComponent } from '../bms-data-collector-view/bms-data-collector-view.component';
import { XconfTreeNode } from '@xprojectorfeatures/xconf/models/xconf-tree-node';
import { ConfigurationDataSourceComponent } from '@xprojectorfeatures/xconf/components/configuration-datasource/configuration-datasource.component';
import { SplitAreaDirective } from 'angular-split';
import { BmsCustomer } from '@app/features/bms/models/bms-customer';
import { Customer } from '@xprojectorcore/xprojector_backend/proto/xprojector.grpc.models.pb';

@Component({
  selector: 'app-bms-customer-configurations',
  templateUrl: './bms-customer-configurations.component.html',
  styleUrls: ['./bms-customer-configurations.component.scss']
})
export class BmsCustomerConfigurationsComponent implements OnInit {
  @ViewChild("bmsDataCollectorView", { read: BmsDataCollectorViewComponent, static: false }) bmsDataCollectorView: BmsDataCollectorViewComponent;
  @ViewChild("datasourceInstance", { read: ConfigurationDataSourceComponent, static: false }) datasourceInstance: ConfigurationDataSourceComponent;
  @ViewChildren(SplitAreaDirective) areasEl: QueryList<SplitAreaDirective>

  private xConfClient: XProjectorXConfClient = inject(XProjectorXConfClient);

  @Input() selectedBmsCustomer: BmsCustomer;
  @Input() selectedCustomer: Customer;


  rightPanelWidth : number = 300;

  datasources : GrpcDataSourceInstance[] = [];
  selectedDatasource : GrpcDataSourceInstance;

  constructor() { }

  ngOnInit() {

    let lsrightPanelWidth = Number.parseInt(localStorage.getItem("xprojector-customer-configuration-rightPanelWidth") || this.rightPanelWidth.toString());
    if (lsrightPanelWidth != this.rightPanelWidth) {
      this.rightPanelWidth = lsrightPanelWidth;
    }

    this.updateDatasources();
  }

  async updateDatasources() {
    this.datasources = await this.xConfClient.getDataSourceInstanceChildren(this.selectedBmsCustomer.customerId);
  }

  async selectedDatasourceChanged($event) {
    setTimeout(() => {
      this.datasourceInstance?.initDataSourceInstance();
    });
  }

  getPaneArea(order: number): SplitAreaDirective {
    let result: SplitAreaDirective = undefined;
    this.areasEl.forEach(area => {
      if (area.order == order) {
        result = area;
      }
    });

    return result;
  }

  async onSplitDragEnd($event) {
    let treePaneArea = this.getPaneArea(2);

    if (treePaneArea) {
      this.rightPanelWidth = treePaneArea.elRef.nativeElement.clientWidth;
      localStorage.setItem("xprojector-customer-configuration-rightPanelWidth", this.rightPanelWidth.toString());
    }

    //this.datasourceInstance?.setWidth(this.rightPanelWidth);
  }

  async onTreeNodeSelect(item: { treeNode: XconfTreeNode, nodeType: GrpcNodeType }) {
    this.bmsDataCollectorView?.setDataNode(item.treeNode.node, this.rightPanelWidth, this.selectedBmsCustomer, this.selectedCustomer);
  }
}
