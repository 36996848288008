import { Component, EventEmitter, OnInit, Output } from '@angular/core';
import { UserRights } from '@xprojectorcore/models/user-rights';
import { StateService } from '@xprojectorcore/services/state-service';
import { GrpcDataSourceInstance, GrpcNodeType } from '@xprojectorcore/xprojector_backend/proto/xprojector.xconf.pb';
import { XProjectorXConfClient } from '@xprojectorcore/xprojector_backend/xprojector-xconf-client';
import { GrpcNode } from '@xprojectorcore/xprojector_backend/proto/xprojector.grpc.models.pb';
import { XProjectorClient } from 'xproj-lib';
import { XconfTreeNode } from '@xprojectorfeatures/xconf/models/xconf-tree-node';

@Component({
  selector: 'app-bms-treeview',
  templateUrl: './bms-treeview.component.html',
  styleUrls: ['./bms-treeview.component.scss']
})
export class BmsTreeviewComponent implements OnInit {

  @Output() onTreeNodeSelect = new EventEmitter<{treeNode: XconfTreeNode, nodeType: GrpcNodeType}>();

  currentUserRights: UserRights;

  private dataSourceInstanceId : string = '_x_bms_datasource';
  dataSourceInstance: GrpcDataSourceInstance;

  constructor(
    private state: StateService,
    private xConfClient: XProjectorXConfClient,
    private xprojClient: XProjectorClient
  ) { }

  async ngOnInit() {
    this.currentUserRights = this.state.userRightsValue;

    this.dataSourceInstance = await this.xConfClient.getDataSourceInstance(this.dataSourceInstanceId);
  }

  async onSelectTreeNode(item: {treeNode : XconfTreeNode, nodeType : GrpcNodeType}) {
    this.onTreeNodeSelect.next(item);
    //this.nodes = await this.xConfClient.getReferencedNodes(item.treeNode.node.id, item.treeNode.node.nodeTypeLabel, [], '', 10);

    switch (item.treeNode.node.nodeTypeLabel)
    {
      case '_x_bms_realestate':

       break;

    }

  }

}
