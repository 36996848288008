import { BmsCustomerAdminClient } from './proto/xprojector.modulebms.customeradmin.pbsc';
import { Injectable, OnDestroy, OnInit } from '@angular/core';
import { StateService } from '@xprojectorcore/services/state-service';
import { AddCustomerUserRequest, AddCustomerUserResponse, BillingTariffchangeRequest, BmsCustomerUser, CreateBillingTariffChangeRequestRequest, CreateBillingTariffChangeRequestResponse, CreteTariffGroupRequest, DeleteBillingTariffChangeRequestRequest, DeleteBillingTariffChangeRequestResponse, DeleteCustomerUserRequest, DeleteCustomerUserResponse, DeleteTariffGroupRequest, ExportApartmentsAndCustomerUsersRequest, GetApartmentCustomerUsersRequest, GetApartmentCustomerUsersResponse, GetApartmentsRequest, GetApartmentsResponse, GetBillingTariffChangeRequestsRequest, GetBillingTariffChangeRequestsResponse, GetCustomerInfoRequest, GetCustomerInfoResponse, GetCustomerUsersRequest, GetCustomerUsersResponse, GetLastInvoicedDateRequest, GetMeterTypesRequest, GetMetersRequest, GetTariffGroupsRequest, GrpcMeter, MeterTypeInfo, ResetPasswordRequest, ResetPasswordResponse, SendCustomerMailRequest, SendCustomerMailResponse, SendWelcomeMailRequest, SendWelcomeMailResponse, TariffGroupInfo, UpdateCustomerUserRequest, UpdateCustomerUserResponse, UpdateTariffGroupRequest } from './proto/xprojector.modulebms.customeradmin.pb';
import { CustomerAdminCustomerUser } from '@app/features/bms/bms-customer/components/bms-customer-admin/bms-customer-admin.component';
import { Timestamp } from './proto/google/protobuf/timestamp.pb';
import { DateHelper } from 'xproj-lib';
import { saveAs } from 'file-saver';

export interface CreateBillingTariffChangeRequestParameters {
  customerId?: string,
  meterType?: string,
  tariffGroup?: string,
  activeFrom?: Date,
  value?: number,
  vatrate?: number,
  includeVAT?: boolean,
  currency?: string
}

@Injectable({
  providedIn: 'root'
})
export class XProjectorBmsCustomerAdminClient implements OnInit, OnDestroy {

  constructor(
    private customerAdminClient: BmsCustomerAdminClient,
    private dateHelper: DateHelper,
    private state: StateService) {
  }

  ngOnDestroy(): void {

  }

  ngOnInit(): void {

  }

  async getApartments(customerId: string, fromItem : number = 0, maxItems : number = 10000) : Promise<GetApartmentsResponse>{
    let request: GetApartmentsRequest = new GetApartmentsRequest({
      customerId: customerId,
      fromItem : fromItem,
      maxItems : maxItems
    });

    return await this.customerAdminClient.getApartments(request, this.state.metadata).toPromise();
  }

  async getCustomerUsers(customerId: string, adminOnly: boolean = false, fromItem : number = 0, maxItems : number = 10000) : Promise<BmsCustomerUser[]>{
    let request: GetCustomerUsersRequest = new GetCustomerUsersRequest({
      customerId: customerId,
      adminsOnly: adminOnly,
      fromItem : fromItem,
      maxItems : maxItems
    });

    let result = await this.customerAdminClient.getCustomerUsers(request, this.state.metadata).toPromise();
    return result.customerUsers;
  }


  async getApartmentCustomerUsers(customerId: string, apartmentId: string, fromItem : number = 0, maxItems : number = 10000) : Promise<GetApartmentCustomerUsersResponse>{
    let request: GetApartmentCustomerUsersRequest = new GetApartmentCustomerUsersRequest({
      customerId: customerId,
      apartmentId: apartmentId,
      fromItem : fromItem,
      maxItems : maxItems
    });

    return await this.customerAdminClient.getApartmentCustomerUsers(request, this.state.metadata).toPromise();
  }

  async deleteCustomerUser(customerId: string, customerUserId: string) : Promise<DeleteCustomerUserResponse>{
    let request: DeleteCustomerUserRequest = new DeleteCustomerUserRequest({
      customerId: customerId,
      customerUserId: customerUserId
    });

    return await this.customerAdminClient.deleteCustomerUser(request, this.state.metadata).toPromise();
  }

  async addCustomerUser(customerId: string, apartmentId: string, customerUser : CustomerAdminCustomerUser) : Promise<AddCustomerUserResponse>{


    let request: AddCustomerUserRequest = new AddCustomerUserRequest({
      customerId: customerId,
      apartmentId: apartmentId,
      customerUser : this.ToBmsCustomerUser(customerUser),
    });

    return await this.customerAdminClient.addCustomerUser(request, this.state.metadata).toPromise();
  }

  async updateCustomerUser(customerId: string, apartmentId: string, customerUser : CustomerAdminCustomerUser) : Promise<UpdateCustomerUserResponse>{
    let request: UpdateCustomerUserRequest = new UpdateCustomerUserRequest({
      customerId: customerId,
      customerUser : this.ToBmsCustomerUser(customerUser),
      apartmentId : apartmentId
    });

    return await this.customerAdminClient.updateCustomerUser(request, this.state.metadata).toPromise();
  }

  async resetPassword(customerId: string, customerUserId : string) : Promise<ResetPasswordResponse>{
    let request: ResetPasswordRequest = new ResetPasswordRequest({
      customerId: customerId,
      customerUserId : customerUserId
    });

    return await this.customerAdminClient.resetPassword(request, this.state.metadata).toPromise();
  }

  async getCustomerInfo(customerId: string) : Promise<GetCustomerInfoResponse>{
    let request: GetCustomerInfoRequest = new GetCustomerInfoRequest({
      customerId: customerId
    });

    return await this.customerAdminClient.getCustomerInfo(request, this.state.metadata).toPromise();
  }

  private ToBmsCustomerUser(user : CustomerAdminCustomerUser) : BmsCustomerUser {
    let result = new BmsCustomerUser();
    result.id = user.id;
    result.firstname = user.firstname;
    result.surname = user.surname;
    result.email = user.email;
    try {
        result.activeFrom = Timestamp.fromDate(this.dateHelper.utils.parse(user.activeFromString, "yyyy-MM-dd"));
    }
    catch {
        result.activeFrom = Timestamp.fromDate(new Date());
    }

    return result;
  }

  async exportApartmentsAndCustomerUsers(customerId: string, customerName : string, headers : string []): Promise<boolean> {
    return new Promise((resolve, reject) => {
      let request: ExportApartmentsAndCustomerUsersRequest = new ExportApartmentsAndCustomerUsersRequest({
        customerId: customerId,
        headers : headers
      });

      let dataChunks: Uint8Array[] = [];
      let i: number = 0;

      this.customerAdminClient.exportApartmentsAndCustomerUsers(request, this.state.metadata).subscribe(
        ((event: any) => {
          //console.log('event', event);

          let datachunk: Uint8Array = event.data;
          if (datachunk) {
            dataChunks.push(datachunk);
          }
        }),
        ((error) => {
          //console.log('error', error);
          reject(error);
        }),
        (() => {
          if (dataChunks.length > 0) {
            //console.log('complete', data);
            var blob = new Blob(dataChunks);
            saveAs(blob, customerName + ".xlsx");

            resolve(true);
          }
          else {
            resolve(false);
          }
        })

      );
    });
  }

  async createBillingTariffChangeRequest({
      customerId = undefined,
      meterType = undefined,
      tariffGroup = undefined,
      activeFrom = undefined,
      value = undefined,
      vatrate = 0.2,
      includeVAT = true,
      currency = 'SEK'
  }:CreateBillingTariffChangeRequestParameters) : Promise<CreateBillingTariffChangeRequestResponse>{

    let request: CreateBillingTariffChangeRequestRequest = new CreateBillingTariffChangeRequestRequest({
      customerId : customerId,
      meterType : meterType,
      tariffGroup : tariffGroup,
      activeFrom : activeFrom ? Timestamp.fromDate(activeFrom) : null,
      value : value,
      vatrate : vatrate,
      includeVat : includeVAT,
      currency : currency
    });

    return await this.customerAdminClient.createBillingTariffChangeRequest(request, this.state.metadata).toPromise();
  }

  async getBillingTariffChangeRequests(customerId: string, includeHistory : boolean, from : Date = undefined, to : Date = undefined, meterType : string = '', tariffGroup : string = '') : Promise<BillingTariffchangeRequest[]>{
    let request: GetBillingTariffChangeRequestsRequest = new GetBillingTariffChangeRequestsRequest({
      customerId : customerId,
      from : from ? Timestamp.fromDate(from) : null,
      to : to ? Timestamp.fromDate(to) : null,
      meterType : meterType,
      tariffGroup : tariffGroup,
      includeHistory : includeHistory
    });

    let result = await this.customerAdminClient.getBillingTariffChangeRequests(request, this.state.metadata).toPromise();

    return result.billingTariffCahngeRequests;
  }

  async getMeterTypes(customerId: string, activeBilling : boolean) : Promise<MeterTypeInfo[]>{
    let request: GetMeterTypesRequest = new GetMeterTypesRequest({
      customerId : customerId,
      activeBilling : activeBilling
    });

    let result = await this.customerAdminClient.getMeterTypes(request, this.state.metadata).toPromise();

    return result.meterTypeInfos;
  }

  async getTariffGroups(customerId: string, activeBilling : boolean, allGroups : boolean = false) : Promise<TariffGroupInfo[]>{
    let request: GetTariffGroupsRequest = new GetTariffGroupsRequest({
      customerId : customerId,
      activeBilling : activeBilling,
      allGroups : allGroups
    });

    let result = await this.customerAdminClient.getTariffGroups(request, this.state.metadata).toPromise();

    return result.tariffGroupsInfos;
  }

  async createTariffGroup(customerId: string, tariffGroup : TariffGroupInfo) : Promise<boolean>{
    let request: CreteTariffGroupRequest = new CreteTariffGroupRequest({
      customerId : customerId,
      tariffGroup : tariffGroup

    });

    let result = await this.customerAdminClient.createTariffGroup(request, this.state.metadata).toPromise();

    return result.ok;
  }

  async updateTariffGroup(customerId: string, tariffGroup : TariffGroupInfo) : Promise<boolean>{
    let request: UpdateTariffGroupRequest = new UpdateTariffGroupRequest({
      customerId : customerId,
      tariffGroup : tariffGroup

    });

    let result = await this.customerAdminClient.updateTariffGroup(request, this.state.metadata).toPromise();

    return result.ok;
  }

  async deleteTariffGroup(customerId: string, tariffGroupId : string) : Promise<boolean>{
    let request: DeleteTariffGroupRequest = new DeleteTariffGroupRequest({
      customerId : customerId,
      tariffGroupId : tariffGroupId

    });

    let result = await this.customerAdminClient.deleteTariffGroup(request, this.state.metadata).toPromise();

    return result.ok;
  }

  async deleteBillingTariffChangeRequest(customerId: string, requestId : string) : Promise<DeleteBillingTariffChangeRequestResponse>{
    let request: DeleteBillingTariffChangeRequestRequest = new DeleteBillingTariffChangeRequestRequest({
      customerId : customerId,
      requestId : requestId + '',
    });

    return await this.customerAdminClient.deleteBillingTariffChangeRequest(request, this.state.metadata).toPromise();
  }

  async getLastInvoicedDate(customerId: string, includeSigned : boolean = false, meterType : string = '') : Promise<Date>{
    let request: GetLastInvoicedDateRequest = new GetLastInvoicedDateRequest({
      customerId : customerId,
      meterType : meterType,
      includeSigned : includeSigned
    });

    try {
      let result = await this.customerAdminClient.getLastInvoicedDate(request, this.state.metadata).toPromise();

      return result.lastInvoicedAt?.toDate();
    }
    catch {
      return undefined;
    }
  }

  async sendWelcomeMail(customerId: string, force : boolean) : Promise<SendWelcomeMailResponse>{
    let request: SendWelcomeMailRequest = new SendWelcomeMailRequest({
      customerId: customerId,
      force: force
    });

    return await this.customerAdminClient.sendWelcomeMail(request, this.state.metadata).toPromise();
  }

  async sendCustomerMail(customerId: string, force : boolean, mailId : string, data : string = '') : Promise<SendCustomerMailResponse>{
    let request: SendCustomerMailRequest = new SendCustomerMailRequest({
      customerId: customerId,
      force: force,
      mailId: mailId,
      data: data
    });

    return await this.customerAdminClient.sendCustomerMail(request, this.state.metadata).toPromise();
  }

  async getMeters(customerId: string, sourceNodeId : string,  sourceNodeTypeLabel : string, meterTypes : string[], facilityType : string, includeAllCustomerRealestates : boolean) : Promise<GrpcMeter[]>{
    let request: GetMetersRequest = new GetMetersRequest({
      customerId: customerId,
      meterTypes: meterTypes,
      sourceNodeId : sourceNodeId,
      sourceNodeTypeLabel : sourceNodeTypeLabel,
      facilityType : facilityType,
      includeAllCustomerRealestates : includeAllCustomerRealestates
    });

    let response = await this.customerAdminClient.getMeters(request, this.state.metadata).toPromise();

    return response.meters;
  }
}
