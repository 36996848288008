<clr-modal [(clrModalOpen)]="showEditQaBotExecProperties" [clrModalClosable]="false">
  <h3 class="modal-title">Qa bot properties</h3>
  <div class="modal-body">
    <form clrForm *ngIf="qaBotExecConfig">

      <clr-input-container>
        <label>Select interpolate start date:</label>
        <input clrInput type="date" step="1" placeholder="" name="qabotoverrideUtcFrom" [(ngModel)]="qabotOverrideUtcFromString" />
      </clr-input-container>

      <clr-checkbox-container>
        <clr-checkbox-wrapper>
          <input type="checkbox" clrCheckbox [(ngModel)]="qabotToEnabled" name="qabotToEnabled"/>
          <label>Interpolate multiple days.</label>
        </clr-checkbox-wrapper>
      </clr-checkbox-container>

      <clr-input-container *ngIf="qabotToEnabled" >
        <label>Select interpolate end date:</label>
        <input clrInput type="date" step="1" placeholder="" name="qabotoverrideUtcTo" [(ngModel)]="qabotOverrideUtcToString" />
      </clr-input-container>

      <clr-checkbox-container>
        <clr-checkbox-wrapper>
          <input type="checkbox" clrCheckbox [(ngModel)]="qaBotExecConfig.ignoreInterpolatedValues" name="qabotignoreInterpolatedValues"/>
          <label>Ignore interpolated values.</label>
        </clr-checkbox-wrapper>
      </clr-checkbox-container>

      <clr-input-container>
        <label>Error meters projection id:</label>
        <input clrInput type="text" placeholder="" name="qaboterrorMetersProjectionId" [(ngModel)]="qaBotExecConfig.errorMetersProjectionId" />
      </clr-input-container>

    </form>
  </div>
  <div class="modal-footer">
    <button type="button" class="btn btn-outline" (click)="showEditQaBotExecProperties = false">Cancel</button>
    <button type="button" class="btn btn-primary"
      (click)="showEditQaBotExecProperties = false; onCloseEditQaBotProperties()">Run</button>
  </div>
</clr-modal>

<as-split direction="horizontal" [unit]="'pixel'" (dragEnd)="onSplitDragEnd($event)" class="meter-view"
  [gutterSize]="7">
  <as-split-area [order]="1">
    <app-configuration-datasource *ngIf="botsDataSourceInstance" [dataSourceInstance]="botsDataSourceInstance"
      [highlightSelected]="true" [eventLogdEnabled]="true" (onTreeNodeSelect)="onTreeNodeSelect($event)"></app-configuration-datasource>
  </as-split-area>
  <as-split-area [(size)]="rightPanelWidth" [order]="2">
    <div *ngIf="this.selectedBotNode && selectedBotNode.botServiceId == 'bms_qa'" class="detail-pane">
      <button type="button" class="btn" (click)="executeSelectedBot()" [clrLoading]="botRunning">
        <clr-icon shape="play"></clr-icon>
        Execute bot...
      </button>
      <label style="margin-left: 1em;">{{execStatusMessage}}</label>

    </div>
  </as-split-area>
</as-split>
