import { Component, OnInit } from '@angular/core';
import { BmsLoRaWANProvsionDevicesResult } from '@app/features/bms/models/bms-lorwan-provisiondevices-result';
import { ClrLoadingState } from '@clr/angular';
import { BmsStateService } from '@core/services/bms-state-service';
import { XprojAlertService, XprojModalService } from 'xproj-lib';
import { saveAs } from 'file-saver';
import { XProjectorBmsLoRaWANClient } from '@app/core/xprojector_backend/xprojector-bms-lorawan-client';
import { LoRaWANApplication } from '@app/core/xprojector_backend/proto/xprojector.modulebms.lorawan.pb';

const APPLICATIONID_PROPEYEDIRECT : string = 'dabb900e-3529-4ad7-a38b-f17f6d5b510a';

@Component({
  selector: 'app-bms-lorawan',
  templateUrl: './bms-lorawan.component.html',
  styleUrls: ['./bms-lorawan.component.scss']
})
export class BmsLorawanComponent implements OnInit {

  files: any[] = [];
  lorawanState : ClrLoadingState = ClrLoadingState.DEFAULT;
  provionResult : BmsLoRaWANProvsionDevicesResult;

  operatorId : string = 'chirpstack';
  applications : LoRaWANApplication[] = [];
  selectedApplication : LoRaWANApplication;

  ClrLoadingState = ClrLoadingState;

  constructor(
    private alertService: XprojAlertService,
    private modalService: XprojModalService,
    private bmsState: BmsStateService,
    private loRaWANClient: XProjectorBmsLoRaWANClient
  ) { }

  ngOnInit(): void {
    this.updateApplications();
  }

  uploadFile(files) {
    if (files.length > 0) {
      this.files = files;
    }
    else {
      this.files = [];
    }
  }

  async updateApplications() {
    if (this.applications.length == 0) {
      this.applications = await this.loRaWANClient.getApplications(this.operatorId);
      this.selectedApplication = this.applications.find(x => x.id == APPLICATIONID_PROPEYEDIRECT);
    }
  }

  async lorawanProvionDevices() {
    if (this.files.length > 0 && this.selectedApplication) {
      try {
        let doProvisionDevices = await this.modalService.ShowConfirmModalAsync({ header: 'Provion devices', description: 'Provision devices, are you sure?' });

        if (doProvisionDevices) {
          this.lorawanState = ClrLoadingState.LOADING;
          this.provionResult = await this.bmsState.provisionLoRaWANDevicesFile(this.files[0], this.operatorId, this.selectedApplication.id);
          if (this.provionResult.ok) {
            //this.alertService.success('Geo location updated ok.');
            this.lorawanState = ClrLoadingState.SUCCESS;
          }
          else {
            //this.alertService.error('Error update all geo locations');
            this.lorawanState = ClrLoadingState.ERROR;
          }
        }
      }
      catch {
        this.lorawanState = ClrLoadingState.ERROR;
      }
    }
  }

  async downloadProvisionResult() {
    if (this.provionResult) {
      let json: string = JSON.stringify(this.provionResult);
      var blob = new Blob([json], { type: "text/plain;charset=utf-8" });
      saveAs(blob, 'LoRaWANProvionResult.json');
    }
  }
}
