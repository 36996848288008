<clr-modal [(clrModalOpen)]="showImportDialog">
  <h3 i18n='@@customeradmin_importheader' class="modal-title">Import apartment users</h3>
  <div class="modal-body">
    <div class="dropzone">
      <ngx-file-drop dropZoneLabel="Drop files here" (onFileDrop)="dropped($event)">
        <ng-template i18n='@@customeradmin_importdrop' ngx-file-drop-content-tmp let-openFileSelector="openFileSelector">
          Drop apartemnt users file. Will raplace current config.
          <button type="button" (click)="openFileSelector()">Browse Files</button>
        </ng-template>
      </ngx-file-drop>
    </div>
    <div class="modal-footer"></div>
    <button i18n='@@customeradmin_importclose' type="button" class="btn btn-outline" (click)="showImportDialog = false">Close</button>
  </div>
</clr-modal>

<div *ngIf="apartmentInfos">
    <h3 i18n='@@customeradmin_infoheader' class="information-area">Apartments user config
      <clr-signpost>
        <clr-signpost-content *clrIfOpen>
          <h3>Apartments user config</h3>
          <p>Edit apatment users.</p>
        </clr-signpost-content>
      </clr-signpost>
    </h3>
    <!-- <p i18n='@@customeradmin_infobody' class="information-area">Administrate apartment residents.</p> -->

    <clr-datagrid [clrDgLoading]="loadingApartmentInfos" class="apartments-table">
        <clr-dg-action-bar>
            <div class="btn-group" style="float: right;">
              <button class="btn btn-sm btn-secondary" (click)="importCustomerUsersAndApartments();" [clrLoading]="importingState"
                    i18n='@@customeradmin_import'>Import<clr-icon shape="import" directions="left"></clr-icon></button>
                <button class="btn btn-sm btn-secondary" (click)="exportCustomerUsersAndApartments();" [clrLoading]="exportingState"
                    i18n='@@customeradmin_export'>Export <clr-icon shape="export" directions="left"></clr-icon></button>
                <button class="btn btn-sm btn-secondary" (click)="updateApartments();"
                    i18n='@@customeradmin_refresh'>Refresh <clr-icon shape="refresh"
                        directions="left"></clr-icon></button>
            </div>
        </clr-dg-action-bar>

        <clr-dg-column [clrDgField]="'apartment.externalId'" [clrDgSortBy]="externalIdSort" [clrDgSortOrder]="ascSort">
            <ng-container i18n='@@customeradmin_externalid'>Apartment Id</ng-container>
        </clr-dg-column>
        <clr-dg-column [clrDgField]="'apartment.address'" [style.min-width.px]="200">
            <ng-container i18n='@@customeradmin_address'>Address</ng-container>
        </clr-dg-column>
        <clr-dg-column [clrDgField]="'apartment.apartmentNo'" [style.min-width.px]="150">
            <ng-container i18n='@@customeradmin_apartmentno'>Apartment No</ng-container>
        </clr-dg-column>
        <clr-dg-column [clrDgField]="'residents'" [style.min-width.px]="150">
            <ng-container i18n='@@customeradmin_residents'>Residents</ng-container>
        </clr-dg-column>

        <clr-dg-row *clrDgItems="let item of apartmentInfos; let i = index" [clrDgItem]="item">
            <clr-dg-cell class="left"
                (click)="item.expanded = !item.expanded">{{item.apartment.externalId}}</clr-dg-cell>
            <clr-dg-cell class="left" (click)="item.expanded = !item.expanded">{{item.apartment.address}}</clr-dg-cell>
            <clr-dg-cell class="left"
                (click)="item.expanded = !item.expanded">{{item.apartment.apartmentNo}}</clr-dg-cell>
            <clr-dg-cell class="left"
                (click)="item.expanded = !item.expanded">{{item.residents}}</clr-dg-cell>

            <app-bms-customer-admin-user *clrIfExpanded="item.expanded" ngProjectAs="clr-dg-row-detail"
                [customerId]="data.customerId" [apartmentInfo]="item"></app-bms-customer-admin-user>
        </clr-dg-row>

        <clr-dg-footer>
            <clr-dg-pagination #pagination [clrDgPageSize]="20">
                <clr-dg-page-size i18n='@@customeradmin_apartmentsperpage' [clrPageSizeOptions]="[10,20,50,100]">
                    Apartments per page</clr-dg-page-size>
                {{pagination.firstItem + 1}} - {{pagination.lastItem + 1}} of {{pagination.totalItems}}
            </clr-dg-pagination>
        </clr-dg-footer>

    </clr-datagrid>

</div>
