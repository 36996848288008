import { NgModule, Optional, SkipSelf } from '@angular/core';
import { CommonModule } from '@angular/common';

import { ClarityModule } from '@clr/angular';

import { BmsAdminRoutingModule } from './bms-admin-routing.module';
import { BmsAdminWorkspaceComponent } from './components/bms-admin-workspace/bms-admin-workspace.component';
import { FormsModule } from '@angular/forms';
import { SharedModule } from '@shared/shared.module';
import { CoreSharedModule } from '@xprojectorshared/core-shared.module';
import { BmsMasterDataComponent } from './components/bms-master-data/bms-master-data.component';
import { BmsMeterAdminComponent } from './components/bms-meter-admin/bms-meter-admin.component';
import { BmsTreeviewComponent } from './components/bms-treeview/bms-treeview.component';
import { XconfModule } from '@xprojectorfeatures/xconf/xconf.module';
import { BmsMeterAdminEditPipe } from './components/bms-meter-admin/bms-meter-admin-edit.pipe';
import { BmsMeterViewComponent } from './components/bms-meter-view/bms-meter-view.component';
import { BmsBuildingAddressViewComponent } from './components/bms-building-address-view/bms-building-address-view.component';
import { BmsApartmentViewComponent } from './components/bms-apartment-view/bms-apartment-view.component';
import { BmsRealestateViewComponent } from './components/bms-realestate-view/bms-realestate-view.component';
import { BmsBillingTariffViewComponent } from './components/bms-billing-tariff-view/bms-billing-tariff-view.component';
import { BmsBuildingViewComponent } from './components/bms-building-view/bms-building-view.component';
import { BmsTrusteeAdminComponent } from './components/bms-trustee-admin/bms-trustee-admin.component';
import { BmsCustomersAdminComponent } from './components/bms-customers-admin/bms-customers-admin.component';
import { BmsMeterListComponent } from './components/bms-meter-list/bms-meter-list.component';
import { BmsResidentExchangViewComponent } from './components/bms-resident-exchang-view/bms-resident-exchang-view.component';
import { BmsBotsAdminComponent } from './components/bms-bots-admin/bms-bots-admin.component';
import { SysAdminModule } from '@xprojectorfeatures/sysadmin/sys-admin.module';
import { BmsExportFilesViewComponent } from './components/bms-export-files-view/bms-export-files-view.component';
import { BmsTrusteeOverviewComponent } from './components/bms-trustee-overview/bms-trustee-overview.component';
import { BmsCustomerViewComponent } from './components/bms-customer-view/bms-customer-view.component';
import { BmsAdminDashboardsComponent } from './components/bms-admin-dashboards/bms-admin-dashboards.component';
import { BmsAdminDashboardComponent } from './components/bms-admin-dashboard/bms-admin-dashboard.component';
import { BmsGeoLocationComponent } from './components/bms-geo-location/bms-geo-location.component';
import { BmsCustomerAddComponent } from './components/bms-customer-add/bms-customer-add.component';
import { BmsCustomerConfigurationsComponent} from './components/bms-customer-configurations/bms-customer-configurations.component';
import { BmsMeteringApiComponent } from './components/bms-metering-api/bms-metering-api.component';
import { BmsExternalCustomersComponent } from './components/bms-external-customers/bms-external-customers.component';
import { BmsExternalUsersComponent } from './components/bms-external-users/bms-external-users.component';
import { BmsMessageAdminComponent } from './components/bms-message-admin/bms-message-admin.component';
import { BmsThingsboardComponent } from './components/bms-thingsboard/bms-thingsboard.component';
import { BmsStateService } from '@app/core/services/bms-state-service';
import { BmsDataCollectorView } from '@app/core/models/bms-data-collector-view';
import { BmsMeterDataCollectorViewComponent,
        BmsGatewayDataCollectorViewComponent,
        BmsDataExportBotDataCollectorViewComponent,
        BmsGroupDataCollectorViewComponent,
        BmsSystemDataCollectorViewComponent } from './components/bms-data-collector-views';
import { BmsDataCollectorViewComponent } from './components/bms-data-collector-view/bms-data-collector-view.component';
import { BmsDataCollectorViewDirective } from './directives/bms-data-collector-view.directive';
import { BmsLorawanMultiMeterDataCollectorViewComponent } from './components/bms-data-collector-views/bms-lorawan-multi-meter-data-collector-view/bms-lorawan-multi-meter-data-collector-view.component';
import { BmsLorawanMetersGroupDataCollectorViewComponent } from './components/bms-data-collector-views/bms-lorawan-meters-group-data-collector-view/bms-lorawan-meters-group-data-collector-view.component';
import { BmsCustomerModule } from '../bms-customer/bms-customer.module';
import { BmsCustomerEnabledPipe } from './components/bms-trustee-admin/bms-customer-enabled.pipe';
import { DataExportStatusFilterComponent } from './filters/data-export-status-filter/data-export-status-filter.component';
import { DataExportExternalidFilterComponent } from './filters/data-export-externalid-filter/data-export-externalid-filter.component';
import { BmsXEdgeServerDataCollectorViewComponent } from './components/bms-data-collector-views/bms-xedge-server-data-collector-view/bms-xedge-server-data-collector-view.component';
import { XEdgeModule } from "@xprojectorfeatures/xedge/xedge.module";
import { NgArrayPipesModule } from "ngx-pipes";
import { BmsLorawanMetersGroupBatteryLevelPipe } from './components/bms-data-collector-views/bms-lorawan-meters-group-data-collector-view/bms-lorawan-meters-group-batterylevel.pipe';
import { BmsGatewayEventsComponent } from './components/bms-gateway-admin/bms-gateway-events/bms-gateway-events.component';
import { BmsGatewayEventsHistoryComponent } from './components/bms-gateway-admin/bms-gateway-events-history/bms-gateway-events-history.component';
import { BmsEventStatusPipe } from './pipes/bms-event-status.pipe';
import { BmsEventActivePipe } from './pipes/bms-event-active.pipe';
import { EventStatusFilterComponent } from './filters/event-status-filter/event-status-filter.component';
import { BmsLorawanComponent } from './components/bms-lorawan/bms-lorawan.component';
import { Bms3DConfigurationComponent } from './components/bms-3d-configuration/bms-3d-configuration.component';
import { BmsCustomersAdminTariffgroupsComponent } from './components/bms-customers-admin-tariffgroups/bms-customers-admin-tariffgroups.component';

import { BimModule } from '@xprojectorfeatures/bim/bim.module';

@NgModule({
  declarations: [
    BmsAdminWorkspaceComponent,
    BmsMasterDataComponent,
    BmsMeterAdminComponent,
    BmsTreeviewComponent,
    BmsMeterAdminEditPipe,
    BmsMeterViewComponent,
    BmsBuildingAddressViewComponent,
    BmsApartmentViewComponent,
    BmsRealestateViewComponent,
    BmsBillingTariffViewComponent,
    BmsBuildingViewComponent,
    BmsTrusteeAdminComponent,
    BmsCustomersAdminComponent,
    BmsMeterListComponent,
    BmsResidentExchangViewComponent,
    BmsBotsAdminComponent,
    BmsExportFilesViewComponent,
    BmsTrusteeOverviewComponent,
    BmsCustomerViewComponent,
    BmsAdminDashboardsComponent,
    BmsAdminDashboardComponent,
    BmsGeoLocationComponent,
    BmsCustomerAddComponent,
    BmsMeteringApiComponent,
    BmsExternalCustomersComponent,
    BmsExternalUsersComponent,
    BmsMessageAdminComponent,
    BmsDataCollectorViewDirective,
    BmsDataCollectorViewComponent,
    BmsMeterDataCollectorViewComponent,
    BmsGatewayDataCollectorViewComponent,
    BmsDataExportBotDataCollectorViewComponent,
    BmsSystemDataCollectorViewComponent,
    BmsGroupDataCollectorViewComponent,
    BmsLorawanMultiMeterDataCollectorViewComponent,
    BmsLorawanMetersGroupDataCollectorViewComponent,
    BmsXEdgeServerDataCollectorViewComponent,
    BmsThingsboardComponent,
    BmsCustomerEnabledPipe,
    DataExportStatusFilterComponent,
    DataExportExternalidFilterComponent,
    BmsLorawanMetersGroupBatteryLevelPipe,
    BmsGatewayEventsComponent,
    BmsGatewayEventsHistoryComponent,
    BmsEventStatusPipe,
    BmsEventActivePipe,
    EventStatusFilterComponent,
    BmsLorawanComponent,
    Bms3DConfigurationComponent,
    BmsCustomerConfigurationsComponent,
    BmsCustomersAdminTariffgroupsComponent
  ],
  imports: [
    //ClarityModule,
    CommonModule,
    BmsAdminRoutingModule,
    FormsModule,
    SharedModule,
    CoreSharedModule,
    XconfModule,
    SysAdminModule,
    BmsCustomerModule,
    XEdgeModule,
    NgArrayPipesModule,
    BimModule
  ]
})
export class BmsAdminModule {
  constructor (private bmsState : BmsStateService, @Optional() @SkipSelf() parentModule?: BmsAdminModule) {
    bmsState.addDataCollectorView( new BmsDataCollectorView(
      BmsMeterDataCollectorViewComponent,
      BmsMeterDataCollectorViewComponent.NodeTypeId
    ));

    bmsState.addDataCollectorView( new BmsDataCollectorView(
      BmsGatewayDataCollectorViewComponent,
      BmsGatewayDataCollectorViewComponent.NodeTypeId
    ));

    bmsState.addDataCollectorView( new BmsDataCollectorView(
      BmsGroupDataCollectorViewComponent,
      BmsGroupDataCollectorViewComponent.NodeTypeId
    ));

    bmsState.addDataCollectorView( new BmsDataCollectorView(
      BmsLorawanMultiMeterDataCollectorViewComponent,
      BmsLorawanMultiMeterDataCollectorViewComponent.NodeTypeId
    ));

    bmsState.addDataCollectorView( new BmsDataCollectorView(
      BmsLorawanMetersGroupDataCollectorViewComponent,
      BmsLorawanMetersGroupDataCollectorViewComponent.NodeTypeId
    ));

    bmsState.addDataCollectorView(new BmsDataCollectorView(
      BmsXEdgeServerDataCollectorViewComponent,
      BmsXEdgeServerDataCollectorViewComponent.NodeTypeId
    ));

    bmsState.addDataCollectorView(new BmsDataCollectorView(
      BmsDataExportBotDataCollectorViewComponent,
      BmsDataExportBotDataCollectorViewComponent.NodeTypeId
    ));

    bmsState.addDataCollectorView(new BmsDataCollectorView(
      BmsSystemDataCollectorViewComponent,
      BmsSystemDataCollectorViewComponent.NodeTypeId
    ));
  }
}
