<div *ngIf="events" class="events-area">
  <h2 class="information-area">Gateway status</h2>
  <p class="information-area">Click on the circles to confirm or close the message.
  </p>
  <clr-datagrid [clrDgLoading]="loadingEvents" class="event-table">

    <clr-dg-action-bar>
      <div class="btn-group" style="float: right;">
        <button class="btn btn-sm btn-secondary" (click)="updateEvents();">Refresh <clr-icon shape="refresh"
            directions="left"></clr-icon></button>
        <button class="btn btn-sm btn-secondary" (click)="exportEvents();">Export <clr-icon shape="export"
            directions="left"></clr-icon></button>

      </div>
    </clr-dg-action-bar>
    <clr-dg-column class="col1">
      <ng-container></ng-container>
    </clr-dg-column>
    <!-- <clr-dg-column [clrDgField]="'event.category'" [style.min-width.px]="200">
      <ng-container>Category</ng-container>
    </clr-dg-column> -->
    <clr-dg-column [clrDgField]="'customerName'" [style.min-width.px]="300">
      <ng-container>Customer</ng-container>
    </clr-dg-column>
    <clr-dg-column [clrDgField]="'gateway'" [style.min-width.px]="200">
      <ng-container>Gateway</ng-container>
    </clr-dg-column>
    <clr-dg-column [clrDgField]="'event.message'" [style.min-width.px]="250">
      <ng-container>Message</ng-container>
    </clr-dg-column>
    <clr-dg-column [clrDgField]="'event.timestamp'" [style.min-width.px]="170" [clrDgSortBy]="eventTimeSort"
      [clrDgSortOrder]="descSort">
      <ng-container>Time</ng-container>
    </clr-dg-column>
    <!-- <clr-dg-column [clrDgField]="'event.pbClass'" [style.width.px]="30">
      <ng-container>Severity</ng-container>
    </clr-dg-column> -->
    <clr-dg-column [clrDgField]="'status'" [style.width.px]="100">
      <ng-container>Status</ng-container>
      <clr-dg-filter>
        <app-event-status-filter></app-event-status-filter>
      </clr-dg-filter>
    </clr-dg-column>
    <clr-dg-column [clrDgField]="'event.comment'" [style.min-width.px]="400">
      <ng-container>Comment</ng-container>
    </clr-dg-column>

    <clr-dg-row *clrDgItems="let item of events; let i = index" [clrDgItem]="item">
      <clr-dg-cell class="col1">
        <clr-dropdown>
          <button [class]="item.class" clrDropdownTrigger></button>
          <clr-dropdown-menu clrPosition="bottom-left" *clrIfOpen>
            <div aria-label="Dropdown header Acknowledge" clrDropdownItem (click)="acknowledgeEvent(item)"
              [class.disabled]="!item.event.active || item.event.acknowledged">
              Ackn.</div>
            <div aria-label="Dropdown header Close" clrDropdownItem (click)="closeEvent(item)"
              [class.disabled]="!item.event.active">Close</div>
            <!-- <div *ngIf="!item.event.muted" aria-label="Dropdown header Mute"
              clrDropdownItem (click)="muteEvent(item)">Mute</div>
            <div *ngIf="item.event.muted" aria-label="Dropdown header Mute"
              clrDropdownItem (click)="unmuteEvent(item)">Unmute</div> -->
          </clr-dropdown-menu>
        </clr-dropdown>
      </clr-dg-cell>
      <!-- <clr-dg-cell class="left">{{item.event.category}}</clr-dg-cell> -->
      <clr-dg-cell class="left">{{item.customerName}}</clr-dg-cell>
      <clr-dg-cell class="left">{{item.gateway}}</clr-dg-cell>
      <clr-dg-cell class="left">{{item.event.message}}</clr-dg-cell>
      <clr-dg-cell class="left">{{dateHelper.utils.format(item.event.timestamp.toDate(), 'keyboardDateTime')}}
      </clr-dg-cell>
      <!-- <clr-dg-cell class="left">{{item.event.pbClass}}</clr-dg-cell> -->
      <clr-dg-cell class="left">{{item.statusText}}
      </clr-dg-cell>
      <clr-dg-cell *ngIf="!item.editMode" class="left"><cds-icon *ngIf="item.event.comment?.length > 0" shape="pencil"
          size="sm" class="edit-mode" (click)="editComment(item)"></cds-icon>{{ item.event.comment}}</clr-dg-cell>
      <clr-dg-cell *ngIf="item.editMode" class="left">
        <form clrForm clrLayout="vertical" autocomplete="off" spellcheck="false" *ngIf="item.editMode">
          <clr-textarea-container>
            <label class="clr-col-12">Edit comment</label>
            <textarea clrTextarea class="clr-col-12" name="operationinfo" [rows]="4" [cols]="50"
              [(ngModel)]="item.copyComment"></textarea>
          </clr-textarea-container>
        </form>
        <div class="edit-buttons" *ngIf="item.editMode">
          <button type="button" class="btn btn-secondary" (click)="item.editMode = false">
            <clr-icon shape="times"></clr-icon>
            Cancel
          </button>
          <button type="submit" class="btn btn-secondary" (click)="saveComment(item);">
            <clr-icon shape="floppy"></clr-icon>
            Save
          </button>
        </div>
      </clr-dg-cell>

      <app-bms-gateway-events-history *clrIfExpanded ngProjectAs="clr-dg-row-detail"
        [eventsCustomerId]="eventsCustomerId" [customerId]="item.customerId" [eventObjectId]="item.event.id"
        [gatewayId]="item.gatewayId" [event]="item.event"></app-bms-gateway-events-history>
    </clr-dg-row>

    <clr-dg-footer>
      <clr-dg-pagination #pagination [clrDgPageSize]="20">
        <clr-dg-page-size [clrPageSizeOptions]="[10,20,50,100]">
          Notifications</clr-dg-page-size>
        {{pagination.firstItem + 1}} - {{pagination.lastItem + 1}} of {{pagination.totalItems}} notifications
      </clr-dg-pagination>
    </clr-dg-footer>

  </clr-datagrid>

  <table class="table table-noborder table-compact information-dot">
    <tbody>
      <tr>
        <td class="col1" [style.width.px]="40">
          <div class="dot-new"></div>
        </td>
        <td class="left">New event.</td>
      </tr>
      <tr>
        <td class="col1" [style.width.px]="40">
          <div class="dot-open"></div>
        </td>
        <td class="left">Event is acknowledged but still active.</td>
      </tr>
      <tr>
        <td class="col1" [style.width.px]="40">
          <div class="dot-closed"></div>
        </td>
        <td class="left">Event is closed.</td>
      </tr>
    </tbody>
  </table>

</div>
