import { Component, OnInit } from '@angular/core';
import { BmsDataCollectorViewData } from '@core/models/bms-data-collector-view-data';
import { DashboardOutputChangeParameters, OutputDataType, LinkedWidgetSelectParameters, WidgetExportParameters, XprojAlertService } from 'xproj-lib';
import { BmsDataCollectorComponent } from '@features/bms/bms-admin/bms-data-collector-view.component';
import { BmsDataUtils } from '../../../utils/bms-data-utils';
import { XProjectorCustomerUsersClient } from '@xprojectorcore/xprojector_backend/xprojector-customerusers-client';
import { BmsAdminService } from '../../../services/bms-admin-service';
import { BmsMeterInputType } from '@app/features/bms/models/bms-meter';

@Component({
  selector: 'app-bmns-meter-data-collector-view',
  templateUrl: './bms-meter-data-collector-view.component.html',
  styleUrls: ['./bms-meter-data-collector-view.component.scss']
})
export class BmsMeterDataCollectorViewComponent implements OnInit, BmsDataCollectorComponent {

  public static NodeTypeId : string = '_x_bms_meter';

  data: BmsDataCollectorViewData;
  visible: boolean;

  responsiveWidth : number = 600;

  meterParameters : DashboardOutputChangeParameters[] = [];

  diffMeter : boolean = false;
  meterValuesActive : boolean = true;
  sessionsActive : boolean = false;

  constructor(
    private alertService: XprojAlertService,
    private customerUserClient: XProjectorCustomerUsersClient,
    private adminService: BmsAdminService
  ) { }

  ngOnInit(): void {
  }


  async initDataCollectorView() {
    this.responsiveWidth = this.data.width;
    this.updateDashboardOutputs();
    if (this.data.node) {
      let meter = await this.adminService.getMeter(+this.data.node.id);
      if (meter) {
        this.diffMeter = meter.inputMeterType == BmsMeterInputType.DiffMeter;
      }
    }
  }

  setWidth(width: number) {
    if (this.data) {
      this.data.width = width;
    }

    this.responsiveWidth = width;
  }

  updateDashboardOutputs() {
    this.meterParameters = [];
    if (this.data.bmsCustomer) {

      let out = new DashboardOutputChangeParameters();
      out.outputParameterName = 'customerid';
      out.value = this.data.bmsCustomer.customerId;
      out.datatype = OutputDataType.String;
      this.meterParameters.push(out);

      out = new DashboardOutputChangeParameters();
      out.outputParameterName = 'customerxdbid';
      out.value = this.data.bmsCustomer.id;
      out.datatype = OutputDataType.Int64;
      this.meterParameters.push(out);

      out = new DashboardOutputChangeParameters();
      out.outputParameterName = 'id';
      out.value = +this.data.node.id;
      out.datatype = OutputDataType.Int64;
      this.meterParameters.push(out);
    }
  }

  async meterDashboardValueChanged(parameters: LinkedWidgetSelectParameters) {

  }

  async onWidgetExport(parameters: WidgetExportParameters) {
    let result = await this.customerUserClient.exportToExcel(parameters);

    if (!result) {
      this.alertService.error('Error export to excel!');
    }
  }

}
