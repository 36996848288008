import { NgModule, Optional, SkipSelf } from '@angular/core';
import { CommonModule } from '@angular/common';
import { BmsCustomerRoutingModule } from './bms-customer-routing.module';
import { BmsCustomerEventsComponent } from './components/bms-customer-events/bms-customer-events.component';
import { StateService } from '@xprojectorcore/services/state-service';
import { PageItem } from '@xprojectorcore/models/page-item';
import { SharedModule } from '@shared/shared.module';
import { BmsCustomerEventsHistoryComponent } from './components/bms-customer-events-history/bms-customer-events-history.component';
import { BmsCustomerEventStatusPipe, BmsCustomerEventActivePipe } from './pipes';
import { BmsCustomerEventStatusComponent } from './components/bms-customer-event-status/bms-customer-event-status.component';
import { BmsCustomerAdminComponent } from './components/bms-customer-admin/bms-customer-admin.component';
import { BmsCustomerAdminUserComponent } from './components/bms-customer-admin-user/bms-customer-admin-user.component';
import { NgxFileDropModule } from 'ngx-file-drop';
import { BmsCustomerTariffsComponent } from './components/bms-customer-tariffs/bms-customer-tariffs.component';

@NgModule({
  declarations: [
    BmsCustomerEventsComponent,
    BmsCustomerEventsHistoryComponent,
    BmsCustomerEventStatusPipe,
    BmsCustomerEventActivePipe,
    BmsCustomerEventStatusComponent,
    BmsCustomerAdminComponent,
    BmsCustomerAdminUserComponent,
    BmsCustomerTariffsComponent
  ],
  exports: [
    BmsCustomerAdminComponent,
    BmsCustomerTariffsComponent
  ],
  imports: [
    CommonModule,
    SharedModule,
    NgxFileDropModule,

    BmsCustomerRoutingModule
  ]
})
export class BmsCustomerModule {
  constructor(private state : StateService, @Optional() @SkipSelf() parentModule?: BmsCustomerModule) {
    state.addPage( new PageItem(
      BmsCustomerEventsComponent,
      BmsCustomerEventsComponent.PAGEID
    ));
    state.addPage( new PageItem(
      BmsCustomerEventStatusComponent,
      BmsCustomerEventStatusComponent.PAGEID_SMOKE,
      BmsCustomerEventStatusComponent.CATEGORY_SMOKE
    ));
    state.addPage( new PageItem(
      BmsCustomerEventStatusComponent,
      BmsCustomerEventStatusComponent.PAGEID_LEAKAGE,
      BmsCustomerEventStatusComponent.CATEGORY_LEAKAGE
    ));
    state.addPage( new PageItem(
      BmsCustomerAdminComponent,
      BmsCustomerAdminComponent.PAGEID
    ));
    state.addPage( new PageItem(
      BmsCustomerTariffsComponent,
      BmsCustomerTariffsComponent.PAGEID
    ));
  }
}
