import { GrpcNodeProperty } from "@xprojectorcore/xprojector_backend/proto/xprojector.grpc.models.pb";
import { GrpcStringOptionsProperty } from "@xprojectorcore/xprojector_backend/proto/xprojector.xconf.pb";
import { BmsBillingPeriod, BmsMonthOfYear } from "../../../core/models/bms-export-bot";

export class BmsCustomerConfig {
  customerId : string;
  organisationNumber : string;
  associationId : string;
  associationId2 : string;
  tariffDecimalCount : number;
  reportDataPointDecimalCount : number;
  trustee : string;
  customerIsTrustee : boolean;
  billingEnabled : boolean;
  billingPeriod : BmsBillingPeriod = BmsBillingPeriod.Month;
  billingPeriodChangedAt : Date;
  billingPeriodChangedAtString : string;
  billingPeriodStartMonth : BmsMonthOfYear = BmsMonthOfYear.March;
  extrapolateCoefficient : number;
  paddingExternalIdCount : number;
  paddingExternalIdOverrideTrustee: boolean;
  disabled : boolean;
  disabledAt : Date;
  mergeChargingStationsWithEl : boolean;
  contractType : string;
  contractTypeStringOptionsProperty : GrpcStringOptionsProperty;
  simCardCount : number;
  eventsEnabled : boolean;
  information : string;
  operationInfo : string;
  invoiceInfo : string;
  invoiceDay : number = 1;
  partnerCompensation : string;
  partnerCompensationStringOptionsProperty : GrpcStringOptionsProperty;
}
