import { Component, OnInit } from '@angular/core';
import { BmsDataCollectorViewData } from '@core/models/bms-data-collector-view-data';
import { DashboardOutputChangeParameters, OutputDataType, LinkedWidgetSelectParameters } from 'xproj-lib';
import { BmsDataCollectorComponent } from '@features/bms/bms-admin/bms-data-collector-view.component';

@Component({
  selector: 'app-bms-xedge-server-data-collector-view',
  templateUrl: './bms-xedge-server-data-collector-view.component.html',
  styleUrls: ['./bms-xedge-server-data-collector-view.component.scss']
})
export class BmsXEdgeServerDataCollectorViewComponent implements OnInit, BmsDataCollectorComponent {

  public static NodeTypeId : string = '_x_xedge_server';

  data: BmsDataCollectorViewData;
  visible: boolean;

  responsiveWidth : number = 600;

  meterParameters : DashboardOutputChangeParameters[] = [];

  constructor(

  ) { }

  ngOnInit(): void {
  }


  initDataCollectorView() {
    this.responsiveWidth = this.data.width;
    this.updateDashboardOutputs();
  }

  setWidth(width: number) {
    if (this.data) {
      this.data.width = width;
    }

    this.responsiveWidth = width;
  }

  updateDashboardOutputs() {
    this.meterParameters = [];
    if (this.data.bmsCustomer) {

      let out = new DashboardOutputChangeParameters();
      out.outputParameterName = 'customerid';
      out.value = this.data.bmsCustomer.customerId;
      out.datatype = OutputDataType.String;
      this.meterParameters.push(out);

      out = new DashboardOutputChangeParameters();
      out.outputParameterName = 'customerxdbid';
      out.value = this.data.bmsCustomer.id;
      out.datatype = OutputDataType.Int64;
      this.meterParameters.push(out);

      out = new DashboardOutputChangeParameters();
      out.outputParameterName = 'id';
      out.value = this.data.node.id;
      out.datatype = OutputDataType.String;
      this.meterParameters.push(out);

      out = new DashboardOutputChangeParameters();
      out.outputParameterName = 'idint';
      out.value = +this.data.node.id;
      out.datatype = OutputDataType.Int64;
      this.meterParameters.push(out);
    }
  }

  async meterDashboardValueChanged(parameters: LinkedWidgetSelectParameters) {

  }

}
