<div [clrLoading]="loading" class="event-history clr-rows">
  <div class="clr-row">
    <div class="clr-col-4">
      <div class="invoice-info" *ngIf="bmsGatewayInfo">
        <div class="info-paragraph"><b>Contract type: </b>{{bmsCustomer?.contractType ?? ''}}</div>
        <div class="info-paragraph"><b>Last meter value at: </b>{{dateHelper.utils.formatByString(bmsGatewayInfo.lastMeterValueAt, 'yyyy-MM-dd HH:mm')}}</div>
        <div class="info-paragraph"><b>Meter count: </b>{{bmsGatewayInfo.meterCount}}</div>
        <div class="info-paragraph"><b>Meter missing value: </b>{{bmsGatewayInfo.meterCountMissingValue}}</div>
        <button class="btn btn-sm" style="float: right;" (click)="viewCustomer();">View customer <clr-icon shape="arrow" directions="left" style="transform: rotate(90deg);"></clr-icon></button>
      </div>
    </div>
    <div class="clr-col-4">
      <div class="invoice-info" *ngIf="bmsCustomer">
        <div class="info-paragraph" *ngIf="!editMode">
          <cds-icon shape="pencil" size="sm" class="edit-mode" (click)="editOperationInfo()"></cds-icon>
          <b> Operation info</b>
        </div>
        <div class="info-paragraph" *ngIf="!editMode">
          {{bmsCustomer.operationInfo}}
        </div>
        <form clrForm clrLayout="vertical" autocomplete="off" spellcheck="false" *ngIf="bmsCustomer && editMode">
          <clr-textarea-container>
            <label class="clr-col-12">Operation info</label>
            <textarea clrTextarea class="clr-col-12" name="operationinfo" [rows]="6" [cols]="50"
              [(ngModel)]="copyOperationInfo"></textarea>
          </clr-textarea-container>
        </form>
        <div class="edit-buttons" *ngIf="editMode">
          <button type="button" class="btn btn-secondary" (click)="editMode = false">
            <clr-icon shape="times"></clr-icon>
            Cancel
          </button>
          <button type="submit" class="btn btn-secondary" (click)="saveBmsCustomer();">
            <clr-icon shape="floppy"></clr-icon>
            Save
          </button>
        </div>
      </div>
    </div>
    <div class="clr-col-4">
      <div class="invoice-info" *ngIf="gatewayOfflineFlag">
        <div class="customer-mail-area">
          <div class="info-paragraph">
            <b> Customer mail</b>
          </div>
          <div class="info-paragraph">Status: {{gatewayOfflineFlag.status > 0 ? 'Sent' : 'Not sent'}}</div>
          <div class="info-paragraph mailStatus">Sent at: {{gatewayOfflineFlag.status > 0 ?
            dateHelper.utils.formatByString(gatewayOfflineFlag.modifiedAt, 'yyyy-MM-dd HH:mm') : '---'}}</div>
          <button type="button" class="btn btn-outline info-paragraph mailStatus" (click)="sendGatewayOfflineMail()"
            [disabled]="!event || !event.acknowledged">
            <clr-icon shape="email"></clr-icon>
            Send mail to customer...
          </button>
          <button type="button" class="btn btn-outline info-paragraph mailStatus" (click)="sendGatewayOfflineMailDebug()"
            [disabled]="!event">
            <clr-icon shape="email"></clr-icon>
            Send debug mail
          </button>
        </div>
      </div>
    </div>
  </div>
  <div class="clr-row">
    <table class="table table-noborder">
      <thead>
        <tr>
          <th class="left">Timestamp</th>
          <th class="left">Status</th>
          <th class="left">High</th>
          <th class="left">Text</th>
        </tr>
      </thead>
      <tbody>
        <tr *ngFor="let item of events">
          <td class="left">{{dateHelper.utils.format(item.timestamp.toDate(), 'keyboardDateTime')}}</td>
          <td class="left">{{item | bmseventstatuspipe}}</td>
          <td class="left">{{item.high | bmseventactivepipe}}</td>
          <td class="left">{{item.comment}}</td>
        </tr>
      </tbody>
    </table>
  </div>
</div>
