import { Component, Input, OnInit } from '@angular/core';
import { BmsCustomer } from '@features/bms/models/bms-customer';
import { XProjectorXConfClient } from '@xprojectorcore/xprojector_backend/xprojector-xconf-client';
import { BmsAdminService } from '@features/bms/bms-admin/services/bms-admin-service';
import { BmsCustomerUser } from '@features/bms/models/bms-customer-user';
import { BmsDataUtils } from '@features/bms/bms-admin/utils/bms-data-utils';
import { ArrayUtils, DateHelper, XprojModalService } from 'xproj-lib';
import { Router } from '@angular/router';
import { Customer } from '@xprojectorcore/xprojector_backend/proto/xprojector.grpc.models.pb';

@Component({
  selector: 'app-bms-external-users',
  templateUrl: './bms-external-users.component.html',
  styleUrls: ['./bms-external-users.component.scss']
})
export class BmsExternalUsersComponent implements OnInit {

  @Input() customer : BmsCustomer;
  @Input() customers: Customer[];

  externalUsers: BmsCustomerUser[] = [];
  selectedExternalUser : BmsCustomerUser

  constructor(
    private xconfClient: XProjectorXConfClient,
    private adminService: BmsAdminService,
    private modalService: XprojModalService,
    private router: Router,
    private dateHelper: DateHelper
) { }

  async ngOnInit() {
    this.externalUsers = await this.getExternalUsers();
  }

  async getExternalUsers() : Promise<BmsCustomerUser[]> {
    let result : BmsCustomerUser[] = [];
    if (this.customer) {
      let nodes = await this.xconfClient.getRefereringNodes(this.customer.customerId, '_x_datasource', ['_x_node_customeruser_hascustomerid'], ['_x_customerusers_user'], 1);

      nodes.forEach(node => {
        let customerUser = new BmsCustomerUser();
        customerUser.id = node.id;

        let s = node.id.split('_');
        if (s.length > 1) {
          customerUser.customerId = s[1];
          let customer = this.customers?.find(c => c.id == customerUser.customerId);
          if (customer) {
            customerUser.customerName = customer.name;
          }
        }

        node.propertyValues.forEach(p => {
          switch (p.key) {
            case 'firstname':
              customerUser.firstname = BmsDataUtils.getValue(p.valueType, p.value, p, this.dateHelper);
              break;
            case 'surname':
              customerUser.surname = BmsDataUtils.getValue(p.valueType, p.value, p, this.dateHelper);
              break;
            case 'email':
              customerUser.email = BmsDataUtils.getValue(p.valueType, p.value, p, this.dateHelper);
              break;
          }
        });

        result.push(customerUser);
      });
    }

    return result;
  }

  selectedExternalUserChanged(user : BmsCustomerUser) {

  }

  async removeExternalUser(externalUser : BmsCustomerUser) {
    if (externalUser) {
      this.modalService.ShowConfirmModal({ header: 'Remove external user', description: 'Remove external users access to this customer, are you sure?' }, async (result) => {
        if (result) {
          await this.xconfClient.deleteReference(externalUser.id, '_x_customerusers_user', this.customer.customerId,
                  '_x_datasource', '_x_node_customeruser_hascustomerid', '', this.customer.customerId);
          this.externalUsers = this.externalUsers.filter(u => u.id != externalUser.id);
        }
      });
    }
  }

  async viewCustomer() {
    if (this.selectedExternalUser) {
      this.router.navigateByUrl('/bmsadmin/customers/' + this.selectedExternalUser.customerId + '/users');
    }
  }


}
