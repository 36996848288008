import { AfterViewInit, ChangeDetectorRef, Component, OnInit, QueryList, ViewChild, ViewChildren } from '@angular/core';
import { BmsMeter } from '@features/bms/models/bms-meter';
import { ConfigurationDataSourceComponent } from '@xprojectorfeatures/xconf/components/configuration-datasource/configuration-datasource.component';
import { GrpcNode, GrpcNodeProperty } from '@xprojectorcore/xprojector_backend/proto/xprojector.grpc.models.pb';
import { GrpcDataSourceInstance, GrpcNodeType, SearchNodesRequest, SearchNodesResponse, SearchProperty } from '@xprojectorcore/xprojector_backend/proto/xprojector.xconf.pb';
import { XProjectorXConfClient } from '@xprojectorcore/xprojector_backend/xprojector-xconf-client';
import { ClrDatagridStateInterface } from '@clr/angular';
import { SplitAreaDirective } from 'angular-split';
import { Aggregation, BaseQuery, BaseQueryInputColumnDescription, ColumnFilteringNumerical, ColumnFilteringRelativeTimestamp, ColumnFilteringString, ColumnFilteringTimestamp, DashboardOutputChangeParameters, FilterComparator, FilterLogicalGroup, FilterLogicalGroupType, MsgPackCloneObject, ProjectionColumnDescription, WidgetUtils, XDataType, XprojAlertService, XprojDashboardComponent, XprojDatagridstringfilterComponent, XProjectorClient } from 'xproj-lib';
import { BmsApartmentViewComponent } from '../bms-apartment-view/bms-apartment-view.component';
import { BmsBillingTariffViewComponent } from '../bms-billing-tariff-view/bms-billing-tariff-view.component';
import { BmsBuildingAddressViewComponent } from '../bms-building-address-view/bms-building-address-view.component';
import { BmsMeterViewComponent } from '../bms-meter-view/bms-meter-view.component';
import { BmsRealestateViewComponent } from '../bms-realestate-view/bms-realestate-view.component';
import { BmsBuildingViewComponent } from '../bms-building-view/bms-building-view.component';
import { BmsEditMode } from '@features/bms/models/bms-edit.mode';
import { BmsEnumMember } from '@features/bms/models/bms-enum-member';
import { BmsMeterListComponent } from '../bms-meter-list/bms-meter-list.component';
import { BmsCustomerViewComponent } from '../bms-customer-view/bms-customer-view.component';
import { BmsAdminService } from '@features/bms/bms-admin/services/bms-admin-service';
import { StringifyOptions } from 'querystring';
import { XconfTreeNode } from '@xprojectorfeatures/xconf/models/xconf-tree-node';

class BmsMeterAdminFilter {
  realestateIds: number[] = [];
  buildingids: number[] = [];
  buildingaddressIds: number[] = [];
  apartmentIds: number[] = [];
}

export class BmsQueryDataColumn {
  id: string;
  columnname: string;
  label: string;
  datatype: XDataType;
  hidden: boolean = false;
  data: any[] = [];
  editMode: BmsEditMode = BmsEditMode.String;
  enumMembers: BmsEnumMember[] = [];
}

class TableData {
  columns: BmsQueryDataColumn[] = [];
  data_col0: any = [];
  initilized: boolean = false;
  oldInitilized: boolean = false;
}

class ColumnConfig {
  public columnName: string = '';
  public label: string = '';
  public columnOutName: string = '';
  public hidden: boolean = false;
  public datatype: XDataType = XDataType.Number;
  public unit: string = '';
  public tags: string[] = [];
  public editMode: BmsEditMode = BmsEditMode.String;
  public enumMembers: BmsEnumMember[] = [];
}

@Component({
  selector: 'app-bms-meter-admin',
  templateUrl: './bms-meter-admin.component.html',
  styleUrls: ['./bms-meter-admin.component.scss']
})
export class BmsMeterAdminComponent implements OnInit, AfterViewInit {
  @ViewChild("meterViev", { read: BmsMeterViewComponent, static: false }) meterView: BmsMeterViewComponent;
  @ViewChild("meterVievData", { read: BmsMeterViewComponent, static: false }) meterViewData: BmsMeterViewComponent;
  @ViewChild("buildingAddressView", { read: BmsBuildingAddressViewComponent, static: false }) buildingAddressView: BmsBuildingAddressViewComponent;
  @ViewChild("buildingView", { read: BmsBuildingViewComponent, static: false }) buildingView: BmsBuildingViewComponent;
  @ViewChild("apartmentView", { read: BmsApartmentViewComponent, static: false }) apartmentView: BmsApartmentViewComponent;
  @ViewChild("realestateView", { read: BmsRealestateViewComponent, static: false }) realestateView: BmsRealestateViewComponent;
  @ViewChild("billingTariffView", { read: BmsBillingTariffViewComponent, static: false }) billingTariffView: BmsBillingTariffViewComponent;
  @ViewChild("customerView", { read: BmsCustomerViewComponent, static: false }) customerView: BmsCustomerViewComponent;

  @ViewChild("bmsMeterList", { read: BmsMeterListComponent, static: false }) bmsMeterList: BmsMeterListComponent;
  @ViewChild("bmsTree", { read: ConfigurationDataSourceComponent, static: false }) bmsTree: ConfigurationDataSourceComponent;

  @ViewChildren(SplitAreaDirective) areasEl: QueryList<SplitAreaDirective>

  rightPanelWidth: number = 300;
  contentPanelWidth : number = 834;

  filter: BmsMeterAdminFilter = new BmsMeterAdminFilter();
  columns: BaseQueryInputColumnDescription[];
  columnConfigs: ColumnConfig[] = [];
  tableData: TableData = new TableData();
  sizeOptions = [10, 20, 50, 100];
  projectioncolumns: ProjectionColumnDescription[] = [];

  state: any;
  currentPage: number = 1;
  loadingQuery: boolean = false;
  loading: boolean = true;
  queryNrRows: number = 0;
  queryNrTotalRows: number = 0;
  selected = -1;
  selectedMeter: BmsMeter;
  selectedNode: GrpcNode;
  selectedNodeParent: GrpcNode;

  searching: boolean = false;
  searchResult: SearchNodesResponse;
  nameFilter: string;
  filterNodes: GrpcNode[] = [];

  customerId : string;

  bmsDataSourceInstance: GrpcDataSourceInstance;
  bmsSelectedPath: string[] = [];

  //apartmentsById : Map<number,

  showColumns: { id: string, label: string }[] = [
    {
      id: 'meterid',
      label: 'Meter Id'
    },
    {
      id: 'vendor',
      label: 'Vendor'
    },
    {
      id: 'unit',
      label: 'Unit'
    },
    {
      id: 'metertype',
      label: 'Type'
    },
    {
      id: 'model',
      label: 'Model'
    },
    {
      id: 'state',
      label: 'State'
    }
  ];

  _meterExpanded: boolean = false;
  get meterExpanded(): boolean {
    return this._meterExpanded;
  }
  set meterExpanded(value: boolean) {
    if (value) {
      this.updateMeterView(this.selectedMeter?.id);
    }
    this._meterExpanded = value;
  }

  _meterDataExpanded: boolean = false;
  get meterDataExpanded(): boolean {
    return this._meterDataExpanded;
  }
  set meterDataExpanded(value: boolean) {
    if (value) {
      this.updateMeterViewData(this.selectedMeter?.id);
    }
    this._meterDataExpanded = value;
  }

  _apartmentExpanded: boolean = false;
  get apartmentExpanded(): boolean {
    return this._apartmentExpanded;
  }
  set apartmentExpanded(value: boolean) {
    if (value) {
      this.updateApartmentView(this.selectedMeter?.apartmentId);
    }
    this._apartmentExpanded = value;
  }

  _buildingExpanded: boolean = false;
  get buildingExpanded(): boolean {
    return this._buildingExpanded;
  }
  set buildingExpanded(value: boolean) {
    this._buildingExpanded = value;
    if (value) {
      this.updateBuildingView(this.selectedMeter?.buildingId);
    }
  }

  _buildingAddressExpanded: boolean = false;
  get buildingAddressExpanded(): boolean {
    return this._buildingAddressExpanded;
  }
  set buildingAddressExpanded(value: boolean) {
    this._buildingAddressExpanded = value;
    if (value) {
      this.updateBuildingAddressView(this.selectedMeter?.buildingAddressId);
    }
  }

  _realestateExpanded: boolean = false;
  get realestateExpanded(): boolean {
    return this._realestateExpanded;
  }
  set realestateExpanded(value: boolean) {
    if (value) {
      this.updateRealestateView(this.selectedMeter?.realestateId);
    }
    this._realestateExpanded = value;
  }

  _billingTariffExpanded: boolean = false;
  get billingTariffExpanded(): boolean {
    return this._billingTariffExpanded;
  }
  set billingTariffExpanded(value: boolean) {
    if (value) {
      this.updateBillingTariffView(this.selectedMeter?.id);
    }
    this._billingTariffExpanded = value;
  }

  _customerExpanded: boolean = false;
  get customerExpanded(): boolean {
    return this._customerExpanded;
  }
  set customerExpanded(value: boolean) {
    if (value) {
      this.updateCustomerView(this.selectedMeter?.realestateId);
    }
    this._customerExpanded = value;
  }


  constructor(
    private alertService: XprojAlertService,
    private adminService: BmsAdminService,
    private xConfClient: XProjectorXConfClient,
    private cdr: ChangeDetectorRef) {

  }


  async ngOnInit() {
    let lsrightPanelWidth = Number.parseInt(localStorage.getItem("xprojector-bmsrightPanelWidth") || this.rightPanelWidth.toString());
    if (lsrightPanelWidth != this.rightPanelWidth) {
      this.rightPanelWidth = lsrightPanelWidth;
    }
    let lscontentPanelWidth = Number.parseInt(localStorage.getItem("xprojector-bmscontentPanelWidth") || this.contentPanelWidth.toString());
    if (lscontentPanelWidth != this.contentPanelWidth) {
      this.contentPanelWidth = lscontentPanelWidth;
    }

    this.bmsDataSourceInstance = await this.xConfClient.getDataSourceInstance('_x_bms_datasource');
  }

  ngAfterViewInit(): void {

  }

  async onSplitDragEnd($event) {
    let treePaneArea = this.getPaneArea(2);

    if (treePaneArea) {
      this.rightPanelWidth = treePaneArea.elRef.nativeElement.clientWidth;
      localStorage.setItem("xprojector-bmsrightPanelWidth", this.rightPanelWidth.toString());
    }

    let contentPaneArea = this.getPaneArea(1);

    if (contentPaneArea) {
      this.contentPanelWidth = contentPaneArea.elRef.nativeElement.clientWidth;
      localStorage.setItem("xprojector-bmscontentPanelWidth", this.contentPanelWidth.toString());
    }
  }

  getPaneArea(order: number): SplitAreaDirective {
    let result: SplitAreaDirective = undefined;
    this.areasEl.forEach(area => {
      if (area.order == order) {
        result = area;
      }
    });

    return result;
  }

  async onSelectBmsTreeNode(item: { treeNode: XconfTreeNode, nodeType: GrpcNodeType }) {
    if (item.treeNode) {

      if (item.treeNode.node.nodeTypeLabel == '_x_bms_meter') {
        let parent : XconfTreeNode = item.treeNode.parent as XconfTreeNode;
        this.selectedNode = parent.node;
        parent = parent.parent as XconfTreeNode;
        this.selectedNodeParent = parent?.node;
      }
      else {
        this.selectedNode = item.treeNode.node;
        let parent: XconfTreeNode = item.treeNode.parent as XconfTreeNode;
        this.selectedNodeParent = parent?.node;
      }

      this.cdr.detectChanges();
      this.bmsMeterList.search();
    }

  }

  async onSelectMeter(meter : BmsMeter) {
    this.selectedMeter = meter;
    await this.resetViews();
    // setTimeout(() => {
    //   this.meterExpanded = true;
    // });
    this.meterExpanded = true;
  }

  async resetViews() {
    await this.updateMeterView(-1);
    await this.updateApartmentView(-1);
    await this.updateBuildingAddressView(-1);
    await this.updateBuildingView(-1);
    await this.updateRealestateView(-1);
    await this.updateBillingTariffView(-1);
    await this.updateCustomerView(-1);
    this.meterExpanded = false;
    this.meterDataExpanded = false;
    this.apartmentExpanded = false;
    this.buildingAddressExpanded = false;
    this.buildingExpanded = false;
    this.realestateExpanded = false;
    this.billingTariffExpanded = false;
    this.customerId = '';
  }

  async updateCustomerId() {
    if (this.selectedMeter && (!this.customerId || this.customerId.length == 0)) {
      this.customerId = await this.adminService.getCustomerIdByRealestate(this.selectedMeter?.realestateId);
      //this.bmsSelectedPath = await this.xConfClient.getShortestPath('_x_bms_datasource', '_x_datasource', this.customerId, '_x_datasource', 10);
    }
    if (this.selectedMeter && this.selectedMeter.realestateId > 0) {
      try {
        this.bmsSelectedPath = await this.xConfClient.getShortestPath('_x_bms_datasource', '_x_datasource', this.selectedMeter.realestateId + '', '_x_bms_realestate', 10);
        //this.bmsSelectedPath = ['_x_bms_root', 'dalarnaslän', 'borlängekommun', '2461743794094080', '2461744423239680'];
        this.bmsTree.selectedPath = this.bmsSelectedPath;
        await this.bmsTree.initDataSourceInstance(false, false);
      }
      catch {}
    }
  }

  async updateMeterView(meterId: number) {
    if (meterId > 0) {
      await this.updateCustomerId();
      this.meterView?.update(meterId, this.customerId);
    }
    else {
      this.meterView?.update(meterId, '');
    }
  }

  async updateMeterViewData(meterId: number) {
    if (meterId > 0) {
      await this.updateCustomerId();
      this.meterViewData?.update(meterId, this.customerId);
    }
    else {
      this.meterViewData?.update(meterId, '');
    }
  }

  async updateApartmentView(apartmentId: number) {
    if (apartmentId > 0) {
      await this.updateCustomerId();
      this.apartmentView?.update(apartmentId, this.customerId);
    }
    else {
      this.apartmentView?.update(apartmentId, '');
    }
  }

  async updateBuildingView(buildingId: number) {
    if (buildingId > 0) {
      await this.updateCustomerId();
      this.buildingView?.update(buildingId, this.customerId);
    }
    else {
      this.buildingView?.update(buildingId, '');
    }
  }

  async updateBuildingAddressView(buildingAddressId: number) {
    if (buildingAddressId > 0) {
      await this.updateCustomerId();
      this.buildingAddressView?.update(buildingAddressId, this.customerId);
    }
    else {
      this.buildingAddressView?.update(buildingAddressId, '');
    }
  }

  async updateRealestateView(realestateId) {
    if (realestateId > 0) {
      await this.updateCustomerId();
      this.realestateView?.update(realestateId, this.customerId);
    }
    else {
      this.realestateView?.update(realestateId, '');
    }
  }

  async updateBillingTariffView(meterId) {
    this.billingTariffView?.update(meterId);
  }

  async updateCustomerView(realestateId : number ) {
    if (this.customerView) {
      if (realestateId > 0) {
        await this.updateCustomerId();
        this.customerView.update(this.customerId);
      }
      else {
        this.customerView.update('');
      }
    }
  }
}
