import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { BmsCustomer } from '@features/bms/models/bms-customer';
import { XProjectorXConfClient } from '@xprojectorcore/xprojector_backend/xprojector-xconf-client';
import { DateHelper } from 'xproj-lib';
import { BmsAdminService } from '../../services/bms-admin-service';

@Component({
  selector: 'app-bms-customer-view',
  templateUrl: './bms-customer-view.component.html',
  styleUrls: ['./bms-customer-view.component.scss']
})
export class BmsCustomerViewComponent implements OnInit {

  bmsCustomer: BmsCustomer = null;

  constructor(
    private xconfClient: XProjectorXConfClient,
    private adminService: BmsAdminService,
    private router: Router,
    private dateHelper: DateHelper
  ) { }

  ngOnInit(): void {
  }

  async update(customerId: string) {
    if (customerId?.length > 0) {
      this.bmsCustomer = await this.adminService.getCustomer(customerId);
    }
    else {
      this.bmsCustomer = null;
    }
  }

  async viewInBMS() {
    this.router.navigateByUrl('/bmsadmin/customers/' + this.bmsCustomer.customerId);
  }
}
