import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { BmsEventComponent } from './components/bms-event/bms-event.component';
import { BmsEventsRoutingModule } from './bms-events-routing.module';
import { SharedModule } from '@shared/shared.module';
import { BmsUnsubscribeEventsComponent } from './components/bms-unsubscribe-events/bms-unsubscribe-events.component';



@NgModule({
  declarations: [
    BmsEventComponent,
    BmsUnsubscribeEventsComponent
  ],
  imports: [
    CommonModule,
    SharedModule,

    BmsEventsRoutingModule
  ]
})
export class BmsEventsModule { }
