/* tslint:disable */
/* eslint-disable */
// @ts-nocheck
//
// THIS IS A GENERATED FILE
// DO NOT MODIFY IT! YOUR CHANGES WILL BE LOST
import {
  GrpcMessage,
  RecursivePartial,
  ToProtobufJSONOptions
} from '@ngx-grpc/common';
import { BinaryReader, BinaryWriter, ByteSource } from 'google-protobuf';
import * as googleProtobuf000 from './google/protobuf/timestamp.pb';
/**
 * Message implementation for modulebms.events.AcknowledgeEventRequest
 */
export class AcknowledgeEventRequest implements GrpcMessage {
  static id = 'modulebms.events.AcknowledgeEventRequest';

  /**
   * Deserialize binary data to message
   * @param instance message instance
   */
  static deserializeBinary(bytes: ByteSource) {
    const instance = new AcknowledgeEventRequest();
    AcknowledgeEventRequest.deserializeBinaryFromReader(
      instance,
      new BinaryReader(bytes)
    );
    return instance;
  }

  /**
   * Check all the properties and set default protobuf values if necessary
   * @param _instance message instance
   */
  static refineValues(_instance: AcknowledgeEventRequest) {
    _instance.customerId = _instance.customerId || '';
    _instance.eventId = _instance.eventId || '0';
    _instance.comment = _instance.comment || '';
    _instance.user = _instance.user || '';
    _instance.userTypeId = _instance.userTypeId || '';
  }

  /**
   * Deserializes / reads binary message into message instance using provided binary reader
   * @param _instance message instance
   * @param _reader binary reader instance
   */
  static deserializeBinaryFromReader(
    _instance: AcknowledgeEventRequest,
    _reader: BinaryReader
  ) {
    while (_reader.nextField()) {
      if (_reader.isEndGroup()) break;

      switch (_reader.getFieldNumber()) {
        case 1:
          _instance.customerId = _reader.readString();
          break;
        case 2:
          _instance.eventId = _reader.readInt64String();
          break;
        case 3:
          _instance.comment = _reader.readString();
          break;
        case 4:
          _instance.user = _reader.readString();
          break;
        case 5:
          _instance.userTypeId = _reader.readString();
          break;
        default:
          _reader.skipField();
      }
    }

    AcknowledgeEventRequest.refineValues(_instance);
  }

  /**
   * Serializes a message to binary format using provided binary reader
   * @param _instance message instance
   * @param _writer binary writer instance
   */
  static serializeBinaryToWriter(
    _instance: AcknowledgeEventRequest,
    _writer: BinaryWriter
  ) {
    if (_instance.customerId) {
      _writer.writeString(1, _instance.customerId);
    }
    if (_instance.eventId) {
      _writer.writeInt64String(2, _instance.eventId);
    }
    if (_instance.comment) {
      _writer.writeString(3, _instance.comment);
    }
    if (_instance.user) {
      _writer.writeString(4, _instance.user);
    }
    if (_instance.userTypeId) {
      _writer.writeString(5, _instance.userTypeId);
    }
  }

  private _customerId?: string;
  private _eventId?: string;
  private _comment?: string;
  private _user?: string;
  private _userTypeId?: string;

  /**
   * Message constructor. Initializes the properties and applies default Protobuf values if necessary
   * @param _value initial values object or instance of AcknowledgeEventRequest to deeply clone from
   */
  constructor(_value?: RecursivePartial<AcknowledgeEventRequest.AsObject>) {
    _value = _value || {};
    this.customerId = _value.customerId;
    this.eventId = _value.eventId;
    this.comment = _value.comment;
    this.user = _value.user;
    this.userTypeId = _value.userTypeId;
    AcknowledgeEventRequest.refineValues(this);
  }
  get customerId(): string | undefined {
    return this._customerId;
  }
  set customerId(value: string | undefined) {
    this._customerId = value;
  }
  get eventId(): string | undefined {
    return this._eventId;
  }
  set eventId(value: string | undefined) {
    this._eventId = value;
  }
  get comment(): string | undefined {
    return this._comment;
  }
  set comment(value: string | undefined) {
    this._comment = value;
  }
  get user(): string | undefined {
    return this._user;
  }
  set user(value: string | undefined) {
    this._user = value;
  }
  get userTypeId(): string | undefined {
    return this._userTypeId;
  }
  set userTypeId(value: string | undefined) {
    this._userTypeId = value;
  }

  /**
   * Serialize message to binary data
   * @param instance message instance
   */
  serializeBinary() {
    const writer = new BinaryWriter();
    AcknowledgeEventRequest.serializeBinaryToWriter(this, writer);
    return writer.getResultBuffer();
  }

  /**
   * Cast message to standard JavaScript object (all non-primitive values are deeply cloned)
   */
  toObject(): AcknowledgeEventRequest.AsObject {
    return {
      customerId: this.customerId,
      eventId: this.eventId,
      comment: this.comment,
      user: this.user,
      userTypeId: this.userTypeId
    };
  }

  /**
   * Convenience method to support JSON.stringify(message), replicates the structure of toObject()
   */
  toJSON() {
    return this.toObject();
  }

  /**
   * Cast message to JSON using protobuf JSON notation: https://developers.google.com/protocol-buffers/docs/proto3#json
   * Attention: output differs from toObject() e.g. enums are represented as names and not as numbers, Timestamp is an ISO Date string format etc.
   * If the message itself or some of descendant messages is google.protobuf.Any, you MUST provide a message pool as options. If not, the messagePool is not required
   */
  toProtobufJSON(
    // @ts-ignore
    options?: ToProtobufJSONOptions
  ): AcknowledgeEventRequest.AsProtobufJSON {
    return {
      customerId: this.customerId,
      eventId: this.eventId,
      comment: this.comment,
      user: this.user,
      userTypeId: this.userTypeId
    };
  }
}
export module AcknowledgeEventRequest {
  /**
   * Standard JavaScript object representation for AcknowledgeEventRequest
   */
  export interface AsObject {
    customerId?: string;
    eventId?: string;
    comment?: string;
    user?: string;
    userTypeId?: string;
  }

  /**
   * Protobuf JSON representation for AcknowledgeEventRequest
   */
  export interface AsProtobufJSON {
    customerId?: string;
    eventId?: string;
    comment?: string;
    user?: string;
    userTypeId?: string;
  }
}

/**
 * Message implementation for modulebms.events.AcknowledgeEventResponse
 */
export class AcknowledgeEventResponse implements GrpcMessage {
  static id = 'modulebms.events.AcknowledgeEventResponse';

  /**
   * Deserialize binary data to message
   * @param instance message instance
   */
  static deserializeBinary(bytes: ByteSource) {
    const instance = new AcknowledgeEventResponse();
    AcknowledgeEventResponse.deserializeBinaryFromReader(
      instance,
      new BinaryReader(bytes)
    );
    return instance;
  }

  /**
   * Check all the properties and set default protobuf values if necessary
   * @param _instance message instance
   */
  static refineValues(_instance: AcknowledgeEventResponse) {
    _instance.ok = _instance.ok || false;
    _instance.message = _instance.message || '';
  }

  /**
   * Deserializes / reads binary message into message instance using provided binary reader
   * @param _instance message instance
   * @param _reader binary reader instance
   */
  static deserializeBinaryFromReader(
    _instance: AcknowledgeEventResponse,
    _reader: BinaryReader
  ) {
    while (_reader.nextField()) {
      if (_reader.isEndGroup()) break;

      switch (_reader.getFieldNumber()) {
        case 1:
          _instance.ok = _reader.readBool();
          break;
        case 2:
          _instance.message = _reader.readString();
          break;
        default:
          _reader.skipField();
      }
    }

    AcknowledgeEventResponse.refineValues(_instance);
  }

  /**
   * Serializes a message to binary format using provided binary reader
   * @param _instance message instance
   * @param _writer binary writer instance
   */
  static serializeBinaryToWriter(
    _instance: AcknowledgeEventResponse,
    _writer: BinaryWriter
  ) {
    if (_instance.ok) {
      _writer.writeBool(1, _instance.ok);
    }
    if (_instance.message) {
      _writer.writeString(2, _instance.message);
    }
  }

  private _ok?: boolean;
  private _message?: string;

  /**
   * Message constructor. Initializes the properties and applies default Protobuf values if necessary
   * @param _value initial values object or instance of AcknowledgeEventResponse to deeply clone from
   */
  constructor(_value?: RecursivePartial<AcknowledgeEventResponse.AsObject>) {
    _value = _value || {};
    this.ok = _value.ok;
    this.message = _value.message;
    AcknowledgeEventResponse.refineValues(this);
  }
  get ok(): boolean | undefined {
    return this._ok;
  }
  set ok(value: boolean | undefined) {
    this._ok = value;
  }
  get message(): string | undefined {
    return this._message;
  }
  set message(value: string | undefined) {
    this._message = value;
  }

  /**
   * Serialize message to binary data
   * @param instance message instance
   */
  serializeBinary() {
    const writer = new BinaryWriter();
    AcknowledgeEventResponse.serializeBinaryToWriter(this, writer);
    return writer.getResultBuffer();
  }

  /**
   * Cast message to standard JavaScript object (all non-primitive values are deeply cloned)
   */
  toObject(): AcknowledgeEventResponse.AsObject {
    return {
      ok: this.ok,
      message: this.message
    };
  }

  /**
   * Convenience method to support JSON.stringify(message), replicates the structure of toObject()
   */
  toJSON() {
    return this.toObject();
  }

  /**
   * Cast message to JSON using protobuf JSON notation: https://developers.google.com/protocol-buffers/docs/proto3#json
   * Attention: output differs from toObject() e.g. enums are represented as names and not as numbers, Timestamp is an ISO Date string format etc.
   * If the message itself or some of descendant messages is google.protobuf.Any, you MUST provide a message pool as options. If not, the messagePool is not required
   */
  toProtobufJSON(
    // @ts-ignore
    options?: ToProtobufJSONOptions
  ): AcknowledgeEventResponse.AsProtobufJSON {
    return {
      ok: this.ok,
      message: this.message
    };
  }
}
export module AcknowledgeEventResponse {
  /**
   * Standard JavaScript object representation for AcknowledgeEventResponse
   */
  export interface AsObject {
    ok?: boolean;
    message?: string;
  }

  /**
   * Protobuf JSON representation for AcknowledgeEventResponse
   */
  export interface AsProtobufJSON {
    ok?: boolean;
    message?: string;
  }
}

/**
 * Message implementation for modulebms.events.ClearEventRequest
 */
export class ClearEventRequest implements GrpcMessage {
  static id = 'modulebms.events.ClearEventRequest';

  /**
   * Deserialize binary data to message
   * @param instance message instance
   */
  static deserializeBinary(bytes: ByteSource) {
    const instance = new ClearEventRequest();
    ClearEventRequest.deserializeBinaryFromReader(
      instance,
      new BinaryReader(bytes)
    );
    return instance;
  }

  /**
   * Check all the properties and set default protobuf values if necessary
   * @param _instance message instance
   */
  static refineValues(_instance: ClearEventRequest) {
    _instance.customerId = _instance.customerId || '';
    _instance.eventId = _instance.eventId || '0';
    _instance.comment = _instance.comment || '';
    _instance.user = _instance.user || '';
    _instance.userTypeId = _instance.userTypeId || '';
  }

  /**
   * Deserializes / reads binary message into message instance using provided binary reader
   * @param _instance message instance
   * @param _reader binary reader instance
   */
  static deserializeBinaryFromReader(
    _instance: ClearEventRequest,
    _reader: BinaryReader
  ) {
    while (_reader.nextField()) {
      if (_reader.isEndGroup()) break;

      switch (_reader.getFieldNumber()) {
        case 1:
          _instance.customerId = _reader.readString();
          break;
        case 2:
          _instance.eventId = _reader.readInt64String();
          break;
        case 3:
          _instance.comment = _reader.readString();
          break;
        case 4:
          _instance.user = _reader.readString();
          break;
        case 5:
          _instance.userTypeId = _reader.readString();
          break;
        default:
          _reader.skipField();
      }
    }

    ClearEventRequest.refineValues(_instance);
  }

  /**
   * Serializes a message to binary format using provided binary reader
   * @param _instance message instance
   * @param _writer binary writer instance
   */
  static serializeBinaryToWriter(
    _instance: ClearEventRequest,
    _writer: BinaryWriter
  ) {
    if (_instance.customerId) {
      _writer.writeString(1, _instance.customerId);
    }
    if (_instance.eventId) {
      _writer.writeInt64String(2, _instance.eventId);
    }
    if (_instance.comment) {
      _writer.writeString(3, _instance.comment);
    }
    if (_instance.user) {
      _writer.writeString(4, _instance.user);
    }
    if (_instance.userTypeId) {
      _writer.writeString(5, _instance.userTypeId);
    }
  }

  private _customerId?: string;
  private _eventId?: string;
  private _comment?: string;
  private _user?: string;
  private _userTypeId?: string;

  /**
   * Message constructor. Initializes the properties and applies default Protobuf values if necessary
   * @param _value initial values object or instance of ClearEventRequest to deeply clone from
   */
  constructor(_value?: RecursivePartial<ClearEventRequest.AsObject>) {
    _value = _value || {};
    this.customerId = _value.customerId;
    this.eventId = _value.eventId;
    this.comment = _value.comment;
    this.user = _value.user;
    this.userTypeId = _value.userTypeId;
    ClearEventRequest.refineValues(this);
  }
  get customerId(): string | undefined {
    return this._customerId;
  }
  set customerId(value: string | undefined) {
    this._customerId = value;
  }
  get eventId(): string | undefined {
    return this._eventId;
  }
  set eventId(value: string | undefined) {
    this._eventId = value;
  }
  get comment(): string | undefined {
    return this._comment;
  }
  set comment(value: string | undefined) {
    this._comment = value;
  }
  get user(): string | undefined {
    return this._user;
  }
  set user(value: string | undefined) {
    this._user = value;
  }
  get userTypeId(): string | undefined {
    return this._userTypeId;
  }
  set userTypeId(value: string | undefined) {
    this._userTypeId = value;
  }

  /**
   * Serialize message to binary data
   * @param instance message instance
   */
  serializeBinary() {
    const writer = new BinaryWriter();
    ClearEventRequest.serializeBinaryToWriter(this, writer);
    return writer.getResultBuffer();
  }

  /**
   * Cast message to standard JavaScript object (all non-primitive values are deeply cloned)
   */
  toObject(): ClearEventRequest.AsObject {
    return {
      customerId: this.customerId,
      eventId: this.eventId,
      comment: this.comment,
      user: this.user,
      userTypeId: this.userTypeId
    };
  }

  /**
   * Convenience method to support JSON.stringify(message), replicates the structure of toObject()
   */
  toJSON() {
    return this.toObject();
  }

  /**
   * Cast message to JSON using protobuf JSON notation: https://developers.google.com/protocol-buffers/docs/proto3#json
   * Attention: output differs from toObject() e.g. enums are represented as names and not as numbers, Timestamp is an ISO Date string format etc.
   * If the message itself or some of descendant messages is google.protobuf.Any, you MUST provide a message pool as options. If not, the messagePool is not required
   */
  toProtobufJSON(
    // @ts-ignore
    options?: ToProtobufJSONOptions
  ): ClearEventRequest.AsProtobufJSON {
    return {
      customerId: this.customerId,
      eventId: this.eventId,
      comment: this.comment,
      user: this.user,
      userTypeId: this.userTypeId
    };
  }
}
export module ClearEventRequest {
  /**
   * Standard JavaScript object representation for ClearEventRequest
   */
  export interface AsObject {
    customerId?: string;
    eventId?: string;
    comment?: string;
    user?: string;
    userTypeId?: string;
  }

  /**
   * Protobuf JSON representation for ClearEventRequest
   */
  export interface AsProtobufJSON {
    customerId?: string;
    eventId?: string;
    comment?: string;
    user?: string;
    userTypeId?: string;
  }
}

/**
 * Message implementation for modulebms.events.ClearEventResponse
 */
export class ClearEventResponse implements GrpcMessage {
  static id = 'modulebms.events.ClearEventResponse';

  /**
   * Deserialize binary data to message
   * @param instance message instance
   */
  static deserializeBinary(bytes: ByteSource) {
    const instance = new ClearEventResponse();
    ClearEventResponse.deserializeBinaryFromReader(
      instance,
      new BinaryReader(bytes)
    );
    return instance;
  }

  /**
   * Check all the properties and set default protobuf values if necessary
   * @param _instance message instance
   */
  static refineValues(_instance: ClearEventResponse) {
    _instance.ok = _instance.ok || false;
    _instance.message = _instance.message || '';
  }

  /**
   * Deserializes / reads binary message into message instance using provided binary reader
   * @param _instance message instance
   * @param _reader binary reader instance
   */
  static deserializeBinaryFromReader(
    _instance: ClearEventResponse,
    _reader: BinaryReader
  ) {
    while (_reader.nextField()) {
      if (_reader.isEndGroup()) break;

      switch (_reader.getFieldNumber()) {
        case 1:
          _instance.ok = _reader.readBool();
          break;
        case 2:
          _instance.message = _reader.readString();
          break;
        default:
          _reader.skipField();
      }
    }

    ClearEventResponse.refineValues(_instance);
  }

  /**
   * Serializes a message to binary format using provided binary reader
   * @param _instance message instance
   * @param _writer binary writer instance
   */
  static serializeBinaryToWriter(
    _instance: ClearEventResponse,
    _writer: BinaryWriter
  ) {
    if (_instance.ok) {
      _writer.writeBool(1, _instance.ok);
    }
    if (_instance.message) {
      _writer.writeString(2, _instance.message);
    }
  }

  private _ok?: boolean;
  private _message?: string;

  /**
   * Message constructor. Initializes the properties and applies default Protobuf values if necessary
   * @param _value initial values object or instance of ClearEventResponse to deeply clone from
   */
  constructor(_value?: RecursivePartial<ClearEventResponse.AsObject>) {
    _value = _value || {};
    this.ok = _value.ok;
    this.message = _value.message;
    ClearEventResponse.refineValues(this);
  }
  get ok(): boolean | undefined {
    return this._ok;
  }
  set ok(value: boolean | undefined) {
    this._ok = value;
  }
  get message(): string | undefined {
    return this._message;
  }
  set message(value: string | undefined) {
    this._message = value;
  }

  /**
   * Serialize message to binary data
   * @param instance message instance
   */
  serializeBinary() {
    const writer = new BinaryWriter();
    ClearEventResponse.serializeBinaryToWriter(this, writer);
    return writer.getResultBuffer();
  }

  /**
   * Cast message to standard JavaScript object (all non-primitive values are deeply cloned)
   */
  toObject(): ClearEventResponse.AsObject {
    return {
      ok: this.ok,
      message: this.message
    };
  }

  /**
   * Convenience method to support JSON.stringify(message), replicates the structure of toObject()
   */
  toJSON() {
    return this.toObject();
  }

  /**
   * Cast message to JSON using protobuf JSON notation: https://developers.google.com/protocol-buffers/docs/proto3#json
   * Attention: output differs from toObject() e.g. enums are represented as names and not as numbers, Timestamp is an ISO Date string format etc.
   * If the message itself or some of descendant messages is google.protobuf.Any, you MUST provide a message pool as options. If not, the messagePool is not required
   */
  toProtobufJSON(
    // @ts-ignore
    options?: ToProtobufJSONOptions
  ): ClearEventResponse.AsProtobufJSON {
    return {
      ok: this.ok,
      message: this.message
    };
  }
}
export module ClearEventResponse {
  /**
   * Standard JavaScript object representation for ClearEventResponse
   */
  export interface AsObject {
    ok?: boolean;
    message?: string;
  }

  /**
   * Protobuf JSON representation for ClearEventResponse
   */
  export interface AsProtobufJSON {
    ok?: boolean;
    message?: string;
  }
}

/**
 * Message implementation for modulebms.events.MuteEventRequest
 */
export class MuteEventRequest implements GrpcMessage {
  static id = 'modulebms.events.MuteEventRequest';

  /**
   * Deserialize binary data to message
   * @param instance message instance
   */
  static deserializeBinary(bytes: ByteSource) {
    const instance = new MuteEventRequest();
    MuteEventRequest.deserializeBinaryFromReader(
      instance,
      new BinaryReader(bytes)
    );
    return instance;
  }

  /**
   * Check all the properties and set default protobuf values if necessary
   * @param _instance message instance
   */
  static refineValues(_instance: MuteEventRequest) {
    _instance.customerId = _instance.customerId || '';
    _instance.eventId = _instance.eventId || '0';
    _instance.comment = _instance.comment || '';
    _instance.user = _instance.user || '';
    _instance.userTypeId = _instance.userTypeId || '';
  }

  /**
   * Deserializes / reads binary message into message instance using provided binary reader
   * @param _instance message instance
   * @param _reader binary reader instance
   */
  static deserializeBinaryFromReader(
    _instance: MuteEventRequest,
    _reader: BinaryReader
  ) {
    while (_reader.nextField()) {
      if (_reader.isEndGroup()) break;

      switch (_reader.getFieldNumber()) {
        case 1:
          _instance.customerId = _reader.readString();
          break;
        case 2:
          _instance.eventId = _reader.readInt64String();
          break;
        case 3:
          _instance.comment = _reader.readString();
          break;
        case 4:
          _instance.user = _reader.readString();
          break;
        case 5:
          _instance.userTypeId = _reader.readString();
          break;
        default:
          _reader.skipField();
      }
    }

    MuteEventRequest.refineValues(_instance);
  }

  /**
   * Serializes a message to binary format using provided binary reader
   * @param _instance message instance
   * @param _writer binary writer instance
   */
  static serializeBinaryToWriter(
    _instance: MuteEventRequest,
    _writer: BinaryWriter
  ) {
    if (_instance.customerId) {
      _writer.writeString(1, _instance.customerId);
    }
    if (_instance.eventId) {
      _writer.writeInt64String(2, _instance.eventId);
    }
    if (_instance.comment) {
      _writer.writeString(3, _instance.comment);
    }
    if (_instance.user) {
      _writer.writeString(4, _instance.user);
    }
    if (_instance.userTypeId) {
      _writer.writeString(5, _instance.userTypeId);
    }
  }

  private _customerId?: string;
  private _eventId?: string;
  private _comment?: string;
  private _user?: string;
  private _userTypeId?: string;

  /**
   * Message constructor. Initializes the properties and applies default Protobuf values if necessary
   * @param _value initial values object or instance of MuteEventRequest to deeply clone from
   */
  constructor(_value?: RecursivePartial<MuteEventRequest.AsObject>) {
    _value = _value || {};
    this.customerId = _value.customerId;
    this.eventId = _value.eventId;
    this.comment = _value.comment;
    this.user = _value.user;
    this.userTypeId = _value.userTypeId;
    MuteEventRequest.refineValues(this);
  }
  get customerId(): string | undefined {
    return this._customerId;
  }
  set customerId(value: string | undefined) {
    this._customerId = value;
  }
  get eventId(): string | undefined {
    return this._eventId;
  }
  set eventId(value: string | undefined) {
    this._eventId = value;
  }
  get comment(): string | undefined {
    return this._comment;
  }
  set comment(value: string | undefined) {
    this._comment = value;
  }
  get user(): string | undefined {
    return this._user;
  }
  set user(value: string | undefined) {
    this._user = value;
  }
  get userTypeId(): string | undefined {
    return this._userTypeId;
  }
  set userTypeId(value: string | undefined) {
    this._userTypeId = value;
  }

  /**
   * Serialize message to binary data
   * @param instance message instance
   */
  serializeBinary() {
    const writer = new BinaryWriter();
    MuteEventRequest.serializeBinaryToWriter(this, writer);
    return writer.getResultBuffer();
  }

  /**
   * Cast message to standard JavaScript object (all non-primitive values are deeply cloned)
   */
  toObject(): MuteEventRequest.AsObject {
    return {
      customerId: this.customerId,
      eventId: this.eventId,
      comment: this.comment,
      user: this.user,
      userTypeId: this.userTypeId
    };
  }

  /**
   * Convenience method to support JSON.stringify(message), replicates the structure of toObject()
   */
  toJSON() {
    return this.toObject();
  }

  /**
   * Cast message to JSON using protobuf JSON notation: https://developers.google.com/protocol-buffers/docs/proto3#json
   * Attention: output differs from toObject() e.g. enums are represented as names and not as numbers, Timestamp is an ISO Date string format etc.
   * If the message itself or some of descendant messages is google.protobuf.Any, you MUST provide a message pool as options. If not, the messagePool is not required
   */
  toProtobufJSON(
    // @ts-ignore
    options?: ToProtobufJSONOptions
  ): MuteEventRequest.AsProtobufJSON {
    return {
      customerId: this.customerId,
      eventId: this.eventId,
      comment: this.comment,
      user: this.user,
      userTypeId: this.userTypeId
    };
  }
}
export module MuteEventRequest {
  /**
   * Standard JavaScript object representation for MuteEventRequest
   */
  export interface AsObject {
    customerId?: string;
    eventId?: string;
    comment?: string;
    user?: string;
    userTypeId?: string;
  }

  /**
   * Protobuf JSON representation for MuteEventRequest
   */
  export interface AsProtobufJSON {
    customerId?: string;
    eventId?: string;
    comment?: string;
    user?: string;
    userTypeId?: string;
  }
}

/**
 * Message implementation for modulebms.events.MuteEventResponse
 */
export class MuteEventResponse implements GrpcMessage {
  static id = 'modulebms.events.MuteEventResponse';

  /**
   * Deserialize binary data to message
   * @param instance message instance
   */
  static deserializeBinary(bytes: ByteSource) {
    const instance = new MuteEventResponse();
    MuteEventResponse.deserializeBinaryFromReader(
      instance,
      new BinaryReader(bytes)
    );
    return instance;
  }

  /**
   * Check all the properties and set default protobuf values if necessary
   * @param _instance message instance
   */
  static refineValues(_instance: MuteEventResponse) {
    _instance.ok = _instance.ok || false;
    _instance.message = _instance.message || '';
  }

  /**
   * Deserializes / reads binary message into message instance using provided binary reader
   * @param _instance message instance
   * @param _reader binary reader instance
   */
  static deserializeBinaryFromReader(
    _instance: MuteEventResponse,
    _reader: BinaryReader
  ) {
    while (_reader.nextField()) {
      if (_reader.isEndGroup()) break;

      switch (_reader.getFieldNumber()) {
        case 1:
          _instance.ok = _reader.readBool();
          break;
        case 2:
          _instance.message = _reader.readString();
          break;
        default:
          _reader.skipField();
      }
    }

    MuteEventResponse.refineValues(_instance);
  }

  /**
   * Serializes a message to binary format using provided binary reader
   * @param _instance message instance
   * @param _writer binary writer instance
   */
  static serializeBinaryToWriter(
    _instance: MuteEventResponse,
    _writer: BinaryWriter
  ) {
    if (_instance.ok) {
      _writer.writeBool(1, _instance.ok);
    }
    if (_instance.message) {
      _writer.writeString(2, _instance.message);
    }
  }

  private _ok?: boolean;
  private _message?: string;

  /**
   * Message constructor. Initializes the properties and applies default Protobuf values if necessary
   * @param _value initial values object or instance of MuteEventResponse to deeply clone from
   */
  constructor(_value?: RecursivePartial<MuteEventResponse.AsObject>) {
    _value = _value || {};
    this.ok = _value.ok;
    this.message = _value.message;
    MuteEventResponse.refineValues(this);
  }
  get ok(): boolean | undefined {
    return this._ok;
  }
  set ok(value: boolean | undefined) {
    this._ok = value;
  }
  get message(): string | undefined {
    return this._message;
  }
  set message(value: string | undefined) {
    this._message = value;
  }

  /**
   * Serialize message to binary data
   * @param instance message instance
   */
  serializeBinary() {
    const writer = new BinaryWriter();
    MuteEventResponse.serializeBinaryToWriter(this, writer);
    return writer.getResultBuffer();
  }

  /**
   * Cast message to standard JavaScript object (all non-primitive values are deeply cloned)
   */
  toObject(): MuteEventResponse.AsObject {
    return {
      ok: this.ok,
      message: this.message
    };
  }

  /**
   * Convenience method to support JSON.stringify(message), replicates the structure of toObject()
   */
  toJSON() {
    return this.toObject();
  }

  /**
   * Cast message to JSON using protobuf JSON notation: https://developers.google.com/protocol-buffers/docs/proto3#json
   * Attention: output differs from toObject() e.g. enums are represented as names and not as numbers, Timestamp is an ISO Date string format etc.
   * If the message itself or some of descendant messages is google.protobuf.Any, you MUST provide a message pool as options. If not, the messagePool is not required
   */
  toProtobufJSON(
    // @ts-ignore
    options?: ToProtobufJSONOptions
  ): MuteEventResponse.AsProtobufJSON {
    return {
      ok: this.ok,
      message: this.message
    };
  }
}
export module MuteEventResponse {
  /**
   * Standard JavaScript object representation for MuteEventResponse
   */
  export interface AsObject {
    ok?: boolean;
    message?: string;
  }

  /**
   * Protobuf JSON representation for MuteEventResponse
   */
  export interface AsProtobufJSON {
    ok?: boolean;
    message?: string;
  }
}

/**
 * Message implementation for modulebms.events.UnmuteEventRequest
 */
export class UnmuteEventRequest implements GrpcMessage {
  static id = 'modulebms.events.UnmuteEventRequest';

  /**
   * Deserialize binary data to message
   * @param instance message instance
   */
  static deserializeBinary(bytes: ByteSource) {
    const instance = new UnmuteEventRequest();
    UnmuteEventRequest.deserializeBinaryFromReader(
      instance,
      new BinaryReader(bytes)
    );
    return instance;
  }

  /**
   * Check all the properties and set default protobuf values if necessary
   * @param _instance message instance
   */
  static refineValues(_instance: UnmuteEventRequest) {
    _instance.customerId = _instance.customerId || '';
    _instance.eventId = _instance.eventId || '0';
    _instance.comment = _instance.comment || '';
    _instance.user = _instance.user || '';
    _instance.userTypeId = _instance.userTypeId || '';
  }

  /**
   * Deserializes / reads binary message into message instance using provided binary reader
   * @param _instance message instance
   * @param _reader binary reader instance
   */
  static deserializeBinaryFromReader(
    _instance: UnmuteEventRequest,
    _reader: BinaryReader
  ) {
    while (_reader.nextField()) {
      if (_reader.isEndGroup()) break;

      switch (_reader.getFieldNumber()) {
        case 1:
          _instance.customerId = _reader.readString();
          break;
        case 2:
          _instance.eventId = _reader.readInt64String();
          break;
        case 3:
          _instance.comment = _reader.readString();
          break;
        case 4:
          _instance.user = _reader.readString();
          break;
        case 5:
          _instance.userTypeId = _reader.readString();
          break;
        default:
          _reader.skipField();
      }
    }

    UnmuteEventRequest.refineValues(_instance);
  }

  /**
   * Serializes a message to binary format using provided binary reader
   * @param _instance message instance
   * @param _writer binary writer instance
   */
  static serializeBinaryToWriter(
    _instance: UnmuteEventRequest,
    _writer: BinaryWriter
  ) {
    if (_instance.customerId) {
      _writer.writeString(1, _instance.customerId);
    }
    if (_instance.eventId) {
      _writer.writeInt64String(2, _instance.eventId);
    }
    if (_instance.comment) {
      _writer.writeString(3, _instance.comment);
    }
    if (_instance.user) {
      _writer.writeString(4, _instance.user);
    }
    if (_instance.userTypeId) {
      _writer.writeString(5, _instance.userTypeId);
    }
  }

  private _customerId?: string;
  private _eventId?: string;
  private _comment?: string;
  private _user?: string;
  private _userTypeId?: string;

  /**
   * Message constructor. Initializes the properties and applies default Protobuf values if necessary
   * @param _value initial values object or instance of UnmuteEventRequest to deeply clone from
   */
  constructor(_value?: RecursivePartial<UnmuteEventRequest.AsObject>) {
    _value = _value || {};
    this.customerId = _value.customerId;
    this.eventId = _value.eventId;
    this.comment = _value.comment;
    this.user = _value.user;
    this.userTypeId = _value.userTypeId;
    UnmuteEventRequest.refineValues(this);
  }
  get customerId(): string | undefined {
    return this._customerId;
  }
  set customerId(value: string | undefined) {
    this._customerId = value;
  }
  get eventId(): string | undefined {
    return this._eventId;
  }
  set eventId(value: string | undefined) {
    this._eventId = value;
  }
  get comment(): string | undefined {
    return this._comment;
  }
  set comment(value: string | undefined) {
    this._comment = value;
  }
  get user(): string | undefined {
    return this._user;
  }
  set user(value: string | undefined) {
    this._user = value;
  }
  get userTypeId(): string | undefined {
    return this._userTypeId;
  }
  set userTypeId(value: string | undefined) {
    this._userTypeId = value;
  }

  /**
   * Serialize message to binary data
   * @param instance message instance
   */
  serializeBinary() {
    const writer = new BinaryWriter();
    UnmuteEventRequest.serializeBinaryToWriter(this, writer);
    return writer.getResultBuffer();
  }

  /**
   * Cast message to standard JavaScript object (all non-primitive values are deeply cloned)
   */
  toObject(): UnmuteEventRequest.AsObject {
    return {
      customerId: this.customerId,
      eventId: this.eventId,
      comment: this.comment,
      user: this.user,
      userTypeId: this.userTypeId
    };
  }

  /**
   * Convenience method to support JSON.stringify(message), replicates the structure of toObject()
   */
  toJSON() {
    return this.toObject();
  }

  /**
   * Cast message to JSON using protobuf JSON notation: https://developers.google.com/protocol-buffers/docs/proto3#json
   * Attention: output differs from toObject() e.g. enums are represented as names and not as numbers, Timestamp is an ISO Date string format etc.
   * If the message itself or some of descendant messages is google.protobuf.Any, you MUST provide a message pool as options. If not, the messagePool is not required
   */
  toProtobufJSON(
    // @ts-ignore
    options?: ToProtobufJSONOptions
  ): UnmuteEventRequest.AsProtobufJSON {
    return {
      customerId: this.customerId,
      eventId: this.eventId,
      comment: this.comment,
      user: this.user,
      userTypeId: this.userTypeId
    };
  }
}
export module UnmuteEventRequest {
  /**
   * Standard JavaScript object representation for UnmuteEventRequest
   */
  export interface AsObject {
    customerId?: string;
    eventId?: string;
    comment?: string;
    user?: string;
    userTypeId?: string;
  }

  /**
   * Protobuf JSON representation for UnmuteEventRequest
   */
  export interface AsProtobufJSON {
    customerId?: string;
    eventId?: string;
    comment?: string;
    user?: string;
    userTypeId?: string;
  }
}

/**
 * Message implementation for modulebms.events.UnmuteEventResponse
 */
export class UnmuteEventResponse implements GrpcMessage {
  static id = 'modulebms.events.UnmuteEventResponse';

  /**
   * Deserialize binary data to message
   * @param instance message instance
   */
  static deserializeBinary(bytes: ByteSource) {
    const instance = new UnmuteEventResponse();
    UnmuteEventResponse.deserializeBinaryFromReader(
      instance,
      new BinaryReader(bytes)
    );
    return instance;
  }

  /**
   * Check all the properties and set default protobuf values if necessary
   * @param _instance message instance
   */
  static refineValues(_instance: UnmuteEventResponse) {
    _instance.ok = _instance.ok || false;
    _instance.message = _instance.message || '';
  }

  /**
   * Deserializes / reads binary message into message instance using provided binary reader
   * @param _instance message instance
   * @param _reader binary reader instance
   */
  static deserializeBinaryFromReader(
    _instance: UnmuteEventResponse,
    _reader: BinaryReader
  ) {
    while (_reader.nextField()) {
      if (_reader.isEndGroup()) break;

      switch (_reader.getFieldNumber()) {
        case 1:
          _instance.ok = _reader.readBool();
          break;
        case 2:
          _instance.message = _reader.readString();
          break;
        default:
          _reader.skipField();
      }
    }

    UnmuteEventResponse.refineValues(_instance);
  }

  /**
   * Serializes a message to binary format using provided binary reader
   * @param _instance message instance
   * @param _writer binary writer instance
   */
  static serializeBinaryToWriter(
    _instance: UnmuteEventResponse,
    _writer: BinaryWriter
  ) {
    if (_instance.ok) {
      _writer.writeBool(1, _instance.ok);
    }
    if (_instance.message) {
      _writer.writeString(2, _instance.message);
    }
  }

  private _ok?: boolean;
  private _message?: string;

  /**
   * Message constructor. Initializes the properties and applies default Protobuf values if necessary
   * @param _value initial values object or instance of UnmuteEventResponse to deeply clone from
   */
  constructor(_value?: RecursivePartial<UnmuteEventResponse.AsObject>) {
    _value = _value || {};
    this.ok = _value.ok;
    this.message = _value.message;
    UnmuteEventResponse.refineValues(this);
  }
  get ok(): boolean | undefined {
    return this._ok;
  }
  set ok(value: boolean | undefined) {
    this._ok = value;
  }
  get message(): string | undefined {
    return this._message;
  }
  set message(value: string | undefined) {
    this._message = value;
  }

  /**
   * Serialize message to binary data
   * @param instance message instance
   */
  serializeBinary() {
    const writer = new BinaryWriter();
    UnmuteEventResponse.serializeBinaryToWriter(this, writer);
    return writer.getResultBuffer();
  }

  /**
   * Cast message to standard JavaScript object (all non-primitive values are deeply cloned)
   */
  toObject(): UnmuteEventResponse.AsObject {
    return {
      ok: this.ok,
      message: this.message
    };
  }

  /**
   * Convenience method to support JSON.stringify(message), replicates the structure of toObject()
   */
  toJSON() {
    return this.toObject();
  }

  /**
   * Cast message to JSON using protobuf JSON notation: https://developers.google.com/protocol-buffers/docs/proto3#json
   * Attention: output differs from toObject() e.g. enums are represented as names and not as numbers, Timestamp is an ISO Date string format etc.
   * If the message itself or some of descendant messages is google.protobuf.Any, you MUST provide a message pool as options. If not, the messagePool is not required
   */
  toProtobufJSON(
    // @ts-ignore
    options?: ToProtobufJSONOptions
  ): UnmuteEventResponse.AsProtobufJSON {
    return {
      ok: this.ok,
      message: this.message
    };
  }
}
export module UnmuteEventResponse {
  /**
   * Standard JavaScript object representation for UnmuteEventResponse
   */
  export interface AsObject {
    ok?: boolean;
    message?: string;
  }

  /**
   * Protobuf JSON representation for UnmuteEventResponse
   */
  export interface AsProtobufJSON {
    ok?: boolean;
    message?: string;
  }
}

/**
 * Message implementation for modulebms.events.UnsubscribeEventsRequest
 */
export class UnsubscribeEventsRequest implements GrpcMessage {
  static id = 'modulebms.events.UnsubscribeEventsRequest';

  /**
   * Deserialize binary data to message
   * @param instance message instance
   */
  static deserializeBinary(bytes: ByteSource) {
    const instance = new UnsubscribeEventsRequest();
    UnsubscribeEventsRequest.deserializeBinaryFromReader(
      instance,
      new BinaryReader(bytes)
    );
    return instance;
  }

  /**
   * Check all the properties and set default protobuf values if necessary
   * @param _instance message instance
   */
  static refineValues(_instance: UnsubscribeEventsRequest) {
    _instance.customerId = _instance.customerId || '';
    _instance.email = _instance.email || '';
    _instance.phone = _instance.phone || '';
  }

  /**
   * Deserializes / reads binary message into message instance using provided binary reader
   * @param _instance message instance
   * @param _reader binary reader instance
   */
  static deserializeBinaryFromReader(
    _instance: UnsubscribeEventsRequest,
    _reader: BinaryReader
  ) {
    while (_reader.nextField()) {
      if (_reader.isEndGroup()) break;

      switch (_reader.getFieldNumber()) {
        case 1:
          _instance.customerId = _reader.readString();
          break;
        case 2:
          _instance.email = _reader.readString();
          break;
        case 3:
          _instance.phone = _reader.readString();
          break;
        default:
          _reader.skipField();
      }
    }

    UnsubscribeEventsRequest.refineValues(_instance);
  }

  /**
   * Serializes a message to binary format using provided binary reader
   * @param _instance message instance
   * @param _writer binary writer instance
   */
  static serializeBinaryToWriter(
    _instance: UnsubscribeEventsRequest,
    _writer: BinaryWriter
  ) {
    if (_instance.customerId) {
      _writer.writeString(1, _instance.customerId);
    }
    if (_instance.email) {
      _writer.writeString(2, _instance.email);
    }
    if (_instance.phone) {
      _writer.writeString(3, _instance.phone);
    }
  }

  private _customerId?: string;
  private _email?: string;
  private _phone?: string;

  /**
   * Message constructor. Initializes the properties and applies default Protobuf values if necessary
   * @param _value initial values object or instance of UnsubscribeEventsRequest to deeply clone from
   */
  constructor(_value?: RecursivePartial<UnsubscribeEventsRequest.AsObject>) {
    _value = _value || {};
    this.customerId = _value.customerId;
    this.email = _value.email;
    this.phone = _value.phone;
    UnsubscribeEventsRequest.refineValues(this);
  }
  get customerId(): string | undefined {
    return this._customerId;
  }
  set customerId(value: string | undefined) {
    this._customerId = value;
  }
  get email(): string | undefined {
    return this._email;
  }
  set email(value: string | undefined) {
    this._email = value;
  }
  get phone(): string | undefined {
    return this._phone;
  }
  set phone(value: string | undefined) {
    this._phone = value;
  }

  /**
   * Serialize message to binary data
   * @param instance message instance
   */
  serializeBinary() {
    const writer = new BinaryWriter();
    UnsubscribeEventsRequest.serializeBinaryToWriter(this, writer);
    return writer.getResultBuffer();
  }

  /**
   * Cast message to standard JavaScript object (all non-primitive values are deeply cloned)
   */
  toObject(): UnsubscribeEventsRequest.AsObject {
    return {
      customerId: this.customerId,
      email: this.email,
      phone: this.phone
    };
  }

  /**
   * Convenience method to support JSON.stringify(message), replicates the structure of toObject()
   */
  toJSON() {
    return this.toObject();
  }

  /**
   * Cast message to JSON using protobuf JSON notation: https://developers.google.com/protocol-buffers/docs/proto3#json
   * Attention: output differs from toObject() e.g. enums are represented as names and not as numbers, Timestamp is an ISO Date string format etc.
   * If the message itself or some of descendant messages is google.protobuf.Any, you MUST provide a message pool as options. If not, the messagePool is not required
   */
  toProtobufJSON(
    // @ts-ignore
    options?: ToProtobufJSONOptions
  ): UnsubscribeEventsRequest.AsProtobufJSON {
    return {
      customerId: this.customerId,
      email: this.email,
      phone: this.phone
    };
  }
}
export module UnsubscribeEventsRequest {
  /**
   * Standard JavaScript object representation for UnsubscribeEventsRequest
   */
  export interface AsObject {
    customerId?: string;
    email?: string;
    phone?: string;
  }

  /**
   * Protobuf JSON representation for UnsubscribeEventsRequest
   */
  export interface AsProtobufJSON {
    customerId?: string;
    email?: string;
    phone?: string;
  }
}

/**
 * Message implementation for modulebms.events.UnsubscribeEventsResponse
 */
export class UnsubscribeEventsResponse implements GrpcMessage {
  static id = 'modulebms.events.UnsubscribeEventsResponse';

  /**
   * Deserialize binary data to message
   * @param instance message instance
   */
  static deserializeBinary(bytes: ByteSource) {
    const instance = new UnsubscribeEventsResponse();
    UnsubscribeEventsResponse.deserializeBinaryFromReader(
      instance,
      new BinaryReader(bytes)
    );
    return instance;
  }

  /**
   * Check all the properties and set default protobuf values if necessary
   * @param _instance message instance
   */
  static refineValues(_instance: UnsubscribeEventsResponse) {
    _instance.ok = _instance.ok || false;
    _instance.message = _instance.message || '';
  }

  /**
   * Deserializes / reads binary message into message instance using provided binary reader
   * @param _instance message instance
   * @param _reader binary reader instance
   */
  static deserializeBinaryFromReader(
    _instance: UnsubscribeEventsResponse,
    _reader: BinaryReader
  ) {
    while (_reader.nextField()) {
      if (_reader.isEndGroup()) break;

      switch (_reader.getFieldNumber()) {
        case 1:
          _instance.ok = _reader.readBool();
          break;
        case 2:
          _instance.message = _reader.readString();
          break;
        default:
          _reader.skipField();
      }
    }

    UnsubscribeEventsResponse.refineValues(_instance);
  }

  /**
   * Serializes a message to binary format using provided binary reader
   * @param _instance message instance
   * @param _writer binary writer instance
   */
  static serializeBinaryToWriter(
    _instance: UnsubscribeEventsResponse,
    _writer: BinaryWriter
  ) {
    if (_instance.ok) {
      _writer.writeBool(1, _instance.ok);
    }
    if (_instance.message) {
      _writer.writeString(2, _instance.message);
    }
  }

  private _ok?: boolean;
  private _message?: string;

  /**
   * Message constructor. Initializes the properties and applies default Protobuf values if necessary
   * @param _value initial values object or instance of UnsubscribeEventsResponse to deeply clone from
   */
  constructor(_value?: RecursivePartial<UnsubscribeEventsResponse.AsObject>) {
    _value = _value || {};
    this.ok = _value.ok;
    this.message = _value.message;
    UnsubscribeEventsResponse.refineValues(this);
  }
  get ok(): boolean | undefined {
    return this._ok;
  }
  set ok(value: boolean | undefined) {
    this._ok = value;
  }
  get message(): string | undefined {
    return this._message;
  }
  set message(value: string | undefined) {
    this._message = value;
  }

  /**
   * Serialize message to binary data
   * @param instance message instance
   */
  serializeBinary() {
    const writer = new BinaryWriter();
    UnsubscribeEventsResponse.serializeBinaryToWriter(this, writer);
    return writer.getResultBuffer();
  }

  /**
   * Cast message to standard JavaScript object (all non-primitive values are deeply cloned)
   */
  toObject(): UnsubscribeEventsResponse.AsObject {
    return {
      ok: this.ok,
      message: this.message
    };
  }

  /**
   * Convenience method to support JSON.stringify(message), replicates the structure of toObject()
   */
  toJSON() {
    return this.toObject();
  }

  /**
   * Cast message to JSON using protobuf JSON notation: https://developers.google.com/protocol-buffers/docs/proto3#json
   * Attention: output differs from toObject() e.g. enums are represented as names and not as numbers, Timestamp is an ISO Date string format etc.
   * If the message itself or some of descendant messages is google.protobuf.Any, you MUST provide a message pool as options. If not, the messagePool is not required
   */
  toProtobufJSON(
    // @ts-ignore
    options?: ToProtobufJSONOptions
  ): UnsubscribeEventsResponse.AsProtobufJSON {
    return {
      ok: this.ok,
      message: this.message
    };
  }
}
export module UnsubscribeEventsResponse {
  /**
   * Standard JavaScript object representation for UnsubscribeEventsResponse
   */
  export interface AsObject {
    ok?: boolean;
    message?: string;
  }

  /**
   * Protobuf JSON representation for UnsubscribeEventsResponse
   */
  export interface AsProtobufJSON {
    ok?: boolean;
    message?: string;
  }
}

/**
 * Message implementation for modulebms.events.GetEventRequest
 */
export class GetEventRequest implements GrpcMessage {
  static id = 'modulebms.events.GetEventRequest';

  /**
   * Deserialize binary data to message
   * @param instance message instance
   */
  static deserializeBinary(bytes: ByteSource) {
    const instance = new GetEventRequest();
    GetEventRequest.deserializeBinaryFromReader(
      instance,
      new BinaryReader(bytes)
    );
    return instance;
  }

  /**
   * Check all the properties and set default protobuf values if necessary
   * @param _instance message instance
   */
  static refineValues(_instance: GetEventRequest) {
    _instance.customerId = _instance.customerId || '';
    _instance.eventId = _instance.eventId || '0';
  }

  /**
   * Deserializes / reads binary message into message instance using provided binary reader
   * @param _instance message instance
   * @param _reader binary reader instance
   */
  static deserializeBinaryFromReader(
    _instance: GetEventRequest,
    _reader: BinaryReader
  ) {
    while (_reader.nextField()) {
      if (_reader.isEndGroup()) break;

      switch (_reader.getFieldNumber()) {
        case 1:
          _instance.customerId = _reader.readString();
          break;
        case 2:
          _instance.eventId = _reader.readInt64String();
          break;
        default:
          _reader.skipField();
      }
    }

    GetEventRequest.refineValues(_instance);
  }

  /**
   * Serializes a message to binary format using provided binary reader
   * @param _instance message instance
   * @param _writer binary writer instance
   */
  static serializeBinaryToWriter(
    _instance: GetEventRequest,
    _writer: BinaryWriter
  ) {
    if (_instance.customerId) {
      _writer.writeString(1, _instance.customerId);
    }
    if (_instance.eventId) {
      _writer.writeInt64String(2, _instance.eventId);
    }
  }

  private _customerId?: string;
  private _eventId?: string;

  /**
   * Message constructor. Initializes the properties and applies default Protobuf values if necessary
   * @param _value initial values object or instance of GetEventRequest to deeply clone from
   */
  constructor(_value?: RecursivePartial<GetEventRequest.AsObject>) {
    _value = _value || {};
    this.customerId = _value.customerId;
    this.eventId = _value.eventId;
    GetEventRequest.refineValues(this);
  }
  get customerId(): string | undefined {
    return this._customerId;
  }
  set customerId(value: string | undefined) {
    this._customerId = value;
  }
  get eventId(): string | undefined {
    return this._eventId;
  }
  set eventId(value: string | undefined) {
    this._eventId = value;
  }

  /**
   * Serialize message to binary data
   * @param instance message instance
   */
  serializeBinary() {
    const writer = new BinaryWriter();
    GetEventRequest.serializeBinaryToWriter(this, writer);
    return writer.getResultBuffer();
  }

  /**
   * Cast message to standard JavaScript object (all non-primitive values are deeply cloned)
   */
  toObject(): GetEventRequest.AsObject {
    return {
      customerId: this.customerId,
      eventId: this.eventId
    };
  }

  /**
   * Convenience method to support JSON.stringify(message), replicates the structure of toObject()
   */
  toJSON() {
    return this.toObject();
  }

  /**
   * Cast message to JSON using protobuf JSON notation: https://developers.google.com/protocol-buffers/docs/proto3#json
   * Attention: output differs from toObject() e.g. enums are represented as names and not as numbers, Timestamp is an ISO Date string format etc.
   * If the message itself or some of descendant messages is google.protobuf.Any, you MUST provide a message pool as options. If not, the messagePool is not required
   */
  toProtobufJSON(
    // @ts-ignore
    options?: ToProtobufJSONOptions
  ): GetEventRequest.AsProtobufJSON {
    return {
      customerId: this.customerId,
      eventId: this.eventId
    };
  }
}
export module GetEventRequest {
  /**
   * Standard JavaScript object representation for GetEventRequest
   */
  export interface AsObject {
    customerId?: string;
    eventId?: string;
  }

  /**
   * Protobuf JSON representation for GetEventRequest
   */
  export interface AsProtobufJSON {
    customerId?: string;
    eventId?: string;
  }
}

/**
 * Message implementation for modulebms.events.GetEventResponse
 */
export class GetEventResponse implements GrpcMessage {
  static id = 'modulebms.events.GetEventResponse';

  /**
   * Deserialize binary data to message
   * @param instance message instance
   */
  static deserializeBinary(bytes: ByteSource) {
    const instance = new GetEventResponse();
    GetEventResponse.deserializeBinaryFromReader(
      instance,
      new BinaryReader(bytes)
    );
    return instance;
  }

  /**
   * Check all the properties and set default protobuf values if necessary
   * @param _instance message instance
   */
  static refineValues(_instance: GetEventResponse) {
    _instance.event = _instance.event || undefined;
    _instance.customerName = _instance.customerName || '';
  }

  /**
   * Deserializes / reads binary message into message instance using provided binary reader
   * @param _instance message instance
   * @param _reader binary reader instance
   */
  static deserializeBinaryFromReader(
    _instance: GetEventResponse,
    _reader: BinaryReader
  ) {
    while (_reader.nextField()) {
      if (_reader.isEndGroup()) break;

      switch (_reader.getFieldNumber()) {
        case 1:
          _instance.event = new Event();
          _reader.readMessage(
            _instance.event,
            Event.deserializeBinaryFromReader
          );
          break;
        case 2:
          _instance.customerName = _reader.readString();
          break;
        default:
          _reader.skipField();
      }
    }

    GetEventResponse.refineValues(_instance);
  }

  /**
   * Serializes a message to binary format using provided binary reader
   * @param _instance message instance
   * @param _writer binary writer instance
   */
  static serializeBinaryToWriter(
    _instance: GetEventResponse,
    _writer: BinaryWriter
  ) {
    if (_instance.event) {
      _writer.writeMessage(
        1,
        _instance.event as any,
        Event.serializeBinaryToWriter
      );
    }
    if (_instance.customerName) {
      _writer.writeString(2, _instance.customerName);
    }
  }

  private _event?: Event;
  private _customerName?: string;

  /**
   * Message constructor. Initializes the properties and applies default Protobuf values if necessary
   * @param _value initial values object or instance of GetEventResponse to deeply clone from
   */
  constructor(_value?: RecursivePartial<GetEventResponse.AsObject>) {
    _value = _value || {};
    this.event = _value.event ? new Event(_value.event) : undefined;
    this.customerName = _value.customerName;
    GetEventResponse.refineValues(this);
  }
  get event(): Event | undefined {
    return this._event;
  }
  set event(value: Event | undefined) {
    this._event = value;
  }
  get customerName(): string | undefined {
    return this._customerName;
  }
  set customerName(value: string | undefined) {
    this._customerName = value;
  }

  /**
   * Serialize message to binary data
   * @param instance message instance
   */
  serializeBinary() {
    const writer = new BinaryWriter();
    GetEventResponse.serializeBinaryToWriter(this, writer);
    return writer.getResultBuffer();
  }

  /**
   * Cast message to standard JavaScript object (all non-primitive values are deeply cloned)
   */
  toObject(): GetEventResponse.AsObject {
    return {
      event: this.event ? this.event.toObject() : undefined,
      customerName: this.customerName
    };
  }

  /**
   * Convenience method to support JSON.stringify(message), replicates the structure of toObject()
   */
  toJSON() {
    return this.toObject();
  }

  /**
   * Cast message to JSON using protobuf JSON notation: https://developers.google.com/protocol-buffers/docs/proto3#json
   * Attention: output differs from toObject() e.g. enums are represented as names and not as numbers, Timestamp is an ISO Date string format etc.
   * If the message itself or some of descendant messages is google.protobuf.Any, you MUST provide a message pool as options. If not, the messagePool is not required
   */
  toProtobufJSON(
    // @ts-ignore
    options?: ToProtobufJSONOptions
  ): GetEventResponse.AsProtobufJSON {
    return {
      event: this.event ? this.event.toProtobufJSON(options) : null,
      customerName: this.customerName
    };
  }
}
export module GetEventResponse {
  /**
   * Standard JavaScript object representation for GetEventResponse
   */
  export interface AsObject {
    event?: Event.AsObject;
    customerName?: string;
  }

  /**
   * Protobuf JSON representation for GetEventResponse
   */
  export interface AsProtobufJSON {
    event?: Event.AsProtobufJSON | null;
    customerName?: string;
  }
}

/**
 * Message implementation for modulebms.events.GetEventsRequest
 */
export class GetEventsRequest implements GrpcMessage {
  static id = 'modulebms.events.GetEventsRequest';

  /**
   * Deserialize binary data to message
   * @param instance message instance
   */
  static deserializeBinary(bytes: ByteSource) {
    const instance = new GetEventsRequest();
    GetEventsRequest.deserializeBinaryFromReader(
      instance,
      new BinaryReader(bytes)
    );
    return instance;
  }

  /**
   * Check all the properties and set default protobuf values if necessary
   * @param _instance message instance
   */
  static refineValues(_instance: GetEventsRequest) {
    _instance.customerId = _instance.customerId || '';
  }

  /**
   * Deserializes / reads binary message into message instance using provided binary reader
   * @param _instance message instance
   * @param _reader binary reader instance
   */
  static deserializeBinaryFromReader(
    _instance: GetEventsRequest,
    _reader: BinaryReader
  ) {
    while (_reader.nextField()) {
      if (_reader.isEndGroup()) break;

      switch (_reader.getFieldNumber()) {
        case 1:
          _instance.customerId = _reader.readString();
          break;
        default:
          _reader.skipField();
      }
    }

    GetEventsRequest.refineValues(_instance);
  }

  /**
   * Serializes a message to binary format using provided binary reader
   * @param _instance message instance
   * @param _writer binary writer instance
   */
  static serializeBinaryToWriter(
    _instance: GetEventsRequest,
    _writer: BinaryWriter
  ) {
    if (_instance.customerId) {
      _writer.writeString(1, _instance.customerId);
    }
  }

  private _customerId?: string;

  /**
   * Message constructor. Initializes the properties and applies default Protobuf values if necessary
   * @param _value initial values object or instance of GetEventsRequest to deeply clone from
   */
  constructor(_value?: RecursivePartial<GetEventsRequest.AsObject>) {
    _value = _value || {};
    this.customerId = _value.customerId;
    GetEventsRequest.refineValues(this);
  }
  get customerId(): string | undefined {
    return this._customerId;
  }
  set customerId(value: string | undefined) {
    this._customerId = value;
  }

  /**
   * Serialize message to binary data
   * @param instance message instance
   */
  serializeBinary() {
    const writer = new BinaryWriter();
    GetEventsRequest.serializeBinaryToWriter(this, writer);
    return writer.getResultBuffer();
  }

  /**
   * Cast message to standard JavaScript object (all non-primitive values are deeply cloned)
   */
  toObject(): GetEventsRequest.AsObject {
    return {
      customerId: this.customerId
    };
  }

  /**
   * Convenience method to support JSON.stringify(message), replicates the structure of toObject()
   */
  toJSON() {
    return this.toObject();
  }

  /**
   * Cast message to JSON using protobuf JSON notation: https://developers.google.com/protocol-buffers/docs/proto3#json
   * Attention: output differs from toObject() e.g. enums are represented as names and not as numbers, Timestamp is an ISO Date string format etc.
   * If the message itself or some of descendant messages is google.protobuf.Any, you MUST provide a message pool as options. If not, the messagePool is not required
   */
  toProtobufJSON(
    // @ts-ignore
    options?: ToProtobufJSONOptions
  ): GetEventsRequest.AsProtobufJSON {
    return {
      customerId: this.customerId
    };
  }
}
export module GetEventsRequest {
  /**
   * Standard JavaScript object representation for GetEventsRequest
   */
  export interface AsObject {
    customerId?: string;
  }

  /**
   * Protobuf JSON representation for GetEventsRequest
   */
  export interface AsProtobufJSON {
    customerId?: string;
  }
}

/**
 * Message implementation for modulebms.events.GetEventsResponse
 */
export class GetEventsResponse implements GrpcMessage {
  static id = 'modulebms.events.GetEventsResponse';

  /**
   * Deserialize binary data to message
   * @param instance message instance
   */
  static deserializeBinary(bytes: ByteSource) {
    const instance = new GetEventsResponse();
    GetEventsResponse.deserializeBinaryFromReader(
      instance,
      new BinaryReader(bytes)
    );
    return instance;
  }

  /**
   * Check all the properties and set default protobuf values if necessary
   * @param _instance message instance
   */
  static refineValues(_instance: GetEventsResponse) {
    _instance.events = _instance.events || [];
  }

  /**
   * Deserializes / reads binary message into message instance using provided binary reader
   * @param _instance message instance
   * @param _reader binary reader instance
   */
  static deserializeBinaryFromReader(
    _instance: GetEventsResponse,
    _reader: BinaryReader
  ) {
    while (_reader.nextField()) {
      if (_reader.isEndGroup()) break;

      switch (_reader.getFieldNumber()) {
        case 1:
          const messageInitializer1 = new Event();
          _reader.readMessage(
            messageInitializer1,
            Event.deserializeBinaryFromReader
          );
          (_instance.events = _instance.events || []).push(messageInitializer1);
          break;
        default:
          _reader.skipField();
      }
    }

    GetEventsResponse.refineValues(_instance);
  }

  /**
   * Serializes a message to binary format using provided binary reader
   * @param _instance message instance
   * @param _writer binary writer instance
   */
  static serializeBinaryToWriter(
    _instance: GetEventsResponse,
    _writer: BinaryWriter
  ) {
    if (_instance.events && _instance.events.length) {
      _writer.writeRepeatedMessage(
        1,
        _instance.events as any,
        Event.serializeBinaryToWriter
      );
    }
  }

  private _events?: Event[];

  /**
   * Message constructor. Initializes the properties and applies default Protobuf values if necessary
   * @param _value initial values object or instance of GetEventsResponse to deeply clone from
   */
  constructor(_value?: RecursivePartial<GetEventsResponse.AsObject>) {
    _value = _value || {};
    this.events = (_value.events || []).map(m => new Event(m));
    GetEventsResponse.refineValues(this);
  }
  get events(): Event[] | undefined {
    return this._events;
  }
  set events(value: Event[] | undefined) {
    this._events = value;
  }

  /**
   * Serialize message to binary data
   * @param instance message instance
   */
  serializeBinary() {
    const writer = new BinaryWriter();
    GetEventsResponse.serializeBinaryToWriter(this, writer);
    return writer.getResultBuffer();
  }

  /**
   * Cast message to standard JavaScript object (all non-primitive values are deeply cloned)
   */
  toObject(): GetEventsResponse.AsObject {
    return {
      events: (this.events || []).map(m => m.toObject())
    };
  }

  /**
   * Convenience method to support JSON.stringify(message), replicates the structure of toObject()
   */
  toJSON() {
    return this.toObject();
  }

  /**
   * Cast message to JSON using protobuf JSON notation: https://developers.google.com/protocol-buffers/docs/proto3#json
   * Attention: output differs from toObject() e.g. enums are represented as names and not as numbers, Timestamp is an ISO Date string format etc.
   * If the message itself or some of descendant messages is google.protobuf.Any, you MUST provide a message pool as options. If not, the messagePool is not required
   */
  toProtobufJSON(
    // @ts-ignore
    options?: ToProtobufJSONOptions
  ): GetEventsResponse.AsProtobufJSON {
    return {
      events: (this.events || []).map(m => m.toProtobufJSON(options))
    };
  }
}
export module GetEventsResponse {
  /**
   * Standard JavaScript object representation for GetEventsResponse
   */
  export interface AsObject {
    events?: Event.AsObject[];
  }

  /**
   * Protobuf JSON representation for GetEventsResponse
   */
  export interface AsProtobufJSON {
    events?: Event.AsProtobufJSON[] | null;
  }
}

/**
 * Message implementation for modulebms.events.GetEventsHistoryRequest
 */
export class GetEventsHistoryRequest implements GrpcMessage {
  static id = 'modulebms.events.GetEventsHistoryRequest';

  /**
   * Deserialize binary data to message
   * @param instance message instance
   */
  static deserializeBinary(bytes: ByteSource) {
    const instance = new GetEventsHistoryRequest();
    GetEventsHistoryRequest.deserializeBinaryFromReader(
      instance,
      new BinaryReader(bytes)
    );
    return instance;
  }

  /**
   * Check all the properties and set default protobuf values if necessary
   * @param _instance message instance
   */
  static refineValues(_instance: GetEventsHistoryRequest) {
    _instance.customerId = _instance.customerId || '';
    _instance.eventObjectId = _instance.eventObjectId || '0';
    _instance.from = _instance.from || undefined;
    _instance.to = _instance.to || undefined;
    _instance.maxItems = _instance.maxItems || 0;
  }

  /**
   * Deserializes / reads binary message into message instance using provided binary reader
   * @param _instance message instance
   * @param _reader binary reader instance
   */
  static deserializeBinaryFromReader(
    _instance: GetEventsHistoryRequest,
    _reader: BinaryReader
  ) {
    while (_reader.nextField()) {
      if (_reader.isEndGroup()) break;

      switch (_reader.getFieldNumber()) {
        case 1:
          _instance.customerId = _reader.readString();
          break;
        case 2:
          _instance.eventObjectId = _reader.readInt64String();
          break;
        case 3:
          _instance.from = new googleProtobuf000.Timestamp();
          _reader.readMessage(
            _instance.from,
            googleProtobuf000.Timestamp.deserializeBinaryFromReader
          );
          break;
        case 4:
          _instance.to = new googleProtobuf000.Timestamp();
          _reader.readMessage(
            _instance.to,
            googleProtobuf000.Timestamp.deserializeBinaryFromReader
          );
          break;
        case 5:
          _instance.maxItems = _reader.readInt32();
          break;
        default:
          _reader.skipField();
      }
    }

    GetEventsHistoryRequest.refineValues(_instance);
  }

  /**
   * Serializes a message to binary format using provided binary reader
   * @param _instance message instance
   * @param _writer binary writer instance
   */
  static serializeBinaryToWriter(
    _instance: GetEventsHistoryRequest,
    _writer: BinaryWriter
  ) {
    if (_instance.customerId) {
      _writer.writeString(1, _instance.customerId);
    }
    if (_instance.eventObjectId) {
      _writer.writeInt64String(2, _instance.eventObjectId);
    }
    if (_instance.from) {
      _writer.writeMessage(
        3,
        _instance.from as any,
        googleProtobuf000.Timestamp.serializeBinaryToWriter
      );
    }
    if (_instance.to) {
      _writer.writeMessage(
        4,
        _instance.to as any,
        googleProtobuf000.Timestamp.serializeBinaryToWriter
      );
    }
    if (_instance.maxItems) {
      _writer.writeInt32(5, _instance.maxItems);
    }
  }

  private _customerId?: string;
  private _eventObjectId?: string;
  private _from?: googleProtobuf000.Timestamp;
  private _to?: googleProtobuf000.Timestamp;
  private _maxItems?: number;

  /**
   * Message constructor. Initializes the properties and applies default Protobuf values if necessary
   * @param _value initial values object or instance of GetEventsHistoryRequest to deeply clone from
   */
  constructor(_value?: RecursivePartial<GetEventsHistoryRequest.AsObject>) {
    _value = _value || {};
    this.customerId = _value.customerId;
    this.eventObjectId = _value.eventObjectId;
    this.from = _value.from
      ? new googleProtobuf000.Timestamp(_value.from)
      : undefined;
    this.to = _value.to
      ? new googleProtobuf000.Timestamp(_value.to)
      : undefined;
    this.maxItems = _value.maxItems;
    GetEventsHistoryRequest.refineValues(this);
  }
  get customerId(): string | undefined {
    return this._customerId;
  }
  set customerId(value: string | undefined) {
    this._customerId = value;
  }
  get eventObjectId(): string | undefined {
    return this._eventObjectId;
  }
  set eventObjectId(value: string | undefined) {
    this._eventObjectId = value;
  }
  get from(): googleProtobuf000.Timestamp | undefined {
    return this._from;
  }
  set from(value: googleProtobuf000.Timestamp | undefined) {
    this._from = value;
  }
  get to(): googleProtobuf000.Timestamp | undefined {
    return this._to;
  }
  set to(value: googleProtobuf000.Timestamp | undefined) {
    this._to = value;
  }
  get maxItems(): number | undefined {
    return this._maxItems;
  }
  set maxItems(value: number | undefined) {
    this._maxItems = value;
  }

  /**
   * Serialize message to binary data
   * @param instance message instance
   */
  serializeBinary() {
    const writer = new BinaryWriter();
    GetEventsHistoryRequest.serializeBinaryToWriter(this, writer);
    return writer.getResultBuffer();
  }

  /**
   * Cast message to standard JavaScript object (all non-primitive values are deeply cloned)
   */
  toObject(): GetEventsHistoryRequest.AsObject {
    return {
      customerId: this.customerId,
      eventObjectId: this.eventObjectId,
      from: this.from ? this.from.toObject() : undefined,
      to: this.to ? this.to.toObject() : undefined,
      maxItems: this.maxItems
    };
  }

  /**
   * Convenience method to support JSON.stringify(message), replicates the structure of toObject()
   */
  toJSON() {
    return this.toObject();
  }

  /**
   * Cast message to JSON using protobuf JSON notation: https://developers.google.com/protocol-buffers/docs/proto3#json
   * Attention: output differs from toObject() e.g. enums are represented as names and not as numbers, Timestamp is an ISO Date string format etc.
   * If the message itself or some of descendant messages is google.protobuf.Any, you MUST provide a message pool as options. If not, the messagePool is not required
   */
  toProtobufJSON(
    // @ts-ignore
    options?: ToProtobufJSONOptions
  ): GetEventsHistoryRequest.AsProtobufJSON {
    return {
      customerId: this.customerId,
      eventObjectId: this.eventObjectId,
      from: this.from ? this.from.toProtobufJSON(options) : null,
      to: this.to ? this.to.toProtobufJSON(options) : null,
      maxItems: this.maxItems
    };
  }
}
export module GetEventsHistoryRequest {
  /**
   * Standard JavaScript object representation for GetEventsHistoryRequest
   */
  export interface AsObject {
    customerId?: string;
    eventObjectId?: string;
    from?: googleProtobuf000.Timestamp.AsObject;
    to?: googleProtobuf000.Timestamp.AsObject;
    maxItems?: number;
  }

  /**
   * Protobuf JSON representation for GetEventsHistoryRequest
   */
  export interface AsProtobufJSON {
    customerId?: string;
    eventObjectId?: string;
    from?: googleProtobuf000.Timestamp.AsProtobufJSON | null;
    to?: googleProtobuf000.Timestamp.AsProtobufJSON | null;
    maxItems?: number;
  }
}

/**
 * Message implementation for modulebms.events.GetEventsHistoryResponse
 */
export class GetEventsHistoryResponse implements GrpcMessage {
  static id = 'modulebms.events.GetEventsHistoryResponse';

  /**
   * Deserialize binary data to message
   * @param instance message instance
   */
  static deserializeBinary(bytes: ByteSource) {
    const instance = new GetEventsHistoryResponse();
    GetEventsHistoryResponse.deserializeBinaryFromReader(
      instance,
      new BinaryReader(bytes)
    );
    return instance;
  }

  /**
   * Check all the properties and set default protobuf values if necessary
   * @param _instance message instance
   */
  static refineValues(_instance: GetEventsHistoryResponse) {
    _instance.events = _instance.events || [];
  }

  /**
   * Deserializes / reads binary message into message instance using provided binary reader
   * @param _instance message instance
   * @param _reader binary reader instance
   */
  static deserializeBinaryFromReader(
    _instance: GetEventsHistoryResponse,
    _reader: BinaryReader
  ) {
    while (_reader.nextField()) {
      if (_reader.isEndGroup()) break;

      switch (_reader.getFieldNumber()) {
        case 1:
          const messageInitializer1 = new EventHistory();
          _reader.readMessage(
            messageInitializer1,
            EventHistory.deserializeBinaryFromReader
          );
          (_instance.events = _instance.events || []).push(messageInitializer1);
          break;
        default:
          _reader.skipField();
      }
    }

    GetEventsHistoryResponse.refineValues(_instance);
  }

  /**
   * Serializes a message to binary format using provided binary reader
   * @param _instance message instance
   * @param _writer binary writer instance
   */
  static serializeBinaryToWriter(
    _instance: GetEventsHistoryResponse,
    _writer: BinaryWriter
  ) {
    if (_instance.events && _instance.events.length) {
      _writer.writeRepeatedMessage(
        1,
        _instance.events as any,
        EventHistory.serializeBinaryToWriter
      );
    }
  }

  private _events?: EventHistory[];

  /**
   * Message constructor. Initializes the properties and applies default Protobuf values if necessary
   * @param _value initial values object or instance of GetEventsHistoryResponse to deeply clone from
   */
  constructor(_value?: RecursivePartial<GetEventsHistoryResponse.AsObject>) {
    _value = _value || {};
    this.events = (_value.events || []).map(m => new EventHistory(m));
    GetEventsHistoryResponse.refineValues(this);
  }
  get events(): EventHistory[] | undefined {
    return this._events;
  }
  set events(value: EventHistory[] | undefined) {
    this._events = value;
  }

  /**
   * Serialize message to binary data
   * @param instance message instance
   */
  serializeBinary() {
    const writer = new BinaryWriter();
    GetEventsHistoryResponse.serializeBinaryToWriter(this, writer);
    return writer.getResultBuffer();
  }

  /**
   * Cast message to standard JavaScript object (all non-primitive values are deeply cloned)
   */
  toObject(): GetEventsHistoryResponse.AsObject {
    return {
      events: (this.events || []).map(m => m.toObject())
    };
  }

  /**
   * Convenience method to support JSON.stringify(message), replicates the structure of toObject()
   */
  toJSON() {
    return this.toObject();
  }

  /**
   * Cast message to JSON using protobuf JSON notation: https://developers.google.com/protocol-buffers/docs/proto3#json
   * Attention: output differs from toObject() e.g. enums are represented as names and not as numbers, Timestamp is an ISO Date string format etc.
   * If the message itself or some of descendant messages is google.protobuf.Any, you MUST provide a message pool as options. If not, the messagePool is not required
   */
  toProtobufJSON(
    // @ts-ignore
    options?: ToProtobufJSONOptions
  ): GetEventsHistoryResponse.AsProtobufJSON {
    return {
      events: (this.events || []).map(m => m.toProtobufJSON(options))
    };
  }
}
export module GetEventsHistoryResponse {
  /**
   * Standard JavaScript object representation for GetEventsHistoryResponse
   */
  export interface AsObject {
    events?: EventHistory.AsObject[];
  }

  /**
   * Protobuf JSON representation for GetEventsHistoryResponse
   */
  export interface AsProtobufJSON {
    events?: EventHistory.AsProtobufJSON[] | null;
  }
}

/**
 * Message implementation for modulebms.events.UpdateEventRequest
 */
export class UpdateEventRequest implements GrpcMessage {
  static id = 'modulebms.events.UpdateEventRequest';

  /**
   * Deserialize binary data to message
   * @param instance message instance
   */
  static deserializeBinary(bytes: ByteSource) {
    const instance = new UpdateEventRequest();
    UpdateEventRequest.deserializeBinaryFromReader(
      instance,
      new BinaryReader(bytes)
    );
    return instance;
  }

  /**
   * Check all the properties and set default protobuf values if necessary
   * @param _instance message instance
   */
  static refineValues(_instance: UpdateEventRequest) {
    _instance.event = _instance.event || undefined;
  }

  /**
   * Deserializes / reads binary message into message instance using provided binary reader
   * @param _instance message instance
   * @param _reader binary reader instance
   */
  static deserializeBinaryFromReader(
    _instance: UpdateEventRequest,
    _reader: BinaryReader
  ) {
    while (_reader.nextField()) {
      if (_reader.isEndGroup()) break;

      switch (_reader.getFieldNumber()) {
        case 1:
          _instance.event = new Event();
          _reader.readMessage(
            _instance.event,
            Event.deserializeBinaryFromReader
          );
          break;
        default:
          _reader.skipField();
      }
    }

    UpdateEventRequest.refineValues(_instance);
  }

  /**
   * Serializes a message to binary format using provided binary reader
   * @param _instance message instance
   * @param _writer binary writer instance
   */
  static serializeBinaryToWriter(
    _instance: UpdateEventRequest,
    _writer: BinaryWriter
  ) {
    if (_instance.event) {
      _writer.writeMessage(
        1,
        _instance.event as any,
        Event.serializeBinaryToWriter
      );
    }
  }

  private _event?: Event;

  /**
   * Message constructor. Initializes the properties and applies default Protobuf values if necessary
   * @param _value initial values object or instance of UpdateEventRequest to deeply clone from
   */
  constructor(_value?: RecursivePartial<UpdateEventRequest.AsObject>) {
    _value = _value || {};
    this.event = _value.event ? new Event(_value.event) : undefined;
    UpdateEventRequest.refineValues(this);
  }
  get event(): Event | undefined {
    return this._event;
  }
  set event(value: Event | undefined) {
    this._event = value;
  }

  /**
   * Serialize message to binary data
   * @param instance message instance
   */
  serializeBinary() {
    const writer = new BinaryWriter();
    UpdateEventRequest.serializeBinaryToWriter(this, writer);
    return writer.getResultBuffer();
  }

  /**
   * Cast message to standard JavaScript object (all non-primitive values are deeply cloned)
   */
  toObject(): UpdateEventRequest.AsObject {
    return {
      event: this.event ? this.event.toObject() : undefined
    };
  }

  /**
   * Convenience method to support JSON.stringify(message), replicates the structure of toObject()
   */
  toJSON() {
    return this.toObject();
  }

  /**
   * Cast message to JSON using protobuf JSON notation: https://developers.google.com/protocol-buffers/docs/proto3#json
   * Attention: output differs from toObject() e.g. enums are represented as names and not as numbers, Timestamp is an ISO Date string format etc.
   * If the message itself or some of descendant messages is google.protobuf.Any, you MUST provide a message pool as options. If not, the messagePool is not required
   */
  toProtobufJSON(
    // @ts-ignore
    options?: ToProtobufJSONOptions
  ): UpdateEventRequest.AsProtobufJSON {
    return {
      event: this.event ? this.event.toProtobufJSON(options) : null
    };
  }
}
export module UpdateEventRequest {
  /**
   * Standard JavaScript object representation for UpdateEventRequest
   */
  export interface AsObject {
    event?: Event.AsObject;
  }

  /**
   * Protobuf JSON representation for UpdateEventRequest
   */
  export interface AsProtobufJSON {
    event?: Event.AsProtobufJSON | null;
  }
}

/**
 * Message implementation for modulebms.events.UpdateEventResponse
 */
export class UpdateEventResponse implements GrpcMessage {
  static id = 'modulebms.events.UpdateEventResponse';

  /**
   * Deserialize binary data to message
   * @param instance message instance
   */
  static deserializeBinary(bytes: ByteSource) {
    const instance = new UpdateEventResponse();
    UpdateEventResponse.deserializeBinaryFromReader(
      instance,
      new BinaryReader(bytes)
    );
    return instance;
  }

  /**
   * Check all the properties and set default protobuf values if necessary
   * @param _instance message instance
   */
  static refineValues(_instance: UpdateEventResponse) {
    _instance.ok = _instance.ok || false;
  }

  /**
   * Deserializes / reads binary message into message instance using provided binary reader
   * @param _instance message instance
   * @param _reader binary reader instance
   */
  static deserializeBinaryFromReader(
    _instance: UpdateEventResponse,
    _reader: BinaryReader
  ) {
    while (_reader.nextField()) {
      if (_reader.isEndGroup()) break;

      switch (_reader.getFieldNumber()) {
        case 1:
          _instance.ok = _reader.readBool();
          break;
        default:
          _reader.skipField();
      }
    }

    UpdateEventResponse.refineValues(_instance);
  }

  /**
   * Serializes a message to binary format using provided binary reader
   * @param _instance message instance
   * @param _writer binary writer instance
   */
  static serializeBinaryToWriter(
    _instance: UpdateEventResponse,
    _writer: BinaryWriter
  ) {
    if (_instance.ok) {
      _writer.writeBool(1, _instance.ok);
    }
  }

  private _ok?: boolean;

  /**
   * Message constructor. Initializes the properties and applies default Protobuf values if necessary
   * @param _value initial values object or instance of UpdateEventResponse to deeply clone from
   */
  constructor(_value?: RecursivePartial<UpdateEventResponse.AsObject>) {
    _value = _value || {};
    this.ok = _value.ok;
    UpdateEventResponse.refineValues(this);
  }
  get ok(): boolean | undefined {
    return this._ok;
  }
  set ok(value: boolean | undefined) {
    this._ok = value;
  }

  /**
   * Serialize message to binary data
   * @param instance message instance
   */
  serializeBinary() {
    const writer = new BinaryWriter();
    UpdateEventResponse.serializeBinaryToWriter(this, writer);
    return writer.getResultBuffer();
  }

  /**
   * Cast message to standard JavaScript object (all non-primitive values are deeply cloned)
   */
  toObject(): UpdateEventResponse.AsObject {
    return {
      ok: this.ok
    };
  }

  /**
   * Convenience method to support JSON.stringify(message), replicates the structure of toObject()
   */
  toJSON() {
    return this.toObject();
  }

  /**
   * Cast message to JSON using protobuf JSON notation: https://developers.google.com/protocol-buffers/docs/proto3#json
   * Attention: output differs from toObject() e.g. enums are represented as names and not as numbers, Timestamp is an ISO Date string format etc.
   * If the message itself or some of descendant messages is google.protobuf.Any, you MUST provide a message pool as options. If not, the messagePool is not required
   */
  toProtobufJSON(
    // @ts-ignore
    options?: ToProtobufJSONOptions
  ): UpdateEventResponse.AsProtobufJSON {
    return {
      ok: this.ok
    };
  }
}
export module UpdateEventResponse {
  /**
   * Standard JavaScript object representation for UpdateEventResponse
   */
  export interface AsObject {
    ok?: boolean;
  }

  /**
   * Protobuf JSON representation for UpdateEventResponse
   */
  export interface AsProtobufJSON {
    ok?: boolean;
  }
}

/**
 * Message implementation for modulebms.events.GetEventSettingsRequest
 */
export class GetEventSettingsRequest implements GrpcMessage {
  static id = 'modulebms.events.GetEventSettingsRequest';

  /**
   * Deserialize binary data to message
   * @param instance message instance
   */
  static deserializeBinary(bytes: ByteSource) {
    const instance = new GetEventSettingsRequest();
    GetEventSettingsRequest.deserializeBinaryFromReader(
      instance,
      new BinaryReader(bytes)
    );
    return instance;
  }

  /**
   * Check all the properties and set default protobuf values if necessary
   * @param _instance message instance
   */
  static refineValues(_instance: GetEventSettingsRequest) {
    _instance.customerId = _instance.customerId || '';
  }

  /**
   * Deserializes / reads binary message into message instance using provided binary reader
   * @param _instance message instance
   * @param _reader binary reader instance
   */
  static deserializeBinaryFromReader(
    _instance: GetEventSettingsRequest,
    _reader: BinaryReader
  ) {
    while (_reader.nextField()) {
      if (_reader.isEndGroup()) break;

      switch (_reader.getFieldNumber()) {
        case 1:
          _instance.customerId = _reader.readString();
          break;
        default:
          _reader.skipField();
      }
    }

    GetEventSettingsRequest.refineValues(_instance);
  }

  /**
   * Serializes a message to binary format using provided binary reader
   * @param _instance message instance
   * @param _writer binary writer instance
   */
  static serializeBinaryToWriter(
    _instance: GetEventSettingsRequest,
    _writer: BinaryWriter
  ) {
    if (_instance.customerId) {
      _writer.writeString(1, _instance.customerId);
    }
  }

  private _customerId?: string;

  /**
   * Message constructor. Initializes the properties and applies default Protobuf values if necessary
   * @param _value initial values object or instance of GetEventSettingsRequest to deeply clone from
   */
  constructor(_value?: RecursivePartial<GetEventSettingsRequest.AsObject>) {
    _value = _value || {};
    this.customerId = _value.customerId;
    GetEventSettingsRequest.refineValues(this);
  }
  get customerId(): string | undefined {
    return this._customerId;
  }
  set customerId(value: string | undefined) {
    this._customerId = value;
  }

  /**
   * Serialize message to binary data
   * @param instance message instance
   */
  serializeBinary() {
    const writer = new BinaryWriter();
    GetEventSettingsRequest.serializeBinaryToWriter(this, writer);
    return writer.getResultBuffer();
  }

  /**
   * Cast message to standard JavaScript object (all non-primitive values are deeply cloned)
   */
  toObject(): GetEventSettingsRequest.AsObject {
    return {
      customerId: this.customerId
    };
  }

  /**
   * Convenience method to support JSON.stringify(message), replicates the structure of toObject()
   */
  toJSON() {
    return this.toObject();
  }

  /**
   * Cast message to JSON using protobuf JSON notation: https://developers.google.com/protocol-buffers/docs/proto3#json
   * Attention: output differs from toObject() e.g. enums are represented as names and not as numbers, Timestamp is an ISO Date string format etc.
   * If the message itself or some of descendant messages is google.protobuf.Any, you MUST provide a message pool as options. If not, the messagePool is not required
   */
  toProtobufJSON(
    // @ts-ignore
    options?: ToProtobufJSONOptions
  ): GetEventSettingsRequest.AsProtobufJSON {
    return {
      customerId: this.customerId
    };
  }
}
export module GetEventSettingsRequest {
  /**
   * Standard JavaScript object representation for GetEventSettingsRequest
   */
  export interface AsObject {
    customerId?: string;
  }

  /**
   * Protobuf JSON representation for GetEventSettingsRequest
   */
  export interface AsProtobufJSON {
    customerId?: string;
  }
}

/**
 * Message implementation for modulebms.events.GetEventSettingsResponse
 */
export class GetEventSettingsResponse implements GrpcMessage {
  static id = 'modulebms.events.GetEventSettingsResponse';

  /**
   * Deserialize binary data to message
   * @param instance message instance
   */
  static deserializeBinary(bytes: ByteSource) {
    const instance = new GetEventSettingsResponse();
    GetEventSettingsResponse.deserializeBinaryFromReader(
      instance,
      new BinaryReader(bytes)
    );
    return instance;
  }

  /**
   * Check all the properties and set default protobuf values if necessary
   * @param _instance message instance
   */
  static refineValues(_instance: GetEventSettingsResponse) {
    _instance.escalationInfos = _instance.escalationInfos || [];
  }

  /**
   * Deserializes / reads binary message into message instance using provided binary reader
   * @param _instance message instance
   * @param _reader binary reader instance
   */
  static deserializeBinaryFromReader(
    _instance: GetEventSettingsResponse,
    _reader: BinaryReader
  ) {
    while (_reader.nextField()) {
      if (_reader.isEndGroup()) break;

      switch (_reader.getFieldNumber()) {
        case 1:
          const messageInitializer1 = new EscalationInfo();
          _reader.readMessage(
            messageInitializer1,
            EscalationInfo.deserializeBinaryFromReader
          );
          (_instance.escalationInfos = _instance.escalationInfos || []).push(
            messageInitializer1
          );
          break;
        default:
          _reader.skipField();
      }
    }

    GetEventSettingsResponse.refineValues(_instance);
  }

  /**
   * Serializes a message to binary format using provided binary reader
   * @param _instance message instance
   * @param _writer binary writer instance
   */
  static serializeBinaryToWriter(
    _instance: GetEventSettingsResponse,
    _writer: BinaryWriter
  ) {
    if (_instance.escalationInfos && _instance.escalationInfos.length) {
      _writer.writeRepeatedMessage(
        1,
        _instance.escalationInfos as any,
        EscalationInfo.serializeBinaryToWriter
      );
    }
  }

  private _escalationInfos?: EscalationInfo[];

  /**
   * Message constructor. Initializes the properties and applies default Protobuf values if necessary
   * @param _value initial values object or instance of GetEventSettingsResponse to deeply clone from
   */
  constructor(_value?: RecursivePartial<GetEventSettingsResponse.AsObject>) {
    _value = _value || {};
    this.escalationInfos = (_value.escalationInfos || []).map(
      m => new EscalationInfo(m)
    );
    GetEventSettingsResponse.refineValues(this);
  }
  get escalationInfos(): EscalationInfo[] | undefined {
    return this._escalationInfos;
  }
  set escalationInfos(value: EscalationInfo[] | undefined) {
    this._escalationInfos = value;
  }

  /**
   * Serialize message to binary data
   * @param instance message instance
   */
  serializeBinary() {
    const writer = new BinaryWriter();
    GetEventSettingsResponse.serializeBinaryToWriter(this, writer);
    return writer.getResultBuffer();
  }

  /**
   * Cast message to standard JavaScript object (all non-primitive values are deeply cloned)
   */
  toObject(): GetEventSettingsResponse.AsObject {
    return {
      escalationInfos: (this.escalationInfos || []).map(m => m.toObject())
    };
  }

  /**
   * Convenience method to support JSON.stringify(message), replicates the structure of toObject()
   */
  toJSON() {
    return this.toObject();
  }

  /**
   * Cast message to JSON using protobuf JSON notation: https://developers.google.com/protocol-buffers/docs/proto3#json
   * Attention: output differs from toObject() e.g. enums are represented as names and not as numbers, Timestamp is an ISO Date string format etc.
   * If the message itself or some of descendant messages is google.protobuf.Any, you MUST provide a message pool as options. If not, the messagePool is not required
   */
  toProtobufJSON(
    // @ts-ignore
    options?: ToProtobufJSONOptions
  ): GetEventSettingsResponse.AsProtobufJSON {
    return {
      escalationInfos: (this.escalationInfos || []).map(m =>
        m.toProtobufJSON(options)
      )
    };
  }
}
export module GetEventSettingsResponse {
  /**
   * Standard JavaScript object representation for GetEventSettingsResponse
   */
  export interface AsObject {
    escalationInfos?: EscalationInfo.AsObject[];
  }

  /**
   * Protobuf JSON representation for GetEventSettingsResponse
   */
  export interface AsProtobufJSON {
    escalationInfos?: EscalationInfo.AsProtobufJSON[] | null;
  }
}

/**
 * Message implementation for modulebms.events.UpdateEventSettingsRequest
 */
export class UpdateEventSettingsRequest implements GrpcMessage {
  static id = 'modulebms.events.UpdateEventSettingsRequest';

  /**
   * Deserialize binary data to message
   * @param instance message instance
   */
  static deserializeBinary(bytes: ByteSource) {
    const instance = new UpdateEventSettingsRequest();
    UpdateEventSettingsRequest.deserializeBinaryFromReader(
      instance,
      new BinaryReader(bytes)
    );
    return instance;
  }

  /**
   * Check all the properties and set default protobuf values if necessary
   * @param _instance message instance
   */
  static refineValues(_instance: UpdateEventSettingsRequest) {
    _instance.customerId = _instance.customerId || '';
    _instance.escalationInfos = _instance.escalationInfos || [];
  }

  /**
   * Deserializes / reads binary message into message instance using provided binary reader
   * @param _instance message instance
   * @param _reader binary reader instance
   */
  static deserializeBinaryFromReader(
    _instance: UpdateEventSettingsRequest,
    _reader: BinaryReader
  ) {
    while (_reader.nextField()) {
      if (_reader.isEndGroup()) break;

      switch (_reader.getFieldNumber()) {
        case 1:
          _instance.customerId = _reader.readString();
          break;
        case 2:
          const messageInitializer2 = new EscalationInfo();
          _reader.readMessage(
            messageInitializer2,
            EscalationInfo.deserializeBinaryFromReader
          );
          (_instance.escalationInfos = _instance.escalationInfos || []).push(
            messageInitializer2
          );
          break;
        default:
          _reader.skipField();
      }
    }

    UpdateEventSettingsRequest.refineValues(_instance);
  }

  /**
   * Serializes a message to binary format using provided binary reader
   * @param _instance message instance
   * @param _writer binary writer instance
   */
  static serializeBinaryToWriter(
    _instance: UpdateEventSettingsRequest,
    _writer: BinaryWriter
  ) {
    if (_instance.customerId) {
      _writer.writeString(1, _instance.customerId);
    }
    if (_instance.escalationInfos && _instance.escalationInfos.length) {
      _writer.writeRepeatedMessage(
        2,
        _instance.escalationInfos as any,
        EscalationInfo.serializeBinaryToWriter
      );
    }
  }

  private _customerId?: string;
  private _escalationInfos?: EscalationInfo[];

  /**
   * Message constructor. Initializes the properties and applies default Protobuf values if necessary
   * @param _value initial values object or instance of UpdateEventSettingsRequest to deeply clone from
   */
  constructor(_value?: RecursivePartial<UpdateEventSettingsRequest.AsObject>) {
    _value = _value || {};
    this.customerId = _value.customerId;
    this.escalationInfos = (_value.escalationInfos || []).map(
      m => new EscalationInfo(m)
    );
    UpdateEventSettingsRequest.refineValues(this);
  }
  get customerId(): string | undefined {
    return this._customerId;
  }
  set customerId(value: string | undefined) {
    this._customerId = value;
  }
  get escalationInfos(): EscalationInfo[] | undefined {
    return this._escalationInfos;
  }
  set escalationInfos(value: EscalationInfo[] | undefined) {
    this._escalationInfos = value;
  }

  /**
   * Serialize message to binary data
   * @param instance message instance
   */
  serializeBinary() {
    const writer = new BinaryWriter();
    UpdateEventSettingsRequest.serializeBinaryToWriter(this, writer);
    return writer.getResultBuffer();
  }

  /**
   * Cast message to standard JavaScript object (all non-primitive values are deeply cloned)
   */
  toObject(): UpdateEventSettingsRequest.AsObject {
    return {
      customerId: this.customerId,
      escalationInfos: (this.escalationInfos || []).map(m => m.toObject())
    };
  }

  /**
   * Convenience method to support JSON.stringify(message), replicates the structure of toObject()
   */
  toJSON() {
    return this.toObject();
  }

  /**
   * Cast message to JSON using protobuf JSON notation: https://developers.google.com/protocol-buffers/docs/proto3#json
   * Attention: output differs from toObject() e.g. enums are represented as names and not as numbers, Timestamp is an ISO Date string format etc.
   * If the message itself or some of descendant messages is google.protobuf.Any, you MUST provide a message pool as options. If not, the messagePool is not required
   */
  toProtobufJSON(
    // @ts-ignore
    options?: ToProtobufJSONOptions
  ): UpdateEventSettingsRequest.AsProtobufJSON {
    return {
      customerId: this.customerId,
      escalationInfos: (this.escalationInfos || []).map(m =>
        m.toProtobufJSON(options)
      )
    };
  }
}
export module UpdateEventSettingsRequest {
  /**
   * Standard JavaScript object representation for UpdateEventSettingsRequest
   */
  export interface AsObject {
    customerId?: string;
    escalationInfos?: EscalationInfo.AsObject[];
  }

  /**
   * Protobuf JSON representation for UpdateEventSettingsRequest
   */
  export interface AsProtobufJSON {
    customerId?: string;
    escalationInfos?: EscalationInfo.AsProtobufJSON[] | null;
  }
}

/**
 * Message implementation for modulebms.events.UpdateEventSettingsResponse
 */
export class UpdateEventSettingsResponse implements GrpcMessage {
  static id = 'modulebms.events.UpdateEventSettingsResponse';

  /**
   * Deserialize binary data to message
   * @param instance message instance
   */
  static deserializeBinary(bytes: ByteSource) {
    const instance = new UpdateEventSettingsResponse();
    UpdateEventSettingsResponse.deserializeBinaryFromReader(
      instance,
      new BinaryReader(bytes)
    );
    return instance;
  }

  /**
   * Check all the properties and set default protobuf values if necessary
   * @param _instance message instance
   */
  static refineValues(_instance: UpdateEventSettingsResponse) {
    _instance.ok = _instance.ok || false;
  }

  /**
   * Deserializes / reads binary message into message instance using provided binary reader
   * @param _instance message instance
   * @param _reader binary reader instance
   */
  static deserializeBinaryFromReader(
    _instance: UpdateEventSettingsResponse,
    _reader: BinaryReader
  ) {
    while (_reader.nextField()) {
      if (_reader.isEndGroup()) break;

      switch (_reader.getFieldNumber()) {
        case 1:
          _instance.ok = _reader.readBool();
          break;
        default:
          _reader.skipField();
      }
    }

    UpdateEventSettingsResponse.refineValues(_instance);
  }

  /**
   * Serializes a message to binary format using provided binary reader
   * @param _instance message instance
   * @param _writer binary writer instance
   */
  static serializeBinaryToWriter(
    _instance: UpdateEventSettingsResponse,
    _writer: BinaryWriter
  ) {
    if (_instance.ok) {
      _writer.writeBool(1, _instance.ok);
    }
  }

  private _ok?: boolean;

  /**
   * Message constructor. Initializes the properties and applies default Protobuf values if necessary
   * @param _value initial values object or instance of UpdateEventSettingsResponse to deeply clone from
   */
  constructor(_value?: RecursivePartial<UpdateEventSettingsResponse.AsObject>) {
    _value = _value || {};
    this.ok = _value.ok;
    UpdateEventSettingsResponse.refineValues(this);
  }
  get ok(): boolean | undefined {
    return this._ok;
  }
  set ok(value: boolean | undefined) {
    this._ok = value;
  }

  /**
   * Serialize message to binary data
   * @param instance message instance
   */
  serializeBinary() {
    const writer = new BinaryWriter();
    UpdateEventSettingsResponse.serializeBinaryToWriter(this, writer);
    return writer.getResultBuffer();
  }

  /**
   * Cast message to standard JavaScript object (all non-primitive values are deeply cloned)
   */
  toObject(): UpdateEventSettingsResponse.AsObject {
    return {
      ok: this.ok
    };
  }

  /**
   * Convenience method to support JSON.stringify(message), replicates the structure of toObject()
   */
  toJSON() {
    return this.toObject();
  }

  /**
   * Cast message to JSON using protobuf JSON notation: https://developers.google.com/protocol-buffers/docs/proto3#json
   * Attention: output differs from toObject() e.g. enums are represented as names and not as numbers, Timestamp is an ISO Date string format etc.
   * If the message itself or some of descendant messages is google.protobuf.Any, you MUST provide a message pool as options. If not, the messagePool is not required
   */
  toProtobufJSON(
    // @ts-ignore
    options?: ToProtobufJSONOptions
  ): UpdateEventSettingsResponse.AsProtobufJSON {
    return {
      ok: this.ok
    };
  }
}
export module UpdateEventSettingsResponse {
  /**
   * Standard JavaScript object representation for UpdateEventSettingsResponse
   */
  export interface AsObject {
    ok?: boolean;
  }

  /**
   * Protobuf JSON representation for UpdateEventSettingsResponse
   */
  export interface AsProtobufJSON {
    ok?: boolean;
  }
}

/**
 * Message implementation for modulebms.events.EscalationInfo
 */
export class EscalationInfo implements GrpcMessage {
  static id = 'modulebms.events.EscalationInfo';

  /**
   * Deserialize binary data to message
   * @param instance message instance
   */
  static deserializeBinary(bytes: ByteSource) {
    const instance = new EscalationInfo();
    EscalationInfo.deserializeBinaryFromReader(
      instance,
      new BinaryReader(bytes)
    );
    return instance;
  }

  /**
   * Check all the properties and set default protobuf values if necessary
   * @param _instance message instance
   */
  static refineValues(_instance: EscalationInfo) {
    _instance.id = _instance.id || '';
    _instance.name = _instance.name || '';
    _instance.includeA = _instance.includeA || false;
    _instance.includeB = _instance.includeB || false;
    _instance.includeC = _instance.includeC || false;
    _instance.primarySendMethod = _instance.primarySendMethod || 0;
    _instance.secondarySendMethod = _instance.secondarySendMethod || 0;
    _instance.users = _instance.users || [];
  }

  /**
   * Deserializes / reads binary message into message instance using provided binary reader
   * @param _instance message instance
   * @param _reader binary reader instance
   */
  static deserializeBinaryFromReader(
    _instance: EscalationInfo,
    _reader: BinaryReader
  ) {
    while (_reader.nextField()) {
      if (_reader.isEndGroup()) break;

      switch (_reader.getFieldNumber()) {
        case 1:
          _instance.id = _reader.readString();
          break;
        case 2:
          _instance.name = _reader.readString();
          break;
        case 3:
          _instance.includeA = _reader.readBool();
          break;
        case 4:
          _instance.includeB = _reader.readBool();
          break;
        case 5:
          _instance.includeC = _reader.readBool();
          break;
        case 6:
          _instance.primarySendMethod = _reader.readEnum();
          break;
        case 7:
          _instance.secondarySendMethod = _reader.readEnum();
          break;
        case 8:
          const messageInitializer8 = new EventUser();
          _reader.readMessage(
            messageInitializer8,
            EventUser.deserializeBinaryFromReader
          );
          (_instance.users = _instance.users || []).push(messageInitializer8);
          break;
        default:
          _reader.skipField();
      }
    }

    EscalationInfo.refineValues(_instance);
  }

  /**
   * Serializes a message to binary format using provided binary reader
   * @param _instance message instance
   * @param _writer binary writer instance
   */
  static serializeBinaryToWriter(
    _instance: EscalationInfo,
    _writer: BinaryWriter
  ) {
    if (_instance.id) {
      _writer.writeString(1, _instance.id);
    }
    if (_instance.name) {
      _writer.writeString(2, _instance.name);
    }
    if (_instance.includeA) {
      _writer.writeBool(3, _instance.includeA);
    }
    if (_instance.includeB) {
      _writer.writeBool(4, _instance.includeB);
    }
    if (_instance.includeC) {
      _writer.writeBool(5, _instance.includeC);
    }
    if (_instance.primarySendMethod) {
      _writer.writeEnum(6, _instance.primarySendMethod);
    }
    if (_instance.secondarySendMethod) {
      _writer.writeEnum(7, _instance.secondarySendMethod);
    }
    if (_instance.users && _instance.users.length) {
      _writer.writeRepeatedMessage(
        8,
        _instance.users as any,
        EventUser.serializeBinaryToWriter
      );
    }
  }

  private _id?: string;
  private _name?: string;
  private _includeA?: boolean;
  private _includeB?: boolean;
  private _includeC?: boolean;
  private _primarySendMethod?: EscalationInfo.SendMethods;
  private _secondarySendMethod?: EscalationInfo.SendMethods;
  private _users?: EventUser[];

  /**
   * Message constructor. Initializes the properties and applies default Protobuf values if necessary
   * @param _value initial values object or instance of EscalationInfo to deeply clone from
   */
  constructor(_value?: RecursivePartial<EscalationInfo.AsObject>) {
    _value = _value || {};
    this.id = _value.id;
    this.name = _value.name;
    this.includeA = _value.includeA;
    this.includeB = _value.includeB;
    this.includeC = _value.includeC;
    this.primarySendMethod = _value.primarySendMethod;
    this.secondarySendMethod = _value.secondarySendMethod;
    this.users = (_value.users || []).map(m => new EventUser(m));
    EscalationInfo.refineValues(this);
  }
  get id(): string | undefined {
    return this._id;
  }
  set id(value: string | undefined) {
    this._id = value;
  }
  get name(): string | undefined {
    return this._name;
  }
  set name(value: string | undefined) {
    this._name = value;
  }
  get includeA(): boolean | undefined {
    return this._includeA;
  }
  set includeA(value: boolean | undefined) {
    this._includeA = value;
  }
  get includeB(): boolean | undefined {
    return this._includeB;
  }
  set includeB(value: boolean | undefined) {
    this._includeB = value;
  }
  get includeC(): boolean | undefined {
    return this._includeC;
  }
  set includeC(value: boolean | undefined) {
    this._includeC = value;
  }
  get primarySendMethod(): EscalationInfo.SendMethods | undefined {
    return this._primarySendMethod;
  }
  set primarySendMethod(value: EscalationInfo.SendMethods | undefined) {
    this._primarySendMethod = value;
  }
  get secondarySendMethod(): EscalationInfo.SendMethods | undefined {
    return this._secondarySendMethod;
  }
  set secondarySendMethod(value: EscalationInfo.SendMethods | undefined) {
    this._secondarySendMethod = value;
  }
  get users(): EventUser[] | undefined {
    return this._users;
  }
  set users(value: EventUser[] | undefined) {
    this._users = value;
  }

  /**
   * Serialize message to binary data
   * @param instance message instance
   */
  serializeBinary() {
    const writer = new BinaryWriter();
    EscalationInfo.serializeBinaryToWriter(this, writer);
    return writer.getResultBuffer();
  }

  /**
   * Cast message to standard JavaScript object (all non-primitive values are deeply cloned)
   */
  toObject(): EscalationInfo.AsObject {
    return {
      id: this.id,
      name: this.name,
      includeA: this.includeA,
      includeB: this.includeB,
      includeC: this.includeC,
      primarySendMethod: this.primarySendMethod,
      secondarySendMethod: this.secondarySendMethod,
      users: (this.users || []).map(m => m.toObject())
    };
  }

  /**
   * Convenience method to support JSON.stringify(message), replicates the structure of toObject()
   */
  toJSON() {
    return this.toObject();
  }

  /**
   * Cast message to JSON using protobuf JSON notation: https://developers.google.com/protocol-buffers/docs/proto3#json
   * Attention: output differs from toObject() e.g. enums are represented as names and not as numbers, Timestamp is an ISO Date string format etc.
   * If the message itself or some of descendant messages is google.protobuf.Any, you MUST provide a message pool as options. If not, the messagePool is not required
   */
  toProtobufJSON(
    // @ts-ignore
    options?: ToProtobufJSONOptions
  ): EscalationInfo.AsProtobufJSON {
    return {
      id: this.id,
      name: this.name,
      includeA: this.includeA,
      includeB: this.includeB,
      includeC: this.includeC,
      primarySendMethod:
        EscalationInfo.SendMethods[
          this.primarySendMethod === null ||
          this.primarySendMethod === undefined
            ? 0
            : this.primarySendMethod
        ],
      secondarySendMethod:
        EscalationInfo.SendMethods[
          this.secondarySendMethod === null ||
          this.secondarySendMethod === undefined
            ? 0
            : this.secondarySendMethod
        ],
      users: (this.users || []).map(m => m.toProtobufJSON(options))
    };
  }
}
export module EscalationInfo {
  /**
   * Standard JavaScript object representation for EscalationInfo
   */
  export interface AsObject {
    id?: string;
    name?: string;
    includeA?: boolean;
    includeB?: boolean;
    includeC?: boolean;
    primarySendMethod?: EscalationInfo.SendMethods;
    secondarySendMethod?: EscalationInfo.SendMethods;
    users?: EventUser.AsObject[];
  }

  /**
   * Protobuf JSON representation for EscalationInfo
   */
  export interface AsProtobufJSON {
    id?: string;
    name?: string;
    includeA?: boolean;
    includeB?: boolean;
    includeC?: boolean;
    primarySendMethod?: string;
    secondarySendMethod?: string;
    users?: EventUser.AsProtobufJSON[] | null;
  }
  export enum SendMethods {
    NONE = 0,
    PHONE = 1,
    EMAIL = 2
  }
}

/**
 * Message implementation for modulebms.events.EventUser
 */
export class EventUser implements GrpcMessage {
  static id = 'modulebms.events.EventUser';

  /**
   * Deserialize binary data to message
   * @param instance message instance
   */
  static deserializeBinary(bytes: ByteSource) {
    const instance = new EventUser();
    EventUser.deserializeBinaryFromReader(instance, new BinaryReader(bytes));
    return instance;
  }

  /**
   * Check all the properties and set default protobuf values if necessary
   * @param _instance message instance
   */
  static refineValues(_instance: EventUser) {
    _instance.id = _instance.id || '';
    _instance.typeId = _instance.typeId || '';
    _instance.name = _instance.name || '';
    _instance.email = _instance.email || '';
    _instance.phone = _instance.phone || '';
  }

  /**
   * Deserializes / reads binary message into message instance using provided binary reader
   * @param _instance message instance
   * @param _reader binary reader instance
   */
  static deserializeBinaryFromReader(
    _instance: EventUser,
    _reader: BinaryReader
  ) {
    while (_reader.nextField()) {
      if (_reader.isEndGroup()) break;

      switch (_reader.getFieldNumber()) {
        case 1:
          _instance.id = _reader.readString();
          break;
        case 2:
          _instance.typeId = _reader.readString();
          break;
        case 3:
          _instance.name = _reader.readString();
          break;
        case 4:
          _instance.email = _reader.readString();
          break;
        case 5:
          _instance.phone = _reader.readString();
          break;
        default:
          _reader.skipField();
      }
    }

    EventUser.refineValues(_instance);
  }

  /**
   * Serializes a message to binary format using provided binary reader
   * @param _instance message instance
   * @param _writer binary writer instance
   */
  static serializeBinaryToWriter(_instance: EventUser, _writer: BinaryWriter) {
    if (_instance.id) {
      _writer.writeString(1, _instance.id);
    }
    if (_instance.typeId) {
      _writer.writeString(2, _instance.typeId);
    }
    if (_instance.name) {
      _writer.writeString(3, _instance.name);
    }
    if (_instance.email) {
      _writer.writeString(4, _instance.email);
    }
    if (_instance.phone) {
      _writer.writeString(5, _instance.phone);
    }
  }

  private _id?: string;
  private _typeId?: string;
  private _name?: string;
  private _email?: string;
  private _phone?: string;

  /**
   * Message constructor. Initializes the properties and applies default Protobuf values if necessary
   * @param _value initial values object or instance of EventUser to deeply clone from
   */
  constructor(_value?: RecursivePartial<EventUser.AsObject>) {
    _value = _value || {};
    this.id = _value.id;
    this.typeId = _value.typeId;
    this.name = _value.name;
    this.email = _value.email;
    this.phone = _value.phone;
    EventUser.refineValues(this);
  }
  get id(): string | undefined {
    return this._id;
  }
  set id(value: string | undefined) {
    this._id = value;
  }
  get typeId(): string | undefined {
    return this._typeId;
  }
  set typeId(value: string | undefined) {
    this._typeId = value;
  }
  get name(): string | undefined {
    return this._name;
  }
  set name(value: string | undefined) {
    this._name = value;
  }
  get email(): string | undefined {
    return this._email;
  }
  set email(value: string | undefined) {
    this._email = value;
  }
  get phone(): string | undefined {
    return this._phone;
  }
  set phone(value: string | undefined) {
    this._phone = value;
  }

  /**
   * Serialize message to binary data
   * @param instance message instance
   */
  serializeBinary() {
    const writer = new BinaryWriter();
    EventUser.serializeBinaryToWriter(this, writer);
    return writer.getResultBuffer();
  }

  /**
   * Cast message to standard JavaScript object (all non-primitive values are deeply cloned)
   */
  toObject(): EventUser.AsObject {
    return {
      id: this.id,
      typeId: this.typeId,
      name: this.name,
      email: this.email,
      phone: this.phone
    };
  }

  /**
   * Convenience method to support JSON.stringify(message), replicates the structure of toObject()
   */
  toJSON() {
    return this.toObject();
  }

  /**
   * Cast message to JSON using protobuf JSON notation: https://developers.google.com/protocol-buffers/docs/proto3#json
   * Attention: output differs from toObject() e.g. enums are represented as names and not as numbers, Timestamp is an ISO Date string format etc.
   * If the message itself or some of descendant messages is google.protobuf.Any, you MUST provide a message pool as options. If not, the messagePool is not required
   */
  toProtobufJSON(
    // @ts-ignore
    options?: ToProtobufJSONOptions
  ): EventUser.AsProtobufJSON {
    return {
      id: this.id,
      typeId: this.typeId,
      name: this.name,
      email: this.email,
      phone: this.phone
    };
  }
}
export module EventUser {
  /**
   * Standard JavaScript object representation for EventUser
   */
  export interface AsObject {
    id?: string;
    typeId?: string;
    name?: string;
    email?: string;
    phone?: string;
  }

  /**
   * Protobuf JSON representation for EventUser
   */
  export interface AsProtobufJSON {
    id?: string;
    typeId?: string;
    name?: string;
    email?: string;
    phone?: string;
  }
}

/**
 * Message implementation for modulebms.events.Event
 */
export class Event implements GrpcMessage {
  static id = 'modulebms.events.Event';

  /**
   * Deserialize binary data to message
   * @param instance message instance
   */
  static deserializeBinary(bytes: ByteSource) {
    const instance = new Event();
    Event.deserializeBinaryFromReader(instance, new BinaryReader(bytes));
    return instance;
  }

  /**
   * Check all the properties and set default protobuf values if necessary
   * @param _instance message instance
   */
  static refineValues(_instance: Event) {
    _instance.id = _instance.id || '0';
    _instance.customerId = _instance.customerId || '';
    _instance.meterId = _instance.meterId || '0';
    _instance.pbClass = _instance.pbClass || '';
    _instance.eventId = _instance.eventId || '';
    _instance.category = _instance.category || '';
    _instance.location = _instance.location || '';
    _instance.active = _instance.active || false;
    _instance.high = _instance.high || false;
    _instance.acknowledged = _instance.acknowledged || false;
    _instance.value = _instance.value || 0;
    _instance.count = _instance.count || 0;
    _instance.message = _instance.message || '';
    _instance.comment = _instance.comment || '';
    _instance.timestamp = _instance.timestamp || undefined;
    _instance.timestampActive = _instance.timestampActive || undefined;
    _instance.timestampAcknowledged =
      _instance.timestampAcknowledged || undefined;
    _instance.timestampCleared = _instance.timestampCleared || undefined;
    _instance.acknowledgedBy = _instance.acknowledgedBy || '';
    _instance.clearedBy = _instance.clearedBy || '';
    _instance.sent = _instance.sent || false;
    _instance.timestampSent = _instance.timestampSent || undefined;
    _instance.muted = _instance.muted || false;
    _instance.mutedUntil = _instance.mutedUntil || undefined;
    _instance.timestampMuted = _instance.timestampMuted || undefined;
    _instance.mutedBy = _instance.mutedBy || '';
    _instance.mutedByTypeId = _instance.mutedByTypeId || '';
    _instance.acknowledgedByTypeId = _instance.acknowledgedByTypeId || '';
    _instance.clearedByTypeId = _instance.clearedByTypeId || '';
  }

  /**
   * Deserializes / reads binary message into message instance using provided binary reader
   * @param _instance message instance
   * @param _reader binary reader instance
   */
  static deserializeBinaryFromReader(_instance: Event, _reader: BinaryReader) {
    while (_reader.nextField()) {
      if (_reader.isEndGroup()) break;

      switch (_reader.getFieldNumber()) {
        case 1:
          _instance.id = _reader.readInt64String();
          break;
        case 2:
          _instance.customerId = _reader.readString();
          break;
        case 3:
          _instance.meterId = _reader.readInt64String();
          break;
        case 4:
          _instance.pbClass = _reader.readString();
          break;
        case 5:
          _instance.eventId = _reader.readString();
          break;
        case 6:
          _instance.category = _reader.readString();
          break;
        case 7:
          _instance.location = _reader.readString();
          break;
        case 8:
          _instance.active = _reader.readBool();
          break;
        case 9:
          _instance.high = _reader.readBool();
          break;
        case 10:
          _instance.acknowledged = _reader.readBool();
          break;
        case 11:
          _instance.value = _reader.readDouble();
          break;
        case 12:
          _instance.count = _reader.readInt32();
          break;
        case 13:
          _instance.message = _reader.readString();
          break;
        case 14:
          _instance.comment = _reader.readString();
          break;
        case 15:
          _instance.timestamp = new googleProtobuf000.Timestamp();
          _reader.readMessage(
            _instance.timestamp,
            googleProtobuf000.Timestamp.deserializeBinaryFromReader
          );
          break;
        case 16:
          _instance.timestampActive = new googleProtobuf000.Timestamp();
          _reader.readMessage(
            _instance.timestampActive,
            googleProtobuf000.Timestamp.deserializeBinaryFromReader
          );
          break;
        case 17:
          _instance.timestampAcknowledged = new googleProtobuf000.Timestamp();
          _reader.readMessage(
            _instance.timestampAcknowledged,
            googleProtobuf000.Timestamp.deserializeBinaryFromReader
          );
          break;
        case 18:
          _instance.timestampCleared = new googleProtobuf000.Timestamp();
          _reader.readMessage(
            _instance.timestampCleared,
            googleProtobuf000.Timestamp.deserializeBinaryFromReader
          );
          break;
        case 19:
          _instance.acknowledgedBy = _reader.readString();
          break;
        case 20:
          _instance.clearedBy = _reader.readString();
          break;
        case 21:
          _instance.sent = _reader.readBool();
          break;
        case 22:
          _instance.timestampSent = new googleProtobuf000.Timestamp();
          _reader.readMessage(
            _instance.timestampSent,
            googleProtobuf000.Timestamp.deserializeBinaryFromReader
          );
          break;
        case 23:
          _instance.muted = _reader.readBool();
          break;
        case 24:
          _instance.mutedUntil = new googleProtobuf000.Timestamp();
          _reader.readMessage(
            _instance.mutedUntil,
            googleProtobuf000.Timestamp.deserializeBinaryFromReader
          );
          break;
        case 25:
          _instance.timestampMuted = new googleProtobuf000.Timestamp();
          _reader.readMessage(
            _instance.timestampMuted,
            googleProtobuf000.Timestamp.deserializeBinaryFromReader
          );
          break;
        case 26:
          _instance.mutedBy = _reader.readString();
          break;
        case 27:
          _instance.mutedByTypeId = _reader.readString();
          break;
        case 28:
          _instance.acknowledgedByTypeId = _reader.readString();
          break;
        case 29:
          _instance.clearedByTypeId = _reader.readString();
          break;
        default:
          _reader.skipField();
      }
    }

    Event.refineValues(_instance);
  }

  /**
   * Serializes a message to binary format using provided binary reader
   * @param _instance message instance
   * @param _writer binary writer instance
   */
  static serializeBinaryToWriter(_instance: Event, _writer: BinaryWriter) {
    if (_instance.id) {
      _writer.writeInt64String(1, _instance.id);
    }
    if (_instance.customerId) {
      _writer.writeString(2, _instance.customerId);
    }
    if (_instance.meterId) {
      _writer.writeInt64String(3, _instance.meterId);
    }
    if (_instance.pbClass) {
      _writer.writeString(4, _instance.pbClass);
    }
    if (_instance.eventId) {
      _writer.writeString(5, _instance.eventId);
    }
    if (_instance.category) {
      _writer.writeString(6, _instance.category);
    }
    if (_instance.location) {
      _writer.writeString(7, _instance.location);
    }
    if (_instance.active) {
      _writer.writeBool(8, _instance.active);
    }
    if (_instance.high) {
      _writer.writeBool(9, _instance.high);
    }
    if (_instance.acknowledged) {
      _writer.writeBool(10, _instance.acknowledged);
    }
    if (_instance.value) {
      _writer.writeDouble(11, _instance.value);
    }
    if (_instance.count) {
      _writer.writeInt32(12, _instance.count);
    }
    if (_instance.message) {
      _writer.writeString(13, _instance.message);
    }
    if (_instance.comment) {
      _writer.writeString(14, _instance.comment);
    }
    if (_instance.timestamp) {
      _writer.writeMessage(
        15,
        _instance.timestamp as any,
        googleProtobuf000.Timestamp.serializeBinaryToWriter
      );
    }
    if (_instance.timestampActive) {
      _writer.writeMessage(
        16,
        _instance.timestampActive as any,
        googleProtobuf000.Timestamp.serializeBinaryToWriter
      );
    }
    if (_instance.timestampAcknowledged) {
      _writer.writeMessage(
        17,
        _instance.timestampAcknowledged as any,
        googleProtobuf000.Timestamp.serializeBinaryToWriter
      );
    }
    if (_instance.timestampCleared) {
      _writer.writeMessage(
        18,
        _instance.timestampCleared as any,
        googleProtobuf000.Timestamp.serializeBinaryToWriter
      );
    }
    if (_instance.acknowledgedBy) {
      _writer.writeString(19, _instance.acknowledgedBy);
    }
    if (_instance.clearedBy) {
      _writer.writeString(20, _instance.clearedBy);
    }
    if (_instance.sent) {
      _writer.writeBool(21, _instance.sent);
    }
    if (_instance.timestampSent) {
      _writer.writeMessage(
        22,
        _instance.timestampSent as any,
        googleProtobuf000.Timestamp.serializeBinaryToWriter
      );
    }
    if (_instance.muted) {
      _writer.writeBool(23, _instance.muted);
    }
    if (_instance.mutedUntil) {
      _writer.writeMessage(
        24,
        _instance.mutedUntil as any,
        googleProtobuf000.Timestamp.serializeBinaryToWriter
      );
    }
    if (_instance.timestampMuted) {
      _writer.writeMessage(
        25,
        _instance.timestampMuted as any,
        googleProtobuf000.Timestamp.serializeBinaryToWriter
      );
    }
    if (_instance.mutedBy) {
      _writer.writeString(26, _instance.mutedBy);
    }
    if (_instance.mutedByTypeId) {
      _writer.writeString(27, _instance.mutedByTypeId);
    }
    if (_instance.acknowledgedByTypeId) {
      _writer.writeString(28, _instance.acknowledgedByTypeId);
    }
    if (_instance.clearedByTypeId) {
      _writer.writeString(29, _instance.clearedByTypeId);
    }
  }

  private _id?: string;
  private _customerId?: string;
  private _meterId?: string;
  private _pbClass?: string;
  private _eventId?: string;
  private _category?: string;
  private _location?: string;
  private _active?: boolean;
  private _high?: boolean;
  private _acknowledged?: boolean;
  private _value?: number;
  private _count?: number;
  private _message?: string;
  private _comment?: string;
  private _timestamp?: googleProtobuf000.Timestamp;
  private _timestampActive?: googleProtobuf000.Timestamp;
  private _timestampAcknowledged?: googleProtobuf000.Timestamp;
  private _timestampCleared?: googleProtobuf000.Timestamp;
  private _acknowledgedBy?: string;
  private _clearedBy?: string;
  private _sent?: boolean;
  private _timestampSent?: googleProtobuf000.Timestamp;
  private _muted?: boolean;
  private _mutedUntil?: googleProtobuf000.Timestamp;
  private _timestampMuted?: googleProtobuf000.Timestamp;
  private _mutedBy?: string;
  private _mutedByTypeId?: string;
  private _acknowledgedByTypeId?: string;
  private _clearedByTypeId?: string;

  /**
   * Message constructor. Initializes the properties and applies default Protobuf values if necessary
   * @param _value initial values object or instance of Event to deeply clone from
   */
  constructor(_value?: RecursivePartial<Event.AsObject>) {
    _value = _value || {};
    this.id = _value.id;
    this.customerId = _value.customerId;
    this.meterId = _value.meterId;
    this.pbClass = _value.pbClass;
    this.eventId = _value.eventId;
    this.category = _value.category;
    this.location = _value.location;
    this.active = _value.active;
    this.high = _value.high;
    this.acknowledged = _value.acknowledged;
    this.value = _value.value;
    this.count = _value.count;
    this.message = _value.message;
    this.comment = _value.comment;
    this.timestamp = _value.timestamp
      ? new googleProtobuf000.Timestamp(_value.timestamp)
      : undefined;
    this.timestampActive = _value.timestampActive
      ? new googleProtobuf000.Timestamp(_value.timestampActive)
      : undefined;
    this.timestampAcknowledged = _value.timestampAcknowledged
      ? new googleProtobuf000.Timestamp(_value.timestampAcknowledged)
      : undefined;
    this.timestampCleared = _value.timestampCleared
      ? new googleProtobuf000.Timestamp(_value.timestampCleared)
      : undefined;
    this.acknowledgedBy = _value.acknowledgedBy;
    this.clearedBy = _value.clearedBy;
    this.sent = _value.sent;
    this.timestampSent = _value.timestampSent
      ? new googleProtobuf000.Timestamp(_value.timestampSent)
      : undefined;
    this.muted = _value.muted;
    this.mutedUntil = _value.mutedUntil
      ? new googleProtobuf000.Timestamp(_value.mutedUntil)
      : undefined;
    this.timestampMuted = _value.timestampMuted
      ? new googleProtobuf000.Timestamp(_value.timestampMuted)
      : undefined;
    this.mutedBy = _value.mutedBy;
    this.mutedByTypeId = _value.mutedByTypeId;
    this.acknowledgedByTypeId = _value.acknowledgedByTypeId;
    this.clearedByTypeId = _value.clearedByTypeId;
    Event.refineValues(this);
  }
  get id(): string | undefined {
    return this._id;
  }
  set id(value: string | undefined) {
    this._id = value;
  }
  get customerId(): string | undefined {
    return this._customerId;
  }
  set customerId(value: string | undefined) {
    this._customerId = value;
  }
  get meterId(): string | undefined {
    return this._meterId;
  }
  set meterId(value: string | undefined) {
    this._meterId = value;
  }
  get pbClass(): string | undefined {
    return this._pbClass;
  }
  set pbClass(value: string | undefined) {
    this._pbClass = value;
  }
  get eventId(): string | undefined {
    return this._eventId;
  }
  set eventId(value: string | undefined) {
    this._eventId = value;
  }
  get category(): string | undefined {
    return this._category;
  }
  set category(value: string | undefined) {
    this._category = value;
  }
  get location(): string | undefined {
    return this._location;
  }
  set location(value: string | undefined) {
    this._location = value;
  }
  get active(): boolean | undefined {
    return this._active;
  }
  set active(value: boolean | undefined) {
    this._active = value;
  }
  get high(): boolean | undefined {
    return this._high;
  }
  set high(value: boolean | undefined) {
    this._high = value;
  }
  get acknowledged(): boolean | undefined {
    return this._acknowledged;
  }
  set acknowledged(value: boolean | undefined) {
    this._acknowledged = value;
  }
  get value(): number | undefined {
    return this._value;
  }
  set value(value: number | undefined) {
    this._value = value;
  }
  get count(): number | undefined {
    return this._count;
  }
  set count(value: number | undefined) {
    this._count = value;
  }
  get message(): string | undefined {
    return this._message;
  }
  set message(value: string | undefined) {
    this._message = value;
  }
  get comment(): string | undefined {
    return this._comment;
  }
  set comment(value: string | undefined) {
    this._comment = value;
  }
  get timestamp(): googleProtobuf000.Timestamp | undefined {
    return this._timestamp;
  }
  set timestamp(value: googleProtobuf000.Timestamp | undefined) {
    this._timestamp = value;
  }
  get timestampActive(): googleProtobuf000.Timestamp | undefined {
    return this._timestampActive;
  }
  set timestampActive(value: googleProtobuf000.Timestamp | undefined) {
    this._timestampActive = value;
  }
  get timestampAcknowledged(): googleProtobuf000.Timestamp | undefined {
    return this._timestampAcknowledged;
  }
  set timestampAcknowledged(value: googleProtobuf000.Timestamp | undefined) {
    this._timestampAcknowledged = value;
  }
  get timestampCleared(): googleProtobuf000.Timestamp | undefined {
    return this._timestampCleared;
  }
  set timestampCleared(value: googleProtobuf000.Timestamp | undefined) {
    this._timestampCleared = value;
  }
  get acknowledgedBy(): string | undefined {
    return this._acknowledgedBy;
  }
  set acknowledgedBy(value: string | undefined) {
    this._acknowledgedBy = value;
  }
  get clearedBy(): string | undefined {
    return this._clearedBy;
  }
  set clearedBy(value: string | undefined) {
    this._clearedBy = value;
  }
  get sent(): boolean | undefined {
    return this._sent;
  }
  set sent(value: boolean | undefined) {
    this._sent = value;
  }
  get timestampSent(): googleProtobuf000.Timestamp | undefined {
    return this._timestampSent;
  }
  set timestampSent(value: googleProtobuf000.Timestamp | undefined) {
    this._timestampSent = value;
  }
  get muted(): boolean | undefined {
    return this._muted;
  }
  set muted(value: boolean | undefined) {
    this._muted = value;
  }
  get mutedUntil(): googleProtobuf000.Timestamp | undefined {
    return this._mutedUntil;
  }
  set mutedUntil(value: googleProtobuf000.Timestamp | undefined) {
    this._mutedUntil = value;
  }
  get timestampMuted(): googleProtobuf000.Timestamp | undefined {
    return this._timestampMuted;
  }
  set timestampMuted(value: googleProtobuf000.Timestamp | undefined) {
    this._timestampMuted = value;
  }
  get mutedBy(): string | undefined {
    return this._mutedBy;
  }
  set mutedBy(value: string | undefined) {
    this._mutedBy = value;
  }
  get mutedByTypeId(): string | undefined {
    return this._mutedByTypeId;
  }
  set mutedByTypeId(value: string | undefined) {
    this._mutedByTypeId = value;
  }
  get acknowledgedByTypeId(): string | undefined {
    return this._acknowledgedByTypeId;
  }
  set acknowledgedByTypeId(value: string | undefined) {
    this._acknowledgedByTypeId = value;
  }
  get clearedByTypeId(): string | undefined {
    return this._clearedByTypeId;
  }
  set clearedByTypeId(value: string | undefined) {
    this._clearedByTypeId = value;
  }

  /**
   * Serialize message to binary data
   * @param instance message instance
   */
  serializeBinary() {
    const writer = new BinaryWriter();
    Event.serializeBinaryToWriter(this, writer);
    return writer.getResultBuffer();
  }

  /**
   * Cast message to standard JavaScript object (all non-primitive values are deeply cloned)
   */
  toObject(): Event.AsObject {
    return {
      id: this.id,
      customerId: this.customerId,
      meterId: this.meterId,
      pbClass: this.pbClass,
      eventId: this.eventId,
      category: this.category,
      location: this.location,
      active: this.active,
      high: this.high,
      acknowledged: this.acknowledged,
      value: this.value,
      count: this.count,
      message: this.message,
      comment: this.comment,
      timestamp: this.timestamp ? this.timestamp.toObject() : undefined,
      timestampActive: this.timestampActive
        ? this.timestampActive.toObject()
        : undefined,
      timestampAcknowledged: this.timestampAcknowledged
        ? this.timestampAcknowledged.toObject()
        : undefined,
      timestampCleared: this.timestampCleared
        ? this.timestampCleared.toObject()
        : undefined,
      acknowledgedBy: this.acknowledgedBy,
      clearedBy: this.clearedBy,
      sent: this.sent,
      timestampSent: this.timestampSent
        ? this.timestampSent.toObject()
        : undefined,
      muted: this.muted,
      mutedUntil: this.mutedUntil ? this.mutedUntil.toObject() : undefined,
      timestampMuted: this.timestampMuted
        ? this.timestampMuted.toObject()
        : undefined,
      mutedBy: this.mutedBy,
      mutedByTypeId: this.mutedByTypeId,
      acknowledgedByTypeId: this.acknowledgedByTypeId,
      clearedByTypeId: this.clearedByTypeId
    };
  }

  /**
   * Convenience method to support JSON.stringify(message), replicates the structure of toObject()
   */
  toJSON() {
    return this.toObject();
  }

  /**
   * Cast message to JSON using protobuf JSON notation: https://developers.google.com/protocol-buffers/docs/proto3#json
   * Attention: output differs from toObject() e.g. enums are represented as names and not as numbers, Timestamp is an ISO Date string format etc.
   * If the message itself or some of descendant messages is google.protobuf.Any, you MUST provide a message pool as options. If not, the messagePool is not required
   */
  toProtobufJSON(
    // @ts-ignore
    options?: ToProtobufJSONOptions
  ): Event.AsProtobufJSON {
    return {
      id: this.id,
      customerId: this.customerId,
      meterId: this.meterId,
      pbClass: this.pbClass,
      eventId: this.eventId,
      category: this.category,
      location: this.location,
      active: this.active,
      high: this.high,
      acknowledged: this.acknowledged,
      value: this.value,
      count: this.count,
      message: this.message,
      comment: this.comment,
      timestamp: this.timestamp ? this.timestamp.toProtobufJSON(options) : null,
      timestampActive: this.timestampActive
        ? this.timestampActive.toProtobufJSON(options)
        : null,
      timestampAcknowledged: this.timestampAcknowledged
        ? this.timestampAcknowledged.toProtobufJSON(options)
        : null,
      timestampCleared: this.timestampCleared
        ? this.timestampCleared.toProtobufJSON(options)
        : null,
      acknowledgedBy: this.acknowledgedBy,
      clearedBy: this.clearedBy,
      sent: this.sent,
      timestampSent: this.timestampSent
        ? this.timestampSent.toProtobufJSON(options)
        : null,
      muted: this.muted,
      mutedUntil: this.mutedUntil
        ? this.mutedUntil.toProtobufJSON(options)
        : null,
      timestampMuted: this.timestampMuted
        ? this.timestampMuted.toProtobufJSON(options)
        : null,
      mutedBy: this.mutedBy,
      mutedByTypeId: this.mutedByTypeId,
      acknowledgedByTypeId: this.acknowledgedByTypeId,
      clearedByTypeId: this.clearedByTypeId
    };
  }
}
export module Event {
  /**
   * Standard JavaScript object representation for Event
   */
  export interface AsObject {
    id?: string;
    customerId?: string;
    meterId?: string;
    pbClass?: string;
    eventId?: string;
    category?: string;
    location?: string;
    active?: boolean;
    high?: boolean;
    acknowledged?: boolean;
    value?: number;
    count?: number;
    message?: string;
    comment?: string;
    timestamp?: googleProtobuf000.Timestamp.AsObject;
    timestampActive?: googleProtobuf000.Timestamp.AsObject;
    timestampAcknowledged?: googleProtobuf000.Timestamp.AsObject;
    timestampCleared?: googleProtobuf000.Timestamp.AsObject;
    acknowledgedBy?: string;
    clearedBy?: string;
    sent?: boolean;
    timestampSent?: googleProtobuf000.Timestamp.AsObject;
    muted?: boolean;
    mutedUntil?: googleProtobuf000.Timestamp.AsObject;
    timestampMuted?: googleProtobuf000.Timestamp.AsObject;
    mutedBy?: string;
    mutedByTypeId?: string;
    acknowledgedByTypeId?: string;
    clearedByTypeId?: string;
  }

  /**
   * Protobuf JSON representation for Event
   */
  export interface AsProtobufJSON {
    id?: string;
    customerId?: string;
    meterId?: string;
    pbClass?: string;
    eventId?: string;
    category?: string;
    location?: string;
    active?: boolean;
    high?: boolean;
    acknowledged?: boolean;
    value?: number;
    count?: number;
    message?: string;
    comment?: string;
    timestamp?: googleProtobuf000.Timestamp.AsProtobufJSON | null;
    timestampActive?: googleProtobuf000.Timestamp.AsProtobufJSON | null;
    timestampAcknowledged?: googleProtobuf000.Timestamp.AsProtobufJSON | null;
    timestampCleared?: googleProtobuf000.Timestamp.AsProtobufJSON | null;
    acknowledgedBy?: string;
    clearedBy?: string;
    sent?: boolean;
    timestampSent?: googleProtobuf000.Timestamp.AsProtobufJSON | null;
    muted?: boolean;
    mutedUntil?: googleProtobuf000.Timestamp.AsProtobufJSON | null;
    timestampMuted?: googleProtobuf000.Timestamp.AsProtobufJSON | null;
    mutedBy?: string;
    mutedByTypeId?: string;
    acknowledgedByTypeId?: string;
    clearedByTypeId?: string;
  }
}

/**
 * Message implementation for modulebms.events.EventHistory
 */
export class EventHistory implements GrpcMessage {
  static id = 'modulebms.events.EventHistory';

  /**
   * Deserialize binary data to message
   * @param instance message instance
   */
  static deserializeBinary(bytes: ByteSource) {
    const instance = new EventHistory();
    EventHistory.deserializeBinaryFromReader(instance, new BinaryReader(bytes));
    return instance;
  }

  /**
   * Check all the properties and set default protobuf values if necessary
   * @param _instance message instance
   */
  static refineValues(_instance: EventHistory) {
    _instance.id = _instance.id || '0';
    _instance.customerId = _instance.customerId || '';
    _instance.meterId = _instance.meterId || '0';
    _instance.pbClass = _instance.pbClass || '';
    _instance.eventId = _instance.eventId || '';
    _instance.category = _instance.category || '';
    _instance.location = _instance.location || '';
    _instance.active = _instance.active || false;
    _instance.high = _instance.high || false;
    _instance.acknowledged = _instance.acknowledged || false;
    _instance.value = _instance.value || 0;
    _instance.count = _instance.count || 0;
    _instance.message = _instance.message || '';
    _instance.comment = _instance.comment || '';
    _instance.timestamp = _instance.timestamp || undefined;
    _instance.timestampActive = _instance.timestampActive || undefined;
    _instance.timestampAcknowledged =
      _instance.timestampAcknowledged || undefined;
    _instance.timestampCleared = _instance.timestampCleared || undefined;
    _instance.acknowledgedBy = _instance.acknowledgedBy || '';
    _instance.clearedBy = _instance.clearedBy || '';
    _instance.sent = _instance.sent || false;
    _instance.timestampSent = _instance.timestampSent || undefined;
    _instance.muted = _instance.muted || false;
    _instance.mutedUntil = _instance.mutedUntil || undefined;
    _instance.timestampMuted = _instance.timestampMuted || undefined;
    _instance.mutedBy = _instance.mutedBy || '';
    _instance.mutedByTypeId = _instance.mutedByTypeId || '';
    _instance.acknowledgedByTypeId = _instance.acknowledgedByTypeId || '';
    _instance.clearedByTypeId = _instance.clearedByTypeId || '';
    _instance.eventObjectId = _instance.eventObjectId || '0';
  }

  /**
   * Deserializes / reads binary message into message instance using provided binary reader
   * @param _instance message instance
   * @param _reader binary reader instance
   */
  static deserializeBinaryFromReader(
    _instance: EventHistory,
    _reader: BinaryReader
  ) {
    while (_reader.nextField()) {
      if (_reader.isEndGroup()) break;

      switch (_reader.getFieldNumber()) {
        case 1:
          _instance.id = _reader.readInt64String();
          break;
        case 2:
          _instance.customerId = _reader.readString();
          break;
        case 3:
          _instance.meterId = _reader.readInt64String();
          break;
        case 4:
          _instance.pbClass = _reader.readString();
          break;
        case 5:
          _instance.eventId = _reader.readString();
          break;
        case 6:
          _instance.category = _reader.readString();
          break;
        case 7:
          _instance.location = _reader.readString();
          break;
        case 8:
          _instance.active = _reader.readBool();
          break;
        case 9:
          _instance.high = _reader.readBool();
          break;
        case 10:
          _instance.acknowledged = _reader.readBool();
          break;
        case 11:
          _instance.value = _reader.readDouble();
          break;
        case 12:
          _instance.count = _reader.readInt32();
          break;
        case 13:
          _instance.message = _reader.readString();
          break;
        case 14:
          _instance.comment = _reader.readString();
          break;
        case 15:
          _instance.timestamp = new googleProtobuf000.Timestamp();
          _reader.readMessage(
            _instance.timestamp,
            googleProtobuf000.Timestamp.deserializeBinaryFromReader
          );
          break;
        case 16:
          _instance.timestampActive = new googleProtobuf000.Timestamp();
          _reader.readMessage(
            _instance.timestampActive,
            googleProtobuf000.Timestamp.deserializeBinaryFromReader
          );
          break;
        case 17:
          _instance.timestampAcknowledged = new googleProtobuf000.Timestamp();
          _reader.readMessage(
            _instance.timestampAcknowledged,
            googleProtobuf000.Timestamp.deserializeBinaryFromReader
          );
          break;
        case 18:
          _instance.timestampCleared = new googleProtobuf000.Timestamp();
          _reader.readMessage(
            _instance.timestampCleared,
            googleProtobuf000.Timestamp.deserializeBinaryFromReader
          );
          break;
        case 19:
          _instance.acknowledgedBy = _reader.readString();
          break;
        case 20:
          _instance.clearedBy = _reader.readString();
          break;
        case 21:
          _instance.sent = _reader.readBool();
          break;
        case 22:
          _instance.timestampSent = new googleProtobuf000.Timestamp();
          _reader.readMessage(
            _instance.timestampSent,
            googleProtobuf000.Timestamp.deserializeBinaryFromReader
          );
          break;
        case 23:
          _instance.muted = _reader.readBool();
          break;
        case 24:
          _instance.mutedUntil = new googleProtobuf000.Timestamp();
          _reader.readMessage(
            _instance.mutedUntil,
            googleProtobuf000.Timestamp.deserializeBinaryFromReader
          );
          break;
        case 25:
          _instance.timestampMuted = new googleProtobuf000.Timestamp();
          _reader.readMessage(
            _instance.timestampMuted,
            googleProtobuf000.Timestamp.deserializeBinaryFromReader
          );
          break;
        case 26:
          _instance.mutedBy = _reader.readString();
          break;
        case 27:
          _instance.mutedByTypeId = _reader.readString();
          break;
        case 28:
          _instance.acknowledgedByTypeId = _reader.readString();
          break;
        case 29:
          _instance.clearedByTypeId = _reader.readString();
          break;
        case 30:
          _instance.eventObjectId = _reader.readInt64String();
          break;
        default:
          _reader.skipField();
      }
    }

    EventHistory.refineValues(_instance);
  }

  /**
   * Serializes a message to binary format using provided binary reader
   * @param _instance message instance
   * @param _writer binary writer instance
   */
  static serializeBinaryToWriter(
    _instance: EventHistory,
    _writer: BinaryWriter
  ) {
    if (_instance.id) {
      _writer.writeInt64String(1, _instance.id);
    }
    if (_instance.customerId) {
      _writer.writeString(2, _instance.customerId);
    }
    if (_instance.meterId) {
      _writer.writeInt64String(3, _instance.meterId);
    }
    if (_instance.pbClass) {
      _writer.writeString(4, _instance.pbClass);
    }
    if (_instance.eventId) {
      _writer.writeString(5, _instance.eventId);
    }
    if (_instance.category) {
      _writer.writeString(6, _instance.category);
    }
    if (_instance.location) {
      _writer.writeString(7, _instance.location);
    }
    if (_instance.active) {
      _writer.writeBool(8, _instance.active);
    }
    if (_instance.high) {
      _writer.writeBool(9, _instance.high);
    }
    if (_instance.acknowledged) {
      _writer.writeBool(10, _instance.acknowledged);
    }
    if (_instance.value) {
      _writer.writeDouble(11, _instance.value);
    }
    if (_instance.count) {
      _writer.writeInt32(12, _instance.count);
    }
    if (_instance.message) {
      _writer.writeString(13, _instance.message);
    }
    if (_instance.comment) {
      _writer.writeString(14, _instance.comment);
    }
    if (_instance.timestamp) {
      _writer.writeMessage(
        15,
        _instance.timestamp as any,
        googleProtobuf000.Timestamp.serializeBinaryToWriter
      );
    }
    if (_instance.timestampActive) {
      _writer.writeMessage(
        16,
        _instance.timestampActive as any,
        googleProtobuf000.Timestamp.serializeBinaryToWriter
      );
    }
    if (_instance.timestampAcknowledged) {
      _writer.writeMessage(
        17,
        _instance.timestampAcknowledged as any,
        googleProtobuf000.Timestamp.serializeBinaryToWriter
      );
    }
    if (_instance.timestampCleared) {
      _writer.writeMessage(
        18,
        _instance.timestampCleared as any,
        googleProtobuf000.Timestamp.serializeBinaryToWriter
      );
    }
    if (_instance.acknowledgedBy) {
      _writer.writeString(19, _instance.acknowledgedBy);
    }
    if (_instance.clearedBy) {
      _writer.writeString(20, _instance.clearedBy);
    }
    if (_instance.sent) {
      _writer.writeBool(21, _instance.sent);
    }
    if (_instance.timestampSent) {
      _writer.writeMessage(
        22,
        _instance.timestampSent as any,
        googleProtobuf000.Timestamp.serializeBinaryToWriter
      );
    }
    if (_instance.muted) {
      _writer.writeBool(23, _instance.muted);
    }
    if (_instance.mutedUntil) {
      _writer.writeMessage(
        24,
        _instance.mutedUntil as any,
        googleProtobuf000.Timestamp.serializeBinaryToWriter
      );
    }
    if (_instance.timestampMuted) {
      _writer.writeMessage(
        25,
        _instance.timestampMuted as any,
        googleProtobuf000.Timestamp.serializeBinaryToWriter
      );
    }
    if (_instance.mutedBy) {
      _writer.writeString(26, _instance.mutedBy);
    }
    if (_instance.mutedByTypeId) {
      _writer.writeString(27, _instance.mutedByTypeId);
    }
    if (_instance.acknowledgedByTypeId) {
      _writer.writeString(28, _instance.acknowledgedByTypeId);
    }
    if (_instance.clearedByTypeId) {
      _writer.writeString(29, _instance.clearedByTypeId);
    }
    if (_instance.eventObjectId) {
      _writer.writeInt64String(30, _instance.eventObjectId);
    }
  }

  private _id?: string;
  private _customerId?: string;
  private _meterId?: string;
  private _pbClass?: string;
  private _eventId?: string;
  private _category?: string;
  private _location?: string;
  private _active?: boolean;
  private _high?: boolean;
  private _acknowledged?: boolean;
  private _value?: number;
  private _count?: number;
  private _message?: string;
  private _comment?: string;
  private _timestamp?: googleProtobuf000.Timestamp;
  private _timestampActive?: googleProtobuf000.Timestamp;
  private _timestampAcknowledged?: googleProtobuf000.Timestamp;
  private _timestampCleared?: googleProtobuf000.Timestamp;
  private _acknowledgedBy?: string;
  private _clearedBy?: string;
  private _sent?: boolean;
  private _timestampSent?: googleProtobuf000.Timestamp;
  private _muted?: boolean;
  private _mutedUntil?: googleProtobuf000.Timestamp;
  private _timestampMuted?: googleProtobuf000.Timestamp;
  private _mutedBy?: string;
  private _mutedByTypeId?: string;
  private _acknowledgedByTypeId?: string;
  private _clearedByTypeId?: string;
  private _eventObjectId?: string;

  /**
   * Message constructor. Initializes the properties and applies default Protobuf values if necessary
   * @param _value initial values object or instance of EventHistory to deeply clone from
   */
  constructor(_value?: RecursivePartial<EventHistory.AsObject>) {
    _value = _value || {};
    this.id = _value.id;
    this.customerId = _value.customerId;
    this.meterId = _value.meterId;
    this.pbClass = _value.pbClass;
    this.eventId = _value.eventId;
    this.category = _value.category;
    this.location = _value.location;
    this.active = _value.active;
    this.high = _value.high;
    this.acknowledged = _value.acknowledged;
    this.value = _value.value;
    this.count = _value.count;
    this.message = _value.message;
    this.comment = _value.comment;
    this.timestamp = _value.timestamp
      ? new googleProtobuf000.Timestamp(_value.timestamp)
      : undefined;
    this.timestampActive = _value.timestampActive
      ? new googleProtobuf000.Timestamp(_value.timestampActive)
      : undefined;
    this.timestampAcknowledged = _value.timestampAcknowledged
      ? new googleProtobuf000.Timestamp(_value.timestampAcknowledged)
      : undefined;
    this.timestampCleared = _value.timestampCleared
      ? new googleProtobuf000.Timestamp(_value.timestampCleared)
      : undefined;
    this.acknowledgedBy = _value.acknowledgedBy;
    this.clearedBy = _value.clearedBy;
    this.sent = _value.sent;
    this.timestampSent = _value.timestampSent
      ? new googleProtobuf000.Timestamp(_value.timestampSent)
      : undefined;
    this.muted = _value.muted;
    this.mutedUntil = _value.mutedUntil
      ? new googleProtobuf000.Timestamp(_value.mutedUntil)
      : undefined;
    this.timestampMuted = _value.timestampMuted
      ? new googleProtobuf000.Timestamp(_value.timestampMuted)
      : undefined;
    this.mutedBy = _value.mutedBy;
    this.mutedByTypeId = _value.mutedByTypeId;
    this.acknowledgedByTypeId = _value.acknowledgedByTypeId;
    this.clearedByTypeId = _value.clearedByTypeId;
    this.eventObjectId = _value.eventObjectId;
    EventHistory.refineValues(this);
  }
  get id(): string | undefined {
    return this._id;
  }
  set id(value: string | undefined) {
    this._id = value;
  }
  get customerId(): string | undefined {
    return this._customerId;
  }
  set customerId(value: string | undefined) {
    this._customerId = value;
  }
  get meterId(): string | undefined {
    return this._meterId;
  }
  set meterId(value: string | undefined) {
    this._meterId = value;
  }
  get pbClass(): string | undefined {
    return this._pbClass;
  }
  set pbClass(value: string | undefined) {
    this._pbClass = value;
  }
  get eventId(): string | undefined {
    return this._eventId;
  }
  set eventId(value: string | undefined) {
    this._eventId = value;
  }
  get category(): string | undefined {
    return this._category;
  }
  set category(value: string | undefined) {
    this._category = value;
  }
  get location(): string | undefined {
    return this._location;
  }
  set location(value: string | undefined) {
    this._location = value;
  }
  get active(): boolean | undefined {
    return this._active;
  }
  set active(value: boolean | undefined) {
    this._active = value;
  }
  get high(): boolean | undefined {
    return this._high;
  }
  set high(value: boolean | undefined) {
    this._high = value;
  }
  get acknowledged(): boolean | undefined {
    return this._acknowledged;
  }
  set acknowledged(value: boolean | undefined) {
    this._acknowledged = value;
  }
  get value(): number | undefined {
    return this._value;
  }
  set value(value: number | undefined) {
    this._value = value;
  }
  get count(): number | undefined {
    return this._count;
  }
  set count(value: number | undefined) {
    this._count = value;
  }
  get message(): string | undefined {
    return this._message;
  }
  set message(value: string | undefined) {
    this._message = value;
  }
  get comment(): string | undefined {
    return this._comment;
  }
  set comment(value: string | undefined) {
    this._comment = value;
  }
  get timestamp(): googleProtobuf000.Timestamp | undefined {
    return this._timestamp;
  }
  set timestamp(value: googleProtobuf000.Timestamp | undefined) {
    this._timestamp = value;
  }
  get timestampActive(): googleProtobuf000.Timestamp | undefined {
    return this._timestampActive;
  }
  set timestampActive(value: googleProtobuf000.Timestamp | undefined) {
    this._timestampActive = value;
  }
  get timestampAcknowledged(): googleProtobuf000.Timestamp | undefined {
    return this._timestampAcknowledged;
  }
  set timestampAcknowledged(value: googleProtobuf000.Timestamp | undefined) {
    this._timestampAcknowledged = value;
  }
  get timestampCleared(): googleProtobuf000.Timestamp | undefined {
    return this._timestampCleared;
  }
  set timestampCleared(value: googleProtobuf000.Timestamp | undefined) {
    this._timestampCleared = value;
  }
  get acknowledgedBy(): string | undefined {
    return this._acknowledgedBy;
  }
  set acknowledgedBy(value: string | undefined) {
    this._acknowledgedBy = value;
  }
  get clearedBy(): string | undefined {
    return this._clearedBy;
  }
  set clearedBy(value: string | undefined) {
    this._clearedBy = value;
  }
  get sent(): boolean | undefined {
    return this._sent;
  }
  set sent(value: boolean | undefined) {
    this._sent = value;
  }
  get timestampSent(): googleProtobuf000.Timestamp | undefined {
    return this._timestampSent;
  }
  set timestampSent(value: googleProtobuf000.Timestamp | undefined) {
    this._timestampSent = value;
  }
  get muted(): boolean | undefined {
    return this._muted;
  }
  set muted(value: boolean | undefined) {
    this._muted = value;
  }
  get mutedUntil(): googleProtobuf000.Timestamp | undefined {
    return this._mutedUntil;
  }
  set mutedUntil(value: googleProtobuf000.Timestamp | undefined) {
    this._mutedUntil = value;
  }
  get timestampMuted(): googleProtobuf000.Timestamp | undefined {
    return this._timestampMuted;
  }
  set timestampMuted(value: googleProtobuf000.Timestamp | undefined) {
    this._timestampMuted = value;
  }
  get mutedBy(): string | undefined {
    return this._mutedBy;
  }
  set mutedBy(value: string | undefined) {
    this._mutedBy = value;
  }
  get mutedByTypeId(): string | undefined {
    return this._mutedByTypeId;
  }
  set mutedByTypeId(value: string | undefined) {
    this._mutedByTypeId = value;
  }
  get acknowledgedByTypeId(): string | undefined {
    return this._acknowledgedByTypeId;
  }
  set acknowledgedByTypeId(value: string | undefined) {
    this._acknowledgedByTypeId = value;
  }
  get clearedByTypeId(): string | undefined {
    return this._clearedByTypeId;
  }
  set clearedByTypeId(value: string | undefined) {
    this._clearedByTypeId = value;
  }
  get eventObjectId(): string | undefined {
    return this._eventObjectId;
  }
  set eventObjectId(value: string | undefined) {
    this._eventObjectId = value;
  }

  /**
   * Serialize message to binary data
   * @param instance message instance
   */
  serializeBinary() {
    const writer = new BinaryWriter();
    EventHistory.serializeBinaryToWriter(this, writer);
    return writer.getResultBuffer();
  }

  /**
   * Cast message to standard JavaScript object (all non-primitive values are deeply cloned)
   */
  toObject(): EventHistory.AsObject {
    return {
      id: this.id,
      customerId: this.customerId,
      meterId: this.meterId,
      pbClass: this.pbClass,
      eventId: this.eventId,
      category: this.category,
      location: this.location,
      active: this.active,
      high: this.high,
      acknowledged: this.acknowledged,
      value: this.value,
      count: this.count,
      message: this.message,
      comment: this.comment,
      timestamp: this.timestamp ? this.timestamp.toObject() : undefined,
      timestampActive: this.timestampActive
        ? this.timestampActive.toObject()
        : undefined,
      timestampAcknowledged: this.timestampAcknowledged
        ? this.timestampAcknowledged.toObject()
        : undefined,
      timestampCleared: this.timestampCleared
        ? this.timestampCleared.toObject()
        : undefined,
      acknowledgedBy: this.acknowledgedBy,
      clearedBy: this.clearedBy,
      sent: this.sent,
      timestampSent: this.timestampSent
        ? this.timestampSent.toObject()
        : undefined,
      muted: this.muted,
      mutedUntil: this.mutedUntil ? this.mutedUntil.toObject() : undefined,
      timestampMuted: this.timestampMuted
        ? this.timestampMuted.toObject()
        : undefined,
      mutedBy: this.mutedBy,
      mutedByTypeId: this.mutedByTypeId,
      acknowledgedByTypeId: this.acknowledgedByTypeId,
      clearedByTypeId: this.clearedByTypeId,
      eventObjectId: this.eventObjectId
    };
  }

  /**
   * Convenience method to support JSON.stringify(message), replicates the structure of toObject()
   */
  toJSON() {
    return this.toObject();
  }

  /**
   * Cast message to JSON using protobuf JSON notation: https://developers.google.com/protocol-buffers/docs/proto3#json
   * Attention: output differs from toObject() e.g. enums are represented as names and not as numbers, Timestamp is an ISO Date string format etc.
   * If the message itself or some of descendant messages is google.protobuf.Any, you MUST provide a message pool as options. If not, the messagePool is not required
   */
  toProtobufJSON(
    // @ts-ignore
    options?: ToProtobufJSONOptions
  ): EventHistory.AsProtobufJSON {
    return {
      id: this.id,
      customerId: this.customerId,
      meterId: this.meterId,
      pbClass: this.pbClass,
      eventId: this.eventId,
      category: this.category,
      location: this.location,
      active: this.active,
      high: this.high,
      acknowledged: this.acknowledged,
      value: this.value,
      count: this.count,
      message: this.message,
      comment: this.comment,
      timestamp: this.timestamp ? this.timestamp.toProtobufJSON(options) : null,
      timestampActive: this.timestampActive
        ? this.timestampActive.toProtobufJSON(options)
        : null,
      timestampAcknowledged: this.timestampAcknowledged
        ? this.timestampAcknowledged.toProtobufJSON(options)
        : null,
      timestampCleared: this.timestampCleared
        ? this.timestampCleared.toProtobufJSON(options)
        : null,
      acknowledgedBy: this.acknowledgedBy,
      clearedBy: this.clearedBy,
      sent: this.sent,
      timestampSent: this.timestampSent
        ? this.timestampSent.toProtobufJSON(options)
        : null,
      muted: this.muted,
      mutedUntil: this.mutedUntil
        ? this.mutedUntil.toProtobufJSON(options)
        : null,
      timestampMuted: this.timestampMuted
        ? this.timestampMuted.toProtobufJSON(options)
        : null,
      mutedBy: this.mutedBy,
      mutedByTypeId: this.mutedByTypeId,
      acknowledgedByTypeId: this.acknowledgedByTypeId,
      clearedByTypeId: this.clearedByTypeId,
      eventObjectId: this.eventObjectId
    };
  }
}
export module EventHistory {
  /**
   * Standard JavaScript object representation for EventHistory
   */
  export interface AsObject {
    id?: string;
    customerId?: string;
    meterId?: string;
    pbClass?: string;
    eventId?: string;
    category?: string;
    location?: string;
    active?: boolean;
    high?: boolean;
    acknowledged?: boolean;
    value?: number;
    count?: number;
    message?: string;
    comment?: string;
    timestamp?: googleProtobuf000.Timestamp.AsObject;
    timestampActive?: googleProtobuf000.Timestamp.AsObject;
    timestampAcknowledged?: googleProtobuf000.Timestamp.AsObject;
    timestampCleared?: googleProtobuf000.Timestamp.AsObject;
    acknowledgedBy?: string;
    clearedBy?: string;
    sent?: boolean;
    timestampSent?: googleProtobuf000.Timestamp.AsObject;
    muted?: boolean;
    mutedUntil?: googleProtobuf000.Timestamp.AsObject;
    timestampMuted?: googleProtobuf000.Timestamp.AsObject;
    mutedBy?: string;
    mutedByTypeId?: string;
    acknowledgedByTypeId?: string;
    clearedByTypeId?: string;
    eventObjectId?: string;
  }

  /**
   * Protobuf JSON representation for EventHistory
   */
  export interface AsProtobufJSON {
    id?: string;
    customerId?: string;
    meterId?: string;
    pbClass?: string;
    eventId?: string;
    category?: string;
    location?: string;
    active?: boolean;
    high?: boolean;
    acknowledged?: boolean;
    value?: number;
    count?: number;
    message?: string;
    comment?: string;
    timestamp?: googleProtobuf000.Timestamp.AsProtobufJSON | null;
    timestampActive?: googleProtobuf000.Timestamp.AsProtobufJSON | null;
    timestampAcknowledged?: googleProtobuf000.Timestamp.AsProtobufJSON | null;
    timestampCleared?: googleProtobuf000.Timestamp.AsProtobufJSON | null;
    acknowledgedBy?: string;
    clearedBy?: string;
    sent?: boolean;
    timestampSent?: googleProtobuf000.Timestamp.AsProtobufJSON | null;
    muted?: boolean;
    mutedUntil?: googleProtobuf000.Timestamp.AsProtobufJSON | null;
    timestampMuted?: googleProtobuf000.Timestamp.AsProtobufJSON | null;
    mutedBy?: string;
    mutedByTypeId?: string;
    acknowledgedByTypeId?: string;
    clearedByTypeId?: string;
    eventObjectId?: string;
  }
}

/**
 * Message implementation for modulebms.events.SendSmsRequest
 */
export class SendSmsRequest implements GrpcMessage {
  static id = 'modulebms.events.SendSmsRequest';

  /**
   * Deserialize binary data to message
   * @param instance message instance
   */
  static deserializeBinary(bytes: ByteSource) {
    const instance = new SendSmsRequest();
    SendSmsRequest.deserializeBinaryFromReader(
      instance,
      new BinaryReader(bytes)
    );
    return instance;
  }

  /**
   * Check all the properties and set default protobuf values if necessary
   * @param _instance message instance
   */
  static refineValues(_instance: SendSmsRequest) {
    _instance.to = _instance.to || '';
    _instance.message = _instance.message || '';
    _instance.customerId = _instance.customerId || '';
    _instance.userId = _instance.userId || '';
    _instance.userTypeId = _instance.userTypeId || '';
  }

  /**
   * Deserializes / reads binary message into message instance using provided binary reader
   * @param _instance message instance
   * @param _reader binary reader instance
   */
  static deserializeBinaryFromReader(
    _instance: SendSmsRequest,
    _reader: BinaryReader
  ) {
    while (_reader.nextField()) {
      if (_reader.isEndGroup()) break;

      switch (_reader.getFieldNumber()) {
        case 1:
          _instance.to = _reader.readString();
          break;
        case 2:
          _instance.message = _reader.readString();
          break;
        case 3:
          _instance.customerId = _reader.readString();
          break;
        case 4:
          _instance.userId = _reader.readString();
          break;
        case 5:
          _instance.userTypeId = _reader.readString();
          break;
        default:
          _reader.skipField();
      }
    }

    SendSmsRequest.refineValues(_instance);
  }

  /**
   * Serializes a message to binary format using provided binary reader
   * @param _instance message instance
   * @param _writer binary writer instance
   */
  static serializeBinaryToWriter(
    _instance: SendSmsRequest,
    _writer: BinaryWriter
  ) {
    if (_instance.to) {
      _writer.writeString(1, _instance.to);
    }
    if (_instance.message) {
      _writer.writeString(2, _instance.message);
    }
    if (_instance.customerId) {
      _writer.writeString(3, _instance.customerId);
    }
    if (_instance.userId) {
      _writer.writeString(4, _instance.userId);
    }
    if (_instance.userTypeId) {
      _writer.writeString(5, _instance.userTypeId);
    }
  }

  private _to?: string;
  private _message?: string;
  private _customerId?: string;
  private _userId?: string;
  private _userTypeId?: string;

  /**
   * Message constructor. Initializes the properties and applies default Protobuf values if necessary
   * @param _value initial values object or instance of SendSmsRequest to deeply clone from
   */
  constructor(_value?: RecursivePartial<SendSmsRequest.AsObject>) {
    _value = _value || {};
    this.to = _value.to;
    this.message = _value.message;
    this.customerId = _value.customerId;
    this.userId = _value.userId;
    this.userTypeId = _value.userTypeId;
    SendSmsRequest.refineValues(this);
  }
  get to(): string | undefined {
    return this._to;
  }
  set to(value: string | undefined) {
    this._to = value;
  }
  get message(): string | undefined {
    return this._message;
  }
  set message(value: string | undefined) {
    this._message = value;
  }
  get customerId(): string | undefined {
    return this._customerId;
  }
  set customerId(value: string | undefined) {
    this._customerId = value;
  }
  get userId(): string | undefined {
    return this._userId;
  }
  set userId(value: string | undefined) {
    this._userId = value;
  }
  get userTypeId(): string | undefined {
    return this._userTypeId;
  }
  set userTypeId(value: string | undefined) {
    this._userTypeId = value;
  }

  /**
   * Serialize message to binary data
   * @param instance message instance
   */
  serializeBinary() {
    const writer = new BinaryWriter();
    SendSmsRequest.serializeBinaryToWriter(this, writer);
    return writer.getResultBuffer();
  }

  /**
   * Cast message to standard JavaScript object (all non-primitive values are deeply cloned)
   */
  toObject(): SendSmsRequest.AsObject {
    return {
      to: this.to,
      message: this.message,
      customerId: this.customerId,
      userId: this.userId,
      userTypeId: this.userTypeId
    };
  }

  /**
   * Convenience method to support JSON.stringify(message), replicates the structure of toObject()
   */
  toJSON() {
    return this.toObject();
  }

  /**
   * Cast message to JSON using protobuf JSON notation: https://developers.google.com/protocol-buffers/docs/proto3#json
   * Attention: output differs from toObject() e.g. enums are represented as names and not as numbers, Timestamp is an ISO Date string format etc.
   * If the message itself or some of descendant messages is google.protobuf.Any, you MUST provide a message pool as options. If not, the messagePool is not required
   */
  toProtobufJSON(
    // @ts-ignore
    options?: ToProtobufJSONOptions
  ): SendSmsRequest.AsProtobufJSON {
    return {
      to: this.to,
      message: this.message,
      customerId: this.customerId,
      userId: this.userId,
      userTypeId: this.userTypeId
    };
  }
}
export module SendSmsRequest {
  /**
   * Standard JavaScript object representation for SendSmsRequest
   */
  export interface AsObject {
    to?: string;
    message?: string;
    customerId?: string;
    userId?: string;
    userTypeId?: string;
  }

  /**
   * Protobuf JSON representation for SendSmsRequest
   */
  export interface AsProtobufJSON {
    to?: string;
    message?: string;
    customerId?: string;
    userId?: string;
    userTypeId?: string;
  }
}

/**
 * Message implementation for modulebms.events.SendSmsResponse
 */
export class SendSmsResponse implements GrpcMessage {
  static id = 'modulebms.events.SendSmsResponse';

  /**
   * Deserialize binary data to message
   * @param instance message instance
   */
  static deserializeBinary(bytes: ByteSource) {
    const instance = new SendSmsResponse();
    SendSmsResponse.deserializeBinaryFromReader(
      instance,
      new BinaryReader(bytes)
    );
    return instance;
  }

  /**
   * Check all the properties and set default protobuf values if necessary
   * @param _instance message instance
   */
  static refineValues(_instance: SendSmsResponse) {
    _instance.ok = _instance.ok || false;
    _instance.message = _instance.message || '';
  }

  /**
   * Deserializes / reads binary message into message instance using provided binary reader
   * @param _instance message instance
   * @param _reader binary reader instance
   */
  static deserializeBinaryFromReader(
    _instance: SendSmsResponse,
    _reader: BinaryReader
  ) {
    while (_reader.nextField()) {
      if (_reader.isEndGroup()) break;

      switch (_reader.getFieldNumber()) {
        case 1:
          _instance.ok = _reader.readBool();
          break;
        case 2:
          _instance.message = _reader.readString();
          break;
        default:
          _reader.skipField();
      }
    }

    SendSmsResponse.refineValues(_instance);
  }

  /**
   * Serializes a message to binary format using provided binary reader
   * @param _instance message instance
   * @param _writer binary writer instance
   */
  static serializeBinaryToWriter(
    _instance: SendSmsResponse,
    _writer: BinaryWriter
  ) {
    if (_instance.ok) {
      _writer.writeBool(1, _instance.ok);
    }
    if (_instance.message) {
      _writer.writeString(2, _instance.message);
    }
  }

  private _ok?: boolean;
  private _message?: string;

  /**
   * Message constructor. Initializes the properties and applies default Protobuf values if necessary
   * @param _value initial values object or instance of SendSmsResponse to deeply clone from
   */
  constructor(_value?: RecursivePartial<SendSmsResponse.AsObject>) {
    _value = _value || {};
    this.ok = _value.ok;
    this.message = _value.message;
    SendSmsResponse.refineValues(this);
  }
  get ok(): boolean | undefined {
    return this._ok;
  }
  set ok(value: boolean | undefined) {
    this._ok = value;
  }
  get message(): string | undefined {
    return this._message;
  }
  set message(value: string | undefined) {
    this._message = value;
  }

  /**
   * Serialize message to binary data
   * @param instance message instance
   */
  serializeBinary() {
    const writer = new BinaryWriter();
    SendSmsResponse.serializeBinaryToWriter(this, writer);
    return writer.getResultBuffer();
  }

  /**
   * Cast message to standard JavaScript object (all non-primitive values are deeply cloned)
   */
  toObject(): SendSmsResponse.AsObject {
    return {
      ok: this.ok,
      message: this.message
    };
  }

  /**
   * Convenience method to support JSON.stringify(message), replicates the structure of toObject()
   */
  toJSON() {
    return this.toObject();
  }

  /**
   * Cast message to JSON using protobuf JSON notation: https://developers.google.com/protocol-buffers/docs/proto3#json
   * Attention: output differs from toObject() e.g. enums are represented as names and not as numbers, Timestamp is an ISO Date string format etc.
   * If the message itself or some of descendant messages is google.protobuf.Any, you MUST provide a message pool as options. If not, the messagePool is not required
   */
  toProtobufJSON(
    // @ts-ignore
    options?: ToProtobufJSONOptions
  ): SendSmsResponse.AsProtobufJSON {
    return {
      ok: this.ok,
      message: this.message
    };
  }
}
export module SendSmsResponse {
  /**
   * Standard JavaScript object representation for SendSmsResponse
   */
  export interface AsObject {
    ok?: boolean;
    message?: string;
  }

  /**
   * Protobuf JSON representation for SendSmsResponse
   */
  export interface AsProtobufJSON {
    ok?: boolean;
    message?: string;
  }
}
