import { Component, OnInit, Input, OnDestroy, AfterViewInit, ViewChild, ElementRef, Output, EventEmitter, ChangeDetectorRef, ChangeDetectionStrategy, NgZone, Inject } from '@angular/core';
import { ColumnConfig, MultiseriesQueryConfig, TableButtonConfig, TableColorThreshold, TableWidgetConfig, TableWidgetQuery } from '../table-widget-config/table-widget-config-service'
import { XDataType, BaseQuery, FilterLogicalGroup, FilterLogicalGroupType, ColumnFilteringTimestamp, FilterComparator, ColumnFilteringString, ColumnFilteringNumerical, XProjectorClient, MsgPackCloneObject, MultiseriesQuery, BaseQueryOutputColumnDescription, Aggregation } from '../../../XProjector/xprojector-client-service';
import { ClrDatagridComparatorInterface, ClrDatagridSortOrder, ClrDatagridStateInterface } from '@clr/angular';
import { XprojDatagridstringfilterComponent } from '../../../filters/datagrid-string-filter/xproj-datagrid-string-filter.component';
import { ArrayUtils } from '../../../utils/array-utils-service';
import { WidgetUtils } from '../../../utils/widget-utils-service';
import { XprojWidgetService, LinkedWidgetChangeParameters, LinkedWidgetSelectParameters, LinkedWidgetSelectValue, WidgetOutputChangeParameters, MasterTimeSettings } from '../../xproj-widget-service';
import { ChartWidgetConfig } from '../../chart/chart-widget-config/xproj-chart-widget-config-service';
import { WidgetBase } from '../../widget-base';
import { GridsterItemComponentInterface } from 'angular-gridster2';
import { GroupSelectionTypes, OutputDataType } from '../../widget-config-service';
import { EditMode, EnumMember } from '../../projection-dataeditor/projection-dataeditor-widget-config/projection-dataeditor-widget-config-service';
import { DateHelper } from '../../../helpers/date-helper-service';
import { LOGGERSERVICE, XprojLoggerService } from '../../../logger/xproj-logger-service';
import { XprojTableDataPipe } from './xproj-table-data.pipe';
import { XprojDatagridNumericFilterComponent } from '../../../filters/datagrid-numeric-filter/xproj-datagrid-numeric-filter.component';

class StringNumberIdComparator implements ClrDatagridComparatorInterface<any> {
  columnName : string = '';

  constructor (columnName : string) {
    this.columnName = columnName;
  }

  compare(a: any, b: any) {
    if (a && b && a[this.columnName] && b[this.columnName]) {
      return a[this.columnName].padStart(10, '0') > b[this.columnName].padStart(10, '0') ? 1 : -1;
    }
    else {
      return a ? 1 : -1
    }
  }
}

export class QueryDataColumn {
  id: string;
  columnname: string;
  columnfiltername: string;
  label: string;
  datatype: XDataType;
  data: any;
  colors: string[];
  projectionid: string;
  group: string[];
  hidden: boolean = false;
  excluded: boolean = false;
  filterEnabled: boolean = false;
  sortEnabled: boolean = false;
  clickable: boolean = false;
  clickableOutputColumnId: string = '';
  editMode: EditMode = EditMode.String;
  enumMembers: EnumMember[] = [];
  issensitive : boolean = false;
  monospacedfont : boolean = false;
  url : boolean = false;
  dateFormat : string = 'yyyy-MM-dd HH:mm';
  dateUtc : boolean = false;
  stringNumberIdSort : StringNumberIdComparator = undefined;
  sortOrder : ClrDatagridSortOrder = undefined;
}

class TableData {
  columns: QueryDataColumn[] = [];
  initilized: boolean = false;
  oldInitilized: boolean = false;
  dataValues: any = [];
  oldColumnCount: number = -1;
}

@Component({
  selector: 'xproj-table-widget',
  templateUrl: './xproj-table-widget.component.html',
  styleUrls: ['./xproj-table-widget.component.scss']
})
export class XprojTableWidgetComponent extends WidgetBase implements OnInit, OnDestroy, AfterViewInit {

  @ViewChild('currentPageInput') currentPageInputRef: ElementRef;

  @Input() chartConfig: ChartWidgetConfig;

  widgetConfig: TableWidgetConfig;

  loadingQuery: boolean = false;
  loading: boolean = true;

  private fromZoom: Date = null;
  private toZoom: Date = null;
  private useRelativeTimestamp: boolean = true;
  private lastQueries: (BaseQuery | MultiseriesQuery)[] = [];

  private forcereload: boolean = false;
  public tableData: TableData = new TableData();
  tableDataEmpty : string[];
  queryNrRows: number = 0;
  queryNrTotalRows: number = 0;

  sizeOptions = [10, 20, 50, 100];
  currentPage: number = 1;
  pageSize: number = this.sizeOptions[1];
  state: ClrDatagridStateInterface

  selectedIndex: number = -1;
  clickFirstRow: boolean = false;

  constructor(
    @Inject(LOGGERSERVICE) public logger: XprojLoggerService,
    public xprojClient: XProjectorClient,
    public widgetService: XprojWidgetService,
    public cdr: ChangeDetectorRef,
    private dateHelper: DateHelper,
    private zone: NgZone) {
    super(logger, xprojClient, widgetService);
  }

  async ngOnInit() {
    this.widgetConfig = this.config as TableWidgetConfig;

    if (this.chartConfig) {
      this.config = this.widgetConfig = new TableWidgetConfig(this.chartConfig);
    }

    await super.ngOnInit();
  }

  async onInit() {
    //this.cdr.detach();

    setTimeout(() => {
      this.sizeOptions = this.widgetConfig.RowsPerPageOptions;
      this.pageSize = this.widgetConfig.DefaultRowsPerPage;
      if (this.widgetConfig.ClientPagination) {
        this.widgetConfig.ConfigQueries.forEach(c => c.Query.maxitems = this.widgetConfig.ClientPaginationMaxItems);
      }
      else {
        this.widgetConfig.ConfigQueries.forEach(c => c.Query.maxitems = this.pageSize);
      }

      if (!this.widgetConfig.ControlledByMaster || !this.responsive) {
        if (this.widgetConfig.AutoClickFirstRow) {
          this.clickFirstRow = true;
        }
        this.refreshQueryTableDelayed();
      }

    });
  }

  ngOnDestroy(): void {
    super.ngOnDestroy();
  }

  async ngAfterViewInit() {


  }

  async onRefresh() {
    this.forcereload = true;
    this.state = undefined;
    await this.refreshQueryTableDelayed();
  }

  async onResized(component: GridsterItemComponentInterface) {
    this.setWidgetHeight(this.getHeight(component));
    await this.refreshQueryTableDelayed();
  }

  async onReset() {
    this.forcereload = true;
    this.useRelativeTimestamp = true;
    this.fromZoom = null;
    this.toZoom = null;
    this.state = undefined;
    await this.refreshQueryTableDelayed();
  }


  async onUpdateQuery() {
    this.state = undefined;
    this.tableData = new TableData();
    this.pageSize = this.widgetConfig.DefaultRowsPerPage;
    if (this.widgetConfig.ClientPagination) {
      this.widgetConfig.ConfigQueries.forEach(c => {
        c.Query.maxitems = this.widgetConfig.ClientPaginationMaxItems;
        c.Query.seekoffset = 0;
      });
    }
    else {
      this.widgetConfig.ConfigQueries.forEach(c => c.Query.maxitems = this.pageSize);
    }
    await this.refreshQueryTableDelayed();
  }

  async onLinkedWidgetChanged(e: LinkedWidgetChangeParameters) {
    let refresh: boolean = false;
    if (e.zoom) {
      if (this.fromZoom != e.zoom.from || this.toZoom != e.zoom.to) {
        this.fromZoom = e.zoom.from;
        this.toZoom = e.zoom.to;
        this.useRelativeTimestamp = false;

        refresh = true;
      }
    }

    if (e.master) {
      if (e.master.projectionId?.length > 0 && e.master.group) {
        this.widgetConfig.ConfigQueries.forEach(query => {
          query.Query.targetprojectionid = e.master.projectionId;
          query.Query.targetgroup = e.master.group;
          refresh = true;
        })
      }
      if (e.master.time) {
        if (e.master.time.relativeTimestamp) {
          this.relativeTimestamp = e.master.time.relativeTimestamp;
          this.useRelativeTimestamp = true;
          refresh = true;
        }
        else {
          this.fromZoom = e.master.time.from;
          this.toZoom = e.master.time.to;
          this.useRelativeTimestamp = false;
          refresh = true;
        }
      }
    }

    if (refresh) {
      await this.refreshQueryTableDelayed();
    }
  }

  async onWidgetOutputTimeChanged(time: MasterTimeSettings) {
    if (time) {
      if (time.relativeTimestamp) {
        this.relativeTimestamp = time.relativeTimestamp;
        this.useRelativeTimestamp = true;
      }
      else {
        this.fromZoom = time.from;
        this.toZoom = time.to;
        this.useRelativeTimestamp = false;
      }
    }
  }

  async onWidgetOutputChanged(event: WidgetOutputChangeParameters[]) {
    setTimeout(async () => {
      if (this.widgetConfig.AutoClickFirstRow) {
        this.clickFirstRow = true;
      }
      this.forcereload = true;
      await this.refreshQueryTableDelayed();
    }, 200);
  }

  async onProjectionDataChanged(projectionIds: string[], force : boolean = false) {
    let i = this.widgetConfig.ConfigQueries.findIndex(q => projectionIds.findIndex(p => p == q.Query.targetprojectionid) >= 0);
    if (i >= 0 || force) {
      this.forcereload = true;
      await this.refreshQueryTable(null, false);
    }
  }

  private setWidgetHeight(height: number): void {
    if (height) {
      this.widgetheight = height;
    }
  }

  async onCellClick($event, i, dataValue : any = null, onlyOutput = false) {
    //console.log('cell click', $event.target?.textContent);
    if (onlyOutput || $event.target?.style.cursor == 'pointer') {
      if (onlyOutput || this.selectedIndex != i) {
        this.selectedIndex = i;
        let e = new LinkedWidgetSelectParameters();
        e.widgetId = this.widgetConfig.Id;
        e.path.push(this.widgetConfig.Id);

        this.tableData.columns.forEach(col => {
          let colInfo = new LinkedWidgetSelectValue();
          colInfo.columnname = col.columnname;
          colInfo.label = col.label;
          colInfo.projectionId = col.projectionid;
          colInfo.value = (dataValue ? dataValue[col.columnname] : col.data[i]) + '';
          colInfo.group = col.group;
          colInfo.datatype = col.datatype;
          colInfo.id = col.id;

          e.values.push(colInfo);
        });

        if (!onlyOutput) {
          let selectedValue = $event.target?.textContent + '';
          e.selected = e.values.find(v => v.value == selectedValue.trim());
        }

        this.widgetService.linkedWidgetSelected(e);

        let outputs: WidgetOutputChangeParameters[] = [];

        this.widgetConfig.OutputParameters.forEach(output => {
          let outputChanged = new WidgetOutputChangeParameters();
          outputChanged.widgetId = this.widgetConfig.Id;
          outputChanged.outputParameterId = output.id;

          let colConfig = this.tableData.columns.find(col => col.id == output.id);
          if (colConfig?.clickableOutputColumnId?.length > 0) {
            let colConfigValue = e.values.find(v => v.id == colConfig.clickableOutputColumnId);
            outputChanged.value = colConfigValue?.value;
          }
          else {
            let colConfigValue = e.values.find(v => v.id == colConfig.id);
            outputChanged.value = colConfigValue?.value;
          }
          outputChanged.datatype = output.datatype;

          outputs.push(outputChanged);
        });

        if (outputs.length > 0) {
          this.widgetService.outputParametersChanged(outputs);
        }
      }
      else {
        this.selectedIndex = -1;

        let outputs: WidgetOutputChangeParameters[] = [];

        this.widgetConfig.OutputParameters.forEach(output => {
          let outputChanged = new WidgetOutputChangeParameters();
          outputChanged.widgetId = this.widgetConfig.Id;
          outputChanged.outputParameterId = output.id;
          outputChanged.value = undefined;
          outputChanged.datatype = output.datatype;

          outputs.push(outputChanged);
        });

        if (outputs.length > 0) {
          this.widgetService.outputParametersChanged(outputs);
        }
      }
    }
  }

  async runButtonLuaScript(buttonConfig: TableButtonConfig, row: number) {
    let values: { id: string, value: any, datatype: XDataType }[] = [];
    this.tableData.columns.forEach(col => {
      values.push({ id: col.label, value: col.data[row], datatype: col.datatype });
    });

    this.logger.debug('runButtonLuaScript', buttonConfig.LuaScript, row, values);
  }

  getColumnValue(column: QueryDataColumn, index: number): any {
    switch (column.editMode) {
      case EditMode.XDatatype:
        return Object.values(XDataType).indexOf(XDataType[column.data[index]]);

      case EditMode.Boolean:
        return column.data[index] != 0;

      case EditMode.Timestamp:
        try {
          return this.dateHelper.utils.formatByString(column.data[index], "yyyy-MM-dd'T'HH:mm:ss");
        }
        catch {
          return column.data[index];
        }

      case EditMode.Enum:
      case EditMode.Flags:
        return column.data[index];

      default:
        return column.data[index];

    }
  }

  getColor(colorThredholds: TableColorThreshold[], value: number): string {
    try {
      if (!colorThredholds || colorThredholds.length == 0) {
        return '';
      }

      for (let i = 0; i < colorThredholds.length; i++) {
        let ct = colorThredholds[i];
        if (value >= ct.MinValue && value < ct.MaxValue) {
          return ct.Color;
        }
      }

      return '';
    }
    catch {
      return '';
    }
  }

  async reQueryMultiSeries(query: MultiseriesQuery, configQueries: TableWidgetQuery[], multiseriesQueryConfig: MultiseriesQueryConfig, queryData: QueryDataColumn[]) {

    let queryResult = await this.xprojClient.RequestQueryMultiseriesQuery(query, this.forcereload, 'tablewidget', this.config.Name);
    this.forcereload = false;

    let numericaldata = queryResult["datanumbers"];
    let timestampdata = queryResult["datatimestamps"];
    let stringdata = queryResult["datastrings"];

    for (let i = 0; i < queryResult["columns"].length; i++) {
      let it = queryResult.columns[i];
      let typ = it.datatype;
      let colname = it.columnoutname;
      let data = [];
      let columnConfig: ColumnConfig;
      let configQuery: TableWidgetQuery;

      configQueries.forEach(cq => {
        if (!columnConfig) {
          columnConfig = cq.ColumnConfigs.find(c => c.ColumnOutName == colname);
          configQuery = cq;
        }
      });

      if (typ <= XDataType.Number) {
        if (columnConfig?.Datatype == XDataType.UInt8 || columnConfig?.Datatype == XDataType.Int32 || columnConfig?.Datatype == XDataType.Int64) {
          if (columnConfig.Transform >= Aggregation.MEAN_ARITHMETIC) {
            data = WidgetUtils.FormatNumbers(numericaldata[it.indexintypedvector], this.globalWidgetSettings.Decimals);
          }
          else {
            data = numericaldata[it.indexintypedvector];
          }
        }
        else {
          data = WidgetUtils.FormatNumbers(numericaldata[it.indexintypedvector], this.globalWidgetSettings.Decimals);
        }
      }
      if (typ == XDataType.String) {
        data = stringdata[it.indexintypedvector];
      }
      if (typ == XDataType.Timestamp) {
        data = timestampdata[it.indexintypedvector];
      }

      //let colname = it["columnoutname"];
      let colData = queryData.find(d => d.columnname == colname);

      let unit = columnConfig?.Unit;

      if (!columnConfig) {
        let scriptedColumnPostJoin = multiseriesQueryConfig.ScriptedColumnsPostJoin.find(scol => scol.Column.columnoutname == colname);
        if (scriptedColumnPostJoin) {
          unit = scriptedColumnPostJoin.Unit;
        }
        // let i = multiseriesQueryConfig.ScriptedColumnsPostJoin.findIndex(col => col.columnoutname == colname);
        // if (i > -1 && i < multiseriesQueryConfig.ScriptedColumnUnits.length) {
        //   unit = multiseriesQueryConfig.ScriptedColumnUnits[i];
        // }
      }

      if (colData) {
        colData.datatype = it.datatype;
        colData.data = data;
      }
      else {
        colData = new QueryDataColumn();
        colData.columnname = colname;
        colData.columnfiltername = it.columnname;
        colData.label = columnConfig?.Label?.length > 0 ? columnConfig.Label : colname;
        if (unit?.length > 0) {
          colData.label = colData.label + ' (' + unit + ')';
        }
        colData.data = data;
        colData.projectionid = configQuery.ProjectionId;
        colData.group = configQuery.Group;
        colData.datatype = it.datatype;
        colData.hidden = columnConfig?.Hidden || (columnConfig.HideIfEmpty && this.allDataIsEmpty(data, columnConfig.Datatype));
        colData.excluded = columnConfig?.Excluded || (columnConfig.HideIfEmpty && this.allDataIsEmpty(data, columnConfig.Datatype));
        colData.clickable = columnConfig?.Clickable;
        colData.issensitive = columnConfig?.IsSensitive;
        colData.monospacedfont = columnConfig?.MonospacedFont;
        colData.clickableOutputColumnId = columnConfig?.ClickableOutputColumnId;
        colData.id = columnConfig?.Id;
        colData.editMode = columnConfig?.EditMode;
        colData.enumMembers = columnConfig?.EnumMembers;
        colData.filterEnabled = columnConfig?.FilterEnabled;
        colData.sortEnabled = columnConfig?.SortEnabled;
        colData.url = columnConfig?.Url;
        colData.dateFormat = this.getDateFormatString(columnConfig?.DateFormat);
        colData.dateUtc = this.globalWidgetSettings?.DateUTC;
        if (columnConfig?.SortStringAsNumber) {
          colData.stringNumberIdSort = new StringNumberIdComparator(colname);
        }
        if (configQuery.defaultSortColumnName == columnConfig.ColumnName) {
          colData.sortOrder = configQuery.defaultSortDescending ? ClrDatagridSortOrder.DESC : ClrDatagridSortOrder.ASC;
        }

        queryData.push(colData);
      }

      colData.colors = [];
      colData.data.forEach(d => {
        colData.colors.push(this.getColor(columnConfig.ColorThresholds, d));
      });
    }

    this.queryNrRows = queryResult.nrpoints;
    this.queryNrTotalRows = queryResult.nroriginalpoints;
  }

  async reQuery(getQuery: () => BaseQuery, configQuery: TableWidgetQuery, queryData: QueryDataColumn[]): Promise<BaseQuery> {
    let result: BaseQuery;
    let queryResult = await this.requestBaseQueryResult(getQuery, (query) => {
      result = query;
      return this.xprojClient.RequestQueryBaseQuery(query, this.forcereload, 'tablewidget', this.config.Name);
    }, configQuery, configQuery.DataFilters, this.forcereload, 'tablewidget', this.config.Name);
    this.forcereload = false;

    if (queryResult.columns) {
      let numericaldata = queryResult.datanumbers;
      let timestampdata = queryResult.datatimestamps;
      let stringdata = queryResult.datastrings;

      configQuery.ColumnConfigs.forEach(columnConfig => {
        let queryColumn: BaseQueryOutputColumnDescription;
        if (columnConfig.ColumnName.indexOf(':') < 0) {
          queryColumn = queryResult.columns.find(col => col.columnoutname == columnConfig.ColumnOutName);
        }
        else {
          if (columnConfig.ColumnName.startsWith('script')) {
            queryColumn = queryResult.columns.find(col => 'script:' + col.columnoutname == columnConfig.ColumnName);
          }
          else {
            queryColumn = queryResult.columns.find(col => col.columnoutname == columnConfig.ColumnName);
          }
        }

        if (queryColumn) {
          let data = [];

          if (queryColumn.datatype <= XDataType.Number) {
            if (columnConfig?.Datatype == XDataType.UInt8 || columnConfig?.Datatype == XDataType.Int32 || columnConfig?.Datatype == XDataType.Int64) {
              if (columnConfig.Transform >= Aggregation.MEAN_ARITHMETIC) {
                data = WidgetUtils.FormatNumbers(numericaldata[queryColumn.indexintypedvector], this.globalWidgetSettings.Decimals);
              }
              else {
                data = numericaldata[queryColumn.indexintypedvector];
              }
                }
            else {
              data = WidgetUtils.FormatNumbers(numericaldata[queryColumn.indexintypedvector], this.globalWidgetSettings.Decimals);
            }
          }
          if (queryColumn.datatype == XDataType.String) {
            data = stringdata[queryColumn.indexintypedvector];
          }
          if (queryColumn.datatype == XDataType.Timestamp) {
            data = timestampdata[queryColumn.indexintypedvector];
          }

          let colData = queryData.find(d => d.columnname == queryColumn.columnoutname);

          if (colData) {
            colData.datatype = queryColumn.datatype;
            colData.data = data;
          }
          else {
            colData = new QueryDataColumn();
            colData.columnname = queryColumn.columnoutname;
            colData.columnfiltername = queryColumn.columnname;
            colData.label = columnConfig?.Label?.length > 0 ? columnConfig.Label : queryColumn.columnoutname;
            if (columnConfig?.Unit?.length > 0) {
              colData.label = colData.label + ' (' + columnConfig?.Unit + ')';
            }
            colData.data = data;
            colData.projectionid = configQuery.ProjectionId;
            colData.group = configQuery.Group;
            colData.datatype = queryColumn.datatype;
            colData.hidden = columnConfig?.Hidden || (columnConfig.HideIfEmpty && this.allDataIsEmpty(data, columnConfig.Datatype));
            colData.excluded = columnConfig?.Excluded || (columnConfig.HideIfEmpty && this.allDataIsEmpty(data, columnConfig.Datatype));
            colData.clickable = columnConfig?.Clickable;
            colData.monospacedfont = columnConfig?.MonospacedFont;
            colData.issensitive = columnConfig?.IsSensitive;
            colData.clickableOutputColumnId = columnConfig?.ClickableOutputColumnId;
            colData.id = columnConfig?.Id;
            colData.editMode = columnConfig?.EditMode;
            colData.enumMembers = columnConfig?.EnumMembers;
            colData.filterEnabled = columnConfig?.FilterEnabled;
            colData.sortEnabled = columnConfig?.SortEnabled;
            colData.url = columnConfig?.Url;
            colData.dateFormat = this.getDateFormatString(columnConfig?.DateFormat);
            colData.dateUtc = this.globalWidgetSettings?.DateUTC;
            if (columnConfig?.SortStringAsNumber) {
              colData.stringNumberIdSort = new StringNumberIdComparator(queryColumn.columnoutname);
            }
            queryData.push(colData);
          }

          colData.colors = [];
          colData.data.forEach(d => {
            colData.colors.push(this.getColor(columnConfig.ColorThresholds, d));
          });
        }
      });
    }

    this.queryNrRows = queryResult.nrpoints;
    this.queryNrTotalRows = queryResult.nroriginalpoints;

    return result;
  }

  getDateFormatString(dateFormat : string) {
    let result = dateFormat;

    if (dateFormat == "fullDate")
        result = this.dateHelper.utils.formats.fullDate;
      else if (dateFormat == "normalDate")
        result = this.dateHelper.utils.formats.normalDate;
      else if (dateFormat == "shortDate")
        result = this.dateHelper.utils.formats.shortDate;
      else if (dateFormat == "monthAndDate")
        result = this.dateHelper.utils.formats.monthAndDate;
      else if (dateFormat == "fullTime24h")
        result = this.dateHelper.utils.formats.fullTime24h;
      else if (dateFormat == "fullDateTime")
        result = this.dateHelper.utils.formats.fullDateTime;
      else if (dateFormat == "fullDateTime24h")
        result = this.dateHelper.utils.formats.fullDateTime24h;
      else if (dateFormat == "keyboardDate")
        result = this.dateHelper.utils.formats.keyboardDate;
      else if (dateFormat == "keyboardDateTime")
        result = this.dateHelper.utils.formats.keyboardDateTime;
      else if (dateFormat == "keyboardDateTime24h")
        result = this.dateHelper.utils.formats.keyboardDateTime24h;

    return result;
  }

  allDataIsEmpty(data: any[], dataType: XDataType): boolean {
    let result = true;

    switch (dataType) {
      case XDataType.String:
        data.forEach(d => {
          if (d != undefined && d.length > 0) {
            result = false;
          }
        });
        break;

      case XDataType.Timestamp:
        result = false;
        break;

      default:
        data.forEach(d => {
          if (!Number.isNaN(d)) {
            result = false;
          }
        });
        break;
    }

    return result;
  }

  queryByConfig: Map<string, string> = new Map<string, string>();

  checkIfNeedRefresh(query: BaseQuery, configQuery: TableWidgetQuery): boolean {
    let result = true;
    let queryJson = JSON.stringify(query)
    result = this.queryByConfig[configQuery.Id] != queryJson;
    this.queryByConfig[configQuery.Id] = queryJson;
    return result;
  }

  getExportQueries(): (BaseQuery | MultiseriesQuery)[] {
      return this.widgetConfig.WidgetPreQueryConfigs?.length > 0 ? [] : this.lastQueries;
      //return (this.widgetConfig.WidgetPreQueryConfigs?.length > 0 || this.widgetConfig.ClientPagination) ? [] : this.lastQueries;
  }

  getExportExcludeColumns(): string[] {
    let result : string[] = [];
    this.widgetConfig.ConfigQueries.forEach(queryConfig => {
      queryConfig.ColumnConfigs.forEach(c => {
        if (c.Excluded) {
          result.push(c.ColumnOutName);
        }
      });
    });

    return result;
  }

  getExportData(): any[][] {
    let result = [];
    let headers = [];
    this.tableData.columns.forEach(c => {
      if (!c.hidden && !c.excluded) {
        headers.push(c.label);
      }
    });
    result.push(headers);

    let tableDataPipe : XprojTableDataPipe = new XprojTableDataPipe(this.dateHelper);

    this.tableData.dataValues.forEach(dataValue => {
      let row = [];
      this.tableData.columns.forEach(c => {
        if (!c.hidden && !c.excluded) {
          row.push(tableDataPipe.transform(c, dataValue));
        }
      });
      result.push(row);
    });

    return result;
  }

  stepFirst() {
    if (this.currentPage != 1) {
      this.currentPage = 1;
      this.selectedIndex = -1;
      this.refreshQueryTableDelayed();
    }
  }

  stepPrevious() {
    if (this.currentPage > 1) {
      this.currentPage--;
      this.selectedIndex = -1;
      this.refreshQueryTableDelayed();
    }
  }

  stepNext() {
    this.currentPage++;
    this.selectedIndex = -1;
    this.refreshQueryTableDelayed();
  }

  updateCurrentPage($event) {
    const parsed = parseInt($event.target.value, 10);

    if (!isNaN(parsed)) {
      if (parsed < 1) {
        this.currentPage = 1;
      }
      else {
        this.currentPage = parsed;
      }
    }

    this.currentPageInputRef.nativeElement.value = this.currentPage;
    this.refreshQueryTableDelayed();
  }

  async refreshQueryTableSetState(state?: ClrDatagridStateInterface) {
    if (!this.widgetConfig?.ClientPagination) {
      if (state) {
        this.state = state;
      }
      await this.refreshQueryTable(state);
    }
    else {
      this.state = undefined;
    }
  }

  loadTimer: any;
  async refreshQueryTableDelayed(state?: ClrDatagridStateInterface, showLoading: boolean = true) {
    if (!this.loadTimer) {
      this.loadTimer = setTimeout(async () => {
        this.loadTimer = undefined;
        await this.refreshQueryTable(state, showLoading);
        if (this.clickFirstRow) {
          if (this.tableData.columns?.length > 0 && this.tableData.columns[0].data?.length > 0) {
            this.clickFirstRow = false;
          }
          this.onCellClick(null, 0, null, true);
        }
      }, 200);
    }
  }

  getQuery(configQuery: TableWidgetQuery, tableData: TableData): BaseQuery {
    let query = configQuery.Query.Clone();

    query.columns = query.columns.filter(col => col.columnname.indexOf(':') < 0);

    let i = 0;
    query.columns.forEach(col => {
      if (i > 0 || configQuery.UseGrouping) {
        let columnConfig = configQuery.ColumnConfigs[configQuery.UseGrouping ? i + 1 : i];
        if (columnConfig.UseAggregationInputParameter) {
          col.columnaggregation = this.getParameterValue(columnConfig.AggregationInputParameterId, col.columnaggregation).value;
        }
      }
      i++;
    });

    //Transformation input parameters
    if (configQuery.UseGrouping && configQuery.UseTransformInputParameter) {
      query.grouping.columntransformation = this.getParameterValue(configQuery.TransformInputParameterId, configQuery.GroupingTransform).value;
    }

    //Projection input parameters
    if (configQuery.UseProjectionInputParameter) {
      query.targetprojectionid = this.getParameterValue(configQuery.ProjectionInputParameterId, configQuery.Query.targetprojectionid).value;
    }

    //Group input parameters
    if (configQuery.GroupSelectionType == GroupSelectionTypes.GROUP_INPUT) {
      query.targetgroup = this.getParameterValue(configQuery.GroupInputParameterId, configQuery.Query.targetgroup).value;
    }
    else if (configQuery.GroupSelectionType == GroupSelectionTypes.GROUP_INPUT_PARAMETERS) {
      query.targetgroup = [];
      configQuery.GroupInputParameterIds.forEach(id => {
        query.targetgroup.push(this.getParameterValue(id, '').value + '');
      });
    }

    WidgetUtils.SetQueryFilterValues(query, configQuery.DataFilters, (i, d) => this.getParameterValue(i, d));
    WidgetUtils.AddQueryTimeframe(query,
      this.fromZoom ?? this.from,
      this.toZoom ?? this.to,
      configQuery.timestampColumnName,
      this.useRelativeTimestamp ? this.relativeTimestamp : null);

    let sortingDefined: boolean = false;

    if (!this.widgetConfig.MultiseriesConfig.Enabled) {
      if (this.state) {
        let filterId = 20000;
        if (this.state.filters) {
          for (let filter of this.state.filters) {
            if (filter instanceof XprojDatagridstringfilterComponent) {
              let dynfilter = filter as XprojDatagridstringfilterComponent;
              let col = dynfilter.columnname;
              let strings = dynfilter.SelectedStrings;
              let gr = new FilterLogicalGroup();
              gr.type = FilterLogicalGroupType.OR;
              gr.queryfilterid = filterId++;
              for (let str of strings) {
                let newFilter = new ColumnFilteringString();
                newFilter.queryfilterid = filterId++;
                newFilter.columnname = col;
                newFilter.comparator = FilterComparator.Equals;
                newFilter.value = str;
                gr.filters.push(newFilter.queryfilterid);
                query.stringfilters.push(newFilter);
              }
              query.filter.filters.push(gr.queryfilterid);
              query.subfiltergroups.push(gr);
              continue;
            }

            if (filter instanceof XprojDatagridNumericFilterComponent) {
              let dynfilter = filter as XprojDatagridNumericFilterComponent;
              let col = dynfilter.columnname;
              let exact = dynfilter.exact;
              let low = dynfilter.low;
              let high = dynfilter.high;
              if (exact != null) {
                let newFilter = new ColumnFilteringNumerical();
                newFilter.columnname = col;
                newFilter.comparator = FilterComparator.Equals;
                newFilter.queryfilterid = ++filterId;
                newFilter.value = exact;
                query.filter.filters.push(newFilter.queryfilterid);
                query.numericalfilters.push(newFilter);
                continue;
              }
              else if (low != null || high != null) {
                let gr = new FilterLogicalGroup();
                gr.type = FilterLogicalGroupType.AND;
                gr.queryfilterid = filterId++;
                if (low != null) {
                  let newFilter = new ColumnFilteringNumerical();
                  newFilter.queryfilterid = filterId++;
                  newFilter.columnname = col;
                  newFilter.comparator = FilterComparator.GreatherThanOrEquals;
                  newFilter.value = low;
                  gr.filters.push(newFilter.queryfilterid);
                  query.numericalfilters.push(newFilter);
                }
                if (high != null) {
                  let newFilter = new ColumnFilteringNumerical();
                  newFilter.queryfilterid = filterId++;
                  newFilter.columnname = col;
                  newFilter.comparator = FilterComparator.LessThanOrEquals;
                  newFilter.value = high;
                  gr.filters.push(newFilter.queryfilterid);
                  query.numericalfilters.push(newFilter);
                }

                query.filter.filters.push(gr.queryfilterid);
                query.subfiltergroups.push(gr);
                continue;
              }
            }

            let columname = filter.property;
            let value = filter.value;

            for (let col of query.columns) {
              if (col.columnoutname != columname)
                continue;

              let scol = tableData.columns.find(c => c.columnname == col.columnname);

              if (scol) {
                switch (scol.datatype) {
                  case XDataType.Number:
                  case XDataType.Float32:
                  case XDataType.Float64:
                  case XDataType.UInt8:
                  case XDataType.Int32:
                  case XDataType.Int64:
                    {
                      let newFilter = new ColumnFilteringNumerical();
                      newFilter.columnname = scol.columnfiltername;
                      newFilter.comparator = FilterComparator.Equals;
                      newFilter.queryfilterid = ++filterId;
                      newFilter.value = parseFloat(value);
                      query.filter.filters.push(newFilter.queryfilterid);
                      query.numericalfilters.push(newFilter);
                      break;
                    }
                  case XDataType.Timestamp:
                    {
                      let newFilter = new ColumnFilteringTimestamp();
                      newFilter.columnname = scol.columnfiltername;
                      newFilter.comparator = FilterComparator.Equals;
                      newFilter.queryfilterid = ++filterId;
                      newFilter.value = new Date(value);
                      query.filter.filters.push(newFilter.queryfilterid);
                      query.timestampfilters.push(newFilter);
                      break;
                    }
                  case XDataType.String:
                    {
                      let newFilter = new ColumnFilteringString();
                      newFilter.columnname = scol.columnfiltername;
                      newFilter.comparator = FilterComparator.Equals;
                      newFilter.queryfilterid = ++filterId;
                      newFilter.value = value;
                      query.filter.filters.push(newFilter.queryfilterid);
                      query.stringfilters.push(newFilter);
                      break;
                    }
                }
              }
            }
          }
        }

        if (this.state.sort) {
          let scol = configQuery.ColumnConfigs.find(c => c.ColumnOutName == this.state.sort.by);
          if (scol) {
            sortingDefined = true;
            if (configQuery.UseGrouping) {
              query.sorting.columnname = scol.ColumnOutName;
            }
            else {
              query.sorting.columnname = scol.ColumnName;
            }
            query.sorting.descending = this.state.sort.reverse;
          }
        }

        this.pageSize = this.state.page.size;
        query.seekoffset = this.state.page.size * (this.currentPage - 1);
        query.maxitems = this.state.page.size;
      }
      else {
        query.seekoffset = this.widgetConfig.ClientPagination ? 0 : this.pageSize * (this.currentPage - 1);
        query.maxitems = this.widgetConfig.ClientPagination ? this.widgetConfig.ClientPaginationMaxItems : this.pageSize;
      }

      if (!sortingDefined) {
        let scol = configQuery.ColumnConfigs.find(c => c.ColumnName == configQuery.defaultSortColumnName);
        if (scol) {
          if (configQuery.UseGrouping) {
            query.sorting.columnname = scol.ColumnOutName;
          }
          else {
            query.sorting.columnname = scol.ColumnName;
          }
          query.sorting.descending = configQuery.defaultSortDescending;
        }
        else if (query.sorting.columnname.indexOf(':') > -1) {
          let scol = query.columns.find(c => c.columnname.indexOf(':') < 0);
          if (configQuery.UseGrouping) {
            query.sorting.columnname = scol.columnoutname;
          }
          else {
            query.sorting.columnname = scol.columnname;
          }
          query.sorting.descending = configQuery.defaultSortDescending;

        }
      }
    }

    return query;
  }

  async refreshQueryTable(state?: ClrDatagridStateInterface, showLoading: boolean = true) {
    if (!this.loadingQuery) {
      if (!this.widgetheight) {
        this.setWidgetHeight(this.widgetConfig.Height);
      }
      if (!this.inputParametersHasValue(true)) {
        this.tableData.initilized = false;
        this.tableData.oldInitilized = false;

        this.tableDataEmpty = [];
        if (this.widgetConfig?.ConfigQueries.length > 0) {
          let configQuery = this.widgetConfig.ConfigQueries[0];
          configQuery.ColumnConfigs.forEach(column => {
            if (!column.Hidden && !column.Excluded) {
              this.tableDataEmpty.push(column.Label.length > 0 ? column.Label : column.ColumnOutName);
            }
          });
        }
      }
      else if (!this.forcereload && this.tableData.oldInitilized != this.tableData.initilized) {
        this.tableData.oldInitilized = this.tableData.initilized;
      }
      else {
        this.tableData.oldInitilized = this.tableData.initilized;

        try {
          this.loadingQuery = true;
          this.loading = showLoading;
          let queryData: QueryDataColumn[] = [];
          let newDataRequested: boolean = false;
          this.lastQueries = [];
          let multiseriesQuery: MultiseriesQuery;
          if (state) {
            this.state = state;
          }

          if (!this.widgetConfig.MultiseriesConfig.Enabled) {
            await ArrayUtils.AsyncForEach(this.widgetConfig.ConfigQueries, async (configQuery: TableWidgetQuery) => {
              //if (this.state || this.forcereload || this.checkIfNeedRefresh(query, configQuery)) {
              newDataRequested = true;
              let query = await this.reQuery(() => this.getQuery(configQuery, this.tableData), configQuery, queryData);
              this.lastQueries.push(query);
              //}
            });
          }
          else {
            await ArrayUtils.AsyncForEach(this.widgetConfig.ConfigQueries, async (configQuery: TableWidgetQuery) => {
              //Multi series query
              if (!multiseriesQuery) {
                multiseriesQuery = new MultiseriesQuery();
              }
              multiseriesQuery.queries.push(this.getQuery(configQuery, this.tableData));
            });
          }

          if (this.widgetConfig.MultiseriesConfig.Enabled && multiseriesQuery) {
            multiseriesQuery.joinablecolumns = this.widgetConfig.MultiseriesConfig.JoinableColumns;
            this.widgetConfig.MultiseriesConfig.ScriptedColumnsPostJoin.forEach(scol => {
              multiseriesQuery.scriptedcolumnspostjoin.push(scol.Column);
            })

            if (this.state) {
              //TODO : sorting

              multiseriesQuery.seekoffset = this.state.page.size * (this.state.page.current - 1);
              multiseriesQuery.maxitems = this.state.page.size;
            }
            else {
              multiseriesQuery.seekoffset = 0;
              this.pageSize = this.widgetConfig.DefaultRowsPerPage;
              multiseriesQuery.maxitems = this.widgetConfig.ClientPagination ? this.widgetConfig.ClientPaginationMaxItems : this.pageSize;
            }

            //if (this.state || this.forcereload || this.checkIfNeedRefresh(query, configQuery)) {
            //if (this.state || this.forcereload) {
            newDataRequested = true;
            await this.reQueryMultiSeries(multiseriesQuery, this.widgetConfig.ConfigQueries, this.widgetConfig.MultiseriesConfig, queryData);
            //}

            this.lastQueries.push(multiseriesQuery);
          }

          if (newDataRequested) {
            queryData.forEach(qd => {
              let col = this.tableData.columns.find(c => c.columnname == qd.columnname);
              if (col) {
                col.data = qd.data;
                col.colors = qd.colors;
              }
              else {
                this.tableData.columns.push(qd);
              }
            });

            this.tableData.columns = this.tableData.columns.filter(td => queryData.findIndex(q => q.columnname == td.columnname) > -1);
          }
        }
        catch (error) {
          this.logger.debug('tablewidget clear table', error, this.widgetConfig?.Name);
          this.tableData.columns.forEach(col => col.data = []);
        }
        finally {
          //this.cdr.detach(); // trying to make stuff work - stefan
          let newDataValues = [];
          if (this.tableData.columns.length > 0) {
            for (let i = 0; i < this.tableData.columns[0].data.length; i++) {
              let dataValue : any = {};
              this.tableData.columns. forEach(col => {
                dataValue[col.columnname] = col.data[i];
              });
              newDataValues.push(dataValue);
            }
          }

          if (this.tableData.oldColumnCount != this.tableData.columns.length) {
            setTimeout(() => {
              this.tableData.dataValues = newDataValues;
            });
          } else {
            this.tableData.dataValues = newDataValues;
          }

          this.loadingQuery = false;
          this.tableData.initilized = this.tableData.columns.length > 0;
          this.tableData.oldColumnCount = this.tableData.columns.length;

          //To prevent changedetection errors on init.
          setTimeout(() => {
            this.loading = false;
          });

          //this.cdr.reattach(); // trying to make stuff work - stefan
        }
      }
    }
  }
}
