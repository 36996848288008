import { Pipe, PipeTransform } from '@angular/core';
import { BmsLorawanDeviceInfo } from '@app/core/models/lorawan-device-info';

@Pipe({
  name: 'bmslorawanmetersgroupbatterylevel'
})
export class BmsLorawanMetersGroupBatteryLevelPipe implements PipeTransform {

  transform(deviceInfo: BmsLorawanDeviceInfo): string {
    let result = '---';
    if (deviceInfo) {
      if (deviceInfo.batteryLevel == 0) {
        result = 'Ext. power';
      }
      else if (deviceInfo.batteryLevel < 255) {
        result = (((deviceInfo.batteryLevel - 1) * 100)/253).toFixed(0) + '%';
      }
    }

    return result;
  }
}
