<table class="table table-vertical" *ngIf="apartment">
  <caption style="margin-bottom: 1em;">
    Apartment info
    <button class="btn btn-sm" style="float: right;" (click)="viewInBMS();">View in BMS <clr-icon shape="arrow" directions="left" style="transform: rotate(90deg);"></clr-icon></button>
  </caption>
  <tbody>
    <tr>
      <th class="left">Sv Lant Id</th>
      <td class="left">{{apartment.svlantApartmentno}}</td>
    </tr>
    <tr>
      <th class="left">External Id</th>
      <td class="left">{{apartment.externalId}}</td>
    </tr>
    <tr>
      <th class="left">Area</th>
      <td class="left">{{apartment.area}} m2</td>
    </tr>
    <tr>
      <th class="left">Size</th>
      <td class="left">{{apartment.size}} rooms</td>
    </tr>
    <tr>
      <th class="left">Create At</th>
      <td class="left">{{dateHelper.utils.format(apartment.createdAt, 'keyboardDateTime')}}</td>
    </tr>
    <tr>
      <th class="left">Modified At</th>
      <td class="left">{{dateHelper.utils.format(apartment.modifiedAt, 'keyboardDateTime')}}</td>
    </tr>
  </tbody>
</table>
<p *ngIf="!apartment"> Apartment info not available.</p>
