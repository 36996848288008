


export class BmsDataExportBotConfig {
  nodeId : string;
  name : string;
  enabled : boolean;
  schedule : string;
}


export class BmsDataExportBotExecution {
  configNodeId : string;
  outputEnabled : boolean;
}
