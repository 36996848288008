import { BmsMeterState } from './bms-meter';

export class BmsMeterStateEvent {
  id : number;
  meterId : number;

  activeFrom : Date;
  meterState : BmsMeterState;

  createdAt : Date;
  modifiedAt : Date;

  deleted : boolean = false;
  deletedAt : Date = new Date(0);
}
