import { AfterViewInit, Component, OnDestroy, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { XProjectorBmsEventsClient } from '@core/xprojector_backend/xprojector-bms-events-client';
import { Event } from '@core/xprojector_backend/proto/xprojector.modulebms.events.pb';
import { NGXLogger } from 'ngx-logger';
import { map } from 'rxjs/operators';
import { DateHelper, XprojAlertService, XprojModalService } from 'xproj-lib';
import { environment } from 'src/environments/environment';
import { UserRights } from '@xprojectorcore/models/user-rights';
import { Subscription } from 'rxjs';
import { StateService } from '@xprojectorcore/services/state-service';

@Component({
  selector: 'app-bms-customer-event',
  templateUrl: './bms-event.component.html',
  styleUrls: ['./bms-event.component.scss']
})
export class BmsEventComponent implements OnInit, AfterViewInit, OnDestroy {

  logo: string;
  title: string;

  customerId: string = '';
  eventId: string = '';
  userId: string = '';
  userTypeId: string = '';

  user: UserRights;
  private userSubscription: Subscription;

  initiated: boolean = false;

  event: Event;
  customerName: string;
  status : string = '';

  constructor(private route: ActivatedRoute,
    private state: StateService,
    private logger: NGXLogger,
    private modalService: XprojModalService,
    private alertService: XprojAlertService,
    private eventsClient: XProjectorBmsEventsClient,
    public dateHelper: DateHelper) {
    this.logo = environment.logo;
    this.title = environment.maintitle;
  }

  ngOnInit(): void {
    this.userSubscription = this.state.user$.subscribe(async (user) => {
      this.user = user;
    });

    this.route.params.pipe(map(p => p.customerid)).subscribe(async (customerId) => {
      if (customerId) {
        this.logger.info('ngOnInit customerId', customerId);
        this.customerId = customerId;
      }
    });
    this.route.params.pipe(map(p => p.eventid)).subscribe(async (eventId) => {
      if (eventId) {
        this.logger.info('ngOnInit eventId', eventId);
        this.eventId = eventId;
      }
    });

    this.userId = this.route.snapshot.queryParams['userid'] ?? '';
    this.userTypeId = this.route.snapshot.queryParams['usertypeid'] ?? '';
  }

  ngAfterViewInit(): void {
    this.updateEvent();
  }

  ngOnDestroy(): void {
    this.userSubscription?.unsubscribe();
  }

  async updateEvent() {
    if (this.customerId?.length > 0 && this.eventId?.length > 0) {
      let result = await this.eventsClient.getEvent(this.customerId, this.eventId);
      this.event = result.event;
      this.customerName = result.customerName;
      this.status = this.event.muted ? $localize `:@@customerevents_muted:Muted` : this.event.active ? (this.event.acknowledged ? $localize `:@@customerevents_open:Open` : $localize `:@@customerevents_new:New`) : $localize `:@@customerevents_closed:Closed`;
      this.logger.info("bmsevent", this.event);
      this.initiated = true;
    }
  }

  async acknowledge() {
    if (this.event) {
      let result = await this.modalService.ShowInputModalAsync({
        header: $localize `:@@bmsevent_acknowledge:Acknowledge`,
        description: $localize `:@@bmsevent_inputcomment:Input comment:`,
        value: '',
        ok: $localize `:@@bmsevent_acknowledge:Acknowledge`,
        cancel: $localize `:@@bmsevent_cancel:Cancel`
      });

      if (result.result) {
        let ackResult = await this.eventsClient.acknowledgeEvent(this.event.customerId, this.event.id, result.value, this.userId, this.userTypeId);
        if (ackResult) {
          this.alertService.success($localize `:@@bmsevent_acknowledgesucces:Event acknoweledged ok.`);
        }
        else {
          this.alertService.error(`:@@bmsevent_acknowledgeerror:Error acknowledge event.`);
        }

        this.updateEvent();
      }
    }
  }

  async clear() {
    if (this.event) {
      let result = await this.modalService.ShowInputModalAsync({
        header: $localize `:@@bmsevent_clear:Clear event`,
        description: $localize `:@@bmsevent_inputcomment:Input comment:`,
        value: '',
        ok: $localize `:@@bmsevent_clear:Clear event`,
        cancel: $localize `:@@bmsevent_cancel:Cancel`
      });

      if (result.result) {
        let clearResult = await this.eventsClient.clearEvent(this.event.customerId, this.event.id, result.value, this.userId, this.userTypeId);

        if (clearResult) {
          this.alertService.success($localize `:@@bmsevent_clearsucces:Event cleared ok.`);
        }
        else {
          this.alertService.error(`:@@bmsevent_clearerror:Error clear event.`);
        }

        this.updateEvent();
      }
    }
  }

  async muteEvent() {
    if (this.event) {
      let result = await this.modalService.ShowInputModalAsync({
        header: $localize `:@@bmsevent_mute:Mute event`,
        description: $localize `:@@bmsevent_inputcomment:Input comment:`,
        value: '',
        ok: $localize `:@@bmsevent_mute:Mute event`,
        cancel: $localize `:@@bmsevent_cancel:Cancel`
      });

      if (result.result) {
        let muteResult = await this.eventsClient.muteEvent(this.event.customerId, this.event.id, result.value, this.state.userId, '_x_customerusers_user');
        if (muteResult) {
          this.alertService.success($localize `:@@bmsevent_mutesucces:Event muted ok.`);
        }
        else {
          this.alertService.error(`:@@bmsevent_muteerror:Error mute event.`);
        }

        this.updateEvent();
      }
    }
  }
}
