import { Component, Input, OnInit, inject } from '@angular/core';
import { BmsTariffGroup } from '../../../models/bms-tariff-group';
import { BmsTariffService } from '../../services/bms-tariff-service';
import { XprojAlertService, XprojModalService } from 'xproj-lib';
import { GrpcNode } from '@xprojectorcore/xprojector_backend/proto/xprojector.grpc.models.pb';
import { XProjectorBmsCustomerAdminClient } from '@app/core/xprojector_backend/xprojector-bms-customeradmin-client';
import { TariffGroupInfo } from '@app/core/xprojector_backend/proto/xprojector.modulebms.customeradmin.pb';
import { UnitConversionsService } from '@xprojectorcore/services/unit-conversions.service';
import { UnitConversion } from '@xprojectorcore/models/unit-conversion';
import { BmsAdminService } from '../../services/bms-admin-service';

@Component({
  selector: 'app-bms-customers-admin-tariffgroups',
  templateUrl: './bms-customers-admin-tariffgroups.component.html',
  styleUrls: ['./bms-customers-admin-tariffgroups.component.scss']
})
export class BmsCustomersAdminTariffgroupsComponent implements OnInit {

  @Input() customerId: string;
  @Input() customerXdbId : number;

  private bmsCustomerAdminClient: XProjectorBmsCustomerAdminClient = inject(XProjectorBmsCustomerAdminClient);
  private alertService: XprojAlertService = inject(XprojAlertService);
  private modalService: XprojModalService = inject(XprojModalService);
  private unitConversions: UnitConversionsService = inject(UnitConversionsService);
  private adminService: BmsAdminService = inject(BmsAdminService);

  loadingTariffGroups: boolean = false;

  selectedTariffGroup: TariffGroupInfo;
  newTariffGroup: TariffGroupInfo;
  tariffGroups: {tariffGroup : TariffGroupInfo, meterCount : number}[] = [];

  units : UnitConversion[] = [];

  constructor() { }

  ngOnInit() {
    this.updateTariffGroups();
  }

  async updateTariffGroups() {
    this.tariffGroups = [];
    let meters = await this.adminService.getMeters(this.customerXdbId, true, true);
    meters = meters.filter(x => x.tariffGroup?.length > 0);
    let tariffGroupInfos = await this.bmsCustomerAdminClient.getTariffGroups(this.customerId, false, true);
    tariffGroupInfos.forEach(x => {
      this.tariffGroups.push({tariffGroup: x, meterCount : meters.filter(meter => meter.tariffGroup == x.name).length});
    });

    if (this.units.length == 0) {
      this.units = await this.unitConversions.getUnitConversions();
    }
  }

  async saveTariffGroup() {
    this.newTariffGroup = null;
    if (this.selectedTariffGroup) {
      try {
        this.loadingTariffGroups = true;

        let result = await this.bmsCustomerAdminClient.updateTariffGroup(this.customerId, this.selectedTariffGroup);
        if (result) {
          this.alertService.success("Tariff group saved ok.");
        }
        else {
          this.alertService.error("Error save tariff group!");
        }
      }
      finally {
        this.loadingTariffGroups = false;
      }
    }
  }

  async addTariffGroup() {
    this.selectedTariffGroup = null;
    this.newTariffGroup = new TariffGroupInfo();
    this.newTariffGroup.unit = 'kWh';
    this.newTariffGroup.maxTariffPerUnit = 99;
  }

  async doAddTariffGroup() {
    if (this.newTariffGroup && this.newTariffGroup.name?.length > 0) {
      let result = await this.bmsCustomerAdminClient.createTariffGroup(this.customerId, this.newTariffGroup);
      if (result) {
        this.alertService.success("Tariff group added ok.");
        this.updateTariffGroups();
      }
      else {
        this.alertService.error("Error add tariff group!");
      }
      this.newTariffGroup = null;
    }
  }

  async deleteTariffGroup() {
    this.newTariffGroup = null;
    if (this.selectedTariffGroup) {
      let doDelete = await this.modalService.ShowConfirmModalAsync({
        header: 'Delete tariff group',
        description: 'Delete tariff group, are you sure?',
        ok: 'Delete',
        cancel: 'Cancel'
      });

      if (doDelete) {
        try {
          this.loadingTariffGroups = true;

          let result = await this.bmsCustomerAdminClient.deleteTariffGroup(this.customerId, this.selectedTariffGroup.id);
          if (result) {
            this.alertService.success("Tariff group deleted ok.");
            this.tariffGroups = this.tariffGroups.filter(x => x.tariffGroup.id != this.selectedTariffGroup.id);
            this.selectedTariffGroup = null;
          }
          else {
            this.alertService.error("Error delete tariff group!");
          }
        }
        finally {
          this.loadingTariffGroups = false;
        }
      }
    }
  }
}
