import { Component, OnInit, inject } from '@angular/core';
import { BmsDataCollectorViewData } from '@core/models/bms-data-collector-view-data';
import { DashboardOutputChangeParameters, XprojAlertService, ArrayUtils, DateHelper, XprojModalService } from 'xproj-lib';
import { BmsDataCollectorComponent } from '@features/bms/bms-admin/bms-data-collector-view.component';
import { XProjectorXConfClient } from '@xprojectorcore/xprojector_backend/xprojector-xconf-client';
import { FileInfo } from '@xprojectorcore/models/file-info';
import { XProjectorFilesClient } from '@xprojectorcore/xprojector_backend/xprojector-files-client';
import { GrpcNode } from '@xprojectorcore/xprojector_backend/proto/xprojector.grpc.models.pb';
import { BmsDataExportBotExecution } from '@features/bms/models/bms-data-export-bot';
import { XbotExecutionService } from '@xprojectorcore/services/xbot-execution.service';
import { StateService } from '@xprojectorcore/services/state-service';
import { NGXLogger } from 'ngx-logger';
import { XProjectorBmsCustomerAdminClient } from '@app/core/xprojector_backend/xprojector-bms-customeradmin-client';
import { GrpcMeter } from '@app/core/xprojector_backend/proto/xprojector.modulebms.customeradmin.pb';
import { BmsDataUtils } from '@features/bms/bms-admin/utils/bms-data-utils';

@Component({
  selector: 'app-bms-system-data-collector-view-data-collector-view',
  templateUrl: './bms-system-data-collector-view.component.html',
  styleUrls: ['./bms-system-data-collector-view.component.scss']
})
export class BmsSystemDataCollectorViewComponent implements OnInit, BmsDataCollectorComponent {

  private xconfClient: XProjectorXConfClient = inject(XProjectorXConfClient);
  private alertService: XprojAlertService = inject(XprojAlertService);
  public dateHelper: DateHelper = inject(DateHelper);
  private state: StateService = inject(StateService);
  private logger: NGXLogger = inject(NGXLogger);
  private modalService: XprojModalService = inject(XprojModalService);
  private bmsCustomerAdminClient: XProjectorBmsCustomerAdminClient = inject(XProjectorBmsCustomerAdminClient);

  public static NodeTypeId : string = '_x_bms_systems_system';

  data: BmsDataCollectorViewData;
  visible: boolean;

  responsiveWidth : number = 600;

  meterParameters : DashboardOutputChangeParameters[] = [];

  execActive : boolean = true;
  logssActive : boolean = false;
  metersActive : boolean = false;

  viewMeter : boolean = false;
  meterDashboardOutputParameters: DashboardOutputChangeParameters[] = [];

  dataExportBotExecution : BmsDataExportBotExecution = new BmsDataExportBotExecution();
  botRunning: boolean = false;
  execStatusMessage: string = '';

  loadingMeters: boolean = false;
  meters : GrpcMeter[] = [];
  meterTypes : string[] = []; //TODO

  constructor() { }

  ngOnInit(): void {
  }


  async initDataCollectorView() {
    this.responsiveWidth = this.data.width;

    this.updateMeters();
  }

  setWidth(width: number) {
    if (this.data) {
      this.data.width = width;
    }

    this.responsiveWidth = width;
  }

  async updateMeters() {
    try {
      this.loadingMeters = true;
      this.meterTypes = BmsDataUtils.getParamterValue(this.data.node, 'metertypes', this.dateHelper);
      this.meters = await this.bmsCustomerAdminClient.getMeters(this.data.customer.id, this.data.node.id, this.data.node.nodeTypeLabel,
         this.meterTypes, '', false);
    }
    finally {
      this.loadingMeters = false;
    }
  }

  displayMeter(meterId : string) {
    this.meterDashboardOutputParameters = [];

    let out = new DashboardOutputChangeParameters();
    out.outputParameterName = 'id';
    out.value = meterId;
    this.meterDashboardOutputParameters.push(out);

    this.viewMeter = true;
  }


}
