<div class="provision-area">
  <h2>Provision LoRaWAN devices</h2>

  <form clrForm clrLayout="vertical" autocomplete="off">

    <clr-select-container>
      <label class="clr-col-12">Select application</label>
      <select clrSelect class="clr-col-12" name="lorawanapplication" [(ngModel)]="selectedApplication">
        <option *ngFor="let application of applications" [ngValue]="application">{{application.name}}</option>
      </select>
    </clr-select-container>

    <clr-input-container>
      <label class="clr-col-12">LoRaWAN Device File</label>
      <input clrInput class="clr-col-12" name="file_file" #file type="file" multiple (change)="uploadFile(file.files)" />
    </clr-input-container>
  </form>

  <button type="button" class="btn btn-outline provision-button" [clrLoading]="lorawanState" [disabled]="files.length == 0"
  (click)="lorawanProvionDevices()">
  <clr-icon shape="plus-circle"></clr-icon>
  Provision devices...
</button>
</div>

<div *ngIf="provionResult">
  <table class="table table-vertical provisionresult-table">
    <caption>
      Provision result
    </caption>
    <tbody>
      <tr>
        <th class="left">Result</th>
        <td class="left">{{this.lorawanState == ClrLoadingState.LOADING ? 'Processing...' : (provionResult.ok ?
          'Success' : 'Error')}}</td>
      </tr>
      <tr *ngIf="!provionResult.ok">
        <th class="left">Message</th>
        <td class="left">{{provionResult.message}}</td>
      </tr>

      <tr *ngIf="provionResult.ok">
        <th class="left">Device count</th>
        <td class="left">{{provionResult.deviceCount}}</td>
      </tr>
      <tr *ngIf="provionResult.ok">
        <th class="left">Device provisioned count</th>
        <td class="left">{{provionResult.createdDeviceCount}}</td>
      </tr>
      <tr *ngIf="provionResult.ok">
        <th class="left">Device error or already provisioned count</th>
        <td class="left">{{provionResult.errorDeviceCount}}</td>
      </tr>
    </tbody>
  </table>

  <clr-accordion class="provisionresult-devices">
    <clr-accordion-panel>
      <clr-accordion-title>Device results</clr-accordion-title>
      <clr-accordion-content *clrIfExpanded>
        <table class="table table-vertical provisionresult-table">
          <caption>
            Device results
          </caption>
          <tbody>
            <tr *ngFor="let item of provionResult.deviceResults">
              <th class="left">{{item.devEui}}</th>
              <td class="left">{{item.ok ? 'Ok' : item.message}}</td>
            </tr>
          </tbody>
        </table>
      </clr-accordion-content>
    </clr-accordion-panel>
  </clr-accordion>

  <button type="button" class="btn btn-primary" (click)="downloadProvisionResult()">
    <clr-icon shape="download"></clr-icon>
    Download result
  </button>
</div>
