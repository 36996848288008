import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { BmsEventComponent } from './components/bms-event/bms-event.component';
import { BmsUnsubscribeEventsComponent } from './components/bms-unsubscribe-events/bms-unsubscribe-events.component';

const routes: Routes = [
  {
    path: 'unsubscribeevents/:customerid', component: BmsUnsubscribeEventsComponent,
  },
  {
      path: ':customerid/:eventid', component: BmsEventComponent,
      // children: [
      //      { path: ':id', component: BmsEventComponent },

      // ]
  },
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class BmsEventsRoutingModule { }
