import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { BmsBuildingAddress } from '@features/bms/models/bms-buildingaddress';
import { GrpcNode } from '@xprojectorcore/xprojector_backend/proto/xprojector.grpc.models.pb';
import { XProjectorXConfClient } from '@xprojectorcore/xprojector_backend/xprojector-xconf-client';
import { DateHelper, XprojAlertService, XProjectorClient } from 'xproj-lib';
import { BmsDataUtils } from '@features/bms/bms-admin/utils/bms-data-utils';

@Component({
  selector: 'app-bms-building-address-view',
  templateUrl: './bms-building-address-view.component.html',
  styleUrls: ['./bms-building-address-view.component.scss']
})
export class BmsBuildingAddressViewComponent implements OnInit {

  buildingAddress: BmsBuildingAddress = null;
  buildingAddressNode: GrpcNode;
  customerId : string;

  constructor(
    private router: Router,
    private xconfClient: XProjectorXConfClient,
    public dateHelper: DateHelper
  ) { }

  ngOnInit(): void {
  }

  async update(buildingAddressId: number, customerId : string) {
    this.customerId = customerId;
    if (buildingAddressId && buildingAddressId > 0) {
      this.buildingAddressNode = await this.xconfClient.getNode(buildingAddressId.toString(), '_x_bms_buildingaddress');
      if (this.buildingAddressNode) {
        this.buildingAddress = new BmsBuildingAddress();
        this.buildingAddressNode.propertyValues.forEach(p => {
          switch (p.key) {
            case 'street':
              this.buildingAddress.street = BmsDataUtils.getValue(p.valueType, p.value, p, this.dateHelper);
              break;
            case 'housenumber':
              this.buildingAddress.housenumber = BmsDataUtils.getValue(p.valueType, p.value, p, this.dateHelper);
              break;
            case 'postalcode':
              this.buildingAddress.postalcode = BmsDataUtils.getValue(p.valueType, p.value, p, this.dateHelper);
              break;
            case 'city':
              this.buildingAddress.city = BmsDataUtils.getValue(p.valueType, p.value, p, this.dateHelper);
              break;
            case 'country':
              this.buildingAddress.country = BmsDataUtils.getValue(p.valueType, p.value, p, this.dateHelper);
              break;
            case 'description':
              this.buildingAddress.description = BmsDataUtils.getValue(p.valueType, p.value, p, this.dateHelper);
              break;
            case 'latitude':
              this.buildingAddress.latitude = BmsDataUtils.getValue(p.valueType, p.value, p, this.dateHelper);
              break;
            case 'longitude':
              this.buildingAddress.longitude = BmsDataUtils.getValue(p.valueType, p.value, p, this.dateHelper);
              break;
            case 'modifiedat':
              this.buildingAddress.modifiedAt = BmsDataUtils.getValue(p.valueType, p.value, p, this.dateHelper);
              break;
            case 'createdat':
              this.buildingAddress.createdAt = BmsDataUtils.getValue(p.valueType, p.value, p, this.dateHelper);
              break;
          }
        });
      }
    }
    else {
      this.buildingAddress = null;
    }
  }

  async viewInBMS() {
    this.router.navigateByUrl('/bmsadmin/customers/' + this.customerId + '/bms/' + this.buildingAddressNode.id + '/' + this.buildingAddressNode.nodeTypeLabel);
  }
}
