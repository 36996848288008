import { ChangeDetectorRef, Component, OnInit, QueryList, ViewChildren } from '@angular/core';
import { TreeNode } from '@xprojectorcore/models/tree-node';
import { XbotExecutionResult } from '@xprojectorcore/models/xbot-execution-queue-item';
import { StateService } from '@xprojectorcore/services/state-service';
import { XbotExecutionService } from '@xprojectorcore/services/xbot-execution.service';
import { GrpcDataSourceInstance, GrpcNodeType } from '@xprojectorcore/xprojector_backend/proto/xprojector.xconf.pb';
import { XProjectorXConfClient } from '@xprojectorcore/xprojector_backend/xprojector-xconf-client';
import { SplitAreaDirective } from 'angular-split';
import { NGXLogger } from 'ngx-logger';
import { DateHelper, XprojAlertService } from 'xproj-lib';
import { BmsQaBotConfig, BmsQaBotExecution } from '@features/bms/models/bms-qa-bot';

@Component({
  selector: 'app-bms-bots-admin',
  templateUrl: './bms-bots-admin.component.html',
  styleUrls: ['./bms-bots-admin.component.scss']
})
export class BmsBotsAdminComponent implements OnInit {

  @ViewChildren(SplitAreaDirective) areasEl: QueryList<SplitAreaDirective>

  rightPanelWidth: number = 600;

  botsDataSourceInstance: GrpcDataSourceInstance;

  selectedBotNode: { treeNode: TreeNode, nodeType: GrpcNodeType, botServiceId?: string } = null;

  qaBotConfig: BmsQaBotConfig = null;
  qaBotExecConfig = new BmsQaBotExecution();
  showEditQaBotExecProperties: boolean = false;
  qabotOverrideUtcFromString: string;
  qabotOverrideUtcToString: string;
  qabotToEnabled: boolean = false;

  botRunning: boolean = false;
  execStatusMessage: string = '';

  constructor(
    private state: StateService,
    private xConfClient: XProjectorXConfClient,
    private alertService: XprojAlertService,
    private xbotExecutionService: XbotExecutionService,
    private logger: NGXLogger,
    private cdr: ChangeDetectorRef,
    private dateHelper: DateHelper
  ) { }

  async ngOnInit() {
    let lsrightPanelWidth = Number.parseInt(localStorage.getItem("xprojector-bmsbotsadminrightPanelWidth") || this.rightPanelWidth.toString());
    if (lsrightPanelWidth != this.rightPanelWidth) {
      this.rightPanelWidth = lsrightPanelWidth;
    }

    await this.initBotsDatasource();
  }

  async initBotsDatasource() {
    if (!this.botsDataSourceInstance) {
      this.botsDataSourceInstance = await this.xConfClient.getDataSourceInstance('_x_botservices_datasource');
    }
  }

  async onSplitDragEnd($event) {
    let treePaneArea = this.getPaneArea(2);

    if (treePaneArea) {
      this.rightPanelWidth = treePaneArea.elRef.nativeElement.clientWidth;
      localStorage.setItem("xprojector-bmsbotsadminrightPanelWidth", this.rightPanelWidth.toString());
    }

  }

  getPaneArea(order: number): SplitAreaDirective {
    let result: SplitAreaDirective = undefined;
    this.areasEl.forEach(area => {
      if (area.order == order) {
        result = area;
      }
    });

    return result;
  }

  async onTreeNodeSelect(item: { treeNode: TreeNode, nodeType: GrpcNodeType }) {
    if (item?.nodeType.label == '_x_botservices_bot') {
      this.selectedBotNode = item;
      await this.updateQaConfig();
    }
    else {
      this.selectedBotNode = null;
    }

  }

  async updateQaConfig() {
    if (this.selectedBotNode) {
      let botConfigNode = await this.xConfClient.getNode(this.selectedBotNode.treeNode.id, this.selectedBotNode.nodeType.label);

      if (botConfigNode) {
        this.qaBotConfig = new BmsQaBotConfig();
        this.qaBotConfig.nodeId = this.selectedBotNode.treeNode.id;
        this.qaBotConfig.name = this.selectedBotNode.treeNode.name;
        botConfigNode.propertyValues.forEach(p => {
          switch (p.key) {
            case 'botserviceid':
              this.selectedBotNode.botServiceId = p.value;
              break;
            case 'enabled':
              this.qaBotConfig.enabled = p.value.toLowerCase() == 'true';
              break;
            case 'schedule':
              this.qaBotConfig.schedule = p.value;
              break;
            case 'relativefromdays':
              this.qaBotConfig.relativeFromDays = +p.value;
              break;
            case 'maxconcurrenttasks':
              this.qaBotConfig.maxConcurrentTasks = +p.value;
              break;
            case 'ignoreinterpolatedvalues':
              this.qaBotConfig.ignoreInterpolatedValues = p.value.toLowerCase() == 'true';
              break;
            case 'errormetersprojectionid':
              this.qaBotConfig.errorMetersProjectionId = p.value;
              break;
          }
        });
      }
    }
  }

  async executQaBot() {
    if (this.qaBotConfig) {
      this.qaBotExecConfig = new BmsQaBotExecution();
      this.qaBotExecConfig.configNodeId = this.qaBotConfig.nodeId;
      this.qaBotExecConfig.ignoreInterpolatedValues = this.qaBotConfig.ignoreInterpolatedValues;
      this.qaBotExecConfig.relativeFromDays = this.qaBotConfig.relativeFromDays;
      this.qaBotExecConfig.maxConcurrentTasks = this.qaBotConfig.maxConcurrentTasks;
      this.qaBotExecConfig.errorMetersProjectionId = this.qaBotConfig.errorMetersProjectionId;
      this.qaBotExecConfig.overrideUtcFrom = new Date();
      this.qaBotExecConfig.overrideUtcTo = this.dateHelper.utils.addDays(this.qaBotExecConfig.overrideUtcFrom, 1);
      this.qabotOverrideUtcFromString = this.dateHelper.utils.formatByString(this.qaBotExecConfig.overrideUtcFrom, 'yyyy-MM-dd');
      this.qabotOverrideUtcToString = this.dateHelper.utils.formatByString(this.qaBotExecConfig.overrideUtcTo, 'yyyy-MM-dd');

      this.showEditQaBotExecProperties = true;
    }
  }

  async onCloseEditQaBotProperties() {
    if (this.qaBotExecConfig) {
      this.qaBotExecConfig.overrideUtcFrom = this.dateHelper.utils.parse(this.qabotOverrideUtcFromString, 'yyyy-MM-dd');
      this.qaBotExecConfig.overrideUtcFrom = this.dateHelper.utils.addMinutes(this.qaBotExecConfig.overrideUtcFrom, -this.qaBotExecConfig.overrideUtcFrom.getTimezoneOffset());
      if (this.qabotToEnabled) {
        this.qaBotExecConfig.overrideUtcTo = this.dateHelper.utils.parse(this.qabotOverrideUtcToString, 'yyyy-MM-dd');
        this.qaBotExecConfig.overrideUtcTo = this.dateHelper.utils.addMinutes(this.qaBotExecConfig.overrideUtcTo, -this.qaBotExecConfig.overrideUtcTo.getTimezoneOffset());
      }
      else {
        this.qaBotExecConfig.overrideUtcTo = null;
      }

      this.executeBot('bms_qa', this.qaBotExecConfig, 1800, 100, (result, message) => {
        this.logger.info(message);
      });
    }

  }

  async executeSelectedBot() {
    if (this.selectedBotNode) {
      switch (this.selectedBotNode.nodeType.label) {
        case '_x_botservices_bot':
          await this.executQaBot();
          break;
      }

    }
  }

  async executeBot(botId: string, botExecConfig: any, timeout: number, priority: number, callback: (result: XbotExecutionResult, message: string) => void) {
    this.botRunning = true;
    this.xbotExecutionService.executeBot(botId, JSON.stringify(botExecConfig), timeout, priority, 'botsadmin', this.state.userId).subscribe(
      result => {
        if (result.result == XbotExecutionResult.STATUS) {
          this.execStatusMessage = result.message;
          if (callback) {
            callback(result.result, result.message);
          }
        }
        else if (result.result == XbotExecutionResult.OK) {
          this.alertService.info(result.message);
          if (callback) {
            callback(result.result, result.message);
          }
        }
        else if (result.result == XbotExecutionResult.CANCELED) {
          this.alertService.error('Execution canceled.');
          if (callback) {
            callback(result.result, result.message);
          }
        }
        else {
          this.alertService.error(result.message);
          if (callback) {
            callback(result.result, result.message);
          }
        }
      },
      error => {
        this.botRunning = false;
        this.alertService.error(error.message);
        if (callback) {
          callback(XbotExecutionResult.ERROR, error.message);
        }
      },
      () => {
        this.execStatusMessage = '';
        this.botRunning = false;
      }
    )
  }

}
