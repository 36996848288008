import { NgModule, Optional, SkipSelf } from "@angular/core";
import { GRPC_WORKER } from "@ngx-grpc/worker-client";
import { BmsAdminModule } from './bms-admin/bms-admin.module';
import { XProjectorBmsExportClient } from '@core/xprojector_backend/xprojector-bms-export-client';
import { XProjectorBmsEventsClient } from '@core/xprojector_backend/xprojector-bms-events-client';
import { XProjectorBmsCustomerAdminClient } from '@core/xprojector_backend/xprojector-bms-customeradmin-client';
import { XProjectorBmsThingsboardClient } from '@core/xprojector_backend/xprojector-bms-thingsboard-client';
import { BmsEventsModule } from "./bms-events/bms-events.module";
import { BmsCustomerModule } from "./bms-customer/bms-customer.module";
import { XProjectorBmsLoRaWANClient } from '@core/xprojector_backend/xprojector-bms-lorawan-client';

@NgModule({
  declarations: [

  ],
  imports: [
    BmsAdminModule,
    BmsEventsModule,
    BmsCustomerModule
  ],
  providers: [
    XProjectorBmsExportClient,
    XProjectorBmsEventsClient,
    XProjectorBmsCustomerAdminClient,
    XProjectorBmsThingsboardClient,
    XProjectorBmsLoRaWANClient
  ]
})
export class BmsModule {

}
