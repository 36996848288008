import { Injectable } from '@angular/core';
import { BmsResidentExchange } from '@features/bms/models/bms-resident-exchange';
import { NGXLogger } from 'ngx-logger';
import { ArrayUtils, BaseQuery, BaseQueryInputColumnDescription, ColumnFilteringNumerical, ColumnFilteringString, ColumnFilteringTimestamp, FilterComparator, FilterLogicalGroup, FilterLogicalGroupType, ProjectionColumnDescription, SetDataColumnDescription, SetDataQuery, XDataType, XProjectorClient } from 'xproj-lib';
import { flakeId } from '../utils/bms-data-utils';

export interface BmsResidentExchangeParameters {
  customerId? : string,
  apartmentId?: number,
  facilityId?: number,
  exchangeDateAfter?: Date,
  maxItems?: number
}

const RESIDENT_EXCHANGE_PROJECTIONID: string = 'hs-residentexchange';

@Injectable({
  providedIn: 'root'
})
export class BmsResidentExchangeService {

  residentExchangeColumns: ProjectionColumnDescription[] = [];

  private setColumns: SetDataColumnDescription[] = [
    {
      columnname: 'id',
      datatype: XDataType.Int64,
      indexintypedvector: 0
    },
    {
      columnname: 'customerid',
      datatype: XDataType.String,
      indexintypedvector: 0
    },
    {
      columnname: 'apartmentid',
      datatype: XDataType.Int64,
      indexintypedvector: 1
    },
    {
      columnname: 'facilityid',
      datatype: XDataType.Int64,
      indexintypedvector: 2
    },
    {
      columnname: 'createdat',
      datatype: XDataType.Timestamp,
      indexintypedvector: 0
    },
    {
      columnname: 'modifiedat',
      datatype: XDataType.Timestamp,
      indexintypedvector: 1
    },
    {
      columnname: 'deleted',
      datatype: XDataType.UInt8,
      indexintypedvector: 3
    },
    {
      columnname: 'deletedat',
      datatype: XDataType.Timestamp,
      indexintypedvector: 2
    },
    {
      columnname: 'exchangedate',
      datatype: XDataType.Timestamp,
      indexintypedvector: 3
    }
  ];

  constructor(
    private xprojClient: XProjectorClient,
    private logger: NGXLogger
  ) {

  }

  async setResidentExchanges(residentExchanges: BmsResidentExchange[]): Promise<boolean> {
    let query: SetDataQuery = new SetDataQuery();

    await ArrayUtils.AsyncForEach(residentExchanges, async (residentExchange) => {
      if (!residentExchange.id || residentExchange.id == 0) {
        residentExchange.id = await flakeId.nextId();
      }
    });

    query.datastrings = [
      residentExchanges.map(t => t.customerId),
    ];
    query.datanumbers = [
      residentExchanges.map(t => t.id),
      residentExchanges.map(t => t.apartmentId ?? 0),
      residentExchanges.map(t => t.facilityId ?? 0),
      residentExchanges.map(t => t.deleted ? 1 : 0),
    ];
    query.datatimestamps = [
      residentExchanges.map(t => t.createdAt ?? new Date()),
      residentExchanges.map(t => t.modifiedAt ?? new Date()),
      residentExchanges.map(t => t.deletedAt ?? new Date(0)),
      residentExchanges.map(t => t.exchangeDate ?? new Date())
    ];

    query.projectionid = RESIDENT_EXCHANGE_PROJECTIONID;
    query.columns = this.setColumns;

    return await this.xprojClient.RequestSetData(query);
  }

  async getResidenExchanges({
    customerId = undefined,
    apartmentId = undefined,
    facilityId = undefined,
    exchangeDateAfter = undefined,
    maxItems = 10
  }: BmsResidentExchangeParameters): Promise<BmsResidentExchange[]> {

    let result: BmsResidentExchange[] = [];
    if (customerId && (apartmentId || facilityId)) {
      if (this.residentExchangeColumns.length == 0) {
        this.residentExchangeColumns = await this.xprojClient.RequestListQueryableProjectionColumns(RESIDENT_EXCHANGE_PROJECTIONID, '', 0, 100);
      }

      let query: BaseQuery = new BaseQuery();
      query.targetprojectionid = RESIDENT_EXCHANGE_PROJECTIONID;
      query.maxitems = maxItems;
      query.targetgroup = [];

      let filterId = 0;
      query.filter.type = FilterLogicalGroupType.AND;

      if (apartmentId) {
        let idFiltering = new ColumnFilteringNumerical();
        idFiltering.columnname = 'apartmentid';
        idFiltering.comparator = FilterComparator.Equals;
        idFiltering.value = apartmentId;
        idFiltering.queryfilterid = ++filterId;
        query.filter.filters.push(idFiltering.queryfilterid);
        query.numericalfilters.push(idFiltering);
      }
      else if (facilityId) {
        let idFiltering = new ColumnFilteringNumerical();
        idFiltering.columnname = 'facilityid';
        idFiltering.comparator = FilterComparator.Equals;
        idFiltering.value = facilityId;
        idFiltering.queryfilterid = ++filterId;
        query.filter.filters.push(idFiltering.queryfilterid);
        query.numericalfilters.push(idFiltering);
      }

      if (exchangeDateAfter) {
        let timestampFiltering = new ColumnFilteringTimestamp();
        timestampFiltering.columnname = 'exchangedate';
        timestampFiltering.comparator = FilterComparator.GreatherThanOrEquals;
        timestampFiltering.value = exchangeDateAfter;
        timestampFiltering.queryfilterid = ++filterId;
        query.filter.filters.push(timestampFiltering.queryfilterid);
        query.timestampfilters.push(timestampFiltering);
      }

      let deletedFiltering = new ColumnFilteringNumerical();
      deletedFiltering.columnname = 'deleted';
      deletedFiltering.comparator = FilterComparator.Equals;
      deletedFiltering.value = 0;
      deletedFiltering.queryfilterid = ++filterId;
      query.filter.filters.push(deletedFiltering.queryfilterid);
      query.numericalfilters.push(deletedFiltering);

      this.residentExchangeColumns.filter(c => c.columnname != 'customerid').forEach(c => {
        let inCol = new BaseQueryInputColumnDescription();
        inCol.columnname = c.columnname;
        inCol.columnoutname = c.columnname;
        query.columns.push(inCol);
      });

      query.sorting.columnname = 'exchangedate';
      query.sorting.descending = true;

      query.targetgroup = [customerId];

      try {
        let queryResult = await this.xprojClient.RequestQueryBaseQuery(query, true);

        let numericaldata = queryResult.datanumbers;
        let timestampdata = queryResult.datatimestamps;
        let stringdata = queryResult.datastrings;

        let rowCount = 0;
        if (numericaldata.length > 0) {
          rowCount = numericaldata[0].length;
        }

        for (let row = 0; row < rowCount; row++) {
          let residentExchange = new BmsResidentExchange();
          residentExchange.customerId = customerId;

          for (let i = 0; i < queryResult.columns.length; i++) {
            let it = queryResult.columns[i];
            let typ = it.datatype;
            let data = [];
            if (typ == XDataType.Number) {
              data = numericaldata[it.indexintypedvector];
            }
            if (typ == XDataType.String) {
              data = stringdata[it.indexintypedvector];
            }
            if (typ == XDataType.Timestamp) {
              data = timestampdata[it.indexintypedvector];
            }

            switch (it.columnoutname) {
              case 'id':
                residentExchange.id = data[row];
                break;
              case 'apartmentid':
                residentExchange.apartmentId = data[row];
                break;
              case 'facilityid':
                residentExchange.facilityId = data[row];
                break;
              case 'exchangedate':
                residentExchange.exchangeDate = data[row];
                break;
              case 'createdat':
                residentExchange.createdAt = data[row];
                break;
              case 'modifiedat':
                residentExchange.modifiedAt = data[row];
                break;
            }
          }

          result.push(residentExchange);
        }
      }
      catch (err) {
        this.logger.error(err);
      }
    }
    return result;
  }

  async deleteResidentExchange(residentExchange : BmsResidentExchange) : Promise<boolean> {
    residentExchange.deleted = true;
    residentExchange.deletedAt = new Date();

    return this.setResidentExchanges([residentExchange]);
  }
}
