import { ChangeDetectorRef, Component, OnInit } from '@angular/core';
import { BmsBillingPeriod, BmsMonthOfYear } from '@core/models/bms-export-bot';
import { GetExportInfoResponse } from '@core/xprojector_backend/proto/xprojector.modulebms.export.pb';
import { XProjectorBmsExportClient } from '@core/xprojector_backend/xprojector-bms-export-client';
import { Timestamp } from '@xprojectorcore/xprojector_backend/proto/google/protobuf/timestamp.pb';
import { NGXLogger } from 'ngx-logger';
import { threadId } from 'worker_threads';
import { DateHelper } from 'xproj-lib';
import * as XLSX from 'xlsx';

@Component({
  selector: 'app-bms-trustee-overview',
  templateUrl: './bms-trustee-overview.component.html',
  styleUrls: ['./bms-trustee-overview.component.scss']
})
export class BmsTrusteeOverviewComponent implements OnInit {

  exportPeriods : { start: Date, end: Date }[] = [];
  exportPeriodsSpecial : { start: Date, end: Date }[] = [];
  selectedExportPeriod : number = 1;
  selectedBillingPeriod : BmsBillingPeriod = BmsBillingPeriod.Month;
  generatingExportReport : boolean = false;

  selectedExportPeriodSpecial : number = 1;
  selectedBillingPeriodSpecial : BmsBillingPeriod = BmsBillingPeriod.Month;

  exportInfo : {timestamp : Date, data : GetExportInfoResponse};
  exportSpecialInfo : {timestamp : Date, data : GetExportInfoResponse, start : Date, end : Date};

  reportActive : boolean = true;
  trusteeCustomerActive : boolean = false;

  BmsBillingPeriod = BmsBillingPeriod;

  constructor(
    private logger: NGXLogger,
    private bmsExportClient : XProjectorBmsExportClient,
    private dateHelper: DateHelper,
    private cdr: ChangeDetectorRef,
  ) { }

  async ngOnInit() {
    this.exportPeriods = this.getExportPeriods(BmsBillingPeriod.Month, BmsMonthOfYear.March);
    this.exportPeriodsSpecial = this.getExportPeriods(BmsBillingPeriod.Month, BmsMonthOfYear.March);
    this.selectedExportPeriod = Number.parseInt(localStorage.getItem("xprojector-bmstrustee-selectedexportperiod") || "1");
    this.selectedBillingPeriod = BmsBillingPeriod[localStorage.getItem("xprojector-bmstrustee-selectedbillingperiod") || "Month"];
    let exportInfoJson = localStorage.getItem("xprojector-bmstrustee-exportinfo");
    if (exportInfoJson?.length > 0) {
      this.exportInfo = JSON.parse(exportInfoJson);
      this.exportInfo.timestamp = new Date(this.exportInfo.timestamp);

      //if (this.dateHelper.utils.addMinutes(this.exportInfo.timestamp, 5) < new Date()) {
        // setTimeout(async () => {
        //   try {
        //     this.generatingExportReport = true;
        //     let result = await this.bmsExportClient.getExportInfo(this.selectedBillingPeriod,
        //       this.dateHelper.utils.addMinutes(this.exportPeriods[this.selectedExportPeriod].start, -this.exportPeriods[this.selectedExportPeriod].start.getTimezoneOffset()),
        //       this.dateHelper.utils.addMinutes(this.exportPeriods[this.selectedExportPeriod].end, -this.exportPeriods[this.selectedExportPeriod].end.getTimezoneOffset())
        //     );
        //     this.exportInfo = {timestamp : result.timestamp.toDate(), data : result};
        //     localStorage.setItem("xprojector-bmstrustee-exportinfo", JSON.stringify(this.exportInfo));
        //   }
        //   finally {
        //     this.generatingExportReport = false;
        //   }
        // });
      //}
    }
    this.selectedExportPeriodSpecial = Number.parseInt(localStorage.getItem("xprojector-bmstrustee-selectedexportperiod-special") || "1");
    this.selectedBillingPeriodSpecial = BmsBillingPeriod[localStorage.getItem("xprojector-bmstrustee-selectedbillingperiod-special") || "Month"];
    let exportInfoJsonSpecial = localStorage.getItem("xprojector-bmstrustee-exportinfo-special");
    if (exportInfoJsonSpecial?.length > 0) {
      this.exportSpecialInfo = JSON.parse(exportInfoJsonSpecial);
      this.exportSpecialInfo.timestamp = new Date(this.exportSpecialInfo.timestamp);
      this.exportSpecialInfo.start = new Date(this.exportSpecialInfo.start);
      this.exportSpecialInfo.end = new Date(this.exportSpecialInfo.end);
    }
  }

  formatByString(date: Date, format: string, displayUTC: boolean = false): string {
    let d = displayUTC ? this.dateHelper.utils.addMinutes(date, date.getTimezoneOffset()) : date;
    return this.dateHelper.utils.formatByString(d, format);
  }

  getExportPeriods(billingPeriod: BmsBillingPeriod, startMonth: BmsMonthOfYear): { start: Date, end: Date }[] {
    let result = [];
    let now = new Date();

    switch (billingPeriod ?? BmsBillingPeriod.Month) {
      case BmsBillingPeriod.Week:
        let startOfWeek = this.dateHelper.utils.startOfWeek(now);
        let endOfWeek = this.dateHelper.utils.endOfWeek(now);
        for (let i = 0; i < 13; i++) {
          result.push({ start: this.dateHelper.utils.addWeeks(startOfWeek, -i), end: this.dateHelper.utils.addWeeks(endOfWeek, -i) });
        }
        break;

      case BmsBillingPeriod.Month:
        for (let i = 0; i < 13; i++) {
          let month = this.dateHelper.utils.addMonths(now, -i);
          let startOfMonth = this.dateHelper.utils.startOfMonth(month);
          let endOfMonth = this.dateHelper.utils.endOfMonth(month);
          result.push({ start: startOfMonth, end: endOfMonth });
        }
        break;

      case BmsBillingPeriod.Quarter:
        let month = this.dateHelper.utils.getMonth(now);
        let offset = 0 + ((month + 4 - (1 + ((startMonth - 1) % 3))) % 3);

        for (let i = 0; i < 13; i++) {
          let monthStart = this.dateHelper.utils.addMonths(now, -((i * 3) + offset));
          let monthEnd = this.dateHelper.utils.addMonths(now, -((i * 3) + offset) + 2);
          let startOfMonth = this.dateHelper.utils.startOfMonth(monthStart);
          let endOfMonth = this.dateHelper.utils.endOfMonth(monthEnd);
          result.push({ start: startOfMonth, end: endOfMonth });
        }
        break;

      default:
        for (let i = 1; i < 13; i++) {
          let month = this.dateHelper.utils.addMonths(now, -i);
          let startOfMonth = this.dateHelper.utils.startOfMonth(month);
          let endOfMonth = this.dateHelper.utils.endOfMonth(month);
          result.push({ start: startOfMonth, end: endOfMonth });
        }
        break;
    }

    return result;
  }

  selectedBillingPeriodChange() {
    this.exportPeriods = this.getExportPeriods(this.selectedBillingPeriod, BmsMonthOfYear.March);
    this.selectedExportPeriod = 1;
  }

  selectedBillingPeriodSpecialChange() {
    this.exportPeriodsSpecial = this.getExportPeriods(this.selectedBillingPeriodSpecial, BmsMonthOfYear.March);
    this.selectedExportPeriodSpecial = 1;
  }

  async onViewReport() {
    try {
      this.generatingExportReport = true;
      this.exportInfo = undefined;
      this.cdr.detectChanges();
      let result = await this.bmsExportClient.getExportInfo(this.selectedBillingPeriod,
          this.dateHelper.utils.addMinutes(this.exportPeriods[this.selectedExportPeriod].start, -this.exportPeriods[this.selectedExportPeriod].start.getTimezoneOffset()),
          this.dateHelper.utils.addMinutes(this.exportPeriods[this.selectedExportPeriod].end, -this.exportPeriods[this.selectedExportPeriod].end.getTimezoneOffset())
      );

      this.exportInfo = {timestamp : result.timestamp.toDate(), data : result};

      localStorage.setItem("xprojector-bmstrustee-exportinfo", JSON.stringify(this.exportInfo));
      localStorage.setItem("xprojector-bmstrustee-selectedexportperiod", this.selectedExportPeriod.toString());
      localStorage.setItem("xprojector-bmstrustee-selectedbillingperiod", BmsBillingPeriod[this.selectedBillingPeriod]);
      this.logger.info('exportInfo', this.exportInfo);
    }
    finally {
      this.generatingExportReport = false;
    }
  }

  async onViewSpecialReport() {
    try {
      this.generatingExportReport = true;
      this.exportSpecialInfo = undefined;
      this.cdr.detectChanges();
      let result = await this.bmsExportClient.getExportInfo(this.selectedBillingPeriodSpecial,
          this.dateHelper.utils.addMinutes(this.exportPeriodsSpecial[this.selectedExportPeriod].start, -this.exportPeriodsSpecial[this.selectedExportPeriod].start.getTimezoneOffset()),
          this.dateHelper.utils.addMinutes(this.exportPeriodsSpecial[this.selectedExportPeriod].end, -this.exportPeriodsSpecial[this.selectedExportPeriod].end.getTimezoneOffset()),
          15, true
      );

      this.exportSpecialInfo = {timestamp : result.timestamp.toDate(), data : result, start : result.start.toDate(), end : result.end.toDate() };

      localStorage.setItem("xprojector-bmstrustee-exportinfo-special", JSON.stringify(this.exportSpecialInfo));
      localStorage.setItem("xprojector-bmstrustee-selectedexportperiod-special", this.selectedExportPeriodSpecial.toString());
      localStorage.setItem("xprojector-bmstrustee-selectedbillingperiod-special", BmsBillingPeriod[this.selectedBillingPeriodSpecial]);

      this.logger.info('exportSpecialInfo', this.exportSpecialInfo);
    }
    finally {
      this.generatingExportReport = false;
    }
  }

  exportSpecialReport() {
    if (this.exportSpecialInfo) {
        let rows = [];
        var headers: string[] = ['Trustee', 'Customer', 'Signed', 'Invoiced', 'After15', 'InvoiceInfo'];

        this.exportSpecialInfo.data.trustees.forEach(trustee => {
          trustee.customers.forEach(customer => {
            rows.push({ Trustee : trustee.name, Customer : customer.name, Signed : customer.signed ? 'yes' : 'no', Invoiced : customer.invoiced ? 'yes' : 'no', After15 : (customer.invoiceDay >= 15) ? 'yes' : 'no', InvoiceInfo : customer.invoiceInfo });
          });
        });

        const ws: XLSX.WorkSheet = XLSX.utils.json_to_sheet(rows, { header: headers });

        const wb: XLSX.WorkBook = XLSX.utils.book_new();
        XLSX.utils.book_append_sheet(wb, ws, 'ExportInfo');
        try {
          XLSX.writeFile(wb, 'ExportSpecialReport_' + this.formatByString(this.exportSpecialInfo.start, 'yyyyMMdd') + '_' +
          this.formatByString(this.exportSpecialInfo.end, 'yyyyMMdd') + '.xlsx');
        }
        catch {
          XLSX.writeFile(wb, 'ExportSpecialReport.xlsx');
        }
    }
  }
}
