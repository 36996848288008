import { Component, Input, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { BmsCustomerConfig } from '@app/features/bms/models/bms-customer-config';
import { EventHistory, Event } from '@core/xprojector_backend/proto/xprojector.modulebms.events.pb';
import { XProjectorBmsEventsClient } from '@core/xprojector_backend/xprojector-bms-events-client';
import { NGXLogger } from 'ngx-logger';
import { map } from 'rxjs/operators';
import { DateHelper, XprojAlertService, XprojModalService } from 'xproj-lib';
import { BmsAdminService } from '../../../services/bms-admin-service';
import { BmsCustomerFlag } from '@app/features/bms/models/bms-customer-flag';
import { XProjectorBmsCustomerAdminClient } from '@app/core/xprojector_backend/xprojector-bms-customeradmin-client';
import { BmsGatewayInfo } from '@app/features/bms/models/bms-gateway-info';

@Component({
  selector: 'app-bms-gateway-events-history',
  templateUrl: './bms-gateway-events-history.component.html',
  styleUrls: ['./bms-gateway-events-history.component.scss']
})
export class BmsGatewayEventsHistoryComponent implements OnInit {

  @Input() eventsCustomerId: string = '';
  @Input() gatewayId: number = -1;
  @Input() customerId: string = '';
  @Input() eventObjectId: string = '';
  @Input() event : Event;

  events : EventHistory[] = [];
  bmsCustomer: BmsCustomerConfig;
  loading : boolean = false;
  editMode : boolean = false;
  copyOperationInfo : string = '';
  bmsGatewayInfo : BmsGatewayInfo;

  gatewayOfflineFlag : BmsCustomerFlag;

  constructor(
    private route: ActivatedRoute,
    private eventsClient: XProjectorBmsEventsClient,
    private logger: NGXLogger,
    private adminService: BmsAdminService,
    private modalService: XprojModalService,
    private alertService: XprojAlertService,
    private bmsCustomerAdminClient: XProjectorBmsCustomerAdminClient,
    public dateHelper: DateHelper,
    private router: Router,
  ) { }

  ngOnInit(): void {
    this.route.params.pipe(map(p => p.customerid)).subscribe(async (customerId) => {
      if (customerId) {
        this.logger.info('ngOnInit customerId', customerId);
        this.customerId = customerId;
      }
    });
    this.route.params.pipe(map(p => p.eventid)).subscribe(async (eventId) => {
      if (eventId) {
        this.eventObjectId = eventId;
      }
    });

    this.update();
  }

  async update() {
    await this.updateEventsHistory();
    await this.updateMailInfo();
  }

  async updateEventsHistory() {
    try {
      this.loading = true;
      this.events = await this.eventsClient.getEventsHistory(this.eventsCustomerId, this.eventObjectId, null, null, 10);
      this.bmsCustomer = await this.adminService.getCustomerConfig(this.customerId);
      this.bmsGatewayInfo = await this.adminService.getGatewayInfo(this.gatewayId);
    }
    finally {
      this.loading = false;
    }
  }

  async updateMailInfo() {
    this.gatewayOfflineFlag = await this.adminService.getCustomerFlag(this.customerId, 'gatewayofflinemail', this.gatewayId);
    if (this.gatewayOfflineFlag) {
      var lastNewEvent = this.events.find(x => x.active && !x.acknowledged);
      if (lastNewEvent && lastNewEvent.timestamp.toDate() > this.gatewayOfflineFlag.modifiedAt) {
        this.gatewayOfflineFlag.status = 0;
      }
    }
    else {
      this.gatewayOfflineFlag = new BmsCustomerFlag();
      this.gatewayOfflineFlag.customerId = this.customerId;
      this.gatewayOfflineFlag.flagId = 'gatewayofflinemail';
      this.gatewayOfflineFlag.status = 0;
      this.gatewayOfflineFlag.nodeId = this.gatewayId;
    }
  }

  editOperationInfo() {
    if (this.bmsCustomer) {
      this.copyOperationInfo = this.bmsCustomer.operationInfo;
      this.editMode = true;
    }
  }

  async saveBmsCustomer() {
    if (this.bmsCustomer) {
      this.bmsCustomer.operationInfo = this.copyOperationInfo;
      let result = await this.adminService.saveCustomerConfigInfos(this.bmsCustomer);

      if (result.result) {
        this.editMode = false;
      }
    }
  }

  async sendGatewayOfflineMail() {
    if (this.gatewayOfflineFlag) {
      let sendMail = true;
      if (this.gatewayOfflineFlag.status > 0) {
        sendMail = await this.modalService.ShowConfirmModalAsync({
          header: 'Send gateway offline mail',
          description: 'Gateway offline mail already sent, send again?',
          ok: 'Send',
          cancel: 'Cancel'
        });
      }
      else {
        sendMail = await this.modalService.ShowConfirmModalAsync({
          header: 'Send gateway offline mail',
          description: 'Send customer gateway offline mail, are you sure?',
          ok: 'Send',
          cancel: 'Cancel'
        });
      }

      if (sendMail) {
        let result = await this.bmsCustomerAdminClient.sendCustomerMail(this.customerId, true, 'gatewayofflinemail', this.gatewayId + '');
        if (result.ok) {
          this.gatewayOfflineFlag = await this.adminService.getCustomerFlag(this.customerId, 'gatewayofflinemail', this.gatewayId);
          let info : string[] = ['Customer gateway offline mail sent to:'];
          info = info.concat(result.sentTo);

          this.modalService.ShowConfirmModal({
            header: 'Customer gateway offline mail',
            description: info,
            showCancel: false
          }, (result) => { });
        }
        else {
          this.alertService.error('Customer gateway offline mail error: ' + result.message);
        }
      }
    }
  }

  async sendGatewayOfflineMailDebug() {
    if (this.gatewayOfflineFlag) {
      let result = await this.bmsCustomerAdminClient.sendCustomerMail(this.customerId, true, 'gatewayofflinemail_debug', this.gatewayId + '');
      if (result.ok) {
        let info : string[] = ['Customer gateway offline debugmail sent to:'];
        info = info.concat(result.sentTo);

        this.modalService.ShowConfirmModal({
          header: 'Customer gateway offline debugmail',
          description: info,
          showCancel: false
        }, (result) => { });
      }
      else {
        this.alertService.error('Customer gateway offline mail error: ' + result.message);
      }
    }
  }

  async viewCustomer() {
    window.open('/bmsadmin/customers/' + this.bmsCustomer.customerId);
    //this.router.navigateByUrl('/bmsadmin/customers/' + this.bmsCustomer.customerId);
  }


}
