import { AfterViewInit, Component, OnInit } from '@angular/core';
import { BarcodeScanner } from '@capacitor-community/barcode-scanner';

@Component({
  selector: 'app-start-mobile-app',
  templateUrl: './start-mobile-app.component.html',
  styleUrls: ['./start-mobile-app.component.scss']
})
export class StartMobileAppComponent implements OnInit, AfterViewInit {

  // todo design: https://github.com/capacitor-community/barcode-scanner/blob/main/examples/scan-area-layout/index.html
  private oldbackgr = '';
  constructor() 
  {    
  }

  public ScannedData : string = "";

  setTransparent()
  {
    let maincontainer = document.getElementsByClassName('main-container');
    maincontainer[0]["style"]["background-color"] = 'transparent';
    BarcodeScanner.hideBackground(); // make background of WebView transparent
  }
  setVisible()
  {
    let maincontainer = document.getElementsByClassName('main-container');
    maincontainer[0]["style"]["background-color"] = this.oldbackgr;
    BarcodeScanner.showBackground();
  }
  stopscan()
  {
    BarcodeScanner.stopScan();
    this.setVisible();
  }

  public disablescan = false;

  async scan()
  {    
    this.setTransparent();

    const result = await BarcodeScanner.startScan(); // start scanning and wait for a result

    if (result.hasContent) {
      this.ScannedData = result.content;
    }
    this.stopscan();
  }

  ngAfterViewInit(): void {
    let maincontainer = document.getElementsByClassName('main-container');
    let t = maincontainer[0]["style"]["background-color"];
    if(t)
      this.oldbackgr = t;
  }
  ngOnInit(): void {
  }

}
