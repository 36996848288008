<h3>Exported files - {{selectedTrustee?.name}}</h3>
<div class="clr-row">
  <div class="clr-col-6">
    <form clrForm clrLayout="vertical">
      <clr-select-container>
        <label>Customer</label>
        <select clrSelect name="customerselect" [(ngModel)]="customerInfo"
          (ngModelChange)="selectedCustomerChange($event)">
          <option *ngFor="let customerInfo of customerInfos" [ngValue]="customerInfo">
            {{customerInfo.customerName}}
          </option>
        </select>
      </clr-select-container>

      <!-- <clr-select-container>
      <label>Metertype</label>
      <select clrSelect name="datareportbasisexportmetertypeselect" [(ngModel)]="selectedExportDataValueMeterType"
        (ngModelChange)="updateDataBasis()">
        <option *ngFor="let meterType of dataExportValuesMeterTypes; let i = index" [ngValue]="meterType">
          {{meterType}}
        </option>
      </select>
    </clr-select-container> -->
    </form>
  </div>
  <div class="clr-col-6">
    <form clrForm clrLayout="vertical">
      <clr-select-container *ngIf="exportPeriods">
        <label>Export period</label>
        <select clrSelect name="exportperiodselect" [(ngModel)]="selectedExportPeriodIndex"
          (ngModelChange)="selectedExportPeriodChange($event)">
          <option *ngFor="let period of exportPeriods; let i = index" [ngValue]="i">
            {{formatByString(period.start,
            'yyyy-MM-dd') + ' - ' + formatByString(period.end, 'yyyy-MM-dd') }}</option>
        </select>
      </clr-select-container>
    </form>
  </div>
</div>

<clr-datagrid [(clrDgSelected)]="selectedExportFileInfos" [clrDgRowSelection]="true"
  (clrDgSelectedChange)="selectedExportFileInfoChanged($event)">

  <clr-dg-action-bar>
    <div class="btn-group">
      <button type="button" class="btn btn-sm btn-secondary" (click)="omDownloadExportedFiles(false)"
        [disabled]="selectedExportFileInfos?.length == 0">
        <clr-icon shape="download"></clr-icon> Download selected ({{selectedExportFileInfos?.length}})
      </button>
    </div>
    <div class="btn-group">
      <button type="button" class="btn btn-sm btn-secondary" (click)="omDownloadExportedFiles(true)"
        [disabled]="exportedFileInfos.length == 0">
        <clr-icon shape="download"></clr-icon> Download All
      </button>
    </div>
  </clr-dg-action-bar>

  <clr-dg-column [clrDgField]="'name'">Name</clr-dg-column>
  <clr-dg-column [clrDgField]="'lastModified'" [clrDgSortOrder]="descSort">Date modified</clr-dg-column>

  <clr-dg-row *clrDgItems="let fileInfo of exportedFileInfos" [clrDgItem]="fileInfo">
    <clr-dg-cell>
      {{fileInfo.name}}
    </clr-dg-cell>
    <clr-dg-cell>
      {{formatDate(fileInfo.lastModified)}}
    </clr-dg-cell>
  </clr-dg-row>

  <clr-dg-footer>
    <clr-dg-pagination #pagination [clrDgPageSize]="10">
      <clr-dg-page-size [clrPageSizeOptions]="[10,20,50,100]">Files per page</clr-dg-page-size>
      {{pagination.firstItem + 1}} - {{pagination.lastItem + 1}} of {{pagination.totalItems}} files
    </clr-dg-pagination>
  </clr-dg-footer>
</clr-datagrid>
