export class BmsResidentExchange {
  id : number;
  customerId : string;
  apartmentId : number;
  facilityId : number;
  exchangeDate : Date;
  createdAt : Date;
  modifiedAt : Date;
  deleted : boolean = false;
  deletedAt : Date = new Date(0);
}
