import { Component, Inject, OnDestroy } from '@angular/core';
import { Subscription } from 'rxjs';
import { StateService } from '@xprojectorcore/services/state-service';
import { XProjectorCustomerUsersClient } from '@xprojectorcore/xprojector_backend/xprojector-customerusers-client';
import { Router } from '@angular/router';
import { XProjectorSysAdminClient } from '@xprojectorcore/xprojector_backend/xprojector-sysadmin-client';
import { xprojCommonStrings } from './common-strings';
import { XprojCommonStringsService } from 'xproj-lib';
import { Title } from '@angular/platform-browser';
import { DOCUMENT } from '@angular/common';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent implements OnDestroy {
  title = 'ROSSAKER XProjector';

  private userSubscription: Subscription;

  public constructor(
    @Inject(DOCUMENT) private document: Document,
    public state: StateService,
    private customerUserClient: XProjectorCustomerUsersClient,
    private sysAdminClient: XProjectorSysAdminClient,
    private commonStrings: XprojCommonStringsService,
    private titleService: Title,
    private router: Router) {

    this.commonStrings.localize(xprojCommonStrings);

    //var title = $localize `:@@app_title:XProjector`;
    this.setTitle(environment.title);

    //var favicon = $localize `:@@app_favicon:favicon.ico`;
    this.setFavicon(environment.favicon);

    this.userSubscription = this.state.user$.subscribe(async (user) => {

      {
        let href = this.router.url;
        if ( href.startsWith('/start-mobile-app') )
        {
          return;
        }
      }

      if (user && !user.isAdminUser) {
        let customerUser = await this.customerUserClient.getActiveCustomerUser();
        if (customerUser) {
          this.state.username = customerUser.firstname + ' ' + customerUser.surname;
        }
        let customer = await this.customerUserClient.getActiveCustomer();
        if (customer) {
          this.state.customerName = customer.name;
          this.state.customerId = customer.id;
          this.state.sandboxEnabled = customer.sandboxEnabled;
        }

        if (!state.userCustomerContentDatas || state.userCustomerContentDatas.length == 0) {
          let customerContent = await this.customerUserClient.getCustomerContent();
          if (customerContent) {
            this.state.updateCustomerConentDatas(customerContent.customeContentData);
          }
        }

        if (this.state.userCustomerContentDatas?.length > 0) {
          let href = this.router.url;
          if (!href.startsWith('/customers') && !href.startsWith('/createcredentials') && !href.startsWith('/resetpassword')
            && !href.startsWith('/bmscustomer/') && !href.startsWith('/userprofile')) {
            this.router.navigateByUrl('customers');
          }
        }
        else {
          let href = this.router.url;
          if (href == '/' || href == '/home') {
            let defaultDashboardId = customer.defaultDashboardId;
            if (customerUser.defaultDashboardId?.length > 0) {
              defaultDashboardId = customerUser.defaultDashboardId;
            }

            if (defaultDashboardId?.length > 0) {
              this.router.navigateByUrl('dashboard/' + defaultDashboardId);
            }
          }
        }
      } else if (user && user.isAdminUser) {
        let activeUser = await this.sysAdminClient.getActiveUser();
        if (activeUser) {
          this.state.username = activeUser.firstname + ' ' + activeUser.surname;
          let href = this.router.url;
          if (href?.startsWith('/login')) {
            this.router.navigateByUrl('/');
          }
        }
      } else {
        this.state.username = '';
        this.state.customerName = '';
      }
    });
  }

  setTitle(newTitle: string) {
    this.titleService.setTitle(newTitle);
  }

  setFavicon(faviconAsset: string) {
    this.document.getElementById('appFavicon')?.setAttribute('href', faviconAsset);
  }


  ngOnDestroy(): void {
    this.userSubscription?.unsubscribe();
  }
}
