<clr-modal [(clrModalOpen)]="showNewTariffModal">
  <h3 i18n='@@customertariffs_modalheader' class="modal-title">New tariff request</h3>
  <div class="modal-body">
    <table class="table table-vertical">
      <caption style="margin-bottom: 1em;">
        <h3 i18n='@@customertariffs_modalnewtariffconfirm'>Save new tariff?</h3>
      </caption>
      <tbody>
        <tr>
          <th i18n='@@customertariffs_modalmetertype' class="left">Meter type</th>
          <td class="left">{{getDisplayMeterType(setBiilingTariffInfo.meterTypeValue?.displayName)}}</td>
        </tr>
        <tr>
          <th i18n='@@customertariffs_modalactivefrom' class="left">Active from</th>
          <td class="left">{{dateHelper.utils.format(setBiilingTariffInfo.activeFromUtc, 'monthAndYear')}}</td>
        </tr>
        <tr>
          <th i18n='@@customertariffs_modalpriceperunit' class="left">Price per unit</th>
          <td class="left">{{setBiilingTariffInfo.value.toFixed(2)}}</td>
        </tr>
        <tr>
          <th i18n='@@customertariffs_modalunit' class="left">Unit</th>
          <td class="left">{{setBiilingTariffInfo.unit}}</td>
        </tr>
      </tbody>
    </table>
  </div>
  <div class="modal-footer">
    <button i18n='@@customertariffs_modalcancel' class="btn btn-outline"
      (click)="cancelNewTariffRequest()">Cancel</button>
    <button i18n='@@customertariffs_modalsave' class="btn btn-primary" (click)="saveNewTariffRequest()">Save</button>
  </div>
</clr-modal>

<div *ngIf="tariffChangeRequests">
  <h3 i18n='@@customertariffs_infoheader' class="information-area">Tariffs</h3>
  <div class="clr-row newtariff-area">
    <div class="clr-col-6">
      <div class="card">
        <div class="card-header">
          <h4 i18n='@@customertariffs_newtariff'>New tariff</h4>
        </div>
        <div class="card-block">
          <div class="card-text">
            <form clrForm *ngIf="setBiilingTariffInfo">
              <clr-select-container>
                <label class="clr-col-5" i18n='@@customertariffs_activefrom'>Active from</label>
                <select clrSelect class="clr-col-7" name="tariffChangeDates" [(ngModel)]="setBiilingTariffInfo.activeFromIndex">
                  <option *ngFor="let date of tariffChangeDates; let i = index" [ngValue]="i">
                    {{dateHelper.utils.format(date, 'monthAndYear')}}</option>
                </select>
              </clr-select-container>

              <clr-select-container>
                <label class="clr-col-5" i18n='@@customertariffs_metertype'>Meter type</label>
                <select class="clr-col-7" clrSelect name="setbillingmetertype" [(ngModel)]="setBiilingTariffInfo.meterTypeValue">
                  <option *ngFor="let meterTypeValue of meterTypeValues" [ngValue]="meterTypeValue">{{meterTypeValue.displayName}}</option>
                </select>
              </clr-select-container>

              <clr-input-container>
                <label class="clr-col-5" i18n='@@customertariffs_tariff'>Tariff:</label>
                <input clrInput class="clr-col-7" type="number" min="0" max="100000" step="0.1" placeholder="" name="setbillingtariff"
                  [(ngModel)]="setBiilingTariffInfo.value" />
              </clr-input-container>
            </form>
          </div>
          <div class="card-footer">
            <div class="btn-group btn-primary">
              <button i18n='@@customertariffs_newtariffbutton' type="submit" class="btn btn-outline" (click)="createNewTariff();">
                <clr-icon shape="floppy"></clr-icon>
                New tariff...
              </button>
            </div>

            <clr-checkbox-container *ngIf="admin">
              <clr-checkbox-wrapper>
                <input type="checkbox" clrCheckbox [(ngModel)]="overrideLastInvoicedAt" name="overrideLastInvoicedAt" (change)="overrideLastInvoicedAtChange()"/>
                <label i18n='@@customertariffs_overridelastinvoicedat'>Ignore last invoiced date.</label>
              </clr-checkbox-wrapper>
            </clr-checkbox-container>

          </div>
        </div>
      </div>
    </div>
    <div class="clr-col-6">
      <div class="tariffinfo">
        <div i18n='@@customertariffs_info_activefrom' class="info-paragraph">
          <b>Active from:</b> First day of month. <a href="mailto:support@homesolutions.se">Send Email</a>
        </div>
        <div i18n='@@customertariffs_info_metertype' class="info-paragraph">
          <b>Meter type:</b> Meter type
        </div>
        <div i18n='@@customertariffs_info_tariff' class="info-paragraph">
          <b>Tariff:</b> The tariff include tax.
        </div>
      </div>
    </div>
  </div>

  <div class="clr-row">
    <div class="clr-col-12">
      <h4 i18n='@@customertariffs_currenttariifs' class="information-area">Current tariffs.</h4>

      <clr-datagrid [clrDgLoading]="loadingTariffChangerequests" class="currenttariffs-table">
        <clr-dg-column [clrDgField]="'tariff.meterType'" [clrDgSortOrder]="ascSort">
          <ng-container i18n='@@customertariffs_infometertype'>Meter type</ng-container>
        </clr-dg-column>
        <clr-dg-column [clrDgField]="'activeFromString'" [style.min-width.px]="100">
          <ng-container i18n='@@customertariffs_infoactivefrom'>Active from</ng-container>
        </clr-dg-column>
        <clr-dg-column [clrDgField]="'tariff.value'" [style.min-width.px]="100">
          <ng-container i18n='@@customertariffs_infoprice'>Price</ng-container>
        </clr-dg-column>
        <clr-dg-column [style.min-width.px]="100">
          <ng-container i18n='@@customertariffs_infonextactivefrom'>Next</ng-container>
        </clr-dg-column>
        <clr-dg-column [style.min-width.px]="100">
          <ng-container i18n='@@customertariffs_infonextprice'>Next price</ng-container>
        </clr-dg-column>

        <clr-dg-row *clrDgItems="let item of currentTariffChangeRequests; let i = index" [clrDgItem]="item">
          <clr-dg-cell class="left" (click)="item.expanded = !item.expanded">{{item.displayMeterType}}</clr-dg-cell>
          <clr-dg-cell class="left" (click)="item.expanded = !item.expanded">{{item.activeFromString}}</clr-dg-cell>
          <clr-dg-cell class="left" (click)="item.expanded = !item.expanded">{{item.tariff.value.toFixed(2)}}
            kr/{{item.tariff.unit}}</clr-dg-cell>
          <clr-dg-cell *ngIf="item.nextTariff" class="left"
            (click)="item.expanded = !item.expanded">{{dateHelper.utils.formatByString(item.nextTariff.activeFrom.toDate(),
            'yyyy-MM-dd')}}</clr-dg-cell>
          <clr-dg-cell *ngIf="item.nextTariff" class="left"
            (click)="item.expanded = !item.expanded">{{item.nextTariff.value.toFixed(2)}}
            kr/{{item.tariff.unit}}</clr-dg-cell>
          <clr-dg-cell *ngIf="!item.nextTariff" class="left" (click)="item.expanded = !item.expanded">---</clr-dg-cell>
          <clr-dg-cell *ngIf="!item.nextTariff" class="left" (click)="item.expanded = !item.expanded">---</clr-dg-cell>

          <clr-dg-row-detail *clrIfExpanded="item.expanded">
            <table class="table table-noborder">
              <thead>
                <tr>
                  <th i18n='@@customertariffs_infohistoryactivefrom' class="left">Active from</th>
                  <th i18n='@@customertariffs_infohistoryprice' class="left">Price</th>
                  <th i18n='@@customertariffs_infohistoryuser' class="left">User</th>
                  <th class="left"></th>
                </tr>
              </thead>
              <tbody>
                <tr *ngFor="let tariff of item.allTariffs">
                  <td class="left">{{dateHelper.utils.formatByString(tariff.activeFrom.toDate(), 'yyyy-MM-dd')}}</td>
                  <td class="left">{{tariff.value.toFixed(2)}}</td>
                  <td class="left">{{getCustomerUserDisplayname(tariff.userId)}}</td>
                  <td class="left" *ngIf="tariff.activeFrom.toDate() > lastInvoicedAt"><clr-icon shape="trash"
                      style="cursor: pointer;" (click)="deleteTariff(item, tariff)"></clr-icon></td>
                  <td class="left" *ngIf="tariff.activeFrom.toDate() <= lastInvoicedAt"></td>
                </tr>
              </tbody>
            </table>
          </clr-dg-row-detail>
        </clr-dg-row>
      </clr-datagrid>
    </div>
  </div>

  <div class="clr-row">
    <div class="clr-col-12">
      <clr-accordion class="auditlogs">
        <clr-accordion-panel>
          <clr-accordion-title i18n='@@customertariffs_auditlog'>Audit log</clr-accordion-title>
          <clr-accordion-content [(clrIfExpanded)]="auditLogActive">
            <clr-tabs class="tabs-vertical">
              <clr-tab *ngFor="let item of tariffChangeRequestsHistoryByMeterType">
                <button clrTabLink>{{item.displayName}}</button>
                <ng-template [(clrIfActive)]="item.active">
                  <clr-tab-content>
                    <clr-datagrid [clrDgLoading]="loadingTariffChangerequestsHistory" class="auditlog-table">
                      <clr-dg-column [clrDgField]="'modifiedAt'" [style.min-width.px]="150" [clrDgSortOrder]="descSort">
                        <ng-container i18n='@@customertariffs_auditlogtimestamp'>Timestamp</ng-container>
                      </clr-dg-column>
                      <clr-dg-column [clrDgField]="'activeFromDate'" [style.min-width.px]="100">
                        <ng-container i18n='@@customertariffs_auditlogactivefrom'>Active from</ng-container>
                      </clr-dg-column>
                      <clr-dg-column [clrDgField]="'value'" [style.min-width.px]="100">
                        <ng-container i18n='@@customertariffs_auditlogprice'>Price</ng-container>
                      </clr-dg-column>
                      <clr-dg-column [clrDgField]="'requestTypeString'" [style.min-width.px]="100">
                        <ng-container i18n='@@customertariffs_auditlogrequesttype'>Type</ng-container>
                      </clr-dg-column>
                      <clr-dg-column [clrDgField]="'user'" [style.min-width.px]="200">
                        <ng-container i18n='@@customertariffs_auditloguser'>User</ng-container>
                      </clr-dg-column>

                      <clr-dg-row *clrDgItems="let info of item.tariffChangeRequestInfos; let i = index"
                        [clrDgItem]="info">
                        <clr-dg-cell class="left">{{dateHelper.utils.formatByString(info.createdAt, 'yyyy-MM-dd HH:mm')}}</clr-dg-cell>
                        <clr-dg-cell class="left">{{dateHelper.utils.formatByString(info.activeFromDate,
                          'yyyy-MM-dd')}}</clr-dg-cell>
                        <clr-dg-cell class="left">{{info.value.toFixed(2)}}</clr-dg-cell>
                        <clr-dg-cell class="left">{{info.requestTypeString}}</clr-dg-cell>
                        <clr-dg-cell class="left">{{info.user}}</clr-dg-cell>
                      </clr-dg-row>

                      <clr-dg-footer>
                        <clr-dg-pagination #pagination [clrDgPageSize]="10">
                          <clr-dg-page-size i18n='@@customertariffs_auditloglogsperpage' [clrPageSizeOptions]="[10,20,50,100]"> Logs per page</clr-dg-page-size>
                          {{pagination.firstItem + 1}} - {{pagination.lastItem + 1}} of {{pagination.totalItems}} logs
                        </clr-dg-pagination>
                      </clr-dg-footer>
                    </clr-datagrid>
                  </clr-tab-content>
                </ng-template>
              </clr-tab>
            </clr-tabs>
          </clr-accordion-content>
        </clr-accordion-panel>
      </clr-accordion>
    </div>
  </div>
</div>
