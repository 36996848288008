


export class BmsQaBotConfig {
  nodeId : string;
  name : string;
  enabled : boolean;
  schedule : string;

  relativeFromDays : number;
  ignoreInterpolatedValues : boolean;
  maxConcurrentTasks : number;
  errorMetersProjectionId : string;
}


export class BmsQaBotExecution {
  configNodeId : string;
  ignoreInterpolatedValues : boolean;
  relativeFromDays : number = 1;
  maxConcurrentTasks : number;
  overrideUtcFrom : Date = null;
  overrideUtcTo : Date = null;
  errorMetersProjectionId : string;
}
