export class BmsMeterExchange {
  id : number;
  customerId : string;
  oldMeterId : number;
  newMeterId : number;
  newMeterStartValue : number;
  oldMeterEndValue : number = 0;
  exchangeDate : Date;
  createdAt : Date;
  modifiedAt : Date;
  deleted : boolean = false;
  deletedAt : Date = new Date(0);
}
