import { ThingsboardClient } from './proto/xprojector.module.thingsboard.pbsc';
import { Injectable, OnDestroy, OnInit } from '@angular/core';
import { StateService } from '@xprojectorcore/services/state-service';
import {  } from './proto/xprojector.modulebms.events.pb';
import { GetDeviceGroupsRequest, GetDeviceGroupsResponse, GetDevicesRequest, GetDevicesResponse, ImportDataRequest, ImportDataResponse, UpsertDeviceGroupRequest, UpsertDeviceGroupResponse } from './proto/xprojector.module.thingsboard.pb';

@Injectable({
  providedIn: 'root'
})
export class XProjectorBmsThingsboardClient implements OnInit, OnDestroy {

  constructor(
    private thingsboardClient: ThingsboardClient,
    private state: StateService) {
  }

  ngOnDestroy(): void {
  }

  ngOnInit(): void {

  }

  async getDeviceGroups(customerId: string) : Promise<GetDeviceGroupsResponse> {
    let request: GetDeviceGroupsRequest = new GetDeviceGroupsRequest({
      customerId: customerId
    });

    return await this.thingsboardClient.getDeviceGroups(request, this.state.metadata).toPromise();
  }

  async getDevices(deviceGroupId : string) : Promise<GetDevicesResponse> {
    let request: GetDevicesRequest = new GetDevicesRequest({
      deviceGroupId: deviceGroupId
    });

    return await this.thingsboardClient.getDevices(request, this.state.metadata).toPromise();
  }

  async upsertDeviceGroup(customerId: string, deviceGroupId : string) : Promise<UpsertDeviceGroupResponse> {
    let request: UpsertDeviceGroupRequest = new UpsertDeviceGroupRequest({
      customerId: customerId,
      deviceGroupId: deviceGroupId
    });

    return await this.thingsboardClient.upsertDeviceGroup(request, this.state.metadata).toPromise();
  }

  async importData(deviceGroupId : string) : Promise<ImportDataResponse> {
    let request: ImportDataRequest = new ImportDataRequest({
      deviceGroupId: deviceGroupId
    });

    return await this.thingsboardClient.importData(request, this.state.metadata).toPromise();
  }
}
