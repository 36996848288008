import { Component, Input, OnInit, inject } from '@angular/core';
import { DashboardOutputChangeParameters, DateHelper, LinkedWidgetSelectParameters, OutputDataType, XPROJBIMSERVICE, XprojBimService, XprojAlertService, XprojModalService, BimFileInfo } from 'xproj-lib';
import { ClrLoadingState } from '@clr/angular';
import { saveAs } from 'file-saver';
import { BmsAdminService } from '@features/bms/bms-admin/services/bms-admin-service';
import { BmsCustomer } from '@app/features/bms/models/bms-customer';
import { Customer, GrpcNode } from '@xprojectorcore/xprojector_backend/proto/xprojector.grpc.models.pb';

@Component({
  selector: 'app-bms-3d-configuration',
  templateUrl: './bms-3d-configuration.component.html',
  styleUrls: ['./bms-3d-configuration.component.scss']
})
export class Bms3DConfigurationComponent implements OnInit {
  public static NodeTypeId: string = '_x_bms_building';

  private alertService: XprojAlertService = inject(XprojAlertService);
  public dateHelper: DateHelper = inject(DateHelper);
  private modalService: XprojModalService = inject(XprojModalService);
  private xprojBimService: XprojBimService = inject(XPROJBIMSERVICE);
  public adminService: BmsAdminService = inject(BmsAdminService);

  private _bmsCustomer : BmsCustomer;
  @Input() set  bmsCustomer(value : BmsCustomer) {
    this._bmsCustomer = value;
    setTimeout(() => {
      this.init();
    });
  }
  get bmsCustomer() : BmsCustomer {
    return this._bmsCustomer;
  }

  @Input() customer : Customer;
  @Input() node : GrpcNode;

  filesActive : boolean = false;
  editorActive : boolean = true;

  responsiveWidth: number = 600;

  meterParameters: DashboardOutputChangeParameters[] = [];

  uploadFile: any;
  uploadingState: ClrLoadingState = ClrLoadingState.DEFAULT;
  bimFileInfo: BimFileInfo;

  babylonUploadFile: any;
  babylonUploadingState: ClrLoadingState = ClrLoadingState.DEFAULT;
  babylonFileInfo: BimFileInfo;

  floorplanUploadFile: any;
  floorplanUploadingState: ClrLoadingState = ClrLoadingState.DEFAULT;
  floorplanFileInfo: BimFileInfo;

  constructor(

  ) { }

  ngOnInit(): void {
  }


  init() {
    this.updateDashboardOutputs();
    this.updateBIMFileInfo('bim');
    this.updateBIMFileInfo('babylon');
  }

  updateDashboardOutputs() {
    this.meterParameters = [];
    if (this.bmsCustomer) {

      let out = new DashboardOutputChangeParameters();
      out.outputParameterName = 'customerid';
      out.value = this.bmsCustomer.customerId;
      out.datatype = OutputDataType.String;
      this.meterParameters.push(out);

      out = new DashboardOutputChangeParameters();
      out.outputParameterName = 'customerxdbid';
      out.value = this.bmsCustomer.id;
      out.datatype = OutputDataType.Int64;
      this.meterParameters.push(out);

      // out = new DashboardOutputChangeParameters();
      // out.outputParameterName = 'id';
      // out.value = +this.data.node.id;
      // out.datatype = OutputDataType.Int64;
      // this.meterParameters.push(out);
    }
  }

  selectBIMFile(file) {
    if (file.files.length > 0) {
      this.uploadFile = file.files[0];
      if (!this.uploadFile.type || this.uploadFile.type.length == 0) {
        let name: string = file.name;
        let s = name.split('.');
        if (s.length > 1) {
          this.uploadFile.type= s[s.length - 1];
        }
        else {
          this.uploadFile.type = 'unknown';
        }
      }
    }
  }

  selectBabaylonFile(babylonfile) {
    if (babylonfile.files.length > 0) {
      this.babylonUploadFile = babylonfile.files[0];
      if (!this.babylonUploadFile.type || this.babylonUploadFile.type.length == 0) {
        let name: string = babylonfile.name;
        let s = name.split('.');
        if (s.length > 1) {
          this.babylonUploadFile.type= s[s.length - 1];
        }
        else {
          this.babylonUploadFile.type = 'unknown';
        }
      }
    }
  }

  async uploadBIMFile() {
    if (this.uploadFile) {
      try {
        this.uploadingState = ClrLoadingState.LOADING;

        let result = await this.xprojBimService.uploadBimFile(this.node.id, this.uploadFile.name, this.uploadFile.type, this.uploadFile, this.bmsCustomer.customerId);

        if (result) {
          this.uploadingState = ClrLoadingState.SUCCESS;
          this.alertService.success('Upload file ok!');
          await this.updateBIMFileInfo('bim');
        }
        else {

          this.uploadingState = ClrLoadingState.ERROR;
        }
      }
      catch (err) {
        this.alertService.error('Error import file:', err);
      }
    }
  }

  async uploadBabaylonFile() {
    if (this.babylonUploadFile) {
      try {
        this.babylonUploadingState = ClrLoadingState.LOADING;

        let result = await this.xprojBimService.uploadBabylonFile(this.node.id, this.babylonUploadFile.name, this.babylonUploadFile.type, this.babylonUploadFile, this.bmsCustomer.customerId);

        if (result) {
          this.babylonUploadingState = ClrLoadingState.SUCCESS;
          this.alertService.success('Upload file ok!');
          await this.updateBIMFileInfo('babylon');
        }
        else {

          this.babylonUploadingState = ClrLoadingState.ERROR;
        }
      }
      catch (err) {
        this.alertService.error('Error import file:', err);
      }
    }
  }

  async uploadFloorplanFile() {
    if (this.babylonUploadFile) {
      try {
        this.babylonUploadingState = ClrLoadingState.LOADING;

        let result = await this.xprojBimService.uploadFloorplanFile(this.node.id, this.babylonUploadFile.name, this.babylonUploadFile.type, this.babylonUploadFile, this.bmsCustomer.customerId);

        if (result) {
          this.babylonUploadingState = ClrLoadingState.SUCCESS;
          this.alertService.success('Upload file ok!');
          await this.updateBIMFileInfo('babylon');
        }
        else {

          this.babylonUploadingState = ClrLoadingState.ERROR;
        }
      }
      catch (err) {
        this.alertService.error('Error import file:', err);
      }
    }
  }

  async updateBIMFileInfo(fileType: string) {
    if (fileType == 'bim') {
      this.bimFileInfo = await this.xprojBimService.getBimFileInfo(this.node.id, this.bmsCustomer.customerId);
    }
    else if (fileType == 'babylon') {
      this.babylonFileInfo = await this.xprojBimService.getBabylonFileInfo(this.node.id, this.bmsCustomer.customerId);
    }
    else if (fileType == 'floorplan') {
      this.floorplanFileInfo = await this.xprojBimService.getFloorplanFileInfo(this.node.id, this.bmsCustomer.customerId);
    }
  }

  async downloadBIMFile() {
    if (this.bimFileInfo) {
      this.xprojBimService.getBimFile(this.node.id, this.bmsCustomer.customerId)
        .then(blob => {
          saveAs(new TextDecoder().decode(blob.data), this.bimFileInfo.name);
        })
        .catch(error => {
          this.alertService.error(error);
        });
    }
  }

  async downloadBabylonFile() {
    if (this.babylonFileInfo) {
      this.xprojBimService.getBabylonFile(this.node.id, this.bmsCustomer.customerId)
        .then(blob => {
          saveAs(new TextDecoder().decode(blob.data), this.babylonFileInfo.name);
        })
        .catch(error => {
          this.alertService.error(error);
        });
    }
  }

  async downloadFloorplanFile() {
    if (this.babylonFileInfo) {
      this.xprojBimService.getFloorplanFile(this.node.id, this.bmsCustomer.customerId)
        .then(blob => {
          saveAs(new TextDecoder().decode(blob.data), this.floorplanFileInfo.name);
        })
        .catch(error => {
          this.alertService.error(error);
        });
    }
  }

  async deleteBIMFile() {
    if (this.bimFileInfo) {
      let doDelete = await this.modalService.ShowConfirmModalAsync({
        header: 'Delete file',
        description: 'Delete file, are you sure?',
        ok: 'Delete',
        cancel: 'Cancel'
      });

      if (doDelete) {
        this.xprojBimService.deleteBimFile(this.node.id, this.bmsCustomer.customerId)
          .then(result => {
            this.bimFileInfo = undefined;
            this.alertService.success('File deleted');
          })
          .catch(error => {
            this.alertService.error(error);
          });
      }
    }
  }

  async deleteBabylonFile() {
    if (this.babylonFileInfo) {
      let doDelete = await this.modalService.ShowConfirmModalAsync({
        header: 'Delete file',
        description: 'Delete file, are you sure?',
        ok: 'Delete',
        cancel: 'Cancel'
      });

      if (doDelete) {
        this.xprojBimService.deleteBabylonFile(this.node.id, this.bmsCustomer.customerId)
          .then(result => {
            this.babylonFileInfo = undefined;
            this.alertService.success('File deleted');
          })
          .catch(error => {
            this.alertService.error(error);
          });
      }
    }
  }

  async deleteFloorplanFile() {
    if (this.babylonFileInfo) {
      let doDelete = await this.modalService.ShowConfirmModalAsync({
        header: 'Delete file',
        description: 'Delete file, are you sure?',
        ok: 'Delete',
        cancel: 'Cancel'
      });

      if (doDelete) {
        this.xprojBimService.deleteFloorplanFile(this.node.id, this.bmsCustomer.customerId)
          .then(result => {
            this.babylonFileInfo = undefined;
            this.alertService.success('File deleted');
          })
          .catch(error => {
            this.alertService.error(error);
          });
      }
    }
  }
}
