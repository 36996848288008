import { Component, Input, OnInit, inject } from '@angular/core';
import { BmsDataExportValue } from '@app/features/bms/models/bms-data-export-value';
import { ClrDatagridFilter, ClrDatagridStringFilterInterface } from '@clr/angular';
import { Subject } from 'rxjs';
import { LOGGERSERVICE, XprojLoggerService } from 'xproj-lib';

@Component({
  selector: 'app-data-export-externalid-filter',
  templateUrl: './data-export-externalid-filter.component.html',
  styleUrls: ['./data-export-externalid-filter.component.scss']
})
export class DataExportExternalidFilterComponent implements OnInit, ClrDatagridStringFilterInterface<BmsDataExportValue> {

  private logger: XprojLoggerService = inject(LOGGERSERVICE);

  private _exportValues : BmsDataExportValue[] = [];
  @Input() get exportValues() : BmsDataExportValue[] {
    return this._exportValues;
  }
  set exportValues(value: BmsDataExportValue[]) {
    this._exportValues = value;
    this.initExportValues();
  }

  changes = new Subject<any>();


  externalIds : {externalId : string, selected : boolean}[] = [];

  constructor(private filterContainer: ClrDatagridFilter) {
    filterContainer.setFilter(this);
  }

  change(): void {
    this.changes.next(true);
  }

  isActive(): boolean {
     return true;
  }

  accepts(item: BmsDataExportValue): boolean {
    let result = this.externalIds.find(x => x.externalId == item.externalId);
    if (result) {
      return result.selected;
    }

    return true;
  }

  ngOnInit() {

  }

  initExportValues() {
    this.externalIds = [];
    if (this.exportValues) {
      this.exportValues.forEach( x => {
        if (this.externalIds.findIndex(y => y.externalId == x.externalId) < 0) {
          this.externalIds.push({externalId : x.externalId, selected : true })
        }
      });
    }
  }

  statusChanged($event) {
    this.change();
  }

  none() {
    this.externalIds.forEach(x => x.selected = false);
    this.change();
  }

  all() {
    this.externalIds.forEach(x => x.selected = true);
    this.change();
  }
}
