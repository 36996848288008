export enum BmsBillingPeriod {
  Week = 0,
  Month = 1,
  Quarter = 2,
  Year = 3
}

export enum BmsMonthOfYear {
  January = 1,
  February = 2,
  March = 3,
  April = 4,
  May = 5,
  June = 6,
  July = 7,
  August = 8,
  September = 9,
  October = 10,
  November = 11,
  December = 12
}

export class BmsExportBotConfig {
  nodeId : string;
  name : string;
  enabled : boolean;
  schedule : string;
  meterTypes: string[] = [];
  createPeriodDataBasis : boolean = false;
  createPeriodDataReport : boolean = false;
  billingPeriod : BmsBillingPeriod = BmsBillingPeriod.Month;
  billingPeriodStartMonth : BmsMonthOfYear = BmsMonthOfYear.March;
  setInvoicedFlag : boolean = false;
  outputOnlySigned : boolean = false;
  outputOnlyNotInvoiced : boolean = false;
  includeStateTest : boolean = false;
  includeStateActiveBilling : boolean = true;
  includeStateActiveNoBilling : boolean = false;
  includeStateActiveExport : boolean = false;
  signOkDatavalues : boolean = true;
  qaExportEnabled : boolean = true;
  extrapolateMaxDays : number = 10;
  customersPerOutput : number = 20;
  invoiceDayMin : number = 1;
  invoiceDayMax : number = 31;
  invoiceDayWaitTariffSet : boolean = false;
}


export class BmsExportBotExecution {
  configNodeId : string;
  meterTypes: string[] = [];
  periodStart : Date;
  periodEnd : Date;
  enableOutput : boolean = false;
  createPeriodDataBasis : boolean = false;
  createPeriodDataReport : boolean = false;
  //storeOnServer : boolean = false;
  storeOnClient : boolean = true;
  retry : number = 1;
  customerIds : string[] = [];
  bmsBillingPeriod : BmsBillingPeriod = BmsBillingPeriod.Month;
  bmsBillingPeriodStartMonth : BmsMonthOfYear = BmsMonthOfYear.March;
  setInvoicedFlag : boolean = false;
  outputOnlySigned : boolean = false;
  outputOnlyNotInvoiced : boolean = false;
  invoiceDayMin : number = 1;
  invoiceDayMax : number = 1;
  invoiceDayWaitTariffSet : boolean = false;

  includeStateTest : boolean = false;
  includeStateActiveBilling : boolean = true;
  includeStateActiveNoBilling : boolean = false;
  includeStateActiveExport : boolean = false;
  signOkDatavalues : boolean = true;
  qaExportEnabled : boolean = false;
  extrapolateMaxDays : number = 10;

  overwriteSignedAndInvoiced : boolean = false;
  testExportEnabled : boolean = false;
  customersPerOutput : number = 20;
}
