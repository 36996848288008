<clr-select-container *ngIf="deviceGroups">
  <label>Device groups</label>
  <select clrSelect name="selecteddevicegroup" [(ngModel)]="selectedDeviceGroup">
    <option *ngFor="let deviceGroup of deviceGroups" [ngValue]="deviceGroup">{{deviceGroup.name}}</option>
  </select>
</clr-select-container>

<div class="button-row">
  <button type="button" class="btn btn-outline" (click)="updateDeviceGroups()">
    <clr-icon shape="refresh"></clr-icon>
    Update list
  </button>
  <button type="button" class="btn" (click)="importDeviceGroup()" [clrLoading]="loadingState"[disabled]="!this.selectedDeviceGroup || this.selectedDeviceGroup.id.length == 0" >
    <clr-icon shape="import"></clr-icon>
    Add or update...
  </button>
  <!-- <button type="button" class="btn" (click)="importData()" [clrLoading]="loadingState"[disabled]="!this.selectedDeviceGroup || this.selectedDeviceGroup.id.length == 0" >
    <clr-icon shape="import"></clr-icon>
    Import data...
  </button> -->
</div>
