<div class="customers-content-area">
  <clr-tabs>
    <clr-tab>
      <button clrTabLink>Info</button>
      <clr-tab-content>
        <xproj-dashboard #dashboardOverview [editMode]="false" [showDashboardSettings]="false" [systemDashboard]="true"
          [dashboardId]="'bms_gateway'" [enableExport]="false" [dashboardTag]="'latest'"
          [responsiveWidth]="responsiveWidth" [dashboardOutputParameters]="meterParameters"
          (onWidgetValueSelected)="meterDashboardValueChanged($event)" [enableExport]="true"
          (onWidgetExport)="onWidgetExport($event)">
        </xproj-dashboard>
      </clr-tab-content>
    </clr-tab>
    <clr-tab>
      <button clrTabLink>Backup</button>
      <clr-tab-content>
        <div class="card">
          <div class="card-header">
            Gateway backup file
          </div>
          <div class="card-block">
            <div class="card-text">
              <table class="table table-compact table-vertical backup">
                <tbody>
                  <tr>
                    <th class="left" style="width: 100px;">Backup file</th>
                    <td *ngIf="backupFileInfo" class="left">{{backupFileInfo.name}}
                      <!-- <clr-icon shape="download-cloud" style="cursor: pointer" (click)="downloadGatewayFile()"></clr-icon> -->
                    </td>
                    <td *ngIf="!backupFileInfo" class="left">---</td>
                    <td *ngIf="backupFileInfo" style="width: 5px;" class="right">
                      <clr-icon shape="download-cloud" style="cursor: pointer"
                        (click)="downloadGatewayFile()"></clr-icon>
                    </td>
                    <td *ngIf="backupFileInfo" style="width: 5px;" class="right">
                      <clr-icon shape="trash" style="cursor: pointer" (click)="deleteGatewayFile()"></clr-icon>
                    </td>
                  </tr>
                  <tr>
                    <th class="left" style="width: 100px;">Time</th>
                    <td class="left">{{backupFileInfo ? dateHelper.utils.format(backupFileInfo.lastModified,
                      'keyboardDateTime')
                      :
                      '---'}}</td>
                    <td *ngIf="backupFileInfo" style="width: 5px;" class="right"></td>
                    <td *ngIf="backupFileInfo" style="width: 5px;" class="right"></td>
                  </tr>
                </tbody>
              </table>

              <form clrForm clrLayout="vertical" autocomplete="off">
                <clr-input-container>
                  <label class="clr-col-12">Upload gateway backup file, select or drop file.</label>
                  <input clrInput class="clr-col-12" name="importgatewayfile_file" #file type="file"
                    (change)="selectGatewayFile(file)" />
                </clr-input-container>
              </form>

              <button type="button" class="btn btn-sm btn-outline" [clrLoading]="uploadingState"
                [disabled]="!uploadFile" (click)="uploadGatewayFile()">
                <clr-icon shape="import"></clr-icon>
                Upload gateway backup file
              </button>
            </div>
          </div>
        </div>
        <div class="card">
          <div class="card-header">
            Export gateway config
          </div>
          <div class="card-block">
            <div class="card-text">
              <clr-combobox-container>
                <label>Select format:</label>
                <clr-combobox [(ngModel)]="exportConfigId" name="exportconfigidselect">
                  <clr-options>
                    <clr-option  [clrValue]="'Piigab'"> Piigab
                    </clr-option>
                  </clr-options>
                </clr-combobox>
              </clr-combobox-container>

              <button type="button" class="btn btn-sm btn-outline btn-export-config" [clrLoading]="exportConfigState"
                (click)="getGatewayConfig()">
                <clr-icon shape="cog"></clr-icon>
                Export config
              </button>
            </div>
          </div>
        </div>
      </clr-tab-content>
    </clr-tab>
  </clr-tabs>
</div>
