export class BmsBillingTariff {
  id : number;
  meterId : number;
  apartmentId : number;
  facilityId : number;
  buildingAddressId : number;
  buildingId : number;
  realestateId : number;

  activeFrom : Date;
  value : number;
  currency : string;
  includeVat : boolean;
  vatRate : number;

  createdAt : Date;
  modifiedAt : Date;

  deleted : boolean = false;
  deletedAt : Date = new Date(0);
}
