<button type="button" class="btn btn-link" (click)="all();">
  All
</button>
<button type="button" class="btn btn-link" (click)="none();">
  None
</button>

<clr-checkbox-container>
  <clr-checkbox-wrapper *ngFor="let item of externalIds; index as i">
    <input type="checkbox" clrCheckbox [(ngModel)]="item.selected" name="selected_{{i}}" (change)="statusChanged($event)"/>
    <label>{{item.externalId}}</label>
  </clr-checkbox-wrapper>
</clr-checkbox-container>
