<div class="tariff-group-view">
  <clr-datagrid #tariffgroupgrid class="tariff-groups-list" [(clrDgSingleSelected)]="selectedTariffGroup"
    [clrDgLoading]="loadingTariffGroups">

    <clr-dg-action-bar>
      <div class="btn-group">
        <button type="button" class="btn btn-sm btn-secondary" (click)="addTariffGroup()"
          [clrLoading]="loadingTariffGroups">
          <clr-icon shape="export"></clr-icon> Add tariff group...
        </button>
      </div>

    </clr-dg-action-bar>
    <clr-dg-column [clrDgField]="'tariffGroup.name'">
      <ng-container *clrDgHideableColumn="{hidden: false}">Tariff group</ng-container>
    </clr-dg-column>
    <clr-dg-column [clrDgField]="'tariffGroup.description'">
      <ng-container *clrDgHideableColumn="{hidden: false}">Description</ng-container>
    </clr-dg-column>
    <clr-dg-column [clrDgField]="'tariffGroup.unit'">
      <ng-container *clrDgHideableColumn="{hidden: false}">Unit</ng-container>
    </clr-dg-column>
    <clr-dg-column [clrDgField]="'tariffGroup.maxTariffPerUnit'">
      <ng-container *clrDgHideableColumn="{hidden: false}">Max cost per unit</ng-container>
    </clr-dg-column>
    <clr-dg-column [clrDgField]="'meterCount'">
      <ng-container *clrDgHideableColumn="{hidden: false}">Meter count</ng-container>
    </clr-dg-column>

    <clr-dg-row *clrDgItems="let item of tariffGroups ; index as i" [clrDgItem]="item.tariffGroup">
      <clr-dg-cell class="sensitive"> {{item.tariffGroup.name}}
      </clr-dg-cell>
      <clr-dg-cell class="sensitive"> {{item.tariffGroup.description}}
      </clr-dg-cell>
      <clr-dg-cell class="sensitive"> {{item.tariffGroup.unit}}
      </clr-dg-cell>
      <clr-dg-cell class="sensitive"> {{item.tariffGroup.maxTariffPerUnit}}
      </clr-dg-cell>
      <clr-dg-cell class="sensitive"> {{item.meterCount}}
      </clr-dg-cell>
    </clr-dg-row>

  </clr-datagrid>

  <div class="card limit-width" *ngIf="selectedTariffGroup && !newTariffGroup">
    <div class="card-header">
      Edit tariff group - {{selectedTariffGroup.name}}
    </div>

    <div class="card-block">
      <form class="clr-form clr-form-vertical">

        <div class="clr-form-control">
          <label class="clr-control-label">Name</label>
          <div class="clr-control-container">
            <div class="clr-input-wrapper">
              <input clrInput placeholder="name" type="text" size="60"  name="update_tariffgroup_name" [(ngModel)]="selectedTariffGroup.name" />
            </div>
          </div>
        </div>

        <div class="clr-form-control">
          <label class="clr-control-label">Description</label>
          <div class="clr-control-container">
            <div class="clr-input-wrapper">
              <input clrInput placeholder="" type="text" size="60" name="update_tariffgroup_description" [(ngModel)]="selectedTariffGroup.description" />
            </div>
          </div>
        </div>

        <clr-select-container>
          <label>Unit</label>
          <select clrSelect name="interactionmode" [(ngModel)]="selectedTariffGroup.unit">
            <option *ngFor="let item of units" [ngValue]="item.unit"> {{item.unit}} </option>
          </select>
        </clr-select-container>

        <div class="clr-form-control">
          <label class="clr-control-label">Max cost per unit ({{selectedTariffGroup.unit}})</label>
          <div class="clr-control-container">
            <div class="clr-input-wrapper">
              <input clrInput placeholder="name" type="number" min="-1" max="99999" step="1" name="update_tariffgroup_defaultmaxcostperunit" [(ngModel)]="selectedTariffGroup.maxTariffPerUnit" />
            </div>
          </div>
        </div>
      </form>

      <div class="card-footer buttons">
        <button type="button" class="btn btn-danger-outline" (click)="deleteTariffGroup()">
          <clr-icon shape="trash"></clr-icon>
          Delete...
        </button>
        <button type="button" class="btn btn-primary" (click)="saveTariffGroup()">
          <clr-icon shape="floppy"></clr-icon>
          Save
        </button>
      </div>
    </div>
  </div>

  <div class="card limit-width" *ngIf="newTariffGroup">
    <div class="card-header">
      Add tariff group
    </div>

    <div class="card-block">
      <form class="clr-form clr-form-vertical">

        <div class="clr-form-control">
          <label class="clr-control-label">Name</label>
          <div class="clr-control-container">
            <div class="clr-input-wrapper">
              <input clrInput placeholder="name" type="text" size="60"  name="add_tariffgroup_name" [(ngModel)]="newTariffGroup.name" />
            </div>
          </div>
        </div>

        <div class="clr-form-control">
          <label class="clr-control-label">Description</label>
          <div class="clr-control-container">
            <div class="clr-input-wrapper">
              <input clrInput placeholder="" type="text" size="60" name="add_tariffgroup_description" [(ngModel)]="newTariffGroup.description" />
            </div>
          </div>
        </div>

        <clr-select-container>
          <label>Unit</label>
          <select clrSelect name="interactionmode" [(ngModel)]="newTariffGroup.unit">
            <option *ngFor="let item of units" [ngValue]="item.unit"> {{item.unit}} </option>
          </select>
        </clr-select-container>

        <div class="clr-form-control">
          <label class="clr-control-label">Max cost per unit ({{newTariffGroup.unit}})</label>
          <div class="clr-control-container">
            <div class="clr-input-wrapper">
              <input clrInput placeholder="-1" type="number" min="-1" max="99999" step="1" name="add_tariffgroup_defaultmaxcostperunit" [(ngModel)]="newTariffGroup.maxTariffPerUnit" />
            </div>
          </div>
        </div>
      </form>

      <div class="card-footer buttons">
        <button type="button" class="btn btn-secondary" (click)="newTariffGroup = null">
          <clr-icon shape="trash"></clr-icon>
          Cancel
        </button>
        <button type="button" class="btn btn-primary" (click)="doAddTariffGroup()">
          <clr-icon shape="floppy"></clr-icon>
          Add
        </button>
      </div>
    </div>
  </div>
</div>
